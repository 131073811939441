<div class="pt-3 flex flex-col w-full grid grid-cols-1 sm:grid-cols-3 gap-3">
  <div *ngFor="let group of preferenceGroups$ | async; trackBy: 'id' | trackByKey">
    <div class="w-full bg-white p-2 shadow rounded">
      <!-- <mat-expansion-panel-header> -->
      <div class="flex items-center text-lg">
        <div class="font-bold">{{ group.name | titlecase }}</div>
      </div>
      <!-- </mat-expansion-panel-header> -->
      <!-- <div class="mb-3">
          <mat-selection-list #ordersSelection> -->
      <div class="grid grid-cols-1 gap-4 mt-3 select-none">
        <div
          class="flex flex-row justify-between first:mt-0 rounded-sm w-full"
          *ngFor="let pref of group.preferencesList; trackBy: 'id' | trackByKey"
        >
          <mat-slide-toggle
            color="primary"
            [checked]="pref.userEmailEnabled"
            (click)="onHandleSettingsChange(pref.id, pref.userEmailEnabled)"
          >
            <span>{{ pref.prettyPrintName }}</span>
          </mat-slide-toggle>
          <!--<mat-form-field class="hidden" *ngIf="pref.sendFrequencyMinutes.valid">
            <mat-label>Notification Frequency</mat-label>
            <mat-select [value]="pref.sendFrequencyMinutes.pb_int">
              <mat-option (click)="changeFrequency(pref.id, 10080)" [value]="10080">Weekly</mat-option>
              <mat-option (click)="changeFrequency(pref.id, 5660)" [value]="5660">Every Four Days</mat-option>
              <mat-option (click)="changeFrequency(pref.id, 2880)" [value]="2880">Every Two Days</mat-option>
              <mat-option (click)="changeFrequency(pref.id, 1440)" [value]="1440">Once Per Day</mat-option>
              <mat-option (click)="changeFrequency(pref.id, 720)" [value]="720">Twice Per Day</mat-option>
              <mat-option (click)="changeFrequency(pref.id, 480)" [value]="480">Every Eight Hours</mat-option>
              <mat-option (click)="changeFrequency(pref.id, 360)" [value]="360">Four times Per Day</mat-option>
              <mat-option (click)="changeFrequency(pref.id, 120)" [value]="120">Once Every Two Hours</mat-option>
              <mat-option (click)="changeFrequency(pref.id, 60)" [value]="60">Hourly</mat-option>
            </mat-select>
          </mat-form-field>-->
        </div>
      </div>
      <!-- </mat-selection-list> -->
    </div>
  </div>
</div>
