import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, interval, lastValueFrom, Observable, Subject } from 'rxjs';
import { filter, map, shareReplay, take, throttleTime } from 'rxjs/operators';
import { environment } from '~environments/environment';
import { AccountService } from '~proto/account/account_pb_service';
import { Empty } from '~proto/types/types_pb';
import { GrpcService } from '../services/grpc.service';
import { InitializationService } from '../services/initialization.service';

@Injectable({
  providedIn: 'root',
})
export class FeatureFlagService {
  private overrides$$ = new BehaviorSubject<Record<string, boolean>>({});
  private flags$$ = new BehaviorSubject<Record<string, boolean>>(null);
  private flagPoll$$ = new Subject<void>();

  public flags$ = combineLatest([this.flags$$, this.overrides$$]).pipe(
    filter(([flags]) => !!flags),
    map(([flags, overrides]) => Object.assign({}, flags, overrides)),
    shareReplay(1),
  );

  constructor(private grpcService: GrpcService, private initService: InitializationService) {
    this.setupWindowFunctions();
    this.loadFlags();
    this.pollFlags();
  }

  public isFlagActive$(flag: string): Observable<boolean> {
    return this.flags$.pipe(
      map((flags) => {
        return processFlag(flags, flag);
      }),
    );
  }

  private pollFlags() {
    this.flagPoll$$.pipe(throttleTime(30 * 1000)).subscribe(() => {
      this.loadFlags();
    });
    interval(60 * 1000).subscribe(() => {
      if (environment.pollingEnabled) {
        this.flagPoll$$.next(null);
      }
    });
  }

  private async loadFlags() {
    await lastValueFrom(
      this.initService.myUserInfo$.pipe(
        filter((v) => !!v),
        take(1),
      ),
    );
    const flags = await lastValueFrom(
      this.grpcService.invoke$(AccountService.ListAccountFeatureFlags, new Empty(), true),
    );
    if (flags) {
      const flagMap: Record<string, boolean> = (flags.toObject().accountFeaturesList || []).reduce((acc, flag) => {
        acc[flag.name] = flag.active;
        return acc;
      }, {});

      this.flags$$.next(flagMap);
    }
  }

  private setupWindowFunctions() {
    (window as any).listFeatureFlags = () => ({
      production: environment.production,
      flags: this.flags$$.value,
    });

    if (!environment.production) {
      (window as any).setFeatureFlagOverrides = (overrides: Record<string, boolean>) => {
        this.overrides$$.next(overrides || {});
        // Send out same flags so observable pushes out again
        if (Object.keys(overrides).length === 0 && overrides.constructor === Object) {
          console.log('Feature flags are now in sync with firebase');
        } else {
          console.log(
            'Feature flags will be out of sync with firebase until you refresh or call setFeatureFlagOverrides({})',
          );
        }
      };
    }
  }
}

export function processFlag(flags: Record<string, boolean>, flag: string): boolean {
  let targetState = true;
  if (flag.startsWith('!')) {
    flag = flag.substr(1);
    targetState = false;
  }
  if (Reflect.has(flags, flag)) {
    return flags[flag] === targetState;
  }
  return false === targetState;
}
