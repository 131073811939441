import { Injectable } from '@angular/core';
import { SimpleFinn } from '~utilities/simpleFinn';
import { GrpcService } from '../../singleton/services/grpc.service';
import { InternalApiService } from '~proto/internal/internal_service_pb_service';
import { Empty, StringId, StringIdName } from '~proto/types/types_pb';
import { map } from 'rxjs/operators';
import { NGXLogger } from 'ngx-logger';
import { lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class InternalService {
  private isInternal: SimpleFinn<boolean>;
  private accountList: SimpleFinn<StringIdName.AsObject[]>;

  public get isInternal$() {
    return this.isInternal.get$();
  }

  public get accountList$() {
    return this.accountList.get$();
  }

  constructor(private grpc: GrpcService, private logger: NGXLogger) {
    this.isInternal = new SimpleFinn<boolean>(null, this.loadIsInternal.bind(this));
    this.accountList = new SimpleFinn<StringIdName.AsObject[]>([], this.loadAccounts.bind(this));
  }

  private async loadIsInternal(): Promise<boolean> {
    try {
      return lastValueFrom(
        this.grpc.invoke$(InternalApiService.AmIInternal, new Empty(), true).pipe(map((v) => v.toObject().internal)),
      );
    } catch (e) {
      this.logger.error(e);
      return false;
    }
  }

  private async loadAccounts(): Promise<StringIdName.AsObject[]> {
    try {
      return lastValueFrom(
        this.grpc
          .invoke$(InternalApiService.ListAllAccounts, new Empty(), true)
          .pipe(map((v) => v.toObject().accountsList)),
      );
    } catch (e) {
      this.logger.error(e);
      return [];
    }
  }

  public async switchAccount(value: any) {
    try {
      const id = new StringId();
      id.setId(value);
      await lastValueFrom(this.grpc.invoke$(InternalApiService.InternalSwitchAccounts, id));
      return true;
    } catch (e) {
      this.logger.error(e);
      return false;
    }
  }
}
