import { ChangeDetectorRef, Directive, Input, OnDestroy, TemplateRef, ViewContainerRef } from '@angular/core';
import { InitializationService } from '../../singleton/services/initialization.service';
import { BehaviorSubject, combineLatestWith, lastValueFrom, Observable, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { MyUserInformationResponse } from '~proto/user/user_api_pb';

@Directive({
  selector: '[vtIsCpg]',
})
export class IsCpgDirective implements OnDestroy {
  private hasView = false;
  private destroy$$ = new Subject<void>();

  private elseTemplateRef$$ = new BehaviorSubject<TemplateRef<any>>(null);
  private userInfo$: Observable<MyUserInformationResponse.AsObject>;

  private isCpg$$ = new BehaviorSubject(true);

  @Input()
  public set vtIsCpg(isCpg: boolean) {
    this.isCpg$$.next(isCpg);
  }

  @Input()
  public set vtIsCpgElse(templateRef: TemplateRef<any>) {
    this.elseTemplateRef$$.next(templateRef);
  }

  constructor(
    private initService: InitializationService,
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private cd: ChangeDetectorRef,
  ) {
    this.userInfo$ = this.initService.myUserInfo$;
    this.listenForViewUpdates();
  }

  public ngOnDestroy(): void {
    this.destroy$$.next();
    this.destroy$$.unsubscribe();
  }

  private listenForViewUpdates() {
    this.userInfo$
      .pipe(takeUntil(this.destroy$$), combineLatestWith(this.elseTemplateRef$$, this.isCpg$$))
      .subscribe(([userInfo, elseRef, isCpg]) => {
        if (userInfo?.isCpg === isCpg) {
          this.clearView();
          this.viewContainer.createEmbeddedView(this.templateRef);
          this.hasView = true;
          this.cd.markForCheck();
        } else {
          this.clearView();
          if (elseRef) {
            this.viewContainer.createEmbeddedView(elseRef);
            this.hasView = true;
          }
          this.cd.markForCheck();
        }
      });
  }

  private clearView() {
    if (this.hasView) {
      this.viewContainer.clear();
      this.hasView = false;
      this.cd.markForCheck();
    }
  }
}
