import { Component, OnInit, ChangeDetectionStrategy, ViewChild, ElementRef, HostListener } from '@angular/core';
import { Observable } from 'rxjs';
import { Message, ChatbotService } from '../chatbot.service';
import { UntypedFormControl } from '@angular/forms';
import { distinctUntilChanged, tap } from 'rxjs/operators';
import { IntercomService } from '../../../intercom.service';

@Component({
  selector: 'vt-chatbot',
  templateUrl: './chatbot.component.html',
  styles: [
    `
      .active {
        height: 75vh;
        width: 350px;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatbotComponent implements OnInit {
  @ViewChild('chatBotInput', { static: false }) public input: ElementRef<HTMLInputElement>;
  @ViewChild('scroller', { static: false }) private messages: ElementRef;
  public chat$ = new Observable<Message[]>();
  public formControl = new UntypedFormControl();
  public hide = true;

  constructor(private chatbot: ChatbotService, private intercomService: IntercomService) {}

  public ngOnInit() {
    this.chat$ = this.chatbot.chat$.pipe(
      distinctUntilChanged(),
      tap(async () => {
        await new Promise((resolve) => {
          setTimeout(resolve, 200);
        });
        this.scrollToBottom();
      }),
    );
  }

  public toggleHide() {
    this.hide = !this.hide;
    setTimeout(() => {
      if (!this.hide && this.input && this.input.nativeElement && this.input.nativeElement.focus) {
        this.input.nativeElement.focus();
      }
    }, 50);
  }

  public sendMessage() {
    if (this.formControl.value === '') {
      return;
    }
    this.chatbot.sendMessage(this.formControl.value);
    this.formControl.setValue('');
  }

  private scrollToBottom(): void {
    try {
      this.messages.nativeElement.scrollTop = this.messages.nativeElement.scrollHeight;
    } catch (err) {}
  }

  public openIntercom() {
    this.intercomService.show();
  }

  @HostListener('document:keydown.escape', ['$event'])
  public escapeKey(event: KeyboardEvent) {
    if (
      this.input &&
      (event.target as HTMLElement).tagName !== 'BODY' &&
      (event.target as HTMLElement) !== this.input.nativeElement
    ) {
      return;
    }
    if (!this.hide) {
      this.hide = true;
      if (this.input.nativeElement) {
        this.input.nativeElement.blur();
      }
    }
  }

  @HostListener('document:keydown.shift.enter', ['$event'])
  public shiftEnterKey(event: KeyboardEvent) {
    if (
      (event.target as HTMLElement).tagName !== 'BODY' &&
      (event.target as HTMLElement) !== this.input.nativeElement
    ) {
      return;
    }
    if (this.hide) {
      this.hide = false;
    }
  }
}
