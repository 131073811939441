import { Pipe, PipeTransform } from '@angular/core';
import {
  differenceInSeconds,
  differenceInMinutes,
  differenceInHours,
  differenceInDays,
  differenceInMonths,
  isValid,
  add,
  isBefore,
} from 'date-fns';

@Pipe({
  name: 'minutesToHuman',
})
export class MinutesToHumanPipe implements PipeTransform {
  public transform(minutes: number): string {
    return minutesToHuman(minutes);
  }
}

function minutesToHuman(minutes: number): string {
  if (typeof minutes === 'string') {
    return minutes;
  }

  if (minutes === 0) {
    return 'Less than a minute';
  }

  const date = add(new Date(), { minutes });

  return preciseDiffToHuman(date, 2, '');
}

const differenceInFn = {
  second: differenceInSeconds,
  minute: differenceInMinutes,
  hour: differenceInHours,
  day: differenceInDays,
  month: differenceInMonths,
};

type possibleParts = keyof typeof differenceInFn;

interface PreciseDiff {
  seconds: number;
  minutes: number;
  hours: number;
  days: number;
  months: number;
}

function preciseDifference(
  date: Date,
  parts: possibleParts[] = ['month', 'day', 'hour', 'minute'],
): PreciseDiff | null {
  let now = new Date();
  if (isBefore(date, now)) {
    return null;
  }
  const response: PreciseDiff = {
    seconds: 0,
    minutes: 0,
    hours: 0,
    days: 0,
    months: 0,
  };

  parts.forEach((part, i) => {
    const fn = differenceInFn[part];
    if (fn) {
      const differenceInPart = fn(date, now);
      if (differenceInPart) {
        response[`${part}s`] = differenceInPart;
        const additionDuration = { [`${part}s`]: differenceInPart };
        // We are just going to add from "now" until we get up to the date
        now = add(now, additionDuration);
      }
    }
  });
  return response;
}

const dateOrder: (keyof PreciseDiff)[] = ['months', 'days', 'hours', 'minutes'];

const substitutes: Partial<Record<keyof PreciseDiff, string>> = {
  months: 'mo',
  minutes: 'mins',
};

function preciseDiffToHuman(date: Date, maxParts: number, suffix: string): string {
  let response = '—';
  if (isValid(date)) {
    const preciseDiff = preciseDifference(date);
    if (preciseDiff) {
      const result = [];
      let dateOrderIndex = 0;
      while (dateOrderIndex < dateOrder.length && result.length < maxParts) {
        const key = dateOrder[dateOrderIndex];
        if (preciseDiff[key] > 0) {
          const keyName = substitutes[key] ? substitutes[key] : key;
          result.push(`${preciseDiff[key]} ${removePluralIfNeeded(preciseDiff[key] as number, keyName)}`);
        }
        dateOrderIndex++;
      }
      if (result.length) {
        response = `${result.join(' ')} ${suffix}`;
      }
    }
  }
  return response;
}

function removePluralIfNeeded(count: number, pluralString: string) {
  if (count !== 1 || !pluralString.endsWith('s')) {
    return pluralString;
  }
  return pluralString.substring(0, pluralString.length - 1);
}
