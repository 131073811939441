import { Injectable } from '@angular/core';
import { grpc } from '@improbable-eng/grpc-web';
import { ProtobufMessage } from '@improbable-eng/grpc-web/dist/typings/message';
import { MethodDefinition, UnaryMethodDefinition } from '@improbable-eng/grpc-web/dist/typings/service';
import { NGXLogger, NgxLoggerLevel } from 'ngx-logger';
import { Observable, race, Subject, timer, combineLatest, of, from } from 'rxjs';
import { filter, mapTo, switchMap, take, throttleTime, delay, debounceTime, switchMapTo, map } from 'rxjs/operators';
import { environment } from '~environments/environment';
import { AuthService } from './auth.service';
import { differenceInMilliseconds } from 'date-fns';
import { SnackbarService } from './snackbar.service';
import * as LogRocket from 'logrocket';
import { Code } from '@improbable-eng/grpc-web/dist/typings/Code';
import { Metadata } from '@improbable-eng/grpc-web/dist/typings/metadata';
import { EmailTokenService } from './email-token.service';

const lastInvokeTime: Record<string, Date> = {};
const defaultErrorMessage = 'Please contact Vorto Support';

export class GRPCError extends Error {
  constructor(message: string | undefined, public code: grpc.Code, public trailers: grpc.Metadata) {
    super(message);
  }
}

export interface ExpectedErrorResponse {
  id: string;
  info: string;
  developerInfo: string;
  error: string;
}

export interface ErrorDisplay {
  message: string;
  code: number;
}

@Injectable({
  providedIn: 'root',
})
export class GrpcService {
  private errorDisplay$$ = new Subject<ErrorDisplay>();

  constructor(
    private logger: NGXLogger,
    private authService: AuthService,
    private emailTokenService: EmailTokenService,
    private snackBar: SnackbarService,
  ) {
    const logLevelName = sessionStorage.getItem('VTO_LOG_LEVEL') || localStorage.getItem('VTO_LOG_LEVEL');
    const logLevel = NgxLoggerLevel[logLevelName];
    if (logLevel) {
      this.logger.updateConfig({ level: logLevel });
    }
    this.errorDisplay$$.pipe(throttleTime(3000)).subscribe((error) => {
      this.snackBar.showError(error.message, error.message === defaultErrorMessage || error.code === 13);
    });
  }

  public invoke$<T extends ProtobufMessage, U extends ProtobufMessage, M extends UnaryMethodDefinition<T, U>>(
    method: M,
    request: T,
    backgroundRequest = false, // If this is set to true, we will not show the user an error banner,
  ): Observable<InstanceType<M['responseType']>> {
    return this.unary$(method, request, backgroundRequest);
  }

  public unary$<T extends ProtobufMessage, U extends ProtobufMessage, M extends UnaryMethodDefinition<T, U>>(
    method: M,
    request: T,
    backgroundRequest = false, // If this is set to true, we will not show the user an error banner,
  ): Observable<InstanceType<M['responseType']>> {
    this.logger.debug(`⬆ Request: ${method.methodName}`, request.toObject());
    this.warnAboutLastInvokeTimeIfNeeded(method);
    return this.authService.isLoggedIn$.pipe(
      take(1),
      switchMap((isLoggedIn) => {
        const tokenObservables: Array<Observable<string>> = [
          from(this.authService.getTokenSilently()),
          this.emailTokenService.emailToken$,
        ];

        if (!isLoggedIn) {
          tokenObservables[0] = of(null);
        }

        return combineLatest(tokenObservables)
          .pipe(
            debounceTime(25),
            filter(([jwt, emailToken]) => {
              return !!jwt || !!emailToken;
            }),
            map(([jwt, emailToken]) => {
              return [jwt, emailToken] as string[];
            }),
          )
          .pipe(
            take(1),
            switchMap(([jwt, emailToken]) => {
              const metadata: Record<string, string> = {};
              if (emailToken) {
                metadata.Authorization = `uuid_token ${emailToken}`;
              } else if (jwt) {
                metadata.Authorization = `bearer ${jwt}`;
              }
              return new Observable<InstanceType<M['responseType']>>((observer) => {
                let completed = false;
                const req = grpc.unary(method, {
                  // host: 'http://localhost:50051',
                  host: environment.api,
                  metadata,
                  onEnd: (resp) => {
                    if (resp.status !== grpc.Code.OK) {
                      let messageForUser = defaultErrorMessage;
                      try {
                        const err: ExpectedErrorResponse = JSON.parse(resp.statusMessage);
                        if (err.info) {
                          messageForUser = err.info;
                        }
                        const errorInfo = {
                          // clientVersion: this.versionService.currentVersion,
                          errorId: err.id,
                          errorCode: resp.status,
                          errorMessage: err.info,
                          methodName: method.methodName,
                          requestPayload: JSON.stringify(request.toObject()),
                          error: err.error,
                        };
                        this.logger.error(errorInfo);
                        this.logger.error('Developer Info (if any):', err.developerInfo);
                        if (LogRocket) {
                          LogRocket.captureException(new Error(err.info), {
                            extra: {
                              ...errorInfo,
                              developerInfo: err.developerInfo,
                            },
                          });
                        }
                      } catch (_) {
                        this.logger.error({
                          // clientVersion: this.versionService.currentVersion,
                          errorCode: resp.status,
                          errorMessage: resp.statusMessage,
                          methodName: method.methodName,
                          requestPayload: JSON.stringify(request.toObject()),
                        });
                        if (LogRocket) {
                          LogRocket.captureException(new Error('GRPC Catch Error'), {
                            extra: {
                              errorCode: resp.status,
                              errorMessage: resp.statusMessage,
                              methodName: method.methodName,
                              requestPayload: JSON.stringify(request.toObject()),
                            },
                          });
                        }
                      }
                      if (!backgroundRequest && resp.status !== grpc.Code.DeadlineExceeded) {
                        this.errorDisplay$$.next({ message: messageForUser, code: resp.status });
                      }
                      observer.error(new GRPCError(messageForUser, resp.status, resp.headers));
                    } else {
                      completed = true;
                      this.logger.debug(`Response ⬇: ${method.methodName}`, resp.message.toObject());
                      observer.next(resp.message as InstanceType<M['responseType']>);
                      observer.complete();
                    }
                  },
                  request,
                });
                observer.add(() => {
                  if (!completed) {
                    req.close();
                    this.logger.debug(`Request Cancelled: ${method.methodName}`, request.toObject());
                  }
                });
              });
            }),
          );
      }),
    );
  }

  public async stream<T extends ProtobufMessage, U extends ProtobufMessage, M extends MethodDefinition<T, U>>(
    method: M,
    request: T,
    callback: (data: InstanceType<M['responseType']>) => any,
  ): Promise<grpc.Client<any, any>> {
    const client = grpc.client(method, {
      host: environment.api,
      debug: !environment.production,
      transport: grpc.WebsocketTransport(),
    }) as grpc.Client<T, U>;
    client.onMessage((message: InstanceType<M['responseType']>) => {
      this.logger.debug(`Message: ${method.methodName}`, message.toObject());
      callback(message as InstanceType<M['responseType']>);
    });
    client.onEnd((p1: Code, p2: string, p3: Metadata) => {
      this.logger.debug(`End: ${method.methodName}`, p1, p2, p3);
    });
    client.onHeaders((data) => {
      this.logger.debug(`Headers: ${method.methodName}`, data);
    });
    const metadata: Record<string, string> = {};
    const jwt = await this.authService.getTokenSilently();
    if (jwt) {
      metadata.Authorization = `bearer ${jwt}`;
    }
    client.start(metadata);
    client.send(request);
    client.finishSend();
    return client as grpc.Client<T, U>;
  }

  public stream$<T extends ProtobufMessage, U extends ProtobufMessage, M extends MethodDefinition<T, U>>(
    method: M,
    request: T,
  ): Observable<InstanceType<M['responseType']>> {
    return new Observable<InstanceType<M['responseType']>>((subscriber) => {
      const client = grpc.client(method, {
        host: environment.api,
        debug: !environment.production,
        transport: grpc.WebsocketTransport(),
      }) as grpc.Client<T, U>;
      client.onMessage((message: InstanceType<M['responseType']>) => {
        this.logger.debug(`Message: ${method.methodName}`, message.toObject());
        subscriber.next(message);
      });
      client.onEnd((p1: Code, p2: string, p3: Metadata) => {
        this.logger.debug(`End: ${method.methodName}`, p1, p2, p3);
        subscriber.complete();
      });
      client.onHeaders((data) => {
        this.logger.debug(`Headers: ${method.methodName}`, data);
      });

      const metadata: Record<string, string> = {};
      this.authService.getTokenSilently().then((jwt) => {
        metadata.Authorization = `bearer ${jwt}`;
        client.start(metadata);
        client.send(request);
        client.finishSend();
      });

      subscriber.add(() => {
        client?.close();
      });
    });
  }

  private warnAboutLastInvokeTimeIfNeeded<T extends MethodDefinition<any, any>>(rpcCall: T) {
    const rpcName = rpcCall.methodName;
    const now = new Date();
    const lastInvoked = lastInvokeTime[rpcName];
    if (!lastInvoked) {
      lastInvokeTime[rpcName] = now;
    } else {
      const diffInMs = differenceInMilliseconds(now, lastInvoked);
      if (diffInMs < 1000) {
        this.logger.warn(`Recently invoked ${rpcName} (last ${diffInMs}ms), this might need some throttling`);
      }
      lastInvokeTime[rpcName] = now;
    }
  }
}
