import { Component, OnInit, HostBinding } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { VersioningService } from '../../service/versioning.service';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'vt-versioning',
  templateUrl: './versioning.component.html',
  styles: [],
})
export class VersioningComponent implements OnInit {
  @HostBinding('class') public classes = 'version-warning';
  @HostBinding('class.wrong-version') public isWrongVersion = false;

  constructor(
    // Leave this here for the polling
    private versioningService: VersioningService,
    private swUpdate: SwUpdate,
  ) {}

  public ngOnInit() {
    this.swUpdate.versionUpdates.pipe(filter((v) => v.type === 'VERSION_READY')).subscribe((event) => {
      this.isWrongVersion = true;
    });
  }

  public reload() {
    location.reload();
  }
}
