import { BehaviorSubject, Observable, combineLatest } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { sortBy } from './genericObjectSort';
import { sort } from 'remeda';

export interface GroupedItems<T> {
  name: string;
  data: T[];
}

interface StoredData<T> {
  sortingKey: keyof T;
  ascending: boolean;
}

export class SortingObservable<T> {
  private groupingSorters: Record<keyof T, (a: GroupedItems<T>, b: GroupedItems<T>) => number> = {} as any;
  private sortByHeader$$ = new BehaviorSubject<keyof T>(null);
  private sortAscending$$ = new BehaviorSubject<boolean>(true);

  public sortByHeader$ = this.sortByHeader$$.asObservable().pipe(shareReplay(1));
  public sortAscending$ = this.sortAscending$$.asObservable().pipe(shareReplay(1));

  public value$: Observable<T[]>;

  constructor(source$: Observable<T[]>, initalSort: keyof T, private localStorageID = null, sortAsc: boolean = true) {
    this.value$ = combineLatest([this.sortByHeader$, this.sortAscending$, source$]).pipe(
      map(([sortByHeader, sortAscending, data]) => {
        let sortFn = (a: T, b: T) => 0;
        if (sortByHeader) {
          sortFn = sortBy<T>(`${sortAscending ? '' : '-'}${String(sortByHeader)}`);
        }

        return sort(data, sortFn);
      }),
      shareReplay(1),
    );
    this.sortByHeader$$.next(initalSort);
    this.sortAscending$$.next(sortAsc);
  }

  private checkForSavedSettings(initalSort: keyof T) {
    let hasDataInLocalStorage = false;
    if (this.localStorageID) {
      const rawData = localStorage.getItem(this.getLocalStorageKeyName());
      if (rawData) {
        try {
          const data = JSON.parse(rawData) as StoredData<T>;
          if (data.sortingKey) {
            this.setSortingHeader(data.sortingKey);
            this.setSortAscending(data.ascending);
            hasDataInLocalStorage = true;
          }
        } catch {
          // nah
        }
      }
    }
    if (!hasDataInLocalStorage) {
      this.setSortingHeader(initalSort);
      this.setSortAscending(true);
    }
    return hasDataInLocalStorage;
  }

  public setSortingHeader(header: keyof T) {
    if (this.sortByHeader$$.value === header) {
      this.toggleSortAscending();
    } else {
      this.sortByHeader$$.next(header);
    }
    this.updateStoredData();
  }

  public setSortAscending(asc: boolean) {
    this.sortAscending$$.next(asc);
    this.updateStoredData();
  }

  public setGroupSortingFunction(groupName: keyof T, fn: (a: GroupedItems<T>, b: GroupedItems<T>) => number) {
    const asString = groupName as string;
    this.groupingSorters[asString] = fn;
  }

  private toggleSortAscending() {
    this.sortAscending$$.next(!this.sortAscending$$.value);
    this.updateStoredData();
  }

  private updateStoredData() {
    if (!this.localStorageID) {
      return;
    }
    const data: StoredData<T> = {
      sortingKey: this.sortByHeader$$.value,
      ascending: this.sortAscending$$.value,
    };

    localStorage.setItem(this.getLocalStorageKeyName(), JSON.stringify(data));
  }

  private getLocalStorageKeyName() {
    return `GROUPING_SORTING_${this.localStorageID}`;
  }
}
