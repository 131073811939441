import { Injectable } from '@angular/core';
import { BehaviorSubject, lastValueFrom } from 'rxjs';
import { add, parseISO, isValid, isBefore } from 'date-fns';
import { filter, shareReplay, switchMapTo, take, tap } from 'rxjs/operators';

const EMAIL_TOKEN_SESSION_KEY = 'EMAIL_TOKEN_SESSION_KEY';

interface StoredToken {
  expiresAt: string;
  token: string;
}

@Injectable({
  providedIn: 'root',
})
export class EmailTokenService {
  private emailToken$$ = new BehaviorSubject<string>(null);
  private initialized$$ = new BehaviorSubject<boolean>(false);
  public emailToken$ = this.initialized$$.pipe(
    filter((initialized) => initialized),
    switchMapTo(this.emailToken$$.asObservable()),
    shareReplay(1),
  );

  public emailToken(): Promise<string> {
    return lastValueFrom(this.emailToken$.pipe(take(1)));
  }

  constructor() {
    this.checkForToken();
  }

  public setEmailToken(emailToken: string) {
    this.emailToken$$.next(emailToken);
    this.storeToken(emailToken);
  }

  private storeToken(token: string) {
    if (token) {
      const expires = add(new Date(), { hours: 1 });
      const data: StoredToken = {
        expiresAt: expires.toISOString(),
        token,
      };

      sessionStorage.setItem(EMAIL_TOKEN_SESSION_KEY, JSON.stringify(data));
    } else {
      sessionStorage.removeItem(EMAIL_TOKEN_SESSION_KEY);
    }
  }

  private async checkForToken() {
    // Use the built in ones so we don't have to wait for angular to initialize
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('emailToken');
    if (token) {
      this.setEmailToken(token);
    } else {
      const rawData = sessionStorage.getItem(EMAIL_TOKEN_SESSION_KEY);
      if (rawData) {
        try {
          const data: StoredToken = JSON.parse(rawData);
          const expires = parseISO(data.expiresAt);
          if (!isValid(expires)) {
            return;
          }
          if (isBefore(expires, new Date())) {
            return;
          }
          this.setEmailToken(data.token);
        } catch (error) {
          return;
        }
      }
    }
    this.initialized$$.next(true);
  }
}
