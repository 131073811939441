import { Directive, ElementRef, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { NgxMatDatetimePicker } from '@angular-material-components/datetime-picker';
import { ControlContainer, UntypedFormGroup } from '@angular/forms';

@Directive({
  selector: '[vtDatePicker]',
})
export class DatePickerDirective implements OnInit {
  @Input() public ngxMatDatetimePicker: NgxMatDatetimePicker<any>;
  @Input() public formControlName: string;

  private get date(): Date {
    /*const controlName = (this.el.nativeElement.attributes as NamedNodeMap).getNamedItem('formControlName').value;*/
    return (this.formControl.control as UntypedFormGroup).get(this.formControlName).value;
  }

  @HostListener('click', ['$event']) public onClick(event: Event) {
    event.stopPropagation();
    this.ngxMatDatetimePicker.open();
    if (!this.date || !this.ngxMatDatetimePicker) {
      return;
    }
    this.ngxMatDatetimePicker.select(this.date);
    setTimeout(() => {
      // @ts-ignore
      // eslint-disable-next-line no-underscore-dangle
      this.ngxMatDatetimePicker._dialogRef.disableClose = true;
    }, 250);
  }

  constructor(private el: ElementRef, private formControl: ControlContainer) {}

  public ngOnInit(): void {}
}
