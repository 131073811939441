// source: proto/vorto/edge/edge.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var proto_vorto_types_types_pb = require('../../../proto/vorto/types/types_pb.js');
goog.object.extend(proto, proto_vorto_types_types_pb);
goog.exportSymbol('proto.vtv1.AssignmentRepercussions', null, global);
goog.exportSymbol('proto.vtv1.AssignmentRepercussionsLateInfo', null, global);
goog.exportSymbol('proto.vtv1.CalendarDriver', null, global);
goog.exportSymbol('proto.vtv1.CalendarDriverShift', null, global);
goog.exportSymbol('proto.vtv1.CalendarDriverShipment', null, global);
goog.exportSymbol('proto.vtv1.CalendarResponse', null, global);
goog.exportSymbol('proto.vtv1.CalendarShipmentStop', null, global);
goog.exportSymbol('proto.vtv1.CompletedShipmentSummary', null, global);
goog.exportSymbol('proto.vtv1.CreateShipment', null, global);
goog.exportSymbol('proto.vtv1.CreateShipmentStop', null, global);
goog.exportSymbol('proto.vtv1.CreateShipmentTask', null, global);
goog.exportSymbol('proto.vtv1.DriverForAssignment', null, global);
goog.exportSymbol('proto.vtv1.DriverForAssignmentShift', null, global);
goog.exportSymbol('proto.vtv1.DriverSchedule', null, global);
goog.exportSymbol('proto.vtv1.DriverScheduleEntry', null, global);
goog.exportSymbol('proto.vtv1.EdgeNode', null, global);
goog.exportSymbol('proto.vtv1.InProgressShipmentSummary', null, global);
goog.exportSymbol('proto.vtv1.InProgressShipmentSummary.WarningStatus', null, global);
goog.exportSymbol('proto.vtv1.NodeProduct', null, global);
goog.exportSymbol('proto.vtv1.NodeSite', null, global);
goog.exportSymbol('proto.vtv1.NonSite', null, global);
goog.exportSymbol('proto.vtv1.PendingShipmentSummary', null, global);
goog.exportSymbol('proto.vtv1.PendingShipmentSummaryForDriverAssignment', null, global);
goog.exportSymbol('proto.vtv1.PreviousCalendarDriver', null, global);
goog.exportSymbol('proto.vtv1.PreviousCalendarDriverShift', null, global);
goog.exportSymbol('proto.vtv1.PreviousCalendarDriverShipment', null, global);
goog.exportSymbol('proto.vtv1.PreviousCalendarResponse', null, global);
goog.exportSymbol('proto.vtv1.PreviousCalendarShipmentStop', null, global);
goog.exportSymbol('proto.vtv1.ShipmentDetails', null, global);
goog.exportSymbol('proto.vtv1.ShipmentDetailsDriver', null, global);
goog.exportSymbol('proto.vtv1.ShipmentDetailsStop', null, global);
goog.exportSymbol('proto.vtv1.ShipmentDetailsTask', null, global);
goog.exportSymbol('proto.vtv1.ShipmentOrderID', null, global);
goog.exportSymbol('proto.vtv1.ShipmentQuantities', null, global);
goog.exportSymbol('proto.vtv1.UFGStatus', null, global);
goog.exportSymbol('proto.vtv1.WaitingTime', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.EdgeNode = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.EdgeNode, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.EdgeNode.displayName = 'proto.vtv1.EdgeNode';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.NodeSite = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.NodeSite, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.NodeSite.displayName = 'proto.vtv1.NodeSite';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.NodeProduct = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.NodeProduct, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.NodeProduct.displayName = 'proto.vtv1.NodeProduct';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.ShipmentDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.ShipmentDetails.repeatedFields_, null);
};
goog.inherits(proto.vtv1.ShipmentDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.ShipmentDetails.displayName = 'proto.vtv1.ShipmentDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.ShipmentDetailsStop = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.ShipmentDetailsStop.repeatedFields_, null);
};
goog.inherits(proto.vtv1.ShipmentDetailsStop, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.ShipmentDetailsStop.displayName = 'proto.vtv1.ShipmentDetailsStop';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.ShipmentDetailsTask = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.ShipmentDetailsTask, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.ShipmentDetailsTask.displayName = 'proto.vtv1.ShipmentDetailsTask';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.ShipmentDetailsDriver = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.ShipmentDetailsDriver, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.ShipmentDetailsDriver.displayName = 'proto.vtv1.ShipmentDetailsDriver';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.DriverForAssignmentShift = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.DriverForAssignmentShift, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.DriverForAssignmentShift.displayName = 'proto.vtv1.DriverForAssignmentShift';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.ShipmentOrderID = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.ShipmentOrderID, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.ShipmentOrderID.displayName = 'proto.vtv1.ShipmentOrderID';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.AssignmentRepercussions = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.AssignmentRepercussions.repeatedFields_, null);
};
goog.inherits(proto.vtv1.AssignmentRepercussions, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.AssignmentRepercussions.displayName = 'proto.vtv1.AssignmentRepercussions';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.AssignmentRepercussionsLateInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.AssignmentRepercussionsLateInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.AssignmentRepercussionsLateInfo.displayName = 'proto.vtv1.AssignmentRepercussionsLateInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.DriverForAssignment = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.DriverForAssignment.repeatedFields_, null);
};
goog.inherits(proto.vtv1.DriverForAssignment, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.DriverForAssignment.displayName = 'proto.vtv1.DriverForAssignment';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.PendingShipmentSummary = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.PendingShipmentSummary.repeatedFields_, null);
};
goog.inherits(proto.vtv1.PendingShipmentSummary, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.PendingShipmentSummary.displayName = 'proto.vtv1.PendingShipmentSummary';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.PendingShipmentSummaryForDriverAssignment = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.PendingShipmentSummaryForDriverAssignment.repeatedFields_, null);
};
goog.inherits(proto.vtv1.PendingShipmentSummaryForDriverAssignment, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.PendingShipmentSummaryForDriverAssignment.displayName = 'proto.vtv1.PendingShipmentSummaryForDriverAssignment';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.InProgressShipmentSummary = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.InProgressShipmentSummary.repeatedFields_, null);
};
goog.inherits(proto.vtv1.InProgressShipmentSummary, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.InProgressShipmentSummary.displayName = 'proto.vtv1.InProgressShipmentSummary';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.CompletedShipmentSummary = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.CompletedShipmentSummary.repeatedFields_, null);
};
goog.inherits(proto.vtv1.CompletedShipmentSummary, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.CompletedShipmentSummary.displayName = 'proto.vtv1.CompletedShipmentSummary';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.DriverScheduleEntry = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.DriverScheduleEntry.repeatedFields_, null);
};
goog.inherits(proto.vtv1.DriverScheduleEntry, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.DriverScheduleEntry.displayName = 'proto.vtv1.DriverScheduleEntry';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.WaitingTime = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.WaitingTime, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.WaitingTime.displayName = 'proto.vtv1.WaitingTime';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.DriverSchedule = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.DriverSchedule.repeatedFields_, null);
};
goog.inherits(proto.vtv1.DriverSchedule, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.DriverSchedule.displayName = 'proto.vtv1.DriverSchedule';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.CalendarResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.CalendarResponse.repeatedFields_, null);
};
goog.inherits(proto.vtv1.CalendarResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.CalendarResponse.displayName = 'proto.vtv1.CalendarResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.CalendarDriver = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.CalendarDriver.repeatedFields_, null);
};
goog.inherits(proto.vtv1.CalendarDriver, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.CalendarDriver.displayName = 'proto.vtv1.CalendarDriver';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.CalendarDriverShift = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.CalendarDriverShift.repeatedFields_, null);
};
goog.inherits(proto.vtv1.CalendarDriverShift, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.CalendarDriverShift.displayName = 'proto.vtv1.CalendarDriverShift';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.CalendarShipmentStop = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.CalendarShipmentStop, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.CalendarShipmentStop.displayName = 'proto.vtv1.CalendarShipmentStop';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.CalendarDriverShipment = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.CalendarDriverShipment.repeatedFields_, null);
};
goog.inherits(proto.vtv1.CalendarDriverShipment, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.CalendarDriverShipment.displayName = 'proto.vtv1.CalendarDriverShipment';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.PreviousCalendarResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.PreviousCalendarResponse.repeatedFields_, null);
};
goog.inherits(proto.vtv1.PreviousCalendarResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.PreviousCalendarResponse.displayName = 'proto.vtv1.PreviousCalendarResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.PreviousCalendarDriver = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.PreviousCalendarDriver.repeatedFields_, null);
};
goog.inherits(proto.vtv1.PreviousCalendarDriver, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.PreviousCalendarDriver.displayName = 'proto.vtv1.PreviousCalendarDriver';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.PreviousCalendarDriverShift = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.PreviousCalendarDriverShift, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.PreviousCalendarDriverShift.displayName = 'proto.vtv1.PreviousCalendarDriverShift';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.PreviousCalendarDriverShipment = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.PreviousCalendarDriverShipment.repeatedFields_, null);
};
goog.inherits(proto.vtv1.PreviousCalendarDriverShipment, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.PreviousCalendarDriverShipment.displayName = 'proto.vtv1.PreviousCalendarDriverShipment';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.PreviousCalendarShipmentStop = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.PreviousCalendarShipmentStop, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.PreviousCalendarShipmentStop.displayName = 'proto.vtv1.PreviousCalendarShipmentStop';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.CreateShipmentTask = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.CreateShipmentTask, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.CreateShipmentTask.displayName = 'proto.vtv1.CreateShipmentTask';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.NonSite = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.NonSite, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.NonSite.displayName = 'proto.vtv1.NonSite';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.CreateShipmentStop = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.CreateShipmentStop.repeatedFields_, null);
};
goog.inherits(proto.vtv1.CreateShipmentStop, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.CreateShipmentStop.displayName = 'proto.vtv1.CreateShipmentStop';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.CreateShipment = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.CreateShipment.repeatedFields_, null);
};
goog.inherits(proto.vtv1.CreateShipment, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.CreateShipment.displayName = 'proto.vtv1.CreateShipment';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.ShipmentQuantities = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.ShipmentQuantities, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.ShipmentQuantities.displayName = 'proto.vtv1.ShipmentQuantities';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.EdgeNode.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.EdgeNode.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.EdgeNode} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.EdgeNode.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    hasContract: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    isVirtual: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.EdgeNode}
 */
proto.vtv1.EdgeNode.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.EdgeNode;
  return proto.vtv1.EdgeNode.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.EdgeNode} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.EdgeNode}
 */
proto.vtv1.EdgeNode.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasContract(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsVirtual(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.EdgeNode.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.EdgeNode.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.EdgeNode} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.EdgeNode.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getHasContract();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getIsVirtual();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.vtv1.EdgeNode.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.EdgeNode} returns this
 */
proto.vtv1.EdgeNode.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.vtv1.EdgeNode.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.EdgeNode} returns this
 */
proto.vtv1.EdgeNode.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool has_contract = 3;
 * @return {boolean}
 */
proto.vtv1.EdgeNode.prototype.getHasContract = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vtv1.EdgeNode} returns this
 */
proto.vtv1.EdgeNode.prototype.setHasContract = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional bool is_virtual = 4;
 * @return {boolean}
 */
proto.vtv1.EdgeNode.prototype.getIsVirtual = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vtv1.EdgeNode} returns this
 */
proto.vtv1.EdgeNode.prototype.setIsVirtual = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.NodeSite.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.NodeSite.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.NodeSite} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.NodeSite.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    nodeId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    nodeName: jspb.Message.getFieldWithDefault(msg, 4, ""),
    longitude: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    latitude: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    radius: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.NodeSite}
 */
proto.vtv1.NodeSite.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.NodeSite;
  return proto.vtv1.NodeSite.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.NodeSite} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.NodeSite}
 */
proto.vtv1.NodeSite.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setNodeId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setNodeName(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLongitude(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLatitude(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setRadius(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.NodeSite.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.NodeSite.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.NodeSite} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.NodeSite.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getNodeId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getNodeName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getLongitude();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getLatitude();
  if (f !== 0.0) {
    writer.writeDouble(
      6,
      f
    );
  }
  f = message.getRadius();
  if (f !== 0.0) {
    writer.writeDouble(
      7,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.vtv1.NodeSite.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.NodeSite} returns this
 */
proto.vtv1.NodeSite.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.vtv1.NodeSite.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.NodeSite} returns this
 */
proto.vtv1.NodeSite.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string node_id = 3;
 * @return {string}
 */
proto.vtv1.NodeSite.prototype.getNodeId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.NodeSite} returns this
 */
proto.vtv1.NodeSite.prototype.setNodeId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string node_name = 4;
 * @return {string}
 */
proto.vtv1.NodeSite.prototype.getNodeName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.NodeSite} returns this
 */
proto.vtv1.NodeSite.prototype.setNodeName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional double longitude = 5;
 * @return {number}
 */
proto.vtv1.NodeSite.prototype.getLongitude = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.NodeSite} returns this
 */
proto.vtv1.NodeSite.prototype.setLongitude = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional double latitude = 6;
 * @return {number}
 */
proto.vtv1.NodeSite.prototype.getLatitude = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.NodeSite} returns this
 */
proto.vtv1.NodeSite.prototype.setLatitude = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional double radius = 7;
 * @return {number}
 */
proto.vtv1.NodeSite.prototype.getRadius = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.NodeSite} returns this
 */
proto.vtv1.NodeSite.prototype.setRadius = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.NodeProduct.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.NodeProduct.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.NodeProduct} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.NodeProduct.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    nodeId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    nodeName: jspb.Message.getFieldWithDefault(msg, 4, ""),
    unitId: jspb.Message.getFieldWithDefault(msg, 5, 0),
    unitName: jspb.Message.getFieldWithDefault(msg, 6, ""),
    unitAbbreviation: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.NodeProduct}
 */
proto.vtv1.NodeProduct.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.NodeProduct;
  return proto.vtv1.NodeProduct.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.NodeProduct} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.NodeProduct}
 */
proto.vtv1.NodeProduct.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setNodeId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setNodeName(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUnitId(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setUnitName(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setUnitAbbreviation(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.NodeProduct.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.NodeProduct.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.NodeProduct} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.NodeProduct.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getNodeId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getNodeName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getUnitId();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getUnitName();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getUnitAbbreviation();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.vtv1.NodeProduct.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.NodeProduct} returns this
 */
proto.vtv1.NodeProduct.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.vtv1.NodeProduct.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.NodeProduct} returns this
 */
proto.vtv1.NodeProduct.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string node_id = 3;
 * @return {string}
 */
proto.vtv1.NodeProduct.prototype.getNodeId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.NodeProduct} returns this
 */
proto.vtv1.NodeProduct.prototype.setNodeId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string node_name = 4;
 * @return {string}
 */
proto.vtv1.NodeProduct.prototype.getNodeName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.NodeProduct} returns this
 */
proto.vtv1.NodeProduct.prototype.setNodeName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int64 unit_id = 5;
 * @return {number}
 */
proto.vtv1.NodeProduct.prototype.getUnitId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.NodeProduct} returns this
 */
proto.vtv1.NodeProduct.prototype.setUnitId = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional string unit_name = 6;
 * @return {string}
 */
proto.vtv1.NodeProduct.prototype.getUnitName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.NodeProduct} returns this
 */
proto.vtv1.NodeProduct.prototype.setUnitName = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string unit_abbreviation = 7;
 * @return {string}
 */
proto.vtv1.NodeProduct.prototype.getUnitAbbreviation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.NodeProduct} returns this
 */
proto.vtv1.NodeProduct.prototype.setUnitAbbreviation = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.ShipmentDetails.repeatedFields_ = [13,22,23,29,30,31];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.ShipmentDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.ShipmentDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.ShipmentDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.ShipmentDetails.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    edgeContractId: (f = msg.getEdgeContractId()) && proto_vorto_types_types_pb.NullableString.toObject(includeInstance, f),
    edgeContractName: (f = msg.getEdgeContractName()) && proto_vorto_types_types_pb.NullableString.toObject(includeInstance, f),
    createdAt: jspb.Message.getFieldWithDefault(msg, 4, ""),
    createdBy: (f = msg.getCreatedBy()) && proto_vorto_types_types_pb.NullableString.toObject(includeInstance, f),
    createdByName: (f = msg.getCreatedByName()) && proto_vorto_types_types_pb.NullableString.toObject(includeInstance, f),
    description: (f = msg.getDescription()) && proto_vorto_types_types_pb.NullableString.toObject(includeInstance, f),
    completedAt: (f = msg.getCompletedAt()) && proto_vorto_types_types_pb.NullableTime.toObject(includeInstance, f),
    status: jspb.Message.getFieldWithDefault(msg, 9, 0),
    statusUpdatedAt: jspb.Message.getFieldWithDefault(msg, 10, ""),
    ticketNumber: (f = msg.getTicketNumber()) && proto_vorto_types_types_pb.NullableString.toObject(includeInstance, f),
    updatedAt: jspb.Message.getFieldWithDefault(msg, 12, ""),
    stopsList: jspb.Message.toObjectList(msg.getStopsList(),
    proto.vtv1.ShipmentDetailsStop.toObject, includeInstance),
    driver: (f = msg.getDriver()) && proto.vtv1.ShipmentDetailsDriver.toObject(includeInstance, f),
    nodeId: jspb.Message.getFieldWithDefault(msg, 15, ""),
    nodeName: jspb.Message.getFieldWithDefault(msg, 16, ""),
    edgeId: (f = msg.getEdgeId()) && proto_vorto_types_types_pb.NullableString.toObject(includeInstance, f),
    edgeName: (f = msg.getEdgeName()) && proto_vorto_types_types_pb.NullableString.toObject(includeInstance, f),
    orderId: (f = msg.getOrderId()) && proto_vorto_types_types_pb.NullableString.toObject(includeInstance, f),
    progressStatus: jspb.Message.getFieldWithDefault(msg, 20, 0),
    progressStatusUpdatedAt: jspb.Message.getFieldWithDefault(msg, 21, ""),
    trailerNamesList: (f = jspb.Message.getRepeatedField(msg, 22)) == null ? undefined : f,
    filesList: jspb.Message.toObjectList(msg.getFilesList(),
    proto_vorto_types_types_pb.File.toObject, includeInstance),
    purchaseOrderName: (f = msg.getPurchaseOrderName()) && proto_vorto_types_types_pb.NullableString.toObject(includeInstance, f),
    truckNumber: (f = msg.getTruckNumber()) && proto_vorto_types_types_pb.NullableString.toObject(includeInstance, f),
    trailerNumber: (f = msg.getTrailerNumber()) && proto_vorto_types_types_pb.NullableString.toObject(includeInstance, f),
    hasBackhaul: jspb.Message.getBooleanFieldWithDefault(msg, 27, false),
    returnTrailer: (f = msg.getReturnTrailer()) && proto_vorto_types_types_pb.NullableString.toObject(includeInstance, f),
    quantitiesList: jspb.Message.toObjectList(msg.getQuantitiesList(),
    proto.vtv1.ShipmentQuantities.toObject, includeInstance),
    actualQuantitiesList: jspb.Message.toObjectList(msg.getActualQuantitiesList(),
    proto.vtv1.ShipmentQuantities.toObject, includeInstance),
    allQuantitiesList: jspb.Message.toObjectList(msg.getAllQuantitiesList(),
    proto.vtv1.ShipmentQuantities.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.ShipmentDetails}
 */
proto.vtv1.ShipmentDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.ShipmentDetails;
  return proto.vtv1.ShipmentDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.ShipmentDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.ShipmentDetails}
 */
proto.vtv1.ShipmentDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new proto_vorto_types_types_pb.NullableString;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableString.deserializeBinaryFromReader);
      msg.setEdgeContractId(value);
      break;
    case 3:
      var value = new proto_vorto_types_types_pb.NullableString;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableString.deserializeBinaryFromReader);
      msg.setEdgeContractName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedAt(value);
      break;
    case 5:
      var value = new proto_vorto_types_types_pb.NullableString;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableString.deserializeBinaryFromReader);
      msg.setCreatedBy(value);
      break;
    case 6:
      var value = new proto_vorto_types_types_pb.NullableString;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableString.deserializeBinaryFromReader);
      msg.setCreatedByName(value);
      break;
    case 7:
      var value = new proto_vorto_types_types_pb.NullableString;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableString.deserializeBinaryFromReader);
      msg.setDescription(value);
      break;
    case 8:
      var value = new proto_vorto_types_types_pb.NullableTime;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableTime.deserializeBinaryFromReader);
      msg.setCompletedAt(value);
      break;
    case 9:
      var value = /** @type {!proto.vtv1.ShipmentStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatusUpdatedAt(value);
      break;
    case 11:
      var value = new proto_vorto_types_types_pb.NullableString;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableString.deserializeBinaryFromReader);
      msg.setTicketNumber(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setUpdatedAt(value);
      break;
    case 13:
      var value = new proto.vtv1.ShipmentDetailsStop;
      reader.readMessage(value,proto.vtv1.ShipmentDetailsStop.deserializeBinaryFromReader);
      msg.addStops(value);
      break;
    case 14:
      var value = new proto.vtv1.ShipmentDetailsDriver;
      reader.readMessage(value,proto.vtv1.ShipmentDetailsDriver.deserializeBinaryFromReader);
      msg.setDriver(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setNodeId(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setNodeName(value);
      break;
    case 17:
      var value = new proto_vorto_types_types_pb.NullableString;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableString.deserializeBinaryFromReader);
      msg.setEdgeId(value);
      break;
    case 18:
      var value = new proto_vorto_types_types_pb.NullableString;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableString.deserializeBinaryFromReader);
      msg.setEdgeName(value);
      break;
    case 19:
      var value = new proto_vorto_types_types_pb.NullableString;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableString.deserializeBinaryFromReader);
      msg.setOrderId(value);
      break;
    case 20:
      var value = /** @type {!proto.vtv1.ShipmentProgressStatus} */ (reader.readEnum());
      msg.setProgressStatus(value);
      break;
    case 21:
      var value = /** @type {string} */ (reader.readString());
      msg.setProgressStatusUpdatedAt(value);
      break;
    case 22:
      var value = /** @type {string} */ (reader.readString());
      msg.addTrailerNames(value);
      break;
    case 23:
      var value = new proto_vorto_types_types_pb.File;
      reader.readMessage(value,proto_vorto_types_types_pb.File.deserializeBinaryFromReader);
      msg.addFiles(value);
      break;
    case 24:
      var value = new proto_vorto_types_types_pb.NullableString;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableString.deserializeBinaryFromReader);
      msg.setPurchaseOrderName(value);
      break;
    case 25:
      var value = new proto_vorto_types_types_pb.NullableString;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableString.deserializeBinaryFromReader);
      msg.setTruckNumber(value);
      break;
    case 26:
      var value = new proto_vorto_types_types_pb.NullableString;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableString.deserializeBinaryFromReader);
      msg.setTrailerNumber(value);
      break;
    case 27:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasBackhaul(value);
      break;
    case 28:
      var value = new proto_vorto_types_types_pb.NullableString;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableString.deserializeBinaryFromReader);
      msg.setReturnTrailer(value);
      break;
    case 29:
      var value = new proto.vtv1.ShipmentQuantities;
      reader.readMessage(value,proto.vtv1.ShipmentQuantities.deserializeBinaryFromReader);
      msg.addQuantities(value);
      break;
    case 30:
      var value = new proto.vtv1.ShipmentQuantities;
      reader.readMessage(value,proto.vtv1.ShipmentQuantities.deserializeBinaryFromReader);
      msg.addActualQuantities(value);
      break;
    case 31:
      var value = new proto.vtv1.ShipmentQuantities;
      reader.readMessage(value,proto.vtv1.ShipmentQuantities.deserializeBinaryFromReader);
      msg.addAllQuantities(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.ShipmentDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.ShipmentDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.ShipmentDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.ShipmentDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEdgeContractId();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_vorto_types_types_pb.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getEdgeContractName();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto_vorto_types_types_pb.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getCreatedAt();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCreatedBy();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto_vorto_types_types_pb.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getCreatedByName();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto_vorto_types_types_pb.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getDescription();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto_vorto_types_types_pb.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getCompletedAt();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto_vorto_types_types_pb.NullableTime.serializeBinaryToWriter
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      9,
      f
    );
  }
  f = message.getStatusUpdatedAt();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getTicketNumber();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto_vorto_types_types_pb.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getUpdatedAt();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getStopsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      13,
      f,
      proto.vtv1.ShipmentDetailsStop.serializeBinaryToWriter
    );
  }
  f = message.getDriver();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      proto.vtv1.ShipmentDetailsDriver.serializeBinaryToWriter
    );
  }
  f = message.getNodeId();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getNodeName();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getEdgeId();
  if (f != null) {
    writer.writeMessage(
      17,
      f,
      proto_vorto_types_types_pb.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getEdgeName();
  if (f != null) {
    writer.writeMessage(
      18,
      f,
      proto_vorto_types_types_pb.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getOrderId();
  if (f != null) {
    writer.writeMessage(
      19,
      f,
      proto_vorto_types_types_pb.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getProgressStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      20,
      f
    );
  }
  f = message.getProgressStatusUpdatedAt();
  if (f.length > 0) {
    writer.writeString(
      21,
      f
    );
  }
  f = message.getTrailerNamesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      22,
      f
    );
  }
  f = message.getFilesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      23,
      f,
      proto_vorto_types_types_pb.File.serializeBinaryToWriter
    );
  }
  f = message.getPurchaseOrderName();
  if (f != null) {
    writer.writeMessage(
      24,
      f,
      proto_vorto_types_types_pb.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getTruckNumber();
  if (f != null) {
    writer.writeMessage(
      25,
      f,
      proto_vorto_types_types_pb.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getTrailerNumber();
  if (f != null) {
    writer.writeMessage(
      26,
      f,
      proto_vorto_types_types_pb.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getHasBackhaul();
  if (f) {
    writer.writeBool(
      27,
      f
    );
  }
  f = message.getReturnTrailer();
  if (f != null) {
    writer.writeMessage(
      28,
      f,
      proto_vorto_types_types_pb.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getQuantitiesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      29,
      f,
      proto.vtv1.ShipmentQuantities.serializeBinaryToWriter
    );
  }
  f = message.getActualQuantitiesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      30,
      f,
      proto.vtv1.ShipmentQuantities.serializeBinaryToWriter
    );
  }
  f = message.getAllQuantitiesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      31,
      f,
      proto.vtv1.ShipmentQuantities.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.vtv1.ShipmentDetails.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.ShipmentDetails} returns this
 */
proto.vtv1.ShipmentDetails.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional NullableString edge_contract_id = 2;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.ShipmentDetails.prototype.getEdgeContractId = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableString, 2));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.ShipmentDetails} returns this
*/
proto.vtv1.ShipmentDetails.prototype.setEdgeContractId = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.ShipmentDetails} returns this
 */
proto.vtv1.ShipmentDetails.prototype.clearEdgeContractId = function() {
  return this.setEdgeContractId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.ShipmentDetails.prototype.hasEdgeContractId = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional NullableString edge_contract_name = 3;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.ShipmentDetails.prototype.getEdgeContractName = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableString, 3));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.ShipmentDetails} returns this
*/
proto.vtv1.ShipmentDetails.prototype.setEdgeContractName = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.ShipmentDetails} returns this
 */
proto.vtv1.ShipmentDetails.prototype.clearEdgeContractName = function() {
  return this.setEdgeContractName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.ShipmentDetails.prototype.hasEdgeContractName = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string created_at = 4;
 * @return {string}
 */
proto.vtv1.ShipmentDetails.prototype.getCreatedAt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.ShipmentDetails} returns this
 */
proto.vtv1.ShipmentDetails.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional NullableString created_by = 5;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.ShipmentDetails.prototype.getCreatedBy = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableString, 5));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.ShipmentDetails} returns this
*/
proto.vtv1.ShipmentDetails.prototype.setCreatedBy = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.ShipmentDetails} returns this
 */
proto.vtv1.ShipmentDetails.prototype.clearCreatedBy = function() {
  return this.setCreatedBy(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.ShipmentDetails.prototype.hasCreatedBy = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional NullableString created_by_name = 6;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.ShipmentDetails.prototype.getCreatedByName = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableString, 6));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.ShipmentDetails} returns this
*/
proto.vtv1.ShipmentDetails.prototype.setCreatedByName = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.ShipmentDetails} returns this
 */
proto.vtv1.ShipmentDetails.prototype.clearCreatedByName = function() {
  return this.setCreatedByName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.ShipmentDetails.prototype.hasCreatedByName = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional NullableString description = 7;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.ShipmentDetails.prototype.getDescription = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableString, 7));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.ShipmentDetails} returns this
*/
proto.vtv1.ShipmentDetails.prototype.setDescription = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.ShipmentDetails} returns this
 */
proto.vtv1.ShipmentDetails.prototype.clearDescription = function() {
  return this.setDescription(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.ShipmentDetails.prototype.hasDescription = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional NullableTime completed_at = 8;
 * @return {?proto.vtv1.NullableTime}
 */
proto.vtv1.ShipmentDetails.prototype.getCompletedAt = function() {
  return /** @type{?proto.vtv1.NullableTime} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableTime, 8));
};


/**
 * @param {?proto.vtv1.NullableTime|undefined} value
 * @return {!proto.vtv1.ShipmentDetails} returns this
*/
proto.vtv1.ShipmentDetails.prototype.setCompletedAt = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.ShipmentDetails} returns this
 */
proto.vtv1.ShipmentDetails.prototype.clearCompletedAt = function() {
  return this.setCompletedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.ShipmentDetails.prototype.hasCompletedAt = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional ShipmentStatus status = 9;
 * @return {!proto.vtv1.ShipmentStatus}
 */
proto.vtv1.ShipmentDetails.prototype.getStatus = function() {
  return /** @type {!proto.vtv1.ShipmentStatus} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {!proto.vtv1.ShipmentStatus} value
 * @return {!proto.vtv1.ShipmentDetails} returns this
 */
proto.vtv1.ShipmentDetails.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 9, value);
};


/**
 * optional string status_updated_at = 10;
 * @return {string}
 */
proto.vtv1.ShipmentDetails.prototype.getStatusUpdatedAt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.ShipmentDetails} returns this
 */
proto.vtv1.ShipmentDetails.prototype.setStatusUpdatedAt = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional NullableString ticket_number = 11;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.ShipmentDetails.prototype.getTicketNumber = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableString, 11));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.ShipmentDetails} returns this
*/
proto.vtv1.ShipmentDetails.prototype.setTicketNumber = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.ShipmentDetails} returns this
 */
proto.vtv1.ShipmentDetails.prototype.clearTicketNumber = function() {
  return this.setTicketNumber(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.ShipmentDetails.prototype.hasTicketNumber = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional string updated_at = 12;
 * @return {string}
 */
proto.vtv1.ShipmentDetails.prototype.getUpdatedAt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.ShipmentDetails} returns this
 */
proto.vtv1.ShipmentDetails.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * repeated ShipmentDetailsStop stops = 13;
 * @return {!Array<!proto.vtv1.ShipmentDetailsStop>}
 */
proto.vtv1.ShipmentDetails.prototype.getStopsList = function() {
  return /** @type{!Array<!proto.vtv1.ShipmentDetailsStop>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vtv1.ShipmentDetailsStop, 13));
};


/**
 * @param {!Array<!proto.vtv1.ShipmentDetailsStop>} value
 * @return {!proto.vtv1.ShipmentDetails} returns this
*/
proto.vtv1.ShipmentDetails.prototype.setStopsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 13, value);
};


/**
 * @param {!proto.vtv1.ShipmentDetailsStop=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.ShipmentDetailsStop}
 */
proto.vtv1.ShipmentDetails.prototype.addStops = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 13, opt_value, proto.vtv1.ShipmentDetailsStop, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.ShipmentDetails} returns this
 */
proto.vtv1.ShipmentDetails.prototype.clearStopsList = function() {
  return this.setStopsList([]);
};


/**
 * optional ShipmentDetailsDriver driver = 14;
 * @return {?proto.vtv1.ShipmentDetailsDriver}
 */
proto.vtv1.ShipmentDetails.prototype.getDriver = function() {
  return /** @type{?proto.vtv1.ShipmentDetailsDriver} */ (
    jspb.Message.getWrapperField(this, proto.vtv1.ShipmentDetailsDriver, 14));
};


/**
 * @param {?proto.vtv1.ShipmentDetailsDriver|undefined} value
 * @return {!proto.vtv1.ShipmentDetails} returns this
*/
proto.vtv1.ShipmentDetails.prototype.setDriver = function(value) {
  return jspb.Message.setWrapperField(this, 14, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.ShipmentDetails} returns this
 */
proto.vtv1.ShipmentDetails.prototype.clearDriver = function() {
  return this.setDriver(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.ShipmentDetails.prototype.hasDriver = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional string node_id = 15;
 * @return {string}
 */
proto.vtv1.ShipmentDetails.prototype.getNodeId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.ShipmentDetails} returns this
 */
proto.vtv1.ShipmentDetails.prototype.setNodeId = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional string node_name = 16;
 * @return {string}
 */
proto.vtv1.ShipmentDetails.prototype.getNodeName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.ShipmentDetails} returns this
 */
proto.vtv1.ShipmentDetails.prototype.setNodeName = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional NullableString edge_id = 17;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.ShipmentDetails.prototype.getEdgeId = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableString, 17));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.ShipmentDetails} returns this
*/
proto.vtv1.ShipmentDetails.prototype.setEdgeId = function(value) {
  return jspb.Message.setWrapperField(this, 17, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.ShipmentDetails} returns this
 */
proto.vtv1.ShipmentDetails.prototype.clearEdgeId = function() {
  return this.setEdgeId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.ShipmentDetails.prototype.hasEdgeId = function() {
  return jspb.Message.getField(this, 17) != null;
};


/**
 * optional NullableString edge_name = 18;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.ShipmentDetails.prototype.getEdgeName = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableString, 18));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.ShipmentDetails} returns this
*/
proto.vtv1.ShipmentDetails.prototype.setEdgeName = function(value) {
  return jspb.Message.setWrapperField(this, 18, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.ShipmentDetails} returns this
 */
proto.vtv1.ShipmentDetails.prototype.clearEdgeName = function() {
  return this.setEdgeName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.ShipmentDetails.prototype.hasEdgeName = function() {
  return jspb.Message.getField(this, 18) != null;
};


/**
 * optional NullableString order_id = 19;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.ShipmentDetails.prototype.getOrderId = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableString, 19));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.ShipmentDetails} returns this
*/
proto.vtv1.ShipmentDetails.prototype.setOrderId = function(value) {
  return jspb.Message.setWrapperField(this, 19, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.ShipmentDetails} returns this
 */
proto.vtv1.ShipmentDetails.prototype.clearOrderId = function() {
  return this.setOrderId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.ShipmentDetails.prototype.hasOrderId = function() {
  return jspb.Message.getField(this, 19) != null;
};


/**
 * optional ShipmentProgressStatus progress_status = 20;
 * @return {!proto.vtv1.ShipmentProgressStatus}
 */
proto.vtv1.ShipmentDetails.prototype.getProgressStatus = function() {
  return /** @type {!proto.vtv1.ShipmentProgressStatus} */ (jspb.Message.getFieldWithDefault(this, 20, 0));
};


/**
 * @param {!proto.vtv1.ShipmentProgressStatus} value
 * @return {!proto.vtv1.ShipmentDetails} returns this
 */
proto.vtv1.ShipmentDetails.prototype.setProgressStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 20, value);
};


/**
 * optional string progress_status_updated_at = 21;
 * @return {string}
 */
proto.vtv1.ShipmentDetails.prototype.getProgressStatusUpdatedAt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 21, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.ShipmentDetails} returns this
 */
proto.vtv1.ShipmentDetails.prototype.setProgressStatusUpdatedAt = function(value) {
  return jspb.Message.setProto3StringField(this, 21, value);
};


/**
 * repeated string trailer_names = 22;
 * @return {!Array<string>}
 */
proto.vtv1.ShipmentDetails.prototype.getTrailerNamesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 22));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.vtv1.ShipmentDetails} returns this
 */
proto.vtv1.ShipmentDetails.prototype.setTrailerNamesList = function(value) {
  return jspb.Message.setField(this, 22, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.vtv1.ShipmentDetails} returns this
 */
proto.vtv1.ShipmentDetails.prototype.addTrailerNames = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 22, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.ShipmentDetails} returns this
 */
proto.vtv1.ShipmentDetails.prototype.clearTrailerNamesList = function() {
  return this.setTrailerNamesList([]);
};


/**
 * repeated File files = 23;
 * @return {!Array<!proto.vtv1.File>}
 */
proto.vtv1.ShipmentDetails.prototype.getFilesList = function() {
  return /** @type{!Array<!proto.vtv1.File>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_vorto_types_types_pb.File, 23));
};


/**
 * @param {!Array<!proto.vtv1.File>} value
 * @return {!proto.vtv1.ShipmentDetails} returns this
*/
proto.vtv1.ShipmentDetails.prototype.setFilesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 23, value);
};


/**
 * @param {!proto.vtv1.File=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.File}
 */
proto.vtv1.ShipmentDetails.prototype.addFiles = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 23, opt_value, proto.vtv1.File, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.ShipmentDetails} returns this
 */
proto.vtv1.ShipmentDetails.prototype.clearFilesList = function() {
  return this.setFilesList([]);
};


/**
 * optional NullableString purchase_order_name = 24;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.ShipmentDetails.prototype.getPurchaseOrderName = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableString, 24));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.ShipmentDetails} returns this
*/
proto.vtv1.ShipmentDetails.prototype.setPurchaseOrderName = function(value) {
  return jspb.Message.setWrapperField(this, 24, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.ShipmentDetails} returns this
 */
proto.vtv1.ShipmentDetails.prototype.clearPurchaseOrderName = function() {
  return this.setPurchaseOrderName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.ShipmentDetails.prototype.hasPurchaseOrderName = function() {
  return jspb.Message.getField(this, 24) != null;
};


/**
 * optional NullableString truck_number = 25;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.ShipmentDetails.prototype.getTruckNumber = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableString, 25));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.ShipmentDetails} returns this
*/
proto.vtv1.ShipmentDetails.prototype.setTruckNumber = function(value) {
  return jspb.Message.setWrapperField(this, 25, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.ShipmentDetails} returns this
 */
proto.vtv1.ShipmentDetails.prototype.clearTruckNumber = function() {
  return this.setTruckNumber(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.ShipmentDetails.prototype.hasTruckNumber = function() {
  return jspb.Message.getField(this, 25) != null;
};


/**
 * optional NullableString trailer_number = 26;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.ShipmentDetails.prototype.getTrailerNumber = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableString, 26));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.ShipmentDetails} returns this
*/
proto.vtv1.ShipmentDetails.prototype.setTrailerNumber = function(value) {
  return jspb.Message.setWrapperField(this, 26, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.ShipmentDetails} returns this
 */
proto.vtv1.ShipmentDetails.prototype.clearTrailerNumber = function() {
  return this.setTrailerNumber(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.ShipmentDetails.prototype.hasTrailerNumber = function() {
  return jspb.Message.getField(this, 26) != null;
};


/**
 * optional bool has_backhaul = 27;
 * @return {boolean}
 */
proto.vtv1.ShipmentDetails.prototype.getHasBackhaul = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 27, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vtv1.ShipmentDetails} returns this
 */
proto.vtv1.ShipmentDetails.prototype.setHasBackhaul = function(value) {
  return jspb.Message.setProto3BooleanField(this, 27, value);
};


/**
 * optional NullableString return_trailer = 28;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.ShipmentDetails.prototype.getReturnTrailer = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableString, 28));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.ShipmentDetails} returns this
*/
proto.vtv1.ShipmentDetails.prototype.setReturnTrailer = function(value) {
  return jspb.Message.setWrapperField(this, 28, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.ShipmentDetails} returns this
 */
proto.vtv1.ShipmentDetails.prototype.clearReturnTrailer = function() {
  return this.setReturnTrailer(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.ShipmentDetails.prototype.hasReturnTrailer = function() {
  return jspb.Message.getField(this, 28) != null;
};


/**
 * repeated ShipmentQuantities quantities = 29;
 * @return {!Array<!proto.vtv1.ShipmentQuantities>}
 */
proto.vtv1.ShipmentDetails.prototype.getQuantitiesList = function() {
  return /** @type{!Array<!proto.vtv1.ShipmentQuantities>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vtv1.ShipmentQuantities, 29));
};


/**
 * @param {!Array<!proto.vtv1.ShipmentQuantities>} value
 * @return {!proto.vtv1.ShipmentDetails} returns this
*/
proto.vtv1.ShipmentDetails.prototype.setQuantitiesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 29, value);
};


/**
 * @param {!proto.vtv1.ShipmentQuantities=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.ShipmentQuantities}
 */
proto.vtv1.ShipmentDetails.prototype.addQuantities = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 29, opt_value, proto.vtv1.ShipmentQuantities, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.ShipmentDetails} returns this
 */
proto.vtv1.ShipmentDetails.prototype.clearQuantitiesList = function() {
  return this.setQuantitiesList([]);
};


/**
 * repeated ShipmentQuantities actual_quantities = 30;
 * @return {!Array<!proto.vtv1.ShipmentQuantities>}
 */
proto.vtv1.ShipmentDetails.prototype.getActualQuantitiesList = function() {
  return /** @type{!Array<!proto.vtv1.ShipmentQuantities>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vtv1.ShipmentQuantities, 30));
};


/**
 * @param {!Array<!proto.vtv1.ShipmentQuantities>} value
 * @return {!proto.vtv1.ShipmentDetails} returns this
*/
proto.vtv1.ShipmentDetails.prototype.setActualQuantitiesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 30, value);
};


/**
 * @param {!proto.vtv1.ShipmentQuantities=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.ShipmentQuantities}
 */
proto.vtv1.ShipmentDetails.prototype.addActualQuantities = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 30, opt_value, proto.vtv1.ShipmentQuantities, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.ShipmentDetails} returns this
 */
proto.vtv1.ShipmentDetails.prototype.clearActualQuantitiesList = function() {
  return this.setActualQuantitiesList([]);
};


/**
 * repeated ShipmentQuantities all_quantities = 31;
 * @return {!Array<!proto.vtv1.ShipmentQuantities>}
 */
proto.vtv1.ShipmentDetails.prototype.getAllQuantitiesList = function() {
  return /** @type{!Array<!proto.vtv1.ShipmentQuantities>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vtv1.ShipmentQuantities, 31));
};


/**
 * @param {!Array<!proto.vtv1.ShipmentQuantities>} value
 * @return {!proto.vtv1.ShipmentDetails} returns this
*/
proto.vtv1.ShipmentDetails.prototype.setAllQuantitiesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 31, value);
};


/**
 * @param {!proto.vtv1.ShipmentQuantities=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.ShipmentQuantities}
 */
proto.vtv1.ShipmentDetails.prototype.addAllQuantities = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 31, opt_value, proto.vtv1.ShipmentQuantities, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.ShipmentDetails} returns this
 */
proto.vtv1.ShipmentDetails.prototype.clearAllQuantitiesList = function() {
  return this.setAllQuantitiesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.ShipmentDetailsStop.repeatedFields_ = [11];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.ShipmentDetailsStop.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.ShipmentDetailsStop.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.ShipmentDetailsStop} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.ShipmentDetailsStop.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    sequence: jspb.Message.getFieldWithDefault(msg, 2, 0),
    createdAt: jspb.Message.getFieldWithDefault(msg, 3, ""),
    completedAt: (f = msg.getCompletedAt()) && proto_vorto_types_types_pb.NullableTime.toObject(includeInstance, f),
    siteId: (f = msg.getSiteId()) && proto_vorto_types_types_pb.NullableString.toObject(includeInstance, f),
    siteName: jspb.Message.getFieldWithDefault(msg, 6, ""),
    longitude: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    latitude: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
    siteOwnerId: jspb.Message.getFieldWithDefault(msg, 9, ""),
    siteOwnerName: jspb.Message.getFieldWithDefault(msg, 10, ""),
    tasksList: jspb.Message.toObjectList(msg.getTasksList(),
    proto.vtv1.ShipmentDetailsTask.toObject, includeInstance),
    arrivalWindowStartsAt: (f = msg.getArrivalWindowStartsAt()) && proto_vorto_types_types_pb.NullableTime.toObject(includeInstance, f),
    arrivalWindowMinutes: (f = msg.getArrivalWindowMinutes()) && proto_vorto_types_types_pb.NullableInt64.toObject(includeInstance, f),
    willSiteBeClosed: jspb.Message.getBooleanFieldWithDefault(msg, 14, false),
    eta: (f = msg.getEta()) && proto_vorto_types_types_pb.NullableTime.toObject(includeInstance, f),
    siteOwnerInstructions: (f = msg.getSiteOwnerInstructions()) && proto_vorto_types_types_pb.NullableString.toObject(includeInstance, f),
    pickupNumber: (f = msg.getPickupNumber()) && proto_vorto_types_types_pb.NullableString.toObject(includeInstance, f),
    arrivedAt: (f = msg.getArrivedAt()) && proto_vorto_types_types_pb.NullableTime.toObject(includeInstance, f),
    exitedAt: (f = msg.getExitedAt()) && proto_vorto_types_types_pb.NullableTime.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.ShipmentDetailsStop}
 */
proto.vtv1.ShipmentDetailsStop.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.ShipmentDetailsStop;
  return proto.vtv1.ShipmentDetailsStop.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.ShipmentDetailsStop} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.ShipmentDetailsStop}
 */
proto.vtv1.ShipmentDetailsStop.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSequence(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedAt(value);
      break;
    case 4:
      var value = new proto_vorto_types_types_pb.NullableTime;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableTime.deserializeBinaryFromReader);
      msg.setCompletedAt(value);
      break;
    case 5:
      var value = new proto_vorto_types_types_pb.NullableString;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableString.deserializeBinaryFromReader);
      msg.setSiteId(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setSiteName(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLongitude(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLatitude(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setSiteOwnerId(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setSiteOwnerName(value);
      break;
    case 11:
      var value = new proto.vtv1.ShipmentDetailsTask;
      reader.readMessage(value,proto.vtv1.ShipmentDetailsTask.deserializeBinaryFromReader);
      msg.addTasks(value);
      break;
    case 12:
      var value = new proto_vorto_types_types_pb.NullableTime;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableTime.deserializeBinaryFromReader);
      msg.setArrivalWindowStartsAt(value);
      break;
    case 13:
      var value = new proto_vorto_types_types_pb.NullableInt64;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableInt64.deserializeBinaryFromReader);
      msg.setArrivalWindowMinutes(value);
      break;
    case 14:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setWillSiteBeClosed(value);
      break;
    case 15:
      var value = new proto_vorto_types_types_pb.NullableTime;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableTime.deserializeBinaryFromReader);
      msg.setEta(value);
      break;
    case 16:
      var value = new proto_vorto_types_types_pb.NullableString;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableString.deserializeBinaryFromReader);
      msg.setSiteOwnerInstructions(value);
      break;
    case 17:
      var value = new proto_vorto_types_types_pb.NullableString;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableString.deserializeBinaryFromReader);
      msg.setPickupNumber(value);
      break;
    case 18:
      var value = new proto_vorto_types_types_pb.NullableTime;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableTime.deserializeBinaryFromReader);
      msg.setArrivedAt(value);
      break;
    case 19:
      var value = new proto_vorto_types_types_pb.NullableTime;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableTime.deserializeBinaryFromReader);
      msg.setExitedAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.ShipmentDetailsStop.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.ShipmentDetailsStop.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.ShipmentDetailsStop} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.ShipmentDetailsStop.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getSequence();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getCreatedAt();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCompletedAt();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto_vorto_types_types_pb.NullableTime.serializeBinaryToWriter
    );
  }
  f = message.getSiteId();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto_vorto_types_types_pb.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getSiteName();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getLongitude();
  if (f !== 0.0) {
    writer.writeDouble(
      7,
      f
    );
  }
  f = message.getLatitude();
  if (f !== 0.0) {
    writer.writeDouble(
      8,
      f
    );
  }
  f = message.getSiteOwnerId();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getSiteOwnerName();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getTasksList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      11,
      f,
      proto.vtv1.ShipmentDetailsTask.serializeBinaryToWriter
    );
  }
  f = message.getArrivalWindowStartsAt();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      proto_vorto_types_types_pb.NullableTime.serializeBinaryToWriter
    );
  }
  f = message.getArrivalWindowMinutes();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      proto_vorto_types_types_pb.NullableInt64.serializeBinaryToWriter
    );
  }
  f = message.getWillSiteBeClosed();
  if (f) {
    writer.writeBool(
      14,
      f
    );
  }
  f = message.getEta();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      proto_vorto_types_types_pb.NullableTime.serializeBinaryToWriter
    );
  }
  f = message.getSiteOwnerInstructions();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      proto_vorto_types_types_pb.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getPickupNumber();
  if (f != null) {
    writer.writeMessage(
      17,
      f,
      proto_vorto_types_types_pb.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getArrivedAt();
  if (f != null) {
    writer.writeMessage(
      18,
      f,
      proto_vorto_types_types_pb.NullableTime.serializeBinaryToWriter
    );
  }
  f = message.getExitedAt();
  if (f != null) {
    writer.writeMessage(
      19,
      f,
      proto_vorto_types_types_pb.NullableTime.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.vtv1.ShipmentDetailsStop.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.ShipmentDetailsStop} returns this
 */
proto.vtv1.ShipmentDetailsStop.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 sequence = 2;
 * @return {number}
 */
proto.vtv1.ShipmentDetailsStop.prototype.getSequence = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.ShipmentDetailsStop} returns this
 */
proto.vtv1.ShipmentDetailsStop.prototype.setSequence = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string created_at = 3;
 * @return {string}
 */
proto.vtv1.ShipmentDetailsStop.prototype.getCreatedAt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.ShipmentDetailsStop} returns this
 */
proto.vtv1.ShipmentDetailsStop.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional NullableTime completed_at = 4;
 * @return {?proto.vtv1.NullableTime}
 */
proto.vtv1.ShipmentDetailsStop.prototype.getCompletedAt = function() {
  return /** @type{?proto.vtv1.NullableTime} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableTime, 4));
};


/**
 * @param {?proto.vtv1.NullableTime|undefined} value
 * @return {!proto.vtv1.ShipmentDetailsStop} returns this
*/
proto.vtv1.ShipmentDetailsStop.prototype.setCompletedAt = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.ShipmentDetailsStop} returns this
 */
proto.vtv1.ShipmentDetailsStop.prototype.clearCompletedAt = function() {
  return this.setCompletedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.ShipmentDetailsStop.prototype.hasCompletedAt = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional NullableString site_id = 5;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.ShipmentDetailsStop.prototype.getSiteId = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableString, 5));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.ShipmentDetailsStop} returns this
*/
proto.vtv1.ShipmentDetailsStop.prototype.setSiteId = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.ShipmentDetailsStop} returns this
 */
proto.vtv1.ShipmentDetailsStop.prototype.clearSiteId = function() {
  return this.setSiteId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.ShipmentDetailsStop.prototype.hasSiteId = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional string site_name = 6;
 * @return {string}
 */
proto.vtv1.ShipmentDetailsStop.prototype.getSiteName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.ShipmentDetailsStop} returns this
 */
proto.vtv1.ShipmentDetailsStop.prototype.setSiteName = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional double longitude = 7;
 * @return {number}
 */
proto.vtv1.ShipmentDetailsStop.prototype.getLongitude = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.ShipmentDetailsStop} returns this
 */
proto.vtv1.ShipmentDetailsStop.prototype.setLongitude = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional double latitude = 8;
 * @return {number}
 */
proto.vtv1.ShipmentDetailsStop.prototype.getLatitude = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.ShipmentDetailsStop} returns this
 */
proto.vtv1.ShipmentDetailsStop.prototype.setLatitude = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};


/**
 * optional string site_owner_id = 9;
 * @return {string}
 */
proto.vtv1.ShipmentDetailsStop.prototype.getSiteOwnerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.ShipmentDetailsStop} returns this
 */
proto.vtv1.ShipmentDetailsStop.prototype.setSiteOwnerId = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string site_owner_name = 10;
 * @return {string}
 */
proto.vtv1.ShipmentDetailsStop.prototype.getSiteOwnerName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.ShipmentDetailsStop} returns this
 */
proto.vtv1.ShipmentDetailsStop.prototype.setSiteOwnerName = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * repeated ShipmentDetailsTask tasks = 11;
 * @return {!Array<!proto.vtv1.ShipmentDetailsTask>}
 */
proto.vtv1.ShipmentDetailsStop.prototype.getTasksList = function() {
  return /** @type{!Array<!proto.vtv1.ShipmentDetailsTask>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vtv1.ShipmentDetailsTask, 11));
};


/**
 * @param {!Array<!proto.vtv1.ShipmentDetailsTask>} value
 * @return {!proto.vtv1.ShipmentDetailsStop} returns this
*/
proto.vtv1.ShipmentDetailsStop.prototype.setTasksList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 11, value);
};


/**
 * @param {!proto.vtv1.ShipmentDetailsTask=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.ShipmentDetailsTask}
 */
proto.vtv1.ShipmentDetailsStop.prototype.addTasks = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 11, opt_value, proto.vtv1.ShipmentDetailsTask, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.ShipmentDetailsStop} returns this
 */
proto.vtv1.ShipmentDetailsStop.prototype.clearTasksList = function() {
  return this.setTasksList([]);
};


/**
 * optional NullableTime arrival_window_starts_at = 12;
 * @return {?proto.vtv1.NullableTime}
 */
proto.vtv1.ShipmentDetailsStop.prototype.getArrivalWindowStartsAt = function() {
  return /** @type{?proto.vtv1.NullableTime} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableTime, 12));
};


/**
 * @param {?proto.vtv1.NullableTime|undefined} value
 * @return {!proto.vtv1.ShipmentDetailsStop} returns this
*/
proto.vtv1.ShipmentDetailsStop.prototype.setArrivalWindowStartsAt = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.ShipmentDetailsStop} returns this
 */
proto.vtv1.ShipmentDetailsStop.prototype.clearArrivalWindowStartsAt = function() {
  return this.setArrivalWindowStartsAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.ShipmentDetailsStop.prototype.hasArrivalWindowStartsAt = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional NullableInt64 arrival_window_minutes = 13;
 * @return {?proto.vtv1.NullableInt64}
 */
proto.vtv1.ShipmentDetailsStop.prototype.getArrivalWindowMinutes = function() {
  return /** @type{?proto.vtv1.NullableInt64} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableInt64, 13));
};


/**
 * @param {?proto.vtv1.NullableInt64|undefined} value
 * @return {!proto.vtv1.ShipmentDetailsStop} returns this
*/
proto.vtv1.ShipmentDetailsStop.prototype.setArrivalWindowMinutes = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.ShipmentDetailsStop} returns this
 */
proto.vtv1.ShipmentDetailsStop.prototype.clearArrivalWindowMinutes = function() {
  return this.setArrivalWindowMinutes(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.ShipmentDetailsStop.prototype.hasArrivalWindowMinutes = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional bool will_site_be_closed = 14;
 * @return {boolean}
 */
proto.vtv1.ShipmentDetailsStop.prototype.getWillSiteBeClosed = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 14, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vtv1.ShipmentDetailsStop} returns this
 */
proto.vtv1.ShipmentDetailsStop.prototype.setWillSiteBeClosed = function(value) {
  return jspb.Message.setProto3BooleanField(this, 14, value);
};


/**
 * optional NullableTime eta = 15;
 * @return {?proto.vtv1.NullableTime}
 */
proto.vtv1.ShipmentDetailsStop.prototype.getEta = function() {
  return /** @type{?proto.vtv1.NullableTime} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableTime, 15));
};


/**
 * @param {?proto.vtv1.NullableTime|undefined} value
 * @return {!proto.vtv1.ShipmentDetailsStop} returns this
*/
proto.vtv1.ShipmentDetailsStop.prototype.setEta = function(value) {
  return jspb.Message.setWrapperField(this, 15, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.ShipmentDetailsStop} returns this
 */
proto.vtv1.ShipmentDetailsStop.prototype.clearEta = function() {
  return this.setEta(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.ShipmentDetailsStop.prototype.hasEta = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional NullableString site_owner_instructions = 16;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.ShipmentDetailsStop.prototype.getSiteOwnerInstructions = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableString, 16));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.ShipmentDetailsStop} returns this
*/
proto.vtv1.ShipmentDetailsStop.prototype.setSiteOwnerInstructions = function(value) {
  return jspb.Message.setWrapperField(this, 16, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.ShipmentDetailsStop} returns this
 */
proto.vtv1.ShipmentDetailsStop.prototype.clearSiteOwnerInstructions = function() {
  return this.setSiteOwnerInstructions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.ShipmentDetailsStop.prototype.hasSiteOwnerInstructions = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * optional NullableString pickup_number = 17;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.ShipmentDetailsStop.prototype.getPickupNumber = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableString, 17));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.ShipmentDetailsStop} returns this
*/
proto.vtv1.ShipmentDetailsStop.prototype.setPickupNumber = function(value) {
  return jspb.Message.setWrapperField(this, 17, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.ShipmentDetailsStop} returns this
 */
proto.vtv1.ShipmentDetailsStop.prototype.clearPickupNumber = function() {
  return this.setPickupNumber(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.ShipmentDetailsStop.prototype.hasPickupNumber = function() {
  return jspb.Message.getField(this, 17) != null;
};


/**
 * optional NullableTime arrived_at = 18;
 * @return {?proto.vtv1.NullableTime}
 */
proto.vtv1.ShipmentDetailsStop.prototype.getArrivedAt = function() {
  return /** @type{?proto.vtv1.NullableTime} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableTime, 18));
};


/**
 * @param {?proto.vtv1.NullableTime|undefined} value
 * @return {!proto.vtv1.ShipmentDetailsStop} returns this
*/
proto.vtv1.ShipmentDetailsStop.prototype.setArrivedAt = function(value) {
  return jspb.Message.setWrapperField(this, 18, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.ShipmentDetailsStop} returns this
 */
proto.vtv1.ShipmentDetailsStop.prototype.clearArrivedAt = function() {
  return this.setArrivedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.ShipmentDetailsStop.prototype.hasArrivedAt = function() {
  return jspb.Message.getField(this, 18) != null;
};


/**
 * optional NullableTime exited_at = 19;
 * @return {?proto.vtv1.NullableTime}
 */
proto.vtv1.ShipmentDetailsStop.prototype.getExitedAt = function() {
  return /** @type{?proto.vtv1.NullableTime} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableTime, 19));
};


/**
 * @param {?proto.vtv1.NullableTime|undefined} value
 * @return {!proto.vtv1.ShipmentDetailsStop} returns this
*/
proto.vtv1.ShipmentDetailsStop.prototype.setExitedAt = function(value) {
  return jspb.Message.setWrapperField(this, 19, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.ShipmentDetailsStop} returns this
 */
proto.vtv1.ShipmentDetailsStop.prototype.clearExitedAt = function() {
  return this.setExitedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.ShipmentDetailsStop.prototype.hasExitedAt = function() {
  return jspb.Message.getField(this, 19) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.ShipmentDetailsTask.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.ShipmentDetailsTask.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.ShipmentDetailsTask} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.ShipmentDetailsTask.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    action: jspb.Message.getFieldWithDefault(msg, 2, 0),
    bolNumber: (f = msg.getBolNumber()) && proto_vorto_types_types_pb.NullableString.toObject(includeInstance, f),
    quantity: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    productId: jspb.Message.getFieldWithDefault(msg, 5, ""),
    productName: jspb.Message.getFieldWithDefault(msg, 6, ""),
    unitId: jspb.Message.getFieldWithDefault(msg, 7, 0),
    unitName: jspb.Message.getFieldWithDefault(msg, 8, ""),
    unitAbbreviation: jspb.Message.getFieldWithDefault(msg, 9, ""),
    actualQuantity: (f = msg.getActualQuantity()) && proto_vorto_types_types_pb.NullableDouble.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.ShipmentDetailsTask}
 */
proto.vtv1.ShipmentDetailsTask.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.ShipmentDetailsTask;
  return proto.vtv1.ShipmentDetailsTask.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.ShipmentDetailsTask} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.ShipmentDetailsTask}
 */
proto.vtv1.ShipmentDetailsTask.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {!proto.vtv1.TaskAction} */ (reader.readEnum());
      msg.setAction(value);
      break;
    case 3:
      var value = new proto_vorto_types_types_pb.NullableString;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableString.deserializeBinaryFromReader);
      msg.setBolNumber(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setQuantity(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductId(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductName(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUnitId(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setUnitName(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setUnitAbbreviation(value);
      break;
    case 10:
      var value = new proto_vorto_types_types_pb.NullableDouble;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableDouble.deserializeBinaryFromReader);
      msg.setActualQuantity(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.ShipmentDetailsTask.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.ShipmentDetailsTask.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.ShipmentDetailsTask} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.ShipmentDetailsTask.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getAction();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getBolNumber();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto_vorto_types_types_pb.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getQuantity();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getProductId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getProductName();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getUnitId();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
  f = message.getUnitName();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getUnitAbbreviation();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getActualQuantity();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto_vorto_types_types_pb.NullableDouble.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.vtv1.ShipmentDetailsTask.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.ShipmentDetailsTask} returns this
 */
proto.vtv1.ShipmentDetailsTask.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional TaskAction action = 2;
 * @return {!proto.vtv1.TaskAction}
 */
proto.vtv1.ShipmentDetailsTask.prototype.getAction = function() {
  return /** @type {!proto.vtv1.TaskAction} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.vtv1.TaskAction} value
 * @return {!proto.vtv1.ShipmentDetailsTask} returns this
 */
proto.vtv1.ShipmentDetailsTask.prototype.setAction = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional NullableString bol_number = 3;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.ShipmentDetailsTask.prototype.getBolNumber = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableString, 3));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.ShipmentDetailsTask} returns this
*/
proto.vtv1.ShipmentDetailsTask.prototype.setBolNumber = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.ShipmentDetailsTask} returns this
 */
proto.vtv1.ShipmentDetailsTask.prototype.clearBolNumber = function() {
  return this.setBolNumber(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.ShipmentDetailsTask.prototype.hasBolNumber = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional double quantity = 4;
 * @return {number}
 */
proto.vtv1.ShipmentDetailsTask.prototype.getQuantity = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.ShipmentDetailsTask} returns this
 */
proto.vtv1.ShipmentDetailsTask.prototype.setQuantity = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional string product_id = 5;
 * @return {string}
 */
proto.vtv1.ShipmentDetailsTask.prototype.getProductId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.ShipmentDetailsTask} returns this
 */
proto.vtv1.ShipmentDetailsTask.prototype.setProductId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string product_name = 6;
 * @return {string}
 */
proto.vtv1.ShipmentDetailsTask.prototype.getProductName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.ShipmentDetailsTask} returns this
 */
proto.vtv1.ShipmentDetailsTask.prototype.setProductName = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional int64 unit_id = 7;
 * @return {number}
 */
proto.vtv1.ShipmentDetailsTask.prototype.getUnitId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.ShipmentDetailsTask} returns this
 */
proto.vtv1.ShipmentDetailsTask.prototype.setUnitId = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional string unit_name = 8;
 * @return {string}
 */
proto.vtv1.ShipmentDetailsTask.prototype.getUnitName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.ShipmentDetailsTask} returns this
 */
proto.vtv1.ShipmentDetailsTask.prototype.setUnitName = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string unit_abbreviation = 9;
 * @return {string}
 */
proto.vtv1.ShipmentDetailsTask.prototype.getUnitAbbreviation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.ShipmentDetailsTask} returns this
 */
proto.vtv1.ShipmentDetailsTask.prototype.setUnitAbbreviation = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional NullableDouble actual_quantity = 10;
 * @return {?proto.vtv1.NullableDouble}
 */
proto.vtv1.ShipmentDetailsTask.prototype.getActualQuantity = function() {
  return /** @type{?proto.vtv1.NullableDouble} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableDouble, 10));
};


/**
 * @param {?proto.vtv1.NullableDouble|undefined} value
 * @return {!proto.vtv1.ShipmentDetailsTask} returns this
*/
proto.vtv1.ShipmentDetailsTask.prototype.setActualQuantity = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.ShipmentDetailsTask} returns this
 */
proto.vtv1.ShipmentDetailsTask.prototype.clearActualQuantity = function() {
  return this.setActualQuantity(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.ShipmentDetailsTask.prototype.hasActualQuantity = function() {
  return jspb.Message.getField(this, 10) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.ShipmentDetailsDriver.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.ShipmentDetailsDriver.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.ShipmentDetailsDriver} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.ShipmentDetailsDriver.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    phone: jspb.Message.getFieldWithDefault(msg, 2, ""),
    name: jspb.Message.getFieldWithDefault(msg, 3, ""),
    longitude: (f = msg.getLongitude()) && proto_vorto_types_types_pb.NullableDouble.toObject(includeInstance, f),
    latitude: (f = msg.getLatitude()) && proto_vorto_types_types_pb.NullableDouble.toObject(includeInstance, f),
    waypointLatestCreatedAt: (f = msg.getWaypointLatestCreatedAt()) && proto_vorto_types_types_pb.NullableTime.toObject(includeInstance, f),
    exceptionMessage: (f = msg.getExceptionMessage()) && proto_vorto_types_types_pb.NullableString.toObject(includeInstance, f),
    trailerTypeId: jspb.Message.getFieldWithDefault(msg, 8, ""),
    trailerTypeName: jspb.Message.getFieldWithDefault(msg, 9, ""),
    vortoAiDriver: jspb.Message.getBooleanFieldWithDefault(msg, 10, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.ShipmentDetailsDriver}
 */
proto.vtv1.ShipmentDetailsDriver.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.ShipmentDetailsDriver;
  return proto.vtv1.ShipmentDetailsDriver.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.ShipmentDetailsDriver} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.ShipmentDetailsDriver}
 */
proto.vtv1.ShipmentDetailsDriver.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhone(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 4:
      var value = new proto_vorto_types_types_pb.NullableDouble;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableDouble.deserializeBinaryFromReader);
      msg.setLongitude(value);
      break;
    case 5:
      var value = new proto_vorto_types_types_pb.NullableDouble;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableDouble.deserializeBinaryFromReader);
      msg.setLatitude(value);
      break;
    case 6:
      var value = new proto_vorto_types_types_pb.NullableTime;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableTime.deserializeBinaryFromReader);
      msg.setWaypointLatestCreatedAt(value);
      break;
    case 7:
      var value = new proto_vorto_types_types_pb.NullableString;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableString.deserializeBinaryFromReader);
      msg.setExceptionMessage(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setTrailerTypeId(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setTrailerTypeName(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setVortoAiDriver(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.ShipmentDetailsDriver.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.ShipmentDetailsDriver.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.ShipmentDetailsDriver} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.ShipmentDetailsDriver.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPhone();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getLongitude();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto_vorto_types_types_pb.NullableDouble.serializeBinaryToWriter
    );
  }
  f = message.getLatitude();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto_vorto_types_types_pb.NullableDouble.serializeBinaryToWriter
    );
  }
  f = message.getWaypointLatestCreatedAt();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto_vorto_types_types_pb.NullableTime.serializeBinaryToWriter
    );
  }
  f = message.getExceptionMessage();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto_vorto_types_types_pb.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getTrailerTypeId();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getTrailerTypeName();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getVortoAiDriver();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.vtv1.ShipmentDetailsDriver.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.ShipmentDetailsDriver} returns this
 */
proto.vtv1.ShipmentDetailsDriver.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string phone = 2;
 * @return {string}
 */
proto.vtv1.ShipmentDetailsDriver.prototype.getPhone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.ShipmentDetailsDriver} returns this
 */
proto.vtv1.ShipmentDetailsDriver.prototype.setPhone = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string name = 3;
 * @return {string}
 */
proto.vtv1.ShipmentDetailsDriver.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.ShipmentDetailsDriver} returns this
 */
proto.vtv1.ShipmentDetailsDriver.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional NullableDouble longitude = 4;
 * @return {?proto.vtv1.NullableDouble}
 */
proto.vtv1.ShipmentDetailsDriver.prototype.getLongitude = function() {
  return /** @type{?proto.vtv1.NullableDouble} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableDouble, 4));
};


/**
 * @param {?proto.vtv1.NullableDouble|undefined} value
 * @return {!proto.vtv1.ShipmentDetailsDriver} returns this
*/
proto.vtv1.ShipmentDetailsDriver.prototype.setLongitude = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.ShipmentDetailsDriver} returns this
 */
proto.vtv1.ShipmentDetailsDriver.prototype.clearLongitude = function() {
  return this.setLongitude(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.ShipmentDetailsDriver.prototype.hasLongitude = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional NullableDouble latitude = 5;
 * @return {?proto.vtv1.NullableDouble}
 */
proto.vtv1.ShipmentDetailsDriver.prototype.getLatitude = function() {
  return /** @type{?proto.vtv1.NullableDouble} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableDouble, 5));
};


/**
 * @param {?proto.vtv1.NullableDouble|undefined} value
 * @return {!proto.vtv1.ShipmentDetailsDriver} returns this
*/
proto.vtv1.ShipmentDetailsDriver.prototype.setLatitude = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.ShipmentDetailsDriver} returns this
 */
proto.vtv1.ShipmentDetailsDriver.prototype.clearLatitude = function() {
  return this.setLatitude(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.ShipmentDetailsDriver.prototype.hasLatitude = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional NullableTime waypoint_latest_created_at = 6;
 * @return {?proto.vtv1.NullableTime}
 */
proto.vtv1.ShipmentDetailsDriver.prototype.getWaypointLatestCreatedAt = function() {
  return /** @type{?proto.vtv1.NullableTime} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableTime, 6));
};


/**
 * @param {?proto.vtv1.NullableTime|undefined} value
 * @return {!proto.vtv1.ShipmentDetailsDriver} returns this
*/
proto.vtv1.ShipmentDetailsDriver.prototype.setWaypointLatestCreatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.ShipmentDetailsDriver} returns this
 */
proto.vtv1.ShipmentDetailsDriver.prototype.clearWaypointLatestCreatedAt = function() {
  return this.setWaypointLatestCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.ShipmentDetailsDriver.prototype.hasWaypointLatestCreatedAt = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional NullableString exception_message = 7;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.ShipmentDetailsDriver.prototype.getExceptionMessage = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableString, 7));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.ShipmentDetailsDriver} returns this
*/
proto.vtv1.ShipmentDetailsDriver.prototype.setExceptionMessage = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.ShipmentDetailsDriver} returns this
 */
proto.vtv1.ShipmentDetailsDriver.prototype.clearExceptionMessage = function() {
  return this.setExceptionMessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.ShipmentDetailsDriver.prototype.hasExceptionMessage = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional string trailer_type_id = 8;
 * @return {string}
 */
proto.vtv1.ShipmentDetailsDriver.prototype.getTrailerTypeId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.ShipmentDetailsDriver} returns this
 */
proto.vtv1.ShipmentDetailsDriver.prototype.setTrailerTypeId = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string trailer_type_name = 9;
 * @return {string}
 */
proto.vtv1.ShipmentDetailsDriver.prototype.getTrailerTypeName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.ShipmentDetailsDriver} returns this
 */
proto.vtv1.ShipmentDetailsDriver.prototype.setTrailerTypeName = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional bool vorto_ai_driver = 10;
 * @return {boolean}
 */
proto.vtv1.ShipmentDetailsDriver.prototype.getVortoAiDriver = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vtv1.ShipmentDetailsDriver} returns this
 */
proto.vtv1.ShipmentDetailsDriver.prototype.setVortoAiDriver = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.DriverForAssignmentShift.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.DriverForAssignmentShift.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.DriverForAssignmentShift} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.DriverForAssignmentShift.toObject = function(includeInstance, msg) {
  var f, obj = {
    shiftId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    startTime: jspb.Message.getFieldWithDefault(msg, 2, ""),
    endTime: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.DriverForAssignmentShift}
 */
proto.vtv1.DriverForAssignmentShift.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.DriverForAssignmentShift;
  return proto.vtv1.DriverForAssignmentShift.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.DriverForAssignmentShift} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.DriverForAssignmentShift}
 */
proto.vtv1.DriverForAssignmentShift.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setShiftId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setStartTime(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setEndTime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.DriverForAssignmentShift.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.DriverForAssignmentShift.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.DriverForAssignmentShift} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.DriverForAssignmentShift.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getShiftId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getStartTime();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getEndTime();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional int64 shift_id = 1;
 * @return {number}
 */
proto.vtv1.DriverForAssignmentShift.prototype.getShiftId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.DriverForAssignmentShift} returns this
 */
proto.vtv1.DriverForAssignmentShift.prototype.setShiftId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string start_time = 2;
 * @return {string}
 */
proto.vtv1.DriverForAssignmentShift.prototype.getStartTime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.DriverForAssignmentShift} returns this
 */
proto.vtv1.DriverForAssignmentShift.prototype.setStartTime = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string end_time = 3;
 * @return {string}
 */
proto.vtv1.DriverForAssignmentShift.prototype.getEndTime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.DriverForAssignmentShift} returns this
 */
proto.vtv1.DriverForAssignmentShift.prototype.setEndTime = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.ShipmentOrderID.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.ShipmentOrderID.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.ShipmentOrderID} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.ShipmentOrderID.toObject = function(includeInstance, msg) {
  var f, obj = {
    shipmentId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    orderId: (f = msg.getOrderId()) && proto_vorto_types_types_pb.NullableString.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.ShipmentOrderID}
 */
proto.vtv1.ShipmentOrderID.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.ShipmentOrderID;
  return proto.vtv1.ShipmentOrderID.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.ShipmentOrderID} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.ShipmentOrderID}
 */
proto.vtv1.ShipmentOrderID.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setShipmentId(value);
      break;
    case 2:
      var value = new proto_vorto_types_types_pb.NullableString;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableString.deserializeBinaryFromReader);
      msg.setOrderId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.ShipmentOrderID.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.ShipmentOrderID.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.ShipmentOrderID} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.ShipmentOrderID.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getShipmentId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOrderId();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_vorto_types_types_pb.NullableString.serializeBinaryToWriter
    );
  }
};


/**
 * optional string shipment_id = 1;
 * @return {string}
 */
proto.vtv1.ShipmentOrderID.prototype.getShipmentId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.ShipmentOrderID} returns this
 */
proto.vtv1.ShipmentOrderID.prototype.setShipmentId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional NullableString order_id = 2;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.ShipmentOrderID.prototype.getOrderId = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableString, 2));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.ShipmentOrderID} returns this
*/
proto.vtv1.ShipmentOrderID.prototype.setOrderId = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.ShipmentOrderID} returns this
 */
proto.vtv1.ShipmentOrderID.prototype.clearOrderId = function() {
  return this.setOrderId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.ShipmentOrderID.prototype.hasOrderId = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.AssignmentRepercussions.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.AssignmentRepercussions.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.AssignmentRepercussions.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.AssignmentRepercussions} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.AssignmentRepercussions.toObject = function(includeInstance, msg) {
  var f, obj = {
    shipmentOrdersToUnassignList: jspb.Message.toObjectList(msg.getShipmentOrdersToUnassignList(),
    proto.vtv1.ShipmentOrderID.toObject, includeInstance),
    lateShipmentOrdersList: jspb.Message.toObjectList(msg.getLateShipmentOrdersList(),
    proto.vtv1.AssignmentRepercussionsLateInfo.toObject, includeInstance),
    willCreateShift: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.AssignmentRepercussions}
 */
proto.vtv1.AssignmentRepercussions.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.AssignmentRepercussions;
  return proto.vtv1.AssignmentRepercussions.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.AssignmentRepercussions} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.AssignmentRepercussions}
 */
proto.vtv1.AssignmentRepercussions.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.vtv1.ShipmentOrderID;
      reader.readMessage(value,proto.vtv1.ShipmentOrderID.deserializeBinaryFromReader);
      msg.addShipmentOrdersToUnassign(value);
      break;
    case 2:
      var value = new proto.vtv1.AssignmentRepercussionsLateInfo;
      reader.readMessage(value,proto.vtv1.AssignmentRepercussionsLateInfo.deserializeBinaryFromReader);
      msg.addLateShipmentOrders(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setWillCreateShift(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.AssignmentRepercussions.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.AssignmentRepercussions.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.AssignmentRepercussions} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.AssignmentRepercussions.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getShipmentOrdersToUnassignList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.vtv1.ShipmentOrderID.serializeBinaryToWriter
    );
  }
  f = message.getLateShipmentOrdersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.vtv1.AssignmentRepercussionsLateInfo.serializeBinaryToWriter
    );
  }
  f = message.getWillCreateShift();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * repeated ShipmentOrderID shipment_orders_to_unassign = 1;
 * @return {!Array<!proto.vtv1.ShipmentOrderID>}
 */
proto.vtv1.AssignmentRepercussions.prototype.getShipmentOrdersToUnassignList = function() {
  return /** @type{!Array<!proto.vtv1.ShipmentOrderID>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vtv1.ShipmentOrderID, 1));
};


/**
 * @param {!Array<!proto.vtv1.ShipmentOrderID>} value
 * @return {!proto.vtv1.AssignmentRepercussions} returns this
*/
proto.vtv1.AssignmentRepercussions.prototype.setShipmentOrdersToUnassignList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.vtv1.ShipmentOrderID=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.ShipmentOrderID}
 */
proto.vtv1.AssignmentRepercussions.prototype.addShipmentOrdersToUnassign = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.vtv1.ShipmentOrderID, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.AssignmentRepercussions} returns this
 */
proto.vtv1.AssignmentRepercussions.prototype.clearShipmentOrdersToUnassignList = function() {
  return this.setShipmentOrdersToUnassignList([]);
};


/**
 * repeated AssignmentRepercussionsLateInfo late_shipment_orders = 2;
 * @return {!Array<!proto.vtv1.AssignmentRepercussionsLateInfo>}
 */
proto.vtv1.AssignmentRepercussions.prototype.getLateShipmentOrdersList = function() {
  return /** @type{!Array<!proto.vtv1.AssignmentRepercussionsLateInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vtv1.AssignmentRepercussionsLateInfo, 2));
};


/**
 * @param {!Array<!proto.vtv1.AssignmentRepercussionsLateInfo>} value
 * @return {!proto.vtv1.AssignmentRepercussions} returns this
*/
proto.vtv1.AssignmentRepercussions.prototype.setLateShipmentOrdersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.vtv1.AssignmentRepercussionsLateInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.AssignmentRepercussionsLateInfo}
 */
proto.vtv1.AssignmentRepercussions.prototype.addLateShipmentOrders = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.vtv1.AssignmentRepercussionsLateInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.AssignmentRepercussions} returns this
 */
proto.vtv1.AssignmentRepercussions.prototype.clearLateShipmentOrdersList = function() {
  return this.setLateShipmentOrdersList([]);
};


/**
 * optional bool will_create_shift = 3;
 * @return {boolean}
 */
proto.vtv1.AssignmentRepercussions.prototype.getWillCreateShift = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vtv1.AssignmentRepercussions} returns this
 */
proto.vtv1.AssignmentRepercussions.prototype.setWillCreateShift = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.AssignmentRepercussionsLateInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.AssignmentRepercussionsLateInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.AssignmentRepercussionsLateInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.AssignmentRepercussionsLateInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    shipmentOrder: (f = msg.getShipmentOrder()) && proto.vtv1.ShipmentOrderID.toObject(includeInstance, f),
    minutesLate: jspb.Message.getFieldWithDefault(msg, 2, 0),
    inPast: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.AssignmentRepercussionsLateInfo}
 */
proto.vtv1.AssignmentRepercussionsLateInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.AssignmentRepercussionsLateInfo;
  return proto.vtv1.AssignmentRepercussionsLateInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.AssignmentRepercussionsLateInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.AssignmentRepercussionsLateInfo}
 */
proto.vtv1.AssignmentRepercussionsLateInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.vtv1.ShipmentOrderID;
      reader.readMessage(value,proto.vtv1.ShipmentOrderID.deserializeBinaryFromReader);
      msg.setShipmentOrder(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMinutesLate(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setInPast(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.AssignmentRepercussionsLateInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.AssignmentRepercussionsLateInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.AssignmentRepercussionsLateInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.AssignmentRepercussionsLateInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getShipmentOrder();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.vtv1.ShipmentOrderID.serializeBinaryToWriter
    );
  }
  f = message.getMinutesLate();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getInPast();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional ShipmentOrderID shipment_order = 1;
 * @return {?proto.vtv1.ShipmentOrderID}
 */
proto.vtv1.AssignmentRepercussionsLateInfo.prototype.getShipmentOrder = function() {
  return /** @type{?proto.vtv1.ShipmentOrderID} */ (
    jspb.Message.getWrapperField(this, proto.vtv1.ShipmentOrderID, 1));
};


/**
 * @param {?proto.vtv1.ShipmentOrderID|undefined} value
 * @return {!proto.vtv1.AssignmentRepercussionsLateInfo} returns this
*/
proto.vtv1.AssignmentRepercussionsLateInfo.prototype.setShipmentOrder = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.AssignmentRepercussionsLateInfo} returns this
 */
proto.vtv1.AssignmentRepercussionsLateInfo.prototype.clearShipmentOrder = function() {
  return this.setShipmentOrder(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.AssignmentRepercussionsLateInfo.prototype.hasShipmentOrder = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int64 minutes_late = 2;
 * @return {number}
 */
proto.vtv1.AssignmentRepercussionsLateInfo.prototype.getMinutesLate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.AssignmentRepercussionsLateInfo} returns this
 */
proto.vtv1.AssignmentRepercussionsLateInfo.prototype.setMinutesLate = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional bool in_past = 3;
 * @return {boolean}
 */
proto.vtv1.AssignmentRepercussionsLateInfo.prototype.getInPast = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vtv1.AssignmentRepercussionsLateInfo} returns this
 */
proto.vtv1.AssignmentRepercussionsLateInfo.prototype.setInPast = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.DriverForAssignment.repeatedFields_ = [14];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.DriverForAssignment.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.DriverForAssignment.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.DriverForAssignment} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.DriverForAssignment.toObject = function(includeInstance, msg) {
  var f, obj = {
    userId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    phone: jspb.Message.getFieldWithDefault(msg, 2, ""),
    name: jspb.Message.getFieldWithDefault(msg, 3, ""),
    currentShipmentId: (f = msg.getCurrentShipmentId()) && proto_vorto_types_types_pb.NullableString.toObject(includeInstance, f),
    currentShipmentNodeName: (f = msg.getCurrentShipmentNodeName()) && proto_vorto_types_types_pb.NullableString.toObject(includeInstance, f),
    isCurrentShipmentMine: (f = msg.getIsCurrentShipmentMine()) && proto_vorto_types_types_pb.NullableBool.toObject(includeInstance, f),
    distanceToPickupSiteMiles: (f = msg.getDistanceToPickupSiteMiles()) && proto_vorto_types_types_pb.NullableDouble.toObject(includeInstance, f),
    longitude: (f = msg.getLongitude()) && proto_vorto_types_types_pb.NullableDouble.toObject(includeInstance, f),
    latitude: (f = msg.getLatitude()) && proto_vorto_types_types_pb.NullableDouble.toObject(includeInstance, f),
    waypointLatestCreatedAt: (f = msg.getWaypointLatestCreatedAt()) && proto_vorto_types_types_pb.NullableTime.toObject(includeInstance, f),
    status: jspb.Message.getFieldWithDefault(msg, 11, ""),
    exceptionMessage: (f = msg.getExceptionMessage()) && proto_vorto_types_types_pb.NullableString.toObject(includeInstance, f),
    prospectiveSchedule: (f = msg.getProspectiveSchedule()) && proto.vtv1.DriverSchedule.toObject(includeInstance, f),
    shiftsList: jspb.Message.toObjectList(msg.getShiftsList(),
    proto.vtv1.DriverForAssignmentShift.toObject, includeInstance),
    repercussions: (f = msg.getRepercussions()) && proto.vtv1.AssignmentRepercussions.toObject(includeInstance, f),
    driverGroupNotPermitted: jspb.Message.getBooleanFieldWithDefault(msg, 16, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.DriverForAssignment}
 */
proto.vtv1.DriverForAssignment.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.DriverForAssignment;
  return proto.vtv1.DriverForAssignment.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.DriverForAssignment} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.DriverForAssignment}
 */
proto.vtv1.DriverForAssignment.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhone(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 4:
      var value = new proto_vorto_types_types_pb.NullableString;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableString.deserializeBinaryFromReader);
      msg.setCurrentShipmentId(value);
      break;
    case 5:
      var value = new proto_vorto_types_types_pb.NullableString;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableString.deserializeBinaryFromReader);
      msg.setCurrentShipmentNodeName(value);
      break;
    case 6:
      var value = new proto_vorto_types_types_pb.NullableBool;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableBool.deserializeBinaryFromReader);
      msg.setIsCurrentShipmentMine(value);
      break;
    case 7:
      var value = new proto_vorto_types_types_pb.NullableDouble;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableDouble.deserializeBinaryFromReader);
      msg.setDistanceToPickupSiteMiles(value);
      break;
    case 8:
      var value = new proto_vorto_types_types_pb.NullableDouble;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableDouble.deserializeBinaryFromReader);
      msg.setLongitude(value);
      break;
    case 9:
      var value = new proto_vorto_types_types_pb.NullableDouble;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableDouble.deserializeBinaryFromReader);
      msg.setLatitude(value);
      break;
    case 10:
      var value = new proto_vorto_types_types_pb.NullableTime;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableTime.deserializeBinaryFromReader);
      msg.setWaypointLatestCreatedAt(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 12:
      var value = new proto_vorto_types_types_pb.NullableString;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableString.deserializeBinaryFromReader);
      msg.setExceptionMessage(value);
      break;
    case 13:
      var value = new proto.vtv1.DriverSchedule;
      reader.readMessage(value,proto.vtv1.DriverSchedule.deserializeBinaryFromReader);
      msg.setProspectiveSchedule(value);
      break;
    case 14:
      var value = new proto.vtv1.DriverForAssignmentShift;
      reader.readMessage(value,proto.vtv1.DriverForAssignmentShift.deserializeBinaryFromReader);
      msg.addShifts(value);
      break;
    case 15:
      var value = new proto.vtv1.AssignmentRepercussions;
      reader.readMessage(value,proto.vtv1.AssignmentRepercussions.deserializeBinaryFromReader);
      msg.setRepercussions(value);
      break;
    case 16:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDriverGroupNotPermitted(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.DriverForAssignment.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.DriverForAssignment.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.DriverForAssignment} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.DriverForAssignment.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPhone();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCurrentShipmentId();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto_vorto_types_types_pb.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getCurrentShipmentNodeName();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto_vorto_types_types_pb.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getIsCurrentShipmentMine();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto_vorto_types_types_pb.NullableBool.serializeBinaryToWriter
    );
  }
  f = message.getDistanceToPickupSiteMiles();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto_vorto_types_types_pb.NullableDouble.serializeBinaryToWriter
    );
  }
  f = message.getLongitude();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto_vorto_types_types_pb.NullableDouble.serializeBinaryToWriter
    );
  }
  f = message.getLatitude();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto_vorto_types_types_pb.NullableDouble.serializeBinaryToWriter
    );
  }
  f = message.getWaypointLatestCreatedAt();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto_vorto_types_types_pb.NullableTime.serializeBinaryToWriter
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getExceptionMessage();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      proto_vorto_types_types_pb.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getProspectiveSchedule();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      proto.vtv1.DriverSchedule.serializeBinaryToWriter
    );
  }
  f = message.getShiftsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      14,
      f,
      proto.vtv1.DriverForAssignmentShift.serializeBinaryToWriter
    );
  }
  f = message.getRepercussions();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      proto.vtv1.AssignmentRepercussions.serializeBinaryToWriter
    );
  }
  f = message.getDriverGroupNotPermitted();
  if (f) {
    writer.writeBool(
      16,
      f
    );
  }
};


/**
 * optional string user_id = 1;
 * @return {string}
 */
proto.vtv1.DriverForAssignment.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.DriverForAssignment} returns this
 */
proto.vtv1.DriverForAssignment.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string phone = 2;
 * @return {string}
 */
proto.vtv1.DriverForAssignment.prototype.getPhone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.DriverForAssignment} returns this
 */
proto.vtv1.DriverForAssignment.prototype.setPhone = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string name = 3;
 * @return {string}
 */
proto.vtv1.DriverForAssignment.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.DriverForAssignment} returns this
 */
proto.vtv1.DriverForAssignment.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional NullableString current_shipment_id = 4;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.DriverForAssignment.prototype.getCurrentShipmentId = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableString, 4));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.DriverForAssignment} returns this
*/
proto.vtv1.DriverForAssignment.prototype.setCurrentShipmentId = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.DriverForAssignment} returns this
 */
proto.vtv1.DriverForAssignment.prototype.clearCurrentShipmentId = function() {
  return this.setCurrentShipmentId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.DriverForAssignment.prototype.hasCurrentShipmentId = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional NullableString current_shipment_node_name = 5;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.DriverForAssignment.prototype.getCurrentShipmentNodeName = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableString, 5));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.DriverForAssignment} returns this
*/
proto.vtv1.DriverForAssignment.prototype.setCurrentShipmentNodeName = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.DriverForAssignment} returns this
 */
proto.vtv1.DriverForAssignment.prototype.clearCurrentShipmentNodeName = function() {
  return this.setCurrentShipmentNodeName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.DriverForAssignment.prototype.hasCurrentShipmentNodeName = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional NullableBool is_current_shipment_mine = 6;
 * @return {?proto.vtv1.NullableBool}
 */
proto.vtv1.DriverForAssignment.prototype.getIsCurrentShipmentMine = function() {
  return /** @type{?proto.vtv1.NullableBool} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableBool, 6));
};


/**
 * @param {?proto.vtv1.NullableBool|undefined} value
 * @return {!proto.vtv1.DriverForAssignment} returns this
*/
proto.vtv1.DriverForAssignment.prototype.setIsCurrentShipmentMine = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.DriverForAssignment} returns this
 */
proto.vtv1.DriverForAssignment.prototype.clearIsCurrentShipmentMine = function() {
  return this.setIsCurrentShipmentMine(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.DriverForAssignment.prototype.hasIsCurrentShipmentMine = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional NullableDouble distance_to_pickup_site_miles = 7;
 * @return {?proto.vtv1.NullableDouble}
 */
proto.vtv1.DriverForAssignment.prototype.getDistanceToPickupSiteMiles = function() {
  return /** @type{?proto.vtv1.NullableDouble} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableDouble, 7));
};


/**
 * @param {?proto.vtv1.NullableDouble|undefined} value
 * @return {!proto.vtv1.DriverForAssignment} returns this
*/
proto.vtv1.DriverForAssignment.prototype.setDistanceToPickupSiteMiles = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.DriverForAssignment} returns this
 */
proto.vtv1.DriverForAssignment.prototype.clearDistanceToPickupSiteMiles = function() {
  return this.setDistanceToPickupSiteMiles(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.DriverForAssignment.prototype.hasDistanceToPickupSiteMiles = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional NullableDouble longitude = 8;
 * @return {?proto.vtv1.NullableDouble}
 */
proto.vtv1.DriverForAssignment.prototype.getLongitude = function() {
  return /** @type{?proto.vtv1.NullableDouble} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableDouble, 8));
};


/**
 * @param {?proto.vtv1.NullableDouble|undefined} value
 * @return {!proto.vtv1.DriverForAssignment} returns this
*/
proto.vtv1.DriverForAssignment.prototype.setLongitude = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.DriverForAssignment} returns this
 */
proto.vtv1.DriverForAssignment.prototype.clearLongitude = function() {
  return this.setLongitude(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.DriverForAssignment.prototype.hasLongitude = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional NullableDouble latitude = 9;
 * @return {?proto.vtv1.NullableDouble}
 */
proto.vtv1.DriverForAssignment.prototype.getLatitude = function() {
  return /** @type{?proto.vtv1.NullableDouble} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableDouble, 9));
};


/**
 * @param {?proto.vtv1.NullableDouble|undefined} value
 * @return {!proto.vtv1.DriverForAssignment} returns this
*/
proto.vtv1.DriverForAssignment.prototype.setLatitude = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.DriverForAssignment} returns this
 */
proto.vtv1.DriverForAssignment.prototype.clearLatitude = function() {
  return this.setLatitude(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.DriverForAssignment.prototype.hasLatitude = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional NullableTime waypoint_latest_created_at = 10;
 * @return {?proto.vtv1.NullableTime}
 */
proto.vtv1.DriverForAssignment.prototype.getWaypointLatestCreatedAt = function() {
  return /** @type{?proto.vtv1.NullableTime} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableTime, 10));
};


/**
 * @param {?proto.vtv1.NullableTime|undefined} value
 * @return {!proto.vtv1.DriverForAssignment} returns this
*/
proto.vtv1.DriverForAssignment.prototype.setWaypointLatestCreatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.DriverForAssignment} returns this
 */
proto.vtv1.DriverForAssignment.prototype.clearWaypointLatestCreatedAt = function() {
  return this.setWaypointLatestCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.DriverForAssignment.prototype.hasWaypointLatestCreatedAt = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional string status = 11;
 * @return {string}
 */
proto.vtv1.DriverForAssignment.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.DriverForAssignment} returns this
 */
proto.vtv1.DriverForAssignment.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional NullableString exception_message = 12;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.DriverForAssignment.prototype.getExceptionMessage = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableString, 12));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.DriverForAssignment} returns this
*/
proto.vtv1.DriverForAssignment.prototype.setExceptionMessage = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.DriverForAssignment} returns this
 */
proto.vtv1.DriverForAssignment.prototype.clearExceptionMessage = function() {
  return this.setExceptionMessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.DriverForAssignment.prototype.hasExceptionMessage = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional DriverSchedule prospective_schedule = 13;
 * @return {?proto.vtv1.DriverSchedule}
 */
proto.vtv1.DriverForAssignment.prototype.getProspectiveSchedule = function() {
  return /** @type{?proto.vtv1.DriverSchedule} */ (
    jspb.Message.getWrapperField(this, proto.vtv1.DriverSchedule, 13));
};


/**
 * @param {?proto.vtv1.DriverSchedule|undefined} value
 * @return {!proto.vtv1.DriverForAssignment} returns this
*/
proto.vtv1.DriverForAssignment.prototype.setProspectiveSchedule = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.DriverForAssignment} returns this
 */
proto.vtv1.DriverForAssignment.prototype.clearProspectiveSchedule = function() {
  return this.setProspectiveSchedule(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.DriverForAssignment.prototype.hasProspectiveSchedule = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * repeated DriverForAssignmentShift shifts = 14;
 * @return {!Array<!proto.vtv1.DriverForAssignmentShift>}
 */
proto.vtv1.DriverForAssignment.prototype.getShiftsList = function() {
  return /** @type{!Array<!proto.vtv1.DriverForAssignmentShift>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vtv1.DriverForAssignmentShift, 14));
};


/**
 * @param {!Array<!proto.vtv1.DriverForAssignmentShift>} value
 * @return {!proto.vtv1.DriverForAssignment} returns this
*/
proto.vtv1.DriverForAssignment.prototype.setShiftsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 14, value);
};


/**
 * @param {!proto.vtv1.DriverForAssignmentShift=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.DriverForAssignmentShift}
 */
proto.vtv1.DriverForAssignment.prototype.addShifts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 14, opt_value, proto.vtv1.DriverForAssignmentShift, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.DriverForAssignment} returns this
 */
proto.vtv1.DriverForAssignment.prototype.clearShiftsList = function() {
  return this.setShiftsList([]);
};


/**
 * optional AssignmentRepercussions repercussions = 15;
 * @return {?proto.vtv1.AssignmentRepercussions}
 */
proto.vtv1.DriverForAssignment.prototype.getRepercussions = function() {
  return /** @type{?proto.vtv1.AssignmentRepercussions} */ (
    jspb.Message.getWrapperField(this, proto.vtv1.AssignmentRepercussions, 15));
};


/**
 * @param {?proto.vtv1.AssignmentRepercussions|undefined} value
 * @return {!proto.vtv1.DriverForAssignment} returns this
*/
proto.vtv1.DriverForAssignment.prototype.setRepercussions = function(value) {
  return jspb.Message.setWrapperField(this, 15, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.DriverForAssignment} returns this
 */
proto.vtv1.DriverForAssignment.prototype.clearRepercussions = function() {
  return this.setRepercussions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.DriverForAssignment.prototype.hasRepercussions = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional bool driver_group_not_permitted = 16;
 * @return {boolean}
 */
proto.vtv1.DriverForAssignment.prototype.getDriverGroupNotPermitted = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 16, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vtv1.DriverForAssignment} returns this
 */
proto.vtv1.DriverForAssignment.prototype.setDriverGroupNotPermitted = function(value) {
  return jspb.Message.setProto3BooleanField(this, 16, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.PendingShipmentSummary.repeatedFields_ = [21,24,25];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.PendingShipmentSummary.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.PendingShipmentSummary.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.PendingShipmentSummary} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.PendingShipmentSummary.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    originId: (f = msg.getOriginId()) && proto_vorto_types_types_pb.NullableString.toObject(includeInstance, f),
    originName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    destinationId: (f = msg.getDestinationId()) && proto_vorto_types_types_pb.NullableString.toObject(includeInstance, f),
    destinationName: jspb.Message.getFieldWithDefault(msg, 5, ""),
    nodeId: jspb.Message.getFieldWithDefault(msg, 6, ""),
    nodeName: jspb.Message.getFieldWithDefault(msg, 7, ""),
    productId: jspb.Message.getFieldWithDefault(msg, 8, ""),
    productName: jspb.Message.getFieldWithDefault(msg, 9, ""),
    unitId: jspb.Message.getFieldWithDefault(msg, 10, 0),
    unitName: jspb.Message.getFieldWithDefault(msg, 11, ""),
    unitAbbreviation: jspb.Message.getFieldWithDefault(msg, 12, ""),
    quantity: jspb.Message.getFloatingPointFieldWithDefault(msg, 13, 0.0),
    receivedAt: jspb.Message.getFieldWithDefault(msg, 14, ""),
    arrivalWindowStartsAt: (f = msg.getArrivalWindowStartsAt()) && proto_vorto_types_types_pb.NullableTime.toObject(includeInstance, f),
    orderId: (f = msg.getOrderId()) && proto_vorto_types_types_pb.NullableString.toObject(includeInstance, f),
    updatedAt: jspb.Message.getFieldWithDefault(msg, 17, ""),
    trailerTypeId: (f = msg.getTrailerTypeId()) && proto_vorto_types_types_pb.NullableString.toObject(includeInstance, f),
    trailerTypeName: (f = msg.getTrailerTypeName()) && proto_vorto_types_types_pb.NullableString.toObject(includeInstance, f),
    arrivalWindowMinutes: (f = msg.getArrivalWindowMinutes()) && proto_vorto_types_types_pb.NullableInt64.toObject(includeInstance, f),
    trailerNamesList: (f = jspb.Message.getRepeatedField(msg, 21)) == null ? undefined : f,
    ufgStatus: jspb.Message.getFieldWithDefault(msg, 22, 0),
    hasBackhaul: jspb.Message.getBooleanFieldWithDefault(msg, 23, false),
    productNamesList: (f = jspb.Message.getRepeatedField(msg, 24)) == null ? undefined : f,
    assetNamesList: (f = jspb.Message.getRepeatedField(msg, 25)) == null ? undefined : f,
    equipmentOrderId: (f = msg.getEquipmentOrderId()) && proto_vorto_types_types_pb.NullableString.toObject(includeInstance, f),
    equipmentOrderPriority: (f = msg.getEquipmentOrderPriority()) && proto_vorto_types_types_pb.NullableInt64.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.PendingShipmentSummary}
 */
proto.vtv1.PendingShipmentSummary.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.PendingShipmentSummary;
  return proto.vtv1.PendingShipmentSummary.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.PendingShipmentSummary} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.PendingShipmentSummary}
 */
proto.vtv1.PendingShipmentSummary.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new proto_vorto_types_types_pb.NullableString;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableString.deserializeBinaryFromReader);
      msg.setOriginId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setOriginName(value);
      break;
    case 4:
      var value = new proto_vorto_types_types_pb.NullableString;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableString.deserializeBinaryFromReader);
      msg.setDestinationId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setDestinationName(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setNodeId(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setNodeName(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductId(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductName(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUnitId(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setUnitName(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setUnitAbbreviation(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setQuantity(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setReceivedAt(value);
      break;
    case 15:
      var value = new proto_vorto_types_types_pb.NullableTime;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableTime.deserializeBinaryFromReader);
      msg.setArrivalWindowStartsAt(value);
      break;
    case 16:
      var value = new proto_vorto_types_types_pb.NullableString;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableString.deserializeBinaryFromReader);
      msg.setOrderId(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setUpdatedAt(value);
      break;
    case 18:
      var value = new proto_vorto_types_types_pb.NullableString;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableString.deserializeBinaryFromReader);
      msg.setTrailerTypeId(value);
      break;
    case 19:
      var value = new proto_vorto_types_types_pb.NullableString;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableString.deserializeBinaryFromReader);
      msg.setTrailerTypeName(value);
      break;
    case 20:
      var value = new proto_vorto_types_types_pb.NullableInt64;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableInt64.deserializeBinaryFromReader);
      msg.setArrivalWindowMinutes(value);
      break;
    case 21:
      var value = /** @type {string} */ (reader.readString());
      msg.addTrailerNames(value);
      break;
    case 22:
      var value = /** @type {!proto.vtv1.UFGStatus} */ (reader.readEnum());
      msg.setUfgStatus(value);
      break;
    case 23:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasBackhaul(value);
      break;
    case 24:
      var value = /** @type {string} */ (reader.readString());
      msg.addProductNames(value);
      break;
    case 25:
      var value = /** @type {string} */ (reader.readString());
      msg.addAssetNames(value);
      break;
    case 26:
      var value = new proto_vorto_types_types_pb.NullableString;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableString.deserializeBinaryFromReader);
      msg.setEquipmentOrderId(value);
      break;
    case 27:
      var value = new proto_vorto_types_types_pb.NullableInt64;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableInt64.deserializeBinaryFromReader);
      msg.setEquipmentOrderPriority(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.PendingShipmentSummary.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.PendingShipmentSummary.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.PendingShipmentSummary} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.PendingShipmentSummary.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOriginId();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_vorto_types_types_pb.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getOriginName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDestinationId();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto_vorto_types_types_pb.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getDestinationName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getNodeId();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getNodeName();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getProductId();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getProductName();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getUnitId();
  if (f !== 0) {
    writer.writeInt64(
      10,
      f
    );
  }
  f = message.getUnitName();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getUnitAbbreviation();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getQuantity();
  if (f !== 0.0) {
    writer.writeDouble(
      13,
      f
    );
  }
  f = message.getReceivedAt();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getArrivalWindowStartsAt();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      proto_vorto_types_types_pb.NullableTime.serializeBinaryToWriter
    );
  }
  f = message.getOrderId();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      proto_vorto_types_types_pb.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getUpdatedAt();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getTrailerTypeId();
  if (f != null) {
    writer.writeMessage(
      18,
      f,
      proto_vorto_types_types_pb.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getTrailerTypeName();
  if (f != null) {
    writer.writeMessage(
      19,
      f,
      proto_vorto_types_types_pb.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getArrivalWindowMinutes();
  if (f != null) {
    writer.writeMessage(
      20,
      f,
      proto_vorto_types_types_pb.NullableInt64.serializeBinaryToWriter
    );
  }
  f = message.getTrailerNamesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      21,
      f
    );
  }
  f = message.getUfgStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      22,
      f
    );
  }
  f = message.getHasBackhaul();
  if (f) {
    writer.writeBool(
      23,
      f
    );
  }
  f = message.getProductNamesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      24,
      f
    );
  }
  f = message.getAssetNamesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      25,
      f
    );
  }
  f = message.getEquipmentOrderId();
  if (f != null) {
    writer.writeMessage(
      26,
      f,
      proto_vorto_types_types_pb.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getEquipmentOrderPriority();
  if (f != null) {
    writer.writeMessage(
      27,
      f,
      proto_vorto_types_types_pb.NullableInt64.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.vtv1.PendingShipmentSummary.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.PendingShipmentSummary} returns this
 */
proto.vtv1.PendingShipmentSummary.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional NullableString origin_id = 2;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.PendingShipmentSummary.prototype.getOriginId = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableString, 2));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.PendingShipmentSummary} returns this
*/
proto.vtv1.PendingShipmentSummary.prototype.setOriginId = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.PendingShipmentSummary} returns this
 */
proto.vtv1.PendingShipmentSummary.prototype.clearOriginId = function() {
  return this.setOriginId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.PendingShipmentSummary.prototype.hasOriginId = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string origin_name = 3;
 * @return {string}
 */
proto.vtv1.PendingShipmentSummary.prototype.getOriginName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.PendingShipmentSummary} returns this
 */
proto.vtv1.PendingShipmentSummary.prototype.setOriginName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional NullableString destination_id = 4;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.PendingShipmentSummary.prototype.getDestinationId = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableString, 4));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.PendingShipmentSummary} returns this
*/
proto.vtv1.PendingShipmentSummary.prototype.setDestinationId = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.PendingShipmentSummary} returns this
 */
proto.vtv1.PendingShipmentSummary.prototype.clearDestinationId = function() {
  return this.setDestinationId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.PendingShipmentSummary.prototype.hasDestinationId = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string destination_name = 5;
 * @return {string}
 */
proto.vtv1.PendingShipmentSummary.prototype.getDestinationName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.PendingShipmentSummary} returns this
 */
proto.vtv1.PendingShipmentSummary.prototype.setDestinationName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string node_id = 6;
 * @return {string}
 */
proto.vtv1.PendingShipmentSummary.prototype.getNodeId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.PendingShipmentSummary} returns this
 */
proto.vtv1.PendingShipmentSummary.prototype.setNodeId = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string node_name = 7;
 * @return {string}
 */
proto.vtv1.PendingShipmentSummary.prototype.getNodeName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.PendingShipmentSummary} returns this
 */
proto.vtv1.PendingShipmentSummary.prototype.setNodeName = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string product_id = 8;
 * @return {string}
 */
proto.vtv1.PendingShipmentSummary.prototype.getProductId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.PendingShipmentSummary} returns this
 */
proto.vtv1.PendingShipmentSummary.prototype.setProductId = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string product_name = 9;
 * @return {string}
 */
proto.vtv1.PendingShipmentSummary.prototype.getProductName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.PendingShipmentSummary} returns this
 */
proto.vtv1.PendingShipmentSummary.prototype.setProductName = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional int64 unit_id = 10;
 * @return {number}
 */
proto.vtv1.PendingShipmentSummary.prototype.getUnitId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.PendingShipmentSummary} returns this
 */
proto.vtv1.PendingShipmentSummary.prototype.setUnitId = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional string unit_name = 11;
 * @return {string}
 */
proto.vtv1.PendingShipmentSummary.prototype.getUnitName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.PendingShipmentSummary} returns this
 */
proto.vtv1.PendingShipmentSummary.prototype.setUnitName = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string unit_abbreviation = 12;
 * @return {string}
 */
proto.vtv1.PendingShipmentSummary.prototype.getUnitAbbreviation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.PendingShipmentSummary} returns this
 */
proto.vtv1.PendingShipmentSummary.prototype.setUnitAbbreviation = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional double quantity = 13;
 * @return {number}
 */
proto.vtv1.PendingShipmentSummary.prototype.getQuantity = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 13, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.PendingShipmentSummary} returns this
 */
proto.vtv1.PendingShipmentSummary.prototype.setQuantity = function(value) {
  return jspb.Message.setProto3FloatField(this, 13, value);
};


/**
 * optional string received_at = 14;
 * @return {string}
 */
proto.vtv1.PendingShipmentSummary.prototype.getReceivedAt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.PendingShipmentSummary} returns this
 */
proto.vtv1.PendingShipmentSummary.prototype.setReceivedAt = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional NullableTime arrival_window_starts_at = 15;
 * @return {?proto.vtv1.NullableTime}
 */
proto.vtv1.PendingShipmentSummary.prototype.getArrivalWindowStartsAt = function() {
  return /** @type{?proto.vtv1.NullableTime} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableTime, 15));
};


/**
 * @param {?proto.vtv1.NullableTime|undefined} value
 * @return {!proto.vtv1.PendingShipmentSummary} returns this
*/
proto.vtv1.PendingShipmentSummary.prototype.setArrivalWindowStartsAt = function(value) {
  return jspb.Message.setWrapperField(this, 15, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.PendingShipmentSummary} returns this
 */
proto.vtv1.PendingShipmentSummary.prototype.clearArrivalWindowStartsAt = function() {
  return this.setArrivalWindowStartsAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.PendingShipmentSummary.prototype.hasArrivalWindowStartsAt = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional NullableString order_id = 16;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.PendingShipmentSummary.prototype.getOrderId = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableString, 16));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.PendingShipmentSummary} returns this
*/
proto.vtv1.PendingShipmentSummary.prototype.setOrderId = function(value) {
  return jspb.Message.setWrapperField(this, 16, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.PendingShipmentSummary} returns this
 */
proto.vtv1.PendingShipmentSummary.prototype.clearOrderId = function() {
  return this.setOrderId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.PendingShipmentSummary.prototype.hasOrderId = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * optional string updated_at = 17;
 * @return {string}
 */
proto.vtv1.PendingShipmentSummary.prototype.getUpdatedAt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.PendingShipmentSummary} returns this
 */
proto.vtv1.PendingShipmentSummary.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional NullableString trailer_type_id = 18;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.PendingShipmentSummary.prototype.getTrailerTypeId = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableString, 18));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.PendingShipmentSummary} returns this
*/
proto.vtv1.PendingShipmentSummary.prototype.setTrailerTypeId = function(value) {
  return jspb.Message.setWrapperField(this, 18, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.PendingShipmentSummary} returns this
 */
proto.vtv1.PendingShipmentSummary.prototype.clearTrailerTypeId = function() {
  return this.setTrailerTypeId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.PendingShipmentSummary.prototype.hasTrailerTypeId = function() {
  return jspb.Message.getField(this, 18) != null;
};


/**
 * optional NullableString trailer_type_name = 19;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.PendingShipmentSummary.prototype.getTrailerTypeName = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableString, 19));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.PendingShipmentSummary} returns this
*/
proto.vtv1.PendingShipmentSummary.prototype.setTrailerTypeName = function(value) {
  return jspb.Message.setWrapperField(this, 19, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.PendingShipmentSummary} returns this
 */
proto.vtv1.PendingShipmentSummary.prototype.clearTrailerTypeName = function() {
  return this.setTrailerTypeName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.PendingShipmentSummary.prototype.hasTrailerTypeName = function() {
  return jspb.Message.getField(this, 19) != null;
};


/**
 * optional NullableInt64 arrival_window_minutes = 20;
 * @return {?proto.vtv1.NullableInt64}
 */
proto.vtv1.PendingShipmentSummary.prototype.getArrivalWindowMinutes = function() {
  return /** @type{?proto.vtv1.NullableInt64} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableInt64, 20));
};


/**
 * @param {?proto.vtv1.NullableInt64|undefined} value
 * @return {!proto.vtv1.PendingShipmentSummary} returns this
*/
proto.vtv1.PendingShipmentSummary.prototype.setArrivalWindowMinutes = function(value) {
  return jspb.Message.setWrapperField(this, 20, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.PendingShipmentSummary} returns this
 */
proto.vtv1.PendingShipmentSummary.prototype.clearArrivalWindowMinutes = function() {
  return this.setArrivalWindowMinutes(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.PendingShipmentSummary.prototype.hasArrivalWindowMinutes = function() {
  return jspb.Message.getField(this, 20) != null;
};


/**
 * repeated string trailer_names = 21;
 * @return {!Array<string>}
 */
proto.vtv1.PendingShipmentSummary.prototype.getTrailerNamesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 21));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.vtv1.PendingShipmentSummary} returns this
 */
proto.vtv1.PendingShipmentSummary.prototype.setTrailerNamesList = function(value) {
  return jspb.Message.setField(this, 21, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.vtv1.PendingShipmentSummary} returns this
 */
proto.vtv1.PendingShipmentSummary.prototype.addTrailerNames = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 21, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.PendingShipmentSummary} returns this
 */
proto.vtv1.PendingShipmentSummary.prototype.clearTrailerNamesList = function() {
  return this.setTrailerNamesList([]);
};


/**
 * optional UFGStatus ufg_status = 22;
 * @return {!proto.vtv1.UFGStatus}
 */
proto.vtv1.PendingShipmentSummary.prototype.getUfgStatus = function() {
  return /** @type {!proto.vtv1.UFGStatus} */ (jspb.Message.getFieldWithDefault(this, 22, 0));
};


/**
 * @param {!proto.vtv1.UFGStatus} value
 * @return {!proto.vtv1.PendingShipmentSummary} returns this
 */
proto.vtv1.PendingShipmentSummary.prototype.setUfgStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 22, value);
};


/**
 * optional bool has_backhaul = 23;
 * @return {boolean}
 */
proto.vtv1.PendingShipmentSummary.prototype.getHasBackhaul = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 23, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vtv1.PendingShipmentSummary} returns this
 */
proto.vtv1.PendingShipmentSummary.prototype.setHasBackhaul = function(value) {
  return jspb.Message.setProto3BooleanField(this, 23, value);
};


/**
 * repeated string product_names = 24;
 * @return {!Array<string>}
 */
proto.vtv1.PendingShipmentSummary.prototype.getProductNamesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 24));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.vtv1.PendingShipmentSummary} returns this
 */
proto.vtv1.PendingShipmentSummary.prototype.setProductNamesList = function(value) {
  return jspb.Message.setField(this, 24, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.vtv1.PendingShipmentSummary} returns this
 */
proto.vtv1.PendingShipmentSummary.prototype.addProductNames = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 24, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.PendingShipmentSummary} returns this
 */
proto.vtv1.PendingShipmentSummary.prototype.clearProductNamesList = function() {
  return this.setProductNamesList([]);
};


/**
 * repeated string asset_names = 25;
 * @return {!Array<string>}
 */
proto.vtv1.PendingShipmentSummary.prototype.getAssetNamesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 25));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.vtv1.PendingShipmentSummary} returns this
 */
proto.vtv1.PendingShipmentSummary.prototype.setAssetNamesList = function(value) {
  return jspb.Message.setField(this, 25, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.vtv1.PendingShipmentSummary} returns this
 */
proto.vtv1.PendingShipmentSummary.prototype.addAssetNames = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 25, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.PendingShipmentSummary} returns this
 */
proto.vtv1.PendingShipmentSummary.prototype.clearAssetNamesList = function() {
  return this.setAssetNamesList([]);
};


/**
 * optional NullableString equipment_order_id = 26;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.PendingShipmentSummary.prototype.getEquipmentOrderId = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableString, 26));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.PendingShipmentSummary} returns this
*/
proto.vtv1.PendingShipmentSummary.prototype.setEquipmentOrderId = function(value) {
  return jspb.Message.setWrapperField(this, 26, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.PendingShipmentSummary} returns this
 */
proto.vtv1.PendingShipmentSummary.prototype.clearEquipmentOrderId = function() {
  return this.setEquipmentOrderId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.PendingShipmentSummary.prototype.hasEquipmentOrderId = function() {
  return jspb.Message.getField(this, 26) != null;
};


/**
 * optional NullableInt64 equipment_order_priority = 27;
 * @return {?proto.vtv1.NullableInt64}
 */
proto.vtv1.PendingShipmentSummary.prototype.getEquipmentOrderPriority = function() {
  return /** @type{?proto.vtv1.NullableInt64} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableInt64, 27));
};


/**
 * @param {?proto.vtv1.NullableInt64|undefined} value
 * @return {!proto.vtv1.PendingShipmentSummary} returns this
*/
proto.vtv1.PendingShipmentSummary.prototype.setEquipmentOrderPriority = function(value) {
  return jspb.Message.setWrapperField(this, 27, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.PendingShipmentSummary} returns this
 */
proto.vtv1.PendingShipmentSummary.prototype.clearEquipmentOrderPriority = function() {
  return this.setEquipmentOrderPriority(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.PendingShipmentSummary.prototype.hasEquipmentOrderPriority = function() {
  return jspb.Message.getField(this, 27) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.PendingShipmentSummaryForDriverAssignment.repeatedFields_ = [22,24];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.PendingShipmentSummaryForDriverAssignment.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.PendingShipmentSummaryForDriverAssignment.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.PendingShipmentSummaryForDriverAssignment} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.PendingShipmentSummaryForDriverAssignment.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    originId: (f = msg.getOriginId()) && proto_vorto_types_types_pb.NullableString.toObject(includeInstance, f),
    originName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    destinationId: (f = msg.getDestinationId()) && proto_vorto_types_types_pb.NullableString.toObject(includeInstance, f),
    destinationName: jspb.Message.getFieldWithDefault(msg, 5, ""),
    nodeId: jspb.Message.getFieldWithDefault(msg, 6, ""),
    nodeName: jspb.Message.getFieldWithDefault(msg, 7, ""),
    productId: jspb.Message.getFieldWithDefault(msg, 8, ""),
    productName: jspb.Message.getFieldWithDefault(msg, 9, ""),
    unitId: jspb.Message.getFieldWithDefault(msg, 10, 0),
    unitName: jspb.Message.getFieldWithDefault(msg, 11, ""),
    unitAbbreviation: jspb.Message.getFieldWithDefault(msg, 12, ""),
    quantity: jspb.Message.getFloatingPointFieldWithDefault(msg, 13, 0.0),
    receivedAt: jspb.Message.getFieldWithDefault(msg, 14, ""),
    arrivalWindowStartsAt: (f = msg.getArrivalWindowStartsAt()) && proto_vorto_types_types_pb.NullableTime.toObject(includeInstance, f),
    orderId: (f = msg.getOrderId()) && proto_vorto_types_types_pb.NullableString.toObject(includeInstance, f),
    updatedAt: jspb.Message.getFieldWithDefault(msg, 17, ""),
    trailerTypeId: (f = msg.getTrailerTypeId()) && proto_vorto_types_types_pb.NullableString.toObject(includeInstance, f),
    trailerTypeName: (f = msg.getTrailerTypeName()) && proto_vorto_types_types_pb.NullableString.toObject(includeInstance, f),
    arrivalWindowMinutes: (f = msg.getArrivalWindowMinutes()) && proto_vorto_types_types_pb.NullableInt64.toObject(includeInstance, f),
    repercussions: (f = msg.getRepercussions()) && proto.vtv1.AssignmentRepercussions.toObject(includeInstance, f),
    trailerNamesList: (f = jspb.Message.getRepeatedField(msg, 22)) == null ? undefined : f,
    ufgStatus: jspb.Message.getFieldWithDefault(msg, 23, 0),
    assetNamesList: (f = jspb.Message.getRepeatedField(msg, 24)) == null ? undefined : f,
    equipmentOrderId: (f = msg.getEquipmentOrderId()) && proto_vorto_types_types_pb.NullableString.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.PendingShipmentSummaryForDriverAssignment}
 */
proto.vtv1.PendingShipmentSummaryForDriverAssignment.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.PendingShipmentSummaryForDriverAssignment;
  return proto.vtv1.PendingShipmentSummaryForDriverAssignment.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.PendingShipmentSummaryForDriverAssignment} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.PendingShipmentSummaryForDriverAssignment}
 */
proto.vtv1.PendingShipmentSummaryForDriverAssignment.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new proto_vorto_types_types_pb.NullableString;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableString.deserializeBinaryFromReader);
      msg.setOriginId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setOriginName(value);
      break;
    case 4:
      var value = new proto_vorto_types_types_pb.NullableString;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableString.deserializeBinaryFromReader);
      msg.setDestinationId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setDestinationName(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setNodeId(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setNodeName(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductId(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductName(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUnitId(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setUnitName(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setUnitAbbreviation(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setQuantity(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setReceivedAt(value);
      break;
    case 15:
      var value = new proto_vorto_types_types_pb.NullableTime;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableTime.deserializeBinaryFromReader);
      msg.setArrivalWindowStartsAt(value);
      break;
    case 16:
      var value = new proto_vorto_types_types_pb.NullableString;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableString.deserializeBinaryFromReader);
      msg.setOrderId(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setUpdatedAt(value);
      break;
    case 18:
      var value = new proto_vorto_types_types_pb.NullableString;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableString.deserializeBinaryFromReader);
      msg.setTrailerTypeId(value);
      break;
    case 19:
      var value = new proto_vorto_types_types_pb.NullableString;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableString.deserializeBinaryFromReader);
      msg.setTrailerTypeName(value);
      break;
    case 20:
      var value = new proto_vorto_types_types_pb.NullableInt64;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableInt64.deserializeBinaryFromReader);
      msg.setArrivalWindowMinutes(value);
      break;
    case 21:
      var value = new proto.vtv1.AssignmentRepercussions;
      reader.readMessage(value,proto.vtv1.AssignmentRepercussions.deserializeBinaryFromReader);
      msg.setRepercussions(value);
      break;
    case 22:
      var value = /** @type {string} */ (reader.readString());
      msg.addTrailerNames(value);
      break;
    case 23:
      var value = /** @type {!proto.vtv1.UFGStatus} */ (reader.readEnum());
      msg.setUfgStatus(value);
      break;
    case 24:
      var value = /** @type {string} */ (reader.readString());
      msg.addAssetNames(value);
      break;
    case 25:
      var value = new proto_vorto_types_types_pb.NullableString;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableString.deserializeBinaryFromReader);
      msg.setEquipmentOrderId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.PendingShipmentSummaryForDriverAssignment.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.PendingShipmentSummaryForDriverAssignment.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.PendingShipmentSummaryForDriverAssignment} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.PendingShipmentSummaryForDriverAssignment.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOriginId();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_vorto_types_types_pb.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getOriginName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDestinationId();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto_vorto_types_types_pb.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getDestinationName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getNodeId();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getNodeName();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getProductId();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getProductName();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getUnitId();
  if (f !== 0) {
    writer.writeInt64(
      10,
      f
    );
  }
  f = message.getUnitName();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getUnitAbbreviation();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getQuantity();
  if (f !== 0.0) {
    writer.writeDouble(
      13,
      f
    );
  }
  f = message.getReceivedAt();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getArrivalWindowStartsAt();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      proto_vorto_types_types_pb.NullableTime.serializeBinaryToWriter
    );
  }
  f = message.getOrderId();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      proto_vorto_types_types_pb.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getUpdatedAt();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getTrailerTypeId();
  if (f != null) {
    writer.writeMessage(
      18,
      f,
      proto_vorto_types_types_pb.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getTrailerTypeName();
  if (f != null) {
    writer.writeMessage(
      19,
      f,
      proto_vorto_types_types_pb.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getArrivalWindowMinutes();
  if (f != null) {
    writer.writeMessage(
      20,
      f,
      proto_vorto_types_types_pb.NullableInt64.serializeBinaryToWriter
    );
  }
  f = message.getRepercussions();
  if (f != null) {
    writer.writeMessage(
      21,
      f,
      proto.vtv1.AssignmentRepercussions.serializeBinaryToWriter
    );
  }
  f = message.getTrailerNamesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      22,
      f
    );
  }
  f = message.getUfgStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      23,
      f
    );
  }
  f = message.getAssetNamesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      24,
      f
    );
  }
  f = message.getEquipmentOrderId();
  if (f != null) {
    writer.writeMessage(
      25,
      f,
      proto_vorto_types_types_pb.NullableString.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.vtv1.PendingShipmentSummaryForDriverAssignment.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.PendingShipmentSummaryForDriverAssignment} returns this
 */
proto.vtv1.PendingShipmentSummaryForDriverAssignment.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional NullableString origin_id = 2;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.PendingShipmentSummaryForDriverAssignment.prototype.getOriginId = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableString, 2));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.PendingShipmentSummaryForDriverAssignment} returns this
*/
proto.vtv1.PendingShipmentSummaryForDriverAssignment.prototype.setOriginId = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.PendingShipmentSummaryForDriverAssignment} returns this
 */
proto.vtv1.PendingShipmentSummaryForDriverAssignment.prototype.clearOriginId = function() {
  return this.setOriginId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.PendingShipmentSummaryForDriverAssignment.prototype.hasOriginId = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string origin_name = 3;
 * @return {string}
 */
proto.vtv1.PendingShipmentSummaryForDriverAssignment.prototype.getOriginName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.PendingShipmentSummaryForDriverAssignment} returns this
 */
proto.vtv1.PendingShipmentSummaryForDriverAssignment.prototype.setOriginName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional NullableString destination_id = 4;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.PendingShipmentSummaryForDriverAssignment.prototype.getDestinationId = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableString, 4));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.PendingShipmentSummaryForDriverAssignment} returns this
*/
proto.vtv1.PendingShipmentSummaryForDriverAssignment.prototype.setDestinationId = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.PendingShipmentSummaryForDriverAssignment} returns this
 */
proto.vtv1.PendingShipmentSummaryForDriverAssignment.prototype.clearDestinationId = function() {
  return this.setDestinationId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.PendingShipmentSummaryForDriverAssignment.prototype.hasDestinationId = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string destination_name = 5;
 * @return {string}
 */
proto.vtv1.PendingShipmentSummaryForDriverAssignment.prototype.getDestinationName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.PendingShipmentSummaryForDriverAssignment} returns this
 */
proto.vtv1.PendingShipmentSummaryForDriverAssignment.prototype.setDestinationName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string node_id = 6;
 * @return {string}
 */
proto.vtv1.PendingShipmentSummaryForDriverAssignment.prototype.getNodeId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.PendingShipmentSummaryForDriverAssignment} returns this
 */
proto.vtv1.PendingShipmentSummaryForDriverAssignment.prototype.setNodeId = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string node_name = 7;
 * @return {string}
 */
proto.vtv1.PendingShipmentSummaryForDriverAssignment.prototype.getNodeName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.PendingShipmentSummaryForDriverAssignment} returns this
 */
proto.vtv1.PendingShipmentSummaryForDriverAssignment.prototype.setNodeName = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string product_id = 8;
 * @return {string}
 */
proto.vtv1.PendingShipmentSummaryForDriverAssignment.prototype.getProductId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.PendingShipmentSummaryForDriverAssignment} returns this
 */
proto.vtv1.PendingShipmentSummaryForDriverAssignment.prototype.setProductId = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string product_name = 9;
 * @return {string}
 */
proto.vtv1.PendingShipmentSummaryForDriverAssignment.prototype.getProductName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.PendingShipmentSummaryForDriverAssignment} returns this
 */
proto.vtv1.PendingShipmentSummaryForDriverAssignment.prototype.setProductName = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional int64 unit_id = 10;
 * @return {number}
 */
proto.vtv1.PendingShipmentSummaryForDriverAssignment.prototype.getUnitId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.PendingShipmentSummaryForDriverAssignment} returns this
 */
proto.vtv1.PendingShipmentSummaryForDriverAssignment.prototype.setUnitId = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional string unit_name = 11;
 * @return {string}
 */
proto.vtv1.PendingShipmentSummaryForDriverAssignment.prototype.getUnitName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.PendingShipmentSummaryForDriverAssignment} returns this
 */
proto.vtv1.PendingShipmentSummaryForDriverAssignment.prototype.setUnitName = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string unit_abbreviation = 12;
 * @return {string}
 */
proto.vtv1.PendingShipmentSummaryForDriverAssignment.prototype.getUnitAbbreviation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.PendingShipmentSummaryForDriverAssignment} returns this
 */
proto.vtv1.PendingShipmentSummaryForDriverAssignment.prototype.setUnitAbbreviation = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional double quantity = 13;
 * @return {number}
 */
proto.vtv1.PendingShipmentSummaryForDriverAssignment.prototype.getQuantity = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 13, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.PendingShipmentSummaryForDriverAssignment} returns this
 */
proto.vtv1.PendingShipmentSummaryForDriverAssignment.prototype.setQuantity = function(value) {
  return jspb.Message.setProto3FloatField(this, 13, value);
};


/**
 * optional string received_at = 14;
 * @return {string}
 */
proto.vtv1.PendingShipmentSummaryForDriverAssignment.prototype.getReceivedAt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.PendingShipmentSummaryForDriverAssignment} returns this
 */
proto.vtv1.PendingShipmentSummaryForDriverAssignment.prototype.setReceivedAt = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional NullableTime arrival_window_starts_at = 15;
 * @return {?proto.vtv1.NullableTime}
 */
proto.vtv1.PendingShipmentSummaryForDriverAssignment.prototype.getArrivalWindowStartsAt = function() {
  return /** @type{?proto.vtv1.NullableTime} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableTime, 15));
};


/**
 * @param {?proto.vtv1.NullableTime|undefined} value
 * @return {!proto.vtv1.PendingShipmentSummaryForDriverAssignment} returns this
*/
proto.vtv1.PendingShipmentSummaryForDriverAssignment.prototype.setArrivalWindowStartsAt = function(value) {
  return jspb.Message.setWrapperField(this, 15, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.PendingShipmentSummaryForDriverAssignment} returns this
 */
proto.vtv1.PendingShipmentSummaryForDriverAssignment.prototype.clearArrivalWindowStartsAt = function() {
  return this.setArrivalWindowStartsAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.PendingShipmentSummaryForDriverAssignment.prototype.hasArrivalWindowStartsAt = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional NullableString order_id = 16;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.PendingShipmentSummaryForDriverAssignment.prototype.getOrderId = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableString, 16));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.PendingShipmentSummaryForDriverAssignment} returns this
*/
proto.vtv1.PendingShipmentSummaryForDriverAssignment.prototype.setOrderId = function(value) {
  return jspb.Message.setWrapperField(this, 16, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.PendingShipmentSummaryForDriverAssignment} returns this
 */
proto.vtv1.PendingShipmentSummaryForDriverAssignment.prototype.clearOrderId = function() {
  return this.setOrderId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.PendingShipmentSummaryForDriverAssignment.prototype.hasOrderId = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * optional string updated_at = 17;
 * @return {string}
 */
proto.vtv1.PendingShipmentSummaryForDriverAssignment.prototype.getUpdatedAt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.PendingShipmentSummaryForDriverAssignment} returns this
 */
proto.vtv1.PendingShipmentSummaryForDriverAssignment.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional NullableString trailer_type_id = 18;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.PendingShipmentSummaryForDriverAssignment.prototype.getTrailerTypeId = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableString, 18));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.PendingShipmentSummaryForDriverAssignment} returns this
*/
proto.vtv1.PendingShipmentSummaryForDriverAssignment.prototype.setTrailerTypeId = function(value) {
  return jspb.Message.setWrapperField(this, 18, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.PendingShipmentSummaryForDriverAssignment} returns this
 */
proto.vtv1.PendingShipmentSummaryForDriverAssignment.prototype.clearTrailerTypeId = function() {
  return this.setTrailerTypeId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.PendingShipmentSummaryForDriverAssignment.prototype.hasTrailerTypeId = function() {
  return jspb.Message.getField(this, 18) != null;
};


/**
 * optional NullableString trailer_type_name = 19;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.PendingShipmentSummaryForDriverAssignment.prototype.getTrailerTypeName = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableString, 19));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.PendingShipmentSummaryForDriverAssignment} returns this
*/
proto.vtv1.PendingShipmentSummaryForDriverAssignment.prototype.setTrailerTypeName = function(value) {
  return jspb.Message.setWrapperField(this, 19, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.PendingShipmentSummaryForDriverAssignment} returns this
 */
proto.vtv1.PendingShipmentSummaryForDriverAssignment.prototype.clearTrailerTypeName = function() {
  return this.setTrailerTypeName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.PendingShipmentSummaryForDriverAssignment.prototype.hasTrailerTypeName = function() {
  return jspb.Message.getField(this, 19) != null;
};


/**
 * optional NullableInt64 arrival_window_minutes = 20;
 * @return {?proto.vtv1.NullableInt64}
 */
proto.vtv1.PendingShipmentSummaryForDriverAssignment.prototype.getArrivalWindowMinutes = function() {
  return /** @type{?proto.vtv1.NullableInt64} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableInt64, 20));
};


/**
 * @param {?proto.vtv1.NullableInt64|undefined} value
 * @return {!proto.vtv1.PendingShipmentSummaryForDriverAssignment} returns this
*/
proto.vtv1.PendingShipmentSummaryForDriverAssignment.prototype.setArrivalWindowMinutes = function(value) {
  return jspb.Message.setWrapperField(this, 20, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.PendingShipmentSummaryForDriverAssignment} returns this
 */
proto.vtv1.PendingShipmentSummaryForDriverAssignment.prototype.clearArrivalWindowMinutes = function() {
  return this.setArrivalWindowMinutes(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.PendingShipmentSummaryForDriverAssignment.prototype.hasArrivalWindowMinutes = function() {
  return jspb.Message.getField(this, 20) != null;
};


/**
 * optional AssignmentRepercussions repercussions = 21;
 * @return {?proto.vtv1.AssignmentRepercussions}
 */
proto.vtv1.PendingShipmentSummaryForDriverAssignment.prototype.getRepercussions = function() {
  return /** @type{?proto.vtv1.AssignmentRepercussions} */ (
    jspb.Message.getWrapperField(this, proto.vtv1.AssignmentRepercussions, 21));
};


/**
 * @param {?proto.vtv1.AssignmentRepercussions|undefined} value
 * @return {!proto.vtv1.PendingShipmentSummaryForDriverAssignment} returns this
*/
proto.vtv1.PendingShipmentSummaryForDriverAssignment.prototype.setRepercussions = function(value) {
  return jspb.Message.setWrapperField(this, 21, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.PendingShipmentSummaryForDriverAssignment} returns this
 */
proto.vtv1.PendingShipmentSummaryForDriverAssignment.prototype.clearRepercussions = function() {
  return this.setRepercussions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.PendingShipmentSummaryForDriverAssignment.prototype.hasRepercussions = function() {
  return jspb.Message.getField(this, 21) != null;
};


/**
 * repeated string trailer_names = 22;
 * @return {!Array<string>}
 */
proto.vtv1.PendingShipmentSummaryForDriverAssignment.prototype.getTrailerNamesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 22));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.vtv1.PendingShipmentSummaryForDriverAssignment} returns this
 */
proto.vtv1.PendingShipmentSummaryForDriverAssignment.prototype.setTrailerNamesList = function(value) {
  return jspb.Message.setField(this, 22, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.vtv1.PendingShipmentSummaryForDriverAssignment} returns this
 */
proto.vtv1.PendingShipmentSummaryForDriverAssignment.prototype.addTrailerNames = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 22, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.PendingShipmentSummaryForDriverAssignment} returns this
 */
proto.vtv1.PendingShipmentSummaryForDriverAssignment.prototype.clearTrailerNamesList = function() {
  return this.setTrailerNamesList([]);
};


/**
 * optional UFGStatus ufg_status = 23;
 * @return {!proto.vtv1.UFGStatus}
 */
proto.vtv1.PendingShipmentSummaryForDriverAssignment.prototype.getUfgStatus = function() {
  return /** @type {!proto.vtv1.UFGStatus} */ (jspb.Message.getFieldWithDefault(this, 23, 0));
};


/**
 * @param {!proto.vtv1.UFGStatus} value
 * @return {!proto.vtv1.PendingShipmentSummaryForDriverAssignment} returns this
 */
proto.vtv1.PendingShipmentSummaryForDriverAssignment.prototype.setUfgStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 23, value);
};


/**
 * repeated string asset_names = 24;
 * @return {!Array<string>}
 */
proto.vtv1.PendingShipmentSummaryForDriverAssignment.prototype.getAssetNamesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 24));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.vtv1.PendingShipmentSummaryForDriverAssignment} returns this
 */
proto.vtv1.PendingShipmentSummaryForDriverAssignment.prototype.setAssetNamesList = function(value) {
  return jspb.Message.setField(this, 24, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.vtv1.PendingShipmentSummaryForDriverAssignment} returns this
 */
proto.vtv1.PendingShipmentSummaryForDriverAssignment.prototype.addAssetNames = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 24, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.PendingShipmentSummaryForDriverAssignment} returns this
 */
proto.vtv1.PendingShipmentSummaryForDriverAssignment.prototype.clearAssetNamesList = function() {
  return this.setAssetNamesList([]);
};


/**
 * optional NullableString equipment_order_id = 25;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.PendingShipmentSummaryForDriverAssignment.prototype.getEquipmentOrderId = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableString, 25));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.PendingShipmentSummaryForDriverAssignment} returns this
*/
proto.vtv1.PendingShipmentSummaryForDriverAssignment.prototype.setEquipmentOrderId = function(value) {
  return jspb.Message.setWrapperField(this, 25, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.PendingShipmentSummaryForDriverAssignment} returns this
 */
proto.vtv1.PendingShipmentSummaryForDriverAssignment.prototype.clearEquipmentOrderId = function() {
  return this.setEquipmentOrderId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.PendingShipmentSummaryForDriverAssignment.prototype.hasEquipmentOrderId = function() {
  return jspb.Message.getField(this, 25) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.InProgressShipmentSummary.repeatedFields_ = [34,35];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.InProgressShipmentSummary.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.InProgressShipmentSummary.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.InProgressShipmentSummary} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.InProgressShipmentSummary.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    originId: (f = msg.getOriginId()) && proto_vorto_types_types_pb.NullableString.toObject(includeInstance, f),
    originName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    destinationId: (f = msg.getDestinationId()) && proto_vorto_types_types_pb.NullableString.toObject(includeInstance, f),
    destinationName: jspb.Message.getFieldWithDefault(msg, 5, ""),
    nodeId: jspb.Message.getFieldWithDefault(msg, 6, ""),
    nodeName: jspb.Message.getFieldWithDefault(msg, 7, ""),
    productId: jspb.Message.getFieldWithDefault(msg, 8, ""),
    productName: jspb.Message.getFieldWithDefault(msg, 9, ""),
    unitId: jspb.Message.getFieldWithDefault(msg, 10, 0),
    unitName: jspb.Message.getFieldWithDefault(msg, 11, ""),
    unitAbbreviation: jspb.Message.getFieldWithDefault(msg, 12, ""),
    quantity: jspb.Message.getFloatingPointFieldWithDefault(msg, 13, 0.0),
    driverId: jspb.Message.getFieldWithDefault(msg, 14, ""),
    driverName: jspb.Message.getFieldWithDefault(msg, 15, ""),
    exceptionMessage: (f = msg.getExceptionMessage()) && proto_vorto_types_types_pb.NullableString.toObject(includeInstance, f),
    status: jspb.Message.getFieldWithDefault(msg, 17, 0),
    updatedAt: jspb.Message.getFieldWithDefault(msg, 18, ""),
    arrivalWindowStartsAt: (f = msg.getArrivalWindowStartsAt()) && proto_vorto_types_types_pb.NullableTime.toObject(includeInstance, f),
    arrivalWindowEndsAt: (f = msg.getArrivalWindowEndsAt()) && proto_vorto_types_types_pb.NullableTime.toObject(includeInstance, f),
    warningStatus: jspb.Message.getFieldWithDefault(msg, 21, 0),
    originEta: (f = msg.getOriginEta()) && proto_vorto_types_types_pb.NullableTime.toObject(includeInstance, f),
    originOriginalEta: (f = msg.getOriginOriginalEta()) && proto_vorto_types_types_pb.NullableTime.toObject(includeInstance, f),
    destinationEta: (f = msg.getDestinationEta()) && proto_vorto_types_types_pb.NullableTime.toObject(includeInstance, f),
    destinationOriginalEta: (f = msg.getDestinationOriginalEta()) && proto_vorto_types_types_pb.NullableTime.toObject(includeInstance, f),
    driverDelayedMinutes: (f = msg.getDriverDelayedMinutes()) && proto_vorto_types_types_pb.NullableInt64.toObject(includeInstance, f),
    orderId: (f = msg.getOrderId()) && proto_vorto_types_types_pb.NullableString.toObject(includeInstance, f),
    willSiteBeClosed: jspb.Message.getBooleanFieldWithDefault(msg, 28, false),
    progressStatus: jspb.Message.getFieldWithDefault(msg, 29, 0),
    progressStatusUpdatedAt: jspb.Message.getFieldWithDefault(msg, 30, ""),
    trailerTypeId: jspb.Message.getFieldWithDefault(msg, 31, ""),
    trailerTypeName: jspb.Message.getFieldWithDefault(msg, 32, ""),
    hasBackhaul: jspb.Message.getBooleanFieldWithDefault(msg, 33, false),
    productNamesList: (f = jspb.Message.getRepeatedField(msg, 34)) == null ? undefined : f,
    assetNamesList: (f = jspb.Message.getRepeatedField(msg, 35)) == null ? undefined : f,
    equipmentOrderId: (f = msg.getEquipmentOrderId()) && proto_vorto_types_types_pb.NullableString.toObject(includeInstance, f),
    equipmentOrderPriority: (f = msg.getEquipmentOrderPriority()) && proto_vorto_types_types_pb.NullableInt64.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.InProgressShipmentSummary}
 */
proto.vtv1.InProgressShipmentSummary.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.InProgressShipmentSummary;
  return proto.vtv1.InProgressShipmentSummary.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.InProgressShipmentSummary} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.InProgressShipmentSummary}
 */
proto.vtv1.InProgressShipmentSummary.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new proto_vorto_types_types_pb.NullableString;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableString.deserializeBinaryFromReader);
      msg.setOriginId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setOriginName(value);
      break;
    case 4:
      var value = new proto_vorto_types_types_pb.NullableString;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableString.deserializeBinaryFromReader);
      msg.setDestinationId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setDestinationName(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setNodeId(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setNodeName(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductId(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductName(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUnitId(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setUnitName(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setUnitAbbreviation(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setQuantity(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setDriverId(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setDriverName(value);
      break;
    case 16:
      var value = new proto_vorto_types_types_pb.NullableString;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableString.deserializeBinaryFromReader);
      msg.setExceptionMessage(value);
      break;
    case 17:
      var value = /** @type {!proto.vtv1.ShipmentStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setUpdatedAt(value);
      break;
    case 19:
      var value = new proto_vorto_types_types_pb.NullableTime;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableTime.deserializeBinaryFromReader);
      msg.setArrivalWindowStartsAt(value);
      break;
    case 20:
      var value = new proto_vorto_types_types_pb.NullableTime;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableTime.deserializeBinaryFromReader);
      msg.setArrivalWindowEndsAt(value);
      break;
    case 21:
      var value = /** @type {!proto.vtv1.InProgressShipmentSummary.WarningStatus} */ (reader.readEnum());
      msg.setWarningStatus(value);
      break;
    case 22:
      var value = new proto_vorto_types_types_pb.NullableTime;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableTime.deserializeBinaryFromReader);
      msg.setOriginEta(value);
      break;
    case 23:
      var value = new proto_vorto_types_types_pb.NullableTime;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableTime.deserializeBinaryFromReader);
      msg.setOriginOriginalEta(value);
      break;
    case 24:
      var value = new proto_vorto_types_types_pb.NullableTime;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableTime.deserializeBinaryFromReader);
      msg.setDestinationEta(value);
      break;
    case 25:
      var value = new proto_vorto_types_types_pb.NullableTime;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableTime.deserializeBinaryFromReader);
      msg.setDestinationOriginalEta(value);
      break;
    case 26:
      var value = new proto_vorto_types_types_pb.NullableInt64;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableInt64.deserializeBinaryFromReader);
      msg.setDriverDelayedMinutes(value);
      break;
    case 27:
      var value = new proto_vorto_types_types_pb.NullableString;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableString.deserializeBinaryFromReader);
      msg.setOrderId(value);
      break;
    case 28:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setWillSiteBeClosed(value);
      break;
    case 29:
      var value = /** @type {!proto.vtv1.ShipmentProgressStatus} */ (reader.readEnum());
      msg.setProgressStatus(value);
      break;
    case 30:
      var value = /** @type {string} */ (reader.readString());
      msg.setProgressStatusUpdatedAt(value);
      break;
    case 31:
      var value = /** @type {string} */ (reader.readString());
      msg.setTrailerTypeId(value);
      break;
    case 32:
      var value = /** @type {string} */ (reader.readString());
      msg.setTrailerTypeName(value);
      break;
    case 33:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasBackhaul(value);
      break;
    case 34:
      var value = /** @type {string} */ (reader.readString());
      msg.addProductNames(value);
      break;
    case 35:
      var value = /** @type {string} */ (reader.readString());
      msg.addAssetNames(value);
      break;
    case 36:
      var value = new proto_vorto_types_types_pb.NullableString;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableString.deserializeBinaryFromReader);
      msg.setEquipmentOrderId(value);
      break;
    case 37:
      var value = new proto_vorto_types_types_pb.NullableInt64;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableInt64.deserializeBinaryFromReader);
      msg.setEquipmentOrderPriority(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.InProgressShipmentSummary.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.InProgressShipmentSummary.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.InProgressShipmentSummary} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.InProgressShipmentSummary.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOriginId();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_vorto_types_types_pb.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getOriginName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDestinationId();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto_vorto_types_types_pb.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getDestinationName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getNodeId();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getNodeName();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getProductId();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getProductName();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getUnitId();
  if (f !== 0) {
    writer.writeInt64(
      10,
      f
    );
  }
  f = message.getUnitName();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getUnitAbbreviation();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getQuantity();
  if (f !== 0.0) {
    writer.writeDouble(
      13,
      f
    );
  }
  f = message.getDriverId();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getDriverName();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getExceptionMessage();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      proto_vorto_types_types_pb.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      17,
      f
    );
  }
  f = message.getUpdatedAt();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
  f = message.getArrivalWindowStartsAt();
  if (f != null) {
    writer.writeMessage(
      19,
      f,
      proto_vorto_types_types_pb.NullableTime.serializeBinaryToWriter
    );
  }
  f = message.getArrivalWindowEndsAt();
  if (f != null) {
    writer.writeMessage(
      20,
      f,
      proto_vorto_types_types_pb.NullableTime.serializeBinaryToWriter
    );
  }
  f = message.getWarningStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      21,
      f
    );
  }
  f = message.getOriginEta();
  if (f != null) {
    writer.writeMessage(
      22,
      f,
      proto_vorto_types_types_pb.NullableTime.serializeBinaryToWriter
    );
  }
  f = message.getOriginOriginalEta();
  if (f != null) {
    writer.writeMessage(
      23,
      f,
      proto_vorto_types_types_pb.NullableTime.serializeBinaryToWriter
    );
  }
  f = message.getDestinationEta();
  if (f != null) {
    writer.writeMessage(
      24,
      f,
      proto_vorto_types_types_pb.NullableTime.serializeBinaryToWriter
    );
  }
  f = message.getDestinationOriginalEta();
  if (f != null) {
    writer.writeMessage(
      25,
      f,
      proto_vorto_types_types_pb.NullableTime.serializeBinaryToWriter
    );
  }
  f = message.getDriverDelayedMinutes();
  if (f != null) {
    writer.writeMessage(
      26,
      f,
      proto_vorto_types_types_pb.NullableInt64.serializeBinaryToWriter
    );
  }
  f = message.getOrderId();
  if (f != null) {
    writer.writeMessage(
      27,
      f,
      proto_vorto_types_types_pb.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getWillSiteBeClosed();
  if (f) {
    writer.writeBool(
      28,
      f
    );
  }
  f = message.getProgressStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      29,
      f
    );
  }
  f = message.getProgressStatusUpdatedAt();
  if (f.length > 0) {
    writer.writeString(
      30,
      f
    );
  }
  f = message.getTrailerTypeId();
  if (f.length > 0) {
    writer.writeString(
      31,
      f
    );
  }
  f = message.getTrailerTypeName();
  if (f.length > 0) {
    writer.writeString(
      32,
      f
    );
  }
  f = message.getHasBackhaul();
  if (f) {
    writer.writeBool(
      33,
      f
    );
  }
  f = message.getProductNamesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      34,
      f
    );
  }
  f = message.getAssetNamesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      35,
      f
    );
  }
  f = message.getEquipmentOrderId();
  if (f != null) {
    writer.writeMessage(
      36,
      f,
      proto_vorto_types_types_pb.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getEquipmentOrderPriority();
  if (f != null) {
    writer.writeMessage(
      37,
      f,
      proto_vorto_types_types_pb.NullableInt64.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.vtv1.InProgressShipmentSummary.WarningStatus = {
  OK: 0,
  DRIVER_ACTION_NEEDED: 1,
  SHIPMENT_DELAYED: 2
};

/**
 * optional string id = 1;
 * @return {string}
 */
proto.vtv1.InProgressShipmentSummary.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.InProgressShipmentSummary} returns this
 */
proto.vtv1.InProgressShipmentSummary.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional NullableString origin_id = 2;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.InProgressShipmentSummary.prototype.getOriginId = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableString, 2));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.InProgressShipmentSummary} returns this
*/
proto.vtv1.InProgressShipmentSummary.prototype.setOriginId = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.InProgressShipmentSummary} returns this
 */
proto.vtv1.InProgressShipmentSummary.prototype.clearOriginId = function() {
  return this.setOriginId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.InProgressShipmentSummary.prototype.hasOriginId = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string origin_name = 3;
 * @return {string}
 */
proto.vtv1.InProgressShipmentSummary.prototype.getOriginName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.InProgressShipmentSummary} returns this
 */
proto.vtv1.InProgressShipmentSummary.prototype.setOriginName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional NullableString destination_id = 4;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.InProgressShipmentSummary.prototype.getDestinationId = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableString, 4));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.InProgressShipmentSummary} returns this
*/
proto.vtv1.InProgressShipmentSummary.prototype.setDestinationId = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.InProgressShipmentSummary} returns this
 */
proto.vtv1.InProgressShipmentSummary.prototype.clearDestinationId = function() {
  return this.setDestinationId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.InProgressShipmentSummary.prototype.hasDestinationId = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string destination_name = 5;
 * @return {string}
 */
proto.vtv1.InProgressShipmentSummary.prototype.getDestinationName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.InProgressShipmentSummary} returns this
 */
proto.vtv1.InProgressShipmentSummary.prototype.setDestinationName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string node_id = 6;
 * @return {string}
 */
proto.vtv1.InProgressShipmentSummary.prototype.getNodeId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.InProgressShipmentSummary} returns this
 */
proto.vtv1.InProgressShipmentSummary.prototype.setNodeId = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string node_name = 7;
 * @return {string}
 */
proto.vtv1.InProgressShipmentSummary.prototype.getNodeName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.InProgressShipmentSummary} returns this
 */
proto.vtv1.InProgressShipmentSummary.prototype.setNodeName = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string product_id = 8;
 * @return {string}
 */
proto.vtv1.InProgressShipmentSummary.prototype.getProductId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.InProgressShipmentSummary} returns this
 */
proto.vtv1.InProgressShipmentSummary.prototype.setProductId = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string product_name = 9;
 * @return {string}
 */
proto.vtv1.InProgressShipmentSummary.prototype.getProductName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.InProgressShipmentSummary} returns this
 */
proto.vtv1.InProgressShipmentSummary.prototype.setProductName = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional int64 unit_id = 10;
 * @return {number}
 */
proto.vtv1.InProgressShipmentSummary.prototype.getUnitId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.InProgressShipmentSummary} returns this
 */
proto.vtv1.InProgressShipmentSummary.prototype.setUnitId = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional string unit_name = 11;
 * @return {string}
 */
proto.vtv1.InProgressShipmentSummary.prototype.getUnitName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.InProgressShipmentSummary} returns this
 */
proto.vtv1.InProgressShipmentSummary.prototype.setUnitName = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string unit_abbreviation = 12;
 * @return {string}
 */
proto.vtv1.InProgressShipmentSummary.prototype.getUnitAbbreviation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.InProgressShipmentSummary} returns this
 */
proto.vtv1.InProgressShipmentSummary.prototype.setUnitAbbreviation = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional double quantity = 13;
 * @return {number}
 */
proto.vtv1.InProgressShipmentSummary.prototype.getQuantity = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 13, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.InProgressShipmentSummary} returns this
 */
proto.vtv1.InProgressShipmentSummary.prototype.setQuantity = function(value) {
  return jspb.Message.setProto3FloatField(this, 13, value);
};


/**
 * optional string driver_id = 14;
 * @return {string}
 */
proto.vtv1.InProgressShipmentSummary.prototype.getDriverId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.InProgressShipmentSummary} returns this
 */
proto.vtv1.InProgressShipmentSummary.prototype.setDriverId = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string driver_name = 15;
 * @return {string}
 */
proto.vtv1.InProgressShipmentSummary.prototype.getDriverName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.InProgressShipmentSummary} returns this
 */
proto.vtv1.InProgressShipmentSummary.prototype.setDriverName = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional NullableString exception_message = 16;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.InProgressShipmentSummary.prototype.getExceptionMessage = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableString, 16));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.InProgressShipmentSummary} returns this
*/
proto.vtv1.InProgressShipmentSummary.prototype.setExceptionMessage = function(value) {
  return jspb.Message.setWrapperField(this, 16, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.InProgressShipmentSummary} returns this
 */
proto.vtv1.InProgressShipmentSummary.prototype.clearExceptionMessage = function() {
  return this.setExceptionMessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.InProgressShipmentSummary.prototype.hasExceptionMessage = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * optional ShipmentStatus status = 17;
 * @return {!proto.vtv1.ShipmentStatus}
 */
proto.vtv1.InProgressShipmentSummary.prototype.getStatus = function() {
  return /** @type {!proto.vtv1.ShipmentStatus} */ (jspb.Message.getFieldWithDefault(this, 17, 0));
};


/**
 * @param {!proto.vtv1.ShipmentStatus} value
 * @return {!proto.vtv1.InProgressShipmentSummary} returns this
 */
proto.vtv1.InProgressShipmentSummary.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 17, value);
};


/**
 * optional string updated_at = 18;
 * @return {string}
 */
proto.vtv1.InProgressShipmentSummary.prototype.getUpdatedAt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.InProgressShipmentSummary} returns this
 */
proto.vtv1.InProgressShipmentSummary.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};


/**
 * optional NullableTime arrival_window_starts_at = 19;
 * @return {?proto.vtv1.NullableTime}
 */
proto.vtv1.InProgressShipmentSummary.prototype.getArrivalWindowStartsAt = function() {
  return /** @type{?proto.vtv1.NullableTime} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableTime, 19));
};


/**
 * @param {?proto.vtv1.NullableTime|undefined} value
 * @return {!proto.vtv1.InProgressShipmentSummary} returns this
*/
proto.vtv1.InProgressShipmentSummary.prototype.setArrivalWindowStartsAt = function(value) {
  return jspb.Message.setWrapperField(this, 19, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.InProgressShipmentSummary} returns this
 */
proto.vtv1.InProgressShipmentSummary.prototype.clearArrivalWindowStartsAt = function() {
  return this.setArrivalWindowStartsAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.InProgressShipmentSummary.prototype.hasArrivalWindowStartsAt = function() {
  return jspb.Message.getField(this, 19) != null;
};


/**
 * optional NullableTime arrival_window_ends_at = 20;
 * @return {?proto.vtv1.NullableTime}
 */
proto.vtv1.InProgressShipmentSummary.prototype.getArrivalWindowEndsAt = function() {
  return /** @type{?proto.vtv1.NullableTime} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableTime, 20));
};


/**
 * @param {?proto.vtv1.NullableTime|undefined} value
 * @return {!proto.vtv1.InProgressShipmentSummary} returns this
*/
proto.vtv1.InProgressShipmentSummary.prototype.setArrivalWindowEndsAt = function(value) {
  return jspb.Message.setWrapperField(this, 20, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.InProgressShipmentSummary} returns this
 */
proto.vtv1.InProgressShipmentSummary.prototype.clearArrivalWindowEndsAt = function() {
  return this.setArrivalWindowEndsAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.InProgressShipmentSummary.prototype.hasArrivalWindowEndsAt = function() {
  return jspb.Message.getField(this, 20) != null;
};


/**
 * optional WarningStatus warning_status = 21;
 * @return {!proto.vtv1.InProgressShipmentSummary.WarningStatus}
 */
proto.vtv1.InProgressShipmentSummary.prototype.getWarningStatus = function() {
  return /** @type {!proto.vtv1.InProgressShipmentSummary.WarningStatus} */ (jspb.Message.getFieldWithDefault(this, 21, 0));
};


/**
 * @param {!proto.vtv1.InProgressShipmentSummary.WarningStatus} value
 * @return {!proto.vtv1.InProgressShipmentSummary} returns this
 */
proto.vtv1.InProgressShipmentSummary.prototype.setWarningStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 21, value);
};


/**
 * optional NullableTime origin_eta = 22;
 * @return {?proto.vtv1.NullableTime}
 */
proto.vtv1.InProgressShipmentSummary.prototype.getOriginEta = function() {
  return /** @type{?proto.vtv1.NullableTime} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableTime, 22));
};


/**
 * @param {?proto.vtv1.NullableTime|undefined} value
 * @return {!proto.vtv1.InProgressShipmentSummary} returns this
*/
proto.vtv1.InProgressShipmentSummary.prototype.setOriginEta = function(value) {
  return jspb.Message.setWrapperField(this, 22, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.InProgressShipmentSummary} returns this
 */
proto.vtv1.InProgressShipmentSummary.prototype.clearOriginEta = function() {
  return this.setOriginEta(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.InProgressShipmentSummary.prototype.hasOriginEta = function() {
  return jspb.Message.getField(this, 22) != null;
};


/**
 * optional NullableTime origin_original_eta = 23;
 * @return {?proto.vtv1.NullableTime}
 */
proto.vtv1.InProgressShipmentSummary.prototype.getOriginOriginalEta = function() {
  return /** @type{?proto.vtv1.NullableTime} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableTime, 23));
};


/**
 * @param {?proto.vtv1.NullableTime|undefined} value
 * @return {!proto.vtv1.InProgressShipmentSummary} returns this
*/
proto.vtv1.InProgressShipmentSummary.prototype.setOriginOriginalEta = function(value) {
  return jspb.Message.setWrapperField(this, 23, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.InProgressShipmentSummary} returns this
 */
proto.vtv1.InProgressShipmentSummary.prototype.clearOriginOriginalEta = function() {
  return this.setOriginOriginalEta(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.InProgressShipmentSummary.prototype.hasOriginOriginalEta = function() {
  return jspb.Message.getField(this, 23) != null;
};


/**
 * optional NullableTime destination_eta = 24;
 * @return {?proto.vtv1.NullableTime}
 */
proto.vtv1.InProgressShipmentSummary.prototype.getDestinationEta = function() {
  return /** @type{?proto.vtv1.NullableTime} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableTime, 24));
};


/**
 * @param {?proto.vtv1.NullableTime|undefined} value
 * @return {!proto.vtv1.InProgressShipmentSummary} returns this
*/
proto.vtv1.InProgressShipmentSummary.prototype.setDestinationEta = function(value) {
  return jspb.Message.setWrapperField(this, 24, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.InProgressShipmentSummary} returns this
 */
proto.vtv1.InProgressShipmentSummary.prototype.clearDestinationEta = function() {
  return this.setDestinationEta(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.InProgressShipmentSummary.prototype.hasDestinationEta = function() {
  return jspb.Message.getField(this, 24) != null;
};


/**
 * optional NullableTime destination_original_eta = 25;
 * @return {?proto.vtv1.NullableTime}
 */
proto.vtv1.InProgressShipmentSummary.prototype.getDestinationOriginalEta = function() {
  return /** @type{?proto.vtv1.NullableTime} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableTime, 25));
};


/**
 * @param {?proto.vtv1.NullableTime|undefined} value
 * @return {!proto.vtv1.InProgressShipmentSummary} returns this
*/
proto.vtv1.InProgressShipmentSummary.prototype.setDestinationOriginalEta = function(value) {
  return jspb.Message.setWrapperField(this, 25, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.InProgressShipmentSummary} returns this
 */
proto.vtv1.InProgressShipmentSummary.prototype.clearDestinationOriginalEta = function() {
  return this.setDestinationOriginalEta(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.InProgressShipmentSummary.prototype.hasDestinationOriginalEta = function() {
  return jspb.Message.getField(this, 25) != null;
};


/**
 * optional NullableInt64 driver_delayed_minutes = 26;
 * @return {?proto.vtv1.NullableInt64}
 */
proto.vtv1.InProgressShipmentSummary.prototype.getDriverDelayedMinutes = function() {
  return /** @type{?proto.vtv1.NullableInt64} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableInt64, 26));
};


/**
 * @param {?proto.vtv1.NullableInt64|undefined} value
 * @return {!proto.vtv1.InProgressShipmentSummary} returns this
*/
proto.vtv1.InProgressShipmentSummary.prototype.setDriverDelayedMinutes = function(value) {
  return jspb.Message.setWrapperField(this, 26, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.InProgressShipmentSummary} returns this
 */
proto.vtv1.InProgressShipmentSummary.prototype.clearDriverDelayedMinutes = function() {
  return this.setDriverDelayedMinutes(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.InProgressShipmentSummary.prototype.hasDriverDelayedMinutes = function() {
  return jspb.Message.getField(this, 26) != null;
};


/**
 * optional NullableString order_id = 27;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.InProgressShipmentSummary.prototype.getOrderId = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableString, 27));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.InProgressShipmentSummary} returns this
*/
proto.vtv1.InProgressShipmentSummary.prototype.setOrderId = function(value) {
  return jspb.Message.setWrapperField(this, 27, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.InProgressShipmentSummary} returns this
 */
proto.vtv1.InProgressShipmentSummary.prototype.clearOrderId = function() {
  return this.setOrderId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.InProgressShipmentSummary.prototype.hasOrderId = function() {
  return jspb.Message.getField(this, 27) != null;
};


/**
 * optional bool will_site_be_closed = 28;
 * @return {boolean}
 */
proto.vtv1.InProgressShipmentSummary.prototype.getWillSiteBeClosed = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 28, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vtv1.InProgressShipmentSummary} returns this
 */
proto.vtv1.InProgressShipmentSummary.prototype.setWillSiteBeClosed = function(value) {
  return jspb.Message.setProto3BooleanField(this, 28, value);
};


/**
 * optional ShipmentProgressStatus progress_status = 29;
 * @return {!proto.vtv1.ShipmentProgressStatus}
 */
proto.vtv1.InProgressShipmentSummary.prototype.getProgressStatus = function() {
  return /** @type {!proto.vtv1.ShipmentProgressStatus} */ (jspb.Message.getFieldWithDefault(this, 29, 0));
};


/**
 * @param {!proto.vtv1.ShipmentProgressStatus} value
 * @return {!proto.vtv1.InProgressShipmentSummary} returns this
 */
proto.vtv1.InProgressShipmentSummary.prototype.setProgressStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 29, value);
};


/**
 * optional string progress_status_updated_at = 30;
 * @return {string}
 */
proto.vtv1.InProgressShipmentSummary.prototype.getProgressStatusUpdatedAt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 30, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.InProgressShipmentSummary} returns this
 */
proto.vtv1.InProgressShipmentSummary.prototype.setProgressStatusUpdatedAt = function(value) {
  return jspb.Message.setProto3StringField(this, 30, value);
};


/**
 * optional string trailer_type_id = 31;
 * @return {string}
 */
proto.vtv1.InProgressShipmentSummary.prototype.getTrailerTypeId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 31, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.InProgressShipmentSummary} returns this
 */
proto.vtv1.InProgressShipmentSummary.prototype.setTrailerTypeId = function(value) {
  return jspb.Message.setProto3StringField(this, 31, value);
};


/**
 * optional string trailer_type_name = 32;
 * @return {string}
 */
proto.vtv1.InProgressShipmentSummary.prototype.getTrailerTypeName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 32, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.InProgressShipmentSummary} returns this
 */
proto.vtv1.InProgressShipmentSummary.prototype.setTrailerTypeName = function(value) {
  return jspb.Message.setProto3StringField(this, 32, value);
};


/**
 * optional bool has_backhaul = 33;
 * @return {boolean}
 */
proto.vtv1.InProgressShipmentSummary.prototype.getHasBackhaul = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 33, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vtv1.InProgressShipmentSummary} returns this
 */
proto.vtv1.InProgressShipmentSummary.prototype.setHasBackhaul = function(value) {
  return jspb.Message.setProto3BooleanField(this, 33, value);
};


/**
 * repeated string product_names = 34;
 * @return {!Array<string>}
 */
proto.vtv1.InProgressShipmentSummary.prototype.getProductNamesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 34));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.vtv1.InProgressShipmentSummary} returns this
 */
proto.vtv1.InProgressShipmentSummary.prototype.setProductNamesList = function(value) {
  return jspb.Message.setField(this, 34, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.vtv1.InProgressShipmentSummary} returns this
 */
proto.vtv1.InProgressShipmentSummary.prototype.addProductNames = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 34, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.InProgressShipmentSummary} returns this
 */
proto.vtv1.InProgressShipmentSummary.prototype.clearProductNamesList = function() {
  return this.setProductNamesList([]);
};


/**
 * repeated string asset_names = 35;
 * @return {!Array<string>}
 */
proto.vtv1.InProgressShipmentSummary.prototype.getAssetNamesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 35));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.vtv1.InProgressShipmentSummary} returns this
 */
proto.vtv1.InProgressShipmentSummary.prototype.setAssetNamesList = function(value) {
  return jspb.Message.setField(this, 35, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.vtv1.InProgressShipmentSummary} returns this
 */
proto.vtv1.InProgressShipmentSummary.prototype.addAssetNames = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 35, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.InProgressShipmentSummary} returns this
 */
proto.vtv1.InProgressShipmentSummary.prototype.clearAssetNamesList = function() {
  return this.setAssetNamesList([]);
};


/**
 * optional NullableString equipment_order_id = 36;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.InProgressShipmentSummary.prototype.getEquipmentOrderId = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableString, 36));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.InProgressShipmentSummary} returns this
*/
proto.vtv1.InProgressShipmentSummary.prototype.setEquipmentOrderId = function(value) {
  return jspb.Message.setWrapperField(this, 36, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.InProgressShipmentSummary} returns this
 */
proto.vtv1.InProgressShipmentSummary.prototype.clearEquipmentOrderId = function() {
  return this.setEquipmentOrderId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.InProgressShipmentSummary.prototype.hasEquipmentOrderId = function() {
  return jspb.Message.getField(this, 36) != null;
};


/**
 * optional NullableInt64 equipment_order_priority = 37;
 * @return {?proto.vtv1.NullableInt64}
 */
proto.vtv1.InProgressShipmentSummary.prototype.getEquipmentOrderPriority = function() {
  return /** @type{?proto.vtv1.NullableInt64} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableInt64, 37));
};


/**
 * @param {?proto.vtv1.NullableInt64|undefined} value
 * @return {!proto.vtv1.InProgressShipmentSummary} returns this
*/
proto.vtv1.InProgressShipmentSummary.prototype.setEquipmentOrderPriority = function(value) {
  return jspb.Message.setWrapperField(this, 37, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.InProgressShipmentSummary} returns this
 */
proto.vtv1.InProgressShipmentSummary.prototype.clearEquipmentOrderPriority = function() {
  return this.setEquipmentOrderPriority(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.InProgressShipmentSummary.prototype.hasEquipmentOrderPriority = function() {
  return jspb.Message.getField(this, 37) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.CompletedShipmentSummary.repeatedFields_ = [22,23];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.CompletedShipmentSummary.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.CompletedShipmentSummary.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.CompletedShipmentSummary} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.CompletedShipmentSummary.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    originId: (f = msg.getOriginId()) && proto_vorto_types_types_pb.NullableString.toObject(includeInstance, f),
    originName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    destinationId: (f = msg.getDestinationId()) && proto_vorto_types_types_pb.NullableString.toObject(includeInstance, f),
    destinationName: jspb.Message.getFieldWithDefault(msg, 5, ""),
    nodeId: jspb.Message.getFieldWithDefault(msg, 6, ""),
    nodeName: jspb.Message.getFieldWithDefault(msg, 7, ""),
    productId: jspb.Message.getFieldWithDefault(msg, 8, ""),
    productName: jspb.Message.getFieldWithDefault(msg, 9, ""),
    unitId: jspb.Message.getFieldWithDefault(msg, 10, 0),
    unitName: jspb.Message.getFieldWithDefault(msg, 11, ""),
    unitAbbreviation: jspb.Message.getFieldWithDefault(msg, 12, ""),
    quantity: jspb.Message.getFloatingPointFieldWithDefault(msg, 13, 0.0),
    driverId: jspb.Message.getFieldWithDefault(msg, 14, ""),
    driverName: jspb.Message.getFieldWithDefault(msg, 15, ""),
    exceptionMessage: (f = msg.getExceptionMessage()) && proto_vorto_types_types_pb.NullableString.toObject(includeInstance, f),
    status: jspb.Message.getFieldWithDefault(msg, 17, 0),
    completedAt: jspb.Message.getFieldWithDefault(msg, 18, ""),
    orderId: (f = msg.getOrderId()) && proto_vorto_types_types_pb.NullableString.toObject(includeInstance, f),
    updatedAt: jspb.Message.getFieldWithDefault(msg, 20, ""),
    hasBackhaul: jspb.Message.getBooleanFieldWithDefault(msg, 21, false),
    productNamesList: (f = jspb.Message.getRepeatedField(msg, 22)) == null ? undefined : f,
    assetNamesList: (f = jspb.Message.getRepeatedField(msg, 23)) == null ? undefined : f,
    equipmentOrderId: (f = msg.getEquipmentOrderId()) && proto_vorto_types_types_pb.NullableString.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.CompletedShipmentSummary}
 */
proto.vtv1.CompletedShipmentSummary.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.CompletedShipmentSummary;
  return proto.vtv1.CompletedShipmentSummary.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.CompletedShipmentSummary} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.CompletedShipmentSummary}
 */
proto.vtv1.CompletedShipmentSummary.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new proto_vorto_types_types_pb.NullableString;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableString.deserializeBinaryFromReader);
      msg.setOriginId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setOriginName(value);
      break;
    case 4:
      var value = new proto_vorto_types_types_pb.NullableString;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableString.deserializeBinaryFromReader);
      msg.setDestinationId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setDestinationName(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setNodeId(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setNodeName(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductId(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductName(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUnitId(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setUnitName(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setUnitAbbreviation(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setQuantity(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setDriverId(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setDriverName(value);
      break;
    case 16:
      var value = new proto_vorto_types_types_pb.NullableString;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableString.deserializeBinaryFromReader);
      msg.setExceptionMessage(value);
      break;
    case 17:
      var value = /** @type {!proto.vtv1.ShipmentStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompletedAt(value);
      break;
    case 19:
      var value = new proto_vorto_types_types_pb.NullableString;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableString.deserializeBinaryFromReader);
      msg.setOrderId(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setUpdatedAt(value);
      break;
    case 21:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasBackhaul(value);
      break;
    case 22:
      var value = /** @type {string} */ (reader.readString());
      msg.addProductNames(value);
      break;
    case 23:
      var value = /** @type {string} */ (reader.readString());
      msg.addAssetNames(value);
      break;
    case 24:
      var value = new proto_vorto_types_types_pb.NullableString;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableString.deserializeBinaryFromReader);
      msg.setEquipmentOrderId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.CompletedShipmentSummary.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.CompletedShipmentSummary.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.CompletedShipmentSummary} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.CompletedShipmentSummary.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOriginId();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_vorto_types_types_pb.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getOriginName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDestinationId();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto_vorto_types_types_pb.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getDestinationName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getNodeId();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getNodeName();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getProductId();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getProductName();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getUnitId();
  if (f !== 0) {
    writer.writeInt64(
      10,
      f
    );
  }
  f = message.getUnitName();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getUnitAbbreviation();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getQuantity();
  if (f !== 0.0) {
    writer.writeDouble(
      13,
      f
    );
  }
  f = message.getDriverId();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getDriverName();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getExceptionMessage();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      proto_vorto_types_types_pb.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      17,
      f
    );
  }
  f = message.getCompletedAt();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
  f = message.getOrderId();
  if (f != null) {
    writer.writeMessage(
      19,
      f,
      proto_vorto_types_types_pb.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getUpdatedAt();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
  f = message.getHasBackhaul();
  if (f) {
    writer.writeBool(
      21,
      f
    );
  }
  f = message.getProductNamesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      22,
      f
    );
  }
  f = message.getAssetNamesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      23,
      f
    );
  }
  f = message.getEquipmentOrderId();
  if (f != null) {
    writer.writeMessage(
      24,
      f,
      proto_vorto_types_types_pb.NullableString.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.vtv1.CompletedShipmentSummary.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.CompletedShipmentSummary} returns this
 */
proto.vtv1.CompletedShipmentSummary.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional NullableString origin_id = 2;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.CompletedShipmentSummary.prototype.getOriginId = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableString, 2));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.CompletedShipmentSummary} returns this
*/
proto.vtv1.CompletedShipmentSummary.prototype.setOriginId = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.CompletedShipmentSummary} returns this
 */
proto.vtv1.CompletedShipmentSummary.prototype.clearOriginId = function() {
  return this.setOriginId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.CompletedShipmentSummary.prototype.hasOriginId = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string origin_name = 3;
 * @return {string}
 */
proto.vtv1.CompletedShipmentSummary.prototype.getOriginName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.CompletedShipmentSummary} returns this
 */
proto.vtv1.CompletedShipmentSummary.prototype.setOriginName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional NullableString destination_id = 4;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.CompletedShipmentSummary.prototype.getDestinationId = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableString, 4));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.CompletedShipmentSummary} returns this
*/
proto.vtv1.CompletedShipmentSummary.prototype.setDestinationId = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.CompletedShipmentSummary} returns this
 */
proto.vtv1.CompletedShipmentSummary.prototype.clearDestinationId = function() {
  return this.setDestinationId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.CompletedShipmentSummary.prototype.hasDestinationId = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string destination_name = 5;
 * @return {string}
 */
proto.vtv1.CompletedShipmentSummary.prototype.getDestinationName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.CompletedShipmentSummary} returns this
 */
proto.vtv1.CompletedShipmentSummary.prototype.setDestinationName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string node_id = 6;
 * @return {string}
 */
proto.vtv1.CompletedShipmentSummary.prototype.getNodeId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.CompletedShipmentSummary} returns this
 */
proto.vtv1.CompletedShipmentSummary.prototype.setNodeId = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string node_name = 7;
 * @return {string}
 */
proto.vtv1.CompletedShipmentSummary.prototype.getNodeName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.CompletedShipmentSummary} returns this
 */
proto.vtv1.CompletedShipmentSummary.prototype.setNodeName = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string product_id = 8;
 * @return {string}
 */
proto.vtv1.CompletedShipmentSummary.prototype.getProductId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.CompletedShipmentSummary} returns this
 */
proto.vtv1.CompletedShipmentSummary.prototype.setProductId = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string product_name = 9;
 * @return {string}
 */
proto.vtv1.CompletedShipmentSummary.prototype.getProductName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.CompletedShipmentSummary} returns this
 */
proto.vtv1.CompletedShipmentSummary.prototype.setProductName = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional int64 unit_id = 10;
 * @return {number}
 */
proto.vtv1.CompletedShipmentSummary.prototype.getUnitId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.CompletedShipmentSummary} returns this
 */
proto.vtv1.CompletedShipmentSummary.prototype.setUnitId = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional string unit_name = 11;
 * @return {string}
 */
proto.vtv1.CompletedShipmentSummary.prototype.getUnitName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.CompletedShipmentSummary} returns this
 */
proto.vtv1.CompletedShipmentSummary.prototype.setUnitName = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string unit_abbreviation = 12;
 * @return {string}
 */
proto.vtv1.CompletedShipmentSummary.prototype.getUnitAbbreviation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.CompletedShipmentSummary} returns this
 */
proto.vtv1.CompletedShipmentSummary.prototype.setUnitAbbreviation = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional double quantity = 13;
 * @return {number}
 */
proto.vtv1.CompletedShipmentSummary.prototype.getQuantity = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 13, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.CompletedShipmentSummary} returns this
 */
proto.vtv1.CompletedShipmentSummary.prototype.setQuantity = function(value) {
  return jspb.Message.setProto3FloatField(this, 13, value);
};


/**
 * optional string driver_id = 14;
 * @return {string}
 */
proto.vtv1.CompletedShipmentSummary.prototype.getDriverId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.CompletedShipmentSummary} returns this
 */
proto.vtv1.CompletedShipmentSummary.prototype.setDriverId = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string driver_name = 15;
 * @return {string}
 */
proto.vtv1.CompletedShipmentSummary.prototype.getDriverName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.CompletedShipmentSummary} returns this
 */
proto.vtv1.CompletedShipmentSummary.prototype.setDriverName = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional NullableString exception_message = 16;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.CompletedShipmentSummary.prototype.getExceptionMessage = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableString, 16));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.CompletedShipmentSummary} returns this
*/
proto.vtv1.CompletedShipmentSummary.prototype.setExceptionMessage = function(value) {
  return jspb.Message.setWrapperField(this, 16, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.CompletedShipmentSummary} returns this
 */
proto.vtv1.CompletedShipmentSummary.prototype.clearExceptionMessage = function() {
  return this.setExceptionMessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.CompletedShipmentSummary.prototype.hasExceptionMessage = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * optional ShipmentStatus status = 17;
 * @return {!proto.vtv1.ShipmentStatus}
 */
proto.vtv1.CompletedShipmentSummary.prototype.getStatus = function() {
  return /** @type {!proto.vtv1.ShipmentStatus} */ (jspb.Message.getFieldWithDefault(this, 17, 0));
};


/**
 * @param {!proto.vtv1.ShipmentStatus} value
 * @return {!proto.vtv1.CompletedShipmentSummary} returns this
 */
proto.vtv1.CompletedShipmentSummary.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 17, value);
};


/**
 * optional string completed_at = 18;
 * @return {string}
 */
proto.vtv1.CompletedShipmentSummary.prototype.getCompletedAt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.CompletedShipmentSummary} returns this
 */
proto.vtv1.CompletedShipmentSummary.prototype.setCompletedAt = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};


/**
 * optional NullableString order_id = 19;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.CompletedShipmentSummary.prototype.getOrderId = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableString, 19));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.CompletedShipmentSummary} returns this
*/
proto.vtv1.CompletedShipmentSummary.prototype.setOrderId = function(value) {
  return jspb.Message.setWrapperField(this, 19, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.CompletedShipmentSummary} returns this
 */
proto.vtv1.CompletedShipmentSummary.prototype.clearOrderId = function() {
  return this.setOrderId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.CompletedShipmentSummary.prototype.hasOrderId = function() {
  return jspb.Message.getField(this, 19) != null;
};


/**
 * optional string updated_at = 20;
 * @return {string}
 */
proto.vtv1.CompletedShipmentSummary.prototype.getUpdatedAt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.CompletedShipmentSummary} returns this
 */
proto.vtv1.CompletedShipmentSummary.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};


/**
 * optional bool has_backhaul = 21;
 * @return {boolean}
 */
proto.vtv1.CompletedShipmentSummary.prototype.getHasBackhaul = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 21, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vtv1.CompletedShipmentSummary} returns this
 */
proto.vtv1.CompletedShipmentSummary.prototype.setHasBackhaul = function(value) {
  return jspb.Message.setProto3BooleanField(this, 21, value);
};


/**
 * repeated string product_names = 22;
 * @return {!Array<string>}
 */
proto.vtv1.CompletedShipmentSummary.prototype.getProductNamesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 22));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.vtv1.CompletedShipmentSummary} returns this
 */
proto.vtv1.CompletedShipmentSummary.prototype.setProductNamesList = function(value) {
  return jspb.Message.setField(this, 22, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.vtv1.CompletedShipmentSummary} returns this
 */
proto.vtv1.CompletedShipmentSummary.prototype.addProductNames = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 22, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.CompletedShipmentSummary} returns this
 */
proto.vtv1.CompletedShipmentSummary.prototype.clearProductNamesList = function() {
  return this.setProductNamesList([]);
};


/**
 * repeated string asset_names = 23;
 * @return {!Array<string>}
 */
proto.vtv1.CompletedShipmentSummary.prototype.getAssetNamesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 23));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.vtv1.CompletedShipmentSummary} returns this
 */
proto.vtv1.CompletedShipmentSummary.prototype.setAssetNamesList = function(value) {
  return jspb.Message.setField(this, 23, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.vtv1.CompletedShipmentSummary} returns this
 */
proto.vtv1.CompletedShipmentSummary.prototype.addAssetNames = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 23, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.CompletedShipmentSummary} returns this
 */
proto.vtv1.CompletedShipmentSummary.prototype.clearAssetNamesList = function() {
  return this.setAssetNamesList([]);
};


/**
 * optional NullableString equipment_order_id = 24;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.CompletedShipmentSummary.prototype.getEquipmentOrderId = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableString, 24));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.CompletedShipmentSummary} returns this
*/
proto.vtv1.CompletedShipmentSummary.prototype.setEquipmentOrderId = function(value) {
  return jspb.Message.setWrapperField(this, 24, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.CompletedShipmentSummary} returns this
 */
proto.vtv1.CompletedShipmentSummary.prototype.clearEquipmentOrderId = function() {
  return this.setEquipmentOrderId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.CompletedShipmentSummary.prototype.hasEquipmentOrderId = function() {
  return jspb.Message.getField(this, 24) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.DriverScheduleEntry.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.DriverScheduleEntry.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.DriverScheduleEntry.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.DriverScheduleEntry} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.DriverScheduleEntry.toObject = function(includeInstance, msg) {
  var f, obj = {
    shipmentId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    startsAt: jspb.Message.getFieldWithDefault(msg, 2, ""),
    endsAt: jspb.Message.getFieldWithDefault(msg, 3, ""),
    waitingTimesList: jspb.Message.toObjectList(msg.getWaitingTimesList(),
    proto.vtv1.WaitingTime.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.DriverScheduleEntry}
 */
proto.vtv1.DriverScheduleEntry.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.DriverScheduleEntry;
  return proto.vtv1.DriverScheduleEntry.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.DriverScheduleEntry} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.DriverScheduleEntry}
 */
proto.vtv1.DriverScheduleEntry.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setShipmentId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setStartsAt(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setEndsAt(value);
      break;
    case 4:
      var value = new proto.vtv1.WaitingTime;
      reader.readMessage(value,proto.vtv1.WaitingTime.deserializeBinaryFromReader);
      msg.addWaitingTimes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.DriverScheduleEntry.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.DriverScheduleEntry.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.DriverScheduleEntry} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.DriverScheduleEntry.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getShipmentId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStartsAt();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getEndsAt();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getWaitingTimesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.vtv1.WaitingTime.serializeBinaryToWriter
    );
  }
};


/**
 * optional string shipment_id = 1;
 * @return {string}
 */
proto.vtv1.DriverScheduleEntry.prototype.getShipmentId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.DriverScheduleEntry} returns this
 */
proto.vtv1.DriverScheduleEntry.prototype.setShipmentId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string starts_at = 2;
 * @return {string}
 */
proto.vtv1.DriverScheduleEntry.prototype.getStartsAt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.DriverScheduleEntry} returns this
 */
proto.vtv1.DriverScheduleEntry.prototype.setStartsAt = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string ends_at = 3;
 * @return {string}
 */
proto.vtv1.DriverScheduleEntry.prototype.getEndsAt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.DriverScheduleEntry} returns this
 */
proto.vtv1.DriverScheduleEntry.prototype.setEndsAt = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated WaitingTime waiting_times = 4;
 * @return {!Array<!proto.vtv1.WaitingTime>}
 */
proto.vtv1.DriverScheduleEntry.prototype.getWaitingTimesList = function() {
  return /** @type{!Array<!proto.vtv1.WaitingTime>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vtv1.WaitingTime, 4));
};


/**
 * @param {!Array<!proto.vtv1.WaitingTime>} value
 * @return {!proto.vtv1.DriverScheduleEntry} returns this
*/
proto.vtv1.DriverScheduleEntry.prototype.setWaitingTimesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.vtv1.WaitingTime=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.WaitingTime}
 */
proto.vtv1.DriverScheduleEntry.prototype.addWaitingTimes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.vtv1.WaitingTime, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.DriverScheduleEntry} returns this
 */
proto.vtv1.DriverScheduleEntry.prototype.clearWaitingTimesList = function() {
  return this.setWaitingTimesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.WaitingTime.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.WaitingTime.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.WaitingTime} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.WaitingTime.toObject = function(includeInstance, msg) {
  var f, obj = {
    startsAt: jspb.Message.getFieldWithDefault(msg, 1, ""),
    endsAt: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.WaitingTime}
 */
proto.vtv1.WaitingTime.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.WaitingTime;
  return proto.vtv1.WaitingTime.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.WaitingTime} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.WaitingTime}
 */
proto.vtv1.WaitingTime.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setStartsAt(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setEndsAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.WaitingTime.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.WaitingTime.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.WaitingTime} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.WaitingTime.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStartsAt();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEndsAt();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string starts_at = 1;
 * @return {string}
 */
proto.vtv1.WaitingTime.prototype.getStartsAt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.WaitingTime} returns this
 */
proto.vtv1.WaitingTime.prototype.setStartsAt = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string ends_at = 2;
 * @return {string}
 */
proto.vtv1.WaitingTime.prototype.getEndsAt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.WaitingTime} returns this
 */
proto.vtv1.WaitingTime.prototype.setEndsAt = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.DriverSchedule.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.DriverSchedule.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.DriverSchedule.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.DriverSchedule} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.DriverSchedule.toObject = function(includeInstance, msg) {
  var f, obj = {
    entriesList: jspb.Message.toObjectList(msg.getEntriesList(),
    proto.vtv1.DriverScheduleEntry.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.DriverSchedule}
 */
proto.vtv1.DriverSchedule.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.DriverSchedule;
  return proto.vtv1.DriverSchedule.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.DriverSchedule} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.DriverSchedule}
 */
proto.vtv1.DriverSchedule.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.vtv1.DriverScheduleEntry;
      reader.readMessage(value,proto.vtv1.DriverScheduleEntry.deserializeBinaryFromReader);
      msg.addEntries(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.DriverSchedule.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.DriverSchedule.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.DriverSchedule} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.DriverSchedule.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEntriesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.vtv1.DriverScheduleEntry.serializeBinaryToWriter
    );
  }
};


/**
 * repeated DriverScheduleEntry entries = 1;
 * @return {!Array<!proto.vtv1.DriverScheduleEntry>}
 */
proto.vtv1.DriverSchedule.prototype.getEntriesList = function() {
  return /** @type{!Array<!proto.vtv1.DriverScheduleEntry>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vtv1.DriverScheduleEntry, 1));
};


/**
 * @param {!Array<!proto.vtv1.DriverScheduleEntry>} value
 * @return {!proto.vtv1.DriverSchedule} returns this
*/
proto.vtv1.DriverSchedule.prototype.setEntriesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.vtv1.DriverScheduleEntry=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.DriverScheduleEntry}
 */
proto.vtv1.DriverSchedule.prototype.addEntries = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.vtv1.DriverScheduleEntry, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.DriverSchedule} returns this
 */
proto.vtv1.DriverSchedule.prototype.clearEntriesList = function() {
  return this.setEntriesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.CalendarResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.CalendarResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.CalendarResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.CalendarResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.CalendarResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    driversList: jspb.Message.toObjectList(msg.getDriversList(),
    proto.vtv1.CalendarDriver.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.CalendarResponse}
 */
proto.vtv1.CalendarResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.CalendarResponse;
  return proto.vtv1.CalendarResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.CalendarResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.CalendarResponse}
 */
proto.vtv1.CalendarResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.vtv1.CalendarDriver;
      reader.readMessage(value,proto.vtv1.CalendarDriver.deserializeBinaryFromReader);
      msg.addDrivers(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.CalendarResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.CalendarResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.CalendarResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.CalendarResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDriversList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.vtv1.CalendarDriver.serializeBinaryToWriter
    );
  }
};


/**
 * repeated CalendarDriver drivers = 1;
 * @return {!Array<!proto.vtv1.CalendarDriver>}
 */
proto.vtv1.CalendarResponse.prototype.getDriversList = function() {
  return /** @type{!Array<!proto.vtv1.CalendarDriver>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vtv1.CalendarDriver, 1));
};


/**
 * @param {!Array<!proto.vtv1.CalendarDriver>} value
 * @return {!proto.vtv1.CalendarResponse} returns this
*/
proto.vtv1.CalendarResponse.prototype.setDriversList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.vtv1.CalendarDriver=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.CalendarDriver}
 */
proto.vtv1.CalendarResponse.prototype.addDrivers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.vtv1.CalendarDriver, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.CalendarResponse} returns this
 */
proto.vtv1.CalendarResponse.prototype.clearDriversList = function() {
  return this.setDriversList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.CalendarDriver.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.CalendarDriver.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.CalendarDriver.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.CalendarDriver} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.CalendarDriver.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    phoneNumber: jspb.Message.getFieldWithDefault(msg, 3, ""),
    shiftsList: jspb.Message.toObjectList(msg.getShiftsList(),
    proto.vtv1.CalendarDriverShift.toObject, includeInstance),
    groupId: jspb.Message.getFieldWithDefault(msg, 5, ""),
    groupName: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.CalendarDriver}
 */
proto.vtv1.CalendarDriver.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.CalendarDriver;
  return proto.vtv1.CalendarDriver.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.CalendarDriver} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.CalendarDriver}
 */
proto.vtv1.CalendarDriver.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhoneNumber(value);
      break;
    case 4:
      var value = new proto.vtv1.CalendarDriverShift;
      reader.readMessage(value,proto.vtv1.CalendarDriverShift.deserializeBinaryFromReader);
      msg.addShifts(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setGroupId(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setGroupName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.CalendarDriver.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.CalendarDriver.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.CalendarDriver} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.CalendarDriver.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPhoneNumber();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getShiftsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.vtv1.CalendarDriverShift.serializeBinaryToWriter
    );
  }
  f = message.getGroupId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getGroupName();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.vtv1.CalendarDriver.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.CalendarDriver} returns this
 */
proto.vtv1.CalendarDriver.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.vtv1.CalendarDriver.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.CalendarDriver} returns this
 */
proto.vtv1.CalendarDriver.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string phone_number = 3;
 * @return {string}
 */
proto.vtv1.CalendarDriver.prototype.getPhoneNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.CalendarDriver} returns this
 */
proto.vtv1.CalendarDriver.prototype.setPhoneNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated CalendarDriverShift shifts = 4;
 * @return {!Array<!proto.vtv1.CalendarDriverShift>}
 */
proto.vtv1.CalendarDriver.prototype.getShiftsList = function() {
  return /** @type{!Array<!proto.vtv1.CalendarDriverShift>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vtv1.CalendarDriverShift, 4));
};


/**
 * @param {!Array<!proto.vtv1.CalendarDriverShift>} value
 * @return {!proto.vtv1.CalendarDriver} returns this
*/
proto.vtv1.CalendarDriver.prototype.setShiftsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.vtv1.CalendarDriverShift=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.CalendarDriverShift}
 */
proto.vtv1.CalendarDriver.prototype.addShifts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.vtv1.CalendarDriverShift, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.CalendarDriver} returns this
 */
proto.vtv1.CalendarDriver.prototype.clearShiftsList = function() {
  return this.setShiftsList([]);
};


/**
 * optional string group_id = 5;
 * @return {string}
 */
proto.vtv1.CalendarDriver.prototype.getGroupId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.CalendarDriver} returns this
 */
proto.vtv1.CalendarDriver.prototype.setGroupId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string group_name = 6;
 * @return {string}
 */
proto.vtv1.CalendarDriver.prototype.getGroupName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.CalendarDriver} returns this
 */
proto.vtv1.CalendarDriver.prototype.setGroupName = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.CalendarDriverShift.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.CalendarDriverShift.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.CalendarDriverShift.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.CalendarDriverShift} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.CalendarDriverShift.toObject = function(includeInstance, msg) {
  var f, obj = {
    shiftId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    startTime: jspb.Message.getFieldWithDefault(msg, 2, ""),
    endTime: jspb.Message.getFieldWithDefault(msg, 3, ""),
    shipmentsList: jspb.Message.toObjectList(msg.getShipmentsList(),
    proto.vtv1.CalendarDriverShipment.toObject, includeInstance),
    trailerTypeId: jspb.Message.getFieldWithDefault(msg, 5, 0),
    trailerTypeName: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.CalendarDriverShift}
 */
proto.vtv1.CalendarDriverShift.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.CalendarDriverShift;
  return proto.vtv1.CalendarDriverShift.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.CalendarDriverShift} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.CalendarDriverShift}
 */
proto.vtv1.CalendarDriverShift.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setShiftId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setStartTime(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setEndTime(value);
      break;
    case 4:
      var value = new proto.vtv1.CalendarDriverShipment;
      reader.readMessage(value,proto.vtv1.CalendarDriverShipment.deserializeBinaryFromReader);
      msg.addShipments(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTrailerTypeId(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setTrailerTypeName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.CalendarDriverShift.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.CalendarDriverShift.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.CalendarDriverShift} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.CalendarDriverShift.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getShiftId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getStartTime();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getEndTime();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getShipmentsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.vtv1.CalendarDriverShipment.serializeBinaryToWriter
    );
  }
  f = message.getTrailerTypeId();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getTrailerTypeName();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional int64 shift_id = 1;
 * @return {number}
 */
proto.vtv1.CalendarDriverShift.prototype.getShiftId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.CalendarDriverShift} returns this
 */
proto.vtv1.CalendarDriverShift.prototype.setShiftId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string start_time = 2;
 * @return {string}
 */
proto.vtv1.CalendarDriverShift.prototype.getStartTime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.CalendarDriverShift} returns this
 */
proto.vtv1.CalendarDriverShift.prototype.setStartTime = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string end_time = 3;
 * @return {string}
 */
proto.vtv1.CalendarDriverShift.prototype.getEndTime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.CalendarDriverShift} returns this
 */
proto.vtv1.CalendarDriverShift.prototype.setEndTime = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated CalendarDriverShipment shipments = 4;
 * @return {!Array<!proto.vtv1.CalendarDriverShipment>}
 */
proto.vtv1.CalendarDriverShift.prototype.getShipmentsList = function() {
  return /** @type{!Array<!proto.vtv1.CalendarDriverShipment>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vtv1.CalendarDriverShipment, 4));
};


/**
 * @param {!Array<!proto.vtv1.CalendarDriverShipment>} value
 * @return {!proto.vtv1.CalendarDriverShift} returns this
*/
proto.vtv1.CalendarDriverShift.prototype.setShipmentsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.vtv1.CalendarDriverShipment=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.CalendarDriverShipment}
 */
proto.vtv1.CalendarDriverShift.prototype.addShipments = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.vtv1.CalendarDriverShipment, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.CalendarDriverShift} returns this
 */
proto.vtv1.CalendarDriverShift.prototype.clearShipmentsList = function() {
  return this.setShipmentsList([]);
};


/**
 * optional int64 trailer_type_id = 5;
 * @return {number}
 */
proto.vtv1.CalendarDriverShift.prototype.getTrailerTypeId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.CalendarDriverShift} returns this
 */
proto.vtv1.CalendarDriverShift.prototype.setTrailerTypeId = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional string trailer_type_name = 6;
 * @return {string}
 */
proto.vtv1.CalendarDriverShift.prototype.getTrailerTypeName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.CalendarDriverShift} returns this
 */
proto.vtv1.CalendarDriverShift.prototype.setTrailerTypeName = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.CalendarShipmentStop.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.CalendarShipmentStop.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.CalendarShipmentStop} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.CalendarShipmentStop.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    shipmentId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    sequence: jspb.Message.getFieldWithDefault(msg, 3, 0),
    siteName: jspb.Message.getFieldWithDefault(msg, 4, ""),
    longitude: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    latitude: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    willSiteBeClosed: jspb.Message.getBooleanFieldWithDefault(msg, 7, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.CalendarShipmentStop}
 */
proto.vtv1.CalendarShipmentStop.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.CalendarShipmentStop;
  return proto.vtv1.CalendarShipmentStop.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.CalendarShipmentStop} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.CalendarShipmentStop}
 */
proto.vtv1.CalendarShipmentStop.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setShipmentId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSequence(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setSiteName(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLongitude(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLatitude(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setWillSiteBeClosed(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.CalendarShipmentStop.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.CalendarShipmentStop.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.CalendarShipmentStop} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.CalendarShipmentStop.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getShipmentId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSequence();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getSiteName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getLongitude();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getLatitude();
  if (f !== 0.0) {
    writer.writeDouble(
      6,
      f
    );
  }
  f = message.getWillSiteBeClosed();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.vtv1.CalendarShipmentStop.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.CalendarShipmentStop} returns this
 */
proto.vtv1.CalendarShipmentStop.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string shipment_id = 2;
 * @return {string}
 */
proto.vtv1.CalendarShipmentStop.prototype.getShipmentId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.CalendarShipmentStop} returns this
 */
proto.vtv1.CalendarShipmentStop.prototype.setShipmentId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 sequence = 3;
 * @return {number}
 */
proto.vtv1.CalendarShipmentStop.prototype.getSequence = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.CalendarShipmentStop} returns this
 */
proto.vtv1.CalendarShipmentStop.prototype.setSequence = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string site_name = 4;
 * @return {string}
 */
proto.vtv1.CalendarShipmentStop.prototype.getSiteName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.CalendarShipmentStop} returns this
 */
proto.vtv1.CalendarShipmentStop.prototype.setSiteName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional double longitude = 5;
 * @return {number}
 */
proto.vtv1.CalendarShipmentStop.prototype.getLongitude = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.CalendarShipmentStop} returns this
 */
proto.vtv1.CalendarShipmentStop.prototype.setLongitude = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional double latitude = 6;
 * @return {number}
 */
proto.vtv1.CalendarShipmentStop.prototype.getLatitude = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.CalendarShipmentStop} returns this
 */
proto.vtv1.CalendarShipmentStop.prototype.setLatitude = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional bool will_site_be_closed = 7;
 * @return {boolean}
 */
proto.vtv1.CalendarShipmentStop.prototype.getWillSiteBeClosed = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vtv1.CalendarShipmentStop} returns this
 */
proto.vtv1.CalendarShipmentStop.prototype.setWillSiteBeClosed = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.CalendarDriverShipment.repeatedFields_ = [8,16,17];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.CalendarDriverShipment.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.CalendarDriverShipment.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.CalendarDriverShipment} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.CalendarDriverShipment.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    status: jspb.Message.getFieldWithDefault(msg, 2, 0),
    nodeName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    destinationName: jspb.Message.getFieldWithDefault(msg, 4, ""),
    productQuantity: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    productUnitName: jspb.Message.getFieldWithDefault(msg, 6, ""),
    productName: jspb.Message.getFieldWithDefault(msg, 7, ""),
    stopsList: jspb.Message.toObjectList(msg.getStopsList(),
    proto.vtv1.CalendarShipmentStop.toObject, includeInstance),
    driverlongitude: (f = msg.getDriverlongitude()) && proto_vorto_types_types_pb.NullableDouble.toObject(includeInstance, f),
    driverlatitude: (f = msg.getDriverlatitude()) && proto_vorto_types_types_pb.NullableDouble.toObject(includeInstance, f),
    startTime: jspb.Message.getFieldWithDefault(msg, 11, ""),
    endTime: jspb.Message.getFieldWithDefault(msg, 12, ""),
    trailerTypeId: jspb.Message.getFieldWithDefault(msg, 13, ""),
    trailerTypeName: jspb.Message.getFieldWithDefault(msg, 14, ""),
    orderId: jspb.Message.getFieldWithDefault(msg, 15, ""),
    productNamesList: (f = jspb.Message.getRepeatedField(msg, 16)) == null ? undefined : f,
    assetNamesList: (f = jspb.Message.getRepeatedField(msg, 17)) == null ? undefined : f,
    equipmentOrderId: (f = msg.getEquipmentOrderId()) && proto_vorto_types_types_pb.NullableString.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.CalendarDriverShipment}
 */
proto.vtv1.CalendarDriverShipment.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.CalendarDriverShipment;
  return proto.vtv1.CalendarDriverShipment.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.CalendarDriverShipment} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.CalendarDriverShipment}
 */
proto.vtv1.CalendarDriverShipment.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {!proto.vtv1.ShipmentStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setNodeName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDestinationName(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setProductQuantity(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductUnitName(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductName(value);
      break;
    case 8:
      var value = new proto.vtv1.CalendarShipmentStop;
      reader.readMessage(value,proto.vtv1.CalendarShipmentStop.deserializeBinaryFromReader);
      msg.addStops(value);
      break;
    case 9:
      var value = new proto_vorto_types_types_pb.NullableDouble;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableDouble.deserializeBinaryFromReader);
      msg.setDriverlongitude(value);
      break;
    case 10:
      var value = new proto_vorto_types_types_pb.NullableDouble;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableDouble.deserializeBinaryFromReader);
      msg.setDriverlatitude(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setStartTime(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setEndTime(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setTrailerTypeId(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setTrailerTypeName(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrderId(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.addProductNames(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.addAssetNames(value);
      break;
    case 18:
      var value = new proto_vorto_types_types_pb.NullableString;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableString.deserializeBinaryFromReader);
      msg.setEquipmentOrderId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.CalendarDriverShipment.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.CalendarDriverShipment.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.CalendarDriverShipment} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.CalendarDriverShipment.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getNodeName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDestinationName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getProductQuantity();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getProductUnitName();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getProductName();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getStopsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      8,
      f,
      proto.vtv1.CalendarShipmentStop.serializeBinaryToWriter
    );
  }
  f = message.getDriverlongitude();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto_vorto_types_types_pb.NullableDouble.serializeBinaryToWriter
    );
  }
  f = message.getDriverlatitude();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto_vorto_types_types_pb.NullableDouble.serializeBinaryToWriter
    );
  }
  f = message.getStartTime();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getEndTime();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getTrailerTypeId();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getTrailerTypeName();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getOrderId();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getProductNamesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      16,
      f
    );
  }
  f = message.getAssetNamesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      17,
      f
    );
  }
  f = message.getEquipmentOrderId();
  if (f != null) {
    writer.writeMessage(
      18,
      f,
      proto_vorto_types_types_pb.NullableString.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.vtv1.CalendarDriverShipment.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.CalendarDriverShipment} returns this
 */
proto.vtv1.CalendarDriverShipment.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional ShipmentStatus status = 2;
 * @return {!proto.vtv1.ShipmentStatus}
 */
proto.vtv1.CalendarDriverShipment.prototype.getStatus = function() {
  return /** @type {!proto.vtv1.ShipmentStatus} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.vtv1.ShipmentStatus} value
 * @return {!proto.vtv1.CalendarDriverShipment} returns this
 */
proto.vtv1.CalendarDriverShipment.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional string node_name = 3;
 * @return {string}
 */
proto.vtv1.CalendarDriverShipment.prototype.getNodeName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.CalendarDriverShipment} returns this
 */
proto.vtv1.CalendarDriverShipment.prototype.setNodeName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string destination_name = 4;
 * @return {string}
 */
proto.vtv1.CalendarDriverShipment.prototype.getDestinationName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.CalendarDriverShipment} returns this
 */
proto.vtv1.CalendarDriverShipment.prototype.setDestinationName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional double product_quantity = 5;
 * @return {number}
 */
proto.vtv1.CalendarDriverShipment.prototype.getProductQuantity = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.CalendarDriverShipment} returns this
 */
proto.vtv1.CalendarDriverShipment.prototype.setProductQuantity = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional string product_unit_name = 6;
 * @return {string}
 */
proto.vtv1.CalendarDriverShipment.prototype.getProductUnitName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.CalendarDriverShipment} returns this
 */
proto.vtv1.CalendarDriverShipment.prototype.setProductUnitName = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string product_name = 7;
 * @return {string}
 */
proto.vtv1.CalendarDriverShipment.prototype.getProductName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.CalendarDriverShipment} returns this
 */
proto.vtv1.CalendarDriverShipment.prototype.setProductName = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * repeated CalendarShipmentStop stops = 8;
 * @return {!Array<!proto.vtv1.CalendarShipmentStop>}
 */
proto.vtv1.CalendarDriverShipment.prototype.getStopsList = function() {
  return /** @type{!Array<!proto.vtv1.CalendarShipmentStop>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vtv1.CalendarShipmentStop, 8));
};


/**
 * @param {!Array<!proto.vtv1.CalendarShipmentStop>} value
 * @return {!proto.vtv1.CalendarDriverShipment} returns this
*/
proto.vtv1.CalendarDriverShipment.prototype.setStopsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 8, value);
};


/**
 * @param {!proto.vtv1.CalendarShipmentStop=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.CalendarShipmentStop}
 */
proto.vtv1.CalendarDriverShipment.prototype.addStops = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 8, opt_value, proto.vtv1.CalendarShipmentStop, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.CalendarDriverShipment} returns this
 */
proto.vtv1.CalendarDriverShipment.prototype.clearStopsList = function() {
  return this.setStopsList([]);
};


/**
 * optional NullableDouble driverLongitude = 9;
 * @return {?proto.vtv1.NullableDouble}
 */
proto.vtv1.CalendarDriverShipment.prototype.getDriverlongitude = function() {
  return /** @type{?proto.vtv1.NullableDouble} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableDouble, 9));
};


/**
 * @param {?proto.vtv1.NullableDouble|undefined} value
 * @return {!proto.vtv1.CalendarDriverShipment} returns this
*/
proto.vtv1.CalendarDriverShipment.prototype.setDriverlongitude = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.CalendarDriverShipment} returns this
 */
proto.vtv1.CalendarDriverShipment.prototype.clearDriverlongitude = function() {
  return this.setDriverlongitude(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.CalendarDriverShipment.prototype.hasDriverlongitude = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional NullableDouble driverLatitude = 10;
 * @return {?proto.vtv1.NullableDouble}
 */
proto.vtv1.CalendarDriverShipment.prototype.getDriverlatitude = function() {
  return /** @type{?proto.vtv1.NullableDouble} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableDouble, 10));
};


/**
 * @param {?proto.vtv1.NullableDouble|undefined} value
 * @return {!proto.vtv1.CalendarDriverShipment} returns this
*/
proto.vtv1.CalendarDriverShipment.prototype.setDriverlatitude = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.CalendarDriverShipment} returns this
 */
proto.vtv1.CalendarDriverShipment.prototype.clearDriverlatitude = function() {
  return this.setDriverlatitude(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.CalendarDriverShipment.prototype.hasDriverlatitude = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional string start_time = 11;
 * @return {string}
 */
proto.vtv1.CalendarDriverShipment.prototype.getStartTime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.CalendarDriverShipment} returns this
 */
proto.vtv1.CalendarDriverShipment.prototype.setStartTime = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string end_time = 12;
 * @return {string}
 */
proto.vtv1.CalendarDriverShipment.prototype.getEndTime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.CalendarDriverShipment} returns this
 */
proto.vtv1.CalendarDriverShipment.prototype.setEndTime = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string trailer_type_id = 13;
 * @return {string}
 */
proto.vtv1.CalendarDriverShipment.prototype.getTrailerTypeId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.CalendarDriverShipment} returns this
 */
proto.vtv1.CalendarDriverShipment.prototype.setTrailerTypeId = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string trailer_type_name = 14;
 * @return {string}
 */
proto.vtv1.CalendarDriverShipment.prototype.getTrailerTypeName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.CalendarDriverShipment} returns this
 */
proto.vtv1.CalendarDriverShipment.prototype.setTrailerTypeName = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string order_id = 15;
 * @return {string}
 */
proto.vtv1.CalendarDriverShipment.prototype.getOrderId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.CalendarDriverShipment} returns this
 */
proto.vtv1.CalendarDriverShipment.prototype.setOrderId = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * repeated string product_names = 16;
 * @return {!Array<string>}
 */
proto.vtv1.CalendarDriverShipment.prototype.getProductNamesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 16));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.vtv1.CalendarDriverShipment} returns this
 */
proto.vtv1.CalendarDriverShipment.prototype.setProductNamesList = function(value) {
  return jspb.Message.setField(this, 16, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.vtv1.CalendarDriverShipment} returns this
 */
proto.vtv1.CalendarDriverShipment.prototype.addProductNames = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 16, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.CalendarDriverShipment} returns this
 */
proto.vtv1.CalendarDriverShipment.prototype.clearProductNamesList = function() {
  return this.setProductNamesList([]);
};


/**
 * repeated string asset_names = 17;
 * @return {!Array<string>}
 */
proto.vtv1.CalendarDriverShipment.prototype.getAssetNamesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 17));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.vtv1.CalendarDriverShipment} returns this
 */
proto.vtv1.CalendarDriverShipment.prototype.setAssetNamesList = function(value) {
  return jspb.Message.setField(this, 17, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.vtv1.CalendarDriverShipment} returns this
 */
proto.vtv1.CalendarDriverShipment.prototype.addAssetNames = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 17, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.CalendarDriverShipment} returns this
 */
proto.vtv1.CalendarDriverShipment.prototype.clearAssetNamesList = function() {
  return this.setAssetNamesList([]);
};


/**
 * optional NullableString equipment_order_id = 18;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.CalendarDriverShipment.prototype.getEquipmentOrderId = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableString, 18));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.CalendarDriverShipment} returns this
*/
proto.vtv1.CalendarDriverShipment.prototype.setEquipmentOrderId = function(value) {
  return jspb.Message.setWrapperField(this, 18, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.CalendarDriverShipment} returns this
 */
proto.vtv1.CalendarDriverShipment.prototype.clearEquipmentOrderId = function() {
  return this.setEquipmentOrderId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.CalendarDriverShipment.prototype.hasEquipmentOrderId = function() {
  return jspb.Message.getField(this, 18) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.PreviousCalendarResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.PreviousCalendarResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.PreviousCalendarResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.PreviousCalendarResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.PreviousCalendarResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    driversList: jspb.Message.toObjectList(msg.getDriversList(),
    proto.vtv1.PreviousCalendarDriver.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.PreviousCalendarResponse}
 */
proto.vtv1.PreviousCalendarResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.PreviousCalendarResponse;
  return proto.vtv1.PreviousCalendarResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.PreviousCalendarResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.PreviousCalendarResponse}
 */
proto.vtv1.PreviousCalendarResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.vtv1.PreviousCalendarDriver;
      reader.readMessage(value,proto.vtv1.PreviousCalendarDriver.deserializeBinaryFromReader);
      msg.addDrivers(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.PreviousCalendarResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.PreviousCalendarResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.PreviousCalendarResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.PreviousCalendarResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDriversList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.vtv1.PreviousCalendarDriver.serializeBinaryToWriter
    );
  }
};


/**
 * repeated PreviousCalendarDriver drivers = 1;
 * @return {!Array<!proto.vtv1.PreviousCalendarDriver>}
 */
proto.vtv1.PreviousCalendarResponse.prototype.getDriversList = function() {
  return /** @type{!Array<!proto.vtv1.PreviousCalendarDriver>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vtv1.PreviousCalendarDriver, 1));
};


/**
 * @param {!Array<!proto.vtv1.PreviousCalendarDriver>} value
 * @return {!proto.vtv1.PreviousCalendarResponse} returns this
*/
proto.vtv1.PreviousCalendarResponse.prototype.setDriversList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.vtv1.PreviousCalendarDriver=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.PreviousCalendarDriver}
 */
proto.vtv1.PreviousCalendarResponse.prototype.addDrivers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.vtv1.PreviousCalendarDriver, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.PreviousCalendarResponse} returns this
 */
proto.vtv1.PreviousCalendarResponse.prototype.clearDriversList = function() {
  return this.setDriversList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.PreviousCalendarDriver.repeatedFields_ = [4,5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.PreviousCalendarDriver.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.PreviousCalendarDriver.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.PreviousCalendarDriver} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.PreviousCalendarDriver.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    phoneNumber: jspb.Message.getFieldWithDefault(msg, 3, ""),
    shiftsList: jspb.Message.toObjectList(msg.getShiftsList(),
    proto.vtv1.PreviousCalendarDriverShift.toObject, includeInstance),
    shipmentsList: jspb.Message.toObjectList(msg.getShipmentsList(),
    proto.vtv1.PreviousCalendarDriverShipment.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.PreviousCalendarDriver}
 */
proto.vtv1.PreviousCalendarDriver.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.PreviousCalendarDriver;
  return proto.vtv1.PreviousCalendarDriver.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.PreviousCalendarDriver} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.PreviousCalendarDriver}
 */
proto.vtv1.PreviousCalendarDriver.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhoneNumber(value);
      break;
    case 4:
      var value = new proto.vtv1.PreviousCalendarDriverShift;
      reader.readMessage(value,proto.vtv1.PreviousCalendarDriverShift.deserializeBinaryFromReader);
      msg.addShifts(value);
      break;
    case 5:
      var value = new proto.vtv1.PreviousCalendarDriverShipment;
      reader.readMessage(value,proto.vtv1.PreviousCalendarDriverShipment.deserializeBinaryFromReader);
      msg.addShipments(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.PreviousCalendarDriver.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.PreviousCalendarDriver.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.PreviousCalendarDriver} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.PreviousCalendarDriver.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPhoneNumber();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getShiftsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.vtv1.PreviousCalendarDriverShift.serializeBinaryToWriter
    );
  }
  f = message.getShipmentsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.vtv1.PreviousCalendarDriverShipment.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.vtv1.PreviousCalendarDriver.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.PreviousCalendarDriver} returns this
 */
proto.vtv1.PreviousCalendarDriver.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.vtv1.PreviousCalendarDriver.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.PreviousCalendarDriver} returns this
 */
proto.vtv1.PreviousCalendarDriver.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string phone_number = 3;
 * @return {string}
 */
proto.vtv1.PreviousCalendarDriver.prototype.getPhoneNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.PreviousCalendarDriver} returns this
 */
proto.vtv1.PreviousCalendarDriver.prototype.setPhoneNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated PreviousCalendarDriverShift shifts = 4;
 * @return {!Array<!proto.vtv1.PreviousCalendarDriverShift>}
 */
proto.vtv1.PreviousCalendarDriver.prototype.getShiftsList = function() {
  return /** @type{!Array<!proto.vtv1.PreviousCalendarDriverShift>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vtv1.PreviousCalendarDriverShift, 4));
};


/**
 * @param {!Array<!proto.vtv1.PreviousCalendarDriverShift>} value
 * @return {!proto.vtv1.PreviousCalendarDriver} returns this
*/
proto.vtv1.PreviousCalendarDriver.prototype.setShiftsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.vtv1.PreviousCalendarDriverShift=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.PreviousCalendarDriverShift}
 */
proto.vtv1.PreviousCalendarDriver.prototype.addShifts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.vtv1.PreviousCalendarDriverShift, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.PreviousCalendarDriver} returns this
 */
proto.vtv1.PreviousCalendarDriver.prototype.clearShiftsList = function() {
  return this.setShiftsList([]);
};


/**
 * repeated PreviousCalendarDriverShipment shipments = 5;
 * @return {!Array<!proto.vtv1.PreviousCalendarDriverShipment>}
 */
proto.vtv1.PreviousCalendarDriver.prototype.getShipmentsList = function() {
  return /** @type{!Array<!proto.vtv1.PreviousCalendarDriverShipment>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vtv1.PreviousCalendarDriverShipment, 5));
};


/**
 * @param {!Array<!proto.vtv1.PreviousCalendarDriverShipment>} value
 * @return {!proto.vtv1.PreviousCalendarDriver} returns this
*/
proto.vtv1.PreviousCalendarDriver.prototype.setShipmentsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.vtv1.PreviousCalendarDriverShipment=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.PreviousCalendarDriverShipment}
 */
proto.vtv1.PreviousCalendarDriver.prototype.addShipments = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.vtv1.PreviousCalendarDriverShipment, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.PreviousCalendarDriver} returns this
 */
proto.vtv1.PreviousCalendarDriver.prototype.clearShipmentsList = function() {
  return this.setShipmentsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.PreviousCalendarDriverShift.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.PreviousCalendarDriverShift.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.PreviousCalendarDriverShift} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.PreviousCalendarDriverShift.toObject = function(includeInstance, msg) {
  var f, obj = {
    shiftId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    startTime: jspb.Message.getFieldWithDefault(msg, 2, ""),
    endTime: jspb.Message.getFieldWithDefault(msg, 3, ""),
    trailerTypeId: jspb.Message.getFieldWithDefault(msg, 5, 0),
    trailerTypeName: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.PreviousCalendarDriverShift}
 */
proto.vtv1.PreviousCalendarDriverShift.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.PreviousCalendarDriverShift;
  return proto.vtv1.PreviousCalendarDriverShift.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.PreviousCalendarDriverShift} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.PreviousCalendarDriverShift}
 */
proto.vtv1.PreviousCalendarDriverShift.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setShiftId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setStartTime(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setEndTime(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTrailerTypeId(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setTrailerTypeName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.PreviousCalendarDriverShift.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.PreviousCalendarDriverShift.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.PreviousCalendarDriverShift} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.PreviousCalendarDriverShift.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getShiftId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getStartTime();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getEndTime();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getTrailerTypeId();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getTrailerTypeName();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional int64 shift_id = 1;
 * @return {number}
 */
proto.vtv1.PreviousCalendarDriverShift.prototype.getShiftId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.PreviousCalendarDriverShift} returns this
 */
proto.vtv1.PreviousCalendarDriverShift.prototype.setShiftId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string start_time = 2;
 * @return {string}
 */
proto.vtv1.PreviousCalendarDriverShift.prototype.getStartTime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.PreviousCalendarDriverShift} returns this
 */
proto.vtv1.PreviousCalendarDriverShift.prototype.setStartTime = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string end_time = 3;
 * @return {string}
 */
proto.vtv1.PreviousCalendarDriverShift.prototype.getEndTime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.PreviousCalendarDriverShift} returns this
 */
proto.vtv1.PreviousCalendarDriverShift.prototype.setEndTime = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int64 trailer_type_id = 5;
 * @return {number}
 */
proto.vtv1.PreviousCalendarDriverShift.prototype.getTrailerTypeId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.PreviousCalendarDriverShift} returns this
 */
proto.vtv1.PreviousCalendarDriverShift.prototype.setTrailerTypeId = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional string trailer_type_name = 6;
 * @return {string}
 */
proto.vtv1.PreviousCalendarDriverShift.prototype.getTrailerTypeName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.PreviousCalendarDriverShift} returns this
 */
proto.vtv1.PreviousCalendarDriverShift.prototype.setTrailerTypeName = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.PreviousCalendarDriverShipment.repeatedFields_ = [7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.PreviousCalendarDriverShipment.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.PreviousCalendarDriverShipment.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.PreviousCalendarDriverShipment} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.PreviousCalendarDriverShipment.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    status: jspb.Message.getFieldWithDefault(msg, 2, 0),
    nodeName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    productQuantity: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    productUnitName: jspb.Message.getFieldWithDefault(msg, 5, ""),
    productName: jspb.Message.getFieldWithDefault(msg, 6, ""),
    stopsList: jspb.Message.toObjectList(msg.getStopsList(),
    proto.vtv1.PreviousCalendarShipmentStop.toObject, includeInstance),
    startTime: jspb.Message.getFieldWithDefault(msg, 8, ""),
    endTime: jspb.Message.getFieldWithDefault(msg, 9, ""),
    orderId: jspb.Message.getFieldWithDefault(msg, 10, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.PreviousCalendarDriverShipment}
 */
proto.vtv1.PreviousCalendarDriverShipment.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.PreviousCalendarDriverShipment;
  return proto.vtv1.PreviousCalendarDriverShipment.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.PreviousCalendarDriverShipment} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.PreviousCalendarDriverShipment}
 */
proto.vtv1.PreviousCalendarDriverShipment.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {!proto.vtv1.ShipmentStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setNodeName(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setProductQuantity(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductUnitName(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductName(value);
      break;
    case 7:
      var value = new proto.vtv1.PreviousCalendarShipmentStop;
      reader.readMessage(value,proto.vtv1.PreviousCalendarShipmentStop.deserializeBinaryFromReader);
      msg.addStops(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setStartTime(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setEndTime(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrderId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.PreviousCalendarDriverShipment.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.PreviousCalendarDriverShipment.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.PreviousCalendarDriverShipment} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.PreviousCalendarDriverShipment.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getNodeName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getProductQuantity();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getProductUnitName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getProductName();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getStopsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto.vtv1.PreviousCalendarShipmentStop.serializeBinaryToWriter
    );
  }
  f = message.getStartTime();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getEndTime();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getOrderId();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.vtv1.PreviousCalendarDriverShipment.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.PreviousCalendarDriverShipment} returns this
 */
proto.vtv1.PreviousCalendarDriverShipment.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional ShipmentStatus status = 2;
 * @return {!proto.vtv1.ShipmentStatus}
 */
proto.vtv1.PreviousCalendarDriverShipment.prototype.getStatus = function() {
  return /** @type {!proto.vtv1.ShipmentStatus} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.vtv1.ShipmentStatus} value
 * @return {!proto.vtv1.PreviousCalendarDriverShipment} returns this
 */
proto.vtv1.PreviousCalendarDriverShipment.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional string node_name = 3;
 * @return {string}
 */
proto.vtv1.PreviousCalendarDriverShipment.prototype.getNodeName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.PreviousCalendarDriverShipment} returns this
 */
proto.vtv1.PreviousCalendarDriverShipment.prototype.setNodeName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional double product_quantity = 4;
 * @return {number}
 */
proto.vtv1.PreviousCalendarDriverShipment.prototype.getProductQuantity = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.PreviousCalendarDriverShipment} returns this
 */
proto.vtv1.PreviousCalendarDriverShipment.prototype.setProductQuantity = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional string product_unit_name = 5;
 * @return {string}
 */
proto.vtv1.PreviousCalendarDriverShipment.prototype.getProductUnitName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.PreviousCalendarDriverShipment} returns this
 */
proto.vtv1.PreviousCalendarDriverShipment.prototype.setProductUnitName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string product_name = 6;
 * @return {string}
 */
proto.vtv1.PreviousCalendarDriverShipment.prototype.getProductName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.PreviousCalendarDriverShipment} returns this
 */
proto.vtv1.PreviousCalendarDriverShipment.prototype.setProductName = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * repeated PreviousCalendarShipmentStop stops = 7;
 * @return {!Array<!proto.vtv1.PreviousCalendarShipmentStop>}
 */
proto.vtv1.PreviousCalendarDriverShipment.prototype.getStopsList = function() {
  return /** @type{!Array<!proto.vtv1.PreviousCalendarShipmentStop>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vtv1.PreviousCalendarShipmentStop, 7));
};


/**
 * @param {!Array<!proto.vtv1.PreviousCalendarShipmentStop>} value
 * @return {!proto.vtv1.PreviousCalendarDriverShipment} returns this
*/
proto.vtv1.PreviousCalendarDriverShipment.prototype.setStopsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.vtv1.PreviousCalendarShipmentStop=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.PreviousCalendarShipmentStop}
 */
proto.vtv1.PreviousCalendarDriverShipment.prototype.addStops = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.vtv1.PreviousCalendarShipmentStop, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.PreviousCalendarDriverShipment} returns this
 */
proto.vtv1.PreviousCalendarDriverShipment.prototype.clearStopsList = function() {
  return this.setStopsList([]);
};


/**
 * optional string start_time = 8;
 * @return {string}
 */
proto.vtv1.PreviousCalendarDriverShipment.prototype.getStartTime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.PreviousCalendarDriverShipment} returns this
 */
proto.vtv1.PreviousCalendarDriverShipment.prototype.setStartTime = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string end_time = 9;
 * @return {string}
 */
proto.vtv1.PreviousCalendarDriverShipment.prototype.getEndTime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.PreviousCalendarDriverShipment} returns this
 */
proto.vtv1.PreviousCalendarDriverShipment.prototype.setEndTime = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string order_id = 10;
 * @return {string}
 */
proto.vtv1.PreviousCalendarDriverShipment.prototype.getOrderId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.PreviousCalendarDriverShipment} returns this
 */
proto.vtv1.PreviousCalendarDriverShipment.prototype.setOrderId = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.PreviousCalendarShipmentStop.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.PreviousCalendarShipmentStop.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.PreviousCalendarShipmentStop} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.PreviousCalendarShipmentStop.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    shipmentId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    sequence: jspb.Message.getFieldWithDefault(msg, 3, 0),
    siteName: jspb.Message.getFieldWithDefault(msg, 4, ""),
    longitude: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    latitude: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    completedAt: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.PreviousCalendarShipmentStop}
 */
proto.vtv1.PreviousCalendarShipmentStop.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.PreviousCalendarShipmentStop;
  return proto.vtv1.PreviousCalendarShipmentStop.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.PreviousCalendarShipmentStop} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.PreviousCalendarShipmentStop}
 */
proto.vtv1.PreviousCalendarShipmentStop.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setShipmentId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSequence(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setSiteName(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLongitude(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLatitude(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompletedAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.PreviousCalendarShipmentStop.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.PreviousCalendarShipmentStop.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.PreviousCalendarShipmentStop} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.PreviousCalendarShipmentStop.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getShipmentId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSequence();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getSiteName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getLongitude();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getLatitude();
  if (f !== 0.0) {
    writer.writeDouble(
      6,
      f
    );
  }
  f = message.getCompletedAt();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.vtv1.PreviousCalendarShipmentStop.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.PreviousCalendarShipmentStop} returns this
 */
proto.vtv1.PreviousCalendarShipmentStop.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string shipment_id = 2;
 * @return {string}
 */
proto.vtv1.PreviousCalendarShipmentStop.prototype.getShipmentId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.PreviousCalendarShipmentStop} returns this
 */
proto.vtv1.PreviousCalendarShipmentStop.prototype.setShipmentId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 sequence = 3;
 * @return {number}
 */
proto.vtv1.PreviousCalendarShipmentStop.prototype.getSequence = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.PreviousCalendarShipmentStop} returns this
 */
proto.vtv1.PreviousCalendarShipmentStop.prototype.setSequence = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string site_name = 4;
 * @return {string}
 */
proto.vtv1.PreviousCalendarShipmentStop.prototype.getSiteName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.PreviousCalendarShipmentStop} returns this
 */
proto.vtv1.PreviousCalendarShipmentStop.prototype.setSiteName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional double longitude = 5;
 * @return {number}
 */
proto.vtv1.PreviousCalendarShipmentStop.prototype.getLongitude = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.PreviousCalendarShipmentStop} returns this
 */
proto.vtv1.PreviousCalendarShipmentStop.prototype.setLongitude = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional double latitude = 6;
 * @return {number}
 */
proto.vtv1.PreviousCalendarShipmentStop.prototype.getLatitude = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.PreviousCalendarShipmentStop} returns this
 */
proto.vtv1.PreviousCalendarShipmentStop.prototype.setLatitude = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional string completed_at = 7;
 * @return {string}
 */
proto.vtv1.PreviousCalendarShipmentStop.prototype.getCompletedAt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.PreviousCalendarShipmentStop} returns this
 */
proto.vtv1.PreviousCalendarShipmentStop.prototype.setCompletedAt = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.CreateShipmentTask.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.CreateShipmentTask.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.CreateShipmentTask} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.CreateShipmentTask.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    action: jspb.Message.getFieldWithDefault(msg, 2, 0),
    productId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    quantity: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.CreateShipmentTask}
 */
proto.vtv1.CreateShipmentTask.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.CreateShipmentTask;
  return proto.vtv1.CreateShipmentTask.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.CreateShipmentTask} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.CreateShipmentTask}
 */
proto.vtv1.CreateShipmentTask.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {!proto.vtv1.TaskAction} */ (reader.readEnum());
      msg.setAction(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductId(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setQuantity(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.CreateShipmentTask.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.CreateShipmentTask.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.CreateShipmentTask} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.CreateShipmentTask.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getAction();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getProductId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getQuantity();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.vtv1.CreateShipmentTask.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.CreateShipmentTask} returns this
 */
proto.vtv1.CreateShipmentTask.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional TaskAction action = 2;
 * @return {!proto.vtv1.TaskAction}
 */
proto.vtv1.CreateShipmentTask.prototype.getAction = function() {
  return /** @type {!proto.vtv1.TaskAction} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.vtv1.TaskAction} value
 * @return {!proto.vtv1.CreateShipmentTask} returns this
 */
proto.vtv1.CreateShipmentTask.prototype.setAction = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional string product_id = 3;
 * @return {string}
 */
proto.vtv1.CreateShipmentTask.prototype.getProductId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.CreateShipmentTask} returns this
 */
proto.vtv1.CreateShipmentTask.prototype.setProductId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional double quantity = 4;
 * @return {number}
 */
proto.vtv1.CreateShipmentTask.prototype.getQuantity = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.CreateShipmentTask} returns this
 */
proto.vtv1.CreateShipmentTask.prototype.setQuantity = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.NonSite.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.NonSite.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.NonSite} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.NonSite.toObject = function(includeInstance, msg) {
  var f, obj = {
    address: jspb.Message.getFieldWithDefault(msg, 1, ""),
    latitude: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    longitude: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    responsibleParty: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.NonSite}
 */
proto.vtv1.NonSite.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.NonSite;
  return proto.vtv1.NonSite.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.NonSite} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.NonSite}
 */
proto.vtv1.NonSite.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddress(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLatitude(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLongitude(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setResponsibleParty(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.NonSite.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.NonSite.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.NonSite} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.NonSite.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAddress();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLatitude();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getLongitude();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getResponsibleParty();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string address = 1;
 * @return {string}
 */
proto.vtv1.NonSite.prototype.getAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.NonSite} returns this
 */
proto.vtv1.NonSite.prototype.setAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional double latitude = 2;
 * @return {number}
 */
proto.vtv1.NonSite.prototype.getLatitude = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.NonSite} returns this
 */
proto.vtv1.NonSite.prototype.setLatitude = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double longitude = 3;
 * @return {number}
 */
proto.vtv1.NonSite.prototype.getLongitude = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.NonSite} returns this
 */
proto.vtv1.NonSite.prototype.setLongitude = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional string responsible_party = 4;
 * @return {string}
 */
proto.vtv1.NonSite.prototype.getResponsibleParty = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.NonSite} returns this
 */
proto.vtv1.NonSite.prototype.setResponsibleParty = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.CreateShipmentStop.repeatedFields_ = [7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.CreateShipmentStop.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.CreateShipmentStop.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.CreateShipmentStop} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.CreateShipmentStop.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    sequence: jspb.Message.getFieldWithDefault(msg, 2, 0),
    siteId: (f = msg.getSiteId()) && proto_vorto_types_types_pb.NullableString.toObject(includeInstance, f),
    arrivalWindowStartsAt: (f = msg.getArrivalWindowStartsAt()) && proto_vorto_types_types_pb.NullableTime.toObject(includeInstance, f),
    arrivalWindowMinutes: (f = msg.getArrivalWindowMinutes()) && proto_vorto_types_types_pb.NullableInt64.toObject(includeInstance, f),
    nonSite: (f = msg.getNonSite()) && proto.vtv1.NonSite.toObject(includeInstance, f),
    tasksList: jspb.Message.toObjectList(msg.getTasksList(),
    proto.vtv1.CreateShipmentTask.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.CreateShipmentStop}
 */
proto.vtv1.CreateShipmentStop.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.CreateShipmentStop;
  return proto.vtv1.CreateShipmentStop.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.CreateShipmentStop} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.CreateShipmentStop}
 */
proto.vtv1.CreateShipmentStop.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSequence(value);
      break;
    case 3:
      var value = new proto_vorto_types_types_pb.NullableString;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableString.deserializeBinaryFromReader);
      msg.setSiteId(value);
      break;
    case 4:
      var value = new proto_vorto_types_types_pb.NullableTime;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableTime.deserializeBinaryFromReader);
      msg.setArrivalWindowStartsAt(value);
      break;
    case 5:
      var value = new proto_vorto_types_types_pb.NullableInt64;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableInt64.deserializeBinaryFromReader);
      msg.setArrivalWindowMinutes(value);
      break;
    case 6:
      var value = new proto.vtv1.NonSite;
      reader.readMessage(value,proto.vtv1.NonSite.deserializeBinaryFromReader);
      msg.setNonSite(value);
      break;
    case 7:
      var value = new proto.vtv1.CreateShipmentTask;
      reader.readMessage(value,proto.vtv1.CreateShipmentTask.deserializeBinaryFromReader);
      msg.addTasks(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.CreateShipmentStop.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.CreateShipmentStop.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.CreateShipmentStop} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.CreateShipmentStop.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getSequence();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getSiteId();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto_vorto_types_types_pb.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getArrivalWindowStartsAt();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto_vorto_types_types_pb.NullableTime.serializeBinaryToWriter
    );
  }
  f = message.getArrivalWindowMinutes();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto_vorto_types_types_pb.NullableInt64.serializeBinaryToWriter
    );
  }
  f = message.getNonSite();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.vtv1.NonSite.serializeBinaryToWriter
    );
  }
  f = message.getTasksList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto.vtv1.CreateShipmentTask.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.vtv1.CreateShipmentStop.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.CreateShipmentStop} returns this
 */
proto.vtv1.CreateShipmentStop.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 sequence = 2;
 * @return {number}
 */
proto.vtv1.CreateShipmentStop.prototype.getSequence = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.CreateShipmentStop} returns this
 */
proto.vtv1.CreateShipmentStop.prototype.setSequence = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional NullableString site_id = 3;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.CreateShipmentStop.prototype.getSiteId = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableString, 3));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.CreateShipmentStop} returns this
*/
proto.vtv1.CreateShipmentStop.prototype.setSiteId = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.CreateShipmentStop} returns this
 */
proto.vtv1.CreateShipmentStop.prototype.clearSiteId = function() {
  return this.setSiteId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.CreateShipmentStop.prototype.hasSiteId = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional NullableTime arrival_window_starts_at = 4;
 * @return {?proto.vtv1.NullableTime}
 */
proto.vtv1.CreateShipmentStop.prototype.getArrivalWindowStartsAt = function() {
  return /** @type{?proto.vtv1.NullableTime} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableTime, 4));
};


/**
 * @param {?proto.vtv1.NullableTime|undefined} value
 * @return {!proto.vtv1.CreateShipmentStop} returns this
*/
proto.vtv1.CreateShipmentStop.prototype.setArrivalWindowStartsAt = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.CreateShipmentStop} returns this
 */
proto.vtv1.CreateShipmentStop.prototype.clearArrivalWindowStartsAt = function() {
  return this.setArrivalWindowStartsAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.CreateShipmentStop.prototype.hasArrivalWindowStartsAt = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional NullableInt64 arrival_window_minutes = 5;
 * @return {?proto.vtv1.NullableInt64}
 */
proto.vtv1.CreateShipmentStop.prototype.getArrivalWindowMinutes = function() {
  return /** @type{?proto.vtv1.NullableInt64} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableInt64, 5));
};


/**
 * @param {?proto.vtv1.NullableInt64|undefined} value
 * @return {!proto.vtv1.CreateShipmentStop} returns this
*/
proto.vtv1.CreateShipmentStop.prototype.setArrivalWindowMinutes = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.CreateShipmentStop} returns this
 */
proto.vtv1.CreateShipmentStop.prototype.clearArrivalWindowMinutes = function() {
  return this.setArrivalWindowMinutes(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.CreateShipmentStop.prototype.hasArrivalWindowMinutes = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional NonSite non_site = 6;
 * @return {?proto.vtv1.NonSite}
 */
proto.vtv1.CreateShipmentStop.prototype.getNonSite = function() {
  return /** @type{?proto.vtv1.NonSite} */ (
    jspb.Message.getWrapperField(this, proto.vtv1.NonSite, 6));
};


/**
 * @param {?proto.vtv1.NonSite|undefined} value
 * @return {!proto.vtv1.CreateShipmentStop} returns this
*/
proto.vtv1.CreateShipmentStop.prototype.setNonSite = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.CreateShipmentStop} returns this
 */
proto.vtv1.CreateShipmentStop.prototype.clearNonSite = function() {
  return this.setNonSite(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.CreateShipmentStop.prototype.hasNonSite = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * repeated CreateShipmentTask tasks = 7;
 * @return {!Array<!proto.vtv1.CreateShipmentTask>}
 */
proto.vtv1.CreateShipmentStop.prototype.getTasksList = function() {
  return /** @type{!Array<!proto.vtv1.CreateShipmentTask>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vtv1.CreateShipmentTask, 7));
};


/**
 * @param {!Array<!proto.vtv1.CreateShipmentTask>} value
 * @return {!proto.vtv1.CreateShipmentStop} returns this
*/
proto.vtv1.CreateShipmentStop.prototype.setTasksList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.vtv1.CreateShipmentTask=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.CreateShipmentTask}
 */
proto.vtv1.CreateShipmentStop.prototype.addTasks = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.vtv1.CreateShipmentTask, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.CreateShipmentStop} returns this
 */
proto.vtv1.CreateShipmentStop.prototype.clearTasksList = function() {
  return this.setTasksList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.CreateShipment.repeatedFields_ = [7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.CreateShipment.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.CreateShipment.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.CreateShipment} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.CreateShipment.toObject = function(includeInstance, msg) {
  var f, obj = {
    shipperId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    edgeContractId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    shipmentStatus: jspb.Message.getFieldWithDefault(msg, 3, 0),
    description: (f = msg.getDescription()) && proto_vorto_types_types_pb.NullableString.toObject(includeInstance, f),
    ticketNumber: (f = msg.getTicketNumber()) && proto_vorto_types_types_pb.NullableString.toObject(includeInstance, f),
    bolNumber: (f = msg.getBolNumber()) && proto_vorto_types_types_pb.NullableString.toObject(includeInstance, f),
    stopsList: jspb.Message.toObjectList(msg.getStopsList(),
    proto.vtv1.CreateShipmentStop.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.CreateShipment}
 */
proto.vtv1.CreateShipment.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.CreateShipment;
  return proto.vtv1.CreateShipment.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.CreateShipment} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.CreateShipment}
 */
proto.vtv1.CreateShipment.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setShipperId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setEdgeContractId(value);
      break;
    case 3:
      var value = /** @type {!proto.vtv1.ShipmentStatus} */ (reader.readEnum());
      msg.setShipmentStatus(value);
      break;
    case 4:
      var value = new proto_vorto_types_types_pb.NullableString;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableString.deserializeBinaryFromReader);
      msg.setDescription(value);
      break;
    case 5:
      var value = new proto_vorto_types_types_pb.NullableString;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableString.deserializeBinaryFromReader);
      msg.setTicketNumber(value);
      break;
    case 6:
      var value = new proto_vorto_types_types_pb.NullableString;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableString.deserializeBinaryFromReader);
      msg.setBolNumber(value);
      break;
    case 7:
      var value = new proto.vtv1.CreateShipmentStop;
      reader.readMessage(value,proto.vtv1.CreateShipmentStop.deserializeBinaryFromReader);
      msg.addStops(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.CreateShipment.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.CreateShipment.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.CreateShipment} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.CreateShipment.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getShipperId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEdgeContractId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getShipmentStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getDescription();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto_vorto_types_types_pb.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getTicketNumber();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto_vorto_types_types_pb.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getBolNumber();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto_vorto_types_types_pb.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getStopsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto.vtv1.CreateShipmentStop.serializeBinaryToWriter
    );
  }
};


/**
 * optional string shipper_id = 1;
 * @return {string}
 */
proto.vtv1.CreateShipment.prototype.getShipperId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.CreateShipment} returns this
 */
proto.vtv1.CreateShipment.prototype.setShipperId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string edge_contract_id = 2;
 * @return {string}
 */
proto.vtv1.CreateShipment.prototype.getEdgeContractId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.CreateShipment} returns this
 */
proto.vtv1.CreateShipment.prototype.setEdgeContractId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional ShipmentStatus shipment_status = 3;
 * @return {!proto.vtv1.ShipmentStatus}
 */
proto.vtv1.CreateShipment.prototype.getShipmentStatus = function() {
  return /** @type {!proto.vtv1.ShipmentStatus} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.vtv1.ShipmentStatus} value
 * @return {!proto.vtv1.CreateShipment} returns this
 */
proto.vtv1.CreateShipment.prototype.setShipmentStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional NullableString description = 4;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.CreateShipment.prototype.getDescription = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableString, 4));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.CreateShipment} returns this
*/
proto.vtv1.CreateShipment.prototype.setDescription = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.CreateShipment} returns this
 */
proto.vtv1.CreateShipment.prototype.clearDescription = function() {
  return this.setDescription(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.CreateShipment.prototype.hasDescription = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional NullableString ticket_number = 5;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.CreateShipment.prototype.getTicketNumber = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableString, 5));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.CreateShipment} returns this
*/
proto.vtv1.CreateShipment.prototype.setTicketNumber = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.CreateShipment} returns this
 */
proto.vtv1.CreateShipment.prototype.clearTicketNumber = function() {
  return this.setTicketNumber(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.CreateShipment.prototype.hasTicketNumber = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional NullableString bol_number = 6;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.CreateShipment.prototype.getBolNumber = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableString, 6));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.CreateShipment} returns this
*/
proto.vtv1.CreateShipment.prototype.setBolNumber = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.CreateShipment} returns this
 */
proto.vtv1.CreateShipment.prototype.clearBolNumber = function() {
  return this.setBolNumber(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.CreateShipment.prototype.hasBolNumber = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * repeated CreateShipmentStop stops = 7;
 * @return {!Array<!proto.vtv1.CreateShipmentStop>}
 */
proto.vtv1.CreateShipment.prototype.getStopsList = function() {
  return /** @type{!Array<!proto.vtv1.CreateShipmentStop>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vtv1.CreateShipmentStop, 7));
};


/**
 * @param {!Array<!proto.vtv1.CreateShipmentStop>} value
 * @return {!proto.vtv1.CreateShipment} returns this
*/
proto.vtv1.CreateShipment.prototype.setStopsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.vtv1.CreateShipmentStop=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.CreateShipmentStop}
 */
proto.vtv1.CreateShipment.prototype.addStops = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.vtv1.CreateShipmentStop, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.CreateShipment} returns this
 */
proto.vtv1.CreateShipment.prototype.clearStopsList = function() {
  return this.setStopsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.ShipmentQuantities.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.ShipmentQuantities.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.ShipmentQuantities} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.ShipmentQuantities.toObject = function(includeInstance, msg) {
  var f, obj = {
    productId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    productName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    quantity: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    actualQuantity: (f = msg.getActualQuantity()) && proto_vorto_types_types_pb.NullableDouble.toObject(includeInstance, f),
    unit: (f = msg.getUnit()) && proto_vorto_types_types_pb.Unit.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.ShipmentQuantities}
 */
proto.vtv1.ShipmentQuantities.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.ShipmentQuantities;
  return proto.vtv1.ShipmentQuantities.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.ShipmentQuantities} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.ShipmentQuantities}
 */
proto.vtv1.ShipmentQuantities.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductName(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setQuantity(value);
      break;
    case 4:
      var value = new proto_vorto_types_types_pb.NullableDouble;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableDouble.deserializeBinaryFromReader);
      msg.setActualQuantity(value);
      break;
    case 5:
      var value = new proto_vorto_types_types_pb.Unit;
      reader.readMessage(value,proto_vorto_types_types_pb.Unit.deserializeBinaryFromReader);
      msg.setUnit(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.ShipmentQuantities.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.ShipmentQuantities.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.ShipmentQuantities} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.ShipmentQuantities.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProductId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getProductName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getQuantity();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getActualQuantity();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto_vorto_types_types_pb.NullableDouble.serializeBinaryToWriter
    );
  }
  f = message.getUnit();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto_vorto_types_types_pb.Unit.serializeBinaryToWriter
    );
  }
};


/**
 * optional string product_id = 1;
 * @return {string}
 */
proto.vtv1.ShipmentQuantities.prototype.getProductId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.ShipmentQuantities} returns this
 */
proto.vtv1.ShipmentQuantities.prototype.setProductId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string product_name = 2;
 * @return {string}
 */
proto.vtv1.ShipmentQuantities.prototype.getProductName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.ShipmentQuantities} returns this
 */
proto.vtv1.ShipmentQuantities.prototype.setProductName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional double quantity = 3;
 * @return {number}
 */
proto.vtv1.ShipmentQuantities.prototype.getQuantity = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.ShipmentQuantities} returns this
 */
proto.vtv1.ShipmentQuantities.prototype.setQuantity = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional NullableDouble actual_quantity = 4;
 * @return {?proto.vtv1.NullableDouble}
 */
proto.vtv1.ShipmentQuantities.prototype.getActualQuantity = function() {
  return /** @type{?proto.vtv1.NullableDouble} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableDouble, 4));
};


/**
 * @param {?proto.vtv1.NullableDouble|undefined} value
 * @return {!proto.vtv1.ShipmentQuantities} returns this
*/
proto.vtv1.ShipmentQuantities.prototype.setActualQuantity = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.ShipmentQuantities} returns this
 */
proto.vtv1.ShipmentQuantities.prototype.clearActualQuantity = function() {
  return this.setActualQuantity(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.ShipmentQuantities.prototype.hasActualQuantity = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional Unit unit = 5;
 * @return {?proto.vtv1.Unit}
 */
proto.vtv1.ShipmentQuantities.prototype.getUnit = function() {
  return /** @type{?proto.vtv1.Unit} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.Unit, 5));
};


/**
 * @param {?proto.vtv1.Unit|undefined} value
 * @return {!proto.vtv1.ShipmentQuantities} returns this
*/
proto.vtv1.ShipmentQuantities.prototype.setUnit = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.ShipmentQuantities} returns this
 */
proto.vtv1.ShipmentQuantities.prototype.clearUnit = function() {
  return this.setUnit(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.ShipmentQuantities.prototype.hasUnit = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * @enum {number}
 */
proto.vtv1.UFGStatus = {
  PRIORITY: 0,
  NON_PRIORITY: 1,
  UP_FOR_GRABS: 2
};

goog.object.extend(exports, proto.vtv1);
