import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyPipe } from '@angular/common';
import { NullableInt64 } from '~proto/types/types_pb';

const isNullInt = (pennies: any): pennies is NullableInt64.AsObject => {
  return !!pennies && typeof pennies === 'object' && 'valid' in pennies && 'pb_int' in pennies;
};

@Pipe({
  name: 'currencyInPennies',
})
export class CurrencyInPenniesPipe extends CurrencyPipe implements PipeTransform {
  public transform(value: any, ...args: any[]): any {
    if (isNullInt(value)) {
      if (value.valid) {
        return super.transform(value.pb_int / 100, ...args);
      }
      return super.transform(null, ...args);
    }
    if (!isNaN(value)) {
      return super.transform(value / 100, ...args);
    }
    return super.transform(value, ...args);
  }
}
