import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject, Observable, of, lastValueFrom } from 'rxjs';
import { GrpcService } from '../grpc.service';
import { Empty, FlatProduct, StringIdName, StringId, SlimSite, ActiveSite } from '~proto/types/types_pb';
import { map, shareReplay, throttleTime, catchError, distinctUntilChanged } from 'rxjs/operators';
import { sort } from 'remeda';
import { sortByName } from 'src/app/shared/utilities/commonSorters';
import { idArrayToRecord } from 'src/app/shared/utilities/idArrayToRecord';
import { NodeService } from '~proto/node/node_api_pb_service';

@Injectable({
  providedIn: 'root',
})
export class ConsumerSitesService {
  private mySites$$ = new BehaviorSubject<Record<string, StringIdName.AsObject>>({});
  private mySitesThrottle$$ = new Subject<void>();
  private mySitesSharedObservable$: Observable<StringIdName.AsObject[]> = this.mySites$$.asObservable().pipe(
    map((allShippers) => Object.values(allShippers)),
    map(sort(sortByName)),
    shareReplay(1),
  );
  public get mySites$(): Observable<StringIdName.AsObject[]> {
    this.mySitesThrottle$$.next();
    return this.mySitesSharedObservable$;
  }

  /* This is the all the sites with inventories */
  private mySitesWithInventories$$ = new BehaviorSubject<ActiveSite.AsObject[]>(null);
  public mySitesWithInventories$: Observable<ActiveSite.AsObject[]> = this.mySitesWithInventories$$.pipe(
    distinctUntilChanged(),
    shareReplay(1),
  );

  /* This is all the sites with batches */
  private mySitesWithBatches$$ = new BehaviorSubject<ActiveSite.AsObject[]>(null);
  public mySitesWithBatches$: Observable<ActiveSite.AsObject[]> = this.mySitesWithBatches$$.pipe(
    distinctUntilChanged(),
    shareReplay(1),
  );

  constructor(private grpc: GrpcService) {
    this.mySitesThrottle$$.pipe(throttleTime(200)).subscribe(() => this.getAllShippers());
    this.getAllSitesWithInventories();
  }

  public getProductsForSite(siteId: string): Observable<FlatProduct.AsObject[]> {
    const req = new StringId();
    req.setId(siteId);
    return this.grpc.invoke$(NodeService.NodeListProductsForSite, req).pipe(
      map((response) => response.toObject().productsList),
      catchError((error) => {
        // any extra error handling here
        return of([] as FlatProduct.AsObject[]);
      }),
    );
  }

  public getFinishedGoodProductsForSite(siteId: string): Observable<FlatProduct.AsObject[]> {
    const req = new StringId();
    req.setId(siteId);
    return this.grpc.invoke$(NodeService.NodeListFinishedGoodsProductsInventoryForSite, req).pipe(
      map((response) => response.toObject().productsList),
      catchError((error) => {
        // any extra error handling here
        return of([] as FlatProduct.AsObject[]);
      }),
    );
  }

  private async getAllShippers() {
    try {
      const request = new Empty();
      const response = await lastValueFrom(this.grpc.invoke$(NodeService.NodeListSites, request));
      const asObject = response.toObject();
      this.mySites$$.next(idArrayToRecord(asObject.sitesList));
    } catch (error) {
      // grpc will already log this just catching to avoid ugliness
    }
  }

  private async getAllSitesWithInventories() {
    try {
      const request = new Empty();
      const response = await lastValueFrom(this.grpc.invoke$(NodeService.NodeListActiveSitesWithInventories, request));
      this.mySitesWithInventories$$.next(response.toObject().sitesList);
    } catch (error) {
      // meh
    }
  } // end of getAllSitesWithInventories

  private async getAllSitesWithBatchesToComplete() {
    try {
      const request = new Empty();
      const response = await lastValueFrom(
        this.grpc.invoke$(NodeService.NodeListActiveSitesWithBatchesToComplete, request),
      );
      this.mySitesWithBatches$$.next(response.toObject().sitesList);
    } catch (error) {
      // meh
    }
  } // end of getAllSitesWithInventories
}
