// package: vtv1
// file: proto/vorto/notification/notification_api.proto

var proto_vorto_notification_notification_api_pb = require("../../../proto/vorto/notification/notification_api_pb");
var proto_vorto_types_types_pb = require("../../../proto/vorto/types/types_pb");
var grpc = require("@improbable-eng/grpc-web").grpc;

var NotificationService = (function () {
  function NotificationService() {}
  NotificationService.serviceName = "vtv1.NotificationService";
  return NotificationService;
}());

NotificationService.UnsubscribeFromNotification = {
  methodName: "UnsubscribeFromNotification",
  service: NotificationService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_notification_notification_api_pb.UpdatePreferenceRequest,
  responseType: proto_vorto_notification_notification_api_pb.NotificationUserPreferencesResponse
};

NotificationService.ResubscribeToNotification = {
  methodName: "ResubscribeToNotification",
  service: NotificationService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_notification_notification_api_pb.UpdatePreferenceRequest,
  responseType: proto_vorto_notification_notification_api_pb.NotificationUserPreferencesResponse
};

NotificationService.ListPreferences = {
  methodName: "ListPreferences",
  service: NotificationService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_types_types_pb.Empty,
  responseType: proto_vorto_notification_notification_api_pb.NotificationUserPreferencesResponse
};

NotificationService.UpdateNotificationFrequency = {
  methodName: "UpdateNotificationFrequency",
  service: NotificationService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_notification_notification_api_pb.UpdatePreferenceRequest,
  responseType: proto_vorto_notification_notification_api_pb.NotificationUserPreferencesResponse
};

exports.NotificationService = NotificationService;

function NotificationServiceClient(serviceHost, options) {
  this.serviceHost = serviceHost;
  this.options = options || {};
}

NotificationServiceClient.prototype.unsubscribeFromNotification = function unsubscribeFromNotification(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(NotificationService.UnsubscribeFromNotification, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

NotificationServiceClient.prototype.resubscribeToNotification = function resubscribeToNotification(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(NotificationService.ResubscribeToNotification, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

NotificationServiceClient.prototype.listPreferences = function listPreferences(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(NotificationService.ListPreferences, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

NotificationServiceClient.prototype.updateNotificationFrequency = function updateNotificationFrequency(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(NotificationService.UpdateNotificationFrequency, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

exports.NotificationServiceClient = NotificationServiceClient;

