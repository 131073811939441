import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';

interface DataBar {
  heightPercent: string;
  xStartPercent: string;
  widthPercent: string;
  yStartPercent: string;
}

@Component({
  selector: 'vt-mini-bar-chart',
  templateUrl: './mini-bar-chart.component.svg',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MiniBarChartComponent implements OnInit {
  public processedData: DataBar[];
  @Input() public set data(data: number[]) {
    this.processedData = processData(data);
  }
  constructor() {}

  public ngOnInit(): void {}
}

function processData(data: number[] = []): DataBar[] {
  if (!data || data.length === 0) {
    return [];
  }
  const divisions = data.length * 2 - 1;
  const barWidth = 100 / divisions;
  const highest = Math.max(...data);
  return data.map((datum, index) => {
    const rawPercent = (datum / highest) * 100;
    return {
      heightPercent: `${rawPercent}%`,
      xStartPercent: `${index * barWidth * 2}%`,
      widthPercent: `${barWidth}%`,
      yStartPercent: `${100 - rawPercent}%`,
    };
  });
}
