import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';

const colorClasses = {
  indigo: ' pl-2 pr-2 pt-1 pb-1 font-bold bg-indigo-500 hover:bg-indigo-700 text-white rounded',
  bigIndigo:
    'bg-indigo-500 text-white p-2 w-full font-semibold text-lg text-center justify-center rounded cursor-pointer',
  orange: 'pl-2 pr-2 pt-1 pb-1 bg-orange-500 text-white font-bold rounded',
  altOrange: 'p-2 pt-1 pb-1 border rounded ml-3 text-orange-500 font-semibold p-1',
  altIndigo: 'p-2 pt-1 pb-1 rounded text-indigo-500 hover:text-indigo-700 font-semibold p-1',
  red: 'p-2 pt-1 pb-1 bg-red-500 hover:bg-red-700 text-white font-semibold',
  bigRed: 'bg-red-500 text-white p-2 w-full font-semibold text-lg text-center justify-center rounded cursor-pointer',
  altRed: 'p-2 pt-1 pb-1 rounded text-red-500 hover:text-red-700 font-semibold p-1',
  gray: 'pl-2 pr-2 pt-1 pb-1 font-bold text-black bg-gray-400 hover:bg-gray-600',
  cancelGray: 'pl-2 pr-2 pt-1 pb-1 font-bold text-gray-700',
};

@Component({
  selector: 'vt-button',
  templateUrl: './button.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonComponent implements OnInit {
  public colorClasses = colorClasses.indigo;
  @Input() public type = 'button';
  @Input() public networkActive: boolean;
  @Input() public disabled: boolean;
  @Input() public set color(
    color:
      | 'indigo'
      | 'bigIndigo'
      | 'altOrange'
      | 'altIndigo'
      | 'orange'
      | 'red'
      | 'bigRed'
      | 'altRed'
      | 'gray'
      | 'cancelGray',
  ) {
    this.colorClasses = colorClasses[color];
  }

  constructor() {}

  public ngOnInit(): void {}
}
