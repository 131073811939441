export * from './phone.pipe';
export * from './plus-more.pipe';
export * from './shipment-status.pipe';
export * from './standard-date.pipe';
export * from './task-action.pipe';
export * from './track-by.pipe';
export * from './minutes-to-human.pipe';
export * from './standard-time.pipe';
export * from './npt-from-now.pipe';
export * from './currency-in-pennies.pipe';
export * from './isAfterInMinutes.pipe';
export * from './shipment-progress-status.pipe';
export * from './order-status.pipe';
export * from './invoice-status.pipe';
export * from './scs-status.pipe';
export * from './status-order.pipe';
