// source: proto/vorto/user/user_api.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var proto_vorto_user_user_pb = require('../../../proto/vorto/user/user_pb.js');
goog.object.extend(proto, proto_vorto_user_user_pb);
var proto_vorto_types_types_pb = require('../../../proto/vorto/types/types_pb.js');
goog.object.extend(proto, proto_vorto_types_types_pb);
goog.exportSymbol('proto.vtv1.CreateDriverRequest', null, global);
goog.exportSymbol('proto.vtv1.CreateWebUserRequest', null, global);
goog.exportSymbol('proto.vtv1.EditDriverRequest', null, global);
goog.exportSymbol('proto.vtv1.EditWebUserRequest', null, global);
goog.exportSymbol('proto.vtv1.FindDriverByPhoneNumberRequest', null, global);
goog.exportSymbol('proto.vtv1.GetMyAllowedAccountsResponse', null, global);
goog.exportSymbol('proto.vtv1.ListPermissionsResponse', null, global);
goog.exportSymbol('proto.vtv1.ListUsersResponse', null, global);
goog.exportSymbol('proto.vtv1.MyTokenUserInformationResponse', null, global);
goog.exportSymbol('proto.vtv1.MyUserInformationResponse', null, global);
goog.exportSymbol('proto.vtv1.SetMyAccountResponse', null, global);
goog.exportSymbol('proto.vtv1.SingleUserResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.ListUsersResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.ListUsersResponse.repeatedFields_, null);
};
goog.inherits(proto.vtv1.ListUsersResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.ListUsersResponse.displayName = 'proto.vtv1.ListUsersResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.ListPermissionsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.ListPermissionsResponse.repeatedFields_, null);
};
goog.inherits(proto.vtv1.ListPermissionsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.ListPermissionsResponse.displayName = 'proto.vtv1.ListPermissionsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.CreateWebUserRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.CreateWebUserRequest.repeatedFields_, null);
};
goog.inherits(proto.vtv1.CreateWebUserRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.CreateWebUserRequest.displayName = 'proto.vtv1.CreateWebUserRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.EditWebUserRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.EditWebUserRequest.repeatedFields_, null);
};
goog.inherits(proto.vtv1.EditWebUserRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.EditWebUserRequest.displayName = 'proto.vtv1.EditWebUserRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.CreateDriverRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.CreateDriverRequest.repeatedFields_, null);
};
goog.inherits(proto.vtv1.CreateDriverRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.CreateDriverRequest.displayName = 'proto.vtv1.CreateDriverRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.EditDriverRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.EditDriverRequest.repeatedFields_, null);
};
goog.inherits(proto.vtv1.EditDriverRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.EditDriverRequest.displayName = 'proto.vtv1.EditDriverRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.SingleUserResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.SingleUserResponse.repeatedFields_, null);
};
goog.inherits(proto.vtv1.SingleUserResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.SingleUserResponse.displayName = 'proto.vtv1.SingleUserResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.MyUserInformationResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.MyUserInformationResponse.repeatedFields_, null);
};
goog.inherits(proto.vtv1.MyUserInformationResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.MyUserInformationResponse.displayName = 'proto.vtv1.MyUserInformationResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.FindDriverByPhoneNumberRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.FindDriverByPhoneNumberRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.FindDriverByPhoneNumberRequest.displayName = 'proto.vtv1.FindDriverByPhoneNumberRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.MyTokenUserInformationResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.MyTokenUserInformationResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.MyTokenUserInformationResponse.displayName = 'proto.vtv1.MyTokenUserInformationResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.GetMyAllowedAccountsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.GetMyAllowedAccountsResponse.repeatedFields_, null);
};
goog.inherits(proto.vtv1.GetMyAllowedAccountsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.GetMyAllowedAccountsResponse.displayName = 'proto.vtv1.GetMyAllowedAccountsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.SetMyAccountResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.SetMyAccountResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.SetMyAccountResponse.displayName = 'proto.vtv1.SetMyAccountResponse';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.ListUsersResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.ListUsersResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.ListUsersResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.ListUsersResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.ListUsersResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    usersList: jspb.Message.toObjectList(msg.getUsersList(),
    proto_vorto_user_user_pb.User.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.ListUsersResponse}
 */
proto.vtv1.ListUsersResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.ListUsersResponse;
  return proto.vtv1.ListUsersResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.ListUsersResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.ListUsersResponse}
 */
proto.vtv1.ListUsersResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_vorto_user_user_pb.User;
      reader.readMessage(value,proto_vorto_user_user_pb.User.deserializeBinaryFromReader);
      msg.addUsers(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.ListUsersResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.ListUsersResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.ListUsersResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.ListUsersResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUsersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto_vorto_user_user_pb.User.serializeBinaryToWriter
    );
  }
};


/**
 * repeated User users = 1;
 * @return {!Array<!proto.vtv1.User>}
 */
proto.vtv1.ListUsersResponse.prototype.getUsersList = function() {
  return /** @type{!Array<!proto.vtv1.User>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_vorto_user_user_pb.User, 1));
};


/**
 * @param {!Array<!proto.vtv1.User>} value
 * @return {!proto.vtv1.ListUsersResponse} returns this
*/
proto.vtv1.ListUsersResponse.prototype.setUsersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.vtv1.User=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.User}
 */
proto.vtv1.ListUsersResponse.prototype.addUsers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.vtv1.User, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.ListUsersResponse} returns this
 */
proto.vtv1.ListUsersResponse.prototype.clearUsersList = function() {
  return this.setUsersList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.ListPermissionsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.ListPermissionsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.ListPermissionsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.ListPermissionsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.ListPermissionsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    permissionsList: jspb.Message.toObjectList(msg.getPermissionsList(),
    proto_vorto_user_user_pb.Permission.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.ListPermissionsResponse}
 */
proto.vtv1.ListPermissionsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.ListPermissionsResponse;
  return proto.vtv1.ListPermissionsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.ListPermissionsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.ListPermissionsResponse}
 */
proto.vtv1.ListPermissionsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_vorto_user_user_pb.Permission;
      reader.readMessage(value,proto_vorto_user_user_pb.Permission.deserializeBinaryFromReader);
      msg.addPermissions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.ListPermissionsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.ListPermissionsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.ListPermissionsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.ListPermissionsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPermissionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto_vorto_user_user_pb.Permission.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Permission permissions = 1;
 * @return {!Array<!proto.vtv1.Permission>}
 */
proto.vtv1.ListPermissionsResponse.prototype.getPermissionsList = function() {
  return /** @type{!Array<!proto.vtv1.Permission>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_vorto_user_user_pb.Permission, 1));
};


/**
 * @param {!Array<!proto.vtv1.Permission>} value
 * @return {!proto.vtv1.ListPermissionsResponse} returns this
*/
proto.vtv1.ListPermissionsResponse.prototype.setPermissionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.vtv1.Permission=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.Permission}
 */
proto.vtv1.ListPermissionsResponse.prototype.addPermissions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.vtv1.Permission, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.ListPermissionsResponse} returns this
 */
proto.vtv1.ListPermissionsResponse.prototype.clearPermissionsList = function() {
  return this.setPermissionsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.CreateWebUserRequest.repeatedFields_ = [4,5,7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.CreateWebUserRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.CreateWebUserRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.CreateWebUserRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.CreateWebUserRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    email: jspb.Message.getFieldWithDefault(msg, 2, ""),
    password: (f = msg.getPassword()) && proto_vorto_types_types_pb.NullableString.toObject(includeInstance, f),
    permissionsList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
    userRegionsList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f,
    viewOnly: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    sitesList: (f = jspb.Message.getRepeatedField(msg, 7)) == null ? undefined : f,
    notificationPhoneNumber: (f = msg.getNotificationPhoneNumber()) && proto_vorto_types_types_pb.NullableString.toObject(includeInstance, f),
    inventoryOnly: jspb.Message.getBooleanFieldWithDefault(msg, 9, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.CreateWebUserRequest}
 */
proto.vtv1.CreateWebUserRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.CreateWebUserRequest;
  return proto.vtv1.CreateWebUserRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.CreateWebUserRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.CreateWebUserRequest}
 */
proto.vtv1.CreateWebUserRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 3:
      var value = new proto_vorto_types_types_pb.NullableString;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableString.deserializeBinaryFromReader);
      msg.setPassword(value);
      break;
    case 4:
      var value = /** @type {!Array<number>} */ (reader.readPackedInt64());
      msg.setPermissionsList(value);
      break;
    case 5:
      var value = /** @type {!Array<number>} */ (reader.readPackedInt64());
      msg.setUserRegionsList(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setViewOnly(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.addSites(value);
      break;
    case 8:
      var value = new proto_vorto_types_types_pb.NullableString;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableString.deserializeBinaryFromReader);
      msg.setNotificationPhoneNumber(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setInventoryOnly(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.CreateWebUserRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.CreateWebUserRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.CreateWebUserRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.CreateWebUserRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPassword();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto_vorto_types_types_pb.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getPermissionsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      4,
      f
    );
  }
  f = message.getUserRegionsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      5,
      f
    );
  }
  f = message.getViewOnly();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getSitesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      7,
      f
    );
  }
  f = message.getNotificationPhoneNumber();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto_vorto_types_types_pb.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getInventoryOnly();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.vtv1.CreateWebUserRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.CreateWebUserRequest} returns this
 */
proto.vtv1.CreateWebUserRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string email = 2;
 * @return {string}
 */
proto.vtv1.CreateWebUserRequest.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.CreateWebUserRequest} returns this
 */
proto.vtv1.CreateWebUserRequest.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional NullableString password = 3;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.CreateWebUserRequest.prototype.getPassword = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableString, 3));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.CreateWebUserRequest} returns this
*/
proto.vtv1.CreateWebUserRequest.prototype.setPassword = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.CreateWebUserRequest} returns this
 */
proto.vtv1.CreateWebUserRequest.prototype.clearPassword = function() {
  return this.setPassword(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.CreateWebUserRequest.prototype.hasPassword = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * repeated int64 permissions = 4;
 * @return {!Array<number>}
 */
proto.vtv1.CreateWebUserRequest.prototype.getPermissionsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.vtv1.CreateWebUserRequest} returns this
 */
proto.vtv1.CreateWebUserRequest.prototype.setPermissionsList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.vtv1.CreateWebUserRequest} returns this
 */
proto.vtv1.CreateWebUserRequest.prototype.addPermissions = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.CreateWebUserRequest} returns this
 */
proto.vtv1.CreateWebUserRequest.prototype.clearPermissionsList = function() {
  return this.setPermissionsList([]);
};


/**
 * repeated int64 user_regions = 5;
 * @return {!Array<number>}
 */
proto.vtv1.CreateWebUserRequest.prototype.getUserRegionsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.vtv1.CreateWebUserRequest} returns this
 */
proto.vtv1.CreateWebUserRequest.prototype.setUserRegionsList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.vtv1.CreateWebUserRequest} returns this
 */
proto.vtv1.CreateWebUserRequest.prototype.addUserRegions = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.CreateWebUserRequest} returns this
 */
proto.vtv1.CreateWebUserRequest.prototype.clearUserRegionsList = function() {
  return this.setUserRegionsList([]);
};


/**
 * optional bool view_only = 6;
 * @return {boolean}
 */
proto.vtv1.CreateWebUserRequest.prototype.getViewOnly = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vtv1.CreateWebUserRequest} returns this
 */
proto.vtv1.CreateWebUserRequest.prototype.setViewOnly = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * repeated string sites = 7;
 * @return {!Array<string>}
 */
proto.vtv1.CreateWebUserRequest.prototype.getSitesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 7));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.vtv1.CreateWebUserRequest} returns this
 */
proto.vtv1.CreateWebUserRequest.prototype.setSitesList = function(value) {
  return jspb.Message.setField(this, 7, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.vtv1.CreateWebUserRequest} returns this
 */
proto.vtv1.CreateWebUserRequest.prototype.addSites = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 7, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.CreateWebUserRequest} returns this
 */
proto.vtv1.CreateWebUserRequest.prototype.clearSitesList = function() {
  return this.setSitesList([]);
};


/**
 * optional NullableString notification_phone_number = 8;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.CreateWebUserRequest.prototype.getNotificationPhoneNumber = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableString, 8));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.CreateWebUserRequest} returns this
*/
proto.vtv1.CreateWebUserRequest.prototype.setNotificationPhoneNumber = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.CreateWebUserRequest} returns this
 */
proto.vtv1.CreateWebUserRequest.prototype.clearNotificationPhoneNumber = function() {
  return this.setNotificationPhoneNumber(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.CreateWebUserRequest.prototype.hasNotificationPhoneNumber = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional bool inventory_only = 9;
 * @return {boolean}
 */
proto.vtv1.CreateWebUserRequest.prototype.getInventoryOnly = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vtv1.CreateWebUserRequest} returns this
 */
proto.vtv1.CreateWebUserRequest.prototype.setInventoryOnly = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.EditWebUserRequest.repeatedFields_ = [5,6,8];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.EditWebUserRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.EditWebUserRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.EditWebUserRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.EditWebUserRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: (f = msg.getName()) && proto_vorto_types_types_pb.NullableString.toObject(includeInstance, f),
    email: (f = msg.getEmail()) && proto_vorto_types_types_pb.NullableString.toObject(includeInstance, f),
    permissionsList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f,
    userRegionsList: (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f,
    viewOnly: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    sitesList: (f = jspb.Message.getRepeatedField(msg, 8)) == null ? undefined : f,
    notificationPhoneNumber: (f = msg.getNotificationPhoneNumber()) && proto_vorto_types_types_pb.NullableString.toObject(includeInstance, f),
    inventoryOnly: jspb.Message.getBooleanFieldWithDefault(msg, 10, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.EditWebUserRequest}
 */
proto.vtv1.EditWebUserRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.EditWebUserRequest;
  return proto.vtv1.EditWebUserRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.EditWebUserRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.EditWebUserRequest}
 */
proto.vtv1.EditWebUserRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new proto_vorto_types_types_pb.NullableString;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableString.deserializeBinaryFromReader);
      msg.setName(value);
      break;
    case 3:
      var value = new proto_vorto_types_types_pb.NullableString;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableString.deserializeBinaryFromReader);
      msg.setEmail(value);
      break;
    case 5:
      var value = /** @type {!Array<number>} */ (reader.readPackedInt64());
      msg.setPermissionsList(value);
      break;
    case 6:
      var value = /** @type {!Array<number>} */ (reader.readPackedInt64());
      msg.setUserRegionsList(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setViewOnly(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.addSites(value);
      break;
    case 9:
      var value = new proto_vorto_types_types_pb.NullableString;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableString.deserializeBinaryFromReader);
      msg.setNotificationPhoneNumber(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setInventoryOnly(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.EditWebUserRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.EditWebUserRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.EditWebUserRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.EditWebUserRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_vorto_types_types_pb.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getEmail();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto_vorto_types_types_pb.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getPermissionsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      5,
      f
    );
  }
  f = message.getUserRegionsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      6,
      f
    );
  }
  f = message.getViewOnly();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getSitesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      8,
      f
    );
  }
  f = message.getNotificationPhoneNumber();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto_vorto_types_types_pb.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getInventoryOnly();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.vtv1.EditWebUserRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.EditWebUserRequest} returns this
 */
proto.vtv1.EditWebUserRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional NullableString name = 2;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.EditWebUserRequest.prototype.getName = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableString, 2));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.EditWebUserRequest} returns this
*/
proto.vtv1.EditWebUserRequest.prototype.setName = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.EditWebUserRequest} returns this
 */
proto.vtv1.EditWebUserRequest.prototype.clearName = function() {
  return this.setName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.EditWebUserRequest.prototype.hasName = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional NullableString email = 3;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.EditWebUserRequest.prototype.getEmail = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableString, 3));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.EditWebUserRequest} returns this
*/
proto.vtv1.EditWebUserRequest.prototype.setEmail = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.EditWebUserRequest} returns this
 */
proto.vtv1.EditWebUserRequest.prototype.clearEmail = function() {
  return this.setEmail(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.EditWebUserRequest.prototype.hasEmail = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * repeated int64 permissions = 5;
 * @return {!Array<number>}
 */
proto.vtv1.EditWebUserRequest.prototype.getPermissionsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.vtv1.EditWebUserRequest} returns this
 */
proto.vtv1.EditWebUserRequest.prototype.setPermissionsList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.vtv1.EditWebUserRequest} returns this
 */
proto.vtv1.EditWebUserRequest.prototype.addPermissions = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.EditWebUserRequest} returns this
 */
proto.vtv1.EditWebUserRequest.prototype.clearPermissionsList = function() {
  return this.setPermissionsList([]);
};


/**
 * repeated int64 user_regions = 6;
 * @return {!Array<number>}
 */
proto.vtv1.EditWebUserRequest.prototype.getUserRegionsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 6));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.vtv1.EditWebUserRequest} returns this
 */
proto.vtv1.EditWebUserRequest.prototype.setUserRegionsList = function(value) {
  return jspb.Message.setField(this, 6, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.vtv1.EditWebUserRequest} returns this
 */
proto.vtv1.EditWebUserRequest.prototype.addUserRegions = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.EditWebUserRequest} returns this
 */
proto.vtv1.EditWebUserRequest.prototype.clearUserRegionsList = function() {
  return this.setUserRegionsList([]);
};


/**
 * optional bool view_only = 7;
 * @return {boolean}
 */
proto.vtv1.EditWebUserRequest.prototype.getViewOnly = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vtv1.EditWebUserRequest} returns this
 */
proto.vtv1.EditWebUserRequest.prototype.setViewOnly = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * repeated string sites = 8;
 * @return {!Array<string>}
 */
proto.vtv1.EditWebUserRequest.prototype.getSitesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 8));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.vtv1.EditWebUserRequest} returns this
 */
proto.vtv1.EditWebUserRequest.prototype.setSitesList = function(value) {
  return jspb.Message.setField(this, 8, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.vtv1.EditWebUserRequest} returns this
 */
proto.vtv1.EditWebUserRequest.prototype.addSites = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 8, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.EditWebUserRequest} returns this
 */
proto.vtv1.EditWebUserRequest.prototype.clearSitesList = function() {
  return this.setSitesList([]);
};


/**
 * optional NullableString notification_phone_number = 9;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.EditWebUserRequest.prototype.getNotificationPhoneNumber = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableString, 9));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.EditWebUserRequest} returns this
*/
proto.vtv1.EditWebUserRequest.prototype.setNotificationPhoneNumber = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.EditWebUserRequest} returns this
 */
proto.vtv1.EditWebUserRequest.prototype.clearNotificationPhoneNumber = function() {
  return this.setNotificationPhoneNumber(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.EditWebUserRequest.prototype.hasNotificationPhoneNumber = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional bool inventory_only = 10;
 * @return {boolean}
 */
proto.vtv1.EditWebUserRequest.prototype.getInventoryOnly = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vtv1.EditWebUserRequest} returns this
 */
proto.vtv1.EditWebUserRequest.prototype.setInventoryOnly = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.CreateDriverRequest.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.CreateDriverRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.CreateDriverRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.CreateDriverRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.CreateDriverRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    phoneNumber: jspb.Message.getFieldWithDefault(msg, 2, ""),
    pin: jspb.Message.getFieldWithDefault(msg, 3, ""),
    certIdsList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.CreateDriverRequest}
 */
proto.vtv1.CreateDriverRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.CreateDriverRequest;
  return proto.vtv1.CreateDriverRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.CreateDriverRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.CreateDriverRequest}
 */
proto.vtv1.CreateDriverRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhoneNumber(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPin(value);
      break;
    case 4:
      var value = /** @type {!Array<number>} */ (reader.readPackedInt64());
      msg.setCertIdsList(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.CreateDriverRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.CreateDriverRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.CreateDriverRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.CreateDriverRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPhoneNumber();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPin();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCertIdsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      4,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.vtv1.CreateDriverRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.CreateDriverRequest} returns this
 */
proto.vtv1.CreateDriverRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string phone_number = 2;
 * @return {string}
 */
proto.vtv1.CreateDriverRequest.prototype.getPhoneNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.CreateDriverRequest} returns this
 */
proto.vtv1.CreateDriverRequest.prototype.setPhoneNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string pin = 3;
 * @return {string}
 */
proto.vtv1.CreateDriverRequest.prototype.getPin = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.CreateDriverRequest} returns this
 */
proto.vtv1.CreateDriverRequest.prototype.setPin = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated int64 cert_ids = 4;
 * @return {!Array<number>}
 */
proto.vtv1.CreateDriverRequest.prototype.getCertIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.vtv1.CreateDriverRequest} returns this
 */
proto.vtv1.CreateDriverRequest.prototype.setCertIdsList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.vtv1.CreateDriverRequest} returns this
 */
proto.vtv1.CreateDriverRequest.prototype.addCertIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.CreateDriverRequest} returns this
 */
proto.vtv1.CreateDriverRequest.prototype.clearCertIdsList = function() {
  return this.setCertIdsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.EditDriverRequest.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.EditDriverRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.EditDriverRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.EditDriverRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.EditDriverRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: (f = msg.getName()) && proto_vorto_types_types_pb.NullableString.toObject(includeInstance, f),
    phoneNumber: (f = msg.getPhoneNumber()) && proto_vorto_types_types_pb.NullableString.toObject(includeInstance, f),
    pin: (f = msg.getPin()) && proto_vorto_types_types_pb.NullableString.toObject(includeInstance, f),
    certIdsList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.EditDriverRequest}
 */
proto.vtv1.EditDriverRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.EditDriverRequest;
  return proto.vtv1.EditDriverRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.EditDriverRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.EditDriverRequest}
 */
proto.vtv1.EditDriverRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new proto_vorto_types_types_pb.NullableString;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableString.deserializeBinaryFromReader);
      msg.setName(value);
      break;
    case 3:
      var value = new proto_vorto_types_types_pb.NullableString;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableString.deserializeBinaryFromReader);
      msg.setPhoneNumber(value);
      break;
    case 4:
      var value = new proto_vorto_types_types_pb.NullableString;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableString.deserializeBinaryFromReader);
      msg.setPin(value);
      break;
    case 5:
      var value = /** @type {!Array<number>} */ (reader.readPackedInt64());
      msg.setCertIdsList(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.EditDriverRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.EditDriverRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.EditDriverRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.EditDriverRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_vorto_types_types_pb.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getPhoneNumber();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto_vorto_types_types_pb.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getPin();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto_vorto_types_types_pb.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getCertIdsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      5,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.vtv1.EditDriverRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.EditDriverRequest} returns this
 */
proto.vtv1.EditDriverRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional NullableString name = 2;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.EditDriverRequest.prototype.getName = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableString, 2));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.EditDriverRequest} returns this
*/
proto.vtv1.EditDriverRequest.prototype.setName = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.EditDriverRequest} returns this
 */
proto.vtv1.EditDriverRequest.prototype.clearName = function() {
  return this.setName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.EditDriverRequest.prototype.hasName = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional NullableString phone_number = 3;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.EditDriverRequest.prototype.getPhoneNumber = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableString, 3));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.EditDriverRequest} returns this
*/
proto.vtv1.EditDriverRequest.prototype.setPhoneNumber = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.EditDriverRequest} returns this
 */
proto.vtv1.EditDriverRequest.prototype.clearPhoneNumber = function() {
  return this.setPhoneNumber(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.EditDriverRequest.prototype.hasPhoneNumber = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional NullableString pin = 4;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.EditDriverRequest.prototype.getPin = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableString, 4));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.EditDriverRequest} returns this
*/
proto.vtv1.EditDriverRequest.prototype.setPin = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.EditDriverRequest} returns this
 */
proto.vtv1.EditDriverRequest.prototype.clearPin = function() {
  return this.setPin(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.EditDriverRequest.prototype.hasPin = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * repeated int64 cert_ids = 5;
 * @return {!Array<number>}
 */
proto.vtv1.EditDriverRequest.prototype.getCertIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.vtv1.EditDriverRequest} returns this
 */
proto.vtv1.EditDriverRequest.prototype.setCertIdsList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.vtv1.EditDriverRequest} returns this
 */
proto.vtv1.EditDriverRequest.prototype.addCertIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.EditDriverRequest} returns this
 */
proto.vtv1.EditDriverRequest.prototype.clearCertIdsList = function() {
  return this.setCertIdsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.SingleUserResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.SingleUserResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.SingleUserResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.SingleUserResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.SingleUserResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    user: (f = msg.getUser()) && proto_vorto_user_user_pb.User.toObject(includeInstance, f),
    permissionIdsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.SingleUserResponse}
 */
proto.vtv1.SingleUserResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.SingleUserResponse;
  return proto.vtv1.SingleUserResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.SingleUserResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.SingleUserResponse}
 */
proto.vtv1.SingleUserResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_vorto_user_user_pb.User;
      reader.readMessage(value,proto_vorto_user_user_pb.User.deserializeBinaryFromReader);
      msg.setUser(value);
      break;
    case 2:
      var value = /** @type {!Array<number>} */ (reader.readPackedInt64());
      msg.setPermissionIdsList(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.SingleUserResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.SingleUserResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.SingleUserResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.SingleUserResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUser();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_vorto_user_user_pb.User.serializeBinaryToWriter
    );
  }
  f = message.getPermissionIdsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      2,
      f
    );
  }
};


/**
 * optional User user = 1;
 * @return {?proto.vtv1.User}
 */
proto.vtv1.SingleUserResponse.prototype.getUser = function() {
  return /** @type{?proto.vtv1.User} */ (
    jspb.Message.getWrapperField(this, proto_vorto_user_user_pb.User, 1));
};


/**
 * @param {?proto.vtv1.User|undefined} value
 * @return {!proto.vtv1.SingleUserResponse} returns this
*/
proto.vtv1.SingleUserResponse.prototype.setUser = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.SingleUserResponse} returns this
 */
proto.vtv1.SingleUserResponse.prototype.clearUser = function() {
  return this.setUser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.SingleUserResponse.prototype.hasUser = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated int64 permission_ids = 2;
 * @return {!Array<number>}
 */
proto.vtv1.SingleUserResponse.prototype.getPermissionIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.vtv1.SingleUserResponse} returns this
 */
proto.vtv1.SingleUserResponse.prototype.setPermissionIdsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.vtv1.SingleUserResponse} returns this
 */
proto.vtv1.SingleUserResponse.prototype.addPermissionIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.SingleUserResponse} returns this
 */
proto.vtv1.SingleUserResponse.prototype.clearPermissionIdsList = function() {
  return this.setPermissionIdsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.MyUserInformationResponse.repeatedFields_ = [6,7,10];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.MyUserInformationResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.MyUserInformationResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.MyUserInformationResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.MyUserInformationResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    accountId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    accountName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    userId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    name: jspb.Message.getFieldWithDefault(msg, 4, ""),
    email: jspb.Message.getFieldWithDefault(msg, 5, ""),
    accountFeaturesList: (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f,
    userPermissionsList: (f = jspb.Message.getRepeatedField(msg, 7)) == null ? undefined : f,
    createdAt: jspb.Message.getFieldWithDefault(msg, 8, ""),
    isVirtual: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
    userRegionsList: (f = jspb.Message.getRepeatedField(msg, 10)) == null ? undefined : f,
    consumer: jspb.Message.getBooleanFieldWithDefault(msg, 11, false),
    supplier: jspb.Message.getBooleanFieldWithDefault(msg, 12, false),
    shipper: jspb.Message.getBooleanFieldWithDefault(msg, 13, false),
    viewOnly: jspb.Message.getBooleanFieldWithDefault(msg, 24, false),
    inventoryOnly: jspb.Message.getBooleanFieldWithDefault(msg, 25, false),
    canCrudUsers: jspb.Message.getBooleanFieldWithDefault(msg, 26, false),
    isCpg: jspb.Message.getBooleanFieldWithDefault(msg, 27, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.MyUserInformationResponse}
 */
proto.vtv1.MyUserInformationResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.MyUserInformationResponse;
  return proto.vtv1.MyUserInformationResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.MyUserInformationResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.MyUserInformationResponse}
 */
proto.vtv1.MyUserInformationResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.addAccountFeatures(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.addUserPermissions(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedAt(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsVirtual(value);
      break;
    case 10:
      var value = /** @type {!Array<number>} */ (reader.readPackedInt64());
      msg.setUserRegionsList(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setConsumer(value);
      break;
    case 12:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSupplier(value);
      break;
    case 13:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setShipper(value);
      break;
    case 24:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setViewOnly(value);
      break;
    case 25:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setInventoryOnly(value);
      break;
    case 26:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCanCrudUsers(value);
      break;
    case 27:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsCpg(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.MyUserInformationResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.MyUserInformationResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.MyUserInformationResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.MyUserInformationResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccountId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAccountName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getAccountFeaturesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      6,
      f
    );
  }
  f = message.getUserPermissionsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      7,
      f
    );
  }
  f = message.getCreatedAt();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getIsVirtual();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getUserRegionsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      10,
      f
    );
  }
  f = message.getConsumer();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
  f = message.getSupplier();
  if (f) {
    writer.writeBool(
      12,
      f
    );
  }
  f = message.getShipper();
  if (f) {
    writer.writeBool(
      13,
      f
    );
  }
  f = message.getViewOnly();
  if (f) {
    writer.writeBool(
      24,
      f
    );
  }
  f = message.getInventoryOnly();
  if (f) {
    writer.writeBool(
      25,
      f
    );
  }
  f = message.getCanCrudUsers();
  if (f) {
    writer.writeBool(
      26,
      f
    );
  }
  f = message.getIsCpg();
  if (f) {
    writer.writeBool(
      27,
      f
    );
  }
};


/**
 * optional string account_id = 1;
 * @return {string}
 */
proto.vtv1.MyUserInformationResponse.prototype.getAccountId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.MyUserInformationResponse} returns this
 */
proto.vtv1.MyUserInformationResponse.prototype.setAccountId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string account_name = 2;
 * @return {string}
 */
proto.vtv1.MyUserInformationResponse.prototype.getAccountName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.MyUserInformationResponse} returns this
 */
proto.vtv1.MyUserInformationResponse.prototype.setAccountName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string user_id = 3;
 * @return {string}
 */
proto.vtv1.MyUserInformationResponse.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.MyUserInformationResponse} returns this
 */
proto.vtv1.MyUserInformationResponse.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string name = 4;
 * @return {string}
 */
proto.vtv1.MyUserInformationResponse.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.MyUserInformationResponse} returns this
 */
proto.vtv1.MyUserInformationResponse.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string email = 5;
 * @return {string}
 */
proto.vtv1.MyUserInformationResponse.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.MyUserInformationResponse} returns this
 */
proto.vtv1.MyUserInformationResponse.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * repeated string account_features = 6;
 * @return {!Array<string>}
 */
proto.vtv1.MyUserInformationResponse.prototype.getAccountFeaturesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 6));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.vtv1.MyUserInformationResponse} returns this
 */
proto.vtv1.MyUserInformationResponse.prototype.setAccountFeaturesList = function(value) {
  return jspb.Message.setField(this, 6, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.vtv1.MyUserInformationResponse} returns this
 */
proto.vtv1.MyUserInformationResponse.prototype.addAccountFeatures = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.MyUserInformationResponse} returns this
 */
proto.vtv1.MyUserInformationResponse.prototype.clearAccountFeaturesList = function() {
  return this.setAccountFeaturesList([]);
};


/**
 * repeated string user_permissions = 7;
 * @return {!Array<string>}
 */
proto.vtv1.MyUserInformationResponse.prototype.getUserPermissionsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 7));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.vtv1.MyUserInformationResponse} returns this
 */
proto.vtv1.MyUserInformationResponse.prototype.setUserPermissionsList = function(value) {
  return jspb.Message.setField(this, 7, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.vtv1.MyUserInformationResponse} returns this
 */
proto.vtv1.MyUserInformationResponse.prototype.addUserPermissions = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 7, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.MyUserInformationResponse} returns this
 */
proto.vtv1.MyUserInformationResponse.prototype.clearUserPermissionsList = function() {
  return this.setUserPermissionsList([]);
};


/**
 * optional string created_at = 8;
 * @return {string}
 */
proto.vtv1.MyUserInformationResponse.prototype.getCreatedAt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.MyUserInformationResponse} returns this
 */
proto.vtv1.MyUserInformationResponse.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional bool is_virtual = 9;
 * @return {boolean}
 */
proto.vtv1.MyUserInformationResponse.prototype.getIsVirtual = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vtv1.MyUserInformationResponse} returns this
 */
proto.vtv1.MyUserInformationResponse.prototype.setIsVirtual = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};


/**
 * repeated int64 user_regions = 10;
 * @return {!Array<number>}
 */
proto.vtv1.MyUserInformationResponse.prototype.getUserRegionsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 10));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.vtv1.MyUserInformationResponse} returns this
 */
proto.vtv1.MyUserInformationResponse.prototype.setUserRegionsList = function(value) {
  return jspb.Message.setField(this, 10, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.vtv1.MyUserInformationResponse} returns this
 */
proto.vtv1.MyUserInformationResponse.prototype.addUserRegions = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 10, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.MyUserInformationResponse} returns this
 */
proto.vtv1.MyUserInformationResponse.prototype.clearUserRegionsList = function() {
  return this.setUserRegionsList([]);
};


/**
 * optional bool consumer = 11;
 * @return {boolean}
 */
proto.vtv1.MyUserInformationResponse.prototype.getConsumer = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vtv1.MyUserInformationResponse} returns this
 */
proto.vtv1.MyUserInformationResponse.prototype.setConsumer = function(value) {
  return jspb.Message.setProto3BooleanField(this, 11, value);
};


/**
 * optional bool supplier = 12;
 * @return {boolean}
 */
proto.vtv1.MyUserInformationResponse.prototype.getSupplier = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 12, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vtv1.MyUserInformationResponse} returns this
 */
proto.vtv1.MyUserInformationResponse.prototype.setSupplier = function(value) {
  return jspb.Message.setProto3BooleanField(this, 12, value);
};


/**
 * optional bool shipper = 13;
 * @return {boolean}
 */
proto.vtv1.MyUserInformationResponse.prototype.getShipper = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 13, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vtv1.MyUserInformationResponse} returns this
 */
proto.vtv1.MyUserInformationResponse.prototype.setShipper = function(value) {
  return jspb.Message.setProto3BooleanField(this, 13, value);
};


/**
 * optional bool view_only = 24;
 * @return {boolean}
 */
proto.vtv1.MyUserInformationResponse.prototype.getViewOnly = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 24, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vtv1.MyUserInformationResponse} returns this
 */
proto.vtv1.MyUserInformationResponse.prototype.setViewOnly = function(value) {
  return jspb.Message.setProto3BooleanField(this, 24, value);
};


/**
 * optional bool inventory_only = 25;
 * @return {boolean}
 */
proto.vtv1.MyUserInformationResponse.prototype.getInventoryOnly = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 25, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vtv1.MyUserInformationResponse} returns this
 */
proto.vtv1.MyUserInformationResponse.prototype.setInventoryOnly = function(value) {
  return jspb.Message.setProto3BooleanField(this, 25, value);
};


/**
 * optional bool can_crud_users = 26;
 * @return {boolean}
 */
proto.vtv1.MyUserInformationResponse.prototype.getCanCrudUsers = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 26, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vtv1.MyUserInformationResponse} returns this
 */
proto.vtv1.MyUserInformationResponse.prototype.setCanCrudUsers = function(value) {
  return jspb.Message.setProto3BooleanField(this, 26, value);
};


/**
 * optional bool is_cpg = 27;
 * @return {boolean}
 */
proto.vtv1.MyUserInformationResponse.prototype.getIsCpg = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 27, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vtv1.MyUserInformationResponse} returns this
 */
proto.vtv1.MyUserInformationResponse.prototype.setIsCpg = function(value) {
  return jspb.Message.setProto3BooleanField(this, 27, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.FindDriverByPhoneNumberRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.FindDriverByPhoneNumberRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.FindDriverByPhoneNumberRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.FindDriverByPhoneNumberRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    phoneNumber: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.FindDriverByPhoneNumberRequest}
 */
proto.vtv1.FindDriverByPhoneNumberRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.FindDriverByPhoneNumberRequest;
  return proto.vtv1.FindDriverByPhoneNumberRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.FindDriverByPhoneNumberRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.FindDriverByPhoneNumberRequest}
 */
proto.vtv1.FindDriverByPhoneNumberRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhoneNumber(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.FindDriverByPhoneNumberRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.FindDriverByPhoneNumberRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.FindDriverByPhoneNumberRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.FindDriverByPhoneNumberRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPhoneNumber();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string phone_number = 1;
 * @return {string}
 */
proto.vtv1.FindDriverByPhoneNumberRequest.prototype.getPhoneNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.FindDriverByPhoneNumberRequest} returns this
 */
proto.vtv1.FindDriverByPhoneNumberRequest.prototype.setPhoneNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.MyTokenUserInformationResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.MyTokenUserInformationResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.MyTokenUserInformationResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.MyTokenUserInformationResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    accountId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    userId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    expiresAt: jspb.Message.getFieldWithDefault(msg, 3, ""),
    clientAbsoluteRedirectTo: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.MyTokenUserInformationResponse}
 */
proto.vtv1.MyTokenUserInformationResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.MyTokenUserInformationResponse;
  return proto.vtv1.MyTokenUserInformationResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.MyTokenUserInformationResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.MyTokenUserInformationResponse}
 */
proto.vtv1.MyTokenUserInformationResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setExpiresAt(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setClientAbsoluteRedirectTo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.MyTokenUserInformationResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.MyTokenUserInformationResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.MyTokenUserInformationResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.MyTokenUserInformationResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccountId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getExpiresAt();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getClientAbsoluteRedirectTo();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string account_id = 1;
 * @return {string}
 */
proto.vtv1.MyTokenUserInformationResponse.prototype.getAccountId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.MyTokenUserInformationResponse} returns this
 */
proto.vtv1.MyTokenUserInformationResponse.prototype.setAccountId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string user_id = 2;
 * @return {string}
 */
proto.vtv1.MyTokenUserInformationResponse.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.MyTokenUserInformationResponse} returns this
 */
proto.vtv1.MyTokenUserInformationResponse.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string expires_at = 3;
 * @return {string}
 */
proto.vtv1.MyTokenUserInformationResponse.prototype.getExpiresAt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.MyTokenUserInformationResponse} returns this
 */
proto.vtv1.MyTokenUserInformationResponse.prototype.setExpiresAt = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string client_absolute_redirect_to = 4;
 * @return {string}
 */
proto.vtv1.MyTokenUserInformationResponse.prototype.getClientAbsoluteRedirectTo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.MyTokenUserInformationResponse} returns this
 */
proto.vtv1.MyTokenUserInformationResponse.prototype.setClientAbsoluteRedirectTo = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.GetMyAllowedAccountsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.GetMyAllowedAccountsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.GetMyAllowedAccountsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.GetMyAllowedAccountsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.GetMyAllowedAccountsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    accountsList: jspb.Message.toObjectList(msg.getAccountsList(),
    proto_vorto_types_types_pb.StringIdName.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.GetMyAllowedAccountsResponse}
 */
proto.vtv1.GetMyAllowedAccountsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.GetMyAllowedAccountsResponse;
  return proto.vtv1.GetMyAllowedAccountsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.GetMyAllowedAccountsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.GetMyAllowedAccountsResponse}
 */
proto.vtv1.GetMyAllowedAccountsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_vorto_types_types_pb.StringIdName;
      reader.readMessage(value,proto_vorto_types_types_pb.StringIdName.deserializeBinaryFromReader);
      msg.addAccounts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.GetMyAllowedAccountsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.GetMyAllowedAccountsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.GetMyAllowedAccountsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.GetMyAllowedAccountsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccountsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto_vorto_types_types_pb.StringIdName.serializeBinaryToWriter
    );
  }
};


/**
 * repeated StringIdName accounts = 1;
 * @return {!Array<!proto.vtv1.StringIdName>}
 */
proto.vtv1.GetMyAllowedAccountsResponse.prototype.getAccountsList = function() {
  return /** @type{!Array<!proto.vtv1.StringIdName>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_vorto_types_types_pb.StringIdName, 1));
};


/**
 * @param {!Array<!proto.vtv1.StringIdName>} value
 * @return {!proto.vtv1.GetMyAllowedAccountsResponse} returns this
*/
proto.vtv1.GetMyAllowedAccountsResponse.prototype.setAccountsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.vtv1.StringIdName=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.StringIdName}
 */
proto.vtv1.GetMyAllowedAccountsResponse.prototype.addAccounts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.vtv1.StringIdName, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.GetMyAllowedAccountsResponse} returns this
 */
proto.vtv1.GetMyAllowedAccountsResponse.prototype.clearAccountsList = function() {
  return this.setAccountsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.SetMyAccountResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.SetMyAccountResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.SetMyAccountResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.SetMyAccountResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    userMessage: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.SetMyAccountResponse}
 */
proto.vtv1.SetMyAccountResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.SetMyAccountResponse;
  return proto.vtv1.SetMyAccountResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.SetMyAccountResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.SetMyAccountResponse}
 */
proto.vtv1.SetMyAccountResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.SetMyAccountResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.SetMyAccountResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.SetMyAccountResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.SetMyAccountResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserMessage();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string user_message = 1;
 * @return {string}
 */
proto.vtv1.SetMyAccountResponse.prototype.getUserMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.SetMyAccountResponse} returns this
 */
proto.vtv1.SetMyAccountResponse.prototype.setUserMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


goog.object.extend(exports, proto.vtv1);
