import { Pipe, PipeTransform } from '@angular/core';

const singularMap = {
  kilograms: 'kilogram',
  pounds: 'pound',
  tons: 'ton',
  boxes: 'box',
  gallons: 'gallon',
  liters: 'liter',
  barrels: 'barrel',
  each: 'each',
  kg: 'kg',
  lbs: 'lb',
  gal: 'gal',
  l: 'l',
  bbls: 'bbl',
  ea: 'ea',
};

@Pipe({
  name: 'singularUnit',
})
export class SingularUnitPipe implements PipeTransform {
  public transform(value: string): string {
    if (singularMap[value]) {
      return singularMap[value];
    }
    return value;
  }
}
