import { Pipe, PipeTransform } from '@angular/core';
import { ShipmentStatusMap } from '~proto/types/types_pb';
import { SCSStatusMap } from '~proto/order/order_pb';

const prettyNameMap: Record<SCSStatusMap[keyof SCSStatusMap], string> = {
  0: 'Order Creation',
  1: 'Assigned Driver',
  2: 'Declined',
  3: 'Priority Selection',
  4: 'Non-Priority Selection',
  5: '',
  6: 'Route Guide Exhausted',
  7: 'Shipper reassigned',
  8: '',
  9: '',
};

@Pipe({
  name: 'scsStatus',
})
export class SCSStatusPipe implements PipeTransform {
  public transform(status: ShipmentStatusMap[keyof ShipmentStatusMap]): string {
    return prettyNameMap[status];
  }
}
