import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject, Observable, lastValueFrom } from 'rxjs';
import { NotificationGroup } from '~proto/notification/notification_pb';
import { shareReplay, throttleTime } from 'rxjs/operators';
import { GrpcService } from 'src/app/singleton/services/grpc.service';
import { NotificationService } from '~proto/notification/notification_api_pb_service';
import { Empty } from '~proto/types/types_pb';
import { UpdatePreferenceRequest } from '~proto/notification/notification_api_pb';
import { nullOrNumberToNullableInt64 } from '~utilities';

@Injectable({
  providedIn: 'root',
})
export class NotificationsService {
  private networkActive$$ = new BehaviorSubject<boolean>(false);
  public networkActive$ = this.networkActive$$.asObservable().pipe(shareReplay(1));
  private notifications$$ = new BehaviorSubject<NotificationGroup.AsObject[]>([]);
  private notificationsThrottle$$ = new Subject<void>();
  private notificationsShared$ = this.notifications$$.asObservable().pipe(shareReplay(1));
  public get notifications$(): Observable<NotificationGroup.AsObject[]> {
    this.notificationsThrottle$$.next();
    return this.notificationsShared$;
  }

  constructor(private grpcService: GrpcService) {
    this.notificationsThrottle$$.pipe(throttleTime(1000)).subscribe(() => {
      this.loadNotifications();
    });
  }

  public async unsubscribeNotificationPreference(preferenceId: string) {
    const req = new UpdatePreferenceRequest();
    req.setNotificationTypeId(preferenceId);
    // req.setUserEmailEnabled(emailEnabled);
    this.networkActive$$.next(true);
    try {
      const response = await lastValueFrom(
        this.grpcService.invoke$(NotificationService.UnsubscribeFromNotification, req),
      );
      this.notifications$$.next(response.toObject().groupsList);
    } finally {
      this.networkActive$$.next(false);
    }
  }

  public async resubscribeNotificationPreference(preferenceId: string) {
    const req = new UpdatePreferenceRequest();
    req.setNotificationTypeId(preferenceId);
    // req.setUserEmailEnabled(emailEnabled);
    this.networkActive$$.next(true);
    try {
      const response = await lastValueFrom(
        this.grpcService.invoke$(NotificationService.ResubscribeToNotification, req),
      );
      this.notifications$$.next(response.toObject().groupsList);
    } finally {
      this.networkActive$$.next(false);
    }
  }
  private async loadNotifications() {
    this.networkActive$$.next(true);
    try {
      const response = await lastValueFrom(this.grpcService.invoke$(NotificationService.ListPreferences, new Empty()));
      this.notifications$$.next(response.toObject().groupsList);
    } finally {
      this.networkActive$$.next(false);
    }
  }

  public async updateNotificationFrequency(preferenceId: string, newFrequency: number) {
    const req = new UpdatePreferenceRequest();
    req.setNotificationTypeId(preferenceId);
    req.setSendFrequencyMinutes(nullOrNumberToNullableInt64(newFrequency));
    try {
      const response = await lastValueFrom(
        this.grpcService.invoke$(NotificationService.UpdateNotificationFrequency, req),
      );
      this.notifications$$.next(response.toObject().groupsList);
    } finally {
      this.networkActive$$.next(false);
    }
  }
}
