import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'toDate',
})
export class ToDatePipe implements PipeTransform {
  public transform(value: string, ...args: any[]): Date | string {
    if (!value) {
      return '—';
    }
    return new Date(value);
  }
}
