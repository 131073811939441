import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';

@Pipe({
  name: 'compareWith',
})
export class CompareWithPipe implements PipeTransform {
  public transform<T>(value: T | T[] | Observable<T[]>, key: keyof T): (o1: T, o2: T) => boolean {
    return (o1, o2) => {
      if (o1 && o2) {
        return o1[key] === o2[key];
      }
      return false;
    };
  }
}
