// source: proto/vorto/spot_market_response_from_email/spot_market_response_from_email_api.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var proto_vorto_types_types_pb = require('../../../proto/vorto/types/types_pb.js');
goog.object.extend(proto, proto_vorto_types_types_pb);
var proto_vorto_sites_sites_pb = require('../../../proto/vorto/sites/sites_pb.js');
goog.object.extend(proto, proto_vorto_sites_sites_pb);
goog.exportSymbol('proto.vtv1.CreateQuotePayload', null, global);
goog.exportSymbol('proto.vtv1.GetLocationsByAddressSMRPayload', null, global);
goog.exportSymbol('proto.vtv1.GetLocationsByAddressSMRResponse', null, global);
goog.exportSymbol('proto.vtv1.GetPriceToBeatPayload', null, global);
goog.exportSymbol('proto.vtv1.GetPriceToBeatResponse', null, global);
goog.exportSymbol('proto.vtv1.GetSpotMarketRequestDetailsForQuoteResponse', null, global);
goog.exportSymbol('proto.vtv1.SiteToQuoteWith', null, global);
goog.exportSymbol('proto.vtv1.SitesToQuoteWithResponse', null, global);
goog.exportSymbol('proto.vtv1.SpotMarketRequestDetailsForQuoterSummary', null, global);
goog.exportSymbol('proto.vtv1.YourQuote', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.CreateQuotePayload = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.CreateQuotePayload, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.CreateQuotePayload.displayName = 'proto.vtv1.CreateQuotePayload';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.YourQuote = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.YourQuote, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.YourQuote.displayName = 'proto.vtv1.YourQuote';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.SpotMarketRequestDetailsForQuoterSummary = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.SpotMarketRequestDetailsForQuoterSummary.repeatedFields_, null);
};
goog.inherits(proto.vtv1.SpotMarketRequestDetailsForQuoterSummary, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.SpotMarketRequestDetailsForQuoterSummary.displayName = 'proto.vtv1.SpotMarketRequestDetailsForQuoterSummary';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.GetSpotMarketRequestDetailsForQuoteResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.GetSpotMarketRequestDetailsForQuoteResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.GetSpotMarketRequestDetailsForQuoteResponse.displayName = 'proto.vtv1.GetSpotMarketRequestDetailsForQuoteResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.SiteToQuoteWith = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.SiteToQuoteWith, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.SiteToQuoteWith.displayName = 'proto.vtv1.SiteToQuoteWith';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.SitesToQuoteWithResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.SitesToQuoteWithResponse.repeatedFields_, null);
};
goog.inherits(proto.vtv1.SitesToQuoteWithResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.SitesToQuoteWithResponse.displayName = 'proto.vtv1.SitesToQuoteWithResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.GetLocationsByAddressSMRPayload = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.GetLocationsByAddressSMRPayload, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.GetLocationsByAddressSMRPayload.displayName = 'proto.vtv1.GetLocationsByAddressSMRPayload';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.GetLocationsByAddressSMRResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.GetLocationsByAddressSMRResponse.repeatedFields_, null);
};
goog.inherits(proto.vtv1.GetLocationsByAddressSMRResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.GetLocationsByAddressSMRResponse.displayName = 'proto.vtv1.GetLocationsByAddressSMRResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.GetPriceToBeatPayload = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.GetPriceToBeatPayload, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.GetPriceToBeatPayload.displayName = 'proto.vtv1.GetPriceToBeatPayload';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.GetPriceToBeatResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.GetPriceToBeatResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.GetPriceToBeatResponse.displayName = 'proto.vtv1.GetPriceToBeatResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.CreateQuotePayload.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.CreateQuotePayload.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.CreateQuotePayload} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.CreateQuotePayload.toObject = function(includeInstance, msg) {
  var f, obj = {
    price: jspb.Message.getFieldWithDefault(msg, 1, 0),
    siteId: (f = msg.getSiteId()) && proto_vorto_types_types_pb.NullableString.toObject(includeInstance, f),
    address: (f = msg.getAddress()) && proto_vorto_types_types_pb.NullableString.toObject(includeInstance, f),
    latitude: (f = msg.getLatitude()) && proto_vorto_types_types_pb.NullableDouble.toObject(includeInstance, f),
    longitude: (f = msg.getLongitude()) && proto_vorto_types_types_pb.NullableDouble.toObject(includeInstance, f),
    supplierPays: jspb.Message.getBooleanFieldWithDefault(msg, 6, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.CreateQuotePayload}
 */
proto.vtv1.CreateQuotePayload.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.CreateQuotePayload;
  return proto.vtv1.CreateQuotePayload.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.CreateQuotePayload} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.CreateQuotePayload}
 */
proto.vtv1.CreateQuotePayload.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPrice(value);
      break;
    case 2:
      var value = new proto_vorto_types_types_pb.NullableString;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableString.deserializeBinaryFromReader);
      msg.setSiteId(value);
      break;
    case 3:
      var value = new proto_vorto_types_types_pb.NullableString;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableString.deserializeBinaryFromReader);
      msg.setAddress(value);
      break;
    case 4:
      var value = new proto_vorto_types_types_pb.NullableDouble;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableDouble.deserializeBinaryFromReader);
      msg.setLatitude(value);
      break;
    case 5:
      var value = new proto_vorto_types_types_pb.NullableDouble;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableDouble.deserializeBinaryFromReader);
      msg.setLongitude(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSupplierPays(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.CreateQuotePayload.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.CreateQuotePayload.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.CreateQuotePayload} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.CreateQuotePayload.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPrice();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getSiteId();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_vorto_types_types_pb.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getAddress();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto_vorto_types_types_pb.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getLatitude();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto_vorto_types_types_pb.NullableDouble.serializeBinaryToWriter
    );
  }
  f = message.getLongitude();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto_vorto_types_types_pb.NullableDouble.serializeBinaryToWriter
    );
  }
  f = message.getSupplierPays();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
};


/**
 * optional int64 price = 1;
 * @return {number}
 */
proto.vtv1.CreateQuotePayload.prototype.getPrice = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.CreateQuotePayload} returns this
 */
proto.vtv1.CreateQuotePayload.prototype.setPrice = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional NullableString site_id = 2;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.CreateQuotePayload.prototype.getSiteId = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableString, 2));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.CreateQuotePayload} returns this
*/
proto.vtv1.CreateQuotePayload.prototype.setSiteId = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.CreateQuotePayload} returns this
 */
proto.vtv1.CreateQuotePayload.prototype.clearSiteId = function() {
  return this.setSiteId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.CreateQuotePayload.prototype.hasSiteId = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional NullableString address = 3;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.CreateQuotePayload.prototype.getAddress = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableString, 3));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.CreateQuotePayload} returns this
*/
proto.vtv1.CreateQuotePayload.prototype.setAddress = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.CreateQuotePayload} returns this
 */
proto.vtv1.CreateQuotePayload.prototype.clearAddress = function() {
  return this.setAddress(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.CreateQuotePayload.prototype.hasAddress = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional NullableDouble latitude = 4;
 * @return {?proto.vtv1.NullableDouble}
 */
proto.vtv1.CreateQuotePayload.prototype.getLatitude = function() {
  return /** @type{?proto.vtv1.NullableDouble} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableDouble, 4));
};


/**
 * @param {?proto.vtv1.NullableDouble|undefined} value
 * @return {!proto.vtv1.CreateQuotePayload} returns this
*/
proto.vtv1.CreateQuotePayload.prototype.setLatitude = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.CreateQuotePayload} returns this
 */
proto.vtv1.CreateQuotePayload.prototype.clearLatitude = function() {
  return this.setLatitude(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.CreateQuotePayload.prototype.hasLatitude = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional NullableDouble longitude = 5;
 * @return {?proto.vtv1.NullableDouble}
 */
proto.vtv1.CreateQuotePayload.prototype.getLongitude = function() {
  return /** @type{?proto.vtv1.NullableDouble} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableDouble, 5));
};


/**
 * @param {?proto.vtv1.NullableDouble|undefined} value
 * @return {!proto.vtv1.CreateQuotePayload} returns this
*/
proto.vtv1.CreateQuotePayload.prototype.setLongitude = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.CreateQuotePayload} returns this
 */
proto.vtv1.CreateQuotePayload.prototype.clearLongitude = function() {
  return this.setLongitude(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.CreateQuotePayload.prototype.hasLongitude = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional bool supplier_pays = 6;
 * @return {boolean}
 */
proto.vtv1.CreateQuotePayload.prototype.getSupplierPays = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vtv1.CreateQuotePayload} returns this
 */
proto.vtv1.CreateQuotePayload.prototype.setSupplierPays = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.YourQuote.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.YourQuote.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.YourQuote} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.YourQuote.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    totalPrice: jspb.Message.getFieldWithDefault(msg, 2, 0),
    siteId: (f = msg.getSiteId()) && proto_vorto_types_types_pb.NullableString.toObject(includeInstance, f),
    siteName: (f = msg.getSiteName()) && proto_vorto_types_types_pb.NullableString.toObject(includeInstance, f),
    address: (f = msg.getAddress()) && proto_vorto_types_types_pb.NullableString.toObject(includeInstance, f),
    longitude: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    latitude: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    createdBy: (f = msg.getCreatedBy()) && proto_vorto_types_types_pb.NullableString.toObject(includeInstance, f),
    createdByName: jspb.Message.getFieldWithDefault(msg, 9, ""),
    createdAt: jspb.Message.getFieldWithDefault(msg, 10, ""),
    archivedAt: (f = msg.getArchivedAt()) && proto_vorto_types_types_pb.NullableTime.toObject(includeInstance, f),
    unitPrice: jspb.Message.getFieldWithDefault(msg, 12, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.YourQuote}
 */
proto.vtv1.YourQuote.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.YourQuote;
  return proto.vtv1.YourQuote.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.YourQuote} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.YourQuote}
 */
proto.vtv1.YourQuote.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotalPrice(value);
      break;
    case 3:
      var value = new proto_vorto_types_types_pb.NullableString;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableString.deserializeBinaryFromReader);
      msg.setSiteId(value);
      break;
    case 4:
      var value = new proto_vorto_types_types_pb.NullableString;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableString.deserializeBinaryFromReader);
      msg.setSiteName(value);
      break;
    case 5:
      var value = new proto_vorto_types_types_pb.NullableString;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableString.deserializeBinaryFromReader);
      msg.setAddress(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLongitude(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLatitude(value);
      break;
    case 8:
      var value = new proto_vorto_types_types_pb.NullableString;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableString.deserializeBinaryFromReader);
      msg.setCreatedBy(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedByName(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedAt(value);
      break;
    case 11:
      var value = new proto_vorto_types_types_pb.NullableTime;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableTime.deserializeBinaryFromReader);
      msg.setArchivedAt(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUnitPrice(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.YourQuote.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.YourQuote.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.YourQuote} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.YourQuote.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getTotalPrice();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getSiteId();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto_vorto_types_types_pb.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getSiteName();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto_vorto_types_types_pb.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getAddress();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto_vorto_types_types_pb.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getLongitude();
  if (f !== 0.0) {
    writer.writeDouble(
      6,
      f
    );
  }
  f = message.getLatitude();
  if (f !== 0.0) {
    writer.writeDouble(
      7,
      f
    );
  }
  f = message.getCreatedBy();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto_vorto_types_types_pb.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getCreatedByName();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getCreatedAt();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getArchivedAt();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto_vorto_types_types_pb.NullableTime.serializeBinaryToWriter
    );
  }
  f = message.getUnitPrice();
  if (f !== 0) {
    writer.writeInt64(
      12,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.vtv1.YourQuote.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.YourQuote} returns this
 */
proto.vtv1.YourQuote.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 total_price = 2;
 * @return {number}
 */
proto.vtv1.YourQuote.prototype.getTotalPrice = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.YourQuote} returns this
 */
proto.vtv1.YourQuote.prototype.setTotalPrice = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional NullableString site_id = 3;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.YourQuote.prototype.getSiteId = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableString, 3));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.YourQuote} returns this
*/
proto.vtv1.YourQuote.prototype.setSiteId = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.YourQuote} returns this
 */
proto.vtv1.YourQuote.prototype.clearSiteId = function() {
  return this.setSiteId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.YourQuote.prototype.hasSiteId = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional NullableString site_name = 4;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.YourQuote.prototype.getSiteName = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableString, 4));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.YourQuote} returns this
*/
proto.vtv1.YourQuote.prototype.setSiteName = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.YourQuote} returns this
 */
proto.vtv1.YourQuote.prototype.clearSiteName = function() {
  return this.setSiteName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.YourQuote.prototype.hasSiteName = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional NullableString address = 5;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.YourQuote.prototype.getAddress = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableString, 5));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.YourQuote} returns this
*/
proto.vtv1.YourQuote.prototype.setAddress = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.YourQuote} returns this
 */
proto.vtv1.YourQuote.prototype.clearAddress = function() {
  return this.setAddress(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.YourQuote.prototype.hasAddress = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional double longitude = 6;
 * @return {number}
 */
proto.vtv1.YourQuote.prototype.getLongitude = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.YourQuote} returns this
 */
proto.vtv1.YourQuote.prototype.setLongitude = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional double latitude = 7;
 * @return {number}
 */
proto.vtv1.YourQuote.prototype.getLatitude = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.YourQuote} returns this
 */
proto.vtv1.YourQuote.prototype.setLatitude = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional NullableString created_by = 8;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.YourQuote.prototype.getCreatedBy = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableString, 8));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.YourQuote} returns this
*/
proto.vtv1.YourQuote.prototype.setCreatedBy = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.YourQuote} returns this
 */
proto.vtv1.YourQuote.prototype.clearCreatedBy = function() {
  return this.setCreatedBy(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.YourQuote.prototype.hasCreatedBy = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional string created_by_name = 9;
 * @return {string}
 */
proto.vtv1.YourQuote.prototype.getCreatedByName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.YourQuote} returns this
 */
proto.vtv1.YourQuote.prototype.setCreatedByName = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string created_at = 10;
 * @return {string}
 */
proto.vtv1.YourQuote.prototype.getCreatedAt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.YourQuote} returns this
 */
proto.vtv1.YourQuote.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional NullableTime archived_at = 11;
 * @return {?proto.vtv1.NullableTime}
 */
proto.vtv1.YourQuote.prototype.getArchivedAt = function() {
  return /** @type{?proto.vtv1.NullableTime} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableTime, 11));
};


/**
 * @param {?proto.vtv1.NullableTime|undefined} value
 * @return {!proto.vtv1.YourQuote} returns this
*/
proto.vtv1.YourQuote.prototype.setArchivedAt = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.YourQuote} returns this
 */
proto.vtv1.YourQuote.prototype.clearArchivedAt = function() {
  return this.setArchivedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.YourQuote.prototype.hasArchivedAt = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional int64 unit_price = 12;
 * @return {number}
 */
proto.vtv1.YourQuote.prototype.getUnitPrice = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.YourQuote} returns this
 */
proto.vtv1.YourQuote.prototype.setUnitPrice = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.SpotMarketRequestDetailsForQuoterSummary.repeatedFields_ = [22];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.SpotMarketRequestDetailsForQuoterSummary.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.SpotMarketRequestDetailsForQuoterSummary.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.SpotMarketRequestDetailsForQuoterSummary} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.SpotMarketRequestDetailsForQuoterSummary.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    type: jspb.Message.getFieldWithDefault(msg, 2, 0),
    inventoryName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    jobStartTime: jspb.Message.getFieldWithDefault(msg, 4, ""),
    jobEndTime: jspb.Message.getFieldWithDefault(msg, 5, ""),
    biddingStartTime: jspb.Message.getFieldWithDefault(msg, 6, ""),
    biddingEndTime: jspb.Message.getFieldWithDefault(msg, 7, ""),
    quantity: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
    maxPrice: (f = msg.getMaxPrice()) && proto_vorto_types_types_pb.NullableInt64.toObject(includeInstance, f),
    createdByName: jspb.Message.getFieldWithDefault(msg, 10, ""),
    updatedByName: jspb.Message.getFieldWithDefault(msg, 11, ""),
    createdAt: jspb.Message.getFieldWithDefault(msg, 12, ""),
    updatedAt: jspb.Message.getFieldWithDefault(msg, 13, ""),
    productName: jspb.Message.getFieldWithDefault(msg, 14, ""),
    siteName: jspb.Message.getFieldWithDefault(msg, 15, ""),
    unitAbbreviation: jspb.Message.getFieldWithDefault(msg, 16, ""),
    accountName: jspb.Message.getFieldWithDefault(msg, 17, ""),
    description: (f = msg.getDescription()) && proto_vorto_types_types_pb.NullableString.toObject(includeInstance, f),
    messageToBidders: (f = msg.getMessageToBidders()) && proto_vorto_types_types_pb.NullableString.toObject(includeInstance, f),
    latitude: jspb.Message.getFloatingPointFieldWithDefault(msg, 20, 0.0),
    longitude: jspb.Message.getFloatingPointFieldWithDefault(msg, 21, 0.0),
    quotesList: jspb.Message.toObjectList(msg.getQuotesList(),
    proto.vtv1.YourQuote.toObject, includeInstance),
    currentlyWinning: (f = msg.getCurrentlyWinning()) && proto_vorto_types_types_pb.NullableBool.toObject(includeInstance, f),
    shipperType: jspb.Message.getFieldWithDefault(msg, 24, 0),
    siteAddress: (f = msg.getSiteAddress()) && proto_vorto_types_types_pb.NullableString.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.SpotMarketRequestDetailsForQuoterSummary}
 */
proto.vtv1.SpotMarketRequestDetailsForQuoterSummary.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.SpotMarketRequestDetailsForQuoterSummary;
  return proto.vtv1.SpotMarketRequestDetailsForQuoterSummary.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.SpotMarketRequestDetailsForQuoterSummary} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.SpotMarketRequestDetailsForQuoterSummary}
 */
proto.vtv1.SpotMarketRequestDetailsForQuoterSummary.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {!proto.vtv1.SpotMarketRequestType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setInventoryName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setJobStartTime(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setJobEndTime(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setBiddingStartTime(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setBiddingEndTime(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setQuantity(value);
      break;
    case 9:
      var value = new proto_vorto_types_types_pb.NullableInt64;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableInt64.deserializeBinaryFromReader);
      msg.setMaxPrice(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedByName(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setUpdatedByName(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedAt(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setUpdatedAt(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductName(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setSiteName(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setUnitAbbreviation(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountName(value);
      break;
    case 18:
      var value = new proto_vorto_types_types_pb.NullableString;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableString.deserializeBinaryFromReader);
      msg.setDescription(value);
      break;
    case 19:
      var value = new proto_vorto_types_types_pb.NullableString;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableString.deserializeBinaryFromReader);
      msg.setMessageToBidders(value);
      break;
    case 20:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLatitude(value);
      break;
    case 21:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLongitude(value);
      break;
    case 22:
      var value = new proto.vtv1.YourQuote;
      reader.readMessage(value,proto.vtv1.YourQuote.deserializeBinaryFromReader);
      msg.addQuotes(value);
      break;
    case 23:
      var value = new proto_vorto_types_types_pb.NullableBool;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableBool.deserializeBinaryFromReader);
      msg.setCurrentlyWinning(value);
      break;
    case 24:
      var value = /** @type {!proto.vtv1.SpotMarketRequestShipperType} */ (reader.readEnum());
      msg.setShipperType(value);
      break;
    case 25:
      var value = new proto_vorto_types_types_pb.NullableString;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableString.deserializeBinaryFromReader);
      msg.setSiteAddress(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.SpotMarketRequestDetailsForQuoterSummary.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.SpotMarketRequestDetailsForQuoterSummary.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.SpotMarketRequestDetailsForQuoterSummary} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.SpotMarketRequestDetailsForQuoterSummary.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getInventoryName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getJobStartTime();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getJobEndTime();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getBiddingStartTime();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getBiddingEndTime();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getQuantity();
  if (f !== 0.0) {
    writer.writeDouble(
      8,
      f
    );
  }
  f = message.getMaxPrice();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto_vorto_types_types_pb.NullableInt64.serializeBinaryToWriter
    );
  }
  f = message.getCreatedByName();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getUpdatedByName();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getCreatedAt();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getUpdatedAt();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getProductName();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getSiteName();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getUnitAbbreviation();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getAccountName();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getDescription();
  if (f != null) {
    writer.writeMessage(
      18,
      f,
      proto_vorto_types_types_pb.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getMessageToBidders();
  if (f != null) {
    writer.writeMessage(
      19,
      f,
      proto_vorto_types_types_pb.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getLatitude();
  if (f !== 0.0) {
    writer.writeDouble(
      20,
      f
    );
  }
  f = message.getLongitude();
  if (f !== 0.0) {
    writer.writeDouble(
      21,
      f
    );
  }
  f = message.getQuotesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      22,
      f,
      proto.vtv1.YourQuote.serializeBinaryToWriter
    );
  }
  f = message.getCurrentlyWinning();
  if (f != null) {
    writer.writeMessage(
      23,
      f,
      proto_vorto_types_types_pb.NullableBool.serializeBinaryToWriter
    );
  }
  f = message.getShipperType();
  if (f !== 0.0) {
    writer.writeEnum(
      24,
      f
    );
  }
  f = message.getSiteAddress();
  if (f != null) {
    writer.writeMessage(
      25,
      f,
      proto_vorto_types_types_pb.NullableString.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.vtv1.SpotMarketRequestDetailsForQuoterSummary.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.SpotMarketRequestDetailsForQuoterSummary} returns this
 */
proto.vtv1.SpotMarketRequestDetailsForQuoterSummary.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional SpotMarketRequestType type = 2;
 * @return {!proto.vtv1.SpotMarketRequestType}
 */
proto.vtv1.SpotMarketRequestDetailsForQuoterSummary.prototype.getType = function() {
  return /** @type {!proto.vtv1.SpotMarketRequestType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.vtv1.SpotMarketRequestType} value
 * @return {!proto.vtv1.SpotMarketRequestDetailsForQuoterSummary} returns this
 */
proto.vtv1.SpotMarketRequestDetailsForQuoterSummary.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional string inventory_name = 3;
 * @return {string}
 */
proto.vtv1.SpotMarketRequestDetailsForQuoterSummary.prototype.getInventoryName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.SpotMarketRequestDetailsForQuoterSummary} returns this
 */
proto.vtv1.SpotMarketRequestDetailsForQuoterSummary.prototype.setInventoryName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string job_start_time = 4;
 * @return {string}
 */
proto.vtv1.SpotMarketRequestDetailsForQuoterSummary.prototype.getJobStartTime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.SpotMarketRequestDetailsForQuoterSummary} returns this
 */
proto.vtv1.SpotMarketRequestDetailsForQuoterSummary.prototype.setJobStartTime = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string job_end_time = 5;
 * @return {string}
 */
proto.vtv1.SpotMarketRequestDetailsForQuoterSummary.prototype.getJobEndTime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.SpotMarketRequestDetailsForQuoterSummary} returns this
 */
proto.vtv1.SpotMarketRequestDetailsForQuoterSummary.prototype.setJobEndTime = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string bidding_start_time = 6;
 * @return {string}
 */
proto.vtv1.SpotMarketRequestDetailsForQuoterSummary.prototype.getBiddingStartTime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.SpotMarketRequestDetailsForQuoterSummary} returns this
 */
proto.vtv1.SpotMarketRequestDetailsForQuoterSummary.prototype.setBiddingStartTime = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string bidding_end_time = 7;
 * @return {string}
 */
proto.vtv1.SpotMarketRequestDetailsForQuoterSummary.prototype.getBiddingEndTime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.SpotMarketRequestDetailsForQuoterSummary} returns this
 */
proto.vtv1.SpotMarketRequestDetailsForQuoterSummary.prototype.setBiddingEndTime = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional double quantity = 8;
 * @return {number}
 */
proto.vtv1.SpotMarketRequestDetailsForQuoterSummary.prototype.getQuantity = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.SpotMarketRequestDetailsForQuoterSummary} returns this
 */
proto.vtv1.SpotMarketRequestDetailsForQuoterSummary.prototype.setQuantity = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};


/**
 * optional NullableInt64 max_price = 9;
 * @return {?proto.vtv1.NullableInt64}
 */
proto.vtv1.SpotMarketRequestDetailsForQuoterSummary.prototype.getMaxPrice = function() {
  return /** @type{?proto.vtv1.NullableInt64} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableInt64, 9));
};


/**
 * @param {?proto.vtv1.NullableInt64|undefined} value
 * @return {!proto.vtv1.SpotMarketRequestDetailsForQuoterSummary} returns this
*/
proto.vtv1.SpotMarketRequestDetailsForQuoterSummary.prototype.setMaxPrice = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.SpotMarketRequestDetailsForQuoterSummary} returns this
 */
proto.vtv1.SpotMarketRequestDetailsForQuoterSummary.prototype.clearMaxPrice = function() {
  return this.setMaxPrice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.SpotMarketRequestDetailsForQuoterSummary.prototype.hasMaxPrice = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional string created_by_name = 10;
 * @return {string}
 */
proto.vtv1.SpotMarketRequestDetailsForQuoterSummary.prototype.getCreatedByName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.SpotMarketRequestDetailsForQuoterSummary} returns this
 */
proto.vtv1.SpotMarketRequestDetailsForQuoterSummary.prototype.setCreatedByName = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string updated_by_name = 11;
 * @return {string}
 */
proto.vtv1.SpotMarketRequestDetailsForQuoterSummary.prototype.getUpdatedByName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.SpotMarketRequestDetailsForQuoterSummary} returns this
 */
proto.vtv1.SpotMarketRequestDetailsForQuoterSummary.prototype.setUpdatedByName = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string created_at = 12;
 * @return {string}
 */
proto.vtv1.SpotMarketRequestDetailsForQuoterSummary.prototype.getCreatedAt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.SpotMarketRequestDetailsForQuoterSummary} returns this
 */
proto.vtv1.SpotMarketRequestDetailsForQuoterSummary.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string updated_at = 13;
 * @return {string}
 */
proto.vtv1.SpotMarketRequestDetailsForQuoterSummary.prototype.getUpdatedAt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.SpotMarketRequestDetailsForQuoterSummary} returns this
 */
proto.vtv1.SpotMarketRequestDetailsForQuoterSummary.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string product_name = 14;
 * @return {string}
 */
proto.vtv1.SpotMarketRequestDetailsForQuoterSummary.prototype.getProductName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.SpotMarketRequestDetailsForQuoterSummary} returns this
 */
proto.vtv1.SpotMarketRequestDetailsForQuoterSummary.prototype.setProductName = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string site_name = 15;
 * @return {string}
 */
proto.vtv1.SpotMarketRequestDetailsForQuoterSummary.prototype.getSiteName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.SpotMarketRequestDetailsForQuoterSummary} returns this
 */
proto.vtv1.SpotMarketRequestDetailsForQuoterSummary.prototype.setSiteName = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional string unit_abbreviation = 16;
 * @return {string}
 */
proto.vtv1.SpotMarketRequestDetailsForQuoterSummary.prototype.getUnitAbbreviation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.SpotMarketRequestDetailsForQuoterSummary} returns this
 */
proto.vtv1.SpotMarketRequestDetailsForQuoterSummary.prototype.setUnitAbbreviation = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional string account_name = 17;
 * @return {string}
 */
proto.vtv1.SpotMarketRequestDetailsForQuoterSummary.prototype.getAccountName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.SpotMarketRequestDetailsForQuoterSummary} returns this
 */
proto.vtv1.SpotMarketRequestDetailsForQuoterSummary.prototype.setAccountName = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional NullableString description = 18;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.SpotMarketRequestDetailsForQuoterSummary.prototype.getDescription = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableString, 18));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.SpotMarketRequestDetailsForQuoterSummary} returns this
*/
proto.vtv1.SpotMarketRequestDetailsForQuoterSummary.prototype.setDescription = function(value) {
  return jspb.Message.setWrapperField(this, 18, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.SpotMarketRequestDetailsForQuoterSummary} returns this
 */
proto.vtv1.SpotMarketRequestDetailsForQuoterSummary.prototype.clearDescription = function() {
  return this.setDescription(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.SpotMarketRequestDetailsForQuoterSummary.prototype.hasDescription = function() {
  return jspb.Message.getField(this, 18) != null;
};


/**
 * optional NullableString message_to_bidders = 19;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.SpotMarketRequestDetailsForQuoterSummary.prototype.getMessageToBidders = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableString, 19));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.SpotMarketRequestDetailsForQuoterSummary} returns this
*/
proto.vtv1.SpotMarketRequestDetailsForQuoterSummary.prototype.setMessageToBidders = function(value) {
  return jspb.Message.setWrapperField(this, 19, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.SpotMarketRequestDetailsForQuoterSummary} returns this
 */
proto.vtv1.SpotMarketRequestDetailsForQuoterSummary.prototype.clearMessageToBidders = function() {
  return this.setMessageToBidders(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.SpotMarketRequestDetailsForQuoterSummary.prototype.hasMessageToBidders = function() {
  return jspb.Message.getField(this, 19) != null;
};


/**
 * optional double latitude = 20;
 * @return {number}
 */
proto.vtv1.SpotMarketRequestDetailsForQuoterSummary.prototype.getLatitude = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 20, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.SpotMarketRequestDetailsForQuoterSummary} returns this
 */
proto.vtv1.SpotMarketRequestDetailsForQuoterSummary.prototype.setLatitude = function(value) {
  return jspb.Message.setProto3FloatField(this, 20, value);
};


/**
 * optional double longitude = 21;
 * @return {number}
 */
proto.vtv1.SpotMarketRequestDetailsForQuoterSummary.prototype.getLongitude = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 21, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.SpotMarketRequestDetailsForQuoterSummary} returns this
 */
proto.vtv1.SpotMarketRequestDetailsForQuoterSummary.prototype.setLongitude = function(value) {
  return jspb.Message.setProto3FloatField(this, 21, value);
};


/**
 * repeated YourQuote quotes = 22;
 * @return {!Array<!proto.vtv1.YourQuote>}
 */
proto.vtv1.SpotMarketRequestDetailsForQuoterSummary.prototype.getQuotesList = function() {
  return /** @type{!Array<!proto.vtv1.YourQuote>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vtv1.YourQuote, 22));
};


/**
 * @param {!Array<!proto.vtv1.YourQuote>} value
 * @return {!proto.vtv1.SpotMarketRequestDetailsForQuoterSummary} returns this
*/
proto.vtv1.SpotMarketRequestDetailsForQuoterSummary.prototype.setQuotesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 22, value);
};


/**
 * @param {!proto.vtv1.YourQuote=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.YourQuote}
 */
proto.vtv1.SpotMarketRequestDetailsForQuoterSummary.prototype.addQuotes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 22, opt_value, proto.vtv1.YourQuote, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.SpotMarketRequestDetailsForQuoterSummary} returns this
 */
proto.vtv1.SpotMarketRequestDetailsForQuoterSummary.prototype.clearQuotesList = function() {
  return this.setQuotesList([]);
};


/**
 * optional NullableBool currently_winning = 23;
 * @return {?proto.vtv1.NullableBool}
 */
proto.vtv1.SpotMarketRequestDetailsForQuoterSummary.prototype.getCurrentlyWinning = function() {
  return /** @type{?proto.vtv1.NullableBool} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableBool, 23));
};


/**
 * @param {?proto.vtv1.NullableBool|undefined} value
 * @return {!proto.vtv1.SpotMarketRequestDetailsForQuoterSummary} returns this
*/
proto.vtv1.SpotMarketRequestDetailsForQuoterSummary.prototype.setCurrentlyWinning = function(value) {
  return jspb.Message.setWrapperField(this, 23, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.SpotMarketRequestDetailsForQuoterSummary} returns this
 */
proto.vtv1.SpotMarketRequestDetailsForQuoterSummary.prototype.clearCurrentlyWinning = function() {
  return this.setCurrentlyWinning(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.SpotMarketRequestDetailsForQuoterSummary.prototype.hasCurrentlyWinning = function() {
  return jspb.Message.getField(this, 23) != null;
};


/**
 * optional SpotMarketRequestShipperType shipper_type = 24;
 * @return {!proto.vtv1.SpotMarketRequestShipperType}
 */
proto.vtv1.SpotMarketRequestDetailsForQuoterSummary.prototype.getShipperType = function() {
  return /** @type {!proto.vtv1.SpotMarketRequestShipperType} */ (jspb.Message.getFieldWithDefault(this, 24, 0));
};


/**
 * @param {!proto.vtv1.SpotMarketRequestShipperType} value
 * @return {!proto.vtv1.SpotMarketRequestDetailsForQuoterSummary} returns this
 */
proto.vtv1.SpotMarketRequestDetailsForQuoterSummary.prototype.setShipperType = function(value) {
  return jspb.Message.setProto3EnumField(this, 24, value);
};


/**
 * optional NullableString site_address = 25;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.SpotMarketRequestDetailsForQuoterSummary.prototype.getSiteAddress = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableString, 25));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.SpotMarketRequestDetailsForQuoterSummary} returns this
*/
proto.vtv1.SpotMarketRequestDetailsForQuoterSummary.prototype.setSiteAddress = function(value) {
  return jspb.Message.setWrapperField(this, 25, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.SpotMarketRequestDetailsForQuoterSummary} returns this
 */
proto.vtv1.SpotMarketRequestDetailsForQuoterSummary.prototype.clearSiteAddress = function() {
  return this.setSiteAddress(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.SpotMarketRequestDetailsForQuoterSummary.prototype.hasSiteAddress = function() {
  return jspb.Message.getField(this, 25) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.GetSpotMarketRequestDetailsForQuoteResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.GetSpotMarketRequestDetailsForQuoteResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.GetSpotMarketRequestDetailsForQuoteResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.GetSpotMarketRequestDetailsForQuoteResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    summary: (f = msg.getSummary()) && proto.vtv1.SpotMarketRequestDetailsForQuoterSummary.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.GetSpotMarketRequestDetailsForQuoteResponse}
 */
proto.vtv1.GetSpotMarketRequestDetailsForQuoteResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.GetSpotMarketRequestDetailsForQuoteResponse;
  return proto.vtv1.GetSpotMarketRequestDetailsForQuoteResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.GetSpotMarketRequestDetailsForQuoteResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.GetSpotMarketRequestDetailsForQuoteResponse}
 */
proto.vtv1.GetSpotMarketRequestDetailsForQuoteResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.vtv1.SpotMarketRequestDetailsForQuoterSummary;
      reader.readMessage(value,proto.vtv1.SpotMarketRequestDetailsForQuoterSummary.deserializeBinaryFromReader);
      msg.setSummary(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.GetSpotMarketRequestDetailsForQuoteResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.GetSpotMarketRequestDetailsForQuoteResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.GetSpotMarketRequestDetailsForQuoteResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.GetSpotMarketRequestDetailsForQuoteResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSummary();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.vtv1.SpotMarketRequestDetailsForQuoterSummary.serializeBinaryToWriter
    );
  }
};


/**
 * optional SpotMarketRequestDetailsForQuoterSummary summary = 1;
 * @return {?proto.vtv1.SpotMarketRequestDetailsForQuoterSummary}
 */
proto.vtv1.GetSpotMarketRequestDetailsForQuoteResponse.prototype.getSummary = function() {
  return /** @type{?proto.vtv1.SpotMarketRequestDetailsForQuoterSummary} */ (
    jspb.Message.getWrapperField(this, proto.vtv1.SpotMarketRequestDetailsForQuoterSummary, 1));
};


/**
 * @param {?proto.vtv1.SpotMarketRequestDetailsForQuoterSummary|undefined} value
 * @return {!proto.vtv1.GetSpotMarketRequestDetailsForQuoteResponse} returns this
*/
proto.vtv1.GetSpotMarketRequestDetailsForQuoteResponse.prototype.setSummary = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.GetSpotMarketRequestDetailsForQuoteResponse} returns this
 */
proto.vtv1.GetSpotMarketRequestDetailsForQuoteResponse.prototype.clearSummary = function() {
  return this.setSummary(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.GetSpotMarketRequestDetailsForQuoteResponse.prototype.hasSummary = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.SiteToQuoteWith.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.SiteToQuoteWith.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.SiteToQuoteWith} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.SiteToQuoteWith.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    latitude: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    longitude: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    approximateDistance: (f = msg.getApproximateDistance()) && proto_vorto_types_types_pb.NullableInt64.toObject(includeInstance, f),
    name: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.SiteToQuoteWith}
 */
proto.vtv1.SiteToQuoteWith.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.SiteToQuoteWith;
  return proto.vtv1.SiteToQuoteWith.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.SiteToQuoteWith} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.SiteToQuoteWith}
 */
proto.vtv1.SiteToQuoteWith.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLatitude(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLongitude(value);
      break;
    case 4:
      var value = new proto_vorto_types_types_pb.NullableInt64;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableInt64.deserializeBinaryFromReader);
      msg.setApproximateDistance(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.SiteToQuoteWith.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.SiteToQuoteWith.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.SiteToQuoteWith} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.SiteToQuoteWith.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLatitude();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getLongitude();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getApproximateDistance();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto_vorto_types_types_pb.NullableInt64.serializeBinaryToWriter
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.vtv1.SiteToQuoteWith.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.SiteToQuoteWith} returns this
 */
proto.vtv1.SiteToQuoteWith.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional double latitude = 2;
 * @return {number}
 */
proto.vtv1.SiteToQuoteWith.prototype.getLatitude = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.SiteToQuoteWith} returns this
 */
proto.vtv1.SiteToQuoteWith.prototype.setLatitude = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double longitude = 3;
 * @return {number}
 */
proto.vtv1.SiteToQuoteWith.prototype.getLongitude = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.SiteToQuoteWith} returns this
 */
proto.vtv1.SiteToQuoteWith.prototype.setLongitude = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional NullableInt64 approximate_distance = 4;
 * @return {?proto.vtv1.NullableInt64}
 */
proto.vtv1.SiteToQuoteWith.prototype.getApproximateDistance = function() {
  return /** @type{?proto.vtv1.NullableInt64} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableInt64, 4));
};


/**
 * @param {?proto.vtv1.NullableInt64|undefined} value
 * @return {!proto.vtv1.SiteToQuoteWith} returns this
*/
proto.vtv1.SiteToQuoteWith.prototype.setApproximateDistance = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.SiteToQuoteWith} returns this
 */
proto.vtv1.SiteToQuoteWith.prototype.clearApproximateDistance = function() {
  return this.setApproximateDistance(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.SiteToQuoteWith.prototype.hasApproximateDistance = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string name = 5;
 * @return {string}
 */
proto.vtv1.SiteToQuoteWith.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.SiteToQuoteWith} returns this
 */
proto.vtv1.SiteToQuoteWith.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.SitesToQuoteWithResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.SitesToQuoteWithResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.SitesToQuoteWithResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.SitesToQuoteWithResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.SitesToQuoteWithResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    sitesList: jspb.Message.toObjectList(msg.getSitesList(),
    proto.vtv1.SiteToQuoteWith.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.SitesToQuoteWithResponse}
 */
proto.vtv1.SitesToQuoteWithResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.SitesToQuoteWithResponse;
  return proto.vtv1.SitesToQuoteWithResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.SitesToQuoteWithResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.SitesToQuoteWithResponse}
 */
proto.vtv1.SitesToQuoteWithResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.vtv1.SiteToQuoteWith;
      reader.readMessage(value,proto.vtv1.SiteToQuoteWith.deserializeBinaryFromReader);
      msg.addSites(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.SitesToQuoteWithResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.SitesToQuoteWithResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.SitesToQuoteWithResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.SitesToQuoteWithResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSitesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.vtv1.SiteToQuoteWith.serializeBinaryToWriter
    );
  }
};


/**
 * repeated SiteToQuoteWith sites = 1;
 * @return {!Array<!proto.vtv1.SiteToQuoteWith>}
 */
proto.vtv1.SitesToQuoteWithResponse.prototype.getSitesList = function() {
  return /** @type{!Array<!proto.vtv1.SiteToQuoteWith>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vtv1.SiteToQuoteWith, 1));
};


/**
 * @param {!Array<!proto.vtv1.SiteToQuoteWith>} value
 * @return {!proto.vtv1.SitesToQuoteWithResponse} returns this
*/
proto.vtv1.SitesToQuoteWithResponse.prototype.setSitesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.vtv1.SiteToQuoteWith=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.SiteToQuoteWith}
 */
proto.vtv1.SitesToQuoteWithResponse.prototype.addSites = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.vtv1.SiteToQuoteWith, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.SitesToQuoteWithResponse} returns this
 */
proto.vtv1.SitesToQuoteWithResponse.prototype.clearSitesList = function() {
  return this.setSitesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.GetLocationsByAddressSMRPayload.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.GetLocationsByAddressSMRPayload.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.GetLocationsByAddressSMRPayload} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.GetLocationsByAddressSMRPayload.toObject = function(includeInstance, msg) {
  var f, obj = {
    address: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.GetLocationsByAddressSMRPayload}
 */
proto.vtv1.GetLocationsByAddressSMRPayload.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.GetLocationsByAddressSMRPayload;
  return proto.vtv1.GetLocationsByAddressSMRPayload.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.GetLocationsByAddressSMRPayload} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.GetLocationsByAddressSMRPayload}
 */
proto.vtv1.GetLocationsByAddressSMRPayload.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddress(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.GetLocationsByAddressSMRPayload.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.GetLocationsByAddressSMRPayload.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.GetLocationsByAddressSMRPayload} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.GetLocationsByAddressSMRPayload.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAddress();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string address = 1;
 * @return {string}
 */
proto.vtv1.GetLocationsByAddressSMRPayload.prototype.getAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.GetLocationsByAddressSMRPayload} returns this
 */
proto.vtv1.GetLocationsByAddressSMRPayload.prototype.setAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.GetLocationsByAddressSMRResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.GetLocationsByAddressSMRResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.GetLocationsByAddressSMRResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.GetLocationsByAddressSMRResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.GetLocationsByAddressSMRResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    locationsList: jspb.Message.toObjectList(msg.getLocationsList(),
    proto_vorto_sites_sites_pb.Location.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.GetLocationsByAddressSMRResponse}
 */
proto.vtv1.GetLocationsByAddressSMRResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.GetLocationsByAddressSMRResponse;
  return proto.vtv1.GetLocationsByAddressSMRResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.GetLocationsByAddressSMRResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.GetLocationsByAddressSMRResponse}
 */
proto.vtv1.GetLocationsByAddressSMRResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_vorto_sites_sites_pb.Location;
      reader.readMessage(value,proto_vorto_sites_sites_pb.Location.deserializeBinaryFromReader);
      msg.addLocations(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.GetLocationsByAddressSMRResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.GetLocationsByAddressSMRResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.GetLocationsByAddressSMRResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.GetLocationsByAddressSMRResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLocationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto_vorto_sites_sites_pb.Location.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Location locations = 1;
 * @return {!Array<!proto.vtv1.Location>}
 */
proto.vtv1.GetLocationsByAddressSMRResponse.prototype.getLocationsList = function() {
  return /** @type{!Array<!proto.vtv1.Location>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_vorto_sites_sites_pb.Location, 1));
};


/**
 * @param {!Array<!proto.vtv1.Location>} value
 * @return {!proto.vtv1.GetLocationsByAddressSMRResponse} returns this
*/
proto.vtv1.GetLocationsByAddressSMRResponse.prototype.setLocationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.vtv1.Location=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.Location}
 */
proto.vtv1.GetLocationsByAddressSMRResponse.prototype.addLocations = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.vtv1.Location, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.GetLocationsByAddressSMRResponse} returns this
 */
proto.vtv1.GetLocationsByAddressSMRResponse.prototype.clearLocationsList = function() {
  return this.setLocationsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.GetPriceToBeatPayload.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.GetPriceToBeatPayload.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.GetPriceToBeatPayload} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.GetPriceToBeatPayload.toObject = function(includeInstance, msg) {
  var f, obj = {
    longitude: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    latitude: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.GetPriceToBeatPayload}
 */
proto.vtv1.GetPriceToBeatPayload.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.GetPriceToBeatPayload;
  return proto.vtv1.GetPriceToBeatPayload.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.GetPriceToBeatPayload} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.GetPriceToBeatPayload}
 */
proto.vtv1.GetPriceToBeatPayload.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLongitude(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLatitude(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.GetPriceToBeatPayload.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.GetPriceToBeatPayload.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.GetPriceToBeatPayload} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.GetPriceToBeatPayload.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLongitude();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = message.getLatitude();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
};


/**
 * optional double longitude = 1;
 * @return {number}
 */
proto.vtv1.GetPriceToBeatPayload.prototype.getLongitude = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.GetPriceToBeatPayload} returns this
 */
proto.vtv1.GetPriceToBeatPayload.prototype.setLongitude = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional double latitude = 2;
 * @return {number}
 */
proto.vtv1.GetPriceToBeatPayload.prototype.getLatitude = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.GetPriceToBeatPayload} returns this
 */
proto.vtv1.GetPriceToBeatPayload.prototype.setLatitude = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.GetPriceToBeatResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.GetPriceToBeatResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.GetPriceToBeatResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.GetPriceToBeatResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    isValid: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    supplierPaysShippingUnitPrice: jspb.Message.getFieldWithDefault(msg, 2, 0),
    consumerPaysShippingUnitPrice: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.GetPriceToBeatResponse}
 */
proto.vtv1.GetPriceToBeatResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.GetPriceToBeatResponse;
  return proto.vtv1.GetPriceToBeatResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.GetPriceToBeatResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.GetPriceToBeatResponse}
 */
proto.vtv1.GetPriceToBeatResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsValid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSupplierPaysShippingUnitPrice(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setConsumerPaysShippingUnitPrice(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.GetPriceToBeatResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.GetPriceToBeatResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.GetPriceToBeatResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.GetPriceToBeatResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsValid();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getSupplierPaysShippingUnitPrice();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getConsumerPaysShippingUnitPrice();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
};


/**
 * optional bool is_valid = 1;
 * @return {boolean}
 */
proto.vtv1.GetPriceToBeatResponse.prototype.getIsValid = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vtv1.GetPriceToBeatResponse} returns this
 */
proto.vtv1.GetPriceToBeatResponse.prototype.setIsValid = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional int64 supplier_pays_shipping_unit_price = 2;
 * @return {number}
 */
proto.vtv1.GetPriceToBeatResponse.prototype.getSupplierPaysShippingUnitPrice = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.GetPriceToBeatResponse} returns this
 */
proto.vtv1.GetPriceToBeatResponse.prototype.setSupplierPaysShippingUnitPrice = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 consumer_pays_shipping_unit_price = 3;
 * @return {number}
 */
proto.vtv1.GetPriceToBeatResponse.prototype.getConsumerPaysShippingUnitPrice = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.GetPriceToBeatResponse} returns this
 */
proto.vtv1.GetPriceToBeatResponse.prototype.setConsumerPaysShippingUnitPrice = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


goog.object.extend(exports, proto.vtv1);
