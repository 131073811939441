import { Directive, Input, OnDestroy } from '@angular/core';
import { TitleService } from '../../singleton/services/title.service';

@Directive({
  selector: '[vtPageTitle]',
})
export class PageTitleDirective implements OnDestroy {
  @Input('vtPageTitle') public set pageTitle(title: string) {
    this.titleService.setTitle(title);
  }

  constructor(private titleService: TitleService) {}

  public ngOnDestroy() {
    this.titleService.setTitle(null);
  }
}
