import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'joinWithOr',
})
export class JoinWithOrPipe implements PipeTransform {
  public transform(value: any[]): string {
    if (!Array.isArray(value)) {
      return value;
    }

    return joinWithOr(value);
  }
}

export function joinWithOr(arr: any[]) {
  if (arr.length === 1) {
    return arr[0];
  }
  const firsts = arr.slice(0, arr.length - 1);
  const last = arr[arr.length - 1];
  return `${firsts.join(', ')} or ${last}`;
}
