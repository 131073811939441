import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { InitializationService } from '../../singleton/services/initialization.service';
import { UsersService } from '../../modules/user-management/users.service';
import { Observable, of, switchMap } from 'rxjs';
import { StringId, StringIdName } from '~proto/types/types_pb';
import { UntypedFormControl } from '@angular/forms';
import { FusedObservable } from '~utilities/fusedObservable';
import { distinctUntilChanged, take, takeUntil } from 'rxjs/operators';
import { DestroyableComponent } from '../../shared/components';
import { GrpcService } from '../../singleton/services/grpc.service';
import { UserService } from '~proto/user/user_api_pb_service';
import { SnackbarService } from '../../singleton/services/snackbar.service';

@Component({
  selector: 'vt-current-account',
  templateUrl: './current-account.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CurrentAccountComponent extends DestroyableComponent {
  public allowedAccounts$: Observable<StringIdName.AsObject[]>;
  public searchField = new UntypedFormControl(null);
  public accountSelectorFC = new UntypedFormControl(null);

  constructor(
    initializationService: InitializationService,
    private userService: UsersService,
    private grpcService: GrpcService,
    private snackbarService: SnackbarService,
  ) {
    super();
    initializationService.myUserInfo$.pipe(take(1)).subscribe({
      next: (value) => {
        this.accountSelectorFC.setValue(value.accountId);
        this.setupAccountListener();
      },
    });
    const allowedAccounts$ = initializationService.myAllowedAccounts$;
    this.allowedAccounts$ = new FusedObservable<StringIdName.AsObject>(
      allowedAccounts$,
      this.searchField.valueChanges,
      {
        distance: 1000,
        keys: ['name'],
        location: 0,
        minMatchCharLength: 1,
        shouldSort: false,
        threshold: 0.2,
      },
    ).fused$;
  }

  private setupAccountListener() {
    this.accountSelectorFC.valueChanges
      .pipe(
        takeUntil(this.destroy$$),
        distinctUntilChanged(),
        switchMap((newAccountId) => {
          if (!newAccountId) {
            return of(null);
          }
          const req = new StringId();
          req.setId(newAccountId);
          return this.grpcService.invoke$(UserService.SetMyAccount, req);
        }),
      )
      .subscribe({
        next: (response) => {
          if (response) {
            this.snackbarService.showMessage(response.getUserMessage());
            setTimeout(() => window.location.reload(), 500);
          }
        },
      });
  }
}
