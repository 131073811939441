import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ErrorHandler } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoggerModule, NgxLoggerLevel, NGXLogger } from 'ngx-logger';
import { environment } from '~environments/environment';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { InterceptorService } from './singleton/services/token-interceptor.service';
import { ProfileComponent } from './profile/profile.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from './shared/shared.module';
import { MaterialModule } from './shared/material/material.module';
import { ServiceWorkerModule } from '@angular/service-worker';
import { ChatbotModule } from './modules/chatbot/chatbot.module';
import { NotificationsListComponent, AccountNotificationsListComponent } from './profile';
import { RoleGuard } from './singleton/guards/role.guard';
import { NotFoundComponent } from './not-found/not-found.component';
import { TrendModule } from 'ngx-trend';
import { LogRocketErrorHandler } from './logrocket-error-handler';
import { VersioningComponent } from './singleton/components/versioning/versioning.component';
import { CurrentAccountComponent } from './profile/current-account/current-account.component';

@NgModule({
  declarations: [
    AppComponent,
    ProfileComponent,
    NotificationsListComponent,
    NotFoundComponent,
    VersioningComponent,
    AccountNotificationsListComponent,
    CurrentAccountComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    LoggerModule.forRoot({
      level: environment.production ? NgxLoggerLevel.DEBUG : NgxLoggerLevel.ERROR,
      colorScheme: ['blue', 'teal', 'gray', 'gray', 'red', 'red', 'red'],
      enableSourceMaps: !environment.production,
    }),
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    MaterialModule,
    TrendModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !window.location.href.includes('localhost'),
      registrationStrategy: 'registerImmediately',
    }),
    ChatbotModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptorService,
      multi: true,
    },
    RoleGuard,
    { provide: ErrorHandler, useClass: LogRocketErrorHandler, deps: [NGXLogger] },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
