import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { InternalService } from './internal.service';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class InternalGuard implements CanActivate {
  constructor(private internalService: InternalService) {}

  public canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.internalService.isInternal$.pipe(filter((v) => v !== null));
  }
}
