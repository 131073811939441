<div class="flex flex-col bg-white h-full p-1 pr-2 pl-2">
  <div class="flex h-3 mt-3">
    <img
      routerLink="/"
      class="cursor-pointer focus:outline-none"
      style="height: 18px"
      src="./assets/images/nav/vorto_gray.svg"
    />
    <span class="flex items-center absolute cursor-pointer text-gray-600" style="top: 24px; right: 24px"
      >esc&nbsp;<mat-icon class="text-gray-600">close</mat-icon></span
    >
  </div>
  <div class="flex flex-col mt-3 pb-2">
    <a routerLink="/vmi/inventories" class="items-center grid grid-cols-10 hover:bg-indigo-100 p-1 rounded mt-1">
      <mat-icon class="mr-1 col-span-1" inline>inventory</mat-icon>
      <span class="text-sm font-medium col-span-6"> Inventories </span>
    </a>
    <a routerLink="/vmi/facilities" class="items-center grid grid-cols-10 hover:bg-indigo-100 p-1 rounded mt-1">
      <mat-icon class="mr-1 col-span-1" inline>warehouse</mat-icon>
      <span class="text-sm font-medium col-span-6"> Facilities </span>
    </a>
    <a routerLink="/consumer/orders/list" class="items-center grid grid-cols-10 hover:bg-indigo-100 p-1 rounded mt-1"
      ><img class="mr-1 col-span-1" src="./assets/images/lite/nav/billing.svg" />
      <span class="text-sm font-medium col-span-6"> Orders </span>
    </a>
    <a routerLink="/users" class="items-center grid grid-cols-10 hover:bg-indigo-100 p-1 rounded mt-1" *vtNotViewOnly>
      <mat-icon class="mr-1 col-span-1" inline>people</mat-icon>
      <span class="text-sm font-medium col-span-6"> Users </span>
    </a>
    <a
      href="https://rti.shaleapps.com/boards/26"
      target="_blank"
      class="items-center grid grid-cols-10 hover:bg-indigo-100 p-1 rounded mt-1"
    >
      <mat-icon class="mr-1 col-span-1" inline>summarize</mat-icon>
      <span class="text-sm font-medium col-span-6">Custom Reporting</span>
    </a>
  </div>
  <div class="mt-auto flex flex flex-col">
    <div
      class="items-center grid grid-cols-10 p-1 hover:bg-indigo-100 rounded cursor-pointer"
      (click)="sideNav.intercomService.show()"
    >
      <span class="text-sm font-semibold col-span-6">Support</span>
    </div>
    <div
      class="flex flex-row justify-between py-2 mt-2 border-t border-gray-600 w-full"
      *ngIf="sideNav.myUserInfo$ | async as userProfile"
    >
      <a class="flex flex-col pl-1" routerLink="/profile">
        <span class="font-semibold text-sm">{{ userProfile.name }}</span>
        <span class="text-sm">{{ userProfile.accountName }}</span>
      </a>
      <vt-button (click)="sideNav.logout()" color="altIndigo">
        <img src="assets/images/lite/nav/logout.svg" />
      </vt-button>
    </div>
  </div>
</div>
