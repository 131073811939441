import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'plural',
})
export class PluralPipe implements PipeTransform {
  public transform(value: any[]): unknown {
    return value.length > 1 ? 's' : '';
  }
}
