// source: proto/vorto/edge/edge_api.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var proto_vorto_edge_edge_pb = require('../../../proto/vorto/edge/edge_pb.js');
goog.object.extend(proto, proto_vorto_edge_edge_pb);
var proto_vorto_types_types_pb = require('../../../proto/vorto/types/types_pb.js');
goog.object.extend(proto, proto_vorto_types_types_pb);
goog.exportSymbol('proto.vtv1.AddDriverRequest', null, global);
goog.exportSymbol('proto.vtv1.AddDriverResponse', null, global);
goog.exportSymbol('proto.vtv1.AddShiftForDriverRequest', null, global);
goog.exportSymbol('proto.vtv1.ArchiveDriverGroupRequest', null, global);
goog.exportSymbol('proto.vtv1.AssignShipmentAndCreateShiftRequest', null, global);
goog.exportSymbol('proto.vtv1.AssignShipmentAndCreateShiftResponse', null, global);
goog.exportSymbol('proto.vtv1.AssignShipmentRequest', null, global);
goog.exportSymbol('proto.vtv1.CalendarDataFromStream', null, global);
goog.exportSymbol('proto.vtv1.CloseEdgeContractRequest', null, global);
goog.exportSymbol('proto.vtv1.CloseEdgeContractResponse', null, global);
goog.exportSymbol('proto.vtv1.CompleteNextStopOfShipmentRequest', null, global);
goog.exportSymbol('proto.vtv1.CompletedShipmentSummaryRequest', null, global);
goog.exportSymbol('proto.vtv1.CompletedShipmentSummaryResponse', null, global);
goog.exportSymbol('proto.vtv1.CreateDriverGroupRequest', null, global);
goog.exportSymbol('proto.vtv1.CreateEdgeContractRequestAsEdge', null, global);
goog.exportSymbol('proto.vtv1.CreateShipmentRequest', null, global);
goog.exportSymbol('proto.vtv1.DeleteShiftForDriverRequest', null, global);
goog.exportSymbol('proto.vtv1.DriverGroupResponse', null, global);
goog.exportSymbol('proto.vtv1.DriverSearchRequest', null, global);
goog.exportSymbol('proto.vtv1.EdgeBulkUploadScheduleShift', null, global);
goog.exportSymbol('proto.vtv1.EdgeBulkUploadShiftScheduleRequest', null, global);
goog.exportSymbol('proto.vtv1.EdgeBulkUploadShiftScheduleResponse', null, global);
goog.exportSymbol('proto.vtv1.EdgeBulkUploadShiftScheduleVerification', null, global);
goog.exportSymbol('proto.vtv1.EdgeCreateSiteRequest', null, global);
goog.exportSymbol('proto.vtv1.EdgeCreateSiteResponse', null, global);
goog.exportSymbol('proto.vtv1.EditEdgeContractRequestAsEdge', null, global);
goog.exportSymbol('proto.vtv1.EditShiftForDriverRequest', null, global);
goog.exportSymbol('proto.vtv1.GetCalendarDataRequest', null, global);
goog.exportSymbol('proto.vtv1.GetCalendarDataResponse', null, global);
goog.exportSymbol('proto.vtv1.GetEdgeContractNodesRequest', null, global);
goog.exportSymbol('proto.vtv1.GetEdgeContractNodesResponse', null, global);
goog.exportSymbol('proto.vtv1.GetEdgeContractRequest', null, global);
goog.exportSymbol('proto.vtv1.GetEdgeContractsRequest', null, global);
goog.exportSymbol('proto.vtv1.GetEdgeContractsResponse', null, global);
goog.exportSymbol('proto.vtv1.GetPreviousAndNextShiftRequest', null, global);
goog.exportSymbol('proto.vtv1.GetPreviousAndNextShiftResponse', null, global);
goog.exportSymbol('proto.vtv1.GetShipperSitesResponse', null, global);
goog.exportSymbol('proto.vtv1.InProgressShipmentSummaryResponse', null, global);
goog.exportSymbol('proto.vtv1.ListDriversForAssignmentResponse', null, global);
goog.exportSymbol('proto.vtv1.ListEdgeContractsRequest', null, global);
goog.exportSymbol('proto.vtv1.ListEdgeContractsResponseAsEdge', null, global);
goog.exportSymbol('proto.vtv1.ListNodesResponse', null, global);
goog.exportSymbol('proto.vtv1.ListOfTrailersResponse', null, global);
goog.exportSymbol('proto.vtv1.ListPendingShipmentsForDriverRequest', null, global);
goog.exportSymbol('proto.vtv1.ListPendingShipmentsForDriverResponse', null, global);
goog.exportSymbol('proto.vtv1.NodeProductsResponse', null, global);
goog.exportSymbol('proto.vtv1.NodeSitesResponse', null, global);
goog.exportSymbol('proto.vtv1.PendingShipmentSummaryResponse', null, global);
goog.exportSymbol('proto.vtv1.RemoveDriverResponse', null, global);
goog.exportSymbol('proto.vtv1.ShipmentDetailsResponse', null, global);
goog.exportSymbol('proto.vtv1.ShipmentResponse', null, global);
goog.exportSymbol('proto.vtv1.ShipmentsResponse', null, global);
goog.exportSymbol('proto.vtv1.ShipperSite', null, global);
goog.exportSymbol('proto.vtv1.SingleEdgeContractAsEdge', null, global);
goog.exportSymbol('proto.vtv1.ToggleAutoDispatchRequest', null, global);
goog.exportSymbol('proto.vtv1.UpdateDriverGroupRequest', null, global);
goog.exportSymbol('proto.vtv1.UpdateShipmentRequest', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.ArchiveDriverGroupRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.ArchiveDriverGroupRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.ArchiveDriverGroupRequest.displayName = 'proto.vtv1.ArchiveDriverGroupRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.UpdateDriverGroupRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.UpdateDriverGroupRequest.repeatedFields_, null);
};
goog.inherits(proto.vtv1.UpdateDriverGroupRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.UpdateDriverGroupRequest.displayName = 'proto.vtv1.UpdateDriverGroupRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.DriverGroupResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.DriverGroupResponse.repeatedFields_, null);
};
goog.inherits(proto.vtv1.DriverGroupResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.DriverGroupResponse.displayName = 'proto.vtv1.DriverGroupResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.GetShipperSitesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.GetShipperSitesResponse.repeatedFields_, null);
};
goog.inherits(proto.vtv1.GetShipperSitesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.GetShipperSitesResponse.displayName = 'proto.vtv1.GetShipperSitesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.ShipperSite = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.ShipperSite.repeatedFields_, null);
};
goog.inherits(proto.vtv1.ShipperSite, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.ShipperSite.displayName = 'proto.vtv1.ShipperSite';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.CreateDriverGroupRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.CreateDriverGroupRequest.repeatedFields_, null);
};
goog.inherits(proto.vtv1.CreateDriverGroupRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.CreateDriverGroupRequest.displayName = 'proto.vtv1.CreateDriverGroupRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.GetPreviousAndNextShiftRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.GetPreviousAndNextShiftRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.GetPreviousAndNextShiftRequest.displayName = 'proto.vtv1.GetPreviousAndNextShiftRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.GetPreviousAndNextShiftResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.GetPreviousAndNextShiftResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.GetPreviousAndNextShiftResponse.displayName = 'proto.vtv1.GetPreviousAndNextShiftResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.CalendarDataFromStream = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.CalendarDataFromStream, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.CalendarDataFromStream.displayName = 'proto.vtv1.CalendarDataFromStream';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.ListOfTrailersResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.ListOfTrailersResponse.repeatedFields_, null);
};
goog.inherits(proto.vtv1.ListOfTrailersResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.ListOfTrailersResponse.displayName = 'proto.vtv1.ListOfTrailersResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.EdgeBulkUploadScheduleShift = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.EdgeBulkUploadScheduleShift, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.EdgeBulkUploadScheduleShift.displayName = 'proto.vtv1.EdgeBulkUploadScheduleShift';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.EdgeBulkUploadShiftScheduleVerification = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.EdgeBulkUploadShiftScheduleVerification, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.EdgeBulkUploadShiftScheduleVerification.displayName = 'proto.vtv1.EdgeBulkUploadShiftScheduleVerification';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.EdgeBulkUploadShiftScheduleRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.EdgeBulkUploadShiftScheduleRequest.repeatedFields_, null);
};
goog.inherits(proto.vtv1.EdgeBulkUploadShiftScheduleRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.EdgeBulkUploadShiftScheduleRequest.displayName = 'proto.vtv1.EdgeBulkUploadShiftScheduleRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.EdgeBulkUploadShiftScheduleResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.EdgeBulkUploadShiftScheduleResponse.repeatedFields_, null);
};
goog.inherits(proto.vtv1.EdgeBulkUploadShiftScheduleResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.EdgeBulkUploadShiftScheduleResponse.displayName = 'proto.vtv1.EdgeBulkUploadShiftScheduleResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.CreateEdgeContractRequestAsEdge = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.CreateEdgeContractRequestAsEdge.repeatedFields_, null);
};
goog.inherits(proto.vtv1.CreateEdgeContractRequestAsEdge, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.CreateEdgeContractRequestAsEdge.displayName = 'proto.vtv1.CreateEdgeContractRequestAsEdge';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.EditEdgeContractRequestAsEdge = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.EditEdgeContractRequestAsEdge.repeatedFields_, null);
};
goog.inherits(proto.vtv1.EditEdgeContractRequestAsEdge, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.EditEdgeContractRequestAsEdge.displayName = 'proto.vtv1.EditEdgeContractRequestAsEdge';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.SingleEdgeContractAsEdge = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.SingleEdgeContractAsEdge.repeatedFields_, null);
};
goog.inherits(proto.vtv1.SingleEdgeContractAsEdge, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.SingleEdgeContractAsEdge.displayName = 'proto.vtv1.SingleEdgeContractAsEdge';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.ListEdgeContractsResponseAsEdge = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.ListEdgeContractsResponseAsEdge.repeatedFields_, null);
};
goog.inherits(proto.vtv1.ListEdgeContractsResponseAsEdge, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.ListEdgeContractsResponseAsEdge.displayName = 'proto.vtv1.ListEdgeContractsResponseAsEdge';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.ListPendingShipmentsForDriverRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.ListPendingShipmentsForDriverRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.ListPendingShipmentsForDriverRequest.displayName = 'proto.vtv1.ListPendingShipmentsForDriverRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.ListPendingShipmentsForDriverResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.ListPendingShipmentsForDriverResponse.repeatedFields_, null);
};
goog.inherits(proto.vtv1.ListPendingShipmentsForDriverResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.ListPendingShipmentsForDriverResponse.displayName = 'proto.vtv1.ListPendingShipmentsForDriverResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.AddDriverRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.AddDriverRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.AddDriverRequest.displayName = 'proto.vtv1.AddDriverRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.AddDriverResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.AddDriverResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.AddDriverResponse.displayName = 'proto.vtv1.AddDriverResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.RemoveDriverResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.RemoveDriverResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.RemoveDriverResponse.displayName = 'proto.vtv1.RemoveDriverResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.CloseEdgeContractRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.CloseEdgeContractRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.CloseEdgeContractRequest.displayName = 'proto.vtv1.CloseEdgeContractRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.CloseEdgeContractResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.CloseEdgeContractResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.CloseEdgeContractResponse.displayName = 'proto.vtv1.CloseEdgeContractResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.GetEdgeContractRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.GetEdgeContractRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.GetEdgeContractRequest.displayName = 'proto.vtv1.GetEdgeContractRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.GetEdgeContractsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.GetEdgeContractsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.GetEdgeContractsRequest.displayName = 'proto.vtv1.GetEdgeContractsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.GetEdgeContractsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.GetEdgeContractsResponse.repeatedFields_, null);
};
goog.inherits(proto.vtv1.GetEdgeContractsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.GetEdgeContractsResponse.displayName = 'proto.vtv1.GetEdgeContractsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.ShipmentResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.ShipmentResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.ShipmentResponse.displayName = 'proto.vtv1.ShipmentResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.ShipmentsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.ShipmentsResponse.repeatedFields_, null);
};
goog.inherits(proto.vtv1.ShipmentsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.ShipmentsResponse.displayName = 'proto.vtv1.ShipmentsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.GetEdgeContractNodesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.GetEdgeContractNodesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.GetEdgeContractNodesRequest.displayName = 'proto.vtv1.GetEdgeContractNodesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.GetEdgeContractNodesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.GetEdgeContractNodesResponse.repeatedFields_, null);
};
goog.inherits(proto.vtv1.GetEdgeContractNodesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.GetEdgeContractNodesResponse.displayName = 'proto.vtv1.GetEdgeContractNodesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.CreateShipmentRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.CreateShipmentRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.CreateShipmentRequest.displayName = 'proto.vtv1.CreateShipmentRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.AssignShipmentRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.AssignShipmentRequest.repeatedFields_, null);
};
goog.inherits(proto.vtv1.AssignShipmentRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.AssignShipmentRequest.displayName = 'proto.vtv1.AssignShipmentRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.NodeSitesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.NodeSitesResponse.repeatedFields_, null);
};
goog.inherits(proto.vtv1.NodeSitesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.NodeSitesResponse.displayName = 'proto.vtv1.NodeSitesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.NodeProductsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.NodeProductsResponse.repeatedFields_, null);
};
goog.inherits(proto.vtv1.NodeProductsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.NodeProductsResponse.displayName = 'proto.vtv1.NodeProductsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.EdgeCreateSiteRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.EdgeCreateSiteRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.EdgeCreateSiteRequest.displayName = 'proto.vtv1.EdgeCreateSiteRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.EdgeCreateSiteResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.EdgeCreateSiteResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.EdgeCreateSiteResponse.displayName = 'proto.vtv1.EdgeCreateSiteResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.PendingShipmentSummaryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.PendingShipmentSummaryResponse.repeatedFields_, null);
};
goog.inherits(proto.vtv1.PendingShipmentSummaryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.PendingShipmentSummaryResponse.displayName = 'proto.vtv1.PendingShipmentSummaryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.InProgressShipmentSummaryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.InProgressShipmentSummaryResponse.repeatedFields_, null);
};
goog.inherits(proto.vtv1.InProgressShipmentSummaryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.InProgressShipmentSummaryResponse.displayName = 'proto.vtv1.InProgressShipmentSummaryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.CompletedShipmentSummaryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.CompletedShipmentSummaryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.CompletedShipmentSummaryRequest.displayName = 'proto.vtv1.CompletedShipmentSummaryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.CompletedShipmentSummaryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.CompletedShipmentSummaryResponse.repeatedFields_, null);
};
goog.inherits(proto.vtv1.CompletedShipmentSummaryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.CompletedShipmentSummaryResponse.displayName = 'proto.vtv1.CompletedShipmentSummaryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.CompleteNextStopOfShipmentRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.CompleteNextStopOfShipmentRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.CompleteNextStopOfShipmentRequest.displayName = 'proto.vtv1.CompleteNextStopOfShipmentRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.ShipmentDetailsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.ShipmentDetailsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.ShipmentDetailsResponse.displayName = 'proto.vtv1.ShipmentDetailsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.ListDriversForAssignmentResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.ListDriversForAssignmentResponse.repeatedFields_, null);
};
goog.inherits(proto.vtv1.ListDriversForAssignmentResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.ListDriversForAssignmentResponse.displayName = 'proto.vtv1.ListDriversForAssignmentResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.DriverSearchRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.DriverSearchRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.DriverSearchRequest.displayName = 'proto.vtv1.DriverSearchRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.ListNodesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.ListNodesResponse.repeatedFields_, null);
};
goog.inherits(proto.vtv1.ListNodesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.ListNodesResponse.displayName = 'proto.vtv1.ListNodesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.GetCalendarDataRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.GetCalendarDataRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.GetCalendarDataRequest.displayName = 'proto.vtv1.GetCalendarDataRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.GetCalendarDataResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.GetCalendarDataResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.GetCalendarDataResponse.displayName = 'proto.vtv1.GetCalendarDataResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.AddShiftForDriverRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.AddShiftForDriverRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.AddShiftForDriverRequest.displayName = 'proto.vtv1.AddShiftForDriverRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.EditShiftForDriverRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.EditShiftForDriverRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.EditShiftForDriverRequest.displayName = 'proto.vtv1.EditShiftForDriverRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.DeleteShiftForDriverRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.DeleteShiftForDriverRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.DeleteShiftForDriverRequest.displayName = 'proto.vtv1.DeleteShiftForDriverRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.ListEdgeContractsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.ListEdgeContractsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.ListEdgeContractsRequest.displayName = 'proto.vtv1.ListEdgeContractsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.ToggleAutoDispatchRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.ToggleAutoDispatchRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.ToggleAutoDispatchRequest.displayName = 'proto.vtv1.ToggleAutoDispatchRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.UpdateShipmentRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.UpdateShipmentRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.UpdateShipmentRequest.displayName = 'proto.vtv1.UpdateShipmentRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.AssignShipmentAndCreateShiftRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.AssignShipmentAndCreateShiftRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.AssignShipmentAndCreateShiftRequest.displayName = 'proto.vtv1.AssignShipmentAndCreateShiftRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.AssignShipmentAndCreateShiftResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.AssignShipmentAndCreateShiftResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.AssignShipmentAndCreateShiftResponse.displayName = 'proto.vtv1.AssignShipmentAndCreateShiftResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.ArchiveDriverGroupRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.ArchiveDriverGroupRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.ArchiveDriverGroupRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.ArchiveDriverGroupRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    groupId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    startTimestamp: jspb.Message.getFieldWithDefault(msg, 2, ""),
    numberOfDays: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.ArchiveDriverGroupRequest}
 */
proto.vtv1.ArchiveDriverGroupRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.ArchiveDriverGroupRequest;
  return proto.vtv1.ArchiveDriverGroupRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.ArchiveDriverGroupRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.ArchiveDriverGroupRequest}
 */
proto.vtv1.ArchiveDriverGroupRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setGroupId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setStartTimestamp(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setNumberOfDays(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.ArchiveDriverGroupRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.ArchiveDriverGroupRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.ArchiveDriverGroupRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.ArchiveDriverGroupRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGroupId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStartTimestamp();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getNumberOfDays();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
};


/**
 * optional string group_id = 1;
 * @return {string}
 */
proto.vtv1.ArchiveDriverGroupRequest.prototype.getGroupId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.ArchiveDriverGroupRequest} returns this
 */
proto.vtv1.ArchiveDriverGroupRequest.prototype.setGroupId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string start_timestamp = 2;
 * @return {string}
 */
proto.vtv1.ArchiveDriverGroupRequest.prototype.getStartTimestamp = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.ArchiveDriverGroupRequest} returns this
 */
proto.vtv1.ArchiveDriverGroupRequest.prototype.setStartTimestamp = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 number_of_days = 3;
 * @return {number}
 */
proto.vtv1.ArchiveDriverGroupRequest.prototype.getNumberOfDays = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.ArchiveDriverGroupRequest} returns this
 */
proto.vtv1.ArchiveDriverGroupRequest.prototype.setNumberOfDays = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.UpdateDriverGroupRequest.repeatedFields_ = [2,3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.UpdateDriverGroupRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.UpdateDriverGroupRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.UpdateDriverGroupRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.UpdateDriverGroupRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    groupId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    siteIdsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    driverIdsList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    name: jspb.Message.getFieldWithDefault(msg, 4, ""),
    startTimestamp: jspb.Message.getFieldWithDefault(msg, 5, ""),
    numberOfDays: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.UpdateDriverGroupRequest}
 */
proto.vtv1.UpdateDriverGroupRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.UpdateDriverGroupRequest;
  return proto.vtv1.UpdateDriverGroupRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.UpdateDriverGroupRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.UpdateDriverGroupRequest}
 */
proto.vtv1.UpdateDriverGroupRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setGroupId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addSiteIds(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addDriverIds(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setStartTimestamp(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setNumberOfDays(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.UpdateDriverGroupRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.UpdateDriverGroupRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.UpdateDriverGroupRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.UpdateDriverGroupRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGroupId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSiteIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
  f = message.getDriverIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getStartTimestamp();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getNumberOfDays();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
};


/**
 * optional string group_id = 1;
 * @return {string}
 */
proto.vtv1.UpdateDriverGroupRequest.prototype.getGroupId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.UpdateDriverGroupRequest} returns this
 */
proto.vtv1.UpdateDriverGroupRequest.prototype.setGroupId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated string site_ids = 2;
 * @return {!Array<string>}
 */
proto.vtv1.UpdateDriverGroupRequest.prototype.getSiteIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.vtv1.UpdateDriverGroupRequest} returns this
 */
proto.vtv1.UpdateDriverGroupRequest.prototype.setSiteIdsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.vtv1.UpdateDriverGroupRequest} returns this
 */
proto.vtv1.UpdateDriverGroupRequest.prototype.addSiteIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.UpdateDriverGroupRequest} returns this
 */
proto.vtv1.UpdateDriverGroupRequest.prototype.clearSiteIdsList = function() {
  return this.setSiteIdsList([]);
};


/**
 * repeated string driver_ids = 3;
 * @return {!Array<string>}
 */
proto.vtv1.UpdateDriverGroupRequest.prototype.getDriverIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.vtv1.UpdateDriverGroupRequest} returns this
 */
proto.vtv1.UpdateDriverGroupRequest.prototype.setDriverIdsList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.vtv1.UpdateDriverGroupRequest} returns this
 */
proto.vtv1.UpdateDriverGroupRequest.prototype.addDriverIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.UpdateDriverGroupRequest} returns this
 */
proto.vtv1.UpdateDriverGroupRequest.prototype.clearDriverIdsList = function() {
  return this.setDriverIdsList([]);
};


/**
 * optional string name = 4;
 * @return {string}
 */
proto.vtv1.UpdateDriverGroupRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.UpdateDriverGroupRequest} returns this
 */
proto.vtv1.UpdateDriverGroupRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string start_timestamp = 5;
 * @return {string}
 */
proto.vtv1.UpdateDriverGroupRequest.prototype.getStartTimestamp = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.UpdateDriverGroupRequest} returns this
 */
proto.vtv1.UpdateDriverGroupRequest.prototype.setStartTimestamp = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional int64 number_of_days = 6;
 * @return {number}
 */
proto.vtv1.UpdateDriverGroupRequest.prototype.getNumberOfDays = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.UpdateDriverGroupRequest} returns this
 */
proto.vtv1.UpdateDriverGroupRequest.prototype.setNumberOfDays = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.DriverGroupResponse.repeatedFields_ = [3,4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.DriverGroupResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.DriverGroupResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.DriverGroupResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.DriverGroupResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    usersList: jspb.Message.toObjectList(msg.getUsersList(),
    proto_vorto_types_types_pb.StringIdName.toObject, includeInstance),
    sitesList: jspb.Message.toObjectList(msg.getSitesList(),
    proto_vorto_types_types_pb.StringIdName.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.DriverGroupResponse}
 */
proto.vtv1.DriverGroupResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.DriverGroupResponse;
  return proto.vtv1.DriverGroupResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.DriverGroupResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.DriverGroupResponse}
 */
proto.vtv1.DriverGroupResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = new proto_vorto_types_types_pb.StringIdName;
      reader.readMessage(value,proto_vorto_types_types_pb.StringIdName.deserializeBinaryFromReader);
      msg.addUsers(value);
      break;
    case 4:
      var value = new proto_vorto_types_types_pb.StringIdName;
      reader.readMessage(value,proto_vorto_types_types_pb.StringIdName.deserializeBinaryFromReader);
      msg.addSites(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.DriverGroupResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.DriverGroupResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.DriverGroupResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.DriverGroupResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getUsersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto_vorto_types_types_pb.StringIdName.serializeBinaryToWriter
    );
  }
  f = message.getSitesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto_vorto_types_types_pb.StringIdName.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.vtv1.DriverGroupResponse.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.DriverGroupResponse} returns this
 */
proto.vtv1.DriverGroupResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.vtv1.DriverGroupResponse.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.DriverGroupResponse} returns this
 */
proto.vtv1.DriverGroupResponse.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated StringIdName users = 3;
 * @return {!Array<!proto.vtv1.StringIdName>}
 */
proto.vtv1.DriverGroupResponse.prototype.getUsersList = function() {
  return /** @type{!Array<!proto.vtv1.StringIdName>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_vorto_types_types_pb.StringIdName, 3));
};


/**
 * @param {!Array<!proto.vtv1.StringIdName>} value
 * @return {!proto.vtv1.DriverGroupResponse} returns this
*/
proto.vtv1.DriverGroupResponse.prototype.setUsersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.vtv1.StringIdName=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.StringIdName}
 */
proto.vtv1.DriverGroupResponse.prototype.addUsers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.vtv1.StringIdName, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.DriverGroupResponse} returns this
 */
proto.vtv1.DriverGroupResponse.prototype.clearUsersList = function() {
  return this.setUsersList([]);
};


/**
 * repeated StringIdName sites = 4;
 * @return {!Array<!proto.vtv1.StringIdName>}
 */
proto.vtv1.DriverGroupResponse.prototype.getSitesList = function() {
  return /** @type{!Array<!proto.vtv1.StringIdName>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_vorto_types_types_pb.StringIdName, 4));
};


/**
 * @param {!Array<!proto.vtv1.StringIdName>} value
 * @return {!proto.vtv1.DriverGroupResponse} returns this
*/
proto.vtv1.DriverGroupResponse.prototype.setSitesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.vtv1.StringIdName=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.StringIdName}
 */
proto.vtv1.DriverGroupResponse.prototype.addSites = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.vtv1.StringIdName, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.DriverGroupResponse} returns this
 */
proto.vtv1.DriverGroupResponse.prototype.clearSitesList = function() {
  return this.setSitesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.GetShipperSitesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.GetShipperSitesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.GetShipperSitesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.GetShipperSitesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.GetShipperSitesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    shippersList: jspb.Message.toObjectList(msg.getShippersList(),
    proto.vtv1.ShipperSite.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.GetShipperSitesResponse}
 */
proto.vtv1.GetShipperSitesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.GetShipperSitesResponse;
  return proto.vtv1.GetShipperSitesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.GetShipperSitesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.GetShipperSitesResponse}
 */
proto.vtv1.GetShipperSitesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.vtv1.ShipperSite;
      reader.readMessage(value,proto.vtv1.ShipperSite.deserializeBinaryFromReader);
      msg.addShippers(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.GetShipperSitesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.GetShipperSitesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.GetShipperSitesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.GetShipperSitesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getShippersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.vtv1.ShipperSite.serializeBinaryToWriter
    );
  }
};


/**
 * repeated ShipperSite shippers = 1;
 * @return {!Array<!proto.vtv1.ShipperSite>}
 */
proto.vtv1.GetShipperSitesResponse.prototype.getShippersList = function() {
  return /** @type{!Array<!proto.vtv1.ShipperSite>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vtv1.ShipperSite, 1));
};


/**
 * @param {!Array<!proto.vtv1.ShipperSite>} value
 * @return {!proto.vtv1.GetShipperSitesResponse} returns this
*/
proto.vtv1.GetShipperSitesResponse.prototype.setShippersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.vtv1.ShipperSite=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.ShipperSite}
 */
proto.vtv1.GetShipperSitesResponse.prototype.addShippers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.vtv1.ShipperSite, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.GetShipperSitesResponse} returns this
 */
proto.vtv1.GetShipperSitesResponse.prototype.clearShippersList = function() {
  return this.setShippersList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.ShipperSite.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.ShipperSite.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.ShipperSite.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.ShipperSite} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.ShipperSite.toObject = function(includeInstance, msg) {
  var f, obj = {
    shipperId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    shipperName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    sitesList: jspb.Message.toObjectList(msg.getSitesList(),
    proto_vorto_types_types_pb.StringIdName.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.ShipperSite}
 */
proto.vtv1.ShipperSite.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.ShipperSite;
  return proto.vtv1.ShipperSite.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.ShipperSite} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.ShipperSite}
 */
proto.vtv1.ShipperSite.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setShipperId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setShipperName(value);
      break;
    case 3:
      var value = new proto_vorto_types_types_pb.StringIdName;
      reader.readMessage(value,proto_vorto_types_types_pb.StringIdName.deserializeBinaryFromReader);
      msg.addSites(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.ShipperSite.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.ShipperSite.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.ShipperSite} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.ShipperSite.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getShipperId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getShipperName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSitesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto_vorto_types_types_pb.StringIdName.serializeBinaryToWriter
    );
  }
};


/**
 * optional string shipper_id = 1;
 * @return {string}
 */
proto.vtv1.ShipperSite.prototype.getShipperId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.ShipperSite} returns this
 */
proto.vtv1.ShipperSite.prototype.setShipperId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string shipper_name = 2;
 * @return {string}
 */
proto.vtv1.ShipperSite.prototype.getShipperName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.ShipperSite} returns this
 */
proto.vtv1.ShipperSite.prototype.setShipperName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated StringIdName sites = 3;
 * @return {!Array<!proto.vtv1.StringIdName>}
 */
proto.vtv1.ShipperSite.prototype.getSitesList = function() {
  return /** @type{!Array<!proto.vtv1.StringIdName>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_vorto_types_types_pb.StringIdName, 3));
};


/**
 * @param {!Array<!proto.vtv1.StringIdName>} value
 * @return {!proto.vtv1.ShipperSite} returns this
*/
proto.vtv1.ShipperSite.prototype.setSitesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.vtv1.StringIdName=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.StringIdName}
 */
proto.vtv1.ShipperSite.prototype.addSites = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.vtv1.StringIdName, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.ShipperSite} returns this
 */
proto.vtv1.ShipperSite.prototype.clearSitesList = function() {
  return this.setSitesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.CreateDriverGroupRequest.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.CreateDriverGroupRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.CreateDriverGroupRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.CreateDriverGroupRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.CreateDriverGroupRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    siteIdsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    driverIdsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    name: jspb.Message.getFieldWithDefault(msg, 3, ""),
    startTimestamp: jspb.Message.getFieldWithDefault(msg, 4, ""),
    numberOfDays: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.CreateDriverGroupRequest}
 */
proto.vtv1.CreateDriverGroupRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.CreateDriverGroupRequest;
  return proto.vtv1.CreateDriverGroupRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.CreateDriverGroupRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.CreateDriverGroupRequest}
 */
proto.vtv1.CreateDriverGroupRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addSiteIds(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addDriverIds(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setStartTimestamp(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setNumberOfDays(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.CreateDriverGroupRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.CreateDriverGroupRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.CreateDriverGroupRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.CreateDriverGroupRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSiteIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
  f = message.getDriverIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getStartTimestamp();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getNumberOfDays();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
};


/**
 * repeated string site_ids = 1;
 * @return {!Array<string>}
 */
proto.vtv1.CreateDriverGroupRequest.prototype.getSiteIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.vtv1.CreateDriverGroupRequest} returns this
 */
proto.vtv1.CreateDriverGroupRequest.prototype.setSiteIdsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.vtv1.CreateDriverGroupRequest} returns this
 */
proto.vtv1.CreateDriverGroupRequest.prototype.addSiteIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.CreateDriverGroupRequest} returns this
 */
proto.vtv1.CreateDriverGroupRequest.prototype.clearSiteIdsList = function() {
  return this.setSiteIdsList([]);
};


/**
 * repeated string driver_ids = 2;
 * @return {!Array<string>}
 */
proto.vtv1.CreateDriverGroupRequest.prototype.getDriverIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.vtv1.CreateDriverGroupRequest} returns this
 */
proto.vtv1.CreateDriverGroupRequest.prototype.setDriverIdsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.vtv1.CreateDriverGroupRequest} returns this
 */
proto.vtv1.CreateDriverGroupRequest.prototype.addDriverIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.CreateDriverGroupRequest} returns this
 */
proto.vtv1.CreateDriverGroupRequest.prototype.clearDriverIdsList = function() {
  return this.setDriverIdsList([]);
};


/**
 * optional string name = 3;
 * @return {string}
 */
proto.vtv1.CreateDriverGroupRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.CreateDriverGroupRequest} returns this
 */
proto.vtv1.CreateDriverGroupRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string start_timestamp = 4;
 * @return {string}
 */
proto.vtv1.CreateDriverGroupRequest.prototype.getStartTimestamp = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.CreateDriverGroupRequest} returns this
 */
proto.vtv1.CreateDriverGroupRequest.prototype.setStartTimestamp = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int64 number_of_days = 5;
 * @return {number}
 */
proto.vtv1.CreateDriverGroupRequest.prototype.getNumberOfDays = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.CreateDriverGroupRequest} returns this
 */
proto.vtv1.CreateDriverGroupRequest.prototype.setNumberOfDays = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.GetPreviousAndNextShiftRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.GetPreviousAndNextShiftRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.GetPreviousAndNextShiftRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.GetPreviousAndNextShiftRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    shiftStart: jspb.Message.getFieldWithDefault(msg, 1, ""),
    driverId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    shiftDuration: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.GetPreviousAndNextShiftRequest}
 */
proto.vtv1.GetPreviousAndNextShiftRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.GetPreviousAndNextShiftRequest;
  return proto.vtv1.GetPreviousAndNextShiftRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.GetPreviousAndNextShiftRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.GetPreviousAndNextShiftRequest}
 */
proto.vtv1.GetPreviousAndNextShiftRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setShiftStart(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDriverId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setShiftDuration(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.GetPreviousAndNextShiftRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.GetPreviousAndNextShiftRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.GetPreviousAndNextShiftRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.GetPreviousAndNextShiftRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getShiftStart();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDriverId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getShiftDuration();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
};


/**
 * optional string shift_start = 1;
 * @return {string}
 */
proto.vtv1.GetPreviousAndNextShiftRequest.prototype.getShiftStart = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.GetPreviousAndNextShiftRequest} returns this
 */
proto.vtv1.GetPreviousAndNextShiftRequest.prototype.setShiftStart = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string driver_id = 2;
 * @return {string}
 */
proto.vtv1.GetPreviousAndNextShiftRequest.prototype.getDriverId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.GetPreviousAndNextShiftRequest} returns this
 */
proto.vtv1.GetPreviousAndNextShiftRequest.prototype.setDriverId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 shift_duration = 3;
 * @return {number}
 */
proto.vtv1.GetPreviousAndNextShiftRequest.prototype.getShiftDuration = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.GetPreviousAndNextShiftRequest} returns this
 */
proto.vtv1.GetPreviousAndNextShiftRequest.prototype.setShiftDuration = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.GetPreviousAndNextShiftResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.GetPreviousAndNextShiftResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.GetPreviousAndNextShiftResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.GetPreviousAndNextShiftResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    prevShiftStartTime: (f = msg.getPrevShiftStartTime()) && proto_vorto_types_types_pb.NullableTime.toObject(includeInstance, f),
    prevShiftEndTime: (f = msg.getPrevShiftEndTime()) && proto_vorto_types_types_pb.NullableTime.toObject(includeInstance, f),
    nextShiftStartTime: (f = msg.getNextShiftStartTime()) && proto_vorto_types_types_pb.NullableTime.toObject(includeInstance, f),
    nextShiftEndTime: (f = msg.getNextShiftEndTime()) && proto_vorto_types_types_pb.NullableTime.toObject(includeInstance, f),
    prevShiftTrailerName: (f = msg.getPrevShiftTrailerName()) && proto_vorto_types_types_pb.NullableString.toObject(includeInstance, f),
    nextShiftTrailerName: (f = msg.getNextShiftTrailerName()) && proto_vorto_types_types_pb.NullableString.toObject(includeInstance, f),
    prevShiftOverlaps: (f = msg.getPrevShiftOverlaps()) && proto_vorto_types_types_pb.NullableBool.toObject(includeInstance, f),
    nextShiftOverlaps: (f = msg.getNextShiftOverlaps()) && proto_vorto_types_types_pb.NullableBool.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.GetPreviousAndNextShiftResponse}
 */
proto.vtv1.GetPreviousAndNextShiftResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.GetPreviousAndNextShiftResponse;
  return proto.vtv1.GetPreviousAndNextShiftResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.GetPreviousAndNextShiftResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.GetPreviousAndNextShiftResponse}
 */
proto.vtv1.GetPreviousAndNextShiftResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_vorto_types_types_pb.NullableTime;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableTime.deserializeBinaryFromReader);
      msg.setPrevShiftStartTime(value);
      break;
    case 2:
      var value = new proto_vorto_types_types_pb.NullableTime;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableTime.deserializeBinaryFromReader);
      msg.setPrevShiftEndTime(value);
      break;
    case 3:
      var value = new proto_vorto_types_types_pb.NullableTime;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableTime.deserializeBinaryFromReader);
      msg.setNextShiftStartTime(value);
      break;
    case 4:
      var value = new proto_vorto_types_types_pb.NullableTime;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableTime.deserializeBinaryFromReader);
      msg.setNextShiftEndTime(value);
      break;
    case 5:
      var value = new proto_vorto_types_types_pb.NullableString;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableString.deserializeBinaryFromReader);
      msg.setPrevShiftTrailerName(value);
      break;
    case 6:
      var value = new proto_vorto_types_types_pb.NullableString;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableString.deserializeBinaryFromReader);
      msg.setNextShiftTrailerName(value);
      break;
    case 7:
      var value = new proto_vorto_types_types_pb.NullableBool;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableBool.deserializeBinaryFromReader);
      msg.setPrevShiftOverlaps(value);
      break;
    case 8:
      var value = new proto_vorto_types_types_pb.NullableBool;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableBool.deserializeBinaryFromReader);
      msg.setNextShiftOverlaps(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.GetPreviousAndNextShiftResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.GetPreviousAndNextShiftResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.GetPreviousAndNextShiftResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.GetPreviousAndNextShiftResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPrevShiftStartTime();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_vorto_types_types_pb.NullableTime.serializeBinaryToWriter
    );
  }
  f = message.getPrevShiftEndTime();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_vorto_types_types_pb.NullableTime.serializeBinaryToWriter
    );
  }
  f = message.getNextShiftStartTime();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto_vorto_types_types_pb.NullableTime.serializeBinaryToWriter
    );
  }
  f = message.getNextShiftEndTime();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto_vorto_types_types_pb.NullableTime.serializeBinaryToWriter
    );
  }
  f = message.getPrevShiftTrailerName();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto_vorto_types_types_pb.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getNextShiftTrailerName();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto_vorto_types_types_pb.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getPrevShiftOverlaps();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto_vorto_types_types_pb.NullableBool.serializeBinaryToWriter
    );
  }
  f = message.getNextShiftOverlaps();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto_vorto_types_types_pb.NullableBool.serializeBinaryToWriter
    );
  }
};


/**
 * optional NullableTime prev_shift_start_time = 1;
 * @return {?proto.vtv1.NullableTime}
 */
proto.vtv1.GetPreviousAndNextShiftResponse.prototype.getPrevShiftStartTime = function() {
  return /** @type{?proto.vtv1.NullableTime} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableTime, 1));
};


/**
 * @param {?proto.vtv1.NullableTime|undefined} value
 * @return {!proto.vtv1.GetPreviousAndNextShiftResponse} returns this
*/
proto.vtv1.GetPreviousAndNextShiftResponse.prototype.setPrevShiftStartTime = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.GetPreviousAndNextShiftResponse} returns this
 */
proto.vtv1.GetPreviousAndNextShiftResponse.prototype.clearPrevShiftStartTime = function() {
  return this.setPrevShiftStartTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.GetPreviousAndNextShiftResponse.prototype.hasPrevShiftStartTime = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional NullableTime prev_shift_end_time = 2;
 * @return {?proto.vtv1.NullableTime}
 */
proto.vtv1.GetPreviousAndNextShiftResponse.prototype.getPrevShiftEndTime = function() {
  return /** @type{?proto.vtv1.NullableTime} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableTime, 2));
};


/**
 * @param {?proto.vtv1.NullableTime|undefined} value
 * @return {!proto.vtv1.GetPreviousAndNextShiftResponse} returns this
*/
proto.vtv1.GetPreviousAndNextShiftResponse.prototype.setPrevShiftEndTime = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.GetPreviousAndNextShiftResponse} returns this
 */
proto.vtv1.GetPreviousAndNextShiftResponse.prototype.clearPrevShiftEndTime = function() {
  return this.setPrevShiftEndTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.GetPreviousAndNextShiftResponse.prototype.hasPrevShiftEndTime = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional NullableTime next_shift_start_time = 3;
 * @return {?proto.vtv1.NullableTime}
 */
proto.vtv1.GetPreviousAndNextShiftResponse.prototype.getNextShiftStartTime = function() {
  return /** @type{?proto.vtv1.NullableTime} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableTime, 3));
};


/**
 * @param {?proto.vtv1.NullableTime|undefined} value
 * @return {!proto.vtv1.GetPreviousAndNextShiftResponse} returns this
*/
proto.vtv1.GetPreviousAndNextShiftResponse.prototype.setNextShiftStartTime = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.GetPreviousAndNextShiftResponse} returns this
 */
proto.vtv1.GetPreviousAndNextShiftResponse.prototype.clearNextShiftStartTime = function() {
  return this.setNextShiftStartTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.GetPreviousAndNextShiftResponse.prototype.hasNextShiftStartTime = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional NullableTime next_shift_end_time = 4;
 * @return {?proto.vtv1.NullableTime}
 */
proto.vtv1.GetPreviousAndNextShiftResponse.prototype.getNextShiftEndTime = function() {
  return /** @type{?proto.vtv1.NullableTime} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableTime, 4));
};


/**
 * @param {?proto.vtv1.NullableTime|undefined} value
 * @return {!proto.vtv1.GetPreviousAndNextShiftResponse} returns this
*/
proto.vtv1.GetPreviousAndNextShiftResponse.prototype.setNextShiftEndTime = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.GetPreviousAndNextShiftResponse} returns this
 */
proto.vtv1.GetPreviousAndNextShiftResponse.prototype.clearNextShiftEndTime = function() {
  return this.setNextShiftEndTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.GetPreviousAndNextShiftResponse.prototype.hasNextShiftEndTime = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional NullableString prev_shift_trailer_name = 5;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.GetPreviousAndNextShiftResponse.prototype.getPrevShiftTrailerName = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableString, 5));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.GetPreviousAndNextShiftResponse} returns this
*/
proto.vtv1.GetPreviousAndNextShiftResponse.prototype.setPrevShiftTrailerName = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.GetPreviousAndNextShiftResponse} returns this
 */
proto.vtv1.GetPreviousAndNextShiftResponse.prototype.clearPrevShiftTrailerName = function() {
  return this.setPrevShiftTrailerName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.GetPreviousAndNextShiftResponse.prototype.hasPrevShiftTrailerName = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional NullableString next_shift_trailer_name = 6;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.GetPreviousAndNextShiftResponse.prototype.getNextShiftTrailerName = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableString, 6));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.GetPreviousAndNextShiftResponse} returns this
*/
proto.vtv1.GetPreviousAndNextShiftResponse.prototype.setNextShiftTrailerName = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.GetPreviousAndNextShiftResponse} returns this
 */
proto.vtv1.GetPreviousAndNextShiftResponse.prototype.clearNextShiftTrailerName = function() {
  return this.setNextShiftTrailerName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.GetPreviousAndNextShiftResponse.prototype.hasNextShiftTrailerName = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional NullableBool prev_shift_overlaps = 7;
 * @return {?proto.vtv1.NullableBool}
 */
proto.vtv1.GetPreviousAndNextShiftResponse.prototype.getPrevShiftOverlaps = function() {
  return /** @type{?proto.vtv1.NullableBool} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableBool, 7));
};


/**
 * @param {?proto.vtv1.NullableBool|undefined} value
 * @return {!proto.vtv1.GetPreviousAndNextShiftResponse} returns this
*/
proto.vtv1.GetPreviousAndNextShiftResponse.prototype.setPrevShiftOverlaps = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.GetPreviousAndNextShiftResponse} returns this
 */
proto.vtv1.GetPreviousAndNextShiftResponse.prototype.clearPrevShiftOverlaps = function() {
  return this.setPrevShiftOverlaps(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.GetPreviousAndNextShiftResponse.prototype.hasPrevShiftOverlaps = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional NullableBool next_shift_overlaps = 8;
 * @return {?proto.vtv1.NullableBool}
 */
proto.vtv1.GetPreviousAndNextShiftResponse.prototype.getNextShiftOverlaps = function() {
  return /** @type{?proto.vtv1.NullableBool} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableBool, 8));
};


/**
 * @param {?proto.vtv1.NullableBool|undefined} value
 * @return {!proto.vtv1.GetPreviousAndNextShiftResponse} returns this
*/
proto.vtv1.GetPreviousAndNextShiftResponse.prototype.setNextShiftOverlaps = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.GetPreviousAndNextShiftResponse} returns this
 */
proto.vtv1.GetPreviousAndNextShiftResponse.prototype.clearNextShiftOverlaps = function() {
  return this.setNextShiftOverlaps(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.GetPreviousAndNextShiftResponse.prototype.hasNextShiftOverlaps = function() {
  return jspb.Message.getField(this, 8) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.CalendarDataFromStream.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.CalendarDataFromStream.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.CalendarDataFromStream} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.CalendarDataFromStream.toObject = function(includeInstance, msg) {
  var f, obj = {
    date: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.CalendarDataFromStream}
 */
proto.vtv1.CalendarDataFromStream.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.CalendarDataFromStream;
  return proto.vtv1.CalendarDataFromStream.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.CalendarDataFromStream} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.CalendarDataFromStream}
 */
proto.vtv1.CalendarDataFromStream.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.CalendarDataFromStream.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.CalendarDataFromStream.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.CalendarDataFromStream} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.CalendarDataFromStream.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDate();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string date = 1;
 * @return {string}
 */
proto.vtv1.CalendarDataFromStream.prototype.getDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.CalendarDataFromStream} returns this
 */
proto.vtv1.CalendarDataFromStream.prototype.setDate = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.ListOfTrailersResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.ListOfTrailersResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.ListOfTrailersResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.ListOfTrailersResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.ListOfTrailersResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    trailerTypesList: jspb.Message.toObjectList(msg.getTrailerTypesList(),
    proto_vorto_types_types_pb.TrailerType.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.ListOfTrailersResponse}
 */
proto.vtv1.ListOfTrailersResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.ListOfTrailersResponse;
  return proto.vtv1.ListOfTrailersResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.ListOfTrailersResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.ListOfTrailersResponse}
 */
proto.vtv1.ListOfTrailersResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_vorto_types_types_pb.TrailerType;
      reader.readMessage(value,proto_vorto_types_types_pb.TrailerType.deserializeBinaryFromReader);
      msg.addTrailerTypes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.ListOfTrailersResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.ListOfTrailersResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.ListOfTrailersResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.ListOfTrailersResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTrailerTypesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto_vorto_types_types_pb.TrailerType.serializeBinaryToWriter
    );
  }
};


/**
 * repeated TrailerType trailer_types = 1;
 * @return {!Array<!proto.vtv1.TrailerType>}
 */
proto.vtv1.ListOfTrailersResponse.prototype.getTrailerTypesList = function() {
  return /** @type{!Array<!proto.vtv1.TrailerType>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_vorto_types_types_pb.TrailerType, 1));
};


/**
 * @param {!Array<!proto.vtv1.TrailerType>} value
 * @return {!proto.vtv1.ListOfTrailersResponse} returns this
*/
proto.vtv1.ListOfTrailersResponse.prototype.setTrailerTypesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.vtv1.TrailerType=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.TrailerType}
 */
proto.vtv1.ListOfTrailersResponse.prototype.addTrailerTypes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.vtv1.TrailerType, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.ListOfTrailersResponse} returns this
 */
proto.vtv1.ListOfTrailersResponse.prototype.clearTrailerTypesList = function() {
  return this.setTrailerTypesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.EdgeBulkUploadScheduleShift.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.EdgeBulkUploadScheduleShift.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.EdgeBulkUploadScheduleShift} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.EdgeBulkUploadScheduleShift.toObject = function(includeInstance, msg) {
  var f, obj = {
    driverName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    driverPhone: jspb.Message.getFieldWithDefault(msg, 2, ""),
    shiftStartsAt: (f = msg.getShiftStartsAt()) && proto_vorto_types_types_pb.NullableTime.toObject(includeInstance, f),
    shiftDuration: jspb.Message.getFieldWithDefault(msg, 4, 0),
    trailerType: (f = msg.getTrailerType()) && proto_vorto_types_types_pb.NullableString.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.EdgeBulkUploadScheduleShift}
 */
proto.vtv1.EdgeBulkUploadScheduleShift.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.EdgeBulkUploadScheduleShift;
  return proto.vtv1.EdgeBulkUploadScheduleShift.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.EdgeBulkUploadScheduleShift} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.EdgeBulkUploadScheduleShift}
 */
proto.vtv1.EdgeBulkUploadScheduleShift.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDriverName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDriverPhone(value);
      break;
    case 3:
      var value = new proto_vorto_types_types_pb.NullableTime;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableTime.deserializeBinaryFromReader);
      msg.setShiftStartsAt(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setShiftDuration(value);
      break;
    case 5:
      var value = new proto_vorto_types_types_pb.NullableString;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableString.deserializeBinaryFromReader);
      msg.setTrailerType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.EdgeBulkUploadScheduleShift.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.EdgeBulkUploadScheduleShift.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.EdgeBulkUploadScheduleShift} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.EdgeBulkUploadScheduleShift.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDriverName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDriverPhone();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getShiftStartsAt();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto_vorto_types_types_pb.NullableTime.serializeBinaryToWriter
    );
  }
  f = message.getShiftDuration();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getTrailerType();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto_vorto_types_types_pb.NullableString.serializeBinaryToWriter
    );
  }
};


/**
 * optional string driver_name = 1;
 * @return {string}
 */
proto.vtv1.EdgeBulkUploadScheduleShift.prototype.getDriverName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.EdgeBulkUploadScheduleShift} returns this
 */
proto.vtv1.EdgeBulkUploadScheduleShift.prototype.setDriverName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string driver_phone = 2;
 * @return {string}
 */
proto.vtv1.EdgeBulkUploadScheduleShift.prototype.getDriverPhone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.EdgeBulkUploadScheduleShift} returns this
 */
proto.vtv1.EdgeBulkUploadScheduleShift.prototype.setDriverPhone = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional NullableTime shift_starts_at = 3;
 * @return {?proto.vtv1.NullableTime}
 */
proto.vtv1.EdgeBulkUploadScheduleShift.prototype.getShiftStartsAt = function() {
  return /** @type{?proto.vtv1.NullableTime} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableTime, 3));
};


/**
 * @param {?proto.vtv1.NullableTime|undefined} value
 * @return {!proto.vtv1.EdgeBulkUploadScheduleShift} returns this
*/
proto.vtv1.EdgeBulkUploadScheduleShift.prototype.setShiftStartsAt = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.EdgeBulkUploadScheduleShift} returns this
 */
proto.vtv1.EdgeBulkUploadScheduleShift.prototype.clearShiftStartsAt = function() {
  return this.setShiftStartsAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.EdgeBulkUploadScheduleShift.prototype.hasShiftStartsAt = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional int64 shift_duration = 4;
 * @return {number}
 */
proto.vtv1.EdgeBulkUploadScheduleShift.prototype.getShiftDuration = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.EdgeBulkUploadScheduleShift} returns this
 */
proto.vtv1.EdgeBulkUploadScheduleShift.prototype.setShiftDuration = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional NullableString trailer_type = 5;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.EdgeBulkUploadScheduleShift.prototype.getTrailerType = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableString, 5));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.EdgeBulkUploadScheduleShift} returns this
*/
proto.vtv1.EdgeBulkUploadScheduleShift.prototype.setTrailerType = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.EdgeBulkUploadScheduleShift} returns this
 */
proto.vtv1.EdgeBulkUploadScheduleShift.prototype.clearTrailerType = function() {
  return this.setTrailerType(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.EdgeBulkUploadScheduleShift.prototype.hasTrailerType = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.EdgeBulkUploadShiftScheduleVerification.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.EdgeBulkUploadShiftScheduleVerification.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.EdgeBulkUploadShiftScheduleVerification} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.EdgeBulkUploadShiftScheduleVerification.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    message: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.EdgeBulkUploadShiftScheduleVerification}
 */
proto.vtv1.EdgeBulkUploadShiftScheduleVerification.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.EdgeBulkUploadShiftScheduleVerification;
  return proto.vtv1.EdgeBulkUploadShiftScheduleVerification.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.EdgeBulkUploadShiftScheduleVerification} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.EdgeBulkUploadShiftScheduleVerification}
 */
proto.vtv1.EdgeBulkUploadShiftScheduleVerification.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.EdgeBulkUploadShiftScheduleVerification.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.EdgeBulkUploadShiftScheduleVerification.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.EdgeBulkUploadShiftScheduleVerification} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.EdgeBulkUploadShiftScheduleVerification.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.vtv1.EdgeBulkUploadShiftScheduleVerification.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vtv1.EdgeBulkUploadShiftScheduleVerification} returns this
 */
proto.vtv1.EdgeBulkUploadShiftScheduleVerification.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string message = 2;
 * @return {string}
 */
proto.vtv1.EdgeBulkUploadShiftScheduleVerification.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.EdgeBulkUploadShiftScheduleVerification} returns this
 */
proto.vtv1.EdgeBulkUploadShiftScheduleVerification.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.EdgeBulkUploadShiftScheduleRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.EdgeBulkUploadShiftScheduleRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.EdgeBulkUploadShiftScheduleRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.EdgeBulkUploadShiftScheduleRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.EdgeBulkUploadShiftScheduleRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    shiftsList: jspb.Message.toObjectList(msg.getShiftsList(),
    proto.vtv1.EdgeBulkUploadScheduleShift.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.EdgeBulkUploadShiftScheduleRequest}
 */
proto.vtv1.EdgeBulkUploadShiftScheduleRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.EdgeBulkUploadShiftScheduleRequest;
  return proto.vtv1.EdgeBulkUploadShiftScheduleRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.EdgeBulkUploadShiftScheduleRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.EdgeBulkUploadShiftScheduleRequest}
 */
proto.vtv1.EdgeBulkUploadShiftScheduleRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.vtv1.EdgeBulkUploadScheduleShift;
      reader.readMessage(value,proto.vtv1.EdgeBulkUploadScheduleShift.deserializeBinaryFromReader);
      msg.addShifts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.EdgeBulkUploadShiftScheduleRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.EdgeBulkUploadShiftScheduleRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.EdgeBulkUploadShiftScheduleRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.EdgeBulkUploadShiftScheduleRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getShiftsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.vtv1.EdgeBulkUploadScheduleShift.serializeBinaryToWriter
    );
  }
};


/**
 * repeated EdgeBulkUploadScheduleShift shifts = 1;
 * @return {!Array<!proto.vtv1.EdgeBulkUploadScheduleShift>}
 */
proto.vtv1.EdgeBulkUploadShiftScheduleRequest.prototype.getShiftsList = function() {
  return /** @type{!Array<!proto.vtv1.EdgeBulkUploadScheduleShift>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vtv1.EdgeBulkUploadScheduleShift, 1));
};


/**
 * @param {!Array<!proto.vtv1.EdgeBulkUploadScheduleShift>} value
 * @return {!proto.vtv1.EdgeBulkUploadShiftScheduleRequest} returns this
*/
proto.vtv1.EdgeBulkUploadShiftScheduleRequest.prototype.setShiftsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.vtv1.EdgeBulkUploadScheduleShift=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.EdgeBulkUploadScheduleShift}
 */
proto.vtv1.EdgeBulkUploadShiftScheduleRequest.prototype.addShifts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.vtv1.EdgeBulkUploadScheduleShift, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.EdgeBulkUploadShiftScheduleRequest} returns this
 */
proto.vtv1.EdgeBulkUploadShiftScheduleRequest.prototype.clearShiftsList = function() {
  return this.setShiftsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.EdgeBulkUploadShiftScheduleResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.EdgeBulkUploadShiftScheduleResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.EdgeBulkUploadShiftScheduleResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.EdgeBulkUploadShiftScheduleResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.EdgeBulkUploadShiftScheduleResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    verificationsList: jspb.Message.toObjectList(msg.getVerificationsList(),
    proto.vtv1.EdgeBulkUploadShiftScheduleVerification.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.EdgeBulkUploadShiftScheduleResponse}
 */
proto.vtv1.EdgeBulkUploadShiftScheduleResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.EdgeBulkUploadShiftScheduleResponse;
  return proto.vtv1.EdgeBulkUploadShiftScheduleResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.EdgeBulkUploadShiftScheduleResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.EdgeBulkUploadShiftScheduleResponse}
 */
proto.vtv1.EdgeBulkUploadShiftScheduleResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.vtv1.EdgeBulkUploadShiftScheduleVerification;
      reader.readMessage(value,proto.vtv1.EdgeBulkUploadShiftScheduleVerification.deserializeBinaryFromReader);
      msg.addVerifications(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.EdgeBulkUploadShiftScheduleResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.EdgeBulkUploadShiftScheduleResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.EdgeBulkUploadShiftScheduleResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.EdgeBulkUploadShiftScheduleResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVerificationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.vtv1.EdgeBulkUploadShiftScheduleVerification.serializeBinaryToWriter
    );
  }
};


/**
 * repeated EdgeBulkUploadShiftScheduleVerification verifications = 1;
 * @return {!Array<!proto.vtv1.EdgeBulkUploadShiftScheduleVerification>}
 */
proto.vtv1.EdgeBulkUploadShiftScheduleResponse.prototype.getVerificationsList = function() {
  return /** @type{!Array<!proto.vtv1.EdgeBulkUploadShiftScheduleVerification>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vtv1.EdgeBulkUploadShiftScheduleVerification, 1));
};


/**
 * @param {!Array<!proto.vtv1.EdgeBulkUploadShiftScheduleVerification>} value
 * @return {!proto.vtv1.EdgeBulkUploadShiftScheduleResponse} returns this
*/
proto.vtv1.EdgeBulkUploadShiftScheduleResponse.prototype.setVerificationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.vtv1.EdgeBulkUploadShiftScheduleVerification=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.EdgeBulkUploadShiftScheduleVerification}
 */
proto.vtv1.EdgeBulkUploadShiftScheduleResponse.prototype.addVerifications = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.vtv1.EdgeBulkUploadShiftScheduleVerification, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.EdgeBulkUploadShiftScheduleResponse} returns this
 */
proto.vtv1.EdgeBulkUploadShiftScheduleResponse.prototype.clearVerificationsList = function() {
  return this.setVerificationsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.CreateEdgeContractRequestAsEdge.repeatedFields_ = [6,9,10];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.CreateEdgeContractRequestAsEdge.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.CreateEdgeContractRequestAsEdge.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.CreateEdgeContractRequestAsEdge} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.CreateEdgeContractRequestAsEdge.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    nodeId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    startsAt: jspb.Message.getFieldWithDefault(msg, 3, ""),
    type: jspb.Message.getFieldWithDefault(msg, 4, 0),
    expiresAt: (f = msg.getExpiresAt()) && proto_vorto_types_types_pb.NullableTime.toObject(includeInstance, f),
    costPerMileageBracketList: jspb.Message.toObjectList(msg.getCostPerMileageBracketList(),
    proto_vorto_types_types_pb.MileageBracketCost.toObject, includeInstance),
    costPerMile: jspb.Message.getFieldWithDefault(msg, 7, 0),
    edgeCosts: (f = msg.getEdgeCosts()) && proto_vorto_types_types_pb.EdgeCosts.toObject(includeInstance, f),
    productIdsList: (f = jspb.Message.getRepeatedField(msg, 9)) == null ? undefined : f,
    trailerTypesList: jspb.Message.toObjectList(msg.getTrailerTypesList(),
    proto_vorto_types_types_pb.IntIdName.toObject, includeInstance),
    costPerHour: (f = msg.getCostPerHour()) && proto_vorto_types_types_pb.NullableInt64.toObject(includeInstance, f),
    minimumPerHourTotalCost: (f = msg.getMinimumPerHourTotalCost()) && proto_vorto_types_types_pb.NullableInt64.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.CreateEdgeContractRequestAsEdge}
 */
proto.vtv1.CreateEdgeContractRequestAsEdge.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.CreateEdgeContractRequestAsEdge;
  return proto.vtv1.CreateEdgeContractRequestAsEdge.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.CreateEdgeContractRequestAsEdge} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.CreateEdgeContractRequestAsEdge}
 */
proto.vtv1.CreateEdgeContractRequestAsEdge.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setNodeId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setStartsAt(value);
      break;
    case 4:
      var value = /** @type {!proto.vtv1.LinehaulType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 5:
      var value = new proto_vorto_types_types_pb.NullableTime;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableTime.deserializeBinaryFromReader);
      msg.setExpiresAt(value);
      break;
    case 6:
      var value = new proto_vorto_types_types_pb.MileageBracketCost;
      reader.readMessage(value,proto_vorto_types_types_pb.MileageBracketCost.deserializeBinaryFromReader);
      msg.addCostPerMileageBracket(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCostPerMile(value);
      break;
    case 8:
      var value = new proto_vorto_types_types_pb.EdgeCosts;
      reader.readMessage(value,proto_vorto_types_types_pb.EdgeCosts.deserializeBinaryFromReader);
      msg.setEdgeCosts(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.addProductIds(value);
      break;
    case 10:
      var value = new proto_vorto_types_types_pb.IntIdName;
      reader.readMessage(value,proto_vorto_types_types_pb.IntIdName.deserializeBinaryFromReader);
      msg.addTrailerTypes(value);
      break;
    case 11:
      var value = new proto_vorto_types_types_pb.NullableInt64;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableInt64.deserializeBinaryFromReader);
      msg.setCostPerHour(value);
      break;
    case 12:
      var value = new proto_vorto_types_types_pb.NullableInt64;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableInt64.deserializeBinaryFromReader);
      msg.setMinimumPerHourTotalCost(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.CreateEdgeContractRequestAsEdge.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.CreateEdgeContractRequestAsEdge.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.CreateEdgeContractRequestAsEdge} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.CreateEdgeContractRequestAsEdge.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getNodeId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getStartsAt();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getExpiresAt();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto_vorto_types_types_pb.NullableTime.serializeBinaryToWriter
    );
  }
  f = message.getCostPerMileageBracketList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto_vorto_types_types_pb.MileageBracketCost.serializeBinaryToWriter
    );
  }
  f = message.getCostPerMile();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
  f = message.getEdgeCosts();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto_vorto_types_types_pb.EdgeCosts.serializeBinaryToWriter
    );
  }
  f = message.getProductIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      9,
      f
    );
  }
  f = message.getTrailerTypesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      10,
      f,
      proto_vorto_types_types_pb.IntIdName.serializeBinaryToWriter
    );
  }
  f = message.getCostPerHour();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto_vorto_types_types_pb.NullableInt64.serializeBinaryToWriter
    );
  }
  f = message.getMinimumPerHourTotalCost();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      proto_vorto_types_types_pb.NullableInt64.serializeBinaryToWriter
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.vtv1.CreateEdgeContractRequestAsEdge.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.CreateEdgeContractRequestAsEdge} returns this
 */
proto.vtv1.CreateEdgeContractRequestAsEdge.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string node_id = 2;
 * @return {string}
 */
proto.vtv1.CreateEdgeContractRequestAsEdge.prototype.getNodeId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.CreateEdgeContractRequestAsEdge} returns this
 */
proto.vtv1.CreateEdgeContractRequestAsEdge.prototype.setNodeId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string starts_at = 3;
 * @return {string}
 */
proto.vtv1.CreateEdgeContractRequestAsEdge.prototype.getStartsAt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.CreateEdgeContractRequestAsEdge} returns this
 */
proto.vtv1.CreateEdgeContractRequestAsEdge.prototype.setStartsAt = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional LinehaulType type = 4;
 * @return {!proto.vtv1.LinehaulType}
 */
proto.vtv1.CreateEdgeContractRequestAsEdge.prototype.getType = function() {
  return /** @type {!proto.vtv1.LinehaulType} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.vtv1.LinehaulType} value
 * @return {!proto.vtv1.CreateEdgeContractRequestAsEdge} returns this
 */
proto.vtv1.CreateEdgeContractRequestAsEdge.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional NullableTime expires_at = 5;
 * @return {?proto.vtv1.NullableTime}
 */
proto.vtv1.CreateEdgeContractRequestAsEdge.prototype.getExpiresAt = function() {
  return /** @type{?proto.vtv1.NullableTime} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableTime, 5));
};


/**
 * @param {?proto.vtv1.NullableTime|undefined} value
 * @return {!proto.vtv1.CreateEdgeContractRequestAsEdge} returns this
*/
proto.vtv1.CreateEdgeContractRequestAsEdge.prototype.setExpiresAt = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.CreateEdgeContractRequestAsEdge} returns this
 */
proto.vtv1.CreateEdgeContractRequestAsEdge.prototype.clearExpiresAt = function() {
  return this.setExpiresAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.CreateEdgeContractRequestAsEdge.prototype.hasExpiresAt = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * repeated MileageBracketCost cost_per_mileage_bracket = 6;
 * @return {!Array<!proto.vtv1.MileageBracketCost>}
 */
proto.vtv1.CreateEdgeContractRequestAsEdge.prototype.getCostPerMileageBracketList = function() {
  return /** @type{!Array<!proto.vtv1.MileageBracketCost>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_vorto_types_types_pb.MileageBracketCost, 6));
};


/**
 * @param {!Array<!proto.vtv1.MileageBracketCost>} value
 * @return {!proto.vtv1.CreateEdgeContractRequestAsEdge} returns this
*/
proto.vtv1.CreateEdgeContractRequestAsEdge.prototype.setCostPerMileageBracketList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.vtv1.MileageBracketCost=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.MileageBracketCost}
 */
proto.vtv1.CreateEdgeContractRequestAsEdge.prototype.addCostPerMileageBracket = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.vtv1.MileageBracketCost, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.CreateEdgeContractRequestAsEdge} returns this
 */
proto.vtv1.CreateEdgeContractRequestAsEdge.prototype.clearCostPerMileageBracketList = function() {
  return this.setCostPerMileageBracketList([]);
};


/**
 * optional int64 cost_per_mile = 7;
 * @return {number}
 */
proto.vtv1.CreateEdgeContractRequestAsEdge.prototype.getCostPerMile = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.CreateEdgeContractRequestAsEdge} returns this
 */
proto.vtv1.CreateEdgeContractRequestAsEdge.prototype.setCostPerMile = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional EdgeCosts edge_costs = 8;
 * @return {?proto.vtv1.EdgeCosts}
 */
proto.vtv1.CreateEdgeContractRequestAsEdge.prototype.getEdgeCosts = function() {
  return /** @type{?proto.vtv1.EdgeCosts} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.EdgeCosts, 8));
};


/**
 * @param {?proto.vtv1.EdgeCosts|undefined} value
 * @return {!proto.vtv1.CreateEdgeContractRequestAsEdge} returns this
*/
proto.vtv1.CreateEdgeContractRequestAsEdge.prototype.setEdgeCosts = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.CreateEdgeContractRequestAsEdge} returns this
 */
proto.vtv1.CreateEdgeContractRequestAsEdge.prototype.clearEdgeCosts = function() {
  return this.setEdgeCosts(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.CreateEdgeContractRequestAsEdge.prototype.hasEdgeCosts = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * repeated string product_ids = 9;
 * @return {!Array<string>}
 */
proto.vtv1.CreateEdgeContractRequestAsEdge.prototype.getProductIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 9));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.vtv1.CreateEdgeContractRequestAsEdge} returns this
 */
proto.vtv1.CreateEdgeContractRequestAsEdge.prototype.setProductIdsList = function(value) {
  return jspb.Message.setField(this, 9, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.vtv1.CreateEdgeContractRequestAsEdge} returns this
 */
proto.vtv1.CreateEdgeContractRequestAsEdge.prototype.addProductIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 9, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.CreateEdgeContractRequestAsEdge} returns this
 */
proto.vtv1.CreateEdgeContractRequestAsEdge.prototype.clearProductIdsList = function() {
  return this.setProductIdsList([]);
};


/**
 * repeated IntIdName trailer_types = 10;
 * @return {!Array<!proto.vtv1.IntIdName>}
 */
proto.vtv1.CreateEdgeContractRequestAsEdge.prototype.getTrailerTypesList = function() {
  return /** @type{!Array<!proto.vtv1.IntIdName>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_vorto_types_types_pb.IntIdName, 10));
};


/**
 * @param {!Array<!proto.vtv1.IntIdName>} value
 * @return {!proto.vtv1.CreateEdgeContractRequestAsEdge} returns this
*/
proto.vtv1.CreateEdgeContractRequestAsEdge.prototype.setTrailerTypesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 10, value);
};


/**
 * @param {!proto.vtv1.IntIdName=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.IntIdName}
 */
proto.vtv1.CreateEdgeContractRequestAsEdge.prototype.addTrailerTypes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 10, opt_value, proto.vtv1.IntIdName, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.CreateEdgeContractRequestAsEdge} returns this
 */
proto.vtv1.CreateEdgeContractRequestAsEdge.prototype.clearTrailerTypesList = function() {
  return this.setTrailerTypesList([]);
};


/**
 * optional NullableInt64 cost_per_hour = 11;
 * @return {?proto.vtv1.NullableInt64}
 */
proto.vtv1.CreateEdgeContractRequestAsEdge.prototype.getCostPerHour = function() {
  return /** @type{?proto.vtv1.NullableInt64} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableInt64, 11));
};


/**
 * @param {?proto.vtv1.NullableInt64|undefined} value
 * @return {!proto.vtv1.CreateEdgeContractRequestAsEdge} returns this
*/
proto.vtv1.CreateEdgeContractRequestAsEdge.prototype.setCostPerHour = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.CreateEdgeContractRequestAsEdge} returns this
 */
proto.vtv1.CreateEdgeContractRequestAsEdge.prototype.clearCostPerHour = function() {
  return this.setCostPerHour(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.CreateEdgeContractRequestAsEdge.prototype.hasCostPerHour = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional NullableInt64 minimum_per_hour_total_cost = 12;
 * @return {?proto.vtv1.NullableInt64}
 */
proto.vtv1.CreateEdgeContractRequestAsEdge.prototype.getMinimumPerHourTotalCost = function() {
  return /** @type{?proto.vtv1.NullableInt64} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableInt64, 12));
};


/**
 * @param {?proto.vtv1.NullableInt64|undefined} value
 * @return {!proto.vtv1.CreateEdgeContractRequestAsEdge} returns this
*/
proto.vtv1.CreateEdgeContractRequestAsEdge.prototype.setMinimumPerHourTotalCost = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.CreateEdgeContractRequestAsEdge} returns this
 */
proto.vtv1.CreateEdgeContractRequestAsEdge.prototype.clearMinimumPerHourTotalCost = function() {
  return this.setMinimumPerHourTotalCost(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.CreateEdgeContractRequestAsEdge.prototype.hasMinimumPerHourTotalCost = function() {
  return jspb.Message.getField(this, 12) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.EditEdgeContractRequestAsEdge.repeatedFields_ = [6,9,10];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.EditEdgeContractRequestAsEdge.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.EditEdgeContractRequestAsEdge.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.EditEdgeContractRequestAsEdge} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.EditEdgeContractRequestAsEdge.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: (f = msg.getName()) && proto_vorto_types_types_pb.NullableString.toObject(includeInstance, f),
    startsAt: (f = msg.getStartsAt()) && proto_vorto_types_types_pb.NullableTime.toObject(includeInstance, f),
    type: jspb.Message.getFieldWithDefault(msg, 4, 0),
    expiresAt: (f = msg.getExpiresAt()) && proto_vorto_types_types_pb.NullableTime.toObject(includeInstance, f),
    costPerMileageBracketList: jspb.Message.toObjectList(msg.getCostPerMileageBracketList(),
    proto_vorto_types_types_pb.MileageBracketCost.toObject, includeInstance),
    costPerMile: (f = msg.getCostPerMile()) && proto_vorto_types_types_pb.NullableInt64.toObject(includeInstance, f),
    edgeCosts: (f = msg.getEdgeCosts()) && proto_vorto_types_types_pb.EdgeCosts.toObject(includeInstance, f),
    productIdsList: (f = jspb.Message.getRepeatedField(msg, 9)) == null ? undefined : f,
    trailerTypesList: jspb.Message.toObjectList(msg.getTrailerTypesList(),
    proto_vorto_types_types_pb.IntIdName.toObject, includeInstance),
    costPerHour: (f = msg.getCostPerHour()) && proto_vorto_types_types_pb.NullableInt64.toObject(includeInstance, f),
    minimumPerHourTotalCost: (f = msg.getMinimumPerHourTotalCost()) && proto_vorto_types_types_pb.NullableInt64.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.EditEdgeContractRequestAsEdge}
 */
proto.vtv1.EditEdgeContractRequestAsEdge.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.EditEdgeContractRequestAsEdge;
  return proto.vtv1.EditEdgeContractRequestAsEdge.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.EditEdgeContractRequestAsEdge} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.EditEdgeContractRequestAsEdge}
 */
proto.vtv1.EditEdgeContractRequestAsEdge.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new proto_vorto_types_types_pb.NullableString;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableString.deserializeBinaryFromReader);
      msg.setName(value);
      break;
    case 3:
      var value = new proto_vorto_types_types_pb.NullableTime;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableTime.deserializeBinaryFromReader);
      msg.setStartsAt(value);
      break;
    case 4:
      var value = /** @type {!proto.vtv1.LinehaulType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 5:
      var value = new proto_vorto_types_types_pb.NullableTime;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableTime.deserializeBinaryFromReader);
      msg.setExpiresAt(value);
      break;
    case 6:
      var value = new proto_vorto_types_types_pb.MileageBracketCost;
      reader.readMessage(value,proto_vorto_types_types_pb.MileageBracketCost.deserializeBinaryFromReader);
      msg.addCostPerMileageBracket(value);
      break;
    case 7:
      var value = new proto_vorto_types_types_pb.NullableInt64;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableInt64.deserializeBinaryFromReader);
      msg.setCostPerMile(value);
      break;
    case 8:
      var value = new proto_vorto_types_types_pb.EdgeCosts;
      reader.readMessage(value,proto_vorto_types_types_pb.EdgeCosts.deserializeBinaryFromReader);
      msg.setEdgeCosts(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.addProductIds(value);
      break;
    case 10:
      var value = new proto_vorto_types_types_pb.IntIdName;
      reader.readMessage(value,proto_vorto_types_types_pb.IntIdName.deserializeBinaryFromReader);
      msg.addTrailerTypes(value);
      break;
    case 11:
      var value = new proto_vorto_types_types_pb.NullableInt64;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableInt64.deserializeBinaryFromReader);
      msg.setCostPerHour(value);
      break;
    case 12:
      var value = new proto_vorto_types_types_pb.NullableInt64;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableInt64.deserializeBinaryFromReader);
      msg.setMinimumPerHourTotalCost(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.EditEdgeContractRequestAsEdge.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.EditEdgeContractRequestAsEdge.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.EditEdgeContractRequestAsEdge} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.EditEdgeContractRequestAsEdge.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_vorto_types_types_pb.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getStartsAt();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto_vorto_types_types_pb.NullableTime.serializeBinaryToWriter
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getExpiresAt();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto_vorto_types_types_pb.NullableTime.serializeBinaryToWriter
    );
  }
  f = message.getCostPerMileageBracketList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto_vorto_types_types_pb.MileageBracketCost.serializeBinaryToWriter
    );
  }
  f = message.getCostPerMile();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto_vorto_types_types_pb.NullableInt64.serializeBinaryToWriter
    );
  }
  f = message.getEdgeCosts();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto_vorto_types_types_pb.EdgeCosts.serializeBinaryToWriter
    );
  }
  f = message.getProductIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      9,
      f
    );
  }
  f = message.getTrailerTypesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      10,
      f,
      proto_vorto_types_types_pb.IntIdName.serializeBinaryToWriter
    );
  }
  f = message.getCostPerHour();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto_vorto_types_types_pb.NullableInt64.serializeBinaryToWriter
    );
  }
  f = message.getMinimumPerHourTotalCost();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      proto_vorto_types_types_pb.NullableInt64.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.vtv1.EditEdgeContractRequestAsEdge.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.EditEdgeContractRequestAsEdge} returns this
 */
proto.vtv1.EditEdgeContractRequestAsEdge.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional NullableString name = 2;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.EditEdgeContractRequestAsEdge.prototype.getName = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableString, 2));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.EditEdgeContractRequestAsEdge} returns this
*/
proto.vtv1.EditEdgeContractRequestAsEdge.prototype.setName = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.EditEdgeContractRequestAsEdge} returns this
 */
proto.vtv1.EditEdgeContractRequestAsEdge.prototype.clearName = function() {
  return this.setName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.EditEdgeContractRequestAsEdge.prototype.hasName = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional NullableTime starts_at = 3;
 * @return {?proto.vtv1.NullableTime}
 */
proto.vtv1.EditEdgeContractRequestAsEdge.prototype.getStartsAt = function() {
  return /** @type{?proto.vtv1.NullableTime} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableTime, 3));
};


/**
 * @param {?proto.vtv1.NullableTime|undefined} value
 * @return {!proto.vtv1.EditEdgeContractRequestAsEdge} returns this
*/
proto.vtv1.EditEdgeContractRequestAsEdge.prototype.setStartsAt = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.EditEdgeContractRequestAsEdge} returns this
 */
proto.vtv1.EditEdgeContractRequestAsEdge.prototype.clearStartsAt = function() {
  return this.setStartsAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.EditEdgeContractRequestAsEdge.prototype.hasStartsAt = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional LinehaulType type = 4;
 * @return {!proto.vtv1.LinehaulType}
 */
proto.vtv1.EditEdgeContractRequestAsEdge.prototype.getType = function() {
  return /** @type {!proto.vtv1.LinehaulType} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.vtv1.LinehaulType} value
 * @return {!proto.vtv1.EditEdgeContractRequestAsEdge} returns this
 */
proto.vtv1.EditEdgeContractRequestAsEdge.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional NullableTime expires_at = 5;
 * @return {?proto.vtv1.NullableTime}
 */
proto.vtv1.EditEdgeContractRequestAsEdge.prototype.getExpiresAt = function() {
  return /** @type{?proto.vtv1.NullableTime} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableTime, 5));
};


/**
 * @param {?proto.vtv1.NullableTime|undefined} value
 * @return {!proto.vtv1.EditEdgeContractRequestAsEdge} returns this
*/
proto.vtv1.EditEdgeContractRequestAsEdge.prototype.setExpiresAt = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.EditEdgeContractRequestAsEdge} returns this
 */
proto.vtv1.EditEdgeContractRequestAsEdge.prototype.clearExpiresAt = function() {
  return this.setExpiresAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.EditEdgeContractRequestAsEdge.prototype.hasExpiresAt = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * repeated MileageBracketCost cost_per_mileage_bracket = 6;
 * @return {!Array<!proto.vtv1.MileageBracketCost>}
 */
proto.vtv1.EditEdgeContractRequestAsEdge.prototype.getCostPerMileageBracketList = function() {
  return /** @type{!Array<!proto.vtv1.MileageBracketCost>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_vorto_types_types_pb.MileageBracketCost, 6));
};


/**
 * @param {!Array<!proto.vtv1.MileageBracketCost>} value
 * @return {!proto.vtv1.EditEdgeContractRequestAsEdge} returns this
*/
proto.vtv1.EditEdgeContractRequestAsEdge.prototype.setCostPerMileageBracketList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.vtv1.MileageBracketCost=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.MileageBracketCost}
 */
proto.vtv1.EditEdgeContractRequestAsEdge.prototype.addCostPerMileageBracket = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.vtv1.MileageBracketCost, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.EditEdgeContractRequestAsEdge} returns this
 */
proto.vtv1.EditEdgeContractRequestAsEdge.prototype.clearCostPerMileageBracketList = function() {
  return this.setCostPerMileageBracketList([]);
};


/**
 * optional NullableInt64 cost_per_mile = 7;
 * @return {?proto.vtv1.NullableInt64}
 */
proto.vtv1.EditEdgeContractRequestAsEdge.prototype.getCostPerMile = function() {
  return /** @type{?proto.vtv1.NullableInt64} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableInt64, 7));
};


/**
 * @param {?proto.vtv1.NullableInt64|undefined} value
 * @return {!proto.vtv1.EditEdgeContractRequestAsEdge} returns this
*/
proto.vtv1.EditEdgeContractRequestAsEdge.prototype.setCostPerMile = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.EditEdgeContractRequestAsEdge} returns this
 */
proto.vtv1.EditEdgeContractRequestAsEdge.prototype.clearCostPerMile = function() {
  return this.setCostPerMile(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.EditEdgeContractRequestAsEdge.prototype.hasCostPerMile = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional EdgeCosts edge_costs = 8;
 * @return {?proto.vtv1.EdgeCosts}
 */
proto.vtv1.EditEdgeContractRequestAsEdge.prototype.getEdgeCosts = function() {
  return /** @type{?proto.vtv1.EdgeCosts} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.EdgeCosts, 8));
};


/**
 * @param {?proto.vtv1.EdgeCosts|undefined} value
 * @return {!proto.vtv1.EditEdgeContractRequestAsEdge} returns this
*/
proto.vtv1.EditEdgeContractRequestAsEdge.prototype.setEdgeCosts = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.EditEdgeContractRequestAsEdge} returns this
 */
proto.vtv1.EditEdgeContractRequestAsEdge.prototype.clearEdgeCosts = function() {
  return this.setEdgeCosts(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.EditEdgeContractRequestAsEdge.prototype.hasEdgeCosts = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * repeated string product_ids = 9;
 * @return {!Array<string>}
 */
proto.vtv1.EditEdgeContractRequestAsEdge.prototype.getProductIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 9));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.vtv1.EditEdgeContractRequestAsEdge} returns this
 */
proto.vtv1.EditEdgeContractRequestAsEdge.prototype.setProductIdsList = function(value) {
  return jspb.Message.setField(this, 9, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.vtv1.EditEdgeContractRequestAsEdge} returns this
 */
proto.vtv1.EditEdgeContractRequestAsEdge.prototype.addProductIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 9, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.EditEdgeContractRequestAsEdge} returns this
 */
proto.vtv1.EditEdgeContractRequestAsEdge.prototype.clearProductIdsList = function() {
  return this.setProductIdsList([]);
};


/**
 * repeated IntIdName trailer_types = 10;
 * @return {!Array<!proto.vtv1.IntIdName>}
 */
proto.vtv1.EditEdgeContractRequestAsEdge.prototype.getTrailerTypesList = function() {
  return /** @type{!Array<!proto.vtv1.IntIdName>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_vorto_types_types_pb.IntIdName, 10));
};


/**
 * @param {!Array<!proto.vtv1.IntIdName>} value
 * @return {!proto.vtv1.EditEdgeContractRequestAsEdge} returns this
*/
proto.vtv1.EditEdgeContractRequestAsEdge.prototype.setTrailerTypesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 10, value);
};


/**
 * @param {!proto.vtv1.IntIdName=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.IntIdName}
 */
proto.vtv1.EditEdgeContractRequestAsEdge.prototype.addTrailerTypes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 10, opt_value, proto.vtv1.IntIdName, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.EditEdgeContractRequestAsEdge} returns this
 */
proto.vtv1.EditEdgeContractRequestAsEdge.prototype.clearTrailerTypesList = function() {
  return this.setTrailerTypesList([]);
};


/**
 * optional NullableInt64 cost_per_hour = 11;
 * @return {?proto.vtv1.NullableInt64}
 */
proto.vtv1.EditEdgeContractRequestAsEdge.prototype.getCostPerHour = function() {
  return /** @type{?proto.vtv1.NullableInt64} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableInt64, 11));
};


/**
 * @param {?proto.vtv1.NullableInt64|undefined} value
 * @return {!proto.vtv1.EditEdgeContractRequestAsEdge} returns this
*/
proto.vtv1.EditEdgeContractRequestAsEdge.prototype.setCostPerHour = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.EditEdgeContractRequestAsEdge} returns this
 */
proto.vtv1.EditEdgeContractRequestAsEdge.prototype.clearCostPerHour = function() {
  return this.setCostPerHour(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.EditEdgeContractRequestAsEdge.prototype.hasCostPerHour = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional NullableInt64 minimum_per_hour_total_cost = 12;
 * @return {?proto.vtv1.NullableInt64}
 */
proto.vtv1.EditEdgeContractRequestAsEdge.prototype.getMinimumPerHourTotalCost = function() {
  return /** @type{?proto.vtv1.NullableInt64} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableInt64, 12));
};


/**
 * @param {?proto.vtv1.NullableInt64|undefined} value
 * @return {!proto.vtv1.EditEdgeContractRequestAsEdge} returns this
*/
proto.vtv1.EditEdgeContractRequestAsEdge.prototype.setMinimumPerHourTotalCost = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.EditEdgeContractRequestAsEdge} returns this
 */
proto.vtv1.EditEdgeContractRequestAsEdge.prototype.clearMinimumPerHourTotalCost = function() {
  return this.setMinimumPerHourTotalCost(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.EditEdgeContractRequestAsEdge.prototype.hasMinimumPerHourTotalCost = function() {
  return jspb.Message.getField(this, 12) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.SingleEdgeContractAsEdge.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.SingleEdgeContractAsEdge.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.SingleEdgeContractAsEdge.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.SingleEdgeContractAsEdge} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.SingleEdgeContractAsEdge.toObject = function(includeInstance, msg) {
  var f, obj = {
    contract: (f = msg.getContract()) && proto_vorto_types_types_pb.EdgeContract.toObject(includeInstance, f),
    nodeName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    edgeCosts: (f = msg.getEdgeCosts()) && proto_vorto_types_types_pb.EdgeCosts.toObject(includeInstance, f),
    productsList: jspb.Message.toObjectList(msg.getProductsList(),
    proto_vorto_types_types_pb.Product.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.SingleEdgeContractAsEdge}
 */
proto.vtv1.SingleEdgeContractAsEdge.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.SingleEdgeContractAsEdge;
  return proto.vtv1.SingleEdgeContractAsEdge.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.SingleEdgeContractAsEdge} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.SingleEdgeContractAsEdge}
 */
proto.vtv1.SingleEdgeContractAsEdge.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_vorto_types_types_pb.EdgeContract;
      reader.readMessage(value,proto_vorto_types_types_pb.EdgeContract.deserializeBinaryFromReader);
      msg.setContract(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setNodeName(value);
      break;
    case 3:
      var value = new proto_vorto_types_types_pb.EdgeCosts;
      reader.readMessage(value,proto_vorto_types_types_pb.EdgeCosts.deserializeBinaryFromReader);
      msg.setEdgeCosts(value);
      break;
    case 4:
      var value = new proto_vorto_types_types_pb.Product;
      reader.readMessage(value,proto_vorto_types_types_pb.Product.deserializeBinaryFromReader);
      msg.addProducts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.SingleEdgeContractAsEdge.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.SingleEdgeContractAsEdge.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.SingleEdgeContractAsEdge} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.SingleEdgeContractAsEdge.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getContract();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_vorto_types_types_pb.EdgeContract.serializeBinaryToWriter
    );
  }
  f = message.getNodeName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getEdgeCosts();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto_vorto_types_types_pb.EdgeCosts.serializeBinaryToWriter
    );
  }
  f = message.getProductsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto_vorto_types_types_pb.Product.serializeBinaryToWriter
    );
  }
};


/**
 * optional EdgeContract contract = 1;
 * @return {?proto.vtv1.EdgeContract}
 */
proto.vtv1.SingleEdgeContractAsEdge.prototype.getContract = function() {
  return /** @type{?proto.vtv1.EdgeContract} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.EdgeContract, 1));
};


/**
 * @param {?proto.vtv1.EdgeContract|undefined} value
 * @return {!proto.vtv1.SingleEdgeContractAsEdge} returns this
*/
proto.vtv1.SingleEdgeContractAsEdge.prototype.setContract = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.SingleEdgeContractAsEdge} returns this
 */
proto.vtv1.SingleEdgeContractAsEdge.prototype.clearContract = function() {
  return this.setContract(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.SingleEdgeContractAsEdge.prototype.hasContract = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string node_name = 2;
 * @return {string}
 */
proto.vtv1.SingleEdgeContractAsEdge.prototype.getNodeName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.SingleEdgeContractAsEdge} returns this
 */
proto.vtv1.SingleEdgeContractAsEdge.prototype.setNodeName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional EdgeCosts edge_costs = 3;
 * @return {?proto.vtv1.EdgeCosts}
 */
proto.vtv1.SingleEdgeContractAsEdge.prototype.getEdgeCosts = function() {
  return /** @type{?proto.vtv1.EdgeCosts} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.EdgeCosts, 3));
};


/**
 * @param {?proto.vtv1.EdgeCosts|undefined} value
 * @return {!proto.vtv1.SingleEdgeContractAsEdge} returns this
*/
proto.vtv1.SingleEdgeContractAsEdge.prototype.setEdgeCosts = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.SingleEdgeContractAsEdge} returns this
 */
proto.vtv1.SingleEdgeContractAsEdge.prototype.clearEdgeCosts = function() {
  return this.setEdgeCosts(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.SingleEdgeContractAsEdge.prototype.hasEdgeCosts = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * repeated Product products = 4;
 * @return {!Array<!proto.vtv1.Product>}
 */
proto.vtv1.SingleEdgeContractAsEdge.prototype.getProductsList = function() {
  return /** @type{!Array<!proto.vtv1.Product>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_vorto_types_types_pb.Product, 4));
};


/**
 * @param {!Array<!proto.vtv1.Product>} value
 * @return {!proto.vtv1.SingleEdgeContractAsEdge} returns this
*/
proto.vtv1.SingleEdgeContractAsEdge.prototype.setProductsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.vtv1.Product=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.Product}
 */
proto.vtv1.SingleEdgeContractAsEdge.prototype.addProducts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.vtv1.Product, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.SingleEdgeContractAsEdge} returns this
 */
proto.vtv1.SingleEdgeContractAsEdge.prototype.clearProductsList = function() {
  return this.setProductsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.ListEdgeContractsResponseAsEdge.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.ListEdgeContractsResponseAsEdge.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.ListEdgeContractsResponseAsEdge.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.ListEdgeContractsResponseAsEdge} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.ListEdgeContractsResponseAsEdge.toObject = function(includeInstance, msg) {
  var f, obj = {
    edgeContractsList: jspb.Message.toObjectList(msg.getEdgeContractsList(),
    proto_vorto_types_types_pb.SlimEdgeContract.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.ListEdgeContractsResponseAsEdge}
 */
proto.vtv1.ListEdgeContractsResponseAsEdge.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.ListEdgeContractsResponseAsEdge;
  return proto.vtv1.ListEdgeContractsResponseAsEdge.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.ListEdgeContractsResponseAsEdge} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.ListEdgeContractsResponseAsEdge}
 */
proto.vtv1.ListEdgeContractsResponseAsEdge.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_vorto_types_types_pb.SlimEdgeContract;
      reader.readMessage(value,proto_vorto_types_types_pb.SlimEdgeContract.deserializeBinaryFromReader);
      msg.addEdgeContracts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.ListEdgeContractsResponseAsEdge.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.ListEdgeContractsResponseAsEdge.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.ListEdgeContractsResponseAsEdge} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.ListEdgeContractsResponseAsEdge.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEdgeContractsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto_vorto_types_types_pb.SlimEdgeContract.serializeBinaryToWriter
    );
  }
};


/**
 * repeated SlimEdgeContract edge_contracts = 1;
 * @return {!Array<!proto.vtv1.SlimEdgeContract>}
 */
proto.vtv1.ListEdgeContractsResponseAsEdge.prototype.getEdgeContractsList = function() {
  return /** @type{!Array<!proto.vtv1.SlimEdgeContract>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_vorto_types_types_pb.SlimEdgeContract, 1));
};


/**
 * @param {!Array<!proto.vtv1.SlimEdgeContract>} value
 * @return {!proto.vtv1.ListEdgeContractsResponseAsEdge} returns this
*/
proto.vtv1.ListEdgeContractsResponseAsEdge.prototype.setEdgeContractsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.vtv1.SlimEdgeContract=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.SlimEdgeContract}
 */
proto.vtv1.ListEdgeContractsResponseAsEdge.prototype.addEdgeContracts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.vtv1.SlimEdgeContract, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.ListEdgeContractsResponseAsEdge} returns this
 */
proto.vtv1.ListEdgeContractsResponseAsEdge.prototype.clearEdgeContractsList = function() {
  return this.setEdgeContractsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.ListPendingShipmentsForDriverRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.ListPendingShipmentsForDriverRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.ListPendingShipmentsForDriverRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.ListPendingShipmentsForDriverRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    userId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.ListPendingShipmentsForDriverRequest}
 */
proto.vtv1.ListPendingShipmentsForDriverRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.ListPendingShipmentsForDriverRequest;
  return proto.vtv1.ListPendingShipmentsForDriverRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.ListPendingShipmentsForDriverRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.ListPendingShipmentsForDriverRequest}
 */
proto.vtv1.ListPendingShipmentsForDriverRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.ListPendingShipmentsForDriverRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.ListPendingShipmentsForDriverRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.ListPendingShipmentsForDriverRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.ListPendingShipmentsForDriverRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string user_id = 1;
 * @return {string}
 */
proto.vtv1.ListPendingShipmentsForDriverRequest.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.ListPendingShipmentsForDriverRequest} returns this
 */
proto.vtv1.ListPendingShipmentsForDriverRequest.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.ListPendingShipmentsForDriverResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.ListPendingShipmentsForDriverResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.ListPendingShipmentsForDriverResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.ListPendingShipmentsForDriverResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.ListPendingShipmentsForDriverResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    shipmentsList: jspb.Message.toObjectList(msg.getShipmentsList(),
    proto_vorto_edge_edge_pb.PendingShipmentSummaryForDriverAssignment.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.ListPendingShipmentsForDriverResponse}
 */
proto.vtv1.ListPendingShipmentsForDriverResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.ListPendingShipmentsForDriverResponse;
  return proto.vtv1.ListPendingShipmentsForDriverResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.ListPendingShipmentsForDriverResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.ListPendingShipmentsForDriverResponse}
 */
proto.vtv1.ListPendingShipmentsForDriverResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_vorto_edge_edge_pb.PendingShipmentSummaryForDriverAssignment;
      reader.readMessage(value,proto_vorto_edge_edge_pb.PendingShipmentSummaryForDriverAssignment.deserializeBinaryFromReader);
      msg.addShipments(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.ListPendingShipmentsForDriverResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.ListPendingShipmentsForDriverResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.ListPendingShipmentsForDriverResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.ListPendingShipmentsForDriverResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getShipmentsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto_vorto_edge_edge_pb.PendingShipmentSummaryForDriverAssignment.serializeBinaryToWriter
    );
  }
};


/**
 * repeated PendingShipmentSummaryForDriverAssignment shipments = 1;
 * @return {!Array<!proto.vtv1.PendingShipmentSummaryForDriverAssignment>}
 */
proto.vtv1.ListPendingShipmentsForDriverResponse.prototype.getShipmentsList = function() {
  return /** @type{!Array<!proto.vtv1.PendingShipmentSummaryForDriverAssignment>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_vorto_edge_edge_pb.PendingShipmentSummaryForDriverAssignment, 1));
};


/**
 * @param {!Array<!proto.vtv1.PendingShipmentSummaryForDriverAssignment>} value
 * @return {!proto.vtv1.ListPendingShipmentsForDriverResponse} returns this
*/
proto.vtv1.ListPendingShipmentsForDriverResponse.prototype.setShipmentsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.vtv1.PendingShipmentSummaryForDriverAssignment=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.PendingShipmentSummaryForDriverAssignment}
 */
proto.vtv1.ListPendingShipmentsForDriverResponse.prototype.addShipments = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.vtv1.PendingShipmentSummaryForDriverAssignment, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.ListPendingShipmentsForDriverResponse} returns this
 */
proto.vtv1.ListPendingShipmentsForDriverResponse.prototype.clearShipmentsList = function() {
  return this.setShipmentsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.AddDriverRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.AddDriverRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.AddDriverRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.AddDriverRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    userId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.AddDriverRequest}
 */
proto.vtv1.AddDriverRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.AddDriverRequest;
  return proto.vtv1.AddDriverRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.AddDriverRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.AddDriverRequest}
 */
proto.vtv1.AddDriverRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.AddDriverRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.AddDriverRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.AddDriverRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.AddDriverRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string user_id = 1;
 * @return {string}
 */
proto.vtv1.AddDriverRequest.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.AddDriverRequest} returns this
 */
proto.vtv1.AddDriverRequest.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.AddDriverResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.AddDriverResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.AddDriverResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.AddDriverResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.AddDriverResponse}
 */
proto.vtv1.AddDriverResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.AddDriverResponse;
  return proto.vtv1.AddDriverResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.AddDriverResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.AddDriverResponse}
 */
proto.vtv1.AddDriverResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.AddDriverResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.AddDriverResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.AddDriverResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.AddDriverResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.RemoveDriverResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.RemoveDriverResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.RemoveDriverResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.RemoveDriverResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.RemoveDriverResponse}
 */
proto.vtv1.RemoveDriverResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.RemoveDriverResponse;
  return proto.vtv1.RemoveDriverResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.RemoveDriverResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.RemoveDriverResponse}
 */
proto.vtv1.RemoveDriverResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.RemoveDriverResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.RemoveDriverResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.RemoveDriverResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.RemoveDriverResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.CloseEdgeContractRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.CloseEdgeContractRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.CloseEdgeContractRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.CloseEdgeContractRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    edgeContractId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.CloseEdgeContractRequest}
 */
proto.vtv1.CloseEdgeContractRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.CloseEdgeContractRequest;
  return proto.vtv1.CloseEdgeContractRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.CloseEdgeContractRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.CloseEdgeContractRequest}
 */
proto.vtv1.CloseEdgeContractRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEdgeContractId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.CloseEdgeContractRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.CloseEdgeContractRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.CloseEdgeContractRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.CloseEdgeContractRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEdgeContractId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string edge_contract_id = 1;
 * @return {string}
 */
proto.vtv1.CloseEdgeContractRequest.prototype.getEdgeContractId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.CloseEdgeContractRequest} returns this
 */
proto.vtv1.CloseEdgeContractRequest.prototype.setEdgeContractId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.CloseEdgeContractResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.CloseEdgeContractResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.CloseEdgeContractResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.CloseEdgeContractResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.CloseEdgeContractResponse}
 */
proto.vtv1.CloseEdgeContractResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.CloseEdgeContractResponse;
  return proto.vtv1.CloseEdgeContractResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.CloseEdgeContractResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.CloseEdgeContractResponse}
 */
proto.vtv1.CloseEdgeContractResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.CloseEdgeContractResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.CloseEdgeContractResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.CloseEdgeContractResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.CloseEdgeContractResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.GetEdgeContractRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.GetEdgeContractRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.GetEdgeContractRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.GetEdgeContractRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    edgeContractId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.GetEdgeContractRequest}
 */
proto.vtv1.GetEdgeContractRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.GetEdgeContractRequest;
  return proto.vtv1.GetEdgeContractRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.GetEdgeContractRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.GetEdgeContractRequest}
 */
proto.vtv1.GetEdgeContractRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEdgeContractId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.GetEdgeContractRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.GetEdgeContractRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.GetEdgeContractRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.GetEdgeContractRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEdgeContractId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string edge_contract_id = 1;
 * @return {string}
 */
proto.vtv1.GetEdgeContractRequest.prototype.getEdgeContractId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.GetEdgeContractRequest} returns this
 */
proto.vtv1.GetEdgeContractRequest.prototype.setEdgeContractId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.GetEdgeContractsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.GetEdgeContractsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.GetEdgeContractsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.GetEdgeContractsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.GetEdgeContractsRequest}
 */
proto.vtv1.GetEdgeContractsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.GetEdgeContractsRequest;
  return proto.vtv1.GetEdgeContractsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.GetEdgeContractsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.GetEdgeContractsRequest}
 */
proto.vtv1.GetEdgeContractsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.GetEdgeContractsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.GetEdgeContractsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.GetEdgeContractsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.GetEdgeContractsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.GetEdgeContractsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.GetEdgeContractsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.GetEdgeContractsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.GetEdgeContractsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.GetEdgeContractsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    edgeContractsList: jspb.Message.toObjectList(msg.getEdgeContractsList(),
    proto_vorto_types_types_pb.EdgeContract.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.GetEdgeContractsResponse}
 */
proto.vtv1.GetEdgeContractsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.GetEdgeContractsResponse;
  return proto.vtv1.GetEdgeContractsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.GetEdgeContractsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.GetEdgeContractsResponse}
 */
proto.vtv1.GetEdgeContractsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_vorto_types_types_pb.EdgeContract;
      reader.readMessage(value,proto_vorto_types_types_pb.EdgeContract.deserializeBinaryFromReader);
      msg.addEdgeContracts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.GetEdgeContractsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.GetEdgeContractsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.GetEdgeContractsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.GetEdgeContractsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEdgeContractsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto_vorto_types_types_pb.EdgeContract.serializeBinaryToWriter
    );
  }
};


/**
 * repeated EdgeContract edge_contracts = 1;
 * @return {!Array<!proto.vtv1.EdgeContract>}
 */
proto.vtv1.GetEdgeContractsResponse.prototype.getEdgeContractsList = function() {
  return /** @type{!Array<!proto.vtv1.EdgeContract>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_vorto_types_types_pb.EdgeContract, 1));
};


/**
 * @param {!Array<!proto.vtv1.EdgeContract>} value
 * @return {!proto.vtv1.GetEdgeContractsResponse} returns this
*/
proto.vtv1.GetEdgeContractsResponse.prototype.setEdgeContractsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.vtv1.EdgeContract=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.EdgeContract}
 */
proto.vtv1.GetEdgeContractsResponse.prototype.addEdgeContracts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.vtv1.EdgeContract, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.GetEdgeContractsResponse} returns this
 */
proto.vtv1.GetEdgeContractsResponse.prototype.clearEdgeContractsList = function() {
  return this.setEdgeContractsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.ShipmentResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.ShipmentResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.ShipmentResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.ShipmentResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    shipment: (f = msg.getShipment()) && proto_vorto_types_types_pb.Shipment.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.ShipmentResponse}
 */
proto.vtv1.ShipmentResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.ShipmentResponse;
  return proto.vtv1.ShipmentResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.ShipmentResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.ShipmentResponse}
 */
proto.vtv1.ShipmentResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_vorto_types_types_pb.Shipment;
      reader.readMessage(value,proto_vorto_types_types_pb.Shipment.deserializeBinaryFromReader);
      msg.setShipment(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.ShipmentResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.ShipmentResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.ShipmentResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.ShipmentResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getShipment();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_vorto_types_types_pb.Shipment.serializeBinaryToWriter
    );
  }
};


/**
 * optional Shipment shipment = 1;
 * @return {?proto.vtv1.Shipment}
 */
proto.vtv1.ShipmentResponse.prototype.getShipment = function() {
  return /** @type{?proto.vtv1.Shipment} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.Shipment, 1));
};


/**
 * @param {?proto.vtv1.Shipment|undefined} value
 * @return {!proto.vtv1.ShipmentResponse} returns this
*/
proto.vtv1.ShipmentResponse.prototype.setShipment = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.ShipmentResponse} returns this
 */
proto.vtv1.ShipmentResponse.prototype.clearShipment = function() {
  return this.setShipment(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.ShipmentResponse.prototype.hasShipment = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.ShipmentsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.ShipmentsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.ShipmentsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.ShipmentsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.ShipmentsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    shipmentsList: jspb.Message.toObjectList(msg.getShipmentsList(),
    proto_vorto_types_types_pb.Shipment.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.ShipmentsResponse}
 */
proto.vtv1.ShipmentsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.ShipmentsResponse;
  return proto.vtv1.ShipmentsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.ShipmentsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.ShipmentsResponse}
 */
proto.vtv1.ShipmentsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_vorto_types_types_pb.Shipment;
      reader.readMessage(value,proto_vorto_types_types_pb.Shipment.deserializeBinaryFromReader);
      msg.addShipments(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.ShipmentsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.ShipmentsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.ShipmentsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.ShipmentsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getShipmentsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto_vorto_types_types_pb.Shipment.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Shipment shipments = 1;
 * @return {!Array<!proto.vtv1.Shipment>}
 */
proto.vtv1.ShipmentsResponse.prototype.getShipmentsList = function() {
  return /** @type{!Array<!proto.vtv1.Shipment>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_vorto_types_types_pb.Shipment, 1));
};


/**
 * @param {!Array<!proto.vtv1.Shipment>} value
 * @return {!proto.vtv1.ShipmentsResponse} returns this
*/
proto.vtv1.ShipmentsResponse.prototype.setShipmentsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.vtv1.Shipment=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.Shipment}
 */
proto.vtv1.ShipmentsResponse.prototype.addShipments = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.vtv1.Shipment, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.ShipmentsResponse} returns this
 */
proto.vtv1.ShipmentsResponse.prototype.clearShipmentsList = function() {
  return this.setShipmentsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.GetEdgeContractNodesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.GetEdgeContractNodesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.GetEdgeContractNodesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.GetEdgeContractNodesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.GetEdgeContractNodesRequest}
 */
proto.vtv1.GetEdgeContractNodesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.GetEdgeContractNodesRequest;
  return proto.vtv1.GetEdgeContractNodesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.GetEdgeContractNodesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.GetEdgeContractNodesRequest}
 */
proto.vtv1.GetEdgeContractNodesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.GetEdgeContractNodesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.GetEdgeContractNodesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.GetEdgeContractNodesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.GetEdgeContractNodesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.GetEdgeContractNodesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.GetEdgeContractNodesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.GetEdgeContractNodesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.GetEdgeContractNodesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.GetEdgeContractNodesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    nodesList: jspb.Message.toObjectList(msg.getNodesList(),
    proto_vorto_edge_edge_pb.EdgeNode.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.GetEdgeContractNodesResponse}
 */
proto.vtv1.GetEdgeContractNodesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.GetEdgeContractNodesResponse;
  return proto.vtv1.GetEdgeContractNodesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.GetEdgeContractNodesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.GetEdgeContractNodesResponse}
 */
proto.vtv1.GetEdgeContractNodesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_vorto_edge_edge_pb.EdgeNode;
      reader.readMessage(value,proto_vorto_edge_edge_pb.EdgeNode.deserializeBinaryFromReader);
      msg.addNodes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.GetEdgeContractNodesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.GetEdgeContractNodesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.GetEdgeContractNodesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.GetEdgeContractNodesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNodesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto_vorto_edge_edge_pb.EdgeNode.serializeBinaryToWriter
    );
  }
};


/**
 * repeated EdgeNode nodes = 1;
 * @return {!Array<!proto.vtv1.EdgeNode>}
 */
proto.vtv1.GetEdgeContractNodesResponse.prototype.getNodesList = function() {
  return /** @type{!Array<!proto.vtv1.EdgeNode>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_vorto_edge_edge_pb.EdgeNode, 1));
};


/**
 * @param {!Array<!proto.vtv1.EdgeNode>} value
 * @return {!proto.vtv1.GetEdgeContractNodesResponse} returns this
*/
proto.vtv1.GetEdgeContractNodesResponse.prototype.setNodesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.vtv1.EdgeNode=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.EdgeNode}
 */
proto.vtv1.GetEdgeContractNodesResponse.prototype.addNodes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.vtv1.EdgeNode, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.GetEdgeContractNodesResponse} returns this
 */
proto.vtv1.GetEdgeContractNodesResponse.prototype.clearNodesList = function() {
  return this.setNodesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.CreateShipmentRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.CreateShipmentRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.CreateShipmentRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.CreateShipmentRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    shipment: (f = msg.getShipment()) && proto_vorto_types_types_pb.Shipment.toObject(includeInstance, f),
    createShipment: (f = msg.getCreateShipment()) && proto_vorto_edge_edge_pb.CreateShipment.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.CreateShipmentRequest}
 */
proto.vtv1.CreateShipmentRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.CreateShipmentRequest;
  return proto.vtv1.CreateShipmentRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.CreateShipmentRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.CreateShipmentRequest}
 */
proto.vtv1.CreateShipmentRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_vorto_types_types_pb.Shipment;
      reader.readMessage(value,proto_vorto_types_types_pb.Shipment.deserializeBinaryFromReader);
      msg.setShipment(value);
      break;
    case 2:
      var value = new proto_vorto_edge_edge_pb.CreateShipment;
      reader.readMessage(value,proto_vorto_edge_edge_pb.CreateShipment.deserializeBinaryFromReader);
      msg.setCreateShipment(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.CreateShipmentRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.CreateShipmentRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.CreateShipmentRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.CreateShipmentRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getShipment();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_vorto_types_types_pb.Shipment.serializeBinaryToWriter
    );
  }
  f = message.getCreateShipment();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_vorto_edge_edge_pb.CreateShipment.serializeBinaryToWriter
    );
  }
};


/**
 * optional Shipment shipment = 1;
 * @return {?proto.vtv1.Shipment}
 */
proto.vtv1.CreateShipmentRequest.prototype.getShipment = function() {
  return /** @type{?proto.vtv1.Shipment} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.Shipment, 1));
};


/**
 * @param {?proto.vtv1.Shipment|undefined} value
 * @return {!proto.vtv1.CreateShipmentRequest} returns this
*/
proto.vtv1.CreateShipmentRequest.prototype.setShipment = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.CreateShipmentRequest} returns this
 */
proto.vtv1.CreateShipmentRequest.prototype.clearShipment = function() {
  return this.setShipment(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.CreateShipmentRequest.prototype.hasShipment = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional CreateShipment create_shipment = 2;
 * @return {?proto.vtv1.CreateShipment}
 */
proto.vtv1.CreateShipmentRequest.prototype.getCreateShipment = function() {
  return /** @type{?proto.vtv1.CreateShipment} */ (
    jspb.Message.getWrapperField(this, proto_vorto_edge_edge_pb.CreateShipment, 2));
};


/**
 * @param {?proto.vtv1.CreateShipment|undefined} value
 * @return {!proto.vtv1.CreateShipmentRequest} returns this
*/
proto.vtv1.CreateShipmentRequest.prototype.setCreateShipment = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.CreateShipmentRequest} returns this
 */
proto.vtv1.CreateShipmentRequest.prototype.clearCreateShipment = function() {
  return this.setCreateShipment(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.CreateShipmentRequest.prototype.hasCreateShipment = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.AssignShipmentRequest.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.AssignShipmentRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.AssignShipmentRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.AssignShipmentRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.AssignShipmentRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    shipmentId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    driverId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    unassignShipmentIdsList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.AssignShipmentRequest}
 */
proto.vtv1.AssignShipmentRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.AssignShipmentRequest;
  return proto.vtv1.AssignShipmentRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.AssignShipmentRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.AssignShipmentRequest}
 */
proto.vtv1.AssignShipmentRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setShipmentId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDriverId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addUnassignShipmentIds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.AssignShipmentRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.AssignShipmentRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.AssignShipmentRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.AssignShipmentRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getShipmentId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDriverId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getUnassignShipmentIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
};


/**
 * optional string shipment_id = 1;
 * @return {string}
 */
proto.vtv1.AssignShipmentRequest.prototype.getShipmentId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.AssignShipmentRequest} returns this
 */
proto.vtv1.AssignShipmentRequest.prototype.setShipmentId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string driver_id = 2;
 * @return {string}
 */
proto.vtv1.AssignShipmentRequest.prototype.getDriverId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.AssignShipmentRequest} returns this
 */
proto.vtv1.AssignShipmentRequest.prototype.setDriverId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated string unassign_shipment_ids = 3;
 * @return {!Array<string>}
 */
proto.vtv1.AssignShipmentRequest.prototype.getUnassignShipmentIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.vtv1.AssignShipmentRequest} returns this
 */
proto.vtv1.AssignShipmentRequest.prototype.setUnassignShipmentIdsList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.vtv1.AssignShipmentRequest} returns this
 */
proto.vtv1.AssignShipmentRequest.prototype.addUnassignShipmentIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.AssignShipmentRequest} returns this
 */
proto.vtv1.AssignShipmentRequest.prototype.clearUnassignShipmentIdsList = function() {
  return this.setUnassignShipmentIdsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.NodeSitesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.NodeSitesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.NodeSitesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.NodeSitesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.NodeSitesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    sitesList: jspb.Message.toObjectList(msg.getSitesList(),
    proto_vorto_edge_edge_pb.NodeSite.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.NodeSitesResponse}
 */
proto.vtv1.NodeSitesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.NodeSitesResponse;
  return proto.vtv1.NodeSitesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.NodeSitesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.NodeSitesResponse}
 */
proto.vtv1.NodeSitesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_vorto_edge_edge_pb.NodeSite;
      reader.readMessage(value,proto_vorto_edge_edge_pb.NodeSite.deserializeBinaryFromReader);
      msg.addSites(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.NodeSitesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.NodeSitesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.NodeSitesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.NodeSitesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSitesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto_vorto_edge_edge_pb.NodeSite.serializeBinaryToWriter
    );
  }
};


/**
 * repeated NodeSite sites = 1;
 * @return {!Array<!proto.vtv1.NodeSite>}
 */
proto.vtv1.NodeSitesResponse.prototype.getSitesList = function() {
  return /** @type{!Array<!proto.vtv1.NodeSite>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_vorto_edge_edge_pb.NodeSite, 1));
};


/**
 * @param {!Array<!proto.vtv1.NodeSite>} value
 * @return {!proto.vtv1.NodeSitesResponse} returns this
*/
proto.vtv1.NodeSitesResponse.prototype.setSitesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.vtv1.NodeSite=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.NodeSite}
 */
proto.vtv1.NodeSitesResponse.prototype.addSites = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.vtv1.NodeSite, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.NodeSitesResponse} returns this
 */
proto.vtv1.NodeSitesResponse.prototype.clearSitesList = function() {
  return this.setSitesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.NodeProductsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.NodeProductsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.NodeProductsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.NodeProductsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.NodeProductsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    productsList: jspb.Message.toObjectList(msg.getProductsList(),
    proto_vorto_edge_edge_pb.NodeProduct.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.NodeProductsResponse}
 */
proto.vtv1.NodeProductsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.NodeProductsResponse;
  return proto.vtv1.NodeProductsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.NodeProductsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.NodeProductsResponse}
 */
proto.vtv1.NodeProductsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_vorto_edge_edge_pb.NodeProduct;
      reader.readMessage(value,proto_vorto_edge_edge_pb.NodeProduct.deserializeBinaryFromReader);
      msg.addProducts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.NodeProductsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.NodeProductsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.NodeProductsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.NodeProductsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProductsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto_vorto_edge_edge_pb.NodeProduct.serializeBinaryToWriter
    );
  }
};


/**
 * repeated NodeProduct products = 1;
 * @return {!Array<!proto.vtv1.NodeProduct>}
 */
proto.vtv1.NodeProductsResponse.prototype.getProductsList = function() {
  return /** @type{!Array<!proto.vtv1.NodeProduct>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_vorto_edge_edge_pb.NodeProduct, 1));
};


/**
 * @param {!Array<!proto.vtv1.NodeProduct>} value
 * @return {!proto.vtv1.NodeProductsResponse} returns this
*/
proto.vtv1.NodeProductsResponse.prototype.setProductsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.vtv1.NodeProduct=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.NodeProduct}
 */
proto.vtv1.NodeProductsResponse.prototype.addProducts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.vtv1.NodeProduct, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.NodeProductsResponse} returns this
 */
proto.vtv1.NodeProductsResponse.prototype.clearProductsList = function() {
  return this.setProductsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.EdgeCreateSiteRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.EdgeCreateSiteRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.EdgeCreateSiteRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.EdgeCreateSiteRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    nodeId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    longitude: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    latitude: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    radius: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    numberOfBays: jspb.Message.getFieldWithDefault(msg, 6, 0),
    address: (f = msg.getAddress()) && proto_vorto_types_types_pb.NullableString.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.EdgeCreateSiteRequest}
 */
proto.vtv1.EdgeCreateSiteRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.EdgeCreateSiteRequest;
  return proto.vtv1.EdgeCreateSiteRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.EdgeCreateSiteRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.EdgeCreateSiteRequest}
 */
proto.vtv1.EdgeCreateSiteRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setNodeId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLongitude(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLatitude(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setRadius(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setNumberOfBays(value);
      break;
    case 7:
      var value = new proto_vorto_types_types_pb.NullableString;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableString.deserializeBinaryFromReader);
      msg.setAddress(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.EdgeCreateSiteRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.EdgeCreateSiteRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.EdgeCreateSiteRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.EdgeCreateSiteRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getNodeId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLongitude();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getLatitude();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getRadius();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getNumberOfBays();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getAddress();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto_vorto_types_types_pb.NullableString.serializeBinaryToWriter
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.vtv1.EdgeCreateSiteRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.EdgeCreateSiteRequest} returns this
 */
proto.vtv1.EdgeCreateSiteRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string node_id = 2;
 * @return {string}
 */
proto.vtv1.EdgeCreateSiteRequest.prototype.getNodeId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.EdgeCreateSiteRequest} returns this
 */
proto.vtv1.EdgeCreateSiteRequest.prototype.setNodeId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional double longitude = 3;
 * @return {number}
 */
proto.vtv1.EdgeCreateSiteRequest.prototype.getLongitude = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.EdgeCreateSiteRequest} returns this
 */
proto.vtv1.EdgeCreateSiteRequest.prototype.setLongitude = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional double latitude = 4;
 * @return {number}
 */
proto.vtv1.EdgeCreateSiteRequest.prototype.getLatitude = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.EdgeCreateSiteRequest} returns this
 */
proto.vtv1.EdgeCreateSiteRequest.prototype.setLatitude = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional double radius = 5;
 * @return {number}
 */
proto.vtv1.EdgeCreateSiteRequest.prototype.getRadius = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.EdgeCreateSiteRequest} returns this
 */
proto.vtv1.EdgeCreateSiteRequest.prototype.setRadius = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional int64 number_of_bays = 6;
 * @return {number}
 */
proto.vtv1.EdgeCreateSiteRequest.prototype.getNumberOfBays = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.EdgeCreateSiteRequest} returns this
 */
proto.vtv1.EdgeCreateSiteRequest.prototype.setNumberOfBays = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional NullableString address = 7;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.EdgeCreateSiteRequest.prototype.getAddress = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableString, 7));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.EdgeCreateSiteRequest} returns this
*/
proto.vtv1.EdgeCreateSiteRequest.prototype.setAddress = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.EdgeCreateSiteRequest} returns this
 */
proto.vtv1.EdgeCreateSiteRequest.prototype.clearAddress = function() {
  return this.setAddress(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.EdgeCreateSiteRequest.prototype.hasAddress = function() {
  return jspb.Message.getField(this, 7) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.EdgeCreateSiteResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.EdgeCreateSiteResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.EdgeCreateSiteResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.EdgeCreateSiteResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    site: (f = msg.getSite()) && proto_vorto_edge_edge_pb.NodeSite.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.EdgeCreateSiteResponse}
 */
proto.vtv1.EdgeCreateSiteResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.EdgeCreateSiteResponse;
  return proto.vtv1.EdgeCreateSiteResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.EdgeCreateSiteResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.EdgeCreateSiteResponse}
 */
proto.vtv1.EdgeCreateSiteResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_vorto_edge_edge_pb.NodeSite;
      reader.readMessage(value,proto_vorto_edge_edge_pb.NodeSite.deserializeBinaryFromReader);
      msg.setSite(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.EdgeCreateSiteResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.EdgeCreateSiteResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.EdgeCreateSiteResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.EdgeCreateSiteResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSite();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_vorto_edge_edge_pb.NodeSite.serializeBinaryToWriter
    );
  }
};


/**
 * optional NodeSite site = 1;
 * @return {?proto.vtv1.NodeSite}
 */
proto.vtv1.EdgeCreateSiteResponse.prototype.getSite = function() {
  return /** @type{?proto.vtv1.NodeSite} */ (
    jspb.Message.getWrapperField(this, proto_vorto_edge_edge_pb.NodeSite, 1));
};


/**
 * @param {?proto.vtv1.NodeSite|undefined} value
 * @return {!proto.vtv1.EdgeCreateSiteResponse} returns this
*/
proto.vtv1.EdgeCreateSiteResponse.prototype.setSite = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.EdgeCreateSiteResponse} returns this
 */
proto.vtv1.EdgeCreateSiteResponse.prototype.clearSite = function() {
  return this.setSite(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.EdgeCreateSiteResponse.prototype.hasSite = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.PendingShipmentSummaryResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.PendingShipmentSummaryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.PendingShipmentSummaryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.PendingShipmentSummaryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.PendingShipmentSummaryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    shipmentsList: jspb.Message.toObjectList(msg.getShipmentsList(),
    proto_vorto_edge_edge_pb.PendingShipmentSummary.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.PendingShipmentSummaryResponse}
 */
proto.vtv1.PendingShipmentSummaryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.PendingShipmentSummaryResponse;
  return proto.vtv1.PendingShipmentSummaryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.PendingShipmentSummaryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.PendingShipmentSummaryResponse}
 */
proto.vtv1.PendingShipmentSummaryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_vorto_edge_edge_pb.PendingShipmentSummary;
      reader.readMessage(value,proto_vorto_edge_edge_pb.PendingShipmentSummary.deserializeBinaryFromReader);
      msg.addShipments(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.PendingShipmentSummaryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.PendingShipmentSummaryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.PendingShipmentSummaryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.PendingShipmentSummaryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getShipmentsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto_vorto_edge_edge_pb.PendingShipmentSummary.serializeBinaryToWriter
    );
  }
};


/**
 * repeated PendingShipmentSummary shipments = 1;
 * @return {!Array<!proto.vtv1.PendingShipmentSummary>}
 */
proto.vtv1.PendingShipmentSummaryResponse.prototype.getShipmentsList = function() {
  return /** @type{!Array<!proto.vtv1.PendingShipmentSummary>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_vorto_edge_edge_pb.PendingShipmentSummary, 1));
};


/**
 * @param {!Array<!proto.vtv1.PendingShipmentSummary>} value
 * @return {!proto.vtv1.PendingShipmentSummaryResponse} returns this
*/
proto.vtv1.PendingShipmentSummaryResponse.prototype.setShipmentsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.vtv1.PendingShipmentSummary=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.PendingShipmentSummary}
 */
proto.vtv1.PendingShipmentSummaryResponse.prototype.addShipments = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.vtv1.PendingShipmentSummary, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.PendingShipmentSummaryResponse} returns this
 */
proto.vtv1.PendingShipmentSummaryResponse.prototype.clearShipmentsList = function() {
  return this.setShipmentsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.InProgressShipmentSummaryResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.InProgressShipmentSummaryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.InProgressShipmentSummaryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.InProgressShipmentSummaryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.InProgressShipmentSummaryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    shipmentsList: jspb.Message.toObjectList(msg.getShipmentsList(),
    proto_vorto_edge_edge_pb.InProgressShipmentSummary.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.InProgressShipmentSummaryResponse}
 */
proto.vtv1.InProgressShipmentSummaryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.InProgressShipmentSummaryResponse;
  return proto.vtv1.InProgressShipmentSummaryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.InProgressShipmentSummaryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.InProgressShipmentSummaryResponse}
 */
proto.vtv1.InProgressShipmentSummaryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_vorto_edge_edge_pb.InProgressShipmentSummary;
      reader.readMessage(value,proto_vorto_edge_edge_pb.InProgressShipmentSummary.deserializeBinaryFromReader);
      msg.addShipments(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.InProgressShipmentSummaryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.InProgressShipmentSummaryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.InProgressShipmentSummaryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.InProgressShipmentSummaryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getShipmentsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto_vorto_edge_edge_pb.InProgressShipmentSummary.serializeBinaryToWriter
    );
  }
};


/**
 * repeated InProgressShipmentSummary shipments = 1;
 * @return {!Array<!proto.vtv1.InProgressShipmentSummary>}
 */
proto.vtv1.InProgressShipmentSummaryResponse.prototype.getShipmentsList = function() {
  return /** @type{!Array<!proto.vtv1.InProgressShipmentSummary>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_vorto_edge_edge_pb.InProgressShipmentSummary, 1));
};


/**
 * @param {!Array<!proto.vtv1.InProgressShipmentSummary>} value
 * @return {!proto.vtv1.InProgressShipmentSummaryResponse} returns this
*/
proto.vtv1.InProgressShipmentSummaryResponse.prototype.setShipmentsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.vtv1.InProgressShipmentSummary=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.InProgressShipmentSummary}
 */
proto.vtv1.InProgressShipmentSummaryResponse.prototype.addShipments = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.vtv1.InProgressShipmentSummary, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.InProgressShipmentSummaryResponse} returns this
 */
proto.vtv1.InProgressShipmentSummaryResponse.prototype.clearShipmentsList = function() {
  return this.setShipmentsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.CompletedShipmentSummaryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.CompletedShipmentSummaryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.CompletedShipmentSummaryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.CompletedShipmentSummaryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    pageNumber: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.CompletedShipmentSummaryRequest}
 */
proto.vtv1.CompletedShipmentSummaryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.CompletedShipmentSummaryRequest;
  return proto.vtv1.CompletedShipmentSummaryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.CompletedShipmentSummaryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.CompletedShipmentSummaryRequest}
 */
proto.vtv1.CompletedShipmentSummaryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPageNumber(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.CompletedShipmentSummaryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.CompletedShipmentSummaryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.CompletedShipmentSummaryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.CompletedShipmentSummaryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPageNumber();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 page_number = 1;
 * @return {number}
 */
proto.vtv1.CompletedShipmentSummaryRequest.prototype.getPageNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.CompletedShipmentSummaryRequest} returns this
 */
proto.vtv1.CompletedShipmentSummaryRequest.prototype.setPageNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.CompletedShipmentSummaryResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.CompletedShipmentSummaryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.CompletedShipmentSummaryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.CompletedShipmentSummaryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.CompletedShipmentSummaryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    shipmentsList: jspb.Message.toObjectList(msg.getShipmentsList(),
    proto_vorto_edge_edge_pb.CompletedShipmentSummary.toObject, includeInstance),
    shipmentStartIndex: jspb.Message.getFieldWithDefault(msg, 2, 0),
    shipmentsPerPage: jspb.Message.getFieldWithDefault(msg, 3, 0),
    totalShipments: jspb.Message.getFieldWithDefault(msg, 4, 0),
    previousPageRequest: (f = msg.getPreviousPageRequest()) && proto.vtv1.CompletedShipmentSummaryRequest.toObject(includeInstance, f),
    nextPageRequest: (f = msg.getNextPageRequest()) && proto.vtv1.CompletedShipmentSummaryRequest.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.CompletedShipmentSummaryResponse}
 */
proto.vtv1.CompletedShipmentSummaryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.CompletedShipmentSummaryResponse;
  return proto.vtv1.CompletedShipmentSummaryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.CompletedShipmentSummaryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.CompletedShipmentSummaryResponse}
 */
proto.vtv1.CompletedShipmentSummaryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_vorto_edge_edge_pb.CompletedShipmentSummary;
      reader.readMessage(value,proto_vorto_edge_edge_pb.CompletedShipmentSummary.deserializeBinaryFromReader);
      msg.addShipments(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setShipmentStartIndex(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setShipmentsPerPage(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotalShipments(value);
      break;
    case 5:
      var value = new proto.vtv1.CompletedShipmentSummaryRequest;
      reader.readMessage(value,proto.vtv1.CompletedShipmentSummaryRequest.deserializeBinaryFromReader);
      msg.setPreviousPageRequest(value);
      break;
    case 6:
      var value = new proto.vtv1.CompletedShipmentSummaryRequest;
      reader.readMessage(value,proto.vtv1.CompletedShipmentSummaryRequest.deserializeBinaryFromReader);
      msg.setNextPageRequest(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.CompletedShipmentSummaryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.CompletedShipmentSummaryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.CompletedShipmentSummaryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.CompletedShipmentSummaryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getShipmentsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto_vorto_edge_edge_pb.CompletedShipmentSummary.serializeBinaryToWriter
    );
  }
  f = message.getShipmentStartIndex();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getShipmentsPerPage();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getTotalShipments();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getPreviousPageRequest();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.vtv1.CompletedShipmentSummaryRequest.serializeBinaryToWriter
    );
  }
  f = message.getNextPageRequest();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.vtv1.CompletedShipmentSummaryRequest.serializeBinaryToWriter
    );
  }
};


/**
 * repeated CompletedShipmentSummary shipments = 1;
 * @return {!Array<!proto.vtv1.CompletedShipmentSummary>}
 */
proto.vtv1.CompletedShipmentSummaryResponse.prototype.getShipmentsList = function() {
  return /** @type{!Array<!proto.vtv1.CompletedShipmentSummary>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_vorto_edge_edge_pb.CompletedShipmentSummary, 1));
};


/**
 * @param {!Array<!proto.vtv1.CompletedShipmentSummary>} value
 * @return {!proto.vtv1.CompletedShipmentSummaryResponse} returns this
*/
proto.vtv1.CompletedShipmentSummaryResponse.prototype.setShipmentsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.vtv1.CompletedShipmentSummary=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.CompletedShipmentSummary}
 */
proto.vtv1.CompletedShipmentSummaryResponse.prototype.addShipments = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.vtv1.CompletedShipmentSummary, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.CompletedShipmentSummaryResponse} returns this
 */
proto.vtv1.CompletedShipmentSummaryResponse.prototype.clearShipmentsList = function() {
  return this.setShipmentsList([]);
};


/**
 * optional int64 shipment_start_index = 2;
 * @return {number}
 */
proto.vtv1.CompletedShipmentSummaryResponse.prototype.getShipmentStartIndex = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.CompletedShipmentSummaryResponse} returns this
 */
proto.vtv1.CompletedShipmentSummaryResponse.prototype.setShipmentStartIndex = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 shipments_per_page = 3;
 * @return {number}
 */
proto.vtv1.CompletedShipmentSummaryResponse.prototype.getShipmentsPerPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.CompletedShipmentSummaryResponse} returns this
 */
proto.vtv1.CompletedShipmentSummaryResponse.prototype.setShipmentsPerPage = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 total_shipments = 4;
 * @return {number}
 */
proto.vtv1.CompletedShipmentSummaryResponse.prototype.getTotalShipments = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.CompletedShipmentSummaryResponse} returns this
 */
proto.vtv1.CompletedShipmentSummaryResponse.prototype.setTotalShipments = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional CompletedShipmentSummaryRequest previous_page_request = 5;
 * @return {?proto.vtv1.CompletedShipmentSummaryRequest}
 */
proto.vtv1.CompletedShipmentSummaryResponse.prototype.getPreviousPageRequest = function() {
  return /** @type{?proto.vtv1.CompletedShipmentSummaryRequest} */ (
    jspb.Message.getWrapperField(this, proto.vtv1.CompletedShipmentSummaryRequest, 5));
};


/**
 * @param {?proto.vtv1.CompletedShipmentSummaryRequest|undefined} value
 * @return {!proto.vtv1.CompletedShipmentSummaryResponse} returns this
*/
proto.vtv1.CompletedShipmentSummaryResponse.prototype.setPreviousPageRequest = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.CompletedShipmentSummaryResponse} returns this
 */
proto.vtv1.CompletedShipmentSummaryResponse.prototype.clearPreviousPageRequest = function() {
  return this.setPreviousPageRequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.CompletedShipmentSummaryResponse.prototype.hasPreviousPageRequest = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional CompletedShipmentSummaryRequest next_page_request = 6;
 * @return {?proto.vtv1.CompletedShipmentSummaryRequest}
 */
proto.vtv1.CompletedShipmentSummaryResponse.prototype.getNextPageRequest = function() {
  return /** @type{?proto.vtv1.CompletedShipmentSummaryRequest} */ (
    jspb.Message.getWrapperField(this, proto.vtv1.CompletedShipmentSummaryRequest, 6));
};


/**
 * @param {?proto.vtv1.CompletedShipmentSummaryRequest|undefined} value
 * @return {!proto.vtv1.CompletedShipmentSummaryResponse} returns this
*/
proto.vtv1.CompletedShipmentSummaryResponse.prototype.setNextPageRequest = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.CompletedShipmentSummaryResponse} returns this
 */
proto.vtv1.CompletedShipmentSummaryResponse.prototype.clearNextPageRequest = function() {
  return this.setNextPageRequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.CompletedShipmentSummaryResponse.prototype.hasNextPageRequest = function() {
  return jspb.Message.getField(this, 6) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.CompleteNextStopOfShipmentRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.CompleteNextStopOfShipmentRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.CompleteNextStopOfShipmentRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.CompleteNextStopOfShipmentRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    shipmentId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.CompleteNextStopOfShipmentRequest}
 */
proto.vtv1.CompleteNextStopOfShipmentRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.CompleteNextStopOfShipmentRequest;
  return proto.vtv1.CompleteNextStopOfShipmentRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.CompleteNextStopOfShipmentRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.CompleteNextStopOfShipmentRequest}
 */
proto.vtv1.CompleteNextStopOfShipmentRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setShipmentId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.CompleteNextStopOfShipmentRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.CompleteNextStopOfShipmentRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.CompleteNextStopOfShipmentRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.CompleteNextStopOfShipmentRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getShipmentId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string shipment_id = 1;
 * @return {string}
 */
proto.vtv1.CompleteNextStopOfShipmentRequest.prototype.getShipmentId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.CompleteNextStopOfShipmentRequest} returns this
 */
proto.vtv1.CompleteNextStopOfShipmentRequest.prototype.setShipmentId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.ShipmentDetailsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.ShipmentDetailsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.ShipmentDetailsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.ShipmentDetailsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    shipment: (f = msg.getShipment()) && proto_vorto_edge_edge_pb.ShipmentDetails.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.ShipmentDetailsResponse}
 */
proto.vtv1.ShipmentDetailsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.ShipmentDetailsResponse;
  return proto.vtv1.ShipmentDetailsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.ShipmentDetailsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.ShipmentDetailsResponse}
 */
proto.vtv1.ShipmentDetailsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_vorto_edge_edge_pb.ShipmentDetails;
      reader.readMessage(value,proto_vorto_edge_edge_pb.ShipmentDetails.deserializeBinaryFromReader);
      msg.setShipment(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.ShipmentDetailsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.ShipmentDetailsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.ShipmentDetailsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.ShipmentDetailsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getShipment();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_vorto_edge_edge_pb.ShipmentDetails.serializeBinaryToWriter
    );
  }
};


/**
 * optional ShipmentDetails shipment = 1;
 * @return {?proto.vtv1.ShipmentDetails}
 */
proto.vtv1.ShipmentDetailsResponse.prototype.getShipment = function() {
  return /** @type{?proto.vtv1.ShipmentDetails} */ (
    jspb.Message.getWrapperField(this, proto_vorto_edge_edge_pb.ShipmentDetails, 1));
};


/**
 * @param {?proto.vtv1.ShipmentDetails|undefined} value
 * @return {!proto.vtv1.ShipmentDetailsResponse} returns this
*/
proto.vtv1.ShipmentDetailsResponse.prototype.setShipment = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.ShipmentDetailsResponse} returns this
 */
proto.vtv1.ShipmentDetailsResponse.prototype.clearShipment = function() {
  return this.setShipment(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.ShipmentDetailsResponse.prototype.hasShipment = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.ListDriversForAssignmentResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.ListDriversForAssignmentResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.ListDriversForAssignmentResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.ListDriversForAssignmentResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.ListDriversForAssignmentResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    driversforassignmentList: jspb.Message.toObjectList(msg.getDriversforassignmentList(),
    proto_vorto_edge_edge_pb.DriverForAssignment.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.ListDriversForAssignmentResponse}
 */
proto.vtv1.ListDriversForAssignmentResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.ListDriversForAssignmentResponse;
  return proto.vtv1.ListDriversForAssignmentResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.ListDriversForAssignmentResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.ListDriversForAssignmentResponse}
 */
proto.vtv1.ListDriversForAssignmentResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_vorto_edge_edge_pb.DriverForAssignment;
      reader.readMessage(value,proto_vorto_edge_edge_pb.DriverForAssignment.deserializeBinaryFromReader);
      msg.addDriversforassignment(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.ListDriversForAssignmentResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.ListDriversForAssignmentResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.ListDriversForAssignmentResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.ListDriversForAssignmentResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDriversforassignmentList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto_vorto_edge_edge_pb.DriverForAssignment.serializeBinaryToWriter
    );
  }
};


/**
 * repeated DriverForAssignment driversForAssignment = 1;
 * @return {!Array<!proto.vtv1.DriverForAssignment>}
 */
proto.vtv1.ListDriversForAssignmentResponse.prototype.getDriversforassignmentList = function() {
  return /** @type{!Array<!proto.vtv1.DriverForAssignment>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_vorto_edge_edge_pb.DriverForAssignment, 1));
};


/**
 * @param {!Array<!proto.vtv1.DriverForAssignment>} value
 * @return {!proto.vtv1.ListDriversForAssignmentResponse} returns this
*/
proto.vtv1.ListDriversForAssignmentResponse.prototype.setDriversforassignmentList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.vtv1.DriverForAssignment=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.DriverForAssignment}
 */
proto.vtv1.ListDriversForAssignmentResponse.prototype.addDriversforassignment = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.vtv1.DriverForAssignment, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.ListDriversForAssignmentResponse} returns this
 */
proto.vtv1.ListDriversForAssignmentResponse.prototype.clearDriversforassignmentList = function() {
  return this.setDriversforassignmentList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.DriverSearchRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.DriverSearchRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.DriverSearchRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.DriverSearchRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    searchParam: jspb.Message.getFieldWithDefault(msg, 1, ""),
    shipmentId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.DriverSearchRequest}
 */
proto.vtv1.DriverSearchRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.DriverSearchRequest;
  return proto.vtv1.DriverSearchRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.DriverSearchRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.DriverSearchRequest}
 */
proto.vtv1.DriverSearchRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSearchParam(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setShipmentId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.DriverSearchRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.DriverSearchRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.DriverSearchRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.DriverSearchRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSearchParam();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getShipmentId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string search_param = 1;
 * @return {string}
 */
proto.vtv1.DriverSearchRequest.prototype.getSearchParam = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.DriverSearchRequest} returns this
 */
proto.vtv1.DriverSearchRequest.prototype.setSearchParam = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string shipment_id = 2;
 * @return {string}
 */
proto.vtv1.DriverSearchRequest.prototype.getShipmentId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.DriverSearchRequest} returns this
 */
proto.vtv1.DriverSearchRequest.prototype.setShipmentId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.ListNodesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.ListNodesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.ListNodesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.ListNodesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.ListNodesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    nodesIdNameList: jspb.Message.toObjectList(msg.getNodesIdNameList(),
    proto_vorto_edge_edge_pb.EdgeNode.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.ListNodesResponse}
 */
proto.vtv1.ListNodesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.ListNodesResponse;
  return proto.vtv1.ListNodesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.ListNodesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.ListNodesResponse}
 */
proto.vtv1.ListNodesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_vorto_edge_edge_pb.EdgeNode;
      reader.readMessage(value,proto_vorto_edge_edge_pb.EdgeNode.deserializeBinaryFromReader);
      msg.addNodesIdName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.ListNodesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.ListNodesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.ListNodesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.ListNodesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNodesIdNameList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto_vorto_edge_edge_pb.EdgeNode.serializeBinaryToWriter
    );
  }
};


/**
 * repeated EdgeNode nodes_id_name = 1;
 * @return {!Array<!proto.vtv1.EdgeNode>}
 */
proto.vtv1.ListNodesResponse.prototype.getNodesIdNameList = function() {
  return /** @type{!Array<!proto.vtv1.EdgeNode>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_vorto_edge_edge_pb.EdgeNode, 1));
};


/**
 * @param {!Array<!proto.vtv1.EdgeNode>} value
 * @return {!proto.vtv1.ListNodesResponse} returns this
*/
proto.vtv1.ListNodesResponse.prototype.setNodesIdNameList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.vtv1.EdgeNode=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.EdgeNode}
 */
proto.vtv1.ListNodesResponse.prototype.addNodesIdName = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.vtv1.EdgeNode, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.ListNodesResponse} returns this
 */
proto.vtv1.ListNodesResponse.prototype.clearNodesIdNameList = function() {
  return this.setNodesIdNameList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.GetCalendarDataRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.GetCalendarDataRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.GetCalendarDataRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.GetCalendarDataRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    startTimestamp: jspb.Message.getFieldWithDefault(msg, 1, ""),
    numberOfDays: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.GetCalendarDataRequest}
 */
proto.vtv1.GetCalendarDataRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.GetCalendarDataRequest;
  return proto.vtv1.GetCalendarDataRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.GetCalendarDataRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.GetCalendarDataRequest}
 */
proto.vtv1.GetCalendarDataRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setStartTimestamp(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setNumberOfDays(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.GetCalendarDataRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.GetCalendarDataRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.GetCalendarDataRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.GetCalendarDataRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStartTimestamp();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getNumberOfDays();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * optional string start_timestamp = 1;
 * @return {string}
 */
proto.vtv1.GetCalendarDataRequest.prototype.getStartTimestamp = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.GetCalendarDataRequest} returns this
 */
proto.vtv1.GetCalendarDataRequest.prototype.setStartTimestamp = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 number_of_days = 2;
 * @return {number}
 */
proto.vtv1.GetCalendarDataRequest.prototype.getNumberOfDays = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.GetCalendarDataRequest} returns this
 */
proto.vtv1.GetCalendarDataRequest.prototype.setNumberOfDays = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.GetCalendarDataResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.GetCalendarDataResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.GetCalendarDataResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.GetCalendarDataResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    calendarData: (f = msg.getCalendarData()) && proto_vorto_edge_edge_pb.CalendarResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.GetCalendarDataResponse}
 */
proto.vtv1.GetCalendarDataResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.GetCalendarDataResponse;
  return proto.vtv1.GetCalendarDataResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.GetCalendarDataResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.GetCalendarDataResponse}
 */
proto.vtv1.GetCalendarDataResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_vorto_edge_edge_pb.CalendarResponse;
      reader.readMessage(value,proto_vorto_edge_edge_pb.CalendarResponse.deserializeBinaryFromReader);
      msg.setCalendarData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.GetCalendarDataResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.GetCalendarDataResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.GetCalendarDataResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.GetCalendarDataResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCalendarData();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_vorto_edge_edge_pb.CalendarResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional CalendarResponse calendar_data = 1;
 * @return {?proto.vtv1.CalendarResponse}
 */
proto.vtv1.GetCalendarDataResponse.prototype.getCalendarData = function() {
  return /** @type{?proto.vtv1.CalendarResponse} */ (
    jspb.Message.getWrapperField(this, proto_vorto_edge_edge_pb.CalendarResponse, 1));
};


/**
 * @param {?proto.vtv1.CalendarResponse|undefined} value
 * @return {!proto.vtv1.GetCalendarDataResponse} returns this
*/
proto.vtv1.GetCalendarDataResponse.prototype.setCalendarData = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.GetCalendarDataResponse} returns this
 */
proto.vtv1.GetCalendarDataResponse.prototype.clearCalendarData = function() {
  return this.setCalendarData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.GetCalendarDataResponse.prototype.hasCalendarData = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.AddShiftForDriverRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.AddShiftForDriverRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.AddShiftForDriverRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.AddShiftForDriverRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    driverId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    startsAt: jspb.Message.getFieldWithDefault(msg, 2, ""),
    shiftLengthMinutes: jspb.Message.getFieldWithDefault(msg, 3, 0),
    request: (f = msg.getRequest()) && proto.vtv1.GetCalendarDataRequest.toObject(includeInstance, f),
    trailerTypeId: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.AddShiftForDriverRequest}
 */
proto.vtv1.AddShiftForDriverRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.AddShiftForDriverRequest;
  return proto.vtv1.AddShiftForDriverRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.AddShiftForDriverRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.AddShiftForDriverRequest}
 */
proto.vtv1.AddShiftForDriverRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDriverId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setStartsAt(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setShiftLengthMinutes(value);
      break;
    case 4:
      var value = new proto.vtv1.GetCalendarDataRequest;
      reader.readMessage(value,proto.vtv1.GetCalendarDataRequest.deserializeBinaryFromReader);
      msg.setRequest(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTrailerTypeId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.AddShiftForDriverRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.AddShiftForDriverRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.AddShiftForDriverRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.AddShiftForDriverRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDriverId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStartsAt();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getShiftLengthMinutes();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getRequest();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.vtv1.GetCalendarDataRequest.serializeBinaryToWriter
    );
  }
  f = message.getTrailerTypeId();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
};


/**
 * optional string driver_id = 1;
 * @return {string}
 */
proto.vtv1.AddShiftForDriverRequest.prototype.getDriverId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.AddShiftForDriverRequest} returns this
 */
proto.vtv1.AddShiftForDriverRequest.prototype.setDriverId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string starts_at = 2;
 * @return {string}
 */
proto.vtv1.AddShiftForDriverRequest.prototype.getStartsAt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.AddShiftForDriverRequest} returns this
 */
proto.vtv1.AddShiftForDriverRequest.prototype.setStartsAt = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 shift_length_minutes = 3;
 * @return {number}
 */
proto.vtv1.AddShiftForDriverRequest.prototype.getShiftLengthMinutes = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.AddShiftForDriverRequest} returns this
 */
proto.vtv1.AddShiftForDriverRequest.prototype.setShiftLengthMinutes = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional GetCalendarDataRequest request = 4;
 * @return {?proto.vtv1.GetCalendarDataRequest}
 */
proto.vtv1.AddShiftForDriverRequest.prototype.getRequest = function() {
  return /** @type{?proto.vtv1.GetCalendarDataRequest} */ (
    jspb.Message.getWrapperField(this, proto.vtv1.GetCalendarDataRequest, 4));
};


/**
 * @param {?proto.vtv1.GetCalendarDataRequest|undefined} value
 * @return {!proto.vtv1.AddShiftForDriverRequest} returns this
*/
proto.vtv1.AddShiftForDriverRequest.prototype.setRequest = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.AddShiftForDriverRequest} returns this
 */
proto.vtv1.AddShiftForDriverRequest.prototype.clearRequest = function() {
  return this.setRequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.AddShiftForDriverRequest.prototype.hasRequest = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional int64 trailer_type_id = 5;
 * @return {number}
 */
proto.vtv1.AddShiftForDriverRequest.prototype.getTrailerTypeId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.AddShiftForDriverRequest} returns this
 */
proto.vtv1.AddShiftForDriverRequest.prototype.setTrailerTypeId = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.EditShiftForDriverRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.EditShiftForDriverRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.EditShiftForDriverRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.EditShiftForDriverRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    driverShiftId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    startsAt: jspb.Message.getFieldWithDefault(msg, 2, ""),
    shiftLengthMinutes: jspb.Message.getFieldWithDefault(msg, 3, 0),
    request: (f = msg.getRequest()) && proto.vtv1.GetCalendarDataRequest.toObject(includeInstance, f),
    trailerTypeId: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.EditShiftForDriverRequest}
 */
proto.vtv1.EditShiftForDriverRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.EditShiftForDriverRequest;
  return proto.vtv1.EditShiftForDriverRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.EditShiftForDriverRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.EditShiftForDriverRequest}
 */
proto.vtv1.EditShiftForDriverRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDriverShiftId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setStartsAt(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setShiftLengthMinutes(value);
      break;
    case 4:
      var value = new proto.vtv1.GetCalendarDataRequest;
      reader.readMessage(value,proto.vtv1.GetCalendarDataRequest.deserializeBinaryFromReader);
      msg.setRequest(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTrailerTypeId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.EditShiftForDriverRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.EditShiftForDriverRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.EditShiftForDriverRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.EditShiftForDriverRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDriverShiftId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getStartsAt();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getShiftLengthMinutes();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getRequest();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.vtv1.GetCalendarDataRequest.serializeBinaryToWriter
    );
  }
  f = message.getTrailerTypeId();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
};


/**
 * optional int64 driver_shift_id = 1;
 * @return {number}
 */
proto.vtv1.EditShiftForDriverRequest.prototype.getDriverShiftId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.EditShiftForDriverRequest} returns this
 */
proto.vtv1.EditShiftForDriverRequest.prototype.setDriverShiftId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string starts_at = 2;
 * @return {string}
 */
proto.vtv1.EditShiftForDriverRequest.prototype.getStartsAt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.EditShiftForDriverRequest} returns this
 */
proto.vtv1.EditShiftForDriverRequest.prototype.setStartsAt = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 shift_length_minutes = 3;
 * @return {number}
 */
proto.vtv1.EditShiftForDriverRequest.prototype.getShiftLengthMinutes = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.EditShiftForDriverRequest} returns this
 */
proto.vtv1.EditShiftForDriverRequest.prototype.setShiftLengthMinutes = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional GetCalendarDataRequest request = 4;
 * @return {?proto.vtv1.GetCalendarDataRequest}
 */
proto.vtv1.EditShiftForDriverRequest.prototype.getRequest = function() {
  return /** @type{?proto.vtv1.GetCalendarDataRequest} */ (
    jspb.Message.getWrapperField(this, proto.vtv1.GetCalendarDataRequest, 4));
};


/**
 * @param {?proto.vtv1.GetCalendarDataRequest|undefined} value
 * @return {!proto.vtv1.EditShiftForDriverRequest} returns this
*/
proto.vtv1.EditShiftForDriverRequest.prototype.setRequest = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.EditShiftForDriverRequest} returns this
 */
proto.vtv1.EditShiftForDriverRequest.prototype.clearRequest = function() {
  return this.setRequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.EditShiftForDriverRequest.prototype.hasRequest = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional int64 trailer_type_id = 5;
 * @return {number}
 */
proto.vtv1.EditShiftForDriverRequest.prototype.getTrailerTypeId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.EditShiftForDriverRequest} returns this
 */
proto.vtv1.EditShiftForDriverRequest.prototype.setTrailerTypeId = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.DeleteShiftForDriverRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.DeleteShiftForDriverRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.DeleteShiftForDriverRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.DeleteShiftForDriverRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    driverShiftId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    request: (f = msg.getRequest()) && proto.vtv1.GetCalendarDataRequest.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.DeleteShiftForDriverRequest}
 */
proto.vtv1.DeleteShiftForDriverRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.DeleteShiftForDriverRequest;
  return proto.vtv1.DeleteShiftForDriverRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.DeleteShiftForDriverRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.DeleteShiftForDriverRequest}
 */
proto.vtv1.DeleteShiftForDriverRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDriverShiftId(value);
      break;
    case 2:
      var value = new proto.vtv1.GetCalendarDataRequest;
      reader.readMessage(value,proto.vtv1.GetCalendarDataRequest.deserializeBinaryFromReader);
      msg.setRequest(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.DeleteShiftForDriverRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.DeleteShiftForDriverRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.DeleteShiftForDriverRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.DeleteShiftForDriverRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDriverShiftId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getRequest();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.vtv1.GetCalendarDataRequest.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 driver_shift_id = 1;
 * @return {number}
 */
proto.vtv1.DeleteShiftForDriverRequest.prototype.getDriverShiftId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.DeleteShiftForDriverRequest} returns this
 */
proto.vtv1.DeleteShiftForDriverRequest.prototype.setDriverShiftId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional GetCalendarDataRequest request = 2;
 * @return {?proto.vtv1.GetCalendarDataRequest}
 */
proto.vtv1.DeleteShiftForDriverRequest.prototype.getRequest = function() {
  return /** @type{?proto.vtv1.GetCalendarDataRequest} */ (
    jspb.Message.getWrapperField(this, proto.vtv1.GetCalendarDataRequest, 2));
};


/**
 * @param {?proto.vtv1.GetCalendarDataRequest|undefined} value
 * @return {!proto.vtv1.DeleteShiftForDriverRequest} returns this
*/
proto.vtv1.DeleteShiftForDriverRequest.prototype.setRequest = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.DeleteShiftForDriverRequest} returns this
 */
proto.vtv1.DeleteShiftForDriverRequest.prototype.clearRequest = function() {
  return this.setRequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.DeleteShiftForDriverRequest.prototype.hasRequest = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.ListEdgeContractsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.ListEdgeContractsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.ListEdgeContractsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.ListEdgeContractsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    listArchived: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.ListEdgeContractsRequest}
 */
proto.vtv1.ListEdgeContractsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.ListEdgeContractsRequest;
  return proto.vtv1.ListEdgeContractsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.ListEdgeContractsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.ListEdgeContractsRequest}
 */
proto.vtv1.ListEdgeContractsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setListArchived(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.ListEdgeContractsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.ListEdgeContractsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.ListEdgeContractsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.ListEdgeContractsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getListArchived();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool list_archived = 1;
 * @return {boolean}
 */
proto.vtv1.ListEdgeContractsRequest.prototype.getListArchived = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vtv1.ListEdgeContractsRequest} returns this
 */
proto.vtv1.ListEdgeContractsRequest.prototype.setListArchived = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.ToggleAutoDispatchRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.ToggleAutoDispatchRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.ToggleAutoDispatchRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.ToggleAutoDispatchRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    enabled: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    request: (f = msg.getRequest()) && proto.vtv1.GetCalendarDataRequest.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.ToggleAutoDispatchRequest}
 */
proto.vtv1.ToggleAutoDispatchRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.ToggleAutoDispatchRequest;
  return proto.vtv1.ToggleAutoDispatchRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.ToggleAutoDispatchRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.ToggleAutoDispatchRequest}
 */
proto.vtv1.ToggleAutoDispatchRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEnabled(value);
      break;
    case 2:
      var value = new proto.vtv1.GetCalendarDataRequest;
      reader.readMessage(value,proto.vtv1.GetCalendarDataRequest.deserializeBinaryFromReader);
      msg.setRequest(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.ToggleAutoDispatchRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.ToggleAutoDispatchRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.ToggleAutoDispatchRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.ToggleAutoDispatchRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEnabled();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getRequest();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.vtv1.GetCalendarDataRequest.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool enabled = 1;
 * @return {boolean}
 */
proto.vtv1.ToggleAutoDispatchRequest.prototype.getEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vtv1.ToggleAutoDispatchRequest} returns this
 */
proto.vtv1.ToggleAutoDispatchRequest.prototype.setEnabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional GetCalendarDataRequest request = 2;
 * @return {?proto.vtv1.GetCalendarDataRequest}
 */
proto.vtv1.ToggleAutoDispatchRequest.prototype.getRequest = function() {
  return /** @type{?proto.vtv1.GetCalendarDataRequest} */ (
    jspb.Message.getWrapperField(this, proto.vtv1.GetCalendarDataRequest, 2));
};


/**
 * @param {?proto.vtv1.GetCalendarDataRequest|undefined} value
 * @return {!proto.vtv1.ToggleAutoDispatchRequest} returns this
*/
proto.vtv1.ToggleAutoDispatchRequest.prototype.setRequest = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.ToggleAutoDispatchRequest} returns this
 */
proto.vtv1.ToggleAutoDispatchRequest.prototype.clearRequest = function() {
  return this.setRequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.ToggleAutoDispatchRequest.prototype.hasRequest = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.UpdateShipmentRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.UpdateShipmentRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.UpdateShipmentRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.UpdateShipmentRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    shipmentId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    bolNumber: (f = msg.getBolNumber()) && proto_vorto_types_types_pb.NullableString.toObject(includeInstance, f),
    ticketNumber: (f = msg.getTicketNumber()) && proto_vorto_types_types_pb.NullableString.toObject(includeInstance, f),
    actualQuantityMap: (f = msg.getActualQuantityMap()) ? f.toObject(includeInstance, proto.vtv1.NullableDouble.toObject) : [],
    description: (f = msg.getDescription()) && proto_vorto_types_types_pb.NullableString.toObject(includeInstance, f),
    truckNumber: (f = msg.getTruckNumber()) && proto_vorto_types_types_pb.NullableString.toObject(includeInstance, f),
    trailerNumber: (f = msg.getTrailerNumber()) && proto_vorto_types_types_pb.NullableString.toObject(includeInstance, f),
    returnTrailer: (f = msg.getReturnTrailer()) && proto_vorto_types_types_pb.NullableString.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.UpdateShipmentRequest}
 */
proto.vtv1.UpdateShipmentRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.UpdateShipmentRequest;
  return proto.vtv1.UpdateShipmentRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.UpdateShipmentRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.UpdateShipmentRequest}
 */
proto.vtv1.UpdateShipmentRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setShipmentId(value);
      break;
    case 2:
      var value = new proto_vorto_types_types_pb.NullableString;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableString.deserializeBinaryFromReader);
      msg.setBolNumber(value);
      break;
    case 3:
      var value = new proto_vorto_types_types_pb.NullableString;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableString.deserializeBinaryFromReader);
      msg.setTicketNumber(value);
      break;
    case 4:
      var value = msg.getActualQuantityMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.vtv1.NullableDouble.deserializeBinaryFromReader, "", new proto.vtv1.NullableDouble());
         });
      break;
    case 5:
      var value = new proto_vorto_types_types_pb.NullableString;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableString.deserializeBinaryFromReader);
      msg.setDescription(value);
      break;
    case 6:
      var value = new proto_vorto_types_types_pb.NullableString;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableString.deserializeBinaryFromReader);
      msg.setTruckNumber(value);
      break;
    case 7:
      var value = new proto_vorto_types_types_pb.NullableString;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableString.deserializeBinaryFromReader);
      msg.setTrailerNumber(value);
      break;
    case 8:
      var value = new proto_vorto_types_types_pb.NullableString;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableString.deserializeBinaryFromReader);
      msg.setReturnTrailer(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.UpdateShipmentRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.UpdateShipmentRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.UpdateShipmentRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.UpdateShipmentRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getShipmentId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBolNumber();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_vorto_types_types_pb.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getTicketNumber();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto_vorto_types_types_pb.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getActualQuantityMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(4, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.vtv1.NullableDouble.serializeBinaryToWriter);
  }
  f = message.getDescription();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto_vorto_types_types_pb.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getTruckNumber();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto_vorto_types_types_pb.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getTrailerNumber();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto_vorto_types_types_pb.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getReturnTrailer();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto_vorto_types_types_pb.NullableString.serializeBinaryToWriter
    );
  }
};


/**
 * optional string shipment_id = 1;
 * @return {string}
 */
proto.vtv1.UpdateShipmentRequest.prototype.getShipmentId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.UpdateShipmentRequest} returns this
 */
proto.vtv1.UpdateShipmentRequest.prototype.setShipmentId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional NullableString bol_number = 2;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.UpdateShipmentRequest.prototype.getBolNumber = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableString, 2));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.UpdateShipmentRequest} returns this
*/
proto.vtv1.UpdateShipmentRequest.prototype.setBolNumber = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.UpdateShipmentRequest} returns this
 */
proto.vtv1.UpdateShipmentRequest.prototype.clearBolNumber = function() {
  return this.setBolNumber(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.UpdateShipmentRequest.prototype.hasBolNumber = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional NullableString ticket_number = 3;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.UpdateShipmentRequest.prototype.getTicketNumber = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableString, 3));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.UpdateShipmentRequest} returns this
*/
proto.vtv1.UpdateShipmentRequest.prototype.setTicketNumber = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.UpdateShipmentRequest} returns this
 */
proto.vtv1.UpdateShipmentRequest.prototype.clearTicketNumber = function() {
  return this.setTicketNumber(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.UpdateShipmentRequest.prototype.hasTicketNumber = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * map<string, NullableDouble> actual_quantity = 4;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.vtv1.NullableDouble>}
 */
proto.vtv1.UpdateShipmentRequest.prototype.getActualQuantityMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.vtv1.NullableDouble>} */ (
      jspb.Message.getMapField(this, 4, opt_noLazyCreate,
      proto.vtv1.NullableDouble));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.vtv1.UpdateShipmentRequest} returns this
 */
proto.vtv1.UpdateShipmentRequest.prototype.clearActualQuantityMap = function() {
  this.getActualQuantityMap().clear();
  return this;};


/**
 * optional NullableString description = 5;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.UpdateShipmentRequest.prototype.getDescription = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableString, 5));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.UpdateShipmentRequest} returns this
*/
proto.vtv1.UpdateShipmentRequest.prototype.setDescription = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.UpdateShipmentRequest} returns this
 */
proto.vtv1.UpdateShipmentRequest.prototype.clearDescription = function() {
  return this.setDescription(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.UpdateShipmentRequest.prototype.hasDescription = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional NullableString truck_number = 6;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.UpdateShipmentRequest.prototype.getTruckNumber = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableString, 6));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.UpdateShipmentRequest} returns this
*/
proto.vtv1.UpdateShipmentRequest.prototype.setTruckNumber = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.UpdateShipmentRequest} returns this
 */
proto.vtv1.UpdateShipmentRequest.prototype.clearTruckNumber = function() {
  return this.setTruckNumber(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.UpdateShipmentRequest.prototype.hasTruckNumber = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional NullableString trailer_number = 7;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.UpdateShipmentRequest.prototype.getTrailerNumber = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableString, 7));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.UpdateShipmentRequest} returns this
*/
proto.vtv1.UpdateShipmentRequest.prototype.setTrailerNumber = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.UpdateShipmentRequest} returns this
 */
proto.vtv1.UpdateShipmentRequest.prototype.clearTrailerNumber = function() {
  return this.setTrailerNumber(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.UpdateShipmentRequest.prototype.hasTrailerNumber = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional NullableString return_trailer = 8;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.UpdateShipmentRequest.prototype.getReturnTrailer = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableString, 8));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.UpdateShipmentRequest} returns this
*/
proto.vtv1.UpdateShipmentRequest.prototype.setReturnTrailer = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.UpdateShipmentRequest} returns this
 */
proto.vtv1.UpdateShipmentRequest.prototype.clearReturnTrailer = function() {
  return this.setReturnTrailer(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.UpdateShipmentRequest.prototype.hasReturnTrailer = function() {
  return jspb.Message.getField(this, 8) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.AssignShipmentAndCreateShiftRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.AssignShipmentAndCreateShiftRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.AssignShipmentAndCreateShiftRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.AssignShipmentAndCreateShiftRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    shipmentId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    driverId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.AssignShipmentAndCreateShiftRequest}
 */
proto.vtv1.AssignShipmentAndCreateShiftRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.AssignShipmentAndCreateShiftRequest;
  return proto.vtv1.AssignShipmentAndCreateShiftRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.AssignShipmentAndCreateShiftRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.AssignShipmentAndCreateShiftRequest}
 */
proto.vtv1.AssignShipmentAndCreateShiftRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setShipmentId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDriverId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.AssignShipmentAndCreateShiftRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.AssignShipmentAndCreateShiftRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.AssignShipmentAndCreateShiftRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.AssignShipmentAndCreateShiftRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getShipmentId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDriverId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string shipment_id = 1;
 * @return {string}
 */
proto.vtv1.AssignShipmentAndCreateShiftRequest.prototype.getShipmentId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.AssignShipmentAndCreateShiftRequest} returns this
 */
proto.vtv1.AssignShipmentAndCreateShiftRequest.prototype.setShipmentId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string driver_id = 2;
 * @return {string}
 */
proto.vtv1.AssignShipmentAndCreateShiftRequest.prototype.getDriverId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.AssignShipmentAndCreateShiftRequest} returns this
 */
proto.vtv1.AssignShipmentAndCreateShiftRequest.prototype.setDriverId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.AssignShipmentAndCreateShiftResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.AssignShipmentAndCreateShiftResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.AssignShipmentAndCreateShiftResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.AssignShipmentAndCreateShiftResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    conflictingShifts: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    conflictingDeadlines: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.AssignShipmentAndCreateShiftResponse}
 */
proto.vtv1.AssignShipmentAndCreateShiftResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.AssignShipmentAndCreateShiftResponse;
  return proto.vtv1.AssignShipmentAndCreateShiftResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.AssignShipmentAndCreateShiftResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.AssignShipmentAndCreateShiftResponse}
 */
proto.vtv1.AssignShipmentAndCreateShiftResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setConflictingShifts(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setConflictingDeadlines(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.AssignShipmentAndCreateShiftResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.AssignShipmentAndCreateShiftResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.AssignShipmentAndCreateShiftResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.AssignShipmentAndCreateShiftResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getConflictingShifts();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getConflictingDeadlines();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.vtv1.AssignShipmentAndCreateShiftResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vtv1.AssignShipmentAndCreateShiftResponse} returns this
 */
proto.vtv1.AssignShipmentAndCreateShiftResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional bool conflicting_shifts = 2;
 * @return {boolean}
 */
proto.vtv1.AssignShipmentAndCreateShiftResponse.prototype.getConflictingShifts = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vtv1.AssignShipmentAndCreateShiftResponse} returns this
 */
proto.vtv1.AssignShipmentAndCreateShiftResponse.prototype.setConflictingShifts = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional bool conflicting_deadlines = 3;
 * @return {boolean}
 */
proto.vtv1.AssignShipmentAndCreateShiftResponse.prototype.getConflictingDeadlines = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vtv1.AssignShipmentAndCreateShiftResponse} returns this
 */
proto.vtv1.AssignShipmentAndCreateShiftResponse.prototype.setConflictingDeadlines = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


goog.object.extend(exports, proto.vtv1);
