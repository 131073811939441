// package: vtv1
// file: proto/vorto/global/global_api.proto

var proto_vorto_global_global_api_pb = require("../../../proto/vorto/global/global_api_pb");
var grpc = require("@improbable-eng/grpc-web").grpc;

var GlobalService = (function () {
  function GlobalService() {}
  GlobalService.serviceName = "vtv1.GlobalService";
  return GlobalService;
}());

GlobalService.ListTrailerTypes = {
  methodName: "ListTrailerTypes",
  service: GlobalService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_global_global_api_pb.ListTrailerTypesRequest,
  responseType: proto_vorto_global_global_api_pb.ListTrailerTypesResponse
};

GlobalService.ListDriverCertifications = {
  methodName: "ListDriverCertifications",
  service: GlobalService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_global_global_api_pb.ListDriverCertificationsRequest,
  responseType: proto_vorto_global_global_api_pb.ListDriverCertificationsResponse
};

exports.GlobalService = GlobalService;

function GlobalServiceClient(serviceHost, options) {
  this.serviceHost = serviceHost;
  this.options = options || {};
}

GlobalServiceClient.prototype.listTrailerTypes = function listTrailerTypes(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(GlobalService.ListTrailerTypes, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

GlobalServiceClient.prototype.listDriverCertifications = function listDriverCertifications(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(GlobalService.ListDriverCertifications, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

exports.GlobalServiceClient = GlobalServiceClient;

