import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, race, timer } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { filter, map, mapTo, take, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(private auth: AuthService) {}

  public canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return race([
      this.auth.isLoggedIn$.pipe(filter((loggedIn) => loggedIn)),
      timer(1000).pipe(take(1), mapTo(false)),
    ]).pipe(
      tap((loggedIn) => {
        // if (!loggedIn) {
        //   this.auth.login(state.url);
        // }
      }),
    );
  }
}
