import { Pipe, PipeTransform } from '@angular/core';
import { ShipmentProgressStatusMap } from '~proto/types/types_pb';

const prettyNameMap: Record<ShipmentProgressStatusMap[keyof ShipmentProgressStatusMap], string> = {
  0: 'Unknown',
  1: 'Scheduled',
  2: 'Started',
  3: 'At Origin',
  4: 'En Route',
  5: 'At Destination',
  6: 'Completed',
  7: 'At Carrier',
  8: 'At Supplier',
};

@Pipe({
  name: 'shipmentProgressStatus',
})
export class ShipmentProgressStatusPipe implements PipeTransform {
  public transform(status: ShipmentProgressStatusMap[keyof ShipmentProgressStatusMap]): string {
    return prettyNameMap[status];
  }
}
