import { ChangeDetectorRef, Directive, ElementRef, Renderer2, TemplateRef, ViewContainerRef } from '@angular/core';
import { InitializationService } from '../../singleton/services/initialization.service';
import { CanDisable } from '@angular/material/core';
import { filter, pluck, take } from 'rxjs/operators';
import { lastValueFrom } from 'rxjs';

@Directive({
  selector: '[vtNotViewOnly]',
})
export class NotViewOnlyDirective {
  constructor(
    private userService: InitializationService,
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private cd: ChangeDetectorRef,
  ) {
    this.checkIfDisabled();
  }

  private async checkIfDisabled() {
    const user = await lastValueFrom(this.userService.myUserInfo$.pipe(take(1)));

    if (user?.viewOnly) {
      this.viewContainer.clear();
      this.cd.markForCheck();
    } else {
      this.viewContainer.createEmbeddedView(this.templateRef);
      this.cd.markForCheck();
    }
  }
}
