// package: vtv1
// file: proto/vorto/node/node_api.proto

var proto_vorto_node_node_api_pb = require("../../../proto/vorto/node/node_api_pb");
var proto_vorto_node_node_pb = require("../../../proto/vorto/node/node_pb");
var proto_vorto_types_types_pb = require("../../../proto/vorto/types/types_pb");
var grpc = require("@improbable-eng/grpc-web").grpc;

var NodeService = (function () {
  function NodeService() {}
  NodeService.serviceName = "vtv1.NodeService";
  return NodeService;
}());

NodeService.ListSupplierSitesForCreateOrder = {
  methodName: "ListSupplierSitesForCreateOrder",
  service: NodeService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_node_node_api_pb.ListSupplierSitesForCreateOrderRequest,
  responseType: proto_vorto_node_node_api_pb.ListSupplierSitesForCreateOrderResponse
};

NodeService.ListPurchaseOrdersForCreateOrder = {
  methodName: "ListPurchaseOrdersForCreateOrder",
  service: NodeService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_node_node_api_pb.ListPurchaseOrdersForCreateOrderRequest,
  responseType: proto_vorto_node_node_api_pb.PotentialPurchaseOrdersResponse
};

NodeService.ListEdgeContractsForCreateOrders = {
  methodName: "ListEdgeContractsForCreateOrders",
  service: NodeService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_node_node_api_pb.ListEdgeContractsForCreateOrdersRequest,
  responseType: proto_vorto_node_node_api_pb.ListEdgeContractsForCreateOrdersResponse
};

NodeService.ListPurchaseOrders = {
  methodName: "ListPurchaseOrders",
  service: NodeService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_node_node_api_pb.ListPurchaseOrdersRequest,
  responseType: proto_vorto_node_node_api_pb.ListPurchaseOrdersResponse
};

NodeService.ListPurchaseOrdersAsSupplier = {
  methodName: "ListPurchaseOrdersAsSupplier",
  service: NodeService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_types_types_pb.Empty,
  responseType: proto_vorto_node_node_api_pb.ListPurchaseOrdersAsSupplierResponse
};

NodeService.NodeListAtSupplierOrders = {
  methodName: "NodeListAtSupplierOrders",
  service: NodeService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_types_types_pb.Empty,
  responseType: proto_vorto_node_node_api_pb.NodeListAtSupplierOrdersResponse
};

NodeService.NodeListAtEdgeOrders = {
  methodName: "NodeListAtEdgeOrders",
  service: NodeService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_types_types_pb.Empty,
  responseType: proto_vorto_node_node_api_pb.NodeListAtEdgeOrdersResponse
};

NodeService.NodeListScheduledOrders = {
  methodName: "NodeListScheduledOrders",
  service: NodeService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_types_types_pb.Empty,
  responseType: proto_vorto_node_node_api_pb.NodeListScheduledOrdersResponse
};

NodeService.NodeListInProgressOrders = {
  methodName: "NodeListInProgressOrders",
  service: NodeService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_types_types_pb.Empty,
  responseType: proto_vorto_node_node_api_pb.NodeListInProgressOrdersResponse
};

NodeService.NodeListArrivedOrders = {
  methodName: "NodeListArrivedOrders",
  service: NodeService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_types_types_pb.Empty,
  responseType: proto_vorto_node_node_api_pb.NodeListArrivedOrdersResponse
};

NodeService.NodeListCancelledOrders = {
  methodName: "NodeListCancelledOrders",
  service: NodeService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_node_node_api_pb.NodeFinalizedOrdersRequest,
  responseType: proto_vorto_node_node_api_pb.NodeListFinalStateOrdersResponse
};

NodeService.NodeListCompletedOrders = {
  methodName: "NodeListCompletedOrders",
  service: NodeService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_node_node_api_pb.NodeFinalizedOrdersRequest,
  responseType: proto_vorto_node_node_api_pb.NodeListFinalStateOrdersResponse
};

NodeService.CreateOrder = {
  methodName: "CreateOrder",
  service: NodeService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_node_node_api_pb.CreateOrderRequest,
  responseType: proto_vorto_node_node_api_pb.CreateOrderResponse
};

NodeService.CreateV2Order = {
  methodName: "CreateV2Order",
  service: NodeService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_node_node_api_pb.CreateMultiStopOrderRequest,
  responseType: proto_vorto_node_node_api_pb.CreateOrderResponse
};

NodeService.GetPurchaseOrdersForCreateV2Order = {
  methodName: "GetPurchaseOrdersForCreateV2Order",
  service: NodeService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_node_node_api_pb.GetPurchaseOrdersForCreateV2OrderRequest,
  responseType: proto_vorto_node_node_api_pb.PotentialPurchaseOrdersV2Response
};

NodeService.GetRouteGuidesForCreateOrderV2 = {
  methodName: "GetRouteGuidesForCreateOrderV2",
  service: NodeService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_node_node_api_pb.GetRouteGuidesForCreateOrderV2Request,
  responseType: proto_vorto_node_node_api_pb.GetRouteGuidesForCreateOrderV2Response
};

NodeService.GetCarrierContractsForCreateOrderV2 = {
  methodName: "GetCarrierContractsForCreateOrderV2",
  service: NodeService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_node_node_api_pb.GetCarrierContractsForCreateOrderV2Request,
  responseType: proto_vorto_node_node_api_pb.GetCarrierContractsForCreateOrderV2Response
};

NodeService.GetInventoriesForSiteForCreateOrderV2 = {
  methodName: "GetInventoriesForSiteForCreateOrderV2",
  service: NodeService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_node_node_api_pb.GetInventoriesForSiteForCreateOrderV2Request,
  responseType: proto_vorto_node_node_api_pb.GetInventoriesForSiteForCreateOrderV2Response
};

NodeService.CancelOrder = {
  methodName: "CancelOrder",
  service: NodeService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_node_node_api_pb.CancelOrderRequest,
  responseType: proto_vorto_types_types_pb.Empty
};

NodeService.CancelMultipleOrders = {
  methodName: "CancelMultipleOrders",
  service: NodeService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_node_node_api_pb.CancelMultipleOrdersRequestNode,
  responseType: proto_vorto_types_types_pb.Empty
};

NodeService.OrderUpload = {
  methodName: "OrderUpload",
  service: NodeService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_node_node_api_pb.OrderUploadRequest,
  responseType: proto_vorto_node_node_api_pb.OrderUploadResponse
};

NodeService.PlaceUploadedOrders = {
  methodName: "PlaceUploadedOrders",
  service: NodeService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_node_node_api_pb.OrderUploadResponse,
  responseType: proto_vorto_types_types_pb.StringId
};

NodeService.NodeListSupplierSites = {
  methodName: "NodeListSupplierSites",
  service: NodeService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_node_node_api_pb.NodeListSupplierSitesRequest,
  responseType: proto_vorto_node_node_api_pb.NodeListSupplierSitesResponse
};

NodeService.NodeListProducts = {
  methodName: "NodeListProducts",
  service: NodeService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_types_types_pb.Empty,
  responseType: proto_vorto_node_node_api_pb.NodeListProductsResponse
};

NodeService.NodeListArchivedProducts = {
  methodName: "NodeListArchivedProducts",
  service: NodeService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_types_types_pb.Empty,
  responseType: proto_vorto_node_node_api_pb.NodeListProductsResponse
};

NodeService.NodeListProductsForSite = {
  methodName: "NodeListProductsForSite",
  service: NodeService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_types_types_pb.StringId,
  responseType: proto_vorto_node_node_api_pb.NodeListProductsResponse
};

NodeService.NodeCreatePurchaseOrder = {
  methodName: "NodeCreatePurchaseOrder",
  service: NodeService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_node_node_api_pb.NodeCreatePurchaseOrderRequest,
  responseType: proto_vorto_node_node_api_pb.SinglePurchaseOrderResponse
};

NodeService.NodeEditPurchaseOrder = {
  methodName: "NodeEditPurchaseOrder",
  service: NodeService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_node_node_api_pb.NodeEditPurchaseOrderRequest,
  responseType: proto_vorto_node_node_api_pb.SinglePurchaseOrderResponse
};

NodeService.NodeClosePurchaseOrder = {
  methodName: "NodeClosePurchaseOrder",
  service: NodeService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_types_types_pb.StringId,
  responseType: proto_vorto_types_types_pb.Empty
};

NodeService.NodeConfirmPurchaseOrder = {
  methodName: "NodeConfirmPurchaseOrder",
  service: NodeService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_node_node_api_pb.NodeConfirmOrDeclinePurchaseOrder,
  responseType: proto_vorto_node_node_api_pb.SinglePurchaseOrderResponse
};

NodeService.NodeGetPurchaseOrder = {
  methodName: "NodeGetPurchaseOrder",
  service: NodeService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_types_types_pb.StringId,
  responseType: proto_vorto_node_node_api_pb.SinglePurchaseOrderResponse
};

NodeService.NodeUpdatePOBillable = {
  methodName: "NodeUpdatePOBillable",
  service: NodeService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_node_node_api_pb.NodeUpdatePOBillableRequest,
  responseType: proto_vorto_types_types_pb.Empty
};

NodeService.ListInNetworkSuppliers = {
  methodName: "ListInNetworkSuppliers",
  service: NodeService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_node_node_api_pb.ListInNetworkSuppliersRequest,
  responseType: proto_vorto_node_node_api_pb.ListInNetworkSuppliersResponse
};

NodeService.ListInNetworkCustomers = {
  methodName: "ListInNetworkCustomers",
  service: NodeService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_types_types_pb.Empty,
  responseType: proto_vorto_node_node_api_pb.ListInNetworkCustomersResponse
};

NodeService.SearchOutNetworkSuppliersByName = {
  methodName: "SearchOutNetworkSuppliersByName",
  service: NodeService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_node_node_api_pb.SearchOutNetworkSuppliersByNameRequest,
  responseType: proto_vorto_node_node_api_pb.SearchOutNetworkSuppliersByNameResponse
};

NodeService.SearchOutNetworkCustomersByName = {
  methodName: "SearchOutNetworkCustomersByName",
  service: NodeService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_node_node_api_pb.SearchOutNetworkSuppliersByNameRequest,
  responseType: proto_vorto_node_node_api_pb.SearchOutNetworkSuppliersByNameResponse
};

NodeService.GetSupplierContracts = {
  methodName: "GetSupplierContracts",
  service: NodeService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_types_types_pb.Empty,
  responseType: proto_vorto_node_node_api_pb.GetSupplierContractsResponse
};

NodeService.AddSupplierContract = {
  methodName: "AddSupplierContract",
  service: NodeService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_node_node_api_pb.AddSupplierContractRequest,
  responseType: proto_vorto_node_node_api_pb.AddSupplierContractResponse
};

NodeService.EditSupplierContract = {
  methodName: "EditSupplierContract",
  service: NodeService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_node_node_api_pb.EditSupplierContractRequest,
  responseType: proto_vorto_node_node_api_pb.EditSupplierContractResponse
};

NodeService.RemoveSupplierContract = {
  methodName: "RemoveSupplierContract",
  service: NodeService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_node_node_api_pb.RemoveSupplierContractRequest,
  responseType: proto_vorto_node_node_api_pb.RemoveSupplierContractResponse
};

NodeService.AddCustomerToNetwork = {
  methodName: "AddCustomerToNetwork",
  service: NodeService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_types_types_pb.StringId,
  responseType: proto_vorto_node_node_api_pb.ListInNetworkCustomersResponse
};

NodeService.RemoveCustomerFromNetwork = {
  methodName: "RemoveCustomerFromNetwork",
  service: NodeService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_types_types_pb.StringId,
  responseType: proto_vorto_node_node_api_pb.ListInNetworkCustomersResponse
};

NodeService.AddSiteGroup = {
  methodName: "AddSiteGroup",
  service: NodeService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_node_node_api_pb.AddSiteGroupRequest,
  responseType: proto_vorto_node_node_api_pb.AddSiteGroupResponse
};

NodeService.RemoveSiteGroup = {
  methodName: "RemoveSiteGroup",
  service: NodeService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_node_node_api_pb.RemoveSiteGroupRequest,
  responseType: proto_vorto_node_node_api_pb.RemoveSiteGroupResponse
};

NodeService.ListSiteGroups = {
  methodName: "ListSiteGroups",
  service: NodeService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_types_types_pb.Empty,
  responseType: proto_vorto_node_node_api_pb.ListSiteGroupsResponse
};

NodeService.ListAllSiteGroups = {
  methodName: "ListAllSiteGroups",
  service: NodeService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_types_types_pb.Empty,
  responseType: proto_vorto_node_node_api_pb.ListSiteGroupsResponse
};

NodeService.EditSiteGroups = {
  methodName: "EditSiteGroups",
  service: NodeService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_node_node_api_pb.EditSiteGroupsRequest,
  responseType: proto_vorto_node_node_api_pb.ListSiteGroupsResponse
};

NodeService.NodeCreateSite = {
  methodName: "NodeCreateSite",
  service: NodeService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_types_types_pb.CreateSiteCriteria,
  responseType: proto_vorto_node_node_api_pb.NodeCreateSiteResponse
};

NodeService.NodeEditSite = {
  methodName: "NodeEditSite",
  service: NodeService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_node_node_api_pb.NodeEditSiteRequest,
  responseType: proto_vorto_node_node_api_pb.NodeEditSiteResponse
};

NodeService.NodeArchiveSite = {
  methodName: "NodeArchiveSite",
  service: NodeService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_types_types_pb.StringId,
  responseType: proto_vorto_types_types_pb.Empty
};

NodeService.NodeUnArchiveSite = {
  methodName: "NodeUnArchiveSite",
  service: NodeService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_types_types_pb.StringId,
  responseType: proto_vorto_types_types_pb.Empty
};

NodeService.NodeListSites = {
  methodName: "NodeListSites",
  service: NodeService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_types_types_pb.Empty,
  responseType: proto_vorto_node_node_api_pb.NodeListSitesResponse
};

NodeService.NodeListActiveSites = {
  methodName: "NodeListActiveSites",
  service: NodeService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_types_types_pb.Empty,
  responseType: proto_vorto_node_node_api_pb.NodeActiveSiteResponse
};

NodeService.NodeListArchivedSites = {
  methodName: "NodeListArchivedSites",
  service: NodeService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_node_node_api_pb.NodeListPageRequest,
  responseType: proto_vorto_node_node_api_pb.NodeListArchivedSitesResponse
};

NodeService.NodeGetSite = {
  methodName: "NodeGetSite",
  service: NodeService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_types_types_pb.StringId,
  responseType: proto_vorto_node_node_api_pb.NodeGetSiteResponse
};

NodeService.NodeListDistricts = {
  methodName: "NodeListDistricts",
  service: NodeService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_types_types_pb.Empty,
  responseType: proto_vorto_node_node_api_pb.NodeListDistrictsResponse
};

NodeService.NodeListDistrictsBySites = {
  methodName: "NodeListDistrictsBySites",
  service: NodeService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_node_node_api_pb.NodeListDistrictsBySitesRequest,
  responseType: proto_vorto_node_node_api_pb.NodeListDistrictsBySitesResponse
};

NodeService.NodeGetSiteStatistics = {
  methodName: "NodeGetSiteStatistics",
  service: NodeService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_types_types_pb.StringId,
  responseType: proto_vorto_node_node_api_pb.NodeGetSiteStatisticsResponse
};

NodeService.NodeGetSiteUsers = {
  methodName: "NodeGetSiteUsers",
  service: NodeService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_types_types_pb.StringId,
  responseType: proto_vorto_node_node_api_pb.NodeGetSiteUsersResponse
};

NodeService.NodeListActiveSitesWithInventories = {
  methodName: "NodeListActiveSitesWithInventories",
  service: NodeService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_types_types_pb.Empty,
  responseType: proto_vorto_node_node_api_pb.NodeActiveSiteResponse
};

NodeService.NodeListActiveSitesWithBatchesToComplete = {
  methodName: "NodeListActiveSitesWithBatchesToComplete",
  service: NodeService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_types_types_pb.Empty,
  responseType: proto_vorto_node_node_api_pb.NodeActiveSiteResponse
};

NodeService.NodeListAllMySupplierSites = {
  methodName: "NodeListAllMySupplierSites",
  service: NodeService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_types_types_pb.Empty,
  responseType: proto_vorto_node_node_api_pb.NodeListAllMySupplierSitesResponse
};

NodeService.ListSiteDownTimeHistory = {
  methodName: "ListSiteDownTimeHistory",
  service: NodeService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_types_types_pb.StringId,
  responseType: proto_vorto_node_node_api_pb.ListSiteDownTimeHistoryResponse
};

NodeService.ReportSiteDownTime = {
  methodName: "ReportSiteDownTime",
  service: NodeService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_node_node_api_pb.ReportSiteDownTimeRequest,
  responseType: proto_vorto_node_node_api_pb.ListSiteDownTimeHistoryResponse
};

NodeService.ArchiveSiteDownTime = {
  methodName: "ArchiveSiteDownTime",
  service: NodeService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_types_types_pb.IntId,
  responseType: proto_vorto_node_node_api_pb.ListSiteDownTimeHistoryResponse
};

NodeService.UpsertSiteLoadingSchedule = {
  methodName: "UpsertSiteLoadingSchedule",
  service: NodeService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_node_node_api_pb.UpsertSiteLoadingScheduleRequest,
  responseType: proto_vorto_node_node_api_pb.UpsertSiteLoadingScheduleResponse
};

NodeService.GetSiteLoadingSchedule = {
  methodName: "GetSiteLoadingSchedule",
  service: NodeService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_types_types_pb.StringId,
  responseType: proto_vorto_node_node_api_pb.GetSiteLoadingScheduleResponse
};

NodeService.CreateInventory = {
  methodName: "CreateInventory",
  service: NodeService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_node_node_api_pb.CreateInventoryRequest,
  responseType: proto_vorto_node_node_api_pb.CreateInventoryResponse
};

NodeService.ListSiteActiveInventoriesByGroup = {
  methodName: "ListSiteActiveInventoriesByGroup",
  service: NodeService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_types_types_pb.StringId,
  responseType: proto_vorto_node_node_api_pb.ListSiteInventoriesByGroupResponse
};

NodeService.ListSiteArchivedInventories = {
  methodName: "ListSiteArchivedInventories",
  service: NodeService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_types_types_pb.StringId,
  responseType: proto_vorto_node_node_api_pb.ListSiteInventoriesResponse
};

NodeService.ListSiteActiveInventories = {
  methodName: "ListSiteActiveInventories",
  service: NodeService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_types_types_pb.StringId,
  responseType: proto_vorto_node_node_api_pb.ListSiteInventoriesResponse
};

NodeService.EditInventory = {
  methodName: "EditInventory",
  service: NodeService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_node_node_api_pb.EditInventoryRequest,
  responseType: proto_vorto_node_node_api_pb.EditInventoryResponse
};

NodeService.ArchiveInventory = {
  methodName: "ArchiveInventory",
  service: NodeService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_types_types_pb.StringId,
  responseType: proto_vorto_types_types_pb.Empty
};

NodeService.UnArchiveInventory = {
  methodName: "UnArchiveInventory",
  service: NodeService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_types_types_pb.StringId,
  responseType: proto_vorto_types_types_pb.Empty
};

NodeService.GetInventoryAPIStockUpdates = {
  methodName: "GetInventoryAPIStockUpdates",
  service: NodeService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_types_types_pb.StringId,
  responseType: proto_vorto_node_node_api_pb.GetInventoryAPIStockUpdatesResponse
};

NodeService.UpdateInventoryAPIStockUpdates = {
  methodName: "UpdateInventoryAPIStockUpdates",
  service: NodeService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_node_node_api_pb.UpdateInventoryAPIStockUpdatesRequest,
  responseType: proto_vorto_types_types_pb.Empty
};

NodeService.UpdateInventoryStock = {
  methodName: "UpdateInventoryStock",
  service: NodeService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_node_node_api_pb.UpdateInventoryStockRequest,
  responseType: proto_vorto_node_node_api_pb.UpdateInventoryStockResponse
};

NodeService.GetInventoriesWithStock = {
  methodName: "GetInventoriesWithStock",
  service: NodeService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_types_types_pb.Empty,
  responseType: proto_vorto_node_node_api_pb.GetInventoriesWithStockResponse
};

NodeService.UpdateMultipleInventoryStock = {
  methodName: "UpdateMultipleInventoryStock",
  service: NodeService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_node_node_api_pb.UpdateMultipleInventoryStockRequest,
  responseType: proto_vorto_types_types_pb.Empty
};

NodeService.GetInventoryDetail = {
  methodName: "GetInventoryDetail",
  service: NodeService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_types_types_pb.StringId,
  responseType: proto_vorto_node_node_api_pb.InventoryDetailResponse
};

NodeService.SetInventoryRestockStrategy = {
  methodName: "SetInventoryRestockStrategy",
  service: NodeService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_node_node_api_pb.UpdateRestockStrategyRequest,
  responseType: proto_vorto_node_node_api_pb.InventoryDetailResponse
};

NodeService.CreateInventoryGroup = {
  methodName: "CreateInventoryGroup",
  service: NodeService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_node_node_api_pb.CreateInventoryGroupRequest,
  responseType: proto_vorto_types_types_pb.StringIdName
};

NodeService.ListInventoryGroup = {
  methodName: "ListInventoryGroup",
  service: NodeService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_types_types_pb.StringId,
  responseType: proto_vorto_node_node_api_pb.ListInventoryGroupResponse
};

NodeService.EditInventoryGroup = {
  methodName: "EditInventoryGroup",
  service: NodeService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_node_node_api_pb.EditInventoryGroupRequest,
  responseType: proto_vorto_types_types_pb.StringIdName
};

NodeService.GetInventorySupplyPlan = {
  methodName: "GetInventorySupplyPlan",
  service: NodeService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_types_types_pb.StringId,
  responseType: proto_vorto_node_node_api_pb.InventorySupplyPlanResponse
};

NodeService.GetPotentialSuppliersForInventory = {
  methodName: "GetPotentialSuppliersForInventory",
  service: NodeService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_types_types_pb.StringId,
  responseType: proto_vorto_node_node_api_pb.PotentialSuppliersResponse
};

NodeService.GetPotentialPurchaseOrdersForInventory = {
  methodName: "GetPotentialPurchaseOrdersForInventory",
  service: NodeService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_types_types_pb.StringId,
  responseType: proto_vorto_node_node_api_pb.PotentialPurchaseOrdersResponse
};

NodeService.EditInventorySupplyPlan = {
  methodName: "EditInventorySupplyPlan",
  service: NodeService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_node_node_api_pb.EditInventorySupplyPlanRequest,
  responseType: proto_vorto_node_node_api_pb.EditInventorySupplyPlanResponse
};

NodeService.NodeCreateVirtualSupplierForSupplyPlan = {
  methodName: "NodeCreateVirtualSupplierForSupplyPlan",
  service: NodeService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_node_node_api_pb.NodeCreateVirtualSupplierForSupplyPlanRequest,
  responseType: proto_vorto_node_node_api_pb.NodeCreateVirtualSupplierForSupplyPlanResponse
};

NodeService.GetInventoryScheduledSpotMarketRequests = {
  methodName: "GetInventoryScheduledSpotMarketRequests",
  service: NodeService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_types_types_pb.StringId,
  responseType: proto_vorto_node_node_api_pb.GetInventoryScheduledSpotMarketRequestsResponse
};

NodeService.GetInventoryCurrentSpotMarketRequests = {
  methodName: "GetInventoryCurrentSpotMarketRequests",
  service: NodeService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_types_types_pb.StringId,
  responseType: proto_vorto_node_node_api_pb.GetInventoryCurrentSpotMarketRequestsResponse
};

NodeService.NodeCreateInventoryReport = {
  methodName: "NodeCreateInventoryReport",
  service: NodeService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_node_node_api_pb.NodeCreateInventoryReportRequest,
  responseType: proto_vorto_types_types_pb.Report
};

NodeService.NodeUpdateInventoryReport = {
  methodName: "NodeUpdateInventoryReport",
  service: NodeService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_node_node_api_pb.NodeUpdateInventoryReportRequest,
  responseType: proto_vorto_types_types_pb.Report
};

NodeService.NodeCreateSiteReport = {
  methodName: "NodeCreateSiteReport",
  service: NodeService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_node_node_api_pb.NodeCreateSiteReportRequest,
  responseType: proto_vorto_types_types_pb.Report
};

NodeService.NodeUpdateSiteReport = {
  methodName: "NodeUpdateSiteReport",
  service: NodeService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_node_node_api_pb.NodeUpdateSiteReportRequest,
  responseType: proto_vorto_types_types_pb.Report
};

NodeService.GetDashboardSiteDetail = {
  methodName: "GetDashboardSiteDetail",
  service: NodeService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_types_types_pb.Empty,
  responseType: proto_vorto_node_node_api_pb.GetDashboardSiteDetailResponse
};

NodeService.GetDashboardEdgeDetail = {
  methodName: "GetDashboardEdgeDetail",
  service: NodeService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_types_types_pb.Empty,
  responseType: proto_vorto_node_node_api_pb.GetDashboardEdgeDetailResponse
};

NodeService.GetDashboardSupplierDetail = {
  methodName: "GetDashboardSupplierDetail",
  service: NodeService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_types_types_pb.Empty,
  responseType: proto_vorto_node_node_api_pb.GetDashboardSupplierDetailResponse
};

NodeService.GetDashboardAutopilotFeed = {
  methodName: "GetDashboardAutopilotFeed",
  service: NodeService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_types_types_pb.Empty,
  responseType: proto_vorto_node_node_api_pb.GetDashboardAutopilotFeedResponse
};

NodeService.GetSiteDetailsAutopilotFeed = {
  methodName: "GetSiteDetailsAutopilotFeed",
  service: NodeService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_types_types_pb.StringId,
  responseType: proto_vorto_node_node_api_pb.GetDashboardAutopilotFeedResponse
};

NodeService.CreateEdgeContract = {
  methodName: "CreateEdgeContract",
  service: NodeService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_node_node_api_pb.CreateEdgeContractRequestAsNode,
  responseType: proto_vorto_node_node_pb.SingleEdgeContractAsNode
};

NodeService.EditEdgeContract = {
  methodName: "EditEdgeContract",
  service: NodeService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_node_node_api_pb.EditEdgeContractRequestAsNode,
  responseType: proto_vorto_node_node_pb.SingleEdgeContractAsNode
};

NodeService.LockEdgeContract = {
  methodName: "LockEdgeContract",
  service: NodeService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_types_types_pb.StringId,
  responseType: proto_vorto_types_types_pb.Empty
};

NodeService.UnLockEdgeContract = {
  methodName: "UnLockEdgeContract",
  service: NodeService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_types_types_pb.StringId,
  responseType: proto_vorto_types_types_pb.Empty
};

NodeService.CloseEdgeContract = {
  methodName: "CloseEdgeContract",
  service: NodeService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_types_types_pb.StringId,
  responseType: proto_vorto_types_types_pb.Empty
};

NodeService.ReopenClosedEdgeContract = {
  methodName: "ReopenClosedEdgeContract",
  service: NodeService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_types_types_pb.StringId,
  responseType: proto_vorto_node_node_pb.SingleEdgeContractAsNode
};

NodeService.GetEdgeContract = {
  methodName: "GetEdgeContract",
  service: NodeService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_types_types_pb.StringId,
  responseType: proto_vorto_node_node_pb.SingleEdgeContractAsNode
};

NodeService.ListEdgeContracts = {
  methodName: "ListEdgeContracts",
  service: NodeService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_node_node_api_pb.ListContractsRequest,
  responseType: proto_vorto_node_node_api_pb.ListEdgeContractsResponseAsNode
};

NodeService.ListDeliverableSites = {
  methodName: "ListDeliverableSites",
  service: NodeService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_types_types_pb.Empty,
  responseType: proto_vorto_node_node_api_pb.ListDeliverableSitesResponse
};

NodeService.CreateRouteGuide = {
  methodName: "CreateRouteGuide",
  service: NodeService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_node_node_api_pb.CreateRouteGuideRequest,
  responseType: proto_vorto_node_node_api_pb.RouteGuideResponse
};

NodeService.UpsertRouteGuides = {
  methodName: "UpsertRouteGuides",
  service: NodeService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_node_node_api_pb.UpsertRouteGuideRequest,
  responseType: proto_vorto_node_node_api_pb.RouteGuideResponse
};

NodeService.GetRouteGuide = {
  methodName: "GetRouteGuide",
  service: NodeService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_types_types_pb.StringId,
  responseType: proto_vorto_node_node_api_pb.RouteGuideResponse
};

NodeService.GetRouteGuideEdges = {
  methodName: "GetRouteGuideEdges",
  service: NodeService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_types_types_pb.StringId,
  responseType: proto_vorto_node_node_api_pb.RouteGuideEdgesResponse
};

NodeService.GetRouteGuideSpotEdgeContracts = {
  methodName: "GetRouteGuideSpotEdgeContracts",
  service: NodeService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_types_types_pb.StringId,
  responseType: proto_vorto_node_node_api_pb.RouteGuideSpotEdgeContractsResponse
};

NodeService.ListEdges = {
  methodName: "ListEdges",
  service: NodeService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_types_types_pb.Empty,
  responseType: proto_vorto_node_node_api_pb.ListEdgesResponse
};

NodeService.CreateVirtualSupplier = {
  methodName: "CreateVirtualSupplier",
  service: NodeService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_node_node_api_pb.CreateVirtualSupplierRequest,
  responseType: proto_vorto_types_types_pb.SlimAccount
};

NodeService.CreateVirtualSupplierSite = {
  methodName: "CreateVirtualSupplierSite",
  service: NodeService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_node_node_api_pb.CreateVirtualSupplierSiteRequest,
  responseType: proto_vorto_node_node_api_pb.CreateVirtualSupplierSiteResponse
};

NodeService.NodeListTodaysShipments = {
  methodName: "NodeListTodaysShipments",
  service: NodeService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_types_types_pb.Empty,
  responseType: proto_vorto_node_node_api_pb.NodeListTodaysShipmentsResponse
};

NodeService.NodeBulkUploadPurchaseOrders = {
  methodName: "NodeBulkUploadPurchaseOrders",
  service: NodeService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_node_node_api_pb.NodeBulkUploadPORequest,
  responseType: proto_vorto_node_node_api_pb.NodeBulkUploadPOResponse
};

NodeService.NodeBulkUploadSupplierSites = {
  methodName: "NodeBulkUploadSupplierSites",
  service: NodeService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_node_node_api_pb.NodeBulkUploadSupplierSiteRequest,
  responseType: proto_vorto_node_node_api_pb.NodeBulkUploadSupplierSiteResponse
};

NodeService.NodeListSupplyPlans = {
  methodName: "NodeListSupplyPlans",
  service: NodeService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_types_types_pb.Empty,
  responseType: proto_vorto_node_node_api_pb.NodeListSupplyPlansResponse
};

NodeService.NodeListPurchaseOrdersForInventory = {
  methodName: "NodeListPurchaseOrdersForInventory",
  service: NodeService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_types_types_pb.StringId,
  responseType: proto_vorto_node_node_api_pb.NodeListPurchaseOrdersForInventoryResponse
};

NodeService.GetDashboardAutopilotFeedStream = {
  methodName: "GetDashboardAutopilotFeedStream",
  service: NodeService,
  requestStream: false,
  responseStream: true,
  requestType: proto_vorto_types_types_pb.Empty,
  responseType: proto_vorto_node_node_api_pb.GetDashboardAutopilotFeedResponse
};

NodeService.GetOutboundInventories = {
  methodName: "GetOutboundInventories",
  service: NodeService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_types_types_pb.Empty,
  responseType: proto_vorto_node_node_api_pb.GetOutboundInventoriesResponse
};

NodeService.NodeListPurchaseOrdersToConfirm = {
  methodName: "NodeListPurchaseOrdersToConfirm",
  service: NodeService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_node_node_api_pb.ListPurchaseOrdersToConfirmRequest,
  responseType: proto_vorto_node_node_api_pb.ListPurchaseOrdersToConfirmResponse
};

NodeService.GetOrdersToConfirm = {
  methodName: "GetOrdersToConfirm",
  service: NodeService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_node_node_api_pb.ListPendingOrdersToConfirmRequest,
  responseType: proto_vorto_node_node_api_pb.ListPendingOrdersToConfirmResponse
};

NodeService.GetOutboundScheduledOrders = {
  methodName: "GetOutboundScheduledOrders",
  service: NodeService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_node_node_api_pb.GetOutboundScheduledOrdersRequest,
  responseType: proto_vorto_node_node_api_pb.GetOutboundScheduledOrdersResponse
};

NodeService.GetOutboundEnRouteOrders = {
  methodName: "GetOutboundEnRouteOrders",
  service: NodeService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_node_node_api_pb.GetOutboundEnRouteOrdersRequest,
  responseType: proto_vorto_node_node_api_pb.GetOutboundEnRouteOrdersResponse
};

NodeService.GetOutBoundOnSiteOrders = {
  methodName: "GetOutBoundOnSiteOrders",
  service: NodeService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_types_types_pb.Empty,
  responseType: proto_vorto_node_node_api_pb.GetOutBoundOnSiteOrdersResponse
};

NodeService.GetSitesWithPickupsToday = {
  methodName: "GetSitesWithPickupsToday",
  service: NodeService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_node_node_api_pb.GetSitesWithPickupsTodayRequest,
  responseType: proto_vorto_node_node_api_pb.GetSitesWithPickupsTodayResponse
};

NodeService.GetInboundInventories = {
  methodName: "GetInboundInventories",
  service: NodeService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_types_types_pb.Empty,
  responseType: proto_vorto_node_node_api_pb.GetInboundInventoriesResponse
};

NodeService.GetInventories = {
  methodName: "GetInventories",
  service: NodeService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_types_types_pb.Empty,
  responseType: proto_vorto_node_node_api_pb.GetInventoriesResponse
};

NodeService.ConsumerOrderWarningCounts = {
  methodName: "ConsumerOrderWarningCounts",
  service: NodeService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_types_types_pb.Empty,
  responseType: proto_vorto_node_node_api_pb.ConsumerOrderWarningCountsResponse
};

NodeService.CreateFuelMatrix = {
  methodName: "CreateFuelMatrix",
  service: NodeService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_node_node_api_pb.CreateFuelMatrixRequest,
  responseType: proto_vorto_types_types_pb.Empty
};

NodeService.EditFuelMatrix = {
  methodName: "EditFuelMatrix",
  service: NodeService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_node_node_api_pb.EditFuelMatrixRequest,
  responseType: proto_vorto_types_types_pb.Empty
};

NodeService.ListFuelMatrices = {
  methodName: "ListFuelMatrices",
  service: NodeService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_types_types_pb.Empty,
  responseType: proto_vorto_node_node_api_pb.ListFuelMatricesResponse
};

NodeService.GetFuelMatrix = {
  methodName: "GetFuelMatrix",
  service: NodeService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_types_types_pb.StringId,
  responseType: proto_vorto_node_node_pb.FuelMatrix
};

NodeService.GlobalSearch = {
  methodName: "GlobalSearch",
  service: NodeService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_node_node_api_pb.GlobalSearchRequest,
  responseType: proto_vorto_node_node_api_pb.GlobalSearchResponse
};

NodeService.GetVanguardHistory = {
  methodName: "GetVanguardHistory",
  service: NodeService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_types_types_pb.Empty,
  responseType: proto_vorto_node_node_api_pb.GetVanguardHistoryResponse
};

NodeService.UploadVanguardDemand = {
  methodName: "UploadVanguardDemand",
  service: NodeService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_node_node_api_pb.UploadVanguardDemandRequest,
  responseType: proto_vorto_node_node_api_pb.GetVanguardHistoryResponse
};

NodeService.BulkUploadSites = {
  methodName: "BulkUploadSites",
  service: NodeService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_node_node_api_pb.BulkUploadSiteRequest,
  responseType: proto_vorto_node_node_api_pb.BulkUploadSiteResponse
};

NodeService.BulkUploadInventory = {
  methodName: "BulkUploadInventory",
  service: NodeService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_node_node_api_pb.BulkUploadInventoryRequest,
  responseType: proto_vorto_node_node_api_pb.BulkUploadInventoryResponse
};

NodeService.CreateFinishedGoodOrder = {
  methodName: "CreateFinishedGoodOrder",
  service: NodeService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_node_node_api_pb.CreateFinishedGoodOrderRequest,
  responseType: proto_vorto_node_node_api_pb.CreateFinishedGoodOrderResponse
};

NodeService.NodeListFinishedGoodsProductsInventoryForSite = {
  methodName: "NodeListFinishedGoodsProductsInventoryForSite",
  service: NodeService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_types_types_pb.StringId,
  responseType: proto_vorto_node_node_api_pb.NodeListFinishedGoodsProductsInventoryForSiteResponse
};

NodeService.GetInventoryIssues = {
  methodName: "GetInventoryIssues",
  service: NodeService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_types_types_pb.Empty,
  responseType: proto_vorto_node_node_api_pb.GetInventoryIssuesResponse
};

NodeService.ListActiveCostCenters = {
  methodName: "ListActiveCostCenters",
  service: NodeService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_types_types_pb.Empty,
  responseType: proto_vorto_node_node_api_pb.ListActiveCostCentersResponse
};

NodeService.CreateCostCenter = {
  methodName: "CreateCostCenter",
  service: NodeService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_node_node_api_pb.CreateCostCenterRequest,
  responseType: proto_vorto_node_node_api_pb.CreateCostCenterResponse
};

NodeService.UpdateCostCenter = {
  methodName: "UpdateCostCenter",
  service: NodeService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_node_node_api_pb.UpdateCostCenterRequest,
  responseType: proto_vorto_node_node_api_pb.UpdateCostCenterResponse
};

NodeService.ArchiveCostCenter = {
  methodName: "ArchiveCostCenter",
  service: NodeService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_node_node_api_pb.ArchiveCostCenterRequest,
  responseType: proto_vorto_types_types_pb.Empty
};

NodeService.ListActiveSalesOrders = {
  methodName: "ListActiveSalesOrders",
  service: NodeService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_types_types_pb.Empty,
  responseType: proto_vorto_node_node_api_pb.ListActiveSalesOrdersResponse
};

NodeService.GetSlimInventoryDetail = {
  methodName: "GetSlimInventoryDetail",
  service: NodeService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_types_types_pb.StringId,
  responseType: proto_vorto_node_node_api_pb.InventoryDetailSlimResponse
};

NodeService.GetSlimInventoryDetailStocks = {
  methodName: "GetSlimInventoryDetailStocks",
  service: NodeService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_types_types_pb.StringId,
  responseType: proto_vorto_node_node_api_pb.InventoryDetailSlimStocksResponse
};

NodeService.GetSlimInventoryDetailShipments = {
  methodName: "GetSlimInventoryDetailShipments",
  service: NodeService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_types_types_pb.StringId,
  responseType: proto_vorto_node_node_api_pb.InventoryDetailShipmentsResponse
};

NodeService.VMIStockUpdate = {
  methodName: "VMIStockUpdate",
  service: NodeService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_node_node_api_pb.VMIStockUpdateRequest,
  responseType: proto_vorto_types_types_pb.Empty
};

NodeService.VMIListSubscribedInventories = {
  methodName: "VMIListSubscribedInventories",
  service: NodeService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_types_types_pb.Empty,
  responseType: proto_vorto_node_node_api_pb.VMInventoryListResponse
};

NodeService.VMIListAllInventories = {
  methodName: "VMIListAllInventories",
  service: NodeService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_types_types_pb.Empty,
  responseType: proto_vorto_node_node_api_pb.VMInventoryListResponse
};

NodeService.VMIListSharedInventories = {
  methodName: "VMIListSharedInventories",
  service: NodeService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_types_types_pb.Empty,
  responseType: proto_vorto_node_node_api_pb.VMInventoryListResponse
};

NodeService.VMIInventorySubscribe = {
  methodName: "VMIInventorySubscribe",
  service: NodeService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_types_types_pb.StringId,
  responseType: proto_vorto_types_types_pb.Empty
};

NodeService.VMIInventoryUnsubscribe = {
  methodName: "VMIInventoryUnsubscribe",
  service: NodeService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_types_types_pb.StringId,
  responseType: proto_vorto_types_types_pb.Empty
};

NodeService.VMIInventorySubscribeUser = {
  methodName: "VMIInventorySubscribeUser",
  service: NodeService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_node_node_api_pb.VMIInventorySubscribeUpdateRequest,
  responseType: proto_vorto_types_types_pb.Empty
};

NodeService.VMIInventoryUnsubscribeUser = {
  methodName: "VMIInventoryUnsubscribeUser",
  service: NodeService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_node_node_api_pb.VMIInventorySubscribeUpdateRequest,
  responseType: proto_vorto_types_types_pb.Empty
};

NodeService.VMIInventoryAvailableUsers = {
  methodName: "VMIInventoryAvailableUsers",
  service: NodeService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_types_types_pb.StringId,
  responseType: proto_vorto_node_node_api_pb.VMIInventoryAvailableUsersResponse
};

NodeService.VMIInventorySetOwner = {
  methodName: "VMIInventorySetOwner",
  service: NodeService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_node_node_api_pb.VMIInventorySetOwnerRequest,
  responseType: proto_vorto_types_types_pb.Empty
};

NodeService.VMIListSlimFacilities = {
  methodName: "VMIListSlimFacilities",
  service: NodeService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_types_types_pb.Empty,
  responseType: proto_vorto_node_node_api_pb.VMIListSlimFacilitiesResponse
};

NodeService.VMIGetFacilityDetail = {
  methodName: "VMIGetFacilityDetail",
  service: NodeService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_types_types_pb.StringId,
  responseType: proto_vorto_node_node_api_pb.VMIFacilityDetailResponse
};

NodeService.VMIEditFacility = {
  methodName: "VMIEditFacility",
  service: NodeService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_node_node_api_pb.VMIEditFacilityRequest,
  responseType: proto_vorto_node_node_api_pb.VMIFacilityDetailResponse
};

NodeService.VMICreateInventory = {
  methodName: "VMICreateInventory",
  service: NodeService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_node_node_api_pb.VMICreateInventoryRequest,
  responseType: proto_vorto_types_types_pb.StringId
};

NodeService.VMIEditInventory = {
  methodName: "VMIEditInventory",
  service: NodeService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_node_node_api_pb.VMIEditInventoryRequest,
  responseType: proto_vorto_types_types_pb.Empty
};

NodeService.VMIListFacilities = {
  methodName: "VMIListFacilities",
  service: NodeService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_types_types_pb.Empty,
  responseType: proto_vorto_node_node_api_pb.VMIListFacilitiesResponse
};

NodeService.VMICreateFacility = {
  methodName: "VMICreateFacility",
  service: NodeService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_node_node_api_pb.VMICreateFacilityRequest,
  responseType: proto_vorto_types_types_pb.Empty
};

NodeService.VMIFacilityFileList = {
  methodName: "VMIFacilityFileList",
  service: NodeService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_types_types_pb.StringId,
  responseType: proto_vorto_node_node_api_pb.VMIFacilityFileListResponse
};

NodeService.VMIFacilityRemoveFile = {
  methodName: "VMIFacilityRemoveFile",
  service: NodeService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_node_node_api_pb.VMIFacilityRemoveFileRequest,
  responseType: proto_vorto_types_types_pb.Empty
};

exports.NodeService = NodeService;

function NodeServiceClient(serviceHost, options) {
  this.serviceHost = serviceHost;
  this.options = options || {};
}

NodeServiceClient.prototype.listSupplierSitesForCreateOrder = function listSupplierSitesForCreateOrder(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(NodeService.ListSupplierSitesForCreateOrder, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

NodeServiceClient.prototype.listPurchaseOrdersForCreateOrder = function listPurchaseOrdersForCreateOrder(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(NodeService.ListPurchaseOrdersForCreateOrder, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

NodeServiceClient.prototype.listEdgeContractsForCreateOrders = function listEdgeContractsForCreateOrders(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(NodeService.ListEdgeContractsForCreateOrders, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

NodeServiceClient.prototype.listPurchaseOrders = function listPurchaseOrders(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(NodeService.ListPurchaseOrders, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

NodeServiceClient.prototype.listPurchaseOrdersAsSupplier = function listPurchaseOrdersAsSupplier(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(NodeService.ListPurchaseOrdersAsSupplier, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

NodeServiceClient.prototype.nodeListAtSupplierOrders = function nodeListAtSupplierOrders(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(NodeService.NodeListAtSupplierOrders, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

NodeServiceClient.prototype.nodeListAtEdgeOrders = function nodeListAtEdgeOrders(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(NodeService.NodeListAtEdgeOrders, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

NodeServiceClient.prototype.nodeListScheduledOrders = function nodeListScheduledOrders(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(NodeService.NodeListScheduledOrders, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

NodeServiceClient.prototype.nodeListInProgressOrders = function nodeListInProgressOrders(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(NodeService.NodeListInProgressOrders, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

NodeServiceClient.prototype.nodeListArrivedOrders = function nodeListArrivedOrders(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(NodeService.NodeListArrivedOrders, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

NodeServiceClient.prototype.nodeListCancelledOrders = function nodeListCancelledOrders(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(NodeService.NodeListCancelledOrders, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

NodeServiceClient.prototype.nodeListCompletedOrders = function nodeListCompletedOrders(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(NodeService.NodeListCompletedOrders, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

NodeServiceClient.prototype.createOrder = function createOrder(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(NodeService.CreateOrder, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

NodeServiceClient.prototype.createV2Order = function createV2Order(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(NodeService.CreateV2Order, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

NodeServiceClient.prototype.getPurchaseOrdersForCreateV2Order = function getPurchaseOrdersForCreateV2Order(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(NodeService.GetPurchaseOrdersForCreateV2Order, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

NodeServiceClient.prototype.getRouteGuidesForCreateOrderV2 = function getRouteGuidesForCreateOrderV2(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(NodeService.GetRouteGuidesForCreateOrderV2, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

NodeServiceClient.prototype.getCarrierContractsForCreateOrderV2 = function getCarrierContractsForCreateOrderV2(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(NodeService.GetCarrierContractsForCreateOrderV2, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

NodeServiceClient.prototype.getInventoriesForSiteForCreateOrderV2 = function getInventoriesForSiteForCreateOrderV2(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(NodeService.GetInventoriesForSiteForCreateOrderV2, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

NodeServiceClient.prototype.cancelOrder = function cancelOrder(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(NodeService.CancelOrder, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

NodeServiceClient.prototype.cancelMultipleOrders = function cancelMultipleOrders(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(NodeService.CancelMultipleOrders, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

NodeServiceClient.prototype.orderUpload = function orderUpload(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(NodeService.OrderUpload, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

NodeServiceClient.prototype.placeUploadedOrders = function placeUploadedOrders(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(NodeService.PlaceUploadedOrders, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

NodeServiceClient.prototype.nodeListSupplierSites = function nodeListSupplierSites(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(NodeService.NodeListSupplierSites, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

NodeServiceClient.prototype.nodeListProducts = function nodeListProducts(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(NodeService.NodeListProducts, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

NodeServiceClient.prototype.nodeListArchivedProducts = function nodeListArchivedProducts(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(NodeService.NodeListArchivedProducts, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

NodeServiceClient.prototype.nodeListProductsForSite = function nodeListProductsForSite(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(NodeService.NodeListProductsForSite, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

NodeServiceClient.prototype.nodeCreatePurchaseOrder = function nodeCreatePurchaseOrder(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(NodeService.NodeCreatePurchaseOrder, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

NodeServiceClient.prototype.nodeEditPurchaseOrder = function nodeEditPurchaseOrder(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(NodeService.NodeEditPurchaseOrder, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

NodeServiceClient.prototype.nodeClosePurchaseOrder = function nodeClosePurchaseOrder(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(NodeService.NodeClosePurchaseOrder, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

NodeServiceClient.prototype.nodeConfirmPurchaseOrder = function nodeConfirmPurchaseOrder(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(NodeService.NodeConfirmPurchaseOrder, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

NodeServiceClient.prototype.nodeGetPurchaseOrder = function nodeGetPurchaseOrder(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(NodeService.NodeGetPurchaseOrder, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

NodeServiceClient.prototype.nodeUpdatePOBillable = function nodeUpdatePOBillable(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(NodeService.NodeUpdatePOBillable, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

NodeServiceClient.prototype.listInNetworkSuppliers = function listInNetworkSuppliers(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(NodeService.ListInNetworkSuppliers, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

NodeServiceClient.prototype.listInNetworkCustomers = function listInNetworkCustomers(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(NodeService.ListInNetworkCustomers, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

NodeServiceClient.prototype.searchOutNetworkSuppliersByName = function searchOutNetworkSuppliersByName(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(NodeService.SearchOutNetworkSuppliersByName, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

NodeServiceClient.prototype.searchOutNetworkCustomersByName = function searchOutNetworkCustomersByName(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(NodeService.SearchOutNetworkCustomersByName, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

NodeServiceClient.prototype.getSupplierContracts = function getSupplierContracts(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(NodeService.GetSupplierContracts, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

NodeServiceClient.prototype.addSupplierContract = function addSupplierContract(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(NodeService.AddSupplierContract, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

NodeServiceClient.prototype.editSupplierContract = function editSupplierContract(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(NodeService.EditSupplierContract, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

NodeServiceClient.prototype.removeSupplierContract = function removeSupplierContract(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(NodeService.RemoveSupplierContract, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

NodeServiceClient.prototype.addCustomerToNetwork = function addCustomerToNetwork(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(NodeService.AddCustomerToNetwork, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

NodeServiceClient.prototype.removeCustomerFromNetwork = function removeCustomerFromNetwork(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(NodeService.RemoveCustomerFromNetwork, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

NodeServiceClient.prototype.addSiteGroup = function addSiteGroup(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(NodeService.AddSiteGroup, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

NodeServiceClient.prototype.removeSiteGroup = function removeSiteGroup(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(NodeService.RemoveSiteGroup, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

NodeServiceClient.prototype.listSiteGroups = function listSiteGroups(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(NodeService.ListSiteGroups, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

NodeServiceClient.prototype.listAllSiteGroups = function listAllSiteGroups(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(NodeService.ListAllSiteGroups, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

NodeServiceClient.prototype.editSiteGroups = function editSiteGroups(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(NodeService.EditSiteGroups, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

NodeServiceClient.prototype.nodeCreateSite = function nodeCreateSite(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(NodeService.NodeCreateSite, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

NodeServiceClient.prototype.nodeEditSite = function nodeEditSite(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(NodeService.NodeEditSite, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

NodeServiceClient.prototype.nodeArchiveSite = function nodeArchiveSite(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(NodeService.NodeArchiveSite, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

NodeServiceClient.prototype.nodeUnArchiveSite = function nodeUnArchiveSite(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(NodeService.NodeUnArchiveSite, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

NodeServiceClient.prototype.nodeListSites = function nodeListSites(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(NodeService.NodeListSites, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

NodeServiceClient.prototype.nodeListActiveSites = function nodeListActiveSites(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(NodeService.NodeListActiveSites, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

NodeServiceClient.prototype.nodeListArchivedSites = function nodeListArchivedSites(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(NodeService.NodeListArchivedSites, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

NodeServiceClient.prototype.nodeGetSite = function nodeGetSite(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(NodeService.NodeGetSite, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

NodeServiceClient.prototype.nodeListDistricts = function nodeListDistricts(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(NodeService.NodeListDistricts, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

NodeServiceClient.prototype.nodeListDistrictsBySites = function nodeListDistrictsBySites(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(NodeService.NodeListDistrictsBySites, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

NodeServiceClient.prototype.nodeGetSiteStatistics = function nodeGetSiteStatistics(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(NodeService.NodeGetSiteStatistics, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

NodeServiceClient.prototype.nodeGetSiteUsers = function nodeGetSiteUsers(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(NodeService.NodeGetSiteUsers, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

NodeServiceClient.prototype.nodeListActiveSitesWithInventories = function nodeListActiveSitesWithInventories(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(NodeService.NodeListActiveSitesWithInventories, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

NodeServiceClient.prototype.nodeListActiveSitesWithBatchesToComplete = function nodeListActiveSitesWithBatchesToComplete(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(NodeService.NodeListActiveSitesWithBatchesToComplete, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

NodeServiceClient.prototype.nodeListAllMySupplierSites = function nodeListAllMySupplierSites(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(NodeService.NodeListAllMySupplierSites, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

NodeServiceClient.prototype.listSiteDownTimeHistory = function listSiteDownTimeHistory(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(NodeService.ListSiteDownTimeHistory, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

NodeServiceClient.prototype.reportSiteDownTime = function reportSiteDownTime(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(NodeService.ReportSiteDownTime, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

NodeServiceClient.prototype.archiveSiteDownTime = function archiveSiteDownTime(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(NodeService.ArchiveSiteDownTime, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

NodeServiceClient.prototype.upsertSiteLoadingSchedule = function upsertSiteLoadingSchedule(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(NodeService.UpsertSiteLoadingSchedule, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

NodeServiceClient.prototype.getSiteLoadingSchedule = function getSiteLoadingSchedule(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(NodeService.GetSiteLoadingSchedule, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

NodeServiceClient.prototype.createInventory = function createInventory(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(NodeService.CreateInventory, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

NodeServiceClient.prototype.listSiteActiveInventoriesByGroup = function listSiteActiveInventoriesByGroup(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(NodeService.ListSiteActiveInventoriesByGroup, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

NodeServiceClient.prototype.listSiteArchivedInventories = function listSiteArchivedInventories(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(NodeService.ListSiteArchivedInventories, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

NodeServiceClient.prototype.listSiteActiveInventories = function listSiteActiveInventories(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(NodeService.ListSiteActiveInventories, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

NodeServiceClient.prototype.editInventory = function editInventory(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(NodeService.EditInventory, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

NodeServiceClient.prototype.archiveInventory = function archiveInventory(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(NodeService.ArchiveInventory, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

NodeServiceClient.prototype.unArchiveInventory = function unArchiveInventory(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(NodeService.UnArchiveInventory, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

NodeServiceClient.prototype.getInventoryAPIStockUpdates = function getInventoryAPIStockUpdates(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(NodeService.GetInventoryAPIStockUpdates, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

NodeServiceClient.prototype.updateInventoryAPIStockUpdates = function updateInventoryAPIStockUpdates(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(NodeService.UpdateInventoryAPIStockUpdates, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

NodeServiceClient.prototype.updateInventoryStock = function updateInventoryStock(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(NodeService.UpdateInventoryStock, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

NodeServiceClient.prototype.getInventoriesWithStock = function getInventoriesWithStock(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(NodeService.GetInventoriesWithStock, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

NodeServiceClient.prototype.updateMultipleInventoryStock = function updateMultipleInventoryStock(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(NodeService.UpdateMultipleInventoryStock, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

NodeServiceClient.prototype.getInventoryDetail = function getInventoryDetail(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(NodeService.GetInventoryDetail, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

NodeServiceClient.prototype.setInventoryRestockStrategy = function setInventoryRestockStrategy(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(NodeService.SetInventoryRestockStrategy, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

NodeServiceClient.prototype.createInventoryGroup = function createInventoryGroup(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(NodeService.CreateInventoryGroup, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

NodeServiceClient.prototype.listInventoryGroup = function listInventoryGroup(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(NodeService.ListInventoryGroup, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

NodeServiceClient.prototype.editInventoryGroup = function editInventoryGroup(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(NodeService.EditInventoryGroup, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

NodeServiceClient.prototype.getInventorySupplyPlan = function getInventorySupplyPlan(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(NodeService.GetInventorySupplyPlan, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

NodeServiceClient.prototype.getPotentialSuppliersForInventory = function getPotentialSuppliersForInventory(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(NodeService.GetPotentialSuppliersForInventory, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

NodeServiceClient.prototype.getPotentialPurchaseOrdersForInventory = function getPotentialPurchaseOrdersForInventory(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(NodeService.GetPotentialPurchaseOrdersForInventory, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

NodeServiceClient.prototype.editInventorySupplyPlan = function editInventorySupplyPlan(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(NodeService.EditInventorySupplyPlan, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

NodeServiceClient.prototype.nodeCreateVirtualSupplierForSupplyPlan = function nodeCreateVirtualSupplierForSupplyPlan(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(NodeService.NodeCreateVirtualSupplierForSupplyPlan, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

NodeServiceClient.prototype.getInventoryScheduledSpotMarketRequests = function getInventoryScheduledSpotMarketRequests(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(NodeService.GetInventoryScheduledSpotMarketRequests, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

NodeServiceClient.prototype.getInventoryCurrentSpotMarketRequests = function getInventoryCurrentSpotMarketRequests(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(NodeService.GetInventoryCurrentSpotMarketRequests, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

NodeServiceClient.prototype.nodeCreateInventoryReport = function nodeCreateInventoryReport(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(NodeService.NodeCreateInventoryReport, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

NodeServiceClient.prototype.nodeUpdateInventoryReport = function nodeUpdateInventoryReport(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(NodeService.NodeUpdateInventoryReport, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

NodeServiceClient.prototype.nodeCreateSiteReport = function nodeCreateSiteReport(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(NodeService.NodeCreateSiteReport, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

NodeServiceClient.prototype.nodeUpdateSiteReport = function nodeUpdateSiteReport(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(NodeService.NodeUpdateSiteReport, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

NodeServiceClient.prototype.getDashboardSiteDetail = function getDashboardSiteDetail(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(NodeService.GetDashboardSiteDetail, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

NodeServiceClient.prototype.getDashboardEdgeDetail = function getDashboardEdgeDetail(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(NodeService.GetDashboardEdgeDetail, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

NodeServiceClient.prototype.getDashboardSupplierDetail = function getDashboardSupplierDetail(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(NodeService.GetDashboardSupplierDetail, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

NodeServiceClient.prototype.getDashboardAutopilotFeed = function getDashboardAutopilotFeed(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(NodeService.GetDashboardAutopilotFeed, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

NodeServiceClient.prototype.getSiteDetailsAutopilotFeed = function getSiteDetailsAutopilotFeed(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(NodeService.GetSiteDetailsAutopilotFeed, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

NodeServiceClient.prototype.createEdgeContract = function createEdgeContract(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(NodeService.CreateEdgeContract, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

NodeServiceClient.prototype.editEdgeContract = function editEdgeContract(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(NodeService.EditEdgeContract, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

NodeServiceClient.prototype.lockEdgeContract = function lockEdgeContract(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(NodeService.LockEdgeContract, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

NodeServiceClient.prototype.unLockEdgeContract = function unLockEdgeContract(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(NodeService.UnLockEdgeContract, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

NodeServiceClient.prototype.closeEdgeContract = function closeEdgeContract(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(NodeService.CloseEdgeContract, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

NodeServiceClient.prototype.reopenClosedEdgeContract = function reopenClosedEdgeContract(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(NodeService.ReopenClosedEdgeContract, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

NodeServiceClient.prototype.getEdgeContract = function getEdgeContract(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(NodeService.GetEdgeContract, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

NodeServiceClient.prototype.listEdgeContracts = function listEdgeContracts(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(NodeService.ListEdgeContracts, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

NodeServiceClient.prototype.listDeliverableSites = function listDeliverableSites(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(NodeService.ListDeliverableSites, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

NodeServiceClient.prototype.createRouteGuide = function createRouteGuide(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(NodeService.CreateRouteGuide, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

NodeServiceClient.prototype.upsertRouteGuides = function upsertRouteGuides(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(NodeService.UpsertRouteGuides, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

NodeServiceClient.prototype.getRouteGuide = function getRouteGuide(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(NodeService.GetRouteGuide, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

NodeServiceClient.prototype.getRouteGuideEdges = function getRouteGuideEdges(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(NodeService.GetRouteGuideEdges, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

NodeServiceClient.prototype.getRouteGuideSpotEdgeContracts = function getRouteGuideSpotEdgeContracts(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(NodeService.GetRouteGuideSpotEdgeContracts, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

NodeServiceClient.prototype.listEdges = function listEdges(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(NodeService.ListEdges, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

NodeServiceClient.prototype.createVirtualSupplier = function createVirtualSupplier(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(NodeService.CreateVirtualSupplier, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

NodeServiceClient.prototype.createVirtualSupplierSite = function createVirtualSupplierSite(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(NodeService.CreateVirtualSupplierSite, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

NodeServiceClient.prototype.nodeListTodaysShipments = function nodeListTodaysShipments(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(NodeService.NodeListTodaysShipments, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

NodeServiceClient.prototype.nodeBulkUploadPurchaseOrders = function nodeBulkUploadPurchaseOrders(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(NodeService.NodeBulkUploadPurchaseOrders, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

NodeServiceClient.prototype.nodeBulkUploadSupplierSites = function nodeBulkUploadSupplierSites(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(NodeService.NodeBulkUploadSupplierSites, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

NodeServiceClient.prototype.nodeListSupplyPlans = function nodeListSupplyPlans(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(NodeService.NodeListSupplyPlans, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

NodeServiceClient.prototype.nodeListPurchaseOrdersForInventory = function nodeListPurchaseOrdersForInventory(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(NodeService.NodeListPurchaseOrdersForInventory, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

NodeServiceClient.prototype.getDashboardAutopilotFeedStream = function getDashboardAutopilotFeedStream(requestMessage, metadata) {
  var listeners = {
    data: [],
    end: [],
    status: []
  };
  var client = grpc.invoke(NodeService.GetDashboardAutopilotFeedStream, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage);
      });
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners = null;
    }
  });
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

NodeServiceClient.prototype.getOutboundInventories = function getOutboundInventories(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(NodeService.GetOutboundInventories, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

NodeServiceClient.prototype.nodeListPurchaseOrdersToConfirm = function nodeListPurchaseOrdersToConfirm(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(NodeService.NodeListPurchaseOrdersToConfirm, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

NodeServiceClient.prototype.getOrdersToConfirm = function getOrdersToConfirm(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(NodeService.GetOrdersToConfirm, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

NodeServiceClient.prototype.getOutboundScheduledOrders = function getOutboundScheduledOrders(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(NodeService.GetOutboundScheduledOrders, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

NodeServiceClient.prototype.getOutboundEnRouteOrders = function getOutboundEnRouteOrders(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(NodeService.GetOutboundEnRouteOrders, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

NodeServiceClient.prototype.getOutBoundOnSiteOrders = function getOutBoundOnSiteOrders(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(NodeService.GetOutBoundOnSiteOrders, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

NodeServiceClient.prototype.getSitesWithPickupsToday = function getSitesWithPickupsToday(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(NodeService.GetSitesWithPickupsToday, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

NodeServiceClient.prototype.getInboundInventories = function getInboundInventories(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(NodeService.GetInboundInventories, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

NodeServiceClient.prototype.getInventories = function getInventories(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(NodeService.GetInventories, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

NodeServiceClient.prototype.consumerOrderWarningCounts = function consumerOrderWarningCounts(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(NodeService.ConsumerOrderWarningCounts, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

NodeServiceClient.prototype.createFuelMatrix = function createFuelMatrix(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(NodeService.CreateFuelMatrix, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

NodeServiceClient.prototype.editFuelMatrix = function editFuelMatrix(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(NodeService.EditFuelMatrix, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

NodeServiceClient.prototype.listFuelMatrices = function listFuelMatrices(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(NodeService.ListFuelMatrices, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

NodeServiceClient.prototype.getFuelMatrix = function getFuelMatrix(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(NodeService.GetFuelMatrix, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

NodeServiceClient.prototype.globalSearch = function globalSearch(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(NodeService.GlobalSearch, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

NodeServiceClient.prototype.getVanguardHistory = function getVanguardHistory(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(NodeService.GetVanguardHistory, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

NodeServiceClient.prototype.uploadVanguardDemand = function uploadVanguardDemand(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(NodeService.UploadVanguardDemand, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

NodeServiceClient.prototype.bulkUploadSites = function bulkUploadSites(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(NodeService.BulkUploadSites, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

NodeServiceClient.prototype.bulkUploadInventory = function bulkUploadInventory(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(NodeService.BulkUploadInventory, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

NodeServiceClient.prototype.createFinishedGoodOrder = function createFinishedGoodOrder(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(NodeService.CreateFinishedGoodOrder, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

NodeServiceClient.prototype.nodeListFinishedGoodsProductsInventoryForSite = function nodeListFinishedGoodsProductsInventoryForSite(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(NodeService.NodeListFinishedGoodsProductsInventoryForSite, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

NodeServiceClient.prototype.getInventoryIssues = function getInventoryIssues(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(NodeService.GetInventoryIssues, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

NodeServiceClient.prototype.listActiveCostCenters = function listActiveCostCenters(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(NodeService.ListActiveCostCenters, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

NodeServiceClient.prototype.createCostCenter = function createCostCenter(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(NodeService.CreateCostCenter, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

NodeServiceClient.prototype.updateCostCenter = function updateCostCenter(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(NodeService.UpdateCostCenter, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

NodeServiceClient.prototype.archiveCostCenter = function archiveCostCenter(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(NodeService.ArchiveCostCenter, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

NodeServiceClient.prototype.listActiveSalesOrders = function listActiveSalesOrders(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(NodeService.ListActiveSalesOrders, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

NodeServiceClient.prototype.getSlimInventoryDetail = function getSlimInventoryDetail(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(NodeService.GetSlimInventoryDetail, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

NodeServiceClient.prototype.getSlimInventoryDetailStocks = function getSlimInventoryDetailStocks(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(NodeService.GetSlimInventoryDetailStocks, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

NodeServiceClient.prototype.getSlimInventoryDetailShipments = function getSlimInventoryDetailShipments(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(NodeService.GetSlimInventoryDetailShipments, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

NodeServiceClient.prototype.vMIStockUpdate = function vMIStockUpdate(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(NodeService.VMIStockUpdate, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

NodeServiceClient.prototype.vMIListSubscribedInventories = function vMIListSubscribedInventories(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(NodeService.VMIListSubscribedInventories, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

NodeServiceClient.prototype.vMIListAllInventories = function vMIListAllInventories(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(NodeService.VMIListAllInventories, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

NodeServiceClient.prototype.vMIListSharedInventories = function vMIListSharedInventories(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(NodeService.VMIListSharedInventories, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

NodeServiceClient.prototype.vMIInventorySubscribe = function vMIInventorySubscribe(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(NodeService.VMIInventorySubscribe, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

NodeServiceClient.prototype.vMIInventoryUnsubscribe = function vMIInventoryUnsubscribe(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(NodeService.VMIInventoryUnsubscribe, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

NodeServiceClient.prototype.vMIInventorySubscribeUser = function vMIInventorySubscribeUser(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(NodeService.VMIInventorySubscribeUser, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

NodeServiceClient.prototype.vMIInventoryUnsubscribeUser = function vMIInventoryUnsubscribeUser(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(NodeService.VMIInventoryUnsubscribeUser, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

NodeServiceClient.prototype.vMIInventoryAvailableUsers = function vMIInventoryAvailableUsers(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(NodeService.VMIInventoryAvailableUsers, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

NodeServiceClient.prototype.vMIInventorySetOwner = function vMIInventorySetOwner(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(NodeService.VMIInventorySetOwner, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

NodeServiceClient.prototype.vMIListSlimFacilities = function vMIListSlimFacilities(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(NodeService.VMIListSlimFacilities, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

NodeServiceClient.prototype.vMIGetFacilityDetail = function vMIGetFacilityDetail(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(NodeService.VMIGetFacilityDetail, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

NodeServiceClient.prototype.vMIEditFacility = function vMIEditFacility(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(NodeService.VMIEditFacility, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

NodeServiceClient.prototype.vMICreateInventory = function vMICreateInventory(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(NodeService.VMICreateInventory, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

NodeServiceClient.prototype.vMIEditInventory = function vMIEditInventory(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(NodeService.VMIEditInventory, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

NodeServiceClient.prototype.vMIListFacilities = function vMIListFacilities(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(NodeService.VMIListFacilities, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

NodeServiceClient.prototype.vMICreateFacility = function vMICreateFacility(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(NodeService.VMICreateFacility, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

NodeServiceClient.prototype.vMIFacilityFileList = function vMIFacilityFileList(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(NodeService.VMIFacilityFileList, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

NodeServiceClient.prototype.vMIFacilityRemoveFile = function vMIFacilityRemoveFile(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(NodeService.VMIFacilityRemoveFile, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

exports.NodeServiceClient = NodeServiceClient;

