import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';

export interface MultiNumber {
  count: number;
  bgClass?: string;
}

@Component({
  selector: 'vt-multi-number-badge',
  templateUrl: './multi-number-badge.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MultiNumberBadgeComponent implements OnInit {
  @Input() public defaultBgClass = 'bg-indigo-600';
  @Input() public numbers: MultiNumber[] = [];

  constructor() {}

  public ngOnInit(): void {}
}
