// source: proto/vorto/node/node_api.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var proto_vorto_node_node_pb = require('../../../proto/vorto/node/node_pb.js');
goog.object.extend(proto, proto_vorto_node_node_pb);
var proto_vorto_types_types_pb = require('../../../proto/vorto/types/types_pb.js');
goog.object.extend(proto, proto_vorto_types_types_pb);
goog.exportSymbol('proto.vtv1.AddInventoryToGroupRequest', null, global);
goog.exportSymbol('proto.vtv1.AddSiteGroupRequest', null, global);
goog.exportSymbol('proto.vtv1.AddSiteGroupResponse', null, global);
goog.exportSymbol('proto.vtv1.AddSupplierContractRequest', null, global);
goog.exportSymbol('proto.vtv1.AddSupplierContractResponse', null, global);
goog.exportSymbol('proto.vtv1.ArchiveCostCenterRequest', null, global);
goog.exportSymbol('proto.vtv1.BulkUploadInventory', null, global);
goog.exportSymbol('proto.vtv1.BulkUploadInventoryRequest', null, global);
goog.exportSymbol('proto.vtv1.BulkUploadInventoryResponse', null, global);
goog.exportSymbol('proto.vtv1.BulkUploadSite', null, global);
goog.exportSymbol('proto.vtv1.BulkUploadSiteRequest', null, global);
goog.exportSymbol('proto.vtv1.BulkUploadSiteResponse', null, global);
goog.exportSymbol('proto.vtv1.CancelMultipleOrdersRequestNode', null, global);
goog.exportSymbol('proto.vtv1.CancelOrderRequest', null, global);
goog.exportSymbol('proto.vtv1.ConsumerOrderWarningCountsResponse', null, global);
goog.exportSymbol('proto.vtv1.CreateCostCenterRequest', null, global);
goog.exportSymbol('proto.vtv1.CreateCostCenterResponse', null, global);
goog.exportSymbol('proto.vtv1.CreateEdgeContractRequestAsNode', null, global);
goog.exportSymbol('proto.vtv1.CreateFinishedGoodOrderRequest', null, global);
goog.exportSymbol('proto.vtv1.CreateFinishedGoodOrderResponse', null, global);
goog.exportSymbol('proto.vtv1.CreateFuelMatrixRequest', null, global);
goog.exportSymbol('proto.vtv1.CreateInventoryGroupRequest', null, global);
goog.exportSymbol('proto.vtv1.CreateInventoryRequest', null, global);
goog.exportSymbol('proto.vtv1.CreateInventoryResponse', null, global);
goog.exportSymbol('proto.vtv1.CreateMultiStopOrderRequest', null, global);
goog.exportSymbol('proto.vtv1.CreateMultiStopOrderRequestStop', null, global);
goog.exportSymbol('proto.vtv1.CreateMultiStopOrderRequestStopTask', null, global);
goog.exportSymbol('proto.vtv1.CreateOrderEdgeContract', null, global);
goog.exportSymbol('proto.vtv1.CreateOrderPurchaseOrder', null, global);
goog.exportSymbol('proto.vtv1.CreateOrderRequest', null, global);
goog.exportSymbol('proto.vtv1.CreateOrderRequestClonedInventory', null, global);
goog.exportSymbol('proto.vtv1.CreateOrderResponse', null, global);
goog.exportSymbol('proto.vtv1.CreateResponseCriteria', null, global);
goog.exportSymbol('proto.vtv1.CreateRouteGuideRequest', null, global);
goog.exportSymbol('proto.vtv1.CreateVirtualSupplierRequest', null, global);
goog.exportSymbol('proto.vtv1.CreateVirtualSupplierSiteRequest', null, global);
goog.exportSymbol('proto.vtv1.CreateVirtualSupplierSiteResponse', null, global);
goog.exportSymbol('proto.vtv1.CreateVmiAccountRequest', null, global);
goog.exportSymbol('proto.vtv1.CreateVmiAccountResponse', null, global);
goog.exportSymbol('proto.vtv1.EditEdgeContractRequestAsNode', null, global);
goog.exportSymbol('proto.vtv1.EditFuelMatrixRequest', null, global);
goog.exportSymbol('proto.vtv1.EditInventoryGroupRequest', null, global);
goog.exportSymbol('proto.vtv1.EditInventoryRequest', null, global);
goog.exportSymbol('proto.vtv1.EditInventoryResponse', null, global);
goog.exportSymbol('proto.vtv1.EditInventorySupplyPlanRequest', null, global);
goog.exportSymbol('proto.vtv1.EditInventorySupplyPlanResponse', null, global);
goog.exportSymbol('proto.vtv1.EditInventorySupplyPlanSupplier', null, global);
goog.exportSymbol('proto.vtv1.EditSiteGroupsRequest', null, global);
goog.exportSymbol('proto.vtv1.EditSupplierContractRequest', null, global);
goog.exportSymbol('proto.vtv1.EditSupplierContractResponse', null, global);
goog.exportSymbol('proto.vtv1.GetCarrierContractsForCreateOrderV2Request', null, global);
goog.exportSymbol('proto.vtv1.GetCarrierContractsForCreateOrderV2Response', null, global);
goog.exportSymbol('proto.vtv1.GetDashboardAutopilotFeedResponse', null, global);
goog.exportSymbol('proto.vtv1.GetDashboardEdgeDetailResponse', null, global);
goog.exportSymbol('proto.vtv1.GetDashboardSiteDetailResponse', null, global);
goog.exportSymbol('proto.vtv1.GetDashboardSupplierDetailResponse', null, global);
goog.exportSymbol('proto.vtv1.GetInboundInventoriesResponse', null, global);
goog.exportSymbol('proto.vtv1.GetInventoriesForSiteForCreateOrderV2Inventory', null, global);
goog.exportSymbol('proto.vtv1.GetInventoriesForSiteForCreateOrderV2Request', null, global);
goog.exportSymbol('proto.vtv1.GetInventoriesForSiteForCreateOrderV2Response', null, global);
goog.exportSymbol('proto.vtv1.GetInventoriesResponse', null, global);
goog.exportSymbol('proto.vtv1.GetInventoriesWithStockResponse', null, global);
goog.exportSymbol('proto.vtv1.GetInventoryAPIStockUpdatesResponse', null, global);
goog.exportSymbol('proto.vtv1.GetInventoryCurrentSpotMarketRequestsResponse', null, global);
goog.exportSymbol('proto.vtv1.GetInventoryIssuesResponse', null, global);
goog.exportSymbol('proto.vtv1.GetInventoryScheduledSpotMarketRequestsResponse', null, global);
goog.exportSymbol('proto.vtv1.GetOutBoundOnSiteOrdersResponse', null, global);
goog.exportSymbol('proto.vtv1.GetOutboundEnRouteOrdersRequest', null, global);
goog.exportSymbol('proto.vtv1.GetOutboundEnRouteOrdersResponse', null, global);
goog.exportSymbol('proto.vtv1.GetOutboundInventoriesResponse', null, global);
goog.exportSymbol('proto.vtv1.GetOutboundScheduledOrdersRequest', null, global);
goog.exportSymbol('proto.vtv1.GetOutboundScheduledOrdersResponse', null, global);
goog.exportSymbol('proto.vtv1.GetPurchaseOrdersForCreateV2OrderRequest', null, global);
goog.exportSymbol('proto.vtv1.GetRouteGuidesForCreateOrderV2Request', null, global);
goog.exportSymbol('proto.vtv1.GetRouteGuidesForCreateOrderV2Response', null, global);
goog.exportSymbol('proto.vtv1.GetSiteLoadingScheduleResponse', null, global);
goog.exportSymbol('proto.vtv1.GetSitesWithPickupsTodayRequest', null, global);
goog.exportSymbol('proto.vtv1.GetSitesWithPickupsTodayResponse', null, global);
goog.exportSymbol('proto.vtv1.GetSupplierContractsResponse', null, global);
goog.exportSymbol('proto.vtv1.GetTrailerTypesForFuelMatrixResponse', null, global);
goog.exportSymbol('proto.vtv1.GetVanguardHistoryResponse', null, global);
goog.exportSymbol('proto.vtv1.GlobalSearchRequest', null, global);
goog.exportSymbol('proto.vtv1.GlobalSearchResponse', null, global);
goog.exportSymbol('proto.vtv1.GlobalSearchResponseFulfillment', null, global);
goog.exportSymbol('proto.vtv1.GlobalSearchResponseInventory', null, global);
goog.exportSymbol('proto.vtv1.GlobalSearchResponseOrder', null, global);
goog.exportSymbol('proto.vtv1.GlobalSearchResponseUser', null, global);
goog.exportSymbol('proto.vtv1.HasPOForProductResponse', null, global);
goog.exportSymbol('proto.vtv1.InventoryDetailResponse', null, global);
goog.exportSymbol('proto.vtv1.InventoryDetailShipmentsResponse', null, global);
goog.exportSymbol('proto.vtv1.InventoryDetailSlimResponse', null, global);
goog.exportSymbol('proto.vtv1.InventoryDetailSlimStocksResponse', null, global);
goog.exportSymbol('proto.vtv1.InventorySupplyPlanResponse', null, global);
goog.exportSymbol('proto.vtv1.ListActiveCostCentersResponse', null, global);
goog.exportSymbol('proto.vtv1.ListActiveSalesOrdersResponse', null, global);
goog.exportSymbol('proto.vtv1.ListContractsRequest', null, global);
goog.exportSymbol('proto.vtv1.ListDeliverableSitesResponse', null, global);
goog.exportSymbol('proto.vtv1.ListEdgeContractsForCreateOrdersRequest', null, global);
goog.exportSymbol('proto.vtv1.ListEdgeContractsForCreateOrdersResponse', null, global);
goog.exportSymbol('proto.vtv1.ListEdgeContractsResponseAsNode', null, global);
goog.exportSymbol('proto.vtv1.ListEdgesResponse', null, global);
goog.exportSymbol('proto.vtv1.ListFuelMatricesResponse', null, global);
goog.exportSymbol('proto.vtv1.ListInNetworkCustomersResponse', null, global);
goog.exportSymbol('proto.vtv1.ListInNetworkSuppliersRequest', null, global);
goog.exportSymbol('proto.vtv1.ListInNetworkSuppliersResponse', null, global);
goog.exportSymbol('proto.vtv1.ListInventoryGroupResponse', null, global);
goog.exportSymbol('proto.vtv1.ListPendingOrdersToConfirmRequest', null, global);
goog.exportSymbol('proto.vtv1.ListPendingOrdersToConfirmResponse', null, global);
goog.exportSymbol('proto.vtv1.ListPurchaseOrdersAsSupplierResponse', null, global);
goog.exportSymbol('proto.vtv1.ListPurchaseOrdersForCreateOrderRequest', null, global);
goog.exportSymbol('proto.vtv1.ListPurchaseOrdersForCreateOrderResponse', null, global);
goog.exportSymbol('proto.vtv1.ListPurchaseOrdersRequest', null, global);
goog.exportSymbol('proto.vtv1.ListPurchaseOrdersResponse', null, global);
goog.exportSymbol('proto.vtv1.ListPurchaseOrdersToConfirmRequest', null, global);
goog.exportSymbol('proto.vtv1.ListPurchaseOrdersToConfirmResponse', null, global);
goog.exportSymbol('proto.vtv1.ListSiteDownTimeHistoryResponse', null, global);
goog.exportSymbol('proto.vtv1.ListSiteGroupsResponse', null, global);
goog.exportSymbol('proto.vtv1.ListSiteInventoriesByGroupResponse', null, global);
goog.exportSymbol('proto.vtv1.ListSiteInventoriesResponse', null, global);
goog.exportSymbol('proto.vtv1.ListSupplierSitesForCreateOrderRequest', null, global);
goog.exportSymbol('proto.vtv1.ListSupplierSitesForCreateOrderResponse', null, global);
goog.exportSymbol('proto.vtv1.MatchAttemptedString', null, global);
goog.exportSymbol('proto.vtv1.MatchAttemptedStrings', null, global);
goog.exportSymbol('proto.vtv1.NodeActiveSiteResponse', null, global);
goog.exportSymbol('proto.vtv1.NodeBulkPurchaseOrderLineItem', null, global);
goog.exportSymbol('proto.vtv1.NodeBulkUploadPOLineItemVerification', null, global);
goog.exportSymbol('proto.vtv1.NodeBulkUploadPORequest', null, global);
goog.exportSymbol('proto.vtv1.NodeBulkUploadPOResponse', null, global);
goog.exportSymbol('proto.vtv1.NodeBulkUploadPOVerification', null, global);
goog.exportSymbol('proto.vtv1.NodeBulkUploadSupplierSite', null, global);
goog.exportSymbol('proto.vtv1.NodeBulkUploadSupplierSiteRequest', null, global);
goog.exportSymbol('proto.vtv1.NodeBulkUploadSupplierSiteResponse', null, global);
goog.exportSymbol('proto.vtv1.NodeBulkUploadSupplierSiteVerification', null, global);
goog.exportSymbol('proto.vtv1.NodeBulkUploadSupplierVerification', null, global);
goog.exportSymbol('proto.vtv1.NodeConfirmOrDeclinePurchaseOrder', null, global);
goog.exportSymbol('proto.vtv1.NodeCreateInventoryReportRequest', null, global);
goog.exportSymbol('proto.vtv1.NodeCreatePurchaseOrderForSupplyPlanRequest', null, global);
goog.exportSymbol('proto.vtv1.NodeCreatePurchaseOrderForSupplyPlanResponse', null, global);
goog.exportSymbol('proto.vtv1.NodeCreatePurchaseOrderRequest', null, global);
goog.exportSymbol('proto.vtv1.NodeCreateSiteReportRequest', null, global);
goog.exportSymbol('proto.vtv1.NodeCreateSiteRequest', null, global);
goog.exportSymbol('proto.vtv1.NodeCreateSiteResponse', null, global);
goog.exportSymbol('proto.vtv1.NodeCreateVirtualSupplierForSupplyPlanRequest', null, global);
goog.exportSymbol('proto.vtv1.NodeCreateVirtualSupplierForSupplyPlanResponse', null, global);
goog.exportSymbol('proto.vtv1.NodeEditPurchaseOrderRequest', null, global);
goog.exportSymbol('proto.vtv1.NodeEditSiteRequest', null, global);
goog.exportSymbol('proto.vtv1.NodeEditSiteRequestInventoryLineItem', null, global);
goog.exportSymbol('proto.vtv1.NodeEditSiteResponse', null, global);
goog.exportSymbol('proto.vtv1.NodeFinalizedOrdersRequest', null, global);
goog.exportSymbol('proto.vtv1.NodeFinalizedOrdersRequestDeliveryDateFilter', null, global);
goog.exportSymbol('proto.vtv1.NodeGetSiteResponse', null, global);
goog.exportSymbol('proto.vtv1.NodeGetSiteStatisticsResponse', null, global);
goog.exportSymbol('proto.vtv1.NodeGetSiteUsersResponse', null, global);
goog.exportSymbol('proto.vtv1.NodeListAllMySupplierSitesResponse', null, global);
goog.exportSymbol('proto.vtv1.NodeListAllMySupplierSitesSupplier', null, global);
goog.exportSymbol('proto.vtv1.NodeListArchivedSitesResponse', null, global);
goog.exportSymbol('proto.vtv1.NodeListArrivedOrdersResponse', null, global);
goog.exportSymbol('proto.vtv1.NodeListAtEdgeOrdersResponse', null, global);
goog.exportSymbol('proto.vtv1.NodeListAtSupplierOrdersResponse', null, global);
goog.exportSymbol('proto.vtv1.NodeListDistrictsBySitesRequest', null, global);
goog.exportSymbol('proto.vtv1.NodeListDistrictsBySitesResponse', null, global);
goog.exportSymbol('proto.vtv1.NodeListDistrictsResponse', null, global);
goog.exportSymbol('proto.vtv1.NodeListFinalStateOrdersResponse', null, global);
goog.exportSymbol('proto.vtv1.NodeListFinishedGoodsProductsInventoryForSiteResponse', null, global);
goog.exportSymbol('proto.vtv1.NodeListInProgressOrdersResponse', null, global);
goog.exportSymbol('proto.vtv1.NodeListPageRequest', null, global);
goog.exportSymbol('proto.vtv1.NodeListProductsResponse', null, global);
goog.exportSymbol('proto.vtv1.NodeListPurchaseOrdersForInventoryResponse', null, global);
goog.exportSymbol('proto.vtv1.NodeListScheduledOrdersResponse', null, global);
goog.exportSymbol('proto.vtv1.NodeListSitesResponse', null, global);
goog.exportSymbol('proto.vtv1.NodeListSupplierSitesRequest', null, global);
goog.exportSymbol('proto.vtv1.NodeListSupplierSitesResponse', null, global);
goog.exportSymbol('proto.vtv1.NodeListSupplyPlansResponse', null, global);
goog.exportSymbol('proto.vtv1.NodeListTodaysShipmentsResponse', null, global);
goog.exportSymbol('proto.vtv1.NodeUpdateInventoryReportRequest', null, global);
goog.exportSymbol('proto.vtv1.NodeUpdatePOBillableRequest', null, global);
goog.exportSymbol('proto.vtv1.NodeUpdateSiteReportRequest', null, global);
goog.exportSymbol('proto.vtv1.NodeUpsertPurchaseOrderLineItem', null, global);
goog.exportSymbol('proto.vtv1.OrderUploadCreateOrderRequest', null, global);
goog.exportSymbol('proto.vtv1.OrderUploadRequest', null, global);
goog.exportSymbol('proto.vtv1.OrderUploadResponse', null, global);
goog.exportSymbol('proto.vtv1.PotentialPurchaseOrdersResponse', null, global);
goog.exportSymbol('proto.vtv1.PotentialPurchaseOrdersV2Response', null, global);
goog.exportSymbol('proto.vtv1.PotentialSuppliersResponse', null, global);
goog.exportSymbol('proto.vtv1.RemoveSiteGroupRequest', null, global);
goog.exportSymbol('proto.vtv1.RemoveSiteGroupResponse', null, global);
goog.exportSymbol('proto.vtv1.RemoveSupplierContractRequest', null, global);
goog.exportSymbol('proto.vtv1.RemoveSupplierContractResponse', null, global);
goog.exportSymbol('proto.vtv1.ReportSiteDownTimeRequest', null, global);
goog.exportSymbol('proto.vtv1.RouteGuideEdgesResponse', null, global);
goog.exportSymbol('proto.vtv1.RouteGuideResponse', null, global);
goog.exportSymbol('proto.vtv1.RouteGuideSpotEdgeContractsResponse', null, global);
goog.exportSymbol('proto.vtv1.SearchOutNetworkSuppliersByNameRequest', null, global);
goog.exportSymbol('proto.vtv1.SearchOutNetworkSuppliersByNameResponse', null, global);
goog.exportSymbol('proto.vtv1.SinglePurchaseOrderResponse', null, global);
goog.exportSymbol('proto.vtv1.SiteDownTimeHistoryEntry', null, global);
goog.exportSymbol('proto.vtv1.SlimSupplierContract', null, global);
goog.exportSymbol('proto.vtv1.SupplierContract', null, global);
goog.exportSymbol('proto.vtv1.SupplierContractAllocation', null, global);
goog.exportSymbol('proto.vtv1.SupplierContractLineItem', null, global);
goog.exportSymbol('proto.vtv1.SupplierContractSite', null, global);
goog.exportSymbol('proto.vtv1.SupplierCreateSiteResponse', null, global);
goog.exportSymbol('proto.vtv1.SupplyPlanSummary', null, global);
goog.exportSymbol('proto.vtv1.UpdateCostCenterRequest', null, global);
goog.exportSymbol('proto.vtv1.UpdateCostCenterResponse', null, global);
goog.exportSymbol('proto.vtv1.UpdateInventoryAPIStockUpdatesRequest', null, global);
goog.exportSymbol('proto.vtv1.UpdateInventoryStockRequest', null, global);
goog.exportSymbol('proto.vtv1.UpdateInventoryStockResponse', null, global);
goog.exportSymbol('proto.vtv1.UpdateMultipleInventoryStockRequest', null, global);
goog.exportSymbol('proto.vtv1.UpdateRestockStrategyRequest', null, global);
goog.exportSymbol('proto.vtv1.UploadVanguardDemandRequest', null, global);
goog.exportSymbol('proto.vtv1.UploadedOrderData', null, global);
goog.exportSymbol('proto.vtv1.UpsertRouteGuideRequest', null, global);
goog.exportSymbol('proto.vtv1.UpsertSiteLoadingScheduleRequest', null, global);
goog.exportSymbol('proto.vtv1.UpsertSiteLoadingScheduleResponse', null, global);
goog.exportSymbol('proto.vtv1.VMICreateFacilityRequest', null, global);
goog.exportSymbol('proto.vtv1.VMICreateInventoryRequest', null, global);
goog.exportSymbol('proto.vtv1.VMIEditFacilityRequest', null, global);
goog.exportSymbol('proto.vtv1.VMIEditInventoryRequest', null, global);
goog.exportSymbol('proto.vtv1.VMIFacilityDetailResponse', null, global);
goog.exportSymbol('proto.vtv1.VMIFacilityDockUpsertRequest', null, global);
goog.exportSymbol('proto.vtv1.VMIFacilityFileListResponse', null, global);
goog.exportSymbol('proto.vtv1.VMIFacilityRemoveFileRequest', null, global);
goog.exportSymbol('proto.vtv1.VMIInventoryAvailableUsersResponse', null, global);
goog.exportSymbol('proto.vtv1.VMIInventorySetOwnerRequest', null, global);
goog.exportSymbol('proto.vtv1.VMIInventorySubscribeUpdateRequest', null, global);
goog.exportSymbol('proto.vtv1.VMIListFacilitiesResponse', null, global);
goog.exportSymbol('proto.vtv1.VMIListSlimFacilitiesResponse', null, global);
goog.exportSymbol('proto.vtv1.VMIStockUpdateRequest', null, global);
goog.exportSymbol('proto.vtv1.VMInventoryListResponse', null, global);
goog.exportSymbol('proto.vtv1.ValidationErrorMessage', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.NodeGetSiteUsersResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.NodeGetSiteUsersResponse.repeatedFields_, null);
};
goog.inherits(proto.vtv1.NodeGetSiteUsersResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.NodeGetSiteUsersResponse.displayName = 'proto.vtv1.NodeGetSiteUsersResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.UpdateInventoryAPIStockUpdatesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.UpdateInventoryAPIStockUpdatesRequest.repeatedFields_, null);
};
goog.inherits(proto.vtv1.UpdateInventoryAPIStockUpdatesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.UpdateInventoryAPIStockUpdatesRequest.displayName = 'proto.vtv1.UpdateInventoryAPIStockUpdatesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.GetInventoryAPIStockUpdatesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.GetInventoryAPIStockUpdatesResponse.repeatedFields_, null);
};
goog.inherits(proto.vtv1.GetInventoryAPIStockUpdatesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.GetInventoryAPIStockUpdatesResponse.displayName = 'proto.vtv1.GetInventoryAPIStockUpdatesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.GetInventoriesForSiteForCreateOrderV2Request = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.GetInventoriesForSiteForCreateOrderV2Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.GetInventoriesForSiteForCreateOrderV2Request.displayName = 'proto.vtv1.GetInventoriesForSiteForCreateOrderV2Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.GetInventoriesForSiteForCreateOrderV2Response = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.GetInventoriesForSiteForCreateOrderV2Response.repeatedFields_, null);
};
goog.inherits(proto.vtv1.GetInventoriesForSiteForCreateOrderV2Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.GetInventoriesForSiteForCreateOrderV2Response.displayName = 'proto.vtv1.GetInventoriesForSiteForCreateOrderV2Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.GetInventoriesForSiteForCreateOrderV2Inventory = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.GetInventoriesForSiteForCreateOrderV2Inventory, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.GetInventoriesForSiteForCreateOrderV2Inventory.displayName = 'proto.vtv1.GetInventoriesForSiteForCreateOrderV2Inventory';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.GetRouteGuidesForCreateOrderV2Request = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.GetRouteGuidesForCreateOrderV2Request.repeatedFields_, null);
};
goog.inherits(proto.vtv1.GetRouteGuidesForCreateOrderV2Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.GetRouteGuidesForCreateOrderV2Request.displayName = 'proto.vtv1.GetRouteGuidesForCreateOrderV2Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.GetRouteGuidesForCreateOrderV2Response = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.GetRouteGuidesForCreateOrderV2Response.repeatedFields_, null);
};
goog.inherits(proto.vtv1.GetRouteGuidesForCreateOrderV2Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.GetRouteGuidesForCreateOrderV2Response.displayName = 'proto.vtv1.GetRouteGuidesForCreateOrderV2Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.NodeListAllMySupplierSitesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.NodeListAllMySupplierSitesResponse.repeatedFields_, null);
};
goog.inherits(proto.vtv1.NodeListAllMySupplierSitesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.NodeListAllMySupplierSitesResponse.displayName = 'proto.vtv1.NodeListAllMySupplierSitesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.NodeListAllMySupplierSitesSupplier = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.NodeListAllMySupplierSitesSupplier.repeatedFields_, null);
};
goog.inherits(proto.vtv1.NodeListAllMySupplierSitesSupplier, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.NodeListAllMySupplierSitesSupplier.displayName = 'proto.vtv1.NodeListAllMySupplierSitesSupplier';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.ListInNetworkCustomersResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.ListInNetworkCustomersResponse.repeatedFields_, null);
};
goog.inherits(proto.vtv1.ListInNetworkCustomersResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.ListInNetworkCustomersResponse.displayName = 'proto.vtv1.ListInNetworkCustomersResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.ReportSiteDownTimeRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.ReportSiteDownTimeRequest.repeatedFields_, null);
};
goog.inherits(proto.vtv1.ReportSiteDownTimeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.ReportSiteDownTimeRequest.displayName = 'proto.vtv1.ReportSiteDownTimeRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.ListSiteDownTimeHistoryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.ListSiteDownTimeHistoryResponse.repeatedFields_, null);
};
goog.inherits(proto.vtv1.ListSiteDownTimeHistoryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.ListSiteDownTimeHistoryResponse.displayName = 'proto.vtv1.ListSiteDownTimeHistoryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.SiteDownTimeHistoryEntry = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.SiteDownTimeHistoryEntry.repeatedFields_, null);
};
goog.inherits(proto.vtv1.SiteDownTimeHistoryEntry, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.SiteDownTimeHistoryEntry.displayName = 'proto.vtv1.SiteDownTimeHistoryEntry';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.UploadVanguardDemandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.UploadVanguardDemandRequest.repeatedFields_, null);
};
goog.inherits(proto.vtv1.UploadVanguardDemandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.UploadVanguardDemandRequest.displayName = 'proto.vtv1.UploadVanguardDemandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.GetVanguardHistoryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.GetVanguardHistoryResponse.repeatedFields_, null);
};
goog.inherits(proto.vtv1.GetVanguardHistoryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.GetVanguardHistoryResponse.displayName = 'proto.vtv1.GetVanguardHistoryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.EditSiteGroupsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.EditSiteGroupsRequest.repeatedFields_, null);
};
goog.inherits(proto.vtv1.EditSiteGroupsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.EditSiteGroupsRequest.displayName = 'proto.vtv1.EditSiteGroupsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.GetSitesWithPickupsTodayRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.GetSitesWithPickupsTodayRequest.repeatedFields_, null);
};
goog.inherits(proto.vtv1.GetSitesWithPickupsTodayRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.GetSitesWithPickupsTodayRequest.displayName = 'proto.vtv1.GetSitesWithPickupsTodayRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.GetOutboundEnRouteOrdersRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.GetOutboundEnRouteOrdersRequest.repeatedFields_, null);
};
goog.inherits(proto.vtv1.GetOutboundEnRouteOrdersRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.GetOutboundEnRouteOrdersRequest.displayName = 'proto.vtv1.GetOutboundEnRouteOrdersRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.GetOutboundScheduledOrdersRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.GetOutboundScheduledOrdersRequest.repeatedFields_, null);
};
goog.inherits(proto.vtv1.GetOutboundScheduledOrdersRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.GetOutboundScheduledOrdersRequest.displayName = 'proto.vtv1.GetOutboundScheduledOrdersRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.ListPendingOrdersToConfirmRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.ListPendingOrdersToConfirmRequest.repeatedFields_, null);
};
goog.inherits(proto.vtv1.ListPendingOrdersToConfirmRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.ListPendingOrdersToConfirmRequest.displayName = 'proto.vtv1.ListPendingOrdersToConfirmRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.GlobalSearchRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.GlobalSearchRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.GlobalSearchRequest.displayName = 'proto.vtv1.GlobalSearchRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.GlobalSearchResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.GlobalSearchResponse.repeatedFields_, null);
};
goog.inherits(proto.vtv1.GlobalSearchResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.GlobalSearchResponse.displayName = 'proto.vtv1.GlobalSearchResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.GlobalSearchResponseFulfillment = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.GlobalSearchResponseFulfillment, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.GlobalSearchResponseFulfillment.displayName = 'proto.vtv1.GlobalSearchResponseFulfillment';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.GlobalSearchResponseOrder = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.GlobalSearchResponseOrder, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.GlobalSearchResponseOrder.displayName = 'proto.vtv1.GlobalSearchResponseOrder';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.GlobalSearchResponseUser = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.GlobalSearchResponseUser, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.GlobalSearchResponseUser.displayName = 'proto.vtv1.GlobalSearchResponseUser';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.GlobalSearchResponseInventory = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.GlobalSearchResponseInventory, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.GlobalSearchResponseInventory.displayName = 'proto.vtv1.GlobalSearchResponseInventory';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.NodeUpdatePOBillableRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.NodeUpdatePOBillableRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.NodeUpdatePOBillableRequest.displayName = 'proto.vtv1.NodeUpdatePOBillableRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.UpdateMultipleInventoryStockRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.UpdateMultipleInventoryStockRequest.repeatedFields_, null);
};
goog.inherits(proto.vtv1.UpdateMultipleInventoryStockRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.UpdateMultipleInventoryStockRequest.displayName = 'proto.vtv1.UpdateMultipleInventoryStockRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.GetInventoriesWithStockResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.GetInventoriesWithStockResponse.repeatedFields_, null);
};
goog.inherits(proto.vtv1.GetInventoriesWithStockResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.GetInventoriesWithStockResponse.displayName = 'proto.vtv1.GetInventoriesWithStockResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.GetTrailerTypesForFuelMatrixResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.GetTrailerTypesForFuelMatrixResponse.repeatedFields_, null);
};
goog.inherits(proto.vtv1.GetTrailerTypesForFuelMatrixResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.GetTrailerTypesForFuelMatrixResponse.displayName = 'proto.vtv1.GetTrailerTypesForFuelMatrixResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.ListFuelMatricesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.ListFuelMatricesResponse.repeatedFields_, null);
};
goog.inherits(proto.vtv1.ListFuelMatricesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.ListFuelMatricesResponse.displayName = 'proto.vtv1.ListFuelMatricesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.CreateFuelMatrixRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.CreateFuelMatrixRequest.repeatedFields_, null);
};
goog.inherits(proto.vtv1.CreateFuelMatrixRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.CreateFuelMatrixRequest.displayName = 'proto.vtv1.CreateFuelMatrixRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.EditFuelMatrixRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.EditFuelMatrixRequest.repeatedFields_, null);
};
goog.inherits(proto.vtv1.EditFuelMatrixRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.EditFuelMatrixRequest.displayName = 'proto.vtv1.EditFuelMatrixRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.NodeGetSiteStatisticsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.NodeGetSiteStatisticsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.NodeGetSiteStatisticsResponse.displayName = 'proto.vtv1.NodeGetSiteStatisticsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.GetSitesWithPickupsTodayResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.GetSitesWithPickupsTodayResponse.repeatedFields_, null);
};
goog.inherits(proto.vtv1.GetSitesWithPickupsTodayResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.GetSitesWithPickupsTodayResponse.displayName = 'proto.vtv1.GetSitesWithPickupsTodayResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.GetOutboundEnRouteOrdersResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.GetOutboundEnRouteOrdersResponse.repeatedFields_, null);
};
goog.inherits(proto.vtv1.GetOutboundEnRouteOrdersResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.GetOutboundEnRouteOrdersResponse.displayName = 'proto.vtv1.GetOutboundEnRouteOrdersResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.GetOutBoundOnSiteOrdersResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.GetOutBoundOnSiteOrdersResponse.repeatedFields_, null);
};
goog.inherits(proto.vtv1.GetOutBoundOnSiteOrdersResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.GetOutBoundOnSiteOrdersResponse.displayName = 'proto.vtv1.GetOutBoundOnSiteOrdersResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.GetOutboundScheduledOrdersResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.GetOutboundScheduledOrdersResponse.repeatedFields_, null);
};
goog.inherits(proto.vtv1.GetOutboundScheduledOrdersResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.GetOutboundScheduledOrdersResponse.displayName = 'proto.vtv1.GetOutboundScheduledOrdersResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.ListPendingOrdersToConfirmResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.ListPendingOrdersToConfirmResponse.repeatedFields_, null);
};
goog.inherits(proto.vtv1.ListPendingOrdersToConfirmResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.ListPendingOrdersToConfirmResponse.displayName = 'proto.vtv1.ListPendingOrdersToConfirmResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.ListPurchaseOrdersToConfirmRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.ListPurchaseOrdersToConfirmRequest.repeatedFields_, null);
};
goog.inherits(proto.vtv1.ListPurchaseOrdersToConfirmRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.ListPurchaseOrdersToConfirmRequest.displayName = 'proto.vtv1.ListPurchaseOrdersToConfirmRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.ListPurchaseOrdersToConfirmResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.ListPurchaseOrdersToConfirmResponse.repeatedFields_, null);
};
goog.inherits(proto.vtv1.ListPurchaseOrdersToConfirmResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.ListPurchaseOrdersToConfirmResponse.displayName = 'proto.vtv1.ListPurchaseOrdersToConfirmResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.GetInventoriesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.GetInventoriesResponse.repeatedFields_, null);
};
goog.inherits(proto.vtv1.GetInventoriesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.GetInventoriesResponse.displayName = 'proto.vtv1.GetInventoriesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.GetInventoryIssuesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.GetInventoryIssuesResponse.repeatedFields_, null);
};
goog.inherits(proto.vtv1.GetInventoryIssuesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.GetInventoryIssuesResponse.displayName = 'proto.vtv1.GetInventoryIssuesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.GetOutboundInventoriesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.GetOutboundInventoriesResponse.repeatedFields_, null);
};
goog.inherits(proto.vtv1.GetOutboundInventoriesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.GetOutboundInventoriesResponse.displayName = 'proto.vtv1.GetOutboundInventoriesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.GetInboundInventoriesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.GetInboundInventoriesResponse.repeatedFields_, null);
};
goog.inherits(proto.vtv1.GetInboundInventoriesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.GetInboundInventoriesResponse.displayName = 'proto.vtv1.GetInboundInventoriesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.NodeListTodaysShipmentsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.NodeListTodaysShipmentsResponse.repeatedFields_, null);
};
goog.inherits(proto.vtv1.NodeListTodaysShipmentsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.NodeListTodaysShipmentsResponse.displayName = 'proto.vtv1.NodeListTodaysShipmentsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.ListInNetworkSuppliersRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.ListInNetworkSuppliersRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.ListInNetworkSuppliersRequest.displayName = 'proto.vtv1.ListInNetworkSuppliersRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.CreateOrderRequestClonedInventory = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.CreateOrderRequestClonedInventory, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.CreateOrderRequestClonedInventory.displayName = 'proto.vtv1.CreateOrderRequestClonedInventory';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.CreateOrderRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.CreateOrderRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.CreateOrderRequest.displayName = 'proto.vtv1.CreateOrderRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.CreateOrderResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.CreateOrderResponse.repeatedFields_, null);
};
goog.inherits(proto.vtv1.CreateOrderResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.CreateOrderResponse.displayName = 'proto.vtv1.CreateOrderResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.GetPurchaseOrdersForCreateV2OrderRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.GetPurchaseOrdersForCreateV2OrderRequest.repeatedFields_, null);
};
goog.inherits(proto.vtv1.GetPurchaseOrdersForCreateV2OrderRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.GetPurchaseOrdersForCreateV2OrderRequest.displayName = 'proto.vtv1.GetPurchaseOrdersForCreateV2OrderRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.CreateMultiStopOrderRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.CreateMultiStopOrderRequest.repeatedFields_, null);
};
goog.inherits(proto.vtv1.CreateMultiStopOrderRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.CreateMultiStopOrderRequest.displayName = 'proto.vtv1.CreateMultiStopOrderRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.CreateMultiStopOrderRequestStop = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.CreateMultiStopOrderRequestStop.repeatedFields_, null);
};
goog.inherits(proto.vtv1.CreateMultiStopOrderRequestStop, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.CreateMultiStopOrderRequestStop.displayName = 'proto.vtv1.CreateMultiStopOrderRequestStop';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.CreateMultiStopOrderRequestStopTask = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.CreateMultiStopOrderRequestStopTask, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.CreateMultiStopOrderRequestStopTask.displayName = 'proto.vtv1.CreateMultiStopOrderRequestStopTask';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.CancelOrderRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.CancelOrderRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.CancelOrderRequest.displayName = 'proto.vtv1.CancelOrderRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.CancelMultipleOrdersRequestNode = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.CancelMultipleOrdersRequestNode.repeatedFields_, null);
};
goog.inherits(proto.vtv1.CancelMultipleOrdersRequestNode, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.CancelMultipleOrdersRequestNode.displayName = 'proto.vtv1.CancelMultipleOrdersRequestNode';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.ListSupplierSitesForCreateOrderRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.ListSupplierSitesForCreateOrderRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.ListSupplierSitesForCreateOrderRequest.displayName = 'proto.vtv1.ListSupplierSitesForCreateOrderRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.ListSupplierSitesForCreateOrderResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.ListSupplierSitesForCreateOrderResponse.repeatedFields_, null);
};
goog.inherits(proto.vtv1.ListSupplierSitesForCreateOrderResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.ListSupplierSitesForCreateOrderResponse.displayName = 'proto.vtv1.ListSupplierSitesForCreateOrderResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.ListPurchaseOrdersForCreateOrderRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.ListPurchaseOrdersForCreateOrderRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.ListPurchaseOrdersForCreateOrderRequest.displayName = 'proto.vtv1.ListPurchaseOrdersForCreateOrderRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.ListEdgeContractsForCreateOrdersRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.ListEdgeContractsForCreateOrdersRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.ListEdgeContractsForCreateOrdersRequest.displayName = 'proto.vtv1.ListEdgeContractsForCreateOrdersRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.CreateOrderPurchaseOrder = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.CreateOrderPurchaseOrder, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.CreateOrderPurchaseOrder.displayName = 'proto.vtv1.CreateOrderPurchaseOrder';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.ListPurchaseOrdersForCreateOrderResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.ListPurchaseOrdersForCreateOrderResponse.repeatedFields_, null);
};
goog.inherits(proto.vtv1.ListPurchaseOrdersForCreateOrderResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.ListPurchaseOrdersForCreateOrderResponse.displayName = 'proto.vtv1.ListPurchaseOrdersForCreateOrderResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.CreateOrderEdgeContract = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.CreateOrderEdgeContract, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.CreateOrderEdgeContract.displayName = 'proto.vtv1.CreateOrderEdgeContract';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.SlimSupplierContract = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.SlimSupplierContract, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.SlimSupplierContract.displayName = 'proto.vtv1.SlimSupplierContract';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.ListEdgeContractsForCreateOrdersResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.ListEdgeContractsForCreateOrdersResponse.repeatedFields_, null);
};
goog.inherits(proto.vtv1.ListEdgeContractsForCreateOrdersResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.ListEdgeContractsForCreateOrdersResponse.displayName = 'proto.vtv1.ListEdgeContractsForCreateOrdersResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.ListInNetworkSuppliersResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.ListInNetworkSuppliersResponse.repeatedFields_, null);
};
goog.inherits(proto.vtv1.ListInNetworkSuppliersResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.ListInNetworkSuppliersResponse.displayName = 'proto.vtv1.ListInNetworkSuppliersResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.NodeListSupplierSitesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.NodeListSupplierSitesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.NodeListSupplierSitesRequest.displayName = 'proto.vtv1.NodeListSupplierSitesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.NodeListSupplierSitesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.NodeListSupplierSitesResponse.repeatedFields_, null);
};
goog.inherits(proto.vtv1.NodeListSupplierSitesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.NodeListSupplierSitesResponse.displayName = 'proto.vtv1.NodeListSupplierSitesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.NodeListProductsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.NodeListProductsResponse.repeatedFields_, null);
};
goog.inherits(proto.vtv1.NodeListProductsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.NodeListProductsResponse.displayName = 'proto.vtv1.NodeListProductsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.ListPurchaseOrdersRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.ListPurchaseOrdersRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.ListPurchaseOrdersRequest.displayName = 'proto.vtv1.ListPurchaseOrdersRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.ListPurchaseOrdersResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.ListPurchaseOrdersResponse.repeatedFields_, null);
};
goog.inherits(proto.vtv1.ListPurchaseOrdersResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.ListPurchaseOrdersResponse.displayName = 'proto.vtv1.ListPurchaseOrdersResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.ListPurchaseOrdersAsSupplierResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.ListPurchaseOrdersAsSupplierResponse.repeatedFields_, null);
};
goog.inherits(proto.vtv1.ListPurchaseOrdersAsSupplierResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.ListPurchaseOrdersAsSupplierResponse.displayName = 'proto.vtv1.ListPurchaseOrdersAsSupplierResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.NodeCreatePurchaseOrderRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.NodeCreatePurchaseOrderRequest.repeatedFields_, null);
};
goog.inherits(proto.vtv1.NodeCreatePurchaseOrderRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.NodeCreatePurchaseOrderRequest.displayName = 'proto.vtv1.NodeCreatePurchaseOrderRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.NodeCreatePurchaseOrderForSupplyPlanRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.NodeCreatePurchaseOrderForSupplyPlanRequest.repeatedFields_, null);
};
goog.inherits(proto.vtv1.NodeCreatePurchaseOrderForSupplyPlanRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.NodeCreatePurchaseOrderForSupplyPlanRequest.displayName = 'proto.vtv1.NodeCreatePurchaseOrderForSupplyPlanRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.NodeCreatePurchaseOrderForSupplyPlanResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.NodeCreatePurchaseOrderForSupplyPlanResponse.repeatedFields_, null);
};
goog.inherits(proto.vtv1.NodeCreatePurchaseOrderForSupplyPlanResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.NodeCreatePurchaseOrderForSupplyPlanResponse.displayName = 'proto.vtv1.NodeCreatePurchaseOrderForSupplyPlanResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.NodeCreateVirtualSupplierForSupplyPlanResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.NodeCreateVirtualSupplierForSupplyPlanResponse.repeatedFields_, null);
};
goog.inherits(proto.vtv1.NodeCreateVirtualSupplierForSupplyPlanResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.NodeCreateVirtualSupplierForSupplyPlanResponse.displayName = 'proto.vtv1.NodeCreateVirtualSupplierForSupplyPlanResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.NodeUpsertPurchaseOrderLineItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.NodeUpsertPurchaseOrderLineItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.NodeUpsertPurchaseOrderLineItem.displayName = 'proto.vtv1.NodeUpsertPurchaseOrderLineItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.SinglePurchaseOrderResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.SinglePurchaseOrderResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.SinglePurchaseOrderResponse.displayName = 'proto.vtv1.SinglePurchaseOrderResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.NodeEditPurchaseOrderRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.NodeEditPurchaseOrderRequest.repeatedFields_, null);
};
goog.inherits(proto.vtv1.NodeEditPurchaseOrderRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.NodeEditPurchaseOrderRequest.displayName = 'proto.vtv1.NodeEditPurchaseOrderRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.NodeConfirmOrDeclinePurchaseOrder = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.NodeConfirmOrDeclinePurchaseOrder.repeatedFields_, null);
};
goog.inherits(proto.vtv1.NodeConfirmOrDeclinePurchaseOrder, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.NodeConfirmOrDeclinePurchaseOrder.displayName = 'proto.vtv1.NodeConfirmOrDeclinePurchaseOrder';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.SearchOutNetworkSuppliersByNameRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.SearchOutNetworkSuppliersByNameRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.SearchOutNetworkSuppliersByNameRequest.displayName = 'proto.vtv1.SearchOutNetworkSuppliersByNameRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.SearchOutNetworkSuppliersByNameResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.SearchOutNetworkSuppliersByNameResponse.repeatedFields_, null);
};
goog.inherits(proto.vtv1.SearchOutNetworkSuppliersByNameResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.SearchOutNetworkSuppliersByNameResponse.displayName = 'proto.vtv1.SearchOutNetworkSuppliersByNameResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.AddSupplierContractRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.AddSupplierContractRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.AddSupplierContractRequest.displayName = 'proto.vtv1.AddSupplierContractRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.EditSupplierContractRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.EditSupplierContractRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.EditSupplierContractRequest.displayName = 'proto.vtv1.EditSupplierContractRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.SupplierContract = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.SupplierContract.repeatedFields_, null);
};
goog.inherits(proto.vtv1.SupplierContract, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.SupplierContract.displayName = 'proto.vtv1.SupplierContract';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.SupplierContractSite = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.SupplierContractSite, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.SupplierContractSite.displayName = 'proto.vtv1.SupplierContractSite';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.SupplierContractLineItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.SupplierContractLineItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.SupplierContractLineItem.displayName = 'proto.vtv1.SupplierContractLineItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.SupplierContractAllocation = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.SupplierContractAllocation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.SupplierContractAllocation.displayName = 'proto.vtv1.SupplierContractAllocation';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.AddSupplierContractResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.AddSupplierContractResponse.repeatedFields_, null);
};
goog.inherits(proto.vtv1.AddSupplierContractResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.AddSupplierContractResponse.displayName = 'proto.vtv1.AddSupplierContractResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.EditSupplierContractResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.EditSupplierContractResponse.repeatedFields_, null);
};
goog.inherits(proto.vtv1.EditSupplierContractResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.EditSupplierContractResponse.displayName = 'proto.vtv1.EditSupplierContractResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.GetSupplierContractsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.GetSupplierContractsResponse.repeatedFields_, null);
};
goog.inherits(proto.vtv1.GetSupplierContractsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.GetSupplierContractsResponse.displayName = 'proto.vtv1.GetSupplierContractsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.RemoveSupplierContractRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.RemoveSupplierContractRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.RemoveSupplierContractRequest.displayName = 'proto.vtv1.RemoveSupplierContractRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.RemoveSupplierContractResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.RemoveSupplierContractResponse.repeatedFields_, null);
};
goog.inherits(proto.vtv1.RemoveSupplierContractResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.RemoveSupplierContractResponse.displayName = 'proto.vtv1.RemoveSupplierContractResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.AddSiteGroupRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.AddSiteGroupRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.AddSiteGroupRequest.displayName = 'proto.vtv1.AddSiteGroupRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.AddSiteGroupResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.AddSiteGroupResponse.repeatedFields_, null);
};
goog.inherits(proto.vtv1.AddSiteGroupResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.AddSiteGroupResponse.displayName = 'proto.vtv1.AddSiteGroupResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.RemoveSiteGroupRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.RemoveSiteGroupRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.RemoveSiteGroupRequest.displayName = 'proto.vtv1.RemoveSiteGroupRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.RemoveSiteGroupResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.RemoveSiteGroupResponse.repeatedFields_, null);
};
goog.inherits(proto.vtv1.RemoveSiteGroupResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.RemoveSiteGroupResponse.displayName = 'proto.vtv1.RemoveSiteGroupResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.ListSiteGroupsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.ListSiteGroupsResponse.repeatedFields_, null);
};
goog.inherits(proto.vtv1.ListSiteGroupsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.ListSiteGroupsResponse.displayName = 'proto.vtv1.ListSiteGroupsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.NodeCreateSiteRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.NodeCreateSiteRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.NodeCreateSiteRequest.displayName = 'proto.vtv1.NodeCreateSiteRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.NodeCreateSiteResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.NodeCreateSiteResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.NodeCreateSiteResponse.displayName = 'proto.vtv1.NodeCreateSiteResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.NodeActiveSiteResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.NodeActiveSiteResponse.repeatedFields_, null);
};
goog.inherits(proto.vtv1.NodeActiveSiteResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.NodeActiveSiteResponse.displayName = 'proto.vtv1.NodeActiveSiteResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.NodeEditSiteRequestInventoryLineItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.NodeEditSiteRequestInventoryLineItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.NodeEditSiteRequestInventoryLineItem.displayName = 'proto.vtv1.NodeEditSiteRequestInventoryLineItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.NodeEditSiteRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.NodeEditSiteRequest.repeatedFields_, null);
};
goog.inherits(proto.vtv1.NodeEditSiteRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.NodeEditSiteRequest.displayName = 'proto.vtv1.NodeEditSiteRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.NodeEditSiteResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.NodeEditSiteResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.NodeEditSiteResponse.displayName = 'proto.vtv1.NodeEditSiteResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.NodeListFinalStateOrdersResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.NodeListFinalStateOrdersResponse.repeatedFields_, null);
};
goog.inherits(proto.vtv1.NodeListFinalStateOrdersResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.NodeListFinalStateOrdersResponse.displayName = 'proto.vtv1.NodeListFinalStateOrdersResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.NodeListPageRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.NodeListPageRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.NodeListPageRequest.displayName = 'proto.vtv1.NodeListPageRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.NodeFinalizedOrdersRequestDeliveryDateFilter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.NodeFinalizedOrdersRequestDeliveryDateFilter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.NodeFinalizedOrdersRequestDeliveryDateFilter.displayName = 'proto.vtv1.NodeFinalizedOrdersRequestDeliveryDateFilter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.NodeFinalizedOrdersRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.NodeFinalizedOrdersRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.NodeFinalizedOrdersRequest.displayName = 'proto.vtv1.NodeFinalizedOrdersRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.NodeListArchivedSitesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.NodeListArchivedSitesResponse.repeatedFields_, null);
};
goog.inherits(proto.vtv1.NodeListArchivedSitesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.NodeListArchivedSitesResponse.displayName = 'proto.vtv1.NodeListArchivedSitesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.NodeListSitesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.NodeListSitesResponse.repeatedFields_, null);
};
goog.inherits(proto.vtv1.NodeListSitesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.NodeListSitesResponse.displayName = 'proto.vtv1.NodeListSitesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.NodeGetSiteResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.NodeGetSiteResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.NodeGetSiteResponse.displayName = 'proto.vtv1.NodeGetSiteResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.NodeListDistrictsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.NodeListDistrictsResponse.repeatedFields_, null);
};
goog.inherits(proto.vtv1.NodeListDistrictsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.NodeListDistrictsResponse.displayName = 'proto.vtv1.NodeListDistrictsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.NodeListDistrictsBySitesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.NodeListDistrictsBySitesResponse.repeatedFields_, null);
};
goog.inherits(proto.vtv1.NodeListDistrictsBySitesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.NodeListDistrictsBySitesResponse.displayName = 'proto.vtv1.NodeListDistrictsBySitesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.CreateInventoryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.CreateInventoryRequest.repeatedFields_, null);
};
goog.inherits(proto.vtv1.CreateInventoryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.CreateInventoryRequest.displayName = 'proto.vtv1.CreateInventoryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.NodeListDistrictsBySitesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.NodeListDistrictsBySitesRequest.repeatedFields_, null);
};
goog.inherits(proto.vtv1.NodeListDistrictsBySitesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.NodeListDistrictsBySitesRequest.displayName = 'proto.vtv1.NodeListDistrictsBySitesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.CreateInventoryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.CreateInventoryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.CreateInventoryResponse.displayName = 'proto.vtv1.CreateInventoryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.ListSiteInventoriesByGroupResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.ListSiteInventoriesByGroupResponse.repeatedFields_, null);
};
goog.inherits(proto.vtv1.ListSiteInventoriesByGroupResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.ListSiteInventoriesByGroupResponse.displayName = 'proto.vtv1.ListSiteInventoriesByGroupResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.ListSiteInventoriesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.ListSiteInventoriesResponse.repeatedFields_, null);
};
goog.inherits(proto.vtv1.ListSiteInventoriesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.ListSiteInventoriesResponse.displayName = 'proto.vtv1.ListSiteInventoriesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.EditInventoryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.EditInventoryRequest.repeatedFields_, null);
};
goog.inherits(proto.vtv1.EditInventoryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.EditInventoryRequest.displayName = 'proto.vtv1.EditInventoryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.EditInventoryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.EditInventoryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.EditInventoryResponse.displayName = 'proto.vtv1.EditInventoryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.UpdateInventoryStockRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.UpdateInventoryStockRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.UpdateInventoryStockRequest.displayName = 'proto.vtv1.UpdateInventoryStockRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.UpdateInventoryStockResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.UpdateInventoryStockResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.UpdateInventoryStockResponse.displayName = 'proto.vtv1.UpdateInventoryStockResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.InventoryDetailResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.InventoryDetailResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.InventoryDetailResponse.displayName = 'proto.vtv1.InventoryDetailResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.PotentialSuppliersResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.PotentialSuppliersResponse.repeatedFields_, null);
};
goog.inherits(proto.vtv1.PotentialSuppliersResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.PotentialSuppliersResponse.displayName = 'proto.vtv1.PotentialSuppliersResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.PotentialPurchaseOrdersResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.PotentialPurchaseOrdersResponse.repeatedFields_, null);
};
goog.inherits(proto.vtv1.PotentialPurchaseOrdersResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.PotentialPurchaseOrdersResponse.displayName = 'proto.vtv1.PotentialPurchaseOrdersResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.PotentialPurchaseOrdersV2Response = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.PotentialPurchaseOrdersV2Response.repeatedFields_, null);
};
goog.inherits(proto.vtv1.PotentialPurchaseOrdersV2Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.PotentialPurchaseOrdersV2Response.displayName = 'proto.vtv1.PotentialPurchaseOrdersV2Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.EditInventorySupplyPlanRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.EditInventorySupplyPlanRequest.repeatedFields_, null);
};
goog.inherits(proto.vtv1.EditInventorySupplyPlanRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.EditInventorySupplyPlanRequest.displayName = 'proto.vtv1.EditInventorySupplyPlanRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.EditInventorySupplyPlanResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.EditInventorySupplyPlanResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.EditInventorySupplyPlanResponse.displayName = 'proto.vtv1.EditInventorySupplyPlanResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.GetInventoryScheduledSpotMarketRequestsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.GetInventoryScheduledSpotMarketRequestsResponse.repeatedFields_, null);
};
goog.inherits(proto.vtv1.GetInventoryScheduledSpotMarketRequestsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.GetInventoryScheduledSpotMarketRequestsResponse.displayName = 'proto.vtv1.GetInventoryScheduledSpotMarketRequestsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.GetInventoryCurrentSpotMarketRequestsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.GetInventoryCurrentSpotMarketRequestsResponse.repeatedFields_, null);
};
goog.inherits(proto.vtv1.GetInventoryCurrentSpotMarketRequestsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.GetInventoryCurrentSpotMarketRequestsResponse.displayName = 'proto.vtv1.GetInventoryCurrentSpotMarketRequestsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.EditInventorySupplyPlanSupplier = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.EditInventorySupplyPlanSupplier, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.EditInventorySupplyPlanSupplier.displayName = 'proto.vtv1.EditInventorySupplyPlanSupplier';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.InventorySupplyPlanResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.InventorySupplyPlanResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.InventorySupplyPlanResponse.displayName = 'proto.vtv1.InventorySupplyPlanResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.CreateInventoryGroupRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.CreateInventoryGroupRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.CreateInventoryGroupRequest.displayName = 'proto.vtv1.CreateInventoryGroupRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.ListInventoryGroupResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.ListInventoryGroupResponse.repeatedFields_, null);
};
goog.inherits(proto.vtv1.ListInventoryGroupResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.ListInventoryGroupResponse.displayName = 'proto.vtv1.ListInventoryGroupResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.EditInventoryGroupRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.EditInventoryGroupRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.EditInventoryGroupRequest.displayName = 'proto.vtv1.EditInventoryGroupRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.AddInventoryToGroupRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.AddInventoryToGroupRequest.repeatedFields_, null);
};
goog.inherits(proto.vtv1.AddInventoryToGroupRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.AddInventoryToGroupRequest.displayName = 'proto.vtv1.AddInventoryToGroupRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.UpsertSiteLoadingScheduleRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.UpsertSiteLoadingScheduleRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.UpsertSiteLoadingScheduleRequest.displayName = 'proto.vtv1.UpsertSiteLoadingScheduleRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.UpsertSiteLoadingScheduleResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.UpsertSiteLoadingScheduleResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.UpsertSiteLoadingScheduleResponse.displayName = 'proto.vtv1.UpsertSiteLoadingScheduleResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.GetSiteLoadingScheduleResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.GetSiteLoadingScheduleResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.GetSiteLoadingScheduleResponse.displayName = 'proto.vtv1.GetSiteLoadingScheduleResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.NodeListAtEdgeOrdersResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.NodeListAtEdgeOrdersResponse.repeatedFields_, null);
};
goog.inherits(proto.vtv1.NodeListAtEdgeOrdersResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.NodeListAtEdgeOrdersResponse.displayName = 'proto.vtv1.NodeListAtEdgeOrdersResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.NodeListAtSupplierOrdersResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.NodeListAtSupplierOrdersResponse.repeatedFields_, null);
};
goog.inherits(proto.vtv1.NodeListAtSupplierOrdersResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.NodeListAtSupplierOrdersResponse.displayName = 'proto.vtv1.NodeListAtSupplierOrdersResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.NodeListScheduledOrdersResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.NodeListScheduledOrdersResponse.repeatedFields_, null);
};
goog.inherits(proto.vtv1.NodeListScheduledOrdersResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.NodeListScheduledOrdersResponse.displayName = 'proto.vtv1.NodeListScheduledOrdersResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.NodeListInProgressOrdersResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.NodeListInProgressOrdersResponse.repeatedFields_, null);
};
goog.inherits(proto.vtv1.NodeListInProgressOrdersResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.NodeListInProgressOrdersResponse.displayName = 'proto.vtv1.NodeListInProgressOrdersResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.NodeListArrivedOrdersResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.NodeListArrivedOrdersResponse.repeatedFields_, null);
};
goog.inherits(proto.vtv1.NodeListArrivedOrdersResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.NodeListArrivedOrdersResponse.displayName = 'proto.vtv1.NodeListArrivedOrdersResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.UpdateRestockStrategyRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.UpdateRestockStrategyRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.UpdateRestockStrategyRequest.displayName = 'proto.vtv1.UpdateRestockStrategyRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.NodeCreateInventoryReportRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.NodeCreateInventoryReportRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.NodeCreateInventoryReportRequest.displayName = 'proto.vtv1.NodeCreateInventoryReportRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.NodeUpdateInventoryReportRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.NodeUpdateInventoryReportRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.NodeUpdateInventoryReportRequest.displayName = 'proto.vtv1.NodeUpdateInventoryReportRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.NodeCreateSiteReportRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.NodeCreateSiteReportRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.NodeCreateSiteReportRequest.displayName = 'proto.vtv1.NodeCreateSiteReportRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.NodeUpdateSiteReportRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.NodeUpdateSiteReportRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.NodeUpdateSiteReportRequest.displayName = 'proto.vtv1.NodeUpdateSiteReportRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.GetDashboardSiteDetailResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.GetDashboardSiteDetailResponse.repeatedFields_, null);
};
goog.inherits(proto.vtv1.GetDashboardSiteDetailResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.GetDashboardSiteDetailResponse.displayName = 'proto.vtv1.GetDashboardSiteDetailResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.GetDashboardEdgeDetailResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.GetDashboardEdgeDetailResponse.repeatedFields_, null);
};
goog.inherits(proto.vtv1.GetDashboardEdgeDetailResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.GetDashboardEdgeDetailResponse.displayName = 'proto.vtv1.GetDashboardEdgeDetailResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.GetDashboardSupplierDetailResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.GetDashboardSupplierDetailResponse.repeatedFields_, null);
};
goog.inherits(proto.vtv1.GetDashboardSupplierDetailResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.GetDashboardSupplierDetailResponse.displayName = 'proto.vtv1.GetDashboardSupplierDetailResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.GetDashboardAutopilotFeedResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.GetDashboardAutopilotFeedResponse.repeatedFields_, null);
};
goog.inherits(proto.vtv1.GetDashboardAutopilotFeedResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.GetDashboardAutopilotFeedResponse.displayName = 'proto.vtv1.GetDashboardAutopilotFeedResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.SupplierCreateSiteResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.SupplierCreateSiteResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.SupplierCreateSiteResponse.displayName = 'proto.vtv1.SupplierCreateSiteResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.CreateResponseCriteria = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.CreateResponseCriteria, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.CreateResponseCriteria.displayName = 'proto.vtv1.CreateResponseCriteria';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.CreateEdgeContractRequestAsNode = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.CreateEdgeContractRequestAsNode.repeatedFields_, null);
};
goog.inherits(proto.vtv1.CreateEdgeContractRequestAsNode, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.CreateEdgeContractRequestAsNode.displayName = 'proto.vtv1.CreateEdgeContractRequestAsNode';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.EditEdgeContractRequestAsNode = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.EditEdgeContractRequestAsNode.repeatedFields_, null);
};
goog.inherits(proto.vtv1.EditEdgeContractRequestAsNode, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.EditEdgeContractRequestAsNode.displayName = 'proto.vtv1.EditEdgeContractRequestAsNode';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.CreateVirtualSupplierSiteRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.CreateVirtualSupplierSiteRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.CreateVirtualSupplierSiteRequest.displayName = 'proto.vtv1.CreateVirtualSupplierSiteRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.CreateVirtualSupplierSiteResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.CreateVirtualSupplierSiteResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.CreateVirtualSupplierSiteResponse.displayName = 'proto.vtv1.CreateVirtualSupplierSiteResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.CreateVirtualSupplierRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.CreateVirtualSupplierRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.CreateVirtualSupplierRequest.displayName = 'proto.vtv1.CreateVirtualSupplierRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.NodeCreateVirtualSupplierForSupplyPlanRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.NodeCreateVirtualSupplierForSupplyPlanRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.NodeCreateVirtualSupplierForSupplyPlanRequest.displayName = 'proto.vtv1.NodeCreateVirtualSupplierForSupplyPlanRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.ListEdgeContractsResponseAsNode = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.ListEdgeContractsResponseAsNode.repeatedFields_, null);
};
goog.inherits(proto.vtv1.ListEdgeContractsResponseAsNode, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.ListEdgeContractsResponseAsNode.displayName = 'proto.vtv1.ListEdgeContractsResponseAsNode';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.ListContractsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.ListContractsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.ListContractsRequest.displayName = 'proto.vtv1.ListContractsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.ListDeliverableSitesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.ListDeliverableSitesResponse.repeatedFields_, null);
};
goog.inherits(proto.vtv1.ListDeliverableSitesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.ListDeliverableSitesResponse.displayName = 'proto.vtv1.ListDeliverableSitesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.CreateRouteGuideRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.CreateRouteGuideRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.CreateRouteGuideRequest.displayName = 'proto.vtv1.CreateRouteGuideRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.UpsertRouteGuideRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.UpsertRouteGuideRequest.repeatedFields_, null);
};
goog.inherits(proto.vtv1.UpsertRouteGuideRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.UpsertRouteGuideRequest.displayName = 'proto.vtv1.UpsertRouteGuideRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.RouteGuideResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.RouteGuideResponse.repeatedFields_, null);
};
goog.inherits(proto.vtv1.RouteGuideResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.RouteGuideResponse.displayName = 'proto.vtv1.RouteGuideResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.RouteGuideEdgesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.RouteGuideEdgesResponse.repeatedFields_, null);
};
goog.inherits(proto.vtv1.RouteGuideEdgesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.RouteGuideEdgesResponse.displayName = 'proto.vtv1.RouteGuideEdgesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.RouteGuideSpotEdgeContractsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.RouteGuideSpotEdgeContractsResponse.repeatedFields_, null);
};
goog.inherits(proto.vtv1.RouteGuideSpotEdgeContractsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.RouteGuideSpotEdgeContractsResponse.displayName = 'proto.vtv1.RouteGuideSpotEdgeContractsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.ListEdgesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.ListEdgesResponse.repeatedFields_, null);
};
goog.inherits(proto.vtv1.ListEdgesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.ListEdgesResponse.displayName = 'proto.vtv1.ListEdgesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.NodeBulkPurchaseOrderLineItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.NodeBulkPurchaseOrderLineItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.NodeBulkPurchaseOrderLineItem.displayName = 'proto.vtv1.NodeBulkPurchaseOrderLineItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.MatchAttemptedString = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.MatchAttemptedString, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.MatchAttemptedString.displayName = 'proto.vtv1.MatchAttemptedString';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.MatchAttemptedStrings = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.MatchAttemptedStrings.repeatedFields_, null);
};
goog.inherits(proto.vtv1.MatchAttemptedStrings, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.MatchAttemptedStrings.displayName = 'proto.vtv1.MatchAttemptedStrings';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.NodeBulkUploadPOVerification = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.NodeBulkUploadPOVerification.repeatedFields_, null);
};
goog.inherits(proto.vtv1.NodeBulkUploadPOVerification, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.NodeBulkUploadPOVerification.displayName = 'proto.vtv1.NodeBulkUploadPOVerification';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.NodeBulkUploadPOLineItemVerification = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.NodeBulkUploadPOLineItemVerification.repeatedFields_, null);
};
goog.inherits(proto.vtv1.NodeBulkUploadPOLineItemVerification, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.NodeBulkUploadPOLineItemVerification.displayName = 'proto.vtv1.NodeBulkUploadPOLineItemVerification';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.NodeBulkUploadPORequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.NodeBulkUploadPORequest.repeatedFields_, null);
};
goog.inherits(proto.vtv1.NodeBulkUploadPORequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.NodeBulkUploadPORequest.displayName = 'proto.vtv1.NodeBulkUploadPORequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.NodeBulkUploadPOResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.NodeBulkUploadPOResponse.repeatedFields_, null);
};
goog.inherits(proto.vtv1.NodeBulkUploadPOResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.NodeBulkUploadPOResponse.displayName = 'proto.vtv1.NodeBulkUploadPOResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.NodeBulkUploadSupplierSite = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.NodeBulkUploadSupplierSite, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.NodeBulkUploadSupplierSite.displayName = 'proto.vtv1.NodeBulkUploadSupplierSite';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.NodeBulkUploadSupplierVerification = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.NodeBulkUploadSupplierVerification.repeatedFields_, null);
};
goog.inherits(proto.vtv1.NodeBulkUploadSupplierVerification, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.NodeBulkUploadSupplierVerification.displayName = 'proto.vtv1.NodeBulkUploadSupplierVerification';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.NodeBulkUploadSupplierSiteVerification = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.NodeBulkUploadSupplierSiteVerification.repeatedFields_, null);
};
goog.inherits(proto.vtv1.NodeBulkUploadSupplierSiteVerification, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.NodeBulkUploadSupplierSiteVerification.displayName = 'proto.vtv1.NodeBulkUploadSupplierSiteVerification';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.NodeBulkUploadSupplierSiteRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.NodeBulkUploadSupplierSiteRequest.repeatedFields_, null);
};
goog.inherits(proto.vtv1.NodeBulkUploadSupplierSiteRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.NodeBulkUploadSupplierSiteRequest.displayName = 'proto.vtv1.NodeBulkUploadSupplierSiteRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.NodeBulkUploadSupplierSiteResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.NodeBulkUploadSupplierSiteResponse.repeatedFields_, null);
};
goog.inherits(proto.vtv1.NodeBulkUploadSupplierSiteResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.NodeBulkUploadSupplierSiteResponse.displayName = 'proto.vtv1.NodeBulkUploadSupplierSiteResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.HasPOForProductResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.HasPOForProductResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.HasPOForProductResponse.displayName = 'proto.vtv1.HasPOForProductResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.SupplyPlanSummary = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.SupplyPlanSummary.repeatedFields_, null);
};
goog.inherits(proto.vtv1.SupplyPlanSummary, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.SupplyPlanSummary.displayName = 'proto.vtv1.SupplyPlanSummary';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.NodeListSupplyPlansResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.NodeListSupplyPlansResponse.repeatedFields_, null);
};
goog.inherits(proto.vtv1.NodeListSupplyPlansResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.NodeListSupplyPlansResponse.displayName = 'proto.vtv1.NodeListSupplyPlansResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.NodeListPurchaseOrdersForInventoryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.NodeListPurchaseOrdersForInventoryResponse.repeatedFields_, null);
};
goog.inherits(proto.vtv1.NodeListPurchaseOrdersForInventoryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.NodeListPurchaseOrdersForInventoryResponse.displayName = 'proto.vtv1.NodeListPurchaseOrdersForInventoryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.ConsumerOrderWarningCountsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.ConsumerOrderWarningCountsResponse.repeatedFields_, null);
};
goog.inherits(proto.vtv1.ConsumerOrderWarningCountsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.ConsumerOrderWarningCountsResponse.displayName = 'proto.vtv1.ConsumerOrderWarningCountsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.BulkUploadSiteRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.BulkUploadSiteRequest.repeatedFields_, null);
};
goog.inherits(proto.vtv1.BulkUploadSiteRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.BulkUploadSiteRequest.displayName = 'proto.vtv1.BulkUploadSiteRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.ValidationErrorMessage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.ValidationErrorMessage.repeatedFields_, null);
};
goog.inherits(proto.vtv1.ValidationErrorMessage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.ValidationErrorMessage.displayName = 'proto.vtv1.ValidationErrorMessage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.BulkUploadSiteResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.BulkUploadSiteResponse.repeatedFields_, null);
};
goog.inherits(proto.vtv1.BulkUploadSiteResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.BulkUploadSiteResponse.displayName = 'proto.vtv1.BulkUploadSiteResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.BulkUploadSite = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.BulkUploadSite, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.BulkUploadSite.displayName = 'proto.vtv1.BulkUploadSite';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.BulkUploadInventoryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.BulkUploadInventoryRequest.repeatedFields_, null);
};
goog.inherits(proto.vtv1.BulkUploadInventoryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.BulkUploadInventoryRequest.displayName = 'proto.vtv1.BulkUploadInventoryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.BulkUploadInventoryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.BulkUploadInventoryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.BulkUploadInventoryResponse.displayName = 'proto.vtv1.BulkUploadInventoryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.BulkUploadInventory = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.BulkUploadInventory, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.BulkUploadInventory.displayName = 'proto.vtv1.BulkUploadInventory';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.CreateFinishedGoodOrderRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.CreateFinishedGoodOrderRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.CreateFinishedGoodOrderRequest.displayName = 'proto.vtv1.CreateFinishedGoodOrderRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.CreateFinishedGoodOrderResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.CreateFinishedGoodOrderResponse.repeatedFields_, null);
};
goog.inherits(proto.vtv1.CreateFinishedGoodOrderResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.CreateFinishedGoodOrderResponse.displayName = 'proto.vtv1.CreateFinishedGoodOrderResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.NodeListFinishedGoodsProductsInventoryForSiteResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.NodeListFinishedGoodsProductsInventoryForSiteResponse.repeatedFields_, null);
};
goog.inherits(proto.vtv1.NodeListFinishedGoodsProductsInventoryForSiteResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.NodeListFinishedGoodsProductsInventoryForSiteResponse.displayName = 'proto.vtv1.NodeListFinishedGoodsProductsInventoryForSiteResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.UploadedOrderData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.UploadedOrderData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.UploadedOrderData.displayName = 'proto.vtv1.UploadedOrderData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.OrderUploadRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.OrderUploadRequest.repeatedFields_, null);
};
goog.inherits(proto.vtv1.OrderUploadRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.OrderUploadRequest.displayName = 'proto.vtv1.OrderUploadRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.OrderUploadResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.OrderUploadResponse.repeatedFields_, null);
};
goog.inherits(proto.vtv1.OrderUploadResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.OrderUploadResponse.displayName = 'proto.vtv1.OrderUploadResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.OrderUploadCreateOrderRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.OrderUploadCreateOrderRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.OrderUploadCreateOrderRequest.displayName = 'proto.vtv1.OrderUploadCreateOrderRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.GetCarrierContractsForCreateOrderV2Request = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.GetCarrierContractsForCreateOrderV2Request.repeatedFields_, null);
};
goog.inherits(proto.vtv1.GetCarrierContractsForCreateOrderV2Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.GetCarrierContractsForCreateOrderV2Request.displayName = 'proto.vtv1.GetCarrierContractsForCreateOrderV2Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.GetCarrierContractsForCreateOrderV2Response = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.GetCarrierContractsForCreateOrderV2Response.repeatedFields_, null);
};
goog.inherits(proto.vtv1.GetCarrierContractsForCreateOrderV2Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.GetCarrierContractsForCreateOrderV2Response.displayName = 'proto.vtv1.GetCarrierContractsForCreateOrderV2Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.ListActiveCostCentersResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.ListActiveCostCentersResponse.repeatedFields_, null);
};
goog.inherits(proto.vtv1.ListActiveCostCentersResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.ListActiveCostCentersResponse.displayName = 'proto.vtv1.ListActiveCostCentersResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.CreateCostCenterRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.CreateCostCenterRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.CreateCostCenterRequest.displayName = 'proto.vtv1.CreateCostCenterRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.UpdateCostCenterRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.UpdateCostCenterRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.UpdateCostCenterRequest.displayName = 'proto.vtv1.UpdateCostCenterRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.CreateCostCenterResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.CreateCostCenterResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.CreateCostCenterResponse.displayName = 'proto.vtv1.CreateCostCenterResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.UpdateCostCenterResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.UpdateCostCenterResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.UpdateCostCenterResponse.displayName = 'proto.vtv1.UpdateCostCenterResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.ListActiveSalesOrdersResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.ListActiveSalesOrdersResponse.repeatedFields_, null);
};
goog.inherits(proto.vtv1.ListActiveSalesOrdersResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.ListActiveSalesOrdersResponse.displayName = 'proto.vtv1.ListActiveSalesOrdersResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.ArchiveCostCenterRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.ArchiveCostCenterRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.ArchiveCostCenterRequest.displayName = 'proto.vtv1.ArchiveCostCenterRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.InventoryDetailSlimResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.InventoryDetailSlimResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.InventoryDetailSlimResponse.displayName = 'proto.vtv1.InventoryDetailSlimResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.InventoryDetailSlimStocksResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.InventoryDetailSlimStocksResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.InventoryDetailSlimStocksResponse.displayName = 'proto.vtv1.InventoryDetailSlimStocksResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.InventoryDetailShipmentsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.InventoryDetailShipmentsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.InventoryDetailShipmentsResponse.displayName = 'proto.vtv1.InventoryDetailShipmentsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.VMIStockUpdateRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.VMIStockUpdateRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.VMIStockUpdateRequest.displayName = 'proto.vtv1.VMIStockUpdateRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.VMInventoryListResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.VMInventoryListResponse.repeatedFields_, null);
};
goog.inherits(proto.vtv1.VMInventoryListResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.VMInventoryListResponse.displayName = 'proto.vtv1.VMInventoryListResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.CreateVmiAccountRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.CreateVmiAccountRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.CreateVmiAccountRequest.displayName = 'proto.vtv1.CreateVmiAccountRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.CreateVmiAccountResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.CreateVmiAccountResponse.repeatedFields_, null);
};
goog.inherits(proto.vtv1.CreateVmiAccountResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.CreateVmiAccountResponse.displayName = 'proto.vtv1.CreateVmiAccountResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.VMIInventorySubscribeUpdateRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.VMIInventorySubscribeUpdateRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.VMIInventorySubscribeUpdateRequest.displayName = 'proto.vtv1.VMIInventorySubscribeUpdateRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.VMIInventoryAvailableUsersResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.VMIInventoryAvailableUsersResponse.repeatedFields_, null);
};
goog.inherits(proto.vtv1.VMIInventoryAvailableUsersResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.VMIInventoryAvailableUsersResponse.displayName = 'proto.vtv1.VMIInventoryAvailableUsersResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.VMIInventorySetOwnerRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.VMIInventorySetOwnerRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.VMIInventorySetOwnerRequest.displayName = 'proto.vtv1.VMIInventorySetOwnerRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.VMIListSlimFacilitiesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.VMIListSlimFacilitiesResponse.repeatedFields_, null);
};
goog.inherits(proto.vtv1.VMIListSlimFacilitiesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.VMIListSlimFacilitiesResponse.displayName = 'proto.vtv1.VMIListSlimFacilitiesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.VMIFacilityDockUpsertRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.VMIFacilityDockUpsertRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.VMIFacilityDockUpsertRequest.displayName = 'proto.vtv1.VMIFacilityDockUpsertRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.VMIEditFacilityRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.VMIEditFacilityRequest.repeatedFields_, null);
};
goog.inherits(proto.vtv1.VMIEditFacilityRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.VMIEditFacilityRequest.displayName = 'proto.vtv1.VMIEditFacilityRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.VMIFacilityDetailResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.VMIFacilityDetailResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.VMIFacilityDetailResponse.displayName = 'proto.vtv1.VMIFacilityDetailResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.VMICreateInventoryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.VMICreateInventoryRequest.repeatedFields_, null);
};
goog.inherits(proto.vtv1.VMICreateInventoryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.VMICreateInventoryRequest.displayName = 'proto.vtv1.VMICreateInventoryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.VMIEditInventoryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.VMIEditInventoryRequest.repeatedFields_, null);
};
goog.inherits(proto.vtv1.VMIEditInventoryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.VMIEditInventoryRequest.displayName = 'proto.vtv1.VMIEditInventoryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.VMIListFacilitiesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.VMIListFacilitiesResponse.repeatedFields_, null);
};
goog.inherits(proto.vtv1.VMIListFacilitiesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.VMIListFacilitiesResponse.displayName = 'proto.vtv1.VMIListFacilitiesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.VMICreateFacilityRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.VMICreateFacilityRequest.repeatedFields_, null);
};
goog.inherits(proto.vtv1.VMICreateFacilityRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.VMICreateFacilityRequest.displayName = 'proto.vtv1.VMICreateFacilityRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.VMIFacilityFileListResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.VMIFacilityFileListResponse.repeatedFields_, null);
};
goog.inherits(proto.vtv1.VMIFacilityFileListResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.VMIFacilityFileListResponse.displayName = 'proto.vtv1.VMIFacilityFileListResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.VMIFacilityRemoveFileRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.VMIFacilityRemoveFileRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.VMIFacilityRemoveFileRequest.displayName = 'proto.vtv1.VMIFacilityRemoveFileRequest';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.NodeGetSiteUsersResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.NodeGetSiteUsersResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.NodeGetSiteUsersResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.NodeGetSiteUsersResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.NodeGetSiteUsersResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    usersList: jspb.Message.toObjectList(msg.getUsersList(),
    proto_vorto_types_types_pb.StringIdName.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.NodeGetSiteUsersResponse}
 */
proto.vtv1.NodeGetSiteUsersResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.NodeGetSiteUsersResponse;
  return proto.vtv1.NodeGetSiteUsersResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.NodeGetSiteUsersResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.NodeGetSiteUsersResponse}
 */
proto.vtv1.NodeGetSiteUsersResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_vorto_types_types_pb.StringIdName;
      reader.readMessage(value,proto_vorto_types_types_pb.StringIdName.deserializeBinaryFromReader);
      msg.addUsers(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.NodeGetSiteUsersResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.NodeGetSiteUsersResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.NodeGetSiteUsersResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.NodeGetSiteUsersResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUsersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto_vorto_types_types_pb.StringIdName.serializeBinaryToWriter
    );
  }
};


/**
 * repeated StringIdName users = 1;
 * @return {!Array<!proto.vtv1.StringIdName>}
 */
proto.vtv1.NodeGetSiteUsersResponse.prototype.getUsersList = function() {
  return /** @type{!Array<!proto.vtv1.StringIdName>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_vorto_types_types_pb.StringIdName, 1));
};


/**
 * @param {!Array<!proto.vtv1.StringIdName>} value
 * @return {!proto.vtv1.NodeGetSiteUsersResponse} returns this
*/
proto.vtv1.NodeGetSiteUsersResponse.prototype.setUsersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.vtv1.StringIdName=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.StringIdName}
 */
proto.vtv1.NodeGetSiteUsersResponse.prototype.addUsers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.vtv1.StringIdName, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.NodeGetSiteUsersResponse} returns this
 */
proto.vtv1.NodeGetSiteUsersResponse.prototype.clearUsersList = function() {
  return this.setUsersList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.UpdateInventoryAPIStockUpdatesRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.UpdateInventoryAPIStockUpdatesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.UpdateInventoryAPIStockUpdatesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.UpdateInventoryAPIStockUpdatesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.UpdateInventoryAPIStockUpdatesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    inventoryId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    stockUpdatesList: jspb.Message.toObjectList(msg.getStockUpdatesList(),
    proto_vorto_node_node_pb.APIStockUpdate.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.UpdateInventoryAPIStockUpdatesRequest}
 */
proto.vtv1.UpdateInventoryAPIStockUpdatesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.UpdateInventoryAPIStockUpdatesRequest;
  return proto.vtv1.UpdateInventoryAPIStockUpdatesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.UpdateInventoryAPIStockUpdatesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.UpdateInventoryAPIStockUpdatesRequest}
 */
proto.vtv1.UpdateInventoryAPIStockUpdatesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setInventoryId(value);
      break;
    case 2:
      var value = new proto_vorto_node_node_pb.APIStockUpdate;
      reader.readMessage(value,proto_vorto_node_node_pb.APIStockUpdate.deserializeBinaryFromReader);
      msg.addStockUpdates(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.UpdateInventoryAPIStockUpdatesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.UpdateInventoryAPIStockUpdatesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.UpdateInventoryAPIStockUpdatesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.UpdateInventoryAPIStockUpdatesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInventoryId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStockUpdatesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto_vorto_node_node_pb.APIStockUpdate.serializeBinaryToWriter
    );
  }
};


/**
 * optional string inventory_id = 1;
 * @return {string}
 */
proto.vtv1.UpdateInventoryAPIStockUpdatesRequest.prototype.getInventoryId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.UpdateInventoryAPIStockUpdatesRequest} returns this
 */
proto.vtv1.UpdateInventoryAPIStockUpdatesRequest.prototype.setInventoryId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated APIStockUpdate stock_updates = 2;
 * @return {!Array<!proto.vtv1.APIStockUpdate>}
 */
proto.vtv1.UpdateInventoryAPIStockUpdatesRequest.prototype.getStockUpdatesList = function() {
  return /** @type{!Array<!proto.vtv1.APIStockUpdate>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_vorto_node_node_pb.APIStockUpdate, 2));
};


/**
 * @param {!Array<!proto.vtv1.APIStockUpdate>} value
 * @return {!proto.vtv1.UpdateInventoryAPIStockUpdatesRequest} returns this
*/
proto.vtv1.UpdateInventoryAPIStockUpdatesRequest.prototype.setStockUpdatesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.vtv1.APIStockUpdate=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.APIStockUpdate}
 */
proto.vtv1.UpdateInventoryAPIStockUpdatesRequest.prototype.addStockUpdates = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.vtv1.APIStockUpdate, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.UpdateInventoryAPIStockUpdatesRequest} returns this
 */
proto.vtv1.UpdateInventoryAPIStockUpdatesRequest.prototype.clearStockUpdatesList = function() {
  return this.setStockUpdatesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.GetInventoryAPIStockUpdatesResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.GetInventoryAPIStockUpdatesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.GetInventoryAPIStockUpdatesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.GetInventoryAPIStockUpdatesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.GetInventoryAPIStockUpdatesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    inventoryId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    stockUpdatesList: jspb.Message.toObjectList(msg.getStockUpdatesList(),
    proto_vorto_node_node_pb.APIStockUpdate.toObject, includeInstance),
    currentConsumptionRate: (f = msg.getCurrentConsumptionRate()) && proto_vorto_types_types_pb.NullableDouble.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.GetInventoryAPIStockUpdatesResponse}
 */
proto.vtv1.GetInventoryAPIStockUpdatesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.GetInventoryAPIStockUpdatesResponse;
  return proto.vtv1.GetInventoryAPIStockUpdatesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.GetInventoryAPIStockUpdatesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.GetInventoryAPIStockUpdatesResponse}
 */
proto.vtv1.GetInventoryAPIStockUpdatesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setInventoryId(value);
      break;
    case 2:
      var value = new proto_vorto_node_node_pb.APIStockUpdate;
      reader.readMessage(value,proto_vorto_node_node_pb.APIStockUpdate.deserializeBinaryFromReader);
      msg.addStockUpdates(value);
      break;
    case 3:
      var value = new proto_vorto_types_types_pb.NullableDouble;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableDouble.deserializeBinaryFromReader);
      msg.setCurrentConsumptionRate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.GetInventoryAPIStockUpdatesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.GetInventoryAPIStockUpdatesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.GetInventoryAPIStockUpdatesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.GetInventoryAPIStockUpdatesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInventoryId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStockUpdatesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto_vorto_node_node_pb.APIStockUpdate.serializeBinaryToWriter
    );
  }
  f = message.getCurrentConsumptionRate();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto_vorto_types_types_pb.NullableDouble.serializeBinaryToWriter
    );
  }
};


/**
 * optional string inventory_id = 1;
 * @return {string}
 */
proto.vtv1.GetInventoryAPIStockUpdatesResponse.prototype.getInventoryId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.GetInventoryAPIStockUpdatesResponse} returns this
 */
proto.vtv1.GetInventoryAPIStockUpdatesResponse.prototype.setInventoryId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated APIStockUpdate stock_updates = 2;
 * @return {!Array<!proto.vtv1.APIStockUpdate>}
 */
proto.vtv1.GetInventoryAPIStockUpdatesResponse.prototype.getStockUpdatesList = function() {
  return /** @type{!Array<!proto.vtv1.APIStockUpdate>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_vorto_node_node_pb.APIStockUpdate, 2));
};


/**
 * @param {!Array<!proto.vtv1.APIStockUpdate>} value
 * @return {!proto.vtv1.GetInventoryAPIStockUpdatesResponse} returns this
*/
proto.vtv1.GetInventoryAPIStockUpdatesResponse.prototype.setStockUpdatesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.vtv1.APIStockUpdate=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.APIStockUpdate}
 */
proto.vtv1.GetInventoryAPIStockUpdatesResponse.prototype.addStockUpdates = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.vtv1.APIStockUpdate, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.GetInventoryAPIStockUpdatesResponse} returns this
 */
proto.vtv1.GetInventoryAPIStockUpdatesResponse.prototype.clearStockUpdatesList = function() {
  return this.setStockUpdatesList([]);
};


/**
 * optional NullableDouble current_consumption_rate = 3;
 * @return {?proto.vtv1.NullableDouble}
 */
proto.vtv1.GetInventoryAPIStockUpdatesResponse.prototype.getCurrentConsumptionRate = function() {
  return /** @type{?proto.vtv1.NullableDouble} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableDouble, 3));
};


/**
 * @param {?proto.vtv1.NullableDouble|undefined} value
 * @return {!proto.vtv1.GetInventoryAPIStockUpdatesResponse} returns this
*/
proto.vtv1.GetInventoryAPIStockUpdatesResponse.prototype.setCurrentConsumptionRate = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.GetInventoryAPIStockUpdatesResponse} returns this
 */
proto.vtv1.GetInventoryAPIStockUpdatesResponse.prototype.clearCurrentConsumptionRate = function() {
  return this.setCurrentConsumptionRate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.GetInventoryAPIStockUpdatesResponse.prototype.hasCurrentConsumptionRate = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.GetInventoriesForSiteForCreateOrderV2Request.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.GetInventoriesForSiteForCreateOrderV2Request.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.GetInventoriesForSiteForCreateOrderV2Request} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.GetInventoriesForSiteForCreateOrderV2Request.toObject = function(includeInstance, msg) {
  var f, obj = {
    siteId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.GetInventoriesForSiteForCreateOrderV2Request}
 */
proto.vtv1.GetInventoriesForSiteForCreateOrderV2Request.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.GetInventoriesForSiteForCreateOrderV2Request;
  return proto.vtv1.GetInventoriesForSiteForCreateOrderV2Request.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.GetInventoriesForSiteForCreateOrderV2Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.GetInventoriesForSiteForCreateOrderV2Request}
 */
proto.vtv1.GetInventoriesForSiteForCreateOrderV2Request.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSiteId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.GetInventoriesForSiteForCreateOrderV2Request.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.GetInventoriesForSiteForCreateOrderV2Request.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.GetInventoriesForSiteForCreateOrderV2Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.GetInventoriesForSiteForCreateOrderV2Request.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSiteId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string site_id = 1;
 * @return {string}
 */
proto.vtv1.GetInventoriesForSiteForCreateOrderV2Request.prototype.getSiteId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.GetInventoriesForSiteForCreateOrderV2Request} returns this
 */
proto.vtv1.GetInventoriesForSiteForCreateOrderV2Request.prototype.setSiteId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.GetInventoriesForSiteForCreateOrderV2Response.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.GetInventoriesForSiteForCreateOrderV2Response.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.GetInventoriesForSiteForCreateOrderV2Response.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.GetInventoriesForSiteForCreateOrderV2Response} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.GetInventoriesForSiteForCreateOrderV2Response.toObject = function(includeInstance, msg) {
  var f, obj = {
    inventoriesList: jspb.Message.toObjectList(msg.getInventoriesList(),
    proto.vtv1.GetInventoriesForSiteForCreateOrderV2Inventory.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.GetInventoriesForSiteForCreateOrderV2Response}
 */
proto.vtv1.GetInventoriesForSiteForCreateOrderV2Response.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.GetInventoriesForSiteForCreateOrderV2Response;
  return proto.vtv1.GetInventoriesForSiteForCreateOrderV2Response.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.GetInventoriesForSiteForCreateOrderV2Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.GetInventoriesForSiteForCreateOrderV2Response}
 */
proto.vtv1.GetInventoriesForSiteForCreateOrderV2Response.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.vtv1.GetInventoriesForSiteForCreateOrderV2Inventory;
      reader.readMessage(value,proto.vtv1.GetInventoriesForSiteForCreateOrderV2Inventory.deserializeBinaryFromReader);
      msg.addInventories(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.GetInventoriesForSiteForCreateOrderV2Response.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.GetInventoriesForSiteForCreateOrderV2Response.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.GetInventoriesForSiteForCreateOrderV2Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.GetInventoriesForSiteForCreateOrderV2Response.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInventoriesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.vtv1.GetInventoriesForSiteForCreateOrderV2Inventory.serializeBinaryToWriter
    );
  }
};


/**
 * repeated GetInventoriesForSiteForCreateOrderV2Inventory inventories = 1;
 * @return {!Array<!proto.vtv1.GetInventoriesForSiteForCreateOrderV2Inventory>}
 */
proto.vtv1.GetInventoriesForSiteForCreateOrderV2Response.prototype.getInventoriesList = function() {
  return /** @type{!Array<!proto.vtv1.GetInventoriesForSiteForCreateOrderV2Inventory>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vtv1.GetInventoriesForSiteForCreateOrderV2Inventory, 1));
};


/**
 * @param {!Array<!proto.vtv1.GetInventoriesForSiteForCreateOrderV2Inventory>} value
 * @return {!proto.vtv1.GetInventoriesForSiteForCreateOrderV2Response} returns this
*/
proto.vtv1.GetInventoriesForSiteForCreateOrderV2Response.prototype.setInventoriesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.vtv1.GetInventoriesForSiteForCreateOrderV2Inventory=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.GetInventoriesForSiteForCreateOrderV2Inventory}
 */
proto.vtv1.GetInventoriesForSiteForCreateOrderV2Response.prototype.addInventories = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.vtv1.GetInventoriesForSiteForCreateOrderV2Inventory, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.GetInventoriesForSiteForCreateOrderV2Response} returns this
 */
proto.vtv1.GetInventoriesForSiteForCreateOrderV2Response.prototype.clearInventoriesList = function() {
  return this.setInventoriesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.GetInventoriesForSiteForCreateOrderV2Inventory.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.GetInventoriesForSiteForCreateOrderV2Inventory.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.GetInventoriesForSiteForCreateOrderV2Inventory} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.GetInventoriesForSiteForCreateOrderV2Inventory.toObject = function(includeInstance, msg) {
  var f, obj = {
    inventoryId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    inventoryName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    productId: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.GetInventoriesForSiteForCreateOrderV2Inventory}
 */
proto.vtv1.GetInventoriesForSiteForCreateOrderV2Inventory.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.GetInventoriesForSiteForCreateOrderV2Inventory;
  return proto.vtv1.GetInventoriesForSiteForCreateOrderV2Inventory.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.GetInventoriesForSiteForCreateOrderV2Inventory} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.GetInventoriesForSiteForCreateOrderV2Inventory}
 */
proto.vtv1.GetInventoriesForSiteForCreateOrderV2Inventory.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setInventoryId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setInventoryName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.GetInventoriesForSiteForCreateOrderV2Inventory.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.GetInventoriesForSiteForCreateOrderV2Inventory.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.GetInventoriesForSiteForCreateOrderV2Inventory} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.GetInventoriesForSiteForCreateOrderV2Inventory.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInventoryId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getInventoryName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getProductId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string inventory_id = 1;
 * @return {string}
 */
proto.vtv1.GetInventoriesForSiteForCreateOrderV2Inventory.prototype.getInventoryId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.GetInventoriesForSiteForCreateOrderV2Inventory} returns this
 */
proto.vtv1.GetInventoriesForSiteForCreateOrderV2Inventory.prototype.setInventoryId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string inventory_name = 2;
 * @return {string}
 */
proto.vtv1.GetInventoriesForSiteForCreateOrderV2Inventory.prototype.getInventoryName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.GetInventoriesForSiteForCreateOrderV2Inventory} returns this
 */
proto.vtv1.GetInventoriesForSiteForCreateOrderV2Inventory.prototype.setInventoryName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string product_id = 3;
 * @return {string}
 */
proto.vtv1.GetInventoriesForSiteForCreateOrderV2Inventory.prototype.getProductId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.GetInventoriesForSiteForCreateOrderV2Inventory} returns this
 */
proto.vtv1.GetInventoriesForSiteForCreateOrderV2Inventory.prototype.setProductId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.GetRouteGuidesForCreateOrderV2Request.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.GetRouteGuidesForCreateOrderV2Request.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.GetRouteGuidesForCreateOrderV2Request.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.GetRouteGuidesForCreateOrderV2Request} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.GetRouteGuidesForCreateOrderV2Request.toObject = function(includeInstance, msg) {
  var f, obj = {
    siteIdsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    productIdsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.GetRouteGuidesForCreateOrderV2Request}
 */
proto.vtv1.GetRouteGuidesForCreateOrderV2Request.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.GetRouteGuidesForCreateOrderV2Request;
  return proto.vtv1.GetRouteGuidesForCreateOrderV2Request.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.GetRouteGuidesForCreateOrderV2Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.GetRouteGuidesForCreateOrderV2Request}
 */
proto.vtv1.GetRouteGuidesForCreateOrderV2Request.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addSiteIds(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addProductIds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.GetRouteGuidesForCreateOrderV2Request.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.GetRouteGuidesForCreateOrderV2Request.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.GetRouteGuidesForCreateOrderV2Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.GetRouteGuidesForCreateOrderV2Request.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSiteIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
  f = message.getProductIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
};


/**
 * repeated string site_ids = 1;
 * @return {!Array<string>}
 */
proto.vtv1.GetRouteGuidesForCreateOrderV2Request.prototype.getSiteIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.vtv1.GetRouteGuidesForCreateOrderV2Request} returns this
 */
proto.vtv1.GetRouteGuidesForCreateOrderV2Request.prototype.setSiteIdsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.vtv1.GetRouteGuidesForCreateOrderV2Request} returns this
 */
proto.vtv1.GetRouteGuidesForCreateOrderV2Request.prototype.addSiteIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.GetRouteGuidesForCreateOrderV2Request} returns this
 */
proto.vtv1.GetRouteGuidesForCreateOrderV2Request.prototype.clearSiteIdsList = function() {
  return this.setSiteIdsList([]);
};


/**
 * repeated string product_ids = 2;
 * @return {!Array<string>}
 */
proto.vtv1.GetRouteGuidesForCreateOrderV2Request.prototype.getProductIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.vtv1.GetRouteGuidesForCreateOrderV2Request} returns this
 */
proto.vtv1.GetRouteGuidesForCreateOrderV2Request.prototype.setProductIdsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.vtv1.GetRouteGuidesForCreateOrderV2Request} returns this
 */
proto.vtv1.GetRouteGuidesForCreateOrderV2Request.prototype.addProductIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.GetRouteGuidesForCreateOrderV2Request} returns this
 */
proto.vtv1.GetRouteGuidesForCreateOrderV2Request.prototype.clearProductIdsList = function() {
  return this.setProductIdsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.GetRouteGuidesForCreateOrderV2Response.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.GetRouteGuidesForCreateOrderV2Response.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.GetRouteGuidesForCreateOrderV2Response.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.GetRouteGuidesForCreateOrderV2Response} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.GetRouteGuidesForCreateOrderV2Response.toObject = function(includeInstance, msg) {
  var f, obj = {
    routeGuidesList: jspb.Message.toObjectList(msg.getRouteGuidesList(),
    proto_vorto_node_node_pb.RouteGuideForCreateOrderV2.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.GetRouteGuidesForCreateOrderV2Response}
 */
proto.vtv1.GetRouteGuidesForCreateOrderV2Response.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.GetRouteGuidesForCreateOrderV2Response;
  return proto.vtv1.GetRouteGuidesForCreateOrderV2Response.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.GetRouteGuidesForCreateOrderV2Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.GetRouteGuidesForCreateOrderV2Response}
 */
proto.vtv1.GetRouteGuidesForCreateOrderV2Response.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_vorto_node_node_pb.RouteGuideForCreateOrderV2;
      reader.readMessage(value,proto_vorto_node_node_pb.RouteGuideForCreateOrderV2.deserializeBinaryFromReader);
      msg.addRouteGuides(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.GetRouteGuidesForCreateOrderV2Response.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.GetRouteGuidesForCreateOrderV2Response.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.GetRouteGuidesForCreateOrderV2Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.GetRouteGuidesForCreateOrderV2Response.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRouteGuidesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto_vorto_node_node_pb.RouteGuideForCreateOrderV2.serializeBinaryToWriter
    );
  }
};


/**
 * repeated RouteGuideForCreateOrderV2 route_guides = 1;
 * @return {!Array<!proto.vtv1.RouteGuideForCreateOrderV2>}
 */
proto.vtv1.GetRouteGuidesForCreateOrderV2Response.prototype.getRouteGuidesList = function() {
  return /** @type{!Array<!proto.vtv1.RouteGuideForCreateOrderV2>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_vorto_node_node_pb.RouteGuideForCreateOrderV2, 1));
};


/**
 * @param {!Array<!proto.vtv1.RouteGuideForCreateOrderV2>} value
 * @return {!proto.vtv1.GetRouteGuidesForCreateOrderV2Response} returns this
*/
proto.vtv1.GetRouteGuidesForCreateOrderV2Response.prototype.setRouteGuidesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.vtv1.RouteGuideForCreateOrderV2=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.RouteGuideForCreateOrderV2}
 */
proto.vtv1.GetRouteGuidesForCreateOrderV2Response.prototype.addRouteGuides = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.vtv1.RouteGuideForCreateOrderV2, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.GetRouteGuidesForCreateOrderV2Response} returns this
 */
proto.vtv1.GetRouteGuidesForCreateOrderV2Response.prototype.clearRouteGuidesList = function() {
  return this.setRouteGuidesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.NodeListAllMySupplierSitesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.NodeListAllMySupplierSitesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.NodeListAllMySupplierSitesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.NodeListAllMySupplierSitesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.NodeListAllMySupplierSitesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    suppliersList: jspb.Message.toObjectList(msg.getSuppliersList(),
    proto.vtv1.NodeListAllMySupplierSitesSupplier.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.NodeListAllMySupplierSitesResponse}
 */
proto.vtv1.NodeListAllMySupplierSitesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.NodeListAllMySupplierSitesResponse;
  return proto.vtv1.NodeListAllMySupplierSitesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.NodeListAllMySupplierSitesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.NodeListAllMySupplierSitesResponse}
 */
proto.vtv1.NodeListAllMySupplierSitesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.vtv1.NodeListAllMySupplierSitesSupplier;
      reader.readMessage(value,proto.vtv1.NodeListAllMySupplierSitesSupplier.deserializeBinaryFromReader);
      msg.addSuppliers(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.NodeListAllMySupplierSitesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.NodeListAllMySupplierSitesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.NodeListAllMySupplierSitesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.NodeListAllMySupplierSitesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuppliersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.vtv1.NodeListAllMySupplierSitesSupplier.serializeBinaryToWriter
    );
  }
};


/**
 * repeated NodeListAllMySupplierSitesSupplier suppliers = 1;
 * @return {!Array<!proto.vtv1.NodeListAllMySupplierSitesSupplier>}
 */
proto.vtv1.NodeListAllMySupplierSitesResponse.prototype.getSuppliersList = function() {
  return /** @type{!Array<!proto.vtv1.NodeListAllMySupplierSitesSupplier>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vtv1.NodeListAllMySupplierSitesSupplier, 1));
};


/**
 * @param {!Array<!proto.vtv1.NodeListAllMySupplierSitesSupplier>} value
 * @return {!proto.vtv1.NodeListAllMySupplierSitesResponse} returns this
*/
proto.vtv1.NodeListAllMySupplierSitesResponse.prototype.setSuppliersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.vtv1.NodeListAllMySupplierSitesSupplier=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.NodeListAllMySupplierSitesSupplier}
 */
proto.vtv1.NodeListAllMySupplierSitesResponse.prototype.addSuppliers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.vtv1.NodeListAllMySupplierSitesSupplier, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.NodeListAllMySupplierSitesResponse} returns this
 */
proto.vtv1.NodeListAllMySupplierSitesResponse.prototype.clearSuppliersList = function() {
  return this.setSuppliersList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.NodeListAllMySupplierSitesSupplier.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.NodeListAllMySupplierSitesSupplier.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.NodeListAllMySupplierSitesSupplier.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.NodeListAllMySupplierSitesSupplier} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.NodeListAllMySupplierSitesSupplier.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    sitesList: jspb.Message.toObjectList(msg.getSitesList(),
    proto_vorto_types_types_pb.SlimSite.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.NodeListAllMySupplierSitesSupplier}
 */
proto.vtv1.NodeListAllMySupplierSitesSupplier.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.NodeListAllMySupplierSitesSupplier;
  return proto.vtv1.NodeListAllMySupplierSitesSupplier.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.NodeListAllMySupplierSitesSupplier} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.NodeListAllMySupplierSitesSupplier}
 */
proto.vtv1.NodeListAllMySupplierSitesSupplier.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = new proto_vorto_types_types_pb.SlimSite;
      reader.readMessage(value,proto_vorto_types_types_pb.SlimSite.deserializeBinaryFromReader);
      msg.addSites(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.NodeListAllMySupplierSitesSupplier.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.NodeListAllMySupplierSitesSupplier.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.NodeListAllMySupplierSitesSupplier} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.NodeListAllMySupplierSitesSupplier.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSitesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto_vorto_types_types_pb.SlimSite.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.vtv1.NodeListAllMySupplierSitesSupplier.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.NodeListAllMySupplierSitesSupplier} returns this
 */
proto.vtv1.NodeListAllMySupplierSitesSupplier.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.vtv1.NodeListAllMySupplierSitesSupplier.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.NodeListAllMySupplierSitesSupplier} returns this
 */
proto.vtv1.NodeListAllMySupplierSitesSupplier.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated SlimSite sites = 3;
 * @return {!Array<!proto.vtv1.SlimSite>}
 */
proto.vtv1.NodeListAllMySupplierSitesSupplier.prototype.getSitesList = function() {
  return /** @type{!Array<!proto.vtv1.SlimSite>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_vorto_types_types_pb.SlimSite, 3));
};


/**
 * @param {!Array<!proto.vtv1.SlimSite>} value
 * @return {!proto.vtv1.NodeListAllMySupplierSitesSupplier} returns this
*/
proto.vtv1.NodeListAllMySupplierSitesSupplier.prototype.setSitesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.vtv1.SlimSite=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.SlimSite}
 */
proto.vtv1.NodeListAllMySupplierSitesSupplier.prototype.addSites = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.vtv1.SlimSite, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.NodeListAllMySupplierSitesSupplier} returns this
 */
proto.vtv1.NodeListAllMySupplierSitesSupplier.prototype.clearSitesList = function() {
  return this.setSitesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.ListInNetworkCustomersResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.ListInNetworkCustomersResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.ListInNetworkCustomersResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.ListInNetworkCustomersResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.ListInNetworkCustomersResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    customersList: jspb.Message.toObjectList(msg.getCustomersList(),
    proto_vorto_types_types_pb.SlimAccount.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.ListInNetworkCustomersResponse}
 */
proto.vtv1.ListInNetworkCustomersResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.ListInNetworkCustomersResponse;
  return proto.vtv1.ListInNetworkCustomersResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.ListInNetworkCustomersResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.ListInNetworkCustomersResponse}
 */
proto.vtv1.ListInNetworkCustomersResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_vorto_types_types_pb.SlimAccount;
      reader.readMessage(value,proto_vorto_types_types_pb.SlimAccount.deserializeBinaryFromReader);
      msg.addCustomers(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.ListInNetworkCustomersResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.ListInNetworkCustomersResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.ListInNetworkCustomersResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.ListInNetworkCustomersResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCustomersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto_vorto_types_types_pb.SlimAccount.serializeBinaryToWriter
    );
  }
};


/**
 * repeated SlimAccount customers = 1;
 * @return {!Array<!proto.vtv1.SlimAccount>}
 */
proto.vtv1.ListInNetworkCustomersResponse.prototype.getCustomersList = function() {
  return /** @type{!Array<!proto.vtv1.SlimAccount>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_vorto_types_types_pb.SlimAccount, 1));
};


/**
 * @param {!Array<!proto.vtv1.SlimAccount>} value
 * @return {!proto.vtv1.ListInNetworkCustomersResponse} returns this
*/
proto.vtv1.ListInNetworkCustomersResponse.prototype.setCustomersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.vtv1.SlimAccount=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.SlimAccount}
 */
proto.vtv1.ListInNetworkCustomersResponse.prototype.addCustomers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.vtv1.SlimAccount, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.ListInNetworkCustomersResponse} returns this
 */
proto.vtv1.ListInNetworkCustomersResponse.prototype.clearCustomersList = function() {
  return this.setCustomersList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.ReportSiteDownTimeRequest.repeatedFields_ = [7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.ReportSiteDownTimeRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.ReportSiteDownTimeRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.ReportSiteDownTimeRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.ReportSiteDownTimeRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    siteId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    inbound: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    outbound: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    description: (f = msg.getDescription()) && proto_vorto_types_types_pb.NullableString.toObject(includeInstance, f),
    startsAt: jspb.Message.getFieldWithDefault(msg, 5, ""),
    endsAt: jspb.Message.getFieldWithDefault(msg, 6, ""),
    inventoriesList: (f = jspb.Message.getRepeatedField(msg, 7)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.ReportSiteDownTimeRequest}
 */
proto.vtv1.ReportSiteDownTimeRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.ReportSiteDownTimeRequest;
  return proto.vtv1.ReportSiteDownTimeRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.ReportSiteDownTimeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.ReportSiteDownTimeRequest}
 */
proto.vtv1.ReportSiteDownTimeRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSiteId(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setInbound(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOutbound(value);
      break;
    case 4:
      var value = new proto_vorto_types_types_pb.NullableString;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableString.deserializeBinaryFromReader);
      msg.setDescription(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setStartsAt(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setEndsAt(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.addInventories(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.ReportSiteDownTimeRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.ReportSiteDownTimeRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.ReportSiteDownTimeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.ReportSiteDownTimeRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSiteId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getInbound();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getOutbound();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getDescription();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto_vorto_types_types_pb.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getStartsAt();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getEndsAt();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getInventoriesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      7,
      f
    );
  }
};


/**
 * optional string site_id = 1;
 * @return {string}
 */
proto.vtv1.ReportSiteDownTimeRequest.prototype.getSiteId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.ReportSiteDownTimeRequest} returns this
 */
proto.vtv1.ReportSiteDownTimeRequest.prototype.setSiteId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool inbound = 2;
 * @return {boolean}
 */
proto.vtv1.ReportSiteDownTimeRequest.prototype.getInbound = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vtv1.ReportSiteDownTimeRequest} returns this
 */
proto.vtv1.ReportSiteDownTimeRequest.prototype.setInbound = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional bool outbound = 3;
 * @return {boolean}
 */
proto.vtv1.ReportSiteDownTimeRequest.prototype.getOutbound = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vtv1.ReportSiteDownTimeRequest} returns this
 */
proto.vtv1.ReportSiteDownTimeRequest.prototype.setOutbound = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional NullableString description = 4;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.ReportSiteDownTimeRequest.prototype.getDescription = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableString, 4));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.ReportSiteDownTimeRequest} returns this
*/
proto.vtv1.ReportSiteDownTimeRequest.prototype.setDescription = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.ReportSiteDownTimeRequest} returns this
 */
proto.vtv1.ReportSiteDownTimeRequest.prototype.clearDescription = function() {
  return this.setDescription(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.ReportSiteDownTimeRequest.prototype.hasDescription = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string starts_at = 5;
 * @return {string}
 */
proto.vtv1.ReportSiteDownTimeRequest.prototype.getStartsAt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.ReportSiteDownTimeRequest} returns this
 */
proto.vtv1.ReportSiteDownTimeRequest.prototype.setStartsAt = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string ends_at = 6;
 * @return {string}
 */
proto.vtv1.ReportSiteDownTimeRequest.prototype.getEndsAt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.ReportSiteDownTimeRequest} returns this
 */
proto.vtv1.ReportSiteDownTimeRequest.prototype.setEndsAt = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * repeated string inventories = 7;
 * @return {!Array<string>}
 */
proto.vtv1.ReportSiteDownTimeRequest.prototype.getInventoriesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 7));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.vtv1.ReportSiteDownTimeRequest} returns this
 */
proto.vtv1.ReportSiteDownTimeRequest.prototype.setInventoriesList = function(value) {
  return jspb.Message.setField(this, 7, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.vtv1.ReportSiteDownTimeRequest} returns this
 */
proto.vtv1.ReportSiteDownTimeRequest.prototype.addInventories = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 7, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.ReportSiteDownTimeRequest} returns this
 */
proto.vtv1.ReportSiteDownTimeRequest.prototype.clearInventoriesList = function() {
  return this.setInventoriesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.ListSiteDownTimeHistoryResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.ListSiteDownTimeHistoryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.ListSiteDownTimeHistoryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.ListSiteDownTimeHistoryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.ListSiteDownTimeHistoryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    entriesList: jspb.Message.toObjectList(msg.getEntriesList(),
    proto.vtv1.SiteDownTimeHistoryEntry.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.ListSiteDownTimeHistoryResponse}
 */
proto.vtv1.ListSiteDownTimeHistoryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.ListSiteDownTimeHistoryResponse;
  return proto.vtv1.ListSiteDownTimeHistoryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.ListSiteDownTimeHistoryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.ListSiteDownTimeHistoryResponse}
 */
proto.vtv1.ListSiteDownTimeHistoryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.vtv1.SiteDownTimeHistoryEntry;
      reader.readMessage(value,proto.vtv1.SiteDownTimeHistoryEntry.deserializeBinaryFromReader);
      msg.addEntries(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.ListSiteDownTimeHistoryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.ListSiteDownTimeHistoryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.ListSiteDownTimeHistoryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.ListSiteDownTimeHistoryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEntriesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.vtv1.SiteDownTimeHistoryEntry.serializeBinaryToWriter
    );
  }
};


/**
 * repeated SiteDownTimeHistoryEntry entries = 1;
 * @return {!Array<!proto.vtv1.SiteDownTimeHistoryEntry>}
 */
proto.vtv1.ListSiteDownTimeHistoryResponse.prototype.getEntriesList = function() {
  return /** @type{!Array<!proto.vtv1.SiteDownTimeHistoryEntry>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vtv1.SiteDownTimeHistoryEntry, 1));
};


/**
 * @param {!Array<!proto.vtv1.SiteDownTimeHistoryEntry>} value
 * @return {!proto.vtv1.ListSiteDownTimeHistoryResponse} returns this
*/
proto.vtv1.ListSiteDownTimeHistoryResponse.prototype.setEntriesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.vtv1.SiteDownTimeHistoryEntry=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.SiteDownTimeHistoryEntry}
 */
proto.vtv1.ListSiteDownTimeHistoryResponse.prototype.addEntries = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.vtv1.SiteDownTimeHistoryEntry, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.ListSiteDownTimeHistoryResponse} returns this
 */
proto.vtv1.ListSiteDownTimeHistoryResponse.prototype.clearEntriesList = function() {
  return this.setEntriesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.SiteDownTimeHistoryEntry.repeatedFields_ = [10];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.SiteDownTimeHistoryEntry.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.SiteDownTimeHistoryEntry.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.SiteDownTimeHistoryEntry} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.SiteDownTimeHistoryEntry.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    inbound: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    outbound: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    description: (f = msg.getDescription()) && proto_vorto_types_types_pb.NullableString.toObject(includeInstance, f),
    startsAt: jspb.Message.getFieldWithDefault(msg, 5, ""),
    endsAt: jspb.Message.getFieldWithDefault(msg, 6, ""),
    createdAt: jspb.Message.getFieldWithDefault(msg, 7, ""),
    createdBy: jspb.Message.getFieldWithDefault(msg, 8, ""),
    active: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
    inventoryNamesList: (f = jspb.Message.getRepeatedField(msg, 10)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.SiteDownTimeHistoryEntry}
 */
proto.vtv1.SiteDownTimeHistoryEntry.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.SiteDownTimeHistoryEntry;
  return proto.vtv1.SiteDownTimeHistoryEntry.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.SiteDownTimeHistoryEntry} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.SiteDownTimeHistoryEntry}
 */
proto.vtv1.SiteDownTimeHistoryEntry.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setInbound(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOutbound(value);
      break;
    case 4:
      var value = new proto_vorto_types_types_pb.NullableString;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableString.deserializeBinaryFromReader);
      msg.setDescription(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setStartsAt(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setEndsAt(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedAt(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedBy(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setActive(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.addInventoryNames(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.SiteDownTimeHistoryEntry.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.SiteDownTimeHistoryEntry.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.SiteDownTimeHistoryEntry} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.SiteDownTimeHistoryEntry.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getInbound();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getOutbound();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getDescription();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto_vorto_types_types_pb.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getStartsAt();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getEndsAt();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getCreatedAt();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getCreatedBy();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getActive();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getInventoryNamesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      10,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.vtv1.SiteDownTimeHistoryEntry.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.SiteDownTimeHistoryEntry} returns this
 */
proto.vtv1.SiteDownTimeHistoryEntry.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional bool inbound = 2;
 * @return {boolean}
 */
proto.vtv1.SiteDownTimeHistoryEntry.prototype.getInbound = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vtv1.SiteDownTimeHistoryEntry} returns this
 */
proto.vtv1.SiteDownTimeHistoryEntry.prototype.setInbound = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional bool outbound = 3;
 * @return {boolean}
 */
proto.vtv1.SiteDownTimeHistoryEntry.prototype.getOutbound = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vtv1.SiteDownTimeHistoryEntry} returns this
 */
proto.vtv1.SiteDownTimeHistoryEntry.prototype.setOutbound = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional NullableString description = 4;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.SiteDownTimeHistoryEntry.prototype.getDescription = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableString, 4));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.SiteDownTimeHistoryEntry} returns this
*/
proto.vtv1.SiteDownTimeHistoryEntry.prototype.setDescription = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.SiteDownTimeHistoryEntry} returns this
 */
proto.vtv1.SiteDownTimeHistoryEntry.prototype.clearDescription = function() {
  return this.setDescription(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.SiteDownTimeHistoryEntry.prototype.hasDescription = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string starts_at = 5;
 * @return {string}
 */
proto.vtv1.SiteDownTimeHistoryEntry.prototype.getStartsAt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.SiteDownTimeHistoryEntry} returns this
 */
proto.vtv1.SiteDownTimeHistoryEntry.prototype.setStartsAt = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string ends_at = 6;
 * @return {string}
 */
proto.vtv1.SiteDownTimeHistoryEntry.prototype.getEndsAt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.SiteDownTimeHistoryEntry} returns this
 */
proto.vtv1.SiteDownTimeHistoryEntry.prototype.setEndsAt = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string created_at = 7;
 * @return {string}
 */
proto.vtv1.SiteDownTimeHistoryEntry.prototype.getCreatedAt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.SiteDownTimeHistoryEntry} returns this
 */
proto.vtv1.SiteDownTimeHistoryEntry.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string created_by = 8;
 * @return {string}
 */
proto.vtv1.SiteDownTimeHistoryEntry.prototype.getCreatedBy = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.SiteDownTimeHistoryEntry} returns this
 */
proto.vtv1.SiteDownTimeHistoryEntry.prototype.setCreatedBy = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional bool active = 9;
 * @return {boolean}
 */
proto.vtv1.SiteDownTimeHistoryEntry.prototype.getActive = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vtv1.SiteDownTimeHistoryEntry} returns this
 */
proto.vtv1.SiteDownTimeHistoryEntry.prototype.setActive = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};


/**
 * repeated string inventory_names = 10;
 * @return {!Array<string>}
 */
proto.vtv1.SiteDownTimeHistoryEntry.prototype.getInventoryNamesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 10));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.vtv1.SiteDownTimeHistoryEntry} returns this
 */
proto.vtv1.SiteDownTimeHistoryEntry.prototype.setInventoryNamesList = function(value) {
  return jspb.Message.setField(this, 10, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.vtv1.SiteDownTimeHistoryEntry} returns this
 */
proto.vtv1.SiteDownTimeHistoryEntry.prototype.addInventoryNames = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 10, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.SiteDownTimeHistoryEntry} returns this
 */
proto.vtv1.SiteDownTimeHistoryEntry.prototype.clearInventoryNamesList = function() {
  return this.setInventoryNamesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.UploadVanguardDemandRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.UploadVanguardDemandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.UploadVanguardDemandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.UploadVanguardDemandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.UploadVanguardDemandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    entriesList: jspb.Message.toObjectList(msg.getEntriesList(),
    proto_vorto_node_node_pb.VanguardDemandEntry.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.UploadVanguardDemandRequest}
 */
proto.vtv1.UploadVanguardDemandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.UploadVanguardDemandRequest;
  return proto.vtv1.UploadVanguardDemandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.UploadVanguardDemandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.UploadVanguardDemandRequest}
 */
proto.vtv1.UploadVanguardDemandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_vorto_node_node_pb.VanguardDemandEntry;
      reader.readMessage(value,proto_vorto_node_node_pb.VanguardDemandEntry.deserializeBinaryFromReader);
      msg.addEntries(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.UploadVanguardDemandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.UploadVanguardDemandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.UploadVanguardDemandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.UploadVanguardDemandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEntriesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto_vorto_node_node_pb.VanguardDemandEntry.serializeBinaryToWriter
    );
  }
};


/**
 * repeated VanguardDemandEntry entries = 1;
 * @return {!Array<!proto.vtv1.VanguardDemandEntry>}
 */
proto.vtv1.UploadVanguardDemandRequest.prototype.getEntriesList = function() {
  return /** @type{!Array<!proto.vtv1.VanguardDemandEntry>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_vorto_node_node_pb.VanguardDemandEntry, 1));
};


/**
 * @param {!Array<!proto.vtv1.VanguardDemandEntry>} value
 * @return {!proto.vtv1.UploadVanguardDemandRequest} returns this
*/
proto.vtv1.UploadVanguardDemandRequest.prototype.setEntriesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.vtv1.VanguardDemandEntry=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.VanguardDemandEntry}
 */
proto.vtv1.UploadVanguardDemandRequest.prototype.addEntries = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.vtv1.VanguardDemandEntry, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.UploadVanguardDemandRequest} returns this
 */
proto.vtv1.UploadVanguardDemandRequest.prototype.clearEntriesList = function() {
  return this.setEntriesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.GetVanguardHistoryResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.GetVanguardHistoryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.GetVanguardHistoryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.GetVanguardHistoryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.GetVanguardHistoryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    entriesList: jspb.Message.toObjectList(msg.getEntriesList(),
    proto_vorto_node_node_pb.VanguardHistoryEntry.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.GetVanguardHistoryResponse}
 */
proto.vtv1.GetVanguardHistoryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.GetVanguardHistoryResponse;
  return proto.vtv1.GetVanguardHistoryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.GetVanguardHistoryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.GetVanguardHistoryResponse}
 */
proto.vtv1.GetVanguardHistoryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_vorto_node_node_pb.VanguardHistoryEntry;
      reader.readMessage(value,proto_vorto_node_node_pb.VanguardHistoryEntry.deserializeBinaryFromReader);
      msg.addEntries(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.GetVanguardHistoryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.GetVanguardHistoryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.GetVanguardHistoryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.GetVanguardHistoryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEntriesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto_vorto_node_node_pb.VanguardHistoryEntry.serializeBinaryToWriter
    );
  }
};


/**
 * repeated VanguardHistoryEntry entries = 1;
 * @return {!Array<!proto.vtv1.VanguardHistoryEntry>}
 */
proto.vtv1.GetVanguardHistoryResponse.prototype.getEntriesList = function() {
  return /** @type{!Array<!proto.vtv1.VanguardHistoryEntry>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_vorto_node_node_pb.VanguardHistoryEntry, 1));
};


/**
 * @param {!Array<!proto.vtv1.VanguardHistoryEntry>} value
 * @return {!proto.vtv1.GetVanguardHistoryResponse} returns this
*/
proto.vtv1.GetVanguardHistoryResponse.prototype.setEntriesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.vtv1.VanguardHistoryEntry=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.VanguardHistoryEntry}
 */
proto.vtv1.GetVanguardHistoryResponse.prototype.addEntries = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.vtv1.VanguardHistoryEntry, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.GetVanguardHistoryResponse} returns this
 */
proto.vtv1.GetVanguardHistoryResponse.prototype.clearEntriesList = function() {
  return this.setEntriesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.EditSiteGroupsRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.EditSiteGroupsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.EditSiteGroupsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.EditSiteGroupsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.EditSiteGroupsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    groupsList: jspb.Message.toObjectList(msg.getGroupsList(),
    proto_vorto_types_types_pb.StringIdName.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.EditSiteGroupsRequest}
 */
proto.vtv1.EditSiteGroupsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.EditSiteGroupsRequest;
  return proto.vtv1.EditSiteGroupsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.EditSiteGroupsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.EditSiteGroupsRequest}
 */
proto.vtv1.EditSiteGroupsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_vorto_types_types_pb.StringIdName;
      reader.readMessage(value,proto_vorto_types_types_pb.StringIdName.deserializeBinaryFromReader);
      msg.addGroups(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.EditSiteGroupsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.EditSiteGroupsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.EditSiteGroupsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.EditSiteGroupsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGroupsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto_vorto_types_types_pb.StringIdName.serializeBinaryToWriter
    );
  }
};


/**
 * repeated StringIdName groups = 1;
 * @return {!Array<!proto.vtv1.StringIdName>}
 */
proto.vtv1.EditSiteGroupsRequest.prototype.getGroupsList = function() {
  return /** @type{!Array<!proto.vtv1.StringIdName>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_vorto_types_types_pb.StringIdName, 1));
};


/**
 * @param {!Array<!proto.vtv1.StringIdName>} value
 * @return {!proto.vtv1.EditSiteGroupsRequest} returns this
*/
proto.vtv1.EditSiteGroupsRequest.prototype.setGroupsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.vtv1.StringIdName=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.StringIdName}
 */
proto.vtv1.EditSiteGroupsRequest.prototype.addGroups = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.vtv1.StringIdName, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.EditSiteGroupsRequest} returns this
 */
proto.vtv1.EditSiteGroupsRequest.prototype.clearGroupsList = function() {
  return this.setGroupsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.GetSitesWithPickupsTodayRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.GetSitesWithPickupsTodayRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.GetSitesWithPickupsTodayRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.GetSitesWithPickupsTodayRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.GetSitesWithPickupsTodayRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    sitegroupidList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.GetSitesWithPickupsTodayRequest}
 */
proto.vtv1.GetSitesWithPickupsTodayRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.GetSitesWithPickupsTodayRequest;
  return proto.vtv1.GetSitesWithPickupsTodayRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.GetSitesWithPickupsTodayRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.GetSitesWithPickupsTodayRequest}
 */
proto.vtv1.GetSitesWithPickupsTodayRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Array<number>} */ (reader.readPackedInt64());
      msg.setSitegroupidList(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.GetSitesWithPickupsTodayRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.GetSitesWithPickupsTodayRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.GetSitesWithPickupsTodayRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.GetSitesWithPickupsTodayRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSitegroupidList();
  if (f.length > 0) {
    writer.writePackedInt64(
      1,
      f
    );
  }
};


/**
 * repeated int64 siteGroupId = 1;
 * @return {!Array<number>}
 */
proto.vtv1.GetSitesWithPickupsTodayRequest.prototype.getSitegroupidList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.vtv1.GetSitesWithPickupsTodayRequest} returns this
 */
proto.vtv1.GetSitesWithPickupsTodayRequest.prototype.setSitegroupidList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.vtv1.GetSitesWithPickupsTodayRequest} returns this
 */
proto.vtv1.GetSitesWithPickupsTodayRequest.prototype.addSitegroupid = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.GetSitesWithPickupsTodayRequest} returns this
 */
proto.vtv1.GetSitesWithPickupsTodayRequest.prototype.clearSitegroupidList = function() {
  return this.setSitegroupidList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.GetOutboundEnRouteOrdersRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.GetOutboundEnRouteOrdersRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.GetOutboundEnRouteOrdersRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.GetOutboundEnRouteOrdersRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.GetOutboundEnRouteOrdersRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    sitegroupidList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.GetOutboundEnRouteOrdersRequest}
 */
proto.vtv1.GetOutboundEnRouteOrdersRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.GetOutboundEnRouteOrdersRequest;
  return proto.vtv1.GetOutboundEnRouteOrdersRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.GetOutboundEnRouteOrdersRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.GetOutboundEnRouteOrdersRequest}
 */
proto.vtv1.GetOutboundEnRouteOrdersRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Array<number>} */ (reader.readPackedInt64());
      msg.setSitegroupidList(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.GetOutboundEnRouteOrdersRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.GetOutboundEnRouteOrdersRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.GetOutboundEnRouteOrdersRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.GetOutboundEnRouteOrdersRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSitegroupidList();
  if (f.length > 0) {
    writer.writePackedInt64(
      1,
      f
    );
  }
};


/**
 * repeated int64 siteGroupId = 1;
 * @return {!Array<number>}
 */
proto.vtv1.GetOutboundEnRouteOrdersRequest.prototype.getSitegroupidList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.vtv1.GetOutboundEnRouteOrdersRequest} returns this
 */
proto.vtv1.GetOutboundEnRouteOrdersRequest.prototype.setSitegroupidList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.vtv1.GetOutboundEnRouteOrdersRequest} returns this
 */
proto.vtv1.GetOutboundEnRouteOrdersRequest.prototype.addSitegroupid = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.GetOutboundEnRouteOrdersRequest} returns this
 */
proto.vtv1.GetOutboundEnRouteOrdersRequest.prototype.clearSitegroupidList = function() {
  return this.setSitegroupidList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.GetOutboundScheduledOrdersRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.GetOutboundScheduledOrdersRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.GetOutboundScheduledOrdersRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.GetOutboundScheduledOrdersRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.GetOutboundScheduledOrdersRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    sitegroupidList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.GetOutboundScheduledOrdersRequest}
 */
proto.vtv1.GetOutboundScheduledOrdersRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.GetOutboundScheduledOrdersRequest;
  return proto.vtv1.GetOutboundScheduledOrdersRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.GetOutboundScheduledOrdersRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.GetOutboundScheduledOrdersRequest}
 */
proto.vtv1.GetOutboundScheduledOrdersRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Array<number>} */ (reader.readPackedInt64());
      msg.setSitegroupidList(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.GetOutboundScheduledOrdersRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.GetOutboundScheduledOrdersRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.GetOutboundScheduledOrdersRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.GetOutboundScheduledOrdersRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSitegroupidList();
  if (f.length > 0) {
    writer.writePackedInt64(
      1,
      f
    );
  }
};


/**
 * repeated int64 siteGroupId = 1;
 * @return {!Array<number>}
 */
proto.vtv1.GetOutboundScheduledOrdersRequest.prototype.getSitegroupidList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.vtv1.GetOutboundScheduledOrdersRequest} returns this
 */
proto.vtv1.GetOutboundScheduledOrdersRequest.prototype.setSitegroupidList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.vtv1.GetOutboundScheduledOrdersRequest} returns this
 */
proto.vtv1.GetOutboundScheduledOrdersRequest.prototype.addSitegroupid = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.GetOutboundScheduledOrdersRequest} returns this
 */
proto.vtv1.GetOutboundScheduledOrdersRequest.prototype.clearSitegroupidList = function() {
  return this.setSitegroupidList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.ListPendingOrdersToConfirmRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.ListPendingOrdersToConfirmRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.ListPendingOrdersToConfirmRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.ListPendingOrdersToConfirmRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.ListPendingOrdersToConfirmRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    sitegroupidList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.ListPendingOrdersToConfirmRequest}
 */
proto.vtv1.ListPendingOrdersToConfirmRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.ListPendingOrdersToConfirmRequest;
  return proto.vtv1.ListPendingOrdersToConfirmRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.ListPendingOrdersToConfirmRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.ListPendingOrdersToConfirmRequest}
 */
proto.vtv1.ListPendingOrdersToConfirmRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Array<number>} */ (reader.readPackedInt64());
      msg.setSitegroupidList(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.ListPendingOrdersToConfirmRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.ListPendingOrdersToConfirmRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.ListPendingOrdersToConfirmRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.ListPendingOrdersToConfirmRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSitegroupidList();
  if (f.length > 0) {
    writer.writePackedInt64(
      1,
      f
    );
  }
};


/**
 * repeated int64 siteGroupId = 1;
 * @return {!Array<number>}
 */
proto.vtv1.ListPendingOrdersToConfirmRequest.prototype.getSitegroupidList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.vtv1.ListPendingOrdersToConfirmRequest} returns this
 */
proto.vtv1.ListPendingOrdersToConfirmRequest.prototype.setSitegroupidList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.vtv1.ListPendingOrdersToConfirmRequest} returns this
 */
proto.vtv1.ListPendingOrdersToConfirmRequest.prototype.addSitegroupid = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.ListPendingOrdersToConfirmRequest} returns this
 */
proto.vtv1.ListPendingOrdersToConfirmRequest.prototype.clearSitegroupidList = function() {
  return this.setSitegroupidList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.GlobalSearchRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.GlobalSearchRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.GlobalSearchRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.GlobalSearchRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    query: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.GlobalSearchRequest}
 */
proto.vtv1.GlobalSearchRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.GlobalSearchRequest;
  return proto.vtv1.GlobalSearchRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.GlobalSearchRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.GlobalSearchRequest}
 */
proto.vtv1.GlobalSearchRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setQuery(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.GlobalSearchRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.GlobalSearchRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.GlobalSearchRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.GlobalSearchRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getQuery();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string query = 1;
 * @return {string}
 */
proto.vtv1.GlobalSearchRequest.prototype.getQuery = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.GlobalSearchRequest} returns this
 */
proto.vtv1.GlobalSearchRequest.prototype.setQuery = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.GlobalSearchResponse.repeatedFields_ = [1,2,3,4,5,6,7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.GlobalSearchResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.GlobalSearchResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.GlobalSearchResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.GlobalSearchResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    ordersList: jspb.Message.toObjectList(msg.getOrdersList(),
    proto.vtv1.GlobalSearchResponseOrder.toObject, includeInstance),
    sitesList: jspb.Message.toObjectList(msg.getSitesList(),
    proto_vorto_types_types_pb.StringIdName.toObject, includeInstance),
    inventoriesList: jspb.Message.toObjectList(msg.getInventoriesList(),
    proto.vtv1.GlobalSearchResponseInventory.toObject, includeInstance),
    purchaseOrdersList: jspb.Message.toObjectList(msg.getPurchaseOrdersList(),
    proto_vorto_types_types_pb.StringIdName.toObject, includeInstance),
    contractsList: jspb.Message.toObjectList(msg.getContractsList(),
    proto_vorto_types_types_pb.StringIdName.toObject, includeInstance),
    usersList: jspb.Message.toObjectList(msg.getUsersList(),
    proto.vtv1.GlobalSearchResponseUser.toObject, includeInstance),
    fulfillmentsList: jspb.Message.toObjectList(msg.getFulfillmentsList(),
    proto.vtv1.GlobalSearchResponseFulfillment.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.GlobalSearchResponse}
 */
proto.vtv1.GlobalSearchResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.GlobalSearchResponse;
  return proto.vtv1.GlobalSearchResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.GlobalSearchResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.GlobalSearchResponse}
 */
proto.vtv1.GlobalSearchResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.vtv1.GlobalSearchResponseOrder;
      reader.readMessage(value,proto.vtv1.GlobalSearchResponseOrder.deserializeBinaryFromReader);
      msg.addOrders(value);
      break;
    case 2:
      var value = new proto_vorto_types_types_pb.StringIdName;
      reader.readMessage(value,proto_vorto_types_types_pb.StringIdName.deserializeBinaryFromReader);
      msg.addSites(value);
      break;
    case 3:
      var value = new proto.vtv1.GlobalSearchResponseInventory;
      reader.readMessage(value,proto.vtv1.GlobalSearchResponseInventory.deserializeBinaryFromReader);
      msg.addInventories(value);
      break;
    case 4:
      var value = new proto_vorto_types_types_pb.StringIdName;
      reader.readMessage(value,proto_vorto_types_types_pb.StringIdName.deserializeBinaryFromReader);
      msg.addPurchaseOrders(value);
      break;
    case 5:
      var value = new proto_vorto_types_types_pb.StringIdName;
      reader.readMessage(value,proto_vorto_types_types_pb.StringIdName.deserializeBinaryFromReader);
      msg.addContracts(value);
      break;
    case 6:
      var value = new proto.vtv1.GlobalSearchResponseUser;
      reader.readMessage(value,proto.vtv1.GlobalSearchResponseUser.deserializeBinaryFromReader);
      msg.addUsers(value);
      break;
    case 7:
      var value = new proto.vtv1.GlobalSearchResponseFulfillment;
      reader.readMessage(value,proto.vtv1.GlobalSearchResponseFulfillment.deserializeBinaryFromReader);
      msg.addFulfillments(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.GlobalSearchResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.GlobalSearchResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.GlobalSearchResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.GlobalSearchResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrdersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.vtv1.GlobalSearchResponseOrder.serializeBinaryToWriter
    );
  }
  f = message.getSitesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto_vorto_types_types_pb.StringIdName.serializeBinaryToWriter
    );
  }
  f = message.getInventoriesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.vtv1.GlobalSearchResponseInventory.serializeBinaryToWriter
    );
  }
  f = message.getPurchaseOrdersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto_vorto_types_types_pb.StringIdName.serializeBinaryToWriter
    );
  }
  f = message.getContractsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto_vorto_types_types_pb.StringIdName.serializeBinaryToWriter
    );
  }
  f = message.getUsersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.vtv1.GlobalSearchResponseUser.serializeBinaryToWriter
    );
  }
  f = message.getFulfillmentsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto.vtv1.GlobalSearchResponseFulfillment.serializeBinaryToWriter
    );
  }
};


/**
 * repeated GlobalSearchResponseOrder orders = 1;
 * @return {!Array<!proto.vtv1.GlobalSearchResponseOrder>}
 */
proto.vtv1.GlobalSearchResponse.prototype.getOrdersList = function() {
  return /** @type{!Array<!proto.vtv1.GlobalSearchResponseOrder>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vtv1.GlobalSearchResponseOrder, 1));
};


/**
 * @param {!Array<!proto.vtv1.GlobalSearchResponseOrder>} value
 * @return {!proto.vtv1.GlobalSearchResponse} returns this
*/
proto.vtv1.GlobalSearchResponse.prototype.setOrdersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.vtv1.GlobalSearchResponseOrder=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.GlobalSearchResponseOrder}
 */
proto.vtv1.GlobalSearchResponse.prototype.addOrders = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.vtv1.GlobalSearchResponseOrder, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.GlobalSearchResponse} returns this
 */
proto.vtv1.GlobalSearchResponse.prototype.clearOrdersList = function() {
  return this.setOrdersList([]);
};


/**
 * repeated StringIdName sites = 2;
 * @return {!Array<!proto.vtv1.StringIdName>}
 */
proto.vtv1.GlobalSearchResponse.prototype.getSitesList = function() {
  return /** @type{!Array<!proto.vtv1.StringIdName>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_vorto_types_types_pb.StringIdName, 2));
};


/**
 * @param {!Array<!proto.vtv1.StringIdName>} value
 * @return {!proto.vtv1.GlobalSearchResponse} returns this
*/
proto.vtv1.GlobalSearchResponse.prototype.setSitesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.vtv1.StringIdName=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.StringIdName}
 */
proto.vtv1.GlobalSearchResponse.prototype.addSites = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.vtv1.StringIdName, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.GlobalSearchResponse} returns this
 */
proto.vtv1.GlobalSearchResponse.prototype.clearSitesList = function() {
  return this.setSitesList([]);
};


/**
 * repeated GlobalSearchResponseInventory inventories = 3;
 * @return {!Array<!proto.vtv1.GlobalSearchResponseInventory>}
 */
proto.vtv1.GlobalSearchResponse.prototype.getInventoriesList = function() {
  return /** @type{!Array<!proto.vtv1.GlobalSearchResponseInventory>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vtv1.GlobalSearchResponseInventory, 3));
};


/**
 * @param {!Array<!proto.vtv1.GlobalSearchResponseInventory>} value
 * @return {!proto.vtv1.GlobalSearchResponse} returns this
*/
proto.vtv1.GlobalSearchResponse.prototype.setInventoriesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.vtv1.GlobalSearchResponseInventory=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.GlobalSearchResponseInventory}
 */
proto.vtv1.GlobalSearchResponse.prototype.addInventories = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.vtv1.GlobalSearchResponseInventory, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.GlobalSearchResponse} returns this
 */
proto.vtv1.GlobalSearchResponse.prototype.clearInventoriesList = function() {
  return this.setInventoriesList([]);
};


/**
 * repeated StringIdName purchase_orders = 4;
 * @return {!Array<!proto.vtv1.StringIdName>}
 */
proto.vtv1.GlobalSearchResponse.prototype.getPurchaseOrdersList = function() {
  return /** @type{!Array<!proto.vtv1.StringIdName>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_vorto_types_types_pb.StringIdName, 4));
};


/**
 * @param {!Array<!proto.vtv1.StringIdName>} value
 * @return {!proto.vtv1.GlobalSearchResponse} returns this
*/
proto.vtv1.GlobalSearchResponse.prototype.setPurchaseOrdersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.vtv1.StringIdName=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.StringIdName}
 */
proto.vtv1.GlobalSearchResponse.prototype.addPurchaseOrders = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.vtv1.StringIdName, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.GlobalSearchResponse} returns this
 */
proto.vtv1.GlobalSearchResponse.prototype.clearPurchaseOrdersList = function() {
  return this.setPurchaseOrdersList([]);
};


/**
 * repeated StringIdName contracts = 5;
 * @return {!Array<!proto.vtv1.StringIdName>}
 */
proto.vtv1.GlobalSearchResponse.prototype.getContractsList = function() {
  return /** @type{!Array<!proto.vtv1.StringIdName>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_vorto_types_types_pb.StringIdName, 5));
};


/**
 * @param {!Array<!proto.vtv1.StringIdName>} value
 * @return {!proto.vtv1.GlobalSearchResponse} returns this
*/
proto.vtv1.GlobalSearchResponse.prototype.setContractsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.vtv1.StringIdName=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.StringIdName}
 */
proto.vtv1.GlobalSearchResponse.prototype.addContracts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.vtv1.StringIdName, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.GlobalSearchResponse} returns this
 */
proto.vtv1.GlobalSearchResponse.prototype.clearContractsList = function() {
  return this.setContractsList([]);
};


/**
 * repeated GlobalSearchResponseUser users = 6;
 * @return {!Array<!proto.vtv1.GlobalSearchResponseUser>}
 */
proto.vtv1.GlobalSearchResponse.prototype.getUsersList = function() {
  return /** @type{!Array<!proto.vtv1.GlobalSearchResponseUser>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vtv1.GlobalSearchResponseUser, 6));
};


/**
 * @param {!Array<!proto.vtv1.GlobalSearchResponseUser>} value
 * @return {!proto.vtv1.GlobalSearchResponse} returns this
*/
proto.vtv1.GlobalSearchResponse.prototype.setUsersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.vtv1.GlobalSearchResponseUser=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.GlobalSearchResponseUser}
 */
proto.vtv1.GlobalSearchResponse.prototype.addUsers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.vtv1.GlobalSearchResponseUser, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.GlobalSearchResponse} returns this
 */
proto.vtv1.GlobalSearchResponse.prototype.clearUsersList = function() {
  return this.setUsersList([]);
};


/**
 * repeated GlobalSearchResponseFulfillment fulfillments = 7;
 * @return {!Array<!proto.vtv1.GlobalSearchResponseFulfillment>}
 */
proto.vtv1.GlobalSearchResponse.prototype.getFulfillmentsList = function() {
  return /** @type{!Array<!proto.vtv1.GlobalSearchResponseFulfillment>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vtv1.GlobalSearchResponseFulfillment, 7));
};


/**
 * @param {!Array<!proto.vtv1.GlobalSearchResponseFulfillment>} value
 * @return {!proto.vtv1.GlobalSearchResponse} returns this
*/
proto.vtv1.GlobalSearchResponse.prototype.setFulfillmentsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.vtv1.GlobalSearchResponseFulfillment=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.GlobalSearchResponseFulfillment}
 */
proto.vtv1.GlobalSearchResponse.prototype.addFulfillments = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.vtv1.GlobalSearchResponseFulfillment, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.GlobalSearchResponse} returns this
 */
proto.vtv1.GlobalSearchResponse.prototype.clearFulfillmentsList = function() {
  return this.setFulfillmentsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.GlobalSearchResponseFulfillment.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.GlobalSearchResponseFulfillment.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.GlobalSearchResponseFulfillment} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.GlobalSearchResponseFulfillment.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    supplier: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.GlobalSearchResponseFulfillment}
 */
proto.vtv1.GlobalSearchResponseFulfillment.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.GlobalSearchResponseFulfillment;
  return proto.vtv1.GlobalSearchResponseFulfillment.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.GlobalSearchResponseFulfillment} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.GlobalSearchResponseFulfillment}
 */
proto.vtv1.GlobalSearchResponseFulfillment.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSupplier(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.GlobalSearchResponseFulfillment.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.GlobalSearchResponseFulfillment.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.GlobalSearchResponseFulfillment} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.GlobalSearchResponseFulfillment.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSupplier();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.vtv1.GlobalSearchResponseFulfillment.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.GlobalSearchResponseFulfillment} returns this
 */
proto.vtv1.GlobalSearchResponseFulfillment.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool supplier = 2;
 * @return {boolean}
 */
proto.vtv1.GlobalSearchResponseFulfillment.prototype.getSupplier = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vtv1.GlobalSearchResponseFulfillment} returns this
 */
proto.vtv1.GlobalSearchResponseFulfillment.prototype.setSupplier = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.GlobalSearchResponseOrder.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.GlobalSearchResponseOrder.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.GlobalSearchResponseOrder} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.GlobalSearchResponseOrder.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    supplier: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    fulfillmentId: (f = msg.getFulfillmentId()) && proto_vorto_types_types_pb.NullableString.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.GlobalSearchResponseOrder}
 */
proto.vtv1.GlobalSearchResponseOrder.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.GlobalSearchResponseOrder;
  return proto.vtv1.GlobalSearchResponseOrder.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.GlobalSearchResponseOrder} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.GlobalSearchResponseOrder}
 */
proto.vtv1.GlobalSearchResponseOrder.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSupplier(value);
      break;
    case 3:
      var value = new proto_vorto_types_types_pb.NullableString;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableString.deserializeBinaryFromReader);
      msg.setFulfillmentId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.GlobalSearchResponseOrder.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.GlobalSearchResponseOrder.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.GlobalSearchResponseOrder} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.GlobalSearchResponseOrder.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSupplier();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getFulfillmentId();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto_vorto_types_types_pb.NullableString.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.vtv1.GlobalSearchResponseOrder.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.GlobalSearchResponseOrder} returns this
 */
proto.vtv1.GlobalSearchResponseOrder.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool supplier = 2;
 * @return {boolean}
 */
proto.vtv1.GlobalSearchResponseOrder.prototype.getSupplier = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vtv1.GlobalSearchResponseOrder} returns this
 */
proto.vtv1.GlobalSearchResponseOrder.prototype.setSupplier = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional NullableString fulfillment_id = 3;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.GlobalSearchResponseOrder.prototype.getFulfillmentId = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableString, 3));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.GlobalSearchResponseOrder} returns this
*/
proto.vtv1.GlobalSearchResponseOrder.prototype.setFulfillmentId = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.GlobalSearchResponseOrder} returns this
 */
proto.vtv1.GlobalSearchResponseOrder.prototype.clearFulfillmentId = function() {
  return this.setFulfillmentId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.GlobalSearchResponseOrder.prototype.hasFulfillmentId = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.GlobalSearchResponseUser.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.GlobalSearchResponseUser.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.GlobalSearchResponseUser} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.GlobalSearchResponseUser.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    driver: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.GlobalSearchResponseUser}
 */
proto.vtv1.GlobalSearchResponseUser.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.GlobalSearchResponseUser;
  return proto.vtv1.GlobalSearchResponseUser.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.GlobalSearchResponseUser} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.GlobalSearchResponseUser}
 */
proto.vtv1.GlobalSearchResponseUser.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDriver(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.GlobalSearchResponseUser.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.GlobalSearchResponseUser.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.GlobalSearchResponseUser} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.GlobalSearchResponseUser.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDriver();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.vtv1.GlobalSearchResponseUser.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.GlobalSearchResponseUser} returns this
 */
proto.vtv1.GlobalSearchResponseUser.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.vtv1.GlobalSearchResponseUser.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.GlobalSearchResponseUser} returns this
 */
proto.vtv1.GlobalSearchResponseUser.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool driver = 3;
 * @return {boolean}
 */
proto.vtv1.GlobalSearchResponseUser.prototype.getDriver = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vtv1.GlobalSearchResponseUser} returns this
 */
proto.vtv1.GlobalSearchResponseUser.prototype.setDriver = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.GlobalSearchResponseInventory.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.GlobalSearchResponseInventory.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.GlobalSearchResponseInventory} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.GlobalSearchResponseInventory.toObject = function(includeInstance, msg) {
  var f, obj = {
    siteId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    inventoryId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    productName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    inventoryName: jspb.Message.getFieldWithDefault(msg, 4, ""),
    siteName: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.GlobalSearchResponseInventory}
 */
proto.vtv1.GlobalSearchResponseInventory.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.GlobalSearchResponseInventory;
  return proto.vtv1.GlobalSearchResponseInventory.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.GlobalSearchResponseInventory} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.GlobalSearchResponseInventory}
 */
proto.vtv1.GlobalSearchResponseInventory.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSiteId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setInventoryId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setInventoryName(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setSiteName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.GlobalSearchResponseInventory.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.GlobalSearchResponseInventory.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.GlobalSearchResponseInventory} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.GlobalSearchResponseInventory.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSiteId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getInventoryId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getProductName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getInventoryName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getSiteName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string site_id = 1;
 * @return {string}
 */
proto.vtv1.GlobalSearchResponseInventory.prototype.getSiteId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.GlobalSearchResponseInventory} returns this
 */
proto.vtv1.GlobalSearchResponseInventory.prototype.setSiteId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string inventory_id = 2;
 * @return {string}
 */
proto.vtv1.GlobalSearchResponseInventory.prototype.getInventoryId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.GlobalSearchResponseInventory} returns this
 */
proto.vtv1.GlobalSearchResponseInventory.prototype.setInventoryId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string product_name = 3;
 * @return {string}
 */
proto.vtv1.GlobalSearchResponseInventory.prototype.getProductName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.GlobalSearchResponseInventory} returns this
 */
proto.vtv1.GlobalSearchResponseInventory.prototype.setProductName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string inventory_name = 4;
 * @return {string}
 */
proto.vtv1.GlobalSearchResponseInventory.prototype.getInventoryName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.GlobalSearchResponseInventory} returns this
 */
proto.vtv1.GlobalSearchResponseInventory.prototype.setInventoryName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string site_name = 5;
 * @return {string}
 */
proto.vtv1.GlobalSearchResponseInventory.prototype.getSiteName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.GlobalSearchResponseInventory} returns this
 */
proto.vtv1.GlobalSearchResponseInventory.prototype.setSiteName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.NodeUpdatePOBillableRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.NodeUpdatePOBillableRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.NodeUpdatePOBillableRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.NodeUpdatePOBillableRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    poId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    billable: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.NodeUpdatePOBillableRequest}
 */
proto.vtv1.NodeUpdatePOBillableRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.NodeUpdatePOBillableRequest;
  return proto.vtv1.NodeUpdatePOBillableRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.NodeUpdatePOBillableRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.NodeUpdatePOBillableRequest}
 */
proto.vtv1.NodeUpdatePOBillableRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPoId(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setBillable(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.NodeUpdatePOBillableRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.NodeUpdatePOBillableRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.NodeUpdatePOBillableRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.NodeUpdatePOBillableRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPoId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBillable();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional string po_id = 1;
 * @return {string}
 */
proto.vtv1.NodeUpdatePOBillableRequest.prototype.getPoId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.NodeUpdatePOBillableRequest} returns this
 */
proto.vtv1.NodeUpdatePOBillableRequest.prototype.setPoId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool billable = 2;
 * @return {boolean}
 */
proto.vtv1.NodeUpdatePOBillableRequest.prototype.getBillable = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vtv1.NodeUpdatePOBillableRequest} returns this
 */
proto.vtv1.NodeUpdatePOBillableRequest.prototype.setBillable = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.UpdateMultipleInventoryStockRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.UpdateMultipleInventoryStockRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.UpdateMultipleInventoryStockRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.UpdateMultipleInventoryStockRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.UpdateMultipleInventoryStockRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    stocksList: jspb.Message.toObjectList(msg.getStocksList(),
    proto.vtv1.UpdateInventoryStockRequest.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.UpdateMultipleInventoryStockRequest}
 */
proto.vtv1.UpdateMultipleInventoryStockRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.UpdateMultipleInventoryStockRequest;
  return proto.vtv1.UpdateMultipleInventoryStockRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.UpdateMultipleInventoryStockRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.UpdateMultipleInventoryStockRequest}
 */
proto.vtv1.UpdateMultipleInventoryStockRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.vtv1.UpdateInventoryStockRequest;
      reader.readMessage(value,proto.vtv1.UpdateInventoryStockRequest.deserializeBinaryFromReader);
      msg.addStocks(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.UpdateMultipleInventoryStockRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.UpdateMultipleInventoryStockRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.UpdateMultipleInventoryStockRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.UpdateMultipleInventoryStockRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStocksList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.vtv1.UpdateInventoryStockRequest.serializeBinaryToWriter
    );
  }
};


/**
 * repeated UpdateInventoryStockRequest stocks = 1;
 * @return {!Array<!proto.vtv1.UpdateInventoryStockRequest>}
 */
proto.vtv1.UpdateMultipleInventoryStockRequest.prototype.getStocksList = function() {
  return /** @type{!Array<!proto.vtv1.UpdateInventoryStockRequest>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vtv1.UpdateInventoryStockRequest, 1));
};


/**
 * @param {!Array<!proto.vtv1.UpdateInventoryStockRequest>} value
 * @return {!proto.vtv1.UpdateMultipleInventoryStockRequest} returns this
*/
proto.vtv1.UpdateMultipleInventoryStockRequest.prototype.setStocksList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.vtv1.UpdateInventoryStockRequest=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.UpdateInventoryStockRequest}
 */
proto.vtv1.UpdateMultipleInventoryStockRequest.prototype.addStocks = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.vtv1.UpdateInventoryStockRequest, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.UpdateMultipleInventoryStockRequest} returns this
 */
proto.vtv1.UpdateMultipleInventoryStockRequest.prototype.clearStocksList = function() {
  return this.setStocksList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.GetInventoriesWithStockResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.GetInventoriesWithStockResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.GetInventoriesWithStockResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.GetInventoriesWithStockResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.GetInventoriesWithStockResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    inventoriesList: jspb.Message.toObjectList(msg.getInventoriesList(),
    proto_vorto_node_node_pb.SiteWithStock.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.GetInventoriesWithStockResponse}
 */
proto.vtv1.GetInventoriesWithStockResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.GetInventoriesWithStockResponse;
  return proto.vtv1.GetInventoriesWithStockResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.GetInventoriesWithStockResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.GetInventoriesWithStockResponse}
 */
proto.vtv1.GetInventoriesWithStockResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_vorto_node_node_pb.SiteWithStock;
      reader.readMessage(value,proto_vorto_node_node_pb.SiteWithStock.deserializeBinaryFromReader);
      msg.addInventories(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.GetInventoriesWithStockResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.GetInventoriesWithStockResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.GetInventoriesWithStockResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.GetInventoriesWithStockResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInventoriesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto_vorto_node_node_pb.SiteWithStock.serializeBinaryToWriter
    );
  }
};


/**
 * repeated SiteWithStock inventories = 1;
 * @return {!Array<!proto.vtv1.SiteWithStock>}
 */
proto.vtv1.GetInventoriesWithStockResponse.prototype.getInventoriesList = function() {
  return /** @type{!Array<!proto.vtv1.SiteWithStock>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_vorto_node_node_pb.SiteWithStock, 1));
};


/**
 * @param {!Array<!proto.vtv1.SiteWithStock>} value
 * @return {!proto.vtv1.GetInventoriesWithStockResponse} returns this
*/
proto.vtv1.GetInventoriesWithStockResponse.prototype.setInventoriesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.vtv1.SiteWithStock=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.SiteWithStock}
 */
proto.vtv1.GetInventoriesWithStockResponse.prototype.addInventories = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.vtv1.SiteWithStock, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.GetInventoriesWithStockResponse} returns this
 */
proto.vtv1.GetInventoriesWithStockResponse.prototype.clearInventoriesList = function() {
  return this.setInventoriesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.GetTrailerTypesForFuelMatrixResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.GetTrailerTypesForFuelMatrixResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.GetTrailerTypesForFuelMatrixResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.GetTrailerTypesForFuelMatrixResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.GetTrailerTypesForFuelMatrixResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    trailerTypesList: jspb.Message.toObjectList(msg.getTrailerTypesList(),
    proto_vorto_types_types_pb.TrailerType.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.GetTrailerTypesForFuelMatrixResponse}
 */
proto.vtv1.GetTrailerTypesForFuelMatrixResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.GetTrailerTypesForFuelMatrixResponse;
  return proto.vtv1.GetTrailerTypesForFuelMatrixResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.GetTrailerTypesForFuelMatrixResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.GetTrailerTypesForFuelMatrixResponse}
 */
proto.vtv1.GetTrailerTypesForFuelMatrixResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_vorto_types_types_pb.TrailerType;
      reader.readMessage(value,proto_vorto_types_types_pb.TrailerType.deserializeBinaryFromReader);
      msg.addTrailerTypes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.GetTrailerTypesForFuelMatrixResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.GetTrailerTypesForFuelMatrixResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.GetTrailerTypesForFuelMatrixResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.GetTrailerTypesForFuelMatrixResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTrailerTypesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto_vorto_types_types_pb.TrailerType.serializeBinaryToWriter
    );
  }
};


/**
 * repeated TrailerType trailer_types = 1;
 * @return {!Array<!proto.vtv1.TrailerType>}
 */
proto.vtv1.GetTrailerTypesForFuelMatrixResponse.prototype.getTrailerTypesList = function() {
  return /** @type{!Array<!proto.vtv1.TrailerType>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_vorto_types_types_pb.TrailerType, 1));
};


/**
 * @param {!Array<!proto.vtv1.TrailerType>} value
 * @return {!proto.vtv1.GetTrailerTypesForFuelMatrixResponse} returns this
*/
proto.vtv1.GetTrailerTypesForFuelMatrixResponse.prototype.setTrailerTypesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.vtv1.TrailerType=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.TrailerType}
 */
proto.vtv1.GetTrailerTypesForFuelMatrixResponse.prototype.addTrailerTypes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.vtv1.TrailerType, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.GetTrailerTypesForFuelMatrixResponse} returns this
 */
proto.vtv1.GetTrailerTypesForFuelMatrixResponse.prototype.clearTrailerTypesList = function() {
  return this.setTrailerTypesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.ListFuelMatricesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.ListFuelMatricesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.ListFuelMatricesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.ListFuelMatricesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.ListFuelMatricesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    matricesList: jspb.Message.toObjectList(msg.getMatricesList(),
    proto_vorto_node_node_pb.FuelMatrix.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.ListFuelMatricesResponse}
 */
proto.vtv1.ListFuelMatricesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.ListFuelMatricesResponse;
  return proto.vtv1.ListFuelMatricesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.ListFuelMatricesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.ListFuelMatricesResponse}
 */
proto.vtv1.ListFuelMatricesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_vorto_node_node_pb.FuelMatrix;
      reader.readMessage(value,proto_vorto_node_node_pb.FuelMatrix.deserializeBinaryFromReader);
      msg.addMatrices(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.ListFuelMatricesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.ListFuelMatricesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.ListFuelMatricesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.ListFuelMatricesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMatricesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto_vorto_node_node_pb.FuelMatrix.serializeBinaryToWriter
    );
  }
};


/**
 * repeated FuelMatrix matrices = 1;
 * @return {!Array<!proto.vtv1.FuelMatrix>}
 */
proto.vtv1.ListFuelMatricesResponse.prototype.getMatricesList = function() {
  return /** @type{!Array<!proto.vtv1.FuelMatrix>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_vorto_node_node_pb.FuelMatrix, 1));
};


/**
 * @param {!Array<!proto.vtv1.FuelMatrix>} value
 * @return {!proto.vtv1.ListFuelMatricesResponse} returns this
*/
proto.vtv1.ListFuelMatricesResponse.prototype.setMatricesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.vtv1.FuelMatrix=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.FuelMatrix}
 */
proto.vtv1.ListFuelMatricesResponse.prototype.addMatrices = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.vtv1.FuelMatrix, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.ListFuelMatricesResponse} returns this
 */
proto.vtv1.ListFuelMatricesResponse.prototype.clearMatricesList = function() {
  return this.setMatricesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.CreateFuelMatrixRequest.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.CreateFuelMatrixRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.CreateFuelMatrixRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.CreateFuelMatrixRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.CreateFuelMatrixRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    trailerTypesList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    entriesList: jspb.Message.toObjectList(msg.getEntriesList(),
    proto_vorto_node_node_pb.FuelMatrixEntry.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.CreateFuelMatrixRequest}
 */
proto.vtv1.CreateFuelMatrixRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.CreateFuelMatrixRequest;
  return proto.vtv1.CreateFuelMatrixRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.CreateFuelMatrixRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.CreateFuelMatrixRequest}
 */
proto.vtv1.CreateFuelMatrixRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Array<number>} */ (reader.readPackedInt64());
      msg.setTrailerTypesList(value);
      break;
    case 2:
      var value = new proto_vorto_node_node_pb.FuelMatrixEntry;
      reader.readMessage(value,proto_vorto_node_node_pb.FuelMatrixEntry.deserializeBinaryFromReader);
      msg.addEntries(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.CreateFuelMatrixRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.CreateFuelMatrixRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.CreateFuelMatrixRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.CreateFuelMatrixRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTrailerTypesList();
  if (f.length > 0) {
    writer.writePackedInt64(
      1,
      f
    );
  }
  f = message.getEntriesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto_vorto_node_node_pb.FuelMatrixEntry.serializeBinaryToWriter
    );
  }
};


/**
 * repeated int64 trailer_types = 1;
 * @return {!Array<number>}
 */
proto.vtv1.CreateFuelMatrixRequest.prototype.getTrailerTypesList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.vtv1.CreateFuelMatrixRequest} returns this
 */
proto.vtv1.CreateFuelMatrixRequest.prototype.setTrailerTypesList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.vtv1.CreateFuelMatrixRequest} returns this
 */
proto.vtv1.CreateFuelMatrixRequest.prototype.addTrailerTypes = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.CreateFuelMatrixRequest} returns this
 */
proto.vtv1.CreateFuelMatrixRequest.prototype.clearTrailerTypesList = function() {
  return this.setTrailerTypesList([]);
};


/**
 * repeated FuelMatrixEntry entries = 2;
 * @return {!Array<!proto.vtv1.FuelMatrixEntry>}
 */
proto.vtv1.CreateFuelMatrixRequest.prototype.getEntriesList = function() {
  return /** @type{!Array<!proto.vtv1.FuelMatrixEntry>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_vorto_node_node_pb.FuelMatrixEntry, 2));
};


/**
 * @param {!Array<!proto.vtv1.FuelMatrixEntry>} value
 * @return {!proto.vtv1.CreateFuelMatrixRequest} returns this
*/
proto.vtv1.CreateFuelMatrixRequest.prototype.setEntriesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.vtv1.FuelMatrixEntry=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.FuelMatrixEntry}
 */
proto.vtv1.CreateFuelMatrixRequest.prototype.addEntries = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.vtv1.FuelMatrixEntry, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.CreateFuelMatrixRequest} returns this
 */
proto.vtv1.CreateFuelMatrixRequest.prototype.clearEntriesList = function() {
  return this.setEntriesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.EditFuelMatrixRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.EditFuelMatrixRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.EditFuelMatrixRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.EditFuelMatrixRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.EditFuelMatrixRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    entriesList: jspb.Message.toObjectList(msg.getEntriesList(),
    proto_vorto_node_node_pb.FuelMatrixEntry.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.EditFuelMatrixRequest}
 */
proto.vtv1.EditFuelMatrixRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.EditFuelMatrixRequest;
  return proto.vtv1.EditFuelMatrixRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.EditFuelMatrixRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.EditFuelMatrixRequest}
 */
proto.vtv1.EditFuelMatrixRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new proto_vorto_node_node_pb.FuelMatrixEntry;
      reader.readMessage(value,proto_vorto_node_node_pb.FuelMatrixEntry.deserializeBinaryFromReader);
      msg.addEntries(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.EditFuelMatrixRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.EditFuelMatrixRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.EditFuelMatrixRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.EditFuelMatrixRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEntriesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto_vorto_node_node_pb.FuelMatrixEntry.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.vtv1.EditFuelMatrixRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.EditFuelMatrixRequest} returns this
 */
proto.vtv1.EditFuelMatrixRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated FuelMatrixEntry entries = 2;
 * @return {!Array<!proto.vtv1.FuelMatrixEntry>}
 */
proto.vtv1.EditFuelMatrixRequest.prototype.getEntriesList = function() {
  return /** @type{!Array<!proto.vtv1.FuelMatrixEntry>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_vorto_node_node_pb.FuelMatrixEntry, 2));
};


/**
 * @param {!Array<!proto.vtv1.FuelMatrixEntry>} value
 * @return {!proto.vtv1.EditFuelMatrixRequest} returns this
*/
proto.vtv1.EditFuelMatrixRequest.prototype.setEntriesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.vtv1.FuelMatrixEntry=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.FuelMatrixEntry}
 */
proto.vtv1.EditFuelMatrixRequest.prototype.addEntries = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.vtv1.FuelMatrixEntry, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.EditFuelMatrixRequest} returns this
 */
proto.vtv1.EditFuelMatrixRequest.prototype.clearEntriesList = function() {
  return this.setEntriesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.NodeGetSiteStatisticsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.NodeGetSiteStatisticsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.NodeGetSiteStatisticsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.NodeGetSiteStatisticsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    statistics: (f = msg.getStatistics()) && proto_vorto_types_types_pb.SiteStatistics.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.NodeGetSiteStatisticsResponse}
 */
proto.vtv1.NodeGetSiteStatisticsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.NodeGetSiteStatisticsResponse;
  return proto.vtv1.NodeGetSiteStatisticsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.NodeGetSiteStatisticsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.NodeGetSiteStatisticsResponse}
 */
proto.vtv1.NodeGetSiteStatisticsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_vorto_types_types_pb.SiteStatistics;
      reader.readMessage(value,proto_vorto_types_types_pb.SiteStatistics.deserializeBinaryFromReader);
      msg.setStatistics(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.NodeGetSiteStatisticsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.NodeGetSiteStatisticsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.NodeGetSiteStatisticsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.NodeGetSiteStatisticsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatistics();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_vorto_types_types_pb.SiteStatistics.serializeBinaryToWriter
    );
  }
};


/**
 * optional SiteStatistics statistics = 1;
 * @return {?proto.vtv1.SiteStatistics}
 */
proto.vtv1.NodeGetSiteStatisticsResponse.prototype.getStatistics = function() {
  return /** @type{?proto.vtv1.SiteStatistics} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.SiteStatistics, 1));
};


/**
 * @param {?proto.vtv1.SiteStatistics|undefined} value
 * @return {!proto.vtv1.NodeGetSiteStatisticsResponse} returns this
*/
proto.vtv1.NodeGetSiteStatisticsResponse.prototype.setStatistics = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.NodeGetSiteStatisticsResponse} returns this
 */
proto.vtv1.NodeGetSiteStatisticsResponse.prototype.clearStatistics = function() {
  return this.setStatistics(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.NodeGetSiteStatisticsResponse.prototype.hasStatistics = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.GetSitesWithPickupsTodayResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.GetSitesWithPickupsTodayResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.GetSitesWithPickupsTodayResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.GetSitesWithPickupsTodayResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.GetSitesWithPickupsTodayResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    sitesList: jspb.Message.toObjectList(msg.getSitesList(),
    proto_vorto_node_node_pb.SiteWithPickupToday.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.GetSitesWithPickupsTodayResponse}
 */
proto.vtv1.GetSitesWithPickupsTodayResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.GetSitesWithPickupsTodayResponse;
  return proto.vtv1.GetSitesWithPickupsTodayResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.GetSitesWithPickupsTodayResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.GetSitesWithPickupsTodayResponse}
 */
proto.vtv1.GetSitesWithPickupsTodayResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_vorto_node_node_pb.SiteWithPickupToday;
      reader.readMessage(value,proto_vorto_node_node_pb.SiteWithPickupToday.deserializeBinaryFromReader);
      msg.addSites(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.GetSitesWithPickupsTodayResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.GetSitesWithPickupsTodayResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.GetSitesWithPickupsTodayResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.GetSitesWithPickupsTodayResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSitesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto_vorto_node_node_pb.SiteWithPickupToday.serializeBinaryToWriter
    );
  }
};


/**
 * repeated SiteWithPickupToday sites = 1;
 * @return {!Array<!proto.vtv1.SiteWithPickupToday>}
 */
proto.vtv1.GetSitesWithPickupsTodayResponse.prototype.getSitesList = function() {
  return /** @type{!Array<!proto.vtv1.SiteWithPickupToday>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_vorto_node_node_pb.SiteWithPickupToday, 1));
};


/**
 * @param {!Array<!proto.vtv1.SiteWithPickupToday>} value
 * @return {!proto.vtv1.GetSitesWithPickupsTodayResponse} returns this
*/
proto.vtv1.GetSitesWithPickupsTodayResponse.prototype.setSitesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.vtv1.SiteWithPickupToday=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.SiteWithPickupToday}
 */
proto.vtv1.GetSitesWithPickupsTodayResponse.prototype.addSites = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.vtv1.SiteWithPickupToday, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.GetSitesWithPickupsTodayResponse} returns this
 */
proto.vtv1.GetSitesWithPickupsTodayResponse.prototype.clearSitesList = function() {
  return this.setSitesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.GetOutboundEnRouteOrdersResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.GetOutboundEnRouteOrdersResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.GetOutboundEnRouteOrdersResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.GetOutboundEnRouteOrdersResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.GetOutboundEnRouteOrdersResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    ordersList: jspb.Message.toObjectList(msg.getOrdersList(),
    proto_vorto_node_node_pb.PendingOrder.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.GetOutboundEnRouteOrdersResponse}
 */
proto.vtv1.GetOutboundEnRouteOrdersResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.GetOutboundEnRouteOrdersResponse;
  return proto.vtv1.GetOutboundEnRouteOrdersResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.GetOutboundEnRouteOrdersResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.GetOutboundEnRouteOrdersResponse}
 */
proto.vtv1.GetOutboundEnRouteOrdersResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_vorto_node_node_pb.PendingOrder;
      reader.readMessage(value,proto_vorto_node_node_pb.PendingOrder.deserializeBinaryFromReader);
      msg.addOrders(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.GetOutboundEnRouteOrdersResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.GetOutboundEnRouteOrdersResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.GetOutboundEnRouteOrdersResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.GetOutboundEnRouteOrdersResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrdersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto_vorto_node_node_pb.PendingOrder.serializeBinaryToWriter
    );
  }
};


/**
 * repeated PendingOrder orders = 1;
 * @return {!Array<!proto.vtv1.PendingOrder>}
 */
proto.vtv1.GetOutboundEnRouteOrdersResponse.prototype.getOrdersList = function() {
  return /** @type{!Array<!proto.vtv1.PendingOrder>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_vorto_node_node_pb.PendingOrder, 1));
};


/**
 * @param {!Array<!proto.vtv1.PendingOrder>} value
 * @return {!proto.vtv1.GetOutboundEnRouteOrdersResponse} returns this
*/
proto.vtv1.GetOutboundEnRouteOrdersResponse.prototype.setOrdersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.vtv1.PendingOrder=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.PendingOrder}
 */
proto.vtv1.GetOutboundEnRouteOrdersResponse.prototype.addOrders = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.vtv1.PendingOrder, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.GetOutboundEnRouteOrdersResponse} returns this
 */
proto.vtv1.GetOutboundEnRouteOrdersResponse.prototype.clearOrdersList = function() {
  return this.setOrdersList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.GetOutBoundOnSiteOrdersResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.GetOutBoundOnSiteOrdersResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.GetOutBoundOnSiteOrdersResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.GetOutBoundOnSiteOrdersResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.GetOutBoundOnSiteOrdersResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    ordersList: jspb.Message.toObjectList(msg.getOrdersList(),
    proto_vorto_node_node_pb.PendingOrder.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.GetOutBoundOnSiteOrdersResponse}
 */
proto.vtv1.GetOutBoundOnSiteOrdersResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.GetOutBoundOnSiteOrdersResponse;
  return proto.vtv1.GetOutBoundOnSiteOrdersResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.GetOutBoundOnSiteOrdersResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.GetOutBoundOnSiteOrdersResponse}
 */
proto.vtv1.GetOutBoundOnSiteOrdersResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_vorto_node_node_pb.PendingOrder;
      reader.readMessage(value,proto_vorto_node_node_pb.PendingOrder.deserializeBinaryFromReader);
      msg.addOrders(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.GetOutBoundOnSiteOrdersResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.GetOutBoundOnSiteOrdersResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.GetOutBoundOnSiteOrdersResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.GetOutBoundOnSiteOrdersResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrdersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto_vorto_node_node_pb.PendingOrder.serializeBinaryToWriter
    );
  }
};


/**
 * repeated PendingOrder orders = 1;
 * @return {!Array<!proto.vtv1.PendingOrder>}
 */
proto.vtv1.GetOutBoundOnSiteOrdersResponse.prototype.getOrdersList = function() {
  return /** @type{!Array<!proto.vtv1.PendingOrder>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_vorto_node_node_pb.PendingOrder, 1));
};


/**
 * @param {!Array<!proto.vtv1.PendingOrder>} value
 * @return {!proto.vtv1.GetOutBoundOnSiteOrdersResponse} returns this
*/
proto.vtv1.GetOutBoundOnSiteOrdersResponse.prototype.setOrdersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.vtv1.PendingOrder=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.PendingOrder}
 */
proto.vtv1.GetOutBoundOnSiteOrdersResponse.prototype.addOrders = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.vtv1.PendingOrder, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.GetOutBoundOnSiteOrdersResponse} returns this
 */
proto.vtv1.GetOutBoundOnSiteOrdersResponse.prototype.clearOrdersList = function() {
  return this.setOrdersList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.GetOutboundScheduledOrdersResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.GetOutboundScheduledOrdersResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.GetOutboundScheduledOrdersResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.GetOutboundScheduledOrdersResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.GetOutboundScheduledOrdersResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    ordersList: jspb.Message.toObjectList(msg.getOrdersList(),
    proto_vorto_node_node_pb.PendingOrder.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.GetOutboundScheduledOrdersResponse}
 */
proto.vtv1.GetOutboundScheduledOrdersResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.GetOutboundScheduledOrdersResponse;
  return proto.vtv1.GetOutboundScheduledOrdersResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.GetOutboundScheduledOrdersResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.GetOutboundScheduledOrdersResponse}
 */
proto.vtv1.GetOutboundScheduledOrdersResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_vorto_node_node_pb.PendingOrder;
      reader.readMessage(value,proto_vorto_node_node_pb.PendingOrder.deserializeBinaryFromReader);
      msg.addOrders(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.GetOutboundScheduledOrdersResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.GetOutboundScheduledOrdersResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.GetOutboundScheduledOrdersResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.GetOutboundScheduledOrdersResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrdersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto_vorto_node_node_pb.PendingOrder.serializeBinaryToWriter
    );
  }
};


/**
 * repeated PendingOrder orders = 1;
 * @return {!Array<!proto.vtv1.PendingOrder>}
 */
proto.vtv1.GetOutboundScheduledOrdersResponse.prototype.getOrdersList = function() {
  return /** @type{!Array<!proto.vtv1.PendingOrder>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_vorto_node_node_pb.PendingOrder, 1));
};


/**
 * @param {!Array<!proto.vtv1.PendingOrder>} value
 * @return {!proto.vtv1.GetOutboundScheduledOrdersResponse} returns this
*/
proto.vtv1.GetOutboundScheduledOrdersResponse.prototype.setOrdersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.vtv1.PendingOrder=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.PendingOrder}
 */
proto.vtv1.GetOutboundScheduledOrdersResponse.prototype.addOrders = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.vtv1.PendingOrder, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.GetOutboundScheduledOrdersResponse} returns this
 */
proto.vtv1.GetOutboundScheduledOrdersResponse.prototype.clearOrdersList = function() {
  return this.setOrdersList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.ListPendingOrdersToConfirmResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.ListPendingOrdersToConfirmResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.ListPendingOrdersToConfirmResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.ListPendingOrdersToConfirmResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.ListPendingOrdersToConfirmResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    ordersList: jspb.Message.toObjectList(msg.getOrdersList(),
    proto_vorto_node_node_pb.PendingOrder.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.ListPendingOrdersToConfirmResponse}
 */
proto.vtv1.ListPendingOrdersToConfirmResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.ListPendingOrdersToConfirmResponse;
  return proto.vtv1.ListPendingOrdersToConfirmResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.ListPendingOrdersToConfirmResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.ListPendingOrdersToConfirmResponse}
 */
proto.vtv1.ListPendingOrdersToConfirmResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_vorto_node_node_pb.PendingOrder;
      reader.readMessage(value,proto_vorto_node_node_pb.PendingOrder.deserializeBinaryFromReader);
      msg.addOrders(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.ListPendingOrdersToConfirmResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.ListPendingOrdersToConfirmResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.ListPendingOrdersToConfirmResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.ListPendingOrdersToConfirmResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrdersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto_vorto_node_node_pb.PendingOrder.serializeBinaryToWriter
    );
  }
};


/**
 * repeated PendingOrder orders = 1;
 * @return {!Array<!proto.vtv1.PendingOrder>}
 */
proto.vtv1.ListPendingOrdersToConfirmResponse.prototype.getOrdersList = function() {
  return /** @type{!Array<!proto.vtv1.PendingOrder>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_vorto_node_node_pb.PendingOrder, 1));
};


/**
 * @param {!Array<!proto.vtv1.PendingOrder>} value
 * @return {!proto.vtv1.ListPendingOrdersToConfirmResponse} returns this
*/
proto.vtv1.ListPendingOrdersToConfirmResponse.prototype.setOrdersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.vtv1.PendingOrder=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.PendingOrder}
 */
proto.vtv1.ListPendingOrdersToConfirmResponse.prototype.addOrders = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.vtv1.PendingOrder, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.ListPendingOrdersToConfirmResponse} returns this
 */
proto.vtv1.ListPendingOrdersToConfirmResponse.prototype.clearOrdersList = function() {
  return this.setOrdersList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.ListPurchaseOrdersToConfirmRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.ListPurchaseOrdersToConfirmRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.ListPurchaseOrdersToConfirmRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.ListPurchaseOrdersToConfirmRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.ListPurchaseOrdersToConfirmRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    sitegroupidList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.ListPurchaseOrdersToConfirmRequest}
 */
proto.vtv1.ListPurchaseOrdersToConfirmRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.ListPurchaseOrdersToConfirmRequest;
  return proto.vtv1.ListPurchaseOrdersToConfirmRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.ListPurchaseOrdersToConfirmRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.ListPurchaseOrdersToConfirmRequest}
 */
proto.vtv1.ListPurchaseOrdersToConfirmRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Array<number>} */ (reader.readPackedInt64());
      msg.setSitegroupidList(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.ListPurchaseOrdersToConfirmRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.ListPurchaseOrdersToConfirmRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.ListPurchaseOrdersToConfirmRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.ListPurchaseOrdersToConfirmRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSitegroupidList();
  if (f.length > 0) {
    writer.writePackedInt64(
      1,
      f
    );
  }
};


/**
 * repeated int64 siteGroupId = 1;
 * @return {!Array<number>}
 */
proto.vtv1.ListPurchaseOrdersToConfirmRequest.prototype.getSitegroupidList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.vtv1.ListPurchaseOrdersToConfirmRequest} returns this
 */
proto.vtv1.ListPurchaseOrdersToConfirmRequest.prototype.setSitegroupidList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.vtv1.ListPurchaseOrdersToConfirmRequest} returns this
 */
proto.vtv1.ListPurchaseOrdersToConfirmRequest.prototype.addSitegroupid = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.ListPurchaseOrdersToConfirmRequest} returns this
 */
proto.vtv1.ListPurchaseOrdersToConfirmRequest.prototype.clearSitegroupidList = function() {
  return this.setSitegroupidList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.ListPurchaseOrdersToConfirmResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.ListPurchaseOrdersToConfirmResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.ListPurchaseOrdersToConfirmResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.ListPurchaseOrdersToConfirmResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.ListPurchaseOrdersToConfirmResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    purchaseOrdersList: jspb.Message.toObjectList(msg.getPurchaseOrdersList(),
    proto_vorto_node_node_pb.PendingPurchaseOrder.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.ListPurchaseOrdersToConfirmResponse}
 */
proto.vtv1.ListPurchaseOrdersToConfirmResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.ListPurchaseOrdersToConfirmResponse;
  return proto.vtv1.ListPurchaseOrdersToConfirmResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.ListPurchaseOrdersToConfirmResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.ListPurchaseOrdersToConfirmResponse}
 */
proto.vtv1.ListPurchaseOrdersToConfirmResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_vorto_node_node_pb.PendingPurchaseOrder;
      reader.readMessage(value,proto_vorto_node_node_pb.PendingPurchaseOrder.deserializeBinaryFromReader);
      msg.addPurchaseOrders(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.ListPurchaseOrdersToConfirmResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.ListPurchaseOrdersToConfirmResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.ListPurchaseOrdersToConfirmResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.ListPurchaseOrdersToConfirmResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPurchaseOrdersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto_vorto_node_node_pb.PendingPurchaseOrder.serializeBinaryToWriter
    );
  }
};


/**
 * repeated PendingPurchaseOrder purchase_orders = 1;
 * @return {!Array<!proto.vtv1.PendingPurchaseOrder>}
 */
proto.vtv1.ListPurchaseOrdersToConfirmResponse.prototype.getPurchaseOrdersList = function() {
  return /** @type{!Array<!proto.vtv1.PendingPurchaseOrder>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_vorto_node_node_pb.PendingPurchaseOrder, 1));
};


/**
 * @param {!Array<!proto.vtv1.PendingPurchaseOrder>} value
 * @return {!proto.vtv1.ListPurchaseOrdersToConfirmResponse} returns this
*/
proto.vtv1.ListPurchaseOrdersToConfirmResponse.prototype.setPurchaseOrdersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.vtv1.PendingPurchaseOrder=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.PendingPurchaseOrder}
 */
proto.vtv1.ListPurchaseOrdersToConfirmResponse.prototype.addPurchaseOrders = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.vtv1.PendingPurchaseOrder, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.ListPurchaseOrdersToConfirmResponse} returns this
 */
proto.vtv1.ListPurchaseOrdersToConfirmResponse.prototype.clearPurchaseOrdersList = function() {
  return this.setPurchaseOrdersList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.GetInventoriesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.GetInventoriesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.GetInventoriesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.GetInventoriesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.GetInventoriesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    inventoriesList: jspb.Message.toObjectList(msg.getInventoriesList(),
    proto_vorto_node_node_pb.InventoryListItem.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.GetInventoriesResponse}
 */
proto.vtv1.GetInventoriesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.GetInventoriesResponse;
  return proto.vtv1.GetInventoriesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.GetInventoriesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.GetInventoriesResponse}
 */
proto.vtv1.GetInventoriesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_vorto_node_node_pb.InventoryListItem;
      reader.readMessage(value,proto_vorto_node_node_pb.InventoryListItem.deserializeBinaryFromReader);
      msg.addInventories(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.GetInventoriesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.GetInventoriesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.GetInventoriesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.GetInventoriesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInventoriesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto_vorto_node_node_pb.InventoryListItem.serializeBinaryToWriter
    );
  }
};


/**
 * repeated InventoryListItem inventories = 1;
 * @return {!Array<!proto.vtv1.InventoryListItem>}
 */
proto.vtv1.GetInventoriesResponse.prototype.getInventoriesList = function() {
  return /** @type{!Array<!proto.vtv1.InventoryListItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_vorto_node_node_pb.InventoryListItem, 1));
};


/**
 * @param {!Array<!proto.vtv1.InventoryListItem>} value
 * @return {!proto.vtv1.GetInventoriesResponse} returns this
*/
proto.vtv1.GetInventoriesResponse.prototype.setInventoriesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.vtv1.InventoryListItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.InventoryListItem}
 */
proto.vtv1.GetInventoriesResponse.prototype.addInventories = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.vtv1.InventoryListItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.GetInventoriesResponse} returns this
 */
proto.vtv1.GetInventoriesResponse.prototype.clearInventoriesList = function() {
  return this.setInventoriesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.GetInventoryIssuesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.GetInventoryIssuesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.GetInventoryIssuesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.GetInventoryIssuesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.GetInventoryIssuesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    issuesList: jspb.Message.toObjectList(msg.getIssuesList(),
    proto_vorto_node_node_pb.InventoryIssuesListItem.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.GetInventoryIssuesResponse}
 */
proto.vtv1.GetInventoryIssuesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.GetInventoryIssuesResponse;
  return proto.vtv1.GetInventoryIssuesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.GetInventoryIssuesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.GetInventoryIssuesResponse}
 */
proto.vtv1.GetInventoryIssuesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_vorto_node_node_pb.InventoryIssuesListItem;
      reader.readMessage(value,proto_vorto_node_node_pb.InventoryIssuesListItem.deserializeBinaryFromReader);
      msg.addIssues(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.GetInventoryIssuesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.GetInventoryIssuesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.GetInventoryIssuesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.GetInventoryIssuesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIssuesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto_vorto_node_node_pb.InventoryIssuesListItem.serializeBinaryToWriter
    );
  }
};


/**
 * repeated InventoryIssuesListItem issues = 1;
 * @return {!Array<!proto.vtv1.InventoryIssuesListItem>}
 */
proto.vtv1.GetInventoryIssuesResponse.prototype.getIssuesList = function() {
  return /** @type{!Array<!proto.vtv1.InventoryIssuesListItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_vorto_node_node_pb.InventoryIssuesListItem, 1));
};


/**
 * @param {!Array<!proto.vtv1.InventoryIssuesListItem>} value
 * @return {!proto.vtv1.GetInventoryIssuesResponse} returns this
*/
proto.vtv1.GetInventoryIssuesResponse.prototype.setIssuesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.vtv1.InventoryIssuesListItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.InventoryIssuesListItem}
 */
proto.vtv1.GetInventoryIssuesResponse.prototype.addIssues = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.vtv1.InventoryIssuesListItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.GetInventoryIssuesResponse} returns this
 */
proto.vtv1.GetInventoryIssuesResponse.prototype.clearIssuesList = function() {
  return this.setIssuesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.GetOutboundInventoriesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.GetOutboundInventoriesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.GetOutboundInventoriesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.GetOutboundInventoriesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.GetOutboundInventoriesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    inventoriesList: jspb.Message.toObjectList(msg.getInventoriesList(),
    proto_vorto_node_node_pb.OutboundInventory.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.GetOutboundInventoriesResponse}
 */
proto.vtv1.GetOutboundInventoriesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.GetOutboundInventoriesResponse;
  return proto.vtv1.GetOutboundInventoriesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.GetOutboundInventoriesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.GetOutboundInventoriesResponse}
 */
proto.vtv1.GetOutboundInventoriesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_vorto_node_node_pb.OutboundInventory;
      reader.readMessage(value,proto_vorto_node_node_pb.OutboundInventory.deserializeBinaryFromReader);
      msg.addInventories(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.GetOutboundInventoriesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.GetOutboundInventoriesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.GetOutboundInventoriesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.GetOutboundInventoriesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInventoriesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto_vorto_node_node_pb.OutboundInventory.serializeBinaryToWriter
    );
  }
};


/**
 * repeated OutboundInventory inventories = 1;
 * @return {!Array<!proto.vtv1.OutboundInventory>}
 */
proto.vtv1.GetOutboundInventoriesResponse.prototype.getInventoriesList = function() {
  return /** @type{!Array<!proto.vtv1.OutboundInventory>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_vorto_node_node_pb.OutboundInventory, 1));
};


/**
 * @param {!Array<!proto.vtv1.OutboundInventory>} value
 * @return {!proto.vtv1.GetOutboundInventoriesResponse} returns this
*/
proto.vtv1.GetOutboundInventoriesResponse.prototype.setInventoriesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.vtv1.OutboundInventory=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.OutboundInventory}
 */
proto.vtv1.GetOutboundInventoriesResponse.prototype.addInventories = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.vtv1.OutboundInventory, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.GetOutboundInventoriesResponse} returns this
 */
proto.vtv1.GetOutboundInventoriesResponse.prototype.clearInventoriesList = function() {
  return this.setInventoriesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.GetInboundInventoriesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.GetInboundInventoriesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.GetInboundInventoriesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.GetInboundInventoriesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.GetInboundInventoriesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    inventoriesList: jspb.Message.toObjectList(msg.getInventoriesList(),
    proto_vorto_node_node_pb.InboundInventory.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.GetInboundInventoriesResponse}
 */
proto.vtv1.GetInboundInventoriesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.GetInboundInventoriesResponse;
  return proto.vtv1.GetInboundInventoriesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.GetInboundInventoriesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.GetInboundInventoriesResponse}
 */
proto.vtv1.GetInboundInventoriesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_vorto_node_node_pb.InboundInventory;
      reader.readMessage(value,proto_vorto_node_node_pb.InboundInventory.deserializeBinaryFromReader);
      msg.addInventories(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.GetInboundInventoriesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.GetInboundInventoriesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.GetInboundInventoriesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.GetInboundInventoriesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInventoriesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto_vorto_node_node_pb.InboundInventory.serializeBinaryToWriter
    );
  }
};


/**
 * repeated InboundInventory inventories = 1;
 * @return {!Array<!proto.vtv1.InboundInventory>}
 */
proto.vtv1.GetInboundInventoriesResponse.prototype.getInventoriesList = function() {
  return /** @type{!Array<!proto.vtv1.InboundInventory>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_vorto_node_node_pb.InboundInventory, 1));
};


/**
 * @param {!Array<!proto.vtv1.InboundInventory>} value
 * @return {!proto.vtv1.GetInboundInventoriesResponse} returns this
*/
proto.vtv1.GetInboundInventoriesResponse.prototype.setInventoriesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.vtv1.InboundInventory=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.InboundInventory}
 */
proto.vtv1.GetInboundInventoriesResponse.prototype.addInventories = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.vtv1.InboundInventory, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.GetInboundInventoriesResponse} returns this
 */
proto.vtv1.GetInboundInventoriesResponse.prototype.clearInventoriesList = function() {
  return this.setInventoriesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.NodeListTodaysShipmentsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.NodeListTodaysShipmentsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.NodeListTodaysShipmentsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.NodeListTodaysShipmentsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.NodeListTodaysShipmentsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    sitesList: jspb.Message.toObjectList(msg.getSitesList(),
    proto_vorto_node_node_pb.SlimSiteShipments.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.NodeListTodaysShipmentsResponse}
 */
proto.vtv1.NodeListTodaysShipmentsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.NodeListTodaysShipmentsResponse;
  return proto.vtv1.NodeListTodaysShipmentsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.NodeListTodaysShipmentsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.NodeListTodaysShipmentsResponse}
 */
proto.vtv1.NodeListTodaysShipmentsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_vorto_node_node_pb.SlimSiteShipments;
      reader.readMessage(value,proto_vorto_node_node_pb.SlimSiteShipments.deserializeBinaryFromReader);
      msg.addSites(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.NodeListTodaysShipmentsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.NodeListTodaysShipmentsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.NodeListTodaysShipmentsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.NodeListTodaysShipmentsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSitesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto_vorto_node_node_pb.SlimSiteShipments.serializeBinaryToWriter
    );
  }
};


/**
 * repeated SlimSiteShipments sites = 1;
 * @return {!Array<!proto.vtv1.SlimSiteShipments>}
 */
proto.vtv1.NodeListTodaysShipmentsResponse.prototype.getSitesList = function() {
  return /** @type{!Array<!proto.vtv1.SlimSiteShipments>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_vorto_node_node_pb.SlimSiteShipments, 1));
};


/**
 * @param {!Array<!proto.vtv1.SlimSiteShipments>} value
 * @return {!proto.vtv1.NodeListTodaysShipmentsResponse} returns this
*/
proto.vtv1.NodeListTodaysShipmentsResponse.prototype.setSitesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.vtv1.SlimSiteShipments=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.SlimSiteShipments}
 */
proto.vtv1.NodeListTodaysShipmentsResponse.prototype.addSites = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.vtv1.SlimSiteShipments, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.NodeListTodaysShipmentsResponse} returns this
 */
proto.vtv1.NodeListTodaysShipmentsResponse.prototype.clearSitesList = function() {
  return this.setSitesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.ListInNetworkSuppliersRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.ListInNetworkSuppliersRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.ListInNetworkSuppliersRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.ListInNetworkSuppliersRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    hasPo: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.ListInNetworkSuppliersRequest}
 */
proto.vtv1.ListInNetworkSuppliersRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.ListInNetworkSuppliersRequest;
  return proto.vtv1.ListInNetworkSuppliersRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.ListInNetworkSuppliersRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.ListInNetworkSuppliersRequest}
 */
proto.vtv1.ListInNetworkSuppliersRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasPo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.ListInNetworkSuppliersRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.ListInNetworkSuppliersRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.ListInNetworkSuppliersRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.ListInNetworkSuppliersRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHasPo();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool has_po = 1;
 * @return {boolean}
 */
proto.vtv1.ListInNetworkSuppliersRequest.prototype.getHasPo = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vtv1.ListInNetworkSuppliersRequest} returns this
 */
proto.vtv1.ListInNetworkSuppliersRequest.prototype.setHasPo = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.CreateOrderRequestClonedInventory.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.CreateOrderRequestClonedInventory.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.CreateOrderRequestClonedInventory} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.CreateOrderRequestClonedInventory.toObject = function(includeInstance, msg) {
  var f, obj = {
    isClonedInventory: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    inventoryToCloneId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.CreateOrderRequestClonedInventory}
 */
proto.vtv1.CreateOrderRequestClonedInventory.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.CreateOrderRequestClonedInventory;
  return proto.vtv1.CreateOrderRequestClonedInventory.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.CreateOrderRequestClonedInventory} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.CreateOrderRequestClonedInventory}
 */
proto.vtv1.CreateOrderRequestClonedInventory.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsClonedInventory(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setInventoryToCloneId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.CreateOrderRequestClonedInventory.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.CreateOrderRequestClonedInventory.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.CreateOrderRequestClonedInventory} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.CreateOrderRequestClonedInventory.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsClonedInventory();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getInventoryToCloneId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional bool is_cloned_inventory = 1;
 * @return {boolean}
 */
proto.vtv1.CreateOrderRequestClonedInventory.prototype.getIsClonedInventory = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vtv1.CreateOrderRequestClonedInventory} returns this
 */
proto.vtv1.CreateOrderRequestClonedInventory.prototype.setIsClonedInventory = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string inventory_to_clone_id = 2;
 * @return {string}
 */
proto.vtv1.CreateOrderRequestClonedInventory.prototype.getInventoryToCloneId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.CreateOrderRequestClonedInventory} returns this
 */
proto.vtv1.CreateOrderRequestClonedInventory.prototype.setInventoryToCloneId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.CreateOrderRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.CreateOrderRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.CreateOrderRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.CreateOrderRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    productId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    supplierId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    purchaseOrderId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    quantity: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    originSiteId: jspb.Message.getFieldWithDefault(msg, 5, ""),
    destinationSiteId: jspb.Message.getFieldWithDefault(msg, 6, ""),
    deliveryWindowStartsAt: (f = msg.getDeliveryWindowStartsAt()) && proto_vorto_types_types_pb.NullableTime.toObject(includeInstance, f),
    deliveryWindowMinutes: (f = msg.getDeliveryWindowMinutes()) && proto_vorto_types_types_pb.NullableInt64.toObject(includeInstance, f),
    carrierContractId: (f = msg.getCarrierContractId()) && proto_vorto_types_types_pb.NullableString.toObject(includeInstance, f),
    isAsap: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
    quantityOfTruckloads: jspb.Message.getFieldWithDefault(msg, 11, 0),
    description: (f = msg.getDescription()) && proto_vorto_types_types_pb.NullableString.toObject(includeInstance, f),
    hasBackhaul: jspb.Message.getBooleanFieldWithDefault(msg, 13, false),
    inventoryId: (f = msg.getInventoryId()) && proto_vorto_types_types_pb.NullableString.toObject(includeInstance, f),
    cloneInventory: (f = msg.getCloneInventory()) && proto.vtv1.CreateOrderRequestClonedInventory.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.CreateOrderRequest}
 */
proto.vtv1.CreateOrderRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.CreateOrderRequest;
  return proto.vtv1.CreateOrderRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.CreateOrderRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.CreateOrderRequest}
 */
proto.vtv1.CreateOrderRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSupplierId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPurchaseOrderId(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setQuantity(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setOriginSiteId(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setDestinationSiteId(value);
      break;
    case 7:
      var value = new proto_vorto_types_types_pb.NullableTime;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableTime.deserializeBinaryFromReader);
      msg.setDeliveryWindowStartsAt(value);
      break;
    case 8:
      var value = new proto_vorto_types_types_pb.NullableInt64;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableInt64.deserializeBinaryFromReader);
      msg.setDeliveryWindowMinutes(value);
      break;
    case 9:
      var value = new proto_vorto_types_types_pb.NullableString;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableString.deserializeBinaryFromReader);
      msg.setCarrierContractId(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsAsap(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setQuantityOfTruckloads(value);
      break;
    case 12:
      var value = new proto_vorto_types_types_pb.NullableString;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableString.deserializeBinaryFromReader);
      msg.setDescription(value);
      break;
    case 13:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasBackhaul(value);
      break;
    case 14:
      var value = new proto_vorto_types_types_pb.NullableString;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableString.deserializeBinaryFromReader);
      msg.setInventoryId(value);
      break;
    case 15:
      var value = new proto.vtv1.CreateOrderRequestClonedInventory;
      reader.readMessage(value,proto.vtv1.CreateOrderRequestClonedInventory.deserializeBinaryFromReader);
      msg.setCloneInventory(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.CreateOrderRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.CreateOrderRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.CreateOrderRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.CreateOrderRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProductId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSupplierId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPurchaseOrderId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getQuantity();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getOriginSiteId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getDestinationSiteId();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getDeliveryWindowStartsAt();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto_vorto_types_types_pb.NullableTime.serializeBinaryToWriter
    );
  }
  f = message.getDeliveryWindowMinutes();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto_vorto_types_types_pb.NullableInt64.serializeBinaryToWriter
    );
  }
  f = message.getCarrierContractId();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto_vorto_types_types_pb.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getIsAsap();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
  f = message.getQuantityOfTruckloads();
  if (f !== 0) {
    writer.writeInt64(
      11,
      f
    );
  }
  f = message.getDescription();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      proto_vorto_types_types_pb.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getHasBackhaul();
  if (f) {
    writer.writeBool(
      13,
      f
    );
  }
  f = message.getInventoryId();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      proto_vorto_types_types_pb.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getCloneInventory();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      proto.vtv1.CreateOrderRequestClonedInventory.serializeBinaryToWriter
    );
  }
};


/**
 * optional string product_id = 1;
 * @return {string}
 */
proto.vtv1.CreateOrderRequest.prototype.getProductId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.CreateOrderRequest} returns this
 */
proto.vtv1.CreateOrderRequest.prototype.setProductId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string supplier_id = 2;
 * @return {string}
 */
proto.vtv1.CreateOrderRequest.prototype.getSupplierId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.CreateOrderRequest} returns this
 */
proto.vtv1.CreateOrderRequest.prototype.setSupplierId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string purchase_order_id = 3;
 * @return {string}
 */
proto.vtv1.CreateOrderRequest.prototype.getPurchaseOrderId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.CreateOrderRequest} returns this
 */
proto.vtv1.CreateOrderRequest.prototype.setPurchaseOrderId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional double quantity = 4;
 * @return {number}
 */
proto.vtv1.CreateOrderRequest.prototype.getQuantity = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.CreateOrderRequest} returns this
 */
proto.vtv1.CreateOrderRequest.prototype.setQuantity = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional string origin_site_id = 5;
 * @return {string}
 */
proto.vtv1.CreateOrderRequest.prototype.getOriginSiteId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.CreateOrderRequest} returns this
 */
proto.vtv1.CreateOrderRequest.prototype.setOriginSiteId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string destination_site_id = 6;
 * @return {string}
 */
proto.vtv1.CreateOrderRequest.prototype.getDestinationSiteId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.CreateOrderRequest} returns this
 */
proto.vtv1.CreateOrderRequest.prototype.setDestinationSiteId = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional NullableTime delivery_window_starts_at = 7;
 * @return {?proto.vtv1.NullableTime}
 */
proto.vtv1.CreateOrderRequest.prototype.getDeliveryWindowStartsAt = function() {
  return /** @type{?proto.vtv1.NullableTime} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableTime, 7));
};


/**
 * @param {?proto.vtv1.NullableTime|undefined} value
 * @return {!proto.vtv1.CreateOrderRequest} returns this
*/
proto.vtv1.CreateOrderRequest.prototype.setDeliveryWindowStartsAt = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.CreateOrderRequest} returns this
 */
proto.vtv1.CreateOrderRequest.prototype.clearDeliveryWindowStartsAt = function() {
  return this.setDeliveryWindowStartsAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.CreateOrderRequest.prototype.hasDeliveryWindowStartsAt = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional NullableInt64 delivery_window_minutes = 8;
 * @return {?proto.vtv1.NullableInt64}
 */
proto.vtv1.CreateOrderRequest.prototype.getDeliveryWindowMinutes = function() {
  return /** @type{?proto.vtv1.NullableInt64} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableInt64, 8));
};


/**
 * @param {?proto.vtv1.NullableInt64|undefined} value
 * @return {!proto.vtv1.CreateOrderRequest} returns this
*/
proto.vtv1.CreateOrderRequest.prototype.setDeliveryWindowMinutes = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.CreateOrderRequest} returns this
 */
proto.vtv1.CreateOrderRequest.prototype.clearDeliveryWindowMinutes = function() {
  return this.setDeliveryWindowMinutes(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.CreateOrderRequest.prototype.hasDeliveryWindowMinutes = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional NullableString carrier_contract_id = 9;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.CreateOrderRequest.prototype.getCarrierContractId = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableString, 9));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.CreateOrderRequest} returns this
*/
proto.vtv1.CreateOrderRequest.prototype.setCarrierContractId = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.CreateOrderRequest} returns this
 */
proto.vtv1.CreateOrderRequest.prototype.clearCarrierContractId = function() {
  return this.setCarrierContractId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.CreateOrderRequest.prototype.hasCarrierContractId = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional bool is_asap = 10;
 * @return {boolean}
 */
proto.vtv1.CreateOrderRequest.prototype.getIsAsap = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vtv1.CreateOrderRequest} returns this
 */
proto.vtv1.CreateOrderRequest.prototype.setIsAsap = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};


/**
 * optional int64 quantity_of_truckloads = 11;
 * @return {number}
 */
proto.vtv1.CreateOrderRequest.prototype.getQuantityOfTruckloads = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.CreateOrderRequest} returns this
 */
proto.vtv1.CreateOrderRequest.prototype.setQuantityOfTruckloads = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional NullableString description = 12;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.CreateOrderRequest.prototype.getDescription = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableString, 12));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.CreateOrderRequest} returns this
*/
proto.vtv1.CreateOrderRequest.prototype.setDescription = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.CreateOrderRequest} returns this
 */
proto.vtv1.CreateOrderRequest.prototype.clearDescription = function() {
  return this.setDescription(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.CreateOrderRequest.prototype.hasDescription = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional bool has_backhaul = 13;
 * @return {boolean}
 */
proto.vtv1.CreateOrderRequest.prototype.getHasBackhaul = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 13, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vtv1.CreateOrderRequest} returns this
 */
proto.vtv1.CreateOrderRequest.prototype.setHasBackhaul = function(value) {
  return jspb.Message.setProto3BooleanField(this, 13, value);
};


/**
 * optional NullableString inventory_id = 14;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.CreateOrderRequest.prototype.getInventoryId = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableString, 14));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.CreateOrderRequest} returns this
*/
proto.vtv1.CreateOrderRequest.prototype.setInventoryId = function(value) {
  return jspb.Message.setWrapperField(this, 14, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.CreateOrderRequest} returns this
 */
proto.vtv1.CreateOrderRequest.prototype.clearInventoryId = function() {
  return this.setInventoryId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.CreateOrderRequest.prototype.hasInventoryId = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional CreateOrderRequestClonedInventory clone_inventory = 15;
 * @return {?proto.vtv1.CreateOrderRequestClonedInventory}
 */
proto.vtv1.CreateOrderRequest.prototype.getCloneInventory = function() {
  return /** @type{?proto.vtv1.CreateOrderRequestClonedInventory} */ (
    jspb.Message.getWrapperField(this, proto.vtv1.CreateOrderRequestClonedInventory, 15));
};


/**
 * @param {?proto.vtv1.CreateOrderRequestClonedInventory|undefined} value
 * @return {!proto.vtv1.CreateOrderRequest} returns this
*/
proto.vtv1.CreateOrderRequest.prototype.setCloneInventory = function(value) {
  return jspb.Message.setWrapperField(this, 15, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.CreateOrderRequest} returns this
 */
proto.vtv1.CreateOrderRequest.prototype.clearCloneInventory = function() {
  return this.setCloneInventory(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.CreateOrderRequest.prototype.hasCloneInventory = function() {
  return jspb.Message.getField(this, 15) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.CreateOrderResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.CreateOrderResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.CreateOrderResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.CreateOrderResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.CreateOrderResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    orderIdsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.CreateOrderResponse}
 */
proto.vtv1.CreateOrderResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.CreateOrderResponse;
  return proto.vtv1.CreateOrderResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.CreateOrderResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.CreateOrderResponse}
 */
proto.vtv1.CreateOrderResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addOrderIds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.CreateOrderResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.CreateOrderResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.CreateOrderResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.CreateOrderResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrderIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
};


/**
 * repeated string order_ids = 1;
 * @return {!Array<string>}
 */
proto.vtv1.CreateOrderResponse.prototype.getOrderIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.vtv1.CreateOrderResponse} returns this
 */
proto.vtv1.CreateOrderResponse.prototype.setOrderIdsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.vtv1.CreateOrderResponse} returns this
 */
proto.vtv1.CreateOrderResponse.prototype.addOrderIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.CreateOrderResponse} returns this
 */
proto.vtv1.CreateOrderResponse.prototype.clearOrderIdsList = function() {
  return this.setOrderIdsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.GetPurchaseOrdersForCreateV2OrderRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.GetPurchaseOrdersForCreateV2OrderRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.GetPurchaseOrdersForCreateV2OrderRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.GetPurchaseOrdersForCreateV2OrderRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.GetPurchaseOrdersForCreateV2OrderRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    productIdsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.GetPurchaseOrdersForCreateV2OrderRequest}
 */
proto.vtv1.GetPurchaseOrdersForCreateV2OrderRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.GetPurchaseOrdersForCreateV2OrderRequest;
  return proto.vtv1.GetPurchaseOrdersForCreateV2OrderRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.GetPurchaseOrdersForCreateV2OrderRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.GetPurchaseOrdersForCreateV2OrderRequest}
 */
proto.vtv1.GetPurchaseOrdersForCreateV2OrderRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addProductIds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.GetPurchaseOrdersForCreateV2OrderRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.GetPurchaseOrdersForCreateV2OrderRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.GetPurchaseOrdersForCreateV2OrderRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.GetPurchaseOrdersForCreateV2OrderRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProductIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
};


/**
 * repeated string product_ids = 1;
 * @return {!Array<string>}
 */
proto.vtv1.GetPurchaseOrdersForCreateV2OrderRequest.prototype.getProductIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.vtv1.GetPurchaseOrdersForCreateV2OrderRequest} returns this
 */
proto.vtv1.GetPurchaseOrdersForCreateV2OrderRequest.prototype.setProductIdsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.vtv1.GetPurchaseOrdersForCreateV2OrderRequest} returns this
 */
proto.vtv1.GetPurchaseOrdersForCreateV2OrderRequest.prototype.addProductIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.GetPurchaseOrdersForCreateV2OrderRequest} returns this
 */
proto.vtv1.GetPurchaseOrdersForCreateV2OrderRequest.prototype.clearProductIdsList = function() {
  return this.setProductIdsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.CreateMultiStopOrderRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.CreateMultiStopOrderRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.CreateMultiStopOrderRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.CreateMultiStopOrderRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.CreateMultiStopOrderRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    stopsList: jspb.Message.toObjectList(msg.getStopsList(),
    proto.vtv1.CreateMultiStopOrderRequestStop.toObject, includeInstance),
    carrierContractId: (f = msg.getCarrierContractId()) && proto_vorto_types_types_pb.NullableString.toObject(includeInstance, f),
    quantityOfTruckloads: jspb.Message.getFieldWithDefault(msg, 3, 0),
    description: (f = msg.getDescription()) && proto_vorto_types_types_pb.NullableString.toObject(includeInstance, f),
    routeGuideSettingsId: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.CreateMultiStopOrderRequest}
 */
proto.vtv1.CreateMultiStopOrderRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.CreateMultiStopOrderRequest;
  return proto.vtv1.CreateMultiStopOrderRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.CreateMultiStopOrderRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.CreateMultiStopOrderRequest}
 */
proto.vtv1.CreateMultiStopOrderRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.vtv1.CreateMultiStopOrderRequestStop;
      reader.readMessage(value,proto.vtv1.CreateMultiStopOrderRequestStop.deserializeBinaryFromReader);
      msg.addStops(value);
      break;
    case 2:
      var value = new proto_vorto_types_types_pb.NullableString;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableString.deserializeBinaryFromReader);
      msg.setCarrierContractId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setQuantityOfTruckloads(value);
      break;
    case 4:
      var value = new proto_vorto_types_types_pb.NullableString;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableString.deserializeBinaryFromReader);
      msg.setDescription(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRouteGuideSettingsId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.CreateMultiStopOrderRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.CreateMultiStopOrderRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.CreateMultiStopOrderRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.CreateMultiStopOrderRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStopsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.vtv1.CreateMultiStopOrderRequestStop.serializeBinaryToWriter
    );
  }
  f = message.getCarrierContractId();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_vorto_types_types_pb.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getQuantityOfTruckloads();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getDescription();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto_vorto_types_types_pb.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getRouteGuideSettingsId();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
};


/**
 * repeated CreateMultiStopOrderRequestStop stops = 1;
 * @return {!Array<!proto.vtv1.CreateMultiStopOrderRequestStop>}
 */
proto.vtv1.CreateMultiStopOrderRequest.prototype.getStopsList = function() {
  return /** @type{!Array<!proto.vtv1.CreateMultiStopOrderRequestStop>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vtv1.CreateMultiStopOrderRequestStop, 1));
};


/**
 * @param {!Array<!proto.vtv1.CreateMultiStopOrderRequestStop>} value
 * @return {!proto.vtv1.CreateMultiStopOrderRequest} returns this
*/
proto.vtv1.CreateMultiStopOrderRequest.prototype.setStopsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.vtv1.CreateMultiStopOrderRequestStop=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.CreateMultiStopOrderRequestStop}
 */
proto.vtv1.CreateMultiStopOrderRequest.prototype.addStops = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.vtv1.CreateMultiStopOrderRequestStop, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.CreateMultiStopOrderRequest} returns this
 */
proto.vtv1.CreateMultiStopOrderRequest.prototype.clearStopsList = function() {
  return this.setStopsList([]);
};


/**
 * optional NullableString carrier_contract_id = 2;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.CreateMultiStopOrderRequest.prototype.getCarrierContractId = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableString, 2));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.CreateMultiStopOrderRequest} returns this
*/
proto.vtv1.CreateMultiStopOrderRequest.prototype.setCarrierContractId = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.CreateMultiStopOrderRequest} returns this
 */
proto.vtv1.CreateMultiStopOrderRequest.prototype.clearCarrierContractId = function() {
  return this.setCarrierContractId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.CreateMultiStopOrderRequest.prototype.hasCarrierContractId = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional int64 quantity_of_truckloads = 3;
 * @return {number}
 */
proto.vtv1.CreateMultiStopOrderRequest.prototype.getQuantityOfTruckloads = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.CreateMultiStopOrderRequest} returns this
 */
proto.vtv1.CreateMultiStopOrderRequest.prototype.setQuantityOfTruckloads = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional NullableString description = 4;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.CreateMultiStopOrderRequest.prototype.getDescription = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableString, 4));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.CreateMultiStopOrderRequest} returns this
*/
proto.vtv1.CreateMultiStopOrderRequest.prototype.setDescription = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.CreateMultiStopOrderRequest} returns this
 */
proto.vtv1.CreateMultiStopOrderRequest.prototype.clearDescription = function() {
  return this.setDescription(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.CreateMultiStopOrderRequest.prototype.hasDescription = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional int64 route_guide_settings_id = 5;
 * @return {number}
 */
proto.vtv1.CreateMultiStopOrderRequest.prototype.getRouteGuideSettingsId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.CreateMultiStopOrderRequest} returns this
 */
proto.vtv1.CreateMultiStopOrderRequest.prototype.setRouteGuideSettingsId = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.CreateMultiStopOrderRequestStop.repeatedFields_ = [7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.CreateMultiStopOrderRequestStop.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.CreateMultiStopOrderRequestStop.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.CreateMultiStopOrderRequestStop} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.CreateMultiStopOrderRequestStop.toObject = function(includeInstance, msg) {
  var f, obj = {
    sequence: jspb.Message.getFieldWithDefault(msg, 1, 0),
    siteId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    arrivalWindowStartsAt: (f = msg.getArrivalWindowStartsAt()) && proto_vorto_types_types_pb.NullableTime.toObject(includeInstance, f),
    arrivalWindowMinutes: (f = msg.getArrivalWindowMinutes()) && proto_vorto_types_types_pb.NullableInt64.toObject(includeInstance, f),
    notes: (f = msg.getNotes()) && proto_vorto_types_types_pb.NullableString.toObject(includeInstance, f),
    pickupNumber: (f = msg.getPickupNumber()) && proto_vorto_types_types_pb.NullableString.toObject(includeInstance, f),
    tasksList: jspb.Message.toObjectList(msg.getTasksList(),
    proto.vtv1.CreateMultiStopOrderRequestStopTask.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.CreateMultiStopOrderRequestStop}
 */
proto.vtv1.CreateMultiStopOrderRequestStop.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.CreateMultiStopOrderRequestStop;
  return proto.vtv1.CreateMultiStopOrderRequestStop.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.CreateMultiStopOrderRequestStop} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.CreateMultiStopOrderRequestStop}
 */
proto.vtv1.CreateMultiStopOrderRequestStop.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSequence(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSiteId(value);
      break;
    case 3:
      var value = new proto_vorto_types_types_pb.NullableTime;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableTime.deserializeBinaryFromReader);
      msg.setArrivalWindowStartsAt(value);
      break;
    case 4:
      var value = new proto_vorto_types_types_pb.NullableInt64;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableInt64.deserializeBinaryFromReader);
      msg.setArrivalWindowMinutes(value);
      break;
    case 5:
      var value = new proto_vorto_types_types_pb.NullableString;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableString.deserializeBinaryFromReader);
      msg.setNotes(value);
      break;
    case 6:
      var value = new proto_vorto_types_types_pb.NullableString;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableString.deserializeBinaryFromReader);
      msg.setPickupNumber(value);
      break;
    case 7:
      var value = new proto.vtv1.CreateMultiStopOrderRequestStopTask;
      reader.readMessage(value,proto.vtv1.CreateMultiStopOrderRequestStopTask.deserializeBinaryFromReader);
      msg.addTasks(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.CreateMultiStopOrderRequestStop.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.CreateMultiStopOrderRequestStop.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.CreateMultiStopOrderRequestStop} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.CreateMultiStopOrderRequestStop.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSequence();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getSiteId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getArrivalWindowStartsAt();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto_vorto_types_types_pb.NullableTime.serializeBinaryToWriter
    );
  }
  f = message.getArrivalWindowMinutes();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto_vorto_types_types_pb.NullableInt64.serializeBinaryToWriter
    );
  }
  f = message.getNotes();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto_vorto_types_types_pb.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getPickupNumber();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto_vorto_types_types_pb.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getTasksList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto.vtv1.CreateMultiStopOrderRequestStopTask.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 sequence = 1;
 * @return {number}
 */
proto.vtv1.CreateMultiStopOrderRequestStop.prototype.getSequence = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.CreateMultiStopOrderRequestStop} returns this
 */
proto.vtv1.CreateMultiStopOrderRequestStop.prototype.setSequence = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string site_id = 2;
 * @return {string}
 */
proto.vtv1.CreateMultiStopOrderRequestStop.prototype.getSiteId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.CreateMultiStopOrderRequestStop} returns this
 */
proto.vtv1.CreateMultiStopOrderRequestStop.prototype.setSiteId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional NullableTime arrival_window_starts_at = 3;
 * @return {?proto.vtv1.NullableTime}
 */
proto.vtv1.CreateMultiStopOrderRequestStop.prototype.getArrivalWindowStartsAt = function() {
  return /** @type{?proto.vtv1.NullableTime} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableTime, 3));
};


/**
 * @param {?proto.vtv1.NullableTime|undefined} value
 * @return {!proto.vtv1.CreateMultiStopOrderRequestStop} returns this
*/
proto.vtv1.CreateMultiStopOrderRequestStop.prototype.setArrivalWindowStartsAt = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.CreateMultiStopOrderRequestStop} returns this
 */
proto.vtv1.CreateMultiStopOrderRequestStop.prototype.clearArrivalWindowStartsAt = function() {
  return this.setArrivalWindowStartsAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.CreateMultiStopOrderRequestStop.prototype.hasArrivalWindowStartsAt = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional NullableInt64 arrival_window_minutes = 4;
 * @return {?proto.vtv1.NullableInt64}
 */
proto.vtv1.CreateMultiStopOrderRequestStop.prototype.getArrivalWindowMinutes = function() {
  return /** @type{?proto.vtv1.NullableInt64} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableInt64, 4));
};


/**
 * @param {?proto.vtv1.NullableInt64|undefined} value
 * @return {!proto.vtv1.CreateMultiStopOrderRequestStop} returns this
*/
proto.vtv1.CreateMultiStopOrderRequestStop.prototype.setArrivalWindowMinutes = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.CreateMultiStopOrderRequestStop} returns this
 */
proto.vtv1.CreateMultiStopOrderRequestStop.prototype.clearArrivalWindowMinutes = function() {
  return this.setArrivalWindowMinutes(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.CreateMultiStopOrderRequestStop.prototype.hasArrivalWindowMinutes = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional NullableString notes = 5;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.CreateMultiStopOrderRequestStop.prototype.getNotes = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableString, 5));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.CreateMultiStopOrderRequestStop} returns this
*/
proto.vtv1.CreateMultiStopOrderRequestStop.prototype.setNotes = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.CreateMultiStopOrderRequestStop} returns this
 */
proto.vtv1.CreateMultiStopOrderRequestStop.prototype.clearNotes = function() {
  return this.setNotes(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.CreateMultiStopOrderRequestStop.prototype.hasNotes = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional NullableString pickup_number = 6;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.CreateMultiStopOrderRequestStop.prototype.getPickupNumber = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableString, 6));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.CreateMultiStopOrderRequestStop} returns this
*/
proto.vtv1.CreateMultiStopOrderRequestStop.prototype.setPickupNumber = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.CreateMultiStopOrderRequestStop} returns this
 */
proto.vtv1.CreateMultiStopOrderRequestStop.prototype.clearPickupNumber = function() {
  return this.setPickupNumber(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.CreateMultiStopOrderRequestStop.prototype.hasPickupNumber = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * repeated CreateMultiStopOrderRequestStopTask tasks = 7;
 * @return {!Array<!proto.vtv1.CreateMultiStopOrderRequestStopTask>}
 */
proto.vtv1.CreateMultiStopOrderRequestStop.prototype.getTasksList = function() {
  return /** @type{!Array<!proto.vtv1.CreateMultiStopOrderRequestStopTask>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vtv1.CreateMultiStopOrderRequestStopTask, 7));
};


/**
 * @param {!Array<!proto.vtv1.CreateMultiStopOrderRequestStopTask>} value
 * @return {!proto.vtv1.CreateMultiStopOrderRequestStop} returns this
*/
proto.vtv1.CreateMultiStopOrderRequestStop.prototype.setTasksList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.vtv1.CreateMultiStopOrderRequestStopTask=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.CreateMultiStopOrderRequestStopTask}
 */
proto.vtv1.CreateMultiStopOrderRequestStop.prototype.addTasks = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.vtv1.CreateMultiStopOrderRequestStopTask, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.CreateMultiStopOrderRequestStop} returns this
 */
proto.vtv1.CreateMultiStopOrderRequestStop.prototype.clearTasksList = function() {
  return this.setTasksList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.CreateMultiStopOrderRequestStopTask.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.CreateMultiStopOrderRequestStopTask.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.CreateMultiStopOrderRequestStopTask} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.CreateMultiStopOrderRequestStopTask.toObject = function(includeInstance, msg) {
  var f, obj = {
    action: jspb.Message.getFieldWithDefault(msg, 1, 0),
    productId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    quantity: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    purchaseOrder: (f = msg.getPurchaseOrder()) && proto_vorto_types_types_pb.NullableString.toObject(includeInstance, f),
    inventoryId: (f = msg.getInventoryId()) && proto_vorto_types_types_pb.NullableString.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.CreateMultiStopOrderRequestStopTask}
 */
proto.vtv1.CreateMultiStopOrderRequestStopTask.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.CreateMultiStopOrderRequestStopTask;
  return proto.vtv1.CreateMultiStopOrderRequestStopTask.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.CreateMultiStopOrderRequestStopTask} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.CreateMultiStopOrderRequestStopTask}
 */
proto.vtv1.CreateMultiStopOrderRequestStopTask.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.vtv1.TaskAction} */ (reader.readEnum());
      msg.setAction(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setQuantity(value);
      break;
    case 4:
      var value = new proto_vorto_types_types_pb.NullableString;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableString.deserializeBinaryFromReader);
      msg.setPurchaseOrder(value);
      break;
    case 5:
      var value = new proto_vorto_types_types_pb.NullableString;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableString.deserializeBinaryFromReader);
      msg.setInventoryId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.CreateMultiStopOrderRequestStopTask.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.CreateMultiStopOrderRequestStopTask.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.CreateMultiStopOrderRequestStopTask} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.CreateMultiStopOrderRequestStopTask.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAction();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getProductId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getQuantity();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getPurchaseOrder();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto_vorto_types_types_pb.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getInventoryId();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto_vorto_types_types_pb.NullableString.serializeBinaryToWriter
    );
  }
};


/**
 * optional TaskAction action = 1;
 * @return {!proto.vtv1.TaskAction}
 */
proto.vtv1.CreateMultiStopOrderRequestStopTask.prototype.getAction = function() {
  return /** @type {!proto.vtv1.TaskAction} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.vtv1.TaskAction} value
 * @return {!proto.vtv1.CreateMultiStopOrderRequestStopTask} returns this
 */
proto.vtv1.CreateMultiStopOrderRequestStopTask.prototype.setAction = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string product_id = 2;
 * @return {string}
 */
proto.vtv1.CreateMultiStopOrderRequestStopTask.prototype.getProductId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.CreateMultiStopOrderRequestStopTask} returns this
 */
proto.vtv1.CreateMultiStopOrderRequestStopTask.prototype.setProductId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional double quantity = 3;
 * @return {number}
 */
proto.vtv1.CreateMultiStopOrderRequestStopTask.prototype.getQuantity = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.CreateMultiStopOrderRequestStopTask} returns this
 */
proto.vtv1.CreateMultiStopOrderRequestStopTask.prototype.setQuantity = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional NullableString purchase_order = 4;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.CreateMultiStopOrderRequestStopTask.prototype.getPurchaseOrder = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableString, 4));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.CreateMultiStopOrderRequestStopTask} returns this
*/
proto.vtv1.CreateMultiStopOrderRequestStopTask.prototype.setPurchaseOrder = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.CreateMultiStopOrderRequestStopTask} returns this
 */
proto.vtv1.CreateMultiStopOrderRequestStopTask.prototype.clearPurchaseOrder = function() {
  return this.setPurchaseOrder(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.CreateMultiStopOrderRequestStopTask.prototype.hasPurchaseOrder = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional NullableString inventory_id = 5;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.CreateMultiStopOrderRequestStopTask.prototype.getInventoryId = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableString, 5));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.CreateMultiStopOrderRequestStopTask} returns this
*/
proto.vtv1.CreateMultiStopOrderRequestStopTask.prototype.setInventoryId = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.CreateMultiStopOrderRequestStopTask} returns this
 */
proto.vtv1.CreateMultiStopOrderRequestStopTask.prototype.clearInventoryId = function() {
  return this.setInventoryId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.CreateMultiStopOrderRequestStopTask.prototype.hasInventoryId = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.CancelOrderRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.CancelOrderRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.CancelOrderRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.CancelOrderRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    orderId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.CancelOrderRequest}
 */
proto.vtv1.CancelOrderRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.CancelOrderRequest;
  return proto.vtv1.CancelOrderRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.CancelOrderRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.CancelOrderRequest}
 */
proto.vtv1.CancelOrderRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrderId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.CancelOrderRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.CancelOrderRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.CancelOrderRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.CancelOrderRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrderId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string order_id = 1;
 * @return {string}
 */
proto.vtv1.CancelOrderRequest.prototype.getOrderId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.CancelOrderRequest} returns this
 */
proto.vtv1.CancelOrderRequest.prototype.setOrderId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.CancelMultipleOrdersRequestNode.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.CancelMultipleOrdersRequestNode.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.CancelMultipleOrdersRequestNode.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.CancelMultipleOrdersRequestNode} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.CancelMultipleOrdersRequestNode.toObject = function(includeInstance, msg) {
  var f, obj = {
    orderIdsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.CancelMultipleOrdersRequestNode}
 */
proto.vtv1.CancelMultipleOrdersRequestNode.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.CancelMultipleOrdersRequestNode;
  return proto.vtv1.CancelMultipleOrdersRequestNode.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.CancelMultipleOrdersRequestNode} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.CancelMultipleOrdersRequestNode}
 */
proto.vtv1.CancelMultipleOrdersRequestNode.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addOrderIds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.CancelMultipleOrdersRequestNode.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.CancelMultipleOrdersRequestNode.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.CancelMultipleOrdersRequestNode} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.CancelMultipleOrdersRequestNode.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrderIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
};


/**
 * repeated string order_ids = 1;
 * @return {!Array<string>}
 */
proto.vtv1.CancelMultipleOrdersRequestNode.prototype.getOrderIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.vtv1.CancelMultipleOrdersRequestNode} returns this
 */
proto.vtv1.CancelMultipleOrdersRequestNode.prototype.setOrderIdsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.vtv1.CancelMultipleOrdersRequestNode} returns this
 */
proto.vtv1.CancelMultipleOrdersRequestNode.prototype.addOrderIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.CancelMultipleOrdersRequestNode} returns this
 */
proto.vtv1.CancelMultipleOrdersRequestNode.prototype.clearOrderIdsList = function() {
  return this.setOrderIdsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.ListSupplierSitesForCreateOrderRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.ListSupplierSitesForCreateOrderRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.ListSupplierSitesForCreateOrderRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.ListSupplierSitesForCreateOrderRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    productId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    originId: (f = msg.getOriginId()) && proto_vorto_types_types_pb.NullableString.toObject(includeInstance, f),
    windowStart: jspb.Message.getFieldWithDefault(msg, 3, ""),
    windowMinutes: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.ListSupplierSitesForCreateOrderRequest}
 */
proto.vtv1.ListSupplierSitesForCreateOrderRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.ListSupplierSitesForCreateOrderRequest;
  return proto.vtv1.ListSupplierSitesForCreateOrderRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.ListSupplierSitesForCreateOrderRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.ListSupplierSitesForCreateOrderRequest}
 */
proto.vtv1.ListSupplierSitesForCreateOrderRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductId(value);
      break;
    case 2:
      var value = new proto_vorto_types_types_pb.NullableString;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableString.deserializeBinaryFromReader);
      msg.setOriginId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setWindowStart(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setWindowMinutes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.ListSupplierSitesForCreateOrderRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.ListSupplierSitesForCreateOrderRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.ListSupplierSitesForCreateOrderRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.ListSupplierSitesForCreateOrderRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProductId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOriginId();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_vorto_types_types_pb.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getWindowStart();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getWindowMinutes();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
};


/**
 * optional string product_id = 1;
 * @return {string}
 */
proto.vtv1.ListSupplierSitesForCreateOrderRequest.prototype.getProductId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.ListSupplierSitesForCreateOrderRequest} returns this
 */
proto.vtv1.ListSupplierSitesForCreateOrderRequest.prototype.setProductId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional NullableString origin_id = 2;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.ListSupplierSitesForCreateOrderRequest.prototype.getOriginId = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableString, 2));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.ListSupplierSitesForCreateOrderRequest} returns this
*/
proto.vtv1.ListSupplierSitesForCreateOrderRequest.prototype.setOriginId = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.ListSupplierSitesForCreateOrderRequest} returns this
 */
proto.vtv1.ListSupplierSitesForCreateOrderRequest.prototype.clearOriginId = function() {
  return this.setOriginId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.ListSupplierSitesForCreateOrderRequest.prototype.hasOriginId = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string window_start = 3;
 * @return {string}
 */
proto.vtv1.ListSupplierSitesForCreateOrderRequest.prototype.getWindowStart = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.ListSupplierSitesForCreateOrderRequest} returns this
 */
proto.vtv1.ListSupplierSitesForCreateOrderRequest.prototype.setWindowStart = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int64 window_minutes = 4;
 * @return {number}
 */
proto.vtv1.ListSupplierSitesForCreateOrderRequest.prototype.getWindowMinutes = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.ListSupplierSitesForCreateOrderRequest} returns this
 */
proto.vtv1.ListSupplierSitesForCreateOrderRequest.prototype.setWindowMinutes = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.ListSupplierSitesForCreateOrderResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.ListSupplierSitesForCreateOrderResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.ListSupplierSitesForCreateOrderResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.ListSupplierSitesForCreateOrderResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.ListSupplierSitesForCreateOrderResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    sitesList: jspb.Message.toObjectList(msg.getSitesList(),
    proto_vorto_node_node_pb.CreateOrderSite.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.ListSupplierSitesForCreateOrderResponse}
 */
proto.vtv1.ListSupplierSitesForCreateOrderResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.ListSupplierSitesForCreateOrderResponse;
  return proto.vtv1.ListSupplierSitesForCreateOrderResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.ListSupplierSitesForCreateOrderResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.ListSupplierSitesForCreateOrderResponse}
 */
proto.vtv1.ListSupplierSitesForCreateOrderResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_vorto_node_node_pb.CreateOrderSite;
      reader.readMessage(value,proto_vorto_node_node_pb.CreateOrderSite.deserializeBinaryFromReader);
      msg.addSites(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.ListSupplierSitesForCreateOrderResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.ListSupplierSitesForCreateOrderResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.ListSupplierSitesForCreateOrderResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.ListSupplierSitesForCreateOrderResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSitesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto_vorto_node_node_pb.CreateOrderSite.serializeBinaryToWriter
    );
  }
};


/**
 * repeated CreateOrderSite sites = 1;
 * @return {!Array<!proto.vtv1.CreateOrderSite>}
 */
proto.vtv1.ListSupplierSitesForCreateOrderResponse.prototype.getSitesList = function() {
  return /** @type{!Array<!proto.vtv1.CreateOrderSite>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_vorto_node_node_pb.CreateOrderSite, 1));
};


/**
 * @param {!Array<!proto.vtv1.CreateOrderSite>} value
 * @return {!proto.vtv1.ListSupplierSitesForCreateOrderResponse} returns this
*/
proto.vtv1.ListSupplierSitesForCreateOrderResponse.prototype.setSitesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.vtv1.CreateOrderSite=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.CreateOrderSite}
 */
proto.vtv1.ListSupplierSitesForCreateOrderResponse.prototype.addSites = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.vtv1.CreateOrderSite, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.ListSupplierSitesForCreateOrderResponse} returns this
 */
proto.vtv1.ListSupplierSitesForCreateOrderResponse.prototype.clearSitesList = function() {
  return this.setSitesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.ListPurchaseOrdersForCreateOrderRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.ListPurchaseOrdersForCreateOrderRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.ListPurchaseOrdersForCreateOrderRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.ListPurchaseOrdersForCreateOrderRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    productId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    supplierId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    windowStart: jspb.Message.getFieldWithDefault(msg, 3, ""),
    windowMinutes: jspb.Message.getFieldWithDefault(msg, 4, 0),
    destinationSiteId: jspb.Message.getFieldWithDefault(msg, 5, ""),
    supplierSiteId: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.ListPurchaseOrdersForCreateOrderRequest}
 */
proto.vtv1.ListPurchaseOrdersForCreateOrderRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.ListPurchaseOrdersForCreateOrderRequest;
  return proto.vtv1.ListPurchaseOrdersForCreateOrderRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.ListPurchaseOrdersForCreateOrderRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.ListPurchaseOrdersForCreateOrderRequest}
 */
proto.vtv1.ListPurchaseOrdersForCreateOrderRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSupplierId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setWindowStart(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setWindowMinutes(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setDestinationSiteId(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setSupplierSiteId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.ListPurchaseOrdersForCreateOrderRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.ListPurchaseOrdersForCreateOrderRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.ListPurchaseOrdersForCreateOrderRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.ListPurchaseOrdersForCreateOrderRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProductId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSupplierId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getWindowStart();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getWindowMinutes();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getDestinationSiteId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getSupplierSiteId();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional string product_id = 1;
 * @return {string}
 */
proto.vtv1.ListPurchaseOrdersForCreateOrderRequest.prototype.getProductId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.ListPurchaseOrdersForCreateOrderRequest} returns this
 */
proto.vtv1.ListPurchaseOrdersForCreateOrderRequest.prototype.setProductId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string supplier_id = 2;
 * @return {string}
 */
proto.vtv1.ListPurchaseOrdersForCreateOrderRequest.prototype.getSupplierId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.ListPurchaseOrdersForCreateOrderRequest} returns this
 */
proto.vtv1.ListPurchaseOrdersForCreateOrderRequest.prototype.setSupplierId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string window_start = 3;
 * @return {string}
 */
proto.vtv1.ListPurchaseOrdersForCreateOrderRequest.prototype.getWindowStart = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.ListPurchaseOrdersForCreateOrderRequest} returns this
 */
proto.vtv1.ListPurchaseOrdersForCreateOrderRequest.prototype.setWindowStart = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int64 window_minutes = 4;
 * @return {number}
 */
proto.vtv1.ListPurchaseOrdersForCreateOrderRequest.prototype.getWindowMinutes = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.ListPurchaseOrdersForCreateOrderRequest} returns this
 */
proto.vtv1.ListPurchaseOrdersForCreateOrderRequest.prototype.setWindowMinutes = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string destination_site_id = 5;
 * @return {string}
 */
proto.vtv1.ListPurchaseOrdersForCreateOrderRequest.prototype.getDestinationSiteId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.ListPurchaseOrdersForCreateOrderRequest} returns this
 */
proto.vtv1.ListPurchaseOrdersForCreateOrderRequest.prototype.setDestinationSiteId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string supplier_site_id = 6;
 * @return {string}
 */
proto.vtv1.ListPurchaseOrdersForCreateOrderRequest.prototype.getSupplierSiteId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.ListPurchaseOrdersForCreateOrderRequest} returns this
 */
proto.vtv1.ListPurchaseOrdersForCreateOrderRequest.prototype.setSupplierSiteId = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.ListEdgeContractsForCreateOrdersRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.ListEdgeContractsForCreateOrdersRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.ListEdgeContractsForCreateOrdersRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.ListEdgeContractsForCreateOrdersRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    productId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    windowStart: jspb.Message.getFieldWithDefault(msg, 2, ""),
    windowMinutes: jspb.Message.getFieldWithDefault(msg, 3, 0),
    destinationSiteId: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.ListEdgeContractsForCreateOrdersRequest}
 */
proto.vtv1.ListEdgeContractsForCreateOrdersRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.ListEdgeContractsForCreateOrdersRequest;
  return proto.vtv1.ListEdgeContractsForCreateOrdersRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.ListEdgeContractsForCreateOrdersRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.ListEdgeContractsForCreateOrdersRequest}
 */
proto.vtv1.ListEdgeContractsForCreateOrdersRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setWindowStart(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setWindowMinutes(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDestinationSiteId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.ListEdgeContractsForCreateOrdersRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.ListEdgeContractsForCreateOrdersRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.ListEdgeContractsForCreateOrdersRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.ListEdgeContractsForCreateOrdersRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProductId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getWindowStart();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getWindowMinutes();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getDestinationSiteId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string product_id = 1;
 * @return {string}
 */
proto.vtv1.ListEdgeContractsForCreateOrdersRequest.prototype.getProductId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.ListEdgeContractsForCreateOrdersRequest} returns this
 */
proto.vtv1.ListEdgeContractsForCreateOrdersRequest.prototype.setProductId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string window_start = 2;
 * @return {string}
 */
proto.vtv1.ListEdgeContractsForCreateOrdersRequest.prototype.getWindowStart = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.ListEdgeContractsForCreateOrdersRequest} returns this
 */
proto.vtv1.ListEdgeContractsForCreateOrdersRequest.prototype.setWindowStart = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 window_minutes = 3;
 * @return {number}
 */
proto.vtv1.ListEdgeContractsForCreateOrdersRequest.prototype.getWindowMinutes = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.ListEdgeContractsForCreateOrdersRequest} returns this
 */
proto.vtv1.ListEdgeContractsForCreateOrdersRequest.prototype.setWindowMinutes = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string destination_site_id = 4;
 * @return {string}
 */
proto.vtv1.ListEdgeContractsForCreateOrdersRequest.prototype.getDestinationSiteId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.ListEdgeContractsForCreateOrdersRequest} returns this
 */
proto.vtv1.ListEdgeContractsForCreateOrdersRequest.prototype.setDestinationSiteId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.CreateOrderPurchaseOrder.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.CreateOrderPurchaseOrder.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.CreateOrderPurchaseOrder} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.CreateOrderPurchaseOrder.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    siteId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    siteName: jspb.Message.getFieldWithDefault(msg, 4, ""),
    pausedBy: (f = msg.getPausedBy()) && proto_vorto_types_types_pb.NullableString.toObject(includeInstance, f),
    supplierPaysShipping: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    startsAt: jspb.Message.getFieldWithDefault(msg, 7, ""),
    expiresAt: (f = msg.getExpiresAt()) && proto_vorto_types_types_pb.NullableString.toObject(includeInstance, f),
    unitCost: jspb.Message.getFieldWithDefault(msg, 9, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.CreateOrderPurchaseOrder}
 */
proto.vtv1.CreateOrderPurchaseOrder.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.CreateOrderPurchaseOrder;
  return proto.vtv1.CreateOrderPurchaseOrder.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.CreateOrderPurchaseOrder} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.CreateOrderPurchaseOrder}
 */
proto.vtv1.CreateOrderPurchaseOrder.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSiteId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setSiteName(value);
      break;
    case 5:
      var value = new proto_vorto_types_types_pb.NullableString;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableString.deserializeBinaryFromReader);
      msg.setPausedBy(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSupplierPaysShipping(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setStartsAt(value);
      break;
    case 8:
      var value = new proto_vorto_types_types_pb.NullableString;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableString.deserializeBinaryFromReader);
      msg.setExpiresAt(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUnitCost(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.CreateOrderPurchaseOrder.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.CreateOrderPurchaseOrder.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.CreateOrderPurchaseOrder} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.CreateOrderPurchaseOrder.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSiteId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSiteName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getPausedBy();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto_vorto_types_types_pb.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getSupplierPaysShipping();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getStartsAt();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getExpiresAt();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto_vorto_types_types_pb.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getUnitCost();
  if (f !== 0) {
    writer.writeInt64(
      9,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.vtv1.CreateOrderPurchaseOrder.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.CreateOrderPurchaseOrder} returns this
 */
proto.vtv1.CreateOrderPurchaseOrder.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.vtv1.CreateOrderPurchaseOrder.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.CreateOrderPurchaseOrder} returns this
 */
proto.vtv1.CreateOrderPurchaseOrder.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string site_id = 3;
 * @return {string}
 */
proto.vtv1.CreateOrderPurchaseOrder.prototype.getSiteId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.CreateOrderPurchaseOrder} returns this
 */
proto.vtv1.CreateOrderPurchaseOrder.prototype.setSiteId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string site_name = 4;
 * @return {string}
 */
proto.vtv1.CreateOrderPurchaseOrder.prototype.getSiteName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.CreateOrderPurchaseOrder} returns this
 */
proto.vtv1.CreateOrderPurchaseOrder.prototype.setSiteName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional NullableString paused_by = 5;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.CreateOrderPurchaseOrder.prototype.getPausedBy = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableString, 5));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.CreateOrderPurchaseOrder} returns this
*/
proto.vtv1.CreateOrderPurchaseOrder.prototype.setPausedBy = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.CreateOrderPurchaseOrder} returns this
 */
proto.vtv1.CreateOrderPurchaseOrder.prototype.clearPausedBy = function() {
  return this.setPausedBy(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.CreateOrderPurchaseOrder.prototype.hasPausedBy = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional bool supplier_pays_shipping = 6;
 * @return {boolean}
 */
proto.vtv1.CreateOrderPurchaseOrder.prototype.getSupplierPaysShipping = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vtv1.CreateOrderPurchaseOrder} returns this
 */
proto.vtv1.CreateOrderPurchaseOrder.prototype.setSupplierPaysShipping = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional string starts_at = 7;
 * @return {string}
 */
proto.vtv1.CreateOrderPurchaseOrder.prototype.getStartsAt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.CreateOrderPurchaseOrder} returns this
 */
proto.vtv1.CreateOrderPurchaseOrder.prototype.setStartsAt = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional NullableString expires_at = 8;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.CreateOrderPurchaseOrder.prototype.getExpiresAt = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableString, 8));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.CreateOrderPurchaseOrder} returns this
*/
proto.vtv1.CreateOrderPurchaseOrder.prototype.setExpiresAt = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.CreateOrderPurchaseOrder} returns this
 */
proto.vtv1.CreateOrderPurchaseOrder.prototype.clearExpiresAt = function() {
  return this.setExpiresAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.CreateOrderPurchaseOrder.prototype.hasExpiresAt = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional int64 unit_cost = 9;
 * @return {number}
 */
proto.vtv1.CreateOrderPurchaseOrder.prototype.getUnitCost = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.CreateOrderPurchaseOrder} returns this
 */
proto.vtv1.CreateOrderPurchaseOrder.prototype.setUnitCost = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.ListPurchaseOrdersForCreateOrderResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.ListPurchaseOrdersForCreateOrderResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.ListPurchaseOrdersForCreateOrderResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.ListPurchaseOrdersForCreateOrderResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.ListPurchaseOrdersForCreateOrderResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    purchaseOrdersList: jspb.Message.toObjectList(msg.getPurchaseOrdersList(),
    proto.vtv1.CreateOrderPurchaseOrder.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.ListPurchaseOrdersForCreateOrderResponse}
 */
proto.vtv1.ListPurchaseOrdersForCreateOrderResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.ListPurchaseOrdersForCreateOrderResponse;
  return proto.vtv1.ListPurchaseOrdersForCreateOrderResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.ListPurchaseOrdersForCreateOrderResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.ListPurchaseOrdersForCreateOrderResponse}
 */
proto.vtv1.ListPurchaseOrdersForCreateOrderResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.vtv1.CreateOrderPurchaseOrder;
      reader.readMessage(value,proto.vtv1.CreateOrderPurchaseOrder.deserializeBinaryFromReader);
      msg.addPurchaseOrders(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.ListPurchaseOrdersForCreateOrderResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.ListPurchaseOrdersForCreateOrderResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.ListPurchaseOrdersForCreateOrderResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.ListPurchaseOrdersForCreateOrderResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPurchaseOrdersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.vtv1.CreateOrderPurchaseOrder.serializeBinaryToWriter
    );
  }
};


/**
 * repeated CreateOrderPurchaseOrder purchase_orders = 1;
 * @return {!Array<!proto.vtv1.CreateOrderPurchaseOrder>}
 */
proto.vtv1.ListPurchaseOrdersForCreateOrderResponse.prototype.getPurchaseOrdersList = function() {
  return /** @type{!Array<!proto.vtv1.CreateOrderPurchaseOrder>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vtv1.CreateOrderPurchaseOrder, 1));
};


/**
 * @param {!Array<!proto.vtv1.CreateOrderPurchaseOrder>} value
 * @return {!proto.vtv1.ListPurchaseOrdersForCreateOrderResponse} returns this
*/
proto.vtv1.ListPurchaseOrdersForCreateOrderResponse.prototype.setPurchaseOrdersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.vtv1.CreateOrderPurchaseOrder=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.CreateOrderPurchaseOrder}
 */
proto.vtv1.ListPurchaseOrdersForCreateOrderResponse.prototype.addPurchaseOrders = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.vtv1.CreateOrderPurchaseOrder, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.ListPurchaseOrdersForCreateOrderResponse} returns this
 */
proto.vtv1.ListPurchaseOrdersForCreateOrderResponse.prototype.clearPurchaseOrdersList = function() {
  return this.setPurchaseOrdersList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.CreateOrderEdgeContract.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.CreateOrderEdgeContract.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.CreateOrderEdgeContract} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.CreateOrderEdgeContract.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    carrierId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    carrierName: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.CreateOrderEdgeContract}
 */
proto.vtv1.CreateOrderEdgeContract.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.CreateOrderEdgeContract;
  return proto.vtv1.CreateOrderEdgeContract.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.CreateOrderEdgeContract} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.CreateOrderEdgeContract}
 */
proto.vtv1.CreateOrderEdgeContract.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCarrierId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCarrierName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.CreateOrderEdgeContract.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.CreateOrderEdgeContract.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.CreateOrderEdgeContract} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.CreateOrderEdgeContract.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCarrierId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCarrierName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.vtv1.CreateOrderEdgeContract.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.CreateOrderEdgeContract} returns this
 */
proto.vtv1.CreateOrderEdgeContract.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.vtv1.CreateOrderEdgeContract.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.CreateOrderEdgeContract} returns this
 */
proto.vtv1.CreateOrderEdgeContract.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string carrier_id = 3;
 * @return {string}
 */
proto.vtv1.CreateOrderEdgeContract.prototype.getCarrierId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.CreateOrderEdgeContract} returns this
 */
proto.vtv1.CreateOrderEdgeContract.prototype.setCarrierId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string carrier_name = 4;
 * @return {string}
 */
proto.vtv1.CreateOrderEdgeContract.prototype.getCarrierName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.CreateOrderEdgeContract} returns this
 */
proto.vtv1.CreateOrderEdgeContract.prototype.setCarrierName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.SlimSupplierContract.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.SlimSupplierContract.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.SlimSupplierContract} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.SlimSupplierContract.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    virtual: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    startTime: (f = msg.getStartTime()) && proto_vorto_types_types_pb.NullableTime.toObject(includeInstance, f),
    endTime: (f = msg.getEndTime()) && proto_vorto_types_types_pb.NullableTime.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.SlimSupplierContract}
 */
proto.vtv1.SlimSupplierContract.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.SlimSupplierContract;
  return proto.vtv1.SlimSupplierContract.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.SlimSupplierContract} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.SlimSupplierContract}
 */
proto.vtv1.SlimSupplierContract.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setVirtual(value);
      break;
    case 4:
      var value = new proto_vorto_types_types_pb.NullableTime;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableTime.deserializeBinaryFromReader);
      msg.setStartTime(value);
      break;
    case 5:
      var value = new proto_vorto_types_types_pb.NullableTime;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableTime.deserializeBinaryFromReader);
      msg.setEndTime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.SlimSupplierContract.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.SlimSupplierContract.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.SlimSupplierContract} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.SlimSupplierContract.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getVirtual();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getStartTime();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto_vorto_types_types_pb.NullableTime.serializeBinaryToWriter
    );
  }
  f = message.getEndTime();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto_vorto_types_types_pb.NullableTime.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.vtv1.SlimSupplierContract.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.SlimSupplierContract} returns this
 */
proto.vtv1.SlimSupplierContract.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.vtv1.SlimSupplierContract.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.SlimSupplierContract} returns this
 */
proto.vtv1.SlimSupplierContract.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool virtual = 3;
 * @return {boolean}
 */
proto.vtv1.SlimSupplierContract.prototype.getVirtual = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vtv1.SlimSupplierContract} returns this
 */
proto.vtv1.SlimSupplierContract.prototype.setVirtual = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional NullableTime start_time = 4;
 * @return {?proto.vtv1.NullableTime}
 */
proto.vtv1.SlimSupplierContract.prototype.getStartTime = function() {
  return /** @type{?proto.vtv1.NullableTime} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableTime, 4));
};


/**
 * @param {?proto.vtv1.NullableTime|undefined} value
 * @return {!proto.vtv1.SlimSupplierContract} returns this
*/
proto.vtv1.SlimSupplierContract.prototype.setStartTime = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.SlimSupplierContract} returns this
 */
proto.vtv1.SlimSupplierContract.prototype.clearStartTime = function() {
  return this.setStartTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.SlimSupplierContract.prototype.hasStartTime = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional NullableTime end_time = 5;
 * @return {?proto.vtv1.NullableTime}
 */
proto.vtv1.SlimSupplierContract.prototype.getEndTime = function() {
  return /** @type{?proto.vtv1.NullableTime} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableTime, 5));
};


/**
 * @param {?proto.vtv1.NullableTime|undefined} value
 * @return {!proto.vtv1.SlimSupplierContract} returns this
*/
proto.vtv1.SlimSupplierContract.prototype.setEndTime = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.SlimSupplierContract} returns this
 */
proto.vtv1.SlimSupplierContract.prototype.clearEndTime = function() {
  return this.setEndTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.SlimSupplierContract.prototype.hasEndTime = function() {
  return jspb.Message.getField(this, 5) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.ListEdgeContractsForCreateOrdersResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.ListEdgeContractsForCreateOrdersResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.ListEdgeContractsForCreateOrdersResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.ListEdgeContractsForCreateOrdersResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.ListEdgeContractsForCreateOrdersResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    edgesList: jspb.Message.toObjectList(msg.getEdgesList(),
    proto.vtv1.CreateOrderEdgeContract.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.ListEdgeContractsForCreateOrdersResponse}
 */
proto.vtv1.ListEdgeContractsForCreateOrdersResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.ListEdgeContractsForCreateOrdersResponse;
  return proto.vtv1.ListEdgeContractsForCreateOrdersResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.ListEdgeContractsForCreateOrdersResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.ListEdgeContractsForCreateOrdersResponse}
 */
proto.vtv1.ListEdgeContractsForCreateOrdersResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.vtv1.CreateOrderEdgeContract;
      reader.readMessage(value,proto.vtv1.CreateOrderEdgeContract.deserializeBinaryFromReader);
      msg.addEdges(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.ListEdgeContractsForCreateOrdersResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.ListEdgeContractsForCreateOrdersResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.ListEdgeContractsForCreateOrdersResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.ListEdgeContractsForCreateOrdersResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEdgesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.vtv1.CreateOrderEdgeContract.serializeBinaryToWriter
    );
  }
};


/**
 * repeated CreateOrderEdgeContract edges = 1;
 * @return {!Array<!proto.vtv1.CreateOrderEdgeContract>}
 */
proto.vtv1.ListEdgeContractsForCreateOrdersResponse.prototype.getEdgesList = function() {
  return /** @type{!Array<!proto.vtv1.CreateOrderEdgeContract>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vtv1.CreateOrderEdgeContract, 1));
};


/**
 * @param {!Array<!proto.vtv1.CreateOrderEdgeContract>} value
 * @return {!proto.vtv1.ListEdgeContractsForCreateOrdersResponse} returns this
*/
proto.vtv1.ListEdgeContractsForCreateOrdersResponse.prototype.setEdgesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.vtv1.CreateOrderEdgeContract=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.CreateOrderEdgeContract}
 */
proto.vtv1.ListEdgeContractsForCreateOrdersResponse.prototype.addEdges = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.vtv1.CreateOrderEdgeContract, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.ListEdgeContractsForCreateOrdersResponse} returns this
 */
proto.vtv1.ListEdgeContractsForCreateOrdersResponse.prototype.clearEdgesList = function() {
  return this.setEdgesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.ListInNetworkSuppliersResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.ListInNetworkSuppliersResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.ListInNetworkSuppliersResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.ListInNetworkSuppliersResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.ListInNetworkSuppliersResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    suppliersList: jspb.Message.toObjectList(msg.getSuppliersList(),
    proto.vtv1.SlimSupplierContract.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.ListInNetworkSuppliersResponse}
 */
proto.vtv1.ListInNetworkSuppliersResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.ListInNetworkSuppliersResponse;
  return proto.vtv1.ListInNetworkSuppliersResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.ListInNetworkSuppliersResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.ListInNetworkSuppliersResponse}
 */
proto.vtv1.ListInNetworkSuppliersResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.vtv1.SlimSupplierContract;
      reader.readMessage(value,proto.vtv1.SlimSupplierContract.deserializeBinaryFromReader);
      msg.addSuppliers(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.ListInNetworkSuppliersResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.ListInNetworkSuppliersResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.ListInNetworkSuppliersResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.ListInNetworkSuppliersResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuppliersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.vtv1.SlimSupplierContract.serializeBinaryToWriter
    );
  }
};


/**
 * repeated SlimSupplierContract suppliers = 1;
 * @return {!Array<!proto.vtv1.SlimSupplierContract>}
 */
proto.vtv1.ListInNetworkSuppliersResponse.prototype.getSuppliersList = function() {
  return /** @type{!Array<!proto.vtv1.SlimSupplierContract>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vtv1.SlimSupplierContract, 1));
};


/**
 * @param {!Array<!proto.vtv1.SlimSupplierContract>} value
 * @return {!proto.vtv1.ListInNetworkSuppliersResponse} returns this
*/
proto.vtv1.ListInNetworkSuppliersResponse.prototype.setSuppliersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.vtv1.SlimSupplierContract=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.SlimSupplierContract}
 */
proto.vtv1.ListInNetworkSuppliersResponse.prototype.addSuppliers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.vtv1.SlimSupplierContract, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.ListInNetworkSuppliersResponse} returns this
 */
proto.vtv1.ListInNetworkSuppliersResponse.prototype.clearSuppliersList = function() {
  return this.setSuppliersList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.NodeListSupplierSitesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.NodeListSupplierSitesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.NodeListSupplierSitesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.NodeListSupplierSitesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    supplierAccountId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.NodeListSupplierSitesRequest}
 */
proto.vtv1.NodeListSupplierSitesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.NodeListSupplierSitesRequest;
  return proto.vtv1.NodeListSupplierSitesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.NodeListSupplierSitesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.NodeListSupplierSitesRequest}
 */
proto.vtv1.NodeListSupplierSitesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSupplierAccountId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.NodeListSupplierSitesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.NodeListSupplierSitesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.NodeListSupplierSitesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.NodeListSupplierSitesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSupplierAccountId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string supplier_account_id = 1;
 * @return {string}
 */
proto.vtv1.NodeListSupplierSitesRequest.prototype.getSupplierAccountId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.NodeListSupplierSitesRequest} returns this
 */
proto.vtv1.NodeListSupplierSitesRequest.prototype.setSupplierAccountId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.NodeListSupplierSitesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.NodeListSupplierSitesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.NodeListSupplierSitesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.NodeListSupplierSitesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.NodeListSupplierSitesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    sitesList: jspb.Message.toObjectList(msg.getSitesList(),
    proto_vorto_types_types_pb.Site.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.NodeListSupplierSitesResponse}
 */
proto.vtv1.NodeListSupplierSitesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.NodeListSupplierSitesResponse;
  return proto.vtv1.NodeListSupplierSitesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.NodeListSupplierSitesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.NodeListSupplierSitesResponse}
 */
proto.vtv1.NodeListSupplierSitesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_vorto_types_types_pb.Site;
      reader.readMessage(value,proto_vorto_types_types_pb.Site.deserializeBinaryFromReader);
      msg.addSites(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.NodeListSupplierSitesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.NodeListSupplierSitesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.NodeListSupplierSitesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.NodeListSupplierSitesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSitesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto_vorto_types_types_pb.Site.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Site sites = 1;
 * @return {!Array<!proto.vtv1.Site>}
 */
proto.vtv1.NodeListSupplierSitesResponse.prototype.getSitesList = function() {
  return /** @type{!Array<!proto.vtv1.Site>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_vorto_types_types_pb.Site, 1));
};


/**
 * @param {!Array<!proto.vtv1.Site>} value
 * @return {!proto.vtv1.NodeListSupplierSitesResponse} returns this
*/
proto.vtv1.NodeListSupplierSitesResponse.prototype.setSitesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.vtv1.Site=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.Site}
 */
proto.vtv1.NodeListSupplierSitesResponse.prototype.addSites = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.vtv1.Site, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.NodeListSupplierSitesResponse} returns this
 */
proto.vtv1.NodeListSupplierSitesResponse.prototype.clearSitesList = function() {
  return this.setSitesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.NodeListProductsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.NodeListProductsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.NodeListProductsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.NodeListProductsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.NodeListProductsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    productsList: jspb.Message.toObjectList(msg.getProductsList(),
    proto_vorto_types_types_pb.FlatProduct.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.NodeListProductsResponse}
 */
proto.vtv1.NodeListProductsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.NodeListProductsResponse;
  return proto.vtv1.NodeListProductsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.NodeListProductsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.NodeListProductsResponse}
 */
proto.vtv1.NodeListProductsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_vorto_types_types_pb.FlatProduct;
      reader.readMessage(value,proto_vorto_types_types_pb.FlatProduct.deserializeBinaryFromReader);
      msg.addProducts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.NodeListProductsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.NodeListProductsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.NodeListProductsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.NodeListProductsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProductsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto_vorto_types_types_pb.FlatProduct.serializeBinaryToWriter
    );
  }
};


/**
 * repeated FlatProduct products = 1;
 * @return {!Array<!proto.vtv1.FlatProduct>}
 */
proto.vtv1.NodeListProductsResponse.prototype.getProductsList = function() {
  return /** @type{!Array<!proto.vtv1.FlatProduct>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_vorto_types_types_pb.FlatProduct, 1));
};


/**
 * @param {!Array<!proto.vtv1.FlatProduct>} value
 * @return {!proto.vtv1.NodeListProductsResponse} returns this
*/
proto.vtv1.NodeListProductsResponse.prototype.setProductsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.vtv1.FlatProduct=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.FlatProduct}
 */
proto.vtv1.NodeListProductsResponse.prototype.addProducts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.vtv1.FlatProduct, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.NodeListProductsResponse} returns this
 */
proto.vtv1.NodeListProductsResponse.prototype.clearProductsList = function() {
  return this.setProductsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.ListPurchaseOrdersRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.ListPurchaseOrdersRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.ListPurchaseOrdersRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.ListPurchaseOrdersRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    closed: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.ListPurchaseOrdersRequest}
 */
proto.vtv1.ListPurchaseOrdersRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.ListPurchaseOrdersRequest;
  return proto.vtv1.ListPurchaseOrdersRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.ListPurchaseOrdersRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.ListPurchaseOrdersRequest}
 */
proto.vtv1.ListPurchaseOrdersRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setClosed(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.ListPurchaseOrdersRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.ListPurchaseOrdersRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.ListPurchaseOrdersRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.ListPurchaseOrdersRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getClosed();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool closed = 1;
 * @return {boolean}
 */
proto.vtv1.ListPurchaseOrdersRequest.prototype.getClosed = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vtv1.ListPurchaseOrdersRequest} returns this
 */
proto.vtv1.ListPurchaseOrdersRequest.prototype.setClosed = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.ListPurchaseOrdersResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.ListPurchaseOrdersResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.ListPurchaseOrdersResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.ListPurchaseOrdersResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.ListPurchaseOrdersResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    purchaseOrdersList: jspb.Message.toObjectList(msg.getPurchaseOrdersList(),
    proto_vorto_node_node_pb.PurchaseOrderSummary.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.ListPurchaseOrdersResponse}
 */
proto.vtv1.ListPurchaseOrdersResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.ListPurchaseOrdersResponse;
  return proto.vtv1.ListPurchaseOrdersResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.ListPurchaseOrdersResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.ListPurchaseOrdersResponse}
 */
proto.vtv1.ListPurchaseOrdersResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_vorto_node_node_pb.PurchaseOrderSummary;
      reader.readMessage(value,proto_vorto_node_node_pb.PurchaseOrderSummary.deserializeBinaryFromReader);
      msg.addPurchaseOrders(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.ListPurchaseOrdersResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.ListPurchaseOrdersResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.ListPurchaseOrdersResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.ListPurchaseOrdersResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPurchaseOrdersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto_vorto_node_node_pb.PurchaseOrderSummary.serializeBinaryToWriter
    );
  }
};


/**
 * repeated PurchaseOrderSummary purchase_orders = 1;
 * @return {!Array<!proto.vtv1.PurchaseOrderSummary>}
 */
proto.vtv1.ListPurchaseOrdersResponse.prototype.getPurchaseOrdersList = function() {
  return /** @type{!Array<!proto.vtv1.PurchaseOrderSummary>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_vorto_node_node_pb.PurchaseOrderSummary, 1));
};


/**
 * @param {!Array<!proto.vtv1.PurchaseOrderSummary>} value
 * @return {!proto.vtv1.ListPurchaseOrdersResponse} returns this
*/
proto.vtv1.ListPurchaseOrdersResponse.prototype.setPurchaseOrdersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.vtv1.PurchaseOrderSummary=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.PurchaseOrderSummary}
 */
proto.vtv1.ListPurchaseOrdersResponse.prototype.addPurchaseOrders = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.vtv1.PurchaseOrderSummary, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.ListPurchaseOrdersResponse} returns this
 */
proto.vtv1.ListPurchaseOrdersResponse.prototype.clearPurchaseOrdersList = function() {
  return this.setPurchaseOrdersList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.ListPurchaseOrdersAsSupplierResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.ListPurchaseOrdersAsSupplierResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.ListPurchaseOrdersAsSupplierResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.ListPurchaseOrdersAsSupplierResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.ListPurchaseOrdersAsSupplierResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    purchaseOrdersList: jspb.Message.toObjectList(msg.getPurchaseOrdersList(),
    proto_vorto_node_node_pb.PurchaseOrderSummaryAsSupplier.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.ListPurchaseOrdersAsSupplierResponse}
 */
proto.vtv1.ListPurchaseOrdersAsSupplierResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.ListPurchaseOrdersAsSupplierResponse;
  return proto.vtv1.ListPurchaseOrdersAsSupplierResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.ListPurchaseOrdersAsSupplierResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.ListPurchaseOrdersAsSupplierResponse}
 */
proto.vtv1.ListPurchaseOrdersAsSupplierResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_vorto_node_node_pb.PurchaseOrderSummaryAsSupplier;
      reader.readMessage(value,proto_vorto_node_node_pb.PurchaseOrderSummaryAsSupplier.deserializeBinaryFromReader);
      msg.addPurchaseOrders(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.ListPurchaseOrdersAsSupplierResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.ListPurchaseOrdersAsSupplierResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.ListPurchaseOrdersAsSupplierResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.ListPurchaseOrdersAsSupplierResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPurchaseOrdersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto_vorto_node_node_pb.PurchaseOrderSummaryAsSupplier.serializeBinaryToWriter
    );
  }
};


/**
 * repeated PurchaseOrderSummaryAsSupplier purchase_orders = 1;
 * @return {!Array<!proto.vtv1.PurchaseOrderSummaryAsSupplier>}
 */
proto.vtv1.ListPurchaseOrdersAsSupplierResponse.prototype.getPurchaseOrdersList = function() {
  return /** @type{!Array<!proto.vtv1.PurchaseOrderSummaryAsSupplier>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_vorto_node_node_pb.PurchaseOrderSummaryAsSupplier, 1));
};


/**
 * @param {!Array<!proto.vtv1.PurchaseOrderSummaryAsSupplier>} value
 * @return {!proto.vtv1.ListPurchaseOrdersAsSupplierResponse} returns this
*/
proto.vtv1.ListPurchaseOrdersAsSupplierResponse.prototype.setPurchaseOrdersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.vtv1.PurchaseOrderSummaryAsSupplier=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.PurchaseOrderSummaryAsSupplier}
 */
proto.vtv1.ListPurchaseOrdersAsSupplierResponse.prototype.addPurchaseOrders = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.vtv1.PurchaseOrderSummaryAsSupplier, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.ListPurchaseOrdersAsSupplierResponse} returns this
 */
proto.vtv1.ListPurchaseOrdersAsSupplierResponse.prototype.clearPurchaseOrdersList = function() {
  return this.setPurchaseOrdersList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.NodeCreatePurchaseOrderRequest.repeatedFields_ = [3,7,12];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.NodeCreatePurchaseOrderRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.NodeCreatePurchaseOrderRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.NodeCreatePurchaseOrderRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.NodeCreatePurchaseOrderRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    supplierId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    supplierSitesList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    shipperIsNode: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    startsAt: (f = msg.getStartsAt()) && proto_vorto_types_types_pb.NullableTime.toObject(includeInstance, f),
    expiresAt: (f = msg.getExpiresAt()) && proto_vorto_types_types_pb.NullableTime.toObject(includeInstance, f),
    lineItemsList: jspb.Message.toObjectList(msg.getLineItemsList(),
    proto.vtv1.NodeUpsertPurchaseOrderLineItem.toObject, includeInstance),
    leadTimeHours: jspb.Message.getFieldWithDefault(msg, 8, 0),
    requiresSupplierConfirmation: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
    shipmentsRequireSupplierInformation: jspb.Message.getBooleanFieldWithDefault(msg, 11, false),
    inventoryIdsToLinkList: (f = jspb.Message.getRepeatedField(msg, 12)) == null ? undefined : f,
    billable: jspb.Message.getBooleanFieldWithDefault(msg, 13, false),
    internalInboundOutbound: (f = msg.getInternalInboundOutbound()) && proto_vorto_types_types_pb.NullableBool.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.NodeCreatePurchaseOrderRequest}
 */
proto.vtv1.NodeCreatePurchaseOrderRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.NodeCreatePurchaseOrderRequest;
  return proto.vtv1.NodeCreatePurchaseOrderRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.NodeCreatePurchaseOrderRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.NodeCreatePurchaseOrderRequest}
 */
proto.vtv1.NodeCreatePurchaseOrderRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSupplierId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addSupplierSites(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setShipperIsNode(value);
      break;
    case 5:
      var value = new proto_vorto_types_types_pb.NullableTime;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableTime.deserializeBinaryFromReader);
      msg.setStartsAt(value);
      break;
    case 6:
      var value = new proto_vorto_types_types_pb.NullableTime;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableTime.deserializeBinaryFromReader);
      msg.setExpiresAt(value);
      break;
    case 7:
      var value = new proto.vtv1.NodeUpsertPurchaseOrderLineItem;
      reader.readMessage(value,proto.vtv1.NodeUpsertPurchaseOrderLineItem.deserializeBinaryFromReader);
      msg.addLineItems(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLeadTimeHours(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRequiresSupplierConfirmation(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setShipmentsRequireSupplierInformation(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.addInventoryIdsToLink(value);
      break;
    case 13:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setBillable(value);
      break;
    case 14:
      var value = new proto_vorto_types_types_pb.NullableBool;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableBool.deserializeBinaryFromReader);
      msg.setInternalInboundOutbound(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.NodeCreatePurchaseOrderRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.NodeCreatePurchaseOrderRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.NodeCreatePurchaseOrderRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.NodeCreatePurchaseOrderRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSupplierId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSupplierSitesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
  f = message.getShipperIsNode();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getStartsAt();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto_vorto_types_types_pb.NullableTime.serializeBinaryToWriter
    );
  }
  f = message.getExpiresAt();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto_vorto_types_types_pb.NullableTime.serializeBinaryToWriter
    );
  }
  f = message.getLineItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto.vtv1.NodeUpsertPurchaseOrderLineItem.serializeBinaryToWriter
    );
  }
  f = message.getLeadTimeHours();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
  f = message.getRequiresSupplierConfirmation();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
  f = message.getShipmentsRequireSupplierInformation();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
  f = message.getInventoryIdsToLinkList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      12,
      f
    );
  }
  f = message.getBillable();
  if (f) {
    writer.writeBool(
      13,
      f
    );
  }
  f = message.getInternalInboundOutbound();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      proto_vorto_types_types_pb.NullableBool.serializeBinaryToWriter
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.vtv1.NodeCreatePurchaseOrderRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.NodeCreatePurchaseOrderRequest} returns this
 */
proto.vtv1.NodeCreatePurchaseOrderRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string supplier_id = 2;
 * @return {string}
 */
proto.vtv1.NodeCreatePurchaseOrderRequest.prototype.getSupplierId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.NodeCreatePurchaseOrderRequest} returns this
 */
proto.vtv1.NodeCreatePurchaseOrderRequest.prototype.setSupplierId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated string supplier_sites = 3;
 * @return {!Array<string>}
 */
proto.vtv1.NodeCreatePurchaseOrderRequest.prototype.getSupplierSitesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.vtv1.NodeCreatePurchaseOrderRequest} returns this
 */
proto.vtv1.NodeCreatePurchaseOrderRequest.prototype.setSupplierSitesList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.vtv1.NodeCreatePurchaseOrderRequest} returns this
 */
proto.vtv1.NodeCreatePurchaseOrderRequest.prototype.addSupplierSites = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.NodeCreatePurchaseOrderRequest} returns this
 */
proto.vtv1.NodeCreatePurchaseOrderRequest.prototype.clearSupplierSitesList = function() {
  return this.setSupplierSitesList([]);
};


/**
 * optional bool shipper_is_node = 4;
 * @return {boolean}
 */
proto.vtv1.NodeCreatePurchaseOrderRequest.prototype.getShipperIsNode = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vtv1.NodeCreatePurchaseOrderRequest} returns this
 */
proto.vtv1.NodeCreatePurchaseOrderRequest.prototype.setShipperIsNode = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional NullableTime starts_at = 5;
 * @return {?proto.vtv1.NullableTime}
 */
proto.vtv1.NodeCreatePurchaseOrderRequest.prototype.getStartsAt = function() {
  return /** @type{?proto.vtv1.NullableTime} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableTime, 5));
};


/**
 * @param {?proto.vtv1.NullableTime|undefined} value
 * @return {!proto.vtv1.NodeCreatePurchaseOrderRequest} returns this
*/
proto.vtv1.NodeCreatePurchaseOrderRequest.prototype.setStartsAt = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.NodeCreatePurchaseOrderRequest} returns this
 */
proto.vtv1.NodeCreatePurchaseOrderRequest.prototype.clearStartsAt = function() {
  return this.setStartsAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.NodeCreatePurchaseOrderRequest.prototype.hasStartsAt = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional NullableTime expires_at = 6;
 * @return {?proto.vtv1.NullableTime}
 */
proto.vtv1.NodeCreatePurchaseOrderRequest.prototype.getExpiresAt = function() {
  return /** @type{?proto.vtv1.NullableTime} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableTime, 6));
};


/**
 * @param {?proto.vtv1.NullableTime|undefined} value
 * @return {!proto.vtv1.NodeCreatePurchaseOrderRequest} returns this
*/
proto.vtv1.NodeCreatePurchaseOrderRequest.prototype.setExpiresAt = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.NodeCreatePurchaseOrderRequest} returns this
 */
proto.vtv1.NodeCreatePurchaseOrderRequest.prototype.clearExpiresAt = function() {
  return this.setExpiresAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.NodeCreatePurchaseOrderRequest.prototype.hasExpiresAt = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * repeated NodeUpsertPurchaseOrderLineItem line_items = 7;
 * @return {!Array<!proto.vtv1.NodeUpsertPurchaseOrderLineItem>}
 */
proto.vtv1.NodeCreatePurchaseOrderRequest.prototype.getLineItemsList = function() {
  return /** @type{!Array<!proto.vtv1.NodeUpsertPurchaseOrderLineItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vtv1.NodeUpsertPurchaseOrderLineItem, 7));
};


/**
 * @param {!Array<!proto.vtv1.NodeUpsertPurchaseOrderLineItem>} value
 * @return {!proto.vtv1.NodeCreatePurchaseOrderRequest} returns this
*/
proto.vtv1.NodeCreatePurchaseOrderRequest.prototype.setLineItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.vtv1.NodeUpsertPurchaseOrderLineItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.NodeUpsertPurchaseOrderLineItem}
 */
proto.vtv1.NodeCreatePurchaseOrderRequest.prototype.addLineItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.vtv1.NodeUpsertPurchaseOrderLineItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.NodeCreatePurchaseOrderRequest} returns this
 */
proto.vtv1.NodeCreatePurchaseOrderRequest.prototype.clearLineItemsList = function() {
  return this.setLineItemsList([]);
};


/**
 * optional int64 lead_time_hours = 8;
 * @return {number}
 */
proto.vtv1.NodeCreatePurchaseOrderRequest.prototype.getLeadTimeHours = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.NodeCreatePurchaseOrderRequest} returns this
 */
proto.vtv1.NodeCreatePurchaseOrderRequest.prototype.setLeadTimeHours = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional bool requires_supplier_confirmation = 10;
 * @return {boolean}
 */
proto.vtv1.NodeCreatePurchaseOrderRequest.prototype.getRequiresSupplierConfirmation = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vtv1.NodeCreatePurchaseOrderRequest} returns this
 */
proto.vtv1.NodeCreatePurchaseOrderRequest.prototype.setRequiresSupplierConfirmation = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};


/**
 * optional bool shipments_require_supplier_information = 11;
 * @return {boolean}
 */
proto.vtv1.NodeCreatePurchaseOrderRequest.prototype.getShipmentsRequireSupplierInformation = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vtv1.NodeCreatePurchaseOrderRequest} returns this
 */
proto.vtv1.NodeCreatePurchaseOrderRequest.prototype.setShipmentsRequireSupplierInformation = function(value) {
  return jspb.Message.setProto3BooleanField(this, 11, value);
};


/**
 * repeated string inventory_ids_to_link = 12;
 * @return {!Array<string>}
 */
proto.vtv1.NodeCreatePurchaseOrderRequest.prototype.getInventoryIdsToLinkList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 12));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.vtv1.NodeCreatePurchaseOrderRequest} returns this
 */
proto.vtv1.NodeCreatePurchaseOrderRequest.prototype.setInventoryIdsToLinkList = function(value) {
  return jspb.Message.setField(this, 12, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.vtv1.NodeCreatePurchaseOrderRequest} returns this
 */
proto.vtv1.NodeCreatePurchaseOrderRequest.prototype.addInventoryIdsToLink = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 12, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.NodeCreatePurchaseOrderRequest} returns this
 */
proto.vtv1.NodeCreatePurchaseOrderRequest.prototype.clearInventoryIdsToLinkList = function() {
  return this.setInventoryIdsToLinkList([]);
};


/**
 * optional bool billable = 13;
 * @return {boolean}
 */
proto.vtv1.NodeCreatePurchaseOrderRequest.prototype.getBillable = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 13, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vtv1.NodeCreatePurchaseOrderRequest} returns this
 */
proto.vtv1.NodeCreatePurchaseOrderRequest.prototype.setBillable = function(value) {
  return jspb.Message.setProto3BooleanField(this, 13, value);
};


/**
 * optional NullableBool internal_inbound_outbound = 14;
 * @return {?proto.vtv1.NullableBool}
 */
proto.vtv1.NodeCreatePurchaseOrderRequest.prototype.getInternalInboundOutbound = function() {
  return /** @type{?proto.vtv1.NullableBool} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableBool, 14));
};


/**
 * @param {?proto.vtv1.NullableBool|undefined} value
 * @return {!proto.vtv1.NodeCreatePurchaseOrderRequest} returns this
*/
proto.vtv1.NodeCreatePurchaseOrderRequest.prototype.setInternalInboundOutbound = function(value) {
  return jspb.Message.setWrapperField(this, 14, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.NodeCreatePurchaseOrderRequest} returns this
 */
proto.vtv1.NodeCreatePurchaseOrderRequest.prototype.clearInternalInboundOutbound = function() {
  return this.setInternalInboundOutbound(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.NodeCreatePurchaseOrderRequest.prototype.hasInternalInboundOutbound = function() {
  return jspb.Message.getField(this, 14) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.NodeCreatePurchaseOrderForSupplyPlanRequest.repeatedFields_ = [4,8];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.NodeCreatePurchaseOrderForSupplyPlanRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.NodeCreatePurchaseOrderForSupplyPlanRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.NodeCreatePurchaseOrderForSupplyPlanRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.NodeCreatePurchaseOrderForSupplyPlanRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    inventoryId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    supplierId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    supplierSiteIdsList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
    shipperIsNode: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    startsAt: (f = msg.getStartsAt()) && proto_vorto_types_types_pb.NullableTime.toObject(includeInstance, f),
    expiresAt: (f = msg.getExpiresAt()) && proto_vorto_types_types_pb.NullableTime.toObject(includeInstance, f),
    lineItemsList: jspb.Message.toObjectList(msg.getLineItemsList(),
    proto.vtv1.NodeUpsertPurchaseOrderLineItem.toObject, includeInstance),
    leadTimeHours: jspb.Message.getFieldWithDefault(msg, 9, 0),
    autoConfirmRequest: jspb.Message.getBooleanFieldWithDefault(msg, 10, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.NodeCreatePurchaseOrderForSupplyPlanRequest}
 */
proto.vtv1.NodeCreatePurchaseOrderForSupplyPlanRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.NodeCreatePurchaseOrderForSupplyPlanRequest;
  return proto.vtv1.NodeCreatePurchaseOrderForSupplyPlanRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.NodeCreatePurchaseOrderForSupplyPlanRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.NodeCreatePurchaseOrderForSupplyPlanRequest}
 */
proto.vtv1.NodeCreatePurchaseOrderForSupplyPlanRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setInventoryId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSupplierId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.addSupplierSiteIds(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setShipperIsNode(value);
      break;
    case 6:
      var value = new proto_vorto_types_types_pb.NullableTime;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableTime.deserializeBinaryFromReader);
      msg.setStartsAt(value);
      break;
    case 7:
      var value = new proto_vorto_types_types_pb.NullableTime;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableTime.deserializeBinaryFromReader);
      msg.setExpiresAt(value);
      break;
    case 8:
      var value = new proto.vtv1.NodeUpsertPurchaseOrderLineItem;
      reader.readMessage(value,proto.vtv1.NodeUpsertPurchaseOrderLineItem.deserializeBinaryFromReader);
      msg.addLineItems(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLeadTimeHours(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAutoConfirmRequest(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.NodeCreatePurchaseOrderForSupplyPlanRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.NodeCreatePurchaseOrderForSupplyPlanRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.NodeCreatePurchaseOrderForSupplyPlanRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.NodeCreatePurchaseOrderForSupplyPlanRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInventoryId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSupplierId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSupplierSiteIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      4,
      f
    );
  }
  f = message.getShipperIsNode();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getStartsAt();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto_vorto_types_types_pb.NullableTime.serializeBinaryToWriter
    );
  }
  f = message.getExpiresAt();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto_vorto_types_types_pb.NullableTime.serializeBinaryToWriter
    );
  }
  f = message.getLineItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      8,
      f,
      proto.vtv1.NodeUpsertPurchaseOrderLineItem.serializeBinaryToWriter
    );
  }
  f = message.getLeadTimeHours();
  if (f !== 0) {
    writer.writeInt64(
      9,
      f
    );
  }
  f = message.getAutoConfirmRequest();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
};


/**
 * optional string inventory_id = 1;
 * @return {string}
 */
proto.vtv1.NodeCreatePurchaseOrderForSupplyPlanRequest.prototype.getInventoryId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.NodeCreatePurchaseOrderForSupplyPlanRequest} returns this
 */
proto.vtv1.NodeCreatePurchaseOrderForSupplyPlanRequest.prototype.setInventoryId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.vtv1.NodeCreatePurchaseOrderForSupplyPlanRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.NodeCreatePurchaseOrderForSupplyPlanRequest} returns this
 */
proto.vtv1.NodeCreatePurchaseOrderForSupplyPlanRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string supplier_id = 3;
 * @return {string}
 */
proto.vtv1.NodeCreatePurchaseOrderForSupplyPlanRequest.prototype.getSupplierId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.NodeCreatePurchaseOrderForSupplyPlanRequest} returns this
 */
proto.vtv1.NodeCreatePurchaseOrderForSupplyPlanRequest.prototype.setSupplierId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated string supplier_site_ids = 4;
 * @return {!Array<string>}
 */
proto.vtv1.NodeCreatePurchaseOrderForSupplyPlanRequest.prototype.getSupplierSiteIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.vtv1.NodeCreatePurchaseOrderForSupplyPlanRequest} returns this
 */
proto.vtv1.NodeCreatePurchaseOrderForSupplyPlanRequest.prototype.setSupplierSiteIdsList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.vtv1.NodeCreatePurchaseOrderForSupplyPlanRequest} returns this
 */
proto.vtv1.NodeCreatePurchaseOrderForSupplyPlanRequest.prototype.addSupplierSiteIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.NodeCreatePurchaseOrderForSupplyPlanRequest} returns this
 */
proto.vtv1.NodeCreatePurchaseOrderForSupplyPlanRequest.prototype.clearSupplierSiteIdsList = function() {
  return this.setSupplierSiteIdsList([]);
};


/**
 * optional bool shipper_is_node = 5;
 * @return {boolean}
 */
proto.vtv1.NodeCreatePurchaseOrderForSupplyPlanRequest.prototype.getShipperIsNode = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vtv1.NodeCreatePurchaseOrderForSupplyPlanRequest} returns this
 */
proto.vtv1.NodeCreatePurchaseOrderForSupplyPlanRequest.prototype.setShipperIsNode = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional NullableTime starts_at = 6;
 * @return {?proto.vtv1.NullableTime}
 */
proto.vtv1.NodeCreatePurchaseOrderForSupplyPlanRequest.prototype.getStartsAt = function() {
  return /** @type{?proto.vtv1.NullableTime} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableTime, 6));
};


/**
 * @param {?proto.vtv1.NullableTime|undefined} value
 * @return {!proto.vtv1.NodeCreatePurchaseOrderForSupplyPlanRequest} returns this
*/
proto.vtv1.NodeCreatePurchaseOrderForSupplyPlanRequest.prototype.setStartsAt = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.NodeCreatePurchaseOrderForSupplyPlanRequest} returns this
 */
proto.vtv1.NodeCreatePurchaseOrderForSupplyPlanRequest.prototype.clearStartsAt = function() {
  return this.setStartsAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.NodeCreatePurchaseOrderForSupplyPlanRequest.prototype.hasStartsAt = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional NullableTime expires_at = 7;
 * @return {?proto.vtv1.NullableTime}
 */
proto.vtv1.NodeCreatePurchaseOrderForSupplyPlanRequest.prototype.getExpiresAt = function() {
  return /** @type{?proto.vtv1.NullableTime} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableTime, 7));
};


/**
 * @param {?proto.vtv1.NullableTime|undefined} value
 * @return {!proto.vtv1.NodeCreatePurchaseOrderForSupplyPlanRequest} returns this
*/
proto.vtv1.NodeCreatePurchaseOrderForSupplyPlanRequest.prototype.setExpiresAt = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.NodeCreatePurchaseOrderForSupplyPlanRequest} returns this
 */
proto.vtv1.NodeCreatePurchaseOrderForSupplyPlanRequest.prototype.clearExpiresAt = function() {
  return this.setExpiresAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.NodeCreatePurchaseOrderForSupplyPlanRequest.prototype.hasExpiresAt = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * repeated NodeUpsertPurchaseOrderLineItem line_items = 8;
 * @return {!Array<!proto.vtv1.NodeUpsertPurchaseOrderLineItem>}
 */
proto.vtv1.NodeCreatePurchaseOrderForSupplyPlanRequest.prototype.getLineItemsList = function() {
  return /** @type{!Array<!proto.vtv1.NodeUpsertPurchaseOrderLineItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vtv1.NodeUpsertPurchaseOrderLineItem, 8));
};


/**
 * @param {!Array<!proto.vtv1.NodeUpsertPurchaseOrderLineItem>} value
 * @return {!proto.vtv1.NodeCreatePurchaseOrderForSupplyPlanRequest} returns this
*/
proto.vtv1.NodeCreatePurchaseOrderForSupplyPlanRequest.prototype.setLineItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 8, value);
};


/**
 * @param {!proto.vtv1.NodeUpsertPurchaseOrderLineItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.NodeUpsertPurchaseOrderLineItem}
 */
proto.vtv1.NodeCreatePurchaseOrderForSupplyPlanRequest.prototype.addLineItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 8, opt_value, proto.vtv1.NodeUpsertPurchaseOrderLineItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.NodeCreatePurchaseOrderForSupplyPlanRequest} returns this
 */
proto.vtv1.NodeCreatePurchaseOrderForSupplyPlanRequest.prototype.clearLineItemsList = function() {
  return this.setLineItemsList([]);
};


/**
 * optional int64 lead_time_hours = 9;
 * @return {number}
 */
proto.vtv1.NodeCreatePurchaseOrderForSupplyPlanRequest.prototype.getLeadTimeHours = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.NodeCreatePurchaseOrderForSupplyPlanRequest} returns this
 */
proto.vtv1.NodeCreatePurchaseOrderForSupplyPlanRequest.prototype.setLeadTimeHours = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional bool auto_confirm_request = 10;
 * @return {boolean}
 */
proto.vtv1.NodeCreatePurchaseOrderForSupplyPlanRequest.prototype.getAutoConfirmRequest = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vtv1.NodeCreatePurchaseOrderForSupplyPlanRequest} returns this
 */
proto.vtv1.NodeCreatePurchaseOrderForSupplyPlanRequest.prototype.setAutoConfirmRequest = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.NodeCreatePurchaseOrderForSupplyPlanResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.NodeCreatePurchaseOrderForSupplyPlanResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.NodeCreatePurchaseOrderForSupplyPlanResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.NodeCreatePurchaseOrderForSupplyPlanResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.NodeCreatePurchaseOrderForSupplyPlanResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    purchaseOrdersList: jspb.Message.toObjectList(msg.getPurchaseOrdersList(),
    proto_vorto_node_node_pb.InventorySupplyPlanPurchaseOrder.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.NodeCreatePurchaseOrderForSupplyPlanResponse}
 */
proto.vtv1.NodeCreatePurchaseOrderForSupplyPlanResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.NodeCreatePurchaseOrderForSupplyPlanResponse;
  return proto.vtv1.NodeCreatePurchaseOrderForSupplyPlanResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.NodeCreatePurchaseOrderForSupplyPlanResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.NodeCreatePurchaseOrderForSupplyPlanResponse}
 */
proto.vtv1.NodeCreatePurchaseOrderForSupplyPlanResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_vorto_node_node_pb.InventorySupplyPlanPurchaseOrder;
      reader.readMessage(value,proto_vorto_node_node_pb.InventorySupplyPlanPurchaseOrder.deserializeBinaryFromReader);
      msg.addPurchaseOrders(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.NodeCreatePurchaseOrderForSupplyPlanResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.NodeCreatePurchaseOrderForSupplyPlanResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.NodeCreatePurchaseOrderForSupplyPlanResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.NodeCreatePurchaseOrderForSupplyPlanResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPurchaseOrdersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto_vorto_node_node_pb.InventorySupplyPlanPurchaseOrder.serializeBinaryToWriter
    );
  }
};


/**
 * repeated InventorySupplyPlanPurchaseOrder purchase_orders = 1;
 * @return {!Array<!proto.vtv1.InventorySupplyPlanPurchaseOrder>}
 */
proto.vtv1.NodeCreatePurchaseOrderForSupplyPlanResponse.prototype.getPurchaseOrdersList = function() {
  return /** @type{!Array<!proto.vtv1.InventorySupplyPlanPurchaseOrder>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_vorto_node_node_pb.InventorySupplyPlanPurchaseOrder, 1));
};


/**
 * @param {!Array<!proto.vtv1.InventorySupplyPlanPurchaseOrder>} value
 * @return {!proto.vtv1.NodeCreatePurchaseOrderForSupplyPlanResponse} returns this
*/
proto.vtv1.NodeCreatePurchaseOrderForSupplyPlanResponse.prototype.setPurchaseOrdersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.vtv1.InventorySupplyPlanPurchaseOrder=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.InventorySupplyPlanPurchaseOrder}
 */
proto.vtv1.NodeCreatePurchaseOrderForSupplyPlanResponse.prototype.addPurchaseOrders = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.vtv1.InventorySupplyPlanPurchaseOrder, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.NodeCreatePurchaseOrderForSupplyPlanResponse} returns this
 */
proto.vtv1.NodeCreatePurchaseOrderForSupplyPlanResponse.prototype.clearPurchaseOrdersList = function() {
  return this.setPurchaseOrdersList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.NodeCreateVirtualSupplierForSupplyPlanResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.NodeCreateVirtualSupplierForSupplyPlanResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.NodeCreateVirtualSupplierForSupplyPlanResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.NodeCreateVirtualSupplierForSupplyPlanResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.NodeCreateVirtualSupplierForSupplyPlanResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    suppliersList: jspb.Message.toObjectList(msg.getSuppliersList(),
    proto_vorto_node_node_pb.InventorySupplyPlanSupplier.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.NodeCreateVirtualSupplierForSupplyPlanResponse}
 */
proto.vtv1.NodeCreateVirtualSupplierForSupplyPlanResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.NodeCreateVirtualSupplierForSupplyPlanResponse;
  return proto.vtv1.NodeCreateVirtualSupplierForSupplyPlanResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.NodeCreateVirtualSupplierForSupplyPlanResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.NodeCreateVirtualSupplierForSupplyPlanResponse}
 */
proto.vtv1.NodeCreateVirtualSupplierForSupplyPlanResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_vorto_node_node_pb.InventorySupplyPlanSupplier;
      reader.readMessage(value,proto_vorto_node_node_pb.InventorySupplyPlanSupplier.deserializeBinaryFromReader);
      msg.addSuppliers(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.NodeCreateVirtualSupplierForSupplyPlanResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.NodeCreateVirtualSupplierForSupplyPlanResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.NodeCreateVirtualSupplierForSupplyPlanResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.NodeCreateVirtualSupplierForSupplyPlanResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuppliersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto_vorto_node_node_pb.InventorySupplyPlanSupplier.serializeBinaryToWriter
    );
  }
};


/**
 * repeated InventorySupplyPlanSupplier suppliers = 1;
 * @return {!Array<!proto.vtv1.InventorySupplyPlanSupplier>}
 */
proto.vtv1.NodeCreateVirtualSupplierForSupplyPlanResponse.prototype.getSuppliersList = function() {
  return /** @type{!Array<!proto.vtv1.InventorySupplyPlanSupplier>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_vorto_node_node_pb.InventorySupplyPlanSupplier, 1));
};


/**
 * @param {!Array<!proto.vtv1.InventorySupplyPlanSupplier>} value
 * @return {!proto.vtv1.NodeCreateVirtualSupplierForSupplyPlanResponse} returns this
*/
proto.vtv1.NodeCreateVirtualSupplierForSupplyPlanResponse.prototype.setSuppliersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.vtv1.InventorySupplyPlanSupplier=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.InventorySupplyPlanSupplier}
 */
proto.vtv1.NodeCreateVirtualSupplierForSupplyPlanResponse.prototype.addSuppliers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.vtv1.InventorySupplyPlanSupplier, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.NodeCreateVirtualSupplierForSupplyPlanResponse} returns this
 */
proto.vtv1.NodeCreateVirtualSupplierForSupplyPlanResponse.prototype.clearSuppliersList = function() {
  return this.setSuppliersList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.NodeUpsertPurchaseOrderLineItem.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.NodeUpsertPurchaseOrderLineItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.NodeUpsertPurchaseOrderLineItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.NodeUpsertPurchaseOrderLineItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    productId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    unitCostCents: jspb.Message.getFieldWithDefault(msg, 3, 0),
    totalQuantityMin: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    totalQuantityMax: (f = msg.getTotalQuantityMax()) && proto_vorto_types_types_pb.NullableDouble.toObject(includeInstance, f),
    dailyQuantityMin: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    dailyQuantityMax: (f = msg.getDailyQuantityMax()) && proto_vorto_types_types_pb.NullableDouble.toObject(includeInstance, f),
    shortfallUnitCostCents: jspb.Message.getFieldWithDefault(msg, 8, 0),
    description: jspb.Message.getFieldWithDefault(msg, 9, ""),
    quantityInCost: jspb.Message.getFloatingPointFieldWithDefault(msg, 10, 0.0),
    freightTerms: (f = msg.getFreightTerms()) && proto_vorto_types_types_pb.NullableString.toObject(includeInstance, f),
    enableBackhaul: jspb.Message.getBooleanFieldWithDefault(msg, 12, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.NodeUpsertPurchaseOrderLineItem}
 */
proto.vtv1.NodeUpsertPurchaseOrderLineItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.NodeUpsertPurchaseOrderLineItem;
  return proto.vtv1.NodeUpsertPurchaseOrderLineItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.NodeUpsertPurchaseOrderLineItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.NodeUpsertPurchaseOrderLineItem}
 */
proto.vtv1.NodeUpsertPurchaseOrderLineItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUnitCostCents(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalQuantityMin(value);
      break;
    case 5:
      var value = new proto_vorto_types_types_pb.NullableDouble;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableDouble.deserializeBinaryFromReader);
      msg.setTotalQuantityMax(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setDailyQuantityMin(value);
      break;
    case 7:
      var value = new proto_vorto_types_types_pb.NullableDouble;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableDouble.deserializeBinaryFromReader);
      msg.setDailyQuantityMax(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setShortfallUnitCostCents(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setQuantityInCost(value);
      break;
    case 11:
      var value = new proto_vorto_types_types_pb.NullableString;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableString.deserializeBinaryFromReader);
      msg.setFreightTerms(value);
      break;
    case 12:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEnableBackhaul(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.NodeUpsertPurchaseOrderLineItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.NodeUpsertPurchaseOrderLineItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.NodeUpsertPurchaseOrderLineItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.NodeUpsertPurchaseOrderLineItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getProductId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getUnitCostCents();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getTotalQuantityMin();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getTotalQuantityMax();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto_vorto_types_types_pb.NullableDouble.serializeBinaryToWriter
    );
  }
  f = message.getDailyQuantityMin();
  if (f !== 0.0) {
    writer.writeDouble(
      6,
      f
    );
  }
  f = message.getDailyQuantityMax();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto_vorto_types_types_pb.NullableDouble.serializeBinaryToWriter
    );
  }
  f = message.getShortfallUnitCostCents();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getQuantityInCost();
  if (f !== 0.0) {
    writer.writeDouble(
      10,
      f
    );
  }
  f = message.getFreightTerms();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto_vorto_types_types_pb.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getEnableBackhaul();
  if (f) {
    writer.writeBool(
      12,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.vtv1.NodeUpsertPurchaseOrderLineItem.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.NodeUpsertPurchaseOrderLineItem} returns this
 */
proto.vtv1.NodeUpsertPurchaseOrderLineItem.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string product_id = 2;
 * @return {string}
 */
proto.vtv1.NodeUpsertPurchaseOrderLineItem.prototype.getProductId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.NodeUpsertPurchaseOrderLineItem} returns this
 */
proto.vtv1.NodeUpsertPurchaseOrderLineItem.prototype.setProductId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 unit_cost_cents = 3;
 * @return {number}
 */
proto.vtv1.NodeUpsertPurchaseOrderLineItem.prototype.getUnitCostCents = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.NodeUpsertPurchaseOrderLineItem} returns this
 */
proto.vtv1.NodeUpsertPurchaseOrderLineItem.prototype.setUnitCostCents = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional double total_quantity_min = 4;
 * @return {number}
 */
proto.vtv1.NodeUpsertPurchaseOrderLineItem.prototype.getTotalQuantityMin = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.NodeUpsertPurchaseOrderLineItem} returns this
 */
proto.vtv1.NodeUpsertPurchaseOrderLineItem.prototype.setTotalQuantityMin = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional NullableDouble total_quantity_max = 5;
 * @return {?proto.vtv1.NullableDouble}
 */
proto.vtv1.NodeUpsertPurchaseOrderLineItem.prototype.getTotalQuantityMax = function() {
  return /** @type{?proto.vtv1.NullableDouble} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableDouble, 5));
};


/**
 * @param {?proto.vtv1.NullableDouble|undefined} value
 * @return {!proto.vtv1.NodeUpsertPurchaseOrderLineItem} returns this
*/
proto.vtv1.NodeUpsertPurchaseOrderLineItem.prototype.setTotalQuantityMax = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.NodeUpsertPurchaseOrderLineItem} returns this
 */
proto.vtv1.NodeUpsertPurchaseOrderLineItem.prototype.clearTotalQuantityMax = function() {
  return this.setTotalQuantityMax(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.NodeUpsertPurchaseOrderLineItem.prototype.hasTotalQuantityMax = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional double daily_quantity_min = 6;
 * @return {number}
 */
proto.vtv1.NodeUpsertPurchaseOrderLineItem.prototype.getDailyQuantityMin = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.NodeUpsertPurchaseOrderLineItem} returns this
 */
proto.vtv1.NodeUpsertPurchaseOrderLineItem.prototype.setDailyQuantityMin = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional NullableDouble daily_quantity_max = 7;
 * @return {?proto.vtv1.NullableDouble}
 */
proto.vtv1.NodeUpsertPurchaseOrderLineItem.prototype.getDailyQuantityMax = function() {
  return /** @type{?proto.vtv1.NullableDouble} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableDouble, 7));
};


/**
 * @param {?proto.vtv1.NullableDouble|undefined} value
 * @return {!proto.vtv1.NodeUpsertPurchaseOrderLineItem} returns this
*/
proto.vtv1.NodeUpsertPurchaseOrderLineItem.prototype.setDailyQuantityMax = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.NodeUpsertPurchaseOrderLineItem} returns this
 */
proto.vtv1.NodeUpsertPurchaseOrderLineItem.prototype.clearDailyQuantityMax = function() {
  return this.setDailyQuantityMax(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.NodeUpsertPurchaseOrderLineItem.prototype.hasDailyQuantityMax = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional int64 shortfall_unit_cost_cents = 8;
 * @return {number}
 */
proto.vtv1.NodeUpsertPurchaseOrderLineItem.prototype.getShortfallUnitCostCents = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.NodeUpsertPurchaseOrderLineItem} returns this
 */
proto.vtv1.NodeUpsertPurchaseOrderLineItem.prototype.setShortfallUnitCostCents = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional string description = 9;
 * @return {string}
 */
proto.vtv1.NodeUpsertPurchaseOrderLineItem.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.NodeUpsertPurchaseOrderLineItem} returns this
 */
proto.vtv1.NodeUpsertPurchaseOrderLineItem.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional double quantity_in_cost = 10;
 * @return {number}
 */
proto.vtv1.NodeUpsertPurchaseOrderLineItem.prototype.getQuantityInCost = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 10, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.NodeUpsertPurchaseOrderLineItem} returns this
 */
proto.vtv1.NodeUpsertPurchaseOrderLineItem.prototype.setQuantityInCost = function(value) {
  return jspb.Message.setProto3FloatField(this, 10, value);
};


/**
 * optional NullableString freight_terms = 11;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.NodeUpsertPurchaseOrderLineItem.prototype.getFreightTerms = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableString, 11));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.NodeUpsertPurchaseOrderLineItem} returns this
*/
proto.vtv1.NodeUpsertPurchaseOrderLineItem.prototype.setFreightTerms = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.NodeUpsertPurchaseOrderLineItem} returns this
 */
proto.vtv1.NodeUpsertPurchaseOrderLineItem.prototype.clearFreightTerms = function() {
  return this.setFreightTerms(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.NodeUpsertPurchaseOrderLineItem.prototype.hasFreightTerms = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional bool enable_backhaul = 12;
 * @return {boolean}
 */
proto.vtv1.NodeUpsertPurchaseOrderLineItem.prototype.getEnableBackhaul = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 12, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vtv1.NodeUpsertPurchaseOrderLineItem} returns this
 */
proto.vtv1.NodeUpsertPurchaseOrderLineItem.prototype.setEnableBackhaul = function(value) {
  return jspb.Message.setProto3BooleanField(this, 12, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.SinglePurchaseOrderResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.SinglePurchaseOrderResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.SinglePurchaseOrderResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.SinglePurchaseOrderResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    purchaseOrder: (f = msg.getPurchaseOrder()) && proto_vorto_types_types_pb.PurchaseOrder.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.SinglePurchaseOrderResponse}
 */
proto.vtv1.SinglePurchaseOrderResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.SinglePurchaseOrderResponse;
  return proto.vtv1.SinglePurchaseOrderResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.SinglePurchaseOrderResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.SinglePurchaseOrderResponse}
 */
proto.vtv1.SinglePurchaseOrderResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_vorto_types_types_pb.PurchaseOrder;
      reader.readMessage(value,proto_vorto_types_types_pb.PurchaseOrder.deserializeBinaryFromReader);
      msg.setPurchaseOrder(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.SinglePurchaseOrderResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.SinglePurchaseOrderResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.SinglePurchaseOrderResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.SinglePurchaseOrderResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPurchaseOrder();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_vorto_types_types_pb.PurchaseOrder.serializeBinaryToWriter
    );
  }
};


/**
 * optional PurchaseOrder purchase_order = 1;
 * @return {?proto.vtv1.PurchaseOrder}
 */
proto.vtv1.SinglePurchaseOrderResponse.prototype.getPurchaseOrder = function() {
  return /** @type{?proto.vtv1.PurchaseOrder} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.PurchaseOrder, 1));
};


/**
 * @param {?proto.vtv1.PurchaseOrder|undefined} value
 * @return {!proto.vtv1.SinglePurchaseOrderResponse} returns this
*/
proto.vtv1.SinglePurchaseOrderResponse.prototype.setPurchaseOrder = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.SinglePurchaseOrderResponse} returns this
 */
proto.vtv1.SinglePurchaseOrderResponse.prototype.clearPurchaseOrder = function() {
  return this.setPurchaseOrder(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.SinglePurchaseOrderResponse.prototype.hasPurchaseOrder = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.NodeEditPurchaseOrderRequest.repeatedFields_ = [3,9];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.NodeEditPurchaseOrderRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.NodeEditPurchaseOrderRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.NodeEditPurchaseOrderRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.NodeEditPurchaseOrderRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    purchaseOrderId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    supplierId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    supplierSitesList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    name: (f = msg.getName()) && proto_vorto_types_types_pb.NullableString.toObject(includeInstance, f),
    startsAt: (f = msg.getStartsAt()) && proto_vorto_types_types_pb.NullableTime.toObject(includeInstance, f),
    updateExpiresAt: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    expiresAt: (f = msg.getExpiresAt()) && proto_vorto_types_types_pb.NullableTime.toObject(includeInstance, f),
    updateLineItems: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    lineItemsList: jspb.Message.toObjectList(msg.getLineItemsList(),
    proto.vtv1.NodeUpsertPurchaseOrderLineItem.toObject, includeInstance),
    leadTimeHours: (f = msg.getLeadTimeHours()) && proto_vorto_types_types_pb.NullableInt64.toObject(includeInstance, f),
    autoConfirmRequest: jspb.Message.getBooleanFieldWithDefault(msg, 11, false),
    shipmentsRequireSupplierInformation: jspb.Message.getBooleanFieldWithDefault(msg, 12, false),
    paused: jspb.Message.getBooleanFieldWithDefault(msg, 13, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.NodeEditPurchaseOrderRequest}
 */
proto.vtv1.NodeEditPurchaseOrderRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.NodeEditPurchaseOrderRequest;
  return proto.vtv1.NodeEditPurchaseOrderRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.NodeEditPurchaseOrderRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.NodeEditPurchaseOrderRequest}
 */
proto.vtv1.NodeEditPurchaseOrderRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPurchaseOrderId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSupplierId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addSupplierSites(value);
      break;
    case 4:
      var value = new proto_vorto_types_types_pb.NullableString;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableString.deserializeBinaryFromReader);
      msg.setName(value);
      break;
    case 5:
      var value = new proto_vorto_types_types_pb.NullableTime;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableTime.deserializeBinaryFromReader);
      msg.setStartsAt(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setUpdateExpiresAt(value);
      break;
    case 7:
      var value = new proto_vorto_types_types_pb.NullableTime;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableTime.deserializeBinaryFromReader);
      msg.setExpiresAt(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setUpdateLineItems(value);
      break;
    case 9:
      var value = new proto.vtv1.NodeUpsertPurchaseOrderLineItem;
      reader.readMessage(value,proto.vtv1.NodeUpsertPurchaseOrderLineItem.deserializeBinaryFromReader);
      msg.addLineItems(value);
      break;
    case 10:
      var value = new proto_vorto_types_types_pb.NullableInt64;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableInt64.deserializeBinaryFromReader);
      msg.setLeadTimeHours(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAutoConfirmRequest(value);
      break;
    case 12:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setShipmentsRequireSupplierInformation(value);
      break;
    case 13:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPaused(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.NodeEditPurchaseOrderRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.NodeEditPurchaseOrderRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.NodeEditPurchaseOrderRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.NodeEditPurchaseOrderRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPurchaseOrderId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSupplierId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSupplierSitesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
  f = message.getName();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto_vorto_types_types_pb.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getStartsAt();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto_vorto_types_types_pb.NullableTime.serializeBinaryToWriter
    );
  }
  f = message.getUpdateExpiresAt();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getExpiresAt();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto_vorto_types_types_pb.NullableTime.serializeBinaryToWriter
    );
  }
  f = message.getUpdateLineItems();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getLineItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      9,
      f,
      proto.vtv1.NodeUpsertPurchaseOrderLineItem.serializeBinaryToWriter
    );
  }
  f = message.getLeadTimeHours();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto_vorto_types_types_pb.NullableInt64.serializeBinaryToWriter
    );
  }
  f = message.getAutoConfirmRequest();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
  f = message.getShipmentsRequireSupplierInformation();
  if (f) {
    writer.writeBool(
      12,
      f
    );
  }
  f = message.getPaused();
  if (f) {
    writer.writeBool(
      13,
      f
    );
  }
};


/**
 * optional string purchase_order_id = 1;
 * @return {string}
 */
proto.vtv1.NodeEditPurchaseOrderRequest.prototype.getPurchaseOrderId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.NodeEditPurchaseOrderRequest} returns this
 */
proto.vtv1.NodeEditPurchaseOrderRequest.prototype.setPurchaseOrderId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string supplier_id = 2;
 * @return {string}
 */
proto.vtv1.NodeEditPurchaseOrderRequest.prototype.getSupplierId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.NodeEditPurchaseOrderRequest} returns this
 */
proto.vtv1.NodeEditPurchaseOrderRequest.prototype.setSupplierId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated string supplier_sites = 3;
 * @return {!Array<string>}
 */
proto.vtv1.NodeEditPurchaseOrderRequest.prototype.getSupplierSitesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.vtv1.NodeEditPurchaseOrderRequest} returns this
 */
proto.vtv1.NodeEditPurchaseOrderRequest.prototype.setSupplierSitesList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.vtv1.NodeEditPurchaseOrderRequest} returns this
 */
proto.vtv1.NodeEditPurchaseOrderRequest.prototype.addSupplierSites = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.NodeEditPurchaseOrderRequest} returns this
 */
proto.vtv1.NodeEditPurchaseOrderRequest.prototype.clearSupplierSitesList = function() {
  return this.setSupplierSitesList([]);
};


/**
 * optional NullableString name = 4;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.NodeEditPurchaseOrderRequest.prototype.getName = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableString, 4));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.NodeEditPurchaseOrderRequest} returns this
*/
proto.vtv1.NodeEditPurchaseOrderRequest.prototype.setName = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.NodeEditPurchaseOrderRequest} returns this
 */
proto.vtv1.NodeEditPurchaseOrderRequest.prototype.clearName = function() {
  return this.setName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.NodeEditPurchaseOrderRequest.prototype.hasName = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional NullableTime starts_at = 5;
 * @return {?proto.vtv1.NullableTime}
 */
proto.vtv1.NodeEditPurchaseOrderRequest.prototype.getStartsAt = function() {
  return /** @type{?proto.vtv1.NullableTime} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableTime, 5));
};


/**
 * @param {?proto.vtv1.NullableTime|undefined} value
 * @return {!proto.vtv1.NodeEditPurchaseOrderRequest} returns this
*/
proto.vtv1.NodeEditPurchaseOrderRequest.prototype.setStartsAt = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.NodeEditPurchaseOrderRequest} returns this
 */
proto.vtv1.NodeEditPurchaseOrderRequest.prototype.clearStartsAt = function() {
  return this.setStartsAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.NodeEditPurchaseOrderRequest.prototype.hasStartsAt = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional bool update_expires_at = 6;
 * @return {boolean}
 */
proto.vtv1.NodeEditPurchaseOrderRequest.prototype.getUpdateExpiresAt = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vtv1.NodeEditPurchaseOrderRequest} returns this
 */
proto.vtv1.NodeEditPurchaseOrderRequest.prototype.setUpdateExpiresAt = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional NullableTime expires_at = 7;
 * @return {?proto.vtv1.NullableTime}
 */
proto.vtv1.NodeEditPurchaseOrderRequest.prototype.getExpiresAt = function() {
  return /** @type{?proto.vtv1.NullableTime} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableTime, 7));
};


/**
 * @param {?proto.vtv1.NullableTime|undefined} value
 * @return {!proto.vtv1.NodeEditPurchaseOrderRequest} returns this
*/
proto.vtv1.NodeEditPurchaseOrderRequest.prototype.setExpiresAt = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.NodeEditPurchaseOrderRequest} returns this
 */
proto.vtv1.NodeEditPurchaseOrderRequest.prototype.clearExpiresAt = function() {
  return this.setExpiresAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.NodeEditPurchaseOrderRequest.prototype.hasExpiresAt = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional bool update_line_items = 8;
 * @return {boolean}
 */
proto.vtv1.NodeEditPurchaseOrderRequest.prototype.getUpdateLineItems = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vtv1.NodeEditPurchaseOrderRequest} returns this
 */
proto.vtv1.NodeEditPurchaseOrderRequest.prototype.setUpdateLineItems = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * repeated NodeUpsertPurchaseOrderLineItem line_items = 9;
 * @return {!Array<!proto.vtv1.NodeUpsertPurchaseOrderLineItem>}
 */
proto.vtv1.NodeEditPurchaseOrderRequest.prototype.getLineItemsList = function() {
  return /** @type{!Array<!proto.vtv1.NodeUpsertPurchaseOrderLineItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vtv1.NodeUpsertPurchaseOrderLineItem, 9));
};


/**
 * @param {!Array<!proto.vtv1.NodeUpsertPurchaseOrderLineItem>} value
 * @return {!proto.vtv1.NodeEditPurchaseOrderRequest} returns this
*/
proto.vtv1.NodeEditPurchaseOrderRequest.prototype.setLineItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 9, value);
};


/**
 * @param {!proto.vtv1.NodeUpsertPurchaseOrderLineItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.NodeUpsertPurchaseOrderLineItem}
 */
proto.vtv1.NodeEditPurchaseOrderRequest.prototype.addLineItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 9, opt_value, proto.vtv1.NodeUpsertPurchaseOrderLineItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.NodeEditPurchaseOrderRequest} returns this
 */
proto.vtv1.NodeEditPurchaseOrderRequest.prototype.clearLineItemsList = function() {
  return this.setLineItemsList([]);
};


/**
 * optional NullableInt64 lead_time_hours = 10;
 * @return {?proto.vtv1.NullableInt64}
 */
proto.vtv1.NodeEditPurchaseOrderRequest.prototype.getLeadTimeHours = function() {
  return /** @type{?proto.vtv1.NullableInt64} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableInt64, 10));
};


/**
 * @param {?proto.vtv1.NullableInt64|undefined} value
 * @return {!proto.vtv1.NodeEditPurchaseOrderRequest} returns this
*/
proto.vtv1.NodeEditPurchaseOrderRequest.prototype.setLeadTimeHours = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.NodeEditPurchaseOrderRequest} returns this
 */
proto.vtv1.NodeEditPurchaseOrderRequest.prototype.clearLeadTimeHours = function() {
  return this.setLeadTimeHours(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.NodeEditPurchaseOrderRequest.prototype.hasLeadTimeHours = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional bool auto_confirm_request = 11;
 * @return {boolean}
 */
proto.vtv1.NodeEditPurchaseOrderRequest.prototype.getAutoConfirmRequest = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vtv1.NodeEditPurchaseOrderRequest} returns this
 */
proto.vtv1.NodeEditPurchaseOrderRequest.prototype.setAutoConfirmRequest = function(value) {
  return jspb.Message.setProto3BooleanField(this, 11, value);
};


/**
 * optional bool shipments_require_supplier_information = 12;
 * @return {boolean}
 */
proto.vtv1.NodeEditPurchaseOrderRequest.prototype.getShipmentsRequireSupplierInformation = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 12, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vtv1.NodeEditPurchaseOrderRequest} returns this
 */
proto.vtv1.NodeEditPurchaseOrderRequest.prototype.setShipmentsRequireSupplierInformation = function(value) {
  return jspb.Message.setProto3BooleanField(this, 12, value);
};


/**
 * optional bool paused = 13;
 * @return {boolean}
 */
proto.vtv1.NodeEditPurchaseOrderRequest.prototype.getPaused = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 13, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vtv1.NodeEditPurchaseOrderRequest} returns this
 */
proto.vtv1.NodeEditPurchaseOrderRequest.prototype.setPaused = function(value) {
  return jspb.Message.setProto3BooleanField(this, 13, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.NodeConfirmOrDeclinePurchaseOrder.repeatedFields_ = [3,4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.NodeConfirmOrDeclinePurchaseOrder.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.NodeConfirmOrDeclinePurchaseOrder.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.NodeConfirmOrDeclinePurchaseOrder} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.NodeConfirmOrDeclinePurchaseOrder.toObject = function(includeInstance, msg) {
  var f, obj = {
    purchaseOrderId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    confirmed: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    mappingsList: jspb.Message.toObjectList(msg.getMappingsList(),
    proto_vorto_node_node_pb.SupplierMappedProduct.toObject, includeInstance),
    siteIdsList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.NodeConfirmOrDeclinePurchaseOrder}
 */
proto.vtv1.NodeConfirmOrDeclinePurchaseOrder.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.NodeConfirmOrDeclinePurchaseOrder;
  return proto.vtv1.NodeConfirmOrDeclinePurchaseOrder.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.NodeConfirmOrDeclinePurchaseOrder} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.NodeConfirmOrDeclinePurchaseOrder}
 */
proto.vtv1.NodeConfirmOrDeclinePurchaseOrder.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPurchaseOrderId(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setConfirmed(value);
      break;
    case 3:
      var value = new proto_vorto_node_node_pb.SupplierMappedProduct;
      reader.readMessage(value,proto_vorto_node_node_pb.SupplierMappedProduct.deserializeBinaryFromReader);
      msg.addMappings(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.addSiteIds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.NodeConfirmOrDeclinePurchaseOrder.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.NodeConfirmOrDeclinePurchaseOrder.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.NodeConfirmOrDeclinePurchaseOrder} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.NodeConfirmOrDeclinePurchaseOrder.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPurchaseOrderId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getConfirmed();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getMappingsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto_vorto_node_node_pb.SupplierMappedProduct.serializeBinaryToWriter
    );
  }
  f = message.getSiteIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      4,
      f
    );
  }
};


/**
 * optional string purchase_order_id = 1;
 * @return {string}
 */
proto.vtv1.NodeConfirmOrDeclinePurchaseOrder.prototype.getPurchaseOrderId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.NodeConfirmOrDeclinePurchaseOrder} returns this
 */
proto.vtv1.NodeConfirmOrDeclinePurchaseOrder.prototype.setPurchaseOrderId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool confirmed = 2;
 * @return {boolean}
 */
proto.vtv1.NodeConfirmOrDeclinePurchaseOrder.prototype.getConfirmed = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vtv1.NodeConfirmOrDeclinePurchaseOrder} returns this
 */
proto.vtv1.NodeConfirmOrDeclinePurchaseOrder.prototype.setConfirmed = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * repeated SupplierMappedProduct mappings = 3;
 * @return {!Array<!proto.vtv1.SupplierMappedProduct>}
 */
proto.vtv1.NodeConfirmOrDeclinePurchaseOrder.prototype.getMappingsList = function() {
  return /** @type{!Array<!proto.vtv1.SupplierMappedProduct>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_vorto_node_node_pb.SupplierMappedProduct, 3));
};


/**
 * @param {!Array<!proto.vtv1.SupplierMappedProduct>} value
 * @return {!proto.vtv1.NodeConfirmOrDeclinePurchaseOrder} returns this
*/
proto.vtv1.NodeConfirmOrDeclinePurchaseOrder.prototype.setMappingsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.vtv1.SupplierMappedProduct=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.SupplierMappedProduct}
 */
proto.vtv1.NodeConfirmOrDeclinePurchaseOrder.prototype.addMappings = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.vtv1.SupplierMappedProduct, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.NodeConfirmOrDeclinePurchaseOrder} returns this
 */
proto.vtv1.NodeConfirmOrDeclinePurchaseOrder.prototype.clearMappingsList = function() {
  return this.setMappingsList([]);
};


/**
 * repeated string site_ids = 4;
 * @return {!Array<string>}
 */
proto.vtv1.NodeConfirmOrDeclinePurchaseOrder.prototype.getSiteIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.vtv1.NodeConfirmOrDeclinePurchaseOrder} returns this
 */
proto.vtv1.NodeConfirmOrDeclinePurchaseOrder.prototype.setSiteIdsList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.vtv1.NodeConfirmOrDeclinePurchaseOrder} returns this
 */
proto.vtv1.NodeConfirmOrDeclinePurchaseOrder.prototype.addSiteIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.NodeConfirmOrDeclinePurchaseOrder} returns this
 */
proto.vtv1.NodeConfirmOrDeclinePurchaseOrder.prototype.clearSiteIdsList = function() {
  return this.setSiteIdsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.SearchOutNetworkSuppliersByNameRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.SearchOutNetworkSuppliersByNameRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.SearchOutNetworkSuppliersByNameRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.SearchOutNetworkSuppliersByNameRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    matchString: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.SearchOutNetworkSuppliersByNameRequest}
 */
proto.vtv1.SearchOutNetworkSuppliersByNameRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.SearchOutNetworkSuppliersByNameRequest;
  return proto.vtv1.SearchOutNetworkSuppliersByNameRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.SearchOutNetworkSuppliersByNameRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.SearchOutNetworkSuppliersByNameRequest}
 */
proto.vtv1.SearchOutNetworkSuppliersByNameRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMatchString(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.SearchOutNetworkSuppliersByNameRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.SearchOutNetworkSuppliersByNameRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.SearchOutNetworkSuppliersByNameRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.SearchOutNetworkSuppliersByNameRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMatchString();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string match_string = 1;
 * @return {string}
 */
proto.vtv1.SearchOutNetworkSuppliersByNameRequest.prototype.getMatchString = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.SearchOutNetworkSuppliersByNameRequest} returns this
 */
proto.vtv1.SearchOutNetworkSuppliersByNameRequest.prototype.setMatchString = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.SearchOutNetworkSuppliersByNameResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.SearchOutNetworkSuppliersByNameResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.SearchOutNetworkSuppliersByNameResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.SearchOutNetworkSuppliersByNameResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.SearchOutNetworkSuppliersByNameResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    suppliersList: jspb.Message.toObjectList(msg.getSuppliersList(),
    proto_vorto_types_types_pb.SlimAccount.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.SearchOutNetworkSuppliersByNameResponse}
 */
proto.vtv1.SearchOutNetworkSuppliersByNameResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.SearchOutNetworkSuppliersByNameResponse;
  return proto.vtv1.SearchOutNetworkSuppliersByNameResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.SearchOutNetworkSuppliersByNameResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.SearchOutNetworkSuppliersByNameResponse}
 */
proto.vtv1.SearchOutNetworkSuppliersByNameResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_vorto_types_types_pb.SlimAccount;
      reader.readMessage(value,proto_vorto_types_types_pb.SlimAccount.deserializeBinaryFromReader);
      msg.addSuppliers(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.SearchOutNetworkSuppliersByNameResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.SearchOutNetworkSuppliersByNameResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.SearchOutNetworkSuppliersByNameResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.SearchOutNetworkSuppliersByNameResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuppliersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto_vorto_types_types_pb.SlimAccount.serializeBinaryToWriter
    );
  }
};


/**
 * repeated SlimAccount suppliers = 1;
 * @return {!Array<!proto.vtv1.SlimAccount>}
 */
proto.vtv1.SearchOutNetworkSuppliersByNameResponse.prototype.getSuppliersList = function() {
  return /** @type{!Array<!proto.vtv1.SlimAccount>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_vorto_types_types_pb.SlimAccount, 1));
};


/**
 * @param {!Array<!proto.vtv1.SlimAccount>} value
 * @return {!proto.vtv1.SearchOutNetworkSuppliersByNameResponse} returns this
*/
proto.vtv1.SearchOutNetworkSuppliersByNameResponse.prototype.setSuppliersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.vtv1.SlimAccount=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.SlimAccount}
 */
proto.vtv1.SearchOutNetworkSuppliersByNameResponse.prototype.addSuppliers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.vtv1.SlimAccount, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.SearchOutNetworkSuppliersByNameResponse} returns this
 */
proto.vtv1.SearchOutNetworkSuppliersByNameResponse.prototype.clearSuppliersList = function() {
  return this.setSuppliersList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.AddSupplierContractRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.AddSupplierContractRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.AddSupplierContractRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.AddSupplierContractRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    supplierContract: (f = msg.getSupplierContract()) && proto.vtv1.SupplierContract.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.AddSupplierContractRequest}
 */
proto.vtv1.AddSupplierContractRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.AddSupplierContractRequest;
  return proto.vtv1.AddSupplierContractRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.AddSupplierContractRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.AddSupplierContractRequest}
 */
proto.vtv1.AddSupplierContractRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.vtv1.SupplierContract;
      reader.readMessage(value,proto.vtv1.SupplierContract.deserializeBinaryFromReader);
      msg.setSupplierContract(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.AddSupplierContractRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.AddSupplierContractRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.AddSupplierContractRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.AddSupplierContractRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSupplierContract();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.vtv1.SupplierContract.serializeBinaryToWriter
    );
  }
};


/**
 * optional SupplierContract supplier_contract = 1;
 * @return {?proto.vtv1.SupplierContract}
 */
proto.vtv1.AddSupplierContractRequest.prototype.getSupplierContract = function() {
  return /** @type{?proto.vtv1.SupplierContract} */ (
    jspb.Message.getWrapperField(this, proto.vtv1.SupplierContract, 1));
};


/**
 * @param {?proto.vtv1.SupplierContract|undefined} value
 * @return {!proto.vtv1.AddSupplierContractRequest} returns this
*/
proto.vtv1.AddSupplierContractRequest.prototype.setSupplierContract = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.AddSupplierContractRequest} returns this
 */
proto.vtv1.AddSupplierContractRequest.prototype.clearSupplierContract = function() {
  return this.setSupplierContract(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.AddSupplierContractRequest.prototype.hasSupplierContract = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.EditSupplierContractRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.EditSupplierContractRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.EditSupplierContractRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.EditSupplierContractRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    supplierContract: (f = msg.getSupplierContract()) && proto.vtv1.SupplierContract.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.EditSupplierContractRequest}
 */
proto.vtv1.EditSupplierContractRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.EditSupplierContractRequest;
  return proto.vtv1.EditSupplierContractRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.EditSupplierContractRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.EditSupplierContractRequest}
 */
proto.vtv1.EditSupplierContractRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.vtv1.SupplierContract;
      reader.readMessage(value,proto.vtv1.SupplierContract.deserializeBinaryFromReader);
      msg.setSupplierContract(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.EditSupplierContractRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.EditSupplierContractRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.EditSupplierContractRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.EditSupplierContractRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSupplierContract();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.vtv1.SupplierContract.serializeBinaryToWriter
    );
  }
};


/**
 * optional SupplierContract supplier_contract = 1;
 * @return {?proto.vtv1.SupplierContract}
 */
proto.vtv1.EditSupplierContractRequest.prototype.getSupplierContract = function() {
  return /** @type{?proto.vtv1.SupplierContract} */ (
    jspb.Message.getWrapperField(this, proto.vtv1.SupplierContract, 1));
};


/**
 * @param {?proto.vtv1.SupplierContract|undefined} value
 * @return {!proto.vtv1.EditSupplierContractRequest} returns this
*/
proto.vtv1.EditSupplierContractRequest.prototype.setSupplierContract = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.EditSupplierContractRequest} returns this
 */
proto.vtv1.EditSupplierContractRequest.prototype.clearSupplierContract = function() {
  return this.setSupplierContract(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.EditSupplierContractRequest.prototype.hasSupplierContract = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.SupplierContract.repeatedFields_ = [4,7,8];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.SupplierContract.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.SupplierContract.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.SupplierContract} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.SupplierContract.toObject = function(includeInstance, msg) {
  var f, obj = {
    accountId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    lineItemsList: jspb.Message.toObjectList(msg.getLineItemsList(),
    proto.vtv1.SupplierContractLineItem.toObject, includeInstance),
    accountName: jspb.Message.getFieldWithDefault(msg, 5, ""),
    virtual: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    allocationsList: jspb.Message.toObjectList(msg.getAllocationsList(),
    proto.vtv1.SupplierContractAllocation.toObject, includeInstance),
    sitesList: jspb.Message.toObjectList(msg.getSitesList(),
    proto.vtv1.SupplierContractSite.toObject, includeInstance),
    supplierNumber: (f = msg.getSupplierNumber()) && proto_vorto_types_types_pb.NullableString.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.SupplierContract}
 */
proto.vtv1.SupplierContract.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.SupplierContract;
  return proto.vtv1.SupplierContract.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.SupplierContract} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.SupplierContract}
 */
proto.vtv1.SupplierContract.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountId(value);
      break;
    case 4:
      var value = new proto.vtv1.SupplierContractLineItem;
      reader.readMessage(value,proto.vtv1.SupplierContractLineItem.deserializeBinaryFromReader);
      msg.addLineItems(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountName(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setVirtual(value);
      break;
    case 7:
      var value = new proto.vtv1.SupplierContractAllocation;
      reader.readMessage(value,proto.vtv1.SupplierContractAllocation.deserializeBinaryFromReader);
      msg.addAllocations(value);
      break;
    case 8:
      var value = new proto.vtv1.SupplierContractSite;
      reader.readMessage(value,proto.vtv1.SupplierContractSite.deserializeBinaryFromReader);
      msg.addSites(value);
      break;
    case 9:
      var value = new proto_vorto_types_types_pb.NullableString;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableString.deserializeBinaryFromReader);
      msg.setSupplierNumber(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.SupplierContract.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.SupplierContract.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.SupplierContract} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.SupplierContract.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccountId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLineItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.vtv1.SupplierContractLineItem.serializeBinaryToWriter
    );
  }
  f = message.getAccountName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getVirtual();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getAllocationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto.vtv1.SupplierContractAllocation.serializeBinaryToWriter
    );
  }
  f = message.getSitesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      8,
      f,
      proto.vtv1.SupplierContractSite.serializeBinaryToWriter
    );
  }
  f = message.getSupplierNumber();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto_vorto_types_types_pb.NullableString.serializeBinaryToWriter
    );
  }
};


/**
 * optional string account_id = 1;
 * @return {string}
 */
proto.vtv1.SupplierContract.prototype.getAccountId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.SupplierContract} returns this
 */
proto.vtv1.SupplierContract.prototype.setAccountId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated SupplierContractLineItem line_items = 4;
 * @return {!Array<!proto.vtv1.SupplierContractLineItem>}
 */
proto.vtv1.SupplierContract.prototype.getLineItemsList = function() {
  return /** @type{!Array<!proto.vtv1.SupplierContractLineItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vtv1.SupplierContractLineItem, 4));
};


/**
 * @param {!Array<!proto.vtv1.SupplierContractLineItem>} value
 * @return {!proto.vtv1.SupplierContract} returns this
*/
proto.vtv1.SupplierContract.prototype.setLineItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.vtv1.SupplierContractLineItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.SupplierContractLineItem}
 */
proto.vtv1.SupplierContract.prototype.addLineItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.vtv1.SupplierContractLineItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.SupplierContract} returns this
 */
proto.vtv1.SupplierContract.prototype.clearLineItemsList = function() {
  return this.setLineItemsList([]);
};


/**
 * optional string account_name = 5;
 * @return {string}
 */
proto.vtv1.SupplierContract.prototype.getAccountName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.SupplierContract} returns this
 */
proto.vtv1.SupplierContract.prototype.setAccountName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional bool virtual = 6;
 * @return {boolean}
 */
proto.vtv1.SupplierContract.prototype.getVirtual = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vtv1.SupplierContract} returns this
 */
proto.vtv1.SupplierContract.prototype.setVirtual = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * repeated SupplierContractAllocation allocations = 7;
 * @return {!Array<!proto.vtv1.SupplierContractAllocation>}
 */
proto.vtv1.SupplierContract.prototype.getAllocationsList = function() {
  return /** @type{!Array<!proto.vtv1.SupplierContractAllocation>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vtv1.SupplierContractAllocation, 7));
};


/**
 * @param {!Array<!proto.vtv1.SupplierContractAllocation>} value
 * @return {!proto.vtv1.SupplierContract} returns this
*/
proto.vtv1.SupplierContract.prototype.setAllocationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.vtv1.SupplierContractAllocation=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.SupplierContractAllocation}
 */
proto.vtv1.SupplierContract.prototype.addAllocations = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.vtv1.SupplierContractAllocation, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.SupplierContract} returns this
 */
proto.vtv1.SupplierContract.prototype.clearAllocationsList = function() {
  return this.setAllocationsList([]);
};


/**
 * repeated SupplierContractSite sites = 8;
 * @return {!Array<!proto.vtv1.SupplierContractSite>}
 */
proto.vtv1.SupplierContract.prototype.getSitesList = function() {
  return /** @type{!Array<!proto.vtv1.SupplierContractSite>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vtv1.SupplierContractSite, 8));
};


/**
 * @param {!Array<!proto.vtv1.SupplierContractSite>} value
 * @return {!proto.vtv1.SupplierContract} returns this
*/
proto.vtv1.SupplierContract.prototype.setSitesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 8, value);
};


/**
 * @param {!proto.vtv1.SupplierContractSite=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.SupplierContractSite}
 */
proto.vtv1.SupplierContract.prototype.addSites = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 8, opt_value, proto.vtv1.SupplierContractSite, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.SupplierContract} returns this
 */
proto.vtv1.SupplierContract.prototype.clearSitesList = function() {
  return this.setSitesList([]);
};


/**
 * optional NullableString supplier_number = 9;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.SupplierContract.prototype.getSupplierNumber = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableString, 9));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.SupplierContract} returns this
*/
proto.vtv1.SupplierContract.prototype.setSupplierNumber = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.SupplierContract} returns this
 */
proto.vtv1.SupplierContract.prototype.clearSupplierNumber = function() {
  return this.setSupplierNumber(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.SupplierContract.prototype.hasSupplierNumber = function() {
  return jspb.Message.getField(this, 9) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.SupplierContractSite.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.SupplierContractSite.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.SupplierContractSite} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.SupplierContractSite.toObject = function(includeInstance, msg) {
  var f, obj = {
    siteName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    siteAddress: jspb.Message.getFieldWithDefault(msg, 2, ""),
    siteCity: jspb.Message.getFieldWithDefault(msg, 3, ""),
    siteState: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.SupplierContractSite}
 */
proto.vtv1.SupplierContractSite.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.SupplierContractSite;
  return proto.vtv1.SupplierContractSite.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.SupplierContractSite} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.SupplierContractSite}
 */
proto.vtv1.SupplierContractSite.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSiteName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSiteAddress(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSiteCity(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setSiteState(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.SupplierContractSite.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.SupplierContractSite.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.SupplierContractSite} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.SupplierContractSite.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSiteName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSiteAddress();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSiteCity();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSiteState();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string site_name = 1;
 * @return {string}
 */
proto.vtv1.SupplierContractSite.prototype.getSiteName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.SupplierContractSite} returns this
 */
proto.vtv1.SupplierContractSite.prototype.setSiteName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string site_address = 2;
 * @return {string}
 */
proto.vtv1.SupplierContractSite.prototype.getSiteAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.SupplierContractSite} returns this
 */
proto.vtv1.SupplierContractSite.prototype.setSiteAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string site_city = 3;
 * @return {string}
 */
proto.vtv1.SupplierContractSite.prototype.getSiteCity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.SupplierContractSite} returns this
 */
proto.vtv1.SupplierContractSite.prototype.setSiteCity = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string site_state = 4;
 * @return {string}
 */
proto.vtv1.SupplierContractSite.prototype.getSiteState = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.SupplierContractSite} returns this
 */
proto.vtv1.SupplierContractSite.prototype.setSiteState = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.SupplierContractLineItem.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.SupplierContractLineItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.SupplierContractLineItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.SupplierContractLineItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    supplierProductId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    supplierProductName: jspb.Message.getFieldWithDefault(msg, 4, ""),
    consumerProductId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    consumerProductName: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.SupplierContractLineItem}
 */
proto.vtv1.SupplierContractLineItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.SupplierContractLineItem;
  return proto.vtv1.SupplierContractLineItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.SupplierContractLineItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.SupplierContractLineItem}
 */
proto.vtv1.SupplierContractLineItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSupplierProductId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setSupplierProductName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setConsumerProductId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setConsumerProductName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.SupplierContractLineItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.SupplierContractLineItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.SupplierContractLineItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.SupplierContractLineItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSupplierProductId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSupplierProductName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getConsumerProductId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getConsumerProductName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string supplier_product_id = 1;
 * @return {string}
 */
proto.vtv1.SupplierContractLineItem.prototype.getSupplierProductId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.SupplierContractLineItem} returns this
 */
proto.vtv1.SupplierContractLineItem.prototype.setSupplierProductId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string supplier_product_name = 4;
 * @return {string}
 */
proto.vtv1.SupplierContractLineItem.prototype.getSupplierProductName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.SupplierContractLineItem} returns this
 */
proto.vtv1.SupplierContractLineItem.prototype.setSupplierProductName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string consumer_product_id = 2;
 * @return {string}
 */
proto.vtv1.SupplierContractLineItem.prototype.getConsumerProductId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.SupplierContractLineItem} returns this
 */
proto.vtv1.SupplierContractLineItem.prototype.setConsumerProductId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string consumer_product_name = 5;
 * @return {string}
 */
proto.vtv1.SupplierContractLineItem.prototype.getConsumerProductName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.SupplierContractLineItem} returns this
 */
proto.vtv1.SupplierContractLineItem.prototype.setConsumerProductName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.SupplierContractAllocation.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.SupplierContractAllocation.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.SupplierContractAllocation} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.SupplierContractAllocation.toObject = function(includeInstance, msg) {
  var f, obj = {
    productId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    regionId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    allocation: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.SupplierContractAllocation}
 */
proto.vtv1.SupplierContractAllocation.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.SupplierContractAllocation;
  return proto.vtv1.SupplierContractAllocation.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.SupplierContractAllocation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.SupplierContractAllocation}
 */
proto.vtv1.SupplierContractAllocation.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRegionId(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAllocation(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.SupplierContractAllocation.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.SupplierContractAllocation.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.SupplierContractAllocation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.SupplierContractAllocation.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProductId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRegionId();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getAllocation();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
};


/**
 * optional string product_id = 1;
 * @return {string}
 */
proto.vtv1.SupplierContractAllocation.prototype.getProductId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.SupplierContractAllocation} returns this
 */
proto.vtv1.SupplierContractAllocation.prototype.setProductId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 region_id = 3;
 * @return {number}
 */
proto.vtv1.SupplierContractAllocation.prototype.getRegionId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.SupplierContractAllocation} returns this
 */
proto.vtv1.SupplierContractAllocation.prototype.setRegionId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional double allocation = 4;
 * @return {number}
 */
proto.vtv1.SupplierContractAllocation.prototype.getAllocation = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.SupplierContractAllocation} returns this
 */
proto.vtv1.SupplierContractAllocation.prototype.setAllocation = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.AddSupplierContractResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.AddSupplierContractResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.AddSupplierContractResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.AddSupplierContractResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.AddSupplierContractResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    suppliersList: jspb.Message.toObjectList(msg.getSuppliersList(),
    proto.vtv1.SupplierContract.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.AddSupplierContractResponse}
 */
proto.vtv1.AddSupplierContractResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.AddSupplierContractResponse;
  return proto.vtv1.AddSupplierContractResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.AddSupplierContractResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.AddSupplierContractResponse}
 */
proto.vtv1.AddSupplierContractResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.vtv1.SupplierContract;
      reader.readMessage(value,proto.vtv1.SupplierContract.deserializeBinaryFromReader);
      msg.addSuppliers(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.AddSupplierContractResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.AddSupplierContractResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.AddSupplierContractResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.AddSupplierContractResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuppliersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.vtv1.SupplierContract.serializeBinaryToWriter
    );
  }
};


/**
 * repeated SupplierContract suppliers = 1;
 * @return {!Array<!proto.vtv1.SupplierContract>}
 */
proto.vtv1.AddSupplierContractResponse.prototype.getSuppliersList = function() {
  return /** @type{!Array<!proto.vtv1.SupplierContract>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vtv1.SupplierContract, 1));
};


/**
 * @param {!Array<!proto.vtv1.SupplierContract>} value
 * @return {!proto.vtv1.AddSupplierContractResponse} returns this
*/
proto.vtv1.AddSupplierContractResponse.prototype.setSuppliersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.vtv1.SupplierContract=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.SupplierContract}
 */
proto.vtv1.AddSupplierContractResponse.prototype.addSuppliers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.vtv1.SupplierContract, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.AddSupplierContractResponse} returns this
 */
proto.vtv1.AddSupplierContractResponse.prototype.clearSuppliersList = function() {
  return this.setSuppliersList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.EditSupplierContractResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.EditSupplierContractResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.EditSupplierContractResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.EditSupplierContractResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.EditSupplierContractResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    suppliersList: jspb.Message.toObjectList(msg.getSuppliersList(),
    proto.vtv1.SupplierContract.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.EditSupplierContractResponse}
 */
proto.vtv1.EditSupplierContractResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.EditSupplierContractResponse;
  return proto.vtv1.EditSupplierContractResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.EditSupplierContractResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.EditSupplierContractResponse}
 */
proto.vtv1.EditSupplierContractResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.vtv1.SupplierContract;
      reader.readMessage(value,proto.vtv1.SupplierContract.deserializeBinaryFromReader);
      msg.addSuppliers(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.EditSupplierContractResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.EditSupplierContractResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.EditSupplierContractResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.EditSupplierContractResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuppliersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.vtv1.SupplierContract.serializeBinaryToWriter
    );
  }
};


/**
 * repeated SupplierContract suppliers = 1;
 * @return {!Array<!proto.vtv1.SupplierContract>}
 */
proto.vtv1.EditSupplierContractResponse.prototype.getSuppliersList = function() {
  return /** @type{!Array<!proto.vtv1.SupplierContract>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vtv1.SupplierContract, 1));
};


/**
 * @param {!Array<!proto.vtv1.SupplierContract>} value
 * @return {!proto.vtv1.EditSupplierContractResponse} returns this
*/
proto.vtv1.EditSupplierContractResponse.prototype.setSuppliersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.vtv1.SupplierContract=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.SupplierContract}
 */
proto.vtv1.EditSupplierContractResponse.prototype.addSuppliers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.vtv1.SupplierContract, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.EditSupplierContractResponse} returns this
 */
proto.vtv1.EditSupplierContractResponse.prototype.clearSuppliersList = function() {
  return this.setSuppliersList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.GetSupplierContractsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.GetSupplierContractsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.GetSupplierContractsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.GetSupplierContractsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.GetSupplierContractsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    suppliersList: jspb.Message.toObjectList(msg.getSuppliersList(),
    proto.vtv1.SupplierContract.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.GetSupplierContractsResponse}
 */
proto.vtv1.GetSupplierContractsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.GetSupplierContractsResponse;
  return proto.vtv1.GetSupplierContractsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.GetSupplierContractsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.GetSupplierContractsResponse}
 */
proto.vtv1.GetSupplierContractsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.vtv1.SupplierContract;
      reader.readMessage(value,proto.vtv1.SupplierContract.deserializeBinaryFromReader);
      msg.addSuppliers(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.GetSupplierContractsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.GetSupplierContractsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.GetSupplierContractsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.GetSupplierContractsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuppliersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.vtv1.SupplierContract.serializeBinaryToWriter
    );
  }
};


/**
 * repeated SupplierContract suppliers = 1;
 * @return {!Array<!proto.vtv1.SupplierContract>}
 */
proto.vtv1.GetSupplierContractsResponse.prototype.getSuppliersList = function() {
  return /** @type{!Array<!proto.vtv1.SupplierContract>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vtv1.SupplierContract, 1));
};


/**
 * @param {!Array<!proto.vtv1.SupplierContract>} value
 * @return {!proto.vtv1.GetSupplierContractsResponse} returns this
*/
proto.vtv1.GetSupplierContractsResponse.prototype.setSuppliersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.vtv1.SupplierContract=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.SupplierContract}
 */
proto.vtv1.GetSupplierContractsResponse.prototype.addSuppliers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.vtv1.SupplierContract, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.GetSupplierContractsResponse} returns this
 */
proto.vtv1.GetSupplierContractsResponse.prototype.clearSuppliersList = function() {
  return this.setSuppliersList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.RemoveSupplierContractRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.RemoveSupplierContractRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.RemoveSupplierContractRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.RemoveSupplierContractRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    supplierId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.RemoveSupplierContractRequest}
 */
proto.vtv1.RemoveSupplierContractRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.RemoveSupplierContractRequest;
  return proto.vtv1.RemoveSupplierContractRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.RemoveSupplierContractRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.RemoveSupplierContractRequest}
 */
proto.vtv1.RemoveSupplierContractRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSupplierId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.RemoveSupplierContractRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.RemoveSupplierContractRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.RemoveSupplierContractRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.RemoveSupplierContractRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSupplierId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string supplier_id = 1;
 * @return {string}
 */
proto.vtv1.RemoveSupplierContractRequest.prototype.getSupplierId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.RemoveSupplierContractRequest} returns this
 */
proto.vtv1.RemoveSupplierContractRequest.prototype.setSupplierId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.RemoveSupplierContractResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.RemoveSupplierContractResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.RemoveSupplierContractResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.RemoveSupplierContractResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.RemoveSupplierContractResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    suppliersList: jspb.Message.toObjectList(msg.getSuppliersList(),
    proto.vtv1.SupplierContract.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.RemoveSupplierContractResponse}
 */
proto.vtv1.RemoveSupplierContractResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.RemoveSupplierContractResponse;
  return proto.vtv1.RemoveSupplierContractResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.RemoveSupplierContractResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.RemoveSupplierContractResponse}
 */
proto.vtv1.RemoveSupplierContractResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.vtv1.SupplierContract;
      reader.readMessage(value,proto.vtv1.SupplierContract.deserializeBinaryFromReader);
      msg.addSuppliers(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.RemoveSupplierContractResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.RemoveSupplierContractResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.RemoveSupplierContractResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.RemoveSupplierContractResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuppliersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.vtv1.SupplierContract.serializeBinaryToWriter
    );
  }
};


/**
 * repeated SupplierContract suppliers = 1;
 * @return {!Array<!proto.vtv1.SupplierContract>}
 */
proto.vtv1.RemoveSupplierContractResponse.prototype.getSuppliersList = function() {
  return /** @type{!Array<!proto.vtv1.SupplierContract>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vtv1.SupplierContract, 1));
};


/**
 * @param {!Array<!proto.vtv1.SupplierContract>} value
 * @return {!proto.vtv1.RemoveSupplierContractResponse} returns this
*/
proto.vtv1.RemoveSupplierContractResponse.prototype.setSuppliersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.vtv1.SupplierContract=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.SupplierContract}
 */
proto.vtv1.RemoveSupplierContractResponse.prototype.addSuppliers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.vtv1.SupplierContract, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.RemoveSupplierContractResponse} returns this
 */
proto.vtv1.RemoveSupplierContractResponse.prototype.clearSuppliersList = function() {
  return this.setSuppliersList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.AddSiteGroupRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.AddSiteGroupRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.AddSiteGroupRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.AddSiteGroupRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.AddSiteGroupRequest}
 */
proto.vtv1.AddSiteGroupRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.AddSiteGroupRequest;
  return proto.vtv1.AddSiteGroupRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.AddSiteGroupRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.AddSiteGroupRequest}
 */
proto.vtv1.AddSiteGroupRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.AddSiteGroupRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.AddSiteGroupRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.AddSiteGroupRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.AddSiteGroupRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.vtv1.AddSiteGroupRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.AddSiteGroupRequest} returns this
 */
proto.vtv1.AddSiteGroupRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.AddSiteGroupResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.AddSiteGroupResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.AddSiteGroupResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.AddSiteGroupResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.AddSiteGroupResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    siteGroupsList: jspb.Message.toObjectList(msg.getSiteGroupsList(),
    proto_vorto_types_types_pb.SiteGroup.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.AddSiteGroupResponse}
 */
proto.vtv1.AddSiteGroupResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.AddSiteGroupResponse;
  return proto.vtv1.AddSiteGroupResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.AddSiteGroupResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.AddSiteGroupResponse}
 */
proto.vtv1.AddSiteGroupResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_vorto_types_types_pb.SiteGroup;
      reader.readMessage(value,proto_vorto_types_types_pb.SiteGroup.deserializeBinaryFromReader);
      msg.addSiteGroups(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.AddSiteGroupResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.AddSiteGroupResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.AddSiteGroupResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.AddSiteGroupResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSiteGroupsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto_vorto_types_types_pb.SiteGroup.serializeBinaryToWriter
    );
  }
};


/**
 * repeated SiteGroup site_groups = 1;
 * @return {!Array<!proto.vtv1.SiteGroup>}
 */
proto.vtv1.AddSiteGroupResponse.prototype.getSiteGroupsList = function() {
  return /** @type{!Array<!proto.vtv1.SiteGroup>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_vorto_types_types_pb.SiteGroup, 1));
};


/**
 * @param {!Array<!proto.vtv1.SiteGroup>} value
 * @return {!proto.vtv1.AddSiteGroupResponse} returns this
*/
proto.vtv1.AddSiteGroupResponse.prototype.setSiteGroupsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.vtv1.SiteGroup=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.SiteGroup}
 */
proto.vtv1.AddSiteGroupResponse.prototype.addSiteGroups = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.vtv1.SiteGroup, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.AddSiteGroupResponse} returns this
 */
proto.vtv1.AddSiteGroupResponse.prototype.clearSiteGroupsList = function() {
  return this.setSiteGroupsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.RemoveSiteGroupRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.RemoveSiteGroupRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.RemoveSiteGroupRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.RemoveSiteGroupRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    siteGroupId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.RemoveSiteGroupRequest}
 */
proto.vtv1.RemoveSiteGroupRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.RemoveSiteGroupRequest;
  return proto.vtv1.RemoveSiteGroupRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.RemoveSiteGroupRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.RemoveSiteGroupRequest}
 */
proto.vtv1.RemoveSiteGroupRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSiteGroupId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.RemoveSiteGroupRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.RemoveSiteGroupRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.RemoveSiteGroupRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.RemoveSiteGroupRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSiteGroupId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 site_group_id = 1;
 * @return {number}
 */
proto.vtv1.RemoveSiteGroupRequest.prototype.getSiteGroupId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.RemoveSiteGroupRequest} returns this
 */
proto.vtv1.RemoveSiteGroupRequest.prototype.setSiteGroupId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.RemoveSiteGroupResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.RemoveSiteGroupResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.RemoveSiteGroupResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.RemoveSiteGroupResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.RemoveSiteGroupResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    siteGroupsList: jspb.Message.toObjectList(msg.getSiteGroupsList(),
    proto_vorto_types_types_pb.SiteGroup.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.RemoveSiteGroupResponse}
 */
proto.vtv1.RemoveSiteGroupResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.RemoveSiteGroupResponse;
  return proto.vtv1.RemoveSiteGroupResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.RemoveSiteGroupResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.RemoveSiteGroupResponse}
 */
proto.vtv1.RemoveSiteGroupResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_vorto_types_types_pb.SiteGroup;
      reader.readMessage(value,proto_vorto_types_types_pb.SiteGroup.deserializeBinaryFromReader);
      msg.addSiteGroups(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.RemoveSiteGroupResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.RemoveSiteGroupResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.RemoveSiteGroupResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.RemoveSiteGroupResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSiteGroupsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto_vorto_types_types_pb.SiteGroup.serializeBinaryToWriter
    );
  }
};


/**
 * repeated SiteGroup site_groups = 1;
 * @return {!Array<!proto.vtv1.SiteGroup>}
 */
proto.vtv1.RemoveSiteGroupResponse.prototype.getSiteGroupsList = function() {
  return /** @type{!Array<!proto.vtv1.SiteGroup>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_vorto_types_types_pb.SiteGroup, 1));
};


/**
 * @param {!Array<!proto.vtv1.SiteGroup>} value
 * @return {!proto.vtv1.RemoveSiteGroupResponse} returns this
*/
proto.vtv1.RemoveSiteGroupResponse.prototype.setSiteGroupsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.vtv1.SiteGroup=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.SiteGroup}
 */
proto.vtv1.RemoveSiteGroupResponse.prototype.addSiteGroups = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.vtv1.SiteGroup, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.RemoveSiteGroupResponse} returns this
 */
proto.vtv1.RemoveSiteGroupResponse.prototype.clearSiteGroupsList = function() {
  return this.setSiteGroupsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.ListSiteGroupsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.ListSiteGroupsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.ListSiteGroupsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.ListSiteGroupsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.ListSiteGroupsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    siteGroupsList: jspb.Message.toObjectList(msg.getSiteGroupsList(),
    proto_vorto_types_types_pb.SiteGroup.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.ListSiteGroupsResponse}
 */
proto.vtv1.ListSiteGroupsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.ListSiteGroupsResponse;
  return proto.vtv1.ListSiteGroupsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.ListSiteGroupsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.ListSiteGroupsResponse}
 */
proto.vtv1.ListSiteGroupsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_vorto_types_types_pb.SiteGroup;
      reader.readMessage(value,proto_vorto_types_types_pb.SiteGroup.deserializeBinaryFromReader);
      msg.addSiteGroups(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.ListSiteGroupsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.ListSiteGroupsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.ListSiteGroupsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.ListSiteGroupsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSiteGroupsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto_vorto_types_types_pb.SiteGroup.serializeBinaryToWriter
    );
  }
};


/**
 * repeated SiteGroup site_groups = 1;
 * @return {!Array<!proto.vtv1.SiteGroup>}
 */
proto.vtv1.ListSiteGroupsResponse.prototype.getSiteGroupsList = function() {
  return /** @type{!Array<!proto.vtv1.SiteGroup>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_vorto_types_types_pb.SiteGroup, 1));
};


/**
 * @param {!Array<!proto.vtv1.SiteGroup>} value
 * @return {!proto.vtv1.ListSiteGroupsResponse} returns this
*/
proto.vtv1.ListSiteGroupsResponse.prototype.setSiteGroupsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.vtv1.SiteGroup=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.SiteGroup}
 */
proto.vtv1.ListSiteGroupsResponse.prototype.addSiteGroups = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.vtv1.SiteGroup, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.ListSiteGroupsResponse} returns this
 */
proto.vtv1.ListSiteGroupsResponse.prototype.clearSiteGroupsList = function() {
  return this.setSiteGroupsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.NodeCreateSiteRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.NodeCreateSiteRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.NodeCreateSiteRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.NodeCreateSiteRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    longitude: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    latitude: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    radius: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    siteGroupId: (f = msg.getSiteGroupId()) && proto_vorto_types_types_pb.NullableInt64.toObject(includeInstance, f),
    numberOfBays: jspb.Message.getFieldWithDefault(msg, 6, 0),
    defaultDeliveryWindowMinutes: (f = msg.getDefaultDeliveryWindowMinutes()) && proto_vorto_types_types_pb.NullableInt64.toObject(includeInstance, f),
    address: (f = msg.getAddress()) && proto_vorto_types_types_pb.NullableString.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.NodeCreateSiteRequest}
 */
proto.vtv1.NodeCreateSiteRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.NodeCreateSiteRequest;
  return proto.vtv1.NodeCreateSiteRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.NodeCreateSiteRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.NodeCreateSiteRequest}
 */
proto.vtv1.NodeCreateSiteRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLongitude(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLatitude(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setRadius(value);
      break;
    case 5:
      var value = new proto_vorto_types_types_pb.NullableInt64;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableInt64.deserializeBinaryFromReader);
      msg.setSiteGroupId(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setNumberOfBays(value);
      break;
    case 7:
      var value = new proto_vorto_types_types_pb.NullableInt64;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableInt64.deserializeBinaryFromReader);
      msg.setDefaultDeliveryWindowMinutes(value);
      break;
    case 8:
      var value = new proto_vorto_types_types_pb.NullableString;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableString.deserializeBinaryFromReader);
      msg.setAddress(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.NodeCreateSiteRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.NodeCreateSiteRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.NodeCreateSiteRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.NodeCreateSiteRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLongitude();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getLatitude();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getRadius();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getSiteGroupId();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto_vorto_types_types_pb.NullableInt64.serializeBinaryToWriter
    );
  }
  f = message.getNumberOfBays();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getDefaultDeliveryWindowMinutes();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto_vorto_types_types_pb.NullableInt64.serializeBinaryToWriter
    );
  }
  f = message.getAddress();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto_vorto_types_types_pb.NullableString.serializeBinaryToWriter
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.vtv1.NodeCreateSiteRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.NodeCreateSiteRequest} returns this
 */
proto.vtv1.NodeCreateSiteRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional double longitude = 2;
 * @return {number}
 */
proto.vtv1.NodeCreateSiteRequest.prototype.getLongitude = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.NodeCreateSiteRequest} returns this
 */
proto.vtv1.NodeCreateSiteRequest.prototype.setLongitude = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double latitude = 3;
 * @return {number}
 */
proto.vtv1.NodeCreateSiteRequest.prototype.getLatitude = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.NodeCreateSiteRequest} returns this
 */
proto.vtv1.NodeCreateSiteRequest.prototype.setLatitude = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional double radius = 4;
 * @return {number}
 */
proto.vtv1.NodeCreateSiteRequest.prototype.getRadius = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.NodeCreateSiteRequest} returns this
 */
proto.vtv1.NodeCreateSiteRequest.prototype.setRadius = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional NullableInt64 site_group_id = 5;
 * @return {?proto.vtv1.NullableInt64}
 */
proto.vtv1.NodeCreateSiteRequest.prototype.getSiteGroupId = function() {
  return /** @type{?proto.vtv1.NullableInt64} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableInt64, 5));
};


/**
 * @param {?proto.vtv1.NullableInt64|undefined} value
 * @return {!proto.vtv1.NodeCreateSiteRequest} returns this
*/
proto.vtv1.NodeCreateSiteRequest.prototype.setSiteGroupId = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.NodeCreateSiteRequest} returns this
 */
proto.vtv1.NodeCreateSiteRequest.prototype.clearSiteGroupId = function() {
  return this.setSiteGroupId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.NodeCreateSiteRequest.prototype.hasSiteGroupId = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional int64 number_of_bays = 6;
 * @return {number}
 */
proto.vtv1.NodeCreateSiteRequest.prototype.getNumberOfBays = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.NodeCreateSiteRequest} returns this
 */
proto.vtv1.NodeCreateSiteRequest.prototype.setNumberOfBays = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional NullableInt64 default_delivery_window_minutes = 7;
 * @return {?proto.vtv1.NullableInt64}
 */
proto.vtv1.NodeCreateSiteRequest.prototype.getDefaultDeliveryWindowMinutes = function() {
  return /** @type{?proto.vtv1.NullableInt64} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableInt64, 7));
};


/**
 * @param {?proto.vtv1.NullableInt64|undefined} value
 * @return {!proto.vtv1.NodeCreateSiteRequest} returns this
*/
proto.vtv1.NodeCreateSiteRequest.prototype.setDefaultDeliveryWindowMinutes = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.NodeCreateSiteRequest} returns this
 */
proto.vtv1.NodeCreateSiteRequest.prototype.clearDefaultDeliveryWindowMinutes = function() {
  return this.setDefaultDeliveryWindowMinutes(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.NodeCreateSiteRequest.prototype.hasDefaultDeliveryWindowMinutes = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional NullableString address = 8;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.NodeCreateSiteRequest.prototype.getAddress = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableString, 8));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.NodeCreateSiteRequest} returns this
*/
proto.vtv1.NodeCreateSiteRequest.prototype.setAddress = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.NodeCreateSiteRequest} returns this
 */
proto.vtv1.NodeCreateSiteRequest.prototype.clearAddress = function() {
  return this.setAddress(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.NodeCreateSiteRequest.prototype.hasAddress = function() {
  return jspb.Message.getField(this, 8) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.NodeCreateSiteResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.NodeCreateSiteResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.NodeCreateSiteResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.NodeCreateSiteResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    site: (f = msg.getSite()) && proto_vorto_types_types_pb.Site.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.NodeCreateSiteResponse}
 */
proto.vtv1.NodeCreateSiteResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.NodeCreateSiteResponse;
  return proto.vtv1.NodeCreateSiteResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.NodeCreateSiteResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.NodeCreateSiteResponse}
 */
proto.vtv1.NodeCreateSiteResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_vorto_types_types_pb.Site;
      reader.readMessage(value,proto_vorto_types_types_pb.Site.deserializeBinaryFromReader);
      msg.setSite(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.NodeCreateSiteResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.NodeCreateSiteResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.NodeCreateSiteResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.NodeCreateSiteResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSite();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_vorto_types_types_pb.Site.serializeBinaryToWriter
    );
  }
};


/**
 * optional Site site = 1;
 * @return {?proto.vtv1.Site}
 */
proto.vtv1.NodeCreateSiteResponse.prototype.getSite = function() {
  return /** @type{?proto.vtv1.Site} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.Site, 1));
};


/**
 * @param {?proto.vtv1.Site|undefined} value
 * @return {!proto.vtv1.NodeCreateSiteResponse} returns this
*/
proto.vtv1.NodeCreateSiteResponse.prototype.setSite = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.NodeCreateSiteResponse} returns this
 */
proto.vtv1.NodeCreateSiteResponse.prototype.clearSite = function() {
  return this.setSite(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.NodeCreateSiteResponse.prototype.hasSite = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.NodeActiveSiteResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.NodeActiveSiteResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.NodeActiveSiteResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.NodeActiveSiteResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.NodeActiveSiteResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    sitesList: jspb.Message.toObjectList(msg.getSitesList(),
    proto_vorto_types_types_pb.ActiveSite.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.NodeActiveSiteResponse}
 */
proto.vtv1.NodeActiveSiteResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.NodeActiveSiteResponse;
  return proto.vtv1.NodeActiveSiteResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.NodeActiveSiteResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.NodeActiveSiteResponse}
 */
proto.vtv1.NodeActiveSiteResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_vorto_types_types_pb.ActiveSite;
      reader.readMessage(value,proto_vorto_types_types_pb.ActiveSite.deserializeBinaryFromReader);
      msg.addSites(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.NodeActiveSiteResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.NodeActiveSiteResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.NodeActiveSiteResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.NodeActiveSiteResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSitesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto_vorto_types_types_pb.ActiveSite.serializeBinaryToWriter
    );
  }
};


/**
 * repeated ActiveSite sites = 1;
 * @return {!Array<!proto.vtv1.ActiveSite>}
 */
proto.vtv1.NodeActiveSiteResponse.prototype.getSitesList = function() {
  return /** @type{!Array<!proto.vtv1.ActiveSite>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_vorto_types_types_pb.ActiveSite, 1));
};


/**
 * @param {!Array<!proto.vtv1.ActiveSite>} value
 * @return {!proto.vtv1.NodeActiveSiteResponse} returns this
*/
proto.vtv1.NodeActiveSiteResponse.prototype.setSitesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.vtv1.ActiveSite=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.ActiveSite}
 */
proto.vtv1.NodeActiveSiteResponse.prototype.addSites = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.vtv1.ActiveSite, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.NodeActiveSiteResponse} returns this
 */
proto.vtv1.NodeActiveSiteResponse.prototype.clearSitesList = function() {
  return this.setSitesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.NodeEditSiteRequestInventoryLineItem.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.NodeEditSiteRequestInventoryLineItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.NodeEditSiteRequestInventoryLineItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.NodeEditSiteRequestInventoryLineItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    inventoryId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    salesOrderLineItemId: (f = msg.getSalesOrderLineItemId()) && proto_vorto_types_types_pb.NullableString.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.NodeEditSiteRequestInventoryLineItem}
 */
proto.vtv1.NodeEditSiteRequestInventoryLineItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.NodeEditSiteRequestInventoryLineItem;
  return proto.vtv1.NodeEditSiteRequestInventoryLineItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.NodeEditSiteRequestInventoryLineItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.NodeEditSiteRequestInventoryLineItem}
 */
proto.vtv1.NodeEditSiteRequestInventoryLineItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setInventoryId(value);
      break;
    case 2:
      var value = new proto_vorto_types_types_pb.NullableString;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableString.deserializeBinaryFromReader);
      msg.setSalesOrderLineItemId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.NodeEditSiteRequestInventoryLineItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.NodeEditSiteRequestInventoryLineItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.NodeEditSiteRequestInventoryLineItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.NodeEditSiteRequestInventoryLineItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInventoryId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSalesOrderLineItemId();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_vorto_types_types_pb.NullableString.serializeBinaryToWriter
    );
  }
};


/**
 * optional string inventory_id = 1;
 * @return {string}
 */
proto.vtv1.NodeEditSiteRequestInventoryLineItem.prototype.getInventoryId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.NodeEditSiteRequestInventoryLineItem} returns this
 */
proto.vtv1.NodeEditSiteRequestInventoryLineItem.prototype.setInventoryId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional NullableString sales_order_line_item_id = 2;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.NodeEditSiteRequestInventoryLineItem.prototype.getSalesOrderLineItemId = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableString, 2));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.NodeEditSiteRequestInventoryLineItem} returns this
*/
proto.vtv1.NodeEditSiteRequestInventoryLineItem.prototype.setSalesOrderLineItemId = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.NodeEditSiteRequestInventoryLineItem} returns this
 */
proto.vtv1.NodeEditSiteRequestInventoryLineItem.prototype.clearSalesOrderLineItemId = function() {
  return this.setSalesOrderLineItemId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.NodeEditSiteRequestInventoryLineItem.prototype.hasSalesOrderLineItemId = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.NodeEditSiteRequest.repeatedFields_ = [19,20,23];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.NodeEditSiteRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.NodeEditSiteRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.NodeEditSiteRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.NodeEditSiteRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: (f = msg.getName()) && proto_vorto_types_types_pb.NullableString.toObject(includeInstance, f),
    latitude: (f = msg.getLatitude()) && proto_vorto_types_types_pb.NullableDouble.toObject(includeInstance, f),
    longitude: (f = msg.getLongitude()) && proto_vorto_types_types_pb.NullableDouble.toObject(includeInstance, f),
    radius: (f = msg.getRadius()) && proto_vorto_types_types_pb.NullableDouble.toObject(includeInstance, f),
    updateSiteGroupId: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    siteGroupId: (f = msg.getSiteGroupId()) && proto_vorto_types_types_pb.NullableInt64.toObject(includeInstance, f),
    numberOfBays: (f = msg.getNumberOfBays()) && proto_vorto_types_types_pb.NullableInt64.toObject(includeInstance, f),
    defaultDeliveryWindowMinutes: (f = msg.getDefaultDeliveryWindowMinutes()) && proto_vorto_types_types_pb.NullableInt64.toObject(includeInstance, f),
    address: (f = msg.getAddress()) && proto_vorto_types_types_pb.NullableString.toObject(includeInstance, f),
    desiredMaxSupplierResponseTimeHours: (f = msg.getDesiredMaxSupplierResponseTimeHours()) && proto_vorto_types_types_pb.NullableInt64.toObject(includeInstance, f),
    averageDwellTimeCalculationWindow: (f = msg.getAverageDwellTimeCalculationWindow()) && proto_vorto_types_types_pb.NullableInt64.toObject(includeInstance, f),
    directions: (f = msg.getDirections()) && proto_vorto_types_types_pb.NullableString.toObject(includeInstance, f),
    loadingInstructions: (f = msg.getLoadingInstructions()) && proto_vorto_types_types_pb.NullableString.toObject(includeInstance, f),
    salesOrder: (f = msg.getSalesOrder()) && proto_vorto_types_types_pb.NullableString.toObject(includeInstance, f),
    facilityCode: (f = msg.getFacilityCode()) && proto_vorto_types_types_pb.NullableString.toObject(includeInstance, f),
    districtId: (f = msg.getDistrictId()) && proto_vorto_types_types_pb.NullableString.toObject(includeInstance, f),
    taxPercent: (f = msg.getTaxPercent()) && proto_vorto_types_types_pb.NullableDouble.toObject(includeInstance, f),
    fulfillmentSitesList: (f = jspb.Message.getRepeatedField(msg, 19)) == null ? undefined : f,
    siteUserIdsList: (f = jspb.Message.getRepeatedField(msg, 20)) == null ? undefined : f,
    costCenterId: (f = msg.getCostCenterId()) && proto_vorto_types_types_pb.NullableString.toObject(includeInstance, f),
    salesOrderId: (f = msg.getSalesOrderId()) && proto_vorto_types_types_pb.NullableString.toObject(includeInstance, f),
    inventorySalesOrderLineItemsList: jspb.Message.toObjectList(msg.getInventorySalesOrderLineItemsList(),
    proto.vtv1.NodeEditSiteRequestInventoryLineItem.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.NodeEditSiteRequest}
 */
proto.vtv1.NodeEditSiteRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.NodeEditSiteRequest;
  return proto.vtv1.NodeEditSiteRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.NodeEditSiteRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.NodeEditSiteRequest}
 */
proto.vtv1.NodeEditSiteRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new proto_vorto_types_types_pb.NullableString;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableString.deserializeBinaryFromReader);
      msg.setName(value);
      break;
    case 3:
      var value = new proto_vorto_types_types_pb.NullableDouble;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableDouble.deserializeBinaryFromReader);
      msg.setLatitude(value);
      break;
    case 4:
      var value = new proto_vorto_types_types_pb.NullableDouble;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableDouble.deserializeBinaryFromReader);
      msg.setLongitude(value);
      break;
    case 5:
      var value = new proto_vorto_types_types_pb.NullableDouble;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableDouble.deserializeBinaryFromReader);
      msg.setRadius(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setUpdateSiteGroupId(value);
      break;
    case 7:
      var value = new proto_vorto_types_types_pb.NullableInt64;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableInt64.deserializeBinaryFromReader);
      msg.setSiteGroupId(value);
      break;
    case 8:
      var value = new proto_vorto_types_types_pb.NullableInt64;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableInt64.deserializeBinaryFromReader);
      msg.setNumberOfBays(value);
      break;
    case 9:
      var value = new proto_vorto_types_types_pb.NullableInt64;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableInt64.deserializeBinaryFromReader);
      msg.setDefaultDeliveryWindowMinutes(value);
      break;
    case 10:
      var value = new proto_vorto_types_types_pb.NullableString;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableString.deserializeBinaryFromReader);
      msg.setAddress(value);
      break;
    case 11:
      var value = new proto_vorto_types_types_pb.NullableInt64;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableInt64.deserializeBinaryFromReader);
      msg.setDesiredMaxSupplierResponseTimeHours(value);
      break;
    case 12:
      var value = new proto_vorto_types_types_pb.NullableInt64;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableInt64.deserializeBinaryFromReader);
      msg.setAverageDwellTimeCalculationWindow(value);
      break;
    case 13:
      var value = new proto_vorto_types_types_pb.NullableString;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableString.deserializeBinaryFromReader);
      msg.setDirections(value);
      break;
    case 14:
      var value = new proto_vorto_types_types_pb.NullableString;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableString.deserializeBinaryFromReader);
      msg.setLoadingInstructions(value);
      break;
    case 15:
      var value = new proto_vorto_types_types_pb.NullableString;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableString.deserializeBinaryFromReader);
      msg.setSalesOrder(value);
      break;
    case 16:
      var value = new proto_vorto_types_types_pb.NullableString;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableString.deserializeBinaryFromReader);
      msg.setFacilityCode(value);
      break;
    case 17:
      var value = new proto_vorto_types_types_pb.NullableString;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableString.deserializeBinaryFromReader);
      msg.setDistrictId(value);
      break;
    case 18:
      var value = new proto_vorto_types_types_pb.NullableDouble;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableDouble.deserializeBinaryFromReader);
      msg.setTaxPercent(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.addFulfillmentSites(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.addSiteUserIds(value);
      break;
    case 21:
      var value = new proto_vorto_types_types_pb.NullableString;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableString.deserializeBinaryFromReader);
      msg.setCostCenterId(value);
      break;
    case 22:
      var value = new proto_vorto_types_types_pb.NullableString;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableString.deserializeBinaryFromReader);
      msg.setSalesOrderId(value);
      break;
    case 23:
      var value = new proto.vtv1.NodeEditSiteRequestInventoryLineItem;
      reader.readMessage(value,proto.vtv1.NodeEditSiteRequestInventoryLineItem.deserializeBinaryFromReader);
      msg.addInventorySalesOrderLineItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.NodeEditSiteRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.NodeEditSiteRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.NodeEditSiteRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.NodeEditSiteRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_vorto_types_types_pb.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getLatitude();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto_vorto_types_types_pb.NullableDouble.serializeBinaryToWriter
    );
  }
  f = message.getLongitude();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto_vorto_types_types_pb.NullableDouble.serializeBinaryToWriter
    );
  }
  f = message.getRadius();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto_vorto_types_types_pb.NullableDouble.serializeBinaryToWriter
    );
  }
  f = message.getUpdateSiteGroupId();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getSiteGroupId();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto_vorto_types_types_pb.NullableInt64.serializeBinaryToWriter
    );
  }
  f = message.getNumberOfBays();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto_vorto_types_types_pb.NullableInt64.serializeBinaryToWriter
    );
  }
  f = message.getDefaultDeliveryWindowMinutes();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto_vorto_types_types_pb.NullableInt64.serializeBinaryToWriter
    );
  }
  f = message.getAddress();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto_vorto_types_types_pb.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getDesiredMaxSupplierResponseTimeHours();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto_vorto_types_types_pb.NullableInt64.serializeBinaryToWriter
    );
  }
  f = message.getAverageDwellTimeCalculationWindow();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      proto_vorto_types_types_pb.NullableInt64.serializeBinaryToWriter
    );
  }
  f = message.getDirections();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      proto_vorto_types_types_pb.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getLoadingInstructions();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      proto_vorto_types_types_pb.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getSalesOrder();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      proto_vorto_types_types_pb.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getFacilityCode();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      proto_vorto_types_types_pb.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getDistrictId();
  if (f != null) {
    writer.writeMessage(
      17,
      f,
      proto_vorto_types_types_pb.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getTaxPercent();
  if (f != null) {
    writer.writeMessage(
      18,
      f,
      proto_vorto_types_types_pb.NullableDouble.serializeBinaryToWriter
    );
  }
  f = message.getFulfillmentSitesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      19,
      f
    );
  }
  f = message.getSiteUserIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      20,
      f
    );
  }
  f = message.getCostCenterId();
  if (f != null) {
    writer.writeMessage(
      21,
      f,
      proto_vorto_types_types_pb.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getSalesOrderId();
  if (f != null) {
    writer.writeMessage(
      22,
      f,
      proto_vorto_types_types_pb.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getInventorySalesOrderLineItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      23,
      f,
      proto.vtv1.NodeEditSiteRequestInventoryLineItem.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.vtv1.NodeEditSiteRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.NodeEditSiteRequest} returns this
 */
proto.vtv1.NodeEditSiteRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional NullableString name = 2;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.NodeEditSiteRequest.prototype.getName = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableString, 2));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.NodeEditSiteRequest} returns this
*/
proto.vtv1.NodeEditSiteRequest.prototype.setName = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.NodeEditSiteRequest} returns this
 */
proto.vtv1.NodeEditSiteRequest.prototype.clearName = function() {
  return this.setName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.NodeEditSiteRequest.prototype.hasName = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional NullableDouble latitude = 3;
 * @return {?proto.vtv1.NullableDouble}
 */
proto.vtv1.NodeEditSiteRequest.prototype.getLatitude = function() {
  return /** @type{?proto.vtv1.NullableDouble} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableDouble, 3));
};


/**
 * @param {?proto.vtv1.NullableDouble|undefined} value
 * @return {!proto.vtv1.NodeEditSiteRequest} returns this
*/
proto.vtv1.NodeEditSiteRequest.prototype.setLatitude = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.NodeEditSiteRequest} returns this
 */
proto.vtv1.NodeEditSiteRequest.prototype.clearLatitude = function() {
  return this.setLatitude(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.NodeEditSiteRequest.prototype.hasLatitude = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional NullableDouble longitude = 4;
 * @return {?proto.vtv1.NullableDouble}
 */
proto.vtv1.NodeEditSiteRequest.prototype.getLongitude = function() {
  return /** @type{?proto.vtv1.NullableDouble} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableDouble, 4));
};


/**
 * @param {?proto.vtv1.NullableDouble|undefined} value
 * @return {!proto.vtv1.NodeEditSiteRequest} returns this
*/
proto.vtv1.NodeEditSiteRequest.prototype.setLongitude = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.NodeEditSiteRequest} returns this
 */
proto.vtv1.NodeEditSiteRequest.prototype.clearLongitude = function() {
  return this.setLongitude(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.NodeEditSiteRequest.prototype.hasLongitude = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional NullableDouble radius = 5;
 * @return {?proto.vtv1.NullableDouble}
 */
proto.vtv1.NodeEditSiteRequest.prototype.getRadius = function() {
  return /** @type{?proto.vtv1.NullableDouble} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableDouble, 5));
};


/**
 * @param {?proto.vtv1.NullableDouble|undefined} value
 * @return {!proto.vtv1.NodeEditSiteRequest} returns this
*/
proto.vtv1.NodeEditSiteRequest.prototype.setRadius = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.NodeEditSiteRequest} returns this
 */
proto.vtv1.NodeEditSiteRequest.prototype.clearRadius = function() {
  return this.setRadius(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.NodeEditSiteRequest.prototype.hasRadius = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional bool update_site_group_id = 6;
 * @return {boolean}
 */
proto.vtv1.NodeEditSiteRequest.prototype.getUpdateSiteGroupId = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vtv1.NodeEditSiteRequest} returns this
 */
proto.vtv1.NodeEditSiteRequest.prototype.setUpdateSiteGroupId = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional NullableInt64 site_group_id = 7;
 * @return {?proto.vtv1.NullableInt64}
 */
proto.vtv1.NodeEditSiteRequest.prototype.getSiteGroupId = function() {
  return /** @type{?proto.vtv1.NullableInt64} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableInt64, 7));
};


/**
 * @param {?proto.vtv1.NullableInt64|undefined} value
 * @return {!proto.vtv1.NodeEditSiteRequest} returns this
*/
proto.vtv1.NodeEditSiteRequest.prototype.setSiteGroupId = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.NodeEditSiteRequest} returns this
 */
proto.vtv1.NodeEditSiteRequest.prototype.clearSiteGroupId = function() {
  return this.setSiteGroupId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.NodeEditSiteRequest.prototype.hasSiteGroupId = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional NullableInt64 number_of_bays = 8;
 * @return {?proto.vtv1.NullableInt64}
 */
proto.vtv1.NodeEditSiteRequest.prototype.getNumberOfBays = function() {
  return /** @type{?proto.vtv1.NullableInt64} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableInt64, 8));
};


/**
 * @param {?proto.vtv1.NullableInt64|undefined} value
 * @return {!proto.vtv1.NodeEditSiteRequest} returns this
*/
proto.vtv1.NodeEditSiteRequest.prototype.setNumberOfBays = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.NodeEditSiteRequest} returns this
 */
proto.vtv1.NodeEditSiteRequest.prototype.clearNumberOfBays = function() {
  return this.setNumberOfBays(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.NodeEditSiteRequest.prototype.hasNumberOfBays = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional NullableInt64 default_delivery_window_minutes = 9;
 * @return {?proto.vtv1.NullableInt64}
 */
proto.vtv1.NodeEditSiteRequest.prototype.getDefaultDeliveryWindowMinutes = function() {
  return /** @type{?proto.vtv1.NullableInt64} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableInt64, 9));
};


/**
 * @param {?proto.vtv1.NullableInt64|undefined} value
 * @return {!proto.vtv1.NodeEditSiteRequest} returns this
*/
proto.vtv1.NodeEditSiteRequest.prototype.setDefaultDeliveryWindowMinutes = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.NodeEditSiteRequest} returns this
 */
proto.vtv1.NodeEditSiteRequest.prototype.clearDefaultDeliveryWindowMinutes = function() {
  return this.setDefaultDeliveryWindowMinutes(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.NodeEditSiteRequest.prototype.hasDefaultDeliveryWindowMinutes = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional NullableString address = 10;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.NodeEditSiteRequest.prototype.getAddress = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableString, 10));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.NodeEditSiteRequest} returns this
*/
proto.vtv1.NodeEditSiteRequest.prototype.setAddress = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.NodeEditSiteRequest} returns this
 */
proto.vtv1.NodeEditSiteRequest.prototype.clearAddress = function() {
  return this.setAddress(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.NodeEditSiteRequest.prototype.hasAddress = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional NullableInt64 desired_max_supplier_response_time_hours = 11;
 * @return {?proto.vtv1.NullableInt64}
 */
proto.vtv1.NodeEditSiteRequest.prototype.getDesiredMaxSupplierResponseTimeHours = function() {
  return /** @type{?proto.vtv1.NullableInt64} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableInt64, 11));
};


/**
 * @param {?proto.vtv1.NullableInt64|undefined} value
 * @return {!proto.vtv1.NodeEditSiteRequest} returns this
*/
proto.vtv1.NodeEditSiteRequest.prototype.setDesiredMaxSupplierResponseTimeHours = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.NodeEditSiteRequest} returns this
 */
proto.vtv1.NodeEditSiteRequest.prototype.clearDesiredMaxSupplierResponseTimeHours = function() {
  return this.setDesiredMaxSupplierResponseTimeHours(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.NodeEditSiteRequest.prototype.hasDesiredMaxSupplierResponseTimeHours = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional NullableInt64 average_dwell_time_calculation_window = 12;
 * @return {?proto.vtv1.NullableInt64}
 */
proto.vtv1.NodeEditSiteRequest.prototype.getAverageDwellTimeCalculationWindow = function() {
  return /** @type{?proto.vtv1.NullableInt64} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableInt64, 12));
};


/**
 * @param {?proto.vtv1.NullableInt64|undefined} value
 * @return {!proto.vtv1.NodeEditSiteRequest} returns this
*/
proto.vtv1.NodeEditSiteRequest.prototype.setAverageDwellTimeCalculationWindow = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.NodeEditSiteRequest} returns this
 */
proto.vtv1.NodeEditSiteRequest.prototype.clearAverageDwellTimeCalculationWindow = function() {
  return this.setAverageDwellTimeCalculationWindow(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.NodeEditSiteRequest.prototype.hasAverageDwellTimeCalculationWindow = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional NullableString directions = 13;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.NodeEditSiteRequest.prototype.getDirections = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableString, 13));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.NodeEditSiteRequest} returns this
*/
proto.vtv1.NodeEditSiteRequest.prototype.setDirections = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.NodeEditSiteRequest} returns this
 */
proto.vtv1.NodeEditSiteRequest.prototype.clearDirections = function() {
  return this.setDirections(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.NodeEditSiteRequest.prototype.hasDirections = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional NullableString loading_instructions = 14;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.NodeEditSiteRequest.prototype.getLoadingInstructions = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableString, 14));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.NodeEditSiteRequest} returns this
*/
proto.vtv1.NodeEditSiteRequest.prototype.setLoadingInstructions = function(value) {
  return jspb.Message.setWrapperField(this, 14, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.NodeEditSiteRequest} returns this
 */
proto.vtv1.NodeEditSiteRequest.prototype.clearLoadingInstructions = function() {
  return this.setLoadingInstructions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.NodeEditSiteRequest.prototype.hasLoadingInstructions = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional NullableString sales_order = 15;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.NodeEditSiteRequest.prototype.getSalesOrder = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableString, 15));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.NodeEditSiteRequest} returns this
*/
proto.vtv1.NodeEditSiteRequest.prototype.setSalesOrder = function(value) {
  return jspb.Message.setWrapperField(this, 15, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.NodeEditSiteRequest} returns this
 */
proto.vtv1.NodeEditSiteRequest.prototype.clearSalesOrder = function() {
  return this.setSalesOrder(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.NodeEditSiteRequest.prototype.hasSalesOrder = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional NullableString facility_code = 16;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.NodeEditSiteRequest.prototype.getFacilityCode = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableString, 16));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.NodeEditSiteRequest} returns this
*/
proto.vtv1.NodeEditSiteRequest.prototype.setFacilityCode = function(value) {
  return jspb.Message.setWrapperField(this, 16, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.NodeEditSiteRequest} returns this
 */
proto.vtv1.NodeEditSiteRequest.prototype.clearFacilityCode = function() {
  return this.setFacilityCode(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.NodeEditSiteRequest.prototype.hasFacilityCode = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * optional NullableString district_id = 17;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.NodeEditSiteRequest.prototype.getDistrictId = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableString, 17));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.NodeEditSiteRequest} returns this
*/
proto.vtv1.NodeEditSiteRequest.prototype.setDistrictId = function(value) {
  return jspb.Message.setWrapperField(this, 17, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.NodeEditSiteRequest} returns this
 */
proto.vtv1.NodeEditSiteRequest.prototype.clearDistrictId = function() {
  return this.setDistrictId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.NodeEditSiteRequest.prototype.hasDistrictId = function() {
  return jspb.Message.getField(this, 17) != null;
};


/**
 * optional NullableDouble tax_percent = 18;
 * @return {?proto.vtv1.NullableDouble}
 */
proto.vtv1.NodeEditSiteRequest.prototype.getTaxPercent = function() {
  return /** @type{?proto.vtv1.NullableDouble} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableDouble, 18));
};


/**
 * @param {?proto.vtv1.NullableDouble|undefined} value
 * @return {!proto.vtv1.NodeEditSiteRequest} returns this
*/
proto.vtv1.NodeEditSiteRequest.prototype.setTaxPercent = function(value) {
  return jspb.Message.setWrapperField(this, 18, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.NodeEditSiteRequest} returns this
 */
proto.vtv1.NodeEditSiteRequest.prototype.clearTaxPercent = function() {
  return this.setTaxPercent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.NodeEditSiteRequest.prototype.hasTaxPercent = function() {
  return jspb.Message.getField(this, 18) != null;
};


/**
 * repeated string fulfillment_sites = 19;
 * @return {!Array<string>}
 */
proto.vtv1.NodeEditSiteRequest.prototype.getFulfillmentSitesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 19));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.vtv1.NodeEditSiteRequest} returns this
 */
proto.vtv1.NodeEditSiteRequest.prototype.setFulfillmentSitesList = function(value) {
  return jspb.Message.setField(this, 19, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.vtv1.NodeEditSiteRequest} returns this
 */
proto.vtv1.NodeEditSiteRequest.prototype.addFulfillmentSites = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 19, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.NodeEditSiteRequest} returns this
 */
proto.vtv1.NodeEditSiteRequest.prototype.clearFulfillmentSitesList = function() {
  return this.setFulfillmentSitesList([]);
};


/**
 * repeated string site_user_ids = 20;
 * @return {!Array<string>}
 */
proto.vtv1.NodeEditSiteRequest.prototype.getSiteUserIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 20));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.vtv1.NodeEditSiteRequest} returns this
 */
proto.vtv1.NodeEditSiteRequest.prototype.setSiteUserIdsList = function(value) {
  return jspb.Message.setField(this, 20, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.vtv1.NodeEditSiteRequest} returns this
 */
proto.vtv1.NodeEditSiteRequest.prototype.addSiteUserIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 20, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.NodeEditSiteRequest} returns this
 */
proto.vtv1.NodeEditSiteRequest.prototype.clearSiteUserIdsList = function() {
  return this.setSiteUserIdsList([]);
};


/**
 * optional NullableString cost_center_id = 21;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.NodeEditSiteRequest.prototype.getCostCenterId = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableString, 21));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.NodeEditSiteRequest} returns this
*/
proto.vtv1.NodeEditSiteRequest.prototype.setCostCenterId = function(value) {
  return jspb.Message.setWrapperField(this, 21, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.NodeEditSiteRequest} returns this
 */
proto.vtv1.NodeEditSiteRequest.prototype.clearCostCenterId = function() {
  return this.setCostCenterId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.NodeEditSiteRequest.prototype.hasCostCenterId = function() {
  return jspb.Message.getField(this, 21) != null;
};


/**
 * optional NullableString sales_order_id = 22;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.NodeEditSiteRequest.prototype.getSalesOrderId = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableString, 22));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.NodeEditSiteRequest} returns this
*/
proto.vtv1.NodeEditSiteRequest.prototype.setSalesOrderId = function(value) {
  return jspb.Message.setWrapperField(this, 22, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.NodeEditSiteRequest} returns this
 */
proto.vtv1.NodeEditSiteRequest.prototype.clearSalesOrderId = function() {
  return this.setSalesOrderId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.NodeEditSiteRequest.prototype.hasSalesOrderId = function() {
  return jspb.Message.getField(this, 22) != null;
};


/**
 * repeated NodeEditSiteRequestInventoryLineItem inventory_sales_order_line_items = 23;
 * @return {!Array<!proto.vtv1.NodeEditSiteRequestInventoryLineItem>}
 */
proto.vtv1.NodeEditSiteRequest.prototype.getInventorySalesOrderLineItemsList = function() {
  return /** @type{!Array<!proto.vtv1.NodeEditSiteRequestInventoryLineItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vtv1.NodeEditSiteRequestInventoryLineItem, 23));
};


/**
 * @param {!Array<!proto.vtv1.NodeEditSiteRequestInventoryLineItem>} value
 * @return {!proto.vtv1.NodeEditSiteRequest} returns this
*/
proto.vtv1.NodeEditSiteRequest.prototype.setInventorySalesOrderLineItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 23, value);
};


/**
 * @param {!proto.vtv1.NodeEditSiteRequestInventoryLineItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.NodeEditSiteRequestInventoryLineItem}
 */
proto.vtv1.NodeEditSiteRequest.prototype.addInventorySalesOrderLineItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 23, opt_value, proto.vtv1.NodeEditSiteRequestInventoryLineItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.NodeEditSiteRequest} returns this
 */
proto.vtv1.NodeEditSiteRequest.prototype.clearInventorySalesOrderLineItemsList = function() {
  return this.setInventorySalesOrderLineItemsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.NodeEditSiteResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.NodeEditSiteResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.NodeEditSiteResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.NodeEditSiteResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    site: (f = msg.getSite()) && proto_vorto_types_types_pb.Site.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.NodeEditSiteResponse}
 */
proto.vtv1.NodeEditSiteResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.NodeEditSiteResponse;
  return proto.vtv1.NodeEditSiteResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.NodeEditSiteResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.NodeEditSiteResponse}
 */
proto.vtv1.NodeEditSiteResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_vorto_types_types_pb.Site;
      reader.readMessage(value,proto_vorto_types_types_pb.Site.deserializeBinaryFromReader);
      msg.setSite(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.NodeEditSiteResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.NodeEditSiteResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.NodeEditSiteResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.NodeEditSiteResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSite();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_vorto_types_types_pb.Site.serializeBinaryToWriter
    );
  }
};


/**
 * optional Site site = 1;
 * @return {?proto.vtv1.Site}
 */
proto.vtv1.NodeEditSiteResponse.prototype.getSite = function() {
  return /** @type{?proto.vtv1.Site} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.Site, 1));
};


/**
 * @param {?proto.vtv1.Site|undefined} value
 * @return {!proto.vtv1.NodeEditSiteResponse} returns this
*/
proto.vtv1.NodeEditSiteResponse.prototype.setSite = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.NodeEditSiteResponse} returns this
 */
proto.vtv1.NodeEditSiteResponse.prototype.clearSite = function() {
  return this.setSite(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.NodeEditSiteResponse.prototype.hasSite = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.NodeListFinalStateOrdersResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.NodeListFinalStateOrdersResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.NodeListFinalStateOrdersResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.NodeListFinalStateOrdersResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.NodeListFinalStateOrdersResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    ordersList: jspb.Message.toObjectList(msg.getOrdersList(),
    proto_vorto_node_node_pb.FinalStateOrderSummary.toObject, includeInstance),
    orderStartIndex: jspb.Message.getFieldWithDefault(msg, 2, 0),
    ordersPerPage: jspb.Message.getFieldWithDefault(msg, 3, 0),
    totalOrders: jspb.Message.getFieldWithDefault(msg, 4, 0),
    previousPageRequest: (f = msg.getPreviousPageRequest()) && proto.vtv1.NodeFinalizedOrdersRequest.toObject(includeInstance, f),
    nextPageRequest: (f = msg.getNextPageRequest()) && proto.vtv1.NodeFinalizedOrdersRequest.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.NodeListFinalStateOrdersResponse}
 */
proto.vtv1.NodeListFinalStateOrdersResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.NodeListFinalStateOrdersResponse;
  return proto.vtv1.NodeListFinalStateOrdersResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.NodeListFinalStateOrdersResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.NodeListFinalStateOrdersResponse}
 */
proto.vtv1.NodeListFinalStateOrdersResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_vorto_node_node_pb.FinalStateOrderSummary;
      reader.readMessage(value,proto_vorto_node_node_pb.FinalStateOrderSummary.deserializeBinaryFromReader);
      msg.addOrders(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setOrderStartIndex(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setOrdersPerPage(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotalOrders(value);
      break;
    case 5:
      var value = new proto.vtv1.NodeFinalizedOrdersRequest;
      reader.readMessage(value,proto.vtv1.NodeFinalizedOrdersRequest.deserializeBinaryFromReader);
      msg.setPreviousPageRequest(value);
      break;
    case 6:
      var value = new proto.vtv1.NodeFinalizedOrdersRequest;
      reader.readMessage(value,proto.vtv1.NodeFinalizedOrdersRequest.deserializeBinaryFromReader);
      msg.setNextPageRequest(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.NodeListFinalStateOrdersResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.NodeListFinalStateOrdersResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.NodeListFinalStateOrdersResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.NodeListFinalStateOrdersResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrdersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto_vorto_node_node_pb.FinalStateOrderSummary.serializeBinaryToWriter
    );
  }
  f = message.getOrderStartIndex();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getOrdersPerPage();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getTotalOrders();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getPreviousPageRequest();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.vtv1.NodeFinalizedOrdersRequest.serializeBinaryToWriter
    );
  }
  f = message.getNextPageRequest();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.vtv1.NodeFinalizedOrdersRequest.serializeBinaryToWriter
    );
  }
};


/**
 * repeated FinalStateOrderSummary orders = 1;
 * @return {!Array<!proto.vtv1.FinalStateOrderSummary>}
 */
proto.vtv1.NodeListFinalStateOrdersResponse.prototype.getOrdersList = function() {
  return /** @type{!Array<!proto.vtv1.FinalStateOrderSummary>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_vorto_node_node_pb.FinalStateOrderSummary, 1));
};


/**
 * @param {!Array<!proto.vtv1.FinalStateOrderSummary>} value
 * @return {!proto.vtv1.NodeListFinalStateOrdersResponse} returns this
*/
proto.vtv1.NodeListFinalStateOrdersResponse.prototype.setOrdersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.vtv1.FinalStateOrderSummary=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.FinalStateOrderSummary}
 */
proto.vtv1.NodeListFinalStateOrdersResponse.prototype.addOrders = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.vtv1.FinalStateOrderSummary, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.NodeListFinalStateOrdersResponse} returns this
 */
proto.vtv1.NodeListFinalStateOrdersResponse.prototype.clearOrdersList = function() {
  return this.setOrdersList([]);
};


/**
 * optional int64 order_start_index = 2;
 * @return {number}
 */
proto.vtv1.NodeListFinalStateOrdersResponse.prototype.getOrderStartIndex = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.NodeListFinalStateOrdersResponse} returns this
 */
proto.vtv1.NodeListFinalStateOrdersResponse.prototype.setOrderStartIndex = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 orders_per_page = 3;
 * @return {number}
 */
proto.vtv1.NodeListFinalStateOrdersResponse.prototype.getOrdersPerPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.NodeListFinalStateOrdersResponse} returns this
 */
proto.vtv1.NodeListFinalStateOrdersResponse.prototype.setOrdersPerPage = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 total_orders = 4;
 * @return {number}
 */
proto.vtv1.NodeListFinalStateOrdersResponse.prototype.getTotalOrders = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.NodeListFinalStateOrdersResponse} returns this
 */
proto.vtv1.NodeListFinalStateOrdersResponse.prototype.setTotalOrders = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional NodeFinalizedOrdersRequest previous_page_request = 5;
 * @return {?proto.vtv1.NodeFinalizedOrdersRequest}
 */
proto.vtv1.NodeListFinalStateOrdersResponse.prototype.getPreviousPageRequest = function() {
  return /** @type{?proto.vtv1.NodeFinalizedOrdersRequest} */ (
    jspb.Message.getWrapperField(this, proto.vtv1.NodeFinalizedOrdersRequest, 5));
};


/**
 * @param {?proto.vtv1.NodeFinalizedOrdersRequest|undefined} value
 * @return {!proto.vtv1.NodeListFinalStateOrdersResponse} returns this
*/
proto.vtv1.NodeListFinalStateOrdersResponse.prototype.setPreviousPageRequest = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.NodeListFinalStateOrdersResponse} returns this
 */
proto.vtv1.NodeListFinalStateOrdersResponse.prototype.clearPreviousPageRequest = function() {
  return this.setPreviousPageRequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.NodeListFinalStateOrdersResponse.prototype.hasPreviousPageRequest = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional NodeFinalizedOrdersRequest next_page_request = 6;
 * @return {?proto.vtv1.NodeFinalizedOrdersRequest}
 */
proto.vtv1.NodeListFinalStateOrdersResponse.prototype.getNextPageRequest = function() {
  return /** @type{?proto.vtv1.NodeFinalizedOrdersRequest} */ (
    jspb.Message.getWrapperField(this, proto.vtv1.NodeFinalizedOrdersRequest, 6));
};


/**
 * @param {?proto.vtv1.NodeFinalizedOrdersRequest|undefined} value
 * @return {!proto.vtv1.NodeListFinalStateOrdersResponse} returns this
*/
proto.vtv1.NodeListFinalStateOrdersResponse.prototype.setNextPageRequest = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.NodeListFinalStateOrdersResponse} returns this
 */
proto.vtv1.NodeListFinalStateOrdersResponse.prototype.clearNextPageRequest = function() {
  return this.setNextPageRequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.NodeListFinalStateOrdersResponse.prototype.hasNextPageRequest = function() {
  return jspb.Message.getField(this, 6) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.NodeListPageRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.NodeListPageRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.NodeListPageRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.NodeListPageRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    pageNumber: jspb.Message.getFieldWithDefault(msg, 1, 0),
    filter: (f = msg.getFilter()) && proto_vorto_types_types_pb.NullableString.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.NodeListPageRequest}
 */
proto.vtv1.NodeListPageRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.NodeListPageRequest;
  return proto.vtv1.NodeListPageRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.NodeListPageRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.NodeListPageRequest}
 */
proto.vtv1.NodeListPageRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPageNumber(value);
      break;
    case 2:
      var value = new proto_vorto_types_types_pb.NullableString;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableString.deserializeBinaryFromReader);
      msg.setFilter(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.NodeListPageRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.NodeListPageRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.NodeListPageRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.NodeListPageRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPageNumber();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getFilter();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_vorto_types_types_pb.NullableString.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 page_number = 1;
 * @return {number}
 */
proto.vtv1.NodeListPageRequest.prototype.getPageNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.NodeListPageRequest} returns this
 */
proto.vtv1.NodeListPageRequest.prototype.setPageNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional NullableString filter = 2;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.NodeListPageRequest.prototype.getFilter = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableString, 2));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.NodeListPageRequest} returns this
*/
proto.vtv1.NodeListPageRequest.prototype.setFilter = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.NodeListPageRequest} returns this
 */
proto.vtv1.NodeListPageRequest.prototype.clearFilter = function() {
  return this.setFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.NodeListPageRequest.prototype.hasFilter = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.NodeFinalizedOrdersRequestDeliveryDateFilter.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.NodeFinalizedOrdersRequestDeliveryDateFilter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.NodeFinalizedOrdersRequestDeliveryDateFilter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.NodeFinalizedOrdersRequestDeliveryDateFilter.toObject = function(includeInstance, msg) {
  var f, obj = {
    targetdate: jspb.Message.getFieldWithDefault(msg, 1, ""),
    before: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.NodeFinalizedOrdersRequestDeliveryDateFilter}
 */
proto.vtv1.NodeFinalizedOrdersRequestDeliveryDateFilter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.NodeFinalizedOrdersRequestDeliveryDateFilter;
  return proto.vtv1.NodeFinalizedOrdersRequestDeliveryDateFilter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.NodeFinalizedOrdersRequestDeliveryDateFilter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.NodeFinalizedOrdersRequestDeliveryDateFilter}
 */
proto.vtv1.NodeFinalizedOrdersRequestDeliveryDateFilter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTargetdate(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setBefore(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.NodeFinalizedOrdersRequestDeliveryDateFilter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.NodeFinalizedOrdersRequestDeliveryDateFilter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.NodeFinalizedOrdersRequestDeliveryDateFilter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.NodeFinalizedOrdersRequestDeliveryDateFilter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTargetdate();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBefore();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional string targetDate = 1;
 * @return {string}
 */
proto.vtv1.NodeFinalizedOrdersRequestDeliveryDateFilter.prototype.getTargetdate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.NodeFinalizedOrdersRequestDeliveryDateFilter} returns this
 */
proto.vtv1.NodeFinalizedOrdersRequestDeliveryDateFilter.prototype.setTargetdate = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool before = 2;
 * @return {boolean}
 */
proto.vtv1.NodeFinalizedOrdersRequestDeliveryDateFilter.prototype.getBefore = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vtv1.NodeFinalizedOrdersRequestDeliveryDateFilter} returns this
 */
proto.vtv1.NodeFinalizedOrdersRequestDeliveryDateFilter.prototype.setBefore = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.NodeFinalizedOrdersRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.NodeFinalizedOrdersRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.NodeFinalizedOrdersRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.NodeFinalizedOrdersRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    pageNumber: jspb.Message.getFieldWithDefault(msg, 1, 0),
    siteId: (f = msg.getSiteId()) && proto_vorto_types_types_pb.NullableString.toObject(includeInstance, f),
    productId: (f = msg.getProductId()) && proto_vorto_types_types_pb.NullableString.toObject(includeInstance, f),
    deliveryDateFilter: (f = msg.getDeliveryDateFilter()) && proto.vtv1.NodeFinalizedOrdersRequestDeliveryDateFilter.toObject(includeInstance, f),
    inboundOutbound: (f = msg.getInboundOutbound()) && proto_vorto_types_types_pb.NullableString.toObject(includeInstance, f),
    districtId: (f = msg.getDistrictId()) && proto_vorto_types_types_pb.NullableString.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.NodeFinalizedOrdersRequest}
 */
proto.vtv1.NodeFinalizedOrdersRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.NodeFinalizedOrdersRequest;
  return proto.vtv1.NodeFinalizedOrdersRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.NodeFinalizedOrdersRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.NodeFinalizedOrdersRequest}
 */
proto.vtv1.NodeFinalizedOrdersRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPageNumber(value);
      break;
    case 2:
      var value = new proto_vorto_types_types_pb.NullableString;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableString.deserializeBinaryFromReader);
      msg.setSiteId(value);
      break;
    case 3:
      var value = new proto_vorto_types_types_pb.NullableString;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableString.deserializeBinaryFromReader);
      msg.setProductId(value);
      break;
    case 4:
      var value = new proto.vtv1.NodeFinalizedOrdersRequestDeliveryDateFilter;
      reader.readMessage(value,proto.vtv1.NodeFinalizedOrdersRequestDeliveryDateFilter.deserializeBinaryFromReader);
      msg.setDeliveryDateFilter(value);
      break;
    case 5:
      var value = new proto_vorto_types_types_pb.NullableString;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableString.deserializeBinaryFromReader);
      msg.setInboundOutbound(value);
      break;
    case 6:
      var value = new proto_vorto_types_types_pb.NullableString;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableString.deserializeBinaryFromReader);
      msg.setDistrictId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.NodeFinalizedOrdersRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.NodeFinalizedOrdersRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.NodeFinalizedOrdersRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.NodeFinalizedOrdersRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPageNumber();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getSiteId();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_vorto_types_types_pb.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getProductId();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto_vorto_types_types_pb.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getDeliveryDateFilter();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.vtv1.NodeFinalizedOrdersRequestDeliveryDateFilter.serializeBinaryToWriter
    );
  }
  f = message.getInboundOutbound();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto_vorto_types_types_pb.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getDistrictId();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto_vorto_types_types_pb.NullableString.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 page_number = 1;
 * @return {number}
 */
proto.vtv1.NodeFinalizedOrdersRequest.prototype.getPageNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.NodeFinalizedOrdersRequest} returns this
 */
proto.vtv1.NodeFinalizedOrdersRequest.prototype.setPageNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional NullableString site_id = 2;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.NodeFinalizedOrdersRequest.prototype.getSiteId = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableString, 2));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.NodeFinalizedOrdersRequest} returns this
*/
proto.vtv1.NodeFinalizedOrdersRequest.prototype.setSiteId = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.NodeFinalizedOrdersRequest} returns this
 */
proto.vtv1.NodeFinalizedOrdersRequest.prototype.clearSiteId = function() {
  return this.setSiteId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.NodeFinalizedOrdersRequest.prototype.hasSiteId = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional NullableString product_id = 3;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.NodeFinalizedOrdersRequest.prototype.getProductId = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableString, 3));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.NodeFinalizedOrdersRequest} returns this
*/
proto.vtv1.NodeFinalizedOrdersRequest.prototype.setProductId = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.NodeFinalizedOrdersRequest} returns this
 */
proto.vtv1.NodeFinalizedOrdersRequest.prototype.clearProductId = function() {
  return this.setProductId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.NodeFinalizedOrdersRequest.prototype.hasProductId = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional NodeFinalizedOrdersRequestDeliveryDateFilter delivery_date_filter = 4;
 * @return {?proto.vtv1.NodeFinalizedOrdersRequestDeliveryDateFilter}
 */
proto.vtv1.NodeFinalizedOrdersRequest.prototype.getDeliveryDateFilter = function() {
  return /** @type{?proto.vtv1.NodeFinalizedOrdersRequestDeliveryDateFilter} */ (
    jspb.Message.getWrapperField(this, proto.vtv1.NodeFinalizedOrdersRequestDeliveryDateFilter, 4));
};


/**
 * @param {?proto.vtv1.NodeFinalizedOrdersRequestDeliveryDateFilter|undefined} value
 * @return {!proto.vtv1.NodeFinalizedOrdersRequest} returns this
*/
proto.vtv1.NodeFinalizedOrdersRequest.prototype.setDeliveryDateFilter = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.NodeFinalizedOrdersRequest} returns this
 */
proto.vtv1.NodeFinalizedOrdersRequest.prototype.clearDeliveryDateFilter = function() {
  return this.setDeliveryDateFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.NodeFinalizedOrdersRequest.prototype.hasDeliveryDateFilter = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional NullableString inbound_outbound = 5;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.NodeFinalizedOrdersRequest.prototype.getInboundOutbound = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableString, 5));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.NodeFinalizedOrdersRequest} returns this
*/
proto.vtv1.NodeFinalizedOrdersRequest.prototype.setInboundOutbound = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.NodeFinalizedOrdersRequest} returns this
 */
proto.vtv1.NodeFinalizedOrdersRequest.prototype.clearInboundOutbound = function() {
  return this.setInboundOutbound(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.NodeFinalizedOrdersRequest.prototype.hasInboundOutbound = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional NullableString district_id = 6;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.NodeFinalizedOrdersRequest.prototype.getDistrictId = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableString, 6));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.NodeFinalizedOrdersRequest} returns this
*/
proto.vtv1.NodeFinalizedOrdersRequest.prototype.setDistrictId = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.NodeFinalizedOrdersRequest} returns this
 */
proto.vtv1.NodeFinalizedOrdersRequest.prototype.clearDistrictId = function() {
  return this.setDistrictId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.NodeFinalizedOrdersRequest.prototype.hasDistrictId = function() {
  return jspb.Message.getField(this, 6) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.NodeListArchivedSitesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.NodeListArchivedSitesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.NodeListArchivedSitesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.NodeListArchivedSitesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.NodeListArchivedSitesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    sitesList: jspb.Message.toObjectList(msg.getSitesList(),
    proto_vorto_types_types_pb.SlimSite.toObject, includeInstance),
    siteStartIndex: jspb.Message.getFieldWithDefault(msg, 2, 0),
    sitesPerPage: jspb.Message.getFieldWithDefault(msg, 3, 0),
    totalSites: jspb.Message.getFieldWithDefault(msg, 4, 0),
    previousPageRequest: (f = msg.getPreviousPageRequest()) && proto.vtv1.NodeListPageRequest.toObject(includeInstance, f),
    nextPageRequest: (f = msg.getNextPageRequest()) && proto.vtv1.NodeListPageRequest.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.NodeListArchivedSitesResponse}
 */
proto.vtv1.NodeListArchivedSitesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.NodeListArchivedSitesResponse;
  return proto.vtv1.NodeListArchivedSitesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.NodeListArchivedSitesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.NodeListArchivedSitesResponse}
 */
proto.vtv1.NodeListArchivedSitesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_vorto_types_types_pb.SlimSite;
      reader.readMessage(value,proto_vorto_types_types_pb.SlimSite.deserializeBinaryFromReader);
      msg.addSites(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSiteStartIndex(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSitesPerPage(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotalSites(value);
      break;
    case 5:
      var value = new proto.vtv1.NodeListPageRequest;
      reader.readMessage(value,proto.vtv1.NodeListPageRequest.deserializeBinaryFromReader);
      msg.setPreviousPageRequest(value);
      break;
    case 6:
      var value = new proto.vtv1.NodeListPageRequest;
      reader.readMessage(value,proto.vtv1.NodeListPageRequest.deserializeBinaryFromReader);
      msg.setNextPageRequest(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.NodeListArchivedSitesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.NodeListArchivedSitesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.NodeListArchivedSitesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.NodeListArchivedSitesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSitesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto_vorto_types_types_pb.SlimSite.serializeBinaryToWriter
    );
  }
  f = message.getSiteStartIndex();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getSitesPerPage();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getTotalSites();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getPreviousPageRequest();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.vtv1.NodeListPageRequest.serializeBinaryToWriter
    );
  }
  f = message.getNextPageRequest();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.vtv1.NodeListPageRequest.serializeBinaryToWriter
    );
  }
};


/**
 * repeated SlimSite sites = 1;
 * @return {!Array<!proto.vtv1.SlimSite>}
 */
proto.vtv1.NodeListArchivedSitesResponse.prototype.getSitesList = function() {
  return /** @type{!Array<!proto.vtv1.SlimSite>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_vorto_types_types_pb.SlimSite, 1));
};


/**
 * @param {!Array<!proto.vtv1.SlimSite>} value
 * @return {!proto.vtv1.NodeListArchivedSitesResponse} returns this
*/
proto.vtv1.NodeListArchivedSitesResponse.prototype.setSitesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.vtv1.SlimSite=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.SlimSite}
 */
proto.vtv1.NodeListArchivedSitesResponse.prototype.addSites = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.vtv1.SlimSite, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.NodeListArchivedSitesResponse} returns this
 */
proto.vtv1.NodeListArchivedSitesResponse.prototype.clearSitesList = function() {
  return this.setSitesList([]);
};


/**
 * optional int64 site_start_index = 2;
 * @return {number}
 */
proto.vtv1.NodeListArchivedSitesResponse.prototype.getSiteStartIndex = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.NodeListArchivedSitesResponse} returns this
 */
proto.vtv1.NodeListArchivedSitesResponse.prototype.setSiteStartIndex = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 sites_per_page = 3;
 * @return {number}
 */
proto.vtv1.NodeListArchivedSitesResponse.prototype.getSitesPerPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.NodeListArchivedSitesResponse} returns this
 */
proto.vtv1.NodeListArchivedSitesResponse.prototype.setSitesPerPage = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 total_sites = 4;
 * @return {number}
 */
proto.vtv1.NodeListArchivedSitesResponse.prototype.getTotalSites = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.NodeListArchivedSitesResponse} returns this
 */
proto.vtv1.NodeListArchivedSitesResponse.prototype.setTotalSites = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional NodeListPageRequest previous_page_request = 5;
 * @return {?proto.vtv1.NodeListPageRequest}
 */
proto.vtv1.NodeListArchivedSitesResponse.prototype.getPreviousPageRequest = function() {
  return /** @type{?proto.vtv1.NodeListPageRequest} */ (
    jspb.Message.getWrapperField(this, proto.vtv1.NodeListPageRequest, 5));
};


/**
 * @param {?proto.vtv1.NodeListPageRequest|undefined} value
 * @return {!proto.vtv1.NodeListArchivedSitesResponse} returns this
*/
proto.vtv1.NodeListArchivedSitesResponse.prototype.setPreviousPageRequest = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.NodeListArchivedSitesResponse} returns this
 */
proto.vtv1.NodeListArchivedSitesResponse.prototype.clearPreviousPageRequest = function() {
  return this.setPreviousPageRequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.NodeListArchivedSitesResponse.prototype.hasPreviousPageRequest = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional NodeListPageRequest next_page_request = 6;
 * @return {?proto.vtv1.NodeListPageRequest}
 */
proto.vtv1.NodeListArchivedSitesResponse.prototype.getNextPageRequest = function() {
  return /** @type{?proto.vtv1.NodeListPageRequest} */ (
    jspb.Message.getWrapperField(this, proto.vtv1.NodeListPageRequest, 6));
};


/**
 * @param {?proto.vtv1.NodeListPageRequest|undefined} value
 * @return {!proto.vtv1.NodeListArchivedSitesResponse} returns this
*/
proto.vtv1.NodeListArchivedSitesResponse.prototype.setNextPageRequest = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.NodeListArchivedSitesResponse} returns this
 */
proto.vtv1.NodeListArchivedSitesResponse.prototype.clearNextPageRequest = function() {
  return this.setNextPageRequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.NodeListArchivedSitesResponse.prototype.hasNextPageRequest = function() {
  return jspb.Message.getField(this, 6) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.NodeListSitesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.NodeListSitesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.NodeListSitesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.NodeListSitesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.NodeListSitesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    sitesList: jspb.Message.toObjectList(msg.getSitesList(),
    proto_vorto_types_types_pb.SlimSite.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.NodeListSitesResponse}
 */
proto.vtv1.NodeListSitesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.NodeListSitesResponse;
  return proto.vtv1.NodeListSitesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.NodeListSitesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.NodeListSitesResponse}
 */
proto.vtv1.NodeListSitesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_vorto_types_types_pb.SlimSite;
      reader.readMessage(value,proto_vorto_types_types_pb.SlimSite.deserializeBinaryFromReader);
      msg.addSites(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.NodeListSitesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.NodeListSitesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.NodeListSitesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.NodeListSitesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSitesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto_vorto_types_types_pb.SlimSite.serializeBinaryToWriter
    );
  }
};


/**
 * repeated SlimSite sites = 1;
 * @return {!Array<!proto.vtv1.SlimSite>}
 */
proto.vtv1.NodeListSitesResponse.prototype.getSitesList = function() {
  return /** @type{!Array<!proto.vtv1.SlimSite>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_vorto_types_types_pb.SlimSite, 1));
};


/**
 * @param {!Array<!proto.vtv1.SlimSite>} value
 * @return {!proto.vtv1.NodeListSitesResponse} returns this
*/
proto.vtv1.NodeListSitesResponse.prototype.setSitesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.vtv1.SlimSite=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.SlimSite}
 */
proto.vtv1.NodeListSitesResponse.prototype.addSites = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.vtv1.SlimSite, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.NodeListSitesResponse} returns this
 */
proto.vtv1.NodeListSitesResponse.prototype.clearSitesList = function() {
  return this.setSitesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.NodeGetSiteResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.NodeGetSiteResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.NodeGetSiteResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.NodeGetSiteResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    site: (f = msg.getSite()) && proto_vorto_types_types_pb.Site.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.NodeGetSiteResponse}
 */
proto.vtv1.NodeGetSiteResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.NodeGetSiteResponse;
  return proto.vtv1.NodeGetSiteResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.NodeGetSiteResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.NodeGetSiteResponse}
 */
proto.vtv1.NodeGetSiteResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_vorto_types_types_pb.Site;
      reader.readMessage(value,proto_vorto_types_types_pb.Site.deserializeBinaryFromReader);
      msg.setSite(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.NodeGetSiteResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.NodeGetSiteResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.NodeGetSiteResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.NodeGetSiteResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSite();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_vorto_types_types_pb.Site.serializeBinaryToWriter
    );
  }
};


/**
 * optional Site site = 1;
 * @return {?proto.vtv1.Site}
 */
proto.vtv1.NodeGetSiteResponse.prototype.getSite = function() {
  return /** @type{?proto.vtv1.Site} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.Site, 1));
};


/**
 * @param {?proto.vtv1.Site|undefined} value
 * @return {!proto.vtv1.NodeGetSiteResponse} returns this
*/
proto.vtv1.NodeGetSiteResponse.prototype.setSite = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.NodeGetSiteResponse} returns this
 */
proto.vtv1.NodeGetSiteResponse.prototype.clearSite = function() {
  return this.setSite(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.NodeGetSiteResponse.prototype.hasSite = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.NodeListDistrictsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.NodeListDistrictsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.NodeListDistrictsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.NodeListDistrictsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.NodeListDistrictsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    districtsList: jspb.Message.toObjectList(msg.getDistrictsList(),
    proto_vorto_types_types_pb.District.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.NodeListDistrictsResponse}
 */
proto.vtv1.NodeListDistrictsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.NodeListDistrictsResponse;
  return proto.vtv1.NodeListDistrictsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.NodeListDistrictsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.NodeListDistrictsResponse}
 */
proto.vtv1.NodeListDistrictsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_vorto_types_types_pb.District;
      reader.readMessage(value,proto_vorto_types_types_pb.District.deserializeBinaryFromReader);
      msg.addDistricts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.NodeListDistrictsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.NodeListDistrictsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.NodeListDistrictsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.NodeListDistrictsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDistrictsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto_vorto_types_types_pb.District.serializeBinaryToWriter
    );
  }
};


/**
 * repeated District districts = 1;
 * @return {!Array<!proto.vtv1.District>}
 */
proto.vtv1.NodeListDistrictsResponse.prototype.getDistrictsList = function() {
  return /** @type{!Array<!proto.vtv1.District>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_vorto_types_types_pb.District, 1));
};


/**
 * @param {!Array<!proto.vtv1.District>} value
 * @return {!proto.vtv1.NodeListDistrictsResponse} returns this
*/
proto.vtv1.NodeListDistrictsResponse.prototype.setDistrictsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.vtv1.District=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.District}
 */
proto.vtv1.NodeListDistrictsResponse.prototype.addDistricts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.vtv1.District, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.NodeListDistrictsResponse} returns this
 */
proto.vtv1.NodeListDistrictsResponse.prototype.clearDistrictsList = function() {
  return this.setDistrictsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.NodeListDistrictsBySitesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.NodeListDistrictsBySitesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.NodeListDistrictsBySitesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.NodeListDistrictsBySitesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.NodeListDistrictsBySitesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    districtwithidsList: jspb.Message.toObjectList(msg.getDistrictwithidsList(),
    proto_vorto_types_types_pb.DistrictWithIds.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.NodeListDistrictsBySitesResponse}
 */
proto.vtv1.NodeListDistrictsBySitesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.NodeListDistrictsBySitesResponse;
  return proto.vtv1.NodeListDistrictsBySitesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.NodeListDistrictsBySitesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.NodeListDistrictsBySitesResponse}
 */
proto.vtv1.NodeListDistrictsBySitesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_vorto_types_types_pb.DistrictWithIds;
      reader.readMessage(value,proto_vorto_types_types_pb.DistrictWithIds.deserializeBinaryFromReader);
      msg.addDistrictwithids(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.NodeListDistrictsBySitesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.NodeListDistrictsBySitesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.NodeListDistrictsBySitesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.NodeListDistrictsBySitesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDistrictwithidsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto_vorto_types_types_pb.DistrictWithIds.serializeBinaryToWriter
    );
  }
};


/**
 * repeated DistrictWithIds districtWithIds = 1;
 * @return {!Array<!proto.vtv1.DistrictWithIds>}
 */
proto.vtv1.NodeListDistrictsBySitesResponse.prototype.getDistrictwithidsList = function() {
  return /** @type{!Array<!proto.vtv1.DistrictWithIds>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_vorto_types_types_pb.DistrictWithIds, 1));
};


/**
 * @param {!Array<!proto.vtv1.DistrictWithIds>} value
 * @return {!proto.vtv1.NodeListDistrictsBySitesResponse} returns this
*/
proto.vtv1.NodeListDistrictsBySitesResponse.prototype.setDistrictwithidsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.vtv1.DistrictWithIds=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.DistrictWithIds}
 */
proto.vtv1.NodeListDistrictsBySitesResponse.prototype.addDistrictwithids = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.vtv1.DistrictWithIds, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.NodeListDistrictsBySitesResponse} returns this
 */
proto.vtv1.NodeListDistrictsBySitesResponse.prototype.clearDistrictwithidsList = function() {
  return this.setDistrictwithidsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.CreateInventoryRequest.repeatedFields_ = [10,13];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.CreateInventoryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.CreateInventoryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.CreateInventoryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.CreateInventoryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    siteId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    productId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    capacity: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    stock: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    defaultLoadQuantity: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    defaultUnloadingMinutes: jspb.Message.getFieldWithDefault(msg, 7, 0),
    warningPercentage: (f = msg.getWarningPercentage()) && proto_vorto_types_types_pb.NullableDouble.toObject(includeInstance, f),
    warningHours: (f = msg.getWarningHours()) && proto_vorto_types_types_pb.NullableInt64.toObject(includeInstance, f),
    trailerTypeIdsList: (f = jspb.Message.getRepeatedField(msg, 10)) == null ? undefined : f,
    inbound: jspb.Message.getBooleanFieldWithDefault(msg, 11, false),
    outbound: jspb.Message.getBooleanFieldWithDefault(msg, 12, false),
    driverCertIdsList: (f = jspb.Message.getRepeatedField(msg, 13)) == null ? undefined : f,
    byContainer: jspb.Message.getBooleanFieldWithDefault(msg, 14, false),
    salesOrderLineItemId: (f = msg.getSalesOrderLineItemId()) && proto_vorto_types_types_pb.NullableString.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.CreateInventoryRequest}
 */
proto.vtv1.CreateInventoryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.CreateInventoryRequest;
  return proto.vtv1.CreateInventoryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.CreateInventoryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.CreateInventoryRequest}
 */
proto.vtv1.CreateInventoryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSiteId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductId(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setCapacity(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setStock(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setDefaultLoadQuantity(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDefaultUnloadingMinutes(value);
      break;
    case 8:
      var value = new proto_vorto_types_types_pb.NullableDouble;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableDouble.deserializeBinaryFromReader);
      msg.setWarningPercentage(value);
      break;
    case 9:
      var value = new proto_vorto_types_types_pb.NullableInt64;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableInt64.deserializeBinaryFromReader);
      msg.setWarningHours(value);
      break;
    case 10:
      var value = /** @type {!Array<number>} */ (reader.readPackedInt64());
      msg.setTrailerTypeIdsList(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setInbound(value);
      break;
    case 12:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOutbound(value);
      break;
    case 13:
      var value = /** @type {!Array<number>} */ (reader.readPackedInt64());
      msg.setDriverCertIdsList(value);
      break;
    case 14:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setByContainer(value);
      break;
    case 15:
      var value = new proto_vorto_types_types_pb.NullableString;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableString.deserializeBinaryFromReader);
      msg.setSalesOrderLineItemId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.CreateInventoryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.CreateInventoryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.CreateInventoryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.CreateInventoryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSiteId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getProductId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCapacity();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getStock();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getDefaultLoadQuantity();
  if (f !== 0.0) {
    writer.writeDouble(
      6,
      f
    );
  }
  f = message.getDefaultUnloadingMinutes();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
  f = message.getWarningPercentage();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto_vorto_types_types_pb.NullableDouble.serializeBinaryToWriter
    );
  }
  f = message.getWarningHours();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto_vorto_types_types_pb.NullableInt64.serializeBinaryToWriter
    );
  }
  f = message.getTrailerTypeIdsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      10,
      f
    );
  }
  f = message.getInbound();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
  f = message.getOutbound();
  if (f) {
    writer.writeBool(
      12,
      f
    );
  }
  f = message.getDriverCertIdsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      13,
      f
    );
  }
  f = message.getByContainer();
  if (f) {
    writer.writeBool(
      14,
      f
    );
  }
  f = message.getSalesOrderLineItemId();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      proto_vorto_types_types_pb.NullableString.serializeBinaryToWriter
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.vtv1.CreateInventoryRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.CreateInventoryRequest} returns this
 */
proto.vtv1.CreateInventoryRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string site_id = 2;
 * @return {string}
 */
proto.vtv1.CreateInventoryRequest.prototype.getSiteId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.CreateInventoryRequest} returns this
 */
proto.vtv1.CreateInventoryRequest.prototype.setSiteId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string product_id = 3;
 * @return {string}
 */
proto.vtv1.CreateInventoryRequest.prototype.getProductId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.CreateInventoryRequest} returns this
 */
proto.vtv1.CreateInventoryRequest.prototype.setProductId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional double capacity = 4;
 * @return {number}
 */
proto.vtv1.CreateInventoryRequest.prototype.getCapacity = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.CreateInventoryRequest} returns this
 */
proto.vtv1.CreateInventoryRequest.prototype.setCapacity = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional double stock = 5;
 * @return {number}
 */
proto.vtv1.CreateInventoryRequest.prototype.getStock = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.CreateInventoryRequest} returns this
 */
proto.vtv1.CreateInventoryRequest.prototype.setStock = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional double default_load_quantity = 6;
 * @return {number}
 */
proto.vtv1.CreateInventoryRequest.prototype.getDefaultLoadQuantity = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.CreateInventoryRequest} returns this
 */
proto.vtv1.CreateInventoryRequest.prototype.setDefaultLoadQuantity = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional int64 default_unloading_minutes = 7;
 * @return {number}
 */
proto.vtv1.CreateInventoryRequest.prototype.getDefaultUnloadingMinutes = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.CreateInventoryRequest} returns this
 */
proto.vtv1.CreateInventoryRequest.prototype.setDefaultUnloadingMinutes = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional NullableDouble warning_percentage = 8;
 * @return {?proto.vtv1.NullableDouble}
 */
proto.vtv1.CreateInventoryRequest.prototype.getWarningPercentage = function() {
  return /** @type{?proto.vtv1.NullableDouble} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableDouble, 8));
};


/**
 * @param {?proto.vtv1.NullableDouble|undefined} value
 * @return {!proto.vtv1.CreateInventoryRequest} returns this
*/
proto.vtv1.CreateInventoryRequest.prototype.setWarningPercentage = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.CreateInventoryRequest} returns this
 */
proto.vtv1.CreateInventoryRequest.prototype.clearWarningPercentage = function() {
  return this.setWarningPercentage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.CreateInventoryRequest.prototype.hasWarningPercentage = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional NullableInt64 warning_hours = 9;
 * @return {?proto.vtv1.NullableInt64}
 */
proto.vtv1.CreateInventoryRequest.prototype.getWarningHours = function() {
  return /** @type{?proto.vtv1.NullableInt64} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableInt64, 9));
};


/**
 * @param {?proto.vtv1.NullableInt64|undefined} value
 * @return {!proto.vtv1.CreateInventoryRequest} returns this
*/
proto.vtv1.CreateInventoryRequest.prototype.setWarningHours = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.CreateInventoryRequest} returns this
 */
proto.vtv1.CreateInventoryRequest.prototype.clearWarningHours = function() {
  return this.setWarningHours(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.CreateInventoryRequest.prototype.hasWarningHours = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * repeated int64 trailer_type_ids = 10;
 * @return {!Array<number>}
 */
proto.vtv1.CreateInventoryRequest.prototype.getTrailerTypeIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 10));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.vtv1.CreateInventoryRequest} returns this
 */
proto.vtv1.CreateInventoryRequest.prototype.setTrailerTypeIdsList = function(value) {
  return jspb.Message.setField(this, 10, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.vtv1.CreateInventoryRequest} returns this
 */
proto.vtv1.CreateInventoryRequest.prototype.addTrailerTypeIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 10, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.CreateInventoryRequest} returns this
 */
proto.vtv1.CreateInventoryRequest.prototype.clearTrailerTypeIdsList = function() {
  return this.setTrailerTypeIdsList([]);
};


/**
 * optional bool inbound = 11;
 * @return {boolean}
 */
proto.vtv1.CreateInventoryRequest.prototype.getInbound = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vtv1.CreateInventoryRequest} returns this
 */
proto.vtv1.CreateInventoryRequest.prototype.setInbound = function(value) {
  return jspb.Message.setProto3BooleanField(this, 11, value);
};


/**
 * optional bool outbound = 12;
 * @return {boolean}
 */
proto.vtv1.CreateInventoryRequest.prototype.getOutbound = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 12, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vtv1.CreateInventoryRequest} returns this
 */
proto.vtv1.CreateInventoryRequest.prototype.setOutbound = function(value) {
  return jspb.Message.setProto3BooleanField(this, 12, value);
};


/**
 * repeated int64 driver_cert_ids = 13;
 * @return {!Array<number>}
 */
proto.vtv1.CreateInventoryRequest.prototype.getDriverCertIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 13));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.vtv1.CreateInventoryRequest} returns this
 */
proto.vtv1.CreateInventoryRequest.prototype.setDriverCertIdsList = function(value) {
  return jspb.Message.setField(this, 13, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.vtv1.CreateInventoryRequest} returns this
 */
proto.vtv1.CreateInventoryRequest.prototype.addDriverCertIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 13, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.CreateInventoryRequest} returns this
 */
proto.vtv1.CreateInventoryRequest.prototype.clearDriverCertIdsList = function() {
  return this.setDriverCertIdsList([]);
};


/**
 * optional bool by_container = 14;
 * @return {boolean}
 */
proto.vtv1.CreateInventoryRequest.prototype.getByContainer = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 14, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vtv1.CreateInventoryRequest} returns this
 */
proto.vtv1.CreateInventoryRequest.prototype.setByContainer = function(value) {
  return jspb.Message.setProto3BooleanField(this, 14, value);
};


/**
 * optional NullableString sales_order_line_item_id = 15;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.CreateInventoryRequest.prototype.getSalesOrderLineItemId = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableString, 15));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.CreateInventoryRequest} returns this
*/
proto.vtv1.CreateInventoryRequest.prototype.setSalesOrderLineItemId = function(value) {
  return jspb.Message.setWrapperField(this, 15, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.CreateInventoryRequest} returns this
 */
proto.vtv1.CreateInventoryRequest.prototype.clearSalesOrderLineItemId = function() {
  return this.setSalesOrderLineItemId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.CreateInventoryRequest.prototype.hasSalesOrderLineItemId = function() {
  return jspb.Message.getField(this, 15) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.NodeListDistrictsBySitesRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.NodeListDistrictsBySitesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.NodeListDistrictsBySitesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.NodeListDistrictsBySitesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.NodeListDistrictsBySitesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    siteIdsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.NodeListDistrictsBySitesRequest}
 */
proto.vtv1.NodeListDistrictsBySitesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.NodeListDistrictsBySitesRequest;
  return proto.vtv1.NodeListDistrictsBySitesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.NodeListDistrictsBySitesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.NodeListDistrictsBySitesRequest}
 */
proto.vtv1.NodeListDistrictsBySitesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addSiteIds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.NodeListDistrictsBySitesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.NodeListDistrictsBySitesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.NodeListDistrictsBySitesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.NodeListDistrictsBySitesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSiteIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
};


/**
 * repeated string site_ids = 1;
 * @return {!Array<string>}
 */
proto.vtv1.NodeListDistrictsBySitesRequest.prototype.getSiteIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.vtv1.NodeListDistrictsBySitesRequest} returns this
 */
proto.vtv1.NodeListDistrictsBySitesRequest.prototype.setSiteIdsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.vtv1.NodeListDistrictsBySitesRequest} returns this
 */
proto.vtv1.NodeListDistrictsBySitesRequest.prototype.addSiteIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.NodeListDistrictsBySitesRequest} returns this
 */
proto.vtv1.NodeListDistrictsBySitesRequest.prototype.clearSiteIdsList = function() {
  return this.setSiteIdsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.CreateInventoryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.CreateInventoryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.CreateInventoryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.CreateInventoryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    inventory: (f = msg.getInventory()) && proto_vorto_node_node_pb.InventoryDetail.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.CreateInventoryResponse}
 */
proto.vtv1.CreateInventoryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.CreateInventoryResponse;
  return proto.vtv1.CreateInventoryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.CreateInventoryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.CreateInventoryResponse}
 */
proto.vtv1.CreateInventoryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_vorto_node_node_pb.InventoryDetail;
      reader.readMessage(value,proto_vorto_node_node_pb.InventoryDetail.deserializeBinaryFromReader);
      msg.setInventory(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.CreateInventoryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.CreateInventoryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.CreateInventoryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.CreateInventoryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInventory();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_vorto_node_node_pb.InventoryDetail.serializeBinaryToWriter
    );
  }
};


/**
 * optional InventoryDetail inventory = 1;
 * @return {?proto.vtv1.InventoryDetail}
 */
proto.vtv1.CreateInventoryResponse.prototype.getInventory = function() {
  return /** @type{?proto.vtv1.InventoryDetail} */ (
    jspb.Message.getWrapperField(this, proto_vorto_node_node_pb.InventoryDetail, 1));
};


/**
 * @param {?proto.vtv1.InventoryDetail|undefined} value
 * @return {!proto.vtv1.CreateInventoryResponse} returns this
*/
proto.vtv1.CreateInventoryResponse.prototype.setInventory = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.CreateInventoryResponse} returns this
 */
proto.vtv1.CreateInventoryResponse.prototype.clearInventory = function() {
  return this.setInventory(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.CreateInventoryResponse.prototype.hasInventory = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.ListSiteInventoriesByGroupResponse.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.ListSiteInventoriesByGroupResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.ListSiteInventoriesByGroupResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.ListSiteInventoriesByGroupResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.ListSiteInventoriesByGroupResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    groupedInventoriesList: jspb.Message.toObjectList(msg.getGroupedInventoriesList(),
    proto_vorto_node_node_pb.InventoryGroup.toObject, includeInstance),
    ungroupedInventoriesList: jspb.Message.toObjectList(msg.getUngroupedInventoriesList(),
    proto_vorto_node_node_pb.Inventory.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.ListSiteInventoriesByGroupResponse}
 */
proto.vtv1.ListSiteInventoriesByGroupResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.ListSiteInventoriesByGroupResponse;
  return proto.vtv1.ListSiteInventoriesByGroupResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.ListSiteInventoriesByGroupResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.ListSiteInventoriesByGroupResponse}
 */
proto.vtv1.ListSiteInventoriesByGroupResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_vorto_node_node_pb.InventoryGroup;
      reader.readMessage(value,proto_vorto_node_node_pb.InventoryGroup.deserializeBinaryFromReader);
      msg.addGroupedInventories(value);
      break;
    case 2:
      var value = new proto_vorto_node_node_pb.Inventory;
      reader.readMessage(value,proto_vorto_node_node_pb.Inventory.deserializeBinaryFromReader);
      msg.addUngroupedInventories(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.ListSiteInventoriesByGroupResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.ListSiteInventoriesByGroupResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.ListSiteInventoriesByGroupResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.ListSiteInventoriesByGroupResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGroupedInventoriesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto_vorto_node_node_pb.InventoryGroup.serializeBinaryToWriter
    );
  }
  f = message.getUngroupedInventoriesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto_vorto_node_node_pb.Inventory.serializeBinaryToWriter
    );
  }
};


/**
 * repeated InventoryGroup grouped_inventories = 1;
 * @return {!Array<!proto.vtv1.InventoryGroup>}
 */
proto.vtv1.ListSiteInventoriesByGroupResponse.prototype.getGroupedInventoriesList = function() {
  return /** @type{!Array<!proto.vtv1.InventoryGroup>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_vorto_node_node_pb.InventoryGroup, 1));
};


/**
 * @param {!Array<!proto.vtv1.InventoryGroup>} value
 * @return {!proto.vtv1.ListSiteInventoriesByGroupResponse} returns this
*/
proto.vtv1.ListSiteInventoriesByGroupResponse.prototype.setGroupedInventoriesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.vtv1.InventoryGroup=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.InventoryGroup}
 */
proto.vtv1.ListSiteInventoriesByGroupResponse.prototype.addGroupedInventories = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.vtv1.InventoryGroup, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.ListSiteInventoriesByGroupResponse} returns this
 */
proto.vtv1.ListSiteInventoriesByGroupResponse.prototype.clearGroupedInventoriesList = function() {
  return this.setGroupedInventoriesList([]);
};


/**
 * repeated Inventory ungrouped_inventories = 2;
 * @return {!Array<!proto.vtv1.Inventory>}
 */
proto.vtv1.ListSiteInventoriesByGroupResponse.prototype.getUngroupedInventoriesList = function() {
  return /** @type{!Array<!proto.vtv1.Inventory>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_vorto_node_node_pb.Inventory, 2));
};


/**
 * @param {!Array<!proto.vtv1.Inventory>} value
 * @return {!proto.vtv1.ListSiteInventoriesByGroupResponse} returns this
*/
proto.vtv1.ListSiteInventoriesByGroupResponse.prototype.setUngroupedInventoriesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.vtv1.Inventory=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.Inventory}
 */
proto.vtv1.ListSiteInventoriesByGroupResponse.prototype.addUngroupedInventories = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.vtv1.Inventory, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.ListSiteInventoriesByGroupResponse} returns this
 */
proto.vtv1.ListSiteInventoriesByGroupResponse.prototype.clearUngroupedInventoriesList = function() {
  return this.setUngroupedInventoriesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.ListSiteInventoriesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.ListSiteInventoriesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.ListSiteInventoriesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.ListSiteInventoriesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.ListSiteInventoriesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    inventoriesList: jspb.Message.toObjectList(msg.getInventoriesList(),
    proto_vorto_node_node_pb.Inventory.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.ListSiteInventoriesResponse}
 */
proto.vtv1.ListSiteInventoriesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.ListSiteInventoriesResponse;
  return proto.vtv1.ListSiteInventoriesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.ListSiteInventoriesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.ListSiteInventoriesResponse}
 */
proto.vtv1.ListSiteInventoriesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_vorto_node_node_pb.Inventory;
      reader.readMessage(value,proto_vorto_node_node_pb.Inventory.deserializeBinaryFromReader);
      msg.addInventories(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.ListSiteInventoriesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.ListSiteInventoriesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.ListSiteInventoriesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.ListSiteInventoriesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInventoriesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto_vorto_node_node_pb.Inventory.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Inventory inventories = 1;
 * @return {!Array<!proto.vtv1.Inventory>}
 */
proto.vtv1.ListSiteInventoriesResponse.prototype.getInventoriesList = function() {
  return /** @type{!Array<!proto.vtv1.Inventory>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_vorto_node_node_pb.Inventory, 1));
};


/**
 * @param {!Array<!proto.vtv1.Inventory>} value
 * @return {!proto.vtv1.ListSiteInventoriesResponse} returns this
*/
proto.vtv1.ListSiteInventoriesResponse.prototype.setInventoriesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.vtv1.Inventory=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.Inventory}
 */
proto.vtv1.ListSiteInventoriesResponse.prototype.addInventories = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.vtv1.Inventory, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.ListSiteInventoriesResponse} returns this
 */
proto.vtv1.ListSiteInventoriesResponse.prototype.clearInventoriesList = function() {
  return this.setInventoriesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.EditInventoryRequest.repeatedFields_ = [8,14];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.EditInventoryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.EditInventoryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.EditInventoryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.EditInventoryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    inventoryId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: (f = msg.getName()) && proto_vorto_types_types_pb.NullableString.toObject(includeInstance, f),
    capacity: (f = msg.getCapacity()) && proto_vorto_types_types_pb.NullableDouble.toObject(includeInstance, f),
    defaultLoadQuantity: (f = msg.getDefaultLoadQuantity()) && proto_vorto_types_types_pb.NullableDouble.toObject(includeInstance, f),
    defaultUnloadingMinutes: (f = msg.getDefaultUnloadingMinutes()) && proto_vorto_types_types_pb.NullableInt64.toObject(includeInstance, f),
    warningPercentage: (f = msg.getWarningPercentage()) && proto_vorto_types_types_pb.NullableDouble.toObject(includeInstance, f),
    warningHours: (f = msg.getWarningHours()) && proto_vorto_types_types_pb.NullableInt64.toObject(includeInstance, f),
    trailerTypeIdsList: (f = jspb.Message.getRepeatedField(msg, 8)) == null ? undefined : f,
    inbound: jspb.Message.getBooleanFieldWithDefault(msg, 11, false),
    outbound: jspb.Message.getBooleanFieldWithDefault(msg, 12, false),
    reloadWindow: jspb.Message.getFieldWithDefault(msg, 13, 0),
    driverCertIdsList: (f = jspb.Message.getRepeatedField(msg, 14)) == null ? undefined : f,
    byContainer: jspb.Message.getBooleanFieldWithDefault(msg, 15, false),
    salesOrderLineItemId: (f = msg.getSalesOrderLineItemId()) && proto_vorto_types_types_pb.NullableString.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.EditInventoryRequest}
 */
proto.vtv1.EditInventoryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.EditInventoryRequest;
  return proto.vtv1.EditInventoryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.EditInventoryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.EditInventoryRequest}
 */
proto.vtv1.EditInventoryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setInventoryId(value);
      break;
    case 2:
      var value = new proto_vorto_types_types_pb.NullableString;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableString.deserializeBinaryFromReader);
      msg.setName(value);
      break;
    case 3:
      var value = new proto_vorto_types_types_pb.NullableDouble;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableDouble.deserializeBinaryFromReader);
      msg.setCapacity(value);
      break;
    case 4:
      var value = new proto_vorto_types_types_pb.NullableDouble;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableDouble.deserializeBinaryFromReader);
      msg.setDefaultLoadQuantity(value);
      break;
    case 5:
      var value = new proto_vorto_types_types_pb.NullableInt64;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableInt64.deserializeBinaryFromReader);
      msg.setDefaultUnloadingMinutes(value);
      break;
    case 6:
      var value = new proto_vorto_types_types_pb.NullableDouble;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableDouble.deserializeBinaryFromReader);
      msg.setWarningPercentage(value);
      break;
    case 7:
      var value = new proto_vorto_types_types_pb.NullableInt64;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableInt64.deserializeBinaryFromReader);
      msg.setWarningHours(value);
      break;
    case 8:
      var value = /** @type {!Array<number>} */ (reader.readPackedInt64());
      msg.setTrailerTypeIdsList(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setInbound(value);
      break;
    case 12:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOutbound(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setReloadWindow(value);
      break;
    case 14:
      var value = /** @type {!Array<number>} */ (reader.readPackedInt64());
      msg.setDriverCertIdsList(value);
      break;
    case 15:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setByContainer(value);
      break;
    case 16:
      var value = new proto_vorto_types_types_pb.NullableString;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableString.deserializeBinaryFromReader);
      msg.setSalesOrderLineItemId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.EditInventoryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.EditInventoryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.EditInventoryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.EditInventoryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInventoryId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_vorto_types_types_pb.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getCapacity();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto_vorto_types_types_pb.NullableDouble.serializeBinaryToWriter
    );
  }
  f = message.getDefaultLoadQuantity();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto_vorto_types_types_pb.NullableDouble.serializeBinaryToWriter
    );
  }
  f = message.getDefaultUnloadingMinutes();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto_vorto_types_types_pb.NullableInt64.serializeBinaryToWriter
    );
  }
  f = message.getWarningPercentage();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto_vorto_types_types_pb.NullableDouble.serializeBinaryToWriter
    );
  }
  f = message.getWarningHours();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto_vorto_types_types_pb.NullableInt64.serializeBinaryToWriter
    );
  }
  f = message.getTrailerTypeIdsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      8,
      f
    );
  }
  f = message.getInbound();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
  f = message.getOutbound();
  if (f) {
    writer.writeBool(
      12,
      f
    );
  }
  f = message.getReloadWindow();
  if (f !== 0) {
    writer.writeInt64(
      13,
      f
    );
  }
  f = message.getDriverCertIdsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      14,
      f
    );
  }
  f = message.getByContainer();
  if (f) {
    writer.writeBool(
      15,
      f
    );
  }
  f = message.getSalesOrderLineItemId();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      proto_vorto_types_types_pb.NullableString.serializeBinaryToWriter
    );
  }
};


/**
 * optional string inventory_id = 1;
 * @return {string}
 */
proto.vtv1.EditInventoryRequest.prototype.getInventoryId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.EditInventoryRequest} returns this
 */
proto.vtv1.EditInventoryRequest.prototype.setInventoryId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional NullableString name = 2;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.EditInventoryRequest.prototype.getName = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableString, 2));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.EditInventoryRequest} returns this
*/
proto.vtv1.EditInventoryRequest.prototype.setName = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.EditInventoryRequest} returns this
 */
proto.vtv1.EditInventoryRequest.prototype.clearName = function() {
  return this.setName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.EditInventoryRequest.prototype.hasName = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional NullableDouble capacity = 3;
 * @return {?proto.vtv1.NullableDouble}
 */
proto.vtv1.EditInventoryRequest.prototype.getCapacity = function() {
  return /** @type{?proto.vtv1.NullableDouble} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableDouble, 3));
};


/**
 * @param {?proto.vtv1.NullableDouble|undefined} value
 * @return {!proto.vtv1.EditInventoryRequest} returns this
*/
proto.vtv1.EditInventoryRequest.prototype.setCapacity = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.EditInventoryRequest} returns this
 */
proto.vtv1.EditInventoryRequest.prototype.clearCapacity = function() {
  return this.setCapacity(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.EditInventoryRequest.prototype.hasCapacity = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional NullableDouble default_load_quantity = 4;
 * @return {?proto.vtv1.NullableDouble}
 */
proto.vtv1.EditInventoryRequest.prototype.getDefaultLoadQuantity = function() {
  return /** @type{?proto.vtv1.NullableDouble} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableDouble, 4));
};


/**
 * @param {?proto.vtv1.NullableDouble|undefined} value
 * @return {!proto.vtv1.EditInventoryRequest} returns this
*/
proto.vtv1.EditInventoryRequest.prototype.setDefaultLoadQuantity = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.EditInventoryRequest} returns this
 */
proto.vtv1.EditInventoryRequest.prototype.clearDefaultLoadQuantity = function() {
  return this.setDefaultLoadQuantity(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.EditInventoryRequest.prototype.hasDefaultLoadQuantity = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional NullableInt64 default_unloading_minutes = 5;
 * @return {?proto.vtv1.NullableInt64}
 */
proto.vtv1.EditInventoryRequest.prototype.getDefaultUnloadingMinutes = function() {
  return /** @type{?proto.vtv1.NullableInt64} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableInt64, 5));
};


/**
 * @param {?proto.vtv1.NullableInt64|undefined} value
 * @return {!proto.vtv1.EditInventoryRequest} returns this
*/
proto.vtv1.EditInventoryRequest.prototype.setDefaultUnloadingMinutes = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.EditInventoryRequest} returns this
 */
proto.vtv1.EditInventoryRequest.prototype.clearDefaultUnloadingMinutes = function() {
  return this.setDefaultUnloadingMinutes(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.EditInventoryRequest.prototype.hasDefaultUnloadingMinutes = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional NullableDouble warning_percentage = 6;
 * @return {?proto.vtv1.NullableDouble}
 */
proto.vtv1.EditInventoryRequest.prototype.getWarningPercentage = function() {
  return /** @type{?proto.vtv1.NullableDouble} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableDouble, 6));
};


/**
 * @param {?proto.vtv1.NullableDouble|undefined} value
 * @return {!proto.vtv1.EditInventoryRequest} returns this
*/
proto.vtv1.EditInventoryRequest.prototype.setWarningPercentage = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.EditInventoryRequest} returns this
 */
proto.vtv1.EditInventoryRequest.prototype.clearWarningPercentage = function() {
  return this.setWarningPercentage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.EditInventoryRequest.prototype.hasWarningPercentage = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional NullableInt64 warning_hours = 7;
 * @return {?proto.vtv1.NullableInt64}
 */
proto.vtv1.EditInventoryRequest.prototype.getWarningHours = function() {
  return /** @type{?proto.vtv1.NullableInt64} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableInt64, 7));
};


/**
 * @param {?proto.vtv1.NullableInt64|undefined} value
 * @return {!proto.vtv1.EditInventoryRequest} returns this
*/
proto.vtv1.EditInventoryRequest.prototype.setWarningHours = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.EditInventoryRequest} returns this
 */
proto.vtv1.EditInventoryRequest.prototype.clearWarningHours = function() {
  return this.setWarningHours(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.EditInventoryRequest.prototype.hasWarningHours = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * repeated int64 trailer_type_ids = 8;
 * @return {!Array<number>}
 */
proto.vtv1.EditInventoryRequest.prototype.getTrailerTypeIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 8));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.vtv1.EditInventoryRequest} returns this
 */
proto.vtv1.EditInventoryRequest.prototype.setTrailerTypeIdsList = function(value) {
  return jspb.Message.setField(this, 8, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.vtv1.EditInventoryRequest} returns this
 */
proto.vtv1.EditInventoryRequest.prototype.addTrailerTypeIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 8, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.EditInventoryRequest} returns this
 */
proto.vtv1.EditInventoryRequest.prototype.clearTrailerTypeIdsList = function() {
  return this.setTrailerTypeIdsList([]);
};


/**
 * optional bool inbound = 11;
 * @return {boolean}
 */
proto.vtv1.EditInventoryRequest.prototype.getInbound = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vtv1.EditInventoryRequest} returns this
 */
proto.vtv1.EditInventoryRequest.prototype.setInbound = function(value) {
  return jspb.Message.setProto3BooleanField(this, 11, value);
};


/**
 * optional bool outbound = 12;
 * @return {boolean}
 */
proto.vtv1.EditInventoryRequest.prototype.getOutbound = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 12, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vtv1.EditInventoryRequest} returns this
 */
proto.vtv1.EditInventoryRequest.prototype.setOutbound = function(value) {
  return jspb.Message.setProto3BooleanField(this, 12, value);
};


/**
 * optional int64 reload_window = 13;
 * @return {number}
 */
proto.vtv1.EditInventoryRequest.prototype.getReloadWindow = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.EditInventoryRequest} returns this
 */
proto.vtv1.EditInventoryRequest.prototype.setReloadWindow = function(value) {
  return jspb.Message.setProto3IntField(this, 13, value);
};


/**
 * repeated int64 driver_cert_ids = 14;
 * @return {!Array<number>}
 */
proto.vtv1.EditInventoryRequest.prototype.getDriverCertIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 14));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.vtv1.EditInventoryRequest} returns this
 */
proto.vtv1.EditInventoryRequest.prototype.setDriverCertIdsList = function(value) {
  return jspb.Message.setField(this, 14, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.vtv1.EditInventoryRequest} returns this
 */
proto.vtv1.EditInventoryRequest.prototype.addDriverCertIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 14, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.EditInventoryRequest} returns this
 */
proto.vtv1.EditInventoryRequest.prototype.clearDriverCertIdsList = function() {
  return this.setDriverCertIdsList([]);
};


/**
 * optional bool by_container = 15;
 * @return {boolean}
 */
proto.vtv1.EditInventoryRequest.prototype.getByContainer = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 15, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vtv1.EditInventoryRequest} returns this
 */
proto.vtv1.EditInventoryRequest.prototype.setByContainer = function(value) {
  return jspb.Message.setProto3BooleanField(this, 15, value);
};


/**
 * optional NullableString sales_order_line_item_id = 16;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.EditInventoryRequest.prototype.getSalesOrderLineItemId = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableString, 16));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.EditInventoryRequest} returns this
*/
proto.vtv1.EditInventoryRequest.prototype.setSalesOrderLineItemId = function(value) {
  return jspb.Message.setWrapperField(this, 16, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.EditInventoryRequest} returns this
 */
proto.vtv1.EditInventoryRequest.prototype.clearSalesOrderLineItemId = function() {
  return this.setSalesOrderLineItemId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.EditInventoryRequest.prototype.hasSalesOrderLineItemId = function() {
  return jspb.Message.getField(this, 16) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.EditInventoryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.EditInventoryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.EditInventoryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.EditInventoryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    inventory: (f = msg.getInventory()) && proto_vorto_node_node_pb.InventoryDetail.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.EditInventoryResponse}
 */
proto.vtv1.EditInventoryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.EditInventoryResponse;
  return proto.vtv1.EditInventoryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.EditInventoryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.EditInventoryResponse}
 */
proto.vtv1.EditInventoryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_vorto_node_node_pb.InventoryDetail;
      reader.readMessage(value,proto_vorto_node_node_pb.InventoryDetail.deserializeBinaryFromReader);
      msg.setInventory(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.EditInventoryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.EditInventoryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.EditInventoryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.EditInventoryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInventory();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_vorto_node_node_pb.InventoryDetail.serializeBinaryToWriter
    );
  }
};


/**
 * optional InventoryDetail inventory = 1;
 * @return {?proto.vtv1.InventoryDetail}
 */
proto.vtv1.EditInventoryResponse.prototype.getInventory = function() {
  return /** @type{?proto.vtv1.InventoryDetail} */ (
    jspb.Message.getWrapperField(this, proto_vorto_node_node_pb.InventoryDetail, 1));
};


/**
 * @param {?proto.vtv1.InventoryDetail|undefined} value
 * @return {!proto.vtv1.EditInventoryResponse} returns this
*/
proto.vtv1.EditInventoryResponse.prototype.setInventory = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.EditInventoryResponse} returns this
 */
proto.vtv1.EditInventoryResponse.prototype.clearInventory = function() {
  return this.setInventory(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.EditInventoryResponse.prototype.hasInventory = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.UpdateInventoryStockRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.UpdateInventoryStockRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.UpdateInventoryStockRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.UpdateInventoryStockRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    inventoryId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    stock: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    reloadWindow: (f = msg.getReloadWindow()) && proto_vorto_types_types_pb.NullableInt64.toObject(includeInstance, f),
    addBackhauls: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    updateStock: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    leadtimeHours: (f = msg.getLeadtimeHours()) && proto_vorto_types_types_pb.NullableInt64.toObject(includeInstance, f),
    reverseBackhaul: jspb.Message.getBooleanFieldWithDefault(msg, 7, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.UpdateInventoryStockRequest}
 */
proto.vtv1.UpdateInventoryStockRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.UpdateInventoryStockRequest;
  return proto.vtv1.UpdateInventoryStockRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.UpdateInventoryStockRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.UpdateInventoryStockRequest}
 */
proto.vtv1.UpdateInventoryStockRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setInventoryId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setStock(value);
      break;
    case 3:
      var value = new proto_vorto_types_types_pb.NullableInt64;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableInt64.deserializeBinaryFromReader);
      msg.setReloadWindow(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAddBackhauls(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setUpdateStock(value);
      break;
    case 6:
      var value = new proto_vorto_types_types_pb.NullableInt64;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableInt64.deserializeBinaryFromReader);
      msg.setLeadtimeHours(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setReverseBackhaul(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.UpdateInventoryStockRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.UpdateInventoryStockRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.UpdateInventoryStockRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.UpdateInventoryStockRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInventoryId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStock();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getReloadWindow();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto_vorto_types_types_pb.NullableInt64.serializeBinaryToWriter
    );
  }
  f = message.getAddBackhauls();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getUpdateStock();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getLeadtimeHours();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto_vorto_types_types_pb.NullableInt64.serializeBinaryToWriter
    );
  }
  f = message.getReverseBackhaul();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
};


/**
 * optional string inventory_id = 1;
 * @return {string}
 */
proto.vtv1.UpdateInventoryStockRequest.prototype.getInventoryId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.UpdateInventoryStockRequest} returns this
 */
proto.vtv1.UpdateInventoryStockRequest.prototype.setInventoryId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional double stock = 2;
 * @return {number}
 */
proto.vtv1.UpdateInventoryStockRequest.prototype.getStock = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.UpdateInventoryStockRequest} returns this
 */
proto.vtv1.UpdateInventoryStockRequest.prototype.setStock = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional NullableInt64 reload_window = 3;
 * @return {?proto.vtv1.NullableInt64}
 */
proto.vtv1.UpdateInventoryStockRequest.prototype.getReloadWindow = function() {
  return /** @type{?proto.vtv1.NullableInt64} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableInt64, 3));
};


/**
 * @param {?proto.vtv1.NullableInt64|undefined} value
 * @return {!proto.vtv1.UpdateInventoryStockRequest} returns this
*/
proto.vtv1.UpdateInventoryStockRequest.prototype.setReloadWindow = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.UpdateInventoryStockRequest} returns this
 */
proto.vtv1.UpdateInventoryStockRequest.prototype.clearReloadWindow = function() {
  return this.setReloadWindow(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.UpdateInventoryStockRequest.prototype.hasReloadWindow = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional bool add_backhauls = 4;
 * @return {boolean}
 */
proto.vtv1.UpdateInventoryStockRequest.prototype.getAddBackhauls = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vtv1.UpdateInventoryStockRequest} returns this
 */
proto.vtv1.UpdateInventoryStockRequest.prototype.setAddBackhauls = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional bool update_stock = 5;
 * @return {boolean}
 */
proto.vtv1.UpdateInventoryStockRequest.prototype.getUpdateStock = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vtv1.UpdateInventoryStockRequest} returns this
 */
proto.vtv1.UpdateInventoryStockRequest.prototype.setUpdateStock = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional NullableInt64 leadtime_hours = 6;
 * @return {?proto.vtv1.NullableInt64}
 */
proto.vtv1.UpdateInventoryStockRequest.prototype.getLeadtimeHours = function() {
  return /** @type{?proto.vtv1.NullableInt64} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableInt64, 6));
};


/**
 * @param {?proto.vtv1.NullableInt64|undefined} value
 * @return {!proto.vtv1.UpdateInventoryStockRequest} returns this
*/
proto.vtv1.UpdateInventoryStockRequest.prototype.setLeadtimeHours = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.UpdateInventoryStockRequest} returns this
 */
proto.vtv1.UpdateInventoryStockRequest.prototype.clearLeadtimeHours = function() {
  return this.setLeadtimeHours(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.UpdateInventoryStockRequest.prototype.hasLeadtimeHours = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional bool reverse_backhaul = 7;
 * @return {boolean}
 */
proto.vtv1.UpdateInventoryStockRequest.prototype.getReverseBackhaul = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vtv1.UpdateInventoryStockRequest} returns this
 */
proto.vtv1.UpdateInventoryStockRequest.prototype.setReverseBackhaul = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.UpdateInventoryStockResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.UpdateInventoryStockResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.UpdateInventoryStockResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.UpdateInventoryStockResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    inventory: (f = msg.getInventory()) && proto_vorto_node_node_pb.InventoryDetail.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.UpdateInventoryStockResponse}
 */
proto.vtv1.UpdateInventoryStockResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.UpdateInventoryStockResponse;
  return proto.vtv1.UpdateInventoryStockResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.UpdateInventoryStockResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.UpdateInventoryStockResponse}
 */
proto.vtv1.UpdateInventoryStockResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_vorto_node_node_pb.InventoryDetail;
      reader.readMessage(value,proto_vorto_node_node_pb.InventoryDetail.deserializeBinaryFromReader);
      msg.setInventory(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.UpdateInventoryStockResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.UpdateInventoryStockResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.UpdateInventoryStockResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.UpdateInventoryStockResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInventory();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_vorto_node_node_pb.InventoryDetail.serializeBinaryToWriter
    );
  }
};


/**
 * optional InventoryDetail inventory = 1;
 * @return {?proto.vtv1.InventoryDetail}
 */
proto.vtv1.UpdateInventoryStockResponse.prototype.getInventory = function() {
  return /** @type{?proto.vtv1.InventoryDetail} */ (
    jspb.Message.getWrapperField(this, proto_vorto_node_node_pb.InventoryDetail, 1));
};


/**
 * @param {?proto.vtv1.InventoryDetail|undefined} value
 * @return {!proto.vtv1.UpdateInventoryStockResponse} returns this
*/
proto.vtv1.UpdateInventoryStockResponse.prototype.setInventory = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.UpdateInventoryStockResponse} returns this
 */
proto.vtv1.UpdateInventoryStockResponse.prototype.clearInventory = function() {
  return this.setInventory(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.UpdateInventoryStockResponse.prototype.hasInventory = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.InventoryDetailResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.InventoryDetailResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.InventoryDetailResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.InventoryDetailResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    inventoryDetail: (f = msg.getInventoryDetail()) && proto_vorto_node_node_pb.InventoryDetail.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.InventoryDetailResponse}
 */
proto.vtv1.InventoryDetailResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.InventoryDetailResponse;
  return proto.vtv1.InventoryDetailResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.InventoryDetailResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.InventoryDetailResponse}
 */
proto.vtv1.InventoryDetailResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_vorto_node_node_pb.InventoryDetail;
      reader.readMessage(value,proto_vorto_node_node_pb.InventoryDetail.deserializeBinaryFromReader);
      msg.setInventoryDetail(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.InventoryDetailResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.InventoryDetailResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.InventoryDetailResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.InventoryDetailResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInventoryDetail();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_vorto_node_node_pb.InventoryDetail.serializeBinaryToWriter
    );
  }
};


/**
 * optional InventoryDetail inventory_detail = 1;
 * @return {?proto.vtv1.InventoryDetail}
 */
proto.vtv1.InventoryDetailResponse.prototype.getInventoryDetail = function() {
  return /** @type{?proto.vtv1.InventoryDetail} */ (
    jspb.Message.getWrapperField(this, proto_vorto_node_node_pb.InventoryDetail, 1));
};


/**
 * @param {?proto.vtv1.InventoryDetail|undefined} value
 * @return {!proto.vtv1.InventoryDetailResponse} returns this
*/
proto.vtv1.InventoryDetailResponse.prototype.setInventoryDetail = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.InventoryDetailResponse} returns this
 */
proto.vtv1.InventoryDetailResponse.prototype.clearInventoryDetail = function() {
  return this.setInventoryDetail(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.InventoryDetailResponse.prototype.hasInventoryDetail = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.PotentialSuppliersResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.PotentialSuppliersResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.PotentialSuppliersResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.PotentialSuppliersResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.PotentialSuppliersResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    suppliersList: jspb.Message.toObjectList(msg.getSuppliersList(),
    proto_vorto_node_node_pb.PotentialInventorySupplier.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.PotentialSuppliersResponse}
 */
proto.vtv1.PotentialSuppliersResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.PotentialSuppliersResponse;
  return proto.vtv1.PotentialSuppliersResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.PotentialSuppliersResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.PotentialSuppliersResponse}
 */
proto.vtv1.PotentialSuppliersResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_vorto_node_node_pb.PotentialInventorySupplier;
      reader.readMessage(value,proto_vorto_node_node_pb.PotentialInventorySupplier.deserializeBinaryFromReader);
      msg.addSuppliers(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.PotentialSuppliersResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.PotentialSuppliersResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.PotentialSuppliersResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.PotentialSuppliersResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuppliersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto_vorto_node_node_pb.PotentialInventorySupplier.serializeBinaryToWriter
    );
  }
};


/**
 * repeated PotentialInventorySupplier suppliers = 1;
 * @return {!Array<!proto.vtv1.PotentialInventorySupplier>}
 */
proto.vtv1.PotentialSuppliersResponse.prototype.getSuppliersList = function() {
  return /** @type{!Array<!proto.vtv1.PotentialInventorySupplier>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_vorto_node_node_pb.PotentialInventorySupplier, 1));
};


/**
 * @param {!Array<!proto.vtv1.PotentialInventorySupplier>} value
 * @return {!proto.vtv1.PotentialSuppliersResponse} returns this
*/
proto.vtv1.PotentialSuppliersResponse.prototype.setSuppliersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.vtv1.PotentialInventorySupplier=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.PotentialInventorySupplier}
 */
proto.vtv1.PotentialSuppliersResponse.prototype.addSuppliers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.vtv1.PotentialInventorySupplier, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.PotentialSuppliersResponse} returns this
 */
proto.vtv1.PotentialSuppliersResponse.prototype.clearSuppliersList = function() {
  return this.setSuppliersList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.PotentialPurchaseOrdersResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.PotentialPurchaseOrdersResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.PotentialPurchaseOrdersResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.PotentialPurchaseOrdersResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.PotentialPurchaseOrdersResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    purchaseOrdersList: jspb.Message.toObjectList(msg.getPurchaseOrdersList(),
    proto_vorto_node_node_pb.PotentialInventoryPurchaseOrder.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.PotentialPurchaseOrdersResponse}
 */
proto.vtv1.PotentialPurchaseOrdersResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.PotentialPurchaseOrdersResponse;
  return proto.vtv1.PotentialPurchaseOrdersResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.PotentialPurchaseOrdersResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.PotentialPurchaseOrdersResponse}
 */
proto.vtv1.PotentialPurchaseOrdersResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_vorto_node_node_pb.PotentialInventoryPurchaseOrder;
      reader.readMessage(value,proto_vorto_node_node_pb.PotentialInventoryPurchaseOrder.deserializeBinaryFromReader);
      msg.addPurchaseOrders(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.PotentialPurchaseOrdersResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.PotentialPurchaseOrdersResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.PotentialPurchaseOrdersResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.PotentialPurchaseOrdersResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPurchaseOrdersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto_vorto_node_node_pb.PotentialInventoryPurchaseOrder.serializeBinaryToWriter
    );
  }
};


/**
 * repeated PotentialInventoryPurchaseOrder purchase_orders = 1;
 * @return {!Array<!proto.vtv1.PotentialInventoryPurchaseOrder>}
 */
proto.vtv1.PotentialPurchaseOrdersResponse.prototype.getPurchaseOrdersList = function() {
  return /** @type{!Array<!proto.vtv1.PotentialInventoryPurchaseOrder>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_vorto_node_node_pb.PotentialInventoryPurchaseOrder, 1));
};


/**
 * @param {!Array<!proto.vtv1.PotentialInventoryPurchaseOrder>} value
 * @return {!proto.vtv1.PotentialPurchaseOrdersResponse} returns this
*/
proto.vtv1.PotentialPurchaseOrdersResponse.prototype.setPurchaseOrdersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.vtv1.PotentialInventoryPurchaseOrder=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.PotentialInventoryPurchaseOrder}
 */
proto.vtv1.PotentialPurchaseOrdersResponse.prototype.addPurchaseOrders = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.vtv1.PotentialInventoryPurchaseOrder, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.PotentialPurchaseOrdersResponse} returns this
 */
proto.vtv1.PotentialPurchaseOrdersResponse.prototype.clearPurchaseOrdersList = function() {
  return this.setPurchaseOrdersList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.PotentialPurchaseOrdersV2Response.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.PotentialPurchaseOrdersV2Response.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.PotentialPurchaseOrdersV2Response.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.PotentialPurchaseOrdersV2Response} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.PotentialPurchaseOrdersV2Response.toObject = function(includeInstance, msg) {
  var f, obj = {
    purchaseOrdersList: jspb.Message.toObjectList(msg.getPurchaseOrdersList(),
    proto_vorto_node_node_pb.PotentialInventoryPurchaseOrderV2.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.PotentialPurchaseOrdersV2Response}
 */
proto.vtv1.PotentialPurchaseOrdersV2Response.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.PotentialPurchaseOrdersV2Response;
  return proto.vtv1.PotentialPurchaseOrdersV2Response.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.PotentialPurchaseOrdersV2Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.PotentialPurchaseOrdersV2Response}
 */
proto.vtv1.PotentialPurchaseOrdersV2Response.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_vorto_node_node_pb.PotentialInventoryPurchaseOrderV2;
      reader.readMessage(value,proto_vorto_node_node_pb.PotentialInventoryPurchaseOrderV2.deserializeBinaryFromReader);
      msg.addPurchaseOrders(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.PotentialPurchaseOrdersV2Response.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.PotentialPurchaseOrdersV2Response.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.PotentialPurchaseOrdersV2Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.PotentialPurchaseOrdersV2Response.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPurchaseOrdersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto_vorto_node_node_pb.PotentialInventoryPurchaseOrderV2.serializeBinaryToWriter
    );
  }
};


/**
 * repeated PotentialInventoryPurchaseOrderV2 purchase_orders = 1;
 * @return {!Array<!proto.vtv1.PotentialInventoryPurchaseOrderV2>}
 */
proto.vtv1.PotentialPurchaseOrdersV2Response.prototype.getPurchaseOrdersList = function() {
  return /** @type{!Array<!proto.vtv1.PotentialInventoryPurchaseOrderV2>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_vorto_node_node_pb.PotentialInventoryPurchaseOrderV2, 1));
};


/**
 * @param {!Array<!proto.vtv1.PotentialInventoryPurchaseOrderV2>} value
 * @return {!proto.vtv1.PotentialPurchaseOrdersV2Response} returns this
*/
proto.vtv1.PotentialPurchaseOrdersV2Response.prototype.setPurchaseOrdersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.vtv1.PotentialInventoryPurchaseOrderV2=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.PotentialInventoryPurchaseOrderV2}
 */
proto.vtv1.PotentialPurchaseOrdersV2Response.prototype.addPurchaseOrders = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.vtv1.PotentialInventoryPurchaseOrderV2, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.PotentialPurchaseOrdersV2Response} returns this
 */
proto.vtv1.PotentialPurchaseOrdersV2Response.prototype.clearPurchaseOrdersList = function() {
  return this.setPurchaseOrdersList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.EditInventorySupplyPlanRequest.repeatedFields_ = [2,4,7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.EditInventorySupplyPlanRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.EditInventorySupplyPlanRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.EditInventorySupplyPlanRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.EditInventorySupplyPlanRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    inventoryId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    suppliersList: jspb.Message.toObjectList(msg.getSuppliersList(),
    proto.vtv1.EditInventorySupplyPlanSupplier.toObject, includeInstance),
    spotPurchaseType: jspb.Message.getFieldWithDefault(msg, 3, 0),
    poNumbersList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
    marketPriceCheckFrequency: jspb.Message.getFieldWithDefault(msg, 5, 0),
    biddingDuration: jspb.Message.getFieldWithDefault(msg, 6, 0),
    invitesList: jspb.Message.toObjectList(msg.getInvitesList(),
    proto_vorto_node_node_pb.InventorySupplyPlanMarketInvite.toObject, includeInstance),
    shipperType: jspb.Message.getFieldWithDefault(msg, 8, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.EditInventorySupplyPlanRequest}
 */
proto.vtv1.EditInventorySupplyPlanRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.EditInventorySupplyPlanRequest;
  return proto.vtv1.EditInventorySupplyPlanRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.EditInventorySupplyPlanRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.EditInventorySupplyPlanRequest}
 */
proto.vtv1.EditInventorySupplyPlanRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setInventoryId(value);
      break;
    case 2:
      var value = new proto.vtv1.EditInventorySupplyPlanSupplier;
      reader.readMessage(value,proto.vtv1.EditInventorySupplyPlanSupplier.deserializeBinaryFromReader);
      msg.addSuppliers(value);
      break;
    case 3:
      var value = /** @type {!proto.vtv1.SpotMarketRequestType} */ (reader.readEnum());
      msg.setSpotPurchaseType(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.addPoNumbers(value);
      break;
    case 5:
      var value = /** @type {!proto.vtv1.RFQFrequencyType} */ (reader.readEnum());
      msg.setMarketPriceCheckFrequency(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBiddingDuration(value);
      break;
    case 7:
      var value = new proto_vorto_node_node_pb.InventorySupplyPlanMarketInvite;
      reader.readMessage(value,proto_vorto_node_node_pb.InventorySupplyPlanMarketInvite.deserializeBinaryFromReader);
      msg.addInvites(value);
      break;
    case 8:
      var value = /** @type {!proto.vtv1.SpotMarketRequestShipperType} */ (reader.readEnum());
      msg.setShipperType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.EditInventorySupplyPlanRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.EditInventorySupplyPlanRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.EditInventorySupplyPlanRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.EditInventorySupplyPlanRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInventoryId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSuppliersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.vtv1.EditInventorySupplyPlanSupplier.serializeBinaryToWriter
    );
  }
  f = message.getSpotPurchaseType();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getPoNumbersList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      4,
      f
    );
  }
  f = message.getMarketPriceCheckFrequency();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getBiddingDuration();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getInvitesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto_vorto_node_node_pb.InventorySupplyPlanMarketInvite.serializeBinaryToWriter
    );
  }
  f = message.getShipperType();
  if (f !== 0.0) {
    writer.writeEnum(
      8,
      f
    );
  }
};


/**
 * optional string inventory_id = 1;
 * @return {string}
 */
proto.vtv1.EditInventorySupplyPlanRequest.prototype.getInventoryId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.EditInventorySupplyPlanRequest} returns this
 */
proto.vtv1.EditInventorySupplyPlanRequest.prototype.setInventoryId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated EditInventorySupplyPlanSupplier suppliers = 2;
 * @return {!Array<!proto.vtv1.EditInventorySupplyPlanSupplier>}
 */
proto.vtv1.EditInventorySupplyPlanRequest.prototype.getSuppliersList = function() {
  return /** @type{!Array<!proto.vtv1.EditInventorySupplyPlanSupplier>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vtv1.EditInventorySupplyPlanSupplier, 2));
};


/**
 * @param {!Array<!proto.vtv1.EditInventorySupplyPlanSupplier>} value
 * @return {!proto.vtv1.EditInventorySupplyPlanRequest} returns this
*/
proto.vtv1.EditInventorySupplyPlanRequest.prototype.setSuppliersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.vtv1.EditInventorySupplyPlanSupplier=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.EditInventorySupplyPlanSupplier}
 */
proto.vtv1.EditInventorySupplyPlanRequest.prototype.addSuppliers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.vtv1.EditInventorySupplyPlanSupplier, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.EditInventorySupplyPlanRequest} returns this
 */
proto.vtv1.EditInventorySupplyPlanRequest.prototype.clearSuppliersList = function() {
  return this.setSuppliersList([]);
};


/**
 * optional SpotMarketRequestType spot_purchase_type = 3;
 * @return {!proto.vtv1.SpotMarketRequestType}
 */
proto.vtv1.EditInventorySupplyPlanRequest.prototype.getSpotPurchaseType = function() {
  return /** @type {!proto.vtv1.SpotMarketRequestType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.vtv1.SpotMarketRequestType} value
 * @return {!proto.vtv1.EditInventorySupplyPlanRequest} returns this
 */
proto.vtv1.EditInventorySupplyPlanRequest.prototype.setSpotPurchaseType = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * repeated string po_numbers = 4;
 * @return {!Array<string>}
 */
proto.vtv1.EditInventorySupplyPlanRequest.prototype.getPoNumbersList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.vtv1.EditInventorySupplyPlanRequest} returns this
 */
proto.vtv1.EditInventorySupplyPlanRequest.prototype.setPoNumbersList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.vtv1.EditInventorySupplyPlanRequest} returns this
 */
proto.vtv1.EditInventorySupplyPlanRequest.prototype.addPoNumbers = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.EditInventorySupplyPlanRequest} returns this
 */
proto.vtv1.EditInventorySupplyPlanRequest.prototype.clearPoNumbersList = function() {
  return this.setPoNumbersList([]);
};


/**
 * optional RFQFrequencyType market_price_check_frequency = 5;
 * @return {!proto.vtv1.RFQFrequencyType}
 */
proto.vtv1.EditInventorySupplyPlanRequest.prototype.getMarketPriceCheckFrequency = function() {
  return /** @type {!proto.vtv1.RFQFrequencyType} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.vtv1.RFQFrequencyType} value
 * @return {!proto.vtv1.EditInventorySupplyPlanRequest} returns this
 */
proto.vtv1.EditInventorySupplyPlanRequest.prototype.setMarketPriceCheckFrequency = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional int64 bidding_duration = 6;
 * @return {number}
 */
proto.vtv1.EditInventorySupplyPlanRequest.prototype.getBiddingDuration = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.EditInventorySupplyPlanRequest} returns this
 */
proto.vtv1.EditInventorySupplyPlanRequest.prototype.setBiddingDuration = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * repeated InventorySupplyPlanMarketInvite invites = 7;
 * @return {!Array<!proto.vtv1.InventorySupplyPlanMarketInvite>}
 */
proto.vtv1.EditInventorySupplyPlanRequest.prototype.getInvitesList = function() {
  return /** @type{!Array<!proto.vtv1.InventorySupplyPlanMarketInvite>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_vorto_node_node_pb.InventorySupplyPlanMarketInvite, 7));
};


/**
 * @param {!Array<!proto.vtv1.InventorySupplyPlanMarketInvite>} value
 * @return {!proto.vtv1.EditInventorySupplyPlanRequest} returns this
*/
proto.vtv1.EditInventorySupplyPlanRequest.prototype.setInvitesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.vtv1.InventorySupplyPlanMarketInvite=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.InventorySupplyPlanMarketInvite}
 */
proto.vtv1.EditInventorySupplyPlanRequest.prototype.addInvites = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.vtv1.InventorySupplyPlanMarketInvite, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.EditInventorySupplyPlanRequest} returns this
 */
proto.vtv1.EditInventorySupplyPlanRequest.prototype.clearInvitesList = function() {
  return this.setInvitesList([]);
};


/**
 * optional SpotMarketRequestShipperType shipper_type = 8;
 * @return {!proto.vtv1.SpotMarketRequestShipperType}
 */
proto.vtv1.EditInventorySupplyPlanRequest.prototype.getShipperType = function() {
  return /** @type {!proto.vtv1.SpotMarketRequestShipperType} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {!proto.vtv1.SpotMarketRequestShipperType} value
 * @return {!proto.vtv1.EditInventorySupplyPlanRequest} returns this
 */
proto.vtv1.EditInventorySupplyPlanRequest.prototype.setShipperType = function(value) {
  return jspb.Message.setProto3EnumField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.EditInventorySupplyPlanResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.EditInventorySupplyPlanResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.EditInventorySupplyPlanResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.EditInventorySupplyPlanResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    supplyPlan: (f = msg.getSupplyPlan()) && proto_vorto_node_node_pb.InventorySupplyPlan.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.EditInventorySupplyPlanResponse}
 */
proto.vtv1.EditInventorySupplyPlanResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.EditInventorySupplyPlanResponse;
  return proto.vtv1.EditInventorySupplyPlanResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.EditInventorySupplyPlanResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.EditInventorySupplyPlanResponse}
 */
proto.vtv1.EditInventorySupplyPlanResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_vorto_node_node_pb.InventorySupplyPlan;
      reader.readMessage(value,proto_vorto_node_node_pb.InventorySupplyPlan.deserializeBinaryFromReader);
      msg.setSupplyPlan(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.EditInventorySupplyPlanResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.EditInventorySupplyPlanResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.EditInventorySupplyPlanResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.EditInventorySupplyPlanResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSupplyPlan();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_vorto_node_node_pb.InventorySupplyPlan.serializeBinaryToWriter
    );
  }
};


/**
 * optional InventorySupplyPlan supply_plan = 1;
 * @return {?proto.vtv1.InventorySupplyPlan}
 */
proto.vtv1.EditInventorySupplyPlanResponse.prototype.getSupplyPlan = function() {
  return /** @type{?proto.vtv1.InventorySupplyPlan} */ (
    jspb.Message.getWrapperField(this, proto_vorto_node_node_pb.InventorySupplyPlan, 1));
};


/**
 * @param {?proto.vtv1.InventorySupplyPlan|undefined} value
 * @return {!proto.vtv1.EditInventorySupplyPlanResponse} returns this
*/
proto.vtv1.EditInventorySupplyPlanResponse.prototype.setSupplyPlan = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.EditInventorySupplyPlanResponse} returns this
 */
proto.vtv1.EditInventorySupplyPlanResponse.prototype.clearSupplyPlan = function() {
  return this.setSupplyPlan(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.EditInventorySupplyPlanResponse.prototype.hasSupplyPlan = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.GetInventoryScheduledSpotMarketRequestsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.GetInventoryScheduledSpotMarketRequestsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.GetInventoryScheduledSpotMarketRequestsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.GetInventoryScheduledSpotMarketRequestsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.GetInventoryScheduledSpotMarketRequestsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    spotRequestsList: jspb.Message.toObjectList(msg.getSpotRequestsList(),
    proto_vorto_node_node_pb.InventoryScheduledRFQ.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.GetInventoryScheduledSpotMarketRequestsResponse}
 */
proto.vtv1.GetInventoryScheduledSpotMarketRequestsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.GetInventoryScheduledSpotMarketRequestsResponse;
  return proto.vtv1.GetInventoryScheduledSpotMarketRequestsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.GetInventoryScheduledSpotMarketRequestsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.GetInventoryScheduledSpotMarketRequestsResponse}
 */
proto.vtv1.GetInventoryScheduledSpotMarketRequestsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_vorto_node_node_pb.InventoryScheduledRFQ;
      reader.readMessage(value,proto_vorto_node_node_pb.InventoryScheduledRFQ.deserializeBinaryFromReader);
      msg.addSpotRequests(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.GetInventoryScheduledSpotMarketRequestsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.GetInventoryScheduledSpotMarketRequestsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.GetInventoryScheduledSpotMarketRequestsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.GetInventoryScheduledSpotMarketRequestsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSpotRequestsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto_vorto_node_node_pb.InventoryScheduledRFQ.serializeBinaryToWriter
    );
  }
};


/**
 * repeated InventoryScheduledRFQ spot_requests = 1;
 * @return {!Array<!proto.vtv1.InventoryScheduledRFQ>}
 */
proto.vtv1.GetInventoryScheduledSpotMarketRequestsResponse.prototype.getSpotRequestsList = function() {
  return /** @type{!Array<!proto.vtv1.InventoryScheduledRFQ>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_vorto_node_node_pb.InventoryScheduledRFQ, 1));
};


/**
 * @param {!Array<!proto.vtv1.InventoryScheduledRFQ>} value
 * @return {!proto.vtv1.GetInventoryScheduledSpotMarketRequestsResponse} returns this
*/
proto.vtv1.GetInventoryScheduledSpotMarketRequestsResponse.prototype.setSpotRequestsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.vtv1.InventoryScheduledRFQ=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.InventoryScheduledRFQ}
 */
proto.vtv1.GetInventoryScheduledSpotMarketRequestsResponse.prototype.addSpotRequests = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.vtv1.InventoryScheduledRFQ, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.GetInventoryScheduledSpotMarketRequestsResponse} returns this
 */
proto.vtv1.GetInventoryScheduledSpotMarketRequestsResponse.prototype.clearSpotRequestsList = function() {
  return this.setSpotRequestsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.GetInventoryCurrentSpotMarketRequestsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.GetInventoryCurrentSpotMarketRequestsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.GetInventoryCurrentSpotMarketRequestsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.GetInventoryCurrentSpotMarketRequestsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.GetInventoryCurrentSpotMarketRequestsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    spotRequestsList: jspb.Message.toObjectList(msg.getSpotRequestsList(),
    proto_vorto_node_node_pb.InventoryCurrentRFQ.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.GetInventoryCurrentSpotMarketRequestsResponse}
 */
proto.vtv1.GetInventoryCurrentSpotMarketRequestsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.GetInventoryCurrentSpotMarketRequestsResponse;
  return proto.vtv1.GetInventoryCurrentSpotMarketRequestsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.GetInventoryCurrentSpotMarketRequestsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.GetInventoryCurrentSpotMarketRequestsResponse}
 */
proto.vtv1.GetInventoryCurrentSpotMarketRequestsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_vorto_node_node_pb.InventoryCurrentRFQ;
      reader.readMessage(value,proto_vorto_node_node_pb.InventoryCurrentRFQ.deserializeBinaryFromReader);
      msg.addSpotRequests(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.GetInventoryCurrentSpotMarketRequestsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.GetInventoryCurrentSpotMarketRequestsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.GetInventoryCurrentSpotMarketRequestsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.GetInventoryCurrentSpotMarketRequestsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSpotRequestsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto_vorto_node_node_pb.InventoryCurrentRFQ.serializeBinaryToWriter
    );
  }
};


/**
 * repeated InventoryCurrentRFQ spot_requests = 1;
 * @return {!Array<!proto.vtv1.InventoryCurrentRFQ>}
 */
proto.vtv1.GetInventoryCurrentSpotMarketRequestsResponse.prototype.getSpotRequestsList = function() {
  return /** @type{!Array<!proto.vtv1.InventoryCurrentRFQ>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_vorto_node_node_pb.InventoryCurrentRFQ, 1));
};


/**
 * @param {!Array<!proto.vtv1.InventoryCurrentRFQ>} value
 * @return {!proto.vtv1.GetInventoryCurrentSpotMarketRequestsResponse} returns this
*/
proto.vtv1.GetInventoryCurrentSpotMarketRequestsResponse.prototype.setSpotRequestsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.vtv1.InventoryCurrentRFQ=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.InventoryCurrentRFQ}
 */
proto.vtv1.GetInventoryCurrentSpotMarketRequestsResponse.prototype.addSpotRequests = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.vtv1.InventoryCurrentRFQ, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.GetInventoryCurrentSpotMarketRequestsResponse} returns this
 */
proto.vtv1.GetInventoryCurrentSpotMarketRequestsResponse.prototype.clearSpotRequestsList = function() {
  return this.setSpotRequestsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.EditInventorySupplyPlanSupplier.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.EditInventorySupplyPlanSupplier.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.EditInventorySupplyPlanSupplier} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.EditInventorySupplyPlanSupplier.toObject = function(includeInstance, msg) {
  var f, obj = {
    supplierId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    pricePerUnit: (f = msg.getPricePerUnit()) && proto_vorto_types_types_pb.NullableInt64.toObject(includeInstance, f),
    siteId: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.EditInventorySupplyPlanSupplier}
 */
proto.vtv1.EditInventorySupplyPlanSupplier.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.EditInventorySupplyPlanSupplier;
  return proto.vtv1.EditInventorySupplyPlanSupplier.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.EditInventorySupplyPlanSupplier} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.EditInventorySupplyPlanSupplier}
 */
proto.vtv1.EditInventorySupplyPlanSupplier.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSupplierId(value);
      break;
    case 2:
      var value = new proto_vorto_types_types_pb.NullableInt64;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableInt64.deserializeBinaryFromReader);
      msg.setPricePerUnit(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSiteId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.EditInventorySupplyPlanSupplier.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.EditInventorySupplyPlanSupplier.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.EditInventorySupplyPlanSupplier} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.EditInventorySupplyPlanSupplier.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSupplierId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPricePerUnit();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_vorto_types_types_pb.NullableInt64.serializeBinaryToWriter
    );
  }
  f = message.getSiteId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string supplier_id = 1;
 * @return {string}
 */
proto.vtv1.EditInventorySupplyPlanSupplier.prototype.getSupplierId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.EditInventorySupplyPlanSupplier} returns this
 */
proto.vtv1.EditInventorySupplyPlanSupplier.prototype.setSupplierId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional NullableInt64 price_per_unit = 2;
 * @return {?proto.vtv1.NullableInt64}
 */
proto.vtv1.EditInventorySupplyPlanSupplier.prototype.getPricePerUnit = function() {
  return /** @type{?proto.vtv1.NullableInt64} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableInt64, 2));
};


/**
 * @param {?proto.vtv1.NullableInt64|undefined} value
 * @return {!proto.vtv1.EditInventorySupplyPlanSupplier} returns this
*/
proto.vtv1.EditInventorySupplyPlanSupplier.prototype.setPricePerUnit = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.EditInventorySupplyPlanSupplier} returns this
 */
proto.vtv1.EditInventorySupplyPlanSupplier.prototype.clearPricePerUnit = function() {
  return this.setPricePerUnit(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.EditInventorySupplyPlanSupplier.prototype.hasPricePerUnit = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string site_id = 3;
 * @return {string}
 */
proto.vtv1.EditInventorySupplyPlanSupplier.prototype.getSiteId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.EditInventorySupplyPlanSupplier} returns this
 */
proto.vtv1.EditInventorySupplyPlanSupplier.prototype.setSiteId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.InventorySupplyPlanResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.InventorySupplyPlanResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.InventorySupplyPlanResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.InventorySupplyPlanResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    supplyPlan: (f = msg.getSupplyPlan()) && proto_vorto_node_node_pb.InventorySupplyPlan.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.InventorySupplyPlanResponse}
 */
proto.vtv1.InventorySupplyPlanResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.InventorySupplyPlanResponse;
  return proto.vtv1.InventorySupplyPlanResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.InventorySupplyPlanResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.InventorySupplyPlanResponse}
 */
proto.vtv1.InventorySupplyPlanResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_vorto_node_node_pb.InventorySupplyPlan;
      reader.readMessage(value,proto_vorto_node_node_pb.InventorySupplyPlan.deserializeBinaryFromReader);
      msg.setSupplyPlan(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.InventorySupplyPlanResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.InventorySupplyPlanResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.InventorySupplyPlanResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.InventorySupplyPlanResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSupplyPlan();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_vorto_node_node_pb.InventorySupplyPlan.serializeBinaryToWriter
    );
  }
};


/**
 * optional InventorySupplyPlan supply_plan = 1;
 * @return {?proto.vtv1.InventorySupplyPlan}
 */
proto.vtv1.InventorySupplyPlanResponse.prototype.getSupplyPlan = function() {
  return /** @type{?proto.vtv1.InventorySupplyPlan} */ (
    jspb.Message.getWrapperField(this, proto_vorto_node_node_pb.InventorySupplyPlan, 1));
};


/**
 * @param {?proto.vtv1.InventorySupplyPlan|undefined} value
 * @return {!proto.vtv1.InventorySupplyPlanResponse} returns this
*/
proto.vtv1.InventorySupplyPlanResponse.prototype.setSupplyPlan = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.InventorySupplyPlanResponse} returns this
 */
proto.vtv1.InventorySupplyPlanResponse.prototype.clearSupplyPlan = function() {
  return this.setSupplyPlan(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.InventorySupplyPlanResponse.prototype.hasSupplyPlan = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.CreateInventoryGroupRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.CreateInventoryGroupRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.CreateInventoryGroupRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.CreateInventoryGroupRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    siteId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.CreateInventoryGroupRequest}
 */
proto.vtv1.CreateInventoryGroupRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.CreateInventoryGroupRequest;
  return proto.vtv1.CreateInventoryGroupRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.CreateInventoryGroupRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.CreateInventoryGroupRequest}
 */
proto.vtv1.CreateInventoryGroupRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSiteId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.CreateInventoryGroupRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.CreateInventoryGroupRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.CreateInventoryGroupRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.CreateInventoryGroupRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSiteId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string site_id = 1;
 * @return {string}
 */
proto.vtv1.CreateInventoryGroupRequest.prototype.getSiteId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.CreateInventoryGroupRequest} returns this
 */
proto.vtv1.CreateInventoryGroupRequest.prototype.setSiteId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.vtv1.CreateInventoryGroupRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.CreateInventoryGroupRequest} returns this
 */
proto.vtv1.CreateInventoryGroupRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.ListInventoryGroupResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.ListInventoryGroupResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.ListInventoryGroupResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.ListInventoryGroupResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.ListInventoryGroupResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    inventoryGroupsList: jspb.Message.toObjectList(msg.getInventoryGroupsList(),
    proto_vorto_types_types_pb.StringIdName.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.ListInventoryGroupResponse}
 */
proto.vtv1.ListInventoryGroupResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.ListInventoryGroupResponse;
  return proto.vtv1.ListInventoryGroupResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.ListInventoryGroupResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.ListInventoryGroupResponse}
 */
proto.vtv1.ListInventoryGroupResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_vorto_types_types_pb.StringIdName;
      reader.readMessage(value,proto_vorto_types_types_pb.StringIdName.deserializeBinaryFromReader);
      msg.addInventoryGroups(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.ListInventoryGroupResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.ListInventoryGroupResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.ListInventoryGroupResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.ListInventoryGroupResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInventoryGroupsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto_vorto_types_types_pb.StringIdName.serializeBinaryToWriter
    );
  }
};


/**
 * repeated StringIdName inventory_groups = 1;
 * @return {!Array<!proto.vtv1.StringIdName>}
 */
proto.vtv1.ListInventoryGroupResponse.prototype.getInventoryGroupsList = function() {
  return /** @type{!Array<!proto.vtv1.StringIdName>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_vorto_types_types_pb.StringIdName, 1));
};


/**
 * @param {!Array<!proto.vtv1.StringIdName>} value
 * @return {!proto.vtv1.ListInventoryGroupResponse} returns this
*/
proto.vtv1.ListInventoryGroupResponse.prototype.setInventoryGroupsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.vtv1.StringIdName=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.StringIdName}
 */
proto.vtv1.ListInventoryGroupResponse.prototype.addInventoryGroups = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.vtv1.StringIdName, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.ListInventoryGroupResponse} returns this
 */
proto.vtv1.ListInventoryGroupResponse.prototype.clearInventoryGroupsList = function() {
  return this.setInventoryGroupsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.EditInventoryGroupRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.EditInventoryGroupRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.EditInventoryGroupRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.EditInventoryGroupRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    groupId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.EditInventoryGroupRequest}
 */
proto.vtv1.EditInventoryGroupRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.EditInventoryGroupRequest;
  return proto.vtv1.EditInventoryGroupRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.EditInventoryGroupRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.EditInventoryGroupRequest}
 */
proto.vtv1.EditInventoryGroupRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setGroupId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.EditInventoryGroupRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.EditInventoryGroupRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.EditInventoryGroupRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.EditInventoryGroupRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGroupId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string group_id = 1;
 * @return {string}
 */
proto.vtv1.EditInventoryGroupRequest.prototype.getGroupId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.EditInventoryGroupRequest} returns this
 */
proto.vtv1.EditInventoryGroupRequest.prototype.setGroupId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.vtv1.EditInventoryGroupRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.EditInventoryGroupRequest} returns this
 */
proto.vtv1.EditInventoryGroupRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.AddInventoryToGroupRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.AddInventoryToGroupRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.AddInventoryToGroupRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.AddInventoryToGroupRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.AddInventoryToGroupRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    groupId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    inventoryIdsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.AddInventoryToGroupRequest}
 */
proto.vtv1.AddInventoryToGroupRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.AddInventoryToGroupRequest;
  return proto.vtv1.AddInventoryToGroupRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.AddInventoryToGroupRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.AddInventoryToGroupRequest}
 */
proto.vtv1.AddInventoryToGroupRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setGroupId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addInventoryIds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.AddInventoryToGroupRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.AddInventoryToGroupRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.AddInventoryToGroupRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.AddInventoryToGroupRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGroupId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getInventoryIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
};


/**
 * optional string group_id = 1;
 * @return {string}
 */
proto.vtv1.AddInventoryToGroupRequest.prototype.getGroupId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.AddInventoryToGroupRequest} returns this
 */
proto.vtv1.AddInventoryToGroupRequest.prototype.setGroupId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated string inventory_ids = 2;
 * @return {!Array<string>}
 */
proto.vtv1.AddInventoryToGroupRequest.prototype.getInventoryIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.vtv1.AddInventoryToGroupRequest} returns this
 */
proto.vtv1.AddInventoryToGroupRequest.prototype.setInventoryIdsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.vtv1.AddInventoryToGroupRequest} returns this
 */
proto.vtv1.AddInventoryToGroupRequest.prototype.addInventoryIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.AddInventoryToGroupRequest} returns this
 */
proto.vtv1.AddInventoryToGroupRequest.prototype.clearInventoryIdsList = function() {
  return this.setInventoryIdsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.UpsertSiteLoadingScheduleRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.UpsertSiteLoadingScheduleRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.UpsertSiteLoadingScheduleRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.UpsertSiteLoadingScheduleRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    siteLoadingSchedule: (f = msg.getSiteLoadingSchedule()) && proto_vorto_types_types_pb.SiteLoadingSchedule.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.UpsertSiteLoadingScheduleRequest}
 */
proto.vtv1.UpsertSiteLoadingScheduleRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.UpsertSiteLoadingScheduleRequest;
  return proto.vtv1.UpsertSiteLoadingScheduleRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.UpsertSiteLoadingScheduleRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.UpsertSiteLoadingScheduleRequest}
 */
proto.vtv1.UpsertSiteLoadingScheduleRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_vorto_types_types_pb.SiteLoadingSchedule;
      reader.readMessage(value,proto_vorto_types_types_pb.SiteLoadingSchedule.deserializeBinaryFromReader);
      msg.setSiteLoadingSchedule(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.UpsertSiteLoadingScheduleRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.UpsertSiteLoadingScheduleRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.UpsertSiteLoadingScheduleRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.UpsertSiteLoadingScheduleRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSiteLoadingSchedule();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_vorto_types_types_pb.SiteLoadingSchedule.serializeBinaryToWriter
    );
  }
};


/**
 * optional SiteLoadingSchedule site_loading_schedule = 1;
 * @return {?proto.vtv1.SiteLoadingSchedule}
 */
proto.vtv1.UpsertSiteLoadingScheduleRequest.prototype.getSiteLoadingSchedule = function() {
  return /** @type{?proto.vtv1.SiteLoadingSchedule} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.SiteLoadingSchedule, 1));
};


/**
 * @param {?proto.vtv1.SiteLoadingSchedule|undefined} value
 * @return {!proto.vtv1.UpsertSiteLoadingScheduleRequest} returns this
*/
proto.vtv1.UpsertSiteLoadingScheduleRequest.prototype.setSiteLoadingSchedule = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.UpsertSiteLoadingScheduleRequest} returns this
 */
proto.vtv1.UpsertSiteLoadingScheduleRequest.prototype.clearSiteLoadingSchedule = function() {
  return this.setSiteLoadingSchedule(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.UpsertSiteLoadingScheduleRequest.prototype.hasSiteLoadingSchedule = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.UpsertSiteLoadingScheduleResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.UpsertSiteLoadingScheduleResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.UpsertSiteLoadingScheduleResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.UpsertSiteLoadingScheduleResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    siteLoadingSchedule: (f = msg.getSiteLoadingSchedule()) && proto_vorto_types_types_pb.SiteLoadingSchedule.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.UpsertSiteLoadingScheduleResponse}
 */
proto.vtv1.UpsertSiteLoadingScheduleResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.UpsertSiteLoadingScheduleResponse;
  return proto.vtv1.UpsertSiteLoadingScheduleResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.UpsertSiteLoadingScheduleResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.UpsertSiteLoadingScheduleResponse}
 */
proto.vtv1.UpsertSiteLoadingScheduleResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_vorto_types_types_pb.SiteLoadingSchedule;
      reader.readMessage(value,proto_vorto_types_types_pb.SiteLoadingSchedule.deserializeBinaryFromReader);
      msg.setSiteLoadingSchedule(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.UpsertSiteLoadingScheduleResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.UpsertSiteLoadingScheduleResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.UpsertSiteLoadingScheduleResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.UpsertSiteLoadingScheduleResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSiteLoadingSchedule();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_vorto_types_types_pb.SiteLoadingSchedule.serializeBinaryToWriter
    );
  }
};


/**
 * optional SiteLoadingSchedule site_loading_schedule = 1;
 * @return {?proto.vtv1.SiteLoadingSchedule}
 */
proto.vtv1.UpsertSiteLoadingScheduleResponse.prototype.getSiteLoadingSchedule = function() {
  return /** @type{?proto.vtv1.SiteLoadingSchedule} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.SiteLoadingSchedule, 1));
};


/**
 * @param {?proto.vtv1.SiteLoadingSchedule|undefined} value
 * @return {!proto.vtv1.UpsertSiteLoadingScheduleResponse} returns this
*/
proto.vtv1.UpsertSiteLoadingScheduleResponse.prototype.setSiteLoadingSchedule = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.UpsertSiteLoadingScheduleResponse} returns this
 */
proto.vtv1.UpsertSiteLoadingScheduleResponse.prototype.clearSiteLoadingSchedule = function() {
  return this.setSiteLoadingSchedule(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.UpsertSiteLoadingScheduleResponse.prototype.hasSiteLoadingSchedule = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.GetSiteLoadingScheduleResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.GetSiteLoadingScheduleResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.GetSiteLoadingScheduleResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.GetSiteLoadingScheduleResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    siteLoadingSchedule: (f = msg.getSiteLoadingSchedule()) && proto_vorto_types_types_pb.SiteLoadingSchedule.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.GetSiteLoadingScheduleResponse}
 */
proto.vtv1.GetSiteLoadingScheduleResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.GetSiteLoadingScheduleResponse;
  return proto.vtv1.GetSiteLoadingScheduleResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.GetSiteLoadingScheduleResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.GetSiteLoadingScheduleResponse}
 */
proto.vtv1.GetSiteLoadingScheduleResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_vorto_types_types_pb.SiteLoadingSchedule;
      reader.readMessage(value,proto_vorto_types_types_pb.SiteLoadingSchedule.deserializeBinaryFromReader);
      msg.setSiteLoadingSchedule(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.GetSiteLoadingScheduleResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.GetSiteLoadingScheduleResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.GetSiteLoadingScheduleResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.GetSiteLoadingScheduleResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSiteLoadingSchedule();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_vorto_types_types_pb.SiteLoadingSchedule.serializeBinaryToWriter
    );
  }
};


/**
 * optional SiteLoadingSchedule site_loading_schedule = 1;
 * @return {?proto.vtv1.SiteLoadingSchedule}
 */
proto.vtv1.GetSiteLoadingScheduleResponse.prototype.getSiteLoadingSchedule = function() {
  return /** @type{?proto.vtv1.SiteLoadingSchedule} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.SiteLoadingSchedule, 1));
};


/**
 * @param {?proto.vtv1.SiteLoadingSchedule|undefined} value
 * @return {!proto.vtv1.GetSiteLoadingScheduleResponse} returns this
*/
proto.vtv1.GetSiteLoadingScheduleResponse.prototype.setSiteLoadingSchedule = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.GetSiteLoadingScheduleResponse} returns this
 */
proto.vtv1.GetSiteLoadingScheduleResponse.prototype.clearSiteLoadingSchedule = function() {
  return this.setSiteLoadingSchedule(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.GetSiteLoadingScheduleResponse.prototype.hasSiteLoadingSchedule = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.NodeListAtEdgeOrdersResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.NodeListAtEdgeOrdersResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.NodeListAtEdgeOrdersResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.NodeListAtEdgeOrdersResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.NodeListAtEdgeOrdersResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    ordersList: jspb.Message.toObjectList(msg.getOrdersList(),
    proto_vorto_node_node_pb.AtEdgeOrderSummary.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.NodeListAtEdgeOrdersResponse}
 */
proto.vtv1.NodeListAtEdgeOrdersResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.NodeListAtEdgeOrdersResponse;
  return proto.vtv1.NodeListAtEdgeOrdersResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.NodeListAtEdgeOrdersResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.NodeListAtEdgeOrdersResponse}
 */
proto.vtv1.NodeListAtEdgeOrdersResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_vorto_node_node_pb.AtEdgeOrderSummary;
      reader.readMessage(value,proto_vorto_node_node_pb.AtEdgeOrderSummary.deserializeBinaryFromReader);
      msg.addOrders(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.NodeListAtEdgeOrdersResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.NodeListAtEdgeOrdersResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.NodeListAtEdgeOrdersResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.NodeListAtEdgeOrdersResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrdersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto_vorto_node_node_pb.AtEdgeOrderSummary.serializeBinaryToWriter
    );
  }
};


/**
 * repeated AtEdgeOrderSummary orders = 1;
 * @return {!Array<!proto.vtv1.AtEdgeOrderSummary>}
 */
proto.vtv1.NodeListAtEdgeOrdersResponse.prototype.getOrdersList = function() {
  return /** @type{!Array<!proto.vtv1.AtEdgeOrderSummary>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_vorto_node_node_pb.AtEdgeOrderSummary, 1));
};


/**
 * @param {!Array<!proto.vtv1.AtEdgeOrderSummary>} value
 * @return {!proto.vtv1.NodeListAtEdgeOrdersResponse} returns this
*/
proto.vtv1.NodeListAtEdgeOrdersResponse.prototype.setOrdersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.vtv1.AtEdgeOrderSummary=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.AtEdgeOrderSummary}
 */
proto.vtv1.NodeListAtEdgeOrdersResponse.prototype.addOrders = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.vtv1.AtEdgeOrderSummary, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.NodeListAtEdgeOrdersResponse} returns this
 */
proto.vtv1.NodeListAtEdgeOrdersResponse.prototype.clearOrdersList = function() {
  return this.setOrdersList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.NodeListAtSupplierOrdersResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.NodeListAtSupplierOrdersResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.NodeListAtSupplierOrdersResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.NodeListAtSupplierOrdersResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.NodeListAtSupplierOrdersResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    ordersList: jspb.Message.toObjectList(msg.getOrdersList(),
    proto_vorto_node_node_pb.AtSupplierOrderSummary.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.NodeListAtSupplierOrdersResponse}
 */
proto.vtv1.NodeListAtSupplierOrdersResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.NodeListAtSupplierOrdersResponse;
  return proto.vtv1.NodeListAtSupplierOrdersResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.NodeListAtSupplierOrdersResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.NodeListAtSupplierOrdersResponse}
 */
proto.vtv1.NodeListAtSupplierOrdersResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_vorto_node_node_pb.AtSupplierOrderSummary;
      reader.readMessage(value,proto_vorto_node_node_pb.AtSupplierOrderSummary.deserializeBinaryFromReader);
      msg.addOrders(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.NodeListAtSupplierOrdersResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.NodeListAtSupplierOrdersResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.NodeListAtSupplierOrdersResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.NodeListAtSupplierOrdersResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrdersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto_vorto_node_node_pb.AtSupplierOrderSummary.serializeBinaryToWriter
    );
  }
};


/**
 * repeated AtSupplierOrderSummary orders = 1;
 * @return {!Array<!proto.vtv1.AtSupplierOrderSummary>}
 */
proto.vtv1.NodeListAtSupplierOrdersResponse.prototype.getOrdersList = function() {
  return /** @type{!Array<!proto.vtv1.AtSupplierOrderSummary>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_vorto_node_node_pb.AtSupplierOrderSummary, 1));
};


/**
 * @param {!Array<!proto.vtv1.AtSupplierOrderSummary>} value
 * @return {!proto.vtv1.NodeListAtSupplierOrdersResponse} returns this
*/
proto.vtv1.NodeListAtSupplierOrdersResponse.prototype.setOrdersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.vtv1.AtSupplierOrderSummary=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.AtSupplierOrderSummary}
 */
proto.vtv1.NodeListAtSupplierOrdersResponse.prototype.addOrders = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.vtv1.AtSupplierOrderSummary, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.NodeListAtSupplierOrdersResponse} returns this
 */
proto.vtv1.NodeListAtSupplierOrdersResponse.prototype.clearOrdersList = function() {
  return this.setOrdersList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.NodeListScheduledOrdersResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.NodeListScheduledOrdersResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.NodeListScheduledOrdersResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.NodeListScheduledOrdersResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.NodeListScheduledOrdersResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    ordersList: jspb.Message.toObjectList(msg.getOrdersList(),
    proto_vorto_node_node_pb.ScheduledOrderSummary.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.NodeListScheduledOrdersResponse}
 */
proto.vtv1.NodeListScheduledOrdersResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.NodeListScheduledOrdersResponse;
  return proto.vtv1.NodeListScheduledOrdersResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.NodeListScheduledOrdersResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.NodeListScheduledOrdersResponse}
 */
proto.vtv1.NodeListScheduledOrdersResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_vorto_node_node_pb.ScheduledOrderSummary;
      reader.readMessage(value,proto_vorto_node_node_pb.ScheduledOrderSummary.deserializeBinaryFromReader);
      msg.addOrders(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.NodeListScheduledOrdersResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.NodeListScheduledOrdersResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.NodeListScheduledOrdersResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.NodeListScheduledOrdersResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrdersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto_vorto_node_node_pb.ScheduledOrderSummary.serializeBinaryToWriter
    );
  }
};


/**
 * repeated ScheduledOrderSummary orders = 1;
 * @return {!Array<!proto.vtv1.ScheduledOrderSummary>}
 */
proto.vtv1.NodeListScheduledOrdersResponse.prototype.getOrdersList = function() {
  return /** @type{!Array<!proto.vtv1.ScheduledOrderSummary>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_vorto_node_node_pb.ScheduledOrderSummary, 1));
};


/**
 * @param {!Array<!proto.vtv1.ScheduledOrderSummary>} value
 * @return {!proto.vtv1.NodeListScheduledOrdersResponse} returns this
*/
proto.vtv1.NodeListScheduledOrdersResponse.prototype.setOrdersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.vtv1.ScheduledOrderSummary=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.ScheduledOrderSummary}
 */
proto.vtv1.NodeListScheduledOrdersResponse.prototype.addOrders = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.vtv1.ScheduledOrderSummary, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.NodeListScheduledOrdersResponse} returns this
 */
proto.vtv1.NodeListScheduledOrdersResponse.prototype.clearOrdersList = function() {
  return this.setOrdersList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.NodeListInProgressOrdersResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.NodeListInProgressOrdersResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.NodeListInProgressOrdersResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.NodeListInProgressOrdersResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.NodeListInProgressOrdersResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    ordersList: jspb.Message.toObjectList(msg.getOrdersList(),
    proto_vorto_node_node_pb.InProgressOrderSummary.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.NodeListInProgressOrdersResponse}
 */
proto.vtv1.NodeListInProgressOrdersResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.NodeListInProgressOrdersResponse;
  return proto.vtv1.NodeListInProgressOrdersResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.NodeListInProgressOrdersResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.NodeListInProgressOrdersResponse}
 */
proto.vtv1.NodeListInProgressOrdersResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_vorto_node_node_pb.InProgressOrderSummary;
      reader.readMessage(value,proto_vorto_node_node_pb.InProgressOrderSummary.deserializeBinaryFromReader);
      msg.addOrders(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.NodeListInProgressOrdersResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.NodeListInProgressOrdersResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.NodeListInProgressOrdersResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.NodeListInProgressOrdersResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrdersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto_vorto_node_node_pb.InProgressOrderSummary.serializeBinaryToWriter
    );
  }
};


/**
 * repeated InProgressOrderSummary orders = 1;
 * @return {!Array<!proto.vtv1.InProgressOrderSummary>}
 */
proto.vtv1.NodeListInProgressOrdersResponse.prototype.getOrdersList = function() {
  return /** @type{!Array<!proto.vtv1.InProgressOrderSummary>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_vorto_node_node_pb.InProgressOrderSummary, 1));
};


/**
 * @param {!Array<!proto.vtv1.InProgressOrderSummary>} value
 * @return {!proto.vtv1.NodeListInProgressOrdersResponse} returns this
*/
proto.vtv1.NodeListInProgressOrdersResponse.prototype.setOrdersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.vtv1.InProgressOrderSummary=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.InProgressOrderSummary}
 */
proto.vtv1.NodeListInProgressOrdersResponse.prototype.addOrders = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.vtv1.InProgressOrderSummary, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.NodeListInProgressOrdersResponse} returns this
 */
proto.vtv1.NodeListInProgressOrdersResponse.prototype.clearOrdersList = function() {
  return this.setOrdersList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.NodeListArrivedOrdersResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.NodeListArrivedOrdersResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.NodeListArrivedOrdersResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.NodeListArrivedOrdersResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.NodeListArrivedOrdersResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    ordersList: jspb.Message.toObjectList(msg.getOrdersList(),
    proto_vorto_node_node_pb.ArrivedOrderSummary.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.NodeListArrivedOrdersResponse}
 */
proto.vtv1.NodeListArrivedOrdersResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.NodeListArrivedOrdersResponse;
  return proto.vtv1.NodeListArrivedOrdersResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.NodeListArrivedOrdersResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.NodeListArrivedOrdersResponse}
 */
proto.vtv1.NodeListArrivedOrdersResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_vorto_node_node_pb.ArrivedOrderSummary;
      reader.readMessage(value,proto_vorto_node_node_pb.ArrivedOrderSummary.deserializeBinaryFromReader);
      msg.addOrders(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.NodeListArrivedOrdersResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.NodeListArrivedOrdersResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.NodeListArrivedOrdersResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.NodeListArrivedOrdersResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrdersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto_vorto_node_node_pb.ArrivedOrderSummary.serializeBinaryToWriter
    );
  }
};


/**
 * repeated ArrivedOrderSummary orders = 1;
 * @return {!Array<!proto.vtv1.ArrivedOrderSummary>}
 */
proto.vtv1.NodeListArrivedOrdersResponse.prototype.getOrdersList = function() {
  return /** @type{!Array<!proto.vtv1.ArrivedOrderSummary>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_vorto_node_node_pb.ArrivedOrderSummary, 1));
};


/**
 * @param {!Array<!proto.vtv1.ArrivedOrderSummary>} value
 * @return {!proto.vtv1.NodeListArrivedOrdersResponse} returns this
*/
proto.vtv1.NodeListArrivedOrdersResponse.prototype.setOrdersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.vtv1.ArrivedOrderSummary=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.ArrivedOrderSummary}
 */
proto.vtv1.NodeListArrivedOrdersResponse.prototype.addOrders = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.vtv1.ArrivedOrderSummary, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.NodeListArrivedOrdersResponse} returns this
 */
proto.vtv1.NodeListArrivedOrdersResponse.prototype.clearOrdersList = function() {
  return this.setOrdersList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.UpdateRestockStrategyRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.UpdateRestockStrategyRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.UpdateRestockStrategyRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.UpdateRestockStrategyRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    inventoryId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    restockStrategy: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.UpdateRestockStrategyRequest}
 */
proto.vtv1.UpdateRestockStrategyRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.UpdateRestockStrategyRequest;
  return proto.vtv1.UpdateRestockStrategyRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.UpdateRestockStrategyRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.UpdateRestockStrategyRequest}
 */
proto.vtv1.UpdateRestockStrategyRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setInventoryId(value);
      break;
    case 2:
      var value = /** @type {!proto.vtv1.RestockStrategy} */ (reader.readEnum());
      msg.setRestockStrategy(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.UpdateRestockStrategyRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.UpdateRestockStrategyRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.UpdateRestockStrategyRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.UpdateRestockStrategyRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInventoryId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRestockStrategy();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional string inventory_id = 1;
 * @return {string}
 */
proto.vtv1.UpdateRestockStrategyRequest.prototype.getInventoryId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.UpdateRestockStrategyRequest} returns this
 */
proto.vtv1.UpdateRestockStrategyRequest.prototype.setInventoryId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional RestockStrategy restock_strategy = 2;
 * @return {!proto.vtv1.RestockStrategy}
 */
proto.vtv1.UpdateRestockStrategyRequest.prototype.getRestockStrategy = function() {
  return /** @type {!proto.vtv1.RestockStrategy} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.vtv1.RestockStrategy} value
 * @return {!proto.vtv1.UpdateRestockStrategyRequest} returns this
 */
proto.vtv1.UpdateRestockStrategyRequest.prototype.setRestockStrategy = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.NodeCreateInventoryReportRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.NodeCreateInventoryReportRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.NodeCreateInventoryReportRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.NodeCreateInventoryReportRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    inventoryId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    description: jspb.Message.getFieldWithDefault(msg, 2, ""),
    estimatedDurationMinutes: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.NodeCreateInventoryReportRequest}
 */
proto.vtv1.NodeCreateInventoryReportRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.NodeCreateInventoryReportRequest;
  return proto.vtv1.NodeCreateInventoryReportRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.NodeCreateInventoryReportRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.NodeCreateInventoryReportRequest}
 */
proto.vtv1.NodeCreateInventoryReportRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setInventoryId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setEstimatedDurationMinutes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.NodeCreateInventoryReportRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.NodeCreateInventoryReportRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.NodeCreateInventoryReportRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.NodeCreateInventoryReportRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInventoryId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getEstimatedDurationMinutes();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
};


/**
 * optional string inventory_id = 1;
 * @return {string}
 */
proto.vtv1.NodeCreateInventoryReportRequest.prototype.getInventoryId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.NodeCreateInventoryReportRequest} returns this
 */
proto.vtv1.NodeCreateInventoryReportRequest.prototype.setInventoryId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string description = 2;
 * @return {string}
 */
proto.vtv1.NodeCreateInventoryReportRequest.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.NodeCreateInventoryReportRequest} returns this
 */
proto.vtv1.NodeCreateInventoryReportRequest.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 estimated_duration_minutes = 3;
 * @return {number}
 */
proto.vtv1.NodeCreateInventoryReportRequest.prototype.getEstimatedDurationMinutes = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.NodeCreateInventoryReportRequest} returns this
 */
proto.vtv1.NodeCreateInventoryReportRequest.prototype.setEstimatedDurationMinutes = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.NodeUpdateInventoryReportRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.NodeUpdateInventoryReportRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.NodeUpdateInventoryReportRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.NodeUpdateInventoryReportRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    inventoryReportId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    description: (f = msg.getDescription()) && proto_vorto_types_types_pb.NullableString.toObject(includeInstance, f),
    estimatedDurationMinutes: (f = msg.getEstimatedDurationMinutes()) && proto_vorto_types_types_pb.NullableInt64.toObject(includeInstance, f),
    resolved: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.NodeUpdateInventoryReportRequest}
 */
proto.vtv1.NodeUpdateInventoryReportRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.NodeUpdateInventoryReportRequest;
  return proto.vtv1.NodeUpdateInventoryReportRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.NodeUpdateInventoryReportRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.NodeUpdateInventoryReportRequest}
 */
proto.vtv1.NodeUpdateInventoryReportRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setInventoryReportId(value);
      break;
    case 2:
      var value = new proto_vorto_types_types_pb.NullableString;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableString.deserializeBinaryFromReader);
      msg.setDescription(value);
      break;
    case 3:
      var value = new proto_vorto_types_types_pb.NullableInt64;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableInt64.deserializeBinaryFromReader);
      msg.setEstimatedDurationMinutes(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setResolved(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.NodeUpdateInventoryReportRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.NodeUpdateInventoryReportRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.NodeUpdateInventoryReportRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.NodeUpdateInventoryReportRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInventoryReportId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDescription();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_vorto_types_types_pb.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getEstimatedDurationMinutes();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto_vorto_types_types_pb.NullableInt64.serializeBinaryToWriter
    );
  }
  f = message.getResolved();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
};


/**
 * optional string inventory_report_id = 1;
 * @return {string}
 */
proto.vtv1.NodeUpdateInventoryReportRequest.prototype.getInventoryReportId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.NodeUpdateInventoryReportRequest} returns this
 */
proto.vtv1.NodeUpdateInventoryReportRequest.prototype.setInventoryReportId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional NullableString description = 2;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.NodeUpdateInventoryReportRequest.prototype.getDescription = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableString, 2));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.NodeUpdateInventoryReportRequest} returns this
*/
proto.vtv1.NodeUpdateInventoryReportRequest.prototype.setDescription = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.NodeUpdateInventoryReportRequest} returns this
 */
proto.vtv1.NodeUpdateInventoryReportRequest.prototype.clearDescription = function() {
  return this.setDescription(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.NodeUpdateInventoryReportRequest.prototype.hasDescription = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional NullableInt64 estimated_duration_minutes = 3;
 * @return {?proto.vtv1.NullableInt64}
 */
proto.vtv1.NodeUpdateInventoryReportRequest.prototype.getEstimatedDurationMinutes = function() {
  return /** @type{?proto.vtv1.NullableInt64} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableInt64, 3));
};


/**
 * @param {?proto.vtv1.NullableInt64|undefined} value
 * @return {!proto.vtv1.NodeUpdateInventoryReportRequest} returns this
*/
proto.vtv1.NodeUpdateInventoryReportRequest.prototype.setEstimatedDurationMinutes = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.NodeUpdateInventoryReportRequest} returns this
 */
proto.vtv1.NodeUpdateInventoryReportRequest.prototype.clearEstimatedDurationMinutes = function() {
  return this.setEstimatedDurationMinutes(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.NodeUpdateInventoryReportRequest.prototype.hasEstimatedDurationMinutes = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional bool resolved = 4;
 * @return {boolean}
 */
proto.vtv1.NodeUpdateInventoryReportRequest.prototype.getResolved = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vtv1.NodeUpdateInventoryReportRequest} returns this
 */
proto.vtv1.NodeUpdateInventoryReportRequest.prototype.setResolved = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.NodeCreateSiteReportRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.NodeCreateSiteReportRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.NodeCreateSiteReportRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.NodeCreateSiteReportRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    siteId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    description: jspb.Message.getFieldWithDefault(msg, 2, ""),
    estimatedDurationMinutes: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.NodeCreateSiteReportRequest}
 */
proto.vtv1.NodeCreateSiteReportRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.NodeCreateSiteReportRequest;
  return proto.vtv1.NodeCreateSiteReportRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.NodeCreateSiteReportRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.NodeCreateSiteReportRequest}
 */
proto.vtv1.NodeCreateSiteReportRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSiteId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setEstimatedDurationMinutes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.NodeCreateSiteReportRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.NodeCreateSiteReportRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.NodeCreateSiteReportRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.NodeCreateSiteReportRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSiteId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getEstimatedDurationMinutes();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
};


/**
 * optional string site_id = 1;
 * @return {string}
 */
proto.vtv1.NodeCreateSiteReportRequest.prototype.getSiteId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.NodeCreateSiteReportRequest} returns this
 */
proto.vtv1.NodeCreateSiteReportRequest.prototype.setSiteId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string description = 2;
 * @return {string}
 */
proto.vtv1.NodeCreateSiteReportRequest.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.NodeCreateSiteReportRequest} returns this
 */
proto.vtv1.NodeCreateSiteReportRequest.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 estimated_duration_minutes = 3;
 * @return {number}
 */
proto.vtv1.NodeCreateSiteReportRequest.prototype.getEstimatedDurationMinutes = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.NodeCreateSiteReportRequest} returns this
 */
proto.vtv1.NodeCreateSiteReportRequest.prototype.setEstimatedDurationMinutes = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.NodeUpdateSiteReportRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.NodeUpdateSiteReportRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.NodeUpdateSiteReportRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.NodeUpdateSiteReportRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    siteReportId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    description: (f = msg.getDescription()) && proto_vorto_types_types_pb.NullableString.toObject(includeInstance, f),
    estimatedDurationMinutes: (f = msg.getEstimatedDurationMinutes()) && proto_vorto_types_types_pb.NullableInt64.toObject(includeInstance, f),
    resolved: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.NodeUpdateSiteReportRequest}
 */
proto.vtv1.NodeUpdateSiteReportRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.NodeUpdateSiteReportRequest;
  return proto.vtv1.NodeUpdateSiteReportRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.NodeUpdateSiteReportRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.NodeUpdateSiteReportRequest}
 */
proto.vtv1.NodeUpdateSiteReportRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSiteReportId(value);
      break;
    case 2:
      var value = new proto_vorto_types_types_pb.NullableString;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableString.deserializeBinaryFromReader);
      msg.setDescription(value);
      break;
    case 3:
      var value = new proto_vorto_types_types_pb.NullableInt64;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableInt64.deserializeBinaryFromReader);
      msg.setEstimatedDurationMinutes(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setResolved(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.NodeUpdateSiteReportRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.NodeUpdateSiteReportRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.NodeUpdateSiteReportRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.NodeUpdateSiteReportRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSiteReportId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDescription();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_vorto_types_types_pb.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getEstimatedDurationMinutes();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto_vorto_types_types_pb.NullableInt64.serializeBinaryToWriter
    );
  }
  f = message.getResolved();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
};


/**
 * optional string site_report_id = 1;
 * @return {string}
 */
proto.vtv1.NodeUpdateSiteReportRequest.prototype.getSiteReportId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.NodeUpdateSiteReportRequest} returns this
 */
proto.vtv1.NodeUpdateSiteReportRequest.prototype.setSiteReportId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional NullableString description = 2;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.NodeUpdateSiteReportRequest.prototype.getDescription = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableString, 2));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.NodeUpdateSiteReportRequest} returns this
*/
proto.vtv1.NodeUpdateSiteReportRequest.prototype.setDescription = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.NodeUpdateSiteReportRequest} returns this
 */
proto.vtv1.NodeUpdateSiteReportRequest.prototype.clearDescription = function() {
  return this.setDescription(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.NodeUpdateSiteReportRequest.prototype.hasDescription = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional NullableInt64 estimated_duration_minutes = 3;
 * @return {?proto.vtv1.NullableInt64}
 */
proto.vtv1.NodeUpdateSiteReportRequest.prototype.getEstimatedDurationMinutes = function() {
  return /** @type{?proto.vtv1.NullableInt64} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableInt64, 3));
};


/**
 * @param {?proto.vtv1.NullableInt64|undefined} value
 * @return {!proto.vtv1.NodeUpdateSiteReportRequest} returns this
*/
proto.vtv1.NodeUpdateSiteReportRequest.prototype.setEstimatedDurationMinutes = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.NodeUpdateSiteReportRequest} returns this
 */
proto.vtv1.NodeUpdateSiteReportRequest.prototype.clearEstimatedDurationMinutes = function() {
  return this.setEstimatedDurationMinutes(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.NodeUpdateSiteReportRequest.prototype.hasEstimatedDurationMinutes = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional bool resolved = 4;
 * @return {boolean}
 */
proto.vtv1.NodeUpdateSiteReportRequest.prototype.getResolved = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vtv1.NodeUpdateSiteReportRequest} returns this
 */
proto.vtv1.NodeUpdateSiteReportRequest.prototype.setResolved = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.GetDashboardSiteDetailResponse.repeatedFields_ = [1,2,3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.GetDashboardSiteDetailResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.GetDashboardSiteDetailResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.GetDashboardSiteDetailResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.GetDashboardSiteDetailResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    needsAttentionList: jspb.Message.toObjectList(msg.getNeedsAttentionList(),
    proto_vorto_node_node_pb.DashboardSiteResponse.toObject, includeInstance),
    usedMostList: jspb.Message.toObjectList(msg.getUsedMostList(),
    proto_vorto_node_node_pb.DashboardSiteResponse.toObject, includeInstance),
    recentlyAddedList: jspb.Message.toObjectList(msg.getRecentlyAddedList(),
    proto_vorto_node_node_pb.DashboardSiteResponse.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.GetDashboardSiteDetailResponse}
 */
proto.vtv1.GetDashboardSiteDetailResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.GetDashboardSiteDetailResponse;
  return proto.vtv1.GetDashboardSiteDetailResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.GetDashboardSiteDetailResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.GetDashboardSiteDetailResponse}
 */
proto.vtv1.GetDashboardSiteDetailResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_vorto_node_node_pb.DashboardSiteResponse;
      reader.readMessage(value,proto_vorto_node_node_pb.DashboardSiteResponse.deserializeBinaryFromReader);
      msg.addNeedsAttention(value);
      break;
    case 2:
      var value = new proto_vorto_node_node_pb.DashboardSiteResponse;
      reader.readMessage(value,proto_vorto_node_node_pb.DashboardSiteResponse.deserializeBinaryFromReader);
      msg.addUsedMost(value);
      break;
    case 3:
      var value = new proto_vorto_node_node_pb.DashboardSiteResponse;
      reader.readMessage(value,proto_vorto_node_node_pb.DashboardSiteResponse.deserializeBinaryFromReader);
      msg.addRecentlyAdded(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.GetDashboardSiteDetailResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.GetDashboardSiteDetailResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.GetDashboardSiteDetailResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.GetDashboardSiteDetailResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNeedsAttentionList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto_vorto_node_node_pb.DashboardSiteResponse.serializeBinaryToWriter
    );
  }
  f = message.getUsedMostList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto_vorto_node_node_pb.DashboardSiteResponse.serializeBinaryToWriter
    );
  }
  f = message.getRecentlyAddedList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto_vorto_node_node_pb.DashboardSiteResponse.serializeBinaryToWriter
    );
  }
};


/**
 * repeated DashboardSiteResponse needs_attention = 1;
 * @return {!Array<!proto.vtv1.DashboardSiteResponse>}
 */
proto.vtv1.GetDashboardSiteDetailResponse.prototype.getNeedsAttentionList = function() {
  return /** @type{!Array<!proto.vtv1.DashboardSiteResponse>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_vorto_node_node_pb.DashboardSiteResponse, 1));
};


/**
 * @param {!Array<!proto.vtv1.DashboardSiteResponse>} value
 * @return {!proto.vtv1.GetDashboardSiteDetailResponse} returns this
*/
proto.vtv1.GetDashboardSiteDetailResponse.prototype.setNeedsAttentionList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.vtv1.DashboardSiteResponse=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.DashboardSiteResponse}
 */
proto.vtv1.GetDashboardSiteDetailResponse.prototype.addNeedsAttention = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.vtv1.DashboardSiteResponse, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.GetDashboardSiteDetailResponse} returns this
 */
proto.vtv1.GetDashboardSiteDetailResponse.prototype.clearNeedsAttentionList = function() {
  return this.setNeedsAttentionList([]);
};


/**
 * repeated DashboardSiteResponse used_most = 2;
 * @return {!Array<!proto.vtv1.DashboardSiteResponse>}
 */
proto.vtv1.GetDashboardSiteDetailResponse.prototype.getUsedMostList = function() {
  return /** @type{!Array<!proto.vtv1.DashboardSiteResponse>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_vorto_node_node_pb.DashboardSiteResponse, 2));
};


/**
 * @param {!Array<!proto.vtv1.DashboardSiteResponse>} value
 * @return {!proto.vtv1.GetDashboardSiteDetailResponse} returns this
*/
proto.vtv1.GetDashboardSiteDetailResponse.prototype.setUsedMostList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.vtv1.DashboardSiteResponse=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.DashboardSiteResponse}
 */
proto.vtv1.GetDashboardSiteDetailResponse.prototype.addUsedMost = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.vtv1.DashboardSiteResponse, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.GetDashboardSiteDetailResponse} returns this
 */
proto.vtv1.GetDashboardSiteDetailResponse.prototype.clearUsedMostList = function() {
  return this.setUsedMostList([]);
};


/**
 * repeated DashboardSiteResponse recently_added = 3;
 * @return {!Array<!proto.vtv1.DashboardSiteResponse>}
 */
proto.vtv1.GetDashboardSiteDetailResponse.prototype.getRecentlyAddedList = function() {
  return /** @type{!Array<!proto.vtv1.DashboardSiteResponse>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_vorto_node_node_pb.DashboardSiteResponse, 3));
};


/**
 * @param {!Array<!proto.vtv1.DashboardSiteResponse>} value
 * @return {!proto.vtv1.GetDashboardSiteDetailResponse} returns this
*/
proto.vtv1.GetDashboardSiteDetailResponse.prototype.setRecentlyAddedList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.vtv1.DashboardSiteResponse=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.DashboardSiteResponse}
 */
proto.vtv1.GetDashboardSiteDetailResponse.prototype.addRecentlyAdded = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.vtv1.DashboardSiteResponse, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.GetDashboardSiteDetailResponse} returns this
 */
proto.vtv1.GetDashboardSiteDetailResponse.prototype.clearRecentlyAddedList = function() {
  return this.setRecentlyAddedList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.GetDashboardEdgeDetailResponse.repeatedFields_ = [1,2,3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.GetDashboardEdgeDetailResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.GetDashboardEdgeDetailResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.GetDashboardEdgeDetailResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.GetDashboardEdgeDetailResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    needsAttentionList: jspb.Message.toObjectList(msg.getNeedsAttentionList(),
    proto_vorto_node_node_pb.DashboardEdgeResponse.toObject, includeInstance),
    usedMostList: jspb.Message.toObjectList(msg.getUsedMostList(),
    proto_vorto_node_node_pb.DashboardEdgeResponse.toObject, includeInstance),
    recentlyAddedList: jspb.Message.toObjectList(msg.getRecentlyAddedList(),
    proto_vorto_node_node_pb.DashboardEdgeResponse.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.GetDashboardEdgeDetailResponse}
 */
proto.vtv1.GetDashboardEdgeDetailResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.GetDashboardEdgeDetailResponse;
  return proto.vtv1.GetDashboardEdgeDetailResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.GetDashboardEdgeDetailResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.GetDashboardEdgeDetailResponse}
 */
proto.vtv1.GetDashboardEdgeDetailResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_vorto_node_node_pb.DashboardEdgeResponse;
      reader.readMessage(value,proto_vorto_node_node_pb.DashboardEdgeResponse.deserializeBinaryFromReader);
      msg.addNeedsAttention(value);
      break;
    case 2:
      var value = new proto_vorto_node_node_pb.DashboardEdgeResponse;
      reader.readMessage(value,proto_vorto_node_node_pb.DashboardEdgeResponse.deserializeBinaryFromReader);
      msg.addUsedMost(value);
      break;
    case 3:
      var value = new proto_vorto_node_node_pb.DashboardEdgeResponse;
      reader.readMessage(value,proto_vorto_node_node_pb.DashboardEdgeResponse.deserializeBinaryFromReader);
      msg.addRecentlyAdded(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.GetDashboardEdgeDetailResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.GetDashboardEdgeDetailResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.GetDashboardEdgeDetailResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.GetDashboardEdgeDetailResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNeedsAttentionList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto_vorto_node_node_pb.DashboardEdgeResponse.serializeBinaryToWriter
    );
  }
  f = message.getUsedMostList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto_vorto_node_node_pb.DashboardEdgeResponse.serializeBinaryToWriter
    );
  }
  f = message.getRecentlyAddedList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto_vorto_node_node_pb.DashboardEdgeResponse.serializeBinaryToWriter
    );
  }
};


/**
 * repeated DashboardEdgeResponse needs_attention = 1;
 * @return {!Array<!proto.vtv1.DashboardEdgeResponse>}
 */
proto.vtv1.GetDashboardEdgeDetailResponse.prototype.getNeedsAttentionList = function() {
  return /** @type{!Array<!proto.vtv1.DashboardEdgeResponse>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_vorto_node_node_pb.DashboardEdgeResponse, 1));
};


/**
 * @param {!Array<!proto.vtv1.DashboardEdgeResponse>} value
 * @return {!proto.vtv1.GetDashboardEdgeDetailResponse} returns this
*/
proto.vtv1.GetDashboardEdgeDetailResponse.prototype.setNeedsAttentionList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.vtv1.DashboardEdgeResponse=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.DashboardEdgeResponse}
 */
proto.vtv1.GetDashboardEdgeDetailResponse.prototype.addNeedsAttention = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.vtv1.DashboardEdgeResponse, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.GetDashboardEdgeDetailResponse} returns this
 */
proto.vtv1.GetDashboardEdgeDetailResponse.prototype.clearNeedsAttentionList = function() {
  return this.setNeedsAttentionList([]);
};


/**
 * repeated DashboardEdgeResponse used_most = 2;
 * @return {!Array<!proto.vtv1.DashboardEdgeResponse>}
 */
proto.vtv1.GetDashboardEdgeDetailResponse.prototype.getUsedMostList = function() {
  return /** @type{!Array<!proto.vtv1.DashboardEdgeResponse>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_vorto_node_node_pb.DashboardEdgeResponse, 2));
};


/**
 * @param {!Array<!proto.vtv1.DashboardEdgeResponse>} value
 * @return {!proto.vtv1.GetDashboardEdgeDetailResponse} returns this
*/
proto.vtv1.GetDashboardEdgeDetailResponse.prototype.setUsedMostList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.vtv1.DashboardEdgeResponse=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.DashboardEdgeResponse}
 */
proto.vtv1.GetDashboardEdgeDetailResponse.prototype.addUsedMost = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.vtv1.DashboardEdgeResponse, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.GetDashboardEdgeDetailResponse} returns this
 */
proto.vtv1.GetDashboardEdgeDetailResponse.prototype.clearUsedMostList = function() {
  return this.setUsedMostList([]);
};


/**
 * repeated DashboardEdgeResponse recently_added = 3;
 * @return {!Array<!proto.vtv1.DashboardEdgeResponse>}
 */
proto.vtv1.GetDashboardEdgeDetailResponse.prototype.getRecentlyAddedList = function() {
  return /** @type{!Array<!proto.vtv1.DashboardEdgeResponse>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_vorto_node_node_pb.DashboardEdgeResponse, 3));
};


/**
 * @param {!Array<!proto.vtv1.DashboardEdgeResponse>} value
 * @return {!proto.vtv1.GetDashboardEdgeDetailResponse} returns this
*/
proto.vtv1.GetDashboardEdgeDetailResponse.prototype.setRecentlyAddedList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.vtv1.DashboardEdgeResponse=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.DashboardEdgeResponse}
 */
proto.vtv1.GetDashboardEdgeDetailResponse.prototype.addRecentlyAdded = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.vtv1.DashboardEdgeResponse, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.GetDashboardEdgeDetailResponse} returns this
 */
proto.vtv1.GetDashboardEdgeDetailResponse.prototype.clearRecentlyAddedList = function() {
  return this.setRecentlyAddedList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.GetDashboardSupplierDetailResponse.repeatedFields_ = [1,2,3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.GetDashboardSupplierDetailResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.GetDashboardSupplierDetailResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.GetDashboardSupplierDetailResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.GetDashboardSupplierDetailResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    needsAttentionList: jspb.Message.toObjectList(msg.getNeedsAttentionList(),
    proto_vorto_node_node_pb.DashboardSupplierResponse.toObject, includeInstance),
    usedMostList: jspb.Message.toObjectList(msg.getUsedMostList(),
    proto_vorto_node_node_pb.DashboardSupplierResponse.toObject, includeInstance),
    recentlyAddedList: jspb.Message.toObjectList(msg.getRecentlyAddedList(),
    proto_vorto_node_node_pb.DashboardSupplierResponse.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.GetDashboardSupplierDetailResponse}
 */
proto.vtv1.GetDashboardSupplierDetailResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.GetDashboardSupplierDetailResponse;
  return proto.vtv1.GetDashboardSupplierDetailResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.GetDashboardSupplierDetailResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.GetDashboardSupplierDetailResponse}
 */
proto.vtv1.GetDashboardSupplierDetailResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_vorto_node_node_pb.DashboardSupplierResponse;
      reader.readMessage(value,proto_vorto_node_node_pb.DashboardSupplierResponse.deserializeBinaryFromReader);
      msg.addNeedsAttention(value);
      break;
    case 2:
      var value = new proto_vorto_node_node_pb.DashboardSupplierResponse;
      reader.readMessage(value,proto_vorto_node_node_pb.DashboardSupplierResponse.deserializeBinaryFromReader);
      msg.addUsedMost(value);
      break;
    case 3:
      var value = new proto_vorto_node_node_pb.DashboardSupplierResponse;
      reader.readMessage(value,proto_vorto_node_node_pb.DashboardSupplierResponse.deserializeBinaryFromReader);
      msg.addRecentlyAdded(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.GetDashboardSupplierDetailResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.GetDashboardSupplierDetailResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.GetDashboardSupplierDetailResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.GetDashboardSupplierDetailResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNeedsAttentionList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto_vorto_node_node_pb.DashboardSupplierResponse.serializeBinaryToWriter
    );
  }
  f = message.getUsedMostList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto_vorto_node_node_pb.DashboardSupplierResponse.serializeBinaryToWriter
    );
  }
  f = message.getRecentlyAddedList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto_vorto_node_node_pb.DashboardSupplierResponse.serializeBinaryToWriter
    );
  }
};


/**
 * repeated DashboardSupplierResponse needs_attention = 1;
 * @return {!Array<!proto.vtv1.DashboardSupplierResponse>}
 */
proto.vtv1.GetDashboardSupplierDetailResponse.prototype.getNeedsAttentionList = function() {
  return /** @type{!Array<!proto.vtv1.DashboardSupplierResponse>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_vorto_node_node_pb.DashboardSupplierResponse, 1));
};


/**
 * @param {!Array<!proto.vtv1.DashboardSupplierResponse>} value
 * @return {!proto.vtv1.GetDashboardSupplierDetailResponse} returns this
*/
proto.vtv1.GetDashboardSupplierDetailResponse.prototype.setNeedsAttentionList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.vtv1.DashboardSupplierResponse=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.DashboardSupplierResponse}
 */
proto.vtv1.GetDashboardSupplierDetailResponse.prototype.addNeedsAttention = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.vtv1.DashboardSupplierResponse, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.GetDashboardSupplierDetailResponse} returns this
 */
proto.vtv1.GetDashboardSupplierDetailResponse.prototype.clearNeedsAttentionList = function() {
  return this.setNeedsAttentionList([]);
};


/**
 * repeated DashboardSupplierResponse used_most = 2;
 * @return {!Array<!proto.vtv1.DashboardSupplierResponse>}
 */
proto.vtv1.GetDashboardSupplierDetailResponse.prototype.getUsedMostList = function() {
  return /** @type{!Array<!proto.vtv1.DashboardSupplierResponse>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_vorto_node_node_pb.DashboardSupplierResponse, 2));
};


/**
 * @param {!Array<!proto.vtv1.DashboardSupplierResponse>} value
 * @return {!proto.vtv1.GetDashboardSupplierDetailResponse} returns this
*/
proto.vtv1.GetDashboardSupplierDetailResponse.prototype.setUsedMostList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.vtv1.DashboardSupplierResponse=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.DashboardSupplierResponse}
 */
proto.vtv1.GetDashboardSupplierDetailResponse.prototype.addUsedMost = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.vtv1.DashboardSupplierResponse, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.GetDashboardSupplierDetailResponse} returns this
 */
proto.vtv1.GetDashboardSupplierDetailResponse.prototype.clearUsedMostList = function() {
  return this.setUsedMostList([]);
};


/**
 * repeated DashboardSupplierResponse recently_added = 3;
 * @return {!Array<!proto.vtv1.DashboardSupplierResponse>}
 */
proto.vtv1.GetDashboardSupplierDetailResponse.prototype.getRecentlyAddedList = function() {
  return /** @type{!Array<!proto.vtv1.DashboardSupplierResponse>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_vorto_node_node_pb.DashboardSupplierResponse, 3));
};


/**
 * @param {!Array<!proto.vtv1.DashboardSupplierResponse>} value
 * @return {!proto.vtv1.GetDashboardSupplierDetailResponse} returns this
*/
proto.vtv1.GetDashboardSupplierDetailResponse.prototype.setRecentlyAddedList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.vtv1.DashboardSupplierResponse=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.DashboardSupplierResponse}
 */
proto.vtv1.GetDashboardSupplierDetailResponse.prototype.addRecentlyAdded = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.vtv1.DashboardSupplierResponse, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.GetDashboardSupplierDetailResponse} returns this
 */
proto.vtv1.GetDashboardSupplierDetailResponse.prototype.clearRecentlyAddedList = function() {
  return this.setRecentlyAddedList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.GetDashboardAutopilotFeedResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.GetDashboardAutopilotFeedResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.GetDashboardAutopilotFeedResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.GetDashboardAutopilotFeedResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.GetDashboardAutopilotFeedResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    feedList: jspb.Message.toObjectList(msg.getFeedList(),
    proto_vorto_node_node_pb.AutopilotFeed.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.GetDashboardAutopilotFeedResponse}
 */
proto.vtv1.GetDashboardAutopilotFeedResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.GetDashboardAutopilotFeedResponse;
  return proto.vtv1.GetDashboardAutopilotFeedResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.GetDashboardAutopilotFeedResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.GetDashboardAutopilotFeedResponse}
 */
proto.vtv1.GetDashboardAutopilotFeedResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_vorto_node_node_pb.AutopilotFeed;
      reader.readMessage(value,proto_vorto_node_node_pb.AutopilotFeed.deserializeBinaryFromReader);
      msg.addFeed(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.GetDashboardAutopilotFeedResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.GetDashboardAutopilotFeedResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.GetDashboardAutopilotFeedResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.GetDashboardAutopilotFeedResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFeedList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto_vorto_node_node_pb.AutopilotFeed.serializeBinaryToWriter
    );
  }
};


/**
 * repeated AutopilotFeed feed = 1;
 * @return {!Array<!proto.vtv1.AutopilotFeed>}
 */
proto.vtv1.GetDashboardAutopilotFeedResponse.prototype.getFeedList = function() {
  return /** @type{!Array<!proto.vtv1.AutopilotFeed>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_vorto_node_node_pb.AutopilotFeed, 1));
};


/**
 * @param {!Array<!proto.vtv1.AutopilotFeed>} value
 * @return {!proto.vtv1.GetDashboardAutopilotFeedResponse} returns this
*/
proto.vtv1.GetDashboardAutopilotFeedResponse.prototype.setFeedList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.vtv1.AutopilotFeed=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.AutopilotFeed}
 */
proto.vtv1.GetDashboardAutopilotFeedResponse.prototype.addFeed = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.vtv1.AutopilotFeed, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.GetDashboardAutopilotFeedResponse} returns this
 */
proto.vtv1.GetDashboardAutopilotFeedResponse.prototype.clearFeedList = function() {
  return this.setFeedList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.SupplierCreateSiteResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.SupplierCreateSiteResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.SupplierCreateSiteResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.SupplierCreateSiteResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    site: (f = msg.getSite()) && proto_vorto_types_types_pb.Site.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.SupplierCreateSiteResponse}
 */
proto.vtv1.SupplierCreateSiteResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.SupplierCreateSiteResponse;
  return proto.vtv1.SupplierCreateSiteResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.SupplierCreateSiteResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.SupplierCreateSiteResponse}
 */
proto.vtv1.SupplierCreateSiteResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_vorto_types_types_pb.Site;
      reader.readMessage(value,proto_vorto_types_types_pb.Site.deserializeBinaryFromReader);
      msg.setSite(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.SupplierCreateSiteResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.SupplierCreateSiteResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.SupplierCreateSiteResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.SupplierCreateSiteResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSite();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_vorto_types_types_pb.Site.serializeBinaryToWriter
    );
  }
};


/**
 * optional Site site = 1;
 * @return {?proto.vtv1.Site}
 */
proto.vtv1.SupplierCreateSiteResponse.prototype.getSite = function() {
  return /** @type{?proto.vtv1.Site} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.Site, 1));
};


/**
 * @param {?proto.vtv1.Site|undefined} value
 * @return {!proto.vtv1.SupplierCreateSiteResponse} returns this
*/
proto.vtv1.SupplierCreateSiteResponse.prototype.setSite = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.SupplierCreateSiteResponse} returns this
 */
proto.vtv1.SupplierCreateSiteResponse.prototype.clearSite = function() {
  return this.setSite(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.SupplierCreateSiteResponse.prototype.hasSite = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.CreateResponseCriteria.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.CreateResponseCriteria.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.CreateResponseCriteria} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.CreateResponseCriteria.toObject = function(includeInstance, msg) {
  var f, obj = {
    siteId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    quantity: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    demandSignalRequestId: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.CreateResponseCriteria}
 */
proto.vtv1.CreateResponseCriteria.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.CreateResponseCriteria;
  return proto.vtv1.CreateResponseCriteria.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.CreateResponseCriteria} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.CreateResponseCriteria}
 */
proto.vtv1.CreateResponseCriteria.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSiteId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setQuantity(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDemandSignalRequestId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.CreateResponseCriteria.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.CreateResponseCriteria.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.CreateResponseCriteria} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.CreateResponseCriteria.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSiteId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getQuantity();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getDemandSignalRequestId();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
};


/**
 * optional string site_id = 1;
 * @return {string}
 */
proto.vtv1.CreateResponseCriteria.prototype.getSiteId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.CreateResponseCriteria} returns this
 */
proto.vtv1.CreateResponseCriteria.prototype.setSiteId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional double quantity = 2;
 * @return {number}
 */
proto.vtv1.CreateResponseCriteria.prototype.getQuantity = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.CreateResponseCriteria} returns this
 */
proto.vtv1.CreateResponseCriteria.prototype.setQuantity = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional int64 demand_signal_request_id = 3;
 * @return {number}
 */
proto.vtv1.CreateResponseCriteria.prototype.getDemandSignalRequestId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.CreateResponseCriteria} returns this
 */
proto.vtv1.CreateResponseCriteria.prototype.setDemandSignalRequestId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.CreateEdgeContractRequestAsNode.repeatedFields_ = [6,10,11,13,14];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.CreateEdgeContractRequestAsNode.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.CreateEdgeContractRequestAsNode.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.CreateEdgeContractRequestAsNode} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.CreateEdgeContractRequestAsNode.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    edgeId: (f = msg.getEdgeId()) && proto_vorto_types_types_pb.NullableString.toObject(includeInstance, f),
    startsAt: jspb.Message.getFieldWithDefault(msg, 3, ""),
    type: jspb.Message.getFieldWithDefault(msg, 4, 0),
    expiresAt: (f = msg.getExpiresAt()) && proto_vorto_types_types_pb.NullableTime.toObject(includeInstance, f),
    costPerMileageBracketList: jspb.Message.toObjectList(msg.getCostPerMileageBracketList(),
    proto_vorto_types_types_pb.MileageBracketCost.toObject, includeInstance),
    costPerMile: jspb.Message.getFieldWithDefault(msg, 7, 0),
    isSpotContract: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    edgeCosts: (f = msg.getEdgeCosts()) && proto_vorto_types_types_pb.EdgeCosts.toObject(includeInstance, f),
    productIdsList: (f = jspb.Message.getRepeatedField(msg, 10)) == null ? undefined : f,
    trailerTypesList: jspb.Message.toObjectList(msg.getTrailerTypesList(),
    proto_vorto_types_types_pb.IntIdName.toObject, includeInstance),
    p2pFileId: jspb.Message.getFieldWithDefault(msg, 12, 0),
    regionIdsList: jspb.Message.toObjectList(msg.getRegionIdsList(),
    proto_vorto_types_types_pb.IntIdName.toObject, includeInstance),
    p2pPerTonList: jspb.Message.toObjectList(msg.getP2pPerTonList(),
    proto_vorto_types_types_pb.P2PPTEditEntry.toObject, includeInstance),
    allowCarrierManualComplete: jspb.Message.getBooleanFieldWithDefault(msg, 15, false),
    costPerHour: jspb.Message.getFieldWithDefault(msg, 16, 0),
    minimumCostPerHour: jspb.Message.getFieldWithDefault(msg, 17, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.CreateEdgeContractRequestAsNode}
 */
proto.vtv1.CreateEdgeContractRequestAsNode.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.CreateEdgeContractRequestAsNode;
  return proto.vtv1.CreateEdgeContractRequestAsNode.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.CreateEdgeContractRequestAsNode} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.CreateEdgeContractRequestAsNode}
 */
proto.vtv1.CreateEdgeContractRequestAsNode.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = new proto_vorto_types_types_pb.NullableString;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableString.deserializeBinaryFromReader);
      msg.setEdgeId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setStartsAt(value);
      break;
    case 4:
      var value = /** @type {!proto.vtv1.LinehaulType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 5:
      var value = new proto_vorto_types_types_pb.NullableTime;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableTime.deserializeBinaryFromReader);
      msg.setExpiresAt(value);
      break;
    case 6:
      var value = new proto_vorto_types_types_pb.MileageBracketCost;
      reader.readMessage(value,proto_vorto_types_types_pb.MileageBracketCost.deserializeBinaryFromReader);
      msg.addCostPerMileageBracket(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCostPerMile(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSpotContract(value);
      break;
    case 9:
      var value = new proto_vorto_types_types_pb.EdgeCosts;
      reader.readMessage(value,proto_vorto_types_types_pb.EdgeCosts.deserializeBinaryFromReader);
      msg.setEdgeCosts(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.addProductIds(value);
      break;
    case 11:
      var value = new proto_vorto_types_types_pb.IntIdName;
      reader.readMessage(value,proto_vorto_types_types_pb.IntIdName.deserializeBinaryFromReader);
      msg.addTrailerTypes(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setP2pFileId(value);
      break;
    case 13:
      var value = new proto_vorto_types_types_pb.IntIdName;
      reader.readMessage(value,proto_vorto_types_types_pb.IntIdName.deserializeBinaryFromReader);
      msg.addRegionIds(value);
      break;
    case 14:
      var value = new proto_vorto_types_types_pb.P2PPTEditEntry;
      reader.readMessage(value,proto_vorto_types_types_pb.P2PPTEditEntry.deserializeBinaryFromReader);
      msg.addP2pPerTon(value);
      break;
    case 15:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAllowCarrierManualComplete(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCostPerHour(value);
      break;
    case 17:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMinimumCostPerHour(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.CreateEdgeContractRequestAsNode.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.CreateEdgeContractRequestAsNode.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.CreateEdgeContractRequestAsNode} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.CreateEdgeContractRequestAsNode.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEdgeId();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_vorto_types_types_pb.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getStartsAt();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getExpiresAt();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto_vorto_types_types_pb.NullableTime.serializeBinaryToWriter
    );
  }
  f = message.getCostPerMileageBracketList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto_vorto_types_types_pb.MileageBracketCost.serializeBinaryToWriter
    );
  }
  f = message.getCostPerMile();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
  f = message.getIsSpotContract();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getEdgeCosts();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto_vorto_types_types_pb.EdgeCosts.serializeBinaryToWriter
    );
  }
  f = message.getProductIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      10,
      f
    );
  }
  f = message.getTrailerTypesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      11,
      f,
      proto_vorto_types_types_pb.IntIdName.serializeBinaryToWriter
    );
  }
  f = message.getP2pFileId();
  if (f !== 0) {
    writer.writeInt64(
      12,
      f
    );
  }
  f = message.getRegionIdsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      13,
      f,
      proto_vorto_types_types_pb.IntIdName.serializeBinaryToWriter
    );
  }
  f = message.getP2pPerTonList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      14,
      f,
      proto_vorto_types_types_pb.P2PPTEditEntry.serializeBinaryToWriter
    );
  }
  f = message.getAllowCarrierManualComplete();
  if (f) {
    writer.writeBool(
      15,
      f
    );
  }
  f = message.getCostPerHour();
  if (f !== 0) {
    writer.writeInt64(
      16,
      f
    );
  }
  f = message.getMinimumCostPerHour();
  if (f !== 0) {
    writer.writeInt64(
      17,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.vtv1.CreateEdgeContractRequestAsNode.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.CreateEdgeContractRequestAsNode} returns this
 */
proto.vtv1.CreateEdgeContractRequestAsNode.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional NullableString edge_id = 2;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.CreateEdgeContractRequestAsNode.prototype.getEdgeId = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableString, 2));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.CreateEdgeContractRequestAsNode} returns this
*/
proto.vtv1.CreateEdgeContractRequestAsNode.prototype.setEdgeId = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.CreateEdgeContractRequestAsNode} returns this
 */
proto.vtv1.CreateEdgeContractRequestAsNode.prototype.clearEdgeId = function() {
  return this.setEdgeId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.CreateEdgeContractRequestAsNode.prototype.hasEdgeId = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string starts_at = 3;
 * @return {string}
 */
proto.vtv1.CreateEdgeContractRequestAsNode.prototype.getStartsAt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.CreateEdgeContractRequestAsNode} returns this
 */
proto.vtv1.CreateEdgeContractRequestAsNode.prototype.setStartsAt = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional LinehaulType type = 4;
 * @return {!proto.vtv1.LinehaulType}
 */
proto.vtv1.CreateEdgeContractRequestAsNode.prototype.getType = function() {
  return /** @type {!proto.vtv1.LinehaulType} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.vtv1.LinehaulType} value
 * @return {!proto.vtv1.CreateEdgeContractRequestAsNode} returns this
 */
proto.vtv1.CreateEdgeContractRequestAsNode.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional NullableTime expires_at = 5;
 * @return {?proto.vtv1.NullableTime}
 */
proto.vtv1.CreateEdgeContractRequestAsNode.prototype.getExpiresAt = function() {
  return /** @type{?proto.vtv1.NullableTime} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableTime, 5));
};


/**
 * @param {?proto.vtv1.NullableTime|undefined} value
 * @return {!proto.vtv1.CreateEdgeContractRequestAsNode} returns this
*/
proto.vtv1.CreateEdgeContractRequestAsNode.prototype.setExpiresAt = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.CreateEdgeContractRequestAsNode} returns this
 */
proto.vtv1.CreateEdgeContractRequestAsNode.prototype.clearExpiresAt = function() {
  return this.setExpiresAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.CreateEdgeContractRequestAsNode.prototype.hasExpiresAt = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * repeated MileageBracketCost cost_per_mileage_bracket = 6;
 * @return {!Array<!proto.vtv1.MileageBracketCost>}
 */
proto.vtv1.CreateEdgeContractRequestAsNode.prototype.getCostPerMileageBracketList = function() {
  return /** @type{!Array<!proto.vtv1.MileageBracketCost>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_vorto_types_types_pb.MileageBracketCost, 6));
};


/**
 * @param {!Array<!proto.vtv1.MileageBracketCost>} value
 * @return {!proto.vtv1.CreateEdgeContractRequestAsNode} returns this
*/
proto.vtv1.CreateEdgeContractRequestAsNode.prototype.setCostPerMileageBracketList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.vtv1.MileageBracketCost=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.MileageBracketCost}
 */
proto.vtv1.CreateEdgeContractRequestAsNode.prototype.addCostPerMileageBracket = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.vtv1.MileageBracketCost, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.CreateEdgeContractRequestAsNode} returns this
 */
proto.vtv1.CreateEdgeContractRequestAsNode.prototype.clearCostPerMileageBracketList = function() {
  return this.setCostPerMileageBracketList([]);
};


/**
 * optional int64 cost_per_mile = 7;
 * @return {number}
 */
proto.vtv1.CreateEdgeContractRequestAsNode.prototype.getCostPerMile = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.CreateEdgeContractRequestAsNode} returns this
 */
proto.vtv1.CreateEdgeContractRequestAsNode.prototype.setCostPerMile = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional bool is_spot_contract = 8;
 * @return {boolean}
 */
proto.vtv1.CreateEdgeContractRequestAsNode.prototype.getIsSpotContract = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vtv1.CreateEdgeContractRequestAsNode} returns this
 */
proto.vtv1.CreateEdgeContractRequestAsNode.prototype.setIsSpotContract = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional EdgeCosts edge_costs = 9;
 * @return {?proto.vtv1.EdgeCosts}
 */
proto.vtv1.CreateEdgeContractRequestAsNode.prototype.getEdgeCosts = function() {
  return /** @type{?proto.vtv1.EdgeCosts} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.EdgeCosts, 9));
};


/**
 * @param {?proto.vtv1.EdgeCosts|undefined} value
 * @return {!proto.vtv1.CreateEdgeContractRequestAsNode} returns this
*/
proto.vtv1.CreateEdgeContractRequestAsNode.prototype.setEdgeCosts = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.CreateEdgeContractRequestAsNode} returns this
 */
proto.vtv1.CreateEdgeContractRequestAsNode.prototype.clearEdgeCosts = function() {
  return this.setEdgeCosts(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.CreateEdgeContractRequestAsNode.prototype.hasEdgeCosts = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * repeated string product_ids = 10;
 * @return {!Array<string>}
 */
proto.vtv1.CreateEdgeContractRequestAsNode.prototype.getProductIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 10));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.vtv1.CreateEdgeContractRequestAsNode} returns this
 */
proto.vtv1.CreateEdgeContractRequestAsNode.prototype.setProductIdsList = function(value) {
  return jspb.Message.setField(this, 10, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.vtv1.CreateEdgeContractRequestAsNode} returns this
 */
proto.vtv1.CreateEdgeContractRequestAsNode.prototype.addProductIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 10, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.CreateEdgeContractRequestAsNode} returns this
 */
proto.vtv1.CreateEdgeContractRequestAsNode.prototype.clearProductIdsList = function() {
  return this.setProductIdsList([]);
};


/**
 * repeated IntIdName trailer_types = 11;
 * @return {!Array<!proto.vtv1.IntIdName>}
 */
proto.vtv1.CreateEdgeContractRequestAsNode.prototype.getTrailerTypesList = function() {
  return /** @type{!Array<!proto.vtv1.IntIdName>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_vorto_types_types_pb.IntIdName, 11));
};


/**
 * @param {!Array<!proto.vtv1.IntIdName>} value
 * @return {!proto.vtv1.CreateEdgeContractRequestAsNode} returns this
*/
proto.vtv1.CreateEdgeContractRequestAsNode.prototype.setTrailerTypesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 11, value);
};


/**
 * @param {!proto.vtv1.IntIdName=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.IntIdName}
 */
proto.vtv1.CreateEdgeContractRequestAsNode.prototype.addTrailerTypes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 11, opt_value, proto.vtv1.IntIdName, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.CreateEdgeContractRequestAsNode} returns this
 */
proto.vtv1.CreateEdgeContractRequestAsNode.prototype.clearTrailerTypesList = function() {
  return this.setTrailerTypesList([]);
};


/**
 * optional int64 p2p_file_id = 12;
 * @return {number}
 */
proto.vtv1.CreateEdgeContractRequestAsNode.prototype.getP2pFileId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.CreateEdgeContractRequestAsNode} returns this
 */
proto.vtv1.CreateEdgeContractRequestAsNode.prototype.setP2pFileId = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * repeated IntIdName region_ids = 13;
 * @return {!Array<!proto.vtv1.IntIdName>}
 */
proto.vtv1.CreateEdgeContractRequestAsNode.prototype.getRegionIdsList = function() {
  return /** @type{!Array<!proto.vtv1.IntIdName>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_vorto_types_types_pb.IntIdName, 13));
};


/**
 * @param {!Array<!proto.vtv1.IntIdName>} value
 * @return {!proto.vtv1.CreateEdgeContractRequestAsNode} returns this
*/
proto.vtv1.CreateEdgeContractRequestAsNode.prototype.setRegionIdsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 13, value);
};


/**
 * @param {!proto.vtv1.IntIdName=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.IntIdName}
 */
proto.vtv1.CreateEdgeContractRequestAsNode.prototype.addRegionIds = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 13, opt_value, proto.vtv1.IntIdName, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.CreateEdgeContractRequestAsNode} returns this
 */
proto.vtv1.CreateEdgeContractRequestAsNode.prototype.clearRegionIdsList = function() {
  return this.setRegionIdsList([]);
};


/**
 * repeated P2PPTEditEntry p2p_per_ton = 14;
 * @return {!Array<!proto.vtv1.P2PPTEditEntry>}
 */
proto.vtv1.CreateEdgeContractRequestAsNode.prototype.getP2pPerTonList = function() {
  return /** @type{!Array<!proto.vtv1.P2PPTEditEntry>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_vorto_types_types_pb.P2PPTEditEntry, 14));
};


/**
 * @param {!Array<!proto.vtv1.P2PPTEditEntry>} value
 * @return {!proto.vtv1.CreateEdgeContractRequestAsNode} returns this
*/
proto.vtv1.CreateEdgeContractRequestAsNode.prototype.setP2pPerTonList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 14, value);
};


/**
 * @param {!proto.vtv1.P2PPTEditEntry=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.P2PPTEditEntry}
 */
proto.vtv1.CreateEdgeContractRequestAsNode.prototype.addP2pPerTon = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 14, opt_value, proto.vtv1.P2PPTEditEntry, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.CreateEdgeContractRequestAsNode} returns this
 */
proto.vtv1.CreateEdgeContractRequestAsNode.prototype.clearP2pPerTonList = function() {
  return this.setP2pPerTonList([]);
};


/**
 * optional bool allow_carrier_manual_complete = 15;
 * @return {boolean}
 */
proto.vtv1.CreateEdgeContractRequestAsNode.prototype.getAllowCarrierManualComplete = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 15, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vtv1.CreateEdgeContractRequestAsNode} returns this
 */
proto.vtv1.CreateEdgeContractRequestAsNode.prototype.setAllowCarrierManualComplete = function(value) {
  return jspb.Message.setProto3BooleanField(this, 15, value);
};


/**
 * optional int64 cost_per_hour = 16;
 * @return {number}
 */
proto.vtv1.CreateEdgeContractRequestAsNode.prototype.getCostPerHour = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.CreateEdgeContractRequestAsNode} returns this
 */
proto.vtv1.CreateEdgeContractRequestAsNode.prototype.setCostPerHour = function(value) {
  return jspb.Message.setProto3IntField(this, 16, value);
};


/**
 * optional int64 minimum_cost_per_hour = 17;
 * @return {number}
 */
proto.vtv1.CreateEdgeContractRequestAsNode.prototype.getMinimumCostPerHour = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 17, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.CreateEdgeContractRequestAsNode} returns this
 */
proto.vtv1.CreateEdgeContractRequestAsNode.prototype.setMinimumCostPerHour = function(value) {
  return jspb.Message.setProto3IntField(this, 17, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.EditEdgeContractRequestAsNode.repeatedFields_ = [6,10,11,13,14];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.EditEdgeContractRequestAsNode.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.EditEdgeContractRequestAsNode.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.EditEdgeContractRequestAsNode} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.EditEdgeContractRequestAsNode.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: (f = msg.getName()) && proto_vorto_types_types_pb.NullableString.toObject(includeInstance, f),
    startsAt: (f = msg.getStartsAt()) && proto_vorto_types_types_pb.NullableTime.toObject(includeInstance, f),
    type: jspb.Message.getFieldWithDefault(msg, 4, 0),
    expiresAt: (f = msg.getExpiresAt()) && proto_vorto_types_types_pb.NullableTime.toObject(includeInstance, f),
    costPerMileageBracketList: jspb.Message.toObjectList(msg.getCostPerMileageBracketList(),
    proto_vorto_types_types_pb.MileageBracketCost.toObject, includeInstance),
    costPerMile: (f = msg.getCostPerMile()) && proto_vorto_types_types_pb.NullableInt64.toObject(includeInstance, f),
    locked: (f = msg.getLocked()) && proto_vorto_types_types_pb.NullableBool.toObject(includeInstance, f),
    carrierCosts: (f = msg.getCarrierCosts()) && proto_vorto_types_types_pb.EdgeCosts.toObject(includeInstance, f),
    productIdsList: (f = jspb.Message.getRepeatedField(msg, 10)) == null ? undefined : f,
    trailerTypesList: jspb.Message.toObjectList(msg.getTrailerTypesList(),
    proto_vorto_types_types_pb.IntIdName.toObject, includeInstance),
    p2pFileId: jspb.Message.getFieldWithDefault(msg, 12, 0),
    regionIdsList: jspb.Message.toObjectList(msg.getRegionIdsList(),
    proto_vorto_types_types_pb.IntIdName.toObject, includeInstance),
    p2pPerTonList: jspb.Message.toObjectList(msg.getP2pPerTonList(),
    proto_vorto_types_types_pb.P2PPTEditEntry.toObject, includeInstance),
    allowCarrierManualComplete: jspb.Message.getBooleanFieldWithDefault(msg, 15, false),
    costPerHour: (f = msg.getCostPerHour()) && proto_vorto_types_types_pb.NullableInt64.toObject(includeInstance, f),
    minimumCostPerHour: (f = msg.getMinimumCostPerHour()) && proto_vorto_types_types_pb.NullableInt64.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.EditEdgeContractRequestAsNode}
 */
proto.vtv1.EditEdgeContractRequestAsNode.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.EditEdgeContractRequestAsNode;
  return proto.vtv1.EditEdgeContractRequestAsNode.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.EditEdgeContractRequestAsNode} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.EditEdgeContractRequestAsNode}
 */
proto.vtv1.EditEdgeContractRequestAsNode.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new proto_vorto_types_types_pb.NullableString;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableString.deserializeBinaryFromReader);
      msg.setName(value);
      break;
    case 3:
      var value = new proto_vorto_types_types_pb.NullableTime;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableTime.deserializeBinaryFromReader);
      msg.setStartsAt(value);
      break;
    case 4:
      var value = /** @type {!proto.vtv1.LinehaulType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 5:
      var value = new proto_vorto_types_types_pb.NullableTime;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableTime.deserializeBinaryFromReader);
      msg.setExpiresAt(value);
      break;
    case 6:
      var value = new proto_vorto_types_types_pb.MileageBracketCost;
      reader.readMessage(value,proto_vorto_types_types_pb.MileageBracketCost.deserializeBinaryFromReader);
      msg.addCostPerMileageBracket(value);
      break;
    case 7:
      var value = new proto_vorto_types_types_pb.NullableInt64;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableInt64.deserializeBinaryFromReader);
      msg.setCostPerMile(value);
      break;
    case 8:
      var value = new proto_vorto_types_types_pb.NullableBool;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableBool.deserializeBinaryFromReader);
      msg.setLocked(value);
      break;
    case 9:
      var value = new proto_vorto_types_types_pb.EdgeCosts;
      reader.readMessage(value,proto_vorto_types_types_pb.EdgeCosts.deserializeBinaryFromReader);
      msg.setCarrierCosts(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.addProductIds(value);
      break;
    case 11:
      var value = new proto_vorto_types_types_pb.IntIdName;
      reader.readMessage(value,proto_vorto_types_types_pb.IntIdName.deserializeBinaryFromReader);
      msg.addTrailerTypes(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setP2pFileId(value);
      break;
    case 13:
      var value = new proto_vorto_types_types_pb.IntIdName;
      reader.readMessage(value,proto_vorto_types_types_pb.IntIdName.deserializeBinaryFromReader);
      msg.addRegionIds(value);
      break;
    case 14:
      var value = new proto_vorto_types_types_pb.P2PPTEditEntry;
      reader.readMessage(value,proto_vorto_types_types_pb.P2PPTEditEntry.deserializeBinaryFromReader);
      msg.addP2pPerTon(value);
      break;
    case 15:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAllowCarrierManualComplete(value);
      break;
    case 16:
      var value = new proto_vorto_types_types_pb.NullableInt64;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableInt64.deserializeBinaryFromReader);
      msg.setCostPerHour(value);
      break;
    case 17:
      var value = new proto_vorto_types_types_pb.NullableInt64;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableInt64.deserializeBinaryFromReader);
      msg.setMinimumCostPerHour(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.EditEdgeContractRequestAsNode.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.EditEdgeContractRequestAsNode.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.EditEdgeContractRequestAsNode} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.EditEdgeContractRequestAsNode.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_vorto_types_types_pb.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getStartsAt();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto_vorto_types_types_pb.NullableTime.serializeBinaryToWriter
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getExpiresAt();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto_vorto_types_types_pb.NullableTime.serializeBinaryToWriter
    );
  }
  f = message.getCostPerMileageBracketList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto_vorto_types_types_pb.MileageBracketCost.serializeBinaryToWriter
    );
  }
  f = message.getCostPerMile();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto_vorto_types_types_pb.NullableInt64.serializeBinaryToWriter
    );
  }
  f = message.getLocked();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto_vorto_types_types_pb.NullableBool.serializeBinaryToWriter
    );
  }
  f = message.getCarrierCosts();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto_vorto_types_types_pb.EdgeCosts.serializeBinaryToWriter
    );
  }
  f = message.getProductIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      10,
      f
    );
  }
  f = message.getTrailerTypesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      11,
      f,
      proto_vorto_types_types_pb.IntIdName.serializeBinaryToWriter
    );
  }
  f = message.getP2pFileId();
  if (f !== 0) {
    writer.writeInt64(
      12,
      f
    );
  }
  f = message.getRegionIdsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      13,
      f,
      proto_vorto_types_types_pb.IntIdName.serializeBinaryToWriter
    );
  }
  f = message.getP2pPerTonList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      14,
      f,
      proto_vorto_types_types_pb.P2PPTEditEntry.serializeBinaryToWriter
    );
  }
  f = message.getAllowCarrierManualComplete();
  if (f) {
    writer.writeBool(
      15,
      f
    );
  }
  f = message.getCostPerHour();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      proto_vorto_types_types_pb.NullableInt64.serializeBinaryToWriter
    );
  }
  f = message.getMinimumCostPerHour();
  if (f != null) {
    writer.writeMessage(
      17,
      f,
      proto_vorto_types_types_pb.NullableInt64.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.vtv1.EditEdgeContractRequestAsNode.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.EditEdgeContractRequestAsNode} returns this
 */
proto.vtv1.EditEdgeContractRequestAsNode.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional NullableString name = 2;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.EditEdgeContractRequestAsNode.prototype.getName = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableString, 2));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.EditEdgeContractRequestAsNode} returns this
*/
proto.vtv1.EditEdgeContractRequestAsNode.prototype.setName = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.EditEdgeContractRequestAsNode} returns this
 */
proto.vtv1.EditEdgeContractRequestAsNode.prototype.clearName = function() {
  return this.setName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.EditEdgeContractRequestAsNode.prototype.hasName = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional NullableTime starts_at = 3;
 * @return {?proto.vtv1.NullableTime}
 */
proto.vtv1.EditEdgeContractRequestAsNode.prototype.getStartsAt = function() {
  return /** @type{?proto.vtv1.NullableTime} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableTime, 3));
};


/**
 * @param {?proto.vtv1.NullableTime|undefined} value
 * @return {!proto.vtv1.EditEdgeContractRequestAsNode} returns this
*/
proto.vtv1.EditEdgeContractRequestAsNode.prototype.setStartsAt = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.EditEdgeContractRequestAsNode} returns this
 */
proto.vtv1.EditEdgeContractRequestAsNode.prototype.clearStartsAt = function() {
  return this.setStartsAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.EditEdgeContractRequestAsNode.prototype.hasStartsAt = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional LinehaulType type = 4;
 * @return {!proto.vtv1.LinehaulType}
 */
proto.vtv1.EditEdgeContractRequestAsNode.prototype.getType = function() {
  return /** @type {!proto.vtv1.LinehaulType} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.vtv1.LinehaulType} value
 * @return {!proto.vtv1.EditEdgeContractRequestAsNode} returns this
 */
proto.vtv1.EditEdgeContractRequestAsNode.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional NullableTime expires_at = 5;
 * @return {?proto.vtv1.NullableTime}
 */
proto.vtv1.EditEdgeContractRequestAsNode.prototype.getExpiresAt = function() {
  return /** @type{?proto.vtv1.NullableTime} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableTime, 5));
};


/**
 * @param {?proto.vtv1.NullableTime|undefined} value
 * @return {!proto.vtv1.EditEdgeContractRequestAsNode} returns this
*/
proto.vtv1.EditEdgeContractRequestAsNode.prototype.setExpiresAt = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.EditEdgeContractRequestAsNode} returns this
 */
proto.vtv1.EditEdgeContractRequestAsNode.prototype.clearExpiresAt = function() {
  return this.setExpiresAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.EditEdgeContractRequestAsNode.prototype.hasExpiresAt = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * repeated MileageBracketCost cost_per_mileage_bracket = 6;
 * @return {!Array<!proto.vtv1.MileageBracketCost>}
 */
proto.vtv1.EditEdgeContractRequestAsNode.prototype.getCostPerMileageBracketList = function() {
  return /** @type{!Array<!proto.vtv1.MileageBracketCost>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_vorto_types_types_pb.MileageBracketCost, 6));
};


/**
 * @param {!Array<!proto.vtv1.MileageBracketCost>} value
 * @return {!proto.vtv1.EditEdgeContractRequestAsNode} returns this
*/
proto.vtv1.EditEdgeContractRequestAsNode.prototype.setCostPerMileageBracketList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.vtv1.MileageBracketCost=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.MileageBracketCost}
 */
proto.vtv1.EditEdgeContractRequestAsNode.prototype.addCostPerMileageBracket = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.vtv1.MileageBracketCost, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.EditEdgeContractRequestAsNode} returns this
 */
proto.vtv1.EditEdgeContractRequestAsNode.prototype.clearCostPerMileageBracketList = function() {
  return this.setCostPerMileageBracketList([]);
};


/**
 * optional NullableInt64 cost_per_mile = 7;
 * @return {?proto.vtv1.NullableInt64}
 */
proto.vtv1.EditEdgeContractRequestAsNode.prototype.getCostPerMile = function() {
  return /** @type{?proto.vtv1.NullableInt64} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableInt64, 7));
};


/**
 * @param {?proto.vtv1.NullableInt64|undefined} value
 * @return {!proto.vtv1.EditEdgeContractRequestAsNode} returns this
*/
proto.vtv1.EditEdgeContractRequestAsNode.prototype.setCostPerMile = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.EditEdgeContractRequestAsNode} returns this
 */
proto.vtv1.EditEdgeContractRequestAsNode.prototype.clearCostPerMile = function() {
  return this.setCostPerMile(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.EditEdgeContractRequestAsNode.prototype.hasCostPerMile = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional NullableBool locked = 8;
 * @return {?proto.vtv1.NullableBool}
 */
proto.vtv1.EditEdgeContractRequestAsNode.prototype.getLocked = function() {
  return /** @type{?proto.vtv1.NullableBool} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableBool, 8));
};


/**
 * @param {?proto.vtv1.NullableBool|undefined} value
 * @return {!proto.vtv1.EditEdgeContractRequestAsNode} returns this
*/
proto.vtv1.EditEdgeContractRequestAsNode.prototype.setLocked = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.EditEdgeContractRequestAsNode} returns this
 */
proto.vtv1.EditEdgeContractRequestAsNode.prototype.clearLocked = function() {
  return this.setLocked(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.EditEdgeContractRequestAsNode.prototype.hasLocked = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional EdgeCosts carrier_costs = 9;
 * @return {?proto.vtv1.EdgeCosts}
 */
proto.vtv1.EditEdgeContractRequestAsNode.prototype.getCarrierCosts = function() {
  return /** @type{?proto.vtv1.EdgeCosts} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.EdgeCosts, 9));
};


/**
 * @param {?proto.vtv1.EdgeCosts|undefined} value
 * @return {!proto.vtv1.EditEdgeContractRequestAsNode} returns this
*/
proto.vtv1.EditEdgeContractRequestAsNode.prototype.setCarrierCosts = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.EditEdgeContractRequestAsNode} returns this
 */
proto.vtv1.EditEdgeContractRequestAsNode.prototype.clearCarrierCosts = function() {
  return this.setCarrierCosts(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.EditEdgeContractRequestAsNode.prototype.hasCarrierCosts = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * repeated string product_ids = 10;
 * @return {!Array<string>}
 */
proto.vtv1.EditEdgeContractRequestAsNode.prototype.getProductIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 10));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.vtv1.EditEdgeContractRequestAsNode} returns this
 */
proto.vtv1.EditEdgeContractRequestAsNode.prototype.setProductIdsList = function(value) {
  return jspb.Message.setField(this, 10, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.vtv1.EditEdgeContractRequestAsNode} returns this
 */
proto.vtv1.EditEdgeContractRequestAsNode.prototype.addProductIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 10, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.EditEdgeContractRequestAsNode} returns this
 */
proto.vtv1.EditEdgeContractRequestAsNode.prototype.clearProductIdsList = function() {
  return this.setProductIdsList([]);
};


/**
 * repeated IntIdName trailer_types = 11;
 * @return {!Array<!proto.vtv1.IntIdName>}
 */
proto.vtv1.EditEdgeContractRequestAsNode.prototype.getTrailerTypesList = function() {
  return /** @type{!Array<!proto.vtv1.IntIdName>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_vorto_types_types_pb.IntIdName, 11));
};


/**
 * @param {!Array<!proto.vtv1.IntIdName>} value
 * @return {!proto.vtv1.EditEdgeContractRequestAsNode} returns this
*/
proto.vtv1.EditEdgeContractRequestAsNode.prototype.setTrailerTypesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 11, value);
};


/**
 * @param {!proto.vtv1.IntIdName=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.IntIdName}
 */
proto.vtv1.EditEdgeContractRequestAsNode.prototype.addTrailerTypes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 11, opt_value, proto.vtv1.IntIdName, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.EditEdgeContractRequestAsNode} returns this
 */
proto.vtv1.EditEdgeContractRequestAsNode.prototype.clearTrailerTypesList = function() {
  return this.setTrailerTypesList([]);
};


/**
 * optional int64 p2p_file_id = 12;
 * @return {number}
 */
proto.vtv1.EditEdgeContractRequestAsNode.prototype.getP2pFileId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.EditEdgeContractRequestAsNode} returns this
 */
proto.vtv1.EditEdgeContractRequestAsNode.prototype.setP2pFileId = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * repeated IntIdName region_ids = 13;
 * @return {!Array<!proto.vtv1.IntIdName>}
 */
proto.vtv1.EditEdgeContractRequestAsNode.prototype.getRegionIdsList = function() {
  return /** @type{!Array<!proto.vtv1.IntIdName>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_vorto_types_types_pb.IntIdName, 13));
};


/**
 * @param {!Array<!proto.vtv1.IntIdName>} value
 * @return {!proto.vtv1.EditEdgeContractRequestAsNode} returns this
*/
proto.vtv1.EditEdgeContractRequestAsNode.prototype.setRegionIdsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 13, value);
};


/**
 * @param {!proto.vtv1.IntIdName=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.IntIdName}
 */
proto.vtv1.EditEdgeContractRequestAsNode.prototype.addRegionIds = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 13, opt_value, proto.vtv1.IntIdName, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.EditEdgeContractRequestAsNode} returns this
 */
proto.vtv1.EditEdgeContractRequestAsNode.prototype.clearRegionIdsList = function() {
  return this.setRegionIdsList([]);
};


/**
 * repeated P2PPTEditEntry p2p_per_ton = 14;
 * @return {!Array<!proto.vtv1.P2PPTEditEntry>}
 */
proto.vtv1.EditEdgeContractRequestAsNode.prototype.getP2pPerTonList = function() {
  return /** @type{!Array<!proto.vtv1.P2PPTEditEntry>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_vorto_types_types_pb.P2PPTEditEntry, 14));
};


/**
 * @param {!Array<!proto.vtv1.P2PPTEditEntry>} value
 * @return {!proto.vtv1.EditEdgeContractRequestAsNode} returns this
*/
proto.vtv1.EditEdgeContractRequestAsNode.prototype.setP2pPerTonList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 14, value);
};


/**
 * @param {!proto.vtv1.P2PPTEditEntry=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.P2PPTEditEntry}
 */
proto.vtv1.EditEdgeContractRequestAsNode.prototype.addP2pPerTon = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 14, opt_value, proto.vtv1.P2PPTEditEntry, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.EditEdgeContractRequestAsNode} returns this
 */
proto.vtv1.EditEdgeContractRequestAsNode.prototype.clearP2pPerTonList = function() {
  return this.setP2pPerTonList([]);
};


/**
 * optional bool allow_carrier_manual_complete = 15;
 * @return {boolean}
 */
proto.vtv1.EditEdgeContractRequestAsNode.prototype.getAllowCarrierManualComplete = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 15, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vtv1.EditEdgeContractRequestAsNode} returns this
 */
proto.vtv1.EditEdgeContractRequestAsNode.prototype.setAllowCarrierManualComplete = function(value) {
  return jspb.Message.setProto3BooleanField(this, 15, value);
};


/**
 * optional NullableInt64 cost_per_hour = 16;
 * @return {?proto.vtv1.NullableInt64}
 */
proto.vtv1.EditEdgeContractRequestAsNode.prototype.getCostPerHour = function() {
  return /** @type{?proto.vtv1.NullableInt64} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableInt64, 16));
};


/**
 * @param {?proto.vtv1.NullableInt64|undefined} value
 * @return {!proto.vtv1.EditEdgeContractRequestAsNode} returns this
*/
proto.vtv1.EditEdgeContractRequestAsNode.prototype.setCostPerHour = function(value) {
  return jspb.Message.setWrapperField(this, 16, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.EditEdgeContractRequestAsNode} returns this
 */
proto.vtv1.EditEdgeContractRequestAsNode.prototype.clearCostPerHour = function() {
  return this.setCostPerHour(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.EditEdgeContractRequestAsNode.prototype.hasCostPerHour = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * optional NullableInt64 minimum_cost_per_hour = 17;
 * @return {?proto.vtv1.NullableInt64}
 */
proto.vtv1.EditEdgeContractRequestAsNode.prototype.getMinimumCostPerHour = function() {
  return /** @type{?proto.vtv1.NullableInt64} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableInt64, 17));
};


/**
 * @param {?proto.vtv1.NullableInt64|undefined} value
 * @return {!proto.vtv1.EditEdgeContractRequestAsNode} returns this
*/
proto.vtv1.EditEdgeContractRequestAsNode.prototype.setMinimumCostPerHour = function(value) {
  return jspb.Message.setWrapperField(this, 17, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.EditEdgeContractRequestAsNode} returns this
 */
proto.vtv1.EditEdgeContractRequestAsNode.prototype.clearMinimumCostPerHour = function() {
  return this.setMinimumCostPerHour(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.EditEdgeContractRequestAsNode.prototype.hasMinimumCostPerHour = function() {
  return jspb.Message.getField(this, 17) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.CreateVirtualSupplierSiteRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.CreateVirtualSupplierSiteRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.CreateVirtualSupplierSiteRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.CreateVirtualSupplierSiteRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    siteCriteria: (f = msg.getSiteCriteria()) && proto_vorto_types_types_pb.CreateSiteCriteria.toObject(includeInstance, f),
    supplierAccountId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.CreateVirtualSupplierSiteRequest}
 */
proto.vtv1.CreateVirtualSupplierSiteRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.CreateVirtualSupplierSiteRequest;
  return proto.vtv1.CreateVirtualSupplierSiteRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.CreateVirtualSupplierSiteRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.CreateVirtualSupplierSiteRequest}
 */
proto.vtv1.CreateVirtualSupplierSiteRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_vorto_types_types_pb.CreateSiteCriteria;
      reader.readMessage(value,proto_vorto_types_types_pb.CreateSiteCriteria.deserializeBinaryFromReader);
      msg.setSiteCriteria(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSupplierAccountId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.CreateVirtualSupplierSiteRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.CreateVirtualSupplierSiteRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.CreateVirtualSupplierSiteRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.CreateVirtualSupplierSiteRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSiteCriteria();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_vorto_types_types_pb.CreateSiteCriteria.serializeBinaryToWriter
    );
  }
  f = message.getSupplierAccountId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional CreateSiteCriteria site_criteria = 1;
 * @return {?proto.vtv1.CreateSiteCriteria}
 */
proto.vtv1.CreateVirtualSupplierSiteRequest.prototype.getSiteCriteria = function() {
  return /** @type{?proto.vtv1.CreateSiteCriteria} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.CreateSiteCriteria, 1));
};


/**
 * @param {?proto.vtv1.CreateSiteCriteria|undefined} value
 * @return {!proto.vtv1.CreateVirtualSupplierSiteRequest} returns this
*/
proto.vtv1.CreateVirtualSupplierSiteRequest.prototype.setSiteCriteria = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.CreateVirtualSupplierSiteRequest} returns this
 */
proto.vtv1.CreateVirtualSupplierSiteRequest.prototype.clearSiteCriteria = function() {
  return this.setSiteCriteria(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.CreateVirtualSupplierSiteRequest.prototype.hasSiteCriteria = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string supplier_account_id = 2;
 * @return {string}
 */
proto.vtv1.CreateVirtualSupplierSiteRequest.prototype.getSupplierAccountId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.CreateVirtualSupplierSiteRequest} returns this
 */
proto.vtv1.CreateVirtualSupplierSiteRequest.prototype.setSupplierAccountId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.CreateVirtualSupplierSiteResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.CreateVirtualSupplierSiteResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.CreateVirtualSupplierSiteResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.CreateVirtualSupplierSiteResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    site: (f = msg.getSite()) && proto_vorto_types_types_pb.Site.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.CreateVirtualSupplierSiteResponse}
 */
proto.vtv1.CreateVirtualSupplierSiteResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.CreateVirtualSupplierSiteResponse;
  return proto.vtv1.CreateVirtualSupplierSiteResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.CreateVirtualSupplierSiteResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.CreateVirtualSupplierSiteResponse}
 */
proto.vtv1.CreateVirtualSupplierSiteResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_vorto_types_types_pb.Site;
      reader.readMessage(value,proto_vorto_types_types_pb.Site.deserializeBinaryFromReader);
      msg.setSite(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.CreateVirtualSupplierSiteResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.CreateVirtualSupplierSiteResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.CreateVirtualSupplierSiteResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.CreateVirtualSupplierSiteResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSite();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_vorto_types_types_pb.Site.serializeBinaryToWriter
    );
  }
};


/**
 * optional Site site = 1;
 * @return {?proto.vtv1.Site}
 */
proto.vtv1.CreateVirtualSupplierSiteResponse.prototype.getSite = function() {
  return /** @type{?proto.vtv1.Site} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.Site, 1));
};


/**
 * @param {?proto.vtv1.Site|undefined} value
 * @return {!proto.vtv1.CreateVirtualSupplierSiteResponse} returns this
*/
proto.vtv1.CreateVirtualSupplierSiteResponse.prototype.setSite = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.CreateVirtualSupplierSiteResponse} returns this
 */
proto.vtv1.CreateVirtualSupplierSiteResponse.prototype.clearSite = function() {
  return this.setSite(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.CreateVirtualSupplierSiteResponse.prototype.hasSite = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.CreateVirtualSupplierRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.CreateVirtualSupplierRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.CreateVirtualSupplierRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.CreateVirtualSupplierRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    accountName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    supplierUserBaseEmail: jspb.Message.getFieldWithDefault(msg, 2, ""),
    supplierNumber: (f = msg.getSupplierNumber()) && proto_vorto_types_types_pb.NullableString.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.CreateVirtualSupplierRequest}
 */
proto.vtv1.CreateVirtualSupplierRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.CreateVirtualSupplierRequest;
  return proto.vtv1.CreateVirtualSupplierRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.CreateVirtualSupplierRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.CreateVirtualSupplierRequest}
 */
proto.vtv1.CreateVirtualSupplierRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSupplierUserBaseEmail(value);
      break;
    case 3:
      var value = new proto_vorto_types_types_pb.NullableString;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableString.deserializeBinaryFromReader);
      msg.setSupplierNumber(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.CreateVirtualSupplierRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.CreateVirtualSupplierRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.CreateVirtualSupplierRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.CreateVirtualSupplierRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccountName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSupplierUserBaseEmail();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSupplierNumber();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto_vorto_types_types_pb.NullableString.serializeBinaryToWriter
    );
  }
};


/**
 * optional string account_name = 1;
 * @return {string}
 */
proto.vtv1.CreateVirtualSupplierRequest.prototype.getAccountName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.CreateVirtualSupplierRequest} returns this
 */
proto.vtv1.CreateVirtualSupplierRequest.prototype.setAccountName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string supplier_user_base_email = 2;
 * @return {string}
 */
proto.vtv1.CreateVirtualSupplierRequest.prototype.getSupplierUserBaseEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.CreateVirtualSupplierRequest} returns this
 */
proto.vtv1.CreateVirtualSupplierRequest.prototype.setSupplierUserBaseEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional NullableString supplier_number = 3;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.CreateVirtualSupplierRequest.prototype.getSupplierNumber = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableString, 3));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.CreateVirtualSupplierRequest} returns this
*/
proto.vtv1.CreateVirtualSupplierRequest.prototype.setSupplierNumber = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.CreateVirtualSupplierRequest} returns this
 */
proto.vtv1.CreateVirtualSupplierRequest.prototype.clearSupplierNumber = function() {
  return this.setSupplierNumber(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.CreateVirtualSupplierRequest.prototype.hasSupplierNumber = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.NodeCreateVirtualSupplierForSupplyPlanRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.NodeCreateVirtualSupplierForSupplyPlanRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.NodeCreateVirtualSupplierForSupplyPlanRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.NodeCreateVirtualSupplierForSupplyPlanRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    inventoryId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    productId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    accountName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    supplierUserBaseEmail: jspb.Message.getFieldWithDefault(msg, 4, ""),
    siteCriteria: (f = msg.getSiteCriteria()) && proto_vorto_types_types_pb.CreateSiteCriteria.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.NodeCreateVirtualSupplierForSupplyPlanRequest}
 */
proto.vtv1.NodeCreateVirtualSupplierForSupplyPlanRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.NodeCreateVirtualSupplierForSupplyPlanRequest;
  return proto.vtv1.NodeCreateVirtualSupplierForSupplyPlanRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.NodeCreateVirtualSupplierForSupplyPlanRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.NodeCreateVirtualSupplierForSupplyPlanRequest}
 */
proto.vtv1.NodeCreateVirtualSupplierForSupplyPlanRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setInventoryId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setSupplierUserBaseEmail(value);
      break;
    case 5:
      var value = new proto_vorto_types_types_pb.CreateSiteCriteria;
      reader.readMessage(value,proto_vorto_types_types_pb.CreateSiteCriteria.deserializeBinaryFromReader);
      msg.setSiteCriteria(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.NodeCreateVirtualSupplierForSupplyPlanRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.NodeCreateVirtualSupplierForSupplyPlanRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.NodeCreateVirtualSupplierForSupplyPlanRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.NodeCreateVirtualSupplierForSupplyPlanRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInventoryId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getProductId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAccountName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSupplierUserBaseEmail();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getSiteCriteria();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto_vorto_types_types_pb.CreateSiteCriteria.serializeBinaryToWriter
    );
  }
};


/**
 * optional string inventory_id = 1;
 * @return {string}
 */
proto.vtv1.NodeCreateVirtualSupplierForSupplyPlanRequest.prototype.getInventoryId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.NodeCreateVirtualSupplierForSupplyPlanRequest} returns this
 */
proto.vtv1.NodeCreateVirtualSupplierForSupplyPlanRequest.prototype.setInventoryId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string product_id = 2;
 * @return {string}
 */
proto.vtv1.NodeCreateVirtualSupplierForSupplyPlanRequest.prototype.getProductId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.NodeCreateVirtualSupplierForSupplyPlanRequest} returns this
 */
proto.vtv1.NodeCreateVirtualSupplierForSupplyPlanRequest.prototype.setProductId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string account_name = 3;
 * @return {string}
 */
proto.vtv1.NodeCreateVirtualSupplierForSupplyPlanRequest.prototype.getAccountName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.NodeCreateVirtualSupplierForSupplyPlanRequest} returns this
 */
proto.vtv1.NodeCreateVirtualSupplierForSupplyPlanRequest.prototype.setAccountName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string supplier_user_base_email = 4;
 * @return {string}
 */
proto.vtv1.NodeCreateVirtualSupplierForSupplyPlanRequest.prototype.getSupplierUserBaseEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.NodeCreateVirtualSupplierForSupplyPlanRequest} returns this
 */
proto.vtv1.NodeCreateVirtualSupplierForSupplyPlanRequest.prototype.setSupplierUserBaseEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional CreateSiteCriteria site_criteria = 5;
 * @return {?proto.vtv1.CreateSiteCriteria}
 */
proto.vtv1.NodeCreateVirtualSupplierForSupplyPlanRequest.prototype.getSiteCriteria = function() {
  return /** @type{?proto.vtv1.CreateSiteCriteria} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.CreateSiteCriteria, 5));
};


/**
 * @param {?proto.vtv1.CreateSiteCriteria|undefined} value
 * @return {!proto.vtv1.NodeCreateVirtualSupplierForSupplyPlanRequest} returns this
*/
proto.vtv1.NodeCreateVirtualSupplierForSupplyPlanRequest.prototype.setSiteCriteria = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.NodeCreateVirtualSupplierForSupplyPlanRequest} returns this
 */
proto.vtv1.NodeCreateVirtualSupplierForSupplyPlanRequest.prototype.clearSiteCriteria = function() {
  return this.setSiteCriteria(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.NodeCreateVirtualSupplierForSupplyPlanRequest.prototype.hasSiteCriteria = function() {
  return jspb.Message.getField(this, 5) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.ListEdgeContractsResponseAsNode.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.ListEdgeContractsResponseAsNode.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.ListEdgeContractsResponseAsNode.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.ListEdgeContractsResponseAsNode} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.ListEdgeContractsResponseAsNode.toObject = function(includeInstance, msg) {
  var f, obj = {
    edgeContractsList: jspb.Message.toObjectList(msg.getEdgeContractsList(),
    proto_vorto_types_types_pb.SlimEdgeContract.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.ListEdgeContractsResponseAsNode}
 */
proto.vtv1.ListEdgeContractsResponseAsNode.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.ListEdgeContractsResponseAsNode;
  return proto.vtv1.ListEdgeContractsResponseAsNode.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.ListEdgeContractsResponseAsNode} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.ListEdgeContractsResponseAsNode}
 */
proto.vtv1.ListEdgeContractsResponseAsNode.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_vorto_types_types_pb.SlimEdgeContract;
      reader.readMessage(value,proto_vorto_types_types_pb.SlimEdgeContract.deserializeBinaryFromReader);
      msg.addEdgeContracts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.ListEdgeContractsResponseAsNode.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.ListEdgeContractsResponseAsNode.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.ListEdgeContractsResponseAsNode} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.ListEdgeContractsResponseAsNode.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEdgeContractsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto_vorto_types_types_pb.SlimEdgeContract.serializeBinaryToWriter
    );
  }
};


/**
 * repeated SlimEdgeContract edge_contracts = 1;
 * @return {!Array<!proto.vtv1.SlimEdgeContract>}
 */
proto.vtv1.ListEdgeContractsResponseAsNode.prototype.getEdgeContractsList = function() {
  return /** @type{!Array<!proto.vtv1.SlimEdgeContract>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_vorto_types_types_pb.SlimEdgeContract, 1));
};


/**
 * @param {!Array<!proto.vtv1.SlimEdgeContract>} value
 * @return {!proto.vtv1.ListEdgeContractsResponseAsNode} returns this
*/
proto.vtv1.ListEdgeContractsResponseAsNode.prototype.setEdgeContractsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.vtv1.SlimEdgeContract=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.SlimEdgeContract}
 */
proto.vtv1.ListEdgeContractsResponseAsNode.prototype.addEdgeContracts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.vtv1.SlimEdgeContract, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.ListEdgeContractsResponseAsNode} returns this
 */
proto.vtv1.ListEdgeContractsResponseAsNode.prototype.clearEdgeContractsList = function() {
  return this.setEdgeContractsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.ListContractsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.ListContractsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.ListContractsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.ListContractsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    listArchived: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.ListContractsRequest}
 */
proto.vtv1.ListContractsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.ListContractsRequest;
  return proto.vtv1.ListContractsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.ListContractsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.ListContractsRequest}
 */
proto.vtv1.ListContractsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setListArchived(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.ListContractsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.ListContractsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.ListContractsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.ListContractsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getListArchived();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool list_archived = 1;
 * @return {boolean}
 */
proto.vtv1.ListContractsRequest.prototype.getListArchived = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vtv1.ListContractsRequest} returns this
 */
proto.vtv1.ListContractsRequest.prototype.setListArchived = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.ListDeliverableSitesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.ListDeliverableSitesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.ListDeliverableSitesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.ListDeliverableSitesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.ListDeliverableSitesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    deliverableSiteList: jspb.Message.toObjectList(msg.getDeliverableSiteList(),
    proto_vorto_node_node_pb.DeliverableSite.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.ListDeliverableSitesResponse}
 */
proto.vtv1.ListDeliverableSitesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.ListDeliverableSitesResponse;
  return proto.vtv1.ListDeliverableSitesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.ListDeliverableSitesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.ListDeliverableSitesResponse}
 */
proto.vtv1.ListDeliverableSitesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_vorto_node_node_pb.DeliverableSite;
      reader.readMessage(value,proto_vorto_node_node_pb.DeliverableSite.deserializeBinaryFromReader);
      msg.addDeliverableSite(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.ListDeliverableSitesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.ListDeliverableSitesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.ListDeliverableSitesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.ListDeliverableSitesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeliverableSiteList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto_vorto_node_node_pb.DeliverableSite.serializeBinaryToWriter
    );
  }
};


/**
 * repeated DeliverableSite deliverable_site = 1;
 * @return {!Array<!proto.vtv1.DeliverableSite>}
 */
proto.vtv1.ListDeliverableSitesResponse.prototype.getDeliverableSiteList = function() {
  return /** @type{!Array<!proto.vtv1.DeliverableSite>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_vorto_node_node_pb.DeliverableSite, 1));
};


/**
 * @param {!Array<!proto.vtv1.DeliverableSite>} value
 * @return {!proto.vtv1.ListDeliverableSitesResponse} returns this
*/
proto.vtv1.ListDeliverableSitesResponse.prototype.setDeliverableSiteList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.vtv1.DeliverableSite=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.DeliverableSite}
 */
proto.vtv1.ListDeliverableSitesResponse.prototype.addDeliverableSite = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.vtv1.DeliverableSite, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.ListDeliverableSitesResponse} returns this
 */
proto.vtv1.ListDeliverableSitesResponse.prototype.clearDeliverableSiteList = function() {
  return this.setDeliverableSiteList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.CreateRouteGuideRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.CreateRouteGuideRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.CreateRouteGuideRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.CreateRouteGuideRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    routeGuide: (f = msg.getRouteGuide()) && proto_vorto_node_node_pb.RouteGuide.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.CreateRouteGuideRequest}
 */
proto.vtv1.CreateRouteGuideRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.CreateRouteGuideRequest;
  return proto.vtv1.CreateRouteGuideRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.CreateRouteGuideRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.CreateRouteGuideRequest}
 */
proto.vtv1.CreateRouteGuideRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_vorto_node_node_pb.RouteGuide;
      reader.readMessage(value,proto_vorto_node_node_pb.RouteGuide.deserializeBinaryFromReader);
      msg.setRouteGuide(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.CreateRouteGuideRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.CreateRouteGuideRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.CreateRouteGuideRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.CreateRouteGuideRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRouteGuide();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_vorto_node_node_pb.RouteGuide.serializeBinaryToWriter
    );
  }
};


/**
 * optional RouteGuide route_guide = 1;
 * @return {?proto.vtv1.RouteGuide}
 */
proto.vtv1.CreateRouteGuideRequest.prototype.getRouteGuide = function() {
  return /** @type{?proto.vtv1.RouteGuide} */ (
    jspb.Message.getWrapperField(this, proto_vorto_node_node_pb.RouteGuide, 1));
};


/**
 * @param {?proto.vtv1.RouteGuide|undefined} value
 * @return {!proto.vtv1.CreateRouteGuideRequest} returns this
*/
proto.vtv1.CreateRouteGuideRequest.prototype.setRouteGuide = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.CreateRouteGuideRequest} returns this
 */
proto.vtv1.CreateRouteGuideRequest.prototype.clearRouteGuide = function() {
  return this.setRouteGuide(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.CreateRouteGuideRequest.prototype.hasRouteGuide = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.UpsertRouteGuideRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.UpsertRouteGuideRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.UpsertRouteGuideRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.UpsertRouteGuideRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.UpsertRouteGuideRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    siteId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    routeGuideUpsertsList: jspb.Message.toObjectList(msg.getRouteGuideUpsertsList(),
    proto_vorto_node_node_pb.RouteGuideUpsert.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.UpsertRouteGuideRequest}
 */
proto.vtv1.UpsertRouteGuideRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.UpsertRouteGuideRequest;
  return proto.vtv1.UpsertRouteGuideRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.UpsertRouteGuideRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.UpsertRouteGuideRequest}
 */
proto.vtv1.UpsertRouteGuideRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSiteId(value);
      break;
    case 2:
      var value = new proto_vorto_node_node_pb.RouteGuideUpsert;
      reader.readMessage(value,proto_vorto_node_node_pb.RouteGuideUpsert.deserializeBinaryFromReader);
      msg.addRouteGuideUpserts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.UpsertRouteGuideRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.UpsertRouteGuideRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.UpsertRouteGuideRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.UpsertRouteGuideRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSiteId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRouteGuideUpsertsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto_vorto_node_node_pb.RouteGuideUpsert.serializeBinaryToWriter
    );
  }
};


/**
 * optional string site_id = 1;
 * @return {string}
 */
proto.vtv1.UpsertRouteGuideRequest.prototype.getSiteId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.UpsertRouteGuideRequest} returns this
 */
proto.vtv1.UpsertRouteGuideRequest.prototype.setSiteId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated RouteGuideUpsert route_guide_upserts = 2;
 * @return {!Array<!proto.vtv1.RouteGuideUpsert>}
 */
proto.vtv1.UpsertRouteGuideRequest.prototype.getRouteGuideUpsertsList = function() {
  return /** @type{!Array<!proto.vtv1.RouteGuideUpsert>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_vorto_node_node_pb.RouteGuideUpsert, 2));
};


/**
 * @param {!Array<!proto.vtv1.RouteGuideUpsert>} value
 * @return {!proto.vtv1.UpsertRouteGuideRequest} returns this
*/
proto.vtv1.UpsertRouteGuideRequest.prototype.setRouteGuideUpsertsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.vtv1.RouteGuideUpsert=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.RouteGuideUpsert}
 */
proto.vtv1.UpsertRouteGuideRequest.prototype.addRouteGuideUpserts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.vtv1.RouteGuideUpsert, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.UpsertRouteGuideRequest} returns this
 */
proto.vtv1.UpsertRouteGuideRequest.prototype.clearRouteGuideUpsertsList = function() {
  return this.setRouteGuideUpsertsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.RouteGuideResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.RouteGuideResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.RouteGuideResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.RouteGuideResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.RouteGuideResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    routeGuide: (f = msg.getRouteGuide()) && proto_vorto_node_node_pb.RouteGuide.toObject(includeInstance, f),
    routeGuidesList: jspb.Message.toObjectList(msg.getRouteGuidesList(),
    proto_vorto_node_node_pb.RouteGuide.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.RouteGuideResponse}
 */
proto.vtv1.RouteGuideResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.RouteGuideResponse;
  return proto.vtv1.RouteGuideResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.RouteGuideResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.RouteGuideResponse}
 */
proto.vtv1.RouteGuideResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_vorto_node_node_pb.RouteGuide;
      reader.readMessage(value,proto_vorto_node_node_pb.RouteGuide.deserializeBinaryFromReader);
      msg.setRouteGuide(value);
      break;
    case 2:
      var value = new proto_vorto_node_node_pb.RouteGuide;
      reader.readMessage(value,proto_vorto_node_node_pb.RouteGuide.deserializeBinaryFromReader);
      msg.addRouteGuides(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.RouteGuideResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.RouteGuideResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.RouteGuideResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.RouteGuideResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRouteGuide();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_vorto_node_node_pb.RouteGuide.serializeBinaryToWriter
    );
  }
  f = message.getRouteGuidesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto_vorto_node_node_pb.RouteGuide.serializeBinaryToWriter
    );
  }
};


/**
 * optional RouteGuide route_guide = 1;
 * @return {?proto.vtv1.RouteGuide}
 */
proto.vtv1.RouteGuideResponse.prototype.getRouteGuide = function() {
  return /** @type{?proto.vtv1.RouteGuide} */ (
    jspb.Message.getWrapperField(this, proto_vorto_node_node_pb.RouteGuide, 1));
};


/**
 * @param {?proto.vtv1.RouteGuide|undefined} value
 * @return {!proto.vtv1.RouteGuideResponse} returns this
*/
proto.vtv1.RouteGuideResponse.prototype.setRouteGuide = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.RouteGuideResponse} returns this
 */
proto.vtv1.RouteGuideResponse.prototype.clearRouteGuide = function() {
  return this.setRouteGuide(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.RouteGuideResponse.prototype.hasRouteGuide = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated RouteGuide route_guides = 2;
 * @return {!Array<!proto.vtv1.RouteGuide>}
 */
proto.vtv1.RouteGuideResponse.prototype.getRouteGuidesList = function() {
  return /** @type{!Array<!proto.vtv1.RouteGuide>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_vorto_node_node_pb.RouteGuide, 2));
};


/**
 * @param {!Array<!proto.vtv1.RouteGuide>} value
 * @return {!proto.vtv1.RouteGuideResponse} returns this
*/
proto.vtv1.RouteGuideResponse.prototype.setRouteGuidesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.vtv1.RouteGuide=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.RouteGuide}
 */
proto.vtv1.RouteGuideResponse.prototype.addRouteGuides = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.vtv1.RouteGuide, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.RouteGuideResponse} returns this
 */
proto.vtv1.RouteGuideResponse.prototype.clearRouteGuidesList = function() {
  return this.setRouteGuidesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.RouteGuideEdgesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.RouteGuideEdgesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.RouteGuideEdgesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.RouteGuideEdgesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.RouteGuideEdgesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    routeGuideEdgesList: jspb.Message.toObjectList(msg.getRouteGuideEdgesList(),
    proto_vorto_node_node_pb.RouteGuideEdge.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.RouteGuideEdgesResponse}
 */
proto.vtv1.RouteGuideEdgesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.RouteGuideEdgesResponse;
  return proto.vtv1.RouteGuideEdgesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.RouteGuideEdgesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.RouteGuideEdgesResponse}
 */
proto.vtv1.RouteGuideEdgesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_vorto_node_node_pb.RouteGuideEdge;
      reader.readMessage(value,proto_vorto_node_node_pb.RouteGuideEdge.deserializeBinaryFromReader);
      msg.addRouteGuideEdges(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.RouteGuideEdgesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.RouteGuideEdgesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.RouteGuideEdgesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.RouteGuideEdgesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRouteGuideEdgesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto_vorto_node_node_pb.RouteGuideEdge.serializeBinaryToWriter
    );
  }
};


/**
 * repeated RouteGuideEdge route_guide_edges = 1;
 * @return {!Array<!proto.vtv1.RouteGuideEdge>}
 */
proto.vtv1.RouteGuideEdgesResponse.prototype.getRouteGuideEdgesList = function() {
  return /** @type{!Array<!proto.vtv1.RouteGuideEdge>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_vorto_node_node_pb.RouteGuideEdge, 1));
};


/**
 * @param {!Array<!proto.vtv1.RouteGuideEdge>} value
 * @return {!proto.vtv1.RouteGuideEdgesResponse} returns this
*/
proto.vtv1.RouteGuideEdgesResponse.prototype.setRouteGuideEdgesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.vtv1.RouteGuideEdge=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.RouteGuideEdge}
 */
proto.vtv1.RouteGuideEdgesResponse.prototype.addRouteGuideEdges = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.vtv1.RouteGuideEdge, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.RouteGuideEdgesResponse} returns this
 */
proto.vtv1.RouteGuideEdgesResponse.prototype.clearRouteGuideEdgesList = function() {
  return this.setRouteGuideEdgesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.RouteGuideSpotEdgeContractsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.RouteGuideSpotEdgeContractsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.RouteGuideSpotEdgeContractsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.RouteGuideSpotEdgeContractsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.RouteGuideSpotEdgeContractsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    routeGuideSpotCarrierContractsList: jspb.Message.toObjectList(msg.getRouteGuideSpotCarrierContractsList(),
    proto_vorto_node_node_pb.RouteGuideSpotEdgeContract.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.RouteGuideSpotEdgeContractsResponse}
 */
proto.vtv1.RouteGuideSpotEdgeContractsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.RouteGuideSpotEdgeContractsResponse;
  return proto.vtv1.RouteGuideSpotEdgeContractsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.RouteGuideSpotEdgeContractsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.RouteGuideSpotEdgeContractsResponse}
 */
proto.vtv1.RouteGuideSpotEdgeContractsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_vorto_node_node_pb.RouteGuideSpotEdgeContract;
      reader.readMessage(value,proto_vorto_node_node_pb.RouteGuideSpotEdgeContract.deserializeBinaryFromReader);
      msg.addRouteGuideSpotCarrierContracts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.RouteGuideSpotEdgeContractsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.RouteGuideSpotEdgeContractsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.RouteGuideSpotEdgeContractsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.RouteGuideSpotEdgeContractsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRouteGuideSpotCarrierContractsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto_vorto_node_node_pb.RouteGuideSpotEdgeContract.serializeBinaryToWriter
    );
  }
};


/**
 * repeated RouteGuideSpotEdgeContract route_guide_spot_carrier_contracts = 1;
 * @return {!Array<!proto.vtv1.RouteGuideSpotEdgeContract>}
 */
proto.vtv1.RouteGuideSpotEdgeContractsResponse.prototype.getRouteGuideSpotCarrierContractsList = function() {
  return /** @type{!Array<!proto.vtv1.RouteGuideSpotEdgeContract>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_vorto_node_node_pb.RouteGuideSpotEdgeContract, 1));
};


/**
 * @param {!Array<!proto.vtv1.RouteGuideSpotEdgeContract>} value
 * @return {!proto.vtv1.RouteGuideSpotEdgeContractsResponse} returns this
*/
proto.vtv1.RouteGuideSpotEdgeContractsResponse.prototype.setRouteGuideSpotCarrierContractsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.vtv1.RouteGuideSpotEdgeContract=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.RouteGuideSpotEdgeContract}
 */
proto.vtv1.RouteGuideSpotEdgeContractsResponse.prototype.addRouteGuideSpotCarrierContracts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.vtv1.RouteGuideSpotEdgeContract, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.RouteGuideSpotEdgeContractsResponse} returns this
 */
proto.vtv1.RouteGuideSpotEdgeContractsResponse.prototype.clearRouteGuideSpotCarrierContractsList = function() {
  return this.setRouteGuideSpotCarrierContractsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.ListEdgesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.ListEdgesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.ListEdgesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.ListEdgesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.ListEdgesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    edgesList: jspb.Message.toObjectList(msg.getEdgesList(),
    proto_vorto_types_types_pb.AccountIDName.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.ListEdgesResponse}
 */
proto.vtv1.ListEdgesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.ListEdgesResponse;
  return proto.vtv1.ListEdgesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.ListEdgesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.ListEdgesResponse}
 */
proto.vtv1.ListEdgesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_vorto_types_types_pb.AccountIDName;
      reader.readMessage(value,proto_vorto_types_types_pb.AccountIDName.deserializeBinaryFromReader);
      msg.addEdges(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.ListEdgesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.ListEdgesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.ListEdgesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.ListEdgesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEdgesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto_vorto_types_types_pb.AccountIDName.serializeBinaryToWriter
    );
  }
};


/**
 * repeated AccountIDName edges = 1;
 * @return {!Array<!proto.vtv1.AccountIDName>}
 */
proto.vtv1.ListEdgesResponse.prototype.getEdgesList = function() {
  return /** @type{!Array<!proto.vtv1.AccountIDName>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_vorto_types_types_pb.AccountIDName, 1));
};


/**
 * @param {!Array<!proto.vtv1.AccountIDName>} value
 * @return {!proto.vtv1.ListEdgesResponse} returns this
*/
proto.vtv1.ListEdgesResponse.prototype.setEdgesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.vtv1.AccountIDName=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.AccountIDName}
 */
proto.vtv1.ListEdgesResponse.prototype.addEdges = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.vtv1.AccountIDName, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.ListEdgesResponse} returns this
 */
proto.vtv1.ListEdgesResponse.prototype.clearEdgesList = function() {
  return this.setEdgesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.NodeBulkPurchaseOrderLineItem.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.NodeBulkPurchaseOrderLineItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.NodeBulkPurchaseOrderLineItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.NodeBulkPurchaseOrderLineItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    poNumber: jspb.Message.getFieldWithDefault(msg, 1, ""),
    supplierName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    supplierSiteName: jspb.Message.getFieldWithDefault(msg, 4, ""),
    startsAt: (f = msg.getStartsAt()) && proto_vorto_types_types_pb.NullableTime.toObject(includeInstance, f),
    expiresAt: (f = msg.getExpiresAt()) && proto_vorto_types_types_pb.NullableTime.toObject(includeInstance, f),
    leadTimeHours: jspb.Message.getFieldWithDefault(msg, 7, 0),
    shipperIsConsumer: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    lineItemName: jspb.Message.getFieldWithDefault(msg, 9, ""),
    productName: jspb.Message.getFieldWithDefault(msg, 10, ""),
    unitCostCents: jspb.Message.getFloatingPointFieldWithDefault(msg, 11, 0.0),
    totalQuantityMin: jspb.Message.getFloatingPointFieldWithDefault(msg, 12, 0.0),
    totalQuantityMax: (f = msg.getTotalQuantityMax()) && proto_vorto_types_types_pb.NullableDouble.toObject(includeInstance, f),
    dailyQuantityMin: jspb.Message.getFloatingPointFieldWithDefault(msg, 14, 0.0),
    dailyQuantityMax: (f = msg.getDailyQuantityMax()) && proto_vorto_types_types_pb.NullableDouble.toObject(includeInstance, f),
    shortfallUnitCostCents: jspb.Message.getFieldWithDefault(msg, 16, 0),
    description: jspb.Message.getFieldWithDefault(msg, 17, ""),
    poRequiresSupplierConfirmation: jspb.Message.getBooleanFieldWithDefault(msg, 18, false),
    ordersRequireSupplierScheduling: jspb.Message.getBooleanFieldWithDefault(msg, 19, false),
    billable: jspb.Message.getBooleanFieldWithDefault(msg, 20, false),
    quantityInCost: jspb.Message.getFloatingPointFieldWithDefault(msg, 21, 0.0),
    freightTerms: (f = msg.getFreightTerms()) && proto_vorto_types_types_pb.NullableString.toObject(includeInstance, f),
    enableBackhauls: jspb.Message.getBooleanFieldWithDefault(msg, 23, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.NodeBulkPurchaseOrderLineItem}
 */
proto.vtv1.NodeBulkPurchaseOrderLineItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.NodeBulkPurchaseOrderLineItem;
  return proto.vtv1.NodeBulkPurchaseOrderLineItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.NodeBulkPurchaseOrderLineItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.NodeBulkPurchaseOrderLineItem}
 */
proto.vtv1.NodeBulkPurchaseOrderLineItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPoNumber(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSupplierName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setSupplierSiteName(value);
      break;
    case 5:
      var value = new proto_vorto_types_types_pb.NullableTime;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableTime.deserializeBinaryFromReader);
      msg.setStartsAt(value);
      break;
    case 6:
      var value = new proto_vorto_types_types_pb.NullableTime;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableTime.deserializeBinaryFromReader);
      msg.setExpiresAt(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLeadTimeHours(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setShipperIsConsumer(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setLineItemName(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductName(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setUnitCostCents(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalQuantityMin(value);
      break;
    case 13:
      var value = new proto_vorto_types_types_pb.NullableDouble;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableDouble.deserializeBinaryFromReader);
      msg.setTotalQuantityMax(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setDailyQuantityMin(value);
      break;
    case 15:
      var value = new proto_vorto_types_types_pb.NullableDouble;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableDouble.deserializeBinaryFromReader);
      msg.setDailyQuantityMax(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setShortfallUnitCostCents(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 18:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPoRequiresSupplierConfirmation(value);
      break;
    case 19:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOrdersRequireSupplierScheduling(value);
      break;
    case 20:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setBillable(value);
      break;
    case 21:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setQuantityInCost(value);
      break;
    case 22:
      var value = new proto_vorto_types_types_pb.NullableString;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableString.deserializeBinaryFromReader);
      msg.setFreightTerms(value);
      break;
    case 23:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEnableBackhauls(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.NodeBulkPurchaseOrderLineItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.NodeBulkPurchaseOrderLineItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.NodeBulkPurchaseOrderLineItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.NodeBulkPurchaseOrderLineItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPoNumber();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSupplierName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSupplierSiteName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getStartsAt();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto_vorto_types_types_pb.NullableTime.serializeBinaryToWriter
    );
  }
  f = message.getExpiresAt();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto_vorto_types_types_pb.NullableTime.serializeBinaryToWriter
    );
  }
  f = message.getLeadTimeHours();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
  f = message.getShipperIsConsumer();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getLineItemName();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getProductName();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getUnitCostCents();
  if (f !== 0.0) {
    writer.writeDouble(
      11,
      f
    );
  }
  f = message.getTotalQuantityMin();
  if (f !== 0.0) {
    writer.writeDouble(
      12,
      f
    );
  }
  f = message.getTotalQuantityMax();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      proto_vorto_types_types_pb.NullableDouble.serializeBinaryToWriter
    );
  }
  f = message.getDailyQuantityMin();
  if (f !== 0.0) {
    writer.writeDouble(
      14,
      f
    );
  }
  f = message.getDailyQuantityMax();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      proto_vorto_types_types_pb.NullableDouble.serializeBinaryToWriter
    );
  }
  f = message.getShortfallUnitCostCents();
  if (f !== 0) {
    writer.writeInt64(
      16,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getPoRequiresSupplierConfirmation();
  if (f) {
    writer.writeBool(
      18,
      f
    );
  }
  f = message.getOrdersRequireSupplierScheduling();
  if (f) {
    writer.writeBool(
      19,
      f
    );
  }
  f = message.getBillable();
  if (f) {
    writer.writeBool(
      20,
      f
    );
  }
  f = message.getQuantityInCost();
  if (f !== 0.0) {
    writer.writeDouble(
      21,
      f
    );
  }
  f = message.getFreightTerms();
  if (f != null) {
    writer.writeMessage(
      22,
      f,
      proto_vorto_types_types_pb.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getEnableBackhauls();
  if (f) {
    writer.writeBool(
      23,
      f
    );
  }
};


/**
 * optional string po_number = 1;
 * @return {string}
 */
proto.vtv1.NodeBulkPurchaseOrderLineItem.prototype.getPoNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.NodeBulkPurchaseOrderLineItem} returns this
 */
proto.vtv1.NodeBulkPurchaseOrderLineItem.prototype.setPoNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string supplier_name = 3;
 * @return {string}
 */
proto.vtv1.NodeBulkPurchaseOrderLineItem.prototype.getSupplierName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.NodeBulkPurchaseOrderLineItem} returns this
 */
proto.vtv1.NodeBulkPurchaseOrderLineItem.prototype.setSupplierName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string supplier_site_name = 4;
 * @return {string}
 */
proto.vtv1.NodeBulkPurchaseOrderLineItem.prototype.getSupplierSiteName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.NodeBulkPurchaseOrderLineItem} returns this
 */
proto.vtv1.NodeBulkPurchaseOrderLineItem.prototype.setSupplierSiteName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional NullableTime starts_at = 5;
 * @return {?proto.vtv1.NullableTime}
 */
proto.vtv1.NodeBulkPurchaseOrderLineItem.prototype.getStartsAt = function() {
  return /** @type{?proto.vtv1.NullableTime} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableTime, 5));
};


/**
 * @param {?proto.vtv1.NullableTime|undefined} value
 * @return {!proto.vtv1.NodeBulkPurchaseOrderLineItem} returns this
*/
proto.vtv1.NodeBulkPurchaseOrderLineItem.prototype.setStartsAt = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.NodeBulkPurchaseOrderLineItem} returns this
 */
proto.vtv1.NodeBulkPurchaseOrderLineItem.prototype.clearStartsAt = function() {
  return this.setStartsAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.NodeBulkPurchaseOrderLineItem.prototype.hasStartsAt = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional NullableTime expires_at = 6;
 * @return {?proto.vtv1.NullableTime}
 */
proto.vtv1.NodeBulkPurchaseOrderLineItem.prototype.getExpiresAt = function() {
  return /** @type{?proto.vtv1.NullableTime} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableTime, 6));
};


/**
 * @param {?proto.vtv1.NullableTime|undefined} value
 * @return {!proto.vtv1.NodeBulkPurchaseOrderLineItem} returns this
*/
proto.vtv1.NodeBulkPurchaseOrderLineItem.prototype.setExpiresAt = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.NodeBulkPurchaseOrderLineItem} returns this
 */
proto.vtv1.NodeBulkPurchaseOrderLineItem.prototype.clearExpiresAt = function() {
  return this.setExpiresAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.NodeBulkPurchaseOrderLineItem.prototype.hasExpiresAt = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional int64 lead_time_hours = 7;
 * @return {number}
 */
proto.vtv1.NodeBulkPurchaseOrderLineItem.prototype.getLeadTimeHours = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.NodeBulkPurchaseOrderLineItem} returns this
 */
proto.vtv1.NodeBulkPurchaseOrderLineItem.prototype.setLeadTimeHours = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional bool shipper_is_consumer = 8;
 * @return {boolean}
 */
proto.vtv1.NodeBulkPurchaseOrderLineItem.prototype.getShipperIsConsumer = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vtv1.NodeBulkPurchaseOrderLineItem} returns this
 */
proto.vtv1.NodeBulkPurchaseOrderLineItem.prototype.setShipperIsConsumer = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional string line_item_name = 9;
 * @return {string}
 */
proto.vtv1.NodeBulkPurchaseOrderLineItem.prototype.getLineItemName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.NodeBulkPurchaseOrderLineItem} returns this
 */
proto.vtv1.NodeBulkPurchaseOrderLineItem.prototype.setLineItemName = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string product_name = 10;
 * @return {string}
 */
proto.vtv1.NodeBulkPurchaseOrderLineItem.prototype.getProductName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.NodeBulkPurchaseOrderLineItem} returns this
 */
proto.vtv1.NodeBulkPurchaseOrderLineItem.prototype.setProductName = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional double unit_cost_cents = 11;
 * @return {number}
 */
proto.vtv1.NodeBulkPurchaseOrderLineItem.prototype.getUnitCostCents = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 11, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.NodeBulkPurchaseOrderLineItem} returns this
 */
proto.vtv1.NodeBulkPurchaseOrderLineItem.prototype.setUnitCostCents = function(value) {
  return jspb.Message.setProto3FloatField(this, 11, value);
};


/**
 * optional double total_quantity_min = 12;
 * @return {number}
 */
proto.vtv1.NodeBulkPurchaseOrderLineItem.prototype.getTotalQuantityMin = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 12, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.NodeBulkPurchaseOrderLineItem} returns this
 */
proto.vtv1.NodeBulkPurchaseOrderLineItem.prototype.setTotalQuantityMin = function(value) {
  return jspb.Message.setProto3FloatField(this, 12, value);
};


/**
 * optional NullableDouble total_quantity_max = 13;
 * @return {?proto.vtv1.NullableDouble}
 */
proto.vtv1.NodeBulkPurchaseOrderLineItem.prototype.getTotalQuantityMax = function() {
  return /** @type{?proto.vtv1.NullableDouble} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableDouble, 13));
};


/**
 * @param {?proto.vtv1.NullableDouble|undefined} value
 * @return {!proto.vtv1.NodeBulkPurchaseOrderLineItem} returns this
*/
proto.vtv1.NodeBulkPurchaseOrderLineItem.prototype.setTotalQuantityMax = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.NodeBulkPurchaseOrderLineItem} returns this
 */
proto.vtv1.NodeBulkPurchaseOrderLineItem.prototype.clearTotalQuantityMax = function() {
  return this.setTotalQuantityMax(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.NodeBulkPurchaseOrderLineItem.prototype.hasTotalQuantityMax = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional double daily_quantity_min = 14;
 * @return {number}
 */
proto.vtv1.NodeBulkPurchaseOrderLineItem.prototype.getDailyQuantityMin = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 14, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.NodeBulkPurchaseOrderLineItem} returns this
 */
proto.vtv1.NodeBulkPurchaseOrderLineItem.prototype.setDailyQuantityMin = function(value) {
  return jspb.Message.setProto3FloatField(this, 14, value);
};


/**
 * optional NullableDouble daily_quantity_max = 15;
 * @return {?proto.vtv1.NullableDouble}
 */
proto.vtv1.NodeBulkPurchaseOrderLineItem.prototype.getDailyQuantityMax = function() {
  return /** @type{?proto.vtv1.NullableDouble} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableDouble, 15));
};


/**
 * @param {?proto.vtv1.NullableDouble|undefined} value
 * @return {!proto.vtv1.NodeBulkPurchaseOrderLineItem} returns this
*/
proto.vtv1.NodeBulkPurchaseOrderLineItem.prototype.setDailyQuantityMax = function(value) {
  return jspb.Message.setWrapperField(this, 15, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.NodeBulkPurchaseOrderLineItem} returns this
 */
proto.vtv1.NodeBulkPurchaseOrderLineItem.prototype.clearDailyQuantityMax = function() {
  return this.setDailyQuantityMax(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.NodeBulkPurchaseOrderLineItem.prototype.hasDailyQuantityMax = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional int64 shortfall_unit_cost_cents = 16;
 * @return {number}
 */
proto.vtv1.NodeBulkPurchaseOrderLineItem.prototype.getShortfallUnitCostCents = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.NodeBulkPurchaseOrderLineItem} returns this
 */
proto.vtv1.NodeBulkPurchaseOrderLineItem.prototype.setShortfallUnitCostCents = function(value) {
  return jspb.Message.setProto3IntField(this, 16, value);
};


/**
 * optional string description = 17;
 * @return {string}
 */
proto.vtv1.NodeBulkPurchaseOrderLineItem.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.NodeBulkPurchaseOrderLineItem} returns this
 */
proto.vtv1.NodeBulkPurchaseOrderLineItem.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional bool po_requires_supplier_confirmation = 18;
 * @return {boolean}
 */
proto.vtv1.NodeBulkPurchaseOrderLineItem.prototype.getPoRequiresSupplierConfirmation = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 18, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vtv1.NodeBulkPurchaseOrderLineItem} returns this
 */
proto.vtv1.NodeBulkPurchaseOrderLineItem.prototype.setPoRequiresSupplierConfirmation = function(value) {
  return jspb.Message.setProto3BooleanField(this, 18, value);
};


/**
 * optional bool orders_require_supplier_scheduling = 19;
 * @return {boolean}
 */
proto.vtv1.NodeBulkPurchaseOrderLineItem.prototype.getOrdersRequireSupplierScheduling = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 19, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vtv1.NodeBulkPurchaseOrderLineItem} returns this
 */
proto.vtv1.NodeBulkPurchaseOrderLineItem.prototype.setOrdersRequireSupplierScheduling = function(value) {
  return jspb.Message.setProto3BooleanField(this, 19, value);
};


/**
 * optional bool billable = 20;
 * @return {boolean}
 */
proto.vtv1.NodeBulkPurchaseOrderLineItem.prototype.getBillable = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 20, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vtv1.NodeBulkPurchaseOrderLineItem} returns this
 */
proto.vtv1.NodeBulkPurchaseOrderLineItem.prototype.setBillable = function(value) {
  return jspb.Message.setProto3BooleanField(this, 20, value);
};


/**
 * optional double quantity_in_cost = 21;
 * @return {number}
 */
proto.vtv1.NodeBulkPurchaseOrderLineItem.prototype.getQuantityInCost = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 21, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.NodeBulkPurchaseOrderLineItem} returns this
 */
proto.vtv1.NodeBulkPurchaseOrderLineItem.prototype.setQuantityInCost = function(value) {
  return jspb.Message.setProto3FloatField(this, 21, value);
};


/**
 * optional NullableString freight_terms = 22;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.NodeBulkPurchaseOrderLineItem.prototype.getFreightTerms = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableString, 22));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.NodeBulkPurchaseOrderLineItem} returns this
*/
proto.vtv1.NodeBulkPurchaseOrderLineItem.prototype.setFreightTerms = function(value) {
  return jspb.Message.setWrapperField(this, 22, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.NodeBulkPurchaseOrderLineItem} returns this
 */
proto.vtv1.NodeBulkPurchaseOrderLineItem.prototype.clearFreightTerms = function() {
  return this.setFreightTerms(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.NodeBulkPurchaseOrderLineItem.prototype.hasFreightTerms = function() {
  return jspb.Message.getField(this, 22) != null;
};


/**
 * optional bool enable_backhauls = 23;
 * @return {boolean}
 */
proto.vtv1.NodeBulkPurchaseOrderLineItem.prototype.getEnableBackhauls = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 23, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vtv1.NodeBulkPurchaseOrderLineItem} returns this
 */
proto.vtv1.NodeBulkPurchaseOrderLineItem.prototype.setEnableBackhauls = function(value) {
  return jspb.Message.setProto3BooleanField(this, 23, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.MatchAttemptedString.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.MatchAttemptedString.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.MatchAttemptedString} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.MatchAttemptedString.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    percent: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.MatchAttemptedString}
 */
proto.vtv1.MatchAttemptedString.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.MatchAttemptedString;
  return proto.vtv1.MatchAttemptedString.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.MatchAttemptedString} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.MatchAttemptedString}
 */
proto.vtv1.MatchAttemptedString.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPercent(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.MatchAttemptedString.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.MatchAttemptedString.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.MatchAttemptedString} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.MatchAttemptedString.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPercent();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.vtv1.MatchAttemptedString.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.MatchAttemptedString} returns this
 */
proto.vtv1.MatchAttemptedString.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.vtv1.MatchAttemptedString.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.MatchAttemptedString} returns this
 */
proto.vtv1.MatchAttemptedString.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional double percent = 3;
 * @return {number}
 */
proto.vtv1.MatchAttemptedString.prototype.getPercent = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.MatchAttemptedString} returns this
 */
proto.vtv1.MatchAttemptedString.prototype.setPercent = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.MatchAttemptedStrings.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.MatchAttemptedStrings.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.MatchAttemptedStrings.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.MatchAttemptedStrings} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.MatchAttemptedStrings.toObject = function(includeInstance, msg) {
  var f, obj = {
    matchAttemptedStringsList: jspb.Message.toObjectList(msg.getMatchAttemptedStringsList(),
    proto.vtv1.MatchAttemptedString.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.MatchAttemptedStrings}
 */
proto.vtv1.MatchAttemptedStrings.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.MatchAttemptedStrings;
  return proto.vtv1.MatchAttemptedStrings.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.MatchAttemptedStrings} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.MatchAttemptedStrings}
 */
proto.vtv1.MatchAttemptedStrings.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.vtv1.MatchAttemptedString;
      reader.readMessage(value,proto.vtv1.MatchAttemptedString.deserializeBinaryFromReader);
      msg.addMatchAttemptedStrings(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.MatchAttemptedStrings.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.MatchAttemptedStrings.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.MatchAttemptedStrings} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.MatchAttemptedStrings.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMatchAttemptedStringsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.vtv1.MatchAttemptedString.serializeBinaryToWriter
    );
  }
};


/**
 * repeated MatchAttemptedString match_attempted_strings = 1;
 * @return {!Array<!proto.vtv1.MatchAttemptedString>}
 */
proto.vtv1.MatchAttemptedStrings.prototype.getMatchAttemptedStringsList = function() {
  return /** @type{!Array<!proto.vtv1.MatchAttemptedString>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vtv1.MatchAttemptedString, 1));
};


/**
 * @param {!Array<!proto.vtv1.MatchAttemptedString>} value
 * @return {!proto.vtv1.MatchAttemptedStrings} returns this
*/
proto.vtv1.MatchAttemptedStrings.prototype.setMatchAttemptedStringsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.vtv1.MatchAttemptedString=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.MatchAttemptedString}
 */
proto.vtv1.MatchAttemptedStrings.prototype.addMatchAttemptedStrings = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.vtv1.MatchAttemptedString, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.MatchAttemptedStrings} returns this
 */
proto.vtv1.MatchAttemptedStrings.prototype.clearMatchAttemptedStringsList = function() {
  return this.setMatchAttemptedStringsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.NodeBulkUploadPOVerification.repeatedFields_ = [3,10];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.NodeBulkUploadPOVerification.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.NodeBulkUploadPOVerification.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.NodeBulkUploadPOVerification} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.NodeBulkUploadPOVerification.toObject = function(includeInstance, msg) {
  var f, obj = {
    poNumber: jspb.Message.getFieldWithDefault(msg, 1, ""),
    supplierNameProvided: jspb.Message.getFieldWithDefault(msg, 2, ""),
    possibleSuppliersList: jspb.Message.toObjectList(msg.getPossibleSuppliersList(),
    proto.vtv1.MatchAttemptedString.toObject, includeInstance),
    siteNameProvided: jspb.Message.getFieldWithDefault(msg, 4, ""),
    possibleSitesMap: (f = msg.getPossibleSitesMap()) ? f.toObject(includeInstance, proto.vtv1.MatchAttemptedStrings.toObject) : [],
    startsAt: (f = msg.getStartsAt()) && proto_vorto_types_types_pb.NullableTime.toObject(includeInstance, f),
    expiresAt: (f = msg.getExpiresAt()) && proto_vorto_types_types_pb.NullableTime.toObject(includeInstance, f),
    leadTimeHours: jspb.Message.getFieldWithDefault(msg, 8, 0),
    imported: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
    lineItemValidationsList: jspb.Message.toObjectList(msg.getLineItemValidationsList(),
    proto.vtv1.NodeBulkUploadPOLineItemVerification.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.NodeBulkUploadPOVerification}
 */
proto.vtv1.NodeBulkUploadPOVerification.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.NodeBulkUploadPOVerification;
  return proto.vtv1.NodeBulkUploadPOVerification.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.NodeBulkUploadPOVerification} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.NodeBulkUploadPOVerification}
 */
proto.vtv1.NodeBulkUploadPOVerification.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPoNumber(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSupplierNameProvided(value);
      break;
    case 3:
      var value = new proto.vtv1.MatchAttemptedString;
      reader.readMessage(value,proto.vtv1.MatchAttemptedString.deserializeBinaryFromReader);
      msg.addPossibleSuppliers(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setSiteNameProvided(value);
      break;
    case 5:
      var value = msg.getPossibleSitesMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.vtv1.MatchAttemptedStrings.deserializeBinaryFromReader, "", new proto.vtv1.MatchAttemptedStrings());
         });
      break;
    case 6:
      var value = new proto_vorto_types_types_pb.NullableTime;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableTime.deserializeBinaryFromReader);
      msg.setStartsAt(value);
      break;
    case 7:
      var value = new proto_vorto_types_types_pb.NullableTime;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableTime.deserializeBinaryFromReader);
      msg.setExpiresAt(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLeadTimeHours(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setImported(value);
      break;
    case 10:
      var value = new proto.vtv1.NodeBulkUploadPOLineItemVerification;
      reader.readMessage(value,proto.vtv1.NodeBulkUploadPOLineItemVerification.deserializeBinaryFromReader);
      msg.addLineItemValidations(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.NodeBulkUploadPOVerification.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.NodeBulkUploadPOVerification.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.NodeBulkUploadPOVerification} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.NodeBulkUploadPOVerification.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPoNumber();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSupplierNameProvided();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPossibleSuppliersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.vtv1.MatchAttemptedString.serializeBinaryToWriter
    );
  }
  f = message.getSiteNameProvided();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getPossibleSitesMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(5, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.vtv1.MatchAttemptedStrings.serializeBinaryToWriter);
  }
  f = message.getStartsAt();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto_vorto_types_types_pb.NullableTime.serializeBinaryToWriter
    );
  }
  f = message.getExpiresAt();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto_vorto_types_types_pb.NullableTime.serializeBinaryToWriter
    );
  }
  f = message.getLeadTimeHours();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
  f = message.getImported();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getLineItemValidationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      10,
      f,
      proto.vtv1.NodeBulkUploadPOLineItemVerification.serializeBinaryToWriter
    );
  }
};


/**
 * optional string po_number = 1;
 * @return {string}
 */
proto.vtv1.NodeBulkUploadPOVerification.prototype.getPoNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.NodeBulkUploadPOVerification} returns this
 */
proto.vtv1.NodeBulkUploadPOVerification.prototype.setPoNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string supplier_name_provided = 2;
 * @return {string}
 */
proto.vtv1.NodeBulkUploadPOVerification.prototype.getSupplierNameProvided = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.NodeBulkUploadPOVerification} returns this
 */
proto.vtv1.NodeBulkUploadPOVerification.prototype.setSupplierNameProvided = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated MatchAttemptedString possible_suppliers = 3;
 * @return {!Array<!proto.vtv1.MatchAttemptedString>}
 */
proto.vtv1.NodeBulkUploadPOVerification.prototype.getPossibleSuppliersList = function() {
  return /** @type{!Array<!proto.vtv1.MatchAttemptedString>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vtv1.MatchAttemptedString, 3));
};


/**
 * @param {!Array<!proto.vtv1.MatchAttemptedString>} value
 * @return {!proto.vtv1.NodeBulkUploadPOVerification} returns this
*/
proto.vtv1.NodeBulkUploadPOVerification.prototype.setPossibleSuppliersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.vtv1.MatchAttemptedString=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.MatchAttemptedString}
 */
proto.vtv1.NodeBulkUploadPOVerification.prototype.addPossibleSuppliers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.vtv1.MatchAttemptedString, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.NodeBulkUploadPOVerification} returns this
 */
proto.vtv1.NodeBulkUploadPOVerification.prototype.clearPossibleSuppliersList = function() {
  return this.setPossibleSuppliersList([]);
};


/**
 * optional string site_name_provided = 4;
 * @return {string}
 */
proto.vtv1.NodeBulkUploadPOVerification.prototype.getSiteNameProvided = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.NodeBulkUploadPOVerification} returns this
 */
proto.vtv1.NodeBulkUploadPOVerification.prototype.setSiteNameProvided = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * map<string, MatchAttemptedStrings> possible_sites = 5;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.vtv1.MatchAttemptedStrings>}
 */
proto.vtv1.NodeBulkUploadPOVerification.prototype.getPossibleSitesMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.vtv1.MatchAttemptedStrings>} */ (
      jspb.Message.getMapField(this, 5, opt_noLazyCreate,
      proto.vtv1.MatchAttemptedStrings));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.vtv1.NodeBulkUploadPOVerification} returns this
 */
proto.vtv1.NodeBulkUploadPOVerification.prototype.clearPossibleSitesMap = function() {
  this.getPossibleSitesMap().clear();
  return this;};


/**
 * optional NullableTime starts_at = 6;
 * @return {?proto.vtv1.NullableTime}
 */
proto.vtv1.NodeBulkUploadPOVerification.prototype.getStartsAt = function() {
  return /** @type{?proto.vtv1.NullableTime} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableTime, 6));
};


/**
 * @param {?proto.vtv1.NullableTime|undefined} value
 * @return {!proto.vtv1.NodeBulkUploadPOVerification} returns this
*/
proto.vtv1.NodeBulkUploadPOVerification.prototype.setStartsAt = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.NodeBulkUploadPOVerification} returns this
 */
proto.vtv1.NodeBulkUploadPOVerification.prototype.clearStartsAt = function() {
  return this.setStartsAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.NodeBulkUploadPOVerification.prototype.hasStartsAt = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional NullableTime expires_at = 7;
 * @return {?proto.vtv1.NullableTime}
 */
proto.vtv1.NodeBulkUploadPOVerification.prototype.getExpiresAt = function() {
  return /** @type{?proto.vtv1.NullableTime} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableTime, 7));
};


/**
 * @param {?proto.vtv1.NullableTime|undefined} value
 * @return {!proto.vtv1.NodeBulkUploadPOVerification} returns this
*/
proto.vtv1.NodeBulkUploadPOVerification.prototype.setExpiresAt = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.NodeBulkUploadPOVerification} returns this
 */
proto.vtv1.NodeBulkUploadPOVerification.prototype.clearExpiresAt = function() {
  return this.setExpiresAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.NodeBulkUploadPOVerification.prototype.hasExpiresAt = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional int64 lead_time_hours = 8;
 * @return {number}
 */
proto.vtv1.NodeBulkUploadPOVerification.prototype.getLeadTimeHours = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.NodeBulkUploadPOVerification} returns this
 */
proto.vtv1.NodeBulkUploadPOVerification.prototype.setLeadTimeHours = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional bool imported = 9;
 * @return {boolean}
 */
proto.vtv1.NodeBulkUploadPOVerification.prototype.getImported = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vtv1.NodeBulkUploadPOVerification} returns this
 */
proto.vtv1.NodeBulkUploadPOVerification.prototype.setImported = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};


/**
 * repeated NodeBulkUploadPOLineItemVerification line_item_validations = 10;
 * @return {!Array<!proto.vtv1.NodeBulkUploadPOLineItemVerification>}
 */
proto.vtv1.NodeBulkUploadPOVerification.prototype.getLineItemValidationsList = function() {
  return /** @type{!Array<!proto.vtv1.NodeBulkUploadPOLineItemVerification>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vtv1.NodeBulkUploadPOLineItemVerification, 10));
};


/**
 * @param {!Array<!proto.vtv1.NodeBulkUploadPOLineItemVerification>} value
 * @return {!proto.vtv1.NodeBulkUploadPOVerification} returns this
*/
proto.vtv1.NodeBulkUploadPOVerification.prototype.setLineItemValidationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 10, value);
};


/**
 * @param {!proto.vtv1.NodeBulkUploadPOLineItemVerification=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.NodeBulkUploadPOLineItemVerification}
 */
proto.vtv1.NodeBulkUploadPOVerification.prototype.addLineItemValidations = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 10, opt_value, proto.vtv1.NodeBulkUploadPOLineItemVerification, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.NodeBulkUploadPOVerification} returns this
 */
proto.vtv1.NodeBulkUploadPOVerification.prototype.clearLineItemValidationsList = function() {
  return this.setLineItemValidationsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.NodeBulkUploadPOLineItemVerification.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.NodeBulkUploadPOLineItemVerification.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.NodeBulkUploadPOLineItemVerification.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.NodeBulkUploadPOLineItemVerification} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.NodeBulkUploadPOLineItemVerification.toObject = function(includeInstance, msg) {
  var f, obj = {
    lineItemName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    productNameProvided: jspb.Message.getFieldWithDefault(msg, 2, ""),
    possibleProductsList: jspb.Message.toObjectList(msg.getPossibleProductsList(),
    proto.vtv1.MatchAttemptedString.toObject, includeInstance),
    unitCostCents: jspb.Message.getFieldWithDefault(msg, 4, 0),
    totalQuantityMin: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    totalQuantityMax: (f = msg.getTotalQuantityMax()) && proto_vorto_types_types_pb.NullableDouble.toObject(includeInstance, f),
    dailyQuantityMin: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    dailyQuantityMax: (f = msg.getDailyQuantityMax()) && proto_vorto_types_types_pb.NullableDouble.toObject(includeInstance, f),
    shortfallUnitCostCents: jspb.Message.getFieldWithDefault(msg, 9, 0),
    description: jspb.Message.getFieldWithDefault(msg, 10, ""),
    imported: jspb.Message.getBooleanFieldWithDefault(msg, 11, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.NodeBulkUploadPOLineItemVerification}
 */
proto.vtv1.NodeBulkUploadPOLineItemVerification.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.NodeBulkUploadPOLineItemVerification;
  return proto.vtv1.NodeBulkUploadPOLineItemVerification.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.NodeBulkUploadPOLineItemVerification} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.NodeBulkUploadPOLineItemVerification}
 */
proto.vtv1.NodeBulkUploadPOLineItemVerification.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setLineItemName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductNameProvided(value);
      break;
    case 3:
      var value = new proto.vtv1.MatchAttemptedString;
      reader.readMessage(value,proto.vtv1.MatchAttemptedString.deserializeBinaryFromReader);
      msg.addPossibleProducts(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUnitCostCents(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalQuantityMin(value);
      break;
    case 6:
      var value = new proto_vorto_types_types_pb.NullableDouble;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableDouble.deserializeBinaryFromReader);
      msg.setTotalQuantityMax(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setDailyQuantityMin(value);
      break;
    case 8:
      var value = new proto_vorto_types_types_pb.NullableDouble;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableDouble.deserializeBinaryFromReader);
      msg.setDailyQuantityMax(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setShortfallUnitCostCents(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setImported(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.NodeBulkUploadPOLineItemVerification.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.NodeBulkUploadPOLineItemVerification.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.NodeBulkUploadPOLineItemVerification} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.NodeBulkUploadPOLineItemVerification.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLineItemName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getProductNameProvided();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPossibleProductsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.vtv1.MatchAttemptedString.serializeBinaryToWriter
    );
  }
  f = message.getUnitCostCents();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getTotalQuantityMin();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getTotalQuantityMax();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto_vorto_types_types_pb.NullableDouble.serializeBinaryToWriter
    );
  }
  f = message.getDailyQuantityMin();
  if (f !== 0.0) {
    writer.writeDouble(
      7,
      f
    );
  }
  f = message.getDailyQuantityMax();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto_vorto_types_types_pb.NullableDouble.serializeBinaryToWriter
    );
  }
  f = message.getShortfallUnitCostCents();
  if (f !== 0) {
    writer.writeInt64(
      9,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getImported();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
};


/**
 * optional string line_item_name = 1;
 * @return {string}
 */
proto.vtv1.NodeBulkUploadPOLineItemVerification.prototype.getLineItemName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.NodeBulkUploadPOLineItemVerification} returns this
 */
proto.vtv1.NodeBulkUploadPOLineItemVerification.prototype.setLineItemName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string product_name_provided = 2;
 * @return {string}
 */
proto.vtv1.NodeBulkUploadPOLineItemVerification.prototype.getProductNameProvided = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.NodeBulkUploadPOLineItemVerification} returns this
 */
proto.vtv1.NodeBulkUploadPOLineItemVerification.prototype.setProductNameProvided = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated MatchAttemptedString possible_products = 3;
 * @return {!Array<!proto.vtv1.MatchAttemptedString>}
 */
proto.vtv1.NodeBulkUploadPOLineItemVerification.prototype.getPossibleProductsList = function() {
  return /** @type{!Array<!proto.vtv1.MatchAttemptedString>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vtv1.MatchAttemptedString, 3));
};


/**
 * @param {!Array<!proto.vtv1.MatchAttemptedString>} value
 * @return {!proto.vtv1.NodeBulkUploadPOLineItemVerification} returns this
*/
proto.vtv1.NodeBulkUploadPOLineItemVerification.prototype.setPossibleProductsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.vtv1.MatchAttemptedString=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.MatchAttemptedString}
 */
proto.vtv1.NodeBulkUploadPOLineItemVerification.prototype.addPossibleProducts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.vtv1.MatchAttemptedString, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.NodeBulkUploadPOLineItemVerification} returns this
 */
proto.vtv1.NodeBulkUploadPOLineItemVerification.prototype.clearPossibleProductsList = function() {
  return this.setPossibleProductsList([]);
};


/**
 * optional int64 unit_cost_cents = 4;
 * @return {number}
 */
proto.vtv1.NodeBulkUploadPOLineItemVerification.prototype.getUnitCostCents = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.NodeBulkUploadPOLineItemVerification} returns this
 */
proto.vtv1.NodeBulkUploadPOLineItemVerification.prototype.setUnitCostCents = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional double total_quantity_min = 5;
 * @return {number}
 */
proto.vtv1.NodeBulkUploadPOLineItemVerification.prototype.getTotalQuantityMin = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.NodeBulkUploadPOLineItemVerification} returns this
 */
proto.vtv1.NodeBulkUploadPOLineItemVerification.prototype.setTotalQuantityMin = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional NullableDouble total_quantity_max = 6;
 * @return {?proto.vtv1.NullableDouble}
 */
proto.vtv1.NodeBulkUploadPOLineItemVerification.prototype.getTotalQuantityMax = function() {
  return /** @type{?proto.vtv1.NullableDouble} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableDouble, 6));
};


/**
 * @param {?proto.vtv1.NullableDouble|undefined} value
 * @return {!proto.vtv1.NodeBulkUploadPOLineItemVerification} returns this
*/
proto.vtv1.NodeBulkUploadPOLineItemVerification.prototype.setTotalQuantityMax = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.NodeBulkUploadPOLineItemVerification} returns this
 */
proto.vtv1.NodeBulkUploadPOLineItemVerification.prototype.clearTotalQuantityMax = function() {
  return this.setTotalQuantityMax(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.NodeBulkUploadPOLineItemVerification.prototype.hasTotalQuantityMax = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional double daily_quantity_min = 7;
 * @return {number}
 */
proto.vtv1.NodeBulkUploadPOLineItemVerification.prototype.getDailyQuantityMin = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.NodeBulkUploadPOLineItemVerification} returns this
 */
proto.vtv1.NodeBulkUploadPOLineItemVerification.prototype.setDailyQuantityMin = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional NullableDouble daily_quantity_max = 8;
 * @return {?proto.vtv1.NullableDouble}
 */
proto.vtv1.NodeBulkUploadPOLineItemVerification.prototype.getDailyQuantityMax = function() {
  return /** @type{?proto.vtv1.NullableDouble} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableDouble, 8));
};


/**
 * @param {?proto.vtv1.NullableDouble|undefined} value
 * @return {!proto.vtv1.NodeBulkUploadPOLineItemVerification} returns this
*/
proto.vtv1.NodeBulkUploadPOLineItemVerification.prototype.setDailyQuantityMax = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.NodeBulkUploadPOLineItemVerification} returns this
 */
proto.vtv1.NodeBulkUploadPOLineItemVerification.prototype.clearDailyQuantityMax = function() {
  return this.setDailyQuantityMax(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.NodeBulkUploadPOLineItemVerification.prototype.hasDailyQuantityMax = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional int64 shortfall_unit_cost_cents = 9;
 * @return {number}
 */
proto.vtv1.NodeBulkUploadPOLineItemVerification.prototype.getShortfallUnitCostCents = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.NodeBulkUploadPOLineItemVerification} returns this
 */
proto.vtv1.NodeBulkUploadPOLineItemVerification.prototype.setShortfallUnitCostCents = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional string description = 10;
 * @return {string}
 */
proto.vtv1.NodeBulkUploadPOLineItemVerification.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.NodeBulkUploadPOLineItemVerification} returns this
 */
proto.vtv1.NodeBulkUploadPOLineItemVerification.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional bool imported = 11;
 * @return {boolean}
 */
proto.vtv1.NodeBulkUploadPOLineItemVerification.prototype.getImported = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vtv1.NodeBulkUploadPOLineItemVerification} returns this
 */
proto.vtv1.NodeBulkUploadPOLineItemVerification.prototype.setImported = function(value) {
  return jspb.Message.setProto3BooleanField(this, 11, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.NodeBulkUploadPORequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.NodeBulkUploadPORequest.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.NodeBulkUploadPORequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.NodeBulkUploadPORequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.NodeBulkUploadPORequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    lineItemsList: jspb.Message.toObjectList(msg.getLineItemsList(),
    proto.vtv1.NodeBulkPurchaseOrderLineItem.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.NodeBulkUploadPORequest}
 */
proto.vtv1.NodeBulkUploadPORequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.NodeBulkUploadPORequest;
  return proto.vtv1.NodeBulkUploadPORequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.NodeBulkUploadPORequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.NodeBulkUploadPORequest}
 */
proto.vtv1.NodeBulkUploadPORequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.vtv1.NodeBulkPurchaseOrderLineItem;
      reader.readMessage(value,proto.vtv1.NodeBulkPurchaseOrderLineItem.deserializeBinaryFromReader);
      msg.addLineItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.NodeBulkUploadPORequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.NodeBulkUploadPORequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.NodeBulkUploadPORequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.NodeBulkUploadPORequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLineItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.vtv1.NodeBulkPurchaseOrderLineItem.serializeBinaryToWriter
    );
  }
};


/**
 * repeated NodeBulkPurchaseOrderLineItem line_items = 1;
 * @return {!Array<!proto.vtv1.NodeBulkPurchaseOrderLineItem>}
 */
proto.vtv1.NodeBulkUploadPORequest.prototype.getLineItemsList = function() {
  return /** @type{!Array<!proto.vtv1.NodeBulkPurchaseOrderLineItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vtv1.NodeBulkPurchaseOrderLineItem, 1));
};


/**
 * @param {!Array<!proto.vtv1.NodeBulkPurchaseOrderLineItem>} value
 * @return {!proto.vtv1.NodeBulkUploadPORequest} returns this
*/
proto.vtv1.NodeBulkUploadPORequest.prototype.setLineItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.vtv1.NodeBulkPurchaseOrderLineItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.NodeBulkPurchaseOrderLineItem}
 */
proto.vtv1.NodeBulkUploadPORequest.prototype.addLineItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.vtv1.NodeBulkPurchaseOrderLineItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.NodeBulkUploadPORequest} returns this
 */
proto.vtv1.NodeBulkUploadPORequest.prototype.clearLineItemsList = function() {
  return this.setLineItemsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.NodeBulkUploadPOResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.NodeBulkUploadPOResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.NodeBulkUploadPOResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.NodeBulkUploadPOResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.NodeBulkUploadPOResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    verificationsList: jspb.Message.toObjectList(msg.getVerificationsList(),
    proto.vtv1.NodeBulkUploadPOVerification.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.NodeBulkUploadPOResponse}
 */
proto.vtv1.NodeBulkUploadPOResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.NodeBulkUploadPOResponse;
  return proto.vtv1.NodeBulkUploadPOResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.NodeBulkUploadPOResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.NodeBulkUploadPOResponse}
 */
proto.vtv1.NodeBulkUploadPOResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.vtv1.NodeBulkUploadPOVerification;
      reader.readMessage(value,proto.vtv1.NodeBulkUploadPOVerification.deserializeBinaryFromReader);
      msg.addVerifications(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.NodeBulkUploadPOResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.NodeBulkUploadPOResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.NodeBulkUploadPOResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.NodeBulkUploadPOResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVerificationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.vtv1.NodeBulkUploadPOVerification.serializeBinaryToWriter
    );
  }
};


/**
 * repeated NodeBulkUploadPOVerification verifications = 1;
 * @return {!Array<!proto.vtv1.NodeBulkUploadPOVerification>}
 */
proto.vtv1.NodeBulkUploadPOResponse.prototype.getVerificationsList = function() {
  return /** @type{!Array<!proto.vtv1.NodeBulkUploadPOVerification>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vtv1.NodeBulkUploadPOVerification, 1));
};


/**
 * @param {!Array<!proto.vtv1.NodeBulkUploadPOVerification>} value
 * @return {!proto.vtv1.NodeBulkUploadPOResponse} returns this
*/
proto.vtv1.NodeBulkUploadPOResponse.prototype.setVerificationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.vtv1.NodeBulkUploadPOVerification=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.NodeBulkUploadPOVerification}
 */
proto.vtv1.NodeBulkUploadPOResponse.prototype.addVerifications = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.vtv1.NodeBulkUploadPOVerification, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.NodeBulkUploadPOResponse} returns this
 */
proto.vtv1.NodeBulkUploadPOResponse.prototype.clearVerificationsList = function() {
  return this.setVerificationsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.NodeBulkUploadSupplierSite.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.NodeBulkUploadSupplierSite.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.NodeBulkUploadSupplierSite} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.NodeBulkUploadSupplierSite.toObject = function(includeInstance, msg) {
  var f, obj = {
    supplierName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    contactEmail: jspb.Message.getFieldWithDefault(msg, 2, ""),
    supplierSiteName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    supplierSiteAddress: (f = msg.getSupplierSiteAddress()) && proto_vorto_types_types_pb.NullableString.toObject(includeInstance, f),
    latitude: (f = msg.getLatitude()) && proto_vorto_types_types_pb.NullableDouble.toObject(includeInstance, f),
    longitude: (f = msg.getLongitude()) && proto_vorto_types_types_pb.NullableDouble.toObject(includeInstance, f),
    loadingBays: (f = msg.getLoadingBays()) && proto_vorto_types_types_pb.NullableInt64.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.NodeBulkUploadSupplierSite}
 */
proto.vtv1.NodeBulkUploadSupplierSite.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.NodeBulkUploadSupplierSite;
  return proto.vtv1.NodeBulkUploadSupplierSite.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.NodeBulkUploadSupplierSite} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.NodeBulkUploadSupplierSite}
 */
proto.vtv1.NodeBulkUploadSupplierSite.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSupplierName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setContactEmail(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSupplierSiteName(value);
      break;
    case 4:
      var value = new proto_vorto_types_types_pb.NullableString;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableString.deserializeBinaryFromReader);
      msg.setSupplierSiteAddress(value);
      break;
    case 5:
      var value = new proto_vorto_types_types_pb.NullableDouble;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableDouble.deserializeBinaryFromReader);
      msg.setLatitude(value);
      break;
    case 6:
      var value = new proto_vorto_types_types_pb.NullableDouble;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableDouble.deserializeBinaryFromReader);
      msg.setLongitude(value);
      break;
    case 7:
      var value = new proto_vorto_types_types_pb.NullableInt64;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableInt64.deserializeBinaryFromReader);
      msg.setLoadingBays(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.NodeBulkUploadSupplierSite.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.NodeBulkUploadSupplierSite.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.NodeBulkUploadSupplierSite} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.NodeBulkUploadSupplierSite.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSupplierName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getContactEmail();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSupplierSiteName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSupplierSiteAddress();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto_vorto_types_types_pb.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getLatitude();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto_vorto_types_types_pb.NullableDouble.serializeBinaryToWriter
    );
  }
  f = message.getLongitude();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto_vorto_types_types_pb.NullableDouble.serializeBinaryToWriter
    );
  }
  f = message.getLoadingBays();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto_vorto_types_types_pb.NullableInt64.serializeBinaryToWriter
    );
  }
};


/**
 * optional string supplier_name = 1;
 * @return {string}
 */
proto.vtv1.NodeBulkUploadSupplierSite.prototype.getSupplierName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.NodeBulkUploadSupplierSite} returns this
 */
proto.vtv1.NodeBulkUploadSupplierSite.prototype.setSupplierName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string contact_email = 2;
 * @return {string}
 */
proto.vtv1.NodeBulkUploadSupplierSite.prototype.getContactEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.NodeBulkUploadSupplierSite} returns this
 */
proto.vtv1.NodeBulkUploadSupplierSite.prototype.setContactEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string supplier_site_name = 3;
 * @return {string}
 */
proto.vtv1.NodeBulkUploadSupplierSite.prototype.getSupplierSiteName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.NodeBulkUploadSupplierSite} returns this
 */
proto.vtv1.NodeBulkUploadSupplierSite.prototype.setSupplierSiteName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional NullableString supplier_site_address = 4;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.NodeBulkUploadSupplierSite.prototype.getSupplierSiteAddress = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableString, 4));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.NodeBulkUploadSupplierSite} returns this
*/
proto.vtv1.NodeBulkUploadSupplierSite.prototype.setSupplierSiteAddress = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.NodeBulkUploadSupplierSite} returns this
 */
proto.vtv1.NodeBulkUploadSupplierSite.prototype.clearSupplierSiteAddress = function() {
  return this.setSupplierSiteAddress(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.NodeBulkUploadSupplierSite.prototype.hasSupplierSiteAddress = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional NullableDouble latitude = 5;
 * @return {?proto.vtv1.NullableDouble}
 */
proto.vtv1.NodeBulkUploadSupplierSite.prototype.getLatitude = function() {
  return /** @type{?proto.vtv1.NullableDouble} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableDouble, 5));
};


/**
 * @param {?proto.vtv1.NullableDouble|undefined} value
 * @return {!proto.vtv1.NodeBulkUploadSupplierSite} returns this
*/
proto.vtv1.NodeBulkUploadSupplierSite.prototype.setLatitude = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.NodeBulkUploadSupplierSite} returns this
 */
proto.vtv1.NodeBulkUploadSupplierSite.prototype.clearLatitude = function() {
  return this.setLatitude(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.NodeBulkUploadSupplierSite.prototype.hasLatitude = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional NullableDouble longitude = 6;
 * @return {?proto.vtv1.NullableDouble}
 */
proto.vtv1.NodeBulkUploadSupplierSite.prototype.getLongitude = function() {
  return /** @type{?proto.vtv1.NullableDouble} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableDouble, 6));
};


/**
 * @param {?proto.vtv1.NullableDouble|undefined} value
 * @return {!proto.vtv1.NodeBulkUploadSupplierSite} returns this
*/
proto.vtv1.NodeBulkUploadSupplierSite.prototype.setLongitude = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.NodeBulkUploadSupplierSite} returns this
 */
proto.vtv1.NodeBulkUploadSupplierSite.prototype.clearLongitude = function() {
  return this.setLongitude(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.NodeBulkUploadSupplierSite.prototype.hasLongitude = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional NullableInt64 loading_bays = 7;
 * @return {?proto.vtv1.NullableInt64}
 */
proto.vtv1.NodeBulkUploadSupplierSite.prototype.getLoadingBays = function() {
  return /** @type{?proto.vtv1.NullableInt64} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableInt64, 7));
};


/**
 * @param {?proto.vtv1.NullableInt64|undefined} value
 * @return {!proto.vtv1.NodeBulkUploadSupplierSite} returns this
*/
proto.vtv1.NodeBulkUploadSupplierSite.prototype.setLoadingBays = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.NodeBulkUploadSupplierSite} returns this
 */
proto.vtv1.NodeBulkUploadSupplierSite.prototype.clearLoadingBays = function() {
  return this.setLoadingBays(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.NodeBulkUploadSupplierSite.prototype.hasLoadingBays = function() {
  return jspb.Message.getField(this, 7) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.NodeBulkUploadSupplierVerification.repeatedFields_ = [2,3,6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.NodeBulkUploadSupplierVerification.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.NodeBulkUploadSupplierVerification.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.NodeBulkUploadSupplierVerification} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.NodeBulkUploadSupplierVerification.toObject = function(includeInstance, msg) {
  var f, obj = {
    supplierNameProvided: jspb.Message.getFieldWithDefault(msg, 1, ""),
    possibleSuppliersList: jspb.Message.toObjectList(msg.getPossibleSuppliersList(),
    proto.vtv1.MatchAttemptedString.toObject, includeInstance),
    contactEmailList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    matched: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    imported: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    sitesList: jspb.Message.toObjectList(msg.getSitesList(),
    proto.vtv1.NodeBulkUploadSupplierSiteVerification.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.NodeBulkUploadSupplierVerification}
 */
proto.vtv1.NodeBulkUploadSupplierVerification.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.NodeBulkUploadSupplierVerification;
  return proto.vtv1.NodeBulkUploadSupplierVerification.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.NodeBulkUploadSupplierVerification} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.NodeBulkUploadSupplierVerification}
 */
proto.vtv1.NodeBulkUploadSupplierVerification.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSupplierNameProvided(value);
      break;
    case 2:
      var value = new proto.vtv1.MatchAttemptedString;
      reader.readMessage(value,proto.vtv1.MatchAttemptedString.deserializeBinaryFromReader);
      msg.addPossibleSuppliers(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addContactEmail(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setMatched(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setImported(value);
      break;
    case 6:
      var value = new proto.vtv1.NodeBulkUploadSupplierSiteVerification;
      reader.readMessage(value,proto.vtv1.NodeBulkUploadSupplierSiteVerification.deserializeBinaryFromReader);
      msg.addSites(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.NodeBulkUploadSupplierVerification.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.NodeBulkUploadSupplierVerification.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.NodeBulkUploadSupplierVerification} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.NodeBulkUploadSupplierVerification.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSupplierNameProvided();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPossibleSuppliersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.vtv1.MatchAttemptedString.serializeBinaryToWriter
    );
  }
  f = message.getContactEmailList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
  f = message.getMatched();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getImported();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getSitesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.vtv1.NodeBulkUploadSupplierSiteVerification.serializeBinaryToWriter
    );
  }
};


/**
 * optional string supplier_name_provided = 1;
 * @return {string}
 */
proto.vtv1.NodeBulkUploadSupplierVerification.prototype.getSupplierNameProvided = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.NodeBulkUploadSupplierVerification} returns this
 */
proto.vtv1.NodeBulkUploadSupplierVerification.prototype.setSupplierNameProvided = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated MatchAttemptedString possible_suppliers = 2;
 * @return {!Array<!proto.vtv1.MatchAttemptedString>}
 */
proto.vtv1.NodeBulkUploadSupplierVerification.prototype.getPossibleSuppliersList = function() {
  return /** @type{!Array<!proto.vtv1.MatchAttemptedString>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vtv1.MatchAttemptedString, 2));
};


/**
 * @param {!Array<!proto.vtv1.MatchAttemptedString>} value
 * @return {!proto.vtv1.NodeBulkUploadSupplierVerification} returns this
*/
proto.vtv1.NodeBulkUploadSupplierVerification.prototype.setPossibleSuppliersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.vtv1.MatchAttemptedString=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.MatchAttemptedString}
 */
proto.vtv1.NodeBulkUploadSupplierVerification.prototype.addPossibleSuppliers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.vtv1.MatchAttemptedString, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.NodeBulkUploadSupplierVerification} returns this
 */
proto.vtv1.NodeBulkUploadSupplierVerification.prototype.clearPossibleSuppliersList = function() {
  return this.setPossibleSuppliersList([]);
};


/**
 * repeated string contact_email = 3;
 * @return {!Array<string>}
 */
proto.vtv1.NodeBulkUploadSupplierVerification.prototype.getContactEmailList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.vtv1.NodeBulkUploadSupplierVerification} returns this
 */
proto.vtv1.NodeBulkUploadSupplierVerification.prototype.setContactEmailList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.vtv1.NodeBulkUploadSupplierVerification} returns this
 */
proto.vtv1.NodeBulkUploadSupplierVerification.prototype.addContactEmail = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.NodeBulkUploadSupplierVerification} returns this
 */
proto.vtv1.NodeBulkUploadSupplierVerification.prototype.clearContactEmailList = function() {
  return this.setContactEmailList([]);
};


/**
 * optional bool matched = 4;
 * @return {boolean}
 */
proto.vtv1.NodeBulkUploadSupplierVerification.prototype.getMatched = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vtv1.NodeBulkUploadSupplierVerification} returns this
 */
proto.vtv1.NodeBulkUploadSupplierVerification.prototype.setMatched = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional bool imported = 5;
 * @return {boolean}
 */
proto.vtv1.NodeBulkUploadSupplierVerification.prototype.getImported = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vtv1.NodeBulkUploadSupplierVerification} returns this
 */
proto.vtv1.NodeBulkUploadSupplierVerification.prototype.setImported = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * repeated NodeBulkUploadSupplierSiteVerification sites = 6;
 * @return {!Array<!proto.vtv1.NodeBulkUploadSupplierSiteVerification>}
 */
proto.vtv1.NodeBulkUploadSupplierVerification.prototype.getSitesList = function() {
  return /** @type{!Array<!proto.vtv1.NodeBulkUploadSupplierSiteVerification>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vtv1.NodeBulkUploadSupplierSiteVerification, 6));
};


/**
 * @param {!Array<!proto.vtv1.NodeBulkUploadSupplierSiteVerification>} value
 * @return {!proto.vtv1.NodeBulkUploadSupplierVerification} returns this
*/
proto.vtv1.NodeBulkUploadSupplierVerification.prototype.setSitesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.vtv1.NodeBulkUploadSupplierSiteVerification=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.NodeBulkUploadSupplierSiteVerification}
 */
proto.vtv1.NodeBulkUploadSupplierVerification.prototype.addSites = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.vtv1.NodeBulkUploadSupplierSiteVerification, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.NodeBulkUploadSupplierVerification} returns this
 */
proto.vtv1.NodeBulkUploadSupplierVerification.prototype.clearSitesList = function() {
  return this.setSitesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.NodeBulkUploadSupplierSiteVerification.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.NodeBulkUploadSupplierSiteVerification.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.NodeBulkUploadSupplierSiteVerification.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.NodeBulkUploadSupplierSiteVerification} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.NodeBulkUploadSupplierSiteVerification.toObject = function(includeInstance, msg) {
  var f, obj = {
    supplierSiteNameProvided: jspb.Message.getFieldWithDefault(msg, 1, ""),
    possibleSitesList: jspb.Message.toObjectList(msg.getPossibleSitesList(),
    proto.vtv1.MatchAttemptedString.toObject, includeInstance),
    supplierSiteAddress: (f = msg.getSupplierSiteAddress()) && proto_vorto_types_types_pb.NullableString.toObject(includeInstance, f),
    latitude: (f = msg.getLatitude()) && proto_vorto_types_types_pb.NullableDouble.toObject(includeInstance, f),
    longitude: (f = msg.getLongitude()) && proto_vorto_types_types_pb.NullableDouble.toObject(includeInstance, f),
    loadingBays: (f = msg.getLoadingBays()) && proto_vorto_types_types_pb.NullableInt64.toObject(includeInstance, f),
    matched: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    imported: jspb.Message.getBooleanFieldWithDefault(msg, 8, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.NodeBulkUploadSupplierSiteVerification}
 */
proto.vtv1.NodeBulkUploadSupplierSiteVerification.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.NodeBulkUploadSupplierSiteVerification;
  return proto.vtv1.NodeBulkUploadSupplierSiteVerification.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.NodeBulkUploadSupplierSiteVerification} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.NodeBulkUploadSupplierSiteVerification}
 */
proto.vtv1.NodeBulkUploadSupplierSiteVerification.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSupplierSiteNameProvided(value);
      break;
    case 2:
      var value = new proto.vtv1.MatchAttemptedString;
      reader.readMessage(value,proto.vtv1.MatchAttemptedString.deserializeBinaryFromReader);
      msg.addPossibleSites(value);
      break;
    case 3:
      var value = new proto_vorto_types_types_pb.NullableString;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableString.deserializeBinaryFromReader);
      msg.setSupplierSiteAddress(value);
      break;
    case 4:
      var value = new proto_vorto_types_types_pb.NullableDouble;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableDouble.deserializeBinaryFromReader);
      msg.setLatitude(value);
      break;
    case 5:
      var value = new proto_vorto_types_types_pb.NullableDouble;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableDouble.deserializeBinaryFromReader);
      msg.setLongitude(value);
      break;
    case 6:
      var value = new proto_vorto_types_types_pb.NullableInt64;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableInt64.deserializeBinaryFromReader);
      msg.setLoadingBays(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setMatched(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setImported(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.NodeBulkUploadSupplierSiteVerification.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.NodeBulkUploadSupplierSiteVerification.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.NodeBulkUploadSupplierSiteVerification} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.NodeBulkUploadSupplierSiteVerification.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSupplierSiteNameProvided();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPossibleSitesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.vtv1.MatchAttemptedString.serializeBinaryToWriter
    );
  }
  f = message.getSupplierSiteAddress();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto_vorto_types_types_pb.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getLatitude();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto_vorto_types_types_pb.NullableDouble.serializeBinaryToWriter
    );
  }
  f = message.getLongitude();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto_vorto_types_types_pb.NullableDouble.serializeBinaryToWriter
    );
  }
  f = message.getLoadingBays();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto_vorto_types_types_pb.NullableInt64.serializeBinaryToWriter
    );
  }
  f = message.getMatched();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getImported();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
};


/**
 * optional string supplier_site_name_provided = 1;
 * @return {string}
 */
proto.vtv1.NodeBulkUploadSupplierSiteVerification.prototype.getSupplierSiteNameProvided = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.NodeBulkUploadSupplierSiteVerification} returns this
 */
proto.vtv1.NodeBulkUploadSupplierSiteVerification.prototype.setSupplierSiteNameProvided = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated MatchAttemptedString possible_sites = 2;
 * @return {!Array<!proto.vtv1.MatchAttemptedString>}
 */
proto.vtv1.NodeBulkUploadSupplierSiteVerification.prototype.getPossibleSitesList = function() {
  return /** @type{!Array<!proto.vtv1.MatchAttemptedString>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vtv1.MatchAttemptedString, 2));
};


/**
 * @param {!Array<!proto.vtv1.MatchAttemptedString>} value
 * @return {!proto.vtv1.NodeBulkUploadSupplierSiteVerification} returns this
*/
proto.vtv1.NodeBulkUploadSupplierSiteVerification.prototype.setPossibleSitesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.vtv1.MatchAttemptedString=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.MatchAttemptedString}
 */
proto.vtv1.NodeBulkUploadSupplierSiteVerification.prototype.addPossibleSites = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.vtv1.MatchAttemptedString, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.NodeBulkUploadSupplierSiteVerification} returns this
 */
proto.vtv1.NodeBulkUploadSupplierSiteVerification.prototype.clearPossibleSitesList = function() {
  return this.setPossibleSitesList([]);
};


/**
 * optional NullableString supplier_site_address = 3;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.NodeBulkUploadSupplierSiteVerification.prototype.getSupplierSiteAddress = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableString, 3));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.NodeBulkUploadSupplierSiteVerification} returns this
*/
proto.vtv1.NodeBulkUploadSupplierSiteVerification.prototype.setSupplierSiteAddress = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.NodeBulkUploadSupplierSiteVerification} returns this
 */
proto.vtv1.NodeBulkUploadSupplierSiteVerification.prototype.clearSupplierSiteAddress = function() {
  return this.setSupplierSiteAddress(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.NodeBulkUploadSupplierSiteVerification.prototype.hasSupplierSiteAddress = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional NullableDouble latitude = 4;
 * @return {?proto.vtv1.NullableDouble}
 */
proto.vtv1.NodeBulkUploadSupplierSiteVerification.prototype.getLatitude = function() {
  return /** @type{?proto.vtv1.NullableDouble} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableDouble, 4));
};


/**
 * @param {?proto.vtv1.NullableDouble|undefined} value
 * @return {!proto.vtv1.NodeBulkUploadSupplierSiteVerification} returns this
*/
proto.vtv1.NodeBulkUploadSupplierSiteVerification.prototype.setLatitude = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.NodeBulkUploadSupplierSiteVerification} returns this
 */
proto.vtv1.NodeBulkUploadSupplierSiteVerification.prototype.clearLatitude = function() {
  return this.setLatitude(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.NodeBulkUploadSupplierSiteVerification.prototype.hasLatitude = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional NullableDouble longitude = 5;
 * @return {?proto.vtv1.NullableDouble}
 */
proto.vtv1.NodeBulkUploadSupplierSiteVerification.prototype.getLongitude = function() {
  return /** @type{?proto.vtv1.NullableDouble} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableDouble, 5));
};


/**
 * @param {?proto.vtv1.NullableDouble|undefined} value
 * @return {!proto.vtv1.NodeBulkUploadSupplierSiteVerification} returns this
*/
proto.vtv1.NodeBulkUploadSupplierSiteVerification.prototype.setLongitude = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.NodeBulkUploadSupplierSiteVerification} returns this
 */
proto.vtv1.NodeBulkUploadSupplierSiteVerification.prototype.clearLongitude = function() {
  return this.setLongitude(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.NodeBulkUploadSupplierSiteVerification.prototype.hasLongitude = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional NullableInt64 loading_bays = 6;
 * @return {?proto.vtv1.NullableInt64}
 */
proto.vtv1.NodeBulkUploadSupplierSiteVerification.prototype.getLoadingBays = function() {
  return /** @type{?proto.vtv1.NullableInt64} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableInt64, 6));
};


/**
 * @param {?proto.vtv1.NullableInt64|undefined} value
 * @return {!proto.vtv1.NodeBulkUploadSupplierSiteVerification} returns this
*/
proto.vtv1.NodeBulkUploadSupplierSiteVerification.prototype.setLoadingBays = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.NodeBulkUploadSupplierSiteVerification} returns this
 */
proto.vtv1.NodeBulkUploadSupplierSiteVerification.prototype.clearLoadingBays = function() {
  return this.setLoadingBays(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.NodeBulkUploadSupplierSiteVerification.prototype.hasLoadingBays = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional bool matched = 7;
 * @return {boolean}
 */
proto.vtv1.NodeBulkUploadSupplierSiteVerification.prototype.getMatched = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vtv1.NodeBulkUploadSupplierSiteVerification} returns this
 */
proto.vtv1.NodeBulkUploadSupplierSiteVerification.prototype.setMatched = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional bool imported = 8;
 * @return {boolean}
 */
proto.vtv1.NodeBulkUploadSupplierSiteVerification.prototype.getImported = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vtv1.NodeBulkUploadSupplierSiteVerification} returns this
 */
proto.vtv1.NodeBulkUploadSupplierSiteVerification.prototype.setImported = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.NodeBulkUploadSupplierSiteRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.NodeBulkUploadSupplierSiteRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.NodeBulkUploadSupplierSiteRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.NodeBulkUploadSupplierSiteRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.NodeBulkUploadSupplierSiteRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    sitesList: jspb.Message.toObjectList(msg.getSitesList(),
    proto.vtv1.NodeBulkUploadSupplierSite.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.NodeBulkUploadSupplierSiteRequest}
 */
proto.vtv1.NodeBulkUploadSupplierSiteRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.NodeBulkUploadSupplierSiteRequest;
  return proto.vtv1.NodeBulkUploadSupplierSiteRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.NodeBulkUploadSupplierSiteRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.NodeBulkUploadSupplierSiteRequest}
 */
proto.vtv1.NodeBulkUploadSupplierSiteRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.vtv1.NodeBulkUploadSupplierSite;
      reader.readMessage(value,proto.vtv1.NodeBulkUploadSupplierSite.deserializeBinaryFromReader);
      msg.addSites(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.NodeBulkUploadSupplierSiteRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.NodeBulkUploadSupplierSiteRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.NodeBulkUploadSupplierSiteRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.NodeBulkUploadSupplierSiteRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSitesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.vtv1.NodeBulkUploadSupplierSite.serializeBinaryToWriter
    );
  }
};


/**
 * repeated NodeBulkUploadSupplierSite sites = 1;
 * @return {!Array<!proto.vtv1.NodeBulkUploadSupplierSite>}
 */
proto.vtv1.NodeBulkUploadSupplierSiteRequest.prototype.getSitesList = function() {
  return /** @type{!Array<!proto.vtv1.NodeBulkUploadSupplierSite>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vtv1.NodeBulkUploadSupplierSite, 1));
};


/**
 * @param {!Array<!proto.vtv1.NodeBulkUploadSupplierSite>} value
 * @return {!proto.vtv1.NodeBulkUploadSupplierSiteRequest} returns this
*/
proto.vtv1.NodeBulkUploadSupplierSiteRequest.prototype.setSitesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.vtv1.NodeBulkUploadSupplierSite=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.NodeBulkUploadSupplierSite}
 */
proto.vtv1.NodeBulkUploadSupplierSiteRequest.prototype.addSites = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.vtv1.NodeBulkUploadSupplierSite, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.NodeBulkUploadSupplierSiteRequest} returns this
 */
proto.vtv1.NodeBulkUploadSupplierSiteRequest.prototype.clearSitesList = function() {
  return this.setSitesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.NodeBulkUploadSupplierSiteResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.NodeBulkUploadSupplierSiteResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.NodeBulkUploadSupplierSiteResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.NodeBulkUploadSupplierSiteResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.NodeBulkUploadSupplierSiteResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    verificationsList: jspb.Message.toObjectList(msg.getVerificationsList(),
    proto.vtv1.NodeBulkUploadSupplierVerification.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.NodeBulkUploadSupplierSiteResponse}
 */
proto.vtv1.NodeBulkUploadSupplierSiteResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.NodeBulkUploadSupplierSiteResponse;
  return proto.vtv1.NodeBulkUploadSupplierSiteResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.NodeBulkUploadSupplierSiteResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.NodeBulkUploadSupplierSiteResponse}
 */
proto.vtv1.NodeBulkUploadSupplierSiteResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.vtv1.NodeBulkUploadSupplierVerification;
      reader.readMessage(value,proto.vtv1.NodeBulkUploadSupplierVerification.deserializeBinaryFromReader);
      msg.addVerifications(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.NodeBulkUploadSupplierSiteResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.NodeBulkUploadSupplierSiteResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.NodeBulkUploadSupplierSiteResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.NodeBulkUploadSupplierSiteResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVerificationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.vtv1.NodeBulkUploadSupplierVerification.serializeBinaryToWriter
    );
  }
};


/**
 * repeated NodeBulkUploadSupplierVerification verifications = 1;
 * @return {!Array<!proto.vtv1.NodeBulkUploadSupplierVerification>}
 */
proto.vtv1.NodeBulkUploadSupplierSiteResponse.prototype.getVerificationsList = function() {
  return /** @type{!Array<!proto.vtv1.NodeBulkUploadSupplierVerification>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vtv1.NodeBulkUploadSupplierVerification, 1));
};


/**
 * @param {!Array<!proto.vtv1.NodeBulkUploadSupplierVerification>} value
 * @return {!proto.vtv1.NodeBulkUploadSupplierSiteResponse} returns this
*/
proto.vtv1.NodeBulkUploadSupplierSiteResponse.prototype.setVerificationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.vtv1.NodeBulkUploadSupplierVerification=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.NodeBulkUploadSupplierVerification}
 */
proto.vtv1.NodeBulkUploadSupplierSiteResponse.prototype.addVerifications = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.vtv1.NodeBulkUploadSupplierVerification, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.NodeBulkUploadSupplierSiteResponse} returns this
 */
proto.vtv1.NodeBulkUploadSupplierSiteResponse.prototype.clearVerificationsList = function() {
  return this.setVerificationsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.HasPOForProductResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.HasPOForProductResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.HasPOForProductResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.HasPOForProductResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    hasPo: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.HasPOForProductResponse}
 */
proto.vtv1.HasPOForProductResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.HasPOForProductResponse;
  return proto.vtv1.HasPOForProductResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.HasPOForProductResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.HasPOForProductResponse}
 */
proto.vtv1.HasPOForProductResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasPo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.HasPOForProductResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.HasPOForProductResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.HasPOForProductResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.HasPOForProductResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHasPo();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool has_po = 1;
 * @return {boolean}
 */
proto.vtv1.HasPOForProductResponse.prototype.getHasPo = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vtv1.HasPOForProductResponse} returns this
 */
proto.vtv1.HasPOForProductResponse.prototype.setHasPo = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.SupplyPlanSummary.repeatedFields_ = [5,6,7,15];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.SupplyPlanSummary.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.SupplyPlanSummary.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.SupplyPlanSummary} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.SupplyPlanSummary.toObject = function(includeInstance, msg) {
  var f, obj = {
    inventoryId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    productName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    siteName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    status: jspb.Message.getFieldWithDefault(msg, 4, ""),
    unfulfilledDemandSignalsList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f,
    posExpiringSoonList: (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f,
    posAlmostExhaustedList: (f = jspb.Message.getRepeatedField(msg, 7)) == null ? undefined : f,
    statusGroup: jspb.Message.getFieldWithDefault(msg, 8, ""),
    siteId: jspb.Message.getFieldWithDefault(msg, 9, ""),
    poWarningToday: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
    poWarningTomorrow: jspb.Message.getBooleanFieldWithDefault(msg, 11, false),
    warnWhenLowerThan: jspb.Message.getFieldWithDefault(msg, 14, 0),
    posExpiredList: (f = jspb.Message.getRepeatedField(msg, 15)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.SupplyPlanSummary}
 */
proto.vtv1.SupplyPlanSummary.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.SupplyPlanSummary;
  return proto.vtv1.SupplyPlanSummary.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.SupplyPlanSummary} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.SupplyPlanSummary}
 */
proto.vtv1.SupplyPlanSummary.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setInventoryId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSiteName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.addUnfulfilledDemandSignals(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.addPosExpiringSoon(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.addPosAlmostExhausted(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatusGroup(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setSiteId(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPoWarningToday(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPoWarningTomorrow(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setWarnWhenLowerThan(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.addPosExpired(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.SupplyPlanSummary.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.SupplyPlanSummary.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.SupplyPlanSummary} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.SupplyPlanSummary.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInventoryId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getProductName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSiteName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getUnfulfilledDemandSignalsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      5,
      f
    );
  }
  f = message.getPosExpiringSoonList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      6,
      f
    );
  }
  f = message.getPosAlmostExhaustedList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      7,
      f
    );
  }
  f = message.getStatusGroup();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getSiteId();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getPoWarningToday();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
  f = message.getPoWarningTomorrow();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
  f = message.getWarnWhenLowerThan();
  if (f !== 0) {
    writer.writeInt64(
      14,
      f
    );
  }
  f = message.getPosExpiredList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      15,
      f
    );
  }
};


/**
 * optional string inventory_id = 1;
 * @return {string}
 */
proto.vtv1.SupplyPlanSummary.prototype.getInventoryId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.SupplyPlanSummary} returns this
 */
proto.vtv1.SupplyPlanSummary.prototype.setInventoryId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string product_name = 2;
 * @return {string}
 */
proto.vtv1.SupplyPlanSummary.prototype.getProductName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.SupplyPlanSummary} returns this
 */
proto.vtv1.SupplyPlanSummary.prototype.setProductName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string site_name = 3;
 * @return {string}
 */
proto.vtv1.SupplyPlanSummary.prototype.getSiteName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.SupplyPlanSummary} returns this
 */
proto.vtv1.SupplyPlanSummary.prototype.setSiteName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string status = 4;
 * @return {string}
 */
proto.vtv1.SupplyPlanSummary.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.SupplyPlanSummary} returns this
 */
proto.vtv1.SupplyPlanSummary.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * repeated string unfulfilled_demand_signals = 5;
 * @return {!Array<string>}
 */
proto.vtv1.SupplyPlanSummary.prototype.getUnfulfilledDemandSignalsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.vtv1.SupplyPlanSummary} returns this
 */
proto.vtv1.SupplyPlanSummary.prototype.setUnfulfilledDemandSignalsList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.vtv1.SupplyPlanSummary} returns this
 */
proto.vtv1.SupplyPlanSummary.prototype.addUnfulfilledDemandSignals = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.SupplyPlanSummary} returns this
 */
proto.vtv1.SupplyPlanSummary.prototype.clearUnfulfilledDemandSignalsList = function() {
  return this.setUnfulfilledDemandSignalsList([]);
};


/**
 * repeated string pos_expiring_soon = 6;
 * @return {!Array<string>}
 */
proto.vtv1.SupplyPlanSummary.prototype.getPosExpiringSoonList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 6));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.vtv1.SupplyPlanSummary} returns this
 */
proto.vtv1.SupplyPlanSummary.prototype.setPosExpiringSoonList = function(value) {
  return jspb.Message.setField(this, 6, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.vtv1.SupplyPlanSummary} returns this
 */
proto.vtv1.SupplyPlanSummary.prototype.addPosExpiringSoon = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.SupplyPlanSummary} returns this
 */
proto.vtv1.SupplyPlanSummary.prototype.clearPosExpiringSoonList = function() {
  return this.setPosExpiringSoonList([]);
};


/**
 * repeated string pos_almost_exhausted = 7;
 * @return {!Array<string>}
 */
proto.vtv1.SupplyPlanSummary.prototype.getPosAlmostExhaustedList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 7));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.vtv1.SupplyPlanSummary} returns this
 */
proto.vtv1.SupplyPlanSummary.prototype.setPosAlmostExhaustedList = function(value) {
  return jspb.Message.setField(this, 7, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.vtv1.SupplyPlanSummary} returns this
 */
proto.vtv1.SupplyPlanSummary.prototype.addPosAlmostExhausted = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 7, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.SupplyPlanSummary} returns this
 */
proto.vtv1.SupplyPlanSummary.prototype.clearPosAlmostExhaustedList = function() {
  return this.setPosAlmostExhaustedList([]);
};


/**
 * optional string status_group = 8;
 * @return {string}
 */
proto.vtv1.SupplyPlanSummary.prototype.getStatusGroup = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.SupplyPlanSummary} returns this
 */
proto.vtv1.SupplyPlanSummary.prototype.setStatusGroup = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string site_id = 9;
 * @return {string}
 */
proto.vtv1.SupplyPlanSummary.prototype.getSiteId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.SupplyPlanSummary} returns this
 */
proto.vtv1.SupplyPlanSummary.prototype.setSiteId = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional bool po_warning_today = 10;
 * @return {boolean}
 */
proto.vtv1.SupplyPlanSummary.prototype.getPoWarningToday = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vtv1.SupplyPlanSummary} returns this
 */
proto.vtv1.SupplyPlanSummary.prototype.setPoWarningToday = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};


/**
 * optional bool po_warning_tomorrow = 11;
 * @return {boolean}
 */
proto.vtv1.SupplyPlanSummary.prototype.getPoWarningTomorrow = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vtv1.SupplyPlanSummary} returns this
 */
proto.vtv1.SupplyPlanSummary.prototype.setPoWarningTomorrow = function(value) {
  return jspb.Message.setProto3BooleanField(this, 11, value);
};


/**
 * optional int64 warn_when_lower_than = 14;
 * @return {number}
 */
proto.vtv1.SupplyPlanSummary.prototype.getWarnWhenLowerThan = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.SupplyPlanSummary} returns this
 */
proto.vtv1.SupplyPlanSummary.prototype.setWarnWhenLowerThan = function(value) {
  return jspb.Message.setProto3IntField(this, 14, value);
};


/**
 * repeated string pos_expired = 15;
 * @return {!Array<string>}
 */
proto.vtv1.SupplyPlanSummary.prototype.getPosExpiredList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 15));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.vtv1.SupplyPlanSummary} returns this
 */
proto.vtv1.SupplyPlanSummary.prototype.setPosExpiredList = function(value) {
  return jspb.Message.setField(this, 15, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.vtv1.SupplyPlanSummary} returns this
 */
proto.vtv1.SupplyPlanSummary.prototype.addPosExpired = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 15, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.SupplyPlanSummary} returns this
 */
proto.vtv1.SupplyPlanSummary.prototype.clearPosExpiredList = function() {
  return this.setPosExpiredList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.NodeListSupplyPlansResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.NodeListSupplyPlansResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.NodeListSupplyPlansResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.NodeListSupplyPlansResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.NodeListSupplyPlansResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    plansList: jspb.Message.toObjectList(msg.getPlansList(),
    proto.vtv1.SupplyPlanSummary.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.NodeListSupplyPlansResponse}
 */
proto.vtv1.NodeListSupplyPlansResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.NodeListSupplyPlansResponse;
  return proto.vtv1.NodeListSupplyPlansResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.NodeListSupplyPlansResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.NodeListSupplyPlansResponse}
 */
proto.vtv1.NodeListSupplyPlansResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.vtv1.SupplyPlanSummary;
      reader.readMessage(value,proto.vtv1.SupplyPlanSummary.deserializeBinaryFromReader);
      msg.addPlans(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.NodeListSupplyPlansResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.NodeListSupplyPlansResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.NodeListSupplyPlansResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.NodeListSupplyPlansResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPlansList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.vtv1.SupplyPlanSummary.serializeBinaryToWriter
    );
  }
};


/**
 * repeated SupplyPlanSummary plans = 1;
 * @return {!Array<!proto.vtv1.SupplyPlanSummary>}
 */
proto.vtv1.NodeListSupplyPlansResponse.prototype.getPlansList = function() {
  return /** @type{!Array<!proto.vtv1.SupplyPlanSummary>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vtv1.SupplyPlanSummary, 1));
};


/**
 * @param {!Array<!proto.vtv1.SupplyPlanSummary>} value
 * @return {!proto.vtv1.NodeListSupplyPlansResponse} returns this
*/
proto.vtv1.NodeListSupplyPlansResponse.prototype.setPlansList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.vtv1.SupplyPlanSummary=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.SupplyPlanSummary}
 */
proto.vtv1.NodeListSupplyPlansResponse.prototype.addPlans = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.vtv1.SupplyPlanSummary, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.NodeListSupplyPlansResponse} returns this
 */
proto.vtv1.NodeListSupplyPlansResponse.prototype.clearPlansList = function() {
  return this.setPlansList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.NodeListPurchaseOrdersForInventoryResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.NodeListPurchaseOrdersForInventoryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.NodeListPurchaseOrdersForInventoryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.NodeListPurchaseOrdersForInventoryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.NodeListPurchaseOrdersForInventoryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    purchaseordersList: jspb.Message.toObjectList(msg.getPurchaseordersList(),
    proto_vorto_node_node_pb.InventorySupplyPlanPurchaseOrder.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.NodeListPurchaseOrdersForInventoryResponse}
 */
proto.vtv1.NodeListPurchaseOrdersForInventoryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.NodeListPurchaseOrdersForInventoryResponse;
  return proto.vtv1.NodeListPurchaseOrdersForInventoryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.NodeListPurchaseOrdersForInventoryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.NodeListPurchaseOrdersForInventoryResponse}
 */
proto.vtv1.NodeListPurchaseOrdersForInventoryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_vorto_node_node_pb.InventorySupplyPlanPurchaseOrder;
      reader.readMessage(value,proto_vorto_node_node_pb.InventorySupplyPlanPurchaseOrder.deserializeBinaryFromReader);
      msg.addPurchaseorders(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.NodeListPurchaseOrdersForInventoryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.NodeListPurchaseOrdersForInventoryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.NodeListPurchaseOrdersForInventoryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.NodeListPurchaseOrdersForInventoryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPurchaseordersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto_vorto_node_node_pb.InventorySupplyPlanPurchaseOrder.serializeBinaryToWriter
    );
  }
};


/**
 * repeated InventorySupplyPlanPurchaseOrder purchaseOrders = 1;
 * @return {!Array<!proto.vtv1.InventorySupplyPlanPurchaseOrder>}
 */
proto.vtv1.NodeListPurchaseOrdersForInventoryResponse.prototype.getPurchaseordersList = function() {
  return /** @type{!Array<!proto.vtv1.InventorySupplyPlanPurchaseOrder>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_vorto_node_node_pb.InventorySupplyPlanPurchaseOrder, 1));
};


/**
 * @param {!Array<!proto.vtv1.InventorySupplyPlanPurchaseOrder>} value
 * @return {!proto.vtv1.NodeListPurchaseOrdersForInventoryResponse} returns this
*/
proto.vtv1.NodeListPurchaseOrdersForInventoryResponse.prototype.setPurchaseordersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.vtv1.InventorySupplyPlanPurchaseOrder=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.InventorySupplyPlanPurchaseOrder}
 */
proto.vtv1.NodeListPurchaseOrdersForInventoryResponse.prototype.addPurchaseorders = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.vtv1.InventorySupplyPlanPurchaseOrder, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.NodeListPurchaseOrdersForInventoryResponse} returns this
 */
proto.vtv1.NodeListPurchaseOrdersForInventoryResponse.prototype.clearPurchaseordersList = function() {
  return this.setPurchaseordersList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.ConsumerOrderWarningCountsResponse.repeatedFields_ = [1,2,3,4,5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.ConsumerOrderWarningCountsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.ConsumerOrderWarningCountsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.ConsumerOrderWarningCountsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.ConsumerOrderWarningCountsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    oposPendingList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    waitingForSupplierList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    oposNoSpaceList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    supplyPlansNoCarriersList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
    ordersWithErrorsList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.ConsumerOrderWarningCountsResponse}
 */
proto.vtv1.ConsumerOrderWarningCountsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.ConsumerOrderWarningCountsResponse;
  return proto.vtv1.ConsumerOrderWarningCountsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.ConsumerOrderWarningCountsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.ConsumerOrderWarningCountsResponse}
 */
proto.vtv1.ConsumerOrderWarningCountsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addOposPending(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addWaitingForSupplier(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addOposNoSpace(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.addSupplyPlansNoCarriers(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.addOrdersWithErrors(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.ConsumerOrderWarningCountsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.ConsumerOrderWarningCountsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.ConsumerOrderWarningCountsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.ConsumerOrderWarningCountsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOposPendingList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
  f = message.getWaitingForSupplierList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
  f = message.getOposNoSpaceList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
  f = message.getSupplyPlansNoCarriersList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      4,
      f
    );
  }
  f = message.getOrdersWithErrorsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      5,
      f
    );
  }
};


/**
 * repeated string opos_pending = 1;
 * @return {!Array<string>}
 */
proto.vtv1.ConsumerOrderWarningCountsResponse.prototype.getOposPendingList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.vtv1.ConsumerOrderWarningCountsResponse} returns this
 */
proto.vtv1.ConsumerOrderWarningCountsResponse.prototype.setOposPendingList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.vtv1.ConsumerOrderWarningCountsResponse} returns this
 */
proto.vtv1.ConsumerOrderWarningCountsResponse.prototype.addOposPending = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.ConsumerOrderWarningCountsResponse} returns this
 */
proto.vtv1.ConsumerOrderWarningCountsResponse.prototype.clearOposPendingList = function() {
  return this.setOposPendingList([]);
};


/**
 * repeated string waiting_for_supplier = 2;
 * @return {!Array<string>}
 */
proto.vtv1.ConsumerOrderWarningCountsResponse.prototype.getWaitingForSupplierList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.vtv1.ConsumerOrderWarningCountsResponse} returns this
 */
proto.vtv1.ConsumerOrderWarningCountsResponse.prototype.setWaitingForSupplierList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.vtv1.ConsumerOrderWarningCountsResponse} returns this
 */
proto.vtv1.ConsumerOrderWarningCountsResponse.prototype.addWaitingForSupplier = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.ConsumerOrderWarningCountsResponse} returns this
 */
proto.vtv1.ConsumerOrderWarningCountsResponse.prototype.clearWaitingForSupplierList = function() {
  return this.setWaitingForSupplierList([]);
};


/**
 * repeated string opos_no_space = 3;
 * @return {!Array<string>}
 */
proto.vtv1.ConsumerOrderWarningCountsResponse.prototype.getOposNoSpaceList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.vtv1.ConsumerOrderWarningCountsResponse} returns this
 */
proto.vtv1.ConsumerOrderWarningCountsResponse.prototype.setOposNoSpaceList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.vtv1.ConsumerOrderWarningCountsResponse} returns this
 */
proto.vtv1.ConsumerOrderWarningCountsResponse.prototype.addOposNoSpace = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.ConsumerOrderWarningCountsResponse} returns this
 */
proto.vtv1.ConsumerOrderWarningCountsResponse.prototype.clearOposNoSpaceList = function() {
  return this.setOposNoSpaceList([]);
};


/**
 * repeated string supply_plans_no_carriers = 4;
 * @return {!Array<string>}
 */
proto.vtv1.ConsumerOrderWarningCountsResponse.prototype.getSupplyPlansNoCarriersList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.vtv1.ConsumerOrderWarningCountsResponse} returns this
 */
proto.vtv1.ConsumerOrderWarningCountsResponse.prototype.setSupplyPlansNoCarriersList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.vtv1.ConsumerOrderWarningCountsResponse} returns this
 */
proto.vtv1.ConsumerOrderWarningCountsResponse.prototype.addSupplyPlansNoCarriers = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.ConsumerOrderWarningCountsResponse} returns this
 */
proto.vtv1.ConsumerOrderWarningCountsResponse.prototype.clearSupplyPlansNoCarriersList = function() {
  return this.setSupplyPlansNoCarriersList([]);
};


/**
 * repeated string orders_with_errors = 5;
 * @return {!Array<string>}
 */
proto.vtv1.ConsumerOrderWarningCountsResponse.prototype.getOrdersWithErrorsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.vtv1.ConsumerOrderWarningCountsResponse} returns this
 */
proto.vtv1.ConsumerOrderWarningCountsResponse.prototype.setOrdersWithErrorsList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.vtv1.ConsumerOrderWarningCountsResponse} returns this
 */
proto.vtv1.ConsumerOrderWarningCountsResponse.prototype.addOrdersWithErrors = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.ConsumerOrderWarningCountsResponse} returns this
 */
proto.vtv1.ConsumerOrderWarningCountsResponse.prototype.clearOrdersWithErrorsList = function() {
  return this.setOrdersWithErrorsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.BulkUploadSiteRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.BulkUploadSiteRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.BulkUploadSiteRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.BulkUploadSiteRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.BulkUploadSiteRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    sitesList: jspb.Message.toObjectList(msg.getSitesList(),
    proto.vtv1.BulkUploadSite.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.BulkUploadSiteRequest}
 */
proto.vtv1.BulkUploadSiteRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.BulkUploadSiteRequest;
  return proto.vtv1.BulkUploadSiteRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.BulkUploadSiteRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.BulkUploadSiteRequest}
 */
proto.vtv1.BulkUploadSiteRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.vtv1.BulkUploadSite;
      reader.readMessage(value,proto.vtv1.BulkUploadSite.deserializeBinaryFromReader);
      msg.addSites(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.BulkUploadSiteRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.BulkUploadSiteRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.BulkUploadSiteRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.BulkUploadSiteRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSitesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.vtv1.BulkUploadSite.serializeBinaryToWriter
    );
  }
};


/**
 * repeated BulkUploadSite sites = 1;
 * @return {!Array<!proto.vtv1.BulkUploadSite>}
 */
proto.vtv1.BulkUploadSiteRequest.prototype.getSitesList = function() {
  return /** @type{!Array<!proto.vtv1.BulkUploadSite>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vtv1.BulkUploadSite, 1));
};


/**
 * @param {!Array<!proto.vtv1.BulkUploadSite>} value
 * @return {!proto.vtv1.BulkUploadSiteRequest} returns this
*/
proto.vtv1.BulkUploadSiteRequest.prototype.setSitesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.vtv1.BulkUploadSite=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.BulkUploadSite}
 */
proto.vtv1.BulkUploadSiteRequest.prototype.addSites = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.vtv1.BulkUploadSite, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.BulkUploadSiteRequest} returns this
 */
proto.vtv1.BulkUploadSiteRequest.prototype.clearSitesList = function() {
  return this.setSitesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.ValidationErrorMessage.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.ValidationErrorMessage.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.ValidationErrorMessage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.ValidationErrorMessage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.ValidationErrorMessage.toObject = function(includeInstance, msg) {
  var f, obj = {
    row: jspb.Message.getFieldWithDefault(msg, 1, 0),
    errorMessagesList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.ValidationErrorMessage}
 */
proto.vtv1.ValidationErrorMessage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.ValidationErrorMessage;
  return proto.vtv1.ValidationErrorMessage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.ValidationErrorMessage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.ValidationErrorMessage}
 */
proto.vtv1.ValidationErrorMessage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRow(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addErrorMessages(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.ValidationErrorMessage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.ValidationErrorMessage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.ValidationErrorMessage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.ValidationErrorMessage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRow();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getErrorMessagesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
};


/**
 * optional int32 row = 1;
 * @return {number}
 */
proto.vtv1.ValidationErrorMessage.prototype.getRow = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.ValidationErrorMessage} returns this
 */
proto.vtv1.ValidationErrorMessage.prototype.setRow = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * repeated string error_messages = 2;
 * @return {!Array<string>}
 */
proto.vtv1.ValidationErrorMessage.prototype.getErrorMessagesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.vtv1.ValidationErrorMessage} returns this
 */
proto.vtv1.ValidationErrorMessage.prototype.setErrorMessagesList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.vtv1.ValidationErrorMessage} returns this
 */
proto.vtv1.ValidationErrorMessage.prototype.addErrorMessages = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.ValidationErrorMessage} returns this
 */
proto.vtv1.ValidationErrorMessage.prototype.clearErrorMessagesList = function() {
  return this.setErrorMessagesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.BulkUploadSiteResponse.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.BulkUploadSiteResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.BulkUploadSiteResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.BulkUploadSiteResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.BulkUploadSiteResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    message: jspb.Message.getFieldWithDefault(msg, 1, ""),
    totalImported: jspb.Message.getFieldWithDefault(msg, 2, 0),
    validationErrorMessageList: jspb.Message.toObjectList(msg.getValidationErrorMessageList(),
    proto.vtv1.ValidationErrorMessage.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.BulkUploadSiteResponse}
 */
proto.vtv1.BulkUploadSiteResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.BulkUploadSiteResponse;
  return proto.vtv1.BulkUploadSiteResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.BulkUploadSiteResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.BulkUploadSiteResponse}
 */
proto.vtv1.BulkUploadSiteResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalImported(value);
      break;
    case 3:
      var value = new proto.vtv1.ValidationErrorMessage;
      reader.readMessage(value,proto.vtv1.ValidationErrorMessage.deserializeBinaryFromReader);
      msg.addValidationErrorMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.BulkUploadSiteResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.BulkUploadSiteResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.BulkUploadSiteResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.BulkUploadSiteResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTotalImported();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getValidationErrorMessageList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.vtv1.ValidationErrorMessage.serializeBinaryToWriter
    );
  }
};


/**
 * optional string message = 1;
 * @return {string}
 */
proto.vtv1.BulkUploadSiteResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.BulkUploadSiteResponse} returns this
 */
proto.vtv1.BulkUploadSiteResponse.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 total_imported = 2;
 * @return {number}
 */
proto.vtv1.BulkUploadSiteResponse.prototype.getTotalImported = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.BulkUploadSiteResponse} returns this
 */
proto.vtv1.BulkUploadSiteResponse.prototype.setTotalImported = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * repeated ValidationErrorMessage validation_error_message = 3;
 * @return {!Array<!proto.vtv1.ValidationErrorMessage>}
 */
proto.vtv1.BulkUploadSiteResponse.prototype.getValidationErrorMessageList = function() {
  return /** @type{!Array<!proto.vtv1.ValidationErrorMessage>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vtv1.ValidationErrorMessage, 3));
};


/**
 * @param {!Array<!proto.vtv1.ValidationErrorMessage>} value
 * @return {!proto.vtv1.BulkUploadSiteResponse} returns this
*/
proto.vtv1.BulkUploadSiteResponse.prototype.setValidationErrorMessageList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.vtv1.ValidationErrorMessage=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.ValidationErrorMessage}
 */
proto.vtv1.BulkUploadSiteResponse.prototype.addValidationErrorMessage = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.vtv1.ValidationErrorMessage, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.BulkUploadSiteResponse} returns this
 */
proto.vtv1.BulkUploadSiteResponse.prototype.clearValidationErrorMessageList = function() {
  return this.setValidationErrorMessageList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.BulkUploadSite.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.BulkUploadSite.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.BulkUploadSite} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.BulkUploadSite.toObject = function(includeInstance, msg) {
  var f, obj = {
    siteName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    siteAddress: (f = msg.getSiteAddress()) && proto_vorto_types_types_pb.NullableString.toObject(includeInstance, f),
    latitude: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    longitude: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    loadingBays: jspb.Message.getFieldWithDefault(msg, 5, 0),
    salesOrder: (f = msg.getSalesOrder()) && proto_vorto_types_types_pb.NullableString.toObject(includeInstance, f),
    radius: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    facilityCode: (f = msg.getFacilityCode()) && proto_vorto_types_types_pb.NullableString.toObject(includeInstance, f),
    businessUnit: (f = msg.getBusinessUnit()) && proto_vorto_types_types_pb.NullableString.toObject(includeInstance, f),
    costCenter: (f = msg.getCostCenter()) && proto_vorto_types_types_pb.NullableString.toObject(includeInstance, f),
    directions: (f = msg.getDirections()) && proto_vorto_types_types_pb.NullableString.toObject(includeInstance, f),
    loadingInstructions: (f = msg.getLoadingInstructions()) && proto_vorto_types_types_pb.NullableString.toObject(includeInstance, f),
    districtName: (f = msg.getDistrictName()) && proto_vorto_types_types_pb.NullableString.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.BulkUploadSite}
 */
proto.vtv1.BulkUploadSite.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.BulkUploadSite;
  return proto.vtv1.BulkUploadSite.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.BulkUploadSite} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.BulkUploadSite}
 */
proto.vtv1.BulkUploadSite.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSiteName(value);
      break;
    case 2:
      var value = new proto_vorto_types_types_pb.NullableString;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableString.deserializeBinaryFromReader);
      msg.setSiteAddress(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLatitude(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLongitude(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLoadingBays(value);
      break;
    case 6:
      var value = new proto_vorto_types_types_pb.NullableString;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableString.deserializeBinaryFromReader);
      msg.setSalesOrder(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setRadius(value);
      break;
    case 8:
      var value = new proto_vorto_types_types_pb.NullableString;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableString.deserializeBinaryFromReader);
      msg.setFacilityCode(value);
      break;
    case 9:
      var value = new proto_vorto_types_types_pb.NullableString;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableString.deserializeBinaryFromReader);
      msg.setBusinessUnit(value);
      break;
    case 10:
      var value = new proto_vorto_types_types_pb.NullableString;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableString.deserializeBinaryFromReader);
      msg.setCostCenter(value);
      break;
    case 11:
      var value = new proto_vorto_types_types_pb.NullableString;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableString.deserializeBinaryFromReader);
      msg.setDirections(value);
      break;
    case 12:
      var value = new proto_vorto_types_types_pb.NullableString;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableString.deserializeBinaryFromReader);
      msg.setLoadingInstructions(value);
      break;
    case 13:
      var value = new proto_vorto_types_types_pb.NullableString;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableString.deserializeBinaryFromReader);
      msg.setDistrictName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.BulkUploadSite.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.BulkUploadSite.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.BulkUploadSite} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.BulkUploadSite.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSiteName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSiteAddress();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_vorto_types_types_pb.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getLatitude();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getLongitude();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getLoadingBays();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getSalesOrder();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto_vorto_types_types_pb.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getRadius();
  if (f !== 0.0) {
    writer.writeDouble(
      7,
      f
    );
  }
  f = message.getFacilityCode();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto_vorto_types_types_pb.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getBusinessUnit();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto_vorto_types_types_pb.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getCostCenter();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto_vorto_types_types_pb.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getDirections();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto_vorto_types_types_pb.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getLoadingInstructions();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      proto_vorto_types_types_pb.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getDistrictName();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      proto_vorto_types_types_pb.NullableString.serializeBinaryToWriter
    );
  }
};


/**
 * optional string site_name = 1;
 * @return {string}
 */
proto.vtv1.BulkUploadSite.prototype.getSiteName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.BulkUploadSite} returns this
 */
proto.vtv1.BulkUploadSite.prototype.setSiteName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional NullableString site_address = 2;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.BulkUploadSite.prototype.getSiteAddress = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableString, 2));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.BulkUploadSite} returns this
*/
proto.vtv1.BulkUploadSite.prototype.setSiteAddress = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.BulkUploadSite} returns this
 */
proto.vtv1.BulkUploadSite.prototype.clearSiteAddress = function() {
  return this.setSiteAddress(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.BulkUploadSite.prototype.hasSiteAddress = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional double latitude = 3;
 * @return {number}
 */
proto.vtv1.BulkUploadSite.prototype.getLatitude = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.BulkUploadSite} returns this
 */
proto.vtv1.BulkUploadSite.prototype.setLatitude = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional double longitude = 4;
 * @return {number}
 */
proto.vtv1.BulkUploadSite.prototype.getLongitude = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.BulkUploadSite} returns this
 */
proto.vtv1.BulkUploadSite.prototype.setLongitude = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional int64 loading_bays = 5;
 * @return {number}
 */
proto.vtv1.BulkUploadSite.prototype.getLoadingBays = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.BulkUploadSite} returns this
 */
proto.vtv1.BulkUploadSite.prototype.setLoadingBays = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional NullableString sales_order = 6;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.BulkUploadSite.prototype.getSalesOrder = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableString, 6));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.BulkUploadSite} returns this
*/
proto.vtv1.BulkUploadSite.prototype.setSalesOrder = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.BulkUploadSite} returns this
 */
proto.vtv1.BulkUploadSite.prototype.clearSalesOrder = function() {
  return this.setSalesOrder(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.BulkUploadSite.prototype.hasSalesOrder = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional double radius = 7;
 * @return {number}
 */
proto.vtv1.BulkUploadSite.prototype.getRadius = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.BulkUploadSite} returns this
 */
proto.vtv1.BulkUploadSite.prototype.setRadius = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional NullableString facility_code = 8;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.BulkUploadSite.prototype.getFacilityCode = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableString, 8));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.BulkUploadSite} returns this
*/
proto.vtv1.BulkUploadSite.prototype.setFacilityCode = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.BulkUploadSite} returns this
 */
proto.vtv1.BulkUploadSite.prototype.clearFacilityCode = function() {
  return this.setFacilityCode(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.BulkUploadSite.prototype.hasFacilityCode = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional NullableString business_unit = 9;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.BulkUploadSite.prototype.getBusinessUnit = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableString, 9));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.BulkUploadSite} returns this
*/
proto.vtv1.BulkUploadSite.prototype.setBusinessUnit = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.BulkUploadSite} returns this
 */
proto.vtv1.BulkUploadSite.prototype.clearBusinessUnit = function() {
  return this.setBusinessUnit(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.BulkUploadSite.prototype.hasBusinessUnit = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional NullableString cost_center = 10;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.BulkUploadSite.prototype.getCostCenter = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableString, 10));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.BulkUploadSite} returns this
*/
proto.vtv1.BulkUploadSite.prototype.setCostCenter = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.BulkUploadSite} returns this
 */
proto.vtv1.BulkUploadSite.prototype.clearCostCenter = function() {
  return this.setCostCenter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.BulkUploadSite.prototype.hasCostCenter = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional NullableString directions = 11;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.BulkUploadSite.prototype.getDirections = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableString, 11));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.BulkUploadSite} returns this
*/
proto.vtv1.BulkUploadSite.prototype.setDirections = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.BulkUploadSite} returns this
 */
proto.vtv1.BulkUploadSite.prototype.clearDirections = function() {
  return this.setDirections(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.BulkUploadSite.prototype.hasDirections = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional NullableString loading_instructions = 12;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.BulkUploadSite.prototype.getLoadingInstructions = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableString, 12));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.BulkUploadSite} returns this
*/
proto.vtv1.BulkUploadSite.prototype.setLoadingInstructions = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.BulkUploadSite} returns this
 */
proto.vtv1.BulkUploadSite.prototype.clearLoadingInstructions = function() {
  return this.setLoadingInstructions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.BulkUploadSite.prototype.hasLoadingInstructions = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional NullableString district_name = 13;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.BulkUploadSite.prototype.getDistrictName = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableString, 13));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.BulkUploadSite} returns this
*/
proto.vtv1.BulkUploadSite.prototype.setDistrictName = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.BulkUploadSite} returns this
 */
proto.vtv1.BulkUploadSite.prototype.clearDistrictName = function() {
  return this.setDistrictName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.BulkUploadSite.prototype.hasDistrictName = function() {
  return jspb.Message.getField(this, 13) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.BulkUploadInventoryRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.BulkUploadInventoryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.BulkUploadInventoryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.BulkUploadInventoryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.BulkUploadInventoryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    inventoriesList: jspb.Message.toObjectList(msg.getInventoriesList(),
    proto.vtv1.BulkUploadInventory.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.BulkUploadInventoryRequest}
 */
proto.vtv1.BulkUploadInventoryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.BulkUploadInventoryRequest;
  return proto.vtv1.BulkUploadInventoryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.BulkUploadInventoryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.BulkUploadInventoryRequest}
 */
proto.vtv1.BulkUploadInventoryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.vtv1.BulkUploadInventory;
      reader.readMessage(value,proto.vtv1.BulkUploadInventory.deserializeBinaryFromReader);
      msg.addInventories(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.BulkUploadInventoryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.BulkUploadInventoryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.BulkUploadInventoryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.BulkUploadInventoryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInventoriesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.vtv1.BulkUploadInventory.serializeBinaryToWriter
    );
  }
};


/**
 * repeated BulkUploadInventory inventories = 1;
 * @return {!Array<!proto.vtv1.BulkUploadInventory>}
 */
proto.vtv1.BulkUploadInventoryRequest.prototype.getInventoriesList = function() {
  return /** @type{!Array<!proto.vtv1.BulkUploadInventory>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vtv1.BulkUploadInventory, 1));
};


/**
 * @param {!Array<!proto.vtv1.BulkUploadInventory>} value
 * @return {!proto.vtv1.BulkUploadInventoryRequest} returns this
*/
proto.vtv1.BulkUploadInventoryRequest.prototype.setInventoriesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.vtv1.BulkUploadInventory=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.BulkUploadInventory}
 */
proto.vtv1.BulkUploadInventoryRequest.prototype.addInventories = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.vtv1.BulkUploadInventory, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.BulkUploadInventoryRequest} returns this
 */
proto.vtv1.BulkUploadInventoryRequest.prototype.clearInventoriesList = function() {
  return this.setInventoriesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.BulkUploadInventoryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.BulkUploadInventoryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.BulkUploadInventoryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.BulkUploadInventoryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    message: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.BulkUploadInventoryResponse}
 */
proto.vtv1.BulkUploadInventoryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.BulkUploadInventoryResponse;
  return proto.vtv1.BulkUploadInventoryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.BulkUploadInventoryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.BulkUploadInventoryResponse}
 */
proto.vtv1.BulkUploadInventoryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.BulkUploadInventoryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.BulkUploadInventoryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.BulkUploadInventoryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.BulkUploadInventoryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string message = 1;
 * @return {string}
 */
proto.vtv1.BulkUploadInventoryResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.BulkUploadInventoryResponse} returns this
 */
proto.vtv1.BulkUploadInventoryResponse.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.BulkUploadInventory.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.BulkUploadInventory.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.BulkUploadInventory} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.BulkUploadInventory.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    facilityCode: jspb.Message.getFieldWithDefault(msg, 2, ""),
    rawMaterial: jspb.Message.getFieldWithDefault(msg, 3, ""),
    capacity: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    defaultLoadQuantity: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    defaultUnloadingMinutes: jspb.Message.getFieldWithDefault(msg, 6, 0),
    trailerTypes: jspb.Message.getFieldWithDefault(msg, 7, ""),
    driverCertifications: jspb.Message.getFieldWithDefault(msg, 8, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.BulkUploadInventory}
 */
proto.vtv1.BulkUploadInventory.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.BulkUploadInventory;
  return proto.vtv1.BulkUploadInventory.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.BulkUploadInventory} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.BulkUploadInventory}
 */
proto.vtv1.BulkUploadInventory.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFacilityCode(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setRawMaterial(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setCapacity(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setDefaultLoadQuantity(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDefaultUnloadingMinutes(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setTrailerTypes(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setDriverCertifications(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.BulkUploadInventory.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.BulkUploadInventory.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.BulkUploadInventory} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.BulkUploadInventory.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFacilityCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getRawMaterial();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCapacity();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getDefaultLoadQuantity();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getDefaultUnloadingMinutes();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getTrailerTypes();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getDriverCertifications();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.vtv1.BulkUploadInventory.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.BulkUploadInventory} returns this
 */
proto.vtv1.BulkUploadInventory.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string facility_code = 2;
 * @return {string}
 */
proto.vtv1.BulkUploadInventory.prototype.getFacilityCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.BulkUploadInventory} returns this
 */
proto.vtv1.BulkUploadInventory.prototype.setFacilityCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string raw_material = 3;
 * @return {string}
 */
proto.vtv1.BulkUploadInventory.prototype.getRawMaterial = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.BulkUploadInventory} returns this
 */
proto.vtv1.BulkUploadInventory.prototype.setRawMaterial = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional double capacity = 4;
 * @return {number}
 */
proto.vtv1.BulkUploadInventory.prototype.getCapacity = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.BulkUploadInventory} returns this
 */
proto.vtv1.BulkUploadInventory.prototype.setCapacity = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional double default_load_quantity = 5;
 * @return {number}
 */
proto.vtv1.BulkUploadInventory.prototype.getDefaultLoadQuantity = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.BulkUploadInventory} returns this
 */
proto.vtv1.BulkUploadInventory.prototype.setDefaultLoadQuantity = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional int32 default_unloading_minutes = 6;
 * @return {number}
 */
proto.vtv1.BulkUploadInventory.prototype.getDefaultUnloadingMinutes = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.BulkUploadInventory} returns this
 */
proto.vtv1.BulkUploadInventory.prototype.setDefaultUnloadingMinutes = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional string trailer_types = 7;
 * @return {string}
 */
proto.vtv1.BulkUploadInventory.prototype.getTrailerTypes = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.BulkUploadInventory} returns this
 */
proto.vtv1.BulkUploadInventory.prototype.setTrailerTypes = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string driver_certifications = 8;
 * @return {string}
 */
proto.vtv1.BulkUploadInventory.prototype.getDriverCertifications = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.BulkUploadInventory} returns this
 */
proto.vtv1.BulkUploadInventory.prototype.setDriverCertifications = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.CreateFinishedGoodOrderRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.CreateFinishedGoodOrderRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.CreateFinishedGoodOrderRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.CreateFinishedGoodOrderRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    productId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    quantity: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    originSiteId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    destinationSiteId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    deliveryWindowStartsAt: (f = msg.getDeliveryWindowStartsAt()) && proto_vorto_types_types_pb.NullableTime.toObject(includeInstance, f),
    deliveryWindowMinutes: (f = msg.getDeliveryWindowMinutes()) && proto_vorto_types_types_pb.NullableInt64.toObject(includeInstance, f),
    carrierContractId: (f = msg.getCarrierContractId()) && proto_vorto_types_types_pb.NullableString.toObject(includeInstance, f),
    quantityOfTruckloads: jspb.Message.getFieldWithDefault(msg, 8, 0),
    description: (f = msg.getDescription()) && proto_vorto_types_types_pb.NullableString.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.CreateFinishedGoodOrderRequest}
 */
proto.vtv1.CreateFinishedGoodOrderRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.CreateFinishedGoodOrderRequest;
  return proto.vtv1.CreateFinishedGoodOrderRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.CreateFinishedGoodOrderRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.CreateFinishedGoodOrderRequest}
 */
proto.vtv1.CreateFinishedGoodOrderRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setQuantity(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setOriginSiteId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDestinationSiteId(value);
      break;
    case 5:
      var value = new proto_vorto_types_types_pb.NullableTime;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableTime.deserializeBinaryFromReader);
      msg.setDeliveryWindowStartsAt(value);
      break;
    case 6:
      var value = new proto_vorto_types_types_pb.NullableInt64;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableInt64.deserializeBinaryFromReader);
      msg.setDeliveryWindowMinutes(value);
      break;
    case 7:
      var value = new proto_vorto_types_types_pb.NullableString;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableString.deserializeBinaryFromReader);
      msg.setCarrierContractId(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setQuantityOfTruckloads(value);
      break;
    case 9:
      var value = new proto_vorto_types_types_pb.NullableString;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableString.deserializeBinaryFromReader);
      msg.setDescription(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.CreateFinishedGoodOrderRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.CreateFinishedGoodOrderRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.CreateFinishedGoodOrderRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.CreateFinishedGoodOrderRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProductId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getQuantity();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getOriginSiteId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDestinationSiteId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getDeliveryWindowStartsAt();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto_vorto_types_types_pb.NullableTime.serializeBinaryToWriter
    );
  }
  f = message.getDeliveryWindowMinutes();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto_vorto_types_types_pb.NullableInt64.serializeBinaryToWriter
    );
  }
  f = message.getCarrierContractId();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto_vorto_types_types_pb.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getQuantityOfTruckloads();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
  f = message.getDescription();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto_vorto_types_types_pb.NullableString.serializeBinaryToWriter
    );
  }
};


/**
 * optional string product_id = 1;
 * @return {string}
 */
proto.vtv1.CreateFinishedGoodOrderRequest.prototype.getProductId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.CreateFinishedGoodOrderRequest} returns this
 */
proto.vtv1.CreateFinishedGoodOrderRequest.prototype.setProductId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional double quantity = 2;
 * @return {number}
 */
proto.vtv1.CreateFinishedGoodOrderRequest.prototype.getQuantity = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.CreateFinishedGoodOrderRequest} returns this
 */
proto.vtv1.CreateFinishedGoodOrderRequest.prototype.setQuantity = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional string origin_site_id = 3;
 * @return {string}
 */
proto.vtv1.CreateFinishedGoodOrderRequest.prototype.getOriginSiteId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.CreateFinishedGoodOrderRequest} returns this
 */
proto.vtv1.CreateFinishedGoodOrderRequest.prototype.setOriginSiteId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string destination_site_id = 4;
 * @return {string}
 */
proto.vtv1.CreateFinishedGoodOrderRequest.prototype.getDestinationSiteId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.CreateFinishedGoodOrderRequest} returns this
 */
proto.vtv1.CreateFinishedGoodOrderRequest.prototype.setDestinationSiteId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional NullableTime delivery_window_starts_at = 5;
 * @return {?proto.vtv1.NullableTime}
 */
proto.vtv1.CreateFinishedGoodOrderRequest.prototype.getDeliveryWindowStartsAt = function() {
  return /** @type{?proto.vtv1.NullableTime} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableTime, 5));
};


/**
 * @param {?proto.vtv1.NullableTime|undefined} value
 * @return {!proto.vtv1.CreateFinishedGoodOrderRequest} returns this
*/
proto.vtv1.CreateFinishedGoodOrderRequest.prototype.setDeliveryWindowStartsAt = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.CreateFinishedGoodOrderRequest} returns this
 */
proto.vtv1.CreateFinishedGoodOrderRequest.prototype.clearDeliveryWindowStartsAt = function() {
  return this.setDeliveryWindowStartsAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.CreateFinishedGoodOrderRequest.prototype.hasDeliveryWindowStartsAt = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional NullableInt64 delivery_window_minutes = 6;
 * @return {?proto.vtv1.NullableInt64}
 */
proto.vtv1.CreateFinishedGoodOrderRequest.prototype.getDeliveryWindowMinutes = function() {
  return /** @type{?proto.vtv1.NullableInt64} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableInt64, 6));
};


/**
 * @param {?proto.vtv1.NullableInt64|undefined} value
 * @return {!proto.vtv1.CreateFinishedGoodOrderRequest} returns this
*/
proto.vtv1.CreateFinishedGoodOrderRequest.prototype.setDeliveryWindowMinutes = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.CreateFinishedGoodOrderRequest} returns this
 */
proto.vtv1.CreateFinishedGoodOrderRequest.prototype.clearDeliveryWindowMinutes = function() {
  return this.setDeliveryWindowMinutes(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.CreateFinishedGoodOrderRequest.prototype.hasDeliveryWindowMinutes = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional NullableString carrier_contract_id = 7;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.CreateFinishedGoodOrderRequest.prototype.getCarrierContractId = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableString, 7));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.CreateFinishedGoodOrderRequest} returns this
*/
proto.vtv1.CreateFinishedGoodOrderRequest.prototype.setCarrierContractId = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.CreateFinishedGoodOrderRequest} returns this
 */
proto.vtv1.CreateFinishedGoodOrderRequest.prototype.clearCarrierContractId = function() {
  return this.setCarrierContractId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.CreateFinishedGoodOrderRequest.prototype.hasCarrierContractId = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional int64 quantity_of_truckloads = 8;
 * @return {number}
 */
proto.vtv1.CreateFinishedGoodOrderRequest.prototype.getQuantityOfTruckloads = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.CreateFinishedGoodOrderRequest} returns this
 */
proto.vtv1.CreateFinishedGoodOrderRequest.prototype.setQuantityOfTruckloads = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional NullableString description = 9;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.CreateFinishedGoodOrderRequest.prototype.getDescription = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableString, 9));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.CreateFinishedGoodOrderRequest} returns this
*/
proto.vtv1.CreateFinishedGoodOrderRequest.prototype.setDescription = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.CreateFinishedGoodOrderRequest} returns this
 */
proto.vtv1.CreateFinishedGoodOrderRequest.prototype.clearDescription = function() {
  return this.setDescription(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.CreateFinishedGoodOrderRequest.prototype.hasDescription = function() {
  return jspb.Message.getField(this, 9) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.CreateFinishedGoodOrderResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.CreateFinishedGoodOrderResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.CreateFinishedGoodOrderResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.CreateFinishedGoodOrderResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.CreateFinishedGoodOrderResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    orderIdsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.CreateFinishedGoodOrderResponse}
 */
proto.vtv1.CreateFinishedGoodOrderResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.CreateFinishedGoodOrderResponse;
  return proto.vtv1.CreateFinishedGoodOrderResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.CreateFinishedGoodOrderResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.CreateFinishedGoodOrderResponse}
 */
proto.vtv1.CreateFinishedGoodOrderResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addOrderIds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.CreateFinishedGoodOrderResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.CreateFinishedGoodOrderResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.CreateFinishedGoodOrderResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.CreateFinishedGoodOrderResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrderIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
};


/**
 * repeated string order_ids = 1;
 * @return {!Array<string>}
 */
proto.vtv1.CreateFinishedGoodOrderResponse.prototype.getOrderIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.vtv1.CreateFinishedGoodOrderResponse} returns this
 */
proto.vtv1.CreateFinishedGoodOrderResponse.prototype.setOrderIdsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.vtv1.CreateFinishedGoodOrderResponse} returns this
 */
proto.vtv1.CreateFinishedGoodOrderResponse.prototype.addOrderIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.CreateFinishedGoodOrderResponse} returns this
 */
proto.vtv1.CreateFinishedGoodOrderResponse.prototype.clearOrderIdsList = function() {
  return this.setOrderIdsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.NodeListFinishedGoodsProductsInventoryForSiteResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.NodeListFinishedGoodsProductsInventoryForSiteResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.NodeListFinishedGoodsProductsInventoryForSiteResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.NodeListFinishedGoodsProductsInventoryForSiteResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.NodeListFinishedGoodsProductsInventoryForSiteResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    productsList: jspb.Message.toObjectList(msg.getProductsList(),
    proto_vorto_types_types_pb.FlatProduct.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.NodeListFinishedGoodsProductsInventoryForSiteResponse}
 */
proto.vtv1.NodeListFinishedGoodsProductsInventoryForSiteResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.NodeListFinishedGoodsProductsInventoryForSiteResponse;
  return proto.vtv1.NodeListFinishedGoodsProductsInventoryForSiteResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.NodeListFinishedGoodsProductsInventoryForSiteResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.NodeListFinishedGoodsProductsInventoryForSiteResponse}
 */
proto.vtv1.NodeListFinishedGoodsProductsInventoryForSiteResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_vorto_types_types_pb.FlatProduct;
      reader.readMessage(value,proto_vorto_types_types_pb.FlatProduct.deserializeBinaryFromReader);
      msg.addProducts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.NodeListFinishedGoodsProductsInventoryForSiteResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.NodeListFinishedGoodsProductsInventoryForSiteResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.NodeListFinishedGoodsProductsInventoryForSiteResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.NodeListFinishedGoodsProductsInventoryForSiteResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProductsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto_vorto_types_types_pb.FlatProduct.serializeBinaryToWriter
    );
  }
};


/**
 * repeated FlatProduct products = 1;
 * @return {!Array<!proto.vtv1.FlatProduct>}
 */
proto.vtv1.NodeListFinishedGoodsProductsInventoryForSiteResponse.prototype.getProductsList = function() {
  return /** @type{!Array<!proto.vtv1.FlatProduct>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_vorto_types_types_pb.FlatProduct, 1));
};


/**
 * @param {!Array<!proto.vtv1.FlatProduct>} value
 * @return {!proto.vtv1.NodeListFinishedGoodsProductsInventoryForSiteResponse} returns this
*/
proto.vtv1.NodeListFinishedGoodsProductsInventoryForSiteResponse.prototype.setProductsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.vtv1.FlatProduct=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.FlatProduct}
 */
proto.vtv1.NodeListFinishedGoodsProductsInventoryForSiteResponse.prototype.addProducts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.vtv1.FlatProduct, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.NodeListFinishedGoodsProductsInventoryForSiteResponse} returns this
 */
proto.vtv1.NodeListFinishedGoodsProductsInventoryForSiteResponse.prototype.clearProductsList = function() {
  return this.setProductsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.UploadedOrderData.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.UploadedOrderData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.UploadedOrderData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.UploadedOrderData.toObject = function(includeInstance, msg) {
  var f, obj = {
    productName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    supplierName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    purchaseOrderName: (f = msg.getPurchaseOrderName()) && proto_vorto_types_types_pb.NullableString.toObject(includeInstance, f),
    quantity: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    originSiteName: jspb.Message.getFieldWithDefault(msg, 5, ""),
    destinationSiteName: jspb.Message.getFieldWithDefault(msg, 6, ""),
    deliveryWindowStartsAt: jspb.Message.getFieldWithDefault(msg, 7, ""),
    deliveryWindowMinutes: jspb.Message.getFieldWithDefault(msg, 8, 0),
    quantityOfTruckloads: jspb.Message.getFieldWithDefault(msg, 10, 0),
    description: (f = msg.getDescription()) && proto_vorto_types_types_pb.NullableString.toObject(includeInstance, f),
    secondaryProductName: (f = msg.getSecondaryProductName()) && proto_vorto_types_types_pb.NullableString.toObject(includeInstance, f),
    secondaryProductQuantity: (f = msg.getSecondaryProductQuantity()) && proto_vorto_types_types_pb.NullableDouble.toObject(includeInstance, f),
    inventoryName: (f = msg.getInventoryName()) && proto_vorto_types_types_pb.NullableString.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.UploadedOrderData}
 */
proto.vtv1.UploadedOrderData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.UploadedOrderData;
  return proto.vtv1.UploadedOrderData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.UploadedOrderData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.UploadedOrderData}
 */
proto.vtv1.UploadedOrderData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSupplierName(value);
      break;
    case 3:
      var value = new proto_vorto_types_types_pb.NullableString;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableString.deserializeBinaryFromReader);
      msg.setPurchaseOrderName(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setQuantity(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setOriginSiteName(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setDestinationSiteName(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeliveryWindowStartsAt(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDeliveryWindowMinutes(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setQuantityOfTruckloads(value);
      break;
    case 11:
      var value = new proto_vorto_types_types_pb.NullableString;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableString.deserializeBinaryFromReader);
      msg.setDescription(value);
      break;
    case 12:
      var value = new proto_vorto_types_types_pb.NullableString;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableString.deserializeBinaryFromReader);
      msg.setSecondaryProductName(value);
      break;
    case 13:
      var value = new proto_vorto_types_types_pb.NullableDouble;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableDouble.deserializeBinaryFromReader);
      msg.setSecondaryProductQuantity(value);
      break;
    case 14:
      var value = new proto_vorto_types_types_pb.NullableString;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableString.deserializeBinaryFromReader);
      msg.setInventoryName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.UploadedOrderData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.UploadedOrderData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.UploadedOrderData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.UploadedOrderData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProductName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSupplierName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPurchaseOrderName();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto_vorto_types_types_pb.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getQuantity();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getOriginSiteName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getDestinationSiteName();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getDeliveryWindowStartsAt();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getDeliveryWindowMinutes();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
  f = message.getQuantityOfTruckloads();
  if (f !== 0) {
    writer.writeInt64(
      10,
      f
    );
  }
  f = message.getDescription();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto_vorto_types_types_pb.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getSecondaryProductName();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      proto_vorto_types_types_pb.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getSecondaryProductQuantity();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      proto_vorto_types_types_pb.NullableDouble.serializeBinaryToWriter
    );
  }
  f = message.getInventoryName();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      proto_vorto_types_types_pb.NullableString.serializeBinaryToWriter
    );
  }
};


/**
 * optional string product_name = 1;
 * @return {string}
 */
proto.vtv1.UploadedOrderData.prototype.getProductName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.UploadedOrderData} returns this
 */
proto.vtv1.UploadedOrderData.prototype.setProductName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string supplier_name = 2;
 * @return {string}
 */
proto.vtv1.UploadedOrderData.prototype.getSupplierName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.UploadedOrderData} returns this
 */
proto.vtv1.UploadedOrderData.prototype.setSupplierName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional NullableString purchase_order_name = 3;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.UploadedOrderData.prototype.getPurchaseOrderName = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableString, 3));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.UploadedOrderData} returns this
*/
proto.vtv1.UploadedOrderData.prototype.setPurchaseOrderName = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.UploadedOrderData} returns this
 */
proto.vtv1.UploadedOrderData.prototype.clearPurchaseOrderName = function() {
  return this.setPurchaseOrderName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.UploadedOrderData.prototype.hasPurchaseOrderName = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional double quantity = 4;
 * @return {number}
 */
proto.vtv1.UploadedOrderData.prototype.getQuantity = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.UploadedOrderData} returns this
 */
proto.vtv1.UploadedOrderData.prototype.setQuantity = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional string origin_site_name = 5;
 * @return {string}
 */
proto.vtv1.UploadedOrderData.prototype.getOriginSiteName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.UploadedOrderData} returns this
 */
proto.vtv1.UploadedOrderData.prototype.setOriginSiteName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string destination_site_name = 6;
 * @return {string}
 */
proto.vtv1.UploadedOrderData.prototype.getDestinationSiteName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.UploadedOrderData} returns this
 */
proto.vtv1.UploadedOrderData.prototype.setDestinationSiteName = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string delivery_window_starts_at = 7;
 * @return {string}
 */
proto.vtv1.UploadedOrderData.prototype.getDeliveryWindowStartsAt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.UploadedOrderData} returns this
 */
proto.vtv1.UploadedOrderData.prototype.setDeliveryWindowStartsAt = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional int64 delivery_window_minutes = 8;
 * @return {number}
 */
proto.vtv1.UploadedOrderData.prototype.getDeliveryWindowMinutes = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.UploadedOrderData} returns this
 */
proto.vtv1.UploadedOrderData.prototype.setDeliveryWindowMinutes = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional int64 quantity_of_truckloads = 10;
 * @return {number}
 */
proto.vtv1.UploadedOrderData.prototype.getQuantityOfTruckloads = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.UploadedOrderData} returns this
 */
proto.vtv1.UploadedOrderData.prototype.setQuantityOfTruckloads = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional NullableString description = 11;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.UploadedOrderData.prototype.getDescription = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableString, 11));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.UploadedOrderData} returns this
*/
proto.vtv1.UploadedOrderData.prototype.setDescription = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.UploadedOrderData} returns this
 */
proto.vtv1.UploadedOrderData.prototype.clearDescription = function() {
  return this.setDescription(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.UploadedOrderData.prototype.hasDescription = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional NullableString secondary_product_name = 12;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.UploadedOrderData.prototype.getSecondaryProductName = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableString, 12));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.UploadedOrderData} returns this
*/
proto.vtv1.UploadedOrderData.prototype.setSecondaryProductName = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.UploadedOrderData} returns this
 */
proto.vtv1.UploadedOrderData.prototype.clearSecondaryProductName = function() {
  return this.setSecondaryProductName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.UploadedOrderData.prototype.hasSecondaryProductName = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional NullableDouble secondary_product_quantity = 13;
 * @return {?proto.vtv1.NullableDouble}
 */
proto.vtv1.UploadedOrderData.prototype.getSecondaryProductQuantity = function() {
  return /** @type{?proto.vtv1.NullableDouble} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableDouble, 13));
};


/**
 * @param {?proto.vtv1.NullableDouble|undefined} value
 * @return {!proto.vtv1.UploadedOrderData} returns this
*/
proto.vtv1.UploadedOrderData.prototype.setSecondaryProductQuantity = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.UploadedOrderData} returns this
 */
proto.vtv1.UploadedOrderData.prototype.clearSecondaryProductQuantity = function() {
  return this.setSecondaryProductQuantity(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.UploadedOrderData.prototype.hasSecondaryProductQuantity = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional NullableString inventory_name = 14;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.UploadedOrderData.prototype.getInventoryName = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableString, 14));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.UploadedOrderData} returns this
*/
proto.vtv1.UploadedOrderData.prototype.setInventoryName = function(value) {
  return jspb.Message.setWrapperField(this, 14, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.UploadedOrderData} returns this
 */
proto.vtv1.UploadedOrderData.prototype.clearInventoryName = function() {
  return this.setInventoryName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.UploadedOrderData.prototype.hasInventoryName = function() {
  return jspb.Message.getField(this, 14) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.OrderUploadRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.OrderUploadRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.OrderUploadRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.OrderUploadRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.OrderUploadRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    ordersList: jspb.Message.toObjectList(msg.getOrdersList(),
    proto.vtv1.UploadedOrderData.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.OrderUploadRequest}
 */
proto.vtv1.OrderUploadRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.OrderUploadRequest;
  return proto.vtv1.OrderUploadRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.OrderUploadRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.OrderUploadRequest}
 */
proto.vtv1.OrderUploadRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.vtv1.UploadedOrderData;
      reader.readMessage(value,proto.vtv1.UploadedOrderData.deserializeBinaryFromReader);
      msg.addOrders(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.OrderUploadRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.OrderUploadRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.OrderUploadRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.OrderUploadRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrdersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.vtv1.UploadedOrderData.serializeBinaryToWriter
    );
  }
};


/**
 * repeated UploadedOrderData orders = 1;
 * @return {!Array<!proto.vtv1.UploadedOrderData>}
 */
proto.vtv1.OrderUploadRequest.prototype.getOrdersList = function() {
  return /** @type{!Array<!proto.vtv1.UploadedOrderData>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vtv1.UploadedOrderData, 1));
};


/**
 * @param {!Array<!proto.vtv1.UploadedOrderData>} value
 * @return {!proto.vtv1.OrderUploadRequest} returns this
*/
proto.vtv1.OrderUploadRequest.prototype.setOrdersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.vtv1.UploadedOrderData=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.UploadedOrderData}
 */
proto.vtv1.OrderUploadRequest.prototype.addOrders = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.vtv1.UploadedOrderData, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.OrderUploadRequest} returns this
 */
proto.vtv1.OrderUploadRequest.prototype.clearOrdersList = function() {
  return this.setOrdersList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.OrderUploadResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.OrderUploadResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.OrderUploadResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.OrderUploadResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.OrderUploadResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    ordersList: jspb.Message.toObjectList(msg.getOrdersList(),
    proto.vtv1.OrderUploadCreateOrderRequest.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.OrderUploadResponse}
 */
proto.vtv1.OrderUploadResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.OrderUploadResponse;
  return proto.vtv1.OrderUploadResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.OrderUploadResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.OrderUploadResponse}
 */
proto.vtv1.OrderUploadResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.vtv1.OrderUploadCreateOrderRequest;
      reader.readMessage(value,proto.vtv1.OrderUploadCreateOrderRequest.deserializeBinaryFromReader);
      msg.addOrders(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.OrderUploadResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.OrderUploadResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.OrderUploadResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.OrderUploadResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrdersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.vtv1.OrderUploadCreateOrderRequest.serializeBinaryToWriter
    );
  }
};


/**
 * repeated OrderUploadCreateOrderRequest orders = 1;
 * @return {!Array<!proto.vtv1.OrderUploadCreateOrderRequest>}
 */
proto.vtv1.OrderUploadResponse.prototype.getOrdersList = function() {
  return /** @type{!Array<!proto.vtv1.OrderUploadCreateOrderRequest>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vtv1.OrderUploadCreateOrderRequest, 1));
};


/**
 * @param {!Array<!proto.vtv1.OrderUploadCreateOrderRequest>} value
 * @return {!proto.vtv1.OrderUploadResponse} returns this
*/
proto.vtv1.OrderUploadResponse.prototype.setOrdersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.vtv1.OrderUploadCreateOrderRequest=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.OrderUploadCreateOrderRequest}
 */
proto.vtv1.OrderUploadResponse.prototype.addOrders = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.vtv1.OrderUploadCreateOrderRequest, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.OrderUploadResponse} returns this
 */
proto.vtv1.OrderUploadResponse.prototype.clearOrdersList = function() {
  return this.setOrdersList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.OrderUploadCreateOrderRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.OrderUploadCreateOrderRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.OrderUploadCreateOrderRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.OrderUploadCreateOrderRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    product: (f = msg.getProduct()) && proto_vorto_types_types_pb.Product.toObject(includeInstance, f),
    supplierId: (f = msg.getSupplierId()) && proto_vorto_types_types_pb.StringIdName.toObject(includeInstance, f),
    purchaseOrderId: (f = msg.getPurchaseOrderId()) && proto_vorto_types_types_pb.StringIdName.toObject(includeInstance, f),
    quantity: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    originSiteId: (f = msg.getOriginSiteId()) && proto_vorto_types_types_pb.StringIdName.toObject(includeInstance, f),
    destinationSiteId: (f = msg.getDestinationSiteId()) && proto_vorto_types_types_pb.StringIdName.toObject(includeInstance, f),
    deliveryWindowStartsAt: jspb.Message.getFieldWithDefault(msg, 7, ""),
    deliveryWindowMinutes: jspb.Message.getFieldWithDefault(msg, 8, 0),
    quantityOfTruckloads: jspb.Message.getFieldWithDefault(msg, 9, 0),
    description: (f = msg.getDescription()) && proto_vorto_types_types_pb.NullableString.toObject(includeInstance, f),
    totalQuantity: jspb.Message.getFloatingPointFieldWithDefault(msg, 11, 0.0),
    secondaryProduct: (f = msg.getSecondaryProduct()) && proto_vorto_types_types_pb.Product.toObject(includeInstance, f),
    secondaryQuantity: (f = msg.getSecondaryQuantity()) && proto_vorto_types_types_pb.NullableDouble.toObject(includeInstance, f),
    secondaryTotalQuantity: (f = msg.getSecondaryTotalQuantity()) && proto_vorto_types_types_pb.NullableDouble.toObject(includeInstance, f),
    inventory: (f = msg.getInventory()) && proto_vorto_types_types_pb.StringIdName.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.OrderUploadCreateOrderRequest}
 */
proto.vtv1.OrderUploadCreateOrderRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.OrderUploadCreateOrderRequest;
  return proto.vtv1.OrderUploadCreateOrderRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.OrderUploadCreateOrderRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.OrderUploadCreateOrderRequest}
 */
proto.vtv1.OrderUploadCreateOrderRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_vorto_types_types_pb.Product;
      reader.readMessage(value,proto_vorto_types_types_pb.Product.deserializeBinaryFromReader);
      msg.setProduct(value);
      break;
    case 2:
      var value = new proto_vorto_types_types_pb.StringIdName;
      reader.readMessage(value,proto_vorto_types_types_pb.StringIdName.deserializeBinaryFromReader);
      msg.setSupplierId(value);
      break;
    case 3:
      var value = new proto_vorto_types_types_pb.StringIdName;
      reader.readMessage(value,proto_vorto_types_types_pb.StringIdName.deserializeBinaryFromReader);
      msg.setPurchaseOrderId(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setQuantity(value);
      break;
    case 5:
      var value = new proto_vorto_types_types_pb.StringIdName;
      reader.readMessage(value,proto_vorto_types_types_pb.StringIdName.deserializeBinaryFromReader);
      msg.setOriginSiteId(value);
      break;
    case 6:
      var value = new proto_vorto_types_types_pb.StringIdName;
      reader.readMessage(value,proto_vorto_types_types_pb.StringIdName.deserializeBinaryFromReader);
      msg.setDestinationSiteId(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeliveryWindowStartsAt(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDeliveryWindowMinutes(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setQuantityOfTruckloads(value);
      break;
    case 10:
      var value = new proto_vorto_types_types_pb.NullableString;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableString.deserializeBinaryFromReader);
      msg.setDescription(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalQuantity(value);
      break;
    case 12:
      var value = new proto_vorto_types_types_pb.Product;
      reader.readMessage(value,proto_vorto_types_types_pb.Product.deserializeBinaryFromReader);
      msg.setSecondaryProduct(value);
      break;
    case 13:
      var value = new proto_vorto_types_types_pb.NullableDouble;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableDouble.deserializeBinaryFromReader);
      msg.setSecondaryQuantity(value);
      break;
    case 14:
      var value = new proto_vorto_types_types_pb.NullableDouble;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableDouble.deserializeBinaryFromReader);
      msg.setSecondaryTotalQuantity(value);
      break;
    case 15:
      var value = new proto_vorto_types_types_pb.StringIdName;
      reader.readMessage(value,proto_vorto_types_types_pb.StringIdName.deserializeBinaryFromReader);
      msg.setInventory(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.OrderUploadCreateOrderRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.OrderUploadCreateOrderRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.OrderUploadCreateOrderRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.OrderUploadCreateOrderRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProduct();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_vorto_types_types_pb.Product.serializeBinaryToWriter
    );
  }
  f = message.getSupplierId();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_vorto_types_types_pb.StringIdName.serializeBinaryToWriter
    );
  }
  f = message.getPurchaseOrderId();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto_vorto_types_types_pb.StringIdName.serializeBinaryToWriter
    );
  }
  f = message.getQuantity();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getOriginSiteId();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto_vorto_types_types_pb.StringIdName.serializeBinaryToWriter
    );
  }
  f = message.getDestinationSiteId();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto_vorto_types_types_pb.StringIdName.serializeBinaryToWriter
    );
  }
  f = message.getDeliveryWindowStartsAt();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getDeliveryWindowMinutes();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
  f = message.getQuantityOfTruckloads();
  if (f !== 0) {
    writer.writeInt64(
      9,
      f
    );
  }
  f = message.getDescription();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto_vorto_types_types_pb.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getTotalQuantity();
  if (f !== 0.0) {
    writer.writeDouble(
      11,
      f
    );
  }
  f = message.getSecondaryProduct();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      proto_vorto_types_types_pb.Product.serializeBinaryToWriter
    );
  }
  f = message.getSecondaryQuantity();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      proto_vorto_types_types_pb.NullableDouble.serializeBinaryToWriter
    );
  }
  f = message.getSecondaryTotalQuantity();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      proto_vorto_types_types_pb.NullableDouble.serializeBinaryToWriter
    );
  }
  f = message.getInventory();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      proto_vorto_types_types_pb.StringIdName.serializeBinaryToWriter
    );
  }
};


/**
 * optional Product product = 1;
 * @return {?proto.vtv1.Product}
 */
proto.vtv1.OrderUploadCreateOrderRequest.prototype.getProduct = function() {
  return /** @type{?proto.vtv1.Product} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.Product, 1));
};


/**
 * @param {?proto.vtv1.Product|undefined} value
 * @return {!proto.vtv1.OrderUploadCreateOrderRequest} returns this
*/
proto.vtv1.OrderUploadCreateOrderRequest.prototype.setProduct = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.OrderUploadCreateOrderRequest} returns this
 */
proto.vtv1.OrderUploadCreateOrderRequest.prototype.clearProduct = function() {
  return this.setProduct(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.OrderUploadCreateOrderRequest.prototype.hasProduct = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional StringIdName supplier_id = 2;
 * @return {?proto.vtv1.StringIdName}
 */
proto.vtv1.OrderUploadCreateOrderRequest.prototype.getSupplierId = function() {
  return /** @type{?proto.vtv1.StringIdName} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.StringIdName, 2));
};


/**
 * @param {?proto.vtv1.StringIdName|undefined} value
 * @return {!proto.vtv1.OrderUploadCreateOrderRequest} returns this
*/
proto.vtv1.OrderUploadCreateOrderRequest.prototype.setSupplierId = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.OrderUploadCreateOrderRequest} returns this
 */
proto.vtv1.OrderUploadCreateOrderRequest.prototype.clearSupplierId = function() {
  return this.setSupplierId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.OrderUploadCreateOrderRequest.prototype.hasSupplierId = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional StringIdName purchase_order_id = 3;
 * @return {?proto.vtv1.StringIdName}
 */
proto.vtv1.OrderUploadCreateOrderRequest.prototype.getPurchaseOrderId = function() {
  return /** @type{?proto.vtv1.StringIdName} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.StringIdName, 3));
};


/**
 * @param {?proto.vtv1.StringIdName|undefined} value
 * @return {!proto.vtv1.OrderUploadCreateOrderRequest} returns this
*/
proto.vtv1.OrderUploadCreateOrderRequest.prototype.setPurchaseOrderId = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.OrderUploadCreateOrderRequest} returns this
 */
proto.vtv1.OrderUploadCreateOrderRequest.prototype.clearPurchaseOrderId = function() {
  return this.setPurchaseOrderId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.OrderUploadCreateOrderRequest.prototype.hasPurchaseOrderId = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional double quantity = 4;
 * @return {number}
 */
proto.vtv1.OrderUploadCreateOrderRequest.prototype.getQuantity = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.OrderUploadCreateOrderRequest} returns this
 */
proto.vtv1.OrderUploadCreateOrderRequest.prototype.setQuantity = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional StringIdName origin_site_id = 5;
 * @return {?proto.vtv1.StringIdName}
 */
proto.vtv1.OrderUploadCreateOrderRequest.prototype.getOriginSiteId = function() {
  return /** @type{?proto.vtv1.StringIdName} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.StringIdName, 5));
};


/**
 * @param {?proto.vtv1.StringIdName|undefined} value
 * @return {!proto.vtv1.OrderUploadCreateOrderRequest} returns this
*/
proto.vtv1.OrderUploadCreateOrderRequest.prototype.setOriginSiteId = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.OrderUploadCreateOrderRequest} returns this
 */
proto.vtv1.OrderUploadCreateOrderRequest.prototype.clearOriginSiteId = function() {
  return this.setOriginSiteId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.OrderUploadCreateOrderRequest.prototype.hasOriginSiteId = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional StringIdName destination_site_id = 6;
 * @return {?proto.vtv1.StringIdName}
 */
proto.vtv1.OrderUploadCreateOrderRequest.prototype.getDestinationSiteId = function() {
  return /** @type{?proto.vtv1.StringIdName} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.StringIdName, 6));
};


/**
 * @param {?proto.vtv1.StringIdName|undefined} value
 * @return {!proto.vtv1.OrderUploadCreateOrderRequest} returns this
*/
proto.vtv1.OrderUploadCreateOrderRequest.prototype.setDestinationSiteId = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.OrderUploadCreateOrderRequest} returns this
 */
proto.vtv1.OrderUploadCreateOrderRequest.prototype.clearDestinationSiteId = function() {
  return this.setDestinationSiteId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.OrderUploadCreateOrderRequest.prototype.hasDestinationSiteId = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional string delivery_window_starts_at = 7;
 * @return {string}
 */
proto.vtv1.OrderUploadCreateOrderRequest.prototype.getDeliveryWindowStartsAt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.OrderUploadCreateOrderRequest} returns this
 */
proto.vtv1.OrderUploadCreateOrderRequest.prototype.setDeliveryWindowStartsAt = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional int64 delivery_window_minutes = 8;
 * @return {number}
 */
proto.vtv1.OrderUploadCreateOrderRequest.prototype.getDeliveryWindowMinutes = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.OrderUploadCreateOrderRequest} returns this
 */
proto.vtv1.OrderUploadCreateOrderRequest.prototype.setDeliveryWindowMinutes = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional int64 quantity_of_truckloads = 9;
 * @return {number}
 */
proto.vtv1.OrderUploadCreateOrderRequest.prototype.getQuantityOfTruckloads = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.OrderUploadCreateOrderRequest} returns this
 */
proto.vtv1.OrderUploadCreateOrderRequest.prototype.setQuantityOfTruckloads = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional NullableString description = 10;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.OrderUploadCreateOrderRequest.prototype.getDescription = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableString, 10));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.OrderUploadCreateOrderRequest} returns this
*/
proto.vtv1.OrderUploadCreateOrderRequest.prototype.setDescription = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.OrderUploadCreateOrderRequest} returns this
 */
proto.vtv1.OrderUploadCreateOrderRequest.prototype.clearDescription = function() {
  return this.setDescription(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.OrderUploadCreateOrderRequest.prototype.hasDescription = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional double total_quantity = 11;
 * @return {number}
 */
proto.vtv1.OrderUploadCreateOrderRequest.prototype.getTotalQuantity = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 11, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.OrderUploadCreateOrderRequest} returns this
 */
proto.vtv1.OrderUploadCreateOrderRequest.prototype.setTotalQuantity = function(value) {
  return jspb.Message.setProto3FloatField(this, 11, value);
};


/**
 * optional Product secondary_product = 12;
 * @return {?proto.vtv1.Product}
 */
proto.vtv1.OrderUploadCreateOrderRequest.prototype.getSecondaryProduct = function() {
  return /** @type{?proto.vtv1.Product} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.Product, 12));
};


/**
 * @param {?proto.vtv1.Product|undefined} value
 * @return {!proto.vtv1.OrderUploadCreateOrderRequest} returns this
*/
proto.vtv1.OrderUploadCreateOrderRequest.prototype.setSecondaryProduct = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.OrderUploadCreateOrderRequest} returns this
 */
proto.vtv1.OrderUploadCreateOrderRequest.prototype.clearSecondaryProduct = function() {
  return this.setSecondaryProduct(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.OrderUploadCreateOrderRequest.prototype.hasSecondaryProduct = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional NullableDouble secondary_quantity = 13;
 * @return {?proto.vtv1.NullableDouble}
 */
proto.vtv1.OrderUploadCreateOrderRequest.prototype.getSecondaryQuantity = function() {
  return /** @type{?proto.vtv1.NullableDouble} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableDouble, 13));
};


/**
 * @param {?proto.vtv1.NullableDouble|undefined} value
 * @return {!proto.vtv1.OrderUploadCreateOrderRequest} returns this
*/
proto.vtv1.OrderUploadCreateOrderRequest.prototype.setSecondaryQuantity = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.OrderUploadCreateOrderRequest} returns this
 */
proto.vtv1.OrderUploadCreateOrderRequest.prototype.clearSecondaryQuantity = function() {
  return this.setSecondaryQuantity(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.OrderUploadCreateOrderRequest.prototype.hasSecondaryQuantity = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional NullableDouble secondary_total_quantity = 14;
 * @return {?proto.vtv1.NullableDouble}
 */
proto.vtv1.OrderUploadCreateOrderRequest.prototype.getSecondaryTotalQuantity = function() {
  return /** @type{?proto.vtv1.NullableDouble} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableDouble, 14));
};


/**
 * @param {?proto.vtv1.NullableDouble|undefined} value
 * @return {!proto.vtv1.OrderUploadCreateOrderRequest} returns this
*/
proto.vtv1.OrderUploadCreateOrderRequest.prototype.setSecondaryTotalQuantity = function(value) {
  return jspb.Message.setWrapperField(this, 14, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.OrderUploadCreateOrderRequest} returns this
 */
proto.vtv1.OrderUploadCreateOrderRequest.prototype.clearSecondaryTotalQuantity = function() {
  return this.setSecondaryTotalQuantity(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.OrderUploadCreateOrderRequest.prototype.hasSecondaryTotalQuantity = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional StringIdName inventory = 15;
 * @return {?proto.vtv1.StringIdName}
 */
proto.vtv1.OrderUploadCreateOrderRequest.prototype.getInventory = function() {
  return /** @type{?proto.vtv1.StringIdName} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.StringIdName, 15));
};


/**
 * @param {?proto.vtv1.StringIdName|undefined} value
 * @return {!proto.vtv1.OrderUploadCreateOrderRequest} returns this
*/
proto.vtv1.OrderUploadCreateOrderRequest.prototype.setInventory = function(value) {
  return jspb.Message.setWrapperField(this, 15, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.OrderUploadCreateOrderRequest} returns this
 */
proto.vtv1.OrderUploadCreateOrderRequest.prototype.clearInventory = function() {
  return this.setInventory(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.OrderUploadCreateOrderRequest.prototype.hasInventory = function() {
  return jspb.Message.getField(this, 15) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.GetCarrierContractsForCreateOrderV2Request.repeatedFields_ = [2,3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.GetCarrierContractsForCreateOrderV2Request.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.GetCarrierContractsForCreateOrderV2Request.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.GetCarrierContractsForCreateOrderV2Request} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.GetCarrierContractsForCreateOrderV2Request.toObject = function(includeInstance, msg) {
  var f, obj = {
    siteId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    productIdsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    inventoryIdsList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.GetCarrierContractsForCreateOrderV2Request}
 */
proto.vtv1.GetCarrierContractsForCreateOrderV2Request.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.GetCarrierContractsForCreateOrderV2Request;
  return proto.vtv1.GetCarrierContractsForCreateOrderV2Request.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.GetCarrierContractsForCreateOrderV2Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.GetCarrierContractsForCreateOrderV2Request}
 */
proto.vtv1.GetCarrierContractsForCreateOrderV2Request.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSiteId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addProductIds(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addInventoryIds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.GetCarrierContractsForCreateOrderV2Request.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.GetCarrierContractsForCreateOrderV2Request.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.GetCarrierContractsForCreateOrderV2Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.GetCarrierContractsForCreateOrderV2Request.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSiteId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getProductIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
  f = message.getInventoryIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
};


/**
 * optional string site_id = 1;
 * @return {string}
 */
proto.vtv1.GetCarrierContractsForCreateOrderV2Request.prototype.getSiteId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.GetCarrierContractsForCreateOrderV2Request} returns this
 */
proto.vtv1.GetCarrierContractsForCreateOrderV2Request.prototype.setSiteId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated string product_ids = 2;
 * @return {!Array<string>}
 */
proto.vtv1.GetCarrierContractsForCreateOrderV2Request.prototype.getProductIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.vtv1.GetCarrierContractsForCreateOrderV2Request} returns this
 */
proto.vtv1.GetCarrierContractsForCreateOrderV2Request.prototype.setProductIdsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.vtv1.GetCarrierContractsForCreateOrderV2Request} returns this
 */
proto.vtv1.GetCarrierContractsForCreateOrderV2Request.prototype.addProductIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.GetCarrierContractsForCreateOrderV2Request} returns this
 */
proto.vtv1.GetCarrierContractsForCreateOrderV2Request.prototype.clearProductIdsList = function() {
  return this.setProductIdsList([]);
};


/**
 * repeated string inventory_ids = 3;
 * @return {!Array<string>}
 */
proto.vtv1.GetCarrierContractsForCreateOrderV2Request.prototype.getInventoryIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.vtv1.GetCarrierContractsForCreateOrderV2Request} returns this
 */
proto.vtv1.GetCarrierContractsForCreateOrderV2Request.prototype.setInventoryIdsList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.vtv1.GetCarrierContractsForCreateOrderV2Request} returns this
 */
proto.vtv1.GetCarrierContractsForCreateOrderV2Request.prototype.addInventoryIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.GetCarrierContractsForCreateOrderV2Request} returns this
 */
proto.vtv1.GetCarrierContractsForCreateOrderV2Request.prototype.clearInventoryIdsList = function() {
  return this.setInventoryIdsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.GetCarrierContractsForCreateOrderV2Response.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.GetCarrierContractsForCreateOrderV2Response.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.GetCarrierContractsForCreateOrderV2Response.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.GetCarrierContractsForCreateOrderV2Response} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.GetCarrierContractsForCreateOrderV2Response.toObject = function(includeInstance, msg) {
  var f, obj = {
    edgeContractsList: jspb.Message.toObjectList(msg.getEdgeContractsList(),
    proto.vtv1.CreateOrderEdgeContract.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.GetCarrierContractsForCreateOrderV2Response}
 */
proto.vtv1.GetCarrierContractsForCreateOrderV2Response.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.GetCarrierContractsForCreateOrderV2Response;
  return proto.vtv1.GetCarrierContractsForCreateOrderV2Response.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.GetCarrierContractsForCreateOrderV2Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.GetCarrierContractsForCreateOrderV2Response}
 */
proto.vtv1.GetCarrierContractsForCreateOrderV2Response.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.vtv1.CreateOrderEdgeContract;
      reader.readMessage(value,proto.vtv1.CreateOrderEdgeContract.deserializeBinaryFromReader);
      msg.addEdgeContracts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.GetCarrierContractsForCreateOrderV2Response.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.GetCarrierContractsForCreateOrderV2Response.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.GetCarrierContractsForCreateOrderV2Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.GetCarrierContractsForCreateOrderV2Response.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEdgeContractsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.vtv1.CreateOrderEdgeContract.serializeBinaryToWriter
    );
  }
};


/**
 * repeated CreateOrderEdgeContract edge_contracts = 1;
 * @return {!Array<!proto.vtv1.CreateOrderEdgeContract>}
 */
proto.vtv1.GetCarrierContractsForCreateOrderV2Response.prototype.getEdgeContractsList = function() {
  return /** @type{!Array<!proto.vtv1.CreateOrderEdgeContract>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vtv1.CreateOrderEdgeContract, 1));
};


/**
 * @param {!Array<!proto.vtv1.CreateOrderEdgeContract>} value
 * @return {!proto.vtv1.GetCarrierContractsForCreateOrderV2Response} returns this
*/
proto.vtv1.GetCarrierContractsForCreateOrderV2Response.prototype.setEdgeContractsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.vtv1.CreateOrderEdgeContract=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.CreateOrderEdgeContract}
 */
proto.vtv1.GetCarrierContractsForCreateOrderV2Response.prototype.addEdgeContracts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.vtv1.CreateOrderEdgeContract, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.GetCarrierContractsForCreateOrderV2Response} returns this
 */
proto.vtv1.GetCarrierContractsForCreateOrderV2Response.prototype.clearEdgeContractsList = function() {
  return this.setEdgeContractsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.ListActiveCostCentersResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.ListActiveCostCentersResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.ListActiveCostCentersResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.ListActiveCostCentersResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.ListActiveCostCentersResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    costCentersList: jspb.Message.toObjectList(msg.getCostCentersList(),
    proto_vorto_types_types_pb.CostCenter.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.ListActiveCostCentersResponse}
 */
proto.vtv1.ListActiveCostCentersResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.ListActiveCostCentersResponse;
  return proto.vtv1.ListActiveCostCentersResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.ListActiveCostCentersResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.ListActiveCostCentersResponse}
 */
proto.vtv1.ListActiveCostCentersResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_vorto_types_types_pb.CostCenter;
      reader.readMessage(value,proto_vorto_types_types_pb.CostCenter.deserializeBinaryFromReader);
      msg.addCostCenters(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.ListActiveCostCentersResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.ListActiveCostCentersResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.ListActiveCostCentersResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.ListActiveCostCentersResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCostCentersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto_vorto_types_types_pb.CostCenter.serializeBinaryToWriter
    );
  }
};


/**
 * repeated CostCenter cost_centers = 1;
 * @return {!Array<!proto.vtv1.CostCenter>}
 */
proto.vtv1.ListActiveCostCentersResponse.prototype.getCostCentersList = function() {
  return /** @type{!Array<!proto.vtv1.CostCenter>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_vorto_types_types_pb.CostCenter, 1));
};


/**
 * @param {!Array<!proto.vtv1.CostCenter>} value
 * @return {!proto.vtv1.ListActiveCostCentersResponse} returns this
*/
proto.vtv1.ListActiveCostCentersResponse.prototype.setCostCentersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.vtv1.CostCenter=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.CostCenter}
 */
proto.vtv1.ListActiveCostCentersResponse.prototype.addCostCenters = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.vtv1.CostCenter, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.ListActiveCostCentersResponse} returns this
 */
proto.vtv1.ListActiveCostCentersResponse.prototype.clearCostCentersList = function() {
  return this.setCostCentersList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.CreateCostCenterRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.CreateCostCenterRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.CreateCostCenterRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.CreateCostCenterRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    number: jspb.Message.getFieldWithDefault(msg, 2, ""),
    description: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.CreateCostCenterRequest}
 */
proto.vtv1.CreateCostCenterRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.CreateCostCenterRequest;
  return proto.vtv1.CreateCostCenterRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.CreateCostCenterRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.CreateCostCenterRequest}
 */
proto.vtv1.CreateCostCenterRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setNumber(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.CreateCostCenterRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.CreateCostCenterRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.CreateCostCenterRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.CreateCostCenterRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getNumber();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.vtv1.CreateCostCenterRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.CreateCostCenterRequest} returns this
 */
proto.vtv1.CreateCostCenterRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string number = 2;
 * @return {string}
 */
proto.vtv1.CreateCostCenterRequest.prototype.getNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.CreateCostCenterRequest} returns this
 */
proto.vtv1.CreateCostCenterRequest.prototype.setNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string description = 3;
 * @return {string}
 */
proto.vtv1.CreateCostCenterRequest.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.CreateCostCenterRequest} returns this
 */
proto.vtv1.CreateCostCenterRequest.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.UpdateCostCenterRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.UpdateCostCenterRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.UpdateCostCenterRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.UpdateCostCenterRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    description: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.UpdateCostCenterRequest}
 */
proto.vtv1.UpdateCostCenterRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.UpdateCostCenterRequest;
  return proto.vtv1.UpdateCostCenterRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.UpdateCostCenterRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.UpdateCostCenterRequest}
 */
proto.vtv1.UpdateCostCenterRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.UpdateCostCenterRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.UpdateCostCenterRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.UpdateCostCenterRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.UpdateCostCenterRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.vtv1.UpdateCostCenterRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.UpdateCostCenterRequest} returns this
 */
proto.vtv1.UpdateCostCenterRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.vtv1.UpdateCostCenterRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.UpdateCostCenterRequest} returns this
 */
proto.vtv1.UpdateCostCenterRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string description = 3;
 * @return {string}
 */
proto.vtv1.UpdateCostCenterRequest.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.UpdateCostCenterRequest} returns this
 */
proto.vtv1.UpdateCostCenterRequest.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.CreateCostCenterResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.CreateCostCenterResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.CreateCostCenterResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.CreateCostCenterResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.CreateCostCenterResponse}
 */
proto.vtv1.CreateCostCenterResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.CreateCostCenterResponse;
  return proto.vtv1.CreateCostCenterResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.CreateCostCenterResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.CreateCostCenterResponse}
 */
proto.vtv1.CreateCostCenterResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.CreateCostCenterResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.CreateCostCenterResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.CreateCostCenterResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.CreateCostCenterResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.UpdateCostCenterResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.UpdateCostCenterResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.UpdateCostCenterResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.UpdateCostCenterResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.UpdateCostCenterResponse}
 */
proto.vtv1.UpdateCostCenterResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.UpdateCostCenterResponse;
  return proto.vtv1.UpdateCostCenterResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.UpdateCostCenterResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.UpdateCostCenterResponse}
 */
proto.vtv1.UpdateCostCenterResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.UpdateCostCenterResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.UpdateCostCenterResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.UpdateCostCenterResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.UpdateCostCenterResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.ListActiveSalesOrdersResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.ListActiveSalesOrdersResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.ListActiveSalesOrdersResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.ListActiveSalesOrdersResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.ListActiveSalesOrdersResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    salesOrdersList: jspb.Message.toObjectList(msg.getSalesOrdersList(),
    proto_vorto_types_types_pb.SalesOrder.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.ListActiveSalesOrdersResponse}
 */
proto.vtv1.ListActiveSalesOrdersResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.ListActiveSalesOrdersResponse;
  return proto.vtv1.ListActiveSalesOrdersResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.ListActiveSalesOrdersResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.ListActiveSalesOrdersResponse}
 */
proto.vtv1.ListActiveSalesOrdersResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_vorto_types_types_pb.SalesOrder;
      reader.readMessage(value,proto_vorto_types_types_pb.SalesOrder.deserializeBinaryFromReader);
      msg.addSalesOrders(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.ListActiveSalesOrdersResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.ListActiveSalesOrdersResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.ListActiveSalesOrdersResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.ListActiveSalesOrdersResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSalesOrdersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto_vorto_types_types_pb.SalesOrder.serializeBinaryToWriter
    );
  }
};


/**
 * repeated SalesOrder sales_orders = 1;
 * @return {!Array<!proto.vtv1.SalesOrder>}
 */
proto.vtv1.ListActiveSalesOrdersResponse.prototype.getSalesOrdersList = function() {
  return /** @type{!Array<!proto.vtv1.SalesOrder>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_vorto_types_types_pb.SalesOrder, 1));
};


/**
 * @param {!Array<!proto.vtv1.SalesOrder>} value
 * @return {!proto.vtv1.ListActiveSalesOrdersResponse} returns this
*/
proto.vtv1.ListActiveSalesOrdersResponse.prototype.setSalesOrdersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.vtv1.SalesOrder=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.SalesOrder}
 */
proto.vtv1.ListActiveSalesOrdersResponse.prototype.addSalesOrders = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.vtv1.SalesOrder, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.ListActiveSalesOrdersResponse} returns this
 */
proto.vtv1.ListActiveSalesOrdersResponse.prototype.clearSalesOrdersList = function() {
  return this.setSalesOrdersList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.ArchiveCostCenterRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.ArchiveCostCenterRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.ArchiveCostCenterRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.ArchiveCostCenterRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    costCenterId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.ArchiveCostCenterRequest}
 */
proto.vtv1.ArchiveCostCenterRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.ArchiveCostCenterRequest;
  return proto.vtv1.ArchiveCostCenterRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.ArchiveCostCenterRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.ArchiveCostCenterRequest}
 */
proto.vtv1.ArchiveCostCenterRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCostCenterId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.ArchiveCostCenterRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.ArchiveCostCenterRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.ArchiveCostCenterRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.ArchiveCostCenterRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCostCenterId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string cost_center_id = 1;
 * @return {string}
 */
proto.vtv1.ArchiveCostCenterRequest.prototype.getCostCenterId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.ArchiveCostCenterRequest} returns this
 */
proto.vtv1.ArchiveCostCenterRequest.prototype.setCostCenterId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.InventoryDetailSlimResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.InventoryDetailSlimResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.InventoryDetailSlimResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.InventoryDetailSlimResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    inventoryDetail: (f = msg.getInventoryDetail()) && proto_vorto_node_node_pb.InventoryDetailSlim.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.InventoryDetailSlimResponse}
 */
proto.vtv1.InventoryDetailSlimResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.InventoryDetailSlimResponse;
  return proto.vtv1.InventoryDetailSlimResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.InventoryDetailSlimResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.InventoryDetailSlimResponse}
 */
proto.vtv1.InventoryDetailSlimResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_vorto_node_node_pb.InventoryDetailSlim;
      reader.readMessage(value,proto_vorto_node_node_pb.InventoryDetailSlim.deserializeBinaryFromReader);
      msg.setInventoryDetail(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.InventoryDetailSlimResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.InventoryDetailSlimResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.InventoryDetailSlimResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.InventoryDetailSlimResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInventoryDetail();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_vorto_node_node_pb.InventoryDetailSlim.serializeBinaryToWriter
    );
  }
};


/**
 * optional InventoryDetailSlim inventory_detail = 1;
 * @return {?proto.vtv1.InventoryDetailSlim}
 */
proto.vtv1.InventoryDetailSlimResponse.prototype.getInventoryDetail = function() {
  return /** @type{?proto.vtv1.InventoryDetailSlim} */ (
    jspb.Message.getWrapperField(this, proto_vorto_node_node_pb.InventoryDetailSlim, 1));
};


/**
 * @param {?proto.vtv1.InventoryDetailSlim|undefined} value
 * @return {!proto.vtv1.InventoryDetailSlimResponse} returns this
*/
proto.vtv1.InventoryDetailSlimResponse.prototype.setInventoryDetail = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.InventoryDetailSlimResponse} returns this
 */
proto.vtv1.InventoryDetailSlimResponse.prototype.clearInventoryDetail = function() {
  return this.setInventoryDetail(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.InventoryDetailSlimResponse.prototype.hasInventoryDetail = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.InventoryDetailSlimStocksResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.InventoryDetailSlimStocksResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.InventoryDetailSlimStocksResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.InventoryDetailSlimStocksResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    stocks: (f = msg.getStocks()) && proto_vorto_node_node_pb.InventoryDetailSlimStocks.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.InventoryDetailSlimStocksResponse}
 */
proto.vtv1.InventoryDetailSlimStocksResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.InventoryDetailSlimStocksResponse;
  return proto.vtv1.InventoryDetailSlimStocksResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.InventoryDetailSlimStocksResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.InventoryDetailSlimStocksResponse}
 */
proto.vtv1.InventoryDetailSlimStocksResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_vorto_node_node_pb.InventoryDetailSlimStocks;
      reader.readMessage(value,proto_vorto_node_node_pb.InventoryDetailSlimStocks.deserializeBinaryFromReader);
      msg.setStocks(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.InventoryDetailSlimStocksResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.InventoryDetailSlimStocksResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.InventoryDetailSlimStocksResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.InventoryDetailSlimStocksResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStocks();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_vorto_node_node_pb.InventoryDetailSlimStocks.serializeBinaryToWriter
    );
  }
};


/**
 * optional InventoryDetailSlimStocks stocks = 1;
 * @return {?proto.vtv1.InventoryDetailSlimStocks}
 */
proto.vtv1.InventoryDetailSlimStocksResponse.prototype.getStocks = function() {
  return /** @type{?proto.vtv1.InventoryDetailSlimStocks} */ (
    jspb.Message.getWrapperField(this, proto_vorto_node_node_pb.InventoryDetailSlimStocks, 1));
};


/**
 * @param {?proto.vtv1.InventoryDetailSlimStocks|undefined} value
 * @return {!proto.vtv1.InventoryDetailSlimStocksResponse} returns this
*/
proto.vtv1.InventoryDetailSlimStocksResponse.prototype.setStocks = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.InventoryDetailSlimStocksResponse} returns this
 */
proto.vtv1.InventoryDetailSlimStocksResponse.prototype.clearStocks = function() {
  return this.setStocks(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.InventoryDetailSlimStocksResponse.prototype.hasStocks = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.InventoryDetailShipmentsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.InventoryDetailShipmentsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.InventoryDetailShipmentsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.InventoryDetailShipmentsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    shipments: (f = msg.getShipments()) && proto_vorto_node_node_pb.InventoryDetailShipments.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.InventoryDetailShipmentsResponse}
 */
proto.vtv1.InventoryDetailShipmentsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.InventoryDetailShipmentsResponse;
  return proto.vtv1.InventoryDetailShipmentsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.InventoryDetailShipmentsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.InventoryDetailShipmentsResponse}
 */
proto.vtv1.InventoryDetailShipmentsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_vorto_node_node_pb.InventoryDetailShipments;
      reader.readMessage(value,proto_vorto_node_node_pb.InventoryDetailShipments.deserializeBinaryFromReader);
      msg.setShipments(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.InventoryDetailShipmentsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.InventoryDetailShipmentsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.InventoryDetailShipmentsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.InventoryDetailShipmentsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getShipments();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_vorto_node_node_pb.InventoryDetailShipments.serializeBinaryToWriter
    );
  }
};


/**
 * optional InventoryDetailShipments shipments = 1;
 * @return {?proto.vtv1.InventoryDetailShipments}
 */
proto.vtv1.InventoryDetailShipmentsResponse.prototype.getShipments = function() {
  return /** @type{?proto.vtv1.InventoryDetailShipments} */ (
    jspb.Message.getWrapperField(this, proto_vorto_node_node_pb.InventoryDetailShipments, 1));
};


/**
 * @param {?proto.vtv1.InventoryDetailShipments|undefined} value
 * @return {!proto.vtv1.InventoryDetailShipmentsResponse} returns this
*/
proto.vtv1.InventoryDetailShipmentsResponse.prototype.setShipments = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.InventoryDetailShipmentsResponse} returns this
 */
proto.vtv1.InventoryDetailShipmentsResponse.prototype.clearShipments = function() {
  return this.setShipments(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.InventoryDetailShipmentsResponse.prototype.hasShipments = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.VMIStockUpdateRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.VMIStockUpdateRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.VMIStockUpdateRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.VMIStockUpdateRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    inventoryId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    stock: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    stockOutAt: (f = msg.getStockOutAt()) && proto_vorto_types_types_pb.NullableTime.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.VMIStockUpdateRequest}
 */
proto.vtv1.VMIStockUpdateRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.VMIStockUpdateRequest;
  return proto.vtv1.VMIStockUpdateRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.VMIStockUpdateRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.VMIStockUpdateRequest}
 */
proto.vtv1.VMIStockUpdateRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setInventoryId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setStock(value);
      break;
    case 3:
      var value = new proto_vorto_types_types_pb.NullableTime;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableTime.deserializeBinaryFromReader);
      msg.setStockOutAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.VMIStockUpdateRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.VMIStockUpdateRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.VMIStockUpdateRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.VMIStockUpdateRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInventoryId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStock();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getStockOutAt();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto_vorto_types_types_pb.NullableTime.serializeBinaryToWriter
    );
  }
};


/**
 * optional string inventory_id = 1;
 * @return {string}
 */
proto.vtv1.VMIStockUpdateRequest.prototype.getInventoryId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.VMIStockUpdateRequest} returns this
 */
proto.vtv1.VMIStockUpdateRequest.prototype.setInventoryId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional double stock = 2;
 * @return {number}
 */
proto.vtv1.VMIStockUpdateRequest.prototype.getStock = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.VMIStockUpdateRequest} returns this
 */
proto.vtv1.VMIStockUpdateRequest.prototype.setStock = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional NullableTime stock_out_at = 3;
 * @return {?proto.vtv1.NullableTime}
 */
proto.vtv1.VMIStockUpdateRequest.prototype.getStockOutAt = function() {
  return /** @type{?proto.vtv1.NullableTime} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableTime, 3));
};


/**
 * @param {?proto.vtv1.NullableTime|undefined} value
 * @return {!proto.vtv1.VMIStockUpdateRequest} returns this
*/
proto.vtv1.VMIStockUpdateRequest.prototype.setStockOutAt = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.VMIStockUpdateRequest} returns this
 */
proto.vtv1.VMIStockUpdateRequest.prototype.clearStockOutAt = function() {
  return this.setStockOutAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.VMIStockUpdateRequest.prototype.hasStockOutAt = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.VMInventoryListResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.VMInventoryListResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.VMInventoryListResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.VMInventoryListResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.VMInventoryListResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    inventoriesList: jspb.Message.toObjectList(msg.getInventoriesList(),
    proto_vorto_node_node_pb.VMIInventoryListItem.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.VMInventoryListResponse}
 */
proto.vtv1.VMInventoryListResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.VMInventoryListResponse;
  return proto.vtv1.VMInventoryListResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.VMInventoryListResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.VMInventoryListResponse}
 */
proto.vtv1.VMInventoryListResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_vorto_node_node_pb.VMIInventoryListItem;
      reader.readMessage(value,proto_vorto_node_node_pb.VMIInventoryListItem.deserializeBinaryFromReader);
      msg.addInventories(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.VMInventoryListResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.VMInventoryListResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.VMInventoryListResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.VMInventoryListResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInventoriesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto_vorto_node_node_pb.VMIInventoryListItem.serializeBinaryToWriter
    );
  }
};


/**
 * repeated VMIInventoryListItem inventories = 1;
 * @return {!Array<!proto.vtv1.VMIInventoryListItem>}
 */
proto.vtv1.VMInventoryListResponse.prototype.getInventoriesList = function() {
  return /** @type{!Array<!proto.vtv1.VMIInventoryListItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_vorto_node_node_pb.VMIInventoryListItem, 1));
};


/**
 * @param {!Array<!proto.vtv1.VMIInventoryListItem>} value
 * @return {!proto.vtv1.VMInventoryListResponse} returns this
*/
proto.vtv1.VMInventoryListResponse.prototype.setInventoriesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.vtv1.VMIInventoryListItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.VMIInventoryListItem}
 */
proto.vtv1.VMInventoryListResponse.prototype.addInventories = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.vtv1.VMIInventoryListItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.VMInventoryListResponse} returns this
 */
proto.vtv1.VMInventoryListResponse.prototype.clearInventoriesList = function() {
  return this.setInventoriesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.CreateVmiAccountRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.CreateVmiAccountRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.CreateVmiAccountRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.CreateVmiAccountRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.CreateVmiAccountRequest}
 */
proto.vtv1.CreateVmiAccountRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.CreateVmiAccountRequest;
  return proto.vtv1.CreateVmiAccountRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.CreateVmiAccountRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.CreateVmiAccountRequest}
 */
proto.vtv1.CreateVmiAccountRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.CreateVmiAccountRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.CreateVmiAccountRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.CreateVmiAccountRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.CreateVmiAccountRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.vtv1.CreateVmiAccountRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.CreateVmiAccountRequest} returns this
 */
proto.vtv1.CreateVmiAccountRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.CreateVmiAccountResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.CreateVmiAccountResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.CreateVmiAccountResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.CreateVmiAccountResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.CreateVmiAccountResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    accountsList: jspb.Message.toObjectList(msg.getAccountsList(),
    proto_vorto_types_types_pb.AccountIDName.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.CreateVmiAccountResponse}
 */
proto.vtv1.CreateVmiAccountResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.CreateVmiAccountResponse;
  return proto.vtv1.CreateVmiAccountResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.CreateVmiAccountResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.CreateVmiAccountResponse}
 */
proto.vtv1.CreateVmiAccountResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_vorto_types_types_pb.AccountIDName;
      reader.readMessage(value,proto_vorto_types_types_pb.AccountIDName.deserializeBinaryFromReader);
      msg.addAccounts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.CreateVmiAccountResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.CreateVmiAccountResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.CreateVmiAccountResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.CreateVmiAccountResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccountsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto_vorto_types_types_pb.AccountIDName.serializeBinaryToWriter
    );
  }
};


/**
 * repeated AccountIDName accounts = 1;
 * @return {!Array<!proto.vtv1.AccountIDName>}
 */
proto.vtv1.CreateVmiAccountResponse.prototype.getAccountsList = function() {
  return /** @type{!Array<!proto.vtv1.AccountIDName>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_vorto_types_types_pb.AccountIDName, 1));
};


/**
 * @param {!Array<!proto.vtv1.AccountIDName>} value
 * @return {!proto.vtv1.CreateVmiAccountResponse} returns this
*/
proto.vtv1.CreateVmiAccountResponse.prototype.setAccountsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.vtv1.AccountIDName=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.AccountIDName}
 */
proto.vtv1.CreateVmiAccountResponse.prototype.addAccounts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.vtv1.AccountIDName, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.CreateVmiAccountResponse} returns this
 */
proto.vtv1.CreateVmiAccountResponse.prototype.clearAccountsList = function() {
  return this.setAccountsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.VMIInventorySubscribeUpdateRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.VMIInventorySubscribeUpdateRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.VMIInventorySubscribeUpdateRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.VMIInventorySubscribeUpdateRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    inventoryId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    userId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.VMIInventorySubscribeUpdateRequest}
 */
proto.vtv1.VMIInventorySubscribeUpdateRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.VMIInventorySubscribeUpdateRequest;
  return proto.vtv1.VMIInventorySubscribeUpdateRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.VMIInventorySubscribeUpdateRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.VMIInventorySubscribeUpdateRequest}
 */
proto.vtv1.VMIInventorySubscribeUpdateRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setInventoryId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.VMIInventorySubscribeUpdateRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.VMIInventorySubscribeUpdateRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.VMIInventorySubscribeUpdateRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.VMIInventorySubscribeUpdateRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInventoryId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string inventory_id = 1;
 * @return {string}
 */
proto.vtv1.VMIInventorySubscribeUpdateRequest.prototype.getInventoryId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.VMIInventorySubscribeUpdateRequest} returns this
 */
proto.vtv1.VMIInventorySubscribeUpdateRequest.prototype.setInventoryId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string user_id = 2;
 * @return {string}
 */
proto.vtv1.VMIInventorySubscribeUpdateRequest.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.VMIInventorySubscribeUpdateRequest} returns this
 */
proto.vtv1.VMIInventorySubscribeUpdateRequest.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.VMIInventoryAvailableUsersResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.VMIInventoryAvailableUsersResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.VMIInventoryAvailableUsersResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.VMIInventoryAvailableUsersResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.VMIInventoryAvailableUsersResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    usersList: jspb.Message.toObjectList(msg.getUsersList(),
    proto_vorto_node_node_pb.VMIUserAvailableForInventorySubscription.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.VMIInventoryAvailableUsersResponse}
 */
proto.vtv1.VMIInventoryAvailableUsersResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.VMIInventoryAvailableUsersResponse;
  return proto.vtv1.VMIInventoryAvailableUsersResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.VMIInventoryAvailableUsersResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.VMIInventoryAvailableUsersResponse}
 */
proto.vtv1.VMIInventoryAvailableUsersResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_vorto_node_node_pb.VMIUserAvailableForInventorySubscription;
      reader.readMessage(value,proto_vorto_node_node_pb.VMIUserAvailableForInventorySubscription.deserializeBinaryFromReader);
      msg.addUsers(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.VMIInventoryAvailableUsersResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.VMIInventoryAvailableUsersResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.VMIInventoryAvailableUsersResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.VMIInventoryAvailableUsersResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUsersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto_vorto_node_node_pb.VMIUserAvailableForInventorySubscription.serializeBinaryToWriter
    );
  }
};


/**
 * repeated VMIUserAvailableForInventorySubscription users = 1;
 * @return {!Array<!proto.vtv1.VMIUserAvailableForInventorySubscription>}
 */
proto.vtv1.VMIInventoryAvailableUsersResponse.prototype.getUsersList = function() {
  return /** @type{!Array<!proto.vtv1.VMIUserAvailableForInventorySubscription>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_vorto_node_node_pb.VMIUserAvailableForInventorySubscription, 1));
};


/**
 * @param {!Array<!proto.vtv1.VMIUserAvailableForInventorySubscription>} value
 * @return {!proto.vtv1.VMIInventoryAvailableUsersResponse} returns this
*/
proto.vtv1.VMIInventoryAvailableUsersResponse.prototype.setUsersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.vtv1.VMIUserAvailableForInventorySubscription=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.VMIUserAvailableForInventorySubscription}
 */
proto.vtv1.VMIInventoryAvailableUsersResponse.prototype.addUsers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.vtv1.VMIUserAvailableForInventorySubscription, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.VMIInventoryAvailableUsersResponse} returns this
 */
proto.vtv1.VMIInventoryAvailableUsersResponse.prototype.clearUsersList = function() {
  return this.setUsersList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.VMIInventorySetOwnerRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.VMIInventorySetOwnerRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.VMIInventorySetOwnerRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.VMIInventorySetOwnerRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    inventoryId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    userId: (f = msg.getUserId()) && proto_vorto_types_types_pb.NullableString.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.VMIInventorySetOwnerRequest}
 */
proto.vtv1.VMIInventorySetOwnerRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.VMIInventorySetOwnerRequest;
  return proto.vtv1.VMIInventorySetOwnerRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.VMIInventorySetOwnerRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.VMIInventorySetOwnerRequest}
 */
proto.vtv1.VMIInventorySetOwnerRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setInventoryId(value);
      break;
    case 2:
      var value = new proto_vorto_types_types_pb.NullableString;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableString.deserializeBinaryFromReader);
      msg.setUserId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.VMIInventorySetOwnerRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.VMIInventorySetOwnerRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.VMIInventorySetOwnerRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.VMIInventorySetOwnerRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInventoryId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUserId();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_vorto_types_types_pb.NullableString.serializeBinaryToWriter
    );
  }
};


/**
 * optional string inventory_id = 1;
 * @return {string}
 */
proto.vtv1.VMIInventorySetOwnerRequest.prototype.getInventoryId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.VMIInventorySetOwnerRequest} returns this
 */
proto.vtv1.VMIInventorySetOwnerRequest.prototype.setInventoryId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional NullableString user_id = 2;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.VMIInventorySetOwnerRequest.prototype.getUserId = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableString, 2));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.VMIInventorySetOwnerRequest} returns this
*/
proto.vtv1.VMIInventorySetOwnerRequest.prototype.setUserId = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.VMIInventorySetOwnerRequest} returns this
 */
proto.vtv1.VMIInventorySetOwnerRequest.prototype.clearUserId = function() {
  return this.setUserId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.VMIInventorySetOwnerRequest.prototype.hasUserId = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.VMIListSlimFacilitiesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.VMIListSlimFacilitiesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.VMIListSlimFacilitiesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.VMIListSlimFacilitiesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.VMIListSlimFacilitiesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    facilitiesList: jspb.Message.toObjectList(msg.getFacilitiesList(),
    proto_vorto_node_node_pb.VMISlimFacility.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.VMIListSlimFacilitiesResponse}
 */
proto.vtv1.VMIListSlimFacilitiesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.VMIListSlimFacilitiesResponse;
  return proto.vtv1.VMIListSlimFacilitiesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.VMIListSlimFacilitiesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.VMIListSlimFacilitiesResponse}
 */
proto.vtv1.VMIListSlimFacilitiesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_vorto_node_node_pb.VMISlimFacility;
      reader.readMessage(value,proto_vorto_node_node_pb.VMISlimFacility.deserializeBinaryFromReader);
      msg.addFacilities(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.VMIListSlimFacilitiesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.VMIListSlimFacilitiesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.VMIListSlimFacilitiesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.VMIListSlimFacilitiesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFacilitiesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto_vorto_node_node_pb.VMISlimFacility.serializeBinaryToWriter
    );
  }
};


/**
 * repeated VMISlimFacility facilities = 1;
 * @return {!Array<!proto.vtv1.VMISlimFacility>}
 */
proto.vtv1.VMIListSlimFacilitiesResponse.prototype.getFacilitiesList = function() {
  return /** @type{!Array<!proto.vtv1.VMISlimFacility>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_vorto_node_node_pb.VMISlimFacility, 1));
};


/**
 * @param {!Array<!proto.vtv1.VMISlimFacility>} value
 * @return {!proto.vtv1.VMIListSlimFacilitiesResponse} returns this
*/
proto.vtv1.VMIListSlimFacilitiesResponse.prototype.setFacilitiesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.vtv1.VMISlimFacility=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.VMISlimFacility}
 */
proto.vtv1.VMIListSlimFacilitiesResponse.prototype.addFacilities = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.vtv1.VMISlimFacility, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.VMIListSlimFacilitiesResponse} returns this
 */
proto.vtv1.VMIListSlimFacilitiesResponse.prototype.clearFacilitiesList = function() {
  return this.setFacilitiesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.VMIFacilityDockUpsertRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.VMIFacilityDockUpsertRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.VMIFacilityDockUpsertRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.VMIFacilityDockUpsertRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && proto_vorto_types_types_pb.NullableString.toObject(includeInstance, f),
    name: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.VMIFacilityDockUpsertRequest}
 */
proto.vtv1.VMIFacilityDockUpsertRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.VMIFacilityDockUpsertRequest;
  return proto.vtv1.VMIFacilityDockUpsertRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.VMIFacilityDockUpsertRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.VMIFacilityDockUpsertRequest}
 */
proto.vtv1.VMIFacilityDockUpsertRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_vorto_types_types_pb.NullableString;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableString.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.VMIFacilityDockUpsertRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.VMIFacilityDockUpsertRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.VMIFacilityDockUpsertRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.VMIFacilityDockUpsertRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_vorto_types_types_pb.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional NullableString id = 1;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.VMIFacilityDockUpsertRequest.prototype.getId = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableString, 1));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.VMIFacilityDockUpsertRequest} returns this
*/
proto.vtv1.VMIFacilityDockUpsertRequest.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.VMIFacilityDockUpsertRequest} returns this
 */
proto.vtv1.VMIFacilityDockUpsertRequest.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.VMIFacilityDockUpsertRequest.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.vtv1.VMIFacilityDockUpsertRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.VMIFacilityDockUpsertRequest} returns this
 */
proto.vtv1.VMIFacilityDockUpsertRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.VMIEditFacilityRequest.repeatedFields_ = [15];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.VMIEditFacilityRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.VMIEditFacilityRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.VMIEditFacilityRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.VMIEditFacilityRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    address: (f = msg.getAddress()) && proto_vorto_types_types_pb.NullableString.toObject(includeInstance, f),
    lng: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    lat: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    radius: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    schedule: (f = msg.getSchedule()) && proto_vorto_types_types_pb.SiteLoadingSchedule.toObject(includeInstance, f),
    dockCount: jspb.Message.getFieldWithDefault(msg, 8, 0),
    directions: (f = msg.getDirections()) && proto_vorto_types_types_pb.NullableString.toObject(includeInstance, f),
    loadingInstructions: (f = msg.getLoadingInstructions()) && proto_vorto_types_types_pb.NullableString.toObject(includeInstance, f),
    contactName: (f = msg.getContactName()) && proto_vorto_types_types_pb.NullableString.toObject(includeInstance, f),
    contactPhone: (f = msg.getContactPhone()) && proto_vorto_types_types_pb.NullableString.toObject(includeInstance, f),
    contactEmail: (f = msg.getContactEmail()) && proto_vorto_types_types_pb.NullableString.toObject(includeInstance, f),
    preferredContactMethod: jspb.Message.getFieldWithDefault(msg, 14, ""),
    docksList: jspb.Message.toObjectList(msg.getDocksList(),
    proto.vtv1.VMIFacilityDockUpsertRequest.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.VMIEditFacilityRequest}
 */
proto.vtv1.VMIEditFacilityRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.VMIEditFacilityRequest;
  return proto.vtv1.VMIEditFacilityRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.VMIEditFacilityRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.VMIEditFacilityRequest}
 */
proto.vtv1.VMIEditFacilityRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = new proto_vorto_types_types_pb.NullableString;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableString.deserializeBinaryFromReader);
      msg.setAddress(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLng(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLat(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setRadius(value);
      break;
    case 7:
      var value = new proto_vorto_types_types_pb.SiteLoadingSchedule;
      reader.readMessage(value,proto_vorto_types_types_pb.SiteLoadingSchedule.deserializeBinaryFromReader);
      msg.setSchedule(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDockCount(value);
      break;
    case 9:
      var value = new proto_vorto_types_types_pb.NullableString;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableString.deserializeBinaryFromReader);
      msg.setDirections(value);
      break;
    case 10:
      var value = new proto_vorto_types_types_pb.NullableString;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableString.deserializeBinaryFromReader);
      msg.setLoadingInstructions(value);
      break;
    case 11:
      var value = new proto_vorto_types_types_pb.NullableString;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableString.deserializeBinaryFromReader);
      msg.setContactName(value);
      break;
    case 12:
      var value = new proto_vorto_types_types_pb.NullableString;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableString.deserializeBinaryFromReader);
      msg.setContactPhone(value);
      break;
    case 13:
      var value = new proto_vorto_types_types_pb.NullableString;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableString.deserializeBinaryFromReader);
      msg.setContactEmail(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setPreferredContactMethod(value);
      break;
    case 15:
      var value = new proto.vtv1.VMIFacilityDockUpsertRequest;
      reader.readMessage(value,proto.vtv1.VMIFacilityDockUpsertRequest.deserializeBinaryFromReader);
      msg.addDocks(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.VMIEditFacilityRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.VMIEditFacilityRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.VMIEditFacilityRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.VMIEditFacilityRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAddress();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto_vorto_types_types_pb.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getLng();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getLat();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getRadius();
  if (f !== 0.0) {
    writer.writeDouble(
      6,
      f
    );
  }
  f = message.getSchedule();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto_vorto_types_types_pb.SiteLoadingSchedule.serializeBinaryToWriter
    );
  }
  f = message.getDockCount();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
  f = message.getDirections();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto_vorto_types_types_pb.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getLoadingInstructions();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto_vorto_types_types_pb.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getContactName();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto_vorto_types_types_pb.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getContactPhone();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      proto_vorto_types_types_pb.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getContactEmail();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      proto_vorto_types_types_pb.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getPreferredContactMethod();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getDocksList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      15,
      f,
      proto.vtv1.VMIFacilityDockUpsertRequest.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.vtv1.VMIEditFacilityRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.VMIEditFacilityRequest} returns this
 */
proto.vtv1.VMIEditFacilityRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.vtv1.VMIEditFacilityRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.VMIEditFacilityRequest} returns this
 */
proto.vtv1.VMIEditFacilityRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional NullableString address = 3;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.VMIEditFacilityRequest.prototype.getAddress = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableString, 3));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.VMIEditFacilityRequest} returns this
*/
proto.vtv1.VMIEditFacilityRequest.prototype.setAddress = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.VMIEditFacilityRequest} returns this
 */
proto.vtv1.VMIEditFacilityRequest.prototype.clearAddress = function() {
  return this.setAddress(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.VMIEditFacilityRequest.prototype.hasAddress = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional double lng = 4;
 * @return {number}
 */
proto.vtv1.VMIEditFacilityRequest.prototype.getLng = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.VMIEditFacilityRequest} returns this
 */
proto.vtv1.VMIEditFacilityRequest.prototype.setLng = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional double lat = 5;
 * @return {number}
 */
proto.vtv1.VMIEditFacilityRequest.prototype.getLat = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.VMIEditFacilityRequest} returns this
 */
proto.vtv1.VMIEditFacilityRequest.prototype.setLat = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional double radius = 6;
 * @return {number}
 */
proto.vtv1.VMIEditFacilityRequest.prototype.getRadius = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.VMIEditFacilityRequest} returns this
 */
proto.vtv1.VMIEditFacilityRequest.prototype.setRadius = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional SiteLoadingSchedule schedule = 7;
 * @return {?proto.vtv1.SiteLoadingSchedule}
 */
proto.vtv1.VMIEditFacilityRequest.prototype.getSchedule = function() {
  return /** @type{?proto.vtv1.SiteLoadingSchedule} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.SiteLoadingSchedule, 7));
};


/**
 * @param {?proto.vtv1.SiteLoadingSchedule|undefined} value
 * @return {!proto.vtv1.VMIEditFacilityRequest} returns this
*/
proto.vtv1.VMIEditFacilityRequest.prototype.setSchedule = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.VMIEditFacilityRequest} returns this
 */
proto.vtv1.VMIEditFacilityRequest.prototype.clearSchedule = function() {
  return this.setSchedule(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.VMIEditFacilityRequest.prototype.hasSchedule = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional int64 dock_count = 8;
 * @return {number}
 */
proto.vtv1.VMIEditFacilityRequest.prototype.getDockCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.VMIEditFacilityRequest} returns this
 */
proto.vtv1.VMIEditFacilityRequest.prototype.setDockCount = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional NullableString directions = 9;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.VMIEditFacilityRequest.prototype.getDirections = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableString, 9));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.VMIEditFacilityRequest} returns this
*/
proto.vtv1.VMIEditFacilityRequest.prototype.setDirections = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.VMIEditFacilityRequest} returns this
 */
proto.vtv1.VMIEditFacilityRequest.prototype.clearDirections = function() {
  return this.setDirections(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.VMIEditFacilityRequest.prototype.hasDirections = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional NullableString loading_instructions = 10;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.VMIEditFacilityRequest.prototype.getLoadingInstructions = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableString, 10));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.VMIEditFacilityRequest} returns this
*/
proto.vtv1.VMIEditFacilityRequest.prototype.setLoadingInstructions = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.VMIEditFacilityRequest} returns this
 */
proto.vtv1.VMIEditFacilityRequest.prototype.clearLoadingInstructions = function() {
  return this.setLoadingInstructions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.VMIEditFacilityRequest.prototype.hasLoadingInstructions = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional NullableString contact_name = 11;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.VMIEditFacilityRequest.prototype.getContactName = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableString, 11));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.VMIEditFacilityRequest} returns this
*/
proto.vtv1.VMIEditFacilityRequest.prototype.setContactName = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.VMIEditFacilityRequest} returns this
 */
proto.vtv1.VMIEditFacilityRequest.prototype.clearContactName = function() {
  return this.setContactName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.VMIEditFacilityRequest.prototype.hasContactName = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional NullableString contact_phone = 12;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.VMIEditFacilityRequest.prototype.getContactPhone = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableString, 12));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.VMIEditFacilityRequest} returns this
*/
proto.vtv1.VMIEditFacilityRequest.prototype.setContactPhone = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.VMIEditFacilityRequest} returns this
 */
proto.vtv1.VMIEditFacilityRequest.prototype.clearContactPhone = function() {
  return this.setContactPhone(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.VMIEditFacilityRequest.prototype.hasContactPhone = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional NullableString contact_email = 13;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.VMIEditFacilityRequest.prototype.getContactEmail = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableString, 13));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.VMIEditFacilityRequest} returns this
*/
proto.vtv1.VMIEditFacilityRequest.prototype.setContactEmail = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.VMIEditFacilityRequest} returns this
 */
proto.vtv1.VMIEditFacilityRequest.prototype.clearContactEmail = function() {
  return this.setContactEmail(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.VMIEditFacilityRequest.prototype.hasContactEmail = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional string preferred_contact_method = 14;
 * @return {string}
 */
proto.vtv1.VMIEditFacilityRequest.prototype.getPreferredContactMethod = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.VMIEditFacilityRequest} returns this
 */
proto.vtv1.VMIEditFacilityRequest.prototype.setPreferredContactMethod = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * repeated VMIFacilityDockUpsertRequest docks = 15;
 * @return {!Array<!proto.vtv1.VMIFacilityDockUpsertRequest>}
 */
proto.vtv1.VMIEditFacilityRequest.prototype.getDocksList = function() {
  return /** @type{!Array<!proto.vtv1.VMIFacilityDockUpsertRequest>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vtv1.VMIFacilityDockUpsertRequest, 15));
};


/**
 * @param {!Array<!proto.vtv1.VMIFacilityDockUpsertRequest>} value
 * @return {!proto.vtv1.VMIEditFacilityRequest} returns this
*/
proto.vtv1.VMIEditFacilityRequest.prototype.setDocksList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 15, value);
};


/**
 * @param {!proto.vtv1.VMIFacilityDockUpsertRequest=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.VMIFacilityDockUpsertRequest}
 */
proto.vtv1.VMIEditFacilityRequest.prototype.addDocks = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 15, opt_value, proto.vtv1.VMIFacilityDockUpsertRequest, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.VMIEditFacilityRequest} returns this
 */
proto.vtv1.VMIEditFacilityRequest.prototype.clearDocksList = function() {
  return this.setDocksList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.VMIFacilityDetailResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.VMIFacilityDetailResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.VMIFacilityDetailResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.VMIFacilityDetailResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    facility: (f = msg.getFacility()) && proto_vorto_node_node_pb.VMIFacilityDetail.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.VMIFacilityDetailResponse}
 */
proto.vtv1.VMIFacilityDetailResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.VMIFacilityDetailResponse;
  return proto.vtv1.VMIFacilityDetailResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.VMIFacilityDetailResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.VMIFacilityDetailResponse}
 */
proto.vtv1.VMIFacilityDetailResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_vorto_node_node_pb.VMIFacilityDetail;
      reader.readMessage(value,proto_vorto_node_node_pb.VMIFacilityDetail.deserializeBinaryFromReader);
      msg.setFacility(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.VMIFacilityDetailResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.VMIFacilityDetailResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.VMIFacilityDetailResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.VMIFacilityDetailResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFacility();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_vorto_node_node_pb.VMIFacilityDetail.serializeBinaryToWriter
    );
  }
};


/**
 * optional VMIFacilityDetail facility = 1;
 * @return {?proto.vtv1.VMIFacilityDetail}
 */
proto.vtv1.VMIFacilityDetailResponse.prototype.getFacility = function() {
  return /** @type{?proto.vtv1.VMIFacilityDetail} */ (
    jspb.Message.getWrapperField(this, proto_vorto_node_node_pb.VMIFacilityDetail, 1));
};


/**
 * @param {?proto.vtv1.VMIFacilityDetail|undefined} value
 * @return {!proto.vtv1.VMIFacilityDetailResponse} returns this
*/
proto.vtv1.VMIFacilityDetailResponse.prototype.setFacility = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.VMIFacilityDetailResponse} returns this
 */
proto.vtv1.VMIFacilityDetailResponse.prototype.clearFacility = function() {
  return this.setFacility(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.VMIFacilityDetailResponse.prototype.hasFacility = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.VMICreateInventoryRequest.repeatedFields_ = [7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.VMICreateInventoryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.VMICreateInventoryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.VMICreateInventoryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.VMICreateInventoryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    siteId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    productId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    name: jspb.Message.getFieldWithDefault(msg, 3, ""),
    capacity: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    defaultLoadQuantity: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    defaultUnloadingMinutes: jspb.Message.getFieldWithDefault(msg, 6, 0),
    trailerTypesList: (f = jspb.Message.getRepeatedField(msg, 7)) == null ? undefined : f,
    targetZeroStock: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    inbound: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
    outbound: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
    warningPercentage: (f = msg.getWarningPercentage()) && proto_vorto_types_types_pb.NullableDouble.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.VMICreateInventoryRequest}
 */
proto.vtv1.VMICreateInventoryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.VMICreateInventoryRequest;
  return proto.vtv1.VMICreateInventoryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.VMICreateInventoryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.VMICreateInventoryRequest}
 */
proto.vtv1.VMICreateInventoryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSiteId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setCapacity(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setDefaultLoadQuantity(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDefaultUnloadingMinutes(value);
      break;
    case 7:
      var value = /** @type {!Array<number>} */ (reader.readPackedInt64());
      msg.setTrailerTypesList(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setTargetZeroStock(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setInbound(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOutbound(value);
      break;
    case 11:
      var value = new proto_vorto_types_types_pb.NullableDouble;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableDouble.deserializeBinaryFromReader);
      msg.setWarningPercentage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.VMICreateInventoryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.VMICreateInventoryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.VMICreateInventoryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.VMICreateInventoryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSiteId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getProductId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCapacity();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getDefaultLoadQuantity();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getDefaultUnloadingMinutes();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getTrailerTypesList();
  if (f.length > 0) {
    writer.writePackedInt64(
      7,
      f
    );
  }
  f = message.getTargetZeroStock();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getInbound();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getOutbound();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
  f = message.getWarningPercentage();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto_vorto_types_types_pb.NullableDouble.serializeBinaryToWriter
    );
  }
};


/**
 * optional string site_id = 1;
 * @return {string}
 */
proto.vtv1.VMICreateInventoryRequest.prototype.getSiteId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.VMICreateInventoryRequest} returns this
 */
proto.vtv1.VMICreateInventoryRequest.prototype.setSiteId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string product_id = 2;
 * @return {string}
 */
proto.vtv1.VMICreateInventoryRequest.prototype.getProductId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.VMICreateInventoryRequest} returns this
 */
proto.vtv1.VMICreateInventoryRequest.prototype.setProductId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string name = 3;
 * @return {string}
 */
proto.vtv1.VMICreateInventoryRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.VMICreateInventoryRequest} returns this
 */
proto.vtv1.VMICreateInventoryRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional double capacity = 4;
 * @return {number}
 */
proto.vtv1.VMICreateInventoryRequest.prototype.getCapacity = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.VMICreateInventoryRequest} returns this
 */
proto.vtv1.VMICreateInventoryRequest.prototype.setCapacity = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional double default_load_quantity = 5;
 * @return {number}
 */
proto.vtv1.VMICreateInventoryRequest.prototype.getDefaultLoadQuantity = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.VMICreateInventoryRequest} returns this
 */
proto.vtv1.VMICreateInventoryRequest.prototype.setDefaultLoadQuantity = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional int64 default_unloading_minutes = 6;
 * @return {number}
 */
proto.vtv1.VMICreateInventoryRequest.prototype.getDefaultUnloadingMinutes = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.VMICreateInventoryRequest} returns this
 */
proto.vtv1.VMICreateInventoryRequest.prototype.setDefaultUnloadingMinutes = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * repeated int64 trailer_types = 7;
 * @return {!Array<number>}
 */
proto.vtv1.VMICreateInventoryRequest.prototype.getTrailerTypesList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 7));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.vtv1.VMICreateInventoryRequest} returns this
 */
proto.vtv1.VMICreateInventoryRequest.prototype.setTrailerTypesList = function(value) {
  return jspb.Message.setField(this, 7, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.vtv1.VMICreateInventoryRequest} returns this
 */
proto.vtv1.VMICreateInventoryRequest.prototype.addTrailerTypes = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 7, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.VMICreateInventoryRequest} returns this
 */
proto.vtv1.VMICreateInventoryRequest.prototype.clearTrailerTypesList = function() {
  return this.setTrailerTypesList([]);
};


/**
 * optional bool target_zero_stock = 8;
 * @return {boolean}
 */
proto.vtv1.VMICreateInventoryRequest.prototype.getTargetZeroStock = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vtv1.VMICreateInventoryRequest} returns this
 */
proto.vtv1.VMICreateInventoryRequest.prototype.setTargetZeroStock = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional bool inbound = 9;
 * @return {boolean}
 */
proto.vtv1.VMICreateInventoryRequest.prototype.getInbound = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vtv1.VMICreateInventoryRequest} returns this
 */
proto.vtv1.VMICreateInventoryRequest.prototype.setInbound = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};


/**
 * optional bool outbound = 10;
 * @return {boolean}
 */
proto.vtv1.VMICreateInventoryRequest.prototype.getOutbound = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vtv1.VMICreateInventoryRequest} returns this
 */
proto.vtv1.VMICreateInventoryRequest.prototype.setOutbound = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};


/**
 * optional NullableDouble warning_percentage = 11;
 * @return {?proto.vtv1.NullableDouble}
 */
proto.vtv1.VMICreateInventoryRequest.prototype.getWarningPercentage = function() {
  return /** @type{?proto.vtv1.NullableDouble} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableDouble, 11));
};


/**
 * @param {?proto.vtv1.NullableDouble|undefined} value
 * @return {!proto.vtv1.VMICreateInventoryRequest} returns this
*/
proto.vtv1.VMICreateInventoryRequest.prototype.setWarningPercentage = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.VMICreateInventoryRequest} returns this
 */
proto.vtv1.VMICreateInventoryRequest.prototype.clearWarningPercentage = function() {
  return this.setWarningPercentage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.VMICreateInventoryRequest.prototype.hasWarningPercentage = function() {
  return jspb.Message.getField(this, 11) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.VMIEditInventoryRequest.repeatedFields_ = [7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.VMIEditInventoryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.VMIEditInventoryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.VMIEditInventoryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.VMIEditInventoryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 3, ""),
    capacity: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    defaultLoadQuantity: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    defaultUnloadingMinutes: jspb.Message.getFieldWithDefault(msg, 6, 0),
    trailerTypesList: (f = jspb.Message.getRepeatedField(msg, 7)) == null ? undefined : f,
    targetZeroStock: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    inbound: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
    outbound: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
    warningPercentage: (f = msg.getWarningPercentage()) && proto_vorto_types_types_pb.NullableDouble.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.VMIEditInventoryRequest}
 */
proto.vtv1.VMIEditInventoryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.VMIEditInventoryRequest;
  return proto.vtv1.VMIEditInventoryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.VMIEditInventoryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.VMIEditInventoryRequest}
 */
proto.vtv1.VMIEditInventoryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setCapacity(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setDefaultLoadQuantity(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDefaultUnloadingMinutes(value);
      break;
    case 7:
      var value = /** @type {!Array<number>} */ (reader.readPackedInt64());
      msg.setTrailerTypesList(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setTargetZeroStock(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setInbound(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOutbound(value);
      break;
    case 11:
      var value = new proto_vorto_types_types_pb.NullableDouble;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableDouble.deserializeBinaryFromReader);
      msg.setWarningPercentage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.VMIEditInventoryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.VMIEditInventoryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.VMIEditInventoryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.VMIEditInventoryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCapacity();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getDefaultLoadQuantity();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getDefaultUnloadingMinutes();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getTrailerTypesList();
  if (f.length > 0) {
    writer.writePackedInt64(
      7,
      f
    );
  }
  f = message.getTargetZeroStock();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getInbound();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getOutbound();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
  f = message.getWarningPercentage();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto_vorto_types_types_pb.NullableDouble.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.vtv1.VMIEditInventoryRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.VMIEditInventoryRequest} returns this
 */
proto.vtv1.VMIEditInventoryRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 3;
 * @return {string}
 */
proto.vtv1.VMIEditInventoryRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.VMIEditInventoryRequest} returns this
 */
proto.vtv1.VMIEditInventoryRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional double capacity = 4;
 * @return {number}
 */
proto.vtv1.VMIEditInventoryRequest.prototype.getCapacity = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.VMIEditInventoryRequest} returns this
 */
proto.vtv1.VMIEditInventoryRequest.prototype.setCapacity = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional double default_load_quantity = 5;
 * @return {number}
 */
proto.vtv1.VMIEditInventoryRequest.prototype.getDefaultLoadQuantity = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.VMIEditInventoryRequest} returns this
 */
proto.vtv1.VMIEditInventoryRequest.prototype.setDefaultLoadQuantity = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional int64 default_unloading_minutes = 6;
 * @return {number}
 */
proto.vtv1.VMIEditInventoryRequest.prototype.getDefaultUnloadingMinutes = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.VMIEditInventoryRequest} returns this
 */
proto.vtv1.VMIEditInventoryRequest.prototype.setDefaultUnloadingMinutes = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * repeated int64 trailer_types = 7;
 * @return {!Array<number>}
 */
proto.vtv1.VMIEditInventoryRequest.prototype.getTrailerTypesList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 7));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.vtv1.VMIEditInventoryRequest} returns this
 */
proto.vtv1.VMIEditInventoryRequest.prototype.setTrailerTypesList = function(value) {
  return jspb.Message.setField(this, 7, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.vtv1.VMIEditInventoryRequest} returns this
 */
proto.vtv1.VMIEditInventoryRequest.prototype.addTrailerTypes = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 7, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.VMIEditInventoryRequest} returns this
 */
proto.vtv1.VMIEditInventoryRequest.prototype.clearTrailerTypesList = function() {
  return this.setTrailerTypesList([]);
};


/**
 * optional bool target_zero_stock = 8;
 * @return {boolean}
 */
proto.vtv1.VMIEditInventoryRequest.prototype.getTargetZeroStock = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vtv1.VMIEditInventoryRequest} returns this
 */
proto.vtv1.VMIEditInventoryRequest.prototype.setTargetZeroStock = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional bool inbound = 9;
 * @return {boolean}
 */
proto.vtv1.VMIEditInventoryRequest.prototype.getInbound = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vtv1.VMIEditInventoryRequest} returns this
 */
proto.vtv1.VMIEditInventoryRequest.prototype.setInbound = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};


/**
 * optional bool outbound = 10;
 * @return {boolean}
 */
proto.vtv1.VMIEditInventoryRequest.prototype.getOutbound = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vtv1.VMIEditInventoryRequest} returns this
 */
proto.vtv1.VMIEditInventoryRequest.prototype.setOutbound = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};


/**
 * optional NullableDouble warning_percentage = 11;
 * @return {?proto.vtv1.NullableDouble}
 */
proto.vtv1.VMIEditInventoryRequest.prototype.getWarningPercentage = function() {
  return /** @type{?proto.vtv1.NullableDouble} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableDouble, 11));
};


/**
 * @param {?proto.vtv1.NullableDouble|undefined} value
 * @return {!proto.vtv1.VMIEditInventoryRequest} returns this
*/
proto.vtv1.VMIEditInventoryRequest.prototype.setWarningPercentage = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.VMIEditInventoryRequest} returns this
 */
proto.vtv1.VMIEditInventoryRequest.prototype.clearWarningPercentage = function() {
  return this.setWarningPercentage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.VMIEditInventoryRequest.prototype.hasWarningPercentage = function() {
  return jspb.Message.getField(this, 11) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.VMIListFacilitiesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.VMIListFacilitiesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.VMIListFacilitiesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.VMIListFacilitiesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.VMIListFacilitiesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    facilitiesList: jspb.Message.toObjectList(msg.getFacilitiesList(),
    proto_vorto_node_node_pb.VMIFacilityListItem.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.VMIListFacilitiesResponse}
 */
proto.vtv1.VMIListFacilitiesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.VMIListFacilitiesResponse;
  return proto.vtv1.VMIListFacilitiesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.VMIListFacilitiesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.VMIListFacilitiesResponse}
 */
proto.vtv1.VMIListFacilitiesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_vorto_node_node_pb.VMIFacilityListItem;
      reader.readMessage(value,proto_vorto_node_node_pb.VMIFacilityListItem.deserializeBinaryFromReader);
      msg.addFacilities(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.VMIListFacilitiesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.VMIListFacilitiesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.VMIListFacilitiesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.VMIListFacilitiesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFacilitiesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto_vorto_node_node_pb.VMIFacilityListItem.serializeBinaryToWriter
    );
  }
};


/**
 * repeated VMIFacilityListItem facilities = 1;
 * @return {!Array<!proto.vtv1.VMIFacilityListItem>}
 */
proto.vtv1.VMIListFacilitiesResponse.prototype.getFacilitiesList = function() {
  return /** @type{!Array<!proto.vtv1.VMIFacilityListItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_vorto_node_node_pb.VMIFacilityListItem, 1));
};


/**
 * @param {!Array<!proto.vtv1.VMIFacilityListItem>} value
 * @return {!proto.vtv1.VMIListFacilitiesResponse} returns this
*/
proto.vtv1.VMIListFacilitiesResponse.prototype.setFacilitiesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.vtv1.VMIFacilityListItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.VMIFacilityListItem}
 */
proto.vtv1.VMIListFacilitiesResponse.prototype.addFacilities = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.vtv1.VMIFacilityListItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.VMIListFacilitiesResponse} returns this
 */
proto.vtv1.VMIListFacilitiesResponse.prototype.clearFacilitiesList = function() {
  return this.setFacilitiesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.VMICreateFacilityRequest.repeatedFields_ = [14];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.VMICreateFacilityRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.VMICreateFacilityRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.VMICreateFacilityRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.VMICreateFacilityRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    address: (f = msg.getAddress()) && proto_vorto_types_types_pb.NullableString.toObject(includeInstance, f),
    lng: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    lat: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    radius: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    schedule: (f = msg.getSchedule()) && proto_vorto_types_types_pb.SiteLoadingSchedule.toObject(includeInstance, f),
    dockCount: jspb.Message.getFieldWithDefault(msg, 7, 0),
    directions: (f = msg.getDirections()) && proto_vorto_types_types_pb.NullableString.toObject(includeInstance, f),
    loadingInstructions: (f = msg.getLoadingInstructions()) && proto_vorto_types_types_pb.NullableString.toObject(includeInstance, f),
    contactName: (f = msg.getContactName()) && proto_vorto_types_types_pb.NullableString.toObject(includeInstance, f),
    contactPhone: (f = msg.getContactPhone()) && proto_vorto_types_types_pb.NullableString.toObject(includeInstance, f),
    contactEmail: (f = msg.getContactEmail()) && proto_vorto_types_types_pb.NullableString.toObject(includeInstance, f),
    preferredContactMethod: jspb.Message.getFieldWithDefault(msg, 13, ""),
    docksList: jspb.Message.toObjectList(msg.getDocksList(),
    proto.vtv1.VMIFacilityDockUpsertRequest.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.VMICreateFacilityRequest}
 */
proto.vtv1.VMICreateFacilityRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.VMICreateFacilityRequest;
  return proto.vtv1.VMICreateFacilityRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.VMICreateFacilityRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.VMICreateFacilityRequest}
 */
proto.vtv1.VMICreateFacilityRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = new proto_vorto_types_types_pb.NullableString;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableString.deserializeBinaryFromReader);
      msg.setAddress(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLng(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLat(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setRadius(value);
      break;
    case 6:
      var value = new proto_vorto_types_types_pb.SiteLoadingSchedule;
      reader.readMessage(value,proto_vorto_types_types_pb.SiteLoadingSchedule.deserializeBinaryFromReader);
      msg.setSchedule(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDockCount(value);
      break;
    case 8:
      var value = new proto_vorto_types_types_pb.NullableString;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableString.deserializeBinaryFromReader);
      msg.setDirections(value);
      break;
    case 9:
      var value = new proto_vorto_types_types_pb.NullableString;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableString.deserializeBinaryFromReader);
      msg.setLoadingInstructions(value);
      break;
    case 10:
      var value = new proto_vorto_types_types_pb.NullableString;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableString.deserializeBinaryFromReader);
      msg.setContactName(value);
      break;
    case 11:
      var value = new proto_vorto_types_types_pb.NullableString;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableString.deserializeBinaryFromReader);
      msg.setContactPhone(value);
      break;
    case 12:
      var value = new proto_vorto_types_types_pb.NullableString;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableString.deserializeBinaryFromReader);
      msg.setContactEmail(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setPreferredContactMethod(value);
      break;
    case 14:
      var value = new proto.vtv1.VMIFacilityDockUpsertRequest;
      reader.readMessage(value,proto.vtv1.VMIFacilityDockUpsertRequest.deserializeBinaryFromReader);
      msg.addDocks(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.VMICreateFacilityRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.VMICreateFacilityRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.VMICreateFacilityRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.VMICreateFacilityRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAddress();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_vorto_types_types_pb.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getLng();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getLat();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getRadius();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getSchedule();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto_vorto_types_types_pb.SiteLoadingSchedule.serializeBinaryToWriter
    );
  }
  f = message.getDockCount();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
  f = message.getDirections();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto_vorto_types_types_pb.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getLoadingInstructions();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto_vorto_types_types_pb.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getContactName();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto_vorto_types_types_pb.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getContactPhone();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto_vorto_types_types_pb.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getContactEmail();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      proto_vorto_types_types_pb.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getPreferredContactMethod();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getDocksList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      14,
      f,
      proto.vtv1.VMIFacilityDockUpsertRequest.serializeBinaryToWriter
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.vtv1.VMICreateFacilityRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.VMICreateFacilityRequest} returns this
 */
proto.vtv1.VMICreateFacilityRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional NullableString address = 2;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.VMICreateFacilityRequest.prototype.getAddress = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableString, 2));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.VMICreateFacilityRequest} returns this
*/
proto.vtv1.VMICreateFacilityRequest.prototype.setAddress = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.VMICreateFacilityRequest} returns this
 */
proto.vtv1.VMICreateFacilityRequest.prototype.clearAddress = function() {
  return this.setAddress(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.VMICreateFacilityRequest.prototype.hasAddress = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional double lng = 3;
 * @return {number}
 */
proto.vtv1.VMICreateFacilityRequest.prototype.getLng = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.VMICreateFacilityRequest} returns this
 */
proto.vtv1.VMICreateFacilityRequest.prototype.setLng = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional double lat = 4;
 * @return {number}
 */
proto.vtv1.VMICreateFacilityRequest.prototype.getLat = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.VMICreateFacilityRequest} returns this
 */
proto.vtv1.VMICreateFacilityRequest.prototype.setLat = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional double radius = 5;
 * @return {number}
 */
proto.vtv1.VMICreateFacilityRequest.prototype.getRadius = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.VMICreateFacilityRequest} returns this
 */
proto.vtv1.VMICreateFacilityRequest.prototype.setRadius = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional SiteLoadingSchedule schedule = 6;
 * @return {?proto.vtv1.SiteLoadingSchedule}
 */
proto.vtv1.VMICreateFacilityRequest.prototype.getSchedule = function() {
  return /** @type{?proto.vtv1.SiteLoadingSchedule} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.SiteLoadingSchedule, 6));
};


/**
 * @param {?proto.vtv1.SiteLoadingSchedule|undefined} value
 * @return {!proto.vtv1.VMICreateFacilityRequest} returns this
*/
proto.vtv1.VMICreateFacilityRequest.prototype.setSchedule = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.VMICreateFacilityRequest} returns this
 */
proto.vtv1.VMICreateFacilityRequest.prototype.clearSchedule = function() {
  return this.setSchedule(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.VMICreateFacilityRequest.prototype.hasSchedule = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional int64 dock_count = 7;
 * @return {number}
 */
proto.vtv1.VMICreateFacilityRequest.prototype.getDockCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.VMICreateFacilityRequest} returns this
 */
proto.vtv1.VMICreateFacilityRequest.prototype.setDockCount = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional NullableString directions = 8;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.VMICreateFacilityRequest.prototype.getDirections = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableString, 8));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.VMICreateFacilityRequest} returns this
*/
proto.vtv1.VMICreateFacilityRequest.prototype.setDirections = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.VMICreateFacilityRequest} returns this
 */
proto.vtv1.VMICreateFacilityRequest.prototype.clearDirections = function() {
  return this.setDirections(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.VMICreateFacilityRequest.prototype.hasDirections = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional NullableString loading_instructions = 9;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.VMICreateFacilityRequest.prototype.getLoadingInstructions = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableString, 9));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.VMICreateFacilityRequest} returns this
*/
proto.vtv1.VMICreateFacilityRequest.prototype.setLoadingInstructions = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.VMICreateFacilityRequest} returns this
 */
proto.vtv1.VMICreateFacilityRequest.prototype.clearLoadingInstructions = function() {
  return this.setLoadingInstructions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.VMICreateFacilityRequest.prototype.hasLoadingInstructions = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional NullableString contact_name = 10;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.VMICreateFacilityRequest.prototype.getContactName = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableString, 10));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.VMICreateFacilityRequest} returns this
*/
proto.vtv1.VMICreateFacilityRequest.prototype.setContactName = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.VMICreateFacilityRequest} returns this
 */
proto.vtv1.VMICreateFacilityRequest.prototype.clearContactName = function() {
  return this.setContactName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.VMICreateFacilityRequest.prototype.hasContactName = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional NullableString contact_phone = 11;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.VMICreateFacilityRequest.prototype.getContactPhone = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableString, 11));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.VMICreateFacilityRequest} returns this
*/
proto.vtv1.VMICreateFacilityRequest.prototype.setContactPhone = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.VMICreateFacilityRequest} returns this
 */
proto.vtv1.VMICreateFacilityRequest.prototype.clearContactPhone = function() {
  return this.setContactPhone(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.VMICreateFacilityRequest.prototype.hasContactPhone = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional NullableString contact_email = 12;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.VMICreateFacilityRequest.prototype.getContactEmail = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableString, 12));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.VMICreateFacilityRequest} returns this
*/
proto.vtv1.VMICreateFacilityRequest.prototype.setContactEmail = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.VMICreateFacilityRequest} returns this
 */
proto.vtv1.VMICreateFacilityRequest.prototype.clearContactEmail = function() {
  return this.setContactEmail(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.VMICreateFacilityRequest.prototype.hasContactEmail = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional string preferred_contact_method = 13;
 * @return {string}
 */
proto.vtv1.VMICreateFacilityRequest.prototype.getPreferredContactMethod = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.VMICreateFacilityRequest} returns this
 */
proto.vtv1.VMICreateFacilityRequest.prototype.setPreferredContactMethod = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * repeated VMIFacilityDockUpsertRequest docks = 14;
 * @return {!Array<!proto.vtv1.VMIFacilityDockUpsertRequest>}
 */
proto.vtv1.VMICreateFacilityRequest.prototype.getDocksList = function() {
  return /** @type{!Array<!proto.vtv1.VMIFacilityDockUpsertRequest>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vtv1.VMIFacilityDockUpsertRequest, 14));
};


/**
 * @param {!Array<!proto.vtv1.VMIFacilityDockUpsertRequest>} value
 * @return {!proto.vtv1.VMICreateFacilityRequest} returns this
*/
proto.vtv1.VMICreateFacilityRequest.prototype.setDocksList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 14, value);
};


/**
 * @param {!proto.vtv1.VMIFacilityDockUpsertRequest=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.VMIFacilityDockUpsertRequest}
 */
proto.vtv1.VMICreateFacilityRequest.prototype.addDocks = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 14, opt_value, proto.vtv1.VMIFacilityDockUpsertRequest, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.VMICreateFacilityRequest} returns this
 */
proto.vtv1.VMICreateFacilityRequest.prototype.clearDocksList = function() {
  return this.setDocksList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.VMIFacilityFileListResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.VMIFacilityFileListResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.VMIFacilityFileListResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.VMIFacilityFileListResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.VMIFacilityFileListResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    facilityFilesList: jspb.Message.toObjectList(msg.getFacilityFilesList(),
    proto_vorto_node_node_pb.VMIFacilityFile.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.VMIFacilityFileListResponse}
 */
proto.vtv1.VMIFacilityFileListResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.VMIFacilityFileListResponse;
  return proto.vtv1.VMIFacilityFileListResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.VMIFacilityFileListResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.VMIFacilityFileListResponse}
 */
proto.vtv1.VMIFacilityFileListResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_vorto_node_node_pb.VMIFacilityFile;
      reader.readMessage(value,proto_vorto_node_node_pb.VMIFacilityFile.deserializeBinaryFromReader);
      msg.addFacilityFiles(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.VMIFacilityFileListResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.VMIFacilityFileListResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.VMIFacilityFileListResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.VMIFacilityFileListResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFacilityFilesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto_vorto_node_node_pb.VMIFacilityFile.serializeBinaryToWriter
    );
  }
};


/**
 * repeated VMIFacilityFile facility_files = 1;
 * @return {!Array<!proto.vtv1.VMIFacilityFile>}
 */
proto.vtv1.VMIFacilityFileListResponse.prototype.getFacilityFilesList = function() {
  return /** @type{!Array<!proto.vtv1.VMIFacilityFile>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_vorto_node_node_pb.VMIFacilityFile, 1));
};


/**
 * @param {!Array<!proto.vtv1.VMIFacilityFile>} value
 * @return {!proto.vtv1.VMIFacilityFileListResponse} returns this
*/
proto.vtv1.VMIFacilityFileListResponse.prototype.setFacilityFilesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.vtv1.VMIFacilityFile=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.VMIFacilityFile}
 */
proto.vtv1.VMIFacilityFileListResponse.prototype.addFacilityFiles = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.vtv1.VMIFacilityFile, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.VMIFacilityFileListResponse} returns this
 */
proto.vtv1.VMIFacilityFileListResponse.prototype.clearFacilityFilesList = function() {
  return this.setFacilityFilesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.VMIFacilityRemoveFileRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.VMIFacilityRemoveFileRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.VMIFacilityRemoveFileRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.VMIFacilityRemoveFileRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    siteId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    fileId: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.VMIFacilityRemoveFileRequest}
 */
proto.vtv1.VMIFacilityRemoveFileRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.VMIFacilityRemoveFileRequest;
  return proto.vtv1.VMIFacilityRemoveFileRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.VMIFacilityRemoveFileRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.VMIFacilityRemoveFileRequest}
 */
proto.vtv1.VMIFacilityRemoveFileRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSiteId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setFileId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.VMIFacilityRemoveFileRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.VMIFacilityRemoveFileRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.VMIFacilityRemoveFileRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.VMIFacilityRemoveFileRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSiteId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFileId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * optional string site_id = 1;
 * @return {string}
 */
proto.vtv1.VMIFacilityRemoveFileRequest.prototype.getSiteId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.VMIFacilityRemoveFileRequest} returns this
 */
proto.vtv1.VMIFacilityRemoveFileRequest.prototype.setSiteId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 file_id = 2;
 * @return {number}
 */
proto.vtv1.VMIFacilityRemoveFileRequest.prototype.getFileId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.VMIFacilityRemoveFileRequest} returns this
 */
proto.vtv1.VMIFacilityRemoveFileRequest.prototype.setFileId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


goog.object.extend(exports, proto.vtv1);
