<div class="fixed bottom-0 right-0 mr-5 z-50 bg-white shadow" style="height: 75vh; width: 350px;" *ngIf="!hide">
  <div class="flex flex-col h-full">
    <div class="border-b border-gray-400 bg-blue-900" (click)="toggleHide()">
      <div class="flex align-center p-2">
        <img src="/assets/images/vorto-logo.svg" alt="vorto-logo" />
        <span class="font-bold text-lg text-white pl-2">Vorto Assistant</span>
      </div>
    </div>
    <span class="absolute cursor-pointer" style="right: 1rem; top: 1rem;" (click)="toggleHide()"
      ><mat-icon class="text-white">close</mat-icon></span
    >
    <div class="flex-1 p-1 overflow-y-auto" #scroller>
      <div class="w-full bg-green-400 text-white rounded p-1 mb-1">
        Hello! Please type your request into the box below and tell me how I can assist you.
        <div class="flex flex-col mt-2">
          <div>Some Common Shortcuts</div>
          <ng-container *vtHasAccountFeature="'edge'">
            <a class="underline pl-1" routerLink="/carrier_shipments/create_shipment">New Shipment</a>
            <a class="underline pl-1" routerLink="/carrier/calendar/week">Shift Schedule</a>
            <a class="underline pl-1" routerLink="/users/drivers/new">Invite Driver</a>
          </ng-container>
          <ng-container *vtHasAccountFeature="'node'"> </ng-container>
          <a class="underline pl-1 cursor-pointer" (click)="openIntercom()">Contact Support</a>
        </div>
      </div>
      <ng-container *ngFor="let chat of chat$ | async; trackBy: 'message' | trackByKey">
        <div class="flex justify-end" *ngIf="chat.sender === 'client'">
          <div class="w-4/5 bg-green-400 text-white rounded p-1 mb-1">
            <div [innerHTML]="chat.message | safeHtml"></div>
            <a *ngIf="chat.localUrl && chat.localUrl.length" [routerLink]="chat.localUrl">{{ chat.localUrlText }}</a>
          </div>
        </div>
        <div class="flex justify-start" *ngIf="chat.sender === 'bot'">
          <div class="w-4/5 bg-gray-100 rounded p-1 mb-1">
            <div [innerHTML]="chat.message | safeHtml"></div>
            <a *ngIf="chat.localUrl && chat.localUrl.length" [routerLink]="chat.localUrl" class="text-indigo-500">{{
              chat.localUrlText
            }}</a>
          </div>
        </div>
      </ng-container>
    </div>
    <div class="min-h-7 flex items-center pl-1 pr-1 border-t border-gray-400">
      <input
        class="rounded-sm border border-gray-400 flex-1 p-1 mr-1"
        #chatBotInput
        type="text"
        [formControl]="formControl"
        placeholder="Enter Message"
        (keydown.enter)="sendMessage()"
        (keydown.escape)="escapeKey($event)"
      />
      <button ct-button (click)="sendMessage()">Send</button>
    </div>
  </div>
</div>
<div class="fixed rounded-full w-6 h-6" style="right: 8px; bottom: 8px;">
  <div
    *ngIf="hide"
    (click)="toggleHide()"
    ctHotkey="h"
    (hotkeyPressed)="toggleHide()"
    class="bg-indigo-500 cursor-pointer flex items-center justify-center text-white w-full h-full rounded-full"
  >
    <mat-icon>chat</mat-icon>
  </div>
</div>
