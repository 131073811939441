<div
  class="ml-1 mr-1 md:ml-5 md:mr-5 mt-3 h-full flex flex-col"
  [vtPageTitle]="'Profile'"
  *ngIf="myUserInfo$ | async as currentUser"
>
  <div>
    <div class="flex flex-col" style="height: 300px;">
      <div class="flex items-end border-b border-gray-400 pb-3">
        <div class="flex flex-col ml-1">
          <span class="text-gray-900 font-bold text-3xl">{{ currentUser.accountName }}</span
          ><span class="text-gray-700">{{ currentUser.name }}</span>
        </div>
      </div>
      <div class="mt-3">
        <div class="pt-3 h-full flex flex-col w-full">
          <main class="">
            <!-- <mat-accordion [multi]="true" -->
            <div class="mat-elevation-z0 w-1/2">
              <!-- <mat-expansion-panel-header> -->
              <div class="flex items-center">
                <div class="font-bold">Purchase Order (PO)</div>
              </div>
              <!-- </mat-expansion-panel-header> -->
              <!-- <div class="mb-3">
            <mat-selection-list #ordersSelection> -->
              <div
                class="flex flex-col justify-between mt-2 first:mt-0 rounded-sm w-full p-2 cursor-pointer"
                *ngIf="accountSettings$ | async as settings"
                (click)="intercomService.show()"
              >
                <mat-slide-toggle [disabled]="true" [checked]="settings.useVortoPoNumber"
                  ><span>Use Vorto Generated PO Number</span></mat-slide-toggle
                >
                <mat-hint class="text-xs pl-5 text-gray-600"
                  >Contact Vorto support if you would like to change this setting.</mat-hint
                >
              </div>
              <!-- </mat-selection-list> -->
            </div>

            <!-- </mat-expansion-panel> -->
            <!-- </mat-accordion> -->
          </main>
        </div>
      </div>
    </div>
  </div>
</div>
