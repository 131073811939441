import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { shareReplay, throttleTime } from 'rxjs/operators';

export class SimpleFinn<T> {
  protected networkActive$$ = new BehaviorSubject<boolean>(false);
  public networkActive$ = this.networkActive$$.asObservable().pipe(shareReplay(1));
  protected bSubject$$: BehaviorSubject<T>;
  private throttle$$ = new Subject<void>();
  private readonly shared$: Observable<T>;
  protected loadFunc: () => Promise<T>;

  constructor(initValue: T = null, loadFunc: () => Promise<T>) {
    this.bSubject$$ = new BehaviorSubject<T>(initValue);
    this.shared$ = this.bSubject$$.asObservable().pipe(shareReplay(1));
    this.loadFunc = loadFunc;
    this.throttle$$.pipe(throttleTime(1000)).subscribe({
      next: async () => {
        try {
          this.networkActive$$.next(true);
          const res = await this.loadFunc();
          this.bSubject$$.next(res);
        } finally {
          this.networkActive$$.next(false);
        }
      },
      error: () => {
        this.networkActive$$.next(false);
      },
    });
  }

  public get$(): Observable<T> {
    this.throttle$$.next();
    return this.shared$;
  }

  public next(value: T) {
    this.bSubject$$.next(value);
  }
}

export class SimpleFinnPaginated<T extends { previousPageRequest?: M; nextPageRequest?: M }, M> extends SimpleFinn<T> {
  constructor(
    private initValue: T = null,
    private initialLoadFunc: () => Promise<T>,
    private pageLoadFunc: (M) => Promise<T>,
    private converterFunc: (M) => any,
  ) {
    super(initValue, initialLoadFunc);
  }

  public async nextPage() {
    if (!this.bSubject$$.value) {
      return;
    }
    try {
      this.networkActive$$.next(true);
      if (this.converterFunc) {
        const next = await this.pageLoadFunc(this.converterFunc(this.bSubject$$.value?.nextPageRequest));
        this.next(next);
      } else {
        const next = await this.pageLoadFunc(this.bSubject$$.value?.nextPageRequest);
        this.next(next);
      }
    } finally {
      this.networkActive$$.next(false);
    }
  }

  public async previousPage() {
    if (!this.bSubject$$.value) {
      return;
    }
    try {
      this.networkActive$$.next(true);
      if (this.converterFunc) {
        const next = await this.pageLoadFunc(this.converterFunc(this.bSubject$$.value?.previousPageRequest));
        this.next(next);
      } else {
        const next = await this.pageLoadFunc(this.bSubject$$.value?.previousPageRequest);
        this.next(next);
      }
    } finally {
      this.networkActive$$.next(false);
    }
  }

  public async updateFilter(req: any) {
    try {
      this.networkActive$$.next(true);
      const updated = await this.pageLoadFunc(req);
      this.next(updated);
    } finally {
      this.networkActive$$.next(false);
    }
  }
}
