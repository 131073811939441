<div
  class="ml-1 mr-1 md:ml-5 md:mr-5 mt-3 h-full flex flex-col"
  [vtPageTitle]="'Profile'"
  *ngIf="myUserInfo$ | async as currentUser"
>
  <div>
    <div class="flex flex-col" *ngIf="myUserInfo$ | async">
      <div class="flex items-end border-b border-gray-400 pb-3">
        <div class="flex flex-col ml-1">
          <span class="text-gray-900 font-bold text-3xl">{{ currentUser.name }}</span
          ><span class="text-gray-700">{{ (myUserInfo$ | async).accountName }}</span>
        </div>
      </div>
      <div class="mt-3">
        <mat-tab-nav-panel #navPanel>
          <nav mat-tab-nav-bar [tabPanel]="navPanel" mat-stretch-tabs="false">
            <a
              mat-tab-link
              [routerLink]="['./', 'notifications']"
              routerLinkActive
              #open="routerLinkActive"
              [active]="open.isActive"
              [routerLinkActiveOptions]="{ exact: false }"
            >
              <span>Notification Settings</span>
            </a>
            <a
              *ngIf="hasMultipleAccounts$ | async"
              mat-tab-link
              [routerLink]="['./', 'current_account']"
              routerLinkActive
              #currentAccount="routerLinkActive"
              [active]="currentAccount.isActive"
              [routerLinkActiveOptions]="{ exact: false }"
            >
              <span>Switch Account</span>
            </a>
          </nav>
        </mat-tab-nav-panel>
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</div>
