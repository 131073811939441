export const idArrayToRecord = <T extends { id: number | string }>(tArray: T[]): Record<string, T> => {
  if (!tArray) {
    return {};
  }
  return tArray.reduce((record: Record<string, T>, t: T) => {
    record[t.id] = t;
    return record;
  }, {});
};

export const accountIdArrayToRecord = <T extends { accountId: number | string }>(tArray: T[]): Record<string, T> => {
  if (!tArray) {
    return {};
  }
  return tArray.reduce((record: Record<string, T>, t: T) => {
    record[t.accountId] = t;
    return record;
  }, {});
};
