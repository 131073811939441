import { Injectable } from '@angular/core';
import { Permission } from '~proto/user/user_pb';
import { AccountFeature } from '~proto/account/account_pb';
import { BehaviorSubject, combineLatest, lastValueFrom, Observable } from 'rxjs';
import { GrpcService } from './grpc.service';
import { shareReplay, map, take, filter } from 'rxjs/operators';
import { UserService } from '~proto/user/user_api_pb_service';
import { DriverCertification, Empty, StringIdName, TrailerType } from '~proto/types/types_pb';
import { NGXLogger } from 'ngx-logger';
import { nullableStringToNullOrString } from '../../shared/utilities/protoConverters';
import { AuthService } from './auth.service';
import { sort } from 'remeda';
import { sortByName } from '../../shared/utilities/commonSorters';
import { AccountService } from '~proto/account/account_pb_service';
import { MyUserInformationResponse } from '~proto/user/user_api_pb';
import { GlobalService } from '~proto/global/global_api_pb_service';
import * as LogRocket from 'logrocket';
import { EmailTokenService } from './email-token.service';

export type PermissionWithNulls = Omit<Permission.AsObject, 'description'> & {
  description: string | null;
};

@Injectable({
  providedIn: 'root',
})
export class InitializationService {
  private statusText$$ = new BehaviorSubject<string>(null);
  public statusText$ = this.statusText$$.asObservable().pipe(shareReplay(1));

  private networkActive$$ = new BehaviorSubject<number>(0);
  public networkActive$ = this.networkActive$$.asObservable().pipe(
    map((activeConnections) => activeConnections !== 0),
    shareReplay(1),
  );

  private permissions$$ = new BehaviorSubject<PermissionWithNulls[]>([]);
  public permissions$ = this.permissions$$.asObservable().pipe(shareReplay(1));

  private accountFeatures$$ = new BehaviorSubject<AccountFeature.AsObject[]>([]);
  public accountFeatures$ = this.accountFeatures$$.asObservable().pipe(shareReplay(1));

  private myUserInfo$$ = new BehaviorSubject<MyUserInformationResponse.AsObject>(null);
  public myUserInfo$ = this.myUserInfo$$.asObservable().pipe(shareReplay(1));

  private trailerTypes$$ = new BehaviorSubject<TrailerType.AsObject[]>([]);
  public trailerTypes$ = this.trailerTypes$$.asObservable().pipe(shareReplay(1));

  private driverCerts$$ = new BehaviorSubject<DriverCertification.AsObject[]>([]);
  public driverCerts$ = this.driverCerts$$.asObservable().pipe(shareReplay(1));

  private myAllowedAccounts$$ = new BehaviorSubject<StringIdName.AsObject[]>([]);
  public myAllowedAccounts$ = this.myAllowedAccounts$$.asObservable().pipe(shareReplay(1));

  constructor(
    private grpc: GrpcService,
    private logger: NGXLogger,
    private auth: AuthService,
    emailTokenService: EmailTokenService,
  ) {
    combineLatest([auth.isLoggedIn$, emailTokenService.emailToken$])
      .pipe(
        map(([loggedIn, emailToken]) => loggedIn || !!emailToken),
        filter((loggedIn) => loggedIn),
        take(1),
      )
      .subscribe(() => {
        this.loadPermissions();
        this.loadAccountFeatures();
        this.loadMoreAccountThingsAndStuff(); // neat
        this.loadTrailerTypes();
        this.loadDriverCerts();
        this.loadAllowedAccounts();
      });
  }

  public filterOnlyNode() {
    return (source: Observable<any>) => {
      let isNode = false;
      return new Observable((observer) => {
        return source.subscribe(
          async (value) => {
            if (isNode) {
              observer.next(value);
            }
            const myUserInfo = await lastValueFrom(
              this.myUserInfo$.pipe(
                filter((userInfo) => !!userInfo),
                take(1),
              ),
            );
            if (!myUserInfo || myUserInfo.accountFeaturesList.length === 0) {
              return;
            }
            if (myUserInfo.accountFeaturesList.includes('node')) {
              isNode = true;
              observer.next(value);
            }
          },
          (err) => observer.error(err),
          () => observer.complete(),
        );
      });
    };
  }

  public filterOnlyEdge() {
    return (source: Observable<any>) => {
      let isEdge = false;
      return new Observable((observer) => {
        return source.subscribe(
          async (value) => {
            if (isEdge) {
              observer.next(value);
            }
            const myUserInfo = await lastValueFrom(
              this.myUserInfo$.pipe(
                filter((userInfo) => !!userInfo),
                take(1),
              ),
            );
            if (!myUserInfo || myUserInfo.accountFeaturesList.length === 0) {
              return;
            }
            if (myUserInfo.accountFeaturesList.includes('edge')) {
              isEdge = true;
              observer.next(value);
            }
          },
          (err) => observer.error(err),
          () => observer.complete(),
        );
      });
    };
  }

  private async loadPermissions() {
    this.networkActive$$.next(this.networkActive$$.value + 1);
    try {
      const permissions = await lastValueFrom(this.grpc.invoke$(UserService.ListPermissions, new Empty(), true));
      const asPermissionWithNulls = permissions.toObject().permissionsList.map((permission) => ({
        ...permission,
        description: nullableStringToNullOrString(permission.description),
      }));
      const sorted = sort(asPermissionWithNulls, sortByName);
      this.permissions$$.next(sorted);
      this.statusText$$.next('Loaded Permissions');
    } catch (error) {
      this.logger.error(error);
    } finally {
      this.networkActive$$.next(this.networkActive$$.value - 1);
    }
  }
  private async loadAccountFeatures() {
    this.networkActive$$.next(this.networkActive$$.value + 1);
    try {
      const features = await lastValueFrom(this.grpc.invoke$(AccountService.ListAccountFeatures, new Empty(), true));
      const sorted = sort(features.toObject().accountFeaturesList, sortByName);
      this.accountFeatures$$.next(sorted);
      this.statusText$$.next('Loaded Account Features');
    } catch (error) {
      this.logger.error(error);
    } finally {
      this.networkActive$$.next(this.networkActive$$.value - 1);
    }
  } // end loadAccountFeatures

  private async loadMoreAccountThingsAndStuff() {
    this.networkActive$$.next(this.networkActive$$.value + 1);
    try {
      const myUserInfo = await lastValueFrom(this.grpc.invoke$(UserService.GetMyUserInfo, new Empty(), true));
      const user = myUserInfo.toObject();
      this.myUserInfo$$.next(user);
      this.statusText$$.next('Loaded My User Information');
      LogRocket.identify(user.userId, {
        name: user.name,
        email: user.email,
        // Add your own custom user variables here, ie:
        account: user.accountName,
        accountFeatures: user.accountFeaturesList.join(', '),
        userPermissions: user.userPermissionsList.join(', '),
        createAt: user.createdAt,
      });
    } catch (error) {
      // If we can't get the user info, we need to try to login again
      this.auth.logout();
      this.logger.error(error);
    } finally {
      this.networkActive$$.next(this.networkActive$$.value - 1);
    }
  } // end loadMoreAccountThingsAndStuff

  private async loadTrailerTypes() {
    this.networkActive$$.next(this.networkActive$$.value + 1);
    try {
      const trailerTypes = await lastValueFrom(this.grpc.invoke$(GlobalService.ListTrailerTypes, new Empty(), true));
      this.trailerTypes$$.next(trailerTypes.toObject().trailerTypesList);
      this.statusText$$.next('Loaded Trailer Types');
    } catch (error) {
      this.logger.error(error);
    } finally {
      this.networkActive$$.next(this.networkActive$$.value - 1);
    }
  } // end loadTrailerTypes

  private async loadDriverCerts() {
    this.networkActive$$.next(this.networkActive$$.value + 1);
    try {
      const driverCerts = await lastValueFrom(
        this.grpc.invoke$(GlobalService.ListDriverCertifications, new Empty(), true),
      );
      this.driverCerts$$.next(driverCerts.toObject().certificationsList.sort((a, b) => a.name.localeCompare(b.name)));
      this.statusText$$.next('Loaded Driver Certs');
    } catch (error) {
      this.logger.error(error);
    } finally {
      this.networkActive$$.next(this.networkActive$$.value - 1);
    }
  } // end loadTrailerTypes

  private async loadAllowedAccounts() {
    this.networkActive$$.next(this.networkActive$$.value + 1);
    try {
      const allowedAccounts = await lastValueFrom(
        this.grpc.invoke$(UserService.GetMyAllowedAccounts, new Empty(), true),
      );
      this.myAllowedAccounts$$.next(
        allowedAccounts.toObject().accountsList.sort((a, b) => a.name.localeCompare(b.name)),
      );
      this.statusText$$.next('Loaded Allowed Accounts');
    } catch (error) {
      this.logger.error(error);
    } finally {
      this.networkActive$$.next(this.networkActive$$.value - 1);
    }
  }
} // end initializationService
