import { Pipe, PipeTransform } from '@angular/core';
import { OrderDetails } from '~proto/order/order_pb';
import { OrderStatus, OrderStatusMap } from '~proto/types/types_pb';

const prettyNameMap: Record<OrderStatusMap[keyof OrderStatusMap], string> = {
  0: 'Unknown',
  1: 'In Progress',
  2: 'Cancelled',
  3: 'Completed',
  4: 'Pending',
};

@Pipe({
  name: 'statusOrder',
})
export class StatusOrderPipe implements PipeTransform {
  public transform(status: OrderStatusMap[keyof OrderStatusMap]): string {
    return prettyNameMap[status];
  }
}
