import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter',
})
export class FilterPipe implements PipeTransform {
  public transform<T>(value: T[], key: keyof T, valueToFilterFor: any): T[] {
    return value?.filter((value1) => value1[key] === valueToFilterFor);
  }
}
