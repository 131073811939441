import { Injectable } from '@angular/core';
import { interval } from 'rxjs';
import { SwUpdate } from '@angular/service-worker';

@Injectable({
  providedIn: 'root',
})
export class VersioningService {
  constructor(private swUpdate: SwUpdate) {
    if (window.location.hostname === 'localhost') {
      return;
    }
    if (this.swUpdate.isEnabled) {
      this.swUpdate.checkForUpdate();
    } else {
    }

    // Check every 5 minutes if there is an update
    interval(5 * 60 * 1000).subscribe(() => {
      if (this.swUpdate.isEnabled) {
        this.swUpdate.checkForUpdate();
      }
    });
  }
}
