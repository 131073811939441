import { Pipe, PipeTransform } from '@angular/core';
import { isDate, isValid, add, isBefore } from 'date-fns';

@Pipe({
  name: 'isAfterInMinutes',
})
export class IsAfterInMinutes implements PipeTransform {
  public transform(value: Date | string, minuteToAdd: number): boolean {
    if (!value) {
      return false;
    }
    if (typeof value === 'string') {
      value = new Date(value);
    }
    let asDate: Date = value;
    if (!isDate(value)) {
      asDate = new Date(value);
    }
    if (!isValid(asDate)) {
      return false;
    }
    const newDate = new Date();
    const tempDate = add(newDate, { minutes: minuteToAdd });
    return isBefore(asDate, tempDate);
  }
}
