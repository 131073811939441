import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { chunk } from 'remeda';

@Component({
  selector: 'vt-extended-nato',
  templateUrl: './extended-nato.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExtendedNatoComponent {
  public copyOfWord: string;
  public natoReading: string[];

  @Input() public set id(id: string) {
    this.copyOfWord = id;
    this.natoReading = getNatoReading(id);
  }
}

// const natoExtendedMap = {
//   0: 'ZERO',
//   1: 'ONE',
//   2: 'TWO',
//   3: 'THREE',
//   4: 'FOUR',
//   5: 'FIVE',
//   6: 'SIX',
//   7: 'SEVEN',
//   8: 'EIGHT',
//   9: 'NINE',
//   a: 'ALPHA',
//   b: 'BRAVO',
//   c: 'CHARLIE',
//   d: 'DELTA',
//   e: 'ECHO',
//   f: 'FOXTROT',
//   g: 'GOLF',
//   h: 'HOTEL',
//   i: 'INDIA',
//   j: 'JULIET',
//   k: 'KILO',
//   l: 'LIMA',
//   m: 'MIKE',
//   n: 'NOVEMBER',
//   o: 'OSCAR',
//   p: 'PAPA',
//   q: 'QUEBEC',
//   r: 'ROMEO',
//   s: 'SIERRA',
//   t: 'TANGO',
//   u: 'UNIFORM',
//   v: 'VICTOR',
//   w: 'WHISKEY',
//   x: 'RAY',
//   y: 'YANKEE',
//   z: 'ZULU',
// };

const natoExtendedMap = {
  0: 'Zero',
  1: 'One',
  2: 'Two',
  3: 'Three',
  4: 'Four',
  5: 'Five',
  6: 'Six',
  7: 'Seven',
  8: 'Eight',
  9: 'Nine',
  a: 'Alpha',
  b: 'Bravo',
  c: 'Charlie',
  d: 'Delta',
  e: 'Echo',
  f: 'Foxtrot',
  g: 'Golf',
  h: 'Hotel',
  i: 'India',
  j: 'Juliet',
  k: 'Kilo',
  l: 'Lima',
  m: 'Mike',
  n: 'November',
  o: 'Oscar',
  p: 'Papa',
  q: 'Quebec',
  r: 'Romeo',
  s: 'Sierra',
  t: 'Tango',
  u: 'Uniform',
  v: 'Victor',
  w: 'Whiskey',
  x: 'Ray',
  y: 'Yankee',
  z: 'Zulu',
};

function getNatoReading(word: string): string[] {
  const asNato = word.split('').map((c) => natoExtendedMap[c.toLowerCase()]);
  const chunked = chunk(asNato.reverse(), 4); // We want the first set of letters to have the odd number of characters so reverse
  const backInNormalOrder = chunked.reverse().map((ch) => ch.reverse());
  const chunksAsString = backInNormalOrder.map((ch) => ch.join(' '));
  return chunksAsString;
}
