import { Pipe, PipeTransform } from '@angular/core';

interface TrackByFunctionCache {
  [propertyName: string]: (index: number, item: any) => any;
}

const cache: TrackByFunctionCache = Object.create(null);

@Pipe({
  name: 'trackByKey',
})
export class TrackByKey implements PipeTransform {
  public transform<T>(propertyName: string): (index: number, item: any) => any {
    return getTracker(propertyName);
  }
}

export function getTracker<T>(key: keyof T): (index: number, item: T) => any {
  if (key === null) {
    return trackByMemoryReference;
  }
  // Ensure cached function exists.
  if (!cache[key as any]) {
    cache[key as any] = function trackByProperty(index: number, item: T): any {
      return item[key];
    };
  }

  return cache[key as any];
}

function trackByMemoryReference<T>(index: number, item: T): T {
  return item;
}
