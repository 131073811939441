import { Component, OnInit, ViewChild, HostListener, ElementRef } from '@angular/core';
import { InitializationService } from './singleton/services/initialization.service';
import { Observable, BehaviorSubject, timer, race, lastValueFrom } from 'rxjs';
import { RouterStateService } from './singleton/services/router-state-service.service';
import { AuthService } from './singleton/services/auth.service';
import { IntercomService } from './intercom.service';
import { MatDrawer } from '@angular/material/sidenav';
import * as LogRocket from 'logrocket';
import { environment } from '~environments/environment';
import { VERSION } from '~environments/version';
import { EmailTokenService } from './singleton/services/email-token.service';
import { FeatureFlagService } from './singleton/service/feature-flag.service';
import { TakeoverComponent } from './shared/_layout/takeover/takeover.component';
import { map, pluck, shareReplay, take } from 'rxjs/operators';
import { ResponseFromEmailService } from './modules/node/spot-market-response-from-email/response-from-email.service';
import { Router } from '@angular/router';
import { MyUserInformationResponse } from '~proto/user/user_api_pb';
import { MatDialog } from '@angular/material/dialog';

const bypassAuthRoutes = ['/signup'];

@Component({
  selector: 'vt-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  @ViewChild('chatBotInput', { static: false }) public input: ElementRef<HTMLInputElement>;
  @ViewChild('sideNav') public drawer: MatDrawer;
  public isLoadingMeta$: Observable<boolean>;
  public loadingMessage$: Observable<string>;
  private signingUp$$ = new BehaviorSubject<boolean>(false);
  public signingUp$: Observable<boolean> = this.signingUp$$.pipe(shareReplay(1));
  public isLoggedIn$$ = new BehaviorSubject<boolean>(false);
  // Router State Service isn't used here but this makes sure it always has the latest route
  // Same with intercom service
  public userInfo$: Observable<MyUserInformationResponse.AsObject>;
  public auth0ErrorMessage$: Observable<string>;

  constructor(
    private metaService: InitializationService,
    private routerState: RouterStateService,
    private router: Router,
    private intercomService: IntercomService,
    private spotMarketService: ResponseFromEmailService,
    private featureFlagsService: FeatureFlagService,
    private authService: AuthService,
    private matDialog: MatDialog,
    private emailTokenService: EmailTokenService,
  ) {
    // For fake sparkline data
    (window as any).activateSparklines = () => {
      localStorage.setItem('fakeSparklines', 'true');
    };

    (window as any).deactivateSparklines = () => {
      localStorage.removeItem('fakeSparklines');
    };

    // For fake detention data
    (window as any).setDetention = (siteId: string, price: number) => {
      if (price) {
        localStorage.setItem(`DETENTION_${siteId}`, Math.round(price * 100).toString());
      } else {
        localStorage.removeItem(`DETENTION_${siteId}`);
      }
    };
  }

  public ngOnInit() {
    this.loadingMessage$ = this.metaService.statusText$;
    this.isLoadingMeta$ = this.metaService.networkActive$;
    this.userInfo$ = this.metaService.myUserInfo$;
    this.auth0ErrorMessage$ = this.authService.errorMessage$;
    this.checkLogin();
    this.enableLogRocket();
  }

  private async checkLogin() {
    const authLoggedIn = await this.authService.isLoggedIn();
    const tokenLoggedIn = await this.emailTokenService.emailToken();
    const spotMarketCode = await lastValueFrom(
      race(
        this.routerState.routerState$.pipe(
          map((state) => state.queryParams),
          take(1),
          pluck('spotMarketQuoteCode'),
        ),
        timer(50),
      ),
    );
    const route = await lastValueFrom(
      race(
        this.routerState.routerState$.pipe(
          map((value) => value.url),
          take(1),
        ),
        timer(50).pipe(map(() => '')),
      ),
    );
    if (!authLoggedIn && !tokenLoggedIn && !spotMarketCode && !bypassAuthRoutes.includes(route)) {
      this.authService.login();
    } else if (bypassAuthRoutes.includes(route)) {
      this.signingUp$$.next(true);
      return;
    } else if (!spotMarketCode && spotMarketCode !== 0 && spotMarketCode !== undefined) {
      this.router.navigateByUrl(`/spot_market_response_from_email?spotMarketQuoteCode=${spotMarketCode}`);
    } else {
      this.isLoggedIn$$.next(true);
    }
  }

  private enableLogRocket() {
    if (window.location.hostname === 'localhost') {
      return;
    }
    const logRocketOptions = {
      release: this.getVersion(),
    };
    if (environment.production) {
      LogRocket.init('stkslu/vorto-reload-prod', logRocketOptions);
    } else {
      LogRocket.init('stkslu/vorto-reload-stage', logRocketOptions);
    }
  }

  @HostListener('document:keydown', ['$event'])
  public slashKey(event: KeyboardEvent) {
    if ((event.target as HTMLElement).tagName !== 'BODY') {
      return;
    }
    if (event.key === '/') {
      return this.drawer.toggle();
    }
    if (event.key.toLowerCase() === 's') {
      return this.openTakeover();
    }
  }

  public async openTakeover() {
    const accountInfo = await lastValueFrom(this.metaService.myUserInfo$.pipe(take(1)));
    if (
      !accountInfo.accountFeaturesList.filter((value) => value === 'node').length ||
      !!accountInfo.accountFeaturesList.filter((value) => value === 'lite').length ||
      accountInfo.isCpg
    ) {
      return;
    }
    this.matDialog.open(TakeoverComponent, {
      closeOnNavigation: true,
      panelClass: ['w-full', 'h-full', 'absolute', 'top-0', 'left-0', 'bg-blue-900', 'max-w-full', 'p-2'],
      hasBackdrop: false,
      height: '100%',
      width: '100%',
      maxHeight: '100%',
      maxWidth: '100%',
      autoFocus: true,
      position: {
        left: '0px',
        top: '0px',
      },
      // backdropClass: 'w-full h-full absolute top-0 left-0 bg-blue-900',
    });
  }

  private getVersion(): string {
    if (VERSION && VERSION.version) {
      if (VERSION.hash) {
        return `${VERSION.version}.${VERSION.hash}`;
      }
      return VERSION.version;
    }
  }

  public logOut() {
    this.authService.forceLogout();
    //window.location.reload();
  }
}
