import { Injectable } from '@angular/core';
import { from, of, Observable, BehaviorSubject, combineLatest, throwError, lastValueFrom } from 'rxjs';
import { distinctUntilChanged, filter, take, map, switchMapTo, shareReplay } from 'rxjs/operators';
import { Router } from '@angular/router';
import { NGXLogger } from 'ngx-logger';
import { environment } from '~environments/environment';
import { Auth0Client, createAuth0Client } from '@auth0/auth0-spa-js';

export interface Profile {
  'https://app.vorto.ai/userId': string;
  'https://app.vorto.ai/accountId': string;
  'https://app.vorto.ai/permissions': number[];
  'https://app.vorto.ai/accountFeatures': number[];
  'https://app.vorto.ai/regions': number[];
  'https://app.vorto.ai/canCrudUsers': boolean;
  nickname: string;
  name: string;
  picture: string;
  updated_at: Date;
  email: string;
  email_verified: boolean;
  sub: string;
}

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private partsInitialized$$ = new BehaviorSubject<number>(0);
  public isInitialized$: Observable<boolean> = this.partsInitialized$$.asObservable().pipe(map((count) => count === 2));
  private client: Auth0Client;
  private isLoggedIn$$ = new BehaviorSubject<boolean>(false);
  private hasHandledAuthCallback$$ = new BehaviorSubject<boolean>(false);
  // Create an observable of Auth0 instance of client

  public isLoggedIn$ = this.isInitialized$.pipe(
    filter((initialized) => initialized),
    switchMapTo(this.isLoggedIn$$),
    shareReplay(1),
  );

  private errorMessage$$ = new BehaviorSubject<string>(null);
  public errorMessage$: Observable<string> = this.errorMessage$$.pipe(shareReplay(1));

  constructor(private router: Router, private logger: NGXLogger) {
    this.doSetup();
  }

  public async login(redirectPath: string = '/') {
    // A desired redirect path can be passed to login method
    // (e.g., from a route guard)
    // Ensure Auth0 client instance exists
    await this.waitForInitialized();
    this.client.loginWithRedirect({
      authorizationParams: {
        redirect_uri: `${window.location.origin}`,
        environment: environment.production ? 'prod' : 'stage',
      },
      appState: { target: redirectPath },
    });
  }

  public async logout() {
    await this.waitForInitialized();
    this.client.logout({
      clientId: environment.auth0Config.clientId,
      logoutParams: {
        returnTo: `${window.location.origin}`,
      },
    });
  }

  public async getTokenSilently(): Promise<string> {
    await this.waitForInitialized();
    if (this.isLoggedIn$$.value) {
      return this.client.getTokenSilently();
    } else {
      return null;
    }
  }

  public isLoggedIn(): Promise<boolean> {
    return lastValueFrom(this.isLoggedIn$.pipe(take(1)));
  }

  public waitForInitialized(): Promise<boolean> {
    return lastValueFrom(
      this.isInitialized$.pipe(
        filter((initialized) => initialized),
        take(1),
      ),
    );
  }

  private async doSetup() {
    try {
      const client = await createAuth0Client(environment.auth0Config as any);

      this.client = client;

      await this.handleAuthCallback();
      await this.localAuthSetup();
    } catch (error) {
      this.logger.error(error);
      return throwError(error);
    }
  }

  private async handleAuthCallback() {
    // Call when app reloads after user logs in with Auth0
    const params = window.location.search;
    const urlParams = new URLSearchParams(params);
    if (params.includes('code=') && params.includes('state=')) {
      try {
        // Path to redirect to after login processsed
        const cbRes = await this.client.handleRedirectCallback();
        const targetRoute = cbRes.appState && cbRes.appState.target ? cbRes.appState.target : '/';
        this.partsInitialized$$.next(this.partsInitialized$$.value + 1);
        this.router.navigate([targetRoute]);
      } catch (error) {
        this.logger.error(error);
      }
    } else {
      if (urlParams.has('error') && urlParams.has('error_description')) {
        if (urlParams.get('error_description') === 'expired_password') {
          this.errorMessage$$.next('You need to update your password!');
        }
        throw new Error(urlParams.get('error_description'));
      }
      this.partsInitialized$$.next(this.partsInitialized$$.value + 1);
    }
  }

  private async localAuthSetup() {
    // This should only be called on app initialization
    // Set up local authentication streams
    const isLoggedIn = await this.client.isAuthenticated();
    this.isLoggedIn$$.next(isLoggedIn);
    this.partsInitialized$$.next(this.partsInitialized$$.value + 1);
  }

  public async forceLogout() {
    this.client.logout({
      clientId: environment.auth0Config.clientId,
      logoutParams: {
        returnTo: `${window.location.origin}`,
      },
    });
  }
}
