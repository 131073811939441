import { Injectable } from '@angular/core';
import { GrpcService } from '../../singleton/services/grpc.service';
import { GlobalSearchRequest } from '~proto/node/node_api_pb';
import { NodeService } from '~proto/node/node_api_pb_service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class SearchService {
  constructor(private grpc: GrpcService) {}

  public search(query: string) {
    const request = new GlobalSearchRequest();
    request.setQuery(query);
    return this.grpc.invoke$(NodeService.GlobalSearch, request).pipe(map((value) => value.toObject()));
  }
}
