// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const pollingEnabledKey = 'VORTO_POLLING_ENABLED';

export const environment = {
  production: false,
  get api() {
    const storageAPIURL = localStorage.getItem('VORTO_API_URL');
    if (storageAPIURL) {
      return storageAPIURL;
    }
    return 'https://stage.api.vorto.ai';
  },
  intercomId: 'lqotdbzg',
  get pollingEnabled(): boolean {
    const refreshInBackground = getSessionThenLocalWithDefault(pollingEnabledKey, 'true');
    return refreshInBackground.toLowerCase() === 'true';
  },
  auth0Config: {
    domain: 'vortostage.us.auth0.com',
    clientId: 'VIK6GE3oT9CNMJC8pHvDP17H9QYUgpLo',
    authorizationParams: {
      redirect_uri: `${window.location.origin}`,
      environment: 'stage',
      audience: 'https://api.vorto.ai',
    },
    cacheLocation: `localstorage`,
    useRefreshTokens: true,
  },
};

function getSessionThenLocalWithDefault(key: string, defaultValue: string): string {
  const sessionValue = sessionStorage.getItem(key);
  if (sessionValue) {
    return sessionValue;
  }

  const localValue = localStorage.getItem(key);
  if (localValue) {
    return localValue;
  }

  return defaultValue;
}

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
