<ng-container *vtIsCpg="false; else cpgSideNav">
  <ng-container *vtHasAccountFeature="['node', 'edge']; not: 'lite'">
    <div class="flex flex-col bg-gray-100 h-full overflow-y-auto p-3">
      <div class="flex flex-row justify-between items-center">
        <img
          routerLink="/"
          class="cursor-pointer focus:outline-none"
          style="height: 18px"
          src="./assets/images/nav/vorto_gray.svg"
        />
        <span class="flex items-center cursor-pointer text-gray-600"
          >esc&nbsp;<mat-icon class="text-gray-600">close</mat-icon></span
        >
      </div>
      <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 w-full gap-3 mt-3">
        <div *vtHasAccountFeature="'node'" class="bg-white shadow flex flex-col p-3 w-auto">
          <div class="flex flex-row gap-x-1 mb-1 items-center">
            <img src="./assets/images/nav/side-nav-v2/supply-chain.svg" height="34" width="34" />
            <div class="text-xl font-semibold">Supply Chain</div>
          </div>
          <a routerLink="/facility_dashboard" class="text-gray-900 w-full py-1 text-lg">Inbound</a>
          <a routerLink="/outbound" class="text-gray-900 w-full py-1 text-lg">Outbound</a>
          <a *vtFeatureFlag="'equipmentOrders'" routerLink="/equipment-orders" class="text-gray-900 w-full py-1 text-lg"
            >Equipment Orders</a
          >
          <a
            *vtFeatureFlag="'pendingFacilities'"
            routerLink="/pending-facilities"
            class="text-gray-900 w-full py-1 text-lg"
            >Pending Facilities</a
          >
        </div>
        <div *vtHideFromInventoryOnly class="bg-white shadow flex flex-col p-3 w-auto">
          <div class="flex flex-row gap-x-1 mb-1 items-center">
            <img src="./assets/images/nav/side-nav-v2/trucking.svg" height="34" width="34" />
            <div class="text-xl font-semibold">Trucking Logistics</div>
          </div>
          <a
            *vtHasAccountFeature="'edge'"
            routerLink="/carrier_shipments/shipments/pending_list"
            class="text-gray-900 w-full py-1 text-lg"
            >Private Fleet Dispatch</a
          >
          <a *vtHasAccountFeature="'edge'" routerLink="/carrier/calendar/week" class="text-gray-900 w-full py-1 text-lg"
            >Private Fleet Driver Schedule</a
          >
          <a routerLink="/billing-v2" class="text-gray-900 w-full py-1 text-lg">Third Party Carrier Billing</a>
        </div>
        <ng-container *vtHideFromInventoryOnly>
          <div *vtHasAccountFeature="'node'" class="bg-white shadow flex flex-col p-3 w-auto">
            <div class="flex flex-row gap-x-1 mb-1 items-center">
              <img src="./assets/images/nav/side-nav-v2/product-procurement.svg" height="34" width="34" />
              <div class="text-xl font-semibold">Product Procurement</div>
            </div>
            <a routerLink="/spot_market" class="text-gray-900 w-full py-1 text-lg">Product Marketplace</a>
            <a routerLink="/supplier-billing/consumer" class="text-gray-900 w-full py-1 text-lg">Supplier Billing</a>
            <a routerLink="/spot_market/list/landing" class="text-gray-900 w-full py-1 text-lg"
              >AutoForecast For Suppliers</a
            >
          </div>
        </ng-container>
        <div class="bg-white shadow flex flex-col p-3 w-auto">
          <div class="flex flex-row gap-x-1 mb-1 items-center">
            <img src="./assets/images/nav/side-nav-v2/rti.svg" height="34" width="34" />
            <div class="text-xl font-semibold">Real Time Visibility & Reporting</div>
          </div>
          <a *vtHasAccountFeature="'node'" routerLink="/consumer/orders/list" class="text-gray-900 w-full py-1 text-lg"
            >All Orders</a
          >
          <ng-container *vtHideFromInventoryOnly>
            <a routerLink="/billing/invoicing" class="text-gray-900 w-full py-1 text-lg">Billing Dashboard</a>
            <a href="https://rti.shaleapps.com/login" target="_blank" class="text-gray-900 w-full py-1 text-lg">
              Real Time Intelligence
            </a>
          </ng-container>
        </div>
        <div *vtHasAccountFeature="'node'" class="bg-white shadow flex flex-col p-3 w-auto">
          <div class="flex flex-row gap-x-1 mb-1 items-center">
            <img src="./assets/images/nav/side-nav-v2/ecommerce.svg" height="34" width="34" />
            <div class="text-xl font-semibold">E-Commerce</div>
          </div>
          <a routerLink="/fulfillment/shop" class="text-gray-900 w-full py-1 text-lg">Shop</a>
          <a routerLink="/fulfillment/fulfillment" class="text-gray-900 w-full py-1 text-lg">Manage Fulfillment</a>
        </div>
        <div class="bg-white shadow flex flex-col p-3 w-auto">
          <div class="flex flex-row gap-x-1 mb-1 items-center">
            <img src="./assets/images/nav/side-nav-v2/settings.svg" height="34" width="34" />
            <div class="text-xl font-semibold">Settings</div>
          </div>
          <a routerLink="/profile" class="text-gray-900 w-full py-1 text-lg">Notifications</a>
          <a *vtHideFromInventoryOnly routerLink="/users" class="text-gray-900 w-full py-1 text-lg">Manage Users</a>
          <ng-container *vtHasAccountFeature="'node'">
            <a *vtHideFromInventoryOnly routerLink="/setup" class="text-gray-900 w-full py-1 text-lg">
              Setup Facilities, Suppliers, & Carriers
            </a>
          </ng-container>
          <ng-container *ngIf="hasMultipleAccounts$ | async">
            <a routerLink="/profile/current_account" class="text-gray-900 w-full py-1 text-lg"> Switch Account </a>
          </ng-container>
        </div>
        <div class="flex flex-col lg:mt-4 col-span-1 md:col-span-2 lg:col-span-3">
          <div class="flex flex-row gap-x-1 mb-1 items-center cursor-pointer" (click)="intercomService.show()">
            <img src="./assets/images/nav/side-nav-v2/support.svg" height="34" width="34" />
            <div class="text-xl font-semibold">Contact Support</div>
          </div>
          <!--<a
            href="https://vorto.ghost.io/tag/reload/"
            target="_blank"
            class="flex flex-row gap-x-1 mb-1 items-center cursor-pointer"
          >
            <img src="./assets/images/nav/side-nav-v2/updates.svg" height="34" width="34" />
            <div class="text-xl font-semibold">Recent Software Updates</div>
          </a>-->
        </div>
      </div>
      <div
        class="mt-auto flex items-center flex-row justify-between w-full mt-3"
        *ngIf="myUserInfo$ | async as userProfile"
      >
        <a class="flex flex-col border-gray-700" routerLink="/profile">
          <span class="font-semibold text-sm">{{ userProfile.name }}</span>
          <span class="text-sm">{{ userProfile.accountName }}</span>
        </a>
        <div class="flex flex-row gap-x-1 cursor-pointer" (click)="logout()">
          <div class="font-semibold" style="color: #ef6048">Sign Out</div>
          <img src="/assets/images/nav/side-nav-v2/signout.svg" />
        </div>
      </div>
    </div>
  </ng-container>

  <ng-container *vtHasAccountFeature="'lite'">
    <div class="flex flex-col bg-white h-full p-1 pr-2 pl-2">
      <div class="flex h-3 mt-3">
        <img
          routerLink="/"
          class="cursor-pointer focus:outline-none"
          style="height: 18px"
          src="./assets/images/nav/vorto_gray.svg"
        />
        <span class="flex items-center absolute cursor-pointer text-gray-600" style="top: 24px; right: 24px"
          >esc&nbsp;<mat-icon class="text-gray-600">close</mat-icon></span
        >
      </div>
      <div class="flex flex-col mt-3 pb-2">
        <a routerLink="/lite/order" class="mb-2">
          <vt-button color="bigIndigo">
            <div class="flex flex-row items-center gap-x-1">
              <div>
                <img src="assets/images/lite/nav/create-order.svg" />
              </div>
              <div>Create Order</div>
            </div>
          </vt-button>
        </a>
        <a
          routerLink="/consumer/orders/list"
          class="items-center grid grid-cols-10 hover:bg-indigo-100 p-1 rounded mt-1"
          ><img class="mr-1 col-span-1" src="./assets/images/lite/nav/billing.svg" />
          <span class="text-sm font-medium col-span-6"> All Orders </span>
        </a>
        <a routerLink="/billing-v2/dashboard/ap" class="items-center grid grid-cols-10 hover:bg-indigo-100 p-1 rounded"
          ><img class="mr-1 col-span-1" src="./assets/images/lite/nav/all-orders.svg" />
          <span class="text-sm font-medium col-span-6"> Billing Logistics </span>
        </a>
        <a
          href="https://rti.shaleapps.com/boards/26"
          target="_blank"
          class="items-center grid grid-cols-10 hover:bg-indigo-100 p-1 rounded"
        >
          <img class="mr-1 col-span-1" src="./assets/images/lite/nav/custom-reporting.svg" />
          <span class="text-sm font-medium col-span-6">Custom Reporting</span>
        </a>
        <ng-container *ngIf="hasMultipleAccounts$ | async">
          <a routerLink="/profile/current_account" class="text-gray-900 w-full py-1 text-lg">
            <img class="mr-1 col-span-1 opacity-0" src="./assets/images/lite/nav/custom-reporting.svg" />
            <span class="text-sm font-medium col-span-6">Switch Account</span>
          </a>
        </ng-container>
      </div>
      <div class="flex flex-col pt-2 border-t border-gray-600">
        <div class="p-2 bg-indigo-50 text-sm rounded mb-2 cursor-pointer" (click)="intercomService.show()">
          <span class="font-semibold text-indigo-500"> Upgrade your account </span>
          for access to all of Vorto ReLOAD's exclusive tools.
        </div>

        <a disabled class="items-center grid grid-cols-10 p-1 rounded mt-1">
          <img class="mr-1 col-span-1" src="./assets/images/lite/nav/real-time-visibility.svg" />
          <span class="text-sm font-medium col-span-6">Real Time Visibility</span>
        </a>

        <a disabled class="items-center grid grid-cols-10 p-1 rounded mt-1">
          <img class="mr-1 col-span-1" src="./assets/images/lite/nav/fleet-scheduling.svg" />
          <span class="text-sm font-medium col-span-6">Fleet Scheduling</span>
        </a>

        <a disabled class="items-center grid grid-cols-10 p-1 rounded mt-1">
          <img class="mr-1 col-span-1" src="./assets/images/lite/nav/automated-sourcing.svg" />
          <span class="text-sm font-medium col-span-6">Automated Sourcing</span>
        </a>

        <a disabled class="items-center grid grid-cols-10 p-1 rounded mt-1">
          <img class="mr-1 col-span-1" src="./assets/images/lite/nav/automated-ordering.svg" />
          <span class="text-sm font-medium col-span-6">Automated Ordering</span>
        </a>
      </div>
      <div style="bottom: 12px; width: 320px" class="absolute flex flex flex-col">
        <a routerLink="/setup" class="items-center grid grid-cols-10 p-1 hover:bg-indigo-100 rounded cursor-pointer">
          <span class="text-sm font-semibold col-span-6">Setup</span>
        </a>
        <div
          class="items-center grid grid-cols-10 p-1 hover:bg-indigo-100 rounded cursor-pointer"
          (click)="intercomService.show()"
        >
          <span class="text-sm font-semibold col-span-6">Support</span>
        </div>
        <ng-container *vtFeatureFlag="'releaseNotesLites'">
          <div
            id="releaseNotesLites"
            class="items-center grid grid-cols-10 p-1 hover:bg-indigo-100 rounded cursor-pointer"
          >
            <div class="col-span-1"><span id="releaseNotesLiteBadge" class="h-2"></span></div>
            <span class="col-span-6 text-sm text-gray-100 font-semibold"> Release Notes </span>
          </div>
        </ng-container>
        <div
          class="flex flex-row justify-between py-2 mt-2 border-t border-gray-600 w-full"
          *ngIf="myUserInfo$ | async as userProfile"
        >
          <a class="flex flex-col pl-1" routerLink="/profile">
            <span class="font-semibold text-sm">{{ userProfile.name }}</span>
            <span class="text-sm">{{ userProfile.accountName }}</span>
          </a>
          <vt-button (click)="logout()" color="altIndigo">
            <img src="assets/images/lite/nav/logout.svg" />
          </vt-button>
        </div>
      </div>
    </div>
  </ng-container>
</ng-container>
<ng-template #cpgSideNav>
  <vt-cpg-side-nav></vt-cpg-side-nav>
</ng-template>
