import { Directive, Input, OnDestroy, TemplateRef, ChangeDetectorRef, ViewContainerRef } from '@angular/core';
import { InitializationService } from '../../singleton/services/initialization.service';
import { Subject, BehaviorSubject, combineLatest } from 'rxjs';
import { AuthService } from '../../singleton/services/auth.service';
import { takeUntil, startWith } from 'rxjs/operators';
import { AccountFeature } from '~proto/account/account_pb';

@Directive({
  selector: '[vtHasAccountFeature]',
})
export class HasAccountFeatureDirective implements OnDestroy {
  private hasView = false;
  private destroy$$ = new Subject<void>();
  private accountFeature$$ = new BehaviorSubject<(string | number)[]>(null);
  private notAccountFeatures$$ = new BehaviorSubject<string[]>(null);

  @Input() public set vtHasAccountFeature(accountFeatures: string | string[]) {
    if (!Array.isArray(accountFeatures)) {
      this.accountFeature$$.next([accountFeatures]);
    } else {
      this.accountFeature$$.next(accountFeatures);
    }
  }

  @Input() public set vtHasAccountFeatureNot(accountFeatures: string | string[]) {
    if (!Array.isArray(accountFeatures)) {
      this.notAccountFeatures$$.next([accountFeatures]);
    } else {
      this.notAccountFeatures$$.next(accountFeatures);
    }
  }

  constructor(
    private meta: InitializationService,
    private auth: AuthService,
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private cd: ChangeDetectorRef,
  ) {
    this.listenForViewUpdates();
  }

  public ngOnDestroy() {
    this.destroy$$.next();
    this.destroy$$.unsubscribe();
  }

  private listenForViewUpdates() {
    combineLatest([
      this.meta.accountFeatures$.pipe(startWith(null as any)),
      this.meta.myUserInfo$,
      this.accountFeature$$,
      this.notAccountFeatures$$,
    ])
      .pipe(takeUntil(this.destroy$$))
      .subscribe(([accountFeatures, userProfile, accountFeature, notAccountFeatures]) => {
        const featureIds = getFeatureIds(accountFeatures, accountFeature);
        const notFeatureIds = getFeatureIds(accountFeatures, notAccountFeatures);
        if (!featureIds) {
          this.clearView();
        } else {
          let userAccountFeatures: number[] = [];
          if (userProfile && Array.isArray(userProfile.accountFeaturesList)) {
            userAccountFeatures = getFeatureIds(accountFeatures, userProfile.accountFeaturesList);
          }
          if (userHasFeature(userAccountFeatures, featureIds) && !userHasFeature(userAccountFeatures, notFeatureIds)) {
            this.renderView();
          } else {
            this.clearView();
          }
        }
      });
  }

  private renderView() {
    if (!this.hasView) {
      this.viewContainer.createEmbeddedView(this.templateRef);
      this.hasView = true;
      this.cd.markForCheck();
    }
  }

  private clearView() {
    if (this.hasView) {
      this.viewContainer.clear();
      this.hasView = false;
      this.cd.markForCheck();
    }
  }
}

function getFeatureIds(features: AccountFeature.AsObject[], localFeatures: (string | number)[]): number[] | null {
  if (!features || !localFeatures) {
    return null;
  }
  const featureIds: number[] = [];
  localFeatures.forEach((feature) => {
    const key: keyof AccountFeature.AsObject = 'name';
    const targetedFeature = (features || []).find((f) => f[key] === feature);
    if (targetedFeature) {
      featureIds.push(targetedFeature.id);
    }
  });
  return featureIds;
}

function userHasFeature(accountFeatures: number[], featureIds: number[]) {
  return (accountFeatures || []).some((accountFeature) =>
    (featureIds || []).some((featureId) => featureId === accountFeature),
  );
}
