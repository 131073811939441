import { Directive, ElementRef, AfterViewInit } from '@angular/core';

// workaround for https://issues.chromium.org/issues/335553723
// remove when Chrome issue is fixed
@Directive({
  selector: 'mat-label',
})
export class RemoveAriaOwnsDirective implements AfterViewInit {
  constructor(private el: ElementRef) {}

  public ngAfterViewInit() {
    this.el.nativeElement.parentElement.removeAttribute('aria-owns');
  }
}
