import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanLoad,
  Route,
  RouterStateSnapshot,
  UrlSegment,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { InitializationService } from '../../singleton/services/initialization.service';
import { filter, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class NotInventoryOnlyGuard implements CanActivate, CanLoad {
  constructor(private initializationService: InitializationService) {}

  public canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.initializationService.myUserInfo$.pipe(
      filter((v) => !!v),
      map((v) => v.inventoryOnly === false),
    );
  }

  public canLoad(
    route: Route,
    segments: UrlSegment[],
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.initializationService.myUserInfo$.pipe(
      filter((v) => !!v),
      map((v) => v.inventoryOnly === false),
    );
  }
}
