<ng-container *ngIf="(isLoggedIn$$ | async) || (signingUp$ | async); else loggingYouIn">
  <mat-drawer-container class="" [hasBackdrop]="true">
    <mat-drawer
      class="w-full lg:w-1/4"
      [ngClass]="
        (userInfo$ | async)?.accountFeaturesList.includes('lite') === false && !(userInfo$ | async)?.isCpg
          ? ['lg:w-3/4']
          : ['lg:w-1/4']
      "
      (click)="sideNav.toggle()"
      #sideNav
      mode="over"
    >
      <vt-side-nav-menu></vt-side-nav-menu>
    </mat-drawer>
    <mat-drawer-content>
      <img
        *ngIf="userInfo$ | async"
        src="/assets/images/menu.svg"
        class="absolute text-white cursor-pointer hover:bg-indigo-800 rounded p-1 print:hidden"
        style="left: 40px; top: 3px;"
        (click)="sideNav.toggle()"
      />
      <div class="flex flex-col h-screen print:h-auto print:break-inside-avoid">
        <vt-nav class="flex-initial"></vt-nav>
        <div class="router-outlet flex-1 overflow-hidden print:overflow-visible print:block flex flex-col">
          <router-outlet></router-outlet>
        </div>
      </div>
    </mat-drawer-content>
  </mat-drawer-container>
  <vt-versioning></vt-versioning>
</ng-container>
<ng-template #loggingYouIn>
  <div
    class="top-0 left-0 bottom-0 right-0 h-screen bg-white flex flex-col items-center justify-center gap-y-1"
    *ngIf="auth0ErrorMessage$ | async as errMsg; else loggingIn"
  >
    <div class="font-semibold text-lg">{{ errMsg }}</div>
    <div>
      Click logout and then click "Forgot password?" to update your password.
    </div>
    <div>
      Need help updating your password?
      <a href="mailto:reloadhelp@vorto.ai" class="text-indigo-500 underline">Contact Support</a>
    </div>
    <div>
      <vt-button (click)="logOut()">
        Logout
      </vt-button>
    </div>
  </div>
  <ng-template #loggingIn>
    <div class="top-0 left-0 bottom-0 right-0 h-screen bg-white flex items-center justify-center">
      <div>Setting Up Authentication</div>
    </div>
  </ng-template>
</ng-template>
