// source: proto/vorto/node/node.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var proto_vorto_types_types_pb = require('../../../proto/vorto/types/types_pb.js');
goog.object.extend(proto, proto_vorto_types_types_pb);
goog.exportSymbol('proto.vtv1.APIStockUpdate', null, global);
goog.exportSymbol('proto.vtv1.ArrivedOrderSummary', null, global);
goog.exportSymbol('proto.vtv1.AtEdgeOrderSummary', null, global);
goog.exportSymbol('proto.vtv1.AtSupplierOrderSummary', null, global);
goog.exportSymbol('proto.vtv1.AutopilotFeed', null, global);
goog.exportSymbol('proto.vtv1.AutopilotFeedType', null, global);
goog.exportSymbol('proto.vtv1.CancelledOrderSummary', null, global);
goog.exportSymbol('proto.vtv1.CreateOrderSite', null, global);
goog.exportSymbol('proto.vtv1.DashboardEdgeResponse', null, global);
goog.exportSymbol('proto.vtv1.DashboardSiteResponse', null, global);
goog.exportSymbol('proto.vtv1.DashboardSupplierResponse', null, global);
goog.exportSymbol('proto.vtv1.DeliverableSite', null, global);
goog.exportSymbol('proto.vtv1.DemandSignal', null, global);
goog.exportSymbol('proto.vtv1.DemandSignalDetails', null, global);
goog.exportSymbol('proto.vtv1.DemandSignalDetailsRequest', null, global);
goog.exportSymbol('proto.vtv1.DemandSignalDetailsRequest.Status', null, global);
goog.exportSymbol('proto.vtv1.DemandSignalRequestResponse', null, global);
goog.exportSymbol('proto.vtv1.FinalStateOrderSummary', null, global);
goog.exportSymbol('proto.vtv1.FuelMatrix', null, global);
goog.exportSymbol('proto.vtv1.FuelMatrixEntry', null, global);
goog.exportSymbol('proto.vtv1.InProgressOrderSummary', null, global);
goog.exportSymbol('proto.vtv1.InboundInventory', null, global);
goog.exportSymbol('proto.vtv1.Inventory', null, global);
goog.exportSymbol('proto.vtv1.InventoryCurrentRFQ', null, global);
goog.exportSymbol('proto.vtv1.InventoryDataSet', null, global);
goog.exportSymbol('proto.vtv1.InventoryDatapoint', null, global);
goog.exportSymbol('proto.vtv1.InventoryDemand', null, global);
goog.exportSymbol('proto.vtv1.InventoryDetail', null, global);
goog.exportSymbol('proto.vtv1.InventoryDetailShipment', null, global);
goog.exportSymbol('proto.vtv1.InventoryDetailShipments', null, global);
goog.exportSymbol('proto.vtv1.InventoryDetailSlim', null, global);
goog.exportSymbol('proto.vtv1.InventoryDetailSlimSharedWith', null, global);
goog.exportSymbol('proto.vtv1.InventoryDetailSlimStocks', null, global);
goog.exportSymbol('proto.vtv1.InventoryGroup', null, global);
goog.exportSymbol('proto.vtv1.InventoryIssuesListItem', null, global);
goog.exportSymbol('proto.vtv1.InventoryListItem', null, global);
goog.exportSymbol('proto.vtv1.InventoryScheduledRFQ', null, global);
goog.exportSymbol('proto.vtv1.InventorySharingPermission', null, global);
goog.exportSymbol('proto.vtv1.InventoryStockDatapoint', null, global);
goog.exportSymbol('proto.vtv1.InventorySubscriber', null, global);
goog.exportSymbol('proto.vtv1.InventorySupplyPlan', null, global);
goog.exportSymbol('proto.vtv1.InventorySupplyPlanMarketInvite', null, global);
goog.exportSymbol('proto.vtv1.InventorySupplyPlanMarketPriceCheck', null, global);
goog.exportSymbol('proto.vtv1.InventorySupplyPlanPurchaseOrder', null, global);
goog.exportSymbol('proto.vtv1.InventorySupplyPlanPurchaseOrders', null, global);
goog.exportSymbol('proto.vtv1.InventorySupplyPlanSpotPurchasePlan', null, global);
goog.exportSymbol('proto.vtv1.InventorySupplyPlanSupplier', null, global);
goog.exportSymbol('proto.vtv1.InventorySupplyPlanSupplierGuide', null, global);
goog.exportSymbol('proto.vtv1.InventoryWithStock', null, global);
goog.exportSymbol('proto.vtv1.OrderDetailsFromDemandSignal', null, global);
goog.exportSymbol('proto.vtv1.OrderError', null, global);
goog.exportSymbol('proto.vtv1.OrderErrorType', null, global);
goog.exportSymbol('proto.vtv1.OrderSummaryBase', null, global);
goog.exportSymbol('proto.vtv1.OutboundInventory', null, global);
goog.exportSymbol('proto.vtv1.PendingOrder', null, global);
goog.exportSymbol('proto.vtv1.PendingPurchaseOrder', null, global);
goog.exportSymbol('proto.vtv1.PotentialInventoryPurchaseOrder', null, global);
goog.exportSymbol('proto.vtv1.PotentialInventoryPurchaseOrderV2', null, global);
goog.exportSymbol('proto.vtv1.PotentialInventoryPurchaseOrderV2LineItem', null, global);
goog.exportSymbol('proto.vtv1.PotentialInventorySupplier', null, global);
goog.exportSymbol('proto.vtv1.PurchaseOrderSummary', null, global);
goog.exportSymbol('proto.vtv1.PurchaseOrderSummaryAsSupplier', null, global);
goog.exportSymbol('proto.vtv1.RFQFrequencyType', null, global);
goog.exportSymbol('proto.vtv1.RestockStrategy', null, global);
goog.exportSymbol('proto.vtv1.RouteGuide', null, global);
goog.exportSymbol('proto.vtv1.RouteGuideEdge', null, global);
goog.exportSymbol('proto.vtv1.RouteGuideEdgePriority', null, global);
goog.exportSymbol('proto.vtv1.RouteGuideForCreateOrderV2', null, global);
goog.exportSymbol('proto.vtv1.RouteGuideSettings', null, global);
goog.exportSymbol('proto.vtv1.RouteGuideSpotEdgeContract', null, global);
goog.exportSymbol('proto.vtv1.RouteGuideUpsert', null, global);
goog.exportSymbol('proto.vtv1.RouteGuideUpsertData', null, global);
goog.exportSymbol('proto.vtv1.ScheduledOrderSummary', null, global);
goog.exportSymbol('proto.vtv1.SingleEdgeContractAsNode', null, global);
goog.exportSymbol('proto.vtv1.SiteWithPickUpProduct', null, global);
goog.exportSymbol('proto.vtv1.SiteWithPickupToday', null, global);
goog.exportSymbol('proto.vtv1.SiteWithStock', null, global);
goog.exportSymbol('proto.vtv1.SlimSiteShipments', null, global);
goog.exportSymbol('proto.vtv1.SupplierMappedProduct', null, global);
goog.exportSymbol('proto.vtv1.UpdateMultipleInventoryStock', null, global);
goog.exportSymbol('proto.vtv1.VMIFacilityDetail', null, global);
goog.exportSymbol('proto.vtv1.VMIFacilityDock', null, global);
goog.exportSymbol('proto.vtv1.VMIFacilityFile', null, global);
goog.exportSymbol('proto.vtv1.VMIFacilityListItem', null, global);
goog.exportSymbol('proto.vtv1.VMIInventoryListItem', null, global);
goog.exportSymbol('proto.vtv1.VMISlimFacility', null, global);
goog.exportSymbol('proto.vtv1.VMIUserAvailableForInventorySubscription', null, global);
goog.exportSymbol('proto.vtv1.VanguardDemandEntry', null, global);
goog.exportSymbol('proto.vtv1.VanguardHistoryEntry', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.DemandSignal = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.DemandSignal, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.DemandSignal.displayName = 'proto.vtv1.DemandSignal';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.DemandSignalRequestResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.DemandSignalRequestResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.DemandSignalRequestResponse.displayName = 'proto.vtv1.DemandSignalRequestResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.OrderSummaryBase = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.OrderSummaryBase.repeatedFields_, null);
};
goog.inherits(proto.vtv1.OrderSummaryBase, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.OrderSummaryBase.displayName = 'proto.vtv1.OrderSummaryBase';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.AtEdgeOrderSummary = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.AtEdgeOrderSummary.repeatedFields_, null);
};
goog.inherits(proto.vtv1.AtEdgeOrderSummary, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.AtEdgeOrderSummary.displayName = 'proto.vtv1.AtEdgeOrderSummary';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.AtSupplierOrderSummary = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.AtSupplierOrderSummary.repeatedFields_, null);
};
goog.inherits(proto.vtv1.AtSupplierOrderSummary, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.AtSupplierOrderSummary.displayName = 'proto.vtv1.AtSupplierOrderSummary';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.OrderError = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.OrderError, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.OrderError.displayName = 'proto.vtv1.OrderError';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.ScheduledOrderSummary = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.ScheduledOrderSummary, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.ScheduledOrderSummary.displayName = 'proto.vtv1.ScheduledOrderSummary';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.InProgressOrderSummary = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.InProgressOrderSummary, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.InProgressOrderSummary.displayName = 'proto.vtv1.InProgressOrderSummary';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.ArrivedOrderSummary = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.ArrivedOrderSummary, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.ArrivedOrderSummary.displayName = 'proto.vtv1.ArrivedOrderSummary';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.FinalStateOrderSummary = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.FinalStateOrderSummary, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.FinalStateOrderSummary.displayName = 'proto.vtv1.FinalStateOrderSummary';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.CancelledOrderSummary = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.CancelledOrderSummary, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.CancelledOrderSummary.displayName = 'proto.vtv1.CancelledOrderSummary';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.PurchaseOrderSummary = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.PurchaseOrderSummary.repeatedFields_, null);
};
goog.inherits(proto.vtv1.PurchaseOrderSummary, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.PurchaseOrderSummary.displayName = 'proto.vtv1.PurchaseOrderSummary';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.PurchaseOrderSummaryAsSupplier = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.PurchaseOrderSummaryAsSupplier.repeatedFields_, null);
};
goog.inherits(proto.vtv1.PurchaseOrderSummaryAsSupplier, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.PurchaseOrderSummaryAsSupplier.displayName = 'proto.vtv1.PurchaseOrderSummaryAsSupplier';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.Inventory = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.Inventory, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.Inventory.displayName = 'proto.vtv1.Inventory';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.InventoryDetail = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.InventoryDetail.repeatedFields_, null);
};
goog.inherits(proto.vtv1.InventoryDetail, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.InventoryDetail.displayName = 'proto.vtv1.InventoryDetail';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.InventoryStockDatapoint = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.InventoryStockDatapoint, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.InventoryStockDatapoint.displayName = 'proto.vtv1.InventoryStockDatapoint';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.InventoryGroup = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.InventoryGroup.repeatedFields_, null);
};
goog.inherits(proto.vtv1.InventoryGroup, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.InventoryGroup.displayName = 'proto.vtv1.InventoryGroup';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.InventoryScheduledRFQ = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.InventoryScheduledRFQ, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.InventoryScheduledRFQ.displayName = 'proto.vtv1.InventoryScheduledRFQ';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.InventoryCurrentRFQ = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.InventoryCurrentRFQ, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.InventoryCurrentRFQ.displayName = 'proto.vtv1.InventoryCurrentRFQ';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.InventoryDemand = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.InventoryDemand.repeatedFields_, null);
};
goog.inherits(proto.vtv1.InventoryDemand, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.InventoryDemand.displayName = 'proto.vtv1.InventoryDemand';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.InventoryDataSet = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.InventoryDataSet.repeatedFields_, null);
};
goog.inherits(proto.vtv1.InventoryDataSet, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.InventoryDataSet.displayName = 'proto.vtv1.InventoryDataSet';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.InventoryDatapoint = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.InventoryDatapoint, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.InventoryDatapoint.displayName = 'proto.vtv1.InventoryDatapoint';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.InventorySupplyPlan = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.InventorySupplyPlan.repeatedFields_, null);
};
goog.inherits(proto.vtv1.InventorySupplyPlan, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.InventorySupplyPlan.displayName = 'proto.vtv1.InventorySupplyPlan';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.InventorySupplyPlanPurchaseOrders = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.InventorySupplyPlanPurchaseOrders.repeatedFields_, null);
};
goog.inherits(proto.vtv1.InventorySupplyPlanPurchaseOrders, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.InventorySupplyPlanPurchaseOrders.displayName = 'proto.vtv1.InventorySupplyPlanPurchaseOrders';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.InventorySupplyPlanPurchaseOrder = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.InventorySupplyPlanPurchaseOrder.repeatedFields_, null);
};
goog.inherits(proto.vtv1.InventorySupplyPlanPurchaseOrder, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.InventorySupplyPlanPurchaseOrder.displayName = 'proto.vtv1.InventorySupplyPlanPurchaseOrder';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.InventorySupplyPlanMarketPriceCheck = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.InventorySupplyPlanMarketPriceCheck, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.InventorySupplyPlanMarketPriceCheck.displayName = 'proto.vtv1.InventorySupplyPlanMarketPriceCheck';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.InventorySupplyPlanSpotPurchasePlan = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.InventorySupplyPlanSpotPurchasePlan.repeatedFields_, null);
};
goog.inherits(proto.vtv1.InventorySupplyPlanSpotPurchasePlan, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.InventorySupplyPlanSpotPurchasePlan.displayName = 'proto.vtv1.InventorySupplyPlanSpotPurchasePlan';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.InventorySupplyPlanMarketInvite = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.InventorySupplyPlanMarketInvite, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.InventorySupplyPlanMarketInvite.displayName = 'proto.vtv1.InventorySupplyPlanMarketInvite';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.InventorySupplyPlanSupplierGuide = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.InventorySupplyPlanSupplierGuide.repeatedFields_, null);
};
goog.inherits(proto.vtv1.InventorySupplyPlanSupplierGuide, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.InventorySupplyPlanSupplierGuide.displayName = 'proto.vtv1.InventorySupplyPlanSupplierGuide';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.InventorySupplyPlanSupplier = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.InventorySupplyPlanSupplier, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.InventorySupplyPlanSupplier.displayName = 'proto.vtv1.InventorySupplyPlanSupplier';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.PotentialInventorySupplier = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.PotentialInventorySupplier, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.PotentialInventorySupplier.displayName = 'proto.vtv1.PotentialInventorySupplier';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.DemandSignalDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.DemandSignalDetails.repeatedFields_, null);
};
goog.inherits(proto.vtv1.DemandSignalDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.DemandSignalDetails.displayName = 'proto.vtv1.DemandSignalDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.OrderDetailsFromDemandSignal = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.OrderDetailsFromDemandSignal, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.OrderDetailsFromDemandSignal.displayName = 'proto.vtv1.OrderDetailsFromDemandSignal';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.DemandSignalDetailsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.DemandSignalDetailsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.DemandSignalDetailsRequest.displayName = 'proto.vtv1.DemandSignalDetailsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.DashboardSiteResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.DashboardSiteResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.DashboardSiteResponse.displayName = 'proto.vtv1.DashboardSiteResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.DashboardEdgeResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.DashboardEdgeResponse.repeatedFields_, null);
};
goog.inherits(proto.vtv1.DashboardEdgeResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.DashboardEdgeResponse.displayName = 'proto.vtv1.DashboardEdgeResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.DashboardSupplierResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.DashboardSupplierResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.DashboardSupplierResponse.displayName = 'proto.vtv1.DashboardSupplierResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.AutopilotFeed = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.AutopilotFeed, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.AutopilotFeed.displayName = 'proto.vtv1.AutopilotFeed';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.SingleEdgeContractAsNode = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.SingleEdgeContractAsNode.repeatedFields_, null);
};
goog.inherits(proto.vtv1.SingleEdgeContractAsNode, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.SingleEdgeContractAsNode.displayName = 'proto.vtv1.SingleEdgeContractAsNode';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.DeliverableSite = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.DeliverableSite, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.DeliverableSite.displayName = 'proto.vtv1.DeliverableSite';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.RouteGuideForCreateOrderV2 = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.RouteGuideForCreateOrderV2, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.RouteGuideForCreateOrderV2.displayName = 'proto.vtv1.RouteGuideForCreateOrderV2';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.RouteGuide = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.RouteGuide.repeatedFields_, null);
};
goog.inherits(proto.vtv1.RouteGuide, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.RouteGuide.displayName = 'proto.vtv1.RouteGuide';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.RouteGuideUpsert = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.RouteGuideUpsert.repeatedFields_, null);
};
goog.inherits(proto.vtv1.RouteGuideUpsert, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.RouteGuideUpsert.displayName = 'proto.vtv1.RouteGuideUpsert';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.RouteGuideSettings = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.RouteGuideSettings, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.RouteGuideSettings.displayName = 'proto.vtv1.RouteGuideSettings';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.RouteGuideEdgePriority = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.RouteGuideEdgePriority, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.RouteGuideEdgePriority.displayName = 'proto.vtv1.RouteGuideEdgePriority';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.RouteGuideEdge = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.RouteGuideEdge, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.RouteGuideEdge.displayName = 'proto.vtv1.RouteGuideEdge';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.RouteGuideSpotEdgeContract = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.RouteGuideSpotEdgeContract, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.RouteGuideSpotEdgeContract.displayName = 'proto.vtv1.RouteGuideSpotEdgeContract';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.RouteGuideUpsertData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.RouteGuideUpsertData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.RouteGuideUpsertData.displayName = 'proto.vtv1.RouteGuideUpsertData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.SlimSiteShipments = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.SlimSiteShipments, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.SlimSiteShipments.displayName = 'proto.vtv1.SlimSiteShipments';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.UpdateMultipleInventoryStock = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.UpdateMultipleInventoryStock, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.UpdateMultipleInventoryStock.displayName = 'proto.vtv1.UpdateMultipleInventoryStock';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.SiteWithStock = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.SiteWithStock.repeatedFields_, null);
};
goog.inherits(proto.vtv1.SiteWithStock, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.SiteWithStock.displayName = 'proto.vtv1.SiteWithStock';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.InventoryWithStock = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.InventoryWithStock, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.InventoryWithStock.displayName = 'proto.vtv1.InventoryWithStock';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.InventoryListItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.InventoryListItem.repeatedFields_, null);
};
goog.inherits(proto.vtv1.InventoryListItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.InventoryListItem.displayName = 'proto.vtv1.InventoryListItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.InventoryIssuesListItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.InventoryIssuesListItem.repeatedFields_, null);
};
goog.inherits(proto.vtv1.InventoryIssuesListItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.InventoryIssuesListItem.displayName = 'proto.vtv1.InventoryIssuesListItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.InboundInventory = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.InboundInventory.repeatedFields_, null);
};
goog.inherits(proto.vtv1.InboundInventory, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.InboundInventory.displayName = 'proto.vtv1.InboundInventory';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.OutboundInventory = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.OutboundInventory, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.OutboundInventory.displayName = 'proto.vtv1.OutboundInventory';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.PendingPurchaseOrder = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.PendingPurchaseOrder.repeatedFields_, null);
};
goog.inherits(proto.vtv1.PendingPurchaseOrder, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.PendingPurchaseOrder.displayName = 'proto.vtv1.PendingPurchaseOrder';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.SupplierMappedProduct = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.SupplierMappedProduct, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.SupplierMappedProduct.displayName = 'proto.vtv1.SupplierMappedProduct';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.PendingOrder = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.PendingOrder.repeatedFields_, null);
};
goog.inherits(proto.vtv1.PendingOrder, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.PendingOrder.displayName = 'proto.vtv1.PendingOrder';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.SiteWithPickupToday = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.SiteWithPickupToday.repeatedFields_, null);
};
goog.inherits(proto.vtv1.SiteWithPickupToday, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.SiteWithPickupToday.displayName = 'proto.vtv1.SiteWithPickupToday';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.SiteWithPickUpProduct = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.SiteWithPickUpProduct, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.SiteWithPickUpProduct.displayName = 'proto.vtv1.SiteWithPickUpProduct';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.CreateOrderSite = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.CreateOrderSite, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.CreateOrderSite.displayName = 'proto.vtv1.CreateOrderSite';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.FuelMatrix = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.FuelMatrix.repeatedFields_, null);
};
goog.inherits(proto.vtv1.FuelMatrix, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.FuelMatrix.displayName = 'proto.vtv1.FuelMatrix';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.FuelMatrixEntry = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.FuelMatrixEntry, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.FuelMatrixEntry.displayName = 'proto.vtv1.FuelMatrixEntry';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.PotentialInventoryPurchaseOrder = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.PotentialInventoryPurchaseOrder.repeatedFields_, null);
};
goog.inherits(proto.vtv1.PotentialInventoryPurchaseOrder, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.PotentialInventoryPurchaseOrder.displayName = 'proto.vtv1.PotentialInventoryPurchaseOrder';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.PotentialInventoryPurchaseOrderV2 = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.PotentialInventoryPurchaseOrderV2.repeatedFields_, null);
};
goog.inherits(proto.vtv1.PotentialInventoryPurchaseOrderV2, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.PotentialInventoryPurchaseOrderV2.displayName = 'proto.vtv1.PotentialInventoryPurchaseOrderV2';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.PotentialInventoryPurchaseOrderV2LineItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.PotentialInventoryPurchaseOrderV2LineItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.PotentialInventoryPurchaseOrderV2LineItem.displayName = 'proto.vtv1.PotentialInventoryPurchaseOrderV2LineItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.VanguardDemandEntry = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.VanguardDemandEntry, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.VanguardDemandEntry.displayName = 'proto.vtv1.VanguardDemandEntry';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.VanguardHistoryEntry = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.VanguardHistoryEntry, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.VanguardHistoryEntry.displayName = 'proto.vtv1.VanguardHistoryEntry';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.APIStockUpdate = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.APIStockUpdate, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.APIStockUpdate.displayName = 'proto.vtv1.APIStockUpdate';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.InventoryDetailSlim = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.InventoryDetailSlim.repeatedFields_, null);
};
goog.inherits(proto.vtv1.InventoryDetailSlim, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.InventoryDetailSlim.displayName = 'proto.vtv1.InventoryDetailSlim';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.InventorySubscriber = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.InventorySubscriber, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.InventorySubscriber.displayName = 'proto.vtv1.InventorySubscriber';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.InventoryDetailSlimSharedWith = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.InventoryDetailSlimSharedWith, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.InventoryDetailSlimSharedWith.displayName = 'proto.vtv1.InventoryDetailSlimSharedWith';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.InventoryDetailSlimStocks = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.InventoryDetailSlimStocks.repeatedFields_, null);
};
goog.inherits(proto.vtv1.InventoryDetailSlimStocks, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.InventoryDetailSlimStocks.displayName = 'proto.vtv1.InventoryDetailSlimStocks';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.InventoryDetailShipments = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.InventoryDetailShipments.repeatedFields_, null);
};
goog.inherits(proto.vtv1.InventoryDetailShipments, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.InventoryDetailShipments.displayName = 'proto.vtv1.InventoryDetailShipments';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.InventoryDetailShipment = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.InventoryDetailShipment, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.InventoryDetailShipment.displayName = 'proto.vtv1.InventoryDetailShipment';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.VMIInventoryListItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.VMIInventoryListItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.VMIInventoryListItem.displayName = 'proto.vtv1.VMIInventoryListItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.VMIUserAvailableForInventorySubscription = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.VMIUserAvailableForInventorySubscription, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.VMIUserAvailableForInventorySubscription.displayName = 'proto.vtv1.VMIUserAvailableForInventorySubscription';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.VMISlimFacility = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.VMISlimFacility, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.VMISlimFacility.displayName = 'proto.vtv1.VMISlimFacility';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.VMIFacilityDetail = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.VMIFacilityDetail.repeatedFields_, null);
};
goog.inherits(proto.vtv1.VMIFacilityDetail, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.VMIFacilityDetail.displayName = 'proto.vtv1.VMIFacilityDetail';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.VMIFacilityDock = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.VMIFacilityDock, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.VMIFacilityDock.displayName = 'proto.vtv1.VMIFacilityDock';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.VMIFacilityListItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.VMIFacilityListItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.VMIFacilityListItem.displayName = 'proto.vtv1.VMIFacilityListItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.VMIFacilityFile = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.VMIFacilityFile, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.VMIFacilityFile.displayName = 'proto.vtv1.VMIFacilityFile';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.DemandSignal.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.DemandSignal.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.DemandSignal} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.DemandSignal.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    node: (f = msg.getNode()) && proto_vorto_types_types_pb.StringIdName.toObject(includeInstance, f),
    nodeSite: (f = msg.getNodeSite()) && proto_vorto_types_types_pb.Site.toObject(includeInstance, f),
    product: (f = msg.getProduct()) && proto_vorto_types_types_pb.Product.toObject(includeInstance, f),
    quantity: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    deliveryWindowStartsAt: jspb.Message.getFieldWithDefault(msg, 6, ""),
    deliveryWindowMinutes: jspb.Message.getFieldWithDefault(msg, 7, 0),
    updatedAt: jspb.Message.getFieldWithDefault(msg, 8, ""),
    createdAt: jspb.Message.getFieldWithDefault(msg, 9, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.DemandSignal}
 */
proto.vtv1.DemandSignal.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.DemandSignal;
  return proto.vtv1.DemandSignal.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.DemandSignal} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.DemandSignal}
 */
proto.vtv1.DemandSignal.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new proto_vorto_types_types_pb.StringIdName;
      reader.readMessage(value,proto_vorto_types_types_pb.StringIdName.deserializeBinaryFromReader);
      msg.setNode(value);
      break;
    case 3:
      var value = new proto_vorto_types_types_pb.Site;
      reader.readMessage(value,proto_vorto_types_types_pb.Site.deserializeBinaryFromReader);
      msg.setNodeSite(value);
      break;
    case 4:
      var value = new proto_vorto_types_types_pb.Product;
      reader.readMessage(value,proto_vorto_types_types_pb.Product.deserializeBinaryFromReader);
      msg.setProduct(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setQuantity(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeliveryWindowStartsAt(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDeliveryWindowMinutes(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setUpdatedAt(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.DemandSignal.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.DemandSignal.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.DemandSignal} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.DemandSignal.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getNode();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_vorto_types_types_pb.StringIdName.serializeBinaryToWriter
    );
  }
  f = message.getNodeSite();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto_vorto_types_types_pb.Site.serializeBinaryToWriter
    );
  }
  f = message.getProduct();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto_vorto_types_types_pb.Product.serializeBinaryToWriter
    );
  }
  f = message.getQuantity();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getDeliveryWindowStartsAt();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getDeliveryWindowMinutes();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
  f = message.getUpdatedAt();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getCreatedAt();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.vtv1.DemandSignal.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.DemandSignal} returns this
 */
proto.vtv1.DemandSignal.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional StringIdName node = 2;
 * @return {?proto.vtv1.StringIdName}
 */
proto.vtv1.DemandSignal.prototype.getNode = function() {
  return /** @type{?proto.vtv1.StringIdName} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.StringIdName, 2));
};


/**
 * @param {?proto.vtv1.StringIdName|undefined} value
 * @return {!proto.vtv1.DemandSignal} returns this
*/
proto.vtv1.DemandSignal.prototype.setNode = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.DemandSignal} returns this
 */
proto.vtv1.DemandSignal.prototype.clearNode = function() {
  return this.setNode(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.DemandSignal.prototype.hasNode = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Site node_site = 3;
 * @return {?proto.vtv1.Site}
 */
proto.vtv1.DemandSignal.prototype.getNodeSite = function() {
  return /** @type{?proto.vtv1.Site} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.Site, 3));
};


/**
 * @param {?proto.vtv1.Site|undefined} value
 * @return {!proto.vtv1.DemandSignal} returns this
*/
proto.vtv1.DemandSignal.prototype.setNodeSite = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.DemandSignal} returns this
 */
proto.vtv1.DemandSignal.prototype.clearNodeSite = function() {
  return this.setNodeSite(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.DemandSignal.prototype.hasNodeSite = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Product product = 4;
 * @return {?proto.vtv1.Product}
 */
proto.vtv1.DemandSignal.prototype.getProduct = function() {
  return /** @type{?proto.vtv1.Product} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.Product, 4));
};


/**
 * @param {?proto.vtv1.Product|undefined} value
 * @return {!proto.vtv1.DemandSignal} returns this
*/
proto.vtv1.DemandSignal.prototype.setProduct = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.DemandSignal} returns this
 */
proto.vtv1.DemandSignal.prototype.clearProduct = function() {
  return this.setProduct(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.DemandSignal.prototype.hasProduct = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional double quantity = 5;
 * @return {number}
 */
proto.vtv1.DemandSignal.prototype.getQuantity = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.DemandSignal} returns this
 */
proto.vtv1.DemandSignal.prototype.setQuantity = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional string delivery_window_starts_at = 6;
 * @return {string}
 */
proto.vtv1.DemandSignal.prototype.getDeliveryWindowStartsAt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.DemandSignal} returns this
 */
proto.vtv1.DemandSignal.prototype.setDeliveryWindowStartsAt = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional int64 delivery_window_minutes = 7;
 * @return {number}
 */
proto.vtv1.DemandSignal.prototype.getDeliveryWindowMinutes = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.DemandSignal} returns this
 */
proto.vtv1.DemandSignal.prototype.setDeliveryWindowMinutes = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional string updated_at = 8;
 * @return {string}
 */
proto.vtv1.DemandSignal.prototype.getUpdatedAt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.DemandSignal} returns this
 */
proto.vtv1.DemandSignal.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string created_at = 9;
 * @return {string}
 */
proto.vtv1.DemandSignal.prototype.getCreatedAt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.DemandSignal} returns this
 */
proto.vtv1.DemandSignal.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.DemandSignalRequestResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.DemandSignalRequestResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.DemandSignalRequestResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.DemandSignalRequestResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    demandSignal: (f = msg.getDemandSignal()) && proto.vtv1.DemandSignal.toObject(includeInstance, f),
    supplier: (f = msg.getSupplier()) && proto_vorto_types_types_pb.StringIdName.toObject(includeInstance, f),
    responseQuantity: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    supplierSite: (f = msg.getSupplierSite()) && proto_vorto_types_types_pb.Site.toObject(includeInstance, f),
    responseReceivedAt: (f = msg.getResponseReceivedAt()) && proto_vorto_types_types_pb.NullableTime.toObject(includeInstance, f),
    createdAt: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.DemandSignalRequestResponse}
 */
proto.vtv1.DemandSignalRequestResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.DemandSignalRequestResponse;
  return proto.vtv1.DemandSignalRequestResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.DemandSignalRequestResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.DemandSignalRequestResponse}
 */
proto.vtv1.DemandSignalRequestResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new proto.vtv1.DemandSignal;
      reader.readMessage(value,proto.vtv1.DemandSignal.deserializeBinaryFromReader);
      msg.setDemandSignal(value);
      break;
    case 3:
      var value = new proto_vorto_types_types_pb.StringIdName;
      reader.readMessage(value,proto_vorto_types_types_pb.StringIdName.deserializeBinaryFromReader);
      msg.setSupplier(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setResponseQuantity(value);
      break;
    case 5:
      var value = new proto_vorto_types_types_pb.Site;
      reader.readMessage(value,proto_vorto_types_types_pb.Site.deserializeBinaryFromReader);
      msg.setSupplierSite(value);
      break;
    case 6:
      var value = new proto_vorto_types_types_pb.NullableTime;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableTime.deserializeBinaryFromReader);
      msg.setResponseReceivedAt(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.DemandSignalRequestResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.DemandSignalRequestResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.DemandSignalRequestResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.DemandSignalRequestResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDemandSignal();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.vtv1.DemandSignal.serializeBinaryToWriter
    );
  }
  f = message.getSupplier();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto_vorto_types_types_pb.StringIdName.serializeBinaryToWriter
    );
  }
  f = message.getResponseQuantity();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getSupplierSite();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto_vorto_types_types_pb.Site.serializeBinaryToWriter
    );
  }
  f = message.getResponseReceivedAt();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto_vorto_types_types_pb.NullableTime.serializeBinaryToWriter
    );
  }
  f = message.getCreatedAt();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.vtv1.DemandSignalRequestResponse.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.DemandSignalRequestResponse} returns this
 */
proto.vtv1.DemandSignalRequestResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional DemandSignal demand_signal = 2;
 * @return {?proto.vtv1.DemandSignal}
 */
proto.vtv1.DemandSignalRequestResponse.prototype.getDemandSignal = function() {
  return /** @type{?proto.vtv1.DemandSignal} */ (
    jspb.Message.getWrapperField(this, proto.vtv1.DemandSignal, 2));
};


/**
 * @param {?proto.vtv1.DemandSignal|undefined} value
 * @return {!proto.vtv1.DemandSignalRequestResponse} returns this
*/
proto.vtv1.DemandSignalRequestResponse.prototype.setDemandSignal = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.DemandSignalRequestResponse} returns this
 */
proto.vtv1.DemandSignalRequestResponse.prototype.clearDemandSignal = function() {
  return this.setDemandSignal(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.DemandSignalRequestResponse.prototype.hasDemandSignal = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional StringIdName supplier = 3;
 * @return {?proto.vtv1.StringIdName}
 */
proto.vtv1.DemandSignalRequestResponse.prototype.getSupplier = function() {
  return /** @type{?proto.vtv1.StringIdName} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.StringIdName, 3));
};


/**
 * @param {?proto.vtv1.StringIdName|undefined} value
 * @return {!proto.vtv1.DemandSignalRequestResponse} returns this
*/
proto.vtv1.DemandSignalRequestResponse.prototype.setSupplier = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.DemandSignalRequestResponse} returns this
 */
proto.vtv1.DemandSignalRequestResponse.prototype.clearSupplier = function() {
  return this.setSupplier(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.DemandSignalRequestResponse.prototype.hasSupplier = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional double response_quantity = 4;
 * @return {number}
 */
proto.vtv1.DemandSignalRequestResponse.prototype.getResponseQuantity = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.DemandSignalRequestResponse} returns this
 */
proto.vtv1.DemandSignalRequestResponse.prototype.setResponseQuantity = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional Site supplier_site = 5;
 * @return {?proto.vtv1.Site}
 */
proto.vtv1.DemandSignalRequestResponse.prototype.getSupplierSite = function() {
  return /** @type{?proto.vtv1.Site} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.Site, 5));
};


/**
 * @param {?proto.vtv1.Site|undefined} value
 * @return {!proto.vtv1.DemandSignalRequestResponse} returns this
*/
proto.vtv1.DemandSignalRequestResponse.prototype.setSupplierSite = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.DemandSignalRequestResponse} returns this
 */
proto.vtv1.DemandSignalRequestResponse.prototype.clearSupplierSite = function() {
  return this.setSupplierSite(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.DemandSignalRequestResponse.prototype.hasSupplierSite = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional NullableTime response_received_at = 6;
 * @return {?proto.vtv1.NullableTime}
 */
proto.vtv1.DemandSignalRequestResponse.prototype.getResponseReceivedAt = function() {
  return /** @type{?proto.vtv1.NullableTime} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableTime, 6));
};


/**
 * @param {?proto.vtv1.NullableTime|undefined} value
 * @return {!proto.vtv1.DemandSignalRequestResponse} returns this
*/
proto.vtv1.DemandSignalRequestResponse.prototype.setResponseReceivedAt = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.DemandSignalRequestResponse} returns this
 */
proto.vtv1.DemandSignalRequestResponse.prototype.clearResponseReceivedAt = function() {
  return this.setResponseReceivedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.DemandSignalRequestResponse.prototype.hasResponseReceivedAt = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional string created_at = 7;
 * @return {string}
 */
proto.vtv1.DemandSignalRequestResponse.prototype.getCreatedAt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.DemandSignalRequestResponse} returns this
 */
proto.vtv1.DemandSignalRequestResponse.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.OrderSummaryBase.repeatedFields_ = [23,25,26,29];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.OrderSummaryBase.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.OrderSummaryBase.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.OrderSummaryBase} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.OrderSummaryBase.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    originSiteId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    originSiteName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    destinationSiteId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    destinationSiteName: jspb.Message.getFieldWithDefault(msg, 5, ""),
    productId: jspb.Message.getFieldWithDefault(msg, 6, ""),
    productName: jspb.Message.getFieldWithDefault(msg, 7, ""),
    unitId: jspb.Message.getFieldWithDefault(msg, 8, 0),
    unitName: jspb.Message.getFieldWithDefault(msg, 9, ""),
    unitAbbreviation: jspb.Message.getFieldWithDefault(msg, 10, ""),
    quantity: jspb.Message.getFloatingPointFieldWithDefault(msg, 11, 0.0),
    edgeId: (f = msg.getEdgeId()) && proto_vorto_types_types_pb.NullableString.toObject(includeInstance, f),
    edgeName: (f = msg.getEdgeName()) && proto_vorto_types_types_pb.NullableString.toObject(includeInstance, f),
    arrivalWindowStartsAt: (f = msg.getArrivalWindowStartsAt()) && proto_vorto_types_types_pb.NullableTime.toObject(includeInstance, f),
    arrivalWindowEndsAt: (f = msg.getArrivalWindowEndsAt()) && proto_vorto_types_types_pb.NullableTime.toObject(includeInstance, f),
    isAsap: jspb.Message.getBooleanFieldWithDefault(msg, 16, false),
    updatedAt: jspb.Message.getFieldWithDefault(msg, 17, ""),
    progressStatus: jspb.Message.getFieldWithDefault(msg, 18, 0),
    progressStatusUpdatedAt: jspb.Message.getFieldWithDefault(msg, 19, ""),
    supplier: jspb.Message.getBooleanFieldWithDefault(msg, 22, false),
    errorsList: jspb.Message.toObjectList(msg.getErrorsList(),
    proto.vtv1.OrderError.toObject, includeInstance),
    hasBackhaul: jspb.Message.getBooleanFieldWithDefault(msg, 24, false),
    productIdsList: (f = jspb.Message.getRepeatedField(msg, 25)) == null ? undefined : f,
    productNamesList: (f = jspb.Message.getRepeatedField(msg, 26)) == null ? undefined : f,
    fulfillmentId: (f = msg.getFulfillmentId()) && proto_vorto_types_types_pb.NullableString.toObject(includeInstance, f),
    trailerNumber: (f = msg.getTrailerNumber()) && proto_vorto_types_types_pb.NullableString.toObject(includeInstance, f),
    assetNamesList: (f = jspb.Message.getRepeatedField(msg, 29)) == null ? undefined : f,
    equipmentOrderId: (f = msg.getEquipmentOrderId()) && proto_vorto_types_types_pb.NullableString.toObject(includeInstance, f),
    originDistrictId: (f = msg.getOriginDistrictId()) && proto_vorto_types_types_pb.NullableString.toObject(includeInstance, f),
    destinationDistrictId: (f = msg.getDestinationDistrictId()) && proto_vorto_types_types_pb.NullableString.toObject(includeInstance, f),
    equipmentOrderPriority: (f = msg.getEquipmentOrderPriority()) && proto_vorto_types_types_pb.NullableInt64.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.OrderSummaryBase}
 */
proto.vtv1.OrderSummaryBase.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.OrderSummaryBase;
  return proto.vtv1.OrderSummaryBase.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.OrderSummaryBase} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.OrderSummaryBase}
 */
proto.vtv1.OrderSummaryBase.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOriginSiteId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setOriginSiteName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDestinationSiteId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setDestinationSiteName(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductId(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductName(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUnitId(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setUnitName(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setUnitAbbreviation(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setQuantity(value);
      break;
    case 12:
      var value = new proto_vorto_types_types_pb.NullableString;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableString.deserializeBinaryFromReader);
      msg.setEdgeId(value);
      break;
    case 13:
      var value = new proto_vorto_types_types_pb.NullableString;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableString.deserializeBinaryFromReader);
      msg.setEdgeName(value);
      break;
    case 14:
      var value = new proto_vorto_types_types_pb.NullableTime;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableTime.deserializeBinaryFromReader);
      msg.setArrivalWindowStartsAt(value);
      break;
    case 15:
      var value = new proto_vorto_types_types_pb.NullableTime;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableTime.deserializeBinaryFromReader);
      msg.setArrivalWindowEndsAt(value);
      break;
    case 16:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsAsap(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setUpdatedAt(value);
      break;
    case 18:
      var value = /** @type {!proto.vtv1.ShipmentProgressStatus} */ (reader.readEnum());
      msg.setProgressStatus(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setProgressStatusUpdatedAt(value);
      break;
    case 22:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSupplier(value);
      break;
    case 23:
      var value = new proto.vtv1.OrderError;
      reader.readMessage(value,proto.vtv1.OrderError.deserializeBinaryFromReader);
      msg.addErrors(value);
      break;
    case 24:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasBackhaul(value);
      break;
    case 25:
      var value = /** @type {string} */ (reader.readString());
      msg.addProductIds(value);
      break;
    case 26:
      var value = /** @type {string} */ (reader.readString());
      msg.addProductNames(value);
      break;
    case 27:
      var value = new proto_vorto_types_types_pb.NullableString;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableString.deserializeBinaryFromReader);
      msg.setFulfillmentId(value);
      break;
    case 28:
      var value = new proto_vorto_types_types_pb.NullableString;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableString.deserializeBinaryFromReader);
      msg.setTrailerNumber(value);
      break;
    case 29:
      var value = /** @type {string} */ (reader.readString());
      msg.addAssetNames(value);
      break;
    case 30:
      var value = new proto_vorto_types_types_pb.NullableString;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableString.deserializeBinaryFromReader);
      msg.setEquipmentOrderId(value);
      break;
    case 31:
      var value = new proto_vorto_types_types_pb.NullableString;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableString.deserializeBinaryFromReader);
      msg.setOriginDistrictId(value);
      break;
    case 32:
      var value = new proto_vorto_types_types_pb.NullableString;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableString.deserializeBinaryFromReader);
      msg.setDestinationDistrictId(value);
      break;
    case 33:
      var value = new proto_vorto_types_types_pb.NullableInt64;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableInt64.deserializeBinaryFromReader);
      msg.setEquipmentOrderPriority(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.OrderSummaryBase.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.OrderSummaryBase.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.OrderSummaryBase} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.OrderSummaryBase.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOriginSiteId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getOriginSiteName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDestinationSiteId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getDestinationSiteName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getProductId();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getProductName();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getUnitId();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
  f = message.getUnitName();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getUnitAbbreviation();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getQuantity();
  if (f !== 0.0) {
    writer.writeDouble(
      11,
      f
    );
  }
  f = message.getEdgeId();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      proto_vorto_types_types_pb.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getEdgeName();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      proto_vorto_types_types_pb.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getArrivalWindowStartsAt();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      proto_vorto_types_types_pb.NullableTime.serializeBinaryToWriter
    );
  }
  f = message.getArrivalWindowEndsAt();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      proto_vorto_types_types_pb.NullableTime.serializeBinaryToWriter
    );
  }
  f = message.getIsAsap();
  if (f) {
    writer.writeBool(
      16,
      f
    );
  }
  f = message.getUpdatedAt();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getProgressStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      18,
      f
    );
  }
  f = message.getProgressStatusUpdatedAt();
  if (f.length > 0) {
    writer.writeString(
      19,
      f
    );
  }
  f = message.getSupplier();
  if (f) {
    writer.writeBool(
      22,
      f
    );
  }
  f = message.getErrorsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      23,
      f,
      proto.vtv1.OrderError.serializeBinaryToWriter
    );
  }
  f = message.getHasBackhaul();
  if (f) {
    writer.writeBool(
      24,
      f
    );
  }
  f = message.getProductIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      25,
      f
    );
  }
  f = message.getProductNamesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      26,
      f
    );
  }
  f = message.getFulfillmentId();
  if (f != null) {
    writer.writeMessage(
      27,
      f,
      proto_vorto_types_types_pb.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getTrailerNumber();
  if (f != null) {
    writer.writeMessage(
      28,
      f,
      proto_vorto_types_types_pb.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getAssetNamesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      29,
      f
    );
  }
  f = message.getEquipmentOrderId();
  if (f != null) {
    writer.writeMessage(
      30,
      f,
      proto_vorto_types_types_pb.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getOriginDistrictId();
  if (f != null) {
    writer.writeMessage(
      31,
      f,
      proto_vorto_types_types_pb.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getDestinationDistrictId();
  if (f != null) {
    writer.writeMessage(
      32,
      f,
      proto_vorto_types_types_pb.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getEquipmentOrderPriority();
  if (f != null) {
    writer.writeMessage(
      33,
      f,
      proto_vorto_types_types_pb.NullableInt64.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.vtv1.OrderSummaryBase.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.OrderSummaryBase} returns this
 */
proto.vtv1.OrderSummaryBase.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string origin_site_id = 2;
 * @return {string}
 */
proto.vtv1.OrderSummaryBase.prototype.getOriginSiteId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.OrderSummaryBase} returns this
 */
proto.vtv1.OrderSummaryBase.prototype.setOriginSiteId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string origin_site_name = 3;
 * @return {string}
 */
proto.vtv1.OrderSummaryBase.prototype.getOriginSiteName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.OrderSummaryBase} returns this
 */
proto.vtv1.OrderSummaryBase.prototype.setOriginSiteName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string destination_site_id = 4;
 * @return {string}
 */
proto.vtv1.OrderSummaryBase.prototype.getDestinationSiteId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.OrderSummaryBase} returns this
 */
proto.vtv1.OrderSummaryBase.prototype.setDestinationSiteId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string destination_site_name = 5;
 * @return {string}
 */
proto.vtv1.OrderSummaryBase.prototype.getDestinationSiteName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.OrderSummaryBase} returns this
 */
proto.vtv1.OrderSummaryBase.prototype.setDestinationSiteName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string product_id = 6;
 * @return {string}
 */
proto.vtv1.OrderSummaryBase.prototype.getProductId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.OrderSummaryBase} returns this
 */
proto.vtv1.OrderSummaryBase.prototype.setProductId = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string product_name = 7;
 * @return {string}
 */
proto.vtv1.OrderSummaryBase.prototype.getProductName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.OrderSummaryBase} returns this
 */
proto.vtv1.OrderSummaryBase.prototype.setProductName = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional int64 unit_id = 8;
 * @return {number}
 */
proto.vtv1.OrderSummaryBase.prototype.getUnitId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.OrderSummaryBase} returns this
 */
proto.vtv1.OrderSummaryBase.prototype.setUnitId = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional string unit_name = 9;
 * @return {string}
 */
proto.vtv1.OrderSummaryBase.prototype.getUnitName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.OrderSummaryBase} returns this
 */
proto.vtv1.OrderSummaryBase.prototype.setUnitName = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string unit_abbreviation = 10;
 * @return {string}
 */
proto.vtv1.OrderSummaryBase.prototype.getUnitAbbreviation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.OrderSummaryBase} returns this
 */
proto.vtv1.OrderSummaryBase.prototype.setUnitAbbreviation = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional double quantity = 11;
 * @return {number}
 */
proto.vtv1.OrderSummaryBase.prototype.getQuantity = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 11, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.OrderSummaryBase} returns this
 */
proto.vtv1.OrderSummaryBase.prototype.setQuantity = function(value) {
  return jspb.Message.setProto3FloatField(this, 11, value);
};


/**
 * optional NullableString edge_id = 12;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.OrderSummaryBase.prototype.getEdgeId = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableString, 12));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.OrderSummaryBase} returns this
*/
proto.vtv1.OrderSummaryBase.prototype.setEdgeId = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.OrderSummaryBase} returns this
 */
proto.vtv1.OrderSummaryBase.prototype.clearEdgeId = function() {
  return this.setEdgeId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.OrderSummaryBase.prototype.hasEdgeId = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional NullableString edge_name = 13;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.OrderSummaryBase.prototype.getEdgeName = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableString, 13));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.OrderSummaryBase} returns this
*/
proto.vtv1.OrderSummaryBase.prototype.setEdgeName = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.OrderSummaryBase} returns this
 */
proto.vtv1.OrderSummaryBase.prototype.clearEdgeName = function() {
  return this.setEdgeName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.OrderSummaryBase.prototype.hasEdgeName = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional NullableTime arrival_window_starts_at = 14;
 * @return {?proto.vtv1.NullableTime}
 */
proto.vtv1.OrderSummaryBase.prototype.getArrivalWindowStartsAt = function() {
  return /** @type{?proto.vtv1.NullableTime} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableTime, 14));
};


/**
 * @param {?proto.vtv1.NullableTime|undefined} value
 * @return {!proto.vtv1.OrderSummaryBase} returns this
*/
proto.vtv1.OrderSummaryBase.prototype.setArrivalWindowStartsAt = function(value) {
  return jspb.Message.setWrapperField(this, 14, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.OrderSummaryBase} returns this
 */
proto.vtv1.OrderSummaryBase.prototype.clearArrivalWindowStartsAt = function() {
  return this.setArrivalWindowStartsAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.OrderSummaryBase.prototype.hasArrivalWindowStartsAt = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional NullableTime arrival_window_ends_at = 15;
 * @return {?proto.vtv1.NullableTime}
 */
proto.vtv1.OrderSummaryBase.prototype.getArrivalWindowEndsAt = function() {
  return /** @type{?proto.vtv1.NullableTime} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableTime, 15));
};


/**
 * @param {?proto.vtv1.NullableTime|undefined} value
 * @return {!proto.vtv1.OrderSummaryBase} returns this
*/
proto.vtv1.OrderSummaryBase.prototype.setArrivalWindowEndsAt = function(value) {
  return jspb.Message.setWrapperField(this, 15, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.OrderSummaryBase} returns this
 */
proto.vtv1.OrderSummaryBase.prototype.clearArrivalWindowEndsAt = function() {
  return this.setArrivalWindowEndsAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.OrderSummaryBase.prototype.hasArrivalWindowEndsAt = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional bool is_asap = 16;
 * @return {boolean}
 */
proto.vtv1.OrderSummaryBase.prototype.getIsAsap = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 16, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vtv1.OrderSummaryBase} returns this
 */
proto.vtv1.OrderSummaryBase.prototype.setIsAsap = function(value) {
  return jspb.Message.setProto3BooleanField(this, 16, value);
};


/**
 * optional string updated_at = 17;
 * @return {string}
 */
proto.vtv1.OrderSummaryBase.prototype.getUpdatedAt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.OrderSummaryBase} returns this
 */
proto.vtv1.OrderSummaryBase.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional ShipmentProgressStatus progress_status = 18;
 * @return {!proto.vtv1.ShipmentProgressStatus}
 */
proto.vtv1.OrderSummaryBase.prototype.getProgressStatus = function() {
  return /** @type {!proto.vtv1.ShipmentProgressStatus} */ (jspb.Message.getFieldWithDefault(this, 18, 0));
};


/**
 * @param {!proto.vtv1.ShipmentProgressStatus} value
 * @return {!proto.vtv1.OrderSummaryBase} returns this
 */
proto.vtv1.OrderSummaryBase.prototype.setProgressStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 18, value);
};


/**
 * optional string progress_status_updated_at = 19;
 * @return {string}
 */
proto.vtv1.OrderSummaryBase.prototype.getProgressStatusUpdatedAt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.OrderSummaryBase} returns this
 */
proto.vtv1.OrderSummaryBase.prototype.setProgressStatusUpdatedAt = function(value) {
  return jspb.Message.setProto3StringField(this, 19, value);
};


/**
 * optional bool supplier = 22;
 * @return {boolean}
 */
proto.vtv1.OrderSummaryBase.prototype.getSupplier = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 22, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vtv1.OrderSummaryBase} returns this
 */
proto.vtv1.OrderSummaryBase.prototype.setSupplier = function(value) {
  return jspb.Message.setProto3BooleanField(this, 22, value);
};


/**
 * repeated OrderError errors = 23;
 * @return {!Array<!proto.vtv1.OrderError>}
 */
proto.vtv1.OrderSummaryBase.prototype.getErrorsList = function() {
  return /** @type{!Array<!proto.vtv1.OrderError>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vtv1.OrderError, 23));
};


/**
 * @param {!Array<!proto.vtv1.OrderError>} value
 * @return {!proto.vtv1.OrderSummaryBase} returns this
*/
proto.vtv1.OrderSummaryBase.prototype.setErrorsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 23, value);
};


/**
 * @param {!proto.vtv1.OrderError=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.OrderError}
 */
proto.vtv1.OrderSummaryBase.prototype.addErrors = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 23, opt_value, proto.vtv1.OrderError, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.OrderSummaryBase} returns this
 */
proto.vtv1.OrderSummaryBase.prototype.clearErrorsList = function() {
  return this.setErrorsList([]);
};


/**
 * optional bool has_backhaul = 24;
 * @return {boolean}
 */
proto.vtv1.OrderSummaryBase.prototype.getHasBackhaul = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 24, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vtv1.OrderSummaryBase} returns this
 */
proto.vtv1.OrderSummaryBase.prototype.setHasBackhaul = function(value) {
  return jspb.Message.setProto3BooleanField(this, 24, value);
};


/**
 * repeated string product_ids = 25;
 * @return {!Array<string>}
 */
proto.vtv1.OrderSummaryBase.prototype.getProductIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 25));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.vtv1.OrderSummaryBase} returns this
 */
proto.vtv1.OrderSummaryBase.prototype.setProductIdsList = function(value) {
  return jspb.Message.setField(this, 25, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.vtv1.OrderSummaryBase} returns this
 */
proto.vtv1.OrderSummaryBase.prototype.addProductIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 25, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.OrderSummaryBase} returns this
 */
proto.vtv1.OrderSummaryBase.prototype.clearProductIdsList = function() {
  return this.setProductIdsList([]);
};


/**
 * repeated string product_names = 26;
 * @return {!Array<string>}
 */
proto.vtv1.OrderSummaryBase.prototype.getProductNamesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 26));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.vtv1.OrderSummaryBase} returns this
 */
proto.vtv1.OrderSummaryBase.prototype.setProductNamesList = function(value) {
  return jspb.Message.setField(this, 26, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.vtv1.OrderSummaryBase} returns this
 */
proto.vtv1.OrderSummaryBase.prototype.addProductNames = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 26, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.OrderSummaryBase} returns this
 */
proto.vtv1.OrderSummaryBase.prototype.clearProductNamesList = function() {
  return this.setProductNamesList([]);
};


/**
 * optional NullableString fulfillment_id = 27;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.OrderSummaryBase.prototype.getFulfillmentId = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableString, 27));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.OrderSummaryBase} returns this
*/
proto.vtv1.OrderSummaryBase.prototype.setFulfillmentId = function(value) {
  return jspb.Message.setWrapperField(this, 27, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.OrderSummaryBase} returns this
 */
proto.vtv1.OrderSummaryBase.prototype.clearFulfillmentId = function() {
  return this.setFulfillmentId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.OrderSummaryBase.prototype.hasFulfillmentId = function() {
  return jspb.Message.getField(this, 27) != null;
};


/**
 * optional NullableString trailer_number = 28;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.OrderSummaryBase.prototype.getTrailerNumber = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableString, 28));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.OrderSummaryBase} returns this
*/
proto.vtv1.OrderSummaryBase.prototype.setTrailerNumber = function(value) {
  return jspb.Message.setWrapperField(this, 28, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.OrderSummaryBase} returns this
 */
proto.vtv1.OrderSummaryBase.prototype.clearTrailerNumber = function() {
  return this.setTrailerNumber(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.OrderSummaryBase.prototype.hasTrailerNumber = function() {
  return jspb.Message.getField(this, 28) != null;
};


/**
 * repeated string asset_names = 29;
 * @return {!Array<string>}
 */
proto.vtv1.OrderSummaryBase.prototype.getAssetNamesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 29));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.vtv1.OrderSummaryBase} returns this
 */
proto.vtv1.OrderSummaryBase.prototype.setAssetNamesList = function(value) {
  return jspb.Message.setField(this, 29, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.vtv1.OrderSummaryBase} returns this
 */
proto.vtv1.OrderSummaryBase.prototype.addAssetNames = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 29, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.OrderSummaryBase} returns this
 */
proto.vtv1.OrderSummaryBase.prototype.clearAssetNamesList = function() {
  return this.setAssetNamesList([]);
};


/**
 * optional NullableString equipment_order_id = 30;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.OrderSummaryBase.prototype.getEquipmentOrderId = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableString, 30));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.OrderSummaryBase} returns this
*/
proto.vtv1.OrderSummaryBase.prototype.setEquipmentOrderId = function(value) {
  return jspb.Message.setWrapperField(this, 30, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.OrderSummaryBase} returns this
 */
proto.vtv1.OrderSummaryBase.prototype.clearEquipmentOrderId = function() {
  return this.setEquipmentOrderId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.OrderSummaryBase.prototype.hasEquipmentOrderId = function() {
  return jspb.Message.getField(this, 30) != null;
};


/**
 * optional NullableString origin_district_id = 31;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.OrderSummaryBase.prototype.getOriginDistrictId = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableString, 31));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.OrderSummaryBase} returns this
*/
proto.vtv1.OrderSummaryBase.prototype.setOriginDistrictId = function(value) {
  return jspb.Message.setWrapperField(this, 31, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.OrderSummaryBase} returns this
 */
proto.vtv1.OrderSummaryBase.prototype.clearOriginDistrictId = function() {
  return this.setOriginDistrictId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.OrderSummaryBase.prototype.hasOriginDistrictId = function() {
  return jspb.Message.getField(this, 31) != null;
};


/**
 * optional NullableString destination_district_id = 32;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.OrderSummaryBase.prototype.getDestinationDistrictId = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableString, 32));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.OrderSummaryBase} returns this
*/
proto.vtv1.OrderSummaryBase.prototype.setDestinationDistrictId = function(value) {
  return jspb.Message.setWrapperField(this, 32, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.OrderSummaryBase} returns this
 */
proto.vtv1.OrderSummaryBase.prototype.clearDestinationDistrictId = function() {
  return this.setDestinationDistrictId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.OrderSummaryBase.prototype.hasDestinationDistrictId = function() {
  return jspb.Message.getField(this, 32) != null;
};


/**
 * optional NullableInt64 equipment_order_priority = 33;
 * @return {?proto.vtv1.NullableInt64}
 */
proto.vtv1.OrderSummaryBase.prototype.getEquipmentOrderPriority = function() {
  return /** @type{?proto.vtv1.NullableInt64} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableInt64, 33));
};


/**
 * @param {?proto.vtv1.NullableInt64|undefined} value
 * @return {!proto.vtv1.OrderSummaryBase} returns this
*/
proto.vtv1.OrderSummaryBase.prototype.setEquipmentOrderPriority = function(value) {
  return jspb.Message.setWrapperField(this, 33, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.OrderSummaryBase} returns this
 */
proto.vtv1.OrderSummaryBase.prototype.clearEquipmentOrderPriority = function() {
  return this.setEquipmentOrderPriority(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.OrderSummaryBase.prototype.hasEquipmentOrderPriority = function() {
  return jspb.Message.getField(this, 33) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.AtEdgeOrderSummary.repeatedFields_ = [7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.AtEdgeOrderSummary.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.AtEdgeOrderSummary.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.AtEdgeOrderSummary} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.AtEdgeOrderSummary.toObject = function(includeInstance, msg) {
  var f, obj = {
    orderBase: (f = msg.getOrderBase()) && proto.vtv1.OrderSummaryBase.toObject(includeInstance, f),
    sentToEdgeAt: jspb.Message.getFieldWithDefault(msg, 2, ""),
    allDeclined: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    routeGuideExhausted: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    liteOrder: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    liteSearchCompleted: (f = msg.getLiteSearchCompleted()) && proto_vorto_types_types_pb.NullableBool.toObject(includeInstance, f),
    carrierNamesList: (f = jspb.Message.getRepeatedField(msg, 7)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.AtEdgeOrderSummary}
 */
proto.vtv1.AtEdgeOrderSummary.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.AtEdgeOrderSummary;
  return proto.vtv1.AtEdgeOrderSummary.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.AtEdgeOrderSummary} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.AtEdgeOrderSummary}
 */
proto.vtv1.AtEdgeOrderSummary.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.vtv1.OrderSummaryBase;
      reader.readMessage(value,proto.vtv1.OrderSummaryBase.deserializeBinaryFromReader);
      msg.setOrderBase(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSentToEdgeAt(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAllDeclined(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRouteGuideExhausted(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setLiteOrder(value);
      break;
    case 6:
      var value = new proto_vorto_types_types_pb.NullableBool;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableBool.deserializeBinaryFromReader);
      msg.setLiteSearchCompleted(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.addCarrierNames(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.AtEdgeOrderSummary.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.AtEdgeOrderSummary.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.AtEdgeOrderSummary} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.AtEdgeOrderSummary.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrderBase();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.vtv1.OrderSummaryBase.serializeBinaryToWriter
    );
  }
  f = message.getSentToEdgeAt();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAllDeclined();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getRouteGuideExhausted();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getLiteOrder();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getLiteSearchCompleted();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto_vorto_types_types_pb.NullableBool.serializeBinaryToWriter
    );
  }
  f = message.getCarrierNamesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      7,
      f
    );
  }
};


/**
 * optional OrderSummaryBase order_base = 1;
 * @return {?proto.vtv1.OrderSummaryBase}
 */
proto.vtv1.AtEdgeOrderSummary.prototype.getOrderBase = function() {
  return /** @type{?proto.vtv1.OrderSummaryBase} */ (
    jspb.Message.getWrapperField(this, proto.vtv1.OrderSummaryBase, 1));
};


/**
 * @param {?proto.vtv1.OrderSummaryBase|undefined} value
 * @return {!proto.vtv1.AtEdgeOrderSummary} returns this
*/
proto.vtv1.AtEdgeOrderSummary.prototype.setOrderBase = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.AtEdgeOrderSummary} returns this
 */
proto.vtv1.AtEdgeOrderSummary.prototype.clearOrderBase = function() {
  return this.setOrderBase(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.AtEdgeOrderSummary.prototype.hasOrderBase = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string sent_to_edge_at = 2;
 * @return {string}
 */
proto.vtv1.AtEdgeOrderSummary.prototype.getSentToEdgeAt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.AtEdgeOrderSummary} returns this
 */
proto.vtv1.AtEdgeOrderSummary.prototype.setSentToEdgeAt = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool all_declined = 3;
 * @return {boolean}
 */
proto.vtv1.AtEdgeOrderSummary.prototype.getAllDeclined = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vtv1.AtEdgeOrderSummary} returns this
 */
proto.vtv1.AtEdgeOrderSummary.prototype.setAllDeclined = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional bool route_guide_exhausted = 4;
 * @return {boolean}
 */
proto.vtv1.AtEdgeOrderSummary.prototype.getRouteGuideExhausted = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vtv1.AtEdgeOrderSummary} returns this
 */
proto.vtv1.AtEdgeOrderSummary.prototype.setRouteGuideExhausted = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional bool lite_order = 5;
 * @return {boolean}
 */
proto.vtv1.AtEdgeOrderSummary.prototype.getLiteOrder = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vtv1.AtEdgeOrderSummary} returns this
 */
proto.vtv1.AtEdgeOrderSummary.prototype.setLiteOrder = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional NullableBool lite_search_completed = 6;
 * @return {?proto.vtv1.NullableBool}
 */
proto.vtv1.AtEdgeOrderSummary.prototype.getLiteSearchCompleted = function() {
  return /** @type{?proto.vtv1.NullableBool} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableBool, 6));
};


/**
 * @param {?proto.vtv1.NullableBool|undefined} value
 * @return {!proto.vtv1.AtEdgeOrderSummary} returns this
*/
proto.vtv1.AtEdgeOrderSummary.prototype.setLiteSearchCompleted = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.AtEdgeOrderSummary} returns this
 */
proto.vtv1.AtEdgeOrderSummary.prototype.clearLiteSearchCompleted = function() {
  return this.setLiteSearchCompleted(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.AtEdgeOrderSummary.prototype.hasLiteSearchCompleted = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * repeated string carrier_names = 7;
 * @return {!Array<string>}
 */
proto.vtv1.AtEdgeOrderSummary.prototype.getCarrierNamesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 7));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.vtv1.AtEdgeOrderSummary} returns this
 */
proto.vtv1.AtEdgeOrderSummary.prototype.setCarrierNamesList = function(value) {
  return jspb.Message.setField(this, 7, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.vtv1.AtEdgeOrderSummary} returns this
 */
proto.vtv1.AtEdgeOrderSummary.prototype.addCarrierNames = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 7, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.AtEdgeOrderSummary} returns this
 */
proto.vtv1.AtEdgeOrderSummary.prototype.clearCarrierNamesList = function() {
  return this.setCarrierNamesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.AtSupplierOrderSummary.repeatedFields_ = [23,27];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.AtSupplierOrderSummary.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.AtSupplierOrderSummary.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.AtSupplierOrderSummary} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.AtSupplierOrderSummary.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    destinationSiteId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    destinationSiteName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    productId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    productName: jspb.Message.getFieldWithDefault(msg, 5, ""),
    unitId: jspb.Message.getFieldWithDefault(msg, 6, 0),
    unitName: jspb.Message.getFieldWithDefault(msg, 7, ""),
    unitAbbreviation: jspb.Message.getFieldWithDefault(msg, 8, ""),
    quantity: jspb.Message.getFloatingPointFieldWithDefault(msg, 9, 0.0),
    deliveryWindowStartsAt: (f = msg.getDeliveryWindowStartsAt()) && proto_vorto_types_types_pb.NullableTime.toObject(includeInstance, f),
    deliveryWindowEndsAt: (f = msg.getDeliveryWindowEndsAt()) && proto_vorto_types_types_pb.NullableTime.toObject(includeInstance, f),
    isAsap: jspb.Message.getBooleanFieldWithDefault(msg, 12, false),
    updatedAt: jspb.Message.getFieldWithDefault(msg, 13, ""),
    progressStatusUpdatedAt: jspb.Message.getFieldWithDefault(msg, 15, ""),
    sentToSupplierAt: jspb.Message.getFieldWithDefault(msg, 16, ""),
    allDeclined: jspb.Message.getBooleanFieldWithDefault(msg, 17, false),
    supplierGuideExhausted: jspb.Message.getBooleanFieldWithDefault(msg, 18, false),
    upForGrabsPick: jspb.Message.getBooleanFieldWithDefault(msg, 19, false),
    upForGrabsAll: jspb.Message.getBooleanFieldWithDefault(msg, 20, false),
    noPoAvailable: jspb.Message.getBooleanFieldWithDefault(msg, 21, false),
    supplier: jspb.Message.getBooleanFieldWithDefault(msg, 22, false),
    errorsList: jspb.Message.toObjectList(msg.getErrorsList(),
    proto.vtv1.OrderError.toObject, includeInstance),
    originSiteId: (f = msg.getOriginSiteId()) && proto_vorto_types_types_pb.NullableString.toObject(includeInstance, f),
    originSiteName: (f = msg.getOriginSiteName()) && proto_vorto_types_types_pb.NullableString.toObject(includeInstance, f),
    trailerNumber: (f = msg.getTrailerNumber()) && proto_vorto_types_types_pb.NullableString.toObject(includeInstance, f),
    assetNamesList: (f = jspb.Message.getRepeatedField(msg, 27)) == null ? undefined : f,
    equipmentOrderId: (f = msg.getEquipmentOrderId()) && proto_vorto_types_types_pb.NullableString.toObject(includeInstance, f),
    originDistrictId: (f = msg.getOriginDistrictId()) && proto_vorto_types_types_pb.NullableString.toObject(includeInstance, f),
    destinationDistrictId: (f = msg.getDestinationDistrictId()) && proto_vorto_types_types_pb.NullableString.toObject(includeInstance, f),
    equipmentOrderPriority: (f = msg.getEquipmentOrderPriority()) && proto_vorto_types_types_pb.NullableInt64.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.AtSupplierOrderSummary}
 */
proto.vtv1.AtSupplierOrderSummary.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.AtSupplierOrderSummary;
  return proto.vtv1.AtSupplierOrderSummary.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.AtSupplierOrderSummary} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.AtSupplierOrderSummary}
 */
proto.vtv1.AtSupplierOrderSummary.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDestinationSiteId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDestinationSiteName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductName(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUnitId(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setUnitName(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setUnitAbbreviation(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setQuantity(value);
      break;
    case 10:
      var value = new proto_vorto_types_types_pb.NullableTime;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableTime.deserializeBinaryFromReader);
      msg.setDeliveryWindowStartsAt(value);
      break;
    case 11:
      var value = new proto_vorto_types_types_pb.NullableTime;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableTime.deserializeBinaryFromReader);
      msg.setDeliveryWindowEndsAt(value);
      break;
    case 12:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsAsap(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setUpdatedAt(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setProgressStatusUpdatedAt(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setSentToSupplierAt(value);
      break;
    case 17:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAllDeclined(value);
      break;
    case 18:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSupplierGuideExhausted(value);
      break;
    case 19:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setUpForGrabsPick(value);
      break;
    case 20:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setUpForGrabsAll(value);
      break;
    case 21:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setNoPoAvailable(value);
      break;
    case 22:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSupplier(value);
      break;
    case 23:
      var value = new proto.vtv1.OrderError;
      reader.readMessage(value,proto.vtv1.OrderError.deserializeBinaryFromReader);
      msg.addErrors(value);
      break;
    case 24:
      var value = new proto_vorto_types_types_pb.NullableString;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableString.deserializeBinaryFromReader);
      msg.setOriginSiteId(value);
      break;
    case 25:
      var value = new proto_vorto_types_types_pb.NullableString;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableString.deserializeBinaryFromReader);
      msg.setOriginSiteName(value);
      break;
    case 26:
      var value = new proto_vorto_types_types_pb.NullableString;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableString.deserializeBinaryFromReader);
      msg.setTrailerNumber(value);
      break;
    case 27:
      var value = /** @type {string} */ (reader.readString());
      msg.addAssetNames(value);
      break;
    case 28:
      var value = new proto_vorto_types_types_pb.NullableString;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableString.deserializeBinaryFromReader);
      msg.setEquipmentOrderId(value);
      break;
    case 29:
      var value = new proto_vorto_types_types_pb.NullableString;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableString.deserializeBinaryFromReader);
      msg.setOriginDistrictId(value);
      break;
    case 30:
      var value = new proto_vorto_types_types_pb.NullableString;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableString.deserializeBinaryFromReader);
      msg.setDestinationDistrictId(value);
      break;
    case 31:
      var value = new proto_vorto_types_types_pb.NullableInt64;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableInt64.deserializeBinaryFromReader);
      msg.setEquipmentOrderPriority(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.AtSupplierOrderSummary.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.AtSupplierOrderSummary.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.AtSupplierOrderSummary} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.AtSupplierOrderSummary.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDestinationSiteId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDestinationSiteName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getProductId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getProductName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getUnitId();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getUnitName();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getUnitAbbreviation();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getQuantity();
  if (f !== 0.0) {
    writer.writeDouble(
      9,
      f
    );
  }
  f = message.getDeliveryWindowStartsAt();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto_vorto_types_types_pb.NullableTime.serializeBinaryToWriter
    );
  }
  f = message.getDeliveryWindowEndsAt();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto_vorto_types_types_pb.NullableTime.serializeBinaryToWriter
    );
  }
  f = message.getIsAsap();
  if (f) {
    writer.writeBool(
      12,
      f
    );
  }
  f = message.getUpdatedAt();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getProgressStatusUpdatedAt();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getSentToSupplierAt();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getAllDeclined();
  if (f) {
    writer.writeBool(
      17,
      f
    );
  }
  f = message.getSupplierGuideExhausted();
  if (f) {
    writer.writeBool(
      18,
      f
    );
  }
  f = message.getUpForGrabsPick();
  if (f) {
    writer.writeBool(
      19,
      f
    );
  }
  f = message.getUpForGrabsAll();
  if (f) {
    writer.writeBool(
      20,
      f
    );
  }
  f = message.getNoPoAvailable();
  if (f) {
    writer.writeBool(
      21,
      f
    );
  }
  f = message.getSupplier();
  if (f) {
    writer.writeBool(
      22,
      f
    );
  }
  f = message.getErrorsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      23,
      f,
      proto.vtv1.OrderError.serializeBinaryToWriter
    );
  }
  f = message.getOriginSiteId();
  if (f != null) {
    writer.writeMessage(
      24,
      f,
      proto_vorto_types_types_pb.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getOriginSiteName();
  if (f != null) {
    writer.writeMessage(
      25,
      f,
      proto_vorto_types_types_pb.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getTrailerNumber();
  if (f != null) {
    writer.writeMessage(
      26,
      f,
      proto_vorto_types_types_pb.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getAssetNamesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      27,
      f
    );
  }
  f = message.getEquipmentOrderId();
  if (f != null) {
    writer.writeMessage(
      28,
      f,
      proto_vorto_types_types_pb.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getOriginDistrictId();
  if (f != null) {
    writer.writeMessage(
      29,
      f,
      proto_vorto_types_types_pb.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getDestinationDistrictId();
  if (f != null) {
    writer.writeMessage(
      30,
      f,
      proto_vorto_types_types_pb.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getEquipmentOrderPriority();
  if (f != null) {
    writer.writeMessage(
      31,
      f,
      proto_vorto_types_types_pb.NullableInt64.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.vtv1.AtSupplierOrderSummary.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.AtSupplierOrderSummary} returns this
 */
proto.vtv1.AtSupplierOrderSummary.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string destination_site_id = 2;
 * @return {string}
 */
proto.vtv1.AtSupplierOrderSummary.prototype.getDestinationSiteId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.AtSupplierOrderSummary} returns this
 */
proto.vtv1.AtSupplierOrderSummary.prototype.setDestinationSiteId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string destination_site_name = 3;
 * @return {string}
 */
proto.vtv1.AtSupplierOrderSummary.prototype.getDestinationSiteName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.AtSupplierOrderSummary} returns this
 */
proto.vtv1.AtSupplierOrderSummary.prototype.setDestinationSiteName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string product_id = 4;
 * @return {string}
 */
proto.vtv1.AtSupplierOrderSummary.prototype.getProductId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.AtSupplierOrderSummary} returns this
 */
proto.vtv1.AtSupplierOrderSummary.prototype.setProductId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string product_name = 5;
 * @return {string}
 */
proto.vtv1.AtSupplierOrderSummary.prototype.getProductName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.AtSupplierOrderSummary} returns this
 */
proto.vtv1.AtSupplierOrderSummary.prototype.setProductName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional int64 unit_id = 6;
 * @return {number}
 */
proto.vtv1.AtSupplierOrderSummary.prototype.getUnitId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.AtSupplierOrderSummary} returns this
 */
proto.vtv1.AtSupplierOrderSummary.prototype.setUnitId = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional string unit_name = 7;
 * @return {string}
 */
proto.vtv1.AtSupplierOrderSummary.prototype.getUnitName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.AtSupplierOrderSummary} returns this
 */
proto.vtv1.AtSupplierOrderSummary.prototype.setUnitName = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string unit_abbreviation = 8;
 * @return {string}
 */
proto.vtv1.AtSupplierOrderSummary.prototype.getUnitAbbreviation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.AtSupplierOrderSummary} returns this
 */
proto.vtv1.AtSupplierOrderSummary.prototype.setUnitAbbreviation = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional double quantity = 9;
 * @return {number}
 */
proto.vtv1.AtSupplierOrderSummary.prototype.getQuantity = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 9, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.AtSupplierOrderSummary} returns this
 */
proto.vtv1.AtSupplierOrderSummary.prototype.setQuantity = function(value) {
  return jspb.Message.setProto3FloatField(this, 9, value);
};


/**
 * optional NullableTime delivery_window_starts_at = 10;
 * @return {?proto.vtv1.NullableTime}
 */
proto.vtv1.AtSupplierOrderSummary.prototype.getDeliveryWindowStartsAt = function() {
  return /** @type{?proto.vtv1.NullableTime} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableTime, 10));
};


/**
 * @param {?proto.vtv1.NullableTime|undefined} value
 * @return {!proto.vtv1.AtSupplierOrderSummary} returns this
*/
proto.vtv1.AtSupplierOrderSummary.prototype.setDeliveryWindowStartsAt = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.AtSupplierOrderSummary} returns this
 */
proto.vtv1.AtSupplierOrderSummary.prototype.clearDeliveryWindowStartsAt = function() {
  return this.setDeliveryWindowStartsAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.AtSupplierOrderSummary.prototype.hasDeliveryWindowStartsAt = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional NullableTime delivery_window_ends_at = 11;
 * @return {?proto.vtv1.NullableTime}
 */
proto.vtv1.AtSupplierOrderSummary.prototype.getDeliveryWindowEndsAt = function() {
  return /** @type{?proto.vtv1.NullableTime} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableTime, 11));
};


/**
 * @param {?proto.vtv1.NullableTime|undefined} value
 * @return {!proto.vtv1.AtSupplierOrderSummary} returns this
*/
proto.vtv1.AtSupplierOrderSummary.prototype.setDeliveryWindowEndsAt = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.AtSupplierOrderSummary} returns this
 */
proto.vtv1.AtSupplierOrderSummary.prototype.clearDeliveryWindowEndsAt = function() {
  return this.setDeliveryWindowEndsAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.AtSupplierOrderSummary.prototype.hasDeliveryWindowEndsAt = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional bool is_asap = 12;
 * @return {boolean}
 */
proto.vtv1.AtSupplierOrderSummary.prototype.getIsAsap = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 12, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vtv1.AtSupplierOrderSummary} returns this
 */
proto.vtv1.AtSupplierOrderSummary.prototype.setIsAsap = function(value) {
  return jspb.Message.setProto3BooleanField(this, 12, value);
};


/**
 * optional string updated_at = 13;
 * @return {string}
 */
proto.vtv1.AtSupplierOrderSummary.prototype.getUpdatedAt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.AtSupplierOrderSummary} returns this
 */
proto.vtv1.AtSupplierOrderSummary.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string progress_status_updated_at = 15;
 * @return {string}
 */
proto.vtv1.AtSupplierOrderSummary.prototype.getProgressStatusUpdatedAt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.AtSupplierOrderSummary} returns this
 */
proto.vtv1.AtSupplierOrderSummary.prototype.setProgressStatusUpdatedAt = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional string sent_to_supplier_at = 16;
 * @return {string}
 */
proto.vtv1.AtSupplierOrderSummary.prototype.getSentToSupplierAt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.AtSupplierOrderSummary} returns this
 */
proto.vtv1.AtSupplierOrderSummary.prototype.setSentToSupplierAt = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional bool all_declined = 17;
 * @return {boolean}
 */
proto.vtv1.AtSupplierOrderSummary.prototype.getAllDeclined = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 17, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vtv1.AtSupplierOrderSummary} returns this
 */
proto.vtv1.AtSupplierOrderSummary.prototype.setAllDeclined = function(value) {
  return jspb.Message.setProto3BooleanField(this, 17, value);
};


/**
 * optional bool supplier_guide_exhausted = 18;
 * @return {boolean}
 */
proto.vtv1.AtSupplierOrderSummary.prototype.getSupplierGuideExhausted = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 18, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vtv1.AtSupplierOrderSummary} returns this
 */
proto.vtv1.AtSupplierOrderSummary.prototype.setSupplierGuideExhausted = function(value) {
  return jspb.Message.setProto3BooleanField(this, 18, value);
};


/**
 * optional bool up_for_grabs_pick = 19;
 * @return {boolean}
 */
proto.vtv1.AtSupplierOrderSummary.prototype.getUpForGrabsPick = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 19, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vtv1.AtSupplierOrderSummary} returns this
 */
proto.vtv1.AtSupplierOrderSummary.prototype.setUpForGrabsPick = function(value) {
  return jspb.Message.setProto3BooleanField(this, 19, value);
};


/**
 * optional bool up_for_grabs_all = 20;
 * @return {boolean}
 */
proto.vtv1.AtSupplierOrderSummary.prototype.getUpForGrabsAll = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 20, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vtv1.AtSupplierOrderSummary} returns this
 */
proto.vtv1.AtSupplierOrderSummary.prototype.setUpForGrabsAll = function(value) {
  return jspb.Message.setProto3BooleanField(this, 20, value);
};


/**
 * optional bool no_po_available = 21;
 * @return {boolean}
 */
proto.vtv1.AtSupplierOrderSummary.prototype.getNoPoAvailable = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 21, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vtv1.AtSupplierOrderSummary} returns this
 */
proto.vtv1.AtSupplierOrderSummary.prototype.setNoPoAvailable = function(value) {
  return jspb.Message.setProto3BooleanField(this, 21, value);
};


/**
 * optional bool supplier = 22;
 * @return {boolean}
 */
proto.vtv1.AtSupplierOrderSummary.prototype.getSupplier = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 22, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vtv1.AtSupplierOrderSummary} returns this
 */
proto.vtv1.AtSupplierOrderSummary.prototype.setSupplier = function(value) {
  return jspb.Message.setProto3BooleanField(this, 22, value);
};


/**
 * repeated OrderError errors = 23;
 * @return {!Array<!proto.vtv1.OrderError>}
 */
proto.vtv1.AtSupplierOrderSummary.prototype.getErrorsList = function() {
  return /** @type{!Array<!proto.vtv1.OrderError>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vtv1.OrderError, 23));
};


/**
 * @param {!Array<!proto.vtv1.OrderError>} value
 * @return {!proto.vtv1.AtSupplierOrderSummary} returns this
*/
proto.vtv1.AtSupplierOrderSummary.prototype.setErrorsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 23, value);
};


/**
 * @param {!proto.vtv1.OrderError=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.OrderError}
 */
proto.vtv1.AtSupplierOrderSummary.prototype.addErrors = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 23, opt_value, proto.vtv1.OrderError, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.AtSupplierOrderSummary} returns this
 */
proto.vtv1.AtSupplierOrderSummary.prototype.clearErrorsList = function() {
  return this.setErrorsList([]);
};


/**
 * optional NullableString origin_site_id = 24;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.AtSupplierOrderSummary.prototype.getOriginSiteId = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableString, 24));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.AtSupplierOrderSummary} returns this
*/
proto.vtv1.AtSupplierOrderSummary.prototype.setOriginSiteId = function(value) {
  return jspb.Message.setWrapperField(this, 24, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.AtSupplierOrderSummary} returns this
 */
proto.vtv1.AtSupplierOrderSummary.prototype.clearOriginSiteId = function() {
  return this.setOriginSiteId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.AtSupplierOrderSummary.prototype.hasOriginSiteId = function() {
  return jspb.Message.getField(this, 24) != null;
};


/**
 * optional NullableString origin_site_name = 25;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.AtSupplierOrderSummary.prototype.getOriginSiteName = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableString, 25));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.AtSupplierOrderSummary} returns this
*/
proto.vtv1.AtSupplierOrderSummary.prototype.setOriginSiteName = function(value) {
  return jspb.Message.setWrapperField(this, 25, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.AtSupplierOrderSummary} returns this
 */
proto.vtv1.AtSupplierOrderSummary.prototype.clearOriginSiteName = function() {
  return this.setOriginSiteName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.AtSupplierOrderSummary.prototype.hasOriginSiteName = function() {
  return jspb.Message.getField(this, 25) != null;
};


/**
 * optional NullableString trailer_number = 26;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.AtSupplierOrderSummary.prototype.getTrailerNumber = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableString, 26));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.AtSupplierOrderSummary} returns this
*/
proto.vtv1.AtSupplierOrderSummary.prototype.setTrailerNumber = function(value) {
  return jspb.Message.setWrapperField(this, 26, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.AtSupplierOrderSummary} returns this
 */
proto.vtv1.AtSupplierOrderSummary.prototype.clearTrailerNumber = function() {
  return this.setTrailerNumber(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.AtSupplierOrderSummary.prototype.hasTrailerNumber = function() {
  return jspb.Message.getField(this, 26) != null;
};


/**
 * repeated string asset_names = 27;
 * @return {!Array<string>}
 */
proto.vtv1.AtSupplierOrderSummary.prototype.getAssetNamesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 27));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.vtv1.AtSupplierOrderSummary} returns this
 */
proto.vtv1.AtSupplierOrderSummary.prototype.setAssetNamesList = function(value) {
  return jspb.Message.setField(this, 27, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.vtv1.AtSupplierOrderSummary} returns this
 */
proto.vtv1.AtSupplierOrderSummary.prototype.addAssetNames = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 27, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.AtSupplierOrderSummary} returns this
 */
proto.vtv1.AtSupplierOrderSummary.prototype.clearAssetNamesList = function() {
  return this.setAssetNamesList([]);
};


/**
 * optional NullableString equipment_order_id = 28;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.AtSupplierOrderSummary.prototype.getEquipmentOrderId = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableString, 28));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.AtSupplierOrderSummary} returns this
*/
proto.vtv1.AtSupplierOrderSummary.prototype.setEquipmentOrderId = function(value) {
  return jspb.Message.setWrapperField(this, 28, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.AtSupplierOrderSummary} returns this
 */
proto.vtv1.AtSupplierOrderSummary.prototype.clearEquipmentOrderId = function() {
  return this.setEquipmentOrderId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.AtSupplierOrderSummary.prototype.hasEquipmentOrderId = function() {
  return jspb.Message.getField(this, 28) != null;
};


/**
 * optional NullableString origin_district_id = 29;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.AtSupplierOrderSummary.prototype.getOriginDistrictId = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableString, 29));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.AtSupplierOrderSummary} returns this
*/
proto.vtv1.AtSupplierOrderSummary.prototype.setOriginDistrictId = function(value) {
  return jspb.Message.setWrapperField(this, 29, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.AtSupplierOrderSummary} returns this
 */
proto.vtv1.AtSupplierOrderSummary.prototype.clearOriginDistrictId = function() {
  return this.setOriginDistrictId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.AtSupplierOrderSummary.prototype.hasOriginDistrictId = function() {
  return jspb.Message.getField(this, 29) != null;
};


/**
 * optional NullableString destination_district_id = 30;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.AtSupplierOrderSummary.prototype.getDestinationDistrictId = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableString, 30));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.AtSupplierOrderSummary} returns this
*/
proto.vtv1.AtSupplierOrderSummary.prototype.setDestinationDistrictId = function(value) {
  return jspb.Message.setWrapperField(this, 30, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.AtSupplierOrderSummary} returns this
 */
proto.vtv1.AtSupplierOrderSummary.prototype.clearDestinationDistrictId = function() {
  return this.setDestinationDistrictId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.AtSupplierOrderSummary.prototype.hasDestinationDistrictId = function() {
  return jspb.Message.getField(this, 30) != null;
};


/**
 * optional NullableInt64 equipment_order_priority = 31;
 * @return {?proto.vtv1.NullableInt64}
 */
proto.vtv1.AtSupplierOrderSummary.prototype.getEquipmentOrderPriority = function() {
  return /** @type{?proto.vtv1.NullableInt64} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableInt64, 31));
};


/**
 * @param {?proto.vtv1.NullableInt64|undefined} value
 * @return {!proto.vtv1.AtSupplierOrderSummary} returns this
*/
proto.vtv1.AtSupplierOrderSummary.prototype.setEquipmentOrderPriority = function(value) {
  return jspb.Message.setWrapperField(this, 31, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.AtSupplierOrderSummary} returns this
 */
proto.vtv1.AtSupplierOrderSummary.prototype.clearEquipmentOrderPriority = function() {
  return this.setEquipmentOrderPriority(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.AtSupplierOrderSummary.prototype.hasEquipmentOrderPriority = function() {
  return jspb.Message.getField(this, 31) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.OrderError.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.OrderError.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.OrderError} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.OrderError.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, 0),
    createdAt: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.OrderError}
 */
proto.vtv1.OrderError.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.OrderError;
  return proto.vtv1.OrderError.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.OrderError} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.OrderError}
 */
proto.vtv1.OrderError.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.vtv1.OrderErrorType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.OrderError.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.OrderError.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.OrderError} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.OrderError.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getCreatedAt();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional OrderErrorType type = 1;
 * @return {!proto.vtv1.OrderErrorType}
 */
proto.vtv1.OrderError.prototype.getType = function() {
  return /** @type {!proto.vtv1.OrderErrorType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.vtv1.OrderErrorType} value
 * @return {!proto.vtv1.OrderError} returns this
 */
proto.vtv1.OrderError.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string created_at = 2;
 * @return {string}
 */
proto.vtv1.OrderError.prototype.getCreatedAt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.OrderError} returns this
 */
proto.vtv1.OrderError.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.ScheduledOrderSummary.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.ScheduledOrderSummary.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.ScheduledOrderSummary} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.ScheduledOrderSummary.toObject = function(includeInstance, msg) {
  var f, obj = {
    orderBase: (f = msg.getOrderBase()) && proto.vtv1.OrderSummaryBase.toObject(includeInstance, f),
    driverId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    driverName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    exceptionMessage: (f = msg.getExceptionMessage()) && proto_vorto_types_types_pb.NullableString.toObject(includeInstance, f),
    sentToDriverAt: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.ScheduledOrderSummary}
 */
proto.vtv1.ScheduledOrderSummary.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.ScheduledOrderSummary;
  return proto.vtv1.ScheduledOrderSummary.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.ScheduledOrderSummary} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.ScheduledOrderSummary}
 */
proto.vtv1.ScheduledOrderSummary.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.vtv1.OrderSummaryBase;
      reader.readMessage(value,proto.vtv1.OrderSummaryBase.deserializeBinaryFromReader);
      msg.setOrderBase(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDriverId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDriverName(value);
      break;
    case 4:
      var value = new proto_vorto_types_types_pb.NullableString;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableString.deserializeBinaryFromReader);
      msg.setExceptionMessage(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setSentToDriverAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.ScheduledOrderSummary.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.ScheduledOrderSummary.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.ScheduledOrderSummary} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.ScheduledOrderSummary.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrderBase();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.vtv1.OrderSummaryBase.serializeBinaryToWriter
    );
  }
  f = message.getDriverId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDriverName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getExceptionMessage();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto_vorto_types_types_pb.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getSentToDriverAt();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional OrderSummaryBase order_base = 1;
 * @return {?proto.vtv1.OrderSummaryBase}
 */
proto.vtv1.ScheduledOrderSummary.prototype.getOrderBase = function() {
  return /** @type{?proto.vtv1.OrderSummaryBase} */ (
    jspb.Message.getWrapperField(this, proto.vtv1.OrderSummaryBase, 1));
};


/**
 * @param {?proto.vtv1.OrderSummaryBase|undefined} value
 * @return {!proto.vtv1.ScheduledOrderSummary} returns this
*/
proto.vtv1.ScheduledOrderSummary.prototype.setOrderBase = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.ScheduledOrderSummary} returns this
 */
proto.vtv1.ScheduledOrderSummary.prototype.clearOrderBase = function() {
  return this.setOrderBase(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.ScheduledOrderSummary.prototype.hasOrderBase = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string driver_id = 2;
 * @return {string}
 */
proto.vtv1.ScheduledOrderSummary.prototype.getDriverId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.ScheduledOrderSummary} returns this
 */
proto.vtv1.ScheduledOrderSummary.prototype.setDriverId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string driver_name = 3;
 * @return {string}
 */
proto.vtv1.ScheduledOrderSummary.prototype.getDriverName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.ScheduledOrderSummary} returns this
 */
proto.vtv1.ScheduledOrderSummary.prototype.setDriverName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional NullableString exception_message = 4;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.ScheduledOrderSummary.prototype.getExceptionMessage = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableString, 4));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.ScheduledOrderSummary} returns this
*/
proto.vtv1.ScheduledOrderSummary.prototype.setExceptionMessage = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.ScheduledOrderSummary} returns this
 */
proto.vtv1.ScheduledOrderSummary.prototype.clearExceptionMessage = function() {
  return this.setExceptionMessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.ScheduledOrderSummary.prototype.hasExceptionMessage = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string sent_to_driver_at = 5;
 * @return {string}
 */
proto.vtv1.ScheduledOrderSummary.prototype.getSentToDriverAt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.ScheduledOrderSummary} returns this
 */
proto.vtv1.ScheduledOrderSummary.prototype.setSentToDriverAt = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.InProgressOrderSummary.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.InProgressOrderSummary.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.InProgressOrderSummary} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.InProgressOrderSummary.toObject = function(includeInstance, msg) {
  var f, obj = {
    orderBase: (f = msg.getOrderBase()) && proto.vtv1.OrderSummaryBase.toObject(includeInstance, f),
    driverId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    driverName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    exceptionMessage: (f = msg.getExceptionMessage()) && proto_vorto_types_types_pb.NullableString.toObject(includeInstance, f),
    lastUpdatedAt: (f = msg.getLastUpdatedAt()) && proto_vorto_types_types_pb.NullableTime.toObject(includeInstance, f),
    estimatedArrivalAt: (f = msg.getEstimatedArrivalAt()) && proto_vorto_types_types_pb.NullableTime.toObject(includeInstance, f),
    willSiteBeClosed: jspb.Message.getBooleanFieldWithDefault(msg, 7, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.InProgressOrderSummary}
 */
proto.vtv1.InProgressOrderSummary.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.InProgressOrderSummary;
  return proto.vtv1.InProgressOrderSummary.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.InProgressOrderSummary} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.InProgressOrderSummary}
 */
proto.vtv1.InProgressOrderSummary.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.vtv1.OrderSummaryBase;
      reader.readMessage(value,proto.vtv1.OrderSummaryBase.deserializeBinaryFromReader);
      msg.setOrderBase(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDriverId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDriverName(value);
      break;
    case 4:
      var value = new proto_vorto_types_types_pb.NullableString;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableString.deserializeBinaryFromReader);
      msg.setExceptionMessage(value);
      break;
    case 5:
      var value = new proto_vorto_types_types_pb.NullableTime;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableTime.deserializeBinaryFromReader);
      msg.setLastUpdatedAt(value);
      break;
    case 6:
      var value = new proto_vorto_types_types_pb.NullableTime;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableTime.deserializeBinaryFromReader);
      msg.setEstimatedArrivalAt(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setWillSiteBeClosed(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.InProgressOrderSummary.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.InProgressOrderSummary.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.InProgressOrderSummary} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.InProgressOrderSummary.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrderBase();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.vtv1.OrderSummaryBase.serializeBinaryToWriter
    );
  }
  f = message.getDriverId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDriverName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getExceptionMessage();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto_vorto_types_types_pb.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getLastUpdatedAt();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto_vorto_types_types_pb.NullableTime.serializeBinaryToWriter
    );
  }
  f = message.getEstimatedArrivalAt();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto_vorto_types_types_pb.NullableTime.serializeBinaryToWriter
    );
  }
  f = message.getWillSiteBeClosed();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
};


/**
 * optional OrderSummaryBase order_base = 1;
 * @return {?proto.vtv1.OrderSummaryBase}
 */
proto.vtv1.InProgressOrderSummary.prototype.getOrderBase = function() {
  return /** @type{?proto.vtv1.OrderSummaryBase} */ (
    jspb.Message.getWrapperField(this, proto.vtv1.OrderSummaryBase, 1));
};


/**
 * @param {?proto.vtv1.OrderSummaryBase|undefined} value
 * @return {!proto.vtv1.InProgressOrderSummary} returns this
*/
proto.vtv1.InProgressOrderSummary.prototype.setOrderBase = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.InProgressOrderSummary} returns this
 */
proto.vtv1.InProgressOrderSummary.prototype.clearOrderBase = function() {
  return this.setOrderBase(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.InProgressOrderSummary.prototype.hasOrderBase = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string driver_id = 2;
 * @return {string}
 */
proto.vtv1.InProgressOrderSummary.prototype.getDriverId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.InProgressOrderSummary} returns this
 */
proto.vtv1.InProgressOrderSummary.prototype.setDriverId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string driver_name = 3;
 * @return {string}
 */
proto.vtv1.InProgressOrderSummary.prototype.getDriverName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.InProgressOrderSummary} returns this
 */
proto.vtv1.InProgressOrderSummary.prototype.setDriverName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional NullableString exception_message = 4;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.InProgressOrderSummary.prototype.getExceptionMessage = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableString, 4));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.InProgressOrderSummary} returns this
*/
proto.vtv1.InProgressOrderSummary.prototype.setExceptionMessage = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.InProgressOrderSummary} returns this
 */
proto.vtv1.InProgressOrderSummary.prototype.clearExceptionMessage = function() {
  return this.setExceptionMessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.InProgressOrderSummary.prototype.hasExceptionMessage = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional NullableTime last_updated_at = 5;
 * @return {?proto.vtv1.NullableTime}
 */
proto.vtv1.InProgressOrderSummary.prototype.getLastUpdatedAt = function() {
  return /** @type{?proto.vtv1.NullableTime} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableTime, 5));
};


/**
 * @param {?proto.vtv1.NullableTime|undefined} value
 * @return {!proto.vtv1.InProgressOrderSummary} returns this
*/
proto.vtv1.InProgressOrderSummary.prototype.setLastUpdatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.InProgressOrderSummary} returns this
 */
proto.vtv1.InProgressOrderSummary.prototype.clearLastUpdatedAt = function() {
  return this.setLastUpdatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.InProgressOrderSummary.prototype.hasLastUpdatedAt = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional NullableTime estimated_arrival_at = 6;
 * @return {?proto.vtv1.NullableTime}
 */
proto.vtv1.InProgressOrderSummary.prototype.getEstimatedArrivalAt = function() {
  return /** @type{?proto.vtv1.NullableTime} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableTime, 6));
};


/**
 * @param {?proto.vtv1.NullableTime|undefined} value
 * @return {!proto.vtv1.InProgressOrderSummary} returns this
*/
proto.vtv1.InProgressOrderSummary.prototype.setEstimatedArrivalAt = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.InProgressOrderSummary} returns this
 */
proto.vtv1.InProgressOrderSummary.prototype.clearEstimatedArrivalAt = function() {
  return this.setEstimatedArrivalAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.InProgressOrderSummary.prototype.hasEstimatedArrivalAt = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional bool will_site_be_closed = 7;
 * @return {boolean}
 */
proto.vtv1.InProgressOrderSummary.prototype.getWillSiteBeClosed = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vtv1.InProgressOrderSummary} returns this
 */
proto.vtv1.InProgressOrderSummary.prototype.setWillSiteBeClosed = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.ArrivedOrderSummary.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.ArrivedOrderSummary.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.ArrivedOrderSummary} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.ArrivedOrderSummary.toObject = function(includeInstance, msg) {
  var f, obj = {
    orderBase: (f = msg.getOrderBase()) && proto.vtv1.OrderSummaryBase.toObject(includeInstance, f),
    driverName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    driverId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    exceptionMessage: (f = msg.getExceptionMessage()) && proto_vorto_types_types_pb.NullableString.toObject(includeInstance, f),
    arrivedAt: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.ArrivedOrderSummary}
 */
proto.vtv1.ArrivedOrderSummary.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.ArrivedOrderSummary;
  return proto.vtv1.ArrivedOrderSummary.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.ArrivedOrderSummary} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.ArrivedOrderSummary}
 */
proto.vtv1.ArrivedOrderSummary.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.vtv1.OrderSummaryBase;
      reader.readMessage(value,proto.vtv1.OrderSummaryBase.deserializeBinaryFromReader);
      msg.setOrderBase(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDriverName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDriverId(value);
      break;
    case 4:
      var value = new proto_vorto_types_types_pb.NullableString;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableString.deserializeBinaryFromReader);
      msg.setExceptionMessage(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setArrivedAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.ArrivedOrderSummary.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.ArrivedOrderSummary.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.ArrivedOrderSummary} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.ArrivedOrderSummary.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrderBase();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.vtv1.OrderSummaryBase.serializeBinaryToWriter
    );
  }
  f = message.getDriverName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDriverId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getExceptionMessage();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto_vorto_types_types_pb.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getArrivedAt();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional OrderSummaryBase order_base = 1;
 * @return {?proto.vtv1.OrderSummaryBase}
 */
proto.vtv1.ArrivedOrderSummary.prototype.getOrderBase = function() {
  return /** @type{?proto.vtv1.OrderSummaryBase} */ (
    jspb.Message.getWrapperField(this, proto.vtv1.OrderSummaryBase, 1));
};


/**
 * @param {?proto.vtv1.OrderSummaryBase|undefined} value
 * @return {!proto.vtv1.ArrivedOrderSummary} returns this
*/
proto.vtv1.ArrivedOrderSummary.prototype.setOrderBase = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.ArrivedOrderSummary} returns this
 */
proto.vtv1.ArrivedOrderSummary.prototype.clearOrderBase = function() {
  return this.setOrderBase(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.ArrivedOrderSummary.prototype.hasOrderBase = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string driver_name = 2;
 * @return {string}
 */
proto.vtv1.ArrivedOrderSummary.prototype.getDriverName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.ArrivedOrderSummary} returns this
 */
proto.vtv1.ArrivedOrderSummary.prototype.setDriverName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string driver_id = 3;
 * @return {string}
 */
proto.vtv1.ArrivedOrderSummary.prototype.getDriverId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.ArrivedOrderSummary} returns this
 */
proto.vtv1.ArrivedOrderSummary.prototype.setDriverId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional NullableString exception_message = 4;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.ArrivedOrderSummary.prototype.getExceptionMessage = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableString, 4));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.ArrivedOrderSummary} returns this
*/
proto.vtv1.ArrivedOrderSummary.prototype.setExceptionMessage = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.ArrivedOrderSummary} returns this
 */
proto.vtv1.ArrivedOrderSummary.prototype.clearExceptionMessage = function() {
  return this.setExceptionMessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.ArrivedOrderSummary.prototype.hasExceptionMessage = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string arrived_at = 5;
 * @return {string}
 */
proto.vtv1.ArrivedOrderSummary.prototype.getArrivedAt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.ArrivedOrderSummary} returns this
 */
proto.vtv1.ArrivedOrderSummary.prototype.setArrivedAt = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.FinalStateOrderSummary.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.FinalStateOrderSummary.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.FinalStateOrderSummary} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.FinalStateOrderSummary.toObject = function(includeInstance, msg) {
  var f, obj = {
    orderBase: (f = msg.getOrderBase()) && proto.vtv1.OrderSummaryBase.toObject(includeInstance, f),
    driverName: (f = msg.getDriverName()) && proto_vorto_types_types_pb.NullableString.toObject(includeInstance, f),
    driverId: (f = msg.getDriverId()) && proto_vorto_types_types_pb.NullableString.toObject(includeInstance, f),
    exceptionMessage: (f = msg.getExceptionMessage()) && proto_vorto_types_types_pb.NullableString.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.FinalStateOrderSummary}
 */
proto.vtv1.FinalStateOrderSummary.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.FinalStateOrderSummary;
  return proto.vtv1.FinalStateOrderSummary.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.FinalStateOrderSummary} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.FinalStateOrderSummary}
 */
proto.vtv1.FinalStateOrderSummary.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.vtv1.OrderSummaryBase;
      reader.readMessage(value,proto.vtv1.OrderSummaryBase.deserializeBinaryFromReader);
      msg.setOrderBase(value);
      break;
    case 2:
      var value = new proto_vorto_types_types_pb.NullableString;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableString.deserializeBinaryFromReader);
      msg.setDriverName(value);
      break;
    case 3:
      var value = new proto_vorto_types_types_pb.NullableString;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableString.deserializeBinaryFromReader);
      msg.setDriverId(value);
      break;
    case 4:
      var value = new proto_vorto_types_types_pb.NullableString;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableString.deserializeBinaryFromReader);
      msg.setExceptionMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.FinalStateOrderSummary.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.FinalStateOrderSummary.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.FinalStateOrderSummary} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.FinalStateOrderSummary.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrderBase();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.vtv1.OrderSummaryBase.serializeBinaryToWriter
    );
  }
  f = message.getDriverName();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_vorto_types_types_pb.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getDriverId();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto_vorto_types_types_pb.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getExceptionMessage();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto_vorto_types_types_pb.NullableString.serializeBinaryToWriter
    );
  }
};


/**
 * optional OrderSummaryBase order_base = 1;
 * @return {?proto.vtv1.OrderSummaryBase}
 */
proto.vtv1.FinalStateOrderSummary.prototype.getOrderBase = function() {
  return /** @type{?proto.vtv1.OrderSummaryBase} */ (
    jspb.Message.getWrapperField(this, proto.vtv1.OrderSummaryBase, 1));
};


/**
 * @param {?proto.vtv1.OrderSummaryBase|undefined} value
 * @return {!proto.vtv1.FinalStateOrderSummary} returns this
*/
proto.vtv1.FinalStateOrderSummary.prototype.setOrderBase = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.FinalStateOrderSummary} returns this
 */
proto.vtv1.FinalStateOrderSummary.prototype.clearOrderBase = function() {
  return this.setOrderBase(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.FinalStateOrderSummary.prototype.hasOrderBase = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional NullableString driver_name = 2;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.FinalStateOrderSummary.prototype.getDriverName = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableString, 2));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.FinalStateOrderSummary} returns this
*/
proto.vtv1.FinalStateOrderSummary.prototype.setDriverName = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.FinalStateOrderSummary} returns this
 */
proto.vtv1.FinalStateOrderSummary.prototype.clearDriverName = function() {
  return this.setDriverName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.FinalStateOrderSummary.prototype.hasDriverName = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional NullableString driver_id = 3;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.FinalStateOrderSummary.prototype.getDriverId = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableString, 3));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.FinalStateOrderSummary} returns this
*/
proto.vtv1.FinalStateOrderSummary.prototype.setDriverId = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.FinalStateOrderSummary} returns this
 */
proto.vtv1.FinalStateOrderSummary.prototype.clearDriverId = function() {
  return this.setDriverId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.FinalStateOrderSummary.prototype.hasDriverId = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional NullableString exception_message = 4;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.FinalStateOrderSummary.prototype.getExceptionMessage = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableString, 4));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.FinalStateOrderSummary} returns this
*/
proto.vtv1.FinalStateOrderSummary.prototype.setExceptionMessage = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.FinalStateOrderSummary} returns this
 */
proto.vtv1.FinalStateOrderSummary.prototype.clearExceptionMessage = function() {
  return this.setExceptionMessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.FinalStateOrderSummary.prototype.hasExceptionMessage = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.CancelledOrderSummary.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.CancelledOrderSummary.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.CancelledOrderSummary} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.CancelledOrderSummary.toObject = function(includeInstance, msg) {
  var f, obj = {
    orderBase: (f = msg.getOrderBase()) && proto.vtv1.OrderSummaryBase.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.CancelledOrderSummary}
 */
proto.vtv1.CancelledOrderSummary.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.CancelledOrderSummary;
  return proto.vtv1.CancelledOrderSummary.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.CancelledOrderSummary} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.CancelledOrderSummary}
 */
proto.vtv1.CancelledOrderSummary.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.vtv1.OrderSummaryBase;
      reader.readMessage(value,proto.vtv1.OrderSummaryBase.deserializeBinaryFromReader);
      msg.setOrderBase(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.CancelledOrderSummary.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.CancelledOrderSummary.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.CancelledOrderSummary} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.CancelledOrderSummary.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrderBase();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.vtv1.OrderSummaryBase.serializeBinaryToWriter
    );
  }
};


/**
 * optional OrderSummaryBase order_base = 1;
 * @return {?proto.vtv1.OrderSummaryBase}
 */
proto.vtv1.CancelledOrderSummary.prototype.getOrderBase = function() {
  return /** @type{?proto.vtv1.OrderSummaryBase} */ (
    jspb.Message.getWrapperField(this, proto.vtv1.OrderSummaryBase, 1));
};


/**
 * @param {?proto.vtv1.OrderSummaryBase|undefined} value
 * @return {!proto.vtv1.CancelledOrderSummary} returns this
*/
proto.vtv1.CancelledOrderSummary.prototype.setOrderBase = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.CancelledOrderSummary} returns this
 */
proto.vtv1.CancelledOrderSummary.prototype.clearOrderBase = function() {
  return this.setOrderBase(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.CancelledOrderSummary.prototype.hasOrderBase = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.PurchaseOrderSummary.repeatedFields_ = [4,5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.PurchaseOrderSummary.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.PurchaseOrderSummary.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.PurchaseOrderSummary} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.PurchaseOrderSummary.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    supplierName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    supplierLocationNamesList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
    productsList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f,
    startsAt: jspb.Message.getFieldWithDefault(msg, 6, ""),
    expiresAt: (f = msg.getExpiresAt()) && proto_vorto_types_types_pb.NullableTime.toObject(includeInstance, f),
    importedAt: (f = msg.getImportedAt()) && proto_vorto_types_types_pb.NullableTime.toObject(includeInstance, f),
    confirmedAt: (f = msg.getConfirmedAt()) && proto_vorto_types_types_pb.NullableTime.toObject(includeInstance, f),
    declinedAt: (f = msg.getDeclinedAt()) && proto_vorto_types_types_pb.NullableTime.toObject(includeInstance, f),
    pausedBy: (f = msg.getPausedBy()) && proto_vorto_types_types_pb.NullableString.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.PurchaseOrderSummary}
 */
proto.vtv1.PurchaseOrderSummary.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.PurchaseOrderSummary;
  return proto.vtv1.PurchaseOrderSummary.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.PurchaseOrderSummary} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.PurchaseOrderSummary}
 */
proto.vtv1.PurchaseOrderSummary.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSupplierName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.addSupplierLocationNames(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.addProducts(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setStartsAt(value);
      break;
    case 7:
      var value = new proto_vorto_types_types_pb.NullableTime;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableTime.deserializeBinaryFromReader);
      msg.setExpiresAt(value);
      break;
    case 8:
      var value = new proto_vorto_types_types_pb.NullableTime;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableTime.deserializeBinaryFromReader);
      msg.setImportedAt(value);
      break;
    case 9:
      var value = new proto_vorto_types_types_pb.NullableTime;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableTime.deserializeBinaryFromReader);
      msg.setConfirmedAt(value);
      break;
    case 10:
      var value = new proto_vorto_types_types_pb.NullableTime;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableTime.deserializeBinaryFromReader);
      msg.setDeclinedAt(value);
      break;
    case 11:
      var value = new proto_vorto_types_types_pb.NullableString;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableString.deserializeBinaryFromReader);
      msg.setPausedBy(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.PurchaseOrderSummary.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.PurchaseOrderSummary.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.PurchaseOrderSummary} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.PurchaseOrderSummary.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSupplierName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSupplierLocationNamesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      4,
      f
    );
  }
  f = message.getProductsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      5,
      f
    );
  }
  f = message.getStartsAt();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getExpiresAt();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto_vorto_types_types_pb.NullableTime.serializeBinaryToWriter
    );
  }
  f = message.getImportedAt();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto_vorto_types_types_pb.NullableTime.serializeBinaryToWriter
    );
  }
  f = message.getConfirmedAt();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto_vorto_types_types_pb.NullableTime.serializeBinaryToWriter
    );
  }
  f = message.getDeclinedAt();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto_vorto_types_types_pb.NullableTime.serializeBinaryToWriter
    );
  }
  f = message.getPausedBy();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto_vorto_types_types_pb.NullableString.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.vtv1.PurchaseOrderSummary.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.PurchaseOrderSummary} returns this
 */
proto.vtv1.PurchaseOrderSummary.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.vtv1.PurchaseOrderSummary.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.PurchaseOrderSummary} returns this
 */
proto.vtv1.PurchaseOrderSummary.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string supplier_name = 3;
 * @return {string}
 */
proto.vtv1.PurchaseOrderSummary.prototype.getSupplierName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.PurchaseOrderSummary} returns this
 */
proto.vtv1.PurchaseOrderSummary.prototype.setSupplierName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated string supplier_location_names = 4;
 * @return {!Array<string>}
 */
proto.vtv1.PurchaseOrderSummary.prototype.getSupplierLocationNamesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.vtv1.PurchaseOrderSummary} returns this
 */
proto.vtv1.PurchaseOrderSummary.prototype.setSupplierLocationNamesList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.vtv1.PurchaseOrderSummary} returns this
 */
proto.vtv1.PurchaseOrderSummary.prototype.addSupplierLocationNames = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.PurchaseOrderSummary} returns this
 */
proto.vtv1.PurchaseOrderSummary.prototype.clearSupplierLocationNamesList = function() {
  return this.setSupplierLocationNamesList([]);
};


/**
 * repeated string products = 5;
 * @return {!Array<string>}
 */
proto.vtv1.PurchaseOrderSummary.prototype.getProductsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.vtv1.PurchaseOrderSummary} returns this
 */
proto.vtv1.PurchaseOrderSummary.prototype.setProductsList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.vtv1.PurchaseOrderSummary} returns this
 */
proto.vtv1.PurchaseOrderSummary.prototype.addProducts = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.PurchaseOrderSummary} returns this
 */
proto.vtv1.PurchaseOrderSummary.prototype.clearProductsList = function() {
  return this.setProductsList([]);
};


/**
 * optional string starts_at = 6;
 * @return {string}
 */
proto.vtv1.PurchaseOrderSummary.prototype.getStartsAt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.PurchaseOrderSummary} returns this
 */
proto.vtv1.PurchaseOrderSummary.prototype.setStartsAt = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional NullableTime expires_at = 7;
 * @return {?proto.vtv1.NullableTime}
 */
proto.vtv1.PurchaseOrderSummary.prototype.getExpiresAt = function() {
  return /** @type{?proto.vtv1.NullableTime} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableTime, 7));
};


/**
 * @param {?proto.vtv1.NullableTime|undefined} value
 * @return {!proto.vtv1.PurchaseOrderSummary} returns this
*/
proto.vtv1.PurchaseOrderSummary.prototype.setExpiresAt = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.PurchaseOrderSummary} returns this
 */
proto.vtv1.PurchaseOrderSummary.prototype.clearExpiresAt = function() {
  return this.setExpiresAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.PurchaseOrderSummary.prototype.hasExpiresAt = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional NullableTime imported_at = 8;
 * @return {?proto.vtv1.NullableTime}
 */
proto.vtv1.PurchaseOrderSummary.prototype.getImportedAt = function() {
  return /** @type{?proto.vtv1.NullableTime} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableTime, 8));
};


/**
 * @param {?proto.vtv1.NullableTime|undefined} value
 * @return {!proto.vtv1.PurchaseOrderSummary} returns this
*/
proto.vtv1.PurchaseOrderSummary.prototype.setImportedAt = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.PurchaseOrderSummary} returns this
 */
proto.vtv1.PurchaseOrderSummary.prototype.clearImportedAt = function() {
  return this.setImportedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.PurchaseOrderSummary.prototype.hasImportedAt = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional NullableTime confirmed_at = 9;
 * @return {?proto.vtv1.NullableTime}
 */
proto.vtv1.PurchaseOrderSummary.prototype.getConfirmedAt = function() {
  return /** @type{?proto.vtv1.NullableTime} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableTime, 9));
};


/**
 * @param {?proto.vtv1.NullableTime|undefined} value
 * @return {!proto.vtv1.PurchaseOrderSummary} returns this
*/
proto.vtv1.PurchaseOrderSummary.prototype.setConfirmedAt = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.PurchaseOrderSummary} returns this
 */
proto.vtv1.PurchaseOrderSummary.prototype.clearConfirmedAt = function() {
  return this.setConfirmedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.PurchaseOrderSummary.prototype.hasConfirmedAt = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional NullableTime declined_at = 10;
 * @return {?proto.vtv1.NullableTime}
 */
proto.vtv1.PurchaseOrderSummary.prototype.getDeclinedAt = function() {
  return /** @type{?proto.vtv1.NullableTime} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableTime, 10));
};


/**
 * @param {?proto.vtv1.NullableTime|undefined} value
 * @return {!proto.vtv1.PurchaseOrderSummary} returns this
*/
proto.vtv1.PurchaseOrderSummary.prototype.setDeclinedAt = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.PurchaseOrderSummary} returns this
 */
proto.vtv1.PurchaseOrderSummary.prototype.clearDeclinedAt = function() {
  return this.setDeclinedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.PurchaseOrderSummary.prototype.hasDeclinedAt = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional NullableString paused_by = 11;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.PurchaseOrderSummary.prototype.getPausedBy = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableString, 11));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.PurchaseOrderSummary} returns this
*/
proto.vtv1.PurchaseOrderSummary.prototype.setPausedBy = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.PurchaseOrderSummary} returns this
 */
proto.vtv1.PurchaseOrderSummary.prototype.clearPausedBy = function() {
  return this.setPausedBy(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.PurchaseOrderSummary.prototype.hasPausedBy = function() {
  return jspb.Message.getField(this, 11) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.PurchaseOrderSummaryAsSupplier.repeatedFields_ = [4,5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.PurchaseOrderSummaryAsSupplier.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.PurchaseOrderSummaryAsSupplier.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.PurchaseOrderSummaryAsSupplier} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.PurchaseOrderSummaryAsSupplier.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    consumerName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    locationNamesList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
    productsList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f,
    startsAt: jspb.Message.getFieldWithDefault(msg, 6, ""),
    expiresAt: (f = msg.getExpiresAt()) && proto_vorto_types_types_pb.NullableTime.toObject(includeInstance, f),
    confirmedAt: (f = msg.getConfirmedAt()) && proto_vorto_types_types_pb.NullableTime.toObject(includeInstance, f),
    declinedAt: (f = msg.getDeclinedAt()) && proto_vorto_types_types_pb.NullableTime.toObject(includeInstance, f),
    pausedBy: (f = msg.getPausedBy()) && proto_vorto_types_types_pb.NullableString.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.PurchaseOrderSummaryAsSupplier}
 */
proto.vtv1.PurchaseOrderSummaryAsSupplier.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.PurchaseOrderSummaryAsSupplier;
  return proto.vtv1.PurchaseOrderSummaryAsSupplier.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.PurchaseOrderSummaryAsSupplier} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.PurchaseOrderSummaryAsSupplier}
 */
proto.vtv1.PurchaseOrderSummaryAsSupplier.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setConsumerName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.addLocationNames(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.addProducts(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setStartsAt(value);
      break;
    case 7:
      var value = new proto_vorto_types_types_pb.NullableTime;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableTime.deserializeBinaryFromReader);
      msg.setExpiresAt(value);
      break;
    case 9:
      var value = new proto_vorto_types_types_pb.NullableTime;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableTime.deserializeBinaryFromReader);
      msg.setConfirmedAt(value);
      break;
    case 10:
      var value = new proto_vorto_types_types_pb.NullableTime;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableTime.deserializeBinaryFromReader);
      msg.setDeclinedAt(value);
      break;
    case 11:
      var value = new proto_vorto_types_types_pb.NullableString;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableString.deserializeBinaryFromReader);
      msg.setPausedBy(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.PurchaseOrderSummaryAsSupplier.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.PurchaseOrderSummaryAsSupplier.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.PurchaseOrderSummaryAsSupplier} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.PurchaseOrderSummaryAsSupplier.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getConsumerName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getLocationNamesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      4,
      f
    );
  }
  f = message.getProductsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      5,
      f
    );
  }
  f = message.getStartsAt();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getExpiresAt();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto_vorto_types_types_pb.NullableTime.serializeBinaryToWriter
    );
  }
  f = message.getConfirmedAt();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto_vorto_types_types_pb.NullableTime.serializeBinaryToWriter
    );
  }
  f = message.getDeclinedAt();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto_vorto_types_types_pb.NullableTime.serializeBinaryToWriter
    );
  }
  f = message.getPausedBy();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto_vorto_types_types_pb.NullableString.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.vtv1.PurchaseOrderSummaryAsSupplier.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.PurchaseOrderSummaryAsSupplier} returns this
 */
proto.vtv1.PurchaseOrderSummaryAsSupplier.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.vtv1.PurchaseOrderSummaryAsSupplier.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.PurchaseOrderSummaryAsSupplier} returns this
 */
proto.vtv1.PurchaseOrderSummaryAsSupplier.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string consumer_name = 3;
 * @return {string}
 */
proto.vtv1.PurchaseOrderSummaryAsSupplier.prototype.getConsumerName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.PurchaseOrderSummaryAsSupplier} returns this
 */
proto.vtv1.PurchaseOrderSummaryAsSupplier.prototype.setConsumerName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated string location_names = 4;
 * @return {!Array<string>}
 */
proto.vtv1.PurchaseOrderSummaryAsSupplier.prototype.getLocationNamesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.vtv1.PurchaseOrderSummaryAsSupplier} returns this
 */
proto.vtv1.PurchaseOrderSummaryAsSupplier.prototype.setLocationNamesList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.vtv1.PurchaseOrderSummaryAsSupplier} returns this
 */
proto.vtv1.PurchaseOrderSummaryAsSupplier.prototype.addLocationNames = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.PurchaseOrderSummaryAsSupplier} returns this
 */
proto.vtv1.PurchaseOrderSummaryAsSupplier.prototype.clearLocationNamesList = function() {
  return this.setLocationNamesList([]);
};


/**
 * repeated string products = 5;
 * @return {!Array<string>}
 */
proto.vtv1.PurchaseOrderSummaryAsSupplier.prototype.getProductsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.vtv1.PurchaseOrderSummaryAsSupplier} returns this
 */
proto.vtv1.PurchaseOrderSummaryAsSupplier.prototype.setProductsList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.vtv1.PurchaseOrderSummaryAsSupplier} returns this
 */
proto.vtv1.PurchaseOrderSummaryAsSupplier.prototype.addProducts = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.PurchaseOrderSummaryAsSupplier} returns this
 */
proto.vtv1.PurchaseOrderSummaryAsSupplier.prototype.clearProductsList = function() {
  return this.setProductsList([]);
};


/**
 * optional string starts_at = 6;
 * @return {string}
 */
proto.vtv1.PurchaseOrderSummaryAsSupplier.prototype.getStartsAt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.PurchaseOrderSummaryAsSupplier} returns this
 */
proto.vtv1.PurchaseOrderSummaryAsSupplier.prototype.setStartsAt = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional NullableTime expires_at = 7;
 * @return {?proto.vtv1.NullableTime}
 */
proto.vtv1.PurchaseOrderSummaryAsSupplier.prototype.getExpiresAt = function() {
  return /** @type{?proto.vtv1.NullableTime} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableTime, 7));
};


/**
 * @param {?proto.vtv1.NullableTime|undefined} value
 * @return {!proto.vtv1.PurchaseOrderSummaryAsSupplier} returns this
*/
proto.vtv1.PurchaseOrderSummaryAsSupplier.prototype.setExpiresAt = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.PurchaseOrderSummaryAsSupplier} returns this
 */
proto.vtv1.PurchaseOrderSummaryAsSupplier.prototype.clearExpiresAt = function() {
  return this.setExpiresAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.PurchaseOrderSummaryAsSupplier.prototype.hasExpiresAt = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional NullableTime confirmed_at = 9;
 * @return {?proto.vtv1.NullableTime}
 */
proto.vtv1.PurchaseOrderSummaryAsSupplier.prototype.getConfirmedAt = function() {
  return /** @type{?proto.vtv1.NullableTime} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableTime, 9));
};


/**
 * @param {?proto.vtv1.NullableTime|undefined} value
 * @return {!proto.vtv1.PurchaseOrderSummaryAsSupplier} returns this
*/
proto.vtv1.PurchaseOrderSummaryAsSupplier.prototype.setConfirmedAt = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.PurchaseOrderSummaryAsSupplier} returns this
 */
proto.vtv1.PurchaseOrderSummaryAsSupplier.prototype.clearConfirmedAt = function() {
  return this.setConfirmedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.PurchaseOrderSummaryAsSupplier.prototype.hasConfirmedAt = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional NullableTime declined_at = 10;
 * @return {?proto.vtv1.NullableTime}
 */
proto.vtv1.PurchaseOrderSummaryAsSupplier.prototype.getDeclinedAt = function() {
  return /** @type{?proto.vtv1.NullableTime} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableTime, 10));
};


/**
 * @param {?proto.vtv1.NullableTime|undefined} value
 * @return {!proto.vtv1.PurchaseOrderSummaryAsSupplier} returns this
*/
proto.vtv1.PurchaseOrderSummaryAsSupplier.prototype.setDeclinedAt = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.PurchaseOrderSummaryAsSupplier} returns this
 */
proto.vtv1.PurchaseOrderSummaryAsSupplier.prototype.clearDeclinedAt = function() {
  return this.setDeclinedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.PurchaseOrderSummaryAsSupplier.prototype.hasDeclinedAt = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional NullableString paused_by = 11;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.PurchaseOrderSummaryAsSupplier.prototype.getPausedBy = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableString, 11));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.PurchaseOrderSummaryAsSupplier} returns this
*/
proto.vtv1.PurchaseOrderSummaryAsSupplier.prototype.setPausedBy = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.PurchaseOrderSummaryAsSupplier} returns this
 */
proto.vtv1.PurchaseOrderSummaryAsSupplier.prototype.clearPausedBy = function() {
  return this.setPausedBy(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.PurchaseOrderSummaryAsSupplier.prototype.hasPausedBy = function() {
  return jspb.Message.getField(this, 11) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.Inventory.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.Inventory.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.Inventory} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.Inventory.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    productId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    productName: jspb.Message.getFieldWithDefault(msg, 4, ""),
    productUnitName: jspb.Message.getFieldWithDefault(msg, 5, ""),
    productUnitAbbreviation: jspb.Message.getFieldWithDefault(msg, 6, ""),
    capacity: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    stock: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
    stockUpdatedAt: jspb.Message.getFieldWithDefault(msg, 9, ""),
    defaultLoadQuantity: jspb.Message.getFloatingPointFieldWithDefault(msg, 10, 0.0),
    autoRestock: jspb.Message.getBooleanFieldWithDefault(msg, 11, false),
    defaultUnloadingMinutes: jspb.Message.getFieldWithDefault(msg, 12, 0),
    autoOrder: jspb.Message.getBooleanFieldWithDefault(msg, 13, false),
    warningPercentage: (f = msg.getWarningPercentage()) && proto_vorto_types_types_pb.NullableDouble.toObject(includeInstance, f),
    warningHours: (f = msg.getWarningHours()) && proto_vorto_types_types_pb.NullableInt64.toObject(includeInstance, f),
    minutesUntilStockOut: (f = msg.getMinutesUntilStockOut()) && proto_vorto_types_types_pb.NullableInt64.toObject(includeInstance, f),
    inbound: jspb.Message.getBooleanFieldWithDefault(msg, 17, false),
    outbound: jspb.Message.getBooleanFieldWithDefault(msg, 18, false),
    estimatedLoadsLeftToday: jspb.Message.getFieldWithDefault(msg, 19, 0),
    estimatedLoadsLeftTomorrow: jspb.Message.getFieldWithDefault(msg, 20, 0),
    warnWhenLowerThan: jspb.Message.getFieldWithDefault(msg, 21, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.Inventory}
 */
proto.vtv1.Inventory.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.Inventory;
  return proto.vtv1.Inventory.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.Inventory} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.Inventory}
 */
proto.vtv1.Inventory.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductName(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductUnitName(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductUnitAbbreviation(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setCapacity(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setStock(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setStockUpdatedAt(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setDefaultLoadQuantity(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAutoRestock(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDefaultUnloadingMinutes(value);
      break;
    case 13:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAutoOrder(value);
      break;
    case 14:
      var value = new proto_vorto_types_types_pb.NullableDouble;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableDouble.deserializeBinaryFromReader);
      msg.setWarningPercentage(value);
      break;
    case 15:
      var value = new proto_vorto_types_types_pb.NullableInt64;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableInt64.deserializeBinaryFromReader);
      msg.setWarningHours(value);
      break;
    case 16:
      var value = new proto_vorto_types_types_pb.NullableInt64;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableInt64.deserializeBinaryFromReader);
      msg.setMinutesUntilStockOut(value);
      break;
    case 17:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setInbound(value);
      break;
    case 18:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOutbound(value);
      break;
    case 19:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setEstimatedLoadsLeftToday(value);
      break;
    case 20:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setEstimatedLoadsLeftTomorrow(value);
      break;
    case 21:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setWarnWhenLowerThan(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.Inventory.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.Inventory.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.Inventory} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.Inventory.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getProductId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getProductName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getProductUnitName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getProductUnitAbbreviation();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getCapacity();
  if (f !== 0.0) {
    writer.writeDouble(
      7,
      f
    );
  }
  f = message.getStock();
  if (f !== 0.0) {
    writer.writeDouble(
      8,
      f
    );
  }
  f = message.getStockUpdatedAt();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getDefaultLoadQuantity();
  if (f !== 0.0) {
    writer.writeDouble(
      10,
      f
    );
  }
  f = message.getAutoRestock();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
  f = message.getDefaultUnloadingMinutes();
  if (f !== 0) {
    writer.writeInt64(
      12,
      f
    );
  }
  f = message.getAutoOrder();
  if (f) {
    writer.writeBool(
      13,
      f
    );
  }
  f = message.getWarningPercentage();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      proto_vorto_types_types_pb.NullableDouble.serializeBinaryToWriter
    );
  }
  f = message.getWarningHours();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      proto_vorto_types_types_pb.NullableInt64.serializeBinaryToWriter
    );
  }
  f = message.getMinutesUntilStockOut();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      proto_vorto_types_types_pb.NullableInt64.serializeBinaryToWriter
    );
  }
  f = message.getInbound();
  if (f) {
    writer.writeBool(
      17,
      f
    );
  }
  f = message.getOutbound();
  if (f) {
    writer.writeBool(
      18,
      f
    );
  }
  f = message.getEstimatedLoadsLeftToday();
  if (f !== 0) {
    writer.writeInt64(
      19,
      f
    );
  }
  f = message.getEstimatedLoadsLeftTomorrow();
  if (f !== 0) {
    writer.writeInt64(
      20,
      f
    );
  }
  f = message.getWarnWhenLowerThan();
  if (f !== 0) {
    writer.writeInt64(
      21,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.vtv1.Inventory.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.Inventory} returns this
 */
proto.vtv1.Inventory.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.vtv1.Inventory.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.Inventory} returns this
 */
proto.vtv1.Inventory.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string product_id = 3;
 * @return {string}
 */
proto.vtv1.Inventory.prototype.getProductId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.Inventory} returns this
 */
proto.vtv1.Inventory.prototype.setProductId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string product_name = 4;
 * @return {string}
 */
proto.vtv1.Inventory.prototype.getProductName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.Inventory} returns this
 */
proto.vtv1.Inventory.prototype.setProductName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string product_unit_name = 5;
 * @return {string}
 */
proto.vtv1.Inventory.prototype.getProductUnitName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.Inventory} returns this
 */
proto.vtv1.Inventory.prototype.setProductUnitName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string product_unit_abbreviation = 6;
 * @return {string}
 */
proto.vtv1.Inventory.prototype.getProductUnitAbbreviation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.Inventory} returns this
 */
proto.vtv1.Inventory.prototype.setProductUnitAbbreviation = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional double capacity = 7;
 * @return {number}
 */
proto.vtv1.Inventory.prototype.getCapacity = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.Inventory} returns this
 */
proto.vtv1.Inventory.prototype.setCapacity = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional double stock = 8;
 * @return {number}
 */
proto.vtv1.Inventory.prototype.getStock = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.Inventory} returns this
 */
proto.vtv1.Inventory.prototype.setStock = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};


/**
 * optional string stock_updated_at = 9;
 * @return {string}
 */
proto.vtv1.Inventory.prototype.getStockUpdatedAt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.Inventory} returns this
 */
proto.vtv1.Inventory.prototype.setStockUpdatedAt = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional double default_load_quantity = 10;
 * @return {number}
 */
proto.vtv1.Inventory.prototype.getDefaultLoadQuantity = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 10, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.Inventory} returns this
 */
proto.vtv1.Inventory.prototype.setDefaultLoadQuantity = function(value) {
  return jspb.Message.setProto3FloatField(this, 10, value);
};


/**
 * optional bool auto_restock = 11;
 * @return {boolean}
 */
proto.vtv1.Inventory.prototype.getAutoRestock = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vtv1.Inventory} returns this
 */
proto.vtv1.Inventory.prototype.setAutoRestock = function(value) {
  return jspb.Message.setProto3BooleanField(this, 11, value);
};


/**
 * optional int64 default_unloading_minutes = 12;
 * @return {number}
 */
proto.vtv1.Inventory.prototype.getDefaultUnloadingMinutes = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.Inventory} returns this
 */
proto.vtv1.Inventory.prototype.setDefaultUnloadingMinutes = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional bool auto_order = 13;
 * @return {boolean}
 */
proto.vtv1.Inventory.prototype.getAutoOrder = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 13, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vtv1.Inventory} returns this
 */
proto.vtv1.Inventory.prototype.setAutoOrder = function(value) {
  return jspb.Message.setProto3BooleanField(this, 13, value);
};


/**
 * optional NullableDouble warning_percentage = 14;
 * @return {?proto.vtv1.NullableDouble}
 */
proto.vtv1.Inventory.prototype.getWarningPercentage = function() {
  return /** @type{?proto.vtv1.NullableDouble} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableDouble, 14));
};


/**
 * @param {?proto.vtv1.NullableDouble|undefined} value
 * @return {!proto.vtv1.Inventory} returns this
*/
proto.vtv1.Inventory.prototype.setWarningPercentage = function(value) {
  return jspb.Message.setWrapperField(this, 14, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.Inventory} returns this
 */
proto.vtv1.Inventory.prototype.clearWarningPercentage = function() {
  return this.setWarningPercentage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.Inventory.prototype.hasWarningPercentage = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional NullableInt64 warning_hours = 15;
 * @return {?proto.vtv1.NullableInt64}
 */
proto.vtv1.Inventory.prototype.getWarningHours = function() {
  return /** @type{?proto.vtv1.NullableInt64} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableInt64, 15));
};


/**
 * @param {?proto.vtv1.NullableInt64|undefined} value
 * @return {!proto.vtv1.Inventory} returns this
*/
proto.vtv1.Inventory.prototype.setWarningHours = function(value) {
  return jspb.Message.setWrapperField(this, 15, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.Inventory} returns this
 */
proto.vtv1.Inventory.prototype.clearWarningHours = function() {
  return this.setWarningHours(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.Inventory.prototype.hasWarningHours = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional NullableInt64 minutes_until_stock_out = 16;
 * @return {?proto.vtv1.NullableInt64}
 */
proto.vtv1.Inventory.prototype.getMinutesUntilStockOut = function() {
  return /** @type{?proto.vtv1.NullableInt64} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableInt64, 16));
};


/**
 * @param {?proto.vtv1.NullableInt64|undefined} value
 * @return {!proto.vtv1.Inventory} returns this
*/
proto.vtv1.Inventory.prototype.setMinutesUntilStockOut = function(value) {
  return jspb.Message.setWrapperField(this, 16, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.Inventory} returns this
 */
proto.vtv1.Inventory.prototype.clearMinutesUntilStockOut = function() {
  return this.setMinutesUntilStockOut(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.Inventory.prototype.hasMinutesUntilStockOut = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * optional bool inbound = 17;
 * @return {boolean}
 */
proto.vtv1.Inventory.prototype.getInbound = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 17, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vtv1.Inventory} returns this
 */
proto.vtv1.Inventory.prototype.setInbound = function(value) {
  return jspb.Message.setProto3BooleanField(this, 17, value);
};


/**
 * optional bool outbound = 18;
 * @return {boolean}
 */
proto.vtv1.Inventory.prototype.getOutbound = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 18, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vtv1.Inventory} returns this
 */
proto.vtv1.Inventory.prototype.setOutbound = function(value) {
  return jspb.Message.setProto3BooleanField(this, 18, value);
};


/**
 * optional int64 estimated_loads_left_today = 19;
 * @return {number}
 */
proto.vtv1.Inventory.prototype.getEstimatedLoadsLeftToday = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 19, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.Inventory} returns this
 */
proto.vtv1.Inventory.prototype.setEstimatedLoadsLeftToday = function(value) {
  return jspb.Message.setProto3IntField(this, 19, value);
};


/**
 * optional int64 estimated_loads_left_tomorrow = 20;
 * @return {number}
 */
proto.vtv1.Inventory.prototype.getEstimatedLoadsLeftTomorrow = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 20, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.Inventory} returns this
 */
proto.vtv1.Inventory.prototype.setEstimatedLoadsLeftTomorrow = function(value) {
  return jspb.Message.setProto3IntField(this, 20, value);
};


/**
 * optional int64 warn_when_lower_than = 21;
 * @return {number}
 */
proto.vtv1.Inventory.prototype.getWarnWhenLowerThan = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 21, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.Inventory} returns this
 */
proto.vtv1.Inventory.prototype.setWarnWhenLowerThan = function(value) {
  return jspb.Message.setProto3IntField(this, 21, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.InventoryDetail.repeatedFields_ = [15,18,25,29,33,39];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.InventoryDetail.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.InventoryDetail.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.InventoryDetail} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.InventoryDetail.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    productId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    productName: jspb.Message.getFieldWithDefault(msg, 4, ""),
    productUnitName: jspb.Message.getFieldWithDefault(msg, 5, ""),
    productUnitAbbreviation: jspb.Message.getFieldWithDefault(msg, 6, ""),
    capacity: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    stock: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
    stockUpdatedAt: jspb.Message.getFieldWithDefault(msg, 9, ""),
    defaultLoadQuantity: jspb.Message.getFloatingPointFieldWithDefault(msg, 10, 0.0),
    scheduledTodayCount: jspb.Message.getFieldWithDefault(msg, 12, 0),
    enRouteCount: jspb.Message.getFieldWithDefault(msg, 13, 0),
    arrivedCount: jspb.Message.getFieldWithDefault(msg, 14, 0),
    stockHistoryList: jspb.Message.toObjectList(msg.getStockHistoryList(),
    proto.vtv1.InventoryStockDatapoint.toObject, includeInstance),
    defaultUnloadingMinutes: jspb.Message.getFieldWithDefault(msg, 17, 0),
    stockPredictionsList: jspb.Message.toObjectList(msg.getStockPredictionsList(),
    proto.vtv1.InventoryStockDatapoint.toObject, includeInstance),
    restockStrategy: jspb.Message.getFieldWithDefault(msg, 19, 0),
    completedOrderCount: jspb.Message.getFieldWithDefault(msg, 20, 0),
    nptEstimatedAt: (f = msg.getNptEstimatedAt()) && proto_vorto_types_types_pb.NullableTime.toObject(includeInstance, f),
    averageDwellTime: jspb.Message.getFieldWithDefault(msg, 22, 0),
    loadsNeedingAttention: jspb.Message.getFieldWithDefault(msg, 23, 0),
    atCarrierCount: jspb.Message.getFieldWithDefault(msg, 24, 0),
    truckArrivalsList: (f = jspb.Message.getRepeatedField(msg, 25)) == null ? undefined : f,
    sentProductRequests: jspb.Message.getFieldWithDefault(msg, 26, 0),
    warningPercentage: (f = msg.getWarningPercentage()) && proto_vorto_types_types_pb.NullableDouble.toObject(includeInstance, f),
    warningHours: (f = msg.getWarningHours()) && proto_vorto_types_types_pb.NullableInt64.toObject(includeInstance, f),
    trailerTypesList: jspb.Message.toObjectList(msg.getTrailerTypesList(),
    proto_vorto_types_types_pb.IntIdName.toObject, includeInstance),
    stockUpdatedById: (f = msg.getStockUpdatedById()) && proto_vorto_types_types_pb.NullableString.toObject(includeInstance, f),
    stockUpdatedByName: (f = msg.getStockUpdatedByName()) && proto_vorto_types_types_pb.NullableString.toObject(includeInstance, f),
    archivedAt: (f = msg.getArchivedAt()) && proto_vorto_types_types_pb.NullableTime.toObject(includeInstance, f),
    ordersNeedingAttentionIdsList: (f = jspb.Message.getRepeatedField(msg, 33)) == null ? undefined : f,
    inbound: jspb.Message.getBooleanFieldWithDefault(msg, 34, false),
    outbound: jspb.Message.getBooleanFieldWithDefault(msg, 35, false),
    reloadWindow: jspb.Message.getFieldWithDefault(msg, 36, 0),
    addBackhauls: jspb.Message.getBooleanFieldWithDefault(msg, 37, false),
    leadtimeHours: jspb.Message.getFieldWithDefault(msg, 38, 0),
    certIdsList: (f = jspb.Message.getRepeatedField(msg, 39)) == null ? undefined : f,
    byContainer: jspb.Message.getBooleanFieldWithDefault(msg, 40, false),
    reverseBackhaulEnabled: jspb.Message.getBooleanFieldWithDefault(msg, 41, false),
    salesOrderLineItemId: (f = msg.getSalesOrderLineItemId()) && proto_vorto_types_types_pb.NullableString.toObject(includeInstance, f),
    salesOrderLineItemNumber: (f = msg.getSalesOrderLineItemNumber()) && proto_vorto_types_types_pb.NullableString.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.InventoryDetail}
 */
proto.vtv1.InventoryDetail.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.InventoryDetail;
  return proto.vtv1.InventoryDetail.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.InventoryDetail} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.InventoryDetail}
 */
proto.vtv1.InventoryDetail.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductName(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductUnitName(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductUnitAbbreviation(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setCapacity(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setStock(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setStockUpdatedAt(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setDefaultLoadQuantity(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setScheduledTodayCount(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setEnRouteCount(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setArrivedCount(value);
      break;
    case 15:
      var value = new proto.vtv1.InventoryStockDatapoint;
      reader.readMessage(value,proto.vtv1.InventoryStockDatapoint.deserializeBinaryFromReader);
      msg.addStockHistory(value);
      break;
    case 17:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDefaultUnloadingMinutes(value);
      break;
    case 18:
      var value = new proto.vtv1.InventoryStockDatapoint;
      reader.readMessage(value,proto.vtv1.InventoryStockDatapoint.deserializeBinaryFromReader);
      msg.addStockPredictions(value);
      break;
    case 19:
      var value = /** @type {!proto.vtv1.RestockStrategy} */ (reader.readEnum());
      msg.setRestockStrategy(value);
      break;
    case 20:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCompletedOrderCount(value);
      break;
    case 21:
      var value = new proto_vorto_types_types_pb.NullableTime;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableTime.deserializeBinaryFromReader);
      msg.setNptEstimatedAt(value);
      break;
    case 22:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAverageDwellTime(value);
      break;
    case 23:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLoadsNeedingAttention(value);
      break;
    case 24:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAtCarrierCount(value);
      break;
    case 25:
      var value = /** @type {string} */ (reader.readString());
      msg.addTruckArrivals(value);
      break;
    case 26:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSentProductRequests(value);
      break;
    case 27:
      var value = new proto_vorto_types_types_pb.NullableDouble;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableDouble.deserializeBinaryFromReader);
      msg.setWarningPercentage(value);
      break;
    case 28:
      var value = new proto_vorto_types_types_pb.NullableInt64;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableInt64.deserializeBinaryFromReader);
      msg.setWarningHours(value);
      break;
    case 29:
      var value = new proto_vorto_types_types_pb.IntIdName;
      reader.readMessage(value,proto_vorto_types_types_pb.IntIdName.deserializeBinaryFromReader);
      msg.addTrailerTypes(value);
      break;
    case 30:
      var value = new proto_vorto_types_types_pb.NullableString;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableString.deserializeBinaryFromReader);
      msg.setStockUpdatedById(value);
      break;
    case 31:
      var value = new proto_vorto_types_types_pb.NullableString;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableString.deserializeBinaryFromReader);
      msg.setStockUpdatedByName(value);
      break;
    case 32:
      var value = new proto_vorto_types_types_pb.NullableTime;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableTime.deserializeBinaryFromReader);
      msg.setArchivedAt(value);
      break;
    case 33:
      var value = /** @type {string} */ (reader.readString());
      msg.addOrdersNeedingAttentionIds(value);
      break;
    case 34:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setInbound(value);
      break;
    case 35:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOutbound(value);
      break;
    case 36:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setReloadWindow(value);
      break;
    case 37:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAddBackhauls(value);
      break;
    case 38:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLeadtimeHours(value);
      break;
    case 39:
      var value = /** @type {!Array<number>} */ (reader.readPackedInt64());
      msg.setCertIdsList(value);
      break;
    case 40:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setByContainer(value);
      break;
    case 41:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setReverseBackhaulEnabled(value);
      break;
    case 42:
      var value = new proto_vorto_types_types_pb.NullableString;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableString.deserializeBinaryFromReader);
      msg.setSalesOrderLineItemId(value);
      break;
    case 43:
      var value = new proto_vorto_types_types_pb.NullableString;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableString.deserializeBinaryFromReader);
      msg.setSalesOrderLineItemNumber(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.InventoryDetail.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.InventoryDetail.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.InventoryDetail} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.InventoryDetail.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getProductId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getProductName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getProductUnitName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getProductUnitAbbreviation();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getCapacity();
  if (f !== 0.0) {
    writer.writeDouble(
      7,
      f
    );
  }
  f = message.getStock();
  if (f !== 0.0) {
    writer.writeDouble(
      8,
      f
    );
  }
  f = message.getStockUpdatedAt();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getDefaultLoadQuantity();
  if (f !== 0.0) {
    writer.writeDouble(
      10,
      f
    );
  }
  f = message.getScheduledTodayCount();
  if (f !== 0) {
    writer.writeInt64(
      12,
      f
    );
  }
  f = message.getEnRouteCount();
  if (f !== 0) {
    writer.writeInt64(
      13,
      f
    );
  }
  f = message.getArrivedCount();
  if (f !== 0) {
    writer.writeInt64(
      14,
      f
    );
  }
  f = message.getStockHistoryList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      15,
      f,
      proto.vtv1.InventoryStockDatapoint.serializeBinaryToWriter
    );
  }
  f = message.getDefaultUnloadingMinutes();
  if (f !== 0) {
    writer.writeInt64(
      17,
      f
    );
  }
  f = message.getStockPredictionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      18,
      f,
      proto.vtv1.InventoryStockDatapoint.serializeBinaryToWriter
    );
  }
  f = message.getRestockStrategy();
  if (f !== 0.0) {
    writer.writeEnum(
      19,
      f
    );
  }
  f = message.getCompletedOrderCount();
  if (f !== 0) {
    writer.writeInt64(
      20,
      f
    );
  }
  f = message.getNptEstimatedAt();
  if (f != null) {
    writer.writeMessage(
      21,
      f,
      proto_vorto_types_types_pb.NullableTime.serializeBinaryToWriter
    );
  }
  f = message.getAverageDwellTime();
  if (f !== 0) {
    writer.writeInt64(
      22,
      f
    );
  }
  f = message.getLoadsNeedingAttention();
  if (f !== 0) {
    writer.writeInt64(
      23,
      f
    );
  }
  f = message.getAtCarrierCount();
  if (f !== 0) {
    writer.writeInt64(
      24,
      f
    );
  }
  f = message.getTruckArrivalsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      25,
      f
    );
  }
  f = message.getSentProductRequests();
  if (f !== 0) {
    writer.writeInt64(
      26,
      f
    );
  }
  f = message.getWarningPercentage();
  if (f != null) {
    writer.writeMessage(
      27,
      f,
      proto_vorto_types_types_pb.NullableDouble.serializeBinaryToWriter
    );
  }
  f = message.getWarningHours();
  if (f != null) {
    writer.writeMessage(
      28,
      f,
      proto_vorto_types_types_pb.NullableInt64.serializeBinaryToWriter
    );
  }
  f = message.getTrailerTypesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      29,
      f,
      proto_vorto_types_types_pb.IntIdName.serializeBinaryToWriter
    );
  }
  f = message.getStockUpdatedById();
  if (f != null) {
    writer.writeMessage(
      30,
      f,
      proto_vorto_types_types_pb.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getStockUpdatedByName();
  if (f != null) {
    writer.writeMessage(
      31,
      f,
      proto_vorto_types_types_pb.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getArchivedAt();
  if (f != null) {
    writer.writeMessage(
      32,
      f,
      proto_vorto_types_types_pb.NullableTime.serializeBinaryToWriter
    );
  }
  f = message.getOrdersNeedingAttentionIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      33,
      f
    );
  }
  f = message.getInbound();
  if (f) {
    writer.writeBool(
      34,
      f
    );
  }
  f = message.getOutbound();
  if (f) {
    writer.writeBool(
      35,
      f
    );
  }
  f = message.getReloadWindow();
  if (f !== 0) {
    writer.writeInt64(
      36,
      f
    );
  }
  f = message.getAddBackhauls();
  if (f) {
    writer.writeBool(
      37,
      f
    );
  }
  f = message.getLeadtimeHours();
  if (f !== 0) {
    writer.writeInt64(
      38,
      f
    );
  }
  f = message.getCertIdsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      39,
      f
    );
  }
  f = message.getByContainer();
  if (f) {
    writer.writeBool(
      40,
      f
    );
  }
  f = message.getReverseBackhaulEnabled();
  if (f) {
    writer.writeBool(
      41,
      f
    );
  }
  f = message.getSalesOrderLineItemId();
  if (f != null) {
    writer.writeMessage(
      42,
      f,
      proto_vorto_types_types_pb.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getSalesOrderLineItemNumber();
  if (f != null) {
    writer.writeMessage(
      43,
      f,
      proto_vorto_types_types_pb.NullableString.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.vtv1.InventoryDetail.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.InventoryDetail} returns this
 */
proto.vtv1.InventoryDetail.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.vtv1.InventoryDetail.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.InventoryDetail} returns this
 */
proto.vtv1.InventoryDetail.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string product_id = 3;
 * @return {string}
 */
proto.vtv1.InventoryDetail.prototype.getProductId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.InventoryDetail} returns this
 */
proto.vtv1.InventoryDetail.prototype.setProductId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string product_name = 4;
 * @return {string}
 */
proto.vtv1.InventoryDetail.prototype.getProductName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.InventoryDetail} returns this
 */
proto.vtv1.InventoryDetail.prototype.setProductName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string product_unit_name = 5;
 * @return {string}
 */
proto.vtv1.InventoryDetail.prototype.getProductUnitName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.InventoryDetail} returns this
 */
proto.vtv1.InventoryDetail.prototype.setProductUnitName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string product_unit_abbreviation = 6;
 * @return {string}
 */
proto.vtv1.InventoryDetail.prototype.getProductUnitAbbreviation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.InventoryDetail} returns this
 */
proto.vtv1.InventoryDetail.prototype.setProductUnitAbbreviation = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional double capacity = 7;
 * @return {number}
 */
proto.vtv1.InventoryDetail.prototype.getCapacity = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.InventoryDetail} returns this
 */
proto.vtv1.InventoryDetail.prototype.setCapacity = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional double stock = 8;
 * @return {number}
 */
proto.vtv1.InventoryDetail.prototype.getStock = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.InventoryDetail} returns this
 */
proto.vtv1.InventoryDetail.prototype.setStock = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};


/**
 * optional string stock_updated_at = 9;
 * @return {string}
 */
proto.vtv1.InventoryDetail.prototype.getStockUpdatedAt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.InventoryDetail} returns this
 */
proto.vtv1.InventoryDetail.prototype.setStockUpdatedAt = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional double default_load_quantity = 10;
 * @return {number}
 */
proto.vtv1.InventoryDetail.prototype.getDefaultLoadQuantity = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 10, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.InventoryDetail} returns this
 */
proto.vtv1.InventoryDetail.prototype.setDefaultLoadQuantity = function(value) {
  return jspb.Message.setProto3FloatField(this, 10, value);
};


/**
 * optional int64 scheduled_today_count = 12;
 * @return {number}
 */
proto.vtv1.InventoryDetail.prototype.getScheduledTodayCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.InventoryDetail} returns this
 */
proto.vtv1.InventoryDetail.prototype.setScheduledTodayCount = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional int64 en_route_count = 13;
 * @return {number}
 */
proto.vtv1.InventoryDetail.prototype.getEnRouteCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.InventoryDetail} returns this
 */
proto.vtv1.InventoryDetail.prototype.setEnRouteCount = function(value) {
  return jspb.Message.setProto3IntField(this, 13, value);
};


/**
 * optional int64 arrived_count = 14;
 * @return {number}
 */
proto.vtv1.InventoryDetail.prototype.getArrivedCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.InventoryDetail} returns this
 */
proto.vtv1.InventoryDetail.prototype.setArrivedCount = function(value) {
  return jspb.Message.setProto3IntField(this, 14, value);
};


/**
 * repeated InventoryStockDatapoint stock_history = 15;
 * @return {!Array<!proto.vtv1.InventoryStockDatapoint>}
 */
proto.vtv1.InventoryDetail.prototype.getStockHistoryList = function() {
  return /** @type{!Array<!proto.vtv1.InventoryStockDatapoint>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vtv1.InventoryStockDatapoint, 15));
};


/**
 * @param {!Array<!proto.vtv1.InventoryStockDatapoint>} value
 * @return {!proto.vtv1.InventoryDetail} returns this
*/
proto.vtv1.InventoryDetail.prototype.setStockHistoryList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 15, value);
};


/**
 * @param {!proto.vtv1.InventoryStockDatapoint=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.InventoryStockDatapoint}
 */
proto.vtv1.InventoryDetail.prototype.addStockHistory = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 15, opt_value, proto.vtv1.InventoryStockDatapoint, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.InventoryDetail} returns this
 */
proto.vtv1.InventoryDetail.prototype.clearStockHistoryList = function() {
  return this.setStockHistoryList([]);
};


/**
 * optional int64 default_unloading_minutes = 17;
 * @return {number}
 */
proto.vtv1.InventoryDetail.prototype.getDefaultUnloadingMinutes = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 17, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.InventoryDetail} returns this
 */
proto.vtv1.InventoryDetail.prototype.setDefaultUnloadingMinutes = function(value) {
  return jspb.Message.setProto3IntField(this, 17, value);
};


/**
 * repeated InventoryStockDatapoint stock_predictions = 18;
 * @return {!Array<!proto.vtv1.InventoryStockDatapoint>}
 */
proto.vtv1.InventoryDetail.prototype.getStockPredictionsList = function() {
  return /** @type{!Array<!proto.vtv1.InventoryStockDatapoint>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vtv1.InventoryStockDatapoint, 18));
};


/**
 * @param {!Array<!proto.vtv1.InventoryStockDatapoint>} value
 * @return {!proto.vtv1.InventoryDetail} returns this
*/
proto.vtv1.InventoryDetail.prototype.setStockPredictionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 18, value);
};


/**
 * @param {!proto.vtv1.InventoryStockDatapoint=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.InventoryStockDatapoint}
 */
proto.vtv1.InventoryDetail.prototype.addStockPredictions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 18, opt_value, proto.vtv1.InventoryStockDatapoint, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.InventoryDetail} returns this
 */
proto.vtv1.InventoryDetail.prototype.clearStockPredictionsList = function() {
  return this.setStockPredictionsList([]);
};


/**
 * optional RestockStrategy restock_strategy = 19;
 * @return {!proto.vtv1.RestockStrategy}
 */
proto.vtv1.InventoryDetail.prototype.getRestockStrategy = function() {
  return /** @type {!proto.vtv1.RestockStrategy} */ (jspb.Message.getFieldWithDefault(this, 19, 0));
};


/**
 * @param {!proto.vtv1.RestockStrategy} value
 * @return {!proto.vtv1.InventoryDetail} returns this
 */
proto.vtv1.InventoryDetail.prototype.setRestockStrategy = function(value) {
  return jspb.Message.setProto3EnumField(this, 19, value);
};


/**
 * optional int64 completed_order_count = 20;
 * @return {number}
 */
proto.vtv1.InventoryDetail.prototype.getCompletedOrderCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 20, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.InventoryDetail} returns this
 */
proto.vtv1.InventoryDetail.prototype.setCompletedOrderCount = function(value) {
  return jspb.Message.setProto3IntField(this, 20, value);
};


/**
 * optional NullableTime npt_estimated_at = 21;
 * @return {?proto.vtv1.NullableTime}
 */
proto.vtv1.InventoryDetail.prototype.getNptEstimatedAt = function() {
  return /** @type{?proto.vtv1.NullableTime} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableTime, 21));
};


/**
 * @param {?proto.vtv1.NullableTime|undefined} value
 * @return {!proto.vtv1.InventoryDetail} returns this
*/
proto.vtv1.InventoryDetail.prototype.setNptEstimatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 21, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.InventoryDetail} returns this
 */
proto.vtv1.InventoryDetail.prototype.clearNptEstimatedAt = function() {
  return this.setNptEstimatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.InventoryDetail.prototype.hasNptEstimatedAt = function() {
  return jspb.Message.getField(this, 21) != null;
};


/**
 * optional int64 average_dwell_time = 22;
 * @return {number}
 */
proto.vtv1.InventoryDetail.prototype.getAverageDwellTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 22, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.InventoryDetail} returns this
 */
proto.vtv1.InventoryDetail.prototype.setAverageDwellTime = function(value) {
  return jspb.Message.setProto3IntField(this, 22, value);
};


/**
 * optional int64 loads_needing_attention = 23;
 * @return {number}
 */
proto.vtv1.InventoryDetail.prototype.getLoadsNeedingAttention = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 23, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.InventoryDetail} returns this
 */
proto.vtv1.InventoryDetail.prototype.setLoadsNeedingAttention = function(value) {
  return jspb.Message.setProto3IntField(this, 23, value);
};


/**
 * optional int64 at_carrier_count = 24;
 * @return {number}
 */
proto.vtv1.InventoryDetail.prototype.getAtCarrierCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 24, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.InventoryDetail} returns this
 */
proto.vtv1.InventoryDetail.prototype.setAtCarrierCount = function(value) {
  return jspb.Message.setProto3IntField(this, 24, value);
};


/**
 * repeated string truck_arrivals = 25;
 * @return {!Array<string>}
 */
proto.vtv1.InventoryDetail.prototype.getTruckArrivalsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 25));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.vtv1.InventoryDetail} returns this
 */
proto.vtv1.InventoryDetail.prototype.setTruckArrivalsList = function(value) {
  return jspb.Message.setField(this, 25, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.vtv1.InventoryDetail} returns this
 */
proto.vtv1.InventoryDetail.prototype.addTruckArrivals = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 25, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.InventoryDetail} returns this
 */
proto.vtv1.InventoryDetail.prototype.clearTruckArrivalsList = function() {
  return this.setTruckArrivalsList([]);
};


/**
 * optional int64 sent_product_requests = 26;
 * @return {number}
 */
proto.vtv1.InventoryDetail.prototype.getSentProductRequests = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 26, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.InventoryDetail} returns this
 */
proto.vtv1.InventoryDetail.prototype.setSentProductRequests = function(value) {
  return jspb.Message.setProto3IntField(this, 26, value);
};


/**
 * optional NullableDouble warning_percentage = 27;
 * @return {?proto.vtv1.NullableDouble}
 */
proto.vtv1.InventoryDetail.prototype.getWarningPercentage = function() {
  return /** @type{?proto.vtv1.NullableDouble} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableDouble, 27));
};


/**
 * @param {?proto.vtv1.NullableDouble|undefined} value
 * @return {!proto.vtv1.InventoryDetail} returns this
*/
proto.vtv1.InventoryDetail.prototype.setWarningPercentage = function(value) {
  return jspb.Message.setWrapperField(this, 27, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.InventoryDetail} returns this
 */
proto.vtv1.InventoryDetail.prototype.clearWarningPercentage = function() {
  return this.setWarningPercentage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.InventoryDetail.prototype.hasWarningPercentage = function() {
  return jspb.Message.getField(this, 27) != null;
};


/**
 * optional NullableInt64 warning_hours = 28;
 * @return {?proto.vtv1.NullableInt64}
 */
proto.vtv1.InventoryDetail.prototype.getWarningHours = function() {
  return /** @type{?proto.vtv1.NullableInt64} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableInt64, 28));
};


/**
 * @param {?proto.vtv1.NullableInt64|undefined} value
 * @return {!proto.vtv1.InventoryDetail} returns this
*/
proto.vtv1.InventoryDetail.prototype.setWarningHours = function(value) {
  return jspb.Message.setWrapperField(this, 28, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.InventoryDetail} returns this
 */
proto.vtv1.InventoryDetail.prototype.clearWarningHours = function() {
  return this.setWarningHours(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.InventoryDetail.prototype.hasWarningHours = function() {
  return jspb.Message.getField(this, 28) != null;
};


/**
 * repeated IntIdName trailer_types = 29;
 * @return {!Array<!proto.vtv1.IntIdName>}
 */
proto.vtv1.InventoryDetail.prototype.getTrailerTypesList = function() {
  return /** @type{!Array<!proto.vtv1.IntIdName>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_vorto_types_types_pb.IntIdName, 29));
};


/**
 * @param {!Array<!proto.vtv1.IntIdName>} value
 * @return {!proto.vtv1.InventoryDetail} returns this
*/
proto.vtv1.InventoryDetail.prototype.setTrailerTypesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 29, value);
};


/**
 * @param {!proto.vtv1.IntIdName=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.IntIdName}
 */
proto.vtv1.InventoryDetail.prototype.addTrailerTypes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 29, opt_value, proto.vtv1.IntIdName, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.InventoryDetail} returns this
 */
proto.vtv1.InventoryDetail.prototype.clearTrailerTypesList = function() {
  return this.setTrailerTypesList([]);
};


/**
 * optional NullableString stock_updated_by_id = 30;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.InventoryDetail.prototype.getStockUpdatedById = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableString, 30));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.InventoryDetail} returns this
*/
proto.vtv1.InventoryDetail.prototype.setStockUpdatedById = function(value) {
  return jspb.Message.setWrapperField(this, 30, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.InventoryDetail} returns this
 */
proto.vtv1.InventoryDetail.prototype.clearStockUpdatedById = function() {
  return this.setStockUpdatedById(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.InventoryDetail.prototype.hasStockUpdatedById = function() {
  return jspb.Message.getField(this, 30) != null;
};


/**
 * optional NullableString stock_updated_by_name = 31;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.InventoryDetail.prototype.getStockUpdatedByName = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableString, 31));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.InventoryDetail} returns this
*/
proto.vtv1.InventoryDetail.prototype.setStockUpdatedByName = function(value) {
  return jspb.Message.setWrapperField(this, 31, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.InventoryDetail} returns this
 */
proto.vtv1.InventoryDetail.prototype.clearStockUpdatedByName = function() {
  return this.setStockUpdatedByName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.InventoryDetail.prototype.hasStockUpdatedByName = function() {
  return jspb.Message.getField(this, 31) != null;
};


/**
 * optional NullableTime archived_at = 32;
 * @return {?proto.vtv1.NullableTime}
 */
proto.vtv1.InventoryDetail.prototype.getArchivedAt = function() {
  return /** @type{?proto.vtv1.NullableTime} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableTime, 32));
};


/**
 * @param {?proto.vtv1.NullableTime|undefined} value
 * @return {!proto.vtv1.InventoryDetail} returns this
*/
proto.vtv1.InventoryDetail.prototype.setArchivedAt = function(value) {
  return jspb.Message.setWrapperField(this, 32, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.InventoryDetail} returns this
 */
proto.vtv1.InventoryDetail.prototype.clearArchivedAt = function() {
  return this.setArchivedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.InventoryDetail.prototype.hasArchivedAt = function() {
  return jspb.Message.getField(this, 32) != null;
};


/**
 * repeated string orders_needing_attention_ids = 33;
 * @return {!Array<string>}
 */
proto.vtv1.InventoryDetail.prototype.getOrdersNeedingAttentionIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 33));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.vtv1.InventoryDetail} returns this
 */
proto.vtv1.InventoryDetail.prototype.setOrdersNeedingAttentionIdsList = function(value) {
  return jspb.Message.setField(this, 33, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.vtv1.InventoryDetail} returns this
 */
proto.vtv1.InventoryDetail.prototype.addOrdersNeedingAttentionIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 33, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.InventoryDetail} returns this
 */
proto.vtv1.InventoryDetail.prototype.clearOrdersNeedingAttentionIdsList = function() {
  return this.setOrdersNeedingAttentionIdsList([]);
};


/**
 * optional bool inbound = 34;
 * @return {boolean}
 */
proto.vtv1.InventoryDetail.prototype.getInbound = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 34, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vtv1.InventoryDetail} returns this
 */
proto.vtv1.InventoryDetail.prototype.setInbound = function(value) {
  return jspb.Message.setProto3BooleanField(this, 34, value);
};


/**
 * optional bool outbound = 35;
 * @return {boolean}
 */
proto.vtv1.InventoryDetail.prototype.getOutbound = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 35, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vtv1.InventoryDetail} returns this
 */
proto.vtv1.InventoryDetail.prototype.setOutbound = function(value) {
  return jspb.Message.setProto3BooleanField(this, 35, value);
};


/**
 * optional int64 reload_window = 36;
 * @return {number}
 */
proto.vtv1.InventoryDetail.prototype.getReloadWindow = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 36, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.InventoryDetail} returns this
 */
proto.vtv1.InventoryDetail.prototype.setReloadWindow = function(value) {
  return jspb.Message.setProto3IntField(this, 36, value);
};


/**
 * optional bool add_backhauls = 37;
 * @return {boolean}
 */
proto.vtv1.InventoryDetail.prototype.getAddBackhauls = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 37, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vtv1.InventoryDetail} returns this
 */
proto.vtv1.InventoryDetail.prototype.setAddBackhauls = function(value) {
  return jspb.Message.setProto3BooleanField(this, 37, value);
};


/**
 * optional int64 leadtime_hours = 38;
 * @return {number}
 */
proto.vtv1.InventoryDetail.prototype.getLeadtimeHours = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 38, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.InventoryDetail} returns this
 */
proto.vtv1.InventoryDetail.prototype.setLeadtimeHours = function(value) {
  return jspb.Message.setProto3IntField(this, 38, value);
};


/**
 * repeated int64 cert_ids = 39;
 * @return {!Array<number>}
 */
proto.vtv1.InventoryDetail.prototype.getCertIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 39));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.vtv1.InventoryDetail} returns this
 */
proto.vtv1.InventoryDetail.prototype.setCertIdsList = function(value) {
  return jspb.Message.setField(this, 39, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.vtv1.InventoryDetail} returns this
 */
proto.vtv1.InventoryDetail.prototype.addCertIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 39, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.InventoryDetail} returns this
 */
proto.vtv1.InventoryDetail.prototype.clearCertIdsList = function() {
  return this.setCertIdsList([]);
};


/**
 * optional bool by_container = 40;
 * @return {boolean}
 */
proto.vtv1.InventoryDetail.prototype.getByContainer = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 40, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vtv1.InventoryDetail} returns this
 */
proto.vtv1.InventoryDetail.prototype.setByContainer = function(value) {
  return jspb.Message.setProto3BooleanField(this, 40, value);
};


/**
 * optional bool reverse_backhaul_enabled = 41;
 * @return {boolean}
 */
proto.vtv1.InventoryDetail.prototype.getReverseBackhaulEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 41, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vtv1.InventoryDetail} returns this
 */
proto.vtv1.InventoryDetail.prototype.setReverseBackhaulEnabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 41, value);
};


/**
 * optional NullableString sales_order_line_item_id = 42;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.InventoryDetail.prototype.getSalesOrderLineItemId = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableString, 42));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.InventoryDetail} returns this
*/
proto.vtv1.InventoryDetail.prototype.setSalesOrderLineItemId = function(value) {
  return jspb.Message.setWrapperField(this, 42, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.InventoryDetail} returns this
 */
proto.vtv1.InventoryDetail.prototype.clearSalesOrderLineItemId = function() {
  return this.setSalesOrderLineItemId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.InventoryDetail.prototype.hasSalesOrderLineItemId = function() {
  return jspb.Message.getField(this, 42) != null;
};


/**
 * optional NullableString sales_order_line_item_number = 43;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.InventoryDetail.prototype.getSalesOrderLineItemNumber = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableString, 43));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.InventoryDetail} returns this
*/
proto.vtv1.InventoryDetail.prototype.setSalesOrderLineItemNumber = function(value) {
  return jspb.Message.setWrapperField(this, 43, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.InventoryDetail} returns this
 */
proto.vtv1.InventoryDetail.prototype.clearSalesOrderLineItemNumber = function() {
  return this.setSalesOrderLineItemNumber(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.InventoryDetail.prototype.hasSalesOrderLineItemNumber = function() {
  return jspb.Message.getField(this, 43) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.InventoryStockDatapoint.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.InventoryStockDatapoint.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.InventoryStockDatapoint} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.InventoryStockDatapoint.toObject = function(includeInstance, msg) {
  var f, obj = {
    stock: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    createdAt: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.InventoryStockDatapoint}
 */
proto.vtv1.InventoryStockDatapoint.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.InventoryStockDatapoint;
  return proto.vtv1.InventoryStockDatapoint.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.InventoryStockDatapoint} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.InventoryStockDatapoint}
 */
proto.vtv1.InventoryStockDatapoint.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setStock(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.InventoryStockDatapoint.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.InventoryStockDatapoint.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.InventoryStockDatapoint} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.InventoryStockDatapoint.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStock();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = message.getCreatedAt();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional double stock = 1;
 * @return {number}
 */
proto.vtv1.InventoryStockDatapoint.prototype.getStock = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.InventoryStockDatapoint} returns this
 */
proto.vtv1.InventoryStockDatapoint.prototype.setStock = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional string created_at = 2;
 * @return {string}
 */
proto.vtv1.InventoryStockDatapoint.prototype.getCreatedAt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.InventoryStockDatapoint} returns this
 */
proto.vtv1.InventoryStockDatapoint.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.InventoryGroup.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.InventoryGroup.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.InventoryGroup.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.InventoryGroup} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.InventoryGroup.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    inventoriesList: jspb.Message.toObjectList(msg.getInventoriesList(),
    proto.vtv1.Inventory.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.InventoryGroup}
 */
proto.vtv1.InventoryGroup.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.InventoryGroup;
  return proto.vtv1.InventoryGroup.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.InventoryGroup} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.InventoryGroup}
 */
proto.vtv1.InventoryGroup.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = new proto.vtv1.Inventory;
      reader.readMessage(value,proto.vtv1.Inventory.deserializeBinaryFromReader);
      msg.addInventories(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.InventoryGroup.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.InventoryGroup.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.InventoryGroup} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.InventoryGroup.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getInventoriesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.vtv1.Inventory.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.vtv1.InventoryGroup.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.InventoryGroup} returns this
 */
proto.vtv1.InventoryGroup.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.vtv1.InventoryGroup.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.InventoryGroup} returns this
 */
proto.vtv1.InventoryGroup.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated Inventory inventories = 3;
 * @return {!Array<!proto.vtv1.Inventory>}
 */
proto.vtv1.InventoryGroup.prototype.getInventoriesList = function() {
  return /** @type{!Array<!proto.vtv1.Inventory>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vtv1.Inventory, 3));
};


/**
 * @param {!Array<!proto.vtv1.Inventory>} value
 * @return {!proto.vtv1.InventoryGroup} returns this
*/
proto.vtv1.InventoryGroup.prototype.setInventoriesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.vtv1.Inventory=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.Inventory}
 */
proto.vtv1.InventoryGroup.prototype.addInventories = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.vtv1.Inventory, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.InventoryGroup} returns this
 */
proto.vtv1.InventoryGroup.prototype.clearInventoriesList = function() {
  return this.setInventoriesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.InventoryScheduledRFQ.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.InventoryScheduledRFQ.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.InventoryScheduledRFQ} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.InventoryScheduledRFQ.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestType: jspb.Message.getFieldWithDefault(msg, 1, 0),
    quantity: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    createdAt: jspb.Message.getFieldWithDefault(msg, 3, ""),
    startTime: jspb.Message.getFieldWithDefault(msg, 4, ""),
    endTime: jspb.Message.getFieldWithDefault(msg, 5, ""),
    maxPrice: (f = msg.getMaxPrice()) && proto_vorto_types_types_pb.NullableInt64.toObject(includeInstance, f),
    id: jspb.Message.getFieldWithDefault(msg, 7, ""),
    invitations: jspb.Message.getFieldWithDefault(msg, 8, 0),
    uniqueQuoters: jspb.Message.getFieldWithDefault(msg, 9, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.InventoryScheduledRFQ}
 */
proto.vtv1.InventoryScheduledRFQ.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.InventoryScheduledRFQ;
  return proto.vtv1.InventoryScheduledRFQ.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.InventoryScheduledRFQ} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.InventoryScheduledRFQ}
 */
proto.vtv1.InventoryScheduledRFQ.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.vtv1.SpotMarketRequestType} */ (reader.readEnum());
      msg.setRequestType(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setQuantity(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedAt(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setStartTime(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setEndTime(value);
      break;
    case 6:
      var value = new proto_vorto_types_types_pb.NullableInt64;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableInt64.deserializeBinaryFromReader);
      msg.setMaxPrice(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setInvitations(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUniqueQuoters(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.InventoryScheduledRFQ.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.InventoryScheduledRFQ.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.InventoryScheduledRFQ} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.InventoryScheduledRFQ.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getQuantity();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getCreatedAt();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getStartTime();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getEndTime();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getMaxPrice();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto_vorto_types_types_pb.NullableInt64.serializeBinaryToWriter
    );
  }
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getInvitations();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
  f = message.getUniqueQuoters();
  if (f !== 0) {
    writer.writeInt64(
      9,
      f
    );
  }
};


/**
 * optional SpotMarketRequestType request_type = 1;
 * @return {!proto.vtv1.SpotMarketRequestType}
 */
proto.vtv1.InventoryScheduledRFQ.prototype.getRequestType = function() {
  return /** @type {!proto.vtv1.SpotMarketRequestType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.vtv1.SpotMarketRequestType} value
 * @return {!proto.vtv1.InventoryScheduledRFQ} returns this
 */
proto.vtv1.InventoryScheduledRFQ.prototype.setRequestType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional double quantity = 2;
 * @return {number}
 */
proto.vtv1.InventoryScheduledRFQ.prototype.getQuantity = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.InventoryScheduledRFQ} returns this
 */
proto.vtv1.InventoryScheduledRFQ.prototype.setQuantity = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional string created_at = 3;
 * @return {string}
 */
proto.vtv1.InventoryScheduledRFQ.prototype.getCreatedAt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.InventoryScheduledRFQ} returns this
 */
proto.vtv1.InventoryScheduledRFQ.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string start_time = 4;
 * @return {string}
 */
proto.vtv1.InventoryScheduledRFQ.prototype.getStartTime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.InventoryScheduledRFQ} returns this
 */
proto.vtv1.InventoryScheduledRFQ.prototype.setStartTime = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string end_time = 5;
 * @return {string}
 */
proto.vtv1.InventoryScheduledRFQ.prototype.getEndTime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.InventoryScheduledRFQ} returns this
 */
proto.vtv1.InventoryScheduledRFQ.prototype.setEndTime = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional NullableInt64 max_price = 6;
 * @return {?proto.vtv1.NullableInt64}
 */
proto.vtv1.InventoryScheduledRFQ.prototype.getMaxPrice = function() {
  return /** @type{?proto.vtv1.NullableInt64} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableInt64, 6));
};


/**
 * @param {?proto.vtv1.NullableInt64|undefined} value
 * @return {!proto.vtv1.InventoryScheduledRFQ} returns this
*/
proto.vtv1.InventoryScheduledRFQ.prototype.setMaxPrice = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.InventoryScheduledRFQ} returns this
 */
proto.vtv1.InventoryScheduledRFQ.prototype.clearMaxPrice = function() {
  return this.setMaxPrice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.InventoryScheduledRFQ.prototype.hasMaxPrice = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional string id = 7;
 * @return {string}
 */
proto.vtv1.InventoryScheduledRFQ.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.InventoryScheduledRFQ} returns this
 */
proto.vtv1.InventoryScheduledRFQ.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional int64 invitations = 8;
 * @return {number}
 */
proto.vtv1.InventoryScheduledRFQ.prototype.getInvitations = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.InventoryScheduledRFQ} returns this
 */
proto.vtv1.InventoryScheduledRFQ.prototype.setInvitations = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional int64 unique_quoters = 9;
 * @return {number}
 */
proto.vtv1.InventoryScheduledRFQ.prototype.getUniqueQuoters = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.InventoryScheduledRFQ} returns this
 */
proto.vtv1.InventoryScheduledRFQ.prototype.setUniqueQuoters = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.InventoryCurrentRFQ.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.InventoryCurrentRFQ.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.InventoryCurrentRFQ} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.InventoryCurrentRFQ.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestType: jspb.Message.getFieldWithDefault(msg, 1, 0),
    quantity: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    startTime: jspb.Message.getFieldWithDefault(msg, 3, ""),
    endTime: jspb.Message.getFieldWithDefault(msg, 4, ""),
    maxPrice: (f = msg.getMaxPrice()) && proto_vorto_types_types_pb.NullableInt64.toObject(includeInstance, f),
    participantCount: jspb.Message.getFieldWithDefault(msg, 6, 0),
    id: jspb.Message.getFieldWithDefault(msg, 7, ""),
    invitations: jspb.Message.getFieldWithDefault(msg, 8, 0),
    uniqueQuoters: jspb.Message.getFieldWithDefault(msg, 9, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.InventoryCurrentRFQ}
 */
proto.vtv1.InventoryCurrentRFQ.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.InventoryCurrentRFQ;
  return proto.vtv1.InventoryCurrentRFQ.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.InventoryCurrentRFQ} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.InventoryCurrentRFQ}
 */
proto.vtv1.InventoryCurrentRFQ.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.vtv1.SpotMarketRequestType} */ (reader.readEnum());
      msg.setRequestType(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setQuantity(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setStartTime(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setEndTime(value);
      break;
    case 5:
      var value = new proto_vorto_types_types_pb.NullableInt64;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableInt64.deserializeBinaryFromReader);
      msg.setMaxPrice(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setParticipantCount(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setInvitations(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUniqueQuoters(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.InventoryCurrentRFQ.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.InventoryCurrentRFQ.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.InventoryCurrentRFQ} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.InventoryCurrentRFQ.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getQuantity();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getStartTime();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getEndTime();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getMaxPrice();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto_vorto_types_types_pb.NullableInt64.serializeBinaryToWriter
    );
  }
  f = message.getParticipantCount();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getInvitations();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
  f = message.getUniqueQuoters();
  if (f !== 0) {
    writer.writeInt64(
      9,
      f
    );
  }
};


/**
 * optional SpotMarketRequestType request_type = 1;
 * @return {!proto.vtv1.SpotMarketRequestType}
 */
proto.vtv1.InventoryCurrentRFQ.prototype.getRequestType = function() {
  return /** @type {!proto.vtv1.SpotMarketRequestType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.vtv1.SpotMarketRequestType} value
 * @return {!proto.vtv1.InventoryCurrentRFQ} returns this
 */
proto.vtv1.InventoryCurrentRFQ.prototype.setRequestType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional double quantity = 2;
 * @return {number}
 */
proto.vtv1.InventoryCurrentRFQ.prototype.getQuantity = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.InventoryCurrentRFQ} returns this
 */
proto.vtv1.InventoryCurrentRFQ.prototype.setQuantity = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional string start_time = 3;
 * @return {string}
 */
proto.vtv1.InventoryCurrentRFQ.prototype.getStartTime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.InventoryCurrentRFQ} returns this
 */
proto.vtv1.InventoryCurrentRFQ.prototype.setStartTime = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string end_time = 4;
 * @return {string}
 */
proto.vtv1.InventoryCurrentRFQ.prototype.getEndTime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.InventoryCurrentRFQ} returns this
 */
proto.vtv1.InventoryCurrentRFQ.prototype.setEndTime = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional NullableInt64 max_price = 5;
 * @return {?proto.vtv1.NullableInt64}
 */
proto.vtv1.InventoryCurrentRFQ.prototype.getMaxPrice = function() {
  return /** @type{?proto.vtv1.NullableInt64} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableInt64, 5));
};


/**
 * @param {?proto.vtv1.NullableInt64|undefined} value
 * @return {!proto.vtv1.InventoryCurrentRFQ} returns this
*/
proto.vtv1.InventoryCurrentRFQ.prototype.setMaxPrice = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.InventoryCurrentRFQ} returns this
 */
proto.vtv1.InventoryCurrentRFQ.prototype.clearMaxPrice = function() {
  return this.setMaxPrice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.InventoryCurrentRFQ.prototype.hasMaxPrice = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional int64 participant_count = 6;
 * @return {number}
 */
proto.vtv1.InventoryCurrentRFQ.prototype.getParticipantCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.InventoryCurrentRFQ} returns this
 */
proto.vtv1.InventoryCurrentRFQ.prototype.setParticipantCount = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional string id = 7;
 * @return {string}
 */
proto.vtv1.InventoryCurrentRFQ.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.InventoryCurrentRFQ} returns this
 */
proto.vtv1.InventoryCurrentRFQ.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional int64 invitations = 8;
 * @return {number}
 */
proto.vtv1.InventoryCurrentRFQ.prototype.getInvitations = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.InventoryCurrentRFQ} returns this
 */
proto.vtv1.InventoryCurrentRFQ.prototype.setInvitations = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional int64 unique_quoters = 9;
 * @return {number}
 */
proto.vtv1.InventoryCurrentRFQ.prototype.getUniqueQuoters = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.InventoryCurrentRFQ} returns this
 */
proto.vtv1.InventoryCurrentRFQ.prototype.setUniqueQuoters = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.InventoryDemand.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.InventoryDemand.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.InventoryDemand.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.InventoryDemand} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.InventoryDemand.toObject = function(includeInstance, msg) {
  var f, obj = {
    demandList: jspb.Message.toObjectList(msg.getDemandList(),
    proto.vtv1.InventoryDataSet.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.InventoryDemand}
 */
proto.vtv1.InventoryDemand.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.InventoryDemand;
  return proto.vtv1.InventoryDemand.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.InventoryDemand} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.InventoryDemand}
 */
proto.vtv1.InventoryDemand.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.vtv1.InventoryDataSet;
      reader.readMessage(value,proto.vtv1.InventoryDataSet.deserializeBinaryFromReader);
      msg.addDemand(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.InventoryDemand.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.InventoryDemand.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.InventoryDemand} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.InventoryDemand.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDemandList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.vtv1.InventoryDataSet.serializeBinaryToWriter
    );
  }
};


/**
 * repeated InventoryDataSet demand = 1;
 * @return {!Array<!proto.vtv1.InventoryDataSet>}
 */
proto.vtv1.InventoryDemand.prototype.getDemandList = function() {
  return /** @type{!Array<!proto.vtv1.InventoryDataSet>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vtv1.InventoryDataSet, 1));
};


/**
 * @param {!Array<!proto.vtv1.InventoryDataSet>} value
 * @return {!proto.vtv1.InventoryDemand} returns this
*/
proto.vtv1.InventoryDemand.prototype.setDemandList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.vtv1.InventoryDataSet=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.InventoryDataSet}
 */
proto.vtv1.InventoryDemand.prototype.addDemand = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.vtv1.InventoryDataSet, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.InventoryDemand} returns this
 */
proto.vtv1.InventoryDemand.prototype.clearDemandList = function() {
  return this.setDemandList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.InventoryDataSet.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.InventoryDataSet.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.InventoryDataSet.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.InventoryDataSet} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.InventoryDataSet.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    datapointsList: jspb.Message.toObjectList(msg.getDatapointsList(),
    proto.vtv1.InventoryDatapoint.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.InventoryDataSet}
 */
proto.vtv1.InventoryDataSet.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.InventoryDataSet;
  return proto.vtv1.InventoryDataSet.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.InventoryDataSet} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.InventoryDataSet}
 */
proto.vtv1.InventoryDataSet.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = new proto.vtv1.InventoryDatapoint;
      reader.readMessage(value,proto.vtv1.InventoryDatapoint.deserializeBinaryFromReader);
      msg.addDatapoints(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.InventoryDataSet.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.InventoryDataSet.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.InventoryDataSet} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.InventoryDataSet.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDatapointsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.vtv1.InventoryDatapoint.serializeBinaryToWriter
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.vtv1.InventoryDataSet.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.InventoryDataSet} returns this
 */
proto.vtv1.InventoryDataSet.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated InventoryDatapoint datapoints = 2;
 * @return {!Array<!proto.vtv1.InventoryDatapoint>}
 */
proto.vtv1.InventoryDataSet.prototype.getDatapointsList = function() {
  return /** @type{!Array<!proto.vtv1.InventoryDatapoint>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vtv1.InventoryDatapoint, 2));
};


/**
 * @param {!Array<!proto.vtv1.InventoryDatapoint>} value
 * @return {!proto.vtv1.InventoryDataSet} returns this
*/
proto.vtv1.InventoryDataSet.prototype.setDatapointsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.vtv1.InventoryDatapoint=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.InventoryDatapoint}
 */
proto.vtv1.InventoryDataSet.prototype.addDatapoints = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.vtv1.InventoryDatapoint, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.InventoryDataSet} returns this
 */
proto.vtv1.InventoryDataSet.prototype.clearDatapointsList = function() {
  return this.setDatapointsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.InventoryDatapoint.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.InventoryDatapoint.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.InventoryDatapoint} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.InventoryDatapoint.toObject = function(includeInstance, msg) {
  var f, obj = {
    date: jspb.Message.getFieldWithDefault(msg, 1, ""),
    demand: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.InventoryDatapoint}
 */
proto.vtv1.InventoryDatapoint.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.InventoryDatapoint;
  return proto.vtv1.InventoryDatapoint.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.InventoryDatapoint} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.InventoryDatapoint}
 */
proto.vtv1.InventoryDatapoint.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDate(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setDemand(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.InventoryDatapoint.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.InventoryDatapoint.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.InventoryDatapoint} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.InventoryDatapoint.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDate();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDemand();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
};


/**
 * optional string date = 1;
 * @return {string}
 */
proto.vtv1.InventoryDatapoint.prototype.getDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.InventoryDatapoint} returns this
 */
proto.vtv1.InventoryDatapoint.prototype.setDate = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional double demand = 2;
 * @return {number}
 */
proto.vtv1.InventoryDatapoint.prototype.getDemand = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.InventoryDatapoint} returns this
 */
proto.vtv1.InventoryDatapoint.prototype.setDemand = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.InventorySupplyPlan.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.InventorySupplyPlan.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.InventorySupplyPlan.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.InventorySupplyPlan} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.InventorySupplyPlan.toObject = function(includeInstance, msg) {
  var f, obj = {
    supplierGuide: (f = msg.getSupplierGuide()) && proto.vtv1.InventorySupplyPlanSupplierGuide.toObject(includeInstance, f),
    spotPlan: (f = msg.getSpotPlan()) && proto.vtv1.InventorySupplyPlanSpotPurchasePlan.toObject(includeInstance, f),
    marketPriceCheck: (f = msg.getMarketPriceCheck()) && proto.vtv1.InventorySupplyPlanMarketPriceCheck.toObject(includeInstance, f),
    purchaseOrders: (f = msg.getPurchaseOrders()) && proto.vtv1.InventorySupplyPlanPurchaseOrders.toObject(includeInstance, f),
    poEmailsList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.InventorySupplyPlan}
 */
proto.vtv1.InventorySupplyPlan.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.InventorySupplyPlan;
  return proto.vtv1.InventorySupplyPlan.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.InventorySupplyPlan} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.InventorySupplyPlan}
 */
proto.vtv1.InventorySupplyPlan.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.vtv1.InventorySupplyPlanSupplierGuide;
      reader.readMessage(value,proto.vtv1.InventorySupplyPlanSupplierGuide.deserializeBinaryFromReader);
      msg.setSupplierGuide(value);
      break;
    case 2:
      var value = new proto.vtv1.InventorySupplyPlanSpotPurchasePlan;
      reader.readMessage(value,proto.vtv1.InventorySupplyPlanSpotPurchasePlan.deserializeBinaryFromReader);
      msg.setSpotPlan(value);
      break;
    case 3:
      var value = new proto.vtv1.InventorySupplyPlanMarketPriceCheck;
      reader.readMessage(value,proto.vtv1.InventorySupplyPlanMarketPriceCheck.deserializeBinaryFromReader);
      msg.setMarketPriceCheck(value);
      break;
    case 4:
      var value = new proto.vtv1.InventorySupplyPlanPurchaseOrders;
      reader.readMessage(value,proto.vtv1.InventorySupplyPlanPurchaseOrders.deserializeBinaryFromReader);
      msg.setPurchaseOrders(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.addPoEmails(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.InventorySupplyPlan.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.InventorySupplyPlan.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.InventorySupplyPlan} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.InventorySupplyPlan.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSupplierGuide();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.vtv1.InventorySupplyPlanSupplierGuide.serializeBinaryToWriter
    );
  }
  f = message.getSpotPlan();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.vtv1.InventorySupplyPlanSpotPurchasePlan.serializeBinaryToWriter
    );
  }
  f = message.getMarketPriceCheck();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.vtv1.InventorySupplyPlanMarketPriceCheck.serializeBinaryToWriter
    );
  }
  f = message.getPurchaseOrders();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.vtv1.InventorySupplyPlanPurchaseOrders.serializeBinaryToWriter
    );
  }
  f = message.getPoEmailsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      5,
      f
    );
  }
};


/**
 * optional InventorySupplyPlanSupplierGuide supplier_guide = 1;
 * @return {?proto.vtv1.InventorySupplyPlanSupplierGuide}
 */
proto.vtv1.InventorySupplyPlan.prototype.getSupplierGuide = function() {
  return /** @type{?proto.vtv1.InventorySupplyPlanSupplierGuide} */ (
    jspb.Message.getWrapperField(this, proto.vtv1.InventorySupplyPlanSupplierGuide, 1));
};


/**
 * @param {?proto.vtv1.InventorySupplyPlanSupplierGuide|undefined} value
 * @return {!proto.vtv1.InventorySupplyPlan} returns this
*/
proto.vtv1.InventorySupplyPlan.prototype.setSupplierGuide = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.InventorySupplyPlan} returns this
 */
proto.vtv1.InventorySupplyPlan.prototype.clearSupplierGuide = function() {
  return this.setSupplierGuide(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.InventorySupplyPlan.prototype.hasSupplierGuide = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional InventorySupplyPlanSpotPurchasePlan spot_plan = 2;
 * @return {?proto.vtv1.InventorySupplyPlanSpotPurchasePlan}
 */
proto.vtv1.InventorySupplyPlan.prototype.getSpotPlan = function() {
  return /** @type{?proto.vtv1.InventorySupplyPlanSpotPurchasePlan} */ (
    jspb.Message.getWrapperField(this, proto.vtv1.InventorySupplyPlanSpotPurchasePlan, 2));
};


/**
 * @param {?proto.vtv1.InventorySupplyPlanSpotPurchasePlan|undefined} value
 * @return {!proto.vtv1.InventorySupplyPlan} returns this
*/
proto.vtv1.InventorySupplyPlan.prototype.setSpotPlan = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.InventorySupplyPlan} returns this
 */
proto.vtv1.InventorySupplyPlan.prototype.clearSpotPlan = function() {
  return this.setSpotPlan(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.InventorySupplyPlan.prototype.hasSpotPlan = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional InventorySupplyPlanMarketPriceCheck market_price_check = 3;
 * @return {?proto.vtv1.InventorySupplyPlanMarketPriceCheck}
 */
proto.vtv1.InventorySupplyPlan.prototype.getMarketPriceCheck = function() {
  return /** @type{?proto.vtv1.InventorySupplyPlanMarketPriceCheck} */ (
    jspb.Message.getWrapperField(this, proto.vtv1.InventorySupplyPlanMarketPriceCheck, 3));
};


/**
 * @param {?proto.vtv1.InventorySupplyPlanMarketPriceCheck|undefined} value
 * @return {!proto.vtv1.InventorySupplyPlan} returns this
*/
proto.vtv1.InventorySupplyPlan.prototype.setMarketPriceCheck = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.InventorySupplyPlan} returns this
 */
proto.vtv1.InventorySupplyPlan.prototype.clearMarketPriceCheck = function() {
  return this.setMarketPriceCheck(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.InventorySupplyPlan.prototype.hasMarketPriceCheck = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional InventorySupplyPlanPurchaseOrders purchase_orders = 4;
 * @return {?proto.vtv1.InventorySupplyPlanPurchaseOrders}
 */
proto.vtv1.InventorySupplyPlan.prototype.getPurchaseOrders = function() {
  return /** @type{?proto.vtv1.InventorySupplyPlanPurchaseOrders} */ (
    jspb.Message.getWrapperField(this, proto.vtv1.InventorySupplyPlanPurchaseOrders, 4));
};


/**
 * @param {?proto.vtv1.InventorySupplyPlanPurchaseOrders|undefined} value
 * @return {!proto.vtv1.InventorySupplyPlan} returns this
*/
proto.vtv1.InventorySupplyPlan.prototype.setPurchaseOrders = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.InventorySupplyPlan} returns this
 */
proto.vtv1.InventorySupplyPlan.prototype.clearPurchaseOrders = function() {
  return this.setPurchaseOrders(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.InventorySupplyPlan.prototype.hasPurchaseOrders = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * repeated string po_emails = 5;
 * @return {!Array<string>}
 */
proto.vtv1.InventorySupplyPlan.prototype.getPoEmailsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.vtv1.InventorySupplyPlan} returns this
 */
proto.vtv1.InventorySupplyPlan.prototype.setPoEmailsList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.vtv1.InventorySupplyPlan} returns this
 */
proto.vtv1.InventorySupplyPlan.prototype.addPoEmails = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.InventorySupplyPlan} returns this
 */
proto.vtv1.InventorySupplyPlan.prototype.clearPoEmailsList = function() {
  return this.setPoEmailsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.InventorySupplyPlanPurchaseOrders.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.InventorySupplyPlanPurchaseOrders.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.InventorySupplyPlanPurchaseOrders.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.InventorySupplyPlanPurchaseOrders} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.InventorySupplyPlanPurchaseOrders.toObject = function(includeInstance, msg) {
  var f, obj = {
    purchaseOrdersList: jspb.Message.toObjectList(msg.getPurchaseOrdersList(),
    proto.vtv1.InventorySupplyPlanPurchaseOrder.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.InventorySupplyPlanPurchaseOrders}
 */
proto.vtv1.InventorySupplyPlanPurchaseOrders.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.InventorySupplyPlanPurchaseOrders;
  return proto.vtv1.InventorySupplyPlanPurchaseOrders.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.InventorySupplyPlanPurchaseOrders} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.InventorySupplyPlanPurchaseOrders}
 */
proto.vtv1.InventorySupplyPlanPurchaseOrders.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 3:
      var value = new proto.vtv1.InventorySupplyPlanPurchaseOrder;
      reader.readMessage(value,proto.vtv1.InventorySupplyPlanPurchaseOrder.deserializeBinaryFromReader);
      msg.addPurchaseOrders(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.InventorySupplyPlanPurchaseOrders.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.InventorySupplyPlanPurchaseOrders.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.InventorySupplyPlanPurchaseOrders} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.InventorySupplyPlanPurchaseOrders.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPurchaseOrdersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.vtv1.InventorySupplyPlanPurchaseOrder.serializeBinaryToWriter
    );
  }
};


/**
 * repeated InventorySupplyPlanPurchaseOrder purchase_orders = 3;
 * @return {!Array<!proto.vtv1.InventorySupplyPlanPurchaseOrder>}
 */
proto.vtv1.InventorySupplyPlanPurchaseOrders.prototype.getPurchaseOrdersList = function() {
  return /** @type{!Array<!proto.vtv1.InventorySupplyPlanPurchaseOrder>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vtv1.InventorySupplyPlanPurchaseOrder, 3));
};


/**
 * @param {!Array<!proto.vtv1.InventorySupplyPlanPurchaseOrder>} value
 * @return {!proto.vtv1.InventorySupplyPlanPurchaseOrders} returns this
*/
proto.vtv1.InventorySupplyPlanPurchaseOrders.prototype.setPurchaseOrdersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.vtv1.InventorySupplyPlanPurchaseOrder=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.InventorySupplyPlanPurchaseOrder}
 */
proto.vtv1.InventorySupplyPlanPurchaseOrders.prototype.addPurchaseOrders = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.vtv1.InventorySupplyPlanPurchaseOrder, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.InventorySupplyPlanPurchaseOrders} returns this
 */
proto.vtv1.InventorySupplyPlanPurchaseOrders.prototype.clearPurchaseOrdersList = function() {
  return this.setPurchaseOrdersList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.InventorySupplyPlanPurchaseOrder.repeatedFields_ = [14];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.InventorySupplyPlanPurchaseOrder.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.InventorySupplyPlanPurchaseOrder.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.InventorySupplyPlanPurchaseOrder} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.InventorySupplyPlanPurchaseOrder.toObject = function(includeInstance, msg) {
  var f, obj = {
    poId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    poName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    supplierId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    supplierName: jspb.Message.getFieldWithDefault(msg, 4, ""),
    costPerUnit: jspb.Message.getFieldWithDefault(msg, 5, 0),
    lineItemNumber: jspb.Message.getFieldWithDefault(msg, 6, ""),
    orderedToday: jspb.Message.getFieldWithDefault(msg, 7, 0),
    dailyMax: (f = msg.getDailyMax()) && proto_vorto_types_types_pb.NullableDouble.toObject(includeInstance, f),
    orderedTotal: jspb.Message.getFieldWithDefault(msg, 9, 0),
    totalMax: (f = msg.getTotalMax()) && proto_vorto_types_types_pb.NullableDouble.toObject(includeInstance, f),
    expiresAt: (f = msg.getExpiresAt()) && proto_vorto_types_types_pb.NullableTime.toObject(includeInstance, f),
    closedAt: (f = msg.getClosedAt()) && proto_vorto_types_types_pb.NullableTime.toObject(includeInstance, f),
    confirmedAt: (f = msg.getConfirmedAt()) && proto_vorto_types_types_pb.NullableTime.toObject(includeInstance, f),
    supplierSitesList: jspb.Message.toObjectList(msg.getSupplierSitesList(),
    proto_vorto_types_types_pb.StringIdName.toObject, includeInstance),
    effectiveDate: (f = msg.getEffectiveDate()) && proto_vorto_types_types_pb.NullableTime.toObject(includeInstance, f),
    pausedBy: (f = msg.getPausedBy()) && proto_vorto_types_types_pb.NullableString.toObject(includeInstance, f),
    scheduledToday: jspb.Message.getFloatingPointFieldWithDefault(msg, 17, 0.0),
    scheduledTotal: jspb.Message.getFloatingPointFieldWithDefault(msg, 18, 0.0),
    declinedAt: (f = msg.getDeclinedAt()) && proto_vorto_types_types_pb.NullableTime.toObject(includeInstance, f),
    potentialCarriers: jspb.Message.getFieldWithDefault(msg, 20, 0),
    supplierSiteDown: jspb.Message.getBooleanFieldWithDefault(msg, 21, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.InventorySupplyPlanPurchaseOrder}
 */
proto.vtv1.InventorySupplyPlanPurchaseOrder.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.InventorySupplyPlanPurchaseOrder;
  return proto.vtv1.InventorySupplyPlanPurchaseOrder.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.InventorySupplyPlanPurchaseOrder} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.InventorySupplyPlanPurchaseOrder}
 */
proto.vtv1.InventorySupplyPlanPurchaseOrder.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPoId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPoName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSupplierId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setSupplierName(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCostPerUnit(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setLineItemNumber(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setOrderedToday(value);
      break;
    case 8:
      var value = new proto_vorto_types_types_pb.NullableDouble;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableDouble.deserializeBinaryFromReader);
      msg.setDailyMax(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setOrderedTotal(value);
      break;
    case 10:
      var value = new proto_vorto_types_types_pb.NullableDouble;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableDouble.deserializeBinaryFromReader);
      msg.setTotalMax(value);
      break;
    case 11:
      var value = new proto_vorto_types_types_pb.NullableTime;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableTime.deserializeBinaryFromReader);
      msg.setExpiresAt(value);
      break;
    case 12:
      var value = new proto_vorto_types_types_pb.NullableTime;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableTime.deserializeBinaryFromReader);
      msg.setClosedAt(value);
      break;
    case 13:
      var value = new proto_vorto_types_types_pb.NullableTime;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableTime.deserializeBinaryFromReader);
      msg.setConfirmedAt(value);
      break;
    case 14:
      var value = new proto_vorto_types_types_pb.StringIdName;
      reader.readMessage(value,proto_vorto_types_types_pb.StringIdName.deserializeBinaryFromReader);
      msg.addSupplierSites(value);
      break;
    case 15:
      var value = new proto_vorto_types_types_pb.NullableTime;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableTime.deserializeBinaryFromReader);
      msg.setEffectiveDate(value);
      break;
    case 16:
      var value = new proto_vorto_types_types_pb.NullableString;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableString.deserializeBinaryFromReader);
      msg.setPausedBy(value);
      break;
    case 17:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setScheduledToday(value);
      break;
    case 18:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setScheduledTotal(value);
      break;
    case 19:
      var value = new proto_vorto_types_types_pb.NullableTime;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableTime.deserializeBinaryFromReader);
      msg.setDeclinedAt(value);
      break;
    case 20:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPotentialCarriers(value);
      break;
    case 21:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSupplierSiteDown(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.InventorySupplyPlanPurchaseOrder.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.InventorySupplyPlanPurchaseOrder.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.InventorySupplyPlanPurchaseOrder} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.InventorySupplyPlanPurchaseOrder.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPoId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPoName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSupplierId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSupplierName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCostPerUnit();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getLineItemNumber();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getOrderedToday();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
  f = message.getDailyMax();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto_vorto_types_types_pb.NullableDouble.serializeBinaryToWriter
    );
  }
  f = message.getOrderedTotal();
  if (f !== 0) {
    writer.writeInt64(
      9,
      f
    );
  }
  f = message.getTotalMax();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto_vorto_types_types_pb.NullableDouble.serializeBinaryToWriter
    );
  }
  f = message.getExpiresAt();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto_vorto_types_types_pb.NullableTime.serializeBinaryToWriter
    );
  }
  f = message.getClosedAt();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      proto_vorto_types_types_pb.NullableTime.serializeBinaryToWriter
    );
  }
  f = message.getConfirmedAt();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      proto_vorto_types_types_pb.NullableTime.serializeBinaryToWriter
    );
  }
  f = message.getSupplierSitesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      14,
      f,
      proto_vorto_types_types_pb.StringIdName.serializeBinaryToWriter
    );
  }
  f = message.getEffectiveDate();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      proto_vorto_types_types_pb.NullableTime.serializeBinaryToWriter
    );
  }
  f = message.getPausedBy();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      proto_vorto_types_types_pb.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getScheduledToday();
  if (f !== 0.0) {
    writer.writeDouble(
      17,
      f
    );
  }
  f = message.getScheduledTotal();
  if (f !== 0.0) {
    writer.writeDouble(
      18,
      f
    );
  }
  f = message.getDeclinedAt();
  if (f != null) {
    writer.writeMessage(
      19,
      f,
      proto_vorto_types_types_pb.NullableTime.serializeBinaryToWriter
    );
  }
  f = message.getPotentialCarriers();
  if (f !== 0) {
    writer.writeInt64(
      20,
      f
    );
  }
  f = message.getSupplierSiteDown();
  if (f) {
    writer.writeBool(
      21,
      f
    );
  }
};


/**
 * optional string po_id = 1;
 * @return {string}
 */
proto.vtv1.InventorySupplyPlanPurchaseOrder.prototype.getPoId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.InventorySupplyPlanPurchaseOrder} returns this
 */
proto.vtv1.InventorySupplyPlanPurchaseOrder.prototype.setPoId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string po_name = 2;
 * @return {string}
 */
proto.vtv1.InventorySupplyPlanPurchaseOrder.prototype.getPoName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.InventorySupplyPlanPurchaseOrder} returns this
 */
proto.vtv1.InventorySupplyPlanPurchaseOrder.prototype.setPoName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string supplier_id = 3;
 * @return {string}
 */
proto.vtv1.InventorySupplyPlanPurchaseOrder.prototype.getSupplierId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.InventorySupplyPlanPurchaseOrder} returns this
 */
proto.vtv1.InventorySupplyPlanPurchaseOrder.prototype.setSupplierId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string supplier_name = 4;
 * @return {string}
 */
proto.vtv1.InventorySupplyPlanPurchaseOrder.prototype.getSupplierName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.InventorySupplyPlanPurchaseOrder} returns this
 */
proto.vtv1.InventorySupplyPlanPurchaseOrder.prototype.setSupplierName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int64 cost_per_unit = 5;
 * @return {number}
 */
proto.vtv1.InventorySupplyPlanPurchaseOrder.prototype.getCostPerUnit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.InventorySupplyPlanPurchaseOrder} returns this
 */
proto.vtv1.InventorySupplyPlanPurchaseOrder.prototype.setCostPerUnit = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional string line_item_number = 6;
 * @return {string}
 */
proto.vtv1.InventorySupplyPlanPurchaseOrder.prototype.getLineItemNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.InventorySupplyPlanPurchaseOrder} returns this
 */
proto.vtv1.InventorySupplyPlanPurchaseOrder.prototype.setLineItemNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional int64 ordered_today = 7;
 * @return {number}
 */
proto.vtv1.InventorySupplyPlanPurchaseOrder.prototype.getOrderedToday = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.InventorySupplyPlanPurchaseOrder} returns this
 */
proto.vtv1.InventorySupplyPlanPurchaseOrder.prototype.setOrderedToday = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional NullableDouble daily_max = 8;
 * @return {?proto.vtv1.NullableDouble}
 */
proto.vtv1.InventorySupplyPlanPurchaseOrder.prototype.getDailyMax = function() {
  return /** @type{?proto.vtv1.NullableDouble} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableDouble, 8));
};


/**
 * @param {?proto.vtv1.NullableDouble|undefined} value
 * @return {!proto.vtv1.InventorySupplyPlanPurchaseOrder} returns this
*/
proto.vtv1.InventorySupplyPlanPurchaseOrder.prototype.setDailyMax = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.InventorySupplyPlanPurchaseOrder} returns this
 */
proto.vtv1.InventorySupplyPlanPurchaseOrder.prototype.clearDailyMax = function() {
  return this.setDailyMax(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.InventorySupplyPlanPurchaseOrder.prototype.hasDailyMax = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional int64 ordered_total = 9;
 * @return {number}
 */
proto.vtv1.InventorySupplyPlanPurchaseOrder.prototype.getOrderedTotal = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.InventorySupplyPlanPurchaseOrder} returns this
 */
proto.vtv1.InventorySupplyPlanPurchaseOrder.prototype.setOrderedTotal = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional NullableDouble total_max = 10;
 * @return {?proto.vtv1.NullableDouble}
 */
proto.vtv1.InventorySupplyPlanPurchaseOrder.prototype.getTotalMax = function() {
  return /** @type{?proto.vtv1.NullableDouble} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableDouble, 10));
};


/**
 * @param {?proto.vtv1.NullableDouble|undefined} value
 * @return {!proto.vtv1.InventorySupplyPlanPurchaseOrder} returns this
*/
proto.vtv1.InventorySupplyPlanPurchaseOrder.prototype.setTotalMax = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.InventorySupplyPlanPurchaseOrder} returns this
 */
proto.vtv1.InventorySupplyPlanPurchaseOrder.prototype.clearTotalMax = function() {
  return this.setTotalMax(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.InventorySupplyPlanPurchaseOrder.prototype.hasTotalMax = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional NullableTime expires_at = 11;
 * @return {?proto.vtv1.NullableTime}
 */
proto.vtv1.InventorySupplyPlanPurchaseOrder.prototype.getExpiresAt = function() {
  return /** @type{?proto.vtv1.NullableTime} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableTime, 11));
};


/**
 * @param {?proto.vtv1.NullableTime|undefined} value
 * @return {!proto.vtv1.InventorySupplyPlanPurchaseOrder} returns this
*/
proto.vtv1.InventorySupplyPlanPurchaseOrder.prototype.setExpiresAt = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.InventorySupplyPlanPurchaseOrder} returns this
 */
proto.vtv1.InventorySupplyPlanPurchaseOrder.prototype.clearExpiresAt = function() {
  return this.setExpiresAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.InventorySupplyPlanPurchaseOrder.prototype.hasExpiresAt = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional NullableTime closed_at = 12;
 * @return {?proto.vtv1.NullableTime}
 */
proto.vtv1.InventorySupplyPlanPurchaseOrder.prototype.getClosedAt = function() {
  return /** @type{?proto.vtv1.NullableTime} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableTime, 12));
};


/**
 * @param {?proto.vtv1.NullableTime|undefined} value
 * @return {!proto.vtv1.InventorySupplyPlanPurchaseOrder} returns this
*/
proto.vtv1.InventorySupplyPlanPurchaseOrder.prototype.setClosedAt = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.InventorySupplyPlanPurchaseOrder} returns this
 */
proto.vtv1.InventorySupplyPlanPurchaseOrder.prototype.clearClosedAt = function() {
  return this.setClosedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.InventorySupplyPlanPurchaseOrder.prototype.hasClosedAt = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional NullableTime confirmed_at = 13;
 * @return {?proto.vtv1.NullableTime}
 */
proto.vtv1.InventorySupplyPlanPurchaseOrder.prototype.getConfirmedAt = function() {
  return /** @type{?proto.vtv1.NullableTime} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableTime, 13));
};


/**
 * @param {?proto.vtv1.NullableTime|undefined} value
 * @return {!proto.vtv1.InventorySupplyPlanPurchaseOrder} returns this
*/
proto.vtv1.InventorySupplyPlanPurchaseOrder.prototype.setConfirmedAt = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.InventorySupplyPlanPurchaseOrder} returns this
 */
proto.vtv1.InventorySupplyPlanPurchaseOrder.prototype.clearConfirmedAt = function() {
  return this.setConfirmedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.InventorySupplyPlanPurchaseOrder.prototype.hasConfirmedAt = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * repeated StringIdName supplier_sites = 14;
 * @return {!Array<!proto.vtv1.StringIdName>}
 */
proto.vtv1.InventorySupplyPlanPurchaseOrder.prototype.getSupplierSitesList = function() {
  return /** @type{!Array<!proto.vtv1.StringIdName>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_vorto_types_types_pb.StringIdName, 14));
};


/**
 * @param {!Array<!proto.vtv1.StringIdName>} value
 * @return {!proto.vtv1.InventorySupplyPlanPurchaseOrder} returns this
*/
proto.vtv1.InventorySupplyPlanPurchaseOrder.prototype.setSupplierSitesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 14, value);
};


/**
 * @param {!proto.vtv1.StringIdName=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.StringIdName}
 */
proto.vtv1.InventorySupplyPlanPurchaseOrder.prototype.addSupplierSites = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 14, opt_value, proto.vtv1.StringIdName, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.InventorySupplyPlanPurchaseOrder} returns this
 */
proto.vtv1.InventorySupplyPlanPurchaseOrder.prototype.clearSupplierSitesList = function() {
  return this.setSupplierSitesList([]);
};


/**
 * optional NullableTime effective_date = 15;
 * @return {?proto.vtv1.NullableTime}
 */
proto.vtv1.InventorySupplyPlanPurchaseOrder.prototype.getEffectiveDate = function() {
  return /** @type{?proto.vtv1.NullableTime} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableTime, 15));
};


/**
 * @param {?proto.vtv1.NullableTime|undefined} value
 * @return {!proto.vtv1.InventorySupplyPlanPurchaseOrder} returns this
*/
proto.vtv1.InventorySupplyPlanPurchaseOrder.prototype.setEffectiveDate = function(value) {
  return jspb.Message.setWrapperField(this, 15, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.InventorySupplyPlanPurchaseOrder} returns this
 */
proto.vtv1.InventorySupplyPlanPurchaseOrder.prototype.clearEffectiveDate = function() {
  return this.setEffectiveDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.InventorySupplyPlanPurchaseOrder.prototype.hasEffectiveDate = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional NullableString paused_by = 16;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.InventorySupplyPlanPurchaseOrder.prototype.getPausedBy = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableString, 16));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.InventorySupplyPlanPurchaseOrder} returns this
*/
proto.vtv1.InventorySupplyPlanPurchaseOrder.prototype.setPausedBy = function(value) {
  return jspb.Message.setWrapperField(this, 16, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.InventorySupplyPlanPurchaseOrder} returns this
 */
proto.vtv1.InventorySupplyPlanPurchaseOrder.prototype.clearPausedBy = function() {
  return this.setPausedBy(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.InventorySupplyPlanPurchaseOrder.prototype.hasPausedBy = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * optional double scheduled_today = 17;
 * @return {number}
 */
proto.vtv1.InventorySupplyPlanPurchaseOrder.prototype.getScheduledToday = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 17, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.InventorySupplyPlanPurchaseOrder} returns this
 */
proto.vtv1.InventorySupplyPlanPurchaseOrder.prototype.setScheduledToday = function(value) {
  return jspb.Message.setProto3FloatField(this, 17, value);
};


/**
 * optional double scheduled_total = 18;
 * @return {number}
 */
proto.vtv1.InventorySupplyPlanPurchaseOrder.prototype.getScheduledTotal = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 18, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.InventorySupplyPlanPurchaseOrder} returns this
 */
proto.vtv1.InventorySupplyPlanPurchaseOrder.prototype.setScheduledTotal = function(value) {
  return jspb.Message.setProto3FloatField(this, 18, value);
};


/**
 * optional NullableTime declined_at = 19;
 * @return {?proto.vtv1.NullableTime}
 */
proto.vtv1.InventorySupplyPlanPurchaseOrder.prototype.getDeclinedAt = function() {
  return /** @type{?proto.vtv1.NullableTime} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableTime, 19));
};


/**
 * @param {?proto.vtv1.NullableTime|undefined} value
 * @return {!proto.vtv1.InventorySupplyPlanPurchaseOrder} returns this
*/
proto.vtv1.InventorySupplyPlanPurchaseOrder.prototype.setDeclinedAt = function(value) {
  return jspb.Message.setWrapperField(this, 19, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.InventorySupplyPlanPurchaseOrder} returns this
 */
proto.vtv1.InventorySupplyPlanPurchaseOrder.prototype.clearDeclinedAt = function() {
  return this.setDeclinedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.InventorySupplyPlanPurchaseOrder.prototype.hasDeclinedAt = function() {
  return jspb.Message.getField(this, 19) != null;
};


/**
 * optional int64 potential_carriers = 20;
 * @return {number}
 */
proto.vtv1.InventorySupplyPlanPurchaseOrder.prototype.getPotentialCarriers = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 20, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.InventorySupplyPlanPurchaseOrder} returns this
 */
proto.vtv1.InventorySupplyPlanPurchaseOrder.prototype.setPotentialCarriers = function(value) {
  return jspb.Message.setProto3IntField(this, 20, value);
};


/**
 * optional bool supplier_site_down = 21;
 * @return {boolean}
 */
proto.vtv1.InventorySupplyPlanPurchaseOrder.prototype.getSupplierSiteDown = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 21, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vtv1.InventorySupplyPlanPurchaseOrder} returns this
 */
proto.vtv1.InventorySupplyPlanPurchaseOrder.prototype.setSupplierSiteDown = function(value) {
  return jspb.Message.setProto3BooleanField(this, 21, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.InventorySupplyPlanMarketPriceCheck.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.InventorySupplyPlanMarketPriceCheck.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.InventorySupplyPlanMarketPriceCheck} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.InventorySupplyPlanMarketPriceCheck.toObject = function(includeInstance, msg) {
  var f, obj = {
    frequency: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.InventorySupplyPlanMarketPriceCheck}
 */
proto.vtv1.InventorySupplyPlanMarketPriceCheck.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.InventorySupplyPlanMarketPriceCheck;
  return proto.vtv1.InventorySupplyPlanMarketPriceCheck.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.InventorySupplyPlanMarketPriceCheck} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.InventorySupplyPlanMarketPriceCheck}
 */
proto.vtv1.InventorySupplyPlanMarketPriceCheck.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.vtv1.RFQFrequencyType} */ (reader.readEnum());
      msg.setFrequency(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.InventorySupplyPlanMarketPriceCheck.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.InventorySupplyPlanMarketPriceCheck.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.InventorySupplyPlanMarketPriceCheck} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.InventorySupplyPlanMarketPriceCheck.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFrequency();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
};


/**
 * optional RFQFrequencyType frequency = 1;
 * @return {!proto.vtv1.RFQFrequencyType}
 */
proto.vtv1.InventorySupplyPlanMarketPriceCheck.prototype.getFrequency = function() {
  return /** @type {!proto.vtv1.RFQFrequencyType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.vtv1.RFQFrequencyType} value
 * @return {!proto.vtv1.InventorySupplyPlanMarketPriceCheck} returns this
 */
proto.vtv1.InventorySupplyPlanMarketPriceCheck.prototype.setFrequency = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.InventorySupplyPlanSpotPurchasePlan.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.InventorySupplyPlanSpotPurchasePlan.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.InventorySupplyPlanSpotPurchasePlan.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.InventorySupplyPlanSpotPurchasePlan} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.InventorySupplyPlanSpotPurchasePlan.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, 0),
    invitesList: jspb.Message.toObjectList(msg.getInvitesList(),
    proto.vtv1.InventorySupplyPlanMarketInvite.toObject, includeInstance),
    biddingLength: jspb.Message.getFieldWithDefault(msg, 4, 0),
    shipperType: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.InventorySupplyPlanSpotPurchasePlan}
 */
proto.vtv1.InventorySupplyPlanSpotPurchasePlan.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.InventorySupplyPlanSpotPurchasePlan;
  return proto.vtv1.InventorySupplyPlanSpotPurchasePlan.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.InventorySupplyPlanSpotPurchasePlan} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.InventorySupplyPlanSpotPurchasePlan}
 */
proto.vtv1.InventorySupplyPlanSpotPurchasePlan.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.vtv1.SpotMarketRequestType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 3:
      var value = new proto.vtv1.InventorySupplyPlanMarketInvite;
      reader.readMessage(value,proto.vtv1.InventorySupplyPlanMarketInvite.deserializeBinaryFromReader);
      msg.addInvites(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBiddingLength(value);
      break;
    case 5:
      var value = /** @type {!proto.vtv1.SpotMarketRequestShipperType} */ (reader.readEnum());
      msg.setShipperType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.InventorySupplyPlanSpotPurchasePlan.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.InventorySupplyPlanSpotPurchasePlan.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.InventorySupplyPlanSpotPurchasePlan} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.InventorySupplyPlanSpotPurchasePlan.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getInvitesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.vtv1.InventorySupplyPlanMarketInvite.serializeBinaryToWriter
    );
  }
  f = message.getBiddingLength();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getShipperType();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
};


/**
 * optional SpotMarketRequestType type = 1;
 * @return {!proto.vtv1.SpotMarketRequestType}
 */
proto.vtv1.InventorySupplyPlanSpotPurchasePlan.prototype.getType = function() {
  return /** @type {!proto.vtv1.SpotMarketRequestType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.vtv1.SpotMarketRequestType} value
 * @return {!proto.vtv1.InventorySupplyPlanSpotPurchasePlan} returns this
 */
proto.vtv1.InventorySupplyPlanSpotPurchasePlan.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * repeated InventorySupplyPlanMarketInvite invites = 3;
 * @return {!Array<!proto.vtv1.InventorySupplyPlanMarketInvite>}
 */
proto.vtv1.InventorySupplyPlanSpotPurchasePlan.prototype.getInvitesList = function() {
  return /** @type{!Array<!proto.vtv1.InventorySupplyPlanMarketInvite>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vtv1.InventorySupplyPlanMarketInvite, 3));
};


/**
 * @param {!Array<!proto.vtv1.InventorySupplyPlanMarketInvite>} value
 * @return {!proto.vtv1.InventorySupplyPlanSpotPurchasePlan} returns this
*/
proto.vtv1.InventorySupplyPlanSpotPurchasePlan.prototype.setInvitesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.vtv1.InventorySupplyPlanMarketInvite=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.InventorySupplyPlanMarketInvite}
 */
proto.vtv1.InventorySupplyPlanSpotPurchasePlan.prototype.addInvites = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.vtv1.InventorySupplyPlanMarketInvite, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.InventorySupplyPlanSpotPurchasePlan} returns this
 */
proto.vtv1.InventorySupplyPlanSpotPurchasePlan.prototype.clearInvitesList = function() {
  return this.setInvitesList([]);
};


/**
 * optional int64 bidding_length = 4;
 * @return {number}
 */
proto.vtv1.InventorySupplyPlanSpotPurchasePlan.prototype.getBiddingLength = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.InventorySupplyPlanSpotPurchasePlan} returns this
 */
proto.vtv1.InventorySupplyPlanSpotPurchasePlan.prototype.setBiddingLength = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional SpotMarketRequestShipperType shipper_type = 5;
 * @return {!proto.vtv1.SpotMarketRequestShipperType}
 */
proto.vtv1.InventorySupplyPlanSpotPurchasePlan.prototype.getShipperType = function() {
  return /** @type {!proto.vtv1.SpotMarketRequestShipperType} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.vtv1.SpotMarketRequestShipperType} value
 * @return {!proto.vtv1.InventorySupplyPlanSpotPurchasePlan} returns this
 */
proto.vtv1.InventorySupplyPlanSpotPurchasePlan.prototype.setShipperType = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.InventorySupplyPlanMarketInvite.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.InventorySupplyPlanMarketInvite.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.InventorySupplyPlanMarketInvite} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.InventorySupplyPlanMarketInvite.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    email: jspb.Message.getFieldWithDefault(msg, 2, ""),
    userId: (f = msg.getUserId()) && proto_vorto_types_types_pb.NullableString.toObject(includeInstance, f),
    accountId: (f = msg.getAccountId()) && proto_vorto_types_types_pb.NullableString.toObject(includeInstance, f),
    accountName: (f = msg.getAccountName()) && proto_vorto_types_types_pb.NullableString.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.InventorySupplyPlanMarketInvite}
 */
proto.vtv1.InventorySupplyPlanMarketInvite.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.InventorySupplyPlanMarketInvite;
  return proto.vtv1.InventorySupplyPlanMarketInvite.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.InventorySupplyPlanMarketInvite} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.InventorySupplyPlanMarketInvite}
 */
proto.vtv1.InventorySupplyPlanMarketInvite.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 3:
      var value = new proto_vorto_types_types_pb.NullableString;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableString.deserializeBinaryFromReader);
      msg.setUserId(value);
      break;
    case 4:
      var value = new proto_vorto_types_types_pb.NullableString;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableString.deserializeBinaryFromReader);
      msg.setAccountId(value);
      break;
    case 5:
      var value = new proto_vorto_types_types_pb.NullableString;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableString.deserializeBinaryFromReader);
      msg.setAccountName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.InventorySupplyPlanMarketInvite.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.InventorySupplyPlanMarketInvite.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.InventorySupplyPlanMarketInvite} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.InventorySupplyPlanMarketInvite.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getUserId();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto_vorto_types_types_pb.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getAccountId();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto_vorto_types_types_pb.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getAccountName();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto_vorto_types_types_pb.NullableString.serializeBinaryToWriter
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.vtv1.InventorySupplyPlanMarketInvite.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.InventorySupplyPlanMarketInvite} returns this
 */
proto.vtv1.InventorySupplyPlanMarketInvite.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string email = 2;
 * @return {string}
 */
proto.vtv1.InventorySupplyPlanMarketInvite.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.InventorySupplyPlanMarketInvite} returns this
 */
proto.vtv1.InventorySupplyPlanMarketInvite.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional NullableString user_id = 3;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.InventorySupplyPlanMarketInvite.prototype.getUserId = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableString, 3));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.InventorySupplyPlanMarketInvite} returns this
*/
proto.vtv1.InventorySupplyPlanMarketInvite.prototype.setUserId = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.InventorySupplyPlanMarketInvite} returns this
 */
proto.vtv1.InventorySupplyPlanMarketInvite.prototype.clearUserId = function() {
  return this.setUserId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.InventorySupplyPlanMarketInvite.prototype.hasUserId = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional NullableString account_id = 4;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.InventorySupplyPlanMarketInvite.prototype.getAccountId = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableString, 4));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.InventorySupplyPlanMarketInvite} returns this
*/
proto.vtv1.InventorySupplyPlanMarketInvite.prototype.setAccountId = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.InventorySupplyPlanMarketInvite} returns this
 */
proto.vtv1.InventorySupplyPlanMarketInvite.prototype.clearAccountId = function() {
  return this.setAccountId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.InventorySupplyPlanMarketInvite.prototype.hasAccountId = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional NullableString account_name = 5;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.InventorySupplyPlanMarketInvite.prototype.getAccountName = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableString, 5));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.InventorySupplyPlanMarketInvite} returns this
*/
proto.vtv1.InventorySupplyPlanMarketInvite.prototype.setAccountName = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.InventorySupplyPlanMarketInvite} returns this
 */
proto.vtv1.InventorySupplyPlanMarketInvite.prototype.clearAccountName = function() {
  return this.setAccountName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.InventorySupplyPlanMarketInvite.prototype.hasAccountName = function() {
  return jspb.Message.getField(this, 5) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.InventorySupplyPlanSupplierGuide.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.InventorySupplyPlanSupplierGuide.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.InventorySupplyPlanSupplierGuide.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.InventorySupplyPlanSupplierGuide} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.InventorySupplyPlanSupplierGuide.toObject = function(includeInstance, msg) {
  var f, obj = {
    suppliersList: jspb.Message.toObjectList(msg.getSuppliersList(),
    proto.vtv1.InventorySupplyPlanSupplier.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.InventorySupplyPlanSupplierGuide}
 */
proto.vtv1.InventorySupplyPlanSupplierGuide.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.InventorySupplyPlanSupplierGuide;
  return proto.vtv1.InventorySupplyPlanSupplierGuide.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.InventorySupplyPlanSupplierGuide} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.InventorySupplyPlanSupplierGuide}
 */
proto.vtv1.InventorySupplyPlanSupplierGuide.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 3:
      var value = new proto.vtv1.InventorySupplyPlanSupplier;
      reader.readMessage(value,proto.vtv1.InventorySupplyPlanSupplier.deserializeBinaryFromReader);
      msg.addSuppliers(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.InventorySupplyPlanSupplierGuide.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.InventorySupplyPlanSupplierGuide.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.InventorySupplyPlanSupplierGuide} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.InventorySupplyPlanSupplierGuide.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuppliersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.vtv1.InventorySupplyPlanSupplier.serializeBinaryToWriter
    );
  }
};


/**
 * repeated InventorySupplyPlanSupplier suppliers = 3;
 * @return {!Array<!proto.vtv1.InventorySupplyPlanSupplier>}
 */
proto.vtv1.InventorySupplyPlanSupplierGuide.prototype.getSuppliersList = function() {
  return /** @type{!Array<!proto.vtv1.InventorySupplyPlanSupplier>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vtv1.InventorySupplyPlanSupplier, 3));
};


/**
 * @param {!Array<!proto.vtv1.InventorySupplyPlanSupplier>} value
 * @return {!proto.vtv1.InventorySupplyPlanSupplierGuide} returns this
*/
proto.vtv1.InventorySupplyPlanSupplierGuide.prototype.setSuppliersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.vtv1.InventorySupplyPlanSupplier=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.InventorySupplyPlanSupplier}
 */
proto.vtv1.InventorySupplyPlanSupplierGuide.prototype.addSuppliers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.vtv1.InventorySupplyPlanSupplier, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.InventorySupplyPlanSupplierGuide} returns this
 */
proto.vtv1.InventorySupplyPlanSupplierGuide.prototype.clearSuppliersList = function() {
  return this.setSuppliersList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.InventorySupplyPlanSupplier.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.InventorySupplyPlanSupplier.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.InventorySupplyPlanSupplier} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.InventorySupplyPlanSupplier.toObject = function(includeInstance, msg) {
  var f, obj = {
    supplierId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    supplierName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    pricePerUnit: (f = msg.getPricePerUnit()) && proto_vorto_types_types_pb.NullableInt64.toObject(includeInstance, f),
    supplierSiteId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    supplierSiteName: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.InventorySupplyPlanSupplier}
 */
proto.vtv1.InventorySupplyPlanSupplier.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.InventorySupplyPlanSupplier;
  return proto.vtv1.InventorySupplyPlanSupplier.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.InventorySupplyPlanSupplier} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.InventorySupplyPlanSupplier}
 */
proto.vtv1.InventorySupplyPlanSupplier.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSupplierId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSupplierName(value);
      break;
    case 3:
      var value = new proto_vorto_types_types_pb.NullableInt64;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableInt64.deserializeBinaryFromReader);
      msg.setPricePerUnit(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setSupplierSiteId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setSupplierSiteName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.InventorySupplyPlanSupplier.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.InventorySupplyPlanSupplier.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.InventorySupplyPlanSupplier} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.InventorySupplyPlanSupplier.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSupplierId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSupplierName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPricePerUnit();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto_vorto_types_types_pb.NullableInt64.serializeBinaryToWriter
    );
  }
  f = message.getSupplierSiteId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getSupplierSiteName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string supplier_id = 1;
 * @return {string}
 */
proto.vtv1.InventorySupplyPlanSupplier.prototype.getSupplierId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.InventorySupplyPlanSupplier} returns this
 */
proto.vtv1.InventorySupplyPlanSupplier.prototype.setSupplierId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string supplier_name = 2;
 * @return {string}
 */
proto.vtv1.InventorySupplyPlanSupplier.prototype.getSupplierName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.InventorySupplyPlanSupplier} returns this
 */
proto.vtv1.InventorySupplyPlanSupplier.prototype.setSupplierName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional NullableInt64 price_per_unit = 3;
 * @return {?proto.vtv1.NullableInt64}
 */
proto.vtv1.InventorySupplyPlanSupplier.prototype.getPricePerUnit = function() {
  return /** @type{?proto.vtv1.NullableInt64} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableInt64, 3));
};


/**
 * @param {?proto.vtv1.NullableInt64|undefined} value
 * @return {!proto.vtv1.InventorySupplyPlanSupplier} returns this
*/
proto.vtv1.InventorySupplyPlanSupplier.prototype.setPricePerUnit = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.InventorySupplyPlanSupplier} returns this
 */
proto.vtv1.InventorySupplyPlanSupplier.prototype.clearPricePerUnit = function() {
  return this.setPricePerUnit(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.InventorySupplyPlanSupplier.prototype.hasPricePerUnit = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string supplier_site_id = 4;
 * @return {string}
 */
proto.vtv1.InventorySupplyPlanSupplier.prototype.getSupplierSiteId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.InventorySupplyPlanSupplier} returns this
 */
proto.vtv1.InventorySupplyPlanSupplier.prototype.setSupplierSiteId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string supplier_site_name = 5;
 * @return {string}
 */
proto.vtv1.InventorySupplyPlanSupplier.prototype.getSupplierSiteName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.InventorySupplyPlanSupplier} returns this
 */
proto.vtv1.InventorySupplyPlanSupplier.prototype.setSupplierSiteName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.PotentialInventorySupplier.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.PotentialInventorySupplier.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.PotentialInventorySupplier} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.PotentialInventorySupplier.toObject = function(includeInstance, msg) {
  var f, obj = {
    supplierId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    supplierName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    pricePerUnit: jspb.Message.getFieldWithDefault(msg, 3, 0),
    supplierSiteId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    supplierSiteName: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.PotentialInventorySupplier}
 */
proto.vtv1.PotentialInventorySupplier.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.PotentialInventorySupplier;
  return proto.vtv1.PotentialInventorySupplier.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.PotentialInventorySupplier} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.PotentialInventorySupplier}
 */
proto.vtv1.PotentialInventorySupplier.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSupplierId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSupplierName(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPricePerUnit(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setSupplierSiteId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setSupplierSiteName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.PotentialInventorySupplier.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.PotentialInventorySupplier.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.PotentialInventorySupplier} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.PotentialInventorySupplier.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSupplierId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSupplierName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPricePerUnit();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getSupplierSiteId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getSupplierSiteName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string supplier_id = 1;
 * @return {string}
 */
proto.vtv1.PotentialInventorySupplier.prototype.getSupplierId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.PotentialInventorySupplier} returns this
 */
proto.vtv1.PotentialInventorySupplier.prototype.setSupplierId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string supplier_name = 2;
 * @return {string}
 */
proto.vtv1.PotentialInventorySupplier.prototype.getSupplierName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.PotentialInventorySupplier} returns this
 */
proto.vtv1.PotentialInventorySupplier.prototype.setSupplierName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 price_per_unit = 3;
 * @return {number}
 */
proto.vtv1.PotentialInventorySupplier.prototype.getPricePerUnit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.PotentialInventorySupplier} returns this
 */
proto.vtv1.PotentialInventorySupplier.prototype.setPricePerUnit = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string supplier_site_id = 4;
 * @return {string}
 */
proto.vtv1.PotentialInventorySupplier.prototype.getSupplierSiteId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.PotentialInventorySupplier} returns this
 */
proto.vtv1.PotentialInventorySupplier.prototype.setSupplierSiteId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string supplier_site_name = 5;
 * @return {string}
 */
proto.vtv1.PotentialInventorySupplier.prototype.getSupplierSiteName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.PotentialInventorySupplier} returns this
 */
proto.vtv1.PotentialInventorySupplier.prototype.setSupplierSiteName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.DemandSignalDetails.repeatedFields_ = [16,17];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.DemandSignalDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.DemandSignalDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.DemandSignalDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.DemandSignalDetails.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    siteId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    siteName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    quantityOrdered: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    totalQuantityPromised: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    supplierCount: jspb.Message.getFieldWithDefault(msg, 6, 0),
    suppliersResponded: jspb.Message.getFieldWithDefault(msg, 7, 0),
    resultingOrderCount: jspb.Message.getFieldWithDefault(msg, 8, 0),
    deliveryWindowStartsAt: jspb.Message.getFieldWithDefault(msg, 9, ""),
    deliveryWindowMinutes: jspb.Message.getFieldWithDefault(msg, 10, 0),
    createdAt: jspb.Message.getFieldWithDefault(msg, 11, ""),
    lastUpdatedAt: jspb.Message.getFieldWithDefault(msg, 12, ""),
    productId: jspb.Message.getFieldWithDefault(msg, 13, ""),
    productName: jspb.Message.getFieldWithDefault(msg, 14, ""),
    productUnitAbbreviation: jspb.Message.getFieldWithDefault(msg, 15, ""),
    requestsList: jspb.Message.toObjectList(msg.getRequestsList(),
    proto.vtv1.DemandSignalDetailsRequest.toObject, includeInstance),
    ordersList: jspb.Message.toObjectList(msg.getOrdersList(),
    proto.vtv1.OrderDetailsFromDemandSignal.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.DemandSignalDetails}
 */
proto.vtv1.DemandSignalDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.DemandSignalDetails;
  return proto.vtv1.DemandSignalDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.DemandSignalDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.DemandSignalDetails}
 */
proto.vtv1.DemandSignalDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSiteId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSiteName(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setQuantityOrdered(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalQuantityPromised(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSupplierCount(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSuppliersResponded(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setResultingOrderCount(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeliveryWindowStartsAt(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDeliveryWindowMinutes(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedAt(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastUpdatedAt(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductId(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductName(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductUnitAbbreviation(value);
      break;
    case 16:
      var value = new proto.vtv1.DemandSignalDetailsRequest;
      reader.readMessage(value,proto.vtv1.DemandSignalDetailsRequest.deserializeBinaryFromReader);
      msg.addRequests(value);
      break;
    case 17:
      var value = new proto.vtv1.OrderDetailsFromDemandSignal;
      reader.readMessage(value,proto.vtv1.OrderDetailsFromDemandSignal.deserializeBinaryFromReader);
      msg.addOrders(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.DemandSignalDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.DemandSignalDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.DemandSignalDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.DemandSignalDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSiteId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSiteName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getQuantityOrdered();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getTotalQuantityPromised();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getSupplierCount();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getSuppliersResponded();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
  f = message.getResultingOrderCount();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
  f = message.getDeliveryWindowStartsAt();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getDeliveryWindowMinutes();
  if (f !== 0) {
    writer.writeInt64(
      10,
      f
    );
  }
  f = message.getCreatedAt();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getLastUpdatedAt();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getProductId();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getProductName();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getProductUnitAbbreviation();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getRequestsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      16,
      f,
      proto.vtv1.DemandSignalDetailsRequest.serializeBinaryToWriter
    );
  }
  f = message.getOrdersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      17,
      f,
      proto.vtv1.OrderDetailsFromDemandSignal.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.vtv1.DemandSignalDetails.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.DemandSignalDetails} returns this
 */
proto.vtv1.DemandSignalDetails.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string site_id = 2;
 * @return {string}
 */
proto.vtv1.DemandSignalDetails.prototype.getSiteId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.DemandSignalDetails} returns this
 */
proto.vtv1.DemandSignalDetails.prototype.setSiteId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string site_name = 3;
 * @return {string}
 */
proto.vtv1.DemandSignalDetails.prototype.getSiteName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.DemandSignalDetails} returns this
 */
proto.vtv1.DemandSignalDetails.prototype.setSiteName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional double quantity_ordered = 4;
 * @return {number}
 */
proto.vtv1.DemandSignalDetails.prototype.getQuantityOrdered = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.DemandSignalDetails} returns this
 */
proto.vtv1.DemandSignalDetails.prototype.setQuantityOrdered = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional double total_quantity_promised = 5;
 * @return {number}
 */
proto.vtv1.DemandSignalDetails.prototype.getTotalQuantityPromised = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.DemandSignalDetails} returns this
 */
proto.vtv1.DemandSignalDetails.prototype.setTotalQuantityPromised = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional int64 supplier_count = 6;
 * @return {number}
 */
proto.vtv1.DemandSignalDetails.prototype.getSupplierCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.DemandSignalDetails} returns this
 */
proto.vtv1.DemandSignalDetails.prototype.setSupplierCount = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int64 suppliers_responded = 7;
 * @return {number}
 */
proto.vtv1.DemandSignalDetails.prototype.getSuppliersResponded = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.DemandSignalDetails} returns this
 */
proto.vtv1.DemandSignalDetails.prototype.setSuppliersResponded = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int64 resulting_order_count = 8;
 * @return {number}
 */
proto.vtv1.DemandSignalDetails.prototype.getResultingOrderCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.DemandSignalDetails} returns this
 */
proto.vtv1.DemandSignalDetails.prototype.setResultingOrderCount = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional string delivery_window_starts_at = 9;
 * @return {string}
 */
proto.vtv1.DemandSignalDetails.prototype.getDeliveryWindowStartsAt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.DemandSignalDetails} returns this
 */
proto.vtv1.DemandSignalDetails.prototype.setDeliveryWindowStartsAt = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional int64 delivery_window_minutes = 10;
 * @return {number}
 */
proto.vtv1.DemandSignalDetails.prototype.getDeliveryWindowMinutes = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.DemandSignalDetails} returns this
 */
proto.vtv1.DemandSignalDetails.prototype.setDeliveryWindowMinutes = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional string created_at = 11;
 * @return {string}
 */
proto.vtv1.DemandSignalDetails.prototype.getCreatedAt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.DemandSignalDetails} returns this
 */
proto.vtv1.DemandSignalDetails.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string last_updated_at = 12;
 * @return {string}
 */
proto.vtv1.DemandSignalDetails.prototype.getLastUpdatedAt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.DemandSignalDetails} returns this
 */
proto.vtv1.DemandSignalDetails.prototype.setLastUpdatedAt = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string product_id = 13;
 * @return {string}
 */
proto.vtv1.DemandSignalDetails.prototype.getProductId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.DemandSignalDetails} returns this
 */
proto.vtv1.DemandSignalDetails.prototype.setProductId = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string product_name = 14;
 * @return {string}
 */
proto.vtv1.DemandSignalDetails.prototype.getProductName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.DemandSignalDetails} returns this
 */
proto.vtv1.DemandSignalDetails.prototype.setProductName = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string product_unit_abbreviation = 15;
 * @return {string}
 */
proto.vtv1.DemandSignalDetails.prototype.getProductUnitAbbreviation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.DemandSignalDetails} returns this
 */
proto.vtv1.DemandSignalDetails.prototype.setProductUnitAbbreviation = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * repeated DemandSignalDetailsRequest requests = 16;
 * @return {!Array<!proto.vtv1.DemandSignalDetailsRequest>}
 */
proto.vtv1.DemandSignalDetails.prototype.getRequestsList = function() {
  return /** @type{!Array<!proto.vtv1.DemandSignalDetailsRequest>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vtv1.DemandSignalDetailsRequest, 16));
};


/**
 * @param {!Array<!proto.vtv1.DemandSignalDetailsRequest>} value
 * @return {!proto.vtv1.DemandSignalDetails} returns this
*/
proto.vtv1.DemandSignalDetails.prototype.setRequestsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 16, value);
};


/**
 * @param {!proto.vtv1.DemandSignalDetailsRequest=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.DemandSignalDetailsRequest}
 */
proto.vtv1.DemandSignalDetails.prototype.addRequests = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 16, opt_value, proto.vtv1.DemandSignalDetailsRequest, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.DemandSignalDetails} returns this
 */
proto.vtv1.DemandSignalDetails.prototype.clearRequestsList = function() {
  return this.setRequestsList([]);
};


/**
 * repeated OrderDetailsFromDemandSignal orders = 17;
 * @return {!Array<!proto.vtv1.OrderDetailsFromDemandSignal>}
 */
proto.vtv1.DemandSignalDetails.prototype.getOrdersList = function() {
  return /** @type{!Array<!proto.vtv1.OrderDetailsFromDemandSignal>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vtv1.OrderDetailsFromDemandSignal, 17));
};


/**
 * @param {!Array<!proto.vtv1.OrderDetailsFromDemandSignal>} value
 * @return {!proto.vtv1.DemandSignalDetails} returns this
*/
proto.vtv1.DemandSignalDetails.prototype.setOrdersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 17, value);
};


/**
 * @param {!proto.vtv1.OrderDetailsFromDemandSignal=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.OrderDetailsFromDemandSignal}
 */
proto.vtv1.DemandSignalDetails.prototype.addOrders = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 17, opt_value, proto.vtv1.OrderDetailsFromDemandSignal, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.DemandSignalDetails} returns this
 */
proto.vtv1.DemandSignalDetails.prototype.clearOrdersList = function() {
  return this.setOrdersList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.OrderDetailsFromDemandSignal.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.OrderDetailsFromDemandSignal.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.OrderDetailsFromDemandSignal} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.OrderDetailsFromDemandSignal.toObject = function(includeInstance, msg) {
  var f, obj = {
    orderId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    willSiteBeClosed: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    exceptionMessage: (f = msg.getExceptionMessage()) && proto_vorto_types_types_pb.NullableString.toObject(includeInstance, f),
    arrivalWindowStartsAt: (f = msg.getArrivalWindowStartsAt()) && proto_vorto_types_types_pb.NullableTime.toObject(includeInstance, f),
    arrivalWindowEndsAt: (f = msg.getArrivalWindowEndsAt()) && proto_vorto_types_types_pb.NullableInt64.toObject(includeInstance, f),
    edgeName: (f = msg.getEdgeName()) && proto_vorto_types_types_pb.NullableString.toObject(includeInstance, f),
    productName: jspb.Message.getFieldWithDefault(msg, 7, ""),
    unitAbbreviation: jspb.Message.getFieldWithDefault(msg, 8, ""),
    status: jspb.Message.getFieldWithDefault(msg, 9, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.OrderDetailsFromDemandSignal}
 */
proto.vtv1.OrderDetailsFromDemandSignal.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.OrderDetailsFromDemandSignal;
  return proto.vtv1.OrderDetailsFromDemandSignal.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.OrderDetailsFromDemandSignal} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.OrderDetailsFromDemandSignal}
 */
proto.vtv1.OrderDetailsFromDemandSignal.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrderId(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setWillSiteBeClosed(value);
      break;
    case 3:
      var value = new proto_vorto_types_types_pb.NullableString;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableString.deserializeBinaryFromReader);
      msg.setExceptionMessage(value);
      break;
    case 4:
      var value = new proto_vorto_types_types_pb.NullableTime;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableTime.deserializeBinaryFromReader);
      msg.setArrivalWindowStartsAt(value);
      break;
    case 5:
      var value = new proto_vorto_types_types_pb.NullableInt64;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableInt64.deserializeBinaryFromReader);
      msg.setArrivalWindowEndsAt(value);
      break;
    case 6:
      var value = new proto_vorto_types_types_pb.NullableString;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableString.deserializeBinaryFromReader);
      msg.setEdgeName(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductName(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setUnitAbbreviation(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.OrderDetailsFromDemandSignal.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.OrderDetailsFromDemandSignal.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.OrderDetailsFromDemandSignal} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.OrderDetailsFromDemandSignal.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrderId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getWillSiteBeClosed();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getExceptionMessage();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto_vorto_types_types_pb.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getArrivalWindowStartsAt();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto_vorto_types_types_pb.NullableTime.serializeBinaryToWriter
    );
  }
  f = message.getArrivalWindowEndsAt();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto_vorto_types_types_pb.NullableInt64.serializeBinaryToWriter
    );
  }
  f = message.getEdgeName();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto_vorto_types_types_pb.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getProductName();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getUnitAbbreviation();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
};


/**
 * optional string order_id = 1;
 * @return {string}
 */
proto.vtv1.OrderDetailsFromDemandSignal.prototype.getOrderId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.OrderDetailsFromDemandSignal} returns this
 */
proto.vtv1.OrderDetailsFromDemandSignal.prototype.setOrderId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool will_site_be_closed = 2;
 * @return {boolean}
 */
proto.vtv1.OrderDetailsFromDemandSignal.prototype.getWillSiteBeClosed = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vtv1.OrderDetailsFromDemandSignal} returns this
 */
proto.vtv1.OrderDetailsFromDemandSignal.prototype.setWillSiteBeClosed = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional NullableString exception_message = 3;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.OrderDetailsFromDemandSignal.prototype.getExceptionMessage = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableString, 3));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.OrderDetailsFromDemandSignal} returns this
*/
proto.vtv1.OrderDetailsFromDemandSignal.prototype.setExceptionMessage = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.OrderDetailsFromDemandSignal} returns this
 */
proto.vtv1.OrderDetailsFromDemandSignal.prototype.clearExceptionMessage = function() {
  return this.setExceptionMessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.OrderDetailsFromDemandSignal.prototype.hasExceptionMessage = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional NullableTime arrival_window_starts_at = 4;
 * @return {?proto.vtv1.NullableTime}
 */
proto.vtv1.OrderDetailsFromDemandSignal.prototype.getArrivalWindowStartsAt = function() {
  return /** @type{?proto.vtv1.NullableTime} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableTime, 4));
};


/**
 * @param {?proto.vtv1.NullableTime|undefined} value
 * @return {!proto.vtv1.OrderDetailsFromDemandSignal} returns this
*/
proto.vtv1.OrderDetailsFromDemandSignal.prototype.setArrivalWindowStartsAt = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.OrderDetailsFromDemandSignal} returns this
 */
proto.vtv1.OrderDetailsFromDemandSignal.prototype.clearArrivalWindowStartsAt = function() {
  return this.setArrivalWindowStartsAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.OrderDetailsFromDemandSignal.prototype.hasArrivalWindowStartsAt = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional NullableInt64 arrival_window_ends_at = 5;
 * @return {?proto.vtv1.NullableInt64}
 */
proto.vtv1.OrderDetailsFromDemandSignal.prototype.getArrivalWindowEndsAt = function() {
  return /** @type{?proto.vtv1.NullableInt64} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableInt64, 5));
};


/**
 * @param {?proto.vtv1.NullableInt64|undefined} value
 * @return {!proto.vtv1.OrderDetailsFromDemandSignal} returns this
*/
proto.vtv1.OrderDetailsFromDemandSignal.prototype.setArrivalWindowEndsAt = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.OrderDetailsFromDemandSignal} returns this
 */
proto.vtv1.OrderDetailsFromDemandSignal.prototype.clearArrivalWindowEndsAt = function() {
  return this.setArrivalWindowEndsAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.OrderDetailsFromDemandSignal.prototype.hasArrivalWindowEndsAt = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional NullableString edge_name = 6;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.OrderDetailsFromDemandSignal.prototype.getEdgeName = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableString, 6));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.OrderDetailsFromDemandSignal} returns this
*/
proto.vtv1.OrderDetailsFromDemandSignal.prototype.setEdgeName = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.OrderDetailsFromDemandSignal} returns this
 */
proto.vtv1.OrderDetailsFromDemandSignal.prototype.clearEdgeName = function() {
  return this.setEdgeName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.OrderDetailsFromDemandSignal.prototype.hasEdgeName = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional string product_name = 7;
 * @return {string}
 */
proto.vtv1.OrderDetailsFromDemandSignal.prototype.getProductName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.OrderDetailsFromDemandSignal} returns this
 */
proto.vtv1.OrderDetailsFromDemandSignal.prototype.setProductName = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string unit_abbreviation = 8;
 * @return {string}
 */
proto.vtv1.OrderDetailsFromDemandSignal.prototype.getUnitAbbreviation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.OrderDetailsFromDemandSignal} returns this
 */
proto.vtv1.OrderDetailsFromDemandSignal.prototype.setUnitAbbreviation = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string status = 9;
 * @return {string}
 */
proto.vtv1.OrderDetailsFromDemandSignal.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.OrderDetailsFromDemandSignal} returns this
 */
proto.vtv1.OrderDetailsFromDemandSignal.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.DemandSignalDetailsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.DemandSignalDetailsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.DemandSignalDetailsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.DemandSignalDetailsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    responseId: (f = msg.getResponseId()) && proto_vorto_types_types_pb.NullableInt64.toObject(includeInstance, f),
    status: jspb.Message.getFieldWithDefault(msg, 3, 0),
    updatedAt: jspb.Message.getFieldWithDefault(msg, 4, ""),
    supplierId: jspb.Message.getFieldWithDefault(msg, 5, ""),
    supplierName: jspb.Message.getFieldWithDefault(msg, 6, ""),
    supplierSiteId: (f = msg.getSupplierSiteId()) && proto_vorto_types_types_pb.NullableString.toObject(includeInstance, f),
    supplierSiteName: (f = msg.getSupplierSiteName()) && proto_vorto_types_types_pb.NullableString.toObject(includeInstance, f),
    quanityConfirmed: (f = msg.getQuanityConfirmed()) && proto_vorto_types_types_pb.NullableDouble.toObject(includeInstance, f),
    poName: (f = msg.getPoName()) && proto_vorto_types_types_pb.NullableString.toObject(includeInstance, f),
    poLineItemName: (f = msg.getPoLineItemName()) && proto_vorto_types_types_pb.NullableString.toObject(includeInstance, f),
    poId: (f = msg.getPoId()) && proto_vorto_types_types_pb.NullableString.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.DemandSignalDetailsRequest}
 */
proto.vtv1.DemandSignalDetailsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.DemandSignalDetailsRequest;
  return proto.vtv1.DemandSignalDetailsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.DemandSignalDetailsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.DemandSignalDetailsRequest}
 */
proto.vtv1.DemandSignalDetailsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRequestId(value);
      break;
    case 2:
      var value = new proto_vorto_types_types_pb.NullableInt64;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableInt64.deserializeBinaryFromReader);
      msg.setResponseId(value);
      break;
    case 3:
      var value = /** @type {!proto.vtv1.DemandSignalDetailsRequest.Status} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setUpdatedAt(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setSupplierId(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setSupplierName(value);
      break;
    case 7:
      var value = new proto_vorto_types_types_pb.NullableString;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableString.deserializeBinaryFromReader);
      msg.setSupplierSiteId(value);
      break;
    case 8:
      var value = new proto_vorto_types_types_pb.NullableString;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableString.deserializeBinaryFromReader);
      msg.setSupplierSiteName(value);
      break;
    case 9:
      var value = new proto_vorto_types_types_pb.NullableDouble;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableDouble.deserializeBinaryFromReader);
      msg.setQuanityConfirmed(value);
      break;
    case 10:
      var value = new proto_vorto_types_types_pb.NullableString;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableString.deserializeBinaryFromReader);
      msg.setPoName(value);
      break;
    case 11:
      var value = new proto_vorto_types_types_pb.NullableString;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableString.deserializeBinaryFromReader);
      msg.setPoLineItemName(value);
      break;
    case 12:
      var value = new proto_vorto_types_types_pb.NullableString;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableString.deserializeBinaryFromReader);
      msg.setPoId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.DemandSignalDetailsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.DemandSignalDetailsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.DemandSignalDetailsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.DemandSignalDetailsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getResponseId();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_vorto_types_types_pb.NullableInt64.serializeBinaryToWriter
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getUpdatedAt();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getSupplierId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getSupplierName();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getSupplierSiteId();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto_vorto_types_types_pb.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getSupplierSiteName();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto_vorto_types_types_pb.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getQuanityConfirmed();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto_vorto_types_types_pb.NullableDouble.serializeBinaryToWriter
    );
  }
  f = message.getPoName();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto_vorto_types_types_pb.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getPoLineItemName();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto_vorto_types_types_pb.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getPoId();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      proto_vorto_types_types_pb.NullableString.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.vtv1.DemandSignalDetailsRequest.Status = {
  UNKNOWN: 0,
  SENT: 1,
  RESPONDED: 2,
  ORDERED: 3
};

/**
 * optional int64 request_id = 1;
 * @return {number}
 */
proto.vtv1.DemandSignalDetailsRequest.prototype.getRequestId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.DemandSignalDetailsRequest} returns this
 */
proto.vtv1.DemandSignalDetailsRequest.prototype.setRequestId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional NullableInt64 response_id = 2;
 * @return {?proto.vtv1.NullableInt64}
 */
proto.vtv1.DemandSignalDetailsRequest.prototype.getResponseId = function() {
  return /** @type{?proto.vtv1.NullableInt64} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableInt64, 2));
};


/**
 * @param {?proto.vtv1.NullableInt64|undefined} value
 * @return {!proto.vtv1.DemandSignalDetailsRequest} returns this
*/
proto.vtv1.DemandSignalDetailsRequest.prototype.setResponseId = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.DemandSignalDetailsRequest} returns this
 */
proto.vtv1.DemandSignalDetailsRequest.prototype.clearResponseId = function() {
  return this.setResponseId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.DemandSignalDetailsRequest.prototype.hasResponseId = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Status status = 3;
 * @return {!proto.vtv1.DemandSignalDetailsRequest.Status}
 */
proto.vtv1.DemandSignalDetailsRequest.prototype.getStatus = function() {
  return /** @type {!proto.vtv1.DemandSignalDetailsRequest.Status} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.vtv1.DemandSignalDetailsRequest.Status} value
 * @return {!proto.vtv1.DemandSignalDetailsRequest} returns this
 */
proto.vtv1.DemandSignalDetailsRequest.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional string updated_at = 4;
 * @return {string}
 */
proto.vtv1.DemandSignalDetailsRequest.prototype.getUpdatedAt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.DemandSignalDetailsRequest} returns this
 */
proto.vtv1.DemandSignalDetailsRequest.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string supplier_id = 5;
 * @return {string}
 */
proto.vtv1.DemandSignalDetailsRequest.prototype.getSupplierId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.DemandSignalDetailsRequest} returns this
 */
proto.vtv1.DemandSignalDetailsRequest.prototype.setSupplierId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string supplier_name = 6;
 * @return {string}
 */
proto.vtv1.DemandSignalDetailsRequest.prototype.getSupplierName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.DemandSignalDetailsRequest} returns this
 */
proto.vtv1.DemandSignalDetailsRequest.prototype.setSupplierName = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional NullableString supplier_site_id = 7;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.DemandSignalDetailsRequest.prototype.getSupplierSiteId = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableString, 7));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.DemandSignalDetailsRequest} returns this
*/
proto.vtv1.DemandSignalDetailsRequest.prototype.setSupplierSiteId = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.DemandSignalDetailsRequest} returns this
 */
proto.vtv1.DemandSignalDetailsRequest.prototype.clearSupplierSiteId = function() {
  return this.setSupplierSiteId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.DemandSignalDetailsRequest.prototype.hasSupplierSiteId = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional NullableString supplier_site_name = 8;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.DemandSignalDetailsRequest.prototype.getSupplierSiteName = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableString, 8));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.DemandSignalDetailsRequest} returns this
*/
proto.vtv1.DemandSignalDetailsRequest.prototype.setSupplierSiteName = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.DemandSignalDetailsRequest} returns this
 */
proto.vtv1.DemandSignalDetailsRequest.prototype.clearSupplierSiteName = function() {
  return this.setSupplierSiteName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.DemandSignalDetailsRequest.prototype.hasSupplierSiteName = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional NullableDouble quanity_confirmed = 9;
 * @return {?proto.vtv1.NullableDouble}
 */
proto.vtv1.DemandSignalDetailsRequest.prototype.getQuanityConfirmed = function() {
  return /** @type{?proto.vtv1.NullableDouble} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableDouble, 9));
};


/**
 * @param {?proto.vtv1.NullableDouble|undefined} value
 * @return {!proto.vtv1.DemandSignalDetailsRequest} returns this
*/
proto.vtv1.DemandSignalDetailsRequest.prototype.setQuanityConfirmed = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.DemandSignalDetailsRequest} returns this
 */
proto.vtv1.DemandSignalDetailsRequest.prototype.clearQuanityConfirmed = function() {
  return this.setQuanityConfirmed(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.DemandSignalDetailsRequest.prototype.hasQuanityConfirmed = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional NullableString po_name = 10;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.DemandSignalDetailsRequest.prototype.getPoName = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableString, 10));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.DemandSignalDetailsRequest} returns this
*/
proto.vtv1.DemandSignalDetailsRequest.prototype.setPoName = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.DemandSignalDetailsRequest} returns this
 */
proto.vtv1.DemandSignalDetailsRequest.prototype.clearPoName = function() {
  return this.setPoName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.DemandSignalDetailsRequest.prototype.hasPoName = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional NullableString po_line_item_name = 11;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.DemandSignalDetailsRequest.prototype.getPoLineItemName = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableString, 11));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.DemandSignalDetailsRequest} returns this
*/
proto.vtv1.DemandSignalDetailsRequest.prototype.setPoLineItemName = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.DemandSignalDetailsRequest} returns this
 */
proto.vtv1.DemandSignalDetailsRequest.prototype.clearPoLineItemName = function() {
  return this.setPoLineItemName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.DemandSignalDetailsRequest.prototype.hasPoLineItemName = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional NullableString po_id = 12;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.DemandSignalDetailsRequest.prototype.getPoId = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableString, 12));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.DemandSignalDetailsRequest} returns this
*/
proto.vtv1.DemandSignalDetailsRequest.prototype.setPoId = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.DemandSignalDetailsRequest} returns this
 */
proto.vtv1.DemandSignalDetailsRequest.prototype.clearPoId = function() {
  return this.setPoId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.DemandSignalDetailsRequest.prototype.hasPoId = function() {
  return jspb.Message.getField(this, 12) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.DashboardSiteResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.DashboardSiteResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.DashboardSiteResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.DashboardSiteResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    siteId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    siteName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    nptProcessName: (f = msg.getNptProcessName()) && proto_vorto_types_types_pb.NullableString.toObject(includeInstance, f),
    operationalStatus: (f = msg.getOperationalStatus()) && proto_vorto_types_types_pb.NullableString.toObject(includeInstance, f),
    nptEstimatedAt: (f = msg.getNptEstimatedAt()) && proto_vorto_types_types_pb.NullableTime.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.DashboardSiteResponse}
 */
proto.vtv1.DashboardSiteResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.DashboardSiteResponse;
  return proto.vtv1.DashboardSiteResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.DashboardSiteResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.DashboardSiteResponse}
 */
proto.vtv1.DashboardSiteResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSiteId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSiteName(value);
      break;
    case 3:
      var value = new proto_vorto_types_types_pb.NullableString;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableString.deserializeBinaryFromReader);
      msg.setNptProcessName(value);
      break;
    case 4:
      var value = new proto_vorto_types_types_pb.NullableString;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableString.deserializeBinaryFromReader);
      msg.setOperationalStatus(value);
      break;
    case 5:
      var value = new proto_vorto_types_types_pb.NullableTime;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableTime.deserializeBinaryFromReader);
      msg.setNptEstimatedAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.DashboardSiteResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.DashboardSiteResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.DashboardSiteResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.DashboardSiteResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSiteId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSiteName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getNptProcessName();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto_vorto_types_types_pb.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getOperationalStatus();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto_vorto_types_types_pb.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getNptEstimatedAt();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto_vorto_types_types_pb.NullableTime.serializeBinaryToWriter
    );
  }
};


/**
 * optional string site_id = 1;
 * @return {string}
 */
proto.vtv1.DashboardSiteResponse.prototype.getSiteId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.DashboardSiteResponse} returns this
 */
proto.vtv1.DashboardSiteResponse.prototype.setSiteId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string site_name = 2;
 * @return {string}
 */
proto.vtv1.DashboardSiteResponse.prototype.getSiteName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.DashboardSiteResponse} returns this
 */
proto.vtv1.DashboardSiteResponse.prototype.setSiteName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional NullableString npt_process_name = 3;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.DashboardSiteResponse.prototype.getNptProcessName = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableString, 3));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.DashboardSiteResponse} returns this
*/
proto.vtv1.DashboardSiteResponse.prototype.setNptProcessName = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.DashboardSiteResponse} returns this
 */
proto.vtv1.DashboardSiteResponse.prototype.clearNptProcessName = function() {
  return this.setNptProcessName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.DashboardSiteResponse.prototype.hasNptProcessName = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional NullableString operational_status = 4;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.DashboardSiteResponse.prototype.getOperationalStatus = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableString, 4));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.DashboardSiteResponse} returns this
*/
proto.vtv1.DashboardSiteResponse.prototype.setOperationalStatus = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.DashboardSiteResponse} returns this
 */
proto.vtv1.DashboardSiteResponse.prototype.clearOperationalStatus = function() {
  return this.setOperationalStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.DashboardSiteResponse.prototype.hasOperationalStatus = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional NullableTime npt_estimated_at = 5;
 * @return {?proto.vtv1.NullableTime}
 */
proto.vtv1.DashboardSiteResponse.prototype.getNptEstimatedAt = function() {
  return /** @type{?proto.vtv1.NullableTime} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableTime, 5));
};


/**
 * @param {?proto.vtv1.NullableTime|undefined} value
 * @return {!proto.vtv1.DashboardSiteResponse} returns this
*/
proto.vtv1.DashboardSiteResponse.prototype.setNptEstimatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.DashboardSiteResponse} returns this
 */
proto.vtv1.DashboardSiteResponse.prototype.clearNptEstimatedAt = function() {
  return this.setNptEstimatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.DashboardSiteResponse.prototype.hasNptEstimatedAt = function() {
  return jspb.Message.getField(this, 5) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.DashboardEdgeResponse.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.DashboardEdgeResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.DashboardEdgeResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.DashboardEdgeResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.DashboardEdgeResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    accountId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    accountName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    acceptanceRate: jspb.Message.getFieldWithDefault(msg, 3, 0),
    outstandingShipments: jspb.Message.getFieldWithDefault(msg, 4, 0),
    needsAttentionShipmentsList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f,
    onTimeRate: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.DashboardEdgeResponse}
 */
proto.vtv1.DashboardEdgeResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.DashboardEdgeResponse;
  return proto.vtv1.DashboardEdgeResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.DashboardEdgeResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.DashboardEdgeResponse}
 */
proto.vtv1.DashboardEdgeResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountName(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAcceptanceRate(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setOutstandingShipments(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.addNeedsAttentionShipments(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setOnTimeRate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.DashboardEdgeResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.DashboardEdgeResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.DashboardEdgeResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.DashboardEdgeResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccountId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAccountName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAcceptanceRate();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getOutstandingShipments();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getNeedsAttentionShipmentsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      5,
      f
    );
  }
  f = message.getOnTimeRate();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
};


/**
 * optional string account_id = 1;
 * @return {string}
 */
proto.vtv1.DashboardEdgeResponse.prototype.getAccountId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.DashboardEdgeResponse} returns this
 */
proto.vtv1.DashboardEdgeResponse.prototype.setAccountId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string account_name = 2;
 * @return {string}
 */
proto.vtv1.DashboardEdgeResponse.prototype.getAccountName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.DashboardEdgeResponse} returns this
 */
proto.vtv1.DashboardEdgeResponse.prototype.setAccountName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 acceptance_rate = 3;
 * @return {number}
 */
proto.vtv1.DashboardEdgeResponse.prototype.getAcceptanceRate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.DashboardEdgeResponse} returns this
 */
proto.vtv1.DashboardEdgeResponse.prototype.setAcceptanceRate = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 outstanding_shipments = 4;
 * @return {number}
 */
proto.vtv1.DashboardEdgeResponse.prototype.getOutstandingShipments = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.DashboardEdgeResponse} returns this
 */
proto.vtv1.DashboardEdgeResponse.prototype.setOutstandingShipments = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * repeated string needs_attention_shipments = 5;
 * @return {!Array<string>}
 */
proto.vtv1.DashboardEdgeResponse.prototype.getNeedsAttentionShipmentsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.vtv1.DashboardEdgeResponse} returns this
 */
proto.vtv1.DashboardEdgeResponse.prototype.setNeedsAttentionShipmentsList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.vtv1.DashboardEdgeResponse} returns this
 */
proto.vtv1.DashboardEdgeResponse.prototype.addNeedsAttentionShipments = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.DashboardEdgeResponse} returns this
 */
proto.vtv1.DashboardEdgeResponse.prototype.clearNeedsAttentionShipmentsList = function() {
  return this.setNeedsAttentionShipmentsList([]);
};


/**
 * optional int64 on_time_rate = 6;
 * @return {number}
 */
proto.vtv1.DashboardEdgeResponse.prototype.getOnTimeRate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.DashboardEdgeResponse} returns this
 */
proto.vtv1.DashboardEdgeResponse.prototype.setOnTimeRate = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.DashboardSupplierResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.DashboardSupplierResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.DashboardSupplierResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.DashboardSupplierResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    accountId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    accountName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    acceptanceRate: jspb.Message.getFieldWithDefault(msg, 3, 0),
    outstandingShipments: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.DashboardSupplierResponse}
 */
proto.vtv1.DashboardSupplierResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.DashboardSupplierResponse;
  return proto.vtv1.DashboardSupplierResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.DashboardSupplierResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.DashboardSupplierResponse}
 */
proto.vtv1.DashboardSupplierResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountName(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAcceptanceRate(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setOutstandingShipments(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.DashboardSupplierResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.DashboardSupplierResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.DashboardSupplierResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.DashboardSupplierResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccountId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAccountName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAcceptanceRate();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getOutstandingShipments();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
};


/**
 * optional string account_id = 1;
 * @return {string}
 */
proto.vtv1.DashboardSupplierResponse.prototype.getAccountId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.DashboardSupplierResponse} returns this
 */
proto.vtv1.DashboardSupplierResponse.prototype.setAccountId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string account_name = 2;
 * @return {string}
 */
proto.vtv1.DashboardSupplierResponse.prototype.getAccountName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.DashboardSupplierResponse} returns this
 */
proto.vtv1.DashboardSupplierResponse.prototype.setAccountName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 acceptance_rate = 3;
 * @return {number}
 */
proto.vtv1.DashboardSupplierResponse.prototype.getAcceptanceRate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.DashboardSupplierResponse} returns this
 */
proto.vtv1.DashboardSupplierResponse.prototype.setAcceptanceRate = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 outstanding_shipments = 4;
 * @return {number}
 */
proto.vtv1.DashboardSupplierResponse.prototype.getOutstandingShipments = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.DashboardSupplierResponse} returns this
 */
proto.vtv1.DashboardSupplierResponse.prototype.setOutstandingShipments = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.AutopilotFeed.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.AutopilotFeed.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.AutopilotFeed} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.AutopilotFeed.toObject = function(includeInstance, msg) {
  var f, obj = {
    productName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    destinationName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    purchaseOrderName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    description: jspb.Message.getFieldWithDefault(msg, 4, ""),
    createdAt: jspb.Message.getFieldWithDefault(msg, 5, ""),
    type: jspb.Message.getFieldWithDefault(msg, 6, 0),
    from: jspb.Message.getFieldWithDefault(msg, 7, ""),
    to: jspb.Message.getFieldWithDefault(msg, 8, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.AutopilotFeed}
 */
proto.vtv1.AutopilotFeed.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.AutopilotFeed;
  return proto.vtv1.AutopilotFeed.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.AutopilotFeed} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.AutopilotFeed}
 */
proto.vtv1.AutopilotFeed.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDestinationName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPurchaseOrderName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedAt(value);
      break;
    case 6:
      var value = /** @type {!proto.vtv1.AutopilotFeedType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setFrom(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setTo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.AutopilotFeed.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.AutopilotFeed.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.AutopilotFeed} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.AutopilotFeed.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProductName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDestinationName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPurchaseOrderName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCreatedAt();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getFrom();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getTo();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
};


/**
 * optional string product_name = 1;
 * @return {string}
 */
proto.vtv1.AutopilotFeed.prototype.getProductName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.AutopilotFeed} returns this
 */
proto.vtv1.AutopilotFeed.prototype.setProductName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string destination_name = 2;
 * @return {string}
 */
proto.vtv1.AutopilotFeed.prototype.getDestinationName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.AutopilotFeed} returns this
 */
proto.vtv1.AutopilotFeed.prototype.setDestinationName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string purchase_order_name = 3;
 * @return {string}
 */
proto.vtv1.AutopilotFeed.prototype.getPurchaseOrderName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.AutopilotFeed} returns this
 */
proto.vtv1.AutopilotFeed.prototype.setPurchaseOrderName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string description = 4;
 * @return {string}
 */
proto.vtv1.AutopilotFeed.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.AutopilotFeed} returns this
 */
proto.vtv1.AutopilotFeed.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string created_at = 5;
 * @return {string}
 */
proto.vtv1.AutopilotFeed.prototype.getCreatedAt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.AutopilotFeed} returns this
 */
proto.vtv1.AutopilotFeed.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional AutopilotFeedType type = 6;
 * @return {!proto.vtv1.AutopilotFeedType}
 */
proto.vtv1.AutopilotFeed.prototype.getType = function() {
  return /** @type {!proto.vtv1.AutopilotFeedType} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.vtv1.AutopilotFeedType} value
 * @return {!proto.vtv1.AutopilotFeed} returns this
 */
proto.vtv1.AutopilotFeed.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional string from = 7;
 * @return {string}
 */
proto.vtv1.AutopilotFeed.prototype.getFrom = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.AutopilotFeed} returns this
 */
proto.vtv1.AutopilotFeed.prototype.setFrom = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string to = 8;
 * @return {string}
 */
proto.vtv1.AutopilotFeed.prototype.getTo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.AutopilotFeed} returns this
 */
proto.vtv1.AutopilotFeed.prototype.setTo = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.SingleEdgeContractAsNode.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.SingleEdgeContractAsNode.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.SingleEdgeContractAsNode.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.SingleEdgeContractAsNode} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.SingleEdgeContractAsNode.toObject = function(includeInstance, msg) {
  var f, obj = {
    contract: (f = msg.getContract()) && proto_vorto_types_types_pb.EdgeContract.toObject(includeInstance, f),
    edgeName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    edgeCosts: (f = msg.getEdgeCosts()) && proto_vorto_types_types_pb.EdgeCosts.toObject(includeInstance, f),
    productsList: jspb.Message.toObjectList(msg.getProductsList(),
    proto_vorto_types_types_pb.Product.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.SingleEdgeContractAsNode}
 */
proto.vtv1.SingleEdgeContractAsNode.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.SingleEdgeContractAsNode;
  return proto.vtv1.SingleEdgeContractAsNode.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.SingleEdgeContractAsNode} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.SingleEdgeContractAsNode}
 */
proto.vtv1.SingleEdgeContractAsNode.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_vorto_types_types_pb.EdgeContract;
      reader.readMessage(value,proto_vorto_types_types_pb.EdgeContract.deserializeBinaryFromReader);
      msg.setContract(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setEdgeName(value);
      break;
    case 3:
      var value = new proto_vorto_types_types_pb.EdgeCosts;
      reader.readMessage(value,proto_vorto_types_types_pb.EdgeCosts.deserializeBinaryFromReader);
      msg.setEdgeCosts(value);
      break;
    case 4:
      var value = new proto_vorto_types_types_pb.Product;
      reader.readMessage(value,proto_vorto_types_types_pb.Product.deserializeBinaryFromReader);
      msg.addProducts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.SingleEdgeContractAsNode.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.SingleEdgeContractAsNode.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.SingleEdgeContractAsNode} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.SingleEdgeContractAsNode.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getContract();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_vorto_types_types_pb.EdgeContract.serializeBinaryToWriter
    );
  }
  f = message.getEdgeName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getEdgeCosts();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto_vorto_types_types_pb.EdgeCosts.serializeBinaryToWriter
    );
  }
  f = message.getProductsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto_vorto_types_types_pb.Product.serializeBinaryToWriter
    );
  }
};


/**
 * optional EdgeContract contract = 1;
 * @return {?proto.vtv1.EdgeContract}
 */
proto.vtv1.SingleEdgeContractAsNode.prototype.getContract = function() {
  return /** @type{?proto.vtv1.EdgeContract} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.EdgeContract, 1));
};


/**
 * @param {?proto.vtv1.EdgeContract|undefined} value
 * @return {!proto.vtv1.SingleEdgeContractAsNode} returns this
*/
proto.vtv1.SingleEdgeContractAsNode.prototype.setContract = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.SingleEdgeContractAsNode} returns this
 */
proto.vtv1.SingleEdgeContractAsNode.prototype.clearContract = function() {
  return this.setContract(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.SingleEdgeContractAsNode.prototype.hasContract = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string edge_name = 2;
 * @return {string}
 */
proto.vtv1.SingleEdgeContractAsNode.prototype.getEdgeName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.SingleEdgeContractAsNode} returns this
 */
proto.vtv1.SingleEdgeContractAsNode.prototype.setEdgeName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional EdgeCosts edge_costs = 3;
 * @return {?proto.vtv1.EdgeCosts}
 */
proto.vtv1.SingleEdgeContractAsNode.prototype.getEdgeCosts = function() {
  return /** @type{?proto.vtv1.EdgeCosts} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.EdgeCosts, 3));
};


/**
 * @param {?proto.vtv1.EdgeCosts|undefined} value
 * @return {!proto.vtv1.SingleEdgeContractAsNode} returns this
*/
proto.vtv1.SingleEdgeContractAsNode.prototype.setEdgeCosts = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.SingleEdgeContractAsNode} returns this
 */
proto.vtv1.SingleEdgeContractAsNode.prototype.clearEdgeCosts = function() {
  return this.setEdgeCosts(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.SingleEdgeContractAsNode.prototype.hasEdgeCosts = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * repeated Product products = 4;
 * @return {!Array<!proto.vtv1.Product>}
 */
proto.vtv1.SingleEdgeContractAsNode.prototype.getProductsList = function() {
  return /** @type{!Array<!proto.vtv1.Product>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_vorto_types_types_pb.Product, 4));
};


/**
 * @param {!Array<!proto.vtv1.Product>} value
 * @return {!proto.vtv1.SingleEdgeContractAsNode} returns this
*/
proto.vtv1.SingleEdgeContractAsNode.prototype.setProductsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.vtv1.Product=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.Product}
 */
proto.vtv1.SingleEdgeContractAsNode.prototype.addProducts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.vtv1.Product, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.SingleEdgeContractAsNode} returns this
 */
proto.vtv1.SingleEdgeContractAsNode.prototype.clearProductsList = function() {
  return this.setProductsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.DeliverableSite.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.DeliverableSite.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.DeliverableSite} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.DeliverableSite.toObject = function(includeInstance, msg) {
  var f, obj = {
    siteId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    siteName: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.DeliverableSite}
 */
proto.vtv1.DeliverableSite.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.DeliverableSite;
  return proto.vtv1.DeliverableSite.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.DeliverableSite} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.DeliverableSite}
 */
proto.vtv1.DeliverableSite.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSiteId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSiteName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.DeliverableSite.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.DeliverableSite.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.DeliverableSite} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.DeliverableSite.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSiteId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSiteName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string site_id = 1;
 * @return {string}
 */
proto.vtv1.DeliverableSite.prototype.getSiteId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.DeliverableSite} returns this
 */
proto.vtv1.DeliverableSite.prototype.setSiteId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string site_name = 2;
 * @return {string}
 */
proto.vtv1.DeliverableSite.prototype.getSiteName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.DeliverableSite} returns this
 */
proto.vtv1.DeliverableSite.prototype.setSiteName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.RouteGuideForCreateOrderV2.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.RouteGuideForCreateOrderV2.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.RouteGuideForCreateOrderV2} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.RouteGuideForCreateOrderV2.toObject = function(includeInstance, msg) {
  var f, obj = {
    routeGuide: (f = msg.getRouteGuide()) && proto.vtv1.RouteGuide.toObject(includeInstance, f),
    rgId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    siteId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    siteName: jspb.Message.getFieldWithDefault(msg, 4, ""),
    salesOrder: (f = msg.getSalesOrder()) && proto_vorto_types_types_pb.NullableString.toObject(includeInstance, f),
    facilityCode: (f = msg.getFacilityCode()) && proto_vorto_types_types_pb.NullableString.toObject(includeInstance, f),
    amIShipper: jspb.Message.getBooleanFieldWithDefault(msg, 7, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.RouteGuideForCreateOrderV2}
 */
proto.vtv1.RouteGuideForCreateOrderV2.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.RouteGuideForCreateOrderV2;
  return proto.vtv1.RouteGuideForCreateOrderV2.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.RouteGuideForCreateOrderV2} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.RouteGuideForCreateOrderV2}
 */
proto.vtv1.RouteGuideForCreateOrderV2.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.vtv1.RouteGuide;
      reader.readMessage(value,proto.vtv1.RouteGuide.deserializeBinaryFromReader);
      msg.setRouteGuide(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRgId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSiteId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setSiteName(value);
      break;
    case 5:
      var value = new proto_vorto_types_types_pb.NullableString;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableString.deserializeBinaryFromReader);
      msg.setSalesOrder(value);
      break;
    case 6:
      var value = new proto_vorto_types_types_pb.NullableString;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableString.deserializeBinaryFromReader);
      msg.setFacilityCode(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAmIShipper(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.RouteGuideForCreateOrderV2.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.RouteGuideForCreateOrderV2.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.RouteGuideForCreateOrderV2} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.RouteGuideForCreateOrderV2.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRouteGuide();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.vtv1.RouteGuide.serializeBinaryToWriter
    );
  }
  f = message.getRgId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getSiteId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSiteName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getSalesOrder();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto_vorto_types_types_pb.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getFacilityCode();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto_vorto_types_types_pb.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getAmIShipper();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
};


/**
 * optional RouteGuide route_guide = 1;
 * @return {?proto.vtv1.RouteGuide}
 */
proto.vtv1.RouteGuideForCreateOrderV2.prototype.getRouteGuide = function() {
  return /** @type{?proto.vtv1.RouteGuide} */ (
    jspb.Message.getWrapperField(this, proto.vtv1.RouteGuide, 1));
};


/**
 * @param {?proto.vtv1.RouteGuide|undefined} value
 * @return {!proto.vtv1.RouteGuideForCreateOrderV2} returns this
*/
proto.vtv1.RouteGuideForCreateOrderV2.prototype.setRouteGuide = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.RouteGuideForCreateOrderV2} returns this
 */
proto.vtv1.RouteGuideForCreateOrderV2.prototype.clearRouteGuide = function() {
  return this.setRouteGuide(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.RouteGuideForCreateOrderV2.prototype.hasRouteGuide = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int64 rg_id = 2;
 * @return {number}
 */
proto.vtv1.RouteGuideForCreateOrderV2.prototype.getRgId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.RouteGuideForCreateOrderV2} returns this
 */
proto.vtv1.RouteGuideForCreateOrderV2.prototype.setRgId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string site_id = 3;
 * @return {string}
 */
proto.vtv1.RouteGuideForCreateOrderV2.prototype.getSiteId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.RouteGuideForCreateOrderV2} returns this
 */
proto.vtv1.RouteGuideForCreateOrderV2.prototype.setSiteId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string site_name = 4;
 * @return {string}
 */
proto.vtv1.RouteGuideForCreateOrderV2.prototype.getSiteName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.RouteGuideForCreateOrderV2} returns this
 */
proto.vtv1.RouteGuideForCreateOrderV2.prototype.setSiteName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional NullableString sales_order = 5;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.RouteGuideForCreateOrderV2.prototype.getSalesOrder = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableString, 5));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.RouteGuideForCreateOrderV2} returns this
*/
proto.vtv1.RouteGuideForCreateOrderV2.prototype.setSalesOrder = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.RouteGuideForCreateOrderV2} returns this
 */
proto.vtv1.RouteGuideForCreateOrderV2.prototype.clearSalesOrder = function() {
  return this.setSalesOrder(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.RouteGuideForCreateOrderV2.prototype.hasSalesOrder = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional NullableString facility_code = 6;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.RouteGuideForCreateOrderV2.prototype.getFacilityCode = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableString, 6));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.RouteGuideForCreateOrderV2} returns this
*/
proto.vtv1.RouteGuideForCreateOrderV2.prototype.setFacilityCode = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.RouteGuideForCreateOrderV2} returns this
 */
proto.vtv1.RouteGuideForCreateOrderV2.prototype.clearFacilityCode = function() {
  return this.setFacilityCode(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.RouteGuideForCreateOrderV2.prototype.hasFacilityCode = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional bool am_i_shipper = 7;
 * @return {boolean}
 */
proto.vtv1.RouteGuideForCreateOrderV2.prototype.getAmIShipper = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vtv1.RouteGuideForCreateOrderV2} returns this
 */
proto.vtv1.RouteGuideForCreateOrderV2.prototype.setAmIShipper = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.RouteGuide.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.RouteGuide.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.RouteGuide.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.RouteGuide} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.RouteGuide.toObject = function(includeInstance, msg) {
  var f, obj = {
    siteId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    settings: (f = msg.getSettings()) && proto.vtv1.RouteGuideSettings.toObject(includeInstance, f),
    edgePrioritiesList: jspb.Message.toObjectList(msg.getEdgePrioritiesList(),
    proto.vtv1.RouteGuideEdgePriority.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.RouteGuide}
 */
proto.vtv1.RouteGuide.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.RouteGuide;
  return proto.vtv1.RouteGuide.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.RouteGuide} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.RouteGuide}
 */
proto.vtv1.RouteGuide.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSiteId(value);
      break;
    case 2:
      var value = new proto.vtv1.RouteGuideSettings;
      reader.readMessage(value,proto.vtv1.RouteGuideSettings.deserializeBinaryFromReader);
      msg.setSettings(value);
      break;
    case 3:
      var value = new proto.vtv1.RouteGuideEdgePriority;
      reader.readMessage(value,proto.vtv1.RouteGuideEdgePriority.deserializeBinaryFromReader);
      msg.addEdgePriorities(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.RouteGuide.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.RouteGuide.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.RouteGuide} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.RouteGuide.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSiteId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSettings();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.vtv1.RouteGuideSettings.serializeBinaryToWriter
    );
  }
  f = message.getEdgePrioritiesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.vtv1.RouteGuideEdgePriority.serializeBinaryToWriter
    );
  }
};


/**
 * optional string site_id = 1;
 * @return {string}
 */
proto.vtv1.RouteGuide.prototype.getSiteId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.RouteGuide} returns this
 */
proto.vtv1.RouteGuide.prototype.setSiteId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional RouteGuideSettings settings = 2;
 * @return {?proto.vtv1.RouteGuideSettings}
 */
proto.vtv1.RouteGuide.prototype.getSettings = function() {
  return /** @type{?proto.vtv1.RouteGuideSettings} */ (
    jspb.Message.getWrapperField(this, proto.vtv1.RouteGuideSettings, 2));
};


/**
 * @param {?proto.vtv1.RouteGuideSettings|undefined} value
 * @return {!proto.vtv1.RouteGuide} returns this
*/
proto.vtv1.RouteGuide.prototype.setSettings = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.RouteGuide} returns this
 */
proto.vtv1.RouteGuide.prototype.clearSettings = function() {
  return this.setSettings(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.RouteGuide.prototype.hasSettings = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated RouteGuideEdgePriority edge_priorities = 3;
 * @return {!Array<!proto.vtv1.RouteGuideEdgePriority>}
 */
proto.vtv1.RouteGuide.prototype.getEdgePrioritiesList = function() {
  return /** @type{!Array<!proto.vtv1.RouteGuideEdgePriority>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vtv1.RouteGuideEdgePriority, 3));
};


/**
 * @param {!Array<!proto.vtv1.RouteGuideEdgePriority>} value
 * @return {!proto.vtv1.RouteGuide} returns this
*/
proto.vtv1.RouteGuide.prototype.setEdgePrioritiesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.vtv1.RouteGuideEdgePriority=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.RouteGuideEdgePriority}
 */
proto.vtv1.RouteGuide.prototype.addEdgePriorities = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.vtv1.RouteGuideEdgePriority, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.RouteGuide} returns this
 */
proto.vtv1.RouteGuide.prototype.clearEdgePrioritiesList = function() {
  return this.setEdgePrioritiesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.RouteGuideUpsert.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.RouteGuideUpsert.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.RouteGuideUpsert.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.RouteGuideUpsert} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.RouteGuideUpsert.toObject = function(includeInstance, msg) {
  var f, obj = {
    data: (f = msg.getData()) && proto.vtv1.RouteGuideUpsertData.toObject(includeInstance, f),
    edgePrioritiesList: jspb.Message.toObjectList(msg.getEdgePrioritiesList(),
    proto.vtv1.RouteGuideEdgePriority.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.RouteGuideUpsert}
 */
proto.vtv1.RouteGuideUpsert.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.RouteGuideUpsert;
  return proto.vtv1.RouteGuideUpsert.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.RouteGuideUpsert} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.RouteGuideUpsert}
 */
proto.vtv1.RouteGuideUpsert.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.vtv1.RouteGuideUpsertData;
      reader.readMessage(value,proto.vtv1.RouteGuideUpsertData.deserializeBinaryFromReader);
      msg.setData(value);
      break;
    case 2:
      var value = new proto.vtv1.RouteGuideEdgePriority;
      reader.readMessage(value,proto.vtv1.RouteGuideEdgePriority.deserializeBinaryFromReader);
      msg.addEdgePriorities(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.RouteGuideUpsert.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.RouteGuideUpsert.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.RouteGuideUpsert} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.RouteGuideUpsert.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getData();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.vtv1.RouteGuideUpsertData.serializeBinaryToWriter
    );
  }
  f = message.getEdgePrioritiesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.vtv1.RouteGuideEdgePriority.serializeBinaryToWriter
    );
  }
};


/**
 * optional RouteGuideUpsertData data = 1;
 * @return {?proto.vtv1.RouteGuideUpsertData}
 */
proto.vtv1.RouteGuideUpsert.prototype.getData = function() {
  return /** @type{?proto.vtv1.RouteGuideUpsertData} */ (
    jspb.Message.getWrapperField(this, proto.vtv1.RouteGuideUpsertData, 1));
};


/**
 * @param {?proto.vtv1.RouteGuideUpsertData|undefined} value
 * @return {!proto.vtv1.RouteGuideUpsert} returns this
*/
proto.vtv1.RouteGuideUpsert.prototype.setData = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.RouteGuideUpsert} returns this
 */
proto.vtv1.RouteGuideUpsert.prototype.clearData = function() {
  return this.setData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.RouteGuideUpsert.prototype.hasData = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated RouteGuideEdgePriority edge_priorities = 2;
 * @return {!Array<!proto.vtv1.RouteGuideEdgePriority>}
 */
proto.vtv1.RouteGuideUpsert.prototype.getEdgePrioritiesList = function() {
  return /** @type{!Array<!proto.vtv1.RouteGuideEdgePriority>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vtv1.RouteGuideEdgePriority, 2));
};


/**
 * @param {!Array<!proto.vtv1.RouteGuideEdgePriority>} value
 * @return {!proto.vtv1.RouteGuideUpsert} returns this
*/
proto.vtv1.RouteGuideUpsert.prototype.setEdgePrioritiesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.vtv1.RouteGuideEdgePriority=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.RouteGuideEdgePriority}
 */
proto.vtv1.RouteGuideUpsert.prototype.addEdgePriorities = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.vtv1.RouteGuideEdgePriority, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.RouteGuideUpsert} returns this
 */
proto.vtv1.RouteGuideUpsert.prototype.clearEdgePrioritiesList = function() {
  return this.setEdgePrioritiesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.RouteGuideSettings.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.RouteGuideSettings.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.RouteGuideSettings} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.RouteGuideSettings.toObject = function(includeInstance, msg) {
  var f, obj = {
    assessDurationMinutes: jspb.Message.getFieldWithDefault(msg, 1, 0),
    allowNonPriorityEdgeContracts: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    spotEdgeContractId: (f = msg.getSpotEdgeContractId()) && proto_vorto_types_types_pb.NullableString.toObject(includeInstance, f),
    inbound: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    outbound: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    id: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.RouteGuideSettings}
 */
proto.vtv1.RouteGuideSettings.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.RouteGuideSettings;
  return proto.vtv1.RouteGuideSettings.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.RouteGuideSettings} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.RouteGuideSettings}
 */
proto.vtv1.RouteGuideSettings.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAssessDurationMinutes(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAllowNonPriorityEdgeContracts(value);
      break;
    case 3:
      var value = new proto_vorto_types_types_pb.NullableString;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableString.deserializeBinaryFromReader);
      msg.setSpotEdgeContractId(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setInbound(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOutbound(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.RouteGuideSettings.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.RouteGuideSettings.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.RouteGuideSettings} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.RouteGuideSettings.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAssessDurationMinutes();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getAllowNonPriorityEdgeContracts();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getSpotEdgeContractId();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto_vorto_types_types_pb.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getInbound();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getOutbound();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
};


/**
 * optional int64 assess_duration_minutes = 1;
 * @return {number}
 */
proto.vtv1.RouteGuideSettings.prototype.getAssessDurationMinutes = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.RouteGuideSettings} returns this
 */
proto.vtv1.RouteGuideSettings.prototype.setAssessDurationMinutes = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional bool allow_non_priority_edge_contracts = 2;
 * @return {boolean}
 */
proto.vtv1.RouteGuideSettings.prototype.getAllowNonPriorityEdgeContracts = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vtv1.RouteGuideSettings} returns this
 */
proto.vtv1.RouteGuideSettings.prototype.setAllowNonPriorityEdgeContracts = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional NullableString spot_edge_contract_id = 3;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.RouteGuideSettings.prototype.getSpotEdgeContractId = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableString, 3));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.RouteGuideSettings} returns this
*/
proto.vtv1.RouteGuideSettings.prototype.setSpotEdgeContractId = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.RouteGuideSettings} returns this
 */
proto.vtv1.RouteGuideSettings.prototype.clearSpotEdgeContractId = function() {
  return this.setSpotEdgeContractId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.RouteGuideSettings.prototype.hasSpotEdgeContractId = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional bool inbound = 4;
 * @return {boolean}
 */
proto.vtv1.RouteGuideSettings.prototype.getInbound = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vtv1.RouteGuideSettings} returns this
 */
proto.vtv1.RouteGuideSettings.prototype.setInbound = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional bool outbound = 5;
 * @return {boolean}
 */
proto.vtv1.RouteGuideSettings.prototype.getOutbound = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vtv1.RouteGuideSettings} returns this
 */
proto.vtv1.RouteGuideSettings.prototype.setOutbound = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional int64 id = 6;
 * @return {number}
 */
proto.vtv1.RouteGuideSettings.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.RouteGuideSettings} returns this
 */
proto.vtv1.RouteGuideSettings.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.RouteGuideEdgePriority.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.RouteGuideEdgePriority.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.RouteGuideEdgePriority} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.RouteGuideEdgePriority.toObject = function(includeInstance, msg) {
  var f, obj = {
    edgeId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    edgeName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    edgePriority: jspb.Message.getFieldWithDefault(msg, 3, 0),
    currentShipmentCount: jspb.Message.getFieldWithDefault(msg, 4, 0),
    minMiles: (f = msg.getMinMiles()) && proto_vorto_types_types_pb.NullableInt64.toObject(includeInstance, f),
    maxMiles: (f = msg.getMaxMiles()) && proto_vorto_types_types_pb.NullableInt64.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.RouteGuideEdgePriority}
 */
proto.vtv1.RouteGuideEdgePriority.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.RouteGuideEdgePriority;
  return proto.vtv1.RouteGuideEdgePriority.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.RouteGuideEdgePriority} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.RouteGuideEdgePriority}
 */
proto.vtv1.RouteGuideEdgePriority.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEdgeId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setEdgeName(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setEdgePriority(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCurrentShipmentCount(value);
      break;
    case 5:
      var value = new proto_vorto_types_types_pb.NullableInt64;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableInt64.deserializeBinaryFromReader);
      msg.setMinMiles(value);
      break;
    case 6:
      var value = new proto_vorto_types_types_pb.NullableInt64;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableInt64.deserializeBinaryFromReader);
      msg.setMaxMiles(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.RouteGuideEdgePriority.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.RouteGuideEdgePriority.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.RouteGuideEdgePriority} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.RouteGuideEdgePriority.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEdgeId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEdgeName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getEdgePriority();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getCurrentShipmentCount();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getMinMiles();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto_vorto_types_types_pb.NullableInt64.serializeBinaryToWriter
    );
  }
  f = message.getMaxMiles();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto_vorto_types_types_pb.NullableInt64.serializeBinaryToWriter
    );
  }
};


/**
 * optional string edge_id = 1;
 * @return {string}
 */
proto.vtv1.RouteGuideEdgePriority.prototype.getEdgeId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.RouteGuideEdgePriority} returns this
 */
proto.vtv1.RouteGuideEdgePriority.prototype.setEdgeId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string edge_name = 2;
 * @return {string}
 */
proto.vtv1.RouteGuideEdgePriority.prototype.getEdgeName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.RouteGuideEdgePriority} returns this
 */
proto.vtv1.RouteGuideEdgePriority.prototype.setEdgeName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 edge_priority = 3;
 * @return {number}
 */
proto.vtv1.RouteGuideEdgePriority.prototype.getEdgePriority = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.RouteGuideEdgePriority} returns this
 */
proto.vtv1.RouteGuideEdgePriority.prototype.setEdgePriority = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 current_shipment_count = 4;
 * @return {number}
 */
proto.vtv1.RouteGuideEdgePriority.prototype.getCurrentShipmentCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.RouteGuideEdgePriority} returns this
 */
proto.vtv1.RouteGuideEdgePriority.prototype.setCurrentShipmentCount = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional NullableInt64 min_miles = 5;
 * @return {?proto.vtv1.NullableInt64}
 */
proto.vtv1.RouteGuideEdgePriority.prototype.getMinMiles = function() {
  return /** @type{?proto.vtv1.NullableInt64} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableInt64, 5));
};


/**
 * @param {?proto.vtv1.NullableInt64|undefined} value
 * @return {!proto.vtv1.RouteGuideEdgePriority} returns this
*/
proto.vtv1.RouteGuideEdgePriority.prototype.setMinMiles = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.RouteGuideEdgePriority} returns this
 */
proto.vtv1.RouteGuideEdgePriority.prototype.clearMinMiles = function() {
  return this.setMinMiles(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.RouteGuideEdgePriority.prototype.hasMinMiles = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional NullableInt64 max_miles = 6;
 * @return {?proto.vtv1.NullableInt64}
 */
proto.vtv1.RouteGuideEdgePriority.prototype.getMaxMiles = function() {
  return /** @type{?proto.vtv1.NullableInt64} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableInt64, 6));
};


/**
 * @param {?proto.vtv1.NullableInt64|undefined} value
 * @return {!proto.vtv1.RouteGuideEdgePriority} returns this
*/
proto.vtv1.RouteGuideEdgePriority.prototype.setMaxMiles = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.RouteGuideEdgePriority} returns this
 */
proto.vtv1.RouteGuideEdgePriority.prototype.clearMaxMiles = function() {
  return this.setMaxMiles(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.RouteGuideEdgePriority.prototype.hasMaxMiles = function() {
  return jspb.Message.getField(this, 6) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.RouteGuideEdge.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.RouteGuideEdge.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.RouteGuideEdge} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.RouteGuideEdge.toObject = function(includeInstance, msg) {
  var f, obj = {
    edgeId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    edgeName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    inboundContracts: jspb.Message.getFieldWithDefault(msg, 3, 0),
    outboundContracts: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.RouteGuideEdge}
 */
proto.vtv1.RouteGuideEdge.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.RouteGuideEdge;
  return proto.vtv1.RouteGuideEdge.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.RouteGuideEdge} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.RouteGuideEdge}
 */
proto.vtv1.RouteGuideEdge.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEdgeId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setEdgeName(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setInboundContracts(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setOutboundContracts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.RouteGuideEdge.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.RouteGuideEdge.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.RouteGuideEdge} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.RouteGuideEdge.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEdgeId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEdgeName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getInboundContracts();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getOutboundContracts();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
};


/**
 * optional string edge_id = 1;
 * @return {string}
 */
proto.vtv1.RouteGuideEdge.prototype.getEdgeId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.RouteGuideEdge} returns this
 */
proto.vtv1.RouteGuideEdge.prototype.setEdgeId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string edge_name = 2;
 * @return {string}
 */
proto.vtv1.RouteGuideEdge.prototype.getEdgeName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.RouteGuideEdge} returns this
 */
proto.vtv1.RouteGuideEdge.prototype.setEdgeName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 inbound_contracts = 3;
 * @return {number}
 */
proto.vtv1.RouteGuideEdge.prototype.getInboundContracts = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.RouteGuideEdge} returns this
 */
proto.vtv1.RouteGuideEdge.prototype.setInboundContracts = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 outbound_contracts = 4;
 * @return {number}
 */
proto.vtv1.RouteGuideEdge.prototype.getOutboundContracts = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.RouteGuideEdge} returns this
 */
proto.vtv1.RouteGuideEdge.prototype.setOutboundContracts = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.RouteGuideSpotEdgeContract.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.RouteGuideSpotEdgeContract.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.RouteGuideSpotEdgeContract} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.RouteGuideSpotEdgeContract.toObject = function(includeInstance, msg) {
  var f, obj = {
    edgeContractId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    edgeContractName: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.RouteGuideSpotEdgeContract}
 */
proto.vtv1.RouteGuideSpotEdgeContract.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.RouteGuideSpotEdgeContract;
  return proto.vtv1.RouteGuideSpotEdgeContract.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.RouteGuideSpotEdgeContract} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.RouteGuideSpotEdgeContract}
 */
proto.vtv1.RouteGuideSpotEdgeContract.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEdgeContractId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setEdgeContractName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.RouteGuideSpotEdgeContract.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.RouteGuideSpotEdgeContract.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.RouteGuideSpotEdgeContract} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.RouteGuideSpotEdgeContract.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEdgeContractId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEdgeContractName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string edge_contract_id = 1;
 * @return {string}
 */
proto.vtv1.RouteGuideSpotEdgeContract.prototype.getEdgeContractId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.RouteGuideSpotEdgeContract} returns this
 */
proto.vtv1.RouteGuideSpotEdgeContract.prototype.setEdgeContractId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string edge_contract_name = 2;
 * @return {string}
 */
proto.vtv1.RouteGuideSpotEdgeContract.prototype.getEdgeContractName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.RouteGuideSpotEdgeContract} returns this
 */
proto.vtv1.RouteGuideSpotEdgeContract.prototype.setEdgeContractName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.RouteGuideUpsertData.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.RouteGuideUpsertData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.RouteGuideUpsertData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.RouteGuideUpsertData.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && proto_vorto_types_types_pb.NullableInt64.toObject(includeInstance, f),
    assessDurationMinutes: jspb.Message.getFieldWithDefault(msg, 2, 0),
    allowNonPriorityEdgeContracts: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    spotEdgeContractId: (f = msg.getSpotEdgeContractId()) && proto_vorto_types_types_pb.NullableString.toObject(includeInstance, f),
    inbound: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    outbound: jspb.Message.getBooleanFieldWithDefault(msg, 6, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.RouteGuideUpsertData}
 */
proto.vtv1.RouteGuideUpsertData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.RouteGuideUpsertData;
  return proto.vtv1.RouteGuideUpsertData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.RouteGuideUpsertData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.RouteGuideUpsertData}
 */
proto.vtv1.RouteGuideUpsertData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_vorto_types_types_pb.NullableInt64;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableInt64.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAssessDurationMinutes(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAllowNonPriorityEdgeContracts(value);
      break;
    case 4:
      var value = new proto_vorto_types_types_pb.NullableString;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableString.deserializeBinaryFromReader);
      msg.setSpotEdgeContractId(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setInbound(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOutbound(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.RouteGuideUpsertData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.RouteGuideUpsertData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.RouteGuideUpsertData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.RouteGuideUpsertData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_vorto_types_types_pb.NullableInt64.serializeBinaryToWriter
    );
  }
  f = message.getAssessDurationMinutes();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getAllowNonPriorityEdgeContracts();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getSpotEdgeContractId();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto_vorto_types_types_pb.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getInbound();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getOutbound();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
};


/**
 * optional NullableInt64 id = 1;
 * @return {?proto.vtv1.NullableInt64}
 */
proto.vtv1.RouteGuideUpsertData.prototype.getId = function() {
  return /** @type{?proto.vtv1.NullableInt64} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableInt64, 1));
};


/**
 * @param {?proto.vtv1.NullableInt64|undefined} value
 * @return {!proto.vtv1.RouteGuideUpsertData} returns this
*/
proto.vtv1.RouteGuideUpsertData.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.RouteGuideUpsertData} returns this
 */
proto.vtv1.RouteGuideUpsertData.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.RouteGuideUpsertData.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int64 assess_duration_minutes = 2;
 * @return {number}
 */
proto.vtv1.RouteGuideUpsertData.prototype.getAssessDurationMinutes = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.RouteGuideUpsertData} returns this
 */
proto.vtv1.RouteGuideUpsertData.prototype.setAssessDurationMinutes = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional bool allow_non_priority_edge_contracts = 3;
 * @return {boolean}
 */
proto.vtv1.RouteGuideUpsertData.prototype.getAllowNonPriorityEdgeContracts = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vtv1.RouteGuideUpsertData} returns this
 */
proto.vtv1.RouteGuideUpsertData.prototype.setAllowNonPriorityEdgeContracts = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional NullableString spot_edge_contract_id = 4;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.RouteGuideUpsertData.prototype.getSpotEdgeContractId = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableString, 4));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.RouteGuideUpsertData} returns this
*/
proto.vtv1.RouteGuideUpsertData.prototype.setSpotEdgeContractId = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.RouteGuideUpsertData} returns this
 */
proto.vtv1.RouteGuideUpsertData.prototype.clearSpotEdgeContractId = function() {
  return this.setSpotEdgeContractId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.RouteGuideUpsertData.prototype.hasSpotEdgeContractId = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional bool inbound = 5;
 * @return {boolean}
 */
proto.vtv1.RouteGuideUpsertData.prototype.getInbound = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vtv1.RouteGuideUpsertData} returns this
 */
proto.vtv1.RouteGuideUpsertData.prototype.setInbound = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional bool outbound = 6;
 * @return {boolean}
 */
proto.vtv1.RouteGuideUpsertData.prototype.getOutbound = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vtv1.RouteGuideUpsertData} returns this
 */
proto.vtv1.RouteGuideUpsertData.prototype.setOutbound = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.SlimSiteShipments.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.SlimSiteShipments.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.SlimSiteShipments} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.SlimSiteShipments.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    siteGroup: (f = msg.getSiteGroup()) && proto_vorto_types_types_pb.NullableString.toObject(includeInstance, f),
    siteName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    remainingShipments: jspb.Message.getFieldWithDefault(msg, 4, 0),
    enRouteShipments: jspb.Message.getFieldWithDefault(msg, 5, 0),
    onSiteShipments: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.SlimSiteShipments}
 */
proto.vtv1.SlimSiteShipments.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.SlimSiteShipments;
  return proto.vtv1.SlimSiteShipments.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.SlimSiteShipments} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.SlimSiteShipments}
 */
proto.vtv1.SlimSiteShipments.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new proto_vorto_types_types_pb.NullableString;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableString.deserializeBinaryFromReader);
      msg.setSiteGroup(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSiteName(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRemainingShipments(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setEnRouteShipments(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setOnSiteShipments(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.SlimSiteShipments.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.SlimSiteShipments.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.SlimSiteShipments} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.SlimSiteShipments.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSiteGroup();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_vorto_types_types_pb.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getSiteName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getRemainingShipments();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getEnRouteShipments();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getOnSiteShipments();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.vtv1.SlimSiteShipments.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.SlimSiteShipments} returns this
 */
proto.vtv1.SlimSiteShipments.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional NullableString site_group = 2;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.SlimSiteShipments.prototype.getSiteGroup = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableString, 2));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.SlimSiteShipments} returns this
*/
proto.vtv1.SlimSiteShipments.prototype.setSiteGroup = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.SlimSiteShipments} returns this
 */
proto.vtv1.SlimSiteShipments.prototype.clearSiteGroup = function() {
  return this.setSiteGroup(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.SlimSiteShipments.prototype.hasSiteGroup = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string site_name = 3;
 * @return {string}
 */
proto.vtv1.SlimSiteShipments.prototype.getSiteName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.SlimSiteShipments} returns this
 */
proto.vtv1.SlimSiteShipments.prototype.setSiteName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int64 remaining_shipments = 4;
 * @return {number}
 */
proto.vtv1.SlimSiteShipments.prototype.getRemainingShipments = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.SlimSiteShipments} returns this
 */
proto.vtv1.SlimSiteShipments.prototype.setRemainingShipments = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int64 en_route_shipments = 5;
 * @return {number}
 */
proto.vtv1.SlimSiteShipments.prototype.getEnRouteShipments = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.SlimSiteShipments} returns this
 */
proto.vtv1.SlimSiteShipments.prototype.setEnRouteShipments = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int64 on_site_shipments = 6;
 * @return {number}
 */
proto.vtv1.SlimSiteShipments.prototype.getOnSiteShipments = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.SlimSiteShipments} returns this
 */
proto.vtv1.SlimSiteShipments.prototype.setOnSiteShipments = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.UpdateMultipleInventoryStock.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.UpdateMultipleInventoryStock.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.UpdateMultipleInventoryStock} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.UpdateMultipleInventoryStock.toObject = function(includeInstance, msg) {
  var f, obj = {
    inventoryId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    stock: jspb.Message.getFieldWithDefault(msg, 2, 0),
    reloadWindow: (f = msg.getReloadWindow()) && proto_vorto_types_types_pb.NullableInt64.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.UpdateMultipleInventoryStock}
 */
proto.vtv1.UpdateMultipleInventoryStock.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.UpdateMultipleInventoryStock;
  return proto.vtv1.UpdateMultipleInventoryStock.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.UpdateMultipleInventoryStock} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.UpdateMultipleInventoryStock}
 */
proto.vtv1.UpdateMultipleInventoryStock.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setInventoryId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStock(value);
      break;
    case 3:
      var value = new proto_vorto_types_types_pb.NullableInt64;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableInt64.deserializeBinaryFromReader);
      msg.setReloadWindow(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.UpdateMultipleInventoryStock.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.UpdateMultipleInventoryStock.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.UpdateMultipleInventoryStock} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.UpdateMultipleInventoryStock.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInventoryId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStock();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getReloadWindow();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto_vorto_types_types_pb.NullableInt64.serializeBinaryToWriter
    );
  }
};


/**
 * optional string inventory_id = 1;
 * @return {string}
 */
proto.vtv1.UpdateMultipleInventoryStock.prototype.getInventoryId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.UpdateMultipleInventoryStock} returns this
 */
proto.vtv1.UpdateMultipleInventoryStock.prototype.setInventoryId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 stock = 2;
 * @return {number}
 */
proto.vtv1.UpdateMultipleInventoryStock.prototype.getStock = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.UpdateMultipleInventoryStock} returns this
 */
proto.vtv1.UpdateMultipleInventoryStock.prototype.setStock = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional NullableInt64 reload_window = 3;
 * @return {?proto.vtv1.NullableInt64}
 */
proto.vtv1.UpdateMultipleInventoryStock.prototype.getReloadWindow = function() {
  return /** @type{?proto.vtv1.NullableInt64} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableInt64, 3));
};


/**
 * @param {?proto.vtv1.NullableInt64|undefined} value
 * @return {!proto.vtv1.UpdateMultipleInventoryStock} returns this
*/
proto.vtv1.UpdateMultipleInventoryStock.prototype.setReloadWindow = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.UpdateMultipleInventoryStock} returns this
 */
proto.vtv1.UpdateMultipleInventoryStock.prototype.clearReloadWindow = function() {
  return this.setReloadWindow(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.UpdateMultipleInventoryStock.prototype.hasReloadWindow = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.SiteWithStock.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.SiteWithStock.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.SiteWithStock.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.SiteWithStock} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.SiteWithStock.toObject = function(includeInstance, msg) {
  var f, obj = {
    siteName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    siteId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    inventoriesList: jspb.Message.toObjectList(msg.getInventoriesList(),
    proto.vtv1.InventoryWithStock.toObject, includeInstance),
    regionName: (f = msg.getRegionName()) && proto_vorto_types_types_pb.NullableString.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.SiteWithStock}
 */
proto.vtv1.SiteWithStock.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.SiteWithStock;
  return proto.vtv1.SiteWithStock.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.SiteWithStock} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.SiteWithStock}
 */
proto.vtv1.SiteWithStock.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSiteName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSiteId(value);
      break;
    case 3:
      var value = new proto.vtv1.InventoryWithStock;
      reader.readMessage(value,proto.vtv1.InventoryWithStock.deserializeBinaryFromReader);
      msg.addInventories(value);
      break;
    case 4:
      var value = new proto_vorto_types_types_pb.NullableString;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableString.deserializeBinaryFromReader);
      msg.setRegionName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.SiteWithStock.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.SiteWithStock.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.SiteWithStock} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.SiteWithStock.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSiteName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSiteId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getInventoriesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.vtv1.InventoryWithStock.serializeBinaryToWriter
    );
  }
  f = message.getRegionName();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto_vorto_types_types_pb.NullableString.serializeBinaryToWriter
    );
  }
};


/**
 * optional string site_name = 1;
 * @return {string}
 */
proto.vtv1.SiteWithStock.prototype.getSiteName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.SiteWithStock} returns this
 */
proto.vtv1.SiteWithStock.prototype.setSiteName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string site_id = 2;
 * @return {string}
 */
proto.vtv1.SiteWithStock.prototype.getSiteId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.SiteWithStock} returns this
 */
proto.vtv1.SiteWithStock.prototype.setSiteId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated InventoryWithStock inventories = 3;
 * @return {!Array<!proto.vtv1.InventoryWithStock>}
 */
proto.vtv1.SiteWithStock.prototype.getInventoriesList = function() {
  return /** @type{!Array<!proto.vtv1.InventoryWithStock>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vtv1.InventoryWithStock, 3));
};


/**
 * @param {!Array<!proto.vtv1.InventoryWithStock>} value
 * @return {!proto.vtv1.SiteWithStock} returns this
*/
proto.vtv1.SiteWithStock.prototype.setInventoriesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.vtv1.InventoryWithStock=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.InventoryWithStock}
 */
proto.vtv1.SiteWithStock.prototype.addInventories = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.vtv1.InventoryWithStock, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.SiteWithStock} returns this
 */
proto.vtv1.SiteWithStock.prototype.clearInventoriesList = function() {
  return this.setInventoriesList([]);
};


/**
 * optional NullableString region_name = 4;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.SiteWithStock.prototype.getRegionName = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableString, 4));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.SiteWithStock} returns this
*/
proto.vtv1.SiteWithStock.prototype.setRegionName = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.SiteWithStock} returns this
 */
proto.vtv1.SiteWithStock.prototype.clearRegionName = function() {
  return this.setRegionName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.SiteWithStock.prototype.hasRegionName = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.InventoryWithStock.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.InventoryWithStock.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.InventoryWithStock} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.InventoryWithStock.toObject = function(includeInstance, msg) {
  var f, obj = {
    siteId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    siteName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    inventoryId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    inventoryName: jspb.Message.getFieldWithDefault(msg, 4, ""),
    productName: jspb.Message.getFieldWithDefault(msg, 5, ""),
    productUnit: jspb.Message.getFieldWithDefault(msg, 6, ""),
    stock: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    inbound: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    outbound: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
    autoRestock: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
    autoOrder: jspb.Message.getBooleanFieldWithDefault(msg, 11, false),
    capacity: jspb.Message.getFloatingPointFieldWithDefault(msg, 12, 0.0),
    reloadWindow: (f = msg.getReloadWindow()) && proto_vorto_types_types_pb.NullableInt64.toObject(includeInstance, f),
    addBackhaul: jspb.Message.getBooleanFieldWithDefault(msg, 14, false),
    leadTimeHours: (f = msg.getLeadTimeHours()) && proto_vorto_types_types_pb.NullableInt64.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.InventoryWithStock}
 */
proto.vtv1.InventoryWithStock.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.InventoryWithStock;
  return proto.vtv1.InventoryWithStock.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.InventoryWithStock} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.InventoryWithStock}
 */
proto.vtv1.InventoryWithStock.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSiteId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSiteName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setInventoryId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setInventoryName(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductName(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductUnit(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setStock(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setInbound(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOutbound(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAutoRestock(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAutoOrder(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setCapacity(value);
      break;
    case 13:
      var value = new proto_vorto_types_types_pb.NullableInt64;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableInt64.deserializeBinaryFromReader);
      msg.setReloadWindow(value);
      break;
    case 14:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAddBackhaul(value);
      break;
    case 15:
      var value = new proto_vorto_types_types_pb.NullableInt64;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableInt64.deserializeBinaryFromReader);
      msg.setLeadTimeHours(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.InventoryWithStock.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.InventoryWithStock.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.InventoryWithStock} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.InventoryWithStock.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSiteId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSiteName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getInventoryId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getInventoryName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getProductName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getProductUnit();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getStock();
  if (f !== 0.0) {
    writer.writeDouble(
      7,
      f
    );
  }
  f = message.getInbound();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getOutbound();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getAutoRestock();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
  f = message.getAutoOrder();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
  f = message.getCapacity();
  if (f !== 0.0) {
    writer.writeDouble(
      12,
      f
    );
  }
  f = message.getReloadWindow();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      proto_vorto_types_types_pb.NullableInt64.serializeBinaryToWriter
    );
  }
  f = message.getAddBackhaul();
  if (f) {
    writer.writeBool(
      14,
      f
    );
  }
  f = message.getLeadTimeHours();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      proto_vorto_types_types_pb.NullableInt64.serializeBinaryToWriter
    );
  }
};


/**
 * optional string site_id = 1;
 * @return {string}
 */
proto.vtv1.InventoryWithStock.prototype.getSiteId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.InventoryWithStock} returns this
 */
proto.vtv1.InventoryWithStock.prototype.setSiteId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string site_name = 2;
 * @return {string}
 */
proto.vtv1.InventoryWithStock.prototype.getSiteName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.InventoryWithStock} returns this
 */
proto.vtv1.InventoryWithStock.prototype.setSiteName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string inventory_id = 3;
 * @return {string}
 */
proto.vtv1.InventoryWithStock.prototype.getInventoryId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.InventoryWithStock} returns this
 */
proto.vtv1.InventoryWithStock.prototype.setInventoryId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string inventory_name = 4;
 * @return {string}
 */
proto.vtv1.InventoryWithStock.prototype.getInventoryName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.InventoryWithStock} returns this
 */
proto.vtv1.InventoryWithStock.prototype.setInventoryName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string product_name = 5;
 * @return {string}
 */
proto.vtv1.InventoryWithStock.prototype.getProductName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.InventoryWithStock} returns this
 */
proto.vtv1.InventoryWithStock.prototype.setProductName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string product_unit = 6;
 * @return {string}
 */
proto.vtv1.InventoryWithStock.prototype.getProductUnit = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.InventoryWithStock} returns this
 */
proto.vtv1.InventoryWithStock.prototype.setProductUnit = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional double stock = 7;
 * @return {number}
 */
proto.vtv1.InventoryWithStock.prototype.getStock = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.InventoryWithStock} returns this
 */
proto.vtv1.InventoryWithStock.prototype.setStock = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional bool inbound = 8;
 * @return {boolean}
 */
proto.vtv1.InventoryWithStock.prototype.getInbound = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vtv1.InventoryWithStock} returns this
 */
proto.vtv1.InventoryWithStock.prototype.setInbound = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional bool outbound = 9;
 * @return {boolean}
 */
proto.vtv1.InventoryWithStock.prototype.getOutbound = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vtv1.InventoryWithStock} returns this
 */
proto.vtv1.InventoryWithStock.prototype.setOutbound = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};


/**
 * optional bool auto_restock = 10;
 * @return {boolean}
 */
proto.vtv1.InventoryWithStock.prototype.getAutoRestock = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vtv1.InventoryWithStock} returns this
 */
proto.vtv1.InventoryWithStock.prototype.setAutoRestock = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};


/**
 * optional bool auto_order = 11;
 * @return {boolean}
 */
proto.vtv1.InventoryWithStock.prototype.getAutoOrder = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vtv1.InventoryWithStock} returns this
 */
proto.vtv1.InventoryWithStock.prototype.setAutoOrder = function(value) {
  return jspb.Message.setProto3BooleanField(this, 11, value);
};


/**
 * optional double capacity = 12;
 * @return {number}
 */
proto.vtv1.InventoryWithStock.prototype.getCapacity = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 12, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.InventoryWithStock} returns this
 */
proto.vtv1.InventoryWithStock.prototype.setCapacity = function(value) {
  return jspb.Message.setProto3FloatField(this, 12, value);
};


/**
 * optional NullableInt64 reload_window = 13;
 * @return {?proto.vtv1.NullableInt64}
 */
proto.vtv1.InventoryWithStock.prototype.getReloadWindow = function() {
  return /** @type{?proto.vtv1.NullableInt64} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableInt64, 13));
};


/**
 * @param {?proto.vtv1.NullableInt64|undefined} value
 * @return {!proto.vtv1.InventoryWithStock} returns this
*/
proto.vtv1.InventoryWithStock.prototype.setReloadWindow = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.InventoryWithStock} returns this
 */
proto.vtv1.InventoryWithStock.prototype.clearReloadWindow = function() {
  return this.setReloadWindow(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.InventoryWithStock.prototype.hasReloadWindow = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional bool add_backhaul = 14;
 * @return {boolean}
 */
proto.vtv1.InventoryWithStock.prototype.getAddBackhaul = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 14, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vtv1.InventoryWithStock} returns this
 */
proto.vtv1.InventoryWithStock.prototype.setAddBackhaul = function(value) {
  return jspb.Message.setProto3BooleanField(this, 14, value);
};


/**
 * optional NullableInt64 lead_time_hours = 15;
 * @return {?proto.vtv1.NullableInt64}
 */
proto.vtv1.InventoryWithStock.prototype.getLeadTimeHours = function() {
  return /** @type{?proto.vtv1.NullableInt64} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableInt64, 15));
};


/**
 * @param {?proto.vtv1.NullableInt64|undefined} value
 * @return {!proto.vtv1.InventoryWithStock} returns this
*/
proto.vtv1.InventoryWithStock.prototype.setLeadTimeHours = function(value) {
  return jspb.Message.setWrapperField(this, 15, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.InventoryWithStock} returns this
 */
proto.vtv1.InventoryWithStock.prototype.clearLeadTimeHours = function() {
  return this.setLeadTimeHours(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.InventoryWithStock.prototype.hasLeadTimeHours = function() {
  return jspb.Message.getField(this, 15) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.InventoryListItem.repeatedFields_ = [11,14,15,16];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.InventoryListItem.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.InventoryListItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.InventoryListItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.InventoryListItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    inventoryId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    inventoryName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    productId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    productName: jspb.Message.getFieldWithDefault(msg, 4, ""),
    siteId: jspb.Message.getFieldWithDefault(msg, 5, ""),
    siteName: jspb.Message.getFieldWithDefault(msg, 6, ""),
    region: jspb.Message.getFieldWithDefault(msg, 7, ""),
    stock: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
    capacity: jspb.Message.getFloatingPointFieldWithDefault(msg, 9, 0.0),
    stockOutTime: (f = msg.getStockOutTime()) && proto_vorto_types_types_pb.NullableTime.toObject(includeInstance, f),
    loadsNeedingAttentionList: (f = jspb.Message.getRepeatedField(msg, 11)) == null ? undefined : f,
    averageDwellTime: (f = msg.getAverageDwellTime()) && proto_vorto_types_types_pb.NullableDouble.toObject(includeInstance, f),
    loadsReceivingToday: jspb.Message.getFieldWithDefault(msg, 13, 0),
    inventoryStockOutRiskIdsList: (f = jspb.Message.getRepeatedField(msg, 14)) == null ? undefined : f,
    detentionPerDayList: (f = jspb.Message.getRepeatedField(msg, 15)) == null ? undefined : f,
    optimalLoadStatsList: jspb.Message.toObjectList(msg.getOptimalLoadStatsList(),
    proto_vorto_types_types_pb.OptimalLoadsStats.toObject, includeInstance),
    lastStockOutDay: (f = msg.getLastStockOutDay()) && proto_vorto_types_types_pb.NullableString.toObject(includeInstance, f),
    restockStrategy: jspb.Message.getFieldWithDefault(msg, 18, 0),
    rollingDetentionAverage: jspb.Message.getFieldWithDefault(msg, 19, 0),
    updatedAt: (f = msg.getUpdatedAt()) && proto_vorto_types_types_pb.NullableTime.toObject(includeInstance, f),
    updatedBy: (f = msg.getUpdatedBy()) && proto_vorto_types_types_pb.NullableString.toObject(includeInstance, f),
    unitAbbreviation: jspb.Message.getFieldWithDefault(msg, 22, ""),
    trucksEnRoute: (f = msg.getTrucksEnRoute()) && proto_vorto_types_types_pb.NullableInt64.toObject(includeInstance, f),
    upcomingStockDrop: (f = msg.getUpcomingStockDrop()) && proto_vorto_types_types_pb.NullableDouble.toObject(includeInstance, f),
    stockUpdatedAt: (f = msg.getStockUpdatedAt()) && proto_vorto_types_types_pb.NullableTime.toObject(includeInstance, f),
    stockUpdatedById: (f = msg.getStockUpdatedById()) && proto_vorto_types_types_pb.NullableString.toObject(includeInstance, f),
    stockUpdatedByName: (f = msg.getStockUpdatedByName()) && proto_vorto_types_types_pb.NullableString.toObject(includeInstance, f),
    isInbound: jspb.Message.getBooleanFieldWithDefault(msg, 28, false),
    isOutbound: jspb.Message.getBooleanFieldWithDefault(msg, 29, false),
    hasNoInventories: jspb.Message.getBooleanFieldWithDefault(msg, 30, false),
    siteDownAt: (f = msg.getSiteDownAt()) && proto_vorto_types_types_pb.NullableTime.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.InventoryListItem}
 */
proto.vtv1.InventoryListItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.InventoryListItem;
  return proto.vtv1.InventoryListItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.InventoryListItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.InventoryListItem}
 */
proto.vtv1.InventoryListItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setInventoryId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setInventoryName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductName(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setSiteId(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setSiteName(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setRegion(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setStock(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setCapacity(value);
      break;
    case 10:
      var value = new proto_vorto_types_types_pb.NullableTime;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableTime.deserializeBinaryFromReader);
      msg.setStockOutTime(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.addLoadsNeedingAttention(value);
      break;
    case 12:
      var value = new proto_vorto_types_types_pb.NullableDouble;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableDouble.deserializeBinaryFromReader);
      msg.setAverageDwellTime(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLoadsReceivingToday(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.addInventoryStockOutRiskIds(value);
      break;
    case 15:
      var value = /** @type {!Array<number>} */ (reader.readPackedInt64());
      msg.setDetentionPerDayList(value);
      break;
    case 16:
      var value = new proto_vorto_types_types_pb.OptimalLoadsStats;
      reader.readMessage(value,proto_vorto_types_types_pb.OptimalLoadsStats.deserializeBinaryFromReader);
      msg.addOptimalLoadStats(value);
      break;
    case 17:
      var value = new proto_vorto_types_types_pb.NullableString;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableString.deserializeBinaryFromReader);
      msg.setLastStockOutDay(value);
      break;
    case 18:
      var value = /** @type {!proto.vtv1.RestockStrategy} */ (reader.readEnum());
      msg.setRestockStrategy(value);
      break;
    case 19:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRollingDetentionAverage(value);
      break;
    case 20:
      var value = new proto_vorto_types_types_pb.NullableTime;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableTime.deserializeBinaryFromReader);
      msg.setUpdatedAt(value);
      break;
    case 21:
      var value = new proto_vorto_types_types_pb.NullableString;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableString.deserializeBinaryFromReader);
      msg.setUpdatedBy(value);
      break;
    case 22:
      var value = /** @type {string} */ (reader.readString());
      msg.setUnitAbbreviation(value);
      break;
    case 23:
      var value = new proto_vorto_types_types_pb.NullableInt64;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableInt64.deserializeBinaryFromReader);
      msg.setTrucksEnRoute(value);
      break;
    case 24:
      var value = new proto_vorto_types_types_pb.NullableDouble;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableDouble.deserializeBinaryFromReader);
      msg.setUpcomingStockDrop(value);
      break;
    case 25:
      var value = new proto_vorto_types_types_pb.NullableTime;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableTime.deserializeBinaryFromReader);
      msg.setStockUpdatedAt(value);
      break;
    case 26:
      var value = new proto_vorto_types_types_pb.NullableString;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableString.deserializeBinaryFromReader);
      msg.setStockUpdatedById(value);
      break;
    case 27:
      var value = new proto_vorto_types_types_pb.NullableString;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableString.deserializeBinaryFromReader);
      msg.setStockUpdatedByName(value);
      break;
    case 28:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsInbound(value);
      break;
    case 29:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsOutbound(value);
      break;
    case 30:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasNoInventories(value);
      break;
    case 31:
      var value = new proto_vorto_types_types_pb.NullableTime;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableTime.deserializeBinaryFromReader);
      msg.setSiteDownAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.InventoryListItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.InventoryListItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.InventoryListItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.InventoryListItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInventoryId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getInventoryName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getProductId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getProductName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getSiteId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getSiteName();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getRegion();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getStock();
  if (f !== 0.0) {
    writer.writeDouble(
      8,
      f
    );
  }
  f = message.getCapacity();
  if (f !== 0.0) {
    writer.writeDouble(
      9,
      f
    );
  }
  f = message.getStockOutTime();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto_vorto_types_types_pb.NullableTime.serializeBinaryToWriter
    );
  }
  f = message.getLoadsNeedingAttentionList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      11,
      f
    );
  }
  f = message.getAverageDwellTime();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      proto_vorto_types_types_pb.NullableDouble.serializeBinaryToWriter
    );
  }
  f = message.getLoadsReceivingToday();
  if (f !== 0) {
    writer.writeInt64(
      13,
      f
    );
  }
  f = message.getInventoryStockOutRiskIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      14,
      f
    );
  }
  f = message.getDetentionPerDayList();
  if (f.length > 0) {
    writer.writePackedInt64(
      15,
      f
    );
  }
  f = message.getOptimalLoadStatsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      16,
      f,
      proto_vorto_types_types_pb.OptimalLoadsStats.serializeBinaryToWriter
    );
  }
  f = message.getLastStockOutDay();
  if (f != null) {
    writer.writeMessage(
      17,
      f,
      proto_vorto_types_types_pb.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getRestockStrategy();
  if (f !== 0.0) {
    writer.writeEnum(
      18,
      f
    );
  }
  f = message.getRollingDetentionAverage();
  if (f !== 0) {
    writer.writeInt64(
      19,
      f
    );
  }
  f = message.getUpdatedAt();
  if (f != null) {
    writer.writeMessage(
      20,
      f,
      proto_vorto_types_types_pb.NullableTime.serializeBinaryToWriter
    );
  }
  f = message.getUpdatedBy();
  if (f != null) {
    writer.writeMessage(
      21,
      f,
      proto_vorto_types_types_pb.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getUnitAbbreviation();
  if (f.length > 0) {
    writer.writeString(
      22,
      f
    );
  }
  f = message.getTrucksEnRoute();
  if (f != null) {
    writer.writeMessage(
      23,
      f,
      proto_vorto_types_types_pb.NullableInt64.serializeBinaryToWriter
    );
  }
  f = message.getUpcomingStockDrop();
  if (f != null) {
    writer.writeMessage(
      24,
      f,
      proto_vorto_types_types_pb.NullableDouble.serializeBinaryToWriter
    );
  }
  f = message.getStockUpdatedAt();
  if (f != null) {
    writer.writeMessage(
      25,
      f,
      proto_vorto_types_types_pb.NullableTime.serializeBinaryToWriter
    );
  }
  f = message.getStockUpdatedById();
  if (f != null) {
    writer.writeMessage(
      26,
      f,
      proto_vorto_types_types_pb.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getStockUpdatedByName();
  if (f != null) {
    writer.writeMessage(
      27,
      f,
      proto_vorto_types_types_pb.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getIsInbound();
  if (f) {
    writer.writeBool(
      28,
      f
    );
  }
  f = message.getIsOutbound();
  if (f) {
    writer.writeBool(
      29,
      f
    );
  }
  f = message.getHasNoInventories();
  if (f) {
    writer.writeBool(
      30,
      f
    );
  }
  f = message.getSiteDownAt();
  if (f != null) {
    writer.writeMessage(
      31,
      f,
      proto_vorto_types_types_pb.NullableTime.serializeBinaryToWriter
    );
  }
};


/**
 * optional string inventory_id = 1;
 * @return {string}
 */
proto.vtv1.InventoryListItem.prototype.getInventoryId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.InventoryListItem} returns this
 */
proto.vtv1.InventoryListItem.prototype.setInventoryId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string inventory_name = 2;
 * @return {string}
 */
proto.vtv1.InventoryListItem.prototype.getInventoryName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.InventoryListItem} returns this
 */
proto.vtv1.InventoryListItem.prototype.setInventoryName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string product_id = 3;
 * @return {string}
 */
proto.vtv1.InventoryListItem.prototype.getProductId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.InventoryListItem} returns this
 */
proto.vtv1.InventoryListItem.prototype.setProductId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string product_name = 4;
 * @return {string}
 */
proto.vtv1.InventoryListItem.prototype.getProductName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.InventoryListItem} returns this
 */
proto.vtv1.InventoryListItem.prototype.setProductName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string site_id = 5;
 * @return {string}
 */
proto.vtv1.InventoryListItem.prototype.getSiteId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.InventoryListItem} returns this
 */
proto.vtv1.InventoryListItem.prototype.setSiteId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string site_name = 6;
 * @return {string}
 */
proto.vtv1.InventoryListItem.prototype.getSiteName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.InventoryListItem} returns this
 */
proto.vtv1.InventoryListItem.prototype.setSiteName = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string region = 7;
 * @return {string}
 */
proto.vtv1.InventoryListItem.prototype.getRegion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.InventoryListItem} returns this
 */
proto.vtv1.InventoryListItem.prototype.setRegion = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional double stock = 8;
 * @return {number}
 */
proto.vtv1.InventoryListItem.prototype.getStock = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.InventoryListItem} returns this
 */
proto.vtv1.InventoryListItem.prototype.setStock = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};


/**
 * optional double capacity = 9;
 * @return {number}
 */
proto.vtv1.InventoryListItem.prototype.getCapacity = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 9, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.InventoryListItem} returns this
 */
proto.vtv1.InventoryListItem.prototype.setCapacity = function(value) {
  return jspb.Message.setProto3FloatField(this, 9, value);
};


/**
 * optional NullableTime stock_out_time = 10;
 * @return {?proto.vtv1.NullableTime}
 */
proto.vtv1.InventoryListItem.prototype.getStockOutTime = function() {
  return /** @type{?proto.vtv1.NullableTime} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableTime, 10));
};


/**
 * @param {?proto.vtv1.NullableTime|undefined} value
 * @return {!proto.vtv1.InventoryListItem} returns this
*/
proto.vtv1.InventoryListItem.prototype.setStockOutTime = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.InventoryListItem} returns this
 */
proto.vtv1.InventoryListItem.prototype.clearStockOutTime = function() {
  return this.setStockOutTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.InventoryListItem.prototype.hasStockOutTime = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * repeated string loads_needing_attention = 11;
 * @return {!Array<string>}
 */
proto.vtv1.InventoryListItem.prototype.getLoadsNeedingAttentionList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 11));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.vtv1.InventoryListItem} returns this
 */
proto.vtv1.InventoryListItem.prototype.setLoadsNeedingAttentionList = function(value) {
  return jspb.Message.setField(this, 11, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.vtv1.InventoryListItem} returns this
 */
proto.vtv1.InventoryListItem.prototype.addLoadsNeedingAttention = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 11, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.InventoryListItem} returns this
 */
proto.vtv1.InventoryListItem.prototype.clearLoadsNeedingAttentionList = function() {
  return this.setLoadsNeedingAttentionList([]);
};


/**
 * optional NullableDouble average_dwell_time = 12;
 * @return {?proto.vtv1.NullableDouble}
 */
proto.vtv1.InventoryListItem.prototype.getAverageDwellTime = function() {
  return /** @type{?proto.vtv1.NullableDouble} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableDouble, 12));
};


/**
 * @param {?proto.vtv1.NullableDouble|undefined} value
 * @return {!proto.vtv1.InventoryListItem} returns this
*/
proto.vtv1.InventoryListItem.prototype.setAverageDwellTime = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.InventoryListItem} returns this
 */
proto.vtv1.InventoryListItem.prototype.clearAverageDwellTime = function() {
  return this.setAverageDwellTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.InventoryListItem.prototype.hasAverageDwellTime = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional int64 loads_receiving_today = 13;
 * @return {number}
 */
proto.vtv1.InventoryListItem.prototype.getLoadsReceivingToday = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.InventoryListItem} returns this
 */
proto.vtv1.InventoryListItem.prototype.setLoadsReceivingToday = function(value) {
  return jspb.Message.setProto3IntField(this, 13, value);
};


/**
 * repeated string inventory_stock_out_risk_ids = 14;
 * @return {!Array<string>}
 */
proto.vtv1.InventoryListItem.prototype.getInventoryStockOutRiskIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 14));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.vtv1.InventoryListItem} returns this
 */
proto.vtv1.InventoryListItem.prototype.setInventoryStockOutRiskIdsList = function(value) {
  return jspb.Message.setField(this, 14, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.vtv1.InventoryListItem} returns this
 */
proto.vtv1.InventoryListItem.prototype.addInventoryStockOutRiskIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 14, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.InventoryListItem} returns this
 */
proto.vtv1.InventoryListItem.prototype.clearInventoryStockOutRiskIdsList = function() {
  return this.setInventoryStockOutRiskIdsList([]);
};


/**
 * repeated int64 detention_per_day = 15;
 * @return {!Array<number>}
 */
proto.vtv1.InventoryListItem.prototype.getDetentionPerDayList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 15));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.vtv1.InventoryListItem} returns this
 */
proto.vtv1.InventoryListItem.prototype.setDetentionPerDayList = function(value) {
  return jspb.Message.setField(this, 15, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.vtv1.InventoryListItem} returns this
 */
proto.vtv1.InventoryListItem.prototype.addDetentionPerDay = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 15, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.InventoryListItem} returns this
 */
proto.vtv1.InventoryListItem.prototype.clearDetentionPerDayList = function() {
  return this.setDetentionPerDayList([]);
};


/**
 * repeated OptimalLoadsStats optimal_load_stats = 16;
 * @return {!Array<!proto.vtv1.OptimalLoadsStats>}
 */
proto.vtv1.InventoryListItem.prototype.getOptimalLoadStatsList = function() {
  return /** @type{!Array<!proto.vtv1.OptimalLoadsStats>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_vorto_types_types_pb.OptimalLoadsStats, 16));
};


/**
 * @param {!Array<!proto.vtv1.OptimalLoadsStats>} value
 * @return {!proto.vtv1.InventoryListItem} returns this
*/
proto.vtv1.InventoryListItem.prototype.setOptimalLoadStatsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 16, value);
};


/**
 * @param {!proto.vtv1.OptimalLoadsStats=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.OptimalLoadsStats}
 */
proto.vtv1.InventoryListItem.prototype.addOptimalLoadStats = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 16, opt_value, proto.vtv1.OptimalLoadsStats, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.InventoryListItem} returns this
 */
proto.vtv1.InventoryListItem.prototype.clearOptimalLoadStatsList = function() {
  return this.setOptimalLoadStatsList([]);
};


/**
 * optional NullableString last_stock_out_day = 17;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.InventoryListItem.prototype.getLastStockOutDay = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableString, 17));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.InventoryListItem} returns this
*/
proto.vtv1.InventoryListItem.prototype.setLastStockOutDay = function(value) {
  return jspb.Message.setWrapperField(this, 17, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.InventoryListItem} returns this
 */
proto.vtv1.InventoryListItem.prototype.clearLastStockOutDay = function() {
  return this.setLastStockOutDay(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.InventoryListItem.prototype.hasLastStockOutDay = function() {
  return jspb.Message.getField(this, 17) != null;
};


/**
 * optional RestockStrategy restock_strategy = 18;
 * @return {!proto.vtv1.RestockStrategy}
 */
proto.vtv1.InventoryListItem.prototype.getRestockStrategy = function() {
  return /** @type {!proto.vtv1.RestockStrategy} */ (jspb.Message.getFieldWithDefault(this, 18, 0));
};


/**
 * @param {!proto.vtv1.RestockStrategy} value
 * @return {!proto.vtv1.InventoryListItem} returns this
 */
proto.vtv1.InventoryListItem.prototype.setRestockStrategy = function(value) {
  return jspb.Message.setProto3EnumField(this, 18, value);
};


/**
 * optional int64 rolling_detention_average = 19;
 * @return {number}
 */
proto.vtv1.InventoryListItem.prototype.getRollingDetentionAverage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 19, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.InventoryListItem} returns this
 */
proto.vtv1.InventoryListItem.prototype.setRollingDetentionAverage = function(value) {
  return jspb.Message.setProto3IntField(this, 19, value);
};


/**
 * optional NullableTime updated_at = 20;
 * @return {?proto.vtv1.NullableTime}
 */
proto.vtv1.InventoryListItem.prototype.getUpdatedAt = function() {
  return /** @type{?proto.vtv1.NullableTime} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableTime, 20));
};


/**
 * @param {?proto.vtv1.NullableTime|undefined} value
 * @return {!proto.vtv1.InventoryListItem} returns this
*/
proto.vtv1.InventoryListItem.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 20, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.InventoryListItem} returns this
 */
proto.vtv1.InventoryListItem.prototype.clearUpdatedAt = function() {
  return this.setUpdatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.InventoryListItem.prototype.hasUpdatedAt = function() {
  return jspb.Message.getField(this, 20) != null;
};


/**
 * optional NullableString updated_by = 21;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.InventoryListItem.prototype.getUpdatedBy = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableString, 21));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.InventoryListItem} returns this
*/
proto.vtv1.InventoryListItem.prototype.setUpdatedBy = function(value) {
  return jspb.Message.setWrapperField(this, 21, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.InventoryListItem} returns this
 */
proto.vtv1.InventoryListItem.prototype.clearUpdatedBy = function() {
  return this.setUpdatedBy(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.InventoryListItem.prototype.hasUpdatedBy = function() {
  return jspb.Message.getField(this, 21) != null;
};


/**
 * optional string unit_abbreviation = 22;
 * @return {string}
 */
proto.vtv1.InventoryListItem.prototype.getUnitAbbreviation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 22, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.InventoryListItem} returns this
 */
proto.vtv1.InventoryListItem.prototype.setUnitAbbreviation = function(value) {
  return jspb.Message.setProto3StringField(this, 22, value);
};


/**
 * optional NullableInt64 trucks_en_route = 23;
 * @return {?proto.vtv1.NullableInt64}
 */
proto.vtv1.InventoryListItem.prototype.getTrucksEnRoute = function() {
  return /** @type{?proto.vtv1.NullableInt64} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableInt64, 23));
};


/**
 * @param {?proto.vtv1.NullableInt64|undefined} value
 * @return {!proto.vtv1.InventoryListItem} returns this
*/
proto.vtv1.InventoryListItem.prototype.setTrucksEnRoute = function(value) {
  return jspb.Message.setWrapperField(this, 23, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.InventoryListItem} returns this
 */
proto.vtv1.InventoryListItem.prototype.clearTrucksEnRoute = function() {
  return this.setTrucksEnRoute(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.InventoryListItem.prototype.hasTrucksEnRoute = function() {
  return jspb.Message.getField(this, 23) != null;
};


/**
 * optional NullableDouble upcoming_stock_drop = 24;
 * @return {?proto.vtv1.NullableDouble}
 */
proto.vtv1.InventoryListItem.prototype.getUpcomingStockDrop = function() {
  return /** @type{?proto.vtv1.NullableDouble} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableDouble, 24));
};


/**
 * @param {?proto.vtv1.NullableDouble|undefined} value
 * @return {!proto.vtv1.InventoryListItem} returns this
*/
proto.vtv1.InventoryListItem.prototype.setUpcomingStockDrop = function(value) {
  return jspb.Message.setWrapperField(this, 24, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.InventoryListItem} returns this
 */
proto.vtv1.InventoryListItem.prototype.clearUpcomingStockDrop = function() {
  return this.setUpcomingStockDrop(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.InventoryListItem.prototype.hasUpcomingStockDrop = function() {
  return jspb.Message.getField(this, 24) != null;
};


/**
 * optional NullableTime stock_updated_at = 25;
 * @return {?proto.vtv1.NullableTime}
 */
proto.vtv1.InventoryListItem.prototype.getStockUpdatedAt = function() {
  return /** @type{?proto.vtv1.NullableTime} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableTime, 25));
};


/**
 * @param {?proto.vtv1.NullableTime|undefined} value
 * @return {!proto.vtv1.InventoryListItem} returns this
*/
proto.vtv1.InventoryListItem.prototype.setStockUpdatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 25, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.InventoryListItem} returns this
 */
proto.vtv1.InventoryListItem.prototype.clearStockUpdatedAt = function() {
  return this.setStockUpdatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.InventoryListItem.prototype.hasStockUpdatedAt = function() {
  return jspb.Message.getField(this, 25) != null;
};


/**
 * optional NullableString stock_updated_by_id = 26;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.InventoryListItem.prototype.getStockUpdatedById = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableString, 26));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.InventoryListItem} returns this
*/
proto.vtv1.InventoryListItem.prototype.setStockUpdatedById = function(value) {
  return jspb.Message.setWrapperField(this, 26, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.InventoryListItem} returns this
 */
proto.vtv1.InventoryListItem.prototype.clearStockUpdatedById = function() {
  return this.setStockUpdatedById(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.InventoryListItem.prototype.hasStockUpdatedById = function() {
  return jspb.Message.getField(this, 26) != null;
};


/**
 * optional NullableString stock_updated_by_name = 27;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.InventoryListItem.prototype.getStockUpdatedByName = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableString, 27));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.InventoryListItem} returns this
*/
proto.vtv1.InventoryListItem.prototype.setStockUpdatedByName = function(value) {
  return jspb.Message.setWrapperField(this, 27, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.InventoryListItem} returns this
 */
proto.vtv1.InventoryListItem.prototype.clearStockUpdatedByName = function() {
  return this.setStockUpdatedByName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.InventoryListItem.prototype.hasStockUpdatedByName = function() {
  return jspb.Message.getField(this, 27) != null;
};


/**
 * optional bool is_inbound = 28;
 * @return {boolean}
 */
proto.vtv1.InventoryListItem.prototype.getIsInbound = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 28, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vtv1.InventoryListItem} returns this
 */
proto.vtv1.InventoryListItem.prototype.setIsInbound = function(value) {
  return jspb.Message.setProto3BooleanField(this, 28, value);
};


/**
 * optional bool is_outbound = 29;
 * @return {boolean}
 */
proto.vtv1.InventoryListItem.prototype.getIsOutbound = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 29, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vtv1.InventoryListItem} returns this
 */
proto.vtv1.InventoryListItem.prototype.setIsOutbound = function(value) {
  return jspb.Message.setProto3BooleanField(this, 29, value);
};


/**
 * optional bool has_no_inventories = 30;
 * @return {boolean}
 */
proto.vtv1.InventoryListItem.prototype.getHasNoInventories = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 30, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vtv1.InventoryListItem} returns this
 */
proto.vtv1.InventoryListItem.prototype.setHasNoInventories = function(value) {
  return jspb.Message.setProto3BooleanField(this, 30, value);
};


/**
 * optional NullableTime site_down_at = 31;
 * @return {?proto.vtv1.NullableTime}
 */
proto.vtv1.InventoryListItem.prototype.getSiteDownAt = function() {
  return /** @type{?proto.vtv1.NullableTime} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableTime, 31));
};


/**
 * @param {?proto.vtv1.NullableTime|undefined} value
 * @return {!proto.vtv1.InventoryListItem} returns this
*/
proto.vtv1.InventoryListItem.prototype.setSiteDownAt = function(value) {
  return jspb.Message.setWrapperField(this, 31, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.InventoryListItem} returns this
 */
proto.vtv1.InventoryListItem.prototype.clearSiteDownAt = function() {
  return this.setSiteDownAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.InventoryListItem.prototype.hasSiteDownAt = function() {
  return jspb.Message.getField(this, 31) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.InventoryIssuesListItem.repeatedFields_ = [5,6,7,8];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.InventoryIssuesListItem.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.InventoryIssuesListItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.InventoryIssuesListItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.InventoryIssuesListItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    inventoryId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    supplyPlanCount: jspb.Message.getFieldWithDefault(msg, 2, 0),
    materialsNeedReviewCount: jspb.Message.getFieldWithDefault(msg, 3, 0),
    purchaseOrdersAwaitingSupplierActionCount: jspb.Message.getFieldWithDefault(msg, 4, 0),
    ordersAvailableToAllSuppliersList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f,
    manualOrdersAwaitingSupplierActionList: (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f,
    orderWithProblemsList: (f = jspb.Message.getRepeatedField(msg, 7)) == null ? undefined : f,
    ordersRequirePoOrRouteGuideChangeList: (f = jspb.Message.getRepeatedField(msg, 8)) == null ? undefined : f,
    hasIssues: jspb.Message.getBooleanFieldWithDefault(msg, 9, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.InventoryIssuesListItem}
 */
proto.vtv1.InventoryIssuesListItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.InventoryIssuesListItem;
  return proto.vtv1.InventoryIssuesListItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.InventoryIssuesListItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.InventoryIssuesListItem}
 */
proto.vtv1.InventoryIssuesListItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setInventoryId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSupplyPlanCount(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMaterialsNeedReviewCount(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPurchaseOrdersAwaitingSupplierActionCount(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.addOrdersAvailableToAllSuppliers(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.addManualOrdersAwaitingSupplierAction(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.addOrderWithProblems(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.addOrdersRequirePoOrRouteGuideChange(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasIssues(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.InventoryIssuesListItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.InventoryIssuesListItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.InventoryIssuesListItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.InventoryIssuesListItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInventoryId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSupplyPlanCount();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getMaterialsNeedReviewCount();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getPurchaseOrdersAwaitingSupplierActionCount();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getOrdersAvailableToAllSuppliersList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      5,
      f
    );
  }
  f = message.getManualOrdersAwaitingSupplierActionList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      6,
      f
    );
  }
  f = message.getOrderWithProblemsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      7,
      f
    );
  }
  f = message.getOrdersRequirePoOrRouteGuideChangeList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      8,
      f
    );
  }
  f = message.getHasIssues();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
};


/**
 * optional string inventory_id = 1;
 * @return {string}
 */
proto.vtv1.InventoryIssuesListItem.prototype.getInventoryId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.InventoryIssuesListItem} returns this
 */
proto.vtv1.InventoryIssuesListItem.prototype.setInventoryId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 supply_plan_count = 2;
 * @return {number}
 */
proto.vtv1.InventoryIssuesListItem.prototype.getSupplyPlanCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.InventoryIssuesListItem} returns this
 */
proto.vtv1.InventoryIssuesListItem.prototype.setSupplyPlanCount = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 materials_need_review_count = 3;
 * @return {number}
 */
proto.vtv1.InventoryIssuesListItem.prototype.getMaterialsNeedReviewCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.InventoryIssuesListItem} returns this
 */
proto.vtv1.InventoryIssuesListItem.prototype.setMaterialsNeedReviewCount = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 purchase_orders_awaiting_supplier_action_count = 4;
 * @return {number}
 */
proto.vtv1.InventoryIssuesListItem.prototype.getPurchaseOrdersAwaitingSupplierActionCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.InventoryIssuesListItem} returns this
 */
proto.vtv1.InventoryIssuesListItem.prototype.setPurchaseOrdersAwaitingSupplierActionCount = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * repeated string orders_available_to_all_suppliers = 5;
 * @return {!Array<string>}
 */
proto.vtv1.InventoryIssuesListItem.prototype.getOrdersAvailableToAllSuppliersList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.vtv1.InventoryIssuesListItem} returns this
 */
proto.vtv1.InventoryIssuesListItem.prototype.setOrdersAvailableToAllSuppliersList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.vtv1.InventoryIssuesListItem} returns this
 */
proto.vtv1.InventoryIssuesListItem.prototype.addOrdersAvailableToAllSuppliers = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.InventoryIssuesListItem} returns this
 */
proto.vtv1.InventoryIssuesListItem.prototype.clearOrdersAvailableToAllSuppliersList = function() {
  return this.setOrdersAvailableToAllSuppliersList([]);
};


/**
 * repeated string manual_orders_awaiting_supplier_action = 6;
 * @return {!Array<string>}
 */
proto.vtv1.InventoryIssuesListItem.prototype.getManualOrdersAwaitingSupplierActionList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 6));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.vtv1.InventoryIssuesListItem} returns this
 */
proto.vtv1.InventoryIssuesListItem.prototype.setManualOrdersAwaitingSupplierActionList = function(value) {
  return jspb.Message.setField(this, 6, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.vtv1.InventoryIssuesListItem} returns this
 */
proto.vtv1.InventoryIssuesListItem.prototype.addManualOrdersAwaitingSupplierAction = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.InventoryIssuesListItem} returns this
 */
proto.vtv1.InventoryIssuesListItem.prototype.clearManualOrdersAwaitingSupplierActionList = function() {
  return this.setManualOrdersAwaitingSupplierActionList([]);
};


/**
 * repeated string order_with_problems = 7;
 * @return {!Array<string>}
 */
proto.vtv1.InventoryIssuesListItem.prototype.getOrderWithProblemsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 7));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.vtv1.InventoryIssuesListItem} returns this
 */
proto.vtv1.InventoryIssuesListItem.prototype.setOrderWithProblemsList = function(value) {
  return jspb.Message.setField(this, 7, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.vtv1.InventoryIssuesListItem} returns this
 */
proto.vtv1.InventoryIssuesListItem.prototype.addOrderWithProblems = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 7, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.InventoryIssuesListItem} returns this
 */
proto.vtv1.InventoryIssuesListItem.prototype.clearOrderWithProblemsList = function() {
  return this.setOrderWithProblemsList([]);
};


/**
 * repeated string orders_require_po_or_route_guide_change = 8;
 * @return {!Array<string>}
 */
proto.vtv1.InventoryIssuesListItem.prototype.getOrdersRequirePoOrRouteGuideChangeList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 8));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.vtv1.InventoryIssuesListItem} returns this
 */
proto.vtv1.InventoryIssuesListItem.prototype.setOrdersRequirePoOrRouteGuideChangeList = function(value) {
  return jspb.Message.setField(this, 8, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.vtv1.InventoryIssuesListItem} returns this
 */
proto.vtv1.InventoryIssuesListItem.prototype.addOrdersRequirePoOrRouteGuideChange = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 8, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.InventoryIssuesListItem} returns this
 */
proto.vtv1.InventoryIssuesListItem.prototype.clearOrdersRequirePoOrRouteGuideChangeList = function() {
  return this.setOrdersRequirePoOrRouteGuideChangeList([]);
};


/**
 * optional bool has_issues = 9;
 * @return {boolean}
 */
proto.vtv1.InventoryIssuesListItem.prototype.getHasIssues = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vtv1.InventoryIssuesListItem} returns this
 */
proto.vtv1.InventoryIssuesListItem.prototype.setHasIssues = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.InboundInventory.repeatedFields_ = [11,14,15,16];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.InboundInventory.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.InboundInventory.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.InboundInventory} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.InboundInventory.toObject = function(includeInstance, msg) {
  var f, obj = {
    inventoryId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    inventoryName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    productId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    productName: jspb.Message.getFieldWithDefault(msg, 4, ""),
    siteId: jspb.Message.getFieldWithDefault(msg, 5, ""),
    siteName: jspb.Message.getFieldWithDefault(msg, 6, ""),
    region: jspb.Message.getFieldWithDefault(msg, 7, ""),
    stock: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
    capacity: jspb.Message.getFloatingPointFieldWithDefault(msg, 9, 0.0),
    stockOutTime: (f = msg.getStockOutTime()) && proto_vorto_types_types_pb.NullableTime.toObject(includeInstance, f),
    loadsNeedingAttentionList: (f = jspb.Message.getRepeatedField(msg, 11)) == null ? undefined : f,
    averageDwellTime: (f = msg.getAverageDwellTime()) && proto_vorto_types_types_pb.NullableDouble.toObject(includeInstance, f),
    loadsReceivingToday: jspb.Message.getFieldWithDefault(msg, 13, 0),
    inventoryStockOutRiskIdsList: (f = jspb.Message.getRepeatedField(msg, 14)) == null ? undefined : f,
    detentionPerDayList: (f = jspb.Message.getRepeatedField(msg, 15)) == null ? undefined : f,
    optimalLoadStatsList: jspb.Message.toObjectList(msg.getOptimalLoadStatsList(),
    proto_vorto_types_types_pb.OptimalLoadsStats.toObject, includeInstance),
    lastStockOutDay: (f = msg.getLastStockOutDay()) && proto_vorto_types_types_pb.NullableString.toObject(includeInstance, f),
    restockStrategy: jspb.Message.getFieldWithDefault(msg, 18, 0),
    rollingDetentionAverage: jspb.Message.getFieldWithDefault(msg, 19, 0),
    updatedAt: (f = msg.getUpdatedAt()) && proto_vorto_types_types_pb.NullableTime.toObject(includeInstance, f),
    updatedBy: (f = msg.getUpdatedBy()) && proto_vorto_types_types_pb.NullableString.toObject(includeInstance, f),
    unitAbbreviation: jspb.Message.getFieldWithDefault(msg, 22, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.InboundInventory}
 */
proto.vtv1.InboundInventory.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.InboundInventory;
  return proto.vtv1.InboundInventory.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.InboundInventory} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.InboundInventory}
 */
proto.vtv1.InboundInventory.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setInventoryId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setInventoryName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductName(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setSiteId(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setSiteName(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setRegion(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setStock(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setCapacity(value);
      break;
    case 10:
      var value = new proto_vorto_types_types_pb.NullableTime;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableTime.deserializeBinaryFromReader);
      msg.setStockOutTime(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.addLoadsNeedingAttention(value);
      break;
    case 12:
      var value = new proto_vorto_types_types_pb.NullableDouble;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableDouble.deserializeBinaryFromReader);
      msg.setAverageDwellTime(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLoadsReceivingToday(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.addInventoryStockOutRiskIds(value);
      break;
    case 15:
      var value = /** @type {!Array<number>} */ (reader.readPackedInt64());
      msg.setDetentionPerDayList(value);
      break;
    case 16:
      var value = new proto_vorto_types_types_pb.OptimalLoadsStats;
      reader.readMessage(value,proto_vorto_types_types_pb.OptimalLoadsStats.deserializeBinaryFromReader);
      msg.addOptimalLoadStats(value);
      break;
    case 17:
      var value = new proto_vorto_types_types_pb.NullableString;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableString.deserializeBinaryFromReader);
      msg.setLastStockOutDay(value);
      break;
    case 18:
      var value = /** @type {!proto.vtv1.RestockStrategy} */ (reader.readEnum());
      msg.setRestockStrategy(value);
      break;
    case 19:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRollingDetentionAverage(value);
      break;
    case 20:
      var value = new proto_vorto_types_types_pb.NullableTime;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableTime.deserializeBinaryFromReader);
      msg.setUpdatedAt(value);
      break;
    case 21:
      var value = new proto_vorto_types_types_pb.NullableString;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableString.deserializeBinaryFromReader);
      msg.setUpdatedBy(value);
      break;
    case 22:
      var value = /** @type {string} */ (reader.readString());
      msg.setUnitAbbreviation(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.InboundInventory.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.InboundInventory.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.InboundInventory} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.InboundInventory.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInventoryId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getInventoryName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getProductId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getProductName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getSiteId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getSiteName();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getRegion();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getStock();
  if (f !== 0.0) {
    writer.writeDouble(
      8,
      f
    );
  }
  f = message.getCapacity();
  if (f !== 0.0) {
    writer.writeDouble(
      9,
      f
    );
  }
  f = message.getStockOutTime();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto_vorto_types_types_pb.NullableTime.serializeBinaryToWriter
    );
  }
  f = message.getLoadsNeedingAttentionList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      11,
      f
    );
  }
  f = message.getAverageDwellTime();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      proto_vorto_types_types_pb.NullableDouble.serializeBinaryToWriter
    );
  }
  f = message.getLoadsReceivingToday();
  if (f !== 0) {
    writer.writeInt64(
      13,
      f
    );
  }
  f = message.getInventoryStockOutRiskIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      14,
      f
    );
  }
  f = message.getDetentionPerDayList();
  if (f.length > 0) {
    writer.writePackedInt64(
      15,
      f
    );
  }
  f = message.getOptimalLoadStatsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      16,
      f,
      proto_vorto_types_types_pb.OptimalLoadsStats.serializeBinaryToWriter
    );
  }
  f = message.getLastStockOutDay();
  if (f != null) {
    writer.writeMessage(
      17,
      f,
      proto_vorto_types_types_pb.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getRestockStrategy();
  if (f !== 0.0) {
    writer.writeEnum(
      18,
      f
    );
  }
  f = message.getRollingDetentionAverage();
  if (f !== 0) {
    writer.writeInt64(
      19,
      f
    );
  }
  f = message.getUpdatedAt();
  if (f != null) {
    writer.writeMessage(
      20,
      f,
      proto_vorto_types_types_pb.NullableTime.serializeBinaryToWriter
    );
  }
  f = message.getUpdatedBy();
  if (f != null) {
    writer.writeMessage(
      21,
      f,
      proto_vorto_types_types_pb.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getUnitAbbreviation();
  if (f.length > 0) {
    writer.writeString(
      22,
      f
    );
  }
};


/**
 * optional string inventory_id = 1;
 * @return {string}
 */
proto.vtv1.InboundInventory.prototype.getInventoryId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.InboundInventory} returns this
 */
proto.vtv1.InboundInventory.prototype.setInventoryId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string inventory_name = 2;
 * @return {string}
 */
proto.vtv1.InboundInventory.prototype.getInventoryName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.InboundInventory} returns this
 */
proto.vtv1.InboundInventory.prototype.setInventoryName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string product_id = 3;
 * @return {string}
 */
proto.vtv1.InboundInventory.prototype.getProductId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.InboundInventory} returns this
 */
proto.vtv1.InboundInventory.prototype.setProductId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string product_name = 4;
 * @return {string}
 */
proto.vtv1.InboundInventory.prototype.getProductName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.InboundInventory} returns this
 */
proto.vtv1.InboundInventory.prototype.setProductName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string site_id = 5;
 * @return {string}
 */
proto.vtv1.InboundInventory.prototype.getSiteId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.InboundInventory} returns this
 */
proto.vtv1.InboundInventory.prototype.setSiteId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string site_name = 6;
 * @return {string}
 */
proto.vtv1.InboundInventory.prototype.getSiteName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.InboundInventory} returns this
 */
proto.vtv1.InboundInventory.prototype.setSiteName = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string region = 7;
 * @return {string}
 */
proto.vtv1.InboundInventory.prototype.getRegion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.InboundInventory} returns this
 */
proto.vtv1.InboundInventory.prototype.setRegion = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional double stock = 8;
 * @return {number}
 */
proto.vtv1.InboundInventory.prototype.getStock = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.InboundInventory} returns this
 */
proto.vtv1.InboundInventory.prototype.setStock = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};


/**
 * optional double capacity = 9;
 * @return {number}
 */
proto.vtv1.InboundInventory.prototype.getCapacity = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 9, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.InboundInventory} returns this
 */
proto.vtv1.InboundInventory.prototype.setCapacity = function(value) {
  return jspb.Message.setProto3FloatField(this, 9, value);
};


/**
 * optional NullableTime stock_out_time = 10;
 * @return {?proto.vtv1.NullableTime}
 */
proto.vtv1.InboundInventory.prototype.getStockOutTime = function() {
  return /** @type{?proto.vtv1.NullableTime} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableTime, 10));
};


/**
 * @param {?proto.vtv1.NullableTime|undefined} value
 * @return {!proto.vtv1.InboundInventory} returns this
*/
proto.vtv1.InboundInventory.prototype.setStockOutTime = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.InboundInventory} returns this
 */
proto.vtv1.InboundInventory.prototype.clearStockOutTime = function() {
  return this.setStockOutTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.InboundInventory.prototype.hasStockOutTime = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * repeated string loads_needing_attention = 11;
 * @return {!Array<string>}
 */
proto.vtv1.InboundInventory.prototype.getLoadsNeedingAttentionList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 11));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.vtv1.InboundInventory} returns this
 */
proto.vtv1.InboundInventory.prototype.setLoadsNeedingAttentionList = function(value) {
  return jspb.Message.setField(this, 11, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.vtv1.InboundInventory} returns this
 */
proto.vtv1.InboundInventory.prototype.addLoadsNeedingAttention = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 11, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.InboundInventory} returns this
 */
proto.vtv1.InboundInventory.prototype.clearLoadsNeedingAttentionList = function() {
  return this.setLoadsNeedingAttentionList([]);
};


/**
 * optional NullableDouble average_dwell_time = 12;
 * @return {?proto.vtv1.NullableDouble}
 */
proto.vtv1.InboundInventory.prototype.getAverageDwellTime = function() {
  return /** @type{?proto.vtv1.NullableDouble} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableDouble, 12));
};


/**
 * @param {?proto.vtv1.NullableDouble|undefined} value
 * @return {!proto.vtv1.InboundInventory} returns this
*/
proto.vtv1.InboundInventory.prototype.setAverageDwellTime = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.InboundInventory} returns this
 */
proto.vtv1.InboundInventory.prototype.clearAverageDwellTime = function() {
  return this.setAverageDwellTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.InboundInventory.prototype.hasAverageDwellTime = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional int64 loads_receiving_today = 13;
 * @return {number}
 */
proto.vtv1.InboundInventory.prototype.getLoadsReceivingToday = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.InboundInventory} returns this
 */
proto.vtv1.InboundInventory.prototype.setLoadsReceivingToday = function(value) {
  return jspb.Message.setProto3IntField(this, 13, value);
};


/**
 * repeated string inventory_stock_out_risk_ids = 14;
 * @return {!Array<string>}
 */
proto.vtv1.InboundInventory.prototype.getInventoryStockOutRiskIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 14));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.vtv1.InboundInventory} returns this
 */
proto.vtv1.InboundInventory.prototype.setInventoryStockOutRiskIdsList = function(value) {
  return jspb.Message.setField(this, 14, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.vtv1.InboundInventory} returns this
 */
proto.vtv1.InboundInventory.prototype.addInventoryStockOutRiskIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 14, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.InboundInventory} returns this
 */
proto.vtv1.InboundInventory.prototype.clearInventoryStockOutRiskIdsList = function() {
  return this.setInventoryStockOutRiskIdsList([]);
};


/**
 * repeated int64 detention_per_day = 15;
 * @return {!Array<number>}
 */
proto.vtv1.InboundInventory.prototype.getDetentionPerDayList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 15));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.vtv1.InboundInventory} returns this
 */
proto.vtv1.InboundInventory.prototype.setDetentionPerDayList = function(value) {
  return jspb.Message.setField(this, 15, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.vtv1.InboundInventory} returns this
 */
proto.vtv1.InboundInventory.prototype.addDetentionPerDay = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 15, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.InboundInventory} returns this
 */
proto.vtv1.InboundInventory.prototype.clearDetentionPerDayList = function() {
  return this.setDetentionPerDayList([]);
};


/**
 * repeated OptimalLoadsStats optimal_load_stats = 16;
 * @return {!Array<!proto.vtv1.OptimalLoadsStats>}
 */
proto.vtv1.InboundInventory.prototype.getOptimalLoadStatsList = function() {
  return /** @type{!Array<!proto.vtv1.OptimalLoadsStats>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_vorto_types_types_pb.OptimalLoadsStats, 16));
};


/**
 * @param {!Array<!proto.vtv1.OptimalLoadsStats>} value
 * @return {!proto.vtv1.InboundInventory} returns this
*/
proto.vtv1.InboundInventory.prototype.setOptimalLoadStatsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 16, value);
};


/**
 * @param {!proto.vtv1.OptimalLoadsStats=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.OptimalLoadsStats}
 */
proto.vtv1.InboundInventory.prototype.addOptimalLoadStats = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 16, opt_value, proto.vtv1.OptimalLoadsStats, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.InboundInventory} returns this
 */
proto.vtv1.InboundInventory.prototype.clearOptimalLoadStatsList = function() {
  return this.setOptimalLoadStatsList([]);
};


/**
 * optional NullableString last_stock_out_day = 17;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.InboundInventory.prototype.getLastStockOutDay = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableString, 17));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.InboundInventory} returns this
*/
proto.vtv1.InboundInventory.prototype.setLastStockOutDay = function(value) {
  return jspb.Message.setWrapperField(this, 17, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.InboundInventory} returns this
 */
proto.vtv1.InboundInventory.prototype.clearLastStockOutDay = function() {
  return this.setLastStockOutDay(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.InboundInventory.prototype.hasLastStockOutDay = function() {
  return jspb.Message.getField(this, 17) != null;
};


/**
 * optional RestockStrategy restock_strategy = 18;
 * @return {!proto.vtv1.RestockStrategy}
 */
proto.vtv1.InboundInventory.prototype.getRestockStrategy = function() {
  return /** @type {!proto.vtv1.RestockStrategy} */ (jspb.Message.getFieldWithDefault(this, 18, 0));
};


/**
 * @param {!proto.vtv1.RestockStrategy} value
 * @return {!proto.vtv1.InboundInventory} returns this
 */
proto.vtv1.InboundInventory.prototype.setRestockStrategy = function(value) {
  return jspb.Message.setProto3EnumField(this, 18, value);
};


/**
 * optional int64 rolling_detention_average = 19;
 * @return {number}
 */
proto.vtv1.InboundInventory.prototype.getRollingDetentionAverage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 19, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.InboundInventory} returns this
 */
proto.vtv1.InboundInventory.prototype.setRollingDetentionAverage = function(value) {
  return jspb.Message.setProto3IntField(this, 19, value);
};


/**
 * optional NullableTime updated_at = 20;
 * @return {?proto.vtv1.NullableTime}
 */
proto.vtv1.InboundInventory.prototype.getUpdatedAt = function() {
  return /** @type{?proto.vtv1.NullableTime} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableTime, 20));
};


/**
 * @param {?proto.vtv1.NullableTime|undefined} value
 * @return {!proto.vtv1.InboundInventory} returns this
*/
proto.vtv1.InboundInventory.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 20, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.InboundInventory} returns this
 */
proto.vtv1.InboundInventory.prototype.clearUpdatedAt = function() {
  return this.setUpdatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.InboundInventory.prototype.hasUpdatedAt = function() {
  return jspb.Message.getField(this, 20) != null;
};


/**
 * optional NullableString updated_by = 21;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.InboundInventory.prototype.getUpdatedBy = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableString, 21));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.InboundInventory} returns this
*/
proto.vtv1.InboundInventory.prototype.setUpdatedBy = function(value) {
  return jspb.Message.setWrapperField(this, 21, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.InboundInventory} returns this
 */
proto.vtv1.InboundInventory.prototype.clearUpdatedBy = function() {
  return this.setUpdatedBy(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.InboundInventory.prototype.hasUpdatedBy = function() {
  return jspb.Message.getField(this, 21) != null;
};


/**
 * optional string unit_abbreviation = 22;
 * @return {string}
 */
proto.vtv1.InboundInventory.prototype.getUnitAbbreviation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 22, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.InboundInventory} returns this
 */
proto.vtv1.InboundInventory.prototype.setUnitAbbreviation = function(value) {
  return jspb.Message.setProto3StringField(this, 22, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.OutboundInventory.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.OutboundInventory.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.OutboundInventory} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.OutboundInventory.toObject = function(includeInstance, msg) {
  var f, obj = {
    inventoryId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    inventoryName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    productId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    productName: jspb.Message.getFieldWithDefault(msg, 4, ""),
    siteId: jspb.Message.getFieldWithDefault(msg, 5, ""),
    siteName: jspb.Message.getFieldWithDefault(msg, 6, ""),
    region: jspb.Message.getFieldWithDefault(msg, 7, ""),
    stock: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
    capacity: jspb.Message.getFloatingPointFieldWithDefault(msg, 9, 0.0),
    trucksEnRoute: (f = msg.getTrucksEnRoute()) && proto_vorto_types_types_pb.NullableInt64.toObject(includeInstance, f),
    upcomingStockDrop: (f = msg.getUpcomingStockDrop()) && proto_vorto_types_types_pb.NullableDouble.toObject(includeInstance, f),
    stockUpdatedAt: jspb.Message.getFieldWithDefault(msg, 12, ""),
    stockUpdatedById: (f = msg.getStockUpdatedById()) && proto_vorto_types_types_pb.NullableString.toObject(includeInstance, f),
    stockUpdatedByName: (f = msg.getStockUpdatedByName()) && proto_vorto_types_types_pb.NullableString.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.OutboundInventory}
 */
proto.vtv1.OutboundInventory.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.OutboundInventory;
  return proto.vtv1.OutboundInventory.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.OutboundInventory} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.OutboundInventory}
 */
proto.vtv1.OutboundInventory.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setInventoryId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setInventoryName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductName(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setSiteId(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setSiteName(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setRegion(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setStock(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setCapacity(value);
      break;
    case 10:
      var value = new proto_vorto_types_types_pb.NullableInt64;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableInt64.deserializeBinaryFromReader);
      msg.setTrucksEnRoute(value);
      break;
    case 11:
      var value = new proto_vorto_types_types_pb.NullableDouble;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableDouble.deserializeBinaryFromReader);
      msg.setUpcomingStockDrop(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setStockUpdatedAt(value);
      break;
    case 13:
      var value = new proto_vorto_types_types_pb.NullableString;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableString.deserializeBinaryFromReader);
      msg.setStockUpdatedById(value);
      break;
    case 14:
      var value = new proto_vorto_types_types_pb.NullableString;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableString.deserializeBinaryFromReader);
      msg.setStockUpdatedByName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.OutboundInventory.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.OutboundInventory.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.OutboundInventory} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.OutboundInventory.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInventoryId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getInventoryName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getProductId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getProductName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getSiteId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getSiteName();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getRegion();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getStock();
  if (f !== 0.0) {
    writer.writeDouble(
      8,
      f
    );
  }
  f = message.getCapacity();
  if (f !== 0.0) {
    writer.writeDouble(
      9,
      f
    );
  }
  f = message.getTrucksEnRoute();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto_vorto_types_types_pb.NullableInt64.serializeBinaryToWriter
    );
  }
  f = message.getUpcomingStockDrop();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto_vorto_types_types_pb.NullableDouble.serializeBinaryToWriter
    );
  }
  f = message.getStockUpdatedAt();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getStockUpdatedById();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      proto_vorto_types_types_pb.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getStockUpdatedByName();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      proto_vorto_types_types_pb.NullableString.serializeBinaryToWriter
    );
  }
};


/**
 * optional string inventory_id = 1;
 * @return {string}
 */
proto.vtv1.OutboundInventory.prototype.getInventoryId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.OutboundInventory} returns this
 */
proto.vtv1.OutboundInventory.prototype.setInventoryId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string inventory_name = 2;
 * @return {string}
 */
proto.vtv1.OutboundInventory.prototype.getInventoryName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.OutboundInventory} returns this
 */
proto.vtv1.OutboundInventory.prototype.setInventoryName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string product_id = 3;
 * @return {string}
 */
proto.vtv1.OutboundInventory.prototype.getProductId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.OutboundInventory} returns this
 */
proto.vtv1.OutboundInventory.prototype.setProductId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string product_name = 4;
 * @return {string}
 */
proto.vtv1.OutboundInventory.prototype.getProductName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.OutboundInventory} returns this
 */
proto.vtv1.OutboundInventory.prototype.setProductName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string site_id = 5;
 * @return {string}
 */
proto.vtv1.OutboundInventory.prototype.getSiteId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.OutboundInventory} returns this
 */
proto.vtv1.OutboundInventory.prototype.setSiteId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string site_name = 6;
 * @return {string}
 */
proto.vtv1.OutboundInventory.prototype.getSiteName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.OutboundInventory} returns this
 */
proto.vtv1.OutboundInventory.prototype.setSiteName = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string region = 7;
 * @return {string}
 */
proto.vtv1.OutboundInventory.prototype.getRegion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.OutboundInventory} returns this
 */
proto.vtv1.OutboundInventory.prototype.setRegion = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional double stock = 8;
 * @return {number}
 */
proto.vtv1.OutboundInventory.prototype.getStock = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.OutboundInventory} returns this
 */
proto.vtv1.OutboundInventory.prototype.setStock = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};


/**
 * optional double capacity = 9;
 * @return {number}
 */
proto.vtv1.OutboundInventory.prototype.getCapacity = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 9, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.OutboundInventory} returns this
 */
proto.vtv1.OutboundInventory.prototype.setCapacity = function(value) {
  return jspb.Message.setProto3FloatField(this, 9, value);
};


/**
 * optional NullableInt64 trucks_en_route = 10;
 * @return {?proto.vtv1.NullableInt64}
 */
proto.vtv1.OutboundInventory.prototype.getTrucksEnRoute = function() {
  return /** @type{?proto.vtv1.NullableInt64} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableInt64, 10));
};


/**
 * @param {?proto.vtv1.NullableInt64|undefined} value
 * @return {!proto.vtv1.OutboundInventory} returns this
*/
proto.vtv1.OutboundInventory.prototype.setTrucksEnRoute = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.OutboundInventory} returns this
 */
proto.vtv1.OutboundInventory.prototype.clearTrucksEnRoute = function() {
  return this.setTrucksEnRoute(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.OutboundInventory.prototype.hasTrucksEnRoute = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional NullableDouble upcoming_stock_drop = 11;
 * @return {?proto.vtv1.NullableDouble}
 */
proto.vtv1.OutboundInventory.prototype.getUpcomingStockDrop = function() {
  return /** @type{?proto.vtv1.NullableDouble} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableDouble, 11));
};


/**
 * @param {?proto.vtv1.NullableDouble|undefined} value
 * @return {!proto.vtv1.OutboundInventory} returns this
*/
proto.vtv1.OutboundInventory.prototype.setUpcomingStockDrop = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.OutboundInventory} returns this
 */
proto.vtv1.OutboundInventory.prototype.clearUpcomingStockDrop = function() {
  return this.setUpcomingStockDrop(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.OutboundInventory.prototype.hasUpcomingStockDrop = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional string stock_updated_at = 12;
 * @return {string}
 */
proto.vtv1.OutboundInventory.prototype.getStockUpdatedAt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.OutboundInventory} returns this
 */
proto.vtv1.OutboundInventory.prototype.setStockUpdatedAt = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional NullableString stock_updated_by_id = 13;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.OutboundInventory.prototype.getStockUpdatedById = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableString, 13));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.OutboundInventory} returns this
*/
proto.vtv1.OutboundInventory.prototype.setStockUpdatedById = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.OutboundInventory} returns this
 */
proto.vtv1.OutboundInventory.prototype.clearStockUpdatedById = function() {
  return this.setStockUpdatedById(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.OutboundInventory.prototype.hasStockUpdatedById = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional NullableString stock_updated_by_name = 14;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.OutboundInventory.prototype.getStockUpdatedByName = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableString, 14));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.OutboundInventory} returns this
*/
proto.vtv1.OutboundInventory.prototype.setStockUpdatedByName = function(value) {
  return jspb.Message.setWrapperField(this, 14, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.OutboundInventory} returns this
 */
proto.vtv1.OutboundInventory.prototype.clearStockUpdatedByName = function() {
  return this.setStockUpdatedByName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.OutboundInventory.prototype.hasStockUpdatedByName = function() {
  return jspb.Message.getField(this, 14) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.PendingPurchaseOrder.repeatedFields_ = [6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.PendingPurchaseOrder.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.PendingPurchaseOrder.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.PendingPurchaseOrder} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.PendingPurchaseOrder.toObject = function(includeInstance, msg) {
  var f, obj = {
    customerId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    customerName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    poName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    receivedAt: jspb.Message.getFieldWithDefault(msg, 4, ""),
    poId: jspb.Message.getFieldWithDefault(msg, 5, ""),
    unmappedProductsList: jspb.Message.toObjectList(msg.getUnmappedProductsList(),
    proto_vorto_types_types_pb.UnmappedProduct.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.PendingPurchaseOrder}
 */
proto.vtv1.PendingPurchaseOrder.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.PendingPurchaseOrder;
  return proto.vtv1.PendingPurchaseOrder.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.PendingPurchaseOrder} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.PendingPurchaseOrder}
 */
proto.vtv1.PendingPurchaseOrder.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPoName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setReceivedAt(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setPoId(value);
      break;
    case 6:
      var value = new proto_vorto_types_types_pb.UnmappedProduct;
      reader.readMessage(value,proto_vorto_types_types_pb.UnmappedProduct.deserializeBinaryFromReader);
      msg.addUnmappedProducts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.PendingPurchaseOrder.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.PendingPurchaseOrder.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.PendingPurchaseOrder} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.PendingPurchaseOrder.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCustomerId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCustomerName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPoName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getReceivedAt();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getPoId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getUnmappedProductsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto_vorto_types_types_pb.UnmappedProduct.serializeBinaryToWriter
    );
  }
};


/**
 * optional string customer_id = 1;
 * @return {string}
 */
proto.vtv1.PendingPurchaseOrder.prototype.getCustomerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.PendingPurchaseOrder} returns this
 */
proto.vtv1.PendingPurchaseOrder.prototype.setCustomerId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string customer_name = 2;
 * @return {string}
 */
proto.vtv1.PendingPurchaseOrder.prototype.getCustomerName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.PendingPurchaseOrder} returns this
 */
proto.vtv1.PendingPurchaseOrder.prototype.setCustomerName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string po_name = 3;
 * @return {string}
 */
proto.vtv1.PendingPurchaseOrder.prototype.getPoName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.PendingPurchaseOrder} returns this
 */
proto.vtv1.PendingPurchaseOrder.prototype.setPoName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string received_at = 4;
 * @return {string}
 */
proto.vtv1.PendingPurchaseOrder.prototype.getReceivedAt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.PendingPurchaseOrder} returns this
 */
proto.vtv1.PendingPurchaseOrder.prototype.setReceivedAt = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string po_id = 5;
 * @return {string}
 */
proto.vtv1.PendingPurchaseOrder.prototype.getPoId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.PendingPurchaseOrder} returns this
 */
proto.vtv1.PendingPurchaseOrder.prototype.setPoId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * repeated UnmappedProduct unmapped_products = 6;
 * @return {!Array<!proto.vtv1.UnmappedProduct>}
 */
proto.vtv1.PendingPurchaseOrder.prototype.getUnmappedProductsList = function() {
  return /** @type{!Array<!proto.vtv1.UnmappedProduct>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_vorto_types_types_pb.UnmappedProduct, 6));
};


/**
 * @param {!Array<!proto.vtv1.UnmappedProduct>} value
 * @return {!proto.vtv1.PendingPurchaseOrder} returns this
*/
proto.vtv1.PendingPurchaseOrder.prototype.setUnmappedProductsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.vtv1.UnmappedProduct=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.UnmappedProduct}
 */
proto.vtv1.PendingPurchaseOrder.prototype.addUnmappedProducts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.vtv1.UnmappedProduct, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.PendingPurchaseOrder} returns this
 */
proto.vtv1.PendingPurchaseOrder.prototype.clearUnmappedProductsList = function() {
  return this.setUnmappedProductsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.SupplierMappedProduct.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.SupplierMappedProduct.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.SupplierMappedProduct} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.SupplierMappedProduct.toObject = function(includeInstance, msg) {
  var f, obj = {
    consumerProductId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    supplierProductId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.SupplierMappedProduct}
 */
proto.vtv1.SupplierMappedProduct.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.SupplierMappedProduct;
  return proto.vtv1.SupplierMappedProduct.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.SupplierMappedProduct} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.SupplierMappedProduct}
 */
proto.vtv1.SupplierMappedProduct.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setConsumerProductId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSupplierProductId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.SupplierMappedProduct.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.SupplierMappedProduct.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.SupplierMappedProduct} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.SupplierMappedProduct.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getConsumerProductId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSupplierProductId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string consumer_product_id = 1;
 * @return {string}
 */
proto.vtv1.SupplierMappedProduct.prototype.getConsumerProductId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.SupplierMappedProduct} returns this
 */
proto.vtv1.SupplierMappedProduct.prototype.setConsumerProductId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string supplier_product_id = 2;
 * @return {string}
 */
proto.vtv1.SupplierMappedProduct.prototype.getSupplierProductId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.SupplierMappedProduct} returns this
 */
proto.vtv1.SupplierMappedProduct.prototype.setSupplierProductId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.PendingOrder.repeatedFields_ = [14];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.PendingOrder.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.PendingOrder.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.PendingOrder} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.PendingOrder.toObject = function(includeInstance, msg) {
  var f, obj = {
    customerId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    customerName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    productId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    productName: jspb.Message.getFieldWithDefault(msg, 4, ""),
    quantity: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    receivedAt: jspb.Message.getFieldWithDefault(msg, 6, ""),
    siteId: (f = msg.getSiteId()) && proto_vorto_types_types_pb.NullableString.toObject(includeInstance, f),
    siteName: (f = msg.getSiteName()) && proto_vorto_types_types_pb.NullableString.toObject(includeInstance, f),
    windowStart: jspb.Message.getFieldWithDefault(msg, 9, ""),
    windowMinutes: jspb.Message.getFieldWithDefault(msg, 10, 0),
    orderId: jspb.Message.getFieldWithDefault(msg, 11, ""),
    unitAbbreviation: jspb.Message.getFieldWithDefault(msg, 12, ""),
    unitName: jspb.Message.getFieldWithDefault(msg, 13, ""),
    productNamesList: (f = jspb.Message.getRepeatedField(msg, 14)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.PendingOrder}
 */
proto.vtv1.PendingOrder.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.PendingOrder;
  return proto.vtv1.PendingOrder.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.PendingOrder} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.PendingOrder}
 */
proto.vtv1.PendingOrder.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductName(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setQuantity(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setReceivedAt(value);
      break;
    case 7:
      var value = new proto_vorto_types_types_pb.NullableString;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableString.deserializeBinaryFromReader);
      msg.setSiteId(value);
      break;
    case 8:
      var value = new proto_vorto_types_types_pb.NullableString;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableString.deserializeBinaryFromReader);
      msg.setSiteName(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setWindowStart(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setWindowMinutes(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrderId(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setUnitAbbreviation(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setUnitName(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.addProductNames(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.PendingOrder.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.PendingOrder.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.PendingOrder} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.PendingOrder.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCustomerId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCustomerName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getProductId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getProductName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getQuantity();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getReceivedAt();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getSiteId();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto_vorto_types_types_pb.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getSiteName();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto_vorto_types_types_pb.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getWindowStart();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getWindowMinutes();
  if (f !== 0) {
    writer.writeInt64(
      10,
      f
    );
  }
  f = message.getOrderId();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getUnitAbbreviation();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getUnitName();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getProductNamesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      14,
      f
    );
  }
};


/**
 * optional string customer_id = 1;
 * @return {string}
 */
proto.vtv1.PendingOrder.prototype.getCustomerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.PendingOrder} returns this
 */
proto.vtv1.PendingOrder.prototype.setCustomerId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string customer_name = 2;
 * @return {string}
 */
proto.vtv1.PendingOrder.prototype.getCustomerName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.PendingOrder} returns this
 */
proto.vtv1.PendingOrder.prototype.setCustomerName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string product_id = 3;
 * @return {string}
 */
proto.vtv1.PendingOrder.prototype.getProductId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.PendingOrder} returns this
 */
proto.vtv1.PendingOrder.prototype.setProductId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string product_name = 4;
 * @return {string}
 */
proto.vtv1.PendingOrder.prototype.getProductName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.PendingOrder} returns this
 */
proto.vtv1.PendingOrder.prototype.setProductName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional double quantity = 5;
 * @return {number}
 */
proto.vtv1.PendingOrder.prototype.getQuantity = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.PendingOrder} returns this
 */
proto.vtv1.PendingOrder.prototype.setQuantity = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional string received_at = 6;
 * @return {string}
 */
proto.vtv1.PendingOrder.prototype.getReceivedAt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.PendingOrder} returns this
 */
proto.vtv1.PendingOrder.prototype.setReceivedAt = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional NullableString site_id = 7;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.PendingOrder.prototype.getSiteId = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableString, 7));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.PendingOrder} returns this
*/
proto.vtv1.PendingOrder.prototype.setSiteId = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.PendingOrder} returns this
 */
proto.vtv1.PendingOrder.prototype.clearSiteId = function() {
  return this.setSiteId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.PendingOrder.prototype.hasSiteId = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional NullableString site_name = 8;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.PendingOrder.prototype.getSiteName = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableString, 8));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.PendingOrder} returns this
*/
proto.vtv1.PendingOrder.prototype.setSiteName = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.PendingOrder} returns this
 */
proto.vtv1.PendingOrder.prototype.clearSiteName = function() {
  return this.setSiteName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.PendingOrder.prototype.hasSiteName = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional string window_start = 9;
 * @return {string}
 */
proto.vtv1.PendingOrder.prototype.getWindowStart = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.PendingOrder} returns this
 */
proto.vtv1.PendingOrder.prototype.setWindowStart = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional int64 window_minutes = 10;
 * @return {number}
 */
proto.vtv1.PendingOrder.prototype.getWindowMinutes = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.PendingOrder} returns this
 */
proto.vtv1.PendingOrder.prototype.setWindowMinutes = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional string order_id = 11;
 * @return {string}
 */
proto.vtv1.PendingOrder.prototype.getOrderId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.PendingOrder} returns this
 */
proto.vtv1.PendingOrder.prototype.setOrderId = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string unit_abbreviation = 12;
 * @return {string}
 */
proto.vtv1.PendingOrder.prototype.getUnitAbbreviation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.PendingOrder} returns this
 */
proto.vtv1.PendingOrder.prototype.setUnitAbbreviation = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string unit_name = 13;
 * @return {string}
 */
proto.vtv1.PendingOrder.prototype.getUnitName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.PendingOrder} returns this
 */
proto.vtv1.PendingOrder.prototype.setUnitName = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * repeated string product_names = 14;
 * @return {!Array<string>}
 */
proto.vtv1.PendingOrder.prototype.getProductNamesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 14));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.vtv1.PendingOrder} returns this
 */
proto.vtv1.PendingOrder.prototype.setProductNamesList = function(value) {
  return jspb.Message.setField(this, 14, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.vtv1.PendingOrder} returns this
 */
proto.vtv1.PendingOrder.prototype.addProductNames = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 14, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.PendingOrder} returns this
 */
proto.vtv1.PendingOrder.prototype.clearProductNamesList = function() {
  return this.setProductNamesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.SiteWithPickupToday.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.SiteWithPickupToday.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.SiteWithPickupToday.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.SiteWithPickupToday} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.SiteWithPickupToday.toObject = function(includeInstance, msg) {
  var f, obj = {
    siteId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    siteName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    dwellTime: (f = msg.getDwellTime()) && proto_vorto_types_types_pb.NullableDouble.toObject(includeInstance, f),
    productsList: jspb.Message.toObjectList(msg.getProductsList(),
    proto.vtv1.SiteWithPickUpProduct.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.SiteWithPickupToday}
 */
proto.vtv1.SiteWithPickupToday.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.SiteWithPickupToday;
  return proto.vtv1.SiteWithPickupToday.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.SiteWithPickupToday} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.SiteWithPickupToday}
 */
proto.vtv1.SiteWithPickupToday.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSiteId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSiteName(value);
      break;
    case 3:
      var value = new proto_vorto_types_types_pb.NullableDouble;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableDouble.deserializeBinaryFromReader);
      msg.setDwellTime(value);
      break;
    case 4:
      var value = new proto.vtv1.SiteWithPickUpProduct;
      reader.readMessage(value,proto.vtv1.SiteWithPickUpProduct.deserializeBinaryFromReader);
      msg.addProducts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.SiteWithPickupToday.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.SiteWithPickupToday.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.SiteWithPickupToday} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.SiteWithPickupToday.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSiteId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSiteName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDwellTime();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto_vorto_types_types_pb.NullableDouble.serializeBinaryToWriter
    );
  }
  f = message.getProductsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.vtv1.SiteWithPickUpProduct.serializeBinaryToWriter
    );
  }
};


/**
 * optional string site_id = 1;
 * @return {string}
 */
proto.vtv1.SiteWithPickupToday.prototype.getSiteId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.SiteWithPickupToday} returns this
 */
proto.vtv1.SiteWithPickupToday.prototype.setSiteId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string site_name = 2;
 * @return {string}
 */
proto.vtv1.SiteWithPickupToday.prototype.getSiteName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.SiteWithPickupToday} returns this
 */
proto.vtv1.SiteWithPickupToday.prototype.setSiteName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional NullableDouble dwell_time = 3;
 * @return {?proto.vtv1.NullableDouble}
 */
proto.vtv1.SiteWithPickupToday.prototype.getDwellTime = function() {
  return /** @type{?proto.vtv1.NullableDouble} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableDouble, 3));
};


/**
 * @param {?proto.vtv1.NullableDouble|undefined} value
 * @return {!proto.vtv1.SiteWithPickupToday} returns this
*/
proto.vtv1.SiteWithPickupToday.prototype.setDwellTime = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.SiteWithPickupToday} returns this
 */
proto.vtv1.SiteWithPickupToday.prototype.clearDwellTime = function() {
  return this.setDwellTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.SiteWithPickupToday.prototype.hasDwellTime = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * repeated SiteWithPickUpProduct products = 4;
 * @return {!Array<!proto.vtv1.SiteWithPickUpProduct>}
 */
proto.vtv1.SiteWithPickupToday.prototype.getProductsList = function() {
  return /** @type{!Array<!proto.vtv1.SiteWithPickUpProduct>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vtv1.SiteWithPickUpProduct, 4));
};


/**
 * @param {!Array<!proto.vtv1.SiteWithPickUpProduct>} value
 * @return {!proto.vtv1.SiteWithPickupToday} returns this
*/
proto.vtv1.SiteWithPickupToday.prototype.setProductsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.vtv1.SiteWithPickUpProduct=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.SiteWithPickUpProduct}
 */
proto.vtv1.SiteWithPickupToday.prototype.addProducts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.vtv1.SiteWithPickUpProduct, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.SiteWithPickupToday} returns this
 */
proto.vtv1.SiteWithPickupToday.prototype.clearProductsList = function() {
  return this.setProductsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.SiteWithPickUpProduct.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.SiteWithPickUpProduct.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.SiteWithPickUpProduct} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.SiteWithPickUpProduct.toObject = function(includeInstance, msg) {
  var f, obj = {
    productId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    productName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    loadsToday: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.SiteWithPickUpProduct}
 */
proto.vtv1.SiteWithPickUpProduct.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.SiteWithPickUpProduct;
  return proto.vtv1.SiteWithPickUpProduct.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.SiteWithPickUpProduct} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.SiteWithPickUpProduct}
 */
proto.vtv1.SiteWithPickUpProduct.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductName(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLoadsToday(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.SiteWithPickUpProduct.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.SiteWithPickUpProduct.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.SiteWithPickUpProduct} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.SiteWithPickUpProduct.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProductId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getProductName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLoadsToday();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
};


/**
 * optional string product_id = 1;
 * @return {string}
 */
proto.vtv1.SiteWithPickUpProduct.prototype.getProductId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.SiteWithPickUpProduct} returns this
 */
proto.vtv1.SiteWithPickUpProduct.prototype.setProductId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string product_name = 2;
 * @return {string}
 */
proto.vtv1.SiteWithPickUpProduct.prototype.getProductName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.SiteWithPickUpProduct} returns this
 */
proto.vtv1.SiteWithPickUpProduct.prototype.setProductName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 loads_today = 3;
 * @return {number}
 */
proto.vtv1.SiteWithPickUpProduct.prototype.getLoadsToday = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.SiteWithPickUpProduct} returns this
 */
proto.vtv1.SiteWithPickUpProduct.prototype.setLoadsToday = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.CreateOrderSite.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.CreateOrderSite.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.CreateOrderSite} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.CreateOrderSite.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    supplierId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    supplierName: jspb.Message.getFieldWithDefault(msg, 4, ""),
    longitude: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    latitude: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    radius: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    eta: (f = msg.getEta()) && proto_vorto_types_types_pb.NullableInt64.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.CreateOrderSite}
 */
proto.vtv1.CreateOrderSite.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.CreateOrderSite;
  return proto.vtv1.CreateOrderSite.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.CreateOrderSite} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.CreateOrderSite}
 */
proto.vtv1.CreateOrderSite.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSupplierId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setSupplierName(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLongitude(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLatitude(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setRadius(value);
      break;
    case 8:
      var value = new proto_vorto_types_types_pb.NullableInt64;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableInt64.deserializeBinaryFromReader);
      msg.setEta(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.CreateOrderSite.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.CreateOrderSite.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.CreateOrderSite} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.CreateOrderSite.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSupplierId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSupplierName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getLongitude();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getLatitude();
  if (f !== 0.0) {
    writer.writeDouble(
      6,
      f
    );
  }
  f = message.getRadius();
  if (f !== 0.0) {
    writer.writeDouble(
      7,
      f
    );
  }
  f = message.getEta();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto_vorto_types_types_pb.NullableInt64.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.vtv1.CreateOrderSite.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.CreateOrderSite} returns this
 */
proto.vtv1.CreateOrderSite.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.vtv1.CreateOrderSite.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.CreateOrderSite} returns this
 */
proto.vtv1.CreateOrderSite.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string supplier_id = 3;
 * @return {string}
 */
proto.vtv1.CreateOrderSite.prototype.getSupplierId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.CreateOrderSite} returns this
 */
proto.vtv1.CreateOrderSite.prototype.setSupplierId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string supplier_name = 4;
 * @return {string}
 */
proto.vtv1.CreateOrderSite.prototype.getSupplierName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.CreateOrderSite} returns this
 */
proto.vtv1.CreateOrderSite.prototype.setSupplierName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional double longitude = 5;
 * @return {number}
 */
proto.vtv1.CreateOrderSite.prototype.getLongitude = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.CreateOrderSite} returns this
 */
proto.vtv1.CreateOrderSite.prototype.setLongitude = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional double latitude = 6;
 * @return {number}
 */
proto.vtv1.CreateOrderSite.prototype.getLatitude = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.CreateOrderSite} returns this
 */
proto.vtv1.CreateOrderSite.prototype.setLatitude = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional double radius = 7;
 * @return {number}
 */
proto.vtv1.CreateOrderSite.prototype.getRadius = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.CreateOrderSite} returns this
 */
proto.vtv1.CreateOrderSite.prototype.setRadius = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional NullableInt64 eta = 8;
 * @return {?proto.vtv1.NullableInt64}
 */
proto.vtv1.CreateOrderSite.prototype.getEta = function() {
  return /** @type{?proto.vtv1.NullableInt64} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableInt64, 8));
};


/**
 * @param {?proto.vtv1.NullableInt64|undefined} value
 * @return {!proto.vtv1.CreateOrderSite} returns this
*/
proto.vtv1.CreateOrderSite.prototype.setEta = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.CreateOrderSite} returns this
 */
proto.vtv1.CreateOrderSite.prototype.clearEta = function() {
  return this.setEta(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.CreateOrderSite.prototype.hasEta = function() {
  return jspb.Message.getField(this, 8) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.FuelMatrix.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.FuelMatrix.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.FuelMatrix.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.FuelMatrix} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.FuelMatrix.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    trailerTypeId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    trailerTypeName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    entriesList: jspb.Message.toObjectList(msg.getEntriesList(),
    proto.vtv1.FuelMatrixEntry.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.FuelMatrix}
 */
proto.vtv1.FuelMatrix.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.FuelMatrix;
  return proto.vtv1.FuelMatrix.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.FuelMatrix} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.FuelMatrix}
 */
proto.vtv1.FuelMatrix.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTrailerTypeId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTrailerTypeName(value);
      break;
    case 4:
      var value = new proto.vtv1.FuelMatrixEntry;
      reader.readMessage(value,proto.vtv1.FuelMatrixEntry.deserializeBinaryFromReader);
      msg.addEntries(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.FuelMatrix.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.FuelMatrix.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.FuelMatrix} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.FuelMatrix.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTrailerTypeId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getTrailerTypeName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getEntriesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.vtv1.FuelMatrixEntry.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.vtv1.FuelMatrix.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.FuelMatrix} returns this
 */
proto.vtv1.FuelMatrix.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 trailer_type_id = 2;
 * @return {number}
 */
proto.vtv1.FuelMatrix.prototype.getTrailerTypeId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.FuelMatrix} returns this
 */
proto.vtv1.FuelMatrix.prototype.setTrailerTypeId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string trailer_type_name = 3;
 * @return {string}
 */
proto.vtv1.FuelMatrix.prototype.getTrailerTypeName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.FuelMatrix} returns this
 */
proto.vtv1.FuelMatrix.prototype.setTrailerTypeName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated FuelMatrixEntry entries = 4;
 * @return {!Array<!proto.vtv1.FuelMatrixEntry>}
 */
proto.vtv1.FuelMatrix.prototype.getEntriesList = function() {
  return /** @type{!Array<!proto.vtv1.FuelMatrixEntry>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vtv1.FuelMatrixEntry, 4));
};


/**
 * @param {!Array<!proto.vtv1.FuelMatrixEntry>} value
 * @return {!proto.vtv1.FuelMatrix} returns this
*/
proto.vtv1.FuelMatrix.prototype.setEntriesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.vtv1.FuelMatrixEntry=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.FuelMatrixEntry}
 */
proto.vtv1.FuelMatrix.prototype.addEntries = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.vtv1.FuelMatrixEntry, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.FuelMatrix} returns this
 */
proto.vtv1.FuelMatrix.prototype.clearEntriesList = function() {
  return this.setEntriesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.FuelMatrixEntry.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.FuelMatrixEntry.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.FuelMatrixEntry} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.FuelMatrixEntry.toObject = function(includeInstance, msg) {
  var f, obj = {
    lowerBoundPennies: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    upperBoundPennies: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    costInPennies: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.FuelMatrixEntry}
 */
proto.vtv1.FuelMatrixEntry.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.FuelMatrixEntry;
  return proto.vtv1.FuelMatrixEntry.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.FuelMatrixEntry} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.FuelMatrixEntry}
 */
proto.vtv1.FuelMatrixEntry.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLowerBoundPennies(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setUpperBoundPennies(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setCostInPennies(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.FuelMatrixEntry.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.FuelMatrixEntry.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.FuelMatrixEntry} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.FuelMatrixEntry.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLowerBoundPennies();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = message.getUpperBoundPennies();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getCostInPennies();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
};


/**
 * optional double lower_bound_pennies = 1;
 * @return {number}
 */
proto.vtv1.FuelMatrixEntry.prototype.getLowerBoundPennies = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.FuelMatrixEntry} returns this
 */
proto.vtv1.FuelMatrixEntry.prototype.setLowerBoundPennies = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional double upper_bound_pennies = 2;
 * @return {number}
 */
proto.vtv1.FuelMatrixEntry.prototype.getUpperBoundPennies = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.FuelMatrixEntry} returns this
 */
proto.vtv1.FuelMatrixEntry.prototype.setUpperBoundPennies = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double cost_in_pennies = 3;
 * @return {number}
 */
proto.vtv1.FuelMatrixEntry.prototype.getCostInPennies = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.FuelMatrixEntry} returns this
 */
proto.vtv1.FuelMatrixEntry.prototype.setCostInPennies = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.PotentialInventoryPurchaseOrder.repeatedFields_ = [12];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.PotentialInventoryPurchaseOrder.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.PotentialInventoryPurchaseOrder.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.PotentialInventoryPurchaseOrder} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.PotentialInventoryPurchaseOrder.toObject = function(includeInstance, msg) {
  var f, obj = {
    poId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    poName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    supplierId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    supplierName: jspb.Message.getFieldWithDefault(msg, 4, ""),
    costPerUnit: jspb.Message.getFieldWithDefault(msg, 5, 0),
    lineItemNumber: jspb.Message.getFieldWithDefault(msg, 6, ""),
    orderedToday: jspb.Message.getFieldWithDefault(msg, 7, 0),
    dailyMax: (f = msg.getDailyMax()) && proto_vorto_types_types_pb.NullableDouble.toObject(includeInstance, f),
    orderedTotal: jspb.Message.getFieldWithDefault(msg, 9, 0),
    totalMax: (f = msg.getTotalMax()) && proto_vorto_types_types_pb.NullableDouble.toObject(includeInstance, f),
    confirmedAt: (f = msg.getConfirmedAt()) && proto_vorto_types_types_pb.NullableTime.toObject(includeInstance, f),
    supplierSitesList: jspb.Message.toObjectList(msg.getSupplierSitesList(),
    proto_vorto_types_types_pb.StringIdName.toObject, includeInstance),
    effectiveDate: (f = msg.getEffectiveDate()) && proto_vorto_types_types_pb.NullableTime.toObject(includeInstance, f),
    pausedBy: (f = msg.getPausedBy()) && proto_vorto_types_types_pb.NullableString.toObject(includeInstance, f),
    expiresAt: (f = msg.getExpiresAt()) && proto_vorto_types_types_pb.NullableTime.toObject(includeInstance, f),
    scheduledToday: jspb.Message.getFloatingPointFieldWithDefault(msg, 16, 0.0),
    scheduledTotal: jspb.Message.getFloatingPointFieldWithDefault(msg, 17, 0.0),
    potentialCarriers: jspb.Message.getFieldWithDefault(msg, 18, 0),
    consumerIsShipper: jspb.Message.getBooleanFieldWithDefault(msg, 19, false),
    canAddBackhaul: jspb.Message.getBooleanFieldWithDefault(msg, 20, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.PotentialInventoryPurchaseOrder}
 */
proto.vtv1.PotentialInventoryPurchaseOrder.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.PotentialInventoryPurchaseOrder;
  return proto.vtv1.PotentialInventoryPurchaseOrder.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.PotentialInventoryPurchaseOrder} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.PotentialInventoryPurchaseOrder}
 */
proto.vtv1.PotentialInventoryPurchaseOrder.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPoId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPoName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSupplierId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setSupplierName(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCostPerUnit(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setLineItemNumber(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setOrderedToday(value);
      break;
    case 8:
      var value = new proto_vorto_types_types_pb.NullableDouble;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableDouble.deserializeBinaryFromReader);
      msg.setDailyMax(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setOrderedTotal(value);
      break;
    case 10:
      var value = new proto_vorto_types_types_pb.NullableDouble;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableDouble.deserializeBinaryFromReader);
      msg.setTotalMax(value);
      break;
    case 11:
      var value = new proto_vorto_types_types_pb.NullableTime;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableTime.deserializeBinaryFromReader);
      msg.setConfirmedAt(value);
      break;
    case 12:
      var value = new proto_vorto_types_types_pb.StringIdName;
      reader.readMessage(value,proto_vorto_types_types_pb.StringIdName.deserializeBinaryFromReader);
      msg.addSupplierSites(value);
      break;
    case 13:
      var value = new proto_vorto_types_types_pb.NullableTime;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableTime.deserializeBinaryFromReader);
      msg.setEffectiveDate(value);
      break;
    case 14:
      var value = new proto_vorto_types_types_pb.NullableString;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableString.deserializeBinaryFromReader);
      msg.setPausedBy(value);
      break;
    case 15:
      var value = new proto_vorto_types_types_pb.NullableTime;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableTime.deserializeBinaryFromReader);
      msg.setExpiresAt(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setScheduledToday(value);
      break;
    case 17:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setScheduledTotal(value);
      break;
    case 18:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPotentialCarriers(value);
      break;
    case 19:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setConsumerIsShipper(value);
      break;
    case 20:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCanAddBackhaul(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.PotentialInventoryPurchaseOrder.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.PotentialInventoryPurchaseOrder.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.PotentialInventoryPurchaseOrder} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.PotentialInventoryPurchaseOrder.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPoId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPoName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSupplierId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSupplierName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCostPerUnit();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getLineItemNumber();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getOrderedToday();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
  f = message.getDailyMax();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto_vorto_types_types_pb.NullableDouble.serializeBinaryToWriter
    );
  }
  f = message.getOrderedTotal();
  if (f !== 0) {
    writer.writeInt64(
      9,
      f
    );
  }
  f = message.getTotalMax();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto_vorto_types_types_pb.NullableDouble.serializeBinaryToWriter
    );
  }
  f = message.getConfirmedAt();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto_vorto_types_types_pb.NullableTime.serializeBinaryToWriter
    );
  }
  f = message.getSupplierSitesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      12,
      f,
      proto_vorto_types_types_pb.StringIdName.serializeBinaryToWriter
    );
  }
  f = message.getEffectiveDate();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      proto_vorto_types_types_pb.NullableTime.serializeBinaryToWriter
    );
  }
  f = message.getPausedBy();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      proto_vorto_types_types_pb.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getExpiresAt();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      proto_vorto_types_types_pb.NullableTime.serializeBinaryToWriter
    );
  }
  f = message.getScheduledToday();
  if (f !== 0.0) {
    writer.writeDouble(
      16,
      f
    );
  }
  f = message.getScheduledTotal();
  if (f !== 0.0) {
    writer.writeDouble(
      17,
      f
    );
  }
  f = message.getPotentialCarriers();
  if (f !== 0) {
    writer.writeInt64(
      18,
      f
    );
  }
  f = message.getConsumerIsShipper();
  if (f) {
    writer.writeBool(
      19,
      f
    );
  }
  f = message.getCanAddBackhaul();
  if (f) {
    writer.writeBool(
      20,
      f
    );
  }
};


/**
 * optional string po_id = 1;
 * @return {string}
 */
proto.vtv1.PotentialInventoryPurchaseOrder.prototype.getPoId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.PotentialInventoryPurchaseOrder} returns this
 */
proto.vtv1.PotentialInventoryPurchaseOrder.prototype.setPoId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string po_name = 2;
 * @return {string}
 */
proto.vtv1.PotentialInventoryPurchaseOrder.prototype.getPoName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.PotentialInventoryPurchaseOrder} returns this
 */
proto.vtv1.PotentialInventoryPurchaseOrder.prototype.setPoName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string supplier_id = 3;
 * @return {string}
 */
proto.vtv1.PotentialInventoryPurchaseOrder.prototype.getSupplierId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.PotentialInventoryPurchaseOrder} returns this
 */
proto.vtv1.PotentialInventoryPurchaseOrder.prototype.setSupplierId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string supplier_name = 4;
 * @return {string}
 */
proto.vtv1.PotentialInventoryPurchaseOrder.prototype.getSupplierName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.PotentialInventoryPurchaseOrder} returns this
 */
proto.vtv1.PotentialInventoryPurchaseOrder.prototype.setSupplierName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int64 cost_per_unit = 5;
 * @return {number}
 */
proto.vtv1.PotentialInventoryPurchaseOrder.prototype.getCostPerUnit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.PotentialInventoryPurchaseOrder} returns this
 */
proto.vtv1.PotentialInventoryPurchaseOrder.prototype.setCostPerUnit = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional string line_item_number = 6;
 * @return {string}
 */
proto.vtv1.PotentialInventoryPurchaseOrder.prototype.getLineItemNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.PotentialInventoryPurchaseOrder} returns this
 */
proto.vtv1.PotentialInventoryPurchaseOrder.prototype.setLineItemNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional int64 ordered_today = 7;
 * @return {number}
 */
proto.vtv1.PotentialInventoryPurchaseOrder.prototype.getOrderedToday = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.PotentialInventoryPurchaseOrder} returns this
 */
proto.vtv1.PotentialInventoryPurchaseOrder.prototype.setOrderedToday = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional NullableDouble daily_max = 8;
 * @return {?proto.vtv1.NullableDouble}
 */
proto.vtv1.PotentialInventoryPurchaseOrder.prototype.getDailyMax = function() {
  return /** @type{?proto.vtv1.NullableDouble} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableDouble, 8));
};


/**
 * @param {?proto.vtv1.NullableDouble|undefined} value
 * @return {!proto.vtv1.PotentialInventoryPurchaseOrder} returns this
*/
proto.vtv1.PotentialInventoryPurchaseOrder.prototype.setDailyMax = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.PotentialInventoryPurchaseOrder} returns this
 */
proto.vtv1.PotentialInventoryPurchaseOrder.prototype.clearDailyMax = function() {
  return this.setDailyMax(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.PotentialInventoryPurchaseOrder.prototype.hasDailyMax = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional int64 ordered_total = 9;
 * @return {number}
 */
proto.vtv1.PotentialInventoryPurchaseOrder.prototype.getOrderedTotal = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.PotentialInventoryPurchaseOrder} returns this
 */
proto.vtv1.PotentialInventoryPurchaseOrder.prototype.setOrderedTotal = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional NullableDouble total_max = 10;
 * @return {?proto.vtv1.NullableDouble}
 */
proto.vtv1.PotentialInventoryPurchaseOrder.prototype.getTotalMax = function() {
  return /** @type{?proto.vtv1.NullableDouble} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableDouble, 10));
};


/**
 * @param {?proto.vtv1.NullableDouble|undefined} value
 * @return {!proto.vtv1.PotentialInventoryPurchaseOrder} returns this
*/
proto.vtv1.PotentialInventoryPurchaseOrder.prototype.setTotalMax = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.PotentialInventoryPurchaseOrder} returns this
 */
proto.vtv1.PotentialInventoryPurchaseOrder.prototype.clearTotalMax = function() {
  return this.setTotalMax(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.PotentialInventoryPurchaseOrder.prototype.hasTotalMax = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional NullableTime confirmed_at = 11;
 * @return {?proto.vtv1.NullableTime}
 */
proto.vtv1.PotentialInventoryPurchaseOrder.prototype.getConfirmedAt = function() {
  return /** @type{?proto.vtv1.NullableTime} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableTime, 11));
};


/**
 * @param {?proto.vtv1.NullableTime|undefined} value
 * @return {!proto.vtv1.PotentialInventoryPurchaseOrder} returns this
*/
proto.vtv1.PotentialInventoryPurchaseOrder.prototype.setConfirmedAt = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.PotentialInventoryPurchaseOrder} returns this
 */
proto.vtv1.PotentialInventoryPurchaseOrder.prototype.clearConfirmedAt = function() {
  return this.setConfirmedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.PotentialInventoryPurchaseOrder.prototype.hasConfirmedAt = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * repeated StringIdName supplier_sites = 12;
 * @return {!Array<!proto.vtv1.StringIdName>}
 */
proto.vtv1.PotentialInventoryPurchaseOrder.prototype.getSupplierSitesList = function() {
  return /** @type{!Array<!proto.vtv1.StringIdName>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_vorto_types_types_pb.StringIdName, 12));
};


/**
 * @param {!Array<!proto.vtv1.StringIdName>} value
 * @return {!proto.vtv1.PotentialInventoryPurchaseOrder} returns this
*/
proto.vtv1.PotentialInventoryPurchaseOrder.prototype.setSupplierSitesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 12, value);
};


/**
 * @param {!proto.vtv1.StringIdName=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.StringIdName}
 */
proto.vtv1.PotentialInventoryPurchaseOrder.prototype.addSupplierSites = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 12, opt_value, proto.vtv1.StringIdName, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.PotentialInventoryPurchaseOrder} returns this
 */
proto.vtv1.PotentialInventoryPurchaseOrder.prototype.clearSupplierSitesList = function() {
  return this.setSupplierSitesList([]);
};


/**
 * optional NullableTime effective_date = 13;
 * @return {?proto.vtv1.NullableTime}
 */
proto.vtv1.PotentialInventoryPurchaseOrder.prototype.getEffectiveDate = function() {
  return /** @type{?proto.vtv1.NullableTime} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableTime, 13));
};


/**
 * @param {?proto.vtv1.NullableTime|undefined} value
 * @return {!proto.vtv1.PotentialInventoryPurchaseOrder} returns this
*/
proto.vtv1.PotentialInventoryPurchaseOrder.prototype.setEffectiveDate = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.PotentialInventoryPurchaseOrder} returns this
 */
proto.vtv1.PotentialInventoryPurchaseOrder.prototype.clearEffectiveDate = function() {
  return this.setEffectiveDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.PotentialInventoryPurchaseOrder.prototype.hasEffectiveDate = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional NullableString paused_by = 14;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.PotentialInventoryPurchaseOrder.prototype.getPausedBy = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableString, 14));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.PotentialInventoryPurchaseOrder} returns this
*/
proto.vtv1.PotentialInventoryPurchaseOrder.prototype.setPausedBy = function(value) {
  return jspb.Message.setWrapperField(this, 14, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.PotentialInventoryPurchaseOrder} returns this
 */
proto.vtv1.PotentialInventoryPurchaseOrder.prototype.clearPausedBy = function() {
  return this.setPausedBy(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.PotentialInventoryPurchaseOrder.prototype.hasPausedBy = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional NullableTime expires_at = 15;
 * @return {?proto.vtv1.NullableTime}
 */
proto.vtv1.PotentialInventoryPurchaseOrder.prototype.getExpiresAt = function() {
  return /** @type{?proto.vtv1.NullableTime} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableTime, 15));
};


/**
 * @param {?proto.vtv1.NullableTime|undefined} value
 * @return {!proto.vtv1.PotentialInventoryPurchaseOrder} returns this
*/
proto.vtv1.PotentialInventoryPurchaseOrder.prototype.setExpiresAt = function(value) {
  return jspb.Message.setWrapperField(this, 15, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.PotentialInventoryPurchaseOrder} returns this
 */
proto.vtv1.PotentialInventoryPurchaseOrder.prototype.clearExpiresAt = function() {
  return this.setExpiresAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.PotentialInventoryPurchaseOrder.prototype.hasExpiresAt = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional double scheduled_today = 16;
 * @return {number}
 */
proto.vtv1.PotentialInventoryPurchaseOrder.prototype.getScheduledToday = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 16, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.PotentialInventoryPurchaseOrder} returns this
 */
proto.vtv1.PotentialInventoryPurchaseOrder.prototype.setScheduledToday = function(value) {
  return jspb.Message.setProto3FloatField(this, 16, value);
};


/**
 * optional double scheduled_total = 17;
 * @return {number}
 */
proto.vtv1.PotentialInventoryPurchaseOrder.prototype.getScheduledTotal = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 17, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.PotentialInventoryPurchaseOrder} returns this
 */
proto.vtv1.PotentialInventoryPurchaseOrder.prototype.setScheduledTotal = function(value) {
  return jspb.Message.setProto3FloatField(this, 17, value);
};


/**
 * optional int64 potential_carriers = 18;
 * @return {number}
 */
proto.vtv1.PotentialInventoryPurchaseOrder.prototype.getPotentialCarriers = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 18, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.PotentialInventoryPurchaseOrder} returns this
 */
proto.vtv1.PotentialInventoryPurchaseOrder.prototype.setPotentialCarriers = function(value) {
  return jspb.Message.setProto3IntField(this, 18, value);
};


/**
 * optional bool consumer_is_shipper = 19;
 * @return {boolean}
 */
proto.vtv1.PotentialInventoryPurchaseOrder.prototype.getConsumerIsShipper = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 19, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vtv1.PotentialInventoryPurchaseOrder} returns this
 */
proto.vtv1.PotentialInventoryPurchaseOrder.prototype.setConsumerIsShipper = function(value) {
  return jspb.Message.setProto3BooleanField(this, 19, value);
};


/**
 * optional bool can_add_backhaul = 20;
 * @return {boolean}
 */
proto.vtv1.PotentialInventoryPurchaseOrder.prototype.getCanAddBackhaul = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 20, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vtv1.PotentialInventoryPurchaseOrder} returns this
 */
proto.vtv1.PotentialInventoryPurchaseOrder.prototype.setCanAddBackhaul = function(value) {
  return jspb.Message.setProto3BooleanField(this, 20, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.PotentialInventoryPurchaseOrderV2.repeatedFields_ = [6,10];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.PotentialInventoryPurchaseOrderV2.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.PotentialInventoryPurchaseOrderV2.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.PotentialInventoryPurchaseOrderV2} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.PotentialInventoryPurchaseOrderV2.toObject = function(includeInstance, msg) {
  var f, obj = {
    poId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    poName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    supplierId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    supplierName: jspb.Message.getFieldWithDefault(msg, 4, ""),
    confirmedAt: (f = msg.getConfirmedAt()) && proto_vorto_types_types_pb.NullableTime.toObject(includeInstance, f),
    supplierSitesList: jspb.Message.toObjectList(msg.getSupplierSitesList(),
    proto_vorto_types_types_pb.SlimSiteWithLngLat.toObject, includeInstance),
    effectiveDate: jspb.Message.getFieldWithDefault(msg, 7, ""),
    pausedBy: (f = msg.getPausedBy()) && proto_vorto_types_types_pb.NullableString.toObject(includeInstance, f),
    expiresAt: (f = msg.getExpiresAt()) && proto_vorto_types_types_pb.NullableTime.toObject(includeInstance, f),
    lineItemsList: jspb.Message.toObjectList(msg.getLineItemsList(),
    proto.vtv1.PotentialInventoryPurchaseOrderV2LineItem.toObject, includeInstance),
    isExpired: jspb.Message.getBooleanFieldWithDefault(msg, 11, false),
    isCloseToExpiring: jspb.Message.getBooleanFieldWithDefault(msg, 12, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.PotentialInventoryPurchaseOrderV2}
 */
proto.vtv1.PotentialInventoryPurchaseOrderV2.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.PotentialInventoryPurchaseOrderV2;
  return proto.vtv1.PotentialInventoryPurchaseOrderV2.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.PotentialInventoryPurchaseOrderV2} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.PotentialInventoryPurchaseOrderV2}
 */
proto.vtv1.PotentialInventoryPurchaseOrderV2.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPoId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPoName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSupplierId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setSupplierName(value);
      break;
    case 5:
      var value = new proto_vorto_types_types_pb.NullableTime;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableTime.deserializeBinaryFromReader);
      msg.setConfirmedAt(value);
      break;
    case 6:
      var value = new proto_vorto_types_types_pb.SlimSiteWithLngLat;
      reader.readMessage(value,proto_vorto_types_types_pb.SlimSiteWithLngLat.deserializeBinaryFromReader);
      msg.addSupplierSites(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setEffectiveDate(value);
      break;
    case 8:
      var value = new proto_vorto_types_types_pb.NullableString;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableString.deserializeBinaryFromReader);
      msg.setPausedBy(value);
      break;
    case 9:
      var value = new proto_vorto_types_types_pb.NullableTime;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableTime.deserializeBinaryFromReader);
      msg.setExpiresAt(value);
      break;
    case 10:
      var value = new proto.vtv1.PotentialInventoryPurchaseOrderV2LineItem;
      reader.readMessage(value,proto.vtv1.PotentialInventoryPurchaseOrderV2LineItem.deserializeBinaryFromReader);
      msg.addLineItems(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsExpired(value);
      break;
    case 12:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsCloseToExpiring(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.PotentialInventoryPurchaseOrderV2.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.PotentialInventoryPurchaseOrderV2.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.PotentialInventoryPurchaseOrderV2} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.PotentialInventoryPurchaseOrderV2.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPoId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPoName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSupplierId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSupplierName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getConfirmedAt();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto_vorto_types_types_pb.NullableTime.serializeBinaryToWriter
    );
  }
  f = message.getSupplierSitesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto_vorto_types_types_pb.SlimSiteWithLngLat.serializeBinaryToWriter
    );
  }
  f = message.getEffectiveDate();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getPausedBy();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto_vorto_types_types_pb.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getExpiresAt();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto_vorto_types_types_pb.NullableTime.serializeBinaryToWriter
    );
  }
  f = message.getLineItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      10,
      f,
      proto.vtv1.PotentialInventoryPurchaseOrderV2LineItem.serializeBinaryToWriter
    );
  }
  f = message.getIsExpired();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
  f = message.getIsCloseToExpiring();
  if (f) {
    writer.writeBool(
      12,
      f
    );
  }
};


/**
 * optional string po_id = 1;
 * @return {string}
 */
proto.vtv1.PotentialInventoryPurchaseOrderV2.prototype.getPoId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.PotentialInventoryPurchaseOrderV2} returns this
 */
proto.vtv1.PotentialInventoryPurchaseOrderV2.prototype.setPoId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string po_name = 2;
 * @return {string}
 */
proto.vtv1.PotentialInventoryPurchaseOrderV2.prototype.getPoName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.PotentialInventoryPurchaseOrderV2} returns this
 */
proto.vtv1.PotentialInventoryPurchaseOrderV2.prototype.setPoName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string supplier_id = 3;
 * @return {string}
 */
proto.vtv1.PotentialInventoryPurchaseOrderV2.prototype.getSupplierId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.PotentialInventoryPurchaseOrderV2} returns this
 */
proto.vtv1.PotentialInventoryPurchaseOrderV2.prototype.setSupplierId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string supplier_name = 4;
 * @return {string}
 */
proto.vtv1.PotentialInventoryPurchaseOrderV2.prototype.getSupplierName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.PotentialInventoryPurchaseOrderV2} returns this
 */
proto.vtv1.PotentialInventoryPurchaseOrderV2.prototype.setSupplierName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional NullableTime confirmed_at = 5;
 * @return {?proto.vtv1.NullableTime}
 */
proto.vtv1.PotentialInventoryPurchaseOrderV2.prototype.getConfirmedAt = function() {
  return /** @type{?proto.vtv1.NullableTime} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableTime, 5));
};


/**
 * @param {?proto.vtv1.NullableTime|undefined} value
 * @return {!proto.vtv1.PotentialInventoryPurchaseOrderV2} returns this
*/
proto.vtv1.PotentialInventoryPurchaseOrderV2.prototype.setConfirmedAt = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.PotentialInventoryPurchaseOrderV2} returns this
 */
proto.vtv1.PotentialInventoryPurchaseOrderV2.prototype.clearConfirmedAt = function() {
  return this.setConfirmedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.PotentialInventoryPurchaseOrderV2.prototype.hasConfirmedAt = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * repeated SlimSiteWithLngLat supplier_sites = 6;
 * @return {!Array<!proto.vtv1.SlimSiteWithLngLat>}
 */
proto.vtv1.PotentialInventoryPurchaseOrderV2.prototype.getSupplierSitesList = function() {
  return /** @type{!Array<!proto.vtv1.SlimSiteWithLngLat>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_vorto_types_types_pb.SlimSiteWithLngLat, 6));
};


/**
 * @param {!Array<!proto.vtv1.SlimSiteWithLngLat>} value
 * @return {!proto.vtv1.PotentialInventoryPurchaseOrderV2} returns this
*/
proto.vtv1.PotentialInventoryPurchaseOrderV2.prototype.setSupplierSitesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.vtv1.SlimSiteWithLngLat=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.SlimSiteWithLngLat}
 */
proto.vtv1.PotentialInventoryPurchaseOrderV2.prototype.addSupplierSites = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.vtv1.SlimSiteWithLngLat, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.PotentialInventoryPurchaseOrderV2} returns this
 */
proto.vtv1.PotentialInventoryPurchaseOrderV2.prototype.clearSupplierSitesList = function() {
  return this.setSupplierSitesList([]);
};


/**
 * optional string effective_date = 7;
 * @return {string}
 */
proto.vtv1.PotentialInventoryPurchaseOrderV2.prototype.getEffectiveDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.PotentialInventoryPurchaseOrderV2} returns this
 */
proto.vtv1.PotentialInventoryPurchaseOrderV2.prototype.setEffectiveDate = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional NullableString paused_by = 8;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.PotentialInventoryPurchaseOrderV2.prototype.getPausedBy = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableString, 8));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.PotentialInventoryPurchaseOrderV2} returns this
*/
proto.vtv1.PotentialInventoryPurchaseOrderV2.prototype.setPausedBy = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.PotentialInventoryPurchaseOrderV2} returns this
 */
proto.vtv1.PotentialInventoryPurchaseOrderV2.prototype.clearPausedBy = function() {
  return this.setPausedBy(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.PotentialInventoryPurchaseOrderV2.prototype.hasPausedBy = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional NullableTime expires_at = 9;
 * @return {?proto.vtv1.NullableTime}
 */
proto.vtv1.PotentialInventoryPurchaseOrderV2.prototype.getExpiresAt = function() {
  return /** @type{?proto.vtv1.NullableTime} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableTime, 9));
};


/**
 * @param {?proto.vtv1.NullableTime|undefined} value
 * @return {!proto.vtv1.PotentialInventoryPurchaseOrderV2} returns this
*/
proto.vtv1.PotentialInventoryPurchaseOrderV2.prototype.setExpiresAt = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.PotentialInventoryPurchaseOrderV2} returns this
 */
proto.vtv1.PotentialInventoryPurchaseOrderV2.prototype.clearExpiresAt = function() {
  return this.setExpiresAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.PotentialInventoryPurchaseOrderV2.prototype.hasExpiresAt = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * repeated PotentialInventoryPurchaseOrderV2LineItem line_items = 10;
 * @return {!Array<!proto.vtv1.PotentialInventoryPurchaseOrderV2LineItem>}
 */
proto.vtv1.PotentialInventoryPurchaseOrderV2.prototype.getLineItemsList = function() {
  return /** @type{!Array<!proto.vtv1.PotentialInventoryPurchaseOrderV2LineItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vtv1.PotentialInventoryPurchaseOrderV2LineItem, 10));
};


/**
 * @param {!Array<!proto.vtv1.PotentialInventoryPurchaseOrderV2LineItem>} value
 * @return {!proto.vtv1.PotentialInventoryPurchaseOrderV2} returns this
*/
proto.vtv1.PotentialInventoryPurchaseOrderV2.prototype.setLineItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 10, value);
};


/**
 * @param {!proto.vtv1.PotentialInventoryPurchaseOrderV2LineItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.PotentialInventoryPurchaseOrderV2LineItem}
 */
proto.vtv1.PotentialInventoryPurchaseOrderV2.prototype.addLineItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 10, opt_value, proto.vtv1.PotentialInventoryPurchaseOrderV2LineItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.PotentialInventoryPurchaseOrderV2} returns this
 */
proto.vtv1.PotentialInventoryPurchaseOrderV2.prototype.clearLineItemsList = function() {
  return this.setLineItemsList([]);
};


/**
 * optional bool is_expired = 11;
 * @return {boolean}
 */
proto.vtv1.PotentialInventoryPurchaseOrderV2.prototype.getIsExpired = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vtv1.PotentialInventoryPurchaseOrderV2} returns this
 */
proto.vtv1.PotentialInventoryPurchaseOrderV2.prototype.setIsExpired = function(value) {
  return jspb.Message.setProto3BooleanField(this, 11, value);
};


/**
 * optional bool is_close_to_expiring = 12;
 * @return {boolean}
 */
proto.vtv1.PotentialInventoryPurchaseOrderV2.prototype.getIsCloseToExpiring = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 12, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vtv1.PotentialInventoryPurchaseOrderV2} returns this
 */
proto.vtv1.PotentialInventoryPurchaseOrderV2.prototype.setIsCloseToExpiring = function(value) {
  return jspb.Message.setProto3BooleanField(this, 12, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.PotentialInventoryPurchaseOrderV2LineItem.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.PotentialInventoryPurchaseOrderV2LineItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.PotentialInventoryPurchaseOrderV2LineItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.PotentialInventoryPurchaseOrderV2LineItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    product: (f = msg.getProduct()) && proto_vorto_types_types_pb.SlimProduct.toObject(includeInstance, f),
    totalQuantityMax: (f = msg.getTotalQuantityMax()) && proto_vorto_types_types_pb.NullableInt64.toObject(includeInstance, f),
    dailyQuantityMax: (f = msg.getDailyQuantityMax()) && proto_vorto_types_types_pb.NullableInt64.toObject(includeInstance, f),
    unitCostCents: jspb.Message.getFieldWithDefault(msg, 8, 0),
    name: jspb.Message.getFieldWithDefault(msg, 9, ""),
    description: (f = msg.getDescription()) && proto_vorto_types_types_pb.NullableString.toObject(includeInstance, f),
    quantityInCost: jspb.Message.getFloatingPointFieldWithDefault(msg, 11, 0.0),
    freightTerm: (f = msg.getFreightTerm()) && proto_vorto_types_types_pb.NullableString.toObject(includeInstance, f),
    enableBackhaul: jspb.Message.getBooleanFieldWithDefault(msg, 13, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.PotentialInventoryPurchaseOrderV2LineItem}
 */
proto.vtv1.PotentialInventoryPurchaseOrderV2LineItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.PotentialInventoryPurchaseOrderV2LineItem;
  return proto.vtv1.PotentialInventoryPurchaseOrderV2LineItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.PotentialInventoryPurchaseOrderV2LineItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.PotentialInventoryPurchaseOrderV2LineItem}
 */
proto.vtv1.PotentialInventoryPurchaseOrderV2LineItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = new proto_vorto_types_types_pb.SlimProduct;
      reader.readMessage(value,proto_vorto_types_types_pb.SlimProduct.deserializeBinaryFromReader);
      msg.setProduct(value);
      break;
    case 4:
      var value = new proto_vorto_types_types_pb.NullableInt64;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableInt64.deserializeBinaryFromReader);
      msg.setTotalQuantityMax(value);
      break;
    case 6:
      var value = new proto_vorto_types_types_pb.NullableInt64;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableInt64.deserializeBinaryFromReader);
      msg.setDailyQuantityMax(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUnitCostCents(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 10:
      var value = new proto_vorto_types_types_pb.NullableString;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableString.deserializeBinaryFromReader);
      msg.setDescription(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setQuantityInCost(value);
      break;
    case 12:
      var value = new proto_vorto_types_types_pb.NullableString;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableString.deserializeBinaryFromReader);
      msg.setFreightTerm(value);
      break;
    case 13:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEnableBackhaul(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.PotentialInventoryPurchaseOrderV2LineItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.PotentialInventoryPurchaseOrderV2LineItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.PotentialInventoryPurchaseOrderV2LineItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.PotentialInventoryPurchaseOrderV2LineItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getProduct();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_vorto_types_types_pb.SlimProduct.serializeBinaryToWriter
    );
  }
  f = message.getTotalQuantityMax();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto_vorto_types_types_pb.NullableInt64.serializeBinaryToWriter
    );
  }
  f = message.getDailyQuantityMax();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto_vorto_types_types_pb.NullableInt64.serializeBinaryToWriter
    );
  }
  f = message.getUnitCostCents();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getDescription();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto_vorto_types_types_pb.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getQuantityInCost();
  if (f !== 0.0) {
    writer.writeDouble(
      11,
      f
    );
  }
  f = message.getFreightTerm();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      proto_vorto_types_types_pb.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getEnableBackhaul();
  if (f) {
    writer.writeBool(
      13,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.vtv1.PotentialInventoryPurchaseOrderV2LineItem.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.PotentialInventoryPurchaseOrderV2LineItem} returns this
 */
proto.vtv1.PotentialInventoryPurchaseOrderV2LineItem.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional SlimProduct product = 2;
 * @return {?proto.vtv1.SlimProduct}
 */
proto.vtv1.PotentialInventoryPurchaseOrderV2LineItem.prototype.getProduct = function() {
  return /** @type{?proto.vtv1.SlimProduct} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.SlimProduct, 2));
};


/**
 * @param {?proto.vtv1.SlimProduct|undefined} value
 * @return {!proto.vtv1.PotentialInventoryPurchaseOrderV2LineItem} returns this
*/
proto.vtv1.PotentialInventoryPurchaseOrderV2LineItem.prototype.setProduct = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.PotentialInventoryPurchaseOrderV2LineItem} returns this
 */
proto.vtv1.PotentialInventoryPurchaseOrderV2LineItem.prototype.clearProduct = function() {
  return this.setProduct(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.PotentialInventoryPurchaseOrderV2LineItem.prototype.hasProduct = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional NullableInt64 total_quantity_max = 4;
 * @return {?proto.vtv1.NullableInt64}
 */
proto.vtv1.PotentialInventoryPurchaseOrderV2LineItem.prototype.getTotalQuantityMax = function() {
  return /** @type{?proto.vtv1.NullableInt64} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableInt64, 4));
};


/**
 * @param {?proto.vtv1.NullableInt64|undefined} value
 * @return {!proto.vtv1.PotentialInventoryPurchaseOrderV2LineItem} returns this
*/
proto.vtv1.PotentialInventoryPurchaseOrderV2LineItem.prototype.setTotalQuantityMax = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.PotentialInventoryPurchaseOrderV2LineItem} returns this
 */
proto.vtv1.PotentialInventoryPurchaseOrderV2LineItem.prototype.clearTotalQuantityMax = function() {
  return this.setTotalQuantityMax(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.PotentialInventoryPurchaseOrderV2LineItem.prototype.hasTotalQuantityMax = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional NullableInt64 daily_quantity_max = 6;
 * @return {?proto.vtv1.NullableInt64}
 */
proto.vtv1.PotentialInventoryPurchaseOrderV2LineItem.prototype.getDailyQuantityMax = function() {
  return /** @type{?proto.vtv1.NullableInt64} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableInt64, 6));
};


/**
 * @param {?proto.vtv1.NullableInt64|undefined} value
 * @return {!proto.vtv1.PotentialInventoryPurchaseOrderV2LineItem} returns this
*/
proto.vtv1.PotentialInventoryPurchaseOrderV2LineItem.prototype.setDailyQuantityMax = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.PotentialInventoryPurchaseOrderV2LineItem} returns this
 */
proto.vtv1.PotentialInventoryPurchaseOrderV2LineItem.prototype.clearDailyQuantityMax = function() {
  return this.setDailyQuantityMax(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.PotentialInventoryPurchaseOrderV2LineItem.prototype.hasDailyQuantityMax = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional int64 unit_cost_cents = 8;
 * @return {number}
 */
proto.vtv1.PotentialInventoryPurchaseOrderV2LineItem.prototype.getUnitCostCents = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.PotentialInventoryPurchaseOrderV2LineItem} returns this
 */
proto.vtv1.PotentialInventoryPurchaseOrderV2LineItem.prototype.setUnitCostCents = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional string name = 9;
 * @return {string}
 */
proto.vtv1.PotentialInventoryPurchaseOrderV2LineItem.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.PotentialInventoryPurchaseOrderV2LineItem} returns this
 */
proto.vtv1.PotentialInventoryPurchaseOrderV2LineItem.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional NullableString description = 10;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.PotentialInventoryPurchaseOrderV2LineItem.prototype.getDescription = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableString, 10));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.PotentialInventoryPurchaseOrderV2LineItem} returns this
*/
proto.vtv1.PotentialInventoryPurchaseOrderV2LineItem.prototype.setDescription = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.PotentialInventoryPurchaseOrderV2LineItem} returns this
 */
proto.vtv1.PotentialInventoryPurchaseOrderV2LineItem.prototype.clearDescription = function() {
  return this.setDescription(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.PotentialInventoryPurchaseOrderV2LineItem.prototype.hasDescription = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional double quantity_in_cost = 11;
 * @return {number}
 */
proto.vtv1.PotentialInventoryPurchaseOrderV2LineItem.prototype.getQuantityInCost = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 11, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.PotentialInventoryPurchaseOrderV2LineItem} returns this
 */
proto.vtv1.PotentialInventoryPurchaseOrderV2LineItem.prototype.setQuantityInCost = function(value) {
  return jspb.Message.setProto3FloatField(this, 11, value);
};


/**
 * optional NullableString freight_term = 12;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.PotentialInventoryPurchaseOrderV2LineItem.prototype.getFreightTerm = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableString, 12));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.PotentialInventoryPurchaseOrderV2LineItem} returns this
*/
proto.vtv1.PotentialInventoryPurchaseOrderV2LineItem.prototype.setFreightTerm = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.PotentialInventoryPurchaseOrderV2LineItem} returns this
 */
proto.vtv1.PotentialInventoryPurchaseOrderV2LineItem.prototype.clearFreightTerm = function() {
  return this.setFreightTerm(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.PotentialInventoryPurchaseOrderV2LineItem.prototype.hasFreightTerm = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional bool enable_backhaul = 13;
 * @return {boolean}
 */
proto.vtv1.PotentialInventoryPurchaseOrderV2LineItem.prototype.getEnableBackhaul = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 13, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vtv1.PotentialInventoryPurchaseOrderV2LineItem} returns this
 */
proto.vtv1.PotentialInventoryPurchaseOrderV2LineItem.prototype.setEnableBackhaul = function(value) {
  return jspb.Message.setProto3BooleanField(this, 13, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.VanguardDemandEntry.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.VanguardDemandEntry.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.VanguardDemandEntry} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.VanguardDemandEntry.toObject = function(includeInstance, msg) {
  var f, obj = {
    facilityCode: jspb.Message.getFieldWithDefault(msg, 1, ""),
    materialCode: jspb.Message.getFieldWithDefault(msg, 2, ""),
    quantity: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    requestDate: jspb.Message.getFieldWithDefault(msg, 4, ""),
    deliveryDate: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.VanguardDemandEntry}
 */
proto.vtv1.VanguardDemandEntry.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.VanguardDemandEntry;
  return proto.vtv1.VanguardDemandEntry.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.VanguardDemandEntry} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.VanguardDemandEntry}
 */
proto.vtv1.VanguardDemandEntry.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFacilityCode(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMaterialCode(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setQuantity(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestDate(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeliveryDate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.VanguardDemandEntry.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.VanguardDemandEntry.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.VanguardDemandEntry} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.VanguardDemandEntry.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFacilityCode();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMaterialCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getQuantity();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getRequestDate();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getDeliveryDate();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string facility_code = 1;
 * @return {string}
 */
proto.vtv1.VanguardDemandEntry.prototype.getFacilityCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.VanguardDemandEntry} returns this
 */
proto.vtv1.VanguardDemandEntry.prototype.setFacilityCode = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string material_code = 2;
 * @return {string}
 */
proto.vtv1.VanguardDemandEntry.prototype.getMaterialCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.VanguardDemandEntry} returns this
 */
proto.vtv1.VanguardDemandEntry.prototype.setMaterialCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional double quantity = 3;
 * @return {number}
 */
proto.vtv1.VanguardDemandEntry.prototype.getQuantity = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.VanguardDemandEntry} returns this
 */
proto.vtv1.VanguardDemandEntry.prototype.setQuantity = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional string request_date = 4;
 * @return {string}
 */
proto.vtv1.VanguardDemandEntry.prototype.getRequestDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.VanguardDemandEntry} returns this
 */
proto.vtv1.VanguardDemandEntry.prototype.setRequestDate = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string delivery_date = 5;
 * @return {string}
 */
proto.vtv1.VanguardDemandEntry.prototype.getDeliveryDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.VanguardDemandEntry} returns this
 */
proto.vtv1.VanguardDemandEntry.prototype.setDeliveryDate = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.VanguardHistoryEntry.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.VanguardHistoryEntry.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.VanguardHistoryEntry} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.VanguardHistoryEntry.toObject = function(includeInstance, msg) {
  var f, obj = {
    userName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    createdAt: jspb.Message.getFieldWithDefault(msg, 2, ""),
    entries: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.VanguardHistoryEntry}
 */
proto.vtv1.VanguardHistoryEntry.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.VanguardHistoryEntry;
  return proto.vtv1.VanguardHistoryEntry.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.VanguardHistoryEntry} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.VanguardHistoryEntry}
 */
proto.vtv1.VanguardHistoryEntry.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedAt(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setEntries(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.VanguardHistoryEntry.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.VanguardHistoryEntry.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.VanguardHistoryEntry} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.VanguardHistoryEntry.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCreatedAt();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getEntries();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
};


/**
 * optional string user_name = 1;
 * @return {string}
 */
proto.vtv1.VanguardHistoryEntry.prototype.getUserName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.VanguardHistoryEntry} returns this
 */
proto.vtv1.VanguardHistoryEntry.prototype.setUserName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string created_at = 2;
 * @return {string}
 */
proto.vtv1.VanguardHistoryEntry.prototype.getCreatedAt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.VanguardHistoryEntry} returns this
 */
proto.vtv1.VanguardHistoryEntry.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 entries = 3;
 * @return {number}
 */
proto.vtv1.VanguardHistoryEntry.prototype.getEntries = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.VanguardHistoryEntry} returns this
 */
proto.vtv1.VanguardHistoryEntry.prototype.setEntries = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.APIStockUpdate.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.APIStockUpdate.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.APIStockUpdate} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.APIStockUpdate.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    inventoryId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    createdAt: jspb.Message.getFieldWithDefault(msg, 3, ""),
    stock: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.APIStockUpdate}
 */
proto.vtv1.APIStockUpdate.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.APIStockUpdate;
  return proto.vtv1.APIStockUpdate.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.APIStockUpdate} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.APIStockUpdate}
 */
proto.vtv1.APIStockUpdate.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setInventoryId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedAt(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setStock(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.APIStockUpdate.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.APIStockUpdate.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.APIStockUpdate} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.APIStockUpdate.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getInventoryId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCreatedAt();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getStock();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.vtv1.APIStockUpdate.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.APIStockUpdate} returns this
 */
proto.vtv1.APIStockUpdate.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string inventory_id = 2;
 * @return {string}
 */
proto.vtv1.APIStockUpdate.prototype.getInventoryId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.APIStockUpdate} returns this
 */
proto.vtv1.APIStockUpdate.prototype.setInventoryId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string created_at = 3;
 * @return {string}
 */
proto.vtv1.APIStockUpdate.prototype.getCreatedAt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.APIStockUpdate} returns this
 */
proto.vtv1.APIStockUpdate.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional double stock = 4;
 * @return {number}
 */
proto.vtv1.APIStockUpdate.prototype.getStock = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.APIStockUpdate} returns this
 */
proto.vtv1.APIStockUpdate.prototype.setStock = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.InventoryDetailSlim.repeatedFields_ = [13,23,25,26];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.InventoryDetailSlim.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.InventoryDetailSlim.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.InventoryDetailSlim} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.InventoryDetailSlim.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    productId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    productName: jspb.Message.getFieldWithDefault(msg, 4, ""),
    productUnitName: jspb.Message.getFieldWithDefault(msg, 5, ""),
    productUnitAbbreviation: jspb.Message.getFieldWithDefault(msg, 6, ""),
    capacity: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    stockUpdatedAt: jspb.Message.getFieldWithDefault(msg, 8, ""),
    defaultLoadQuantity: jspb.Message.getFloatingPointFieldWithDefault(msg, 9, 0.0),
    defaultUnloadingMinutes: jspb.Message.getFieldWithDefault(msg, 10, 0),
    targetZeroStock: jspb.Message.getBooleanFieldWithDefault(msg, 11, false),
    warningPercentage: (f = msg.getWarningPercentage()) && proto_vorto_types_types_pb.NullableDouble.toObject(includeInstance, f),
    trailerTypesList: jspb.Message.toObjectList(msg.getTrailerTypesList(),
    proto_vorto_types_types_pb.IntIdName.toObject, includeInstance),
    stockUpdatedById: (f = msg.getStockUpdatedById()) && proto_vorto_types_types_pb.NullableString.toObject(includeInstance, f),
    stockUpdatedByName: (f = msg.getStockUpdatedByName()) && proto_vorto_types_types_pb.NullableString.toObject(includeInstance, f),
    archivedAt: (f = msg.getArchivedAt()) && proto_vorto_types_types_pb.NullableTime.toObject(includeInstance, f),
    inbound: jspb.Message.getBooleanFieldWithDefault(msg, 17, false),
    outbound: jspb.Message.getBooleanFieldWithDefault(msg, 18, false),
    reloadWindow: jspb.Message.getFieldWithDefault(msg, 19, 0),
    leadtimeHours: jspb.Message.getFieldWithDefault(msg, 20, 0),
    warningThreshold: (f = msg.getWarningThreshold()) && proto_vorto_types_types_pb.NullableDouble.toObject(includeInstance, f),
    site: (f = msg.getSite()) && proto_vorto_types_types_pb.StringIdName.toObject(includeInstance, f),
    sharedWithList: jspb.Message.toObjectList(msg.getSharedWithList(),
    proto.vtv1.InventoryDetailSlimSharedWith.toObject, includeInstance),
    accessLevel: jspb.Message.getFieldWithDefault(msg, 24, 0),
    managedByList: jspb.Message.toObjectList(msg.getManagedByList(),
    proto.vtv1.InventoryDetailSlimSharedWith.toObject, includeInstance),
    subscribersList: jspb.Message.toObjectList(msg.getSubscribersList(),
    proto.vtv1.InventorySubscriber.toObject, includeInstance),
    owningUserId: (f = msg.getOwningUserId()) && proto_vorto_types_types_pb.NullableString.toObject(includeInstance, f),
    siteOwner: (f = msg.getSiteOwner()) && proto_vorto_types_types_pb.StringIdName.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.InventoryDetailSlim}
 */
proto.vtv1.InventoryDetailSlim.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.InventoryDetailSlim;
  return proto.vtv1.InventoryDetailSlim.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.InventoryDetailSlim} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.InventoryDetailSlim}
 */
proto.vtv1.InventoryDetailSlim.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductName(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductUnitName(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductUnitAbbreviation(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setCapacity(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setStockUpdatedAt(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setDefaultLoadQuantity(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDefaultUnloadingMinutes(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setTargetZeroStock(value);
      break;
    case 12:
      var value = new proto_vorto_types_types_pb.NullableDouble;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableDouble.deserializeBinaryFromReader);
      msg.setWarningPercentage(value);
      break;
    case 13:
      var value = new proto_vorto_types_types_pb.IntIdName;
      reader.readMessage(value,proto_vorto_types_types_pb.IntIdName.deserializeBinaryFromReader);
      msg.addTrailerTypes(value);
      break;
    case 14:
      var value = new proto_vorto_types_types_pb.NullableString;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableString.deserializeBinaryFromReader);
      msg.setStockUpdatedById(value);
      break;
    case 15:
      var value = new proto_vorto_types_types_pb.NullableString;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableString.deserializeBinaryFromReader);
      msg.setStockUpdatedByName(value);
      break;
    case 16:
      var value = new proto_vorto_types_types_pb.NullableTime;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableTime.deserializeBinaryFromReader);
      msg.setArchivedAt(value);
      break;
    case 17:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setInbound(value);
      break;
    case 18:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOutbound(value);
      break;
    case 19:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setReloadWindow(value);
      break;
    case 20:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLeadtimeHours(value);
      break;
    case 21:
      var value = new proto_vorto_types_types_pb.NullableDouble;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableDouble.deserializeBinaryFromReader);
      msg.setWarningThreshold(value);
      break;
    case 22:
      var value = new proto_vorto_types_types_pb.StringIdName;
      reader.readMessage(value,proto_vorto_types_types_pb.StringIdName.deserializeBinaryFromReader);
      msg.setSite(value);
      break;
    case 23:
      var value = new proto.vtv1.InventoryDetailSlimSharedWith;
      reader.readMessage(value,proto.vtv1.InventoryDetailSlimSharedWith.deserializeBinaryFromReader);
      msg.addSharedWith(value);
      break;
    case 24:
      var value = /** @type {!proto.vtv1.InventorySharingPermission} */ (reader.readEnum());
      msg.setAccessLevel(value);
      break;
    case 25:
      var value = new proto.vtv1.InventoryDetailSlimSharedWith;
      reader.readMessage(value,proto.vtv1.InventoryDetailSlimSharedWith.deserializeBinaryFromReader);
      msg.addManagedBy(value);
      break;
    case 26:
      var value = new proto.vtv1.InventorySubscriber;
      reader.readMessage(value,proto.vtv1.InventorySubscriber.deserializeBinaryFromReader);
      msg.addSubscribers(value);
      break;
    case 27:
      var value = new proto_vorto_types_types_pb.NullableString;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableString.deserializeBinaryFromReader);
      msg.setOwningUserId(value);
      break;
    case 28:
      var value = new proto_vorto_types_types_pb.StringIdName;
      reader.readMessage(value,proto_vorto_types_types_pb.StringIdName.deserializeBinaryFromReader);
      msg.setSiteOwner(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.InventoryDetailSlim.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.InventoryDetailSlim.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.InventoryDetailSlim} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.InventoryDetailSlim.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getProductId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getProductName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getProductUnitName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getProductUnitAbbreviation();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getCapacity();
  if (f !== 0.0) {
    writer.writeDouble(
      7,
      f
    );
  }
  f = message.getStockUpdatedAt();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getDefaultLoadQuantity();
  if (f !== 0.0) {
    writer.writeDouble(
      9,
      f
    );
  }
  f = message.getDefaultUnloadingMinutes();
  if (f !== 0) {
    writer.writeInt64(
      10,
      f
    );
  }
  f = message.getTargetZeroStock();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
  f = message.getWarningPercentage();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      proto_vorto_types_types_pb.NullableDouble.serializeBinaryToWriter
    );
  }
  f = message.getTrailerTypesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      13,
      f,
      proto_vorto_types_types_pb.IntIdName.serializeBinaryToWriter
    );
  }
  f = message.getStockUpdatedById();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      proto_vorto_types_types_pb.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getStockUpdatedByName();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      proto_vorto_types_types_pb.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getArchivedAt();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      proto_vorto_types_types_pb.NullableTime.serializeBinaryToWriter
    );
  }
  f = message.getInbound();
  if (f) {
    writer.writeBool(
      17,
      f
    );
  }
  f = message.getOutbound();
  if (f) {
    writer.writeBool(
      18,
      f
    );
  }
  f = message.getReloadWindow();
  if (f !== 0) {
    writer.writeInt64(
      19,
      f
    );
  }
  f = message.getLeadtimeHours();
  if (f !== 0) {
    writer.writeInt64(
      20,
      f
    );
  }
  f = message.getWarningThreshold();
  if (f != null) {
    writer.writeMessage(
      21,
      f,
      proto_vorto_types_types_pb.NullableDouble.serializeBinaryToWriter
    );
  }
  f = message.getSite();
  if (f != null) {
    writer.writeMessage(
      22,
      f,
      proto_vorto_types_types_pb.StringIdName.serializeBinaryToWriter
    );
  }
  f = message.getSharedWithList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      23,
      f,
      proto.vtv1.InventoryDetailSlimSharedWith.serializeBinaryToWriter
    );
  }
  f = message.getAccessLevel();
  if (f !== 0.0) {
    writer.writeEnum(
      24,
      f
    );
  }
  f = message.getManagedByList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      25,
      f,
      proto.vtv1.InventoryDetailSlimSharedWith.serializeBinaryToWriter
    );
  }
  f = message.getSubscribersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      26,
      f,
      proto.vtv1.InventorySubscriber.serializeBinaryToWriter
    );
  }
  f = message.getOwningUserId();
  if (f != null) {
    writer.writeMessage(
      27,
      f,
      proto_vorto_types_types_pb.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getSiteOwner();
  if (f != null) {
    writer.writeMessage(
      28,
      f,
      proto_vorto_types_types_pb.StringIdName.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.vtv1.InventoryDetailSlim.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.InventoryDetailSlim} returns this
 */
proto.vtv1.InventoryDetailSlim.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.vtv1.InventoryDetailSlim.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.InventoryDetailSlim} returns this
 */
proto.vtv1.InventoryDetailSlim.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string product_id = 3;
 * @return {string}
 */
proto.vtv1.InventoryDetailSlim.prototype.getProductId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.InventoryDetailSlim} returns this
 */
proto.vtv1.InventoryDetailSlim.prototype.setProductId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string product_name = 4;
 * @return {string}
 */
proto.vtv1.InventoryDetailSlim.prototype.getProductName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.InventoryDetailSlim} returns this
 */
proto.vtv1.InventoryDetailSlim.prototype.setProductName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string product_unit_name = 5;
 * @return {string}
 */
proto.vtv1.InventoryDetailSlim.prototype.getProductUnitName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.InventoryDetailSlim} returns this
 */
proto.vtv1.InventoryDetailSlim.prototype.setProductUnitName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string product_unit_abbreviation = 6;
 * @return {string}
 */
proto.vtv1.InventoryDetailSlim.prototype.getProductUnitAbbreviation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.InventoryDetailSlim} returns this
 */
proto.vtv1.InventoryDetailSlim.prototype.setProductUnitAbbreviation = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional double capacity = 7;
 * @return {number}
 */
proto.vtv1.InventoryDetailSlim.prototype.getCapacity = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.InventoryDetailSlim} returns this
 */
proto.vtv1.InventoryDetailSlim.prototype.setCapacity = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional string stock_updated_at = 8;
 * @return {string}
 */
proto.vtv1.InventoryDetailSlim.prototype.getStockUpdatedAt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.InventoryDetailSlim} returns this
 */
proto.vtv1.InventoryDetailSlim.prototype.setStockUpdatedAt = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional double default_load_quantity = 9;
 * @return {number}
 */
proto.vtv1.InventoryDetailSlim.prototype.getDefaultLoadQuantity = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 9, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.InventoryDetailSlim} returns this
 */
proto.vtv1.InventoryDetailSlim.prototype.setDefaultLoadQuantity = function(value) {
  return jspb.Message.setProto3FloatField(this, 9, value);
};


/**
 * optional int64 default_unloading_minutes = 10;
 * @return {number}
 */
proto.vtv1.InventoryDetailSlim.prototype.getDefaultUnloadingMinutes = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.InventoryDetailSlim} returns this
 */
proto.vtv1.InventoryDetailSlim.prototype.setDefaultUnloadingMinutes = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional bool target_zero_stock = 11;
 * @return {boolean}
 */
proto.vtv1.InventoryDetailSlim.prototype.getTargetZeroStock = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vtv1.InventoryDetailSlim} returns this
 */
proto.vtv1.InventoryDetailSlim.prototype.setTargetZeroStock = function(value) {
  return jspb.Message.setProto3BooleanField(this, 11, value);
};


/**
 * optional NullableDouble warning_percentage = 12;
 * @return {?proto.vtv1.NullableDouble}
 */
proto.vtv1.InventoryDetailSlim.prototype.getWarningPercentage = function() {
  return /** @type{?proto.vtv1.NullableDouble} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableDouble, 12));
};


/**
 * @param {?proto.vtv1.NullableDouble|undefined} value
 * @return {!proto.vtv1.InventoryDetailSlim} returns this
*/
proto.vtv1.InventoryDetailSlim.prototype.setWarningPercentage = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.InventoryDetailSlim} returns this
 */
proto.vtv1.InventoryDetailSlim.prototype.clearWarningPercentage = function() {
  return this.setWarningPercentage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.InventoryDetailSlim.prototype.hasWarningPercentage = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * repeated IntIdName trailer_types = 13;
 * @return {!Array<!proto.vtv1.IntIdName>}
 */
proto.vtv1.InventoryDetailSlim.prototype.getTrailerTypesList = function() {
  return /** @type{!Array<!proto.vtv1.IntIdName>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_vorto_types_types_pb.IntIdName, 13));
};


/**
 * @param {!Array<!proto.vtv1.IntIdName>} value
 * @return {!proto.vtv1.InventoryDetailSlim} returns this
*/
proto.vtv1.InventoryDetailSlim.prototype.setTrailerTypesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 13, value);
};


/**
 * @param {!proto.vtv1.IntIdName=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.IntIdName}
 */
proto.vtv1.InventoryDetailSlim.prototype.addTrailerTypes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 13, opt_value, proto.vtv1.IntIdName, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.InventoryDetailSlim} returns this
 */
proto.vtv1.InventoryDetailSlim.prototype.clearTrailerTypesList = function() {
  return this.setTrailerTypesList([]);
};


/**
 * optional NullableString stock_updated_by_id = 14;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.InventoryDetailSlim.prototype.getStockUpdatedById = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableString, 14));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.InventoryDetailSlim} returns this
*/
proto.vtv1.InventoryDetailSlim.prototype.setStockUpdatedById = function(value) {
  return jspb.Message.setWrapperField(this, 14, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.InventoryDetailSlim} returns this
 */
proto.vtv1.InventoryDetailSlim.prototype.clearStockUpdatedById = function() {
  return this.setStockUpdatedById(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.InventoryDetailSlim.prototype.hasStockUpdatedById = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional NullableString stock_updated_by_name = 15;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.InventoryDetailSlim.prototype.getStockUpdatedByName = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableString, 15));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.InventoryDetailSlim} returns this
*/
proto.vtv1.InventoryDetailSlim.prototype.setStockUpdatedByName = function(value) {
  return jspb.Message.setWrapperField(this, 15, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.InventoryDetailSlim} returns this
 */
proto.vtv1.InventoryDetailSlim.prototype.clearStockUpdatedByName = function() {
  return this.setStockUpdatedByName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.InventoryDetailSlim.prototype.hasStockUpdatedByName = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional NullableTime archived_at = 16;
 * @return {?proto.vtv1.NullableTime}
 */
proto.vtv1.InventoryDetailSlim.prototype.getArchivedAt = function() {
  return /** @type{?proto.vtv1.NullableTime} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableTime, 16));
};


/**
 * @param {?proto.vtv1.NullableTime|undefined} value
 * @return {!proto.vtv1.InventoryDetailSlim} returns this
*/
proto.vtv1.InventoryDetailSlim.prototype.setArchivedAt = function(value) {
  return jspb.Message.setWrapperField(this, 16, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.InventoryDetailSlim} returns this
 */
proto.vtv1.InventoryDetailSlim.prototype.clearArchivedAt = function() {
  return this.setArchivedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.InventoryDetailSlim.prototype.hasArchivedAt = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * optional bool inbound = 17;
 * @return {boolean}
 */
proto.vtv1.InventoryDetailSlim.prototype.getInbound = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 17, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vtv1.InventoryDetailSlim} returns this
 */
proto.vtv1.InventoryDetailSlim.prototype.setInbound = function(value) {
  return jspb.Message.setProto3BooleanField(this, 17, value);
};


/**
 * optional bool outbound = 18;
 * @return {boolean}
 */
proto.vtv1.InventoryDetailSlim.prototype.getOutbound = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 18, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vtv1.InventoryDetailSlim} returns this
 */
proto.vtv1.InventoryDetailSlim.prototype.setOutbound = function(value) {
  return jspb.Message.setProto3BooleanField(this, 18, value);
};


/**
 * optional int64 reload_window = 19;
 * @return {number}
 */
proto.vtv1.InventoryDetailSlim.prototype.getReloadWindow = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 19, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.InventoryDetailSlim} returns this
 */
proto.vtv1.InventoryDetailSlim.prototype.setReloadWindow = function(value) {
  return jspb.Message.setProto3IntField(this, 19, value);
};


/**
 * optional int64 leadtime_hours = 20;
 * @return {number}
 */
proto.vtv1.InventoryDetailSlim.prototype.getLeadtimeHours = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 20, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.InventoryDetailSlim} returns this
 */
proto.vtv1.InventoryDetailSlim.prototype.setLeadtimeHours = function(value) {
  return jspb.Message.setProto3IntField(this, 20, value);
};


/**
 * optional NullableDouble warning_threshold = 21;
 * @return {?proto.vtv1.NullableDouble}
 */
proto.vtv1.InventoryDetailSlim.prototype.getWarningThreshold = function() {
  return /** @type{?proto.vtv1.NullableDouble} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableDouble, 21));
};


/**
 * @param {?proto.vtv1.NullableDouble|undefined} value
 * @return {!proto.vtv1.InventoryDetailSlim} returns this
*/
proto.vtv1.InventoryDetailSlim.prototype.setWarningThreshold = function(value) {
  return jspb.Message.setWrapperField(this, 21, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.InventoryDetailSlim} returns this
 */
proto.vtv1.InventoryDetailSlim.prototype.clearWarningThreshold = function() {
  return this.setWarningThreshold(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.InventoryDetailSlim.prototype.hasWarningThreshold = function() {
  return jspb.Message.getField(this, 21) != null;
};


/**
 * optional StringIdName site = 22;
 * @return {?proto.vtv1.StringIdName}
 */
proto.vtv1.InventoryDetailSlim.prototype.getSite = function() {
  return /** @type{?proto.vtv1.StringIdName} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.StringIdName, 22));
};


/**
 * @param {?proto.vtv1.StringIdName|undefined} value
 * @return {!proto.vtv1.InventoryDetailSlim} returns this
*/
proto.vtv1.InventoryDetailSlim.prototype.setSite = function(value) {
  return jspb.Message.setWrapperField(this, 22, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.InventoryDetailSlim} returns this
 */
proto.vtv1.InventoryDetailSlim.prototype.clearSite = function() {
  return this.setSite(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.InventoryDetailSlim.prototype.hasSite = function() {
  return jspb.Message.getField(this, 22) != null;
};


/**
 * repeated InventoryDetailSlimSharedWith shared_with = 23;
 * @return {!Array<!proto.vtv1.InventoryDetailSlimSharedWith>}
 */
proto.vtv1.InventoryDetailSlim.prototype.getSharedWithList = function() {
  return /** @type{!Array<!proto.vtv1.InventoryDetailSlimSharedWith>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vtv1.InventoryDetailSlimSharedWith, 23));
};


/**
 * @param {!Array<!proto.vtv1.InventoryDetailSlimSharedWith>} value
 * @return {!proto.vtv1.InventoryDetailSlim} returns this
*/
proto.vtv1.InventoryDetailSlim.prototype.setSharedWithList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 23, value);
};


/**
 * @param {!proto.vtv1.InventoryDetailSlimSharedWith=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.InventoryDetailSlimSharedWith}
 */
proto.vtv1.InventoryDetailSlim.prototype.addSharedWith = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 23, opt_value, proto.vtv1.InventoryDetailSlimSharedWith, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.InventoryDetailSlim} returns this
 */
proto.vtv1.InventoryDetailSlim.prototype.clearSharedWithList = function() {
  return this.setSharedWithList([]);
};


/**
 * optional InventorySharingPermission access_level = 24;
 * @return {!proto.vtv1.InventorySharingPermission}
 */
proto.vtv1.InventoryDetailSlim.prototype.getAccessLevel = function() {
  return /** @type {!proto.vtv1.InventorySharingPermission} */ (jspb.Message.getFieldWithDefault(this, 24, 0));
};


/**
 * @param {!proto.vtv1.InventorySharingPermission} value
 * @return {!proto.vtv1.InventoryDetailSlim} returns this
 */
proto.vtv1.InventoryDetailSlim.prototype.setAccessLevel = function(value) {
  return jspb.Message.setProto3EnumField(this, 24, value);
};


/**
 * repeated InventoryDetailSlimSharedWith managed_by = 25;
 * @return {!Array<!proto.vtv1.InventoryDetailSlimSharedWith>}
 */
proto.vtv1.InventoryDetailSlim.prototype.getManagedByList = function() {
  return /** @type{!Array<!proto.vtv1.InventoryDetailSlimSharedWith>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vtv1.InventoryDetailSlimSharedWith, 25));
};


/**
 * @param {!Array<!proto.vtv1.InventoryDetailSlimSharedWith>} value
 * @return {!proto.vtv1.InventoryDetailSlim} returns this
*/
proto.vtv1.InventoryDetailSlim.prototype.setManagedByList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 25, value);
};


/**
 * @param {!proto.vtv1.InventoryDetailSlimSharedWith=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.InventoryDetailSlimSharedWith}
 */
proto.vtv1.InventoryDetailSlim.prototype.addManagedBy = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 25, opt_value, proto.vtv1.InventoryDetailSlimSharedWith, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.InventoryDetailSlim} returns this
 */
proto.vtv1.InventoryDetailSlim.prototype.clearManagedByList = function() {
  return this.setManagedByList([]);
};


/**
 * repeated InventorySubscriber subscribers = 26;
 * @return {!Array<!proto.vtv1.InventorySubscriber>}
 */
proto.vtv1.InventoryDetailSlim.prototype.getSubscribersList = function() {
  return /** @type{!Array<!proto.vtv1.InventorySubscriber>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vtv1.InventorySubscriber, 26));
};


/**
 * @param {!Array<!proto.vtv1.InventorySubscriber>} value
 * @return {!proto.vtv1.InventoryDetailSlim} returns this
*/
proto.vtv1.InventoryDetailSlim.prototype.setSubscribersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 26, value);
};


/**
 * @param {!proto.vtv1.InventorySubscriber=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.InventorySubscriber}
 */
proto.vtv1.InventoryDetailSlim.prototype.addSubscribers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 26, opt_value, proto.vtv1.InventorySubscriber, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.InventoryDetailSlim} returns this
 */
proto.vtv1.InventoryDetailSlim.prototype.clearSubscribersList = function() {
  return this.setSubscribersList([]);
};


/**
 * optional NullableString owning_user_id = 27;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.InventoryDetailSlim.prototype.getOwningUserId = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableString, 27));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.InventoryDetailSlim} returns this
*/
proto.vtv1.InventoryDetailSlim.prototype.setOwningUserId = function(value) {
  return jspb.Message.setWrapperField(this, 27, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.InventoryDetailSlim} returns this
 */
proto.vtv1.InventoryDetailSlim.prototype.clearOwningUserId = function() {
  return this.setOwningUserId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.InventoryDetailSlim.prototype.hasOwningUserId = function() {
  return jspb.Message.getField(this, 27) != null;
};


/**
 * optional StringIdName site_owner = 28;
 * @return {?proto.vtv1.StringIdName}
 */
proto.vtv1.InventoryDetailSlim.prototype.getSiteOwner = function() {
  return /** @type{?proto.vtv1.StringIdName} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.StringIdName, 28));
};


/**
 * @param {?proto.vtv1.StringIdName|undefined} value
 * @return {!proto.vtv1.InventoryDetailSlim} returns this
*/
proto.vtv1.InventoryDetailSlim.prototype.setSiteOwner = function(value) {
  return jspb.Message.setWrapperField(this, 28, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.InventoryDetailSlim} returns this
 */
proto.vtv1.InventoryDetailSlim.prototype.clearSiteOwner = function() {
  return this.setSiteOwner(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.InventoryDetailSlim.prototype.hasSiteOwner = function() {
  return jspb.Message.getField(this, 28) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.InventorySubscriber.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.InventorySubscriber.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.InventorySubscriber} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.InventorySubscriber.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    subscribedAt: jspb.Message.getFieldWithDefault(msg, 3, ""),
    email: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.InventorySubscriber}
 */
proto.vtv1.InventorySubscriber.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.InventorySubscriber;
  return proto.vtv1.InventorySubscriber.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.InventorySubscriber} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.InventorySubscriber}
 */
proto.vtv1.InventorySubscriber.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubscribedAt(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.InventorySubscriber.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.InventorySubscriber.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.InventorySubscriber} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.InventorySubscriber.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSubscribedAt();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.vtv1.InventorySubscriber.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.InventorySubscriber} returns this
 */
proto.vtv1.InventorySubscriber.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.vtv1.InventorySubscriber.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.InventorySubscriber} returns this
 */
proto.vtv1.InventorySubscriber.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string subscribed_at = 3;
 * @return {string}
 */
proto.vtv1.InventorySubscriber.prototype.getSubscribedAt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.InventorySubscriber} returns this
 */
proto.vtv1.InventorySubscriber.prototype.setSubscribedAt = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string email = 4;
 * @return {string}
 */
proto.vtv1.InventorySubscriber.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.InventorySubscriber} returns this
 */
proto.vtv1.InventorySubscriber.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.InventoryDetailSlimSharedWith.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.InventoryDetailSlimSharedWith.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.InventoryDetailSlimSharedWith} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.InventoryDetailSlimSharedWith.toObject = function(includeInstance, msg) {
  var f, obj = {
    account: (f = msg.getAccount()) && proto_vorto_types_types_pb.StringIdName.toObject(includeInstance, f),
    permission: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.InventoryDetailSlimSharedWith}
 */
proto.vtv1.InventoryDetailSlimSharedWith.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.InventoryDetailSlimSharedWith;
  return proto.vtv1.InventoryDetailSlimSharedWith.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.InventoryDetailSlimSharedWith} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.InventoryDetailSlimSharedWith}
 */
proto.vtv1.InventoryDetailSlimSharedWith.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_vorto_types_types_pb.StringIdName;
      reader.readMessage(value,proto_vorto_types_types_pb.StringIdName.deserializeBinaryFromReader);
      msg.setAccount(value);
      break;
    case 2:
      var value = /** @type {!proto.vtv1.InventorySharingPermission} */ (reader.readEnum());
      msg.setPermission(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.InventoryDetailSlimSharedWith.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.InventoryDetailSlimSharedWith.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.InventoryDetailSlimSharedWith} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.InventoryDetailSlimSharedWith.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccount();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_vorto_types_types_pb.StringIdName.serializeBinaryToWriter
    );
  }
  f = message.getPermission();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional StringIdName account = 1;
 * @return {?proto.vtv1.StringIdName}
 */
proto.vtv1.InventoryDetailSlimSharedWith.prototype.getAccount = function() {
  return /** @type{?proto.vtv1.StringIdName} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.StringIdName, 1));
};


/**
 * @param {?proto.vtv1.StringIdName|undefined} value
 * @return {!proto.vtv1.InventoryDetailSlimSharedWith} returns this
*/
proto.vtv1.InventoryDetailSlimSharedWith.prototype.setAccount = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.InventoryDetailSlimSharedWith} returns this
 */
proto.vtv1.InventoryDetailSlimSharedWith.prototype.clearAccount = function() {
  return this.setAccount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.InventoryDetailSlimSharedWith.prototype.hasAccount = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional InventorySharingPermission permission = 2;
 * @return {!proto.vtv1.InventorySharingPermission}
 */
proto.vtv1.InventoryDetailSlimSharedWith.prototype.getPermission = function() {
  return /** @type {!proto.vtv1.InventorySharingPermission} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.vtv1.InventorySharingPermission} value
 * @return {!proto.vtv1.InventoryDetailSlimSharedWith} returns this
 */
proto.vtv1.InventoryDetailSlimSharedWith.prototype.setPermission = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.InventoryDetailSlimStocks.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.InventoryDetailSlimStocks.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.InventoryDetailSlimStocks.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.InventoryDetailSlimStocks} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.InventoryDetailSlimStocks.toObject = function(includeInstance, msg) {
  var f, obj = {
    stockHistoryList: jspb.Message.toObjectList(msg.getStockHistoryList(),
    proto.vtv1.InventoryStockDatapoint.toObject, includeInstance),
    stockPredictionsList: jspb.Message.toObjectList(msg.getStockPredictionsList(),
    proto.vtv1.InventoryStockDatapoint.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.InventoryDetailSlimStocks}
 */
proto.vtv1.InventoryDetailSlimStocks.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.InventoryDetailSlimStocks;
  return proto.vtv1.InventoryDetailSlimStocks.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.InventoryDetailSlimStocks} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.InventoryDetailSlimStocks}
 */
proto.vtv1.InventoryDetailSlimStocks.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.vtv1.InventoryStockDatapoint;
      reader.readMessage(value,proto.vtv1.InventoryStockDatapoint.deserializeBinaryFromReader);
      msg.addStockHistory(value);
      break;
    case 2:
      var value = new proto.vtv1.InventoryStockDatapoint;
      reader.readMessage(value,proto.vtv1.InventoryStockDatapoint.deserializeBinaryFromReader);
      msg.addStockPredictions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.InventoryDetailSlimStocks.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.InventoryDetailSlimStocks.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.InventoryDetailSlimStocks} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.InventoryDetailSlimStocks.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStockHistoryList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.vtv1.InventoryStockDatapoint.serializeBinaryToWriter
    );
  }
  f = message.getStockPredictionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.vtv1.InventoryStockDatapoint.serializeBinaryToWriter
    );
  }
};


/**
 * repeated InventoryStockDatapoint stock_history = 1;
 * @return {!Array<!proto.vtv1.InventoryStockDatapoint>}
 */
proto.vtv1.InventoryDetailSlimStocks.prototype.getStockHistoryList = function() {
  return /** @type{!Array<!proto.vtv1.InventoryStockDatapoint>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vtv1.InventoryStockDatapoint, 1));
};


/**
 * @param {!Array<!proto.vtv1.InventoryStockDatapoint>} value
 * @return {!proto.vtv1.InventoryDetailSlimStocks} returns this
*/
proto.vtv1.InventoryDetailSlimStocks.prototype.setStockHistoryList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.vtv1.InventoryStockDatapoint=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.InventoryStockDatapoint}
 */
proto.vtv1.InventoryDetailSlimStocks.prototype.addStockHistory = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.vtv1.InventoryStockDatapoint, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.InventoryDetailSlimStocks} returns this
 */
proto.vtv1.InventoryDetailSlimStocks.prototype.clearStockHistoryList = function() {
  return this.setStockHistoryList([]);
};


/**
 * repeated InventoryStockDatapoint stock_predictions = 2;
 * @return {!Array<!proto.vtv1.InventoryStockDatapoint>}
 */
proto.vtv1.InventoryDetailSlimStocks.prototype.getStockPredictionsList = function() {
  return /** @type{!Array<!proto.vtv1.InventoryStockDatapoint>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vtv1.InventoryStockDatapoint, 2));
};


/**
 * @param {!Array<!proto.vtv1.InventoryStockDatapoint>} value
 * @return {!proto.vtv1.InventoryDetailSlimStocks} returns this
*/
proto.vtv1.InventoryDetailSlimStocks.prototype.setStockPredictionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.vtv1.InventoryStockDatapoint=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.InventoryStockDatapoint}
 */
proto.vtv1.InventoryDetailSlimStocks.prototype.addStockPredictions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.vtv1.InventoryStockDatapoint, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.InventoryDetailSlimStocks} returns this
 */
proto.vtv1.InventoryDetailSlimStocks.prototype.clearStockPredictionsList = function() {
  return this.setStockPredictionsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.InventoryDetailShipments.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.InventoryDetailShipments.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.InventoryDetailShipments.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.InventoryDetailShipments} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.InventoryDetailShipments.toObject = function(includeInstance, msg) {
  var f, obj = {
    shipmentsList: jspb.Message.toObjectList(msg.getShipmentsList(),
    proto.vtv1.InventoryDetailShipment.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.InventoryDetailShipments}
 */
proto.vtv1.InventoryDetailShipments.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.InventoryDetailShipments;
  return proto.vtv1.InventoryDetailShipments.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.InventoryDetailShipments} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.InventoryDetailShipments}
 */
proto.vtv1.InventoryDetailShipments.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.vtv1.InventoryDetailShipment;
      reader.readMessage(value,proto.vtv1.InventoryDetailShipment.deserializeBinaryFromReader);
      msg.addShipments(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.InventoryDetailShipments.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.InventoryDetailShipments.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.InventoryDetailShipments} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.InventoryDetailShipments.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getShipmentsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.vtv1.InventoryDetailShipment.serializeBinaryToWriter
    );
  }
};


/**
 * repeated InventoryDetailShipment shipments = 1;
 * @return {!Array<!proto.vtv1.InventoryDetailShipment>}
 */
proto.vtv1.InventoryDetailShipments.prototype.getShipmentsList = function() {
  return /** @type{!Array<!proto.vtv1.InventoryDetailShipment>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vtv1.InventoryDetailShipment, 1));
};


/**
 * @param {!Array<!proto.vtv1.InventoryDetailShipment>} value
 * @return {!proto.vtv1.InventoryDetailShipments} returns this
*/
proto.vtv1.InventoryDetailShipments.prototype.setShipmentsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.vtv1.InventoryDetailShipment=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.InventoryDetailShipment}
 */
proto.vtv1.InventoryDetailShipments.prototype.addShipments = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.vtv1.InventoryDetailShipment, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.InventoryDetailShipments} returns this
 */
proto.vtv1.InventoryDetailShipments.prototype.clearShipmentsList = function() {
  return this.setShipmentsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.InventoryDetailShipment.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.InventoryDetailShipment.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.InventoryDetailShipment} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.InventoryDetailShipment.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    truckNumber: (f = msg.getTruckNumber()) && proto_vorto_types_types_pb.NullableString.toObject(includeInstance, f),
    status: jspb.Message.getFieldWithDefault(msg, 3, 0),
    eta: (f = msg.getEta()) && proto_vorto_types_types_pb.NullableTime.toObject(includeInstance, f),
    etaUpdatedAt: (f = msg.getEtaUpdatedAt()) && proto_vorto_types_types_pb.NullableTime.toObject(includeInstance, f),
    arrivedAt: (f = msg.getArrivedAt()) && proto_vorto_types_types_pb.NullableTime.toObject(includeInstance, f),
    productName: jspb.Message.getFieldWithDefault(msg, 7, ""),
    quantity: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
    unitName: jspb.Message.getFieldWithDefault(msg, 9, ""),
    unitAbbreviation: jspb.Message.getFieldWithDefault(msg, 10, ""),
    arrivalWindowStartsAt: (f = msg.getArrivalWindowStartsAt()) && proto_vorto_types_types_pb.NullableTime.toObject(includeInstance, f),
    arrivalWindowMinutes: (f = msg.getArrivalWindowMinutes()) && proto_vorto_types_types_pb.NullableInt64.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.InventoryDetailShipment}
 */
proto.vtv1.InventoryDetailShipment.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.InventoryDetailShipment;
  return proto.vtv1.InventoryDetailShipment.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.InventoryDetailShipment} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.InventoryDetailShipment}
 */
proto.vtv1.InventoryDetailShipment.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new proto_vorto_types_types_pb.NullableString;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableString.deserializeBinaryFromReader);
      msg.setTruckNumber(value);
      break;
    case 3:
      var value = /** @type {!proto.vtv1.ShipmentStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 4:
      var value = new proto_vorto_types_types_pb.NullableTime;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableTime.deserializeBinaryFromReader);
      msg.setEta(value);
      break;
    case 5:
      var value = new proto_vorto_types_types_pb.NullableTime;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableTime.deserializeBinaryFromReader);
      msg.setEtaUpdatedAt(value);
      break;
    case 6:
      var value = new proto_vorto_types_types_pb.NullableTime;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableTime.deserializeBinaryFromReader);
      msg.setArrivedAt(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductName(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setQuantity(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setUnitName(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setUnitAbbreviation(value);
      break;
    case 11:
      var value = new proto_vorto_types_types_pb.NullableTime;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableTime.deserializeBinaryFromReader);
      msg.setArrivalWindowStartsAt(value);
      break;
    case 12:
      var value = new proto_vorto_types_types_pb.NullableInt64;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableInt64.deserializeBinaryFromReader);
      msg.setArrivalWindowMinutes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.InventoryDetailShipment.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.InventoryDetailShipment.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.InventoryDetailShipment} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.InventoryDetailShipment.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTruckNumber();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_vorto_types_types_pb.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getEta();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto_vorto_types_types_pb.NullableTime.serializeBinaryToWriter
    );
  }
  f = message.getEtaUpdatedAt();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto_vorto_types_types_pb.NullableTime.serializeBinaryToWriter
    );
  }
  f = message.getArrivedAt();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto_vorto_types_types_pb.NullableTime.serializeBinaryToWriter
    );
  }
  f = message.getProductName();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getQuantity();
  if (f !== 0.0) {
    writer.writeDouble(
      8,
      f
    );
  }
  f = message.getUnitName();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getUnitAbbreviation();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getArrivalWindowStartsAt();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto_vorto_types_types_pb.NullableTime.serializeBinaryToWriter
    );
  }
  f = message.getArrivalWindowMinutes();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      proto_vorto_types_types_pb.NullableInt64.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.vtv1.InventoryDetailShipment.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.InventoryDetailShipment} returns this
 */
proto.vtv1.InventoryDetailShipment.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional NullableString truck_number = 2;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.InventoryDetailShipment.prototype.getTruckNumber = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableString, 2));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.InventoryDetailShipment} returns this
*/
proto.vtv1.InventoryDetailShipment.prototype.setTruckNumber = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.InventoryDetailShipment} returns this
 */
proto.vtv1.InventoryDetailShipment.prototype.clearTruckNumber = function() {
  return this.setTruckNumber(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.InventoryDetailShipment.prototype.hasTruckNumber = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional ShipmentStatus status = 3;
 * @return {!proto.vtv1.ShipmentStatus}
 */
proto.vtv1.InventoryDetailShipment.prototype.getStatus = function() {
  return /** @type {!proto.vtv1.ShipmentStatus} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.vtv1.ShipmentStatus} value
 * @return {!proto.vtv1.InventoryDetailShipment} returns this
 */
proto.vtv1.InventoryDetailShipment.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional NullableTime eta = 4;
 * @return {?proto.vtv1.NullableTime}
 */
proto.vtv1.InventoryDetailShipment.prototype.getEta = function() {
  return /** @type{?proto.vtv1.NullableTime} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableTime, 4));
};


/**
 * @param {?proto.vtv1.NullableTime|undefined} value
 * @return {!proto.vtv1.InventoryDetailShipment} returns this
*/
proto.vtv1.InventoryDetailShipment.prototype.setEta = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.InventoryDetailShipment} returns this
 */
proto.vtv1.InventoryDetailShipment.prototype.clearEta = function() {
  return this.setEta(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.InventoryDetailShipment.prototype.hasEta = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional NullableTime eta_updated_at = 5;
 * @return {?proto.vtv1.NullableTime}
 */
proto.vtv1.InventoryDetailShipment.prototype.getEtaUpdatedAt = function() {
  return /** @type{?proto.vtv1.NullableTime} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableTime, 5));
};


/**
 * @param {?proto.vtv1.NullableTime|undefined} value
 * @return {!proto.vtv1.InventoryDetailShipment} returns this
*/
proto.vtv1.InventoryDetailShipment.prototype.setEtaUpdatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.InventoryDetailShipment} returns this
 */
proto.vtv1.InventoryDetailShipment.prototype.clearEtaUpdatedAt = function() {
  return this.setEtaUpdatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.InventoryDetailShipment.prototype.hasEtaUpdatedAt = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional NullableTime arrived_at = 6;
 * @return {?proto.vtv1.NullableTime}
 */
proto.vtv1.InventoryDetailShipment.prototype.getArrivedAt = function() {
  return /** @type{?proto.vtv1.NullableTime} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableTime, 6));
};


/**
 * @param {?proto.vtv1.NullableTime|undefined} value
 * @return {!proto.vtv1.InventoryDetailShipment} returns this
*/
proto.vtv1.InventoryDetailShipment.prototype.setArrivedAt = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.InventoryDetailShipment} returns this
 */
proto.vtv1.InventoryDetailShipment.prototype.clearArrivedAt = function() {
  return this.setArrivedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.InventoryDetailShipment.prototype.hasArrivedAt = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional string product_name = 7;
 * @return {string}
 */
proto.vtv1.InventoryDetailShipment.prototype.getProductName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.InventoryDetailShipment} returns this
 */
proto.vtv1.InventoryDetailShipment.prototype.setProductName = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional double quantity = 8;
 * @return {number}
 */
proto.vtv1.InventoryDetailShipment.prototype.getQuantity = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.InventoryDetailShipment} returns this
 */
proto.vtv1.InventoryDetailShipment.prototype.setQuantity = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};


/**
 * optional string unit_name = 9;
 * @return {string}
 */
proto.vtv1.InventoryDetailShipment.prototype.getUnitName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.InventoryDetailShipment} returns this
 */
proto.vtv1.InventoryDetailShipment.prototype.setUnitName = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string unit_abbreviation = 10;
 * @return {string}
 */
proto.vtv1.InventoryDetailShipment.prototype.getUnitAbbreviation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.InventoryDetailShipment} returns this
 */
proto.vtv1.InventoryDetailShipment.prototype.setUnitAbbreviation = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional NullableTime arrival_window_starts_at = 11;
 * @return {?proto.vtv1.NullableTime}
 */
proto.vtv1.InventoryDetailShipment.prototype.getArrivalWindowStartsAt = function() {
  return /** @type{?proto.vtv1.NullableTime} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableTime, 11));
};


/**
 * @param {?proto.vtv1.NullableTime|undefined} value
 * @return {!proto.vtv1.InventoryDetailShipment} returns this
*/
proto.vtv1.InventoryDetailShipment.prototype.setArrivalWindowStartsAt = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.InventoryDetailShipment} returns this
 */
proto.vtv1.InventoryDetailShipment.prototype.clearArrivalWindowStartsAt = function() {
  return this.setArrivalWindowStartsAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.InventoryDetailShipment.prototype.hasArrivalWindowStartsAt = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional NullableInt64 arrival_window_minutes = 12;
 * @return {?proto.vtv1.NullableInt64}
 */
proto.vtv1.InventoryDetailShipment.prototype.getArrivalWindowMinutes = function() {
  return /** @type{?proto.vtv1.NullableInt64} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableInt64, 12));
};


/**
 * @param {?proto.vtv1.NullableInt64|undefined} value
 * @return {!proto.vtv1.InventoryDetailShipment} returns this
*/
proto.vtv1.InventoryDetailShipment.prototype.setArrivalWindowMinutes = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.InventoryDetailShipment} returns this
 */
proto.vtv1.InventoryDetailShipment.prototype.clearArrivalWindowMinutes = function() {
  return this.setArrivalWindowMinutes(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.InventoryDetailShipment.prototype.hasArrivalWindowMinutes = function() {
  return jspb.Message.getField(this, 12) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.VMIInventoryListItem.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.VMIInventoryListItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.VMIInventoryListItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.VMIInventoryListItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    productId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    productName: jspb.Message.getFieldWithDefault(msg, 4, ""),
    unitName: jspb.Message.getFieldWithDefault(msg, 5, ""),
    unitAbbreviation: jspb.Message.getFieldWithDefault(msg, 6, ""),
    capacity: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    stock: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
    site: (f = msg.getSite()) && proto_vorto_types_types_pb.StringIdName.toObject(includeInstance, f),
    owner: (f = msg.getOwner()) && proto_vorto_types_types_pb.StringIdName.toObject(includeInstance, f),
    stockOutAt: (f = msg.getStockOutAt()) && proto_vorto_types_types_pb.NullableTime.toObject(includeInstance, f),
    stockUpdatedAt: (f = msg.getStockUpdatedAt()) && proto_vorto_types_types_pb.NullableTime.toObject(includeInstance, f),
    stockUpdatedById: (f = msg.getStockUpdatedById()) && proto_vorto_types_types_pb.NullableString.toObject(includeInstance, f),
    stockUpdatedByName: (f = msg.getStockUpdatedByName()) && proto_vorto_types_types_pb.NullableString.toObject(includeInstance, f),
    orders: jspb.Message.getFieldWithDefault(msg, 15, 0),
    viewedAt: (f = msg.getViewedAt()) && proto_vorto_types_types_pb.NullableTime.toObject(includeInstance, f),
    subscribed: jspb.Message.getBooleanFieldWithDefault(msg, 17, false),
    targetZeroStock: jspb.Message.getBooleanFieldWithDefault(msg, 18, false),
    warningThreshold: (f = msg.getWarningThreshold()) && proto_vorto_types_types_pb.NullableDouble.toObject(includeInstance, f),
    inbound: jspb.Message.getBooleanFieldWithDefault(msg, 20, false),
    outbound: jspb.Message.getBooleanFieldWithDefault(msg, 21, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.VMIInventoryListItem}
 */
proto.vtv1.VMIInventoryListItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.VMIInventoryListItem;
  return proto.vtv1.VMIInventoryListItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.VMIInventoryListItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.VMIInventoryListItem}
 */
proto.vtv1.VMIInventoryListItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductName(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setUnitName(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setUnitAbbreviation(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setCapacity(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setStock(value);
      break;
    case 9:
      var value = new proto_vorto_types_types_pb.StringIdName;
      reader.readMessage(value,proto_vorto_types_types_pb.StringIdName.deserializeBinaryFromReader);
      msg.setSite(value);
      break;
    case 10:
      var value = new proto_vorto_types_types_pb.StringIdName;
      reader.readMessage(value,proto_vorto_types_types_pb.StringIdName.deserializeBinaryFromReader);
      msg.setOwner(value);
      break;
    case 11:
      var value = new proto_vorto_types_types_pb.NullableTime;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableTime.deserializeBinaryFromReader);
      msg.setStockOutAt(value);
      break;
    case 12:
      var value = new proto_vorto_types_types_pb.NullableTime;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableTime.deserializeBinaryFromReader);
      msg.setStockUpdatedAt(value);
      break;
    case 13:
      var value = new proto_vorto_types_types_pb.NullableString;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableString.deserializeBinaryFromReader);
      msg.setStockUpdatedById(value);
      break;
    case 14:
      var value = new proto_vorto_types_types_pb.NullableString;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableString.deserializeBinaryFromReader);
      msg.setStockUpdatedByName(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setOrders(value);
      break;
    case 16:
      var value = new proto_vorto_types_types_pb.NullableTime;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableTime.deserializeBinaryFromReader);
      msg.setViewedAt(value);
      break;
    case 17:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSubscribed(value);
      break;
    case 18:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setTargetZeroStock(value);
      break;
    case 19:
      var value = new proto_vorto_types_types_pb.NullableDouble;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableDouble.deserializeBinaryFromReader);
      msg.setWarningThreshold(value);
      break;
    case 20:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setInbound(value);
      break;
    case 21:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOutbound(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.VMIInventoryListItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.VMIInventoryListItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.VMIInventoryListItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.VMIInventoryListItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getProductId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getProductName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getUnitName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getUnitAbbreviation();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getCapacity();
  if (f !== 0.0) {
    writer.writeDouble(
      7,
      f
    );
  }
  f = message.getStock();
  if (f !== 0.0) {
    writer.writeDouble(
      8,
      f
    );
  }
  f = message.getSite();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto_vorto_types_types_pb.StringIdName.serializeBinaryToWriter
    );
  }
  f = message.getOwner();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto_vorto_types_types_pb.StringIdName.serializeBinaryToWriter
    );
  }
  f = message.getStockOutAt();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto_vorto_types_types_pb.NullableTime.serializeBinaryToWriter
    );
  }
  f = message.getStockUpdatedAt();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      proto_vorto_types_types_pb.NullableTime.serializeBinaryToWriter
    );
  }
  f = message.getStockUpdatedById();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      proto_vorto_types_types_pb.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getStockUpdatedByName();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      proto_vorto_types_types_pb.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getOrders();
  if (f !== 0) {
    writer.writeInt64(
      15,
      f
    );
  }
  f = message.getViewedAt();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      proto_vorto_types_types_pb.NullableTime.serializeBinaryToWriter
    );
  }
  f = message.getSubscribed();
  if (f) {
    writer.writeBool(
      17,
      f
    );
  }
  f = message.getTargetZeroStock();
  if (f) {
    writer.writeBool(
      18,
      f
    );
  }
  f = message.getWarningThreshold();
  if (f != null) {
    writer.writeMessage(
      19,
      f,
      proto_vorto_types_types_pb.NullableDouble.serializeBinaryToWriter
    );
  }
  f = message.getInbound();
  if (f) {
    writer.writeBool(
      20,
      f
    );
  }
  f = message.getOutbound();
  if (f) {
    writer.writeBool(
      21,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.vtv1.VMIInventoryListItem.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.VMIInventoryListItem} returns this
 */
proto.vtv1.VMIInventoryListItem.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.vtv1.VMIInventoryListItem.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.VMIInventoryListItem} returns this
 */
proto.vtv1.VMIInventoryListItem.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string product_id = 3;
 * @return {string}
 */
proto.vtv1.VMIInventoryListItem.prototype.getProductId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.VMIInventoryListItem} returns this
 */
proto.vtv1.VMIInventoryListItem.prototype.setProductId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string product_name = 4;
 * @return {string}
 */
proto.vtv1.VMIInventoryListItem.prototype.getProductName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.VMIInventoryListItem} returns this
 */
proto.vtv1.VMIInventoryListItem.prototype.setProductName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string unit_name = 5;
 * @return {string}
 */
proto.vtv1.VMIInventoryListItem.prototype.getUnitName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.VMIInventoryListItem} returns this
 */
proto.vtv1.VMIInventoryListItem.prototype.setUnitName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string unit_abbreviation = 6;
 * @return {string}
 */
proto.vtv1.VMIInventoryListItem.prototype.getUnitAbbreviation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.VMIInventoryListItem} returns this
 */
proto.vtv1.VMIInventoryListItem.prototype.setUnitAbbreviation = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional double capacity = 7;
 * @return {number}
 */
proto.vtv1.VMIInventoryListItem.prototype.getCapacity = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.VMIInventoryListItem} returns this
 */
proto.vtv1.VMIInventoryListItem.prototype.setCapacity = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional double stock = 8;
 * @return {number}
 */
proto.vtv1.VMIInventoryListItem.prototype.getStock = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.VMIInventoryListItem} returns this
 */
proto.vtv1.VMIInventoryListItem.prototype.setStock = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};


/**
 * optional StringIdName site = 9;
 * @return {?proto.vtv1.StringIdName}
 */
proto.vtv1.VMIInventoryListItem.prototype.getSite = function() {
  return /** @type{?proto.vtv1.StringIdName} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.StringIdName, 9));
};


/**
 * @param {?proto.vtv1.StringIdName|undefined} value
 * @return {!proto.vtv1.VMIInventoryListItem} returns this
*/
proto.vtv1.VMIInventoryListItem.prototype.setSite = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.VMIInventoryListItem} returns this
 */
proto.vtv1.VMIInventoryListItem.prototype.clearSite = function() {
  return this.setSite(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.VMIInventoryListItem.prototype.hasSite = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional StringIdName owner = 10;
 * @return {?proto.vtv1.StringIdName}
 */
proto.vtv1.VMIInventoryListItem.prototype.getOwner = function() {
  return /** @type{?proto.vtv1.StringIdName} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.StringIdName, 10));
};


/**
 * @param {?proto.vtv1.StringIdName|undefined} value
 * @return {!proto.vtv1.VMIInventoryListItem} returns this
*/
proto.vtv1.VMIInventoryListItem.prototype.setOwner = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.VMIInventoryListItem} returns this
 */
proto.vtv1.VMIInventoryListItem.prototype.clearOwner = function() {
  return this.setOwner(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.VMIInventoryListItem.prototype.hasOwner = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional NullableTime stock_out_at = 11;
 * @return {?proto.vtv1.NullableTime}
 */
proto.vtv1.VMIInventoryListItem.prototype.getStockOutAt = function() {
  return /** @type{?proto.vtv1.NullableTime} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableTime, 11));
};


/**
 * @param {?proto.vtv1.NullableTime|undefined} value
 * @return {!proto.vtv1.VMIInventoryListItem} returns this
*/
proto.vtv1.VMIInventoryListItem.prototype.setStockOutAt = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.VMIInventoryListItem} returns this
 */
proto.vtv1.VMIInventoryListItem.prototype.clearStockOutAt = function() {
  return this.setStockOutAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.VMIInventoryListItem.prototype.hasStockOutAt = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional NullableTime stock_updated_at = 12;
 * @return {?proto.vtv1.NullableTime}
 */
proto.vtv1.VMIInventoryListItem.prototype.getStockUpdatedAt = function() {
  return /** @type{?proto.vtv1.NullableTime} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableTime, 12));
};


/**
 * @param {?proto.vtv1.NullableTime|undefined} value
 * @return {!proto.vtv1.VMIInventoryListItem} returns this
*/
proto.vtv1.VMIInventoryListItem.prototype.setStockUpdatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.VMIInventoryListItem} returns this
 */
proto.vtv1.VMIInventoryListItem.prototype.clearStockUpdatedAt = function() {
  return this.setStockUpdatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.VMIInventoryListItem.prototype.hasStockUpdatedAt = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional NullableString stock_updated_by_id = 13;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.VMIInventoryListItem.prototype.getStockUpdatedById = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableString, 13));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.VMIInventoryListItem} returns this
*/
proto.vtv1.VMIInventoryListItem.prototype.setStockUpdatedById = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.VMIInventoryListItem} returns this
 */
proto.vtv1.VMIInventoryListItem.prototype.clearStockUpdatedById = function() {
  return this.setStockUpdatedById(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.VMIInventoryListItem.prototype.hasStockUpdatedById = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional NullableString stock_updated_by_name = 14;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.VMIInventoryListItem.prototype.getStockUpdatedByName = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableString, 14));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.VMIInventoryListItem} returns this
*/
proto.vtv1.VMIInventoryListItem.prototype.setStockUpdatedByName = function(value) {
  return jspb.Message.setWrapperField(this, 14, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.VMIInventoryListItem} returns this
 */
proto.vtv1.VMIInventoryListItem.prototype.clearStockUpdatedByName = function() {
  return this.setStockUpdatedByName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.VMIInventoryListItem.prototype.hasStockUpdatedByName = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional int64 orders = 15;
 * @return {number}
 */
proto.vtv1.VMIInventoryListItem.prototype.getOrders = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.VMIInventoryListItem} returns this
 */
proto.vtv1.VMIInventoryListItem.prototype.setOrders = function(value) {
  return jspb.Message.setProto3IntField(this, 15, value);
};


/**
 * optional NullableTime viewed_at = 16;
 * @return {?proto.vtv1.NullableTime}
 */
proto.vtv1.VMIInventoryListItem.prototype.getViewedAt = function() {
  return /** @type{?proto.vtv1.NullableTime} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableTime, 16));
};


/**
 * @param {?proto.vtv1.NullableTime|undefined} value
 * @return {!proto.vtv1.VMIInventoryListItem} returns this
*/
proto.vtv1.VMIInventoryListItem.prototype.setViewedAt = function(value) {
  return jspb.Message.setWrapperField(this, 16, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.VMIInventoryListItem} returns this
 */
proto.vtv1.VMIInventoryListItem.prototype.clearViewedAt = function() {
  return this.setViewedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.VMIInventoryListItem.prototype.hasViewedAt = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * optional bool subscribed = 17;
 * @return {boolean}
 */
proto.vtv1.VMIInventoryListItem.prototype.getSubscribed = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 17, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vtv1.VMIInventoryListItem} returns this
 */
proto.vtv1.VMIInventoryListItem.prototype.setSubscribed = function(value) {
  return jspb.Message.setProto3BooleanField(this, 17, value);
};


/**
 * optional bool target_zero_stock = 18;
 * @return {boolean}
 */
proto.vtv1.VMIInventoryListItem.prototype.getTargetZeroStock = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 18, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vtv1.VMIInventoryListItem} returns this
 */
proto.vtv1.VMIInventoryListItem.prototype.setTargetZeroStock = function(value) {
  return jspb.Message.setProto3BooleanField(this, 18, value);
};


/**
 * optional NullableDouble warning_threshold = 19;
 * @return {?proto.vtv1.NullableDouble}
 */
proto.vtv1.VMIInventoryListItem.prototype.getWarningThreshold = function() {
  return /** @type{?proto.vtv1.NullableDouble} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableDouble, 19));
};


/**
 * @param {?proto.vtv1.NullableDouble|undefined} value
 * @return {!proto.vtv1.VMIInventoryListItem} returns this
*/
proto.vtv1.VMIInventoryListItem.prototype.setWarningThreshold = function(value) {
  return jspb.Message.setWrapperField(this, 19, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.VMIInventoryListItem} returns this
 */
proto.vtv1.VMIInventoryListItem.prototype.clearWarningThreshold = function() {
  return this.setWarningThreshold(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.VMIInventoryListItem.prototype.hasWarningThreshold = function() {
  return jspb.Message.getField(this, 19) != null;
};


/**
 * optional bool inbound = 20;
 * @return {boolean}
 */
proto.vtv1.VMIInventoryListItem.prototype.getInbound = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 20, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vtv1.VMIInventoryListItem} returns this
 */
proto.vtv1.VMIInventoryListItem.prototype.setInbound = function(value) {
  return jspb.Message.setProto3BooleanField(this, 20, value);
};


/**
 * optional bool outbound = 21;
 * @return {boolean}
 */
proto.vtv1.VMIInventoryListItem.prototype.getOutbound = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 21, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vtv1.VMIInventoryListItem} returns this
 */
proto.vtv1.VMIInventoryListItem.prototype.setOutbound = function(value) {
  return jspb.Message.setProto3BooleanField(this, 21, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.VMIUserAvailableForInventorySubscription.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.VMIUserAvailableForInventorySubscription.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.VMIUserAvailableForInventorySubscription} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.VMIUserAvailableForInventorySubscription.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    email: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.VMIUserAvailableForInventorySubscription}
 */
proto.vtv1.VMIUserAvailableForInventorySubscription.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.VMIUserAvailableForInventorySubscription;
  return proto.vtv1.VMIUserAvailableForInventorySubscription.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.VMIUserAvailableForInventorySubscription} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.VMIUserAvailableForInventorySubscription}
 */
proto.vtv1.VMIUserAvailableForInventorySubscription.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.VMIUserAvailableForInventorySubscription.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.VMIUserAvailableForInventorySubscription.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.VMIUserAvailableForInventorySubscription} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.VMIUserAvailableForInventorySubscription.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.vtv1.VMIUserAvailableForInventorySubscription.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.VMIUserAvailableForInventorySubscription} returns this
 */
proto.vtv1.VMIUserAvailableForInventorySubscription.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.vtv1.VMIUserAvailableForInventorySubscription.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.VMIUserAvailableForInventorySubscription} returns this
 */
proto.vtv1.VMIUserAvailableForInventorySubscription.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string email = 3;
 * @return {string}
 */
proto.vtv1.VMIUserAvailableForInventorySubscription.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.VMIUserAvailableForInventorySubscription} returns this
 */
proto.vtv1.VMIUserAvailableForInventorySubscription.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.VMISlimFacility.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.VMISlimFacility.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.VMISlimFacility} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.VMISlimFacility.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    owner: (f = msg.getOwner()) && proto_vorto_types_types_pb.StringIdName.toObject(includeInstance, f),
    address: (f = msg.getAddress()) && proto_vorto_types_types_pb.NullableString.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.VMISlimFacility}
 */
proto.vtv1.VMISlimFacility.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.VMISlimFacility;
  return proto.vtv1.VMISlimFacility.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.VMISlimFacility} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.VMISlimFacility}
 */
proto.vtv1.VMISlimFacility.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = new proto_vorto_types_types_pb.StringIdName;
      reader.readMessage(value,proto_vorto_types_types_pb.StringIdName.deserializeBinaryFromReader);
      msg.setOwner(value);
      break;
    case 4:
      var value = new proto_vorto_types_types_pb.NullableString;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableString.deserializeBinaryFromReader);
      msg.setAddress(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.VMISlimFacility.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.VMISlimFacility.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.VMISlimFacility} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.VMISlimFacility.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getOwner();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto_vorto_types_types_pb.StringIdName.serializeBinaryToWriter
    );
  }
  f = message.getAddress();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto_vorto_types_types_pb.NullableString.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.vtv1.VMISlimFacility.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.VMISlimFacility} returns this
 */
proto.vtv1.VMISlimFacility.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.vtv1.VMISlimFacility.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.VMISlimFacility} returns this
 */
proto.vtv1.VMISlimFacility.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional StringIdName owner = 3;
 * @return {?proto.vtv1.StringIdName}
 */
proto.vtv1.VMISlimFacility.prototype.getOwner = function() {
  return /** @type{?proto.vtv1.StringIdName} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.StringIdName, 3));
};


/**
 * @param {?proto.vtv1.StringIdName|undefined} value
 * @return {!proto.vtv1.VMISlimFacility} returns this
*/
proto.vtv1.VMISlimFacility.prototype.setOwner = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.VMISlimFacility} returns this
 */
proto.vtv1.VMISlimFacility.prototype.clearOwner = function() {
  return this.setOwner(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.VMISlimFacility.prototype.hasOwner = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional NullableString address = 4;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.VMISlimFacility.prototype.getAddress = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableString, 4));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.VMISlimFacility} returns this
*/
proto.vtv1.VMISlimFacility.prototype.setAddress = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.VMISlimFacility} returns this
 */
proto.vtv1.VMISlimFacility.prototype.clearAddress = function() {
  return this.setAddress(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.VMISlimFacility.prototype.hasAddress = function() {
  return jspb.Message.getField(this, 4) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.VMIFacilityDetail.repeatedFields_ = [15];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.VMIFacilityDetail.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.VMIFacilityDetail.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.VMIFacilityDetail} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.VMIFacilityDetail.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    address: (f = msg.getAddress()) && proto_vorto_types_types_pb.NullableString.toObject(includeInstance, f),
    lng: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    lat: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    radius: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    schedule: (f = msg.getSchedule()) && proto_vorto_types_types_pb.SiteLoadingSchedule.toObject(includeInstance, f),
    dockCount: jspb.Message.getFieldWithDefault(msg, 8, 0),
    directions: (f = msg.getDirections()) && proto_vorto_types_types_pb.NullableString.toObject(includeInstance, f),
    loadingInstructions: (f = msg.getLoadingInstructions()) && proto_vorto_types_types_pb.NullableString.toObject(includeInstance, f),
    contactName: (f = msg.getContactName()) && proto_vorto_types_types_pb.NullableString.toObject(includeInstance, f),
    contactPhone: (f = msg.getContactPhone()) && proto_vorto_types_types_pb.NullableString.toObject(includeInstance, f),
    contactEmail: (f = msg.getContactEmail()) && proto_vorto_types_types_pb.NullableString.toObject(includeInstance, f),
    preferredContactMethod: jspb.Message.getFieldWithDefault(msg, 14, ""),
    docksList: jspb.Message.toObjectList(msg.getDocksList(),
    proto.vtv1.VMIFacilityDock.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.VMIFacilityDetail}
 */
proto.vtv1.VMIFacilityDetail.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.VMIFacilityDetail;
  return proto.vtv1.VMIFacilityDetail.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.VMIFacilityDetail} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.VMIFacilityDetail}
 */
proto.vtv1.VMIFacilityDetail.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = new proto_vorto_types_types_pb.NullableString;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableString.deserializeBinaryFromReader);
      msg.setAddress(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLng(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLat(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setRadius(value);
      break;
    case 7:
      var value = new proto_vorto_types_types_pb.SiteLoadingSchedule;
      reader.readMessage(value,proto_vorto_types_types_pb.SiteLoadingSchedule.deserializeBinaryFromReader);
      msg.setSchedule(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDockCount(value);
      break;
    case 9:
      var value = new proto_vorto_types_types_pb.NullableString;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableString.deserializeBinaryFromReader);
      msg.setDirections(value);
      break;
    case 10:
      var value = new proto_vorto_types_types_pb.NullableString;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableString.deserializeBinaryFromReader);
      msg.setLoadingInstructions(value);
      break;
    case 11:
      var value = new proto_vorto_types_types_pb.NullableString;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableString.deserializeBinaryFromReader);
      msg.setContactName(value);
      break;
    case 12:
      var value = new proto_vorto_types_types_pb.NullableString;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableString.deserializeBinaryFromReader);
      msg.setContactPhone(value);
      break;
    case 13:
      var value = new proto_vorto_types_types_pb.NullableString;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableString.deserializeBinaryFromReader);
      msg.setContactEmail(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setPreferredContactMethod(value);
      break;
    case 15:
      var value = new proto.vtv1.VMIFacilityDock;
      reader.readMessage(value,proto.vtv1.VMIFacilityDock.deserializeBinaryFromReader);
      msg.addDocks(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.VMIFacilityDetail.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.VMIFacilityDetail.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.VMIFacilityDetail} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.VMIFacilityDetail.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAddress();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto_vorto_types_types_pb.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getLng();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getLat();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getRadius();
  if (f !== 0.0) {
    writer.writeDouble(
      6,
      f
    );
  }
  f = message.getSchedule();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto_vorto_types_types_pb.SiteLoadingSchedule.serializeBinaryToWriter
    );
  }
  f = message.getDockCount();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
  f = message.getDirections();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto_vorto_types_types_pb.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getLoadingInstructions();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto_vorto_types_types_pb.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getContactName();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto_vorto_types_types_pb.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getContactPhone();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      proto_vorto_types_types_pb.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getContactEmail();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      proto_vorto_types_types_pb.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getPreferredContactMethod();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getDocksList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      15,
      f,
      proto.vtv1.VMIFacilityDock.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.vtv1.VMIFacilityDetail.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.VMIFacilityDetail} returns this
 */
proto.vtv1.VMIFacilityDetail.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.vtv1.VMIFacilityDetail.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.VMIFacilityDetail} returns this
 */
proto.vtv1.VMIFacilityDetail.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional NullableString address = 3;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.VMIFacilityDetail.prototype.getAddress = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableString, 3));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.VMIFacilityDetail} returns this
*/
proto.vtv1.VMIFacilityDetail.prototype.setAddress = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.VMIFacilityDetail} returns this
 */
proto.vtv1.VMIFacilityDetail.prototype.clearAddress = function() {
  return this.setAddress(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.VMIFacilityDetail.prototype.hasAddress = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional double lng = 4;
 * @return {number}
 */
proto.vtv1.VMIFacilityDetail.prototype.getLng = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.VMIFacilityDetail} returns this
 */
proto.vtv1.VMIFacilityDetail.prototype.setLng = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional double lat = 5;
 * @return {number}
 */
proto.vtv1.VMIFacilityDetail.prototype.getLat = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.VMIFacilityDetail} returns this
 */
proto.vtv1.VMIFacilityDetail.prototype.setLat = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional double radius = 6;
 * @return {number}
 */
proto.vtv1.VMIFacilityDetail.prototype.getRadius = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.VMIFacilityDetail} returns this
 */
proto.vtv1.VMIFacilityDetail.prototype.setRadius = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional SiteLoadingSchedule schedule = 7;
 * @return {?proto.vtv1.SiteLoadingSchedule}
 */
proto.vtv1.VMIFacilityDetail.prototype.getSchedule = function() {
  return /** @type{?proto.vtv1.SiteLoadingSchedule} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.SiteLoadingSchedule, 7));
};


/**
 * @param {?proto.vtv1.SiteLoadingSchedule|undefined} value
 * @return {!proto.vtv1.VMIFacilityDetail} returns this
*/
proto.vtv1.VMIFacilityDetail.prototype.setSchedule = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.VMIFacilityDetail} returns this
 */
proto.vtv1.VMIFacilityDetail.prototype.clearSchedule = function() {
  return this.setSchedule(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.VMIFacilityDetail.prototype.hasSchedule = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional int64 dock_count = 8;
 * @return {number}
 */
proto.vtv1.VMIFacilityDetail.prototype.getDockCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.VMIFacilityDetail} returns this
 */
proto.vtv1.VMIFacilityDetail.prototype.setDockCount = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional NullableString directions = 9;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.VMIFacilityDetail.prototype.getDirections = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableString, 9));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.VMIFacilityDetail} returns this
*/
proto.vtv1.VMIFacilityDetail.prototype.setDirections = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.VMIFacilityDetail} returns this
 */
proto.vtv1.VMIFacilityDetail.prototype.clearDirections = function() {
  return this.setDirections(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.VMIFacilityDetail.prototype.hasDirections = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional NullableString loading_instructions = 10;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.VMIFacilityDetail.prototype.getLoadingInstructions = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableString, 10));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.VMIFacilityDetail} returns this
*/
proto.vtv1.VMIFacilityDetail.prototype.setLoadingInstructions = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.VMIFacilityDetail} returns this
 */
proto.vtv1.VMIFacilityDetail.prototype.clearLoadingInstructions = function() {
  return this.setLoadingInstructions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.VMIFacilityDetail.prototype.hasLoadingInstructions = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional NullableString contact_name = 11;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.VMIFacilityDetail.prototype.getContactName = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableString, 11));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.VMIFacilityDetail} returns this
*/
proto.vtv1.VMIFacilityDetail.prototype.setContactName = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.VMIFacilityDetail} returns this
 */
proto.vtv1.VMIFacilityDetail.prototype.clearContactName = function() {
  return this.setContactName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.VMIFacilityDetail.prototype.hasContactName = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional NullableString contact_phone = 12;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.VMIFacilityDetail.prototype.getContactPhone = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableString, 12));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.VMIFacilityDetail} returns this
*/
proto.vtv1.VMIFacilityDetail.prototype.setContactPhone = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.VMIFacilityDetail} returns this
 */
proto.vtv1.VMIFacilityDetail.prototype.clearContactPhone = function() {
  return this.setContactPhone(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.VMIFacilityDetail.prototype.hasContactPhone = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional NullableString contact_email = 13;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.VMIFacilityDetail.prototype.getContactEmail = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableString, 13));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.VMIFacilityDetail} returns this
*/
proto.vtv1.VMIFacilityDetail.prototype.setContactEmail = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.VMIFacilityDetail} returns this
 */
proto.vtv1.VMIFacilityDetail.prototype.clearContactEmail = function() {
  return this.setContactEmail(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.VMIFacilityDetail.prototype.hasContactEmail = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional string preferred_contact_method = 14;
 * @return {string}
 */
proto.vtv1.VMIFacilityDetail.prototype.getPreferredContactMethod = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.VMIFacilityDetail} returns this
 */
proto.vtv1.VMIFacilityDetail.prototype.setPreferredContactMethod = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * repeated VMIFacilityDock docks = 15;
 * @return {!Array<!proto.vtv1.VMIFacilityDock>}
 */
proto.vtv1.VMIFacilityDetail.prototype.getDocksList = function() {
  return /** @type{!Array<!proto.vtv1.VMIFacilityDock>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vtv1.VMIFacilityDock, 15));
};


/**
 * @param {!Array<!proto.vtv1.VMIFacilityDock>} value
 * @return {!proto.vtv1.VMIFacilityDetail} returns this
*/
proto.vtv1.VMIFacilityDetail.prototype.setDocksList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 15, value);
};


/**
 * @param {!proto.vtv1.VMIFacilityDock=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.VMIFacilityDock}
 */
proto.vtv1.VMIFacilityDetail.prototype.addDocks = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 15, opt_value, proto.vtv1.VMIFacilityDock, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.VMIFacilityDetail} returns this
 */
proto.vtv1.VMIFacilityDetail.prototype.clearDocksList = function() {
  return this.setDocksList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.VMIFacilityDock.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.VMIFacilityDock.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.VMIFacilityDock} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.VMIFacilityDock.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.VMIFacilityDock}
 */
proto.vtv1.VMIFacilityDock.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.VMIFacilityDock;
  return proto.vtv1.VMIFacilityDock.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.VMIFacilityDock} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.VMIFacilityDock}
 */
proto.vtv1.VMIFacilityDock.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.VMIFacilityDock.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.VMIFacilityDock.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.VMIFacilityDock} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.VMIFacilityDock.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.vtv1.VMIFacilityDock.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.VMIFacilityDock} returns this
 */
proto.vtv1.VMIFacilityDock.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.vtv1.VMIFacilityDock.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.VMIFacilityDock} returns this
 */
proto.vtv1.VMIFacilityDock.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.VMIFacilityListItem.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.VMIFacilityListItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.VMIFacilityListItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.VMIFacilityListItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    owner: (f = msg.getOwner()) && proto_vorto_types_types_pb.StringIdName.toObject(includeInstance, f),
    inventories: jspb.Message.getFieldWithDefault(msg, 4, 0),
    docks: jspb.Message.getFieldWithDefault(msg, 5, 0),
    orders: jspb.Message.getFieldWithDefault(msg, 6, 0),
    waitTimeMinutes: jspb.Message.getFieldWithDefault(msg, 7, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.VMIFacilityListItem}
 */
proto.vtv1.VMIFacilityListItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.VMIFacilityListItem;
  return proto.vtv1.VMIFacilityListItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.VMIFacilityListItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.VMIFacilityListItem}
 */
proto.vtv1.VMIFacilityListItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = new proto_vorto_types_types_pb.StringIdName;
      reader.readMessage(value,proto_vorto_types_types_pb.StringIdName.deserializeBinaryFromReader);
      msg.setOwner(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setInventories(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDocks(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setOrders(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setWaitTimeMinutes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.VMIFacilityListItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.VMIFacilityListItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.VMIFacilityListItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.VMIFacilityListItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getOwner();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto_vorto_types_types_pb.StringIdName.serializeBinaryToWriter
    );
  }
  f = message.getInventories();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getDocks();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getOrders();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getWaitTimeMinutes();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.vtv1.VMIFacilityListItem.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.VMIFacilityListItem} returns this
 */
proto.vtv1.VMIFacilityListItem.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.vtv1.VMIFacilityListItem.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.VMIFacilityListItem} returns this
 */
proto.vtv1.VMIFacilityListItem.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional StringIdName owner = 3;
 * @return {?proto.vtv1.StringIdName}
 */
proto.vtv1.VMIFacilityListItem.prototype.getOwner = function() {
  return /** @type{?proto.vtv1.StringIdName} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.StringIdName, 3));
};


/**
 * @param {?proto.vtv1.StringIdName|undefined} value
 * @return {!proto.vtv1.VMIFacilityListItem} returns this
*/
proto.vtv1.VMIFacilityListItem.prototype.setOwner = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.VMIFacilityListItem} returns this
 */
proto.vtv1.VMIFacilityListItem.prototype.clearOwner = function() {
  return this.setOwner(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.VMIFacilityListItem.prototype.hasOwner = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional int64 inventories = 4;
 * @return {number}
 */
proto.vtv1.VMIFacilityListItem.prototype.getInventories = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.VMIFacilityListItem} returns this
 */
proto.vtv1.VMIFacilityListItem.prototype.setInventories = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int64 docks = 5;
 * @return {number}
 */
proto.vtv1.VMIFacilityListItem.prototype.getDocks = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.VMIFacilityListItem} returns this
 */
proto.vtv1.VMIFacilityListItem.prototype.setDocks = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int64 orders = 6;
 * @return {number}
 */
proto.vtv1.VMIFacilityListItem.prototype.getOrders = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.VMIFacilityListItem} returns this
 */
proto.vtv1.VMIFacilityListItem.prototype.setOrders = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int64 wait_time_minutes = 7;
 * @return {number}
 */
proto.vtv1.VMIFacilityListItem.prototype.getWaitTimeMinutes = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.VMIFacilityListItem} returns this
 */
proto.vtv1.VMIFacilityListItem.prototype.setWaitTimeMinutes = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.VMIFacilityFile.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.VMIFacilityFile.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.VMIFacilityFile} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.VMIFacilityFile.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    url: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.VMIFacilityFile}
 */
proto.vtv1.VMIFacilityFile.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.VMIFacilityFile;
  return proto.vtv1.VMIFacilityFile.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.VMIFacilityFile} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.VMIFacilityFile}
 */
proto.vtv1.VMIFacilityFile.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.VMIFacilityFile.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.VMIFacilityFile.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.VMIFacilityFile} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.VMIFacilityFile.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getUrl();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.vtv1.VMIFacilityFile.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.VMIFacilityFile} returns this
 */
proto.vtv1.VMIFacilityFile.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.vtv1.VMIFacilityFile.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.VMIFacilityFile} returns this
 */
proto.vtv1.VMIFacilityFile.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string url = 3;
 * @return {string}
 */
proto.vtv1.VMIFacilityFile.prototype.getUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.VMIFacilityFile} returns this
 */
proto.vtv1.VMIFacilityFile.prototype.setUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * @enum {number}
 */
proto.vtv1.OrderErrorType = {
  ORDER_ERROR_NO_CARRIERS: 0,
  ORDER_ERROR_NO_SUPPLIERS: 1,
  ORDER_ERROR_SUPPLIER_NOT_RESPONDING: 2,
  ORDER_ERROR_CARRIER_NOT_RESPONDING: 3,
  ORDER_ERROR_ROUTE_GUIDE_EXHAUSTED: 4,
  ORDER_ERROR_SUPPLIER_ALL_SUPPLIERS_DECLINED: 5
};

/**
 * @enum {number}
 */
proto.vtv1.RestockStrategy = {
  OFF: 0,
  AUTO_ORDER: 1,
  AUTO_RESTOCK: 2,
  AUTO_OFFLOAD: 3
};

/**
 * @enum {number}
 */
proto.vtv1.RFQFrequencyType = {
  DAILY: 0,
  WEEKLY: 1,
  MONTHLY: 2,
  QUARTERLY: 3
};

/**
 * @enum {number}
 */
proto.vtv1.AutopilotFeedType = {
  AUTOPILOT_FEED_TYPE_UNKNOWN: 0,
  AUTOPILOT_FEED_TYPE_AUTO_ORDER: 1,
  AUTOPILOT_FEED_TYPE_AUTO_DISPATCH: 2,
  AUTOPILOT_FEED_TYPE_AUTO_DEMAND_SIGNAL: 3,
  AUTOPILOT_FEED_SUPPLIER_ACTION: 4,
  AUTOPILOT_FEED_PO_CONFIRMATION: 5
};

/**
 * @enum {number}
 */
proto.vtv1.InventorySharingPermission = {
  INVENTORY_SHARING_PERMISSION_NONE: 0,
  INVENTORY_SHARING_PERMISSION_VIEW: 1,
  INVENTORY_SHARING_PERMISSION_MANAGE: 2,
  INVENTORY_SHARING_PERMISSION_ADMIN: 3
};

goog.object.extend(exports, proto.vtv1);
