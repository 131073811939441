import { Pipe, PipeTransform } from '@angular/core';
import { ShipmentStatusMap } from '~proto/types/types_pb';

const prettyNameMap: Record<ShipmentStatusMap[keyof ShipmentStatusMap], string> = {
  0: 'Unknown',
  1: 'Pending',
  2: 'Assigned',
  3: 'Driver Accepted',
  4: 'Completed',
  5: 'Cancelled',
};

@Pipe({
  name: 'shipmentStatus',
})
export class ShipmentStatusPipe implements PipeTransform {
  public transform(status: ShipmentStatusMap[keyof ShipmentStatusMap]): string {
    return prettyNameMap[status];
  }
}
