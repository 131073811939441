import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { GrpcService } from 'src/app/singleton/services/grpc.service';
import { ClientMessageResponse, ClientMessageRequest } from '~proto/dialog/dialog_api_pb';
import { DialogService } from '~proto/dialog/dialog_api_pb_service';

export interface Message extends ClientMessageResponse.AsObject {
  sender: 'bot' | 'client';
}

@Injectable({
  providedIn: 'root',
})
export class ChatbotService {
  private onRight$$ = new BehaviorSubject<boolean>(true);
  private chat$$ = new BehaviorSubject<Message[]>([]);

  public get chat$(): Observable<Message[]> {
    return this.chat$$.asObservable();
  }

  public get onRight$(): Observable<boolean> {
    return this.onRight$$.asObservable();
  }

  constructor(private grpcService: GrpcService) {}

  public sendMessage(message: string) {
    const request = new ClientMessageRequest();
    this.chat$$.next([...this.chat$$.value, { localUrl: '', localUrlText: '', message, sender: 'client' }]);
    request.setMessage(message);
    return this.grpcService
      .invoke$(DialogService.clientMessage, request)
      .pipe(map((response: ClientMessageResponse) => response.toObject()))
      .subscribe((responseMessage) => {
        this.chat$$.next([...this.chat$$.value, { ...responseMessage, sender: 'bot' }]);
      });
  }

  public onRight(isOnRight: boolean) {
    this.onRight$$.next(isOnRight);
  }
}
