import { Component, OnInit, AfterViewInit } from '@angular/core';
import { AuthService } from '../../../singleton/services/auth.service';
import { InitializationService } from 'src/app/singleton/services/initialization.service';
import { MyUserInformationResponse } from '~proto/user/user_api_pb';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, pluck } from 'rxjs/operators';
import { ShipmentsService } from 'src/app/modules/edge/edge-shipments/shipments.service';
import { IntercomService } from 'src/app/intercom.service';
import { EmailTokenService } from 'src/app/singleton/services/email-token.service';

declare let Headway: any;

@Component({
  selector: 'vt-side-nav-menu',
  templateUrl: './side-nav.component.html',
  styles: [],
  // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SideNavComponent implements OnInit, AfterViewInit {
  public myUserInfo$: Observable<MyUserInformationResponse.AsObject>;
  private headwayConfig = {
    selector: '#releaseNotesBadge', // CSS selector where to inject the badge
    trigger: '#releaseNotes',
    account: '7vLj0y',
  };
  public hasMultipleAccounts$: Observable<boolean>;

  constructor(
    public initializationService: InitializationService,
    public intercomService: IntercomService,
    public authService: AuthService,
    private emailTokenService: EmailTokenService,
  ) {
    this.hasMultipleAccounts$ = this.initializationService.myAllowedAccounts$.pipe(
      map((allowedAccounts) => allowedAccounts?.length > 1),
    );
  }

  public ngOnInit(): void {
    this.myUserInfo$ = this.initializationService.myUserInfo$;
  } // end of onInit

  public async ngAfterViewInit() {
    await new Promise((resolve) => setTimeout(resolve, 500));
    if ((window as any).Headway) {
      Headway.init(this.headwayConfig);
    }
  }

  public logout() {
    this.authService.logout();
    this.emailTokenService.setEmailToken(null);
  }
} // end of sideNavComponent
