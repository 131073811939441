import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { NotificationGroup } from '~proto/notification/notification_pb';
import { Observable } from 'rxjs';
import { NotificationsService } from '../notifications.service';
import { UntypedFormGroup } from '@angular/forms';
import { MyUserInformationResponse } from '~proto/user/user_api_pb';
import { InitializationService } from 'src/app/singleton/services/initialization.service';
import { AuthService } from 'src/app/singleton/services/auth.service';
import { IntercomService } from 'src/app/intercom.service';
import { AccountSettingsService } from '../../singleton/service/account-settings.service';
import { AccountSettingsResponse } from '~proto/types/types_pb';

@Component({
  selector: 'vt-account-notifications-list',
  templateUrl: './account-settings.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccountNotificationsListComponent implements OnInit {
  public myUserInfo$: Observable<MyUserInformationResponse.AsObject>;
  public formGroup: UntypedFormGroup;
  public networkActive$: Observable<boolean>;
  public accountSettings$: Observable<AccountSettingsResponse.AsObject>;

  constructor(
    private notificationsService: NotificationsService,
    private initializationService: InitializationService,
    public intercomService: IntercomService,
    public accountSettingsService: AccountSettingsService,
  ) {}

  public ngOnInit(): void {
    this.networkActive$ = this.notificationsService.networkActive$;
    this.myUserInfo$ = this.initializationService.myUserInfo$;
    this.accountSettings$ = this.accountSettingsService.accountSettings$;
    // populate the settings
  }
} // end of NotificationsListComponent
