import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { NotificationGroup } from '~proto/notification/notification_pb';
import { combineLatest, Observable } from 'rxjs';
import { NotificationsService } from '../notifications.service';
import { UntypedFormGroup } from '@angular/forms';
import { FeatureFlagService } from '../../singleton/service/feature-flag.service';
import { map } from 'rxjs/operators';

@Component({
  selector: 'vt-notifications-list',
  templateUrl: './settings.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationsListComponent implements OnInit {
  public formGroup: UntypedFormGroup;
  public networkActive$: Observable<boolean>;
  public preferenceGroups$: Observable<NotificationGroup.AsObject[]>;
  public timingOptions: [{ name: 'Once Per Day'; value: 1440 }];

  constructor(private notificationsService: NotificationsService, private featureFlags: FeatureFlagService) {}

  public ngOnInit(): void {
    this.preferenceGroups$ = this.notificationsService.notifications$.pipe(
      map((value) => value.sort((a, b) => b.preferencesList.length - a.preferencesList.length)),
    );
    this.networkActive$ = this.notificationsService.networkActive$;
    // populate the settings
  }

  public async onHandleSettingsChange(id: string, isToggled: boolean) {
    if (isToggled) {
      const success = await this.notificationsService.unsubscribeNotificationPreference(id);
      return true;
    } else {
      const success = await this.notificationsService.resubscribeNotificationPreference(id);
      return true;
    }
  } // end of onHandleSettingsChange

  public async changeFrequency(preferenceId: string, newFrequency: number) {
    await this.notificationsService.updateNotificationFrequency(preferenceId, newFrequency);
  }
} // end of NotificationsListComponent
