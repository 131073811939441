<div class="mx-1 inline-flex flex-row items-center">
  <ng-container *ngFor="let number of numbers; let i = index; trackBy: numbers | trackByKey2: 'bgClass'">
    <span
      style="height: 20px;"
      [class.px-2]="numbers.length === 1"
      class="p-1 leading-none text-white text-xs inline-flex items-center justify-center"
      [class.rounded-l-full]="i === 0"
      [class.rounded-r-full]="i === numbers.length - 1"
      [ngClass]="number.bgClass || defaultBgClass"
    >
      {{ number.count | number }}
    </span>
  </ng-container>
</div>
