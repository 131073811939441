import { Injectable } from '@angular/core';
import { GrpcService } from '../services/grpc.service';
import { BehaviorSubject, Subject, Observable, lastValueFrom } from 'rxjs';
import { ToggleAutoDispatchRequest } from '~proto/edge/edge_api_pb';
import { shareReplay, throttleTime } from 'rxjs/operators';
import { AccountService } from '~proto/account/account_pb_service';
import { Empty, AccountSettingsResponse } from '~proto/types/types_pb';
import { EdgeService } from '~proto/edge/edge_api_pb_service';

@Injectable({
  providedIn: 'root',
})
export class AccountSettingsService {
  // PRODUCTION PROCESSES
  private accountSettings$$ = new BehaviorSubject<AccountSettingsResponse.AsObject>(null);
  private accountSettingsThrottle$$ = new Subject<void>();
  private accountSettingsSharedObservable$: Observable<
    AccountSettingsResponse.AsObject
  > = this.accountSettings$$.asObservable().pipe(shareReplay(1));
  public get accountSettings$(): Observable<AccountSettingsResponse.AsObject> {
    this.accountSettingsThrottle$$.next();
    return this.accountSettingsSharedObservable$;
  }

  constructor(private grpcService: GrpcService) {
    this.accountSettingsThrottle$$.pipe(throttleTime(1000)).subscribe(() => {
      this.loadAccountSettings();
    });
  }

  private async loadAccountSettings() {
    try {
      const accountSettings = await lastValueFrom(
        this.grpcService.invoke$(AccountService.GetAccountSettings, new Empty()),
      );
      this.accountSettings$$.next(accountSettings.toObject());
    } catch (error) {
      // any extra error handling here
    }
  }
}
