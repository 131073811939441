import { Pipe, PipeTransform } from '@angular/core';
import { NullableDouble, NullableInt64 } from '~proto/types/types_pb';

@Pipe({
  name: 'approxBigNumber',
})
export class ApproxBigNumberPipe implements PipeTransform {
  public transform(bigNumber: number | NullableInt64.AsObject | NullableDouble.AsObject): string {
    const value = getNumberOrNull(bigNumber);

    if (value === null) {
      return '—';
    }
    if (value < 1000) {
      return `${fixedNumber(value, 0, 0)}`;
    } else if (value < Math.pow(10, 6)) {
      return `${fixedNumber(value, 3, 0)}K`;
    } else if (value < Math.pow(10, 9)) {
      return `${fixedNumber(value, 6, 1)}MM`;
    } else if (value < Math.pow(10, 12)) {
      return `${fixedNumber(value, 9, 1)}B`;
    }
  }
}

// If this ends in .0 we are not going to show the decimal
function fixedNumber(num: number, power: number, decimals: number): string {
  const numberString = (num / Math.pow(10, power)).toFixed(decimals);
  return numberString.replace('.0', '');
}

function getNumberOrNull(bigNumber: number | NullableInt64.AsObject | NullableDouble.AsObject): number {
  if (bigNumber === null || bigNumber === undefined) {
    return null;
  }

  if (isNumber(bigNumber)) {
    return bigNumber;
  }

  if (!bigNumber.valid) {
    return null;
  }

  if (isNullableInt(bigNumber)) {
    return bigNumber.pb_int;
  }

  return bigNumber.pb_double;
}

function isNumber(n: number | NullableInt64.AsObject | NullableDouble.AsObject): n is number {
  if (n.constructor === Object) {
    return false;
  }
  return true;
}

function isNullableInt(n: NullableInt64.AsObject | NullableDouble.AsObject): n is NullableInt64.AsObject {
  return 'pb_int' in n;
}
