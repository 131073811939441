<div class="h-full flex relative">
  <button
    [type]="type"
    [class]="'text-sm rounded transition-colors duration-200 disabled:opacity-50 flex items-center ' + colorClasses"
    [disabled]="networkActive || disabled"
  >
    <ng-content></ng-content>
  </button>
  <div class="flex justify-center items-center absolute h-full w-full" *ngIf="networkActive">
    <mat-spinner diameter="30"> </mat-spinner>
  </div>
</div>
