import { Pipe, PipeTransform } from '@angular/core';
import { InvoiceStatusMap, LineItemType, LineItemTypeMap } from '~proto/billing/billing_pb';

const prettyNameMap: Record<LineItemTypeMap[keyof LineItemTypeMap], string> = {
  0: 'Custom',
  1: 'Dead Head',
  2: 'Fuel Surcharge',
  3: 'Line Haul',
  4: 'Loading Detention',
  5: 'Unloading Detention',
  6: 'Backhaul',
  7: 'TONU',
};

@Pipe({
  name: 'chargeType',
})
export class ChargeTypePipe implements PipeTransform {
  public transform(status: LineItemTypeMap[keyof LineItemTypeMap]): unknown {
    return prettyNameMap[status];
  }
}
