import { Injectable } from '@angular/core';
import { grpc } from '@improbable-eng/grpc-web';
import { ProtobufMessage } from '@improbable-eng/grpc-web/dist/typings/message';
import { MethodDefinition } from '@improbable-eng/grpc-web/dist/typings/service';
import { NGXLogger } from 'ngx-logger';
import { Observable, Subject, BehaviorSubject, of, lastValueFrom } from 'rxjs';
import { environment } from '~environments/environment';
import * as LogRocket from 'logrocket';
import { SnackbarService } from 'src/app/singleton/services/snackbar.service';
import { ExpectedErrorResponse, GRPCError, ErrorDisplay } from 'src/app/singleton/services/grpc.service';
import { throttleTime, map, take, pluck } from 'rxjs/operators';
import { RouterStateService } from 'src/app/singleton/services/router-state-service.service';
import {
  SpotMarketRequestDetailsForQuoterSummary,
  SitesToQuoteWithResponse,
  SiteToQuoteWith,
  CreateQuotePayload,
  GetLocationsByAddressSMRPayload,
  GetPriceToBeatResponse,
  GetPriceToBeatPayload,
} from '~proto/spot_market_response_from_email/spot_market_response_from_email_api_pb';
import { SpotMarketResponseFromEmailService } from '~proto/spot_market_response_from_email/spot_market_response_from_email_api_pb_service';
import { Empty } from '~proto/types/types_pb';
import { Location } from '~proto/sites/sites_pb';
import { GetDashboardAutopilotFeedResponse } from '~proto/node/node_api_pb';
import { Code } from '@improbable-eng/grpc-web/dist/typings/Code';
import { Metadata } from '@improbable-eng/grpc-web/dist/typings/metadata';
import { Router } from '@angular/router';

const defaultErrorMessage = 'Please contact Vorto Support';

@Injectable({
  providedIn: 'root',
})
export class ResponseFromEmailService {
  private currentRequest$$ = new BehaviorSubject<SpotMarketRequestDetailsForQuoterSummary.AsObject>(null);
  public get currentRequest$(): Observable<SpotMarketRequestDetailsForQuoterSummary.AsObject> {
    return this.currentRequest$$.asObservable();
  }

  private sitesToQuoteWith$$ = new BehaviorSubject<SiteToQuoteWith.AsObject[]>(null);
  public get sitesToQuoteWith$(): Observable<SiteToQuoteWith.AsObject[]> {
    return this.sitesToQuoteWith$$.asObservable();
  }

  private errorDisplay$$ = new Subject<ErrorDisplay>();
  constructor(
    private logger: NGXLogger,
    private router: Router,
    private snackBar: SnackbarService,
    private routerStateService: RouterStateService,
  ) {
    this.errorDisplay$$.pipe(throttleTime(3000)).subscribe((error) => {
      this.snackBar.showError(error.message, error.message === defaultErrorMessage || error.code === 13);
    });
  }

  public async createQuote(req: CreateQuotePayload): Promise<boolean> {
    const code = await this.getCode();
    try {
      const response = await lastValueFrom(this.invoke$(SpotMarketResponseFromEmailService.CreateQuote, req, code));
      this.currentRequest$$.next(response.toObject().summary);
      return true;
    } catch (error) {
      return false;
    }
  }

  public async loadSpotMarketRequestDetails() {
    this.loadSitesToQuoteWith();
    const code = await this.getCode();
    const response = await lastValueFrom(
      this.invoke$(SpotMarketResponseFromEmailService.GetRequestDetails, new Empty(), code),
    );

    this.currentRequest$$.next(response.toObject().summary);
    this.stream(SpotMarketResponseFromEmailService.GetRequestDetailsStream, new Empty(), code, (data) =>
      this.currentRequest$$.next(data.toObject().summary),
    );
  }

  private async loadSitesToQuoteWith() {
    const code = await this.getCode();
    const response = await lastValueFrom(
      this.invoke$(SpotMarketResponseFromEmailService.GetSitesToQuoteWith, new Empty(), code),
    );
    this.sitesToQuoteWith$$.next(response.toObject().sitesList);
  }

  public async getCode(): Promise<string> {
    return lastValueFrom(
      this.routerStateService.routerState$.pipe(
        map((state) => state.queryParams),
        take(1),
        pluck('spotMarketQuoteCode'),
      ),
    );
  }

  public async getPriceToBeat(longitude: number, latitude: number): Promise<GetPriceToBeatResponse.AsObject> {
    const code = await this.getCode();
    const req = new GetPriceToBeatPayload();
    req.setLatitude(latitude);
    req.setLongitude(longitude);

    try {
      const response = await lastValueFrom(this.invoke$(SpotMarketResponseFromEmailService.GetPriceToBeat, req, code));
      return response.toObject();
    } catch (error) {
      return {
        isValid: false,
        supplierPaysShippingUnitPrice: 0,
        consumerPaysShippingUnitPrice: 0,
      };
    }
  }

  public async getLocationFromAddress(address: string): Promise<Location.AsObject[]> {
    if (address.length < 4) {
      return [];
    }
    const code = await this.getCode();
    const req = new GetLocationsByAddressSMRPayload();
    req.setAddress(address);

    try {
      const results = await lastValueFrom(
        this.invoke$(SpotMarketResponseFromEmailService.GetLocationsByAddressSMR, req, code),
      );
      return results.toObject().locationsList;
    } catch (_) {
      // For safety
    }
  }

  private invoke$<T extends ProtobufMessage, U extends ProtobufMessage, M extends MethodDefinition<T, U>>(
    method: M,
    request: T,
    spotMarketQuoteCode: string,
  ): Observable<InstanceType<M['responseType']>> {
    this.logger.debug(`ResponseFromEmailService ⬆ Request: ${method.methodName}`, request.toObject());
    const metadata: Record<string, string> = {};
    metadata.Authorization = `spotMarketQuoteCode ${spotMarketQuoteCode}`;
    return new Observable<InstanceType<M['responseType']>>((observer) => {
      grpc.invoke(method, {
        host: environment.api,
        metadata,
        onEnd: (code: grpc.Code, msg: string, requestMetadata: grpc.Metadata) => {
          if (code !== grpc.Code.OK) {
            let messageForUser = defaultErrorMessage;
            try {
              const err: ExpectedErrorResponse = JSON.parse(msg);
              if (err.info) {
                messageForUser = err.info;
              }
              const errorInfo = {
                // clientVersion: this.versionService.currentVersion,
                errorId: err.id,
                errorCode: code,
                errorMessage: err.info,
                methodName: method.methodName,
                requestPayload: JSON.stringify(request.toObject()),
                error: err.error,
              };
              this.logger.error(errorInfo);
              this.logger.error('Developer Info (if any):', err.developerInfo);
              if (LogRocket) {
                LogRocket.captureException(new Error(err.info), {
                  extra: {
                    ...errorInfo,
                    developerInfo: err.developerInfo,
                  },
                });
              }
            } catch (_) {
              this.logger.error({
                // clientVersion: this.versionService.currentVersion,
                errorCode: code,
                errorMessage: msg,
                methodName: method.methodName,
                requestPayload: JSON.stringify(request.toObject()),
              });
              if (LogRocket) {
                LogRocket.captureException(new Error('GRPC Catch Error'), {
                  extra: {
                    errorCode: code,
                    errorMessage: msg,
                    methodName: method.methodName,
                    requestPayload: JSON.stringify(request.toObject()),
                  },
                });
              }
            }
            this.errorDisplay$$.next({ message: messageForUser, code });
            observer.error(new GRPCError(messageForUser, code, requestMetadata));
          } else {
            observer.complete();
          }
        },
        onMessage: (message: any) => {
          this.logger.debug(`ResponseFromEmailService Response ⬇: ${method.methodName}`, message.toObject());
          observer.next(message as InstanceType<M['responseType']>);
        },
        request,
      });
    });
  }

  public async stream<T extends ProtobufMessage, U extends ProtobufMessage, M extends MethodDefinition<T, U>>(
    method: M,
    request: T,
    spotMarketQuoteCode: string,
    callback: (data: InstanceType<M['responseType']>) => any,
  ) {
    const client = grpc.client(method, {
      host: environment.api,
      debug: !environment.production,
      transport: grpc.WebsocketTransport(),
    });
    client.onMessage((message: InstanceType<M['responseType']>) => {
      this.logger.debug(`Message: ${method.methodName}`, message.toObject());
      callback(message as InstanceType<M['responseType']>);
    });
    client.onEnd((p1: Code, p2: string, p3: Metadata) => {
      this.logger.debug(`End: ${method.methodName}`, p1, p2, p3);
    });
    client.onHeaders((data) => {
      this.logger.debug(`Headers: ${method.methodName}`, data);
    });
    const metadata: Record<string, string> = {};
    metadata.Authorization = `spotMarketQuoteCode ${spotMarketQuoteCode}`;
    client.start(metadata);
    client.send(request);
  }
}
