// package: vtv1
// file: proto/vorto/user/user_api.proto

var proto_vorto_user_user_api_pb = require("../../../proto/vorto/user/user_api_pb");
var proto_vorto_types_types_pb = require("../../../proto/vorto/types/types_pb");
var grpc = require("@improbable-eng/grpc-web").grpc;

var UserService = (function () {
  function UserService() {}
  UserService.serviceName = "vtv1.UserService";
  return UserService;
}());

UserService.ListWebUsers = {
  methodName: "ListWebUsers",
  service: UserService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_types_types_pb.Empty,
  responseType: proto_vorto_user_user_api_pb.ListUsersResponse
};

UserService.ListDrivers = {
  methodName: "ListDrivers",
  service: UserService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_types_types_pb.Empty,
  responseType: proto_vorto_user_user_api_pb.ListUsersResponse
};

UserService.ListPermissions = {
  methodName: "ListPermissions",
  service: UserService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_types_types_pb.Empty,
  responseType: proto_vorto_user_user_api_pb.ListPermissionsResponse
};

UserService.CreateWebUser = {
  methodName: "CreateWebUser",
  service: UserService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_user_user_api_pb.CreateWebUserRequest,
  responseType: proto_vorto_user_user_api_pb.SingleUserResponse
};

UserService.GetWebUser = {
  methodName: "GetWebUser",
  service: UserService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_types_types_pb.StringId,
  responseType: proto_vorto_user_user_api_pb.SingleUserResponse
};

UserService.EditWebUser = {
  methodName: "EditWebUser",
  service: UserService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_user_user_api_pb.EditWebUserRequest,
  responseType: proto_vorto_user_user_api_pb.SingleUserResponse
};

UserService.DeleteWebUser = {
  methodName: "DeleteWebUser",
  service: UserService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_types_types_pb.StringId,
  responseType: proto_vorto_types_types_pb.Empty
};

UserService.CreateDriver = {
  methodName: "CreateDriver",
  service: UserService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_user_user_api_pb.CreateDriverRequest,
  responseType: proto_vorto_user_user_api_pb.SingleUserResponse
};

UserService.GetDriver = {
  methodName: "GetDriver",
  service: UserService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_types_types_pb.StringId,
  responseType: proto_vorto_user_user_api_pb.SingleUserResponse
};

UserService.EditDriver = {
  methodName: "EditDriver",
  service: UserService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_user_user_api_pb.EditDriverRequest,
  responseType: proto_vorto_user_user_api_pb.SingleUserResponse
};

UserService.GetMyUserInfo = {
  methodName: "GetMyUserInfo",
  service: UserService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_types_types_pb.Empty,
  responseType: proto_vorto_user_user_api_pb.MyUserInformationResponse
};

UserService.FindDriverByPhoneNumber = {
  methodName: "FindDriverByPhoneNumber",
  service: UserService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_user_user_api_pb.FindDriverByPhoneNumberRequest,
  responseType: proto_vorto_user_user_api_pb.ListUsersResponse
};

UserService.GetMyTokenUserInfo = {
  methodName: "GetMyTokenUserInfo",
  service: UserService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_types_types_pb.Empty,
  responseType: proto_vorto_user_user_api_pb.MyTokenUserInformationResponse
};

UserService.GetMyAllowedAccounts = {
  methodName: "GetMyAllowedAccounts",
  service: UserService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_types_types_pb.Empty,
  responseType: proto_vorto_user_user_api_pb.GetMyAllowedAccountsResponse
};

UserService.SetMyAccount = {
  methodName: "SetMyAccount",
  service: UserService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_types_types_pb.StringId,
  responseType: proto_vorto_user_user_api_pb.SetMyAccountResponse
};

exports.UserService = UserService;

function UserServiceClient(serviceHost, options) {
  this.serviceHost = serviceHost;
  this.options = options || {};
}

UserServiceClient.prototype.listWebUsers = function listWebUsers(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(UserService.ListWebUsers, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

UserServiceClient.prototype.listDrivers = function listDrivers(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(UserService.ListDrivers, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

UserServiceClient.prototype.listPermissions = function listPermissions(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(UserService.ListPermissions, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

UserServiceClient.prototype.createWebUser = function createWebUser(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(UserService.CreateWebUser, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

UserServiceClient.prototype.getWebUser = function getWebUser(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(UserService.GetWebUser, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

UserServiceClient.prototype.editWebUser = function editWebUser(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(UserService.EditWebUser, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

UserServiceClient.prototype.deleteWebUser = function deleteWebUser(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(UserService.DeleteWebUser, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

UserServiceClient.prototype.createDriver = function createDriver(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(UserService.CreateDriver, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

UserServiceClient.prototype.getDriver = function getDriver(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(UserService.GetDriver, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

UserServiceClient.prototype.editDriver = function editDriver(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(UserService.EditDriver, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

UserServiceClient.prototype.getMyUserInfo = function getMyUserInfo(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(UserService.GetMyUserInfo, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

UserServiceClient.prototype.findDriverByPhoneNumber = function findDriverByPhoneNumber(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(UserService.FindDriverByPhoneNumber, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

UserServiceClient.prototype.getMyTokenUserInfo = function getMyTokenUserInfo(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(UserService.GetMyTokenUserInfo, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

UserServiceClient.prototype.getMyAllowedAccounts = function getMyAllowedAccounts(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(UserService.GetMyAllowedAccounts, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

UserServiceClient.prototype.setMyAccount = function setMyAccount(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(UserService.SetMyAccount, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

exports.UserServiceClient = UserServiceClient;

