import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sum',
})
export class SumPipe implements PipeTransform {
  public transform(value: number[], ...args: string[]): number {
    return value.reduce((previousValue, currentValue) => previousValue + currentValue, 0);
  }
}
