import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { getTracker } from './track-by.pipe';

@Pipe({
  name: 'trackByKey2',
})
export class TrackByKey2Pipe implements PipeTransform {
  // eslint-disable-next-line
  public transform<T>(value: T[] | Observable<T[]>, key: keyof T): (index: number, item: T) => T {
    return getTracker<T>(key);
  }
}
