import { combineLatest, Observable } from 'rxjs';
import { UntypedFormGroup } from '@angular/forms';
import { startWith } from 'rxjs/operators';

export const combineLatestStartWithNull = (fields: string[], form: UntypedFormGroup): Observable<any> => {
  const observables = [];
  for (const field of fields) {
    observables.push(form.get(field).valueChanges.pipe(startWith(null as any)));
  }
  return combineLatest(observables);
};
