import { Pipe, PipeTransform } from '@angular/core';
import { InvoiceStatusMap } from '~proto/billing/billing_pb';

const prettyNameMap: Record<InvoiceStatusMap[keyof InvoiceStatusMap], string> = {
  0: 'Draft Ticket',
  1: 'Ticket Submitted',
  2: 'Invoice Submitted',
  3: 'Invoice Approved',
};

@Pipe({
  name: 'invoiceStatus',
})
export class InvoiceStatusPipe implements PipeTransform {
  public transform(status: InvoiceStatusMap[keyof InvoiceStatusMap]): string {
    return prettyNameMap[status];
  }
}
