import { Injectable } from '@angular/core';
import {
  CanLoad,
  Route,
  UrlSegment,
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable, race } from 'rxjs';
import { map, tap, filter, take } from 'rxjs/operators';
import { InitializationService } from './singleton/services/initialization.service';

const accountFeatureNameMap = {
  node: 1,
  edge: 2,
};

@Injectable({
  providedIn: 'root',
})
export class HasAccountTypeGuard implements CanLoad, CanActivate {
  constructor(private initializationService: InitializationService, private router: Router) {}

  public canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
    if (!route.data || !route.data.accountFeatures || !Array.isArray(route.data.accountFeatures)) {
      console.error('Route data did not include accountFeatures or was not array');
      return false;
    }
    const featureIds = route.data.accountFeatures
      .map((feature) => accountFeatureNameMap[feature])
      .filter((featureId) => !!featureId);
    return this.initializationService.myUserInfo$.pipe(
      filter((user) => !!user),
      map((user) => user.accountFeaturesList),
      map((features) => features.map((feature) => accountFeatureNameMap[feature])),
      take(1),
      map((features) => {
        return {
          hasFeature: features.some((feature) => featureIds.includes(feature)),
          features,
        };
      }),
      tap(({ hasFeature, features }) => {
        if (!hasFeature) {
          if (route.data.redirectTo) {
            this.router.navigate(route.data.redirectTo);
          } else {
            console.error(route.path, 'does not have features:', route.data.accountFeatures, 'does have', features);
            setTimeout(() => this.router.navigate(['/', 'not_found']), 100);
          }
        }
      }),
      map(({ hasFeature }) => hasFeature),
    );
  }

  public canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    if (!route.data || !route.data.accountFeatures || !Array.isArray(route.data.accountFeatures)) {
      console.error('Route data did not include accountFeatures or was not array');
      return false;
    }
    const featureIds = route.data.accountFeatures
      .map((feature) => accountFeatureNameMap[feature])
      .filter((featureId) => !!featureId);
    return this.initializationService.myUserInfo$.pipe(
      filter((user) => !!user),
      map((user) => user.accountFeaturesList),
      map((features) => features.map((feature) => accountFeatureNameMap[feature])),
      take(1),
      map((features) => {
        return {
          hasFeature: features.some((feature) => featureIds.includes(feature)),
          features,
        };
      }),
      tap(({ hasFeature, features }) => {
        if (!hasFeature) {
          if (route.data.redirectTo) {
            this.router.navigate(route.data.redirectTo);
          } else {
            console.error(route.url, 'does not have features:', route.data.accountFeatures, 'does have', features);
            setTimeout(() => this.router.navigate(['/', 'not_found']), 100);
          }
        }
      }),
      map(({ hasFeature }) => hasFeature),
    );
  }
}
