import { Injectable } from '@angular/core';
import { User } from '~proto/user/user_pb';
import { BehaviorSubject, Observable, Subject, of, lastValueFrom } from 'rxjs';
import { map, shareReplay, switchMap, tap, distinctUntilChanged, throttleTime } from 'rxjs/operators';
import { sort, omit } from 'remeda';
import { sortByName } from '../../shared/utilities/commonSorters';
import { GrpcService } from '../../singleton/services/grpc.service';
import { UserService } from '~proto/user/user_api_pb_service';
import { nullableStringToNullOrString } from '../../shared/utilities/protoConverters';
import { idArrayToRecord } from '../../shared/utilities/idArrayToRecord';
import { Empty, StringId } from '~proto/types/types_pb';
import { RouterStateService } from '../../singleton/services/router-state-service.service';
import * as RouterConstants from './user-management-routing.constants';
import { NGXLogger } from 'ngx-logger';
import {
  CreateWebUserRequest,
  EditWebUserRequest,
  CreateDriverRequest,
  EditDriverRequest,
  FindDriverByPhoneNumberRequest,
} from '~proto/user/user_api_pb';
import { EdgeService } from '~proto/edge/edge_api_pb_service';

export type UserWithNulls = Omit<User.AsObject, 'email' | 'phone' | 'pin'> & {
  email: string | null;
  phone: string | null;
  pin: string | null;
};

export interface UserWithNullsWithPermissions extends UserWithNulls {
  permissionIds: number[];
}

@Injectable({
  providedIn: 'root',
})
export class UsersService {
  private webUsers$$ = new BehaviorSubject<Record<string, UserWithNulls>>({});
  private webUsersThrottle$$ = new Subject<void>();
  private webUsersSharedObservable$: Observable<UserWithNulls[]> = this.webUsers$$.asObservable().pipe(
    map((users) => Object.values(users)),
    map(sort(sortByName)),
    shareReplay(1),
  );

  private drivers$$ = new BehaviorSubject<Record<string, UserWithNulls>>({});
  private driversThrottle$$ = new Subject<void>();
  private driversSharedObservable$: Observable<UserWithNulls[]> = this.drivers$$.asObservable().pipe(
    map((users) => Object.values(users)),
    map(sort(sortByName)),
    shareReplay(1),
  );

  private currentWebUser$$ = new BehaviorSubject<UserWithNullsWithPermissions>(null);
  public currentWebUser$: Observable<UserWithNullsWithPermissions> = this.currentWebUser$$.pipe(
    distinctUntilChanged(),
    shareReplay(1),
  );

  private currentDriver$$ = new BehaviorSubject<UserWithNulls>(null);
  public currentDriver$: Observable<UserWithNulls> = this.currentDriver$$.pipe(distinctUntilChanged(), shareReplay(1));

  public get webUsers$(): Observable<UserWithNulls[]> {
    this.webUsersThrottle$$.next();
    return this.webUsersSharedObservable$;
  }

  public get drivers$(): Observable<UserWithNulls[]> {
    this.driversThrottle$$.next();
    return this.driversSharedObservable$;
  }

  constructor(private grpc: GrpcService, private routerState: RouterStateService, private logger: NGXLogger) {
    this.webUsersThrottle$$.pipe(throttleTime(200)).subscribe(() => this.loadWebUsers());
    this.driversThrottle$$.pipe(throttleTime(200)).subscribe(() => this.loadDrivers());
    this.listenForWebUser();
    this.listenForDriver();
  }

  public async createUser(createUserRequest: CreateWebUserRequest): Promise<boolean> {
    try {
      const response = await lastValueFrom(this.grpc.invoke$(UserService.CreateWebUser, createUserRequest));
      const asObject = response.toObject();
      this.webUsers$$.next({
        ...this.webUsers$$.value,
        [asObject.user.id]: toUserWithNulls(asObject.user),
      });
      return true;
    } catch (error) {
      return false;
    }
  }

  public async updateUser(editUserRequest: EditWebUserRequest): Promise<boolean> {
    try {
      const response = await lastValueFrom(this.grpc.invoke$(UserService.EditWebUser, editUserRequest));
      const asObject = response.toObject();
      this.webUsers$$.next({
        ...this.webUsers$$.value,
        [asObject.user.id]: toUserWithNulls(asObject.user),
      });
      return true;
    } catch (error) {
      return false;
    }
  }

  public async removeWebUser(req: StringId): Promise<boolean> {
    try {
      await lastValueFrom(this.grpc.invoke$(UserService.DeleteWebUser, req));
      this.webUsers$$.next(omit(this.webUsers$$.value, [req.getId()]));
      return true;
    } catch (error) {
      return false;
    }
  }

  public async createDriver(createDriverRequest: CreateDriverRequest): Promise<UserWithNulls | false> {
    try {
      const response = await lastValueFrom(this.grpc.invoke$(UserService.CreateDriver, createDriverRequest));
      const asObject = response.toObject();
      const asUserWithNulls = toUserWithNulls(asObject.user);
      this.drivers$$.next({
        ...this.drivers$$.value,
        [asObject.user.id]: asUserWithNulls,
      });
      return asUserWithNulls;
    } catch (error) {
      return false;
    }
  }

  public async updateDriver(editDriverRequest: EditDriverRequest): Promise<boolean> {
    try {
      const response = await lastValueFrom(this.grpc.invoke$(UserService.EditDriver, editDriverRequest));
      const asObject = response.toObject();
      this.drivers$$.next({
        ...this.drivers$$.value,
        [asObject.user.id]: toUserWithNulls(asObject.user),
      });
      return true;
    } catch (error) {
      return false;
    }
  }

  public async removeDriver(req: StringId): Promise<boolean> {
    try {
      await lastValueFrom(this.grpc.invoke$(EdgeService.RemoveDriver, req));
      this.drivers$$.next(omit(this.drivers$$.value, [req.getId()]));
      return true;
    } catch (error) {
      return false;
    }
  }

  public async searchDriversByPhoneNumber(phoneNumber: string): Promise<UserWithNulls[]> {
    const request = new FindDriverByPhoneNumberRequest();
    request.setPhoneNumber(phoneNumber);
    const results = await lastValueFrom(this.grpc.invoke$(UserService.FindDriverByPhoneNumber, request));
    return results.toObject().usersList.map(toUserWithNulls);
  }

  private async loadWebUsers() {
    const response = await lastValueFrom(this.grpc.invoke$(UserService.ListWebUsers, new Empty()));
    const asUserWithNulls = response.toObject().usersList.map(toUserWithNulls);
    this.webUsers$$.next(idArrayToRecord(asUserWithNulls));
  }

  private async loadDrivers() {
    const response = await lastValueFrom(this.grpc.invoke$(UserService.ListDrivers, new Empty()));
    const asUserWithNulls = response.toObject().usersList.map(toUserWithNulls);
    this.drivers$$.next(idArrayToRecord(asUserWithNulls));
  }

  private loadWebUser(webUserId: string): Observable<UserWithNullsWithPermissions> {
    const userRequest = new StringId();
    userRequest.setId(webUserId);

    try {
      return this.grpc.invoke$(UserService.GetWebUser, userRequest).pipe(
        map((response) => {
          const asObject = response.toObject();
          const asUserWithNulls = toUserWithNulls(asObject.user);
          return {
            ...asUserWithNulls,
            permissionIds: asObject.permissionIdsList,
          };
        }),
      );
    } catch (error) {
      this.logger.error(error);
    }
  }

  private listenForWebUser() {
    this.routerState
      .listenForParamChange$(RouterConstants.WEB_USER_ID)
      .pipe(
        tap((webUserId) => {
          // Clear the current user if needed
          if (!webUserId) {
            this.currentWebUser$$.next(null);
          } else if (this.currentWebUser$$.value && this.currentWebUser$$.value.id !== webUserId) {
            this.currentWebUser$$.next(null);
          }
        }),
        switchMap((webUserId) => {
          if (!webUserId) {
            return of(null);
          }
          return this.loadWebUser(webUserId);
        }),
      )
      .subscribe((currentUser) => {
        this.currentWebUser$$.next(currentUser);
      });
  }

  private loadDriver(driverId: string): Observable<UserWithNulls> {
    const userRequest = new StringId();
    userRequest.setId(driverId);

    try {
      return this.grpc.invoke$(UserService.GetDriver, userRequest).pipe(
        map((response) => {
          const asObject = response.toObject();
          return toUserWithNulls(asObject.user);
        }),
      );
    } catch (error) {
      this.logger.error(error);
    }
  }

  private listenForDriver() {
    this.routerState
      .listenForParamChange$(RouterConstants.DRIVER_USER_ID)
      .pipe(
        tap((driverId) => {
          // Clear the current user if needed
          if (!driverId) {
            this.currentDriver$$.next(null);
          } else if (this.currentDriver$$.value && this.currentDriver$$.value.id !== driverId) {
            this.currentDriver$$.next(null);
          }
        }),
        switchMap((driverId) => {
          if (!driverId) {
            return of(null);
          }
          return this.loadDriver(driverId);
        }),
      )
      .subscribe((currentDriver) => {
        this.currentDriver$$.next(currentDriver);
      });
  }
}

export function toUserWithNulls(user: User.AsObject): UserWithNulls {
  return {
    ...user,
    email: nullableStringToNullOrString(user.email),
    phone: nullableStringToNullOrString(user.phone),
    pin: nullableStringToNullOrString(user.pin),
  };
}
