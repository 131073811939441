import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'joinWithComma',
})
export class JoinWithCommaPipe implements PipeTransform {
  public transform(value: any[]): string {
    if (!Array.isArray(value)) {
      return value;
    }

    if (value.length === 0) {
      return '';
    }

    return value?.join(', ');
  }
}
