import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatExpansionModule } from '@angular/material/expansion';
import {
  NgxMatDatetimePickerModule,
  NgxMatNativeDateModule,
  NgxMatTimepickerModule,
  NgxMatDateAdapter,
  NgxMatDatetimePicker,
} from '@angular-material-components/datetime-picker';
import { MatStepperModule } from '@angular/material/stepper';
import { MatSidenavModule } from '@angular/material/sidenav';
import { CustomDateAdapter } from './CustomDate';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { Platform } from '@angular/cdk/platform';
import { MatMenu, MatMenuModule } from '@angular/material/menu';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatChipsModule } from '@angular/material/chips';
import { MatSliderModule } from '@angular/material/slider';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatListModule } from '@angular/material/list';
import { MatButtonModule } from '@angular/material/button';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSelectModule } from '@angular/material/select';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTabsModule } from '@angular/material/tabs';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatCardModule } from '@angular/material/card';
import { MatPaginatorModule } from '@angular/material/paginator';

/**
 * Firefox struggles with this open function and appears to need a bit of a delay.
 * Monkey patching the open function to open after a 100ms delay.
 */
const oldOpen = NgxMatDatetimePicker.prototype.open;

NgxMatDatetimePicker.prototype.open = function open() {
  new Promise((resolve) => {
    setTimeout(resolve, 100);
  }).then(() => oldOpen.apply(this, arguments));
};

@NgModule({
  declarations: [],
  exports: [
    MatMenuModule,
    MatInputModule,
    MatFormFieldModule,
    MatChipsModule,
    MatSliderModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatListModule,
    MatButtonModule,
    MatSnackBarModule,
    MatSelectModule,
    MatDialogModule,
    MatTooltipModule,
    MatDatepickerModule,
    MatTabsModule,
    MatExpansionModule,
    NgxMatDatetimePickerModule,
    NgxMatNativeDateModule,
    NgxMatTimepickerModule,
    MatStepperModule,
    MatSlideToggleModule,
    MatCheckboxModule,
    MatProgressBarModule,
    MatSidenavModule,
    MatAutocompleteModule,
    MatCardModule,
    MatPaginatorModule,
  ],
  imports: [
    CommonModule,
    MatMenuModule,
    MatInputModule,
    MatFormFieldModule,
    MatChipsModule,
    MatSliderModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatListModule,
    MatButtonModule,
    MatSnackBarModule,
    MatSelectModule,
    MatDialogModule,
    MatTooltipModule,
    MatDatepickerModule,
    MatTabsModule,
    MatExpansionModule,
    NgxMatDatetimePickerModule,
    NgxMatNativeDateModule,
    NgxMatTimepickerModule,
    MatStepperModule,
    MatSlideToggleModule,
    MatCheckboxModule,
    MatProgressBarModule,
    MatSidenavModule,
    MatCardModule,
    MatPaginatorModule,
  ],
  providers: [
    {
      provide: NgxMatDateAdapter,
      useClass: CustomDateAdapter,
      deps: [MAT_DATE_LOCALE, Platform],
    },
  ],
})
export class MaterialModule {}
