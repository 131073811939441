import { isDate, isValid } from 'date-fns';

export const convertToDate = (value: any): Date => {
  if (!value) {
    return null;
  }

  if (isDate(value) && isValid(value)) {
    return value;
  }

  const asDate = new Date(value);
  if (isDate(asDate) && isValid(asDate)) {
    return asDate;
  }

  // This is a nullable time from the server
  if (value.constructor === Object) {
    if (!value.valid || !value.time) {
      return null;
    }
    const asDateFromGrpc = new Date(value.time);
    if (isDate(asDateFromGrpc) && isValid(asDateFromGrpc)) {
      return asDateFromGrpc;
    }
  }

  return null;
};
