import { ErrorHandler } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import * as LogRocket from 'logrocket';

export class LogRocketErrorHandler implements ErrorHandler {
  constructor(private ngxLogger: NGXLogger) {}
  public handleError(error) {
    this.ngxLogger.error(error);
    if (error instanceof Error) {
      if (LogRocket) {
        LogRocket.captureException(error);
      }
    }
  }
}
