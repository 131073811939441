import { Pipe, PipeTransform } from '@angular/core';
import { TaskActionMap, TaskAction } from '~proto/types/types_pb';

@Pipe({
  name: 'taskAction',
})
export class TaskActionPipe implements PipeTransform {
  public transform(value: TaskActionMap[keyof TaskActionMap]): string {
    switch (value) {
      case TaskAction.TASK_ACTION_DROPOFF:
        return 'Drop Off';
      case TaskAction.TASK_ACTION_PICKUP:
        return 'Pick Up';
      case TaskAction.TASK_ACTION_RETURN_DROPOFF:
        return 'Backhaul Drop Off';
      case TaskAction.TASK_ACTION_RETURN_PICKUP:
        return 'Backhaul Pick Up';
    }
  }
}
