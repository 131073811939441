// package: vtv1
// file: proto/vorto/edge/edge_api.proto

var proto_vorto_edge_edge_api_pb = require("../../../proto/vorto/edge/edge_api_pb");
var proto_vorto_edge_edge_pb = require("../../../proto/vorto/edge/edge_pb");
var proto_vorto_types_types_pb = require("../../../proto/vorto/types/types_pb");
var grpc = require("@improbable-eng/grpc-web").grpc;

var EdgeService = (function () {
  function EdgeService() {}
  EdgeService.serviceName = "vtv1.EdgeService";
  return EdgeService;
}());

EdgeService.AddDriver = {
  methodName: "AddDriver",
  service: EdgeService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_edge_edge_api_pb.AddDriverRequest,
  responseType: proto_vorto_edge_edge_api_pb.AddDriverResponse
};

EdgeService.RemoveDriver = {
  methodName: "RemoveDriver",
  service: EdgeService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_types_types_pb.StringId,
  responseType: proto_vorto_types_types_pb.Empty
};

EdgeService.CreateEdgeContract = {
  methodName: "CreateEdgeContract",
  service: EdgeService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_edge_edge_api_pb.CreateEdgeContractRequestAsEdge,
  responseType: proto_vorto_edge_edge_api_pb.SingleEdgeContractAsEdge
};

EdgeService.EditEdgeContract = {
  methodName: "EditEdgeContract",
  service: EdgeService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_edge_edge_api_pb.EditEdgeContractRequestAsEdge,
  responseType: proto_vorto_edge_edge_api_pb.SingleEdgeContractAsEdge
};

EdgeService.CloseEdgeContract = {
  methodName: "CloseEdgeContract",
  service: EdgeService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_types_types_pb.StringId,
  responseType: proto_vorto_types_types_pb.Empty
};

EdgeService.ReopenClosedEdgeContract = {
  methodName: "ReopenClosedEdgeContract",
  service: EdgeService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_types_types_pb.StringId,
  responseType: proto_vorto_edge_edge_api_pb.SingleEdgeContractAsEdge
};

EdgeService.GetEdgeContract = {
  methodName: "GetEdgeContract",
  service: EdgeService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_types_types_pb.StringId,
  responseType: proto_vorto_edge_edge_api_pb.SingleEdgeContractAsEdge
};

EdgeService.ListEdgeContracts = {
  methodName: "ListEdgeContracts",
  service: EdgeService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_edge_edge_api_pb.ListEdgeContractsRequest,
  responseType: proto_vorto_edge_edge_api_pb.ListEdgeContractsResponseAsEdge
};

EdgeService.GetEdgeContractNodes = {
  methodName: "GetEdgeContractNodes",
  service: EdgeService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_edge_edge_api_pb.GetEdgeContractNodesRequest,
  responseType: proto_vorto_edge_edge_api_pb.GetEdgeContractNodesResponse
};

EdgeService.CreateShipment = {
  methodName: "CreateShipment",
  service: EdgeService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_edge_edge_api_pb.CreateShipmentRequest,
  responseType: proto_vorto_edge_edge_api_pb.ShipmentDetailsResponse
};

EdgeService.DeclineShipment = {
  methodName: "DeclineShipment",
  service: EdgeService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_types_types_pb.StringId,
  responseType: proto_vorto_edge_edge_api_pb.ShipmentDetailsResponse
};

EdgeService.CancelShipment = {
  methodName: "CancelShipment",
  service: EdgeService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_types_types_pb.StringId,
  responseType: proto_vorto_edge_edge_api_pb.ShipmentDetailsResponse
};

EdgeService.AssignShipment = {
  methodName: "AssignShipment",
  service: EdgeService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_edge_edge_api_pb.AssignShipmentRequest,
  responseType: proto_vorto_edge_edge_api_pb.ShipmentDetailsResponse
};

EdgeService.UnassignShipment = {
  methodName: "UnassignShipment",
  service: EdgeService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_types_types_pb.StringId,
  responseType: proto_vorto_edge_edge_api_pb.ShipmentDetailsResponse
};

EdgeService.ListPendingShipments = {
  methodName: "ListPendingShipments",
  service: EdgeService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_types_types_pb.Empty,
  responseType: proto_vorto_edge_edge_api_pb.PendingShipmentSummaryResponse
};

EdgeService.ListInProgressShipments = {
  methodName: "ListInProgressShipments",
  service: EdgeService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_types_types_pb.Empty,
  responseType: proto_vorto_edge_edge_api_pb.InProgressShipmentSummaryResponse
};

EdgeService.ListCompletedShipments = {
  methodName: "ListCompletedShipments",
  service: EdgeService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_edge_edge_api_pb.CompletedShipmentSummaryRequest,
  responseType: proto_vorto_edge_edge_api_pb.CompletedShipmentSummaryResponse
};

EdgeService.ListNodeSites = {
  methodName: "ListNodeSites",
  service: EdgeService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_types_types_pb.Empty,
  responseType: proto_vorto_edge_edge_api_pb.NodeSitesResponse
};

EdgeService.ListNodeProducts = {
  methodName: "ListNodeProducts",
  service: EdgeService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_types_types_pb.Empty,
  responseType: proto_vorto_edge_edge_api_pb.NodeProductsResponse
};

EdgeService.EdgeCreateSite = {
  methodName: "EdgeCreateSite",
  service: EdgeService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_edge_edge_api_pb.EdgeCreateSiteRequest,
  responseType: proto_vorto_edge_edge_api_pb.EdgeCreateSiteResponse
};

EdgeService.AcceptShipmentForDriver = {
  methodName: "AcceptShipmentForDriver",
  service: EdgeService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_types_types_pb.StringId,
  responseType: proto_vorto_edge_edge_api_pb.ShipmentDetailsResponse
};

EdgeService.GetShipmentDetails = {
  methodName: "GetShipmentDetails",
  service: EdgeService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_types_types_pb.StringId,
  responseType: proto_vorto_edge_edge_api_pb.ShipmentDetailsResponse
};

EdgeService.GetShipmentDetailsStream = {
  methodName: "GetShipmentDetailsStream",
  service: EdgeService,
  requestStream: false,
  responseStream: true,
  requestType: proto_vorto_types_types_pb.StringId,
  responseType: proto_vorto_edge_edge_api_pb.ShipmentDetailsResponse
};

EdgeService.ListDriversForAssignment = {
  methodName: "ListDriversForAssignment",
  service: EdgeService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_types_types_pb.StringId,
  responseType: proto_vorto_edge_edge_api_pb.ListDriversForAssignmentResponse
};

EdgeService.FindDriverByNameOrPhone = {
  methodName: "FindDriverByNameOrPhone",
  service: EdgeService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_edge_edge_api_pb.DriverSearchRequest,
  responseType: proto_vorto_edge_edge_api_pb.ListDriversForAssignmentResponse
};

EdgeService.ListNodes = {
  methodName: "ListNodes",
  service: EdgeService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_types_types_pb.Empty,
  responseType: proto_vorto_edge_edge_api_pb.ListNodesResponse
};

EdgeService.GetCalendarData = {
  methodName: "GetCalendarData",
  service: EdgeService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_edge_edge_api_pb.GetCalendarDataRequest,
  responseType: proto_vorto_edge_edge_api_pb.GetCalendarDataResponse
};

EdgeService.GetCalendarDataStream = {
  methodName: "GetCalendarDataStream",
  service: EdgeService,
  requestStream: false,
  responseStream: true,
  requestType: proto_vorto_types_types_pb.Empty,
  responseType: proto_vorto_edge_edge_api_pb.CalendarDataFromStream
};

EdgeService.GetPreviousCalendarData = {
  methodName: "GetPreviousCalendarData",
  service: EdgeService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_edge_edge_api_pb.GetCalendarDataRequest,
  responseType: proto_vorto_edge_edge_pb.PreviousCalendarResponse
};

EdgeService.AddShiftForDriver = {
  methodName: "AddShiftForDriver",
  service: EdgeService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_edge_edge_api_pb.AddShiftForDriverRequest,
  responseType: proto_vorto_edge_edge_api_pb.GetCalendarDataResponse
};

EdgeService.EditShiftForDriver = {
  methodName: "EditShiftForDriver",
  service: EdgeService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_edge_edge_api_pb.EditShiftForDriverRequest,
  responseType: proto_vorto_edge_edge_api_pb.GetCalendarDataResponse
};

EdgeService.AssignShipmentAndCreateShift = {
  methodName: "AssignShipmentAndCreateShift",
  service: EdgeService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_edge_edge_api_pb.AssignShipmentAndCreateShiftRequest,
  responseType: proto_vorto_edge_edge_api_pb.AssignShipmentAndCreateShiftResponse
};

EdgeService.DeleteShiftForDriver = {
  methodName: "DeleteShiftForDriver",
  service: EdgeService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_edge_edge_api_pb.DeleteShiftForDriverRequest,
  responseType: proto_vorto_edge_edge_api_pb.GetCalendarDataResponse
};

EdgeService.ListSitesForNode = {
  methodName: "ListSitesForNode",
  service: EdgeService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_types_types_pb.StringId,
  responseType: proto_vorto_edge_edge_api_pb.NodeSitesResponse
};

EdgeService.ToggleAutoDispatch = {
  methodName: "ToggleAutoDispatch",
  service: EdgeService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_edge_edge_api_pb.ToggleAutoDispatchRequest,
  responseType: proto_vorto_edge_edge_api_pb.GetCalendarDataResponse
};

EdgeService.UpdateShipment = {
  methodName: "UpdateShipment",
  service: EdgeService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_edge_edge_api_pb.UpdateShipmentRequest,
  responseType: proto_vorto_edge_edge_api_pb.ShipmentDetailsResponse
};

EdgeService.ListPendingShipmentsForDriver = {
  methodName: "ListPendingShipmentsForDriver",
  service: EdgeService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_edge_edge_api_pb.ListPendingShipmentsForDriverRequest,
  responseType: proto_vorto_edge_edge_api_pb.ListPendingShipmentsForDriverResponse
};

EdgeService.EdgeBulkUploadShiftSchedule = {
  methodName: "EdgeBulkUploadShiftSchedule",
  service: EdgeService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_edge_edge_api_pb.EdgeBulkUploadShiftScheduleRequest,
  responseType: proto_vorto_edge_edge_api_pb.EdgeBulkUploadShiftScheduleResponse
};

EdgeService.GetRecentlyUsedTrailerTypes = {
  methodName: "GetRecentlyUsedTrailerTypes",
  service: EdgeService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_types_types_pb.Empty,
  responseType: proto_vorto_edge_edge_api_pb.ListOfTrailersResponse
};

EdgeService.GetMostUsedTrailerTypes = {
  methodName: "GetMostUsedTrailerTypes",
  service: EdgeService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_types_types_pb.Empty,
  responseType: proto_vorto_edge_edge_api_pb.ListOfTrailersResponse
};

EdgeService.GetPreviousAndNextShift = {
  methodName: "GetPreviousAndNextShift",
  service: EdgeService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_edge_edge_api_pb.GetPreviousAndNextShiftRequest,
  responseType: proto_vorto_edge_edge_api_pb.GetPreviousAndNextShiftResponse
};

EdgeService.GetShipperSites = {
  methodName: "GetShipperSites",
  service: EdgeService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_types_types_pb.Empty,
  responseType: proto_vorto_edge_edge_api_pb.GetShipperSitesResponse
};

EdgeService.CreateDriverGroup = {
  methodName: "CreateDriverGroup",
  service: EdgeService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_edge_edge_api_pb.CreateDriverGroupRequest,
  responseType: proto_vorto_edge_edge_api_pb.GetCalendarDataResponse
};

EdgeService.UpdateDriverGroup = {
  methodName: "UpdateDriverGroup",
  service: EdgeService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_edge_edge_api_pb.UpdateDriverGroupRequest,
  responseType: proto_vorto_edge_edge_api_pb.GetCalendarDataResponse
};

EdgeService.GetDriverGroup = {
  methodName: "GetDriverGroup",
  service: EdgeService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_types_types_pb.StringId,
  responseType: proto_vorto_edge_edge_api_pb.DriverGroupResponse
};

EdgeService.ArchiveDriverGroup = {
  methodName: "ArchiveDriverGroup",
  service: EdgeService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_edge_edge_api_pb.ArchiveDriverGroupRequest,
  responseType: proto_vorto_edge_edge_api_pb.GetCalendarDataResponse
};

EdgeService.MarkShipmentAsInTransit = {
  methodName: "MarkShipmentAsInTransit",
  service: EdgeService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_types_types_pb.StringId,
  responseType: proto_vorto_edge_edge_api_pb.ShipmentDetailsResponse
};

EdgeService.MarkShipmentAsComplete = {
  methodName: "MarkShipmentAsComplete",
  service: EdgeService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_types_types_pb.StringId,
  responseType: proto_vorto_edge_edge_api_pb.ShipmentDetailsResponse
};

exports.EdgeService = EdgeService;

function EdgeServiceClient(serviceHost, options) {
  this.serviceHost = serviceHost;
  this.options = options || {};
}

EdgeServiceClient.prototype.addDriver = function addDriver(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(EdgeService.AddDriver, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

EdgeServiceClient.prototype.removeDriver = function removeDriver(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(EdgeService.RemoveDriver, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

EdgeServiceClient.prototype.createEdgeContract = function createEdgeContract(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(EdgeService.CreateEdgeContract, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

EdgeServiceClient.prototype.editEdgeContract = function editEdgeContract(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(EdgeService.EditEdgeContract, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

EdgeServiceClient.prototype.closeEdgeContract = function closeEdgeContract(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(EdgeService.CloseEdgeContract, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

EdgeServiceClient.prototype.reopenClosedEdgeContract = function reopenClosedEdgeContract(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(EdgeService.ReopenClosedEdgeContract, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

EdgeServiceClient.prototype.getEdgeContract = function getEdgeContract(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(EdgeService.GetEdgeContract, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

EdgeServiceClient.prototype.listEdgeContracts = function listEdgeContracts(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(EdgeService.ListEdgeContracts, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

EdgeServiceClient.prototype.getEdgeContractNodes = function getEdgeContractNodes(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(EdgeService.GetEdgeContractNodes, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

EdgeServiceClient.prototype.createShipment = function createShipment(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(EdgeService.CreateShipment, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

EdgeServiceClient.prototype.declineShipment = function declineShipment(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(EdgeService.DeclineShipment, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

EdgeServiceClient.prototype.cancelShipment = function cancelShipment(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(EdgeService.CancelShipment, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

EdgeServiceClient.prototype.assignShipment = function assignShipment(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(EdgeService.AssignShipment, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

EdgeServiceClient.prototype.unassignShipment = function unassignShipment(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(EdgeService.UnassignShipment, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

EdgeServiceClient.prototype.listPendingShipments = function listPendingShipments(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(EdgeService.ListPendingShipments, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

EdgeServiceClient.prototype.listInProgressShipments = function listInProgressShipments(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(EdgeService.ListInProgressShipments, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

EdgeServiceClient.prototype.listCompletedShipments = function listCompletedShipments(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(EdgeService.ListCompletedShipments, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

EdgeServiceClient.prototype.listNodeSites = function listNodeSites(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(EdgeService.ListNodeSites, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

EdgeServiceClient.prototype.listNodeProducts = function listNodeProducts(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(EdgeService.ListNodeProducts, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

EdgeServiceClient.prototype.edgeCreateSite = function edgeCreateSite(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(EdgeService.EdgeCreateSite, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

EdgeServiceClient.prototype.acceptShipmentForDriver = function acceptShipmentForDriver(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(EdgeService.AcceptShipmentForDriver, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

EdgeServiceClient.prototype.getShipmentDetails = function getShipmentDetails(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(EdgeService.GetShipmentDetails, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

EdgeServiceClient.prototype.getShipmentDetailsStream = function getShipmentDetailsStream(requestMessage, metadata) {
  var listeners = {
    data: [],
    end: [],
    status: []
  };
  var client = grpc.invoke(EdgeService.GetShipmentDetailsStream, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage);
      });
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners = null;
    }
  });
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

EdgeServiceClient.prototype.listDriversForAssignment = function listDriversForAssignment(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(EdgeService.ListDriversForAssignment, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

EdgeServiceClient.prototype.findDriverByNameOrPhone = function findDriverByNameOrPhone(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(EdgeService.FindDriverByNameOrPhone, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

EdgeServiceClient.prototype.listNodes = function listNodes(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(EdgeService.ListNodes, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

EdgeServiceClient.prototype.getCalendarData = function getCalendarData(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(EdgeService.GetCalendarData, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

EdgeServiceClient.prototype.getCalendarDataStream = function getCalendarDataStream(requestMessage, metadata) {
  var listeners = {
    data: [],
    end: [],
    status: []
  };
  var client = grpc.invoke(EdgeService.GetCalendarDataStream, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage);
      });
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners = null;
    }
  });
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

EdgeServiceClient.prototype.getPreviousCalendarData = function getPreviousCalendarData(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(EdgeService.GetPreviousCalendarData, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

EdgeServiceClient.prototype.addShiftForDriver = function addShiftForDriver(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(EdgeService.AddShiftForDriver, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

EdgeServiceClient.prototype.editShiftForDriver = function editShiftForDriver(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(EdgeService.EditShiftForDriver, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

EdgeServiceClient.prototype.assignShipmentAndCreateShift = function assignShipmentAndCreateShift(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(EdgeService.AssignShipmentAndCreateShift, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

EdgeServiceClient.prototype.deleteShiftForDriver = function deleteShiftForDriver(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(EdgeService.DeleteShiftForDriver, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

EdgeServiceClient.prototype.listSitesForNode = function listSitesForNode(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(EdgeService.ListSitesForNode, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

EdgeServiceClient.prototype.toggleAutoDispatch = function toggleAutoDispatch(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(EdgeService.ToggleAutoDispatch, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

EdgeServiceClient.prototype.updateShipment = function updateShipment(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(EdgeService.UpdateShipment, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

EdgeServiceClient.prototype.listPendingShipmentsForDriver = function listPendingShipmentsForDriver(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(EdgeService.ListPendingShipmentsForDriver, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

EdgeServiceClient.prototype.edgeBulkUploadShiftSchedule = function edgeBulkUploadShiftSchedule(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(EdgeService.EdgeBulkUploadShiftSchedule, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

EdgeServiceClient.prototype.getRecentlyUsedTrailerTypes = function getRecentlyUsedTrailerTypes(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(EdgeService.GetRecentlyUsedTrailerTypes, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

EdgeServiceClient.prototype.getMostUsedTrailerTypes = function getMostUsedTrailerTypes(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(EdgeService.GetMostUsedTrailerTypes, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

EdgeServiceClient.prototype.getPreviousAndNextShift = function getPreviousAndNextShift(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(EdgeService.GetPreviousAndNextShift, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

EdgeServiceClient.prototype.getShipperSites = function getShipperSites(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(EdgeService.GetShipperSites, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

EdgeServiceClient.prototype.createDriverGroup = function createDriverGroup(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(EdgeService.CreateDriverGroup, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

EdgeServiceClient.prototype.updateDriverGroup = function updateDriverGroup(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(EdgeService.UpdateDriverGroup, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

EdgeServiceClient.prototype.getDriverGroup = function getDriverGroup(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(EdgeService.GetDriverGroup, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

EdgeServiceClient.prototype.archiveDriverGroup = function archiveDriverGroup(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(EdgeService.ArchiveDriverGroup, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

EdgeServiceClient.prototype.markShipmentAsInTransit = function markShipmentAsInTransit(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(EdgeService.MarkShipmentAsInTransit, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

EdgeServiceClient.prototype.markShipmentAsComplete = function markShipmentAsComplete(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(EdgeService.MarkShipmentAsComplete, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

exports.EdgeServiceClient = EdgeServiceClient;

