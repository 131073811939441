import { Directive, ElementRef, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
  selector: '[trackFormFocus]',
})
export class TrackFormFocusDirective {
  @Output()
  public formFocused = new EventEmitter<{ formIndex: number }>();

  constructor(private el: ElementRef) {}

  @HostListener('focusin', ['$event.target'])
  public onFocusIn(target: HTMLElement) {
    // could do more refined checks here, like if target is an input
    const form = target.closest('form');
    if (form) {
      this.formFocused.emit({ formIndex: +form.dataset.index });
    }
  }
}
