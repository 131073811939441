import { Pipe, PipeTransform } from '@angular/core';
import { isDate, isValid, add, isBefore, isAfter } from 'date-fns';
import { convertToDate } from '~utilities/convertToDate';

@Pipe({
  name: 'isAfterDate',
})
export class IsAfterDate implements PipeTransform {
  public transform(value: any, dateToCompare: any): boolean {
    const date1 = convertToDate(value);
    if (!date1) {
      return false;
    }

    const date2 = convertToDate(dateToCompare);
    if (!date2) {
      return false;
    }

    return isAfter(date1, date2);
  }
}
