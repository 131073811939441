import { Pipe, PipeTransform } from '@angular/core';
import { ShipmentStatusMap, SpotMarketRequestTypeMap } from '~proto/types/types_pb';

const prettyNameMap: Record<SpotMarketRequestTypeMap[keyof SpotMarketRequestTypeMap], string> = {
  0: 'Unknown',
  1: 'Blind Bid',
  2: 'Reverse Auction',
  3: 'None',
};

@Pipe({
  name: 'spotMarketType',
})
export class SpotMarketTypePipe implements PipeTransform {
  public transform(status: SpotMarketRequestTypeMap[keyof SpotMarketRequestTypeMap]): string {
    return prettyNameMap[status];
  }
}
