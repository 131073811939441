import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dashIfNotValid',
})
export class DashIfNotValidPipe implements PipeTransform {
  public transform(value: any): string {
    if (!value) {
      return '—';
    }
    if (value.constructor === Object) {
      if (!value.valid) {
        return '—';
      }
      const keys = Object.keys(value);
      for (const key of keys) {
        if (key !== 'valid') {
          return value[key];
        }
      }
    }
    return value;
  }
}
