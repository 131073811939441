// source: proto/vorto/user/user.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var proto_vorto_types_types_pb = require('../../../proto/vorto/types/types_pb.js');
goog.object.extend(proto, proto_vorto_types_types_pb);
goog.exportSymbol('proto.vtv1.Permission', null, global);
goog.exportSymbol('proto.vtv1.User', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.User = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.User.repeatedFields_, null);
};
goog.inherits(proto.vtv1.User, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.User.displayName = 'proto.vtv1.User';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.Permission = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.Permission, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.Permission.displayName = 'proto.vtv1.Permission';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.User.repeatedFields_ = [10,11,12,14];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.User.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.User.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.User} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.User.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    accountId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    email: (f = msg.getEmail()) && proto_vorto_types_types_pb.NullableString.toObject(includeInstance, f),
    phone: (f = msg.getPhone()) && proto_vorto_types_types_pb.NullableString.toObject(includeInstance, f),
    pin: (f = msg.getPin()) && proto_vorto_types_types_pb.NullableString.toObject(includeInstance, f),
    updatedAt: jspb.Message.getFieldWithDefault(msg, 8, ""),
    createdAt: jspb.Message.getFieldWithDefault(msg, 9, ""),
    regionsList: (f = jspb.Message.getRepeatedField(msg, 10)) == null ? undefined : f,
    regionNamesList: (f = jspb.Message.getRepeatedField(msg, 11)) == null ? undefined : f,
    certsList: (f = jspb.Message.getRepeatedField(msg, 12)) == null ? undefined : f,
    viewOnly: jspb.Message.getBooleanFieldWithDefault(msg, 13, false),
    sitesList: (f = jspb.Message.getRepeatedField(msg, 14)) == null ? undefined : f,
    notificationPhoneNumber: (f = msg.getNotificationPhoneNumber()) && proto_vorto_types_types_pb.NullableString.toObject(includeInstance, f),
    inventoryOnly: jspb.Message.getBooleanFieldWithDefault(msg, 16, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.User}
 */
proto.vtv1.User.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.User;
  return proto.vtv1.User.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.User} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.User}
 */
proto.vtv1.User.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountId(value);
      break;
    case 4:
      var value = new proto_vorto_types_types_pb.NullableString;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableString.deserializeBinaryFromReader);
      msg.setEmail(value);
      break;
    case 5:
      var value = new proto_vorto_types_types_pb.NullableString;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableString.deserializeBinaryFromReader);
      msg.setPhone(value);
      break;
    case 6:
      var value = new proto_vorto_types_types_pb.NullableString;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableString.deserializeBinaryFromReader);
      msg.setPin(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setUpdatedAt(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedAt(value);
      break;
    case 10:
      var value = /** @type {!Array<number>} */ (reader.readPackedInt64());
      msg.setRegionsList(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.addRegionNames(value);
      break;
    case 12:
      var value = /** @type {!Array<number>} */ (reader.readPackedInt64());
      msg.setCertsList(value);
      break;
    case 13:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setViewOnly(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.addSites(value);
      break;
    case 15:
      var value = new proto_vorto_types_types_pb.NullableString;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableString.deserializeBinaryFromReader);
      msg.setNotificationPhoneNumber(value);
      break;
    case 16:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setInventoryOnly(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.User.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.User.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.User} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.User.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAccountId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getEmail();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto_vorto_types_types_pb.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getPhone();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto_vorto_types_types_pb.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getPin();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto_vorto_types_types_pb.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getUpdatedAt();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getCreatedAt();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getRegionsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      10,
      f
    );
  }
  f = message.getRegionNamesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      11,
      f
    );
  }
  f = message.getCertsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      12,
      f
    );
  }
  f = message.getViewOnly();
  if (f) {
    writer.writeBool(
      13,
      f
    );
  }
  f = message.getSitesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      14,
      f
    );
  }
  f = message.getNotificationPhoneNumber();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      proto_vorto_types_types_pb.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getInventoryOnly();
  if (f) {
    writer.writeBool(
      16,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.vtv1.User.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.User} returns this
 */
proto.vtv1.User.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.vtv1.User.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.User} returns this
 */
proto.vtv1.User.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string account_id = 3;
 * @return {string}
 */
proto.vtv1.User.prototype.getAccountId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.User} returns this
 */
proto.vtv1.User.prototype.setAccountId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional NullableString email = 4;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.User.prototype.getEmail = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableString, 4));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.User} returns this
*/
proto.vtv1.User.prototype.setEmail = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.User} returns this
 */
proto.vtv1.User.prototype.clearEmail = function() {
  return this.setEmail(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.User.prototype.hasEmail = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional NullableString phone = 5;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.User.prototype.getPhone = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableString, 5));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.User} returns this
*/
proto.vtv1.User.prototype.setPhone = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.User} returns this
 */
proto.vtv1.User.prototype.clearPhone = function() {
  return this.setPhone(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.User.prototype.hasPhone = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional NullableString pin = 6;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.User.prototype.getPin = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableString, 6));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.User} returns this
*/
proto.vtv1.User.prototype.setPin = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.User} returns this
 */
proto.vtv1.User.prototype.clearPin = function() {
  return this.setPin(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.User.prototype.hasPin = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional string updated_at = 8;
 * @return {string}
 */
proto.vtv1.User.prototype.getUpdatedAt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.User} returns this
 */
proto.vtv1.User.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string created_at = 9;
 * @return {string}
 */
proto.vtv1.User.prototype.getCreatedAt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.User} returns this
 */
proto.vtv1.User.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * repeated int64 regions = 10;
 * @return {!Array<number>}
 */
proto.vtv1.User.prototype.getRegionsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 10));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.vtv1.User} returns this
 */
proto.vtv1.User.prototype.setRegionsList = function(value) {
  return jspb.Message.setField(this, 10, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.vtv1.User} returns this
 */
proto.vtv1.User.prototype.addRegions = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 10, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.User} returns this
 */
proto.vtv1.User.prototype.clearRegionsList = function() {
  return this.setRegionsList([]);
};


/**
 * repeated string region_names = 11;
 * @return {!Array<string>}
 */
proto.vtv1.User.prototype.getRegionNamesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 11));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.vtv1.User} returns this
 */
proto.vtv1.User.prototype.setRegionNamesList = function(value) {
  return jspb.Message.setField(this, 11, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.vtv1.User} returns this
 */
proto.vtv1.User.prototype.addRegionNames = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 11, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.User} returns this
 */
proto.vtv1.User.prototype.clearRegionNamesList = function() {
  return this.setRegionNamesList([]);
};


/**
 * repeated int64 certs = 12;
 * @return {!Array<number>}
 */
proto.vtv1.User.prototype.getCertsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 12));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.vtv1.User} returns this
 */
proto.vtv1.User.prototype.setCertsList = function(value) {
  return jspb.Message.setField(this, 12, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.vtv1.User} returns this
 */
proto.vtv1.User.prototype.addCerts = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 12, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.User} returns this
 */
proto.vtv1.User.prototype.clearCertsList = function() {
  return this.setCertsList([]);
};


/**
 * optional bool view_only = 13;
 * @return {boolean}
 */
proto.vtv1.User.prototype.getViewOnly = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 13, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vtv1.User} returns this
 */
proto.vtv1.User.prototype.setViewOnly = function(value) {
  return jspb.Message.setProto3BooleanField(this, 13, value);
};


/**
 * repeated string sites = 14;
 * @return {!Array<string>}
 */
proto.vtv1.User.prototype.getSitesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 14));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.vtv1.User} returns this
 */
proto.vtv1.User.prototype.setSitesList = function(value) {
  return jspb.Message.setField(this, 14, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.vtv1.User} returns this
 */
proto.vtv1.User.prototype.addSites = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 14, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.User} returns this
 */
proto.vtv1.User.prototype.clearSitesList = function() {
  return this.setSitesList([]);
};


/**
 * optional NullableString notification_phone_number = 15;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.User.prototype.getNotificationPhoneNumber = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableString, 15));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.User} returns this
*/
proto.vtv1.User.prototype.setNotificationPhoneNumber = function(value) {
  return jspb.Message.setWrapperField(this, 15, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.User} returns this
 */
proto.vtv1.User.prototype.clearNotificationPhoneNumber = function() {
  return this.setNotificationPhoneNumber(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.User.prototype.hasNotificationPhoneNumber = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional bool inventory_only = 16;
 * @return {boolean}
 */
proto.vtv1.User.prototype.getInventoryOnly = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 16, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vtv1.User} returns this
 */
proto.vtv1.User.prototype.setInventoryOnly = function(value) {
  return jspb.Message.setProto3BooleanField(this, 16, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.Permission.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.Permission.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.Permission} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.Permission.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    description: (f = msg.getDescription()) && proto_vorto_types_types_pb.NullableString.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.Permission}
 */
proto.vtv1.Permission.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.Permission;
  return proto.vtv1.Permission.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.Permission} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.Permission}
 */
proto.vtv1.Permission.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = new proto_vorto_types_types_pb.NullableString;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableString.deserializeBinaryFromReader);
      msg.setDescription(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.Permission.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.Permission.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.Permission} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.Permission.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDescription();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto_vorto_types_types_pb.NullableString.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.vtv1.Permission.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.Permission} returns this
 */
proto.vtv1.Permission.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.vtv1.Permission.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.Permission} returns this
 */
proto.vtv1.Permission.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional NullableString description = 3;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.Permission.prototype.getDescription = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableString, 3));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.Permission} returns this
*/
proto.vtv1.Permission.prototype.setDescription = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.Permission} returns this
 */
proto.vtv1.Permission.prototype.clearDescription = function() {
  return this.setDescription(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.Permission.prototype.hasDescription = function() {
  return jspb.Message.getField(this, 3) != null;
};


goog.object.extend(exports, proto.vtv1);
