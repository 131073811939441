import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ProfileComponent } from './profile/profile.component';
import { AuthGuard } from './singleton/guards/auth.guard';
import { RoleGuard } from './singleton/guards/role.guard';
import { NotificationsListComponent, AccountNotificationsListComponent } from './profile';
import { NotFoundComponent } from './not-found/not-found.component';
import { HasAccountTypeGuard } from './has-account-type.guard';
import { InternalGuard } from './modules/internal/internal.guard';
import { NotInventoryOnlyGuard } from './shared/guards/not-inventory-only.guard';
import { CurrentAccountComponent } from './profile/current-account/current-account.component';

const routes: Routes = [
  {
    loadChildren: () => import('src/app/modules/token-auth/token-auth.module').then((m) => m.TokenAuthModule),
    path: 'token_auth',
  },
  {
    loadChildren: () => import('src/app/modules/node/spot-market/spot-market.module').then((m) => m.SpotMarketModule),
    path: 'spot_market',
    canLoad: [HasAccountTypeGuard],
    data: { accountFeatures: ['node'] },
  },
  {
    loadChildren: () =>
      import('src/app/modules/node/procurement-dashboard/procurement-dashboard.module').then(
        (m) => m.ProcurementDashboardModule,
      ),
    path: 'procurement_dashboard',
    canLoad: [HasAccountTypeGuard],
    data: { accountFeatures: ['node'] },
  },
  {
    loadChildren: () => import('src/app/modules/node/incident/incident.module').then((m) => m.IncidentReportModule),
    path: 'incident-report',
    canLoad: [HasAccountTypeGuard],
    data: { accountFeatures: ['node'] },
  },
  {
    loadChildren: () =>
      import('src/app/modules/dashboards/inbound-dashboard/inbound-dashboard.module').then(
        (m) => m.InboundDashboardModule,
      ),
    path: 'facility_dashboard',
    canLoad: [HasAccountTypeGuard],
    data: { accountFeatures: ['node'] },
  },
  {
    path: 'inbound',
    redirectTo: 'facility_dashboard',
    pathMatch: 'prefix',
  },
  {
    loadChildren: () =>
      import('src/app/modules/dashboards/setup-dashboard/setup-dashboard.module').then((m) => m.SetupDashboardModule),
    path: 'setup',
    canLoad: [HasAccountTypeGuard, NotInventoryOnlyGuard],
    data: { accountFeatures: ['node'] },
  },
  {
    loadChildren: () => import('src/app/modules/billing/billing.module').then((m) => m.BillingModule),
    path: 'billing',
    canLoad: [HasAccountTypeGuard, NotInventoryOnlyGuard],
    data: { accountFeatures: ['node', 'edge'] },
  },
  {
    loadChildren: () => import('src/app/modules/billing-v2/billing-v2.module').then((m) => m.BillingV2Module),
    path: 'billing-v2',
    canLoad: [HasAccountTypeGuard, NotInventoryOnlyGuard],
    data: { accountFeatures: ['node', 'edge'] },
  },
  {
    loadChildren: () =>
      import('src/app/modules/supplier-billing/supplier-billing.module').then((m) => m.SupplierBillingModule),
    path: 'supplier-billing',
    canLoad: [HasAccountTypeGuard, NotInventoryOnlyGuard],
    data: { accountFeatures: ['node'] },
  },
  {
    loadChildren: () =>
      import('src/app/modules/dashboards/outbound-dashboard/outbound-dashboard.module').then(
        (m) => m.OutboundDashboardModule,
      ),
    path: 'outbound',
    canLoad: [HasAccountTypeGuard],
    data: { accountFeatures: ['node'] },
  },
  {
    loadChildren: () =>
      import('src/app/modules/edge/edge-calendar/edge-calendar.module').then((m) => m.EdgeCalendarModule),
    path: 'carrier/calendar',
    canLoad: [HasAccountTypeGuard],
    data: { accountFeatures: ['edge'] },
  },
  {
    loadChildren: () => import('src/app/modules/orders/orders.module').then((m) => m.OrdersModule),
    path: 'orders',
  },
  {
    loadChildren: () =>
      import('src/app/modules/equipment-orders/equipment-orders.module').then((m) => m.EquipmentOrdersModule),
    path: 'equipment-orders',
  },
  {
    loadChildren: () =>
      import('src/app/modules/pending-facilities/pending-facilities.module').then((m) => m.PendingFacilitiesModule),
    path: 'pending-facilities',
  },
  {
    loadChildren: () => import('src/app/modules/draft-po/draft-po.module').then((m) => m.DraftPoModule),
    path: 'draft-pos',
  },
  {
    loadChildren: () =>
      import('src/app/modules/user-management/user-management.module').then((m) => m.UserManagementModule),
    path: 'users',
    canLoad: [NotInventoryOnlyGuard],
  },
  {
    loadChildren: () =>
      import('src/app/modules/node/route-guides/route-guides.module').then((m) => m.RouteGuidesModule),
    path: 'route_guides',
  },
  {
    loadChildren: () =>
      import('src/app/modules/edge/edge-shipments/edge-shipments.module').then((m) => m.CarrierShipmentsModule),
    path: 'carrier_shipments',
    canLoad: [HasAccountTypeGuard],
    data: { accountFeatures: ['edge'] },
  },
  {
    loadChildren: () =>
      import('src/app/modules/node/supplier-management/supplier-management.module').then(
        (m) => m.ShipperSuppliersModule,
      ),
    path: 'network_management',
    // canLoad: [HasAccountTypeGuard],
    // data: { accountFeatures: ['node'] },
  },
  {
    loadChildren: () =>
      import('src/app/modules/edge/edge-contract-management-as-node/edge-contract-management-as-shipper.module').then(
        (m) => m.EdgeContractManagementAsShipperModule,
      ),
    path: 'carrier_management',
    canLoad: [HasAccountTypeGuard, NotInventoryOnlyGuard],
    data: { accountFeatures: ['node'] },
  },
  {
    loadChildren: () =>
      import('src/app/modules/node/node-po-management/node-po-management.module').then(
        (m) => m.ConsumerPoManagementModule,
      ),
    path: 'purchase_orders',
    canLoad: [HasAccountTypeGuard, NotInventoryOnlyGuard],
    data: { accountFeatures: ['node'] },
  },
  {
    loadChildren: () =>
      import('src/app/modules/node/node-ordering/consumer-ordering.module').then((m) => m.ConsumerOrderingModule),
    path: 'consumer/orders',
    canLoad: [HasAccountTypeGuard],
    data: { accountFeatures: ['node'] },
  },
  {
    loadChildren: () =>
      import('src/app/modules/node/node-site-management/site-management.module').then((m) => m.SiteManagementModule),
    path: 'facility_management',
    canLoad: [HasAccountTypeGuard],
    data: { accountFeatures: ['node'] },
  },
  {
    loadChildren: () =>
      import('src/app/modules/node/demand-import/demand-import.module').then((m) => m.DemandImportModule),
    path: 'demand_import',
    canLoad: [HasAccountTypeGuard, NotInventoryOnlyGuard],
    data: { accountFeatures: ['node'] },
  },
  {
    loadChildren: () =>
      import('src/app/modules/node/product-management/product-management.module').then(
        (m) => m.ProductManagementModule,
      ),
    path: 'sku_management',
    canLoad: [HasAccountTypeGuard, NotInventoryOnlyGuard],
    data: { accountFeatures: ['node'] },
  },
  {
    loadChildren: () => import('src/app/modules/node/fuel-matrix/fuel-matrix.module').then((m) => m.FuelMatrixModule),
    path: 'fuel_matrices',
    canLoad: [HasAccountTypeGuard, NotInventoryOnlyGuard],
    data: { accountFeatures: ['node'] },
  },
  {
    loadChildren: () => import('src/app/modules/lite/lite.module').then((m) => m.LiteModule),
    path: 'lite',
  },
  {
    loadChildren: () =>
      import('src/app/modules/node/production-process/production-process.module').then(
        (m) => m.ProductionProcessModule,
      ),
    path: 'bill-of-materials',
    canLoad: [HasAccountTypeGuard, NotInventoryOnlyGuard],
    data: { accountFeatures: ['node'] },
  },
  {
    loadChildren: () =>
      import('src/app/modules/node/spot-market-response-from-email/spot-market-response-from-email.module').then(
        (m) => m.SpotMarketResponseFromEmailModule,
      ),
    path: 'spot_market_response_from_email',
  },
  {
    loadChildren: () => import('src/app/modules/node/fulfillment/fulfillment.module').then((m) => m.FulfillmentModule),
    path: 'fulfillment',
    canActivate: [AuthGuard],
  },
  {
    loadChildren: () => import('src/app/modules/internal/internal.module').then((m) => m.InternalModule),
    path: 'internal',
    canActivate: [AuthGuard, InternalGuard],
  },
  {
    loadChildren: () => import('src/app/modules/vmi/vmi.module').then((m) => m.VmiModule),
    path: 'vmi',
    canLoad: [HasAccountTypeGuard],
    data: { accountFeatures: ['node'] },
  },
  {
    loadChildren: () => import('src/app/modules/signup/signup.module').then((m) => m.SignupModule),
    path: 'signup',
  },
  {
    path: 'profile/account',
    component: AccountNotificationsListComponent,
    canActivate: [AuthGuard],
  },
  {
    children: [
      {
        path: 'notifications',
        component: NotificationsListComponent,
      },
      {
        path: 'current_account',
        component: CurrentAccountComponent,
      },
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'notifications',
      },
    ],
    component: ProfileComponent,
    path: 'profile',
    canActivate: [AuthGuard],
  },
  {
    path: '',
    component: ProfileComponent,
    canActivate: [AuthGuard, RoleGuard],
  },
  {
    path: '**',
    component: NotFoundComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
