export const stringOrDateToDateISO = (date: string | Date) => {
  if (date === '' || date === null) {
    return null;
  }
  if (typeof date === 'string') {
    return new Date(date).toISOString();
  }
  return date.toISOString();
};

export const stringOrDateToDate = (date: string | Date) => {
  if (typeof date === 'string') {
    return new Date(date);
  }
  return date;
};
