import { Pipe, PipeTransform } from '@angular/core';

const isImage = /(gif|jpe?g|tiff?|png|webp|bmp|svg)$/i;
@Pipe({
  name: 'isImage',
})
export class IsImagePipe implements PipeTransform {
  public transform(value: string): boolean {
    const convURL = new URL(value);
    return isImage.test(convURL.pathname);
  }
}
