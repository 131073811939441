import { Injectable, Optional, Inject } from '@angular/core';
import { NgxMatNativeDateAdapter } from '@angular-material-components/datetime-picker';
import { isToday, isTomorrow, format } from 'date-fns';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { Platform } from '@angular/cdk/platform';

@Injectable()
export class CustomDateAdapter extends NgxMatNativeDateAdapter {
  constructor(@Optional() @Inject(MAT_DATE_LOCALE) private matDateLocale: string, private platform: Platform) {
    super(matDateLocale, platform);
    super.setLocale(matDateLocale);
  }
  public format(date: Date, displayFormat: any): string {
    if (!this.isValid(date)) {
      throw Error('NativeDateAdapter: Cannot format invalid date.');
    }

    let formattingText = `h:mmaaaaa'm' M/dd/yyyy`;
    if (isToday(date)) {
      formattingText = `h:mmaaaaa'm' 'Today'`;
    } else if (isTomorrow(date)) {
      formattingText = `h:mmaaaaa'm' 'Tomorrow'`;
    }
    return format(date, formattingText);
  }

  public copyTime(toDate: Date, fromDate: Date) {
    if (toDate.getHours() === 0 && toDate.getMinutes() === 0) {
      toDate.setHours(fromDate.getHours(), fromDate.getMinutes());
    }
    super.copyTime(toDate, toDate);
  }
}
