import { NullableString, NullableTime, NullableInt64, NullableDouble, NullableBool } from '~proto/types/types_pb';
import { stringOrDateToDateISO } from '~utilities/stringOrDateToString';

export const nullableStringToNullOrString = (nullableString: NullableString.AsObject): string | null => {
  if (!nullableString || !nullableString.valid) {
    return null;
  }
  return nullableString.str;
};

export const nullOrStringToNullableString = (str: string | null): NullableString => {
  const nullableString = new NullableString();
  if (str === null || str === undefined || str?.length === 0) {
    return nullableString;
  }
  nullableString.setValid(true);
  nullableString.setStr(str);
  return nullableString;
};

export const nullableTimeToNullOrString = (nullableTime: NullableTime.AsObject): string | null => {
  if (!nullableTime || !nullableTime.valid) {
    return null;
  }
  return nullableTime.time;
};

export const nullOrStringToNullableTime = (time: string | null): NullableTime => {
  const nullableTime = new NullableTime();
  if (!time) {
    return nullableTime;
  }
  nullableTime.setValid(true);
  nullableTime.setTime(stringOrDateToDateISO(time));
  return nullableTime;
};

export const nullOrDateToNullableTime = (date: Date | null): NullableTime => {
  const nullableTime = new NullableTime();
  if (!date) {
    return nullableTime;
  }
  nullableTime.setValid(true);
  if (typeof date === 'string') {
    nullableTime.setTime(date);
  } else {
    nullableTime.setTime(date.toISOString());
  }
  return nullableTime;
};

export const nullableIntToNullOrNumber = (nullableInt: NullableInt64.AsObject): number | null => {
  if (!nullableInt || !nullableInt.valid) {
    return null;
  }
  return nullableInt.pb_int;
};

export const nullableTimeToNullOrDate = (nullableTime: NullableTime.AsObject): Date | null => {
  if (!nullableTime || !nullableTime.valid) {
    return null;
  }
  return new Date(nullableTime.time);
};

export const nullOrNumberToNullableInt64 = (int: number | null | undefined): NullableInt64 => {
  const nullableInt = new NullableInt64();
  if (int === null || int === undefined) {
    return nullableInt;
  }
  nullableInt.setValid(true);
  nullableInt.setInt(int);
  return nullableInt;
};

export const nullableDoubleToNullOrNumber = (nullableDouble: NullableDouble.AsObject): number | null => {
  if (!nullableDouble || !nullableDouble.valid) {
    return null;
  }
  return nullableDouble.pb_double;
};

export const nullOrNumberToNullableDouble = (double: number | null): NullableDouble => {
  const nullableDouble = new NullableDouble();
  if (double === null) {
    return nullableDouble;
  }
  nullableDouble.setValid(true);
  nullableDouble.setDouble(double);
  return nullableDouble;
};

export const nullableBoolToNullOrNumber = (nullableBool: NullableBool.AsObject): boolean | null => {
  if (!nullableBool || !nullableBool.valid) {
    return null;
  }
  return nullableBool.bool;
};

export const nullOrBoolToNullableBool = (bool: boolean | null): NullableBool => {
  const nullableBool = new NullableBool();
  if (bool === null) {
    return nullableBool;
  }
  nullableBool.setValid(true);
  nullableBool.setBool(bool);
  return nullableBool;
};
