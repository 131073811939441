import { Directive, Optional } from '@angular/core';
import { InitializationService } from '../../singleton/services/initialization.service';
import { FormControlDirective, FormGroupDirective } from '@angular/forms';
import { take } from 'rxjs/operators';
import { lastValueFrom } from 'rxjs';

@Directive({
  selector: '[vtViewOnlyDisableForm]',
})
export class ViewOnlyDisableFormDirective {
  constructor(
    private userService: InitializationService,
    @Optional() private formGroupDirective: FormGroupDirective,
    @Optional() private formControlDirective: FormControlDirective,
  ) {
    this.checkIfDisabled();
  }

  private async checkIfDisabled() {
    const user = await lastValueFrom(this.userService.myUserInfo$.pipe(take(1)));

    if (user?.viewOnly && this.formGroupDirective && this.formGroupDirective.enabled) {
      this.formGroupDirective.form.disable();
    }
    if (user?.viewOnly && this.formControlDirective && this.formControlDirective.enabled) {
      this.formControlDirective.form.disable();
    }
  }
}
