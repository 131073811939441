<div style="height: 48px;" class="pl-5 pr-5 bg-indigo-900 flex items-center justify-between print:hidden">
  <div class="flex items-center mx-auto">
    <a routerLink="/">
      <img style="height: 20px;" src="/assets/images/vorto-logo.svg" alt="vorto-logo" />
    </a>
  </div>
  <div>
    <div class="flex items-center" *ngIf="authService.isLoggedIn$ | async; else signedOut">
      <ng-container *vtIsCpg="false">
        <div
          class="rounded cursor-pointer bg-indigo-800 w-4 h-4 flex items-center justify-center text-white"
          *vtHasAccountFeature="'node'; not: 'lite'"
          (click)="openTakeover()"
        >
          <mat-icon>search </mat-icon>
        </div>
      </ng-container>
    </div>
  </div>
  <ng-template #signedOut>
    <div class="text-white cursor-pointer" (click)="authService.login()">
      Sign In
    </div>
  </ng-template>
</div>
