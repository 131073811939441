import { Pipe, PipeTransform } from '@angular/core';
import { interval, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  add,
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  differenceInMonths,
  differenceInSeconds,
  isAfter,
  isBefore,
  sub,
} from 'date-fns';

const countdownObservables: Record<string, Observable<string>> = {};

@Pipe({
  name: 'countdown',
})
export class CountdownPipe implements PipeTransform {
  public transform(dateAsString: string): Observable<string> {
    if (!countdownObservables[dateAsString]) {
      countdownObservables[dateAsString] = getCountDown(dateAsString);
    }
    return countdownObservables[dateAsString];
  }
}

interface PreciseDiff {
  days: number;
  firstDateWasLater: boolean;
  hours: number;
  minutes: number;
  months: number;
  seconds: number;
  years: number;
}
const dateOrder: (keyof PreciseDiff)[] = ['years', 'months', 'days', 'hours', 'minutes', 'seconds'];
const substitutes: Partial<Record<keyof PreciseDiff, string>> = { months: 'mo' };

function getCountDown(dateAsString: string): Observable<string> {
  try {
    const asMoment = new Date(dateAsString);
    return interval(200).pipe(
      map(() => {
        const preciseDiff: PreciseDiff = preciseDifference(asMoment);
        if (preciseDiff === null) {
          return '-';
        }
        const result = [];
        let dateOrderIndex = 0;
        while (dateOrderIndex < dateOrder.length && result.length < 3) {
          const key = dateOrder[dateOrderIndex];
          if (preciseDiff[key] > 0 || result.length === 1) {
            const keyName = substitutes[key] ? substitutes[key] : key.substring(0, 1).toLocaleLowerCase();
            result.push(`${preciseDiff[key]}${keyName}`);
          }
          dateOrderIndex++;
        }
        if (result.length) {
          return `${result.join(' ')}`;
        }
        return '-';
      }),
    );
  } catch (e) {
    console.error(e);
  }
  return of('-');
}

const differenceInFn = {
  second: differenceInSeconds,
  minute: differenceInMinutes,
  hour: differenceInHours,
  day: differenceInDays,
  month: differenceInMonths,
};
type possibleParts = keyof typeof differenceInFn;

function preciseDifference(
  date: Date,
  parts: possibleParts[] = ['month', 'day', 'hour', 'minute', 'second'],
): PreciseDiff | null {
  let now = new Date();
  if (isBefore(date, now)) {
    return null;
  }
  const response: PreciseDiff = {
    seconds: 0,
    minutes: 0,
    hours: 0,
    days: 0,
    months: 0,
    years: 0,
    firstDateWasLater: false,
  };

  parts.forEach((part, i) => {
    const fn = differenceInFn[part];
    if (fn) {
      const differenceInPart = fn(date, now);
      if (differenceInPart) {
        response[`${part}s`] = differenceInPart;
        const additionDuration = { [`${part}s`]: differenceInPart };
        // We are just going to subtract from "now" until we get down to the date
        now = add(now, additionDuration);
      }
    }
  });
  return response;
}
