import { Injectable } from '@angular/core';
import { map, distinctUntilChanged, take, filter } from 'rxjs/operators';
import { RouterStateService } from './singleton/services/router-state-service.service';
import { AuthService } from './singleton/services/auth.service';
import { InitializationService } from './singleton/services/initialization.service';
import { environment } from '~environments/environment';
import { lastValueFrom } from 'rxjs';

declare const Intercom: any;

@Injectable({
  providedIn: 'root',
})
export class IntercomService {
  constructor(
    private routerState: RouterStateService,
    private authService: AuthService,
    private initializationService: InitializationService,
  ) {
    this.listenForLoggedIn();
    this.listenToRouterStateChanges();
  }

  public show() {
    if (hasIntercom()) {
      Intercom('show');
    }
  }

  public showMessages() {
    if (hasIntercom()) {
      Intercom('showMessages');
    }
  }

  public showNewMessage() {
    if (hasIntercom()) {
      Intercom('showNewMessage');
    }
  }

  private async boot() {
    const user = await lastValueFrom(
      this.initializationService.myUserInfo$.pipe(
        filter((u) => !!u && !!u.userId),
        take(1),
      ),
    );
    if (user && user.userId && hasIntercom()) {
      const intercomSettings = {
        app_id: environment.intercomId,
        name: user.name,
        email: user.email,
        company: user.accountName,
        companyName: user.accountName,
        user_id: user.userId,
        consumer: user.consumer,
        supplier: user.supplier,
        shipper: user.shipper,
        hide_default_launcher: true,
        // intercom doesn't like arrays?
        user_permissions: user.userPermissionsList,
        account_features: user.accountFeaturesList,
        node: user.accountFeaturesList.includes('node'),
        edge: user.accountFeaturesList.includes('edge'),
        lite: user.accountFeaturesList.includes('lite'),
        virtual: user.isVirtual,
        created_at: new Date(user.createdAt),
      };
      Intercom('boot', intercomSettings);
    }
  }

  private shutdown() {
    if (hasIntercom()) {
      Intercom('shutdown');
    }
  }

  private listenToRouterStateChanges() {
    this.routerState.routerState$
      .pipe(
        map((state) => state.url),
        distinctUntilChanged(),
      )
      .subscribe(() => {
        if (hasIntercom()) {
          Intercom('update');
        }
      });
  }

  private listenForLoggedIn() {
    this.authService.isLoggedIn$.pipe(distinctUntilChanged()).subscribe((isLoggedIn) => {
      if (isLoggedIn) {
        this.boot();
      } else {
        this.shutdown();
      }
    });
  }
}

function hasIntercom() {
  return typeof Intercom !== 'undefined';
}
