import { Pipe, PipeTransform } from '@angular/core';
import { OrderDetails } from '~proto/order/order_pb';

const prettyNameMap: Record<OrderDetails.StatusMap[keyof OrderDetails.StatusMap], string> = {
  0: 'Unknown',
  1: 'At Carrier',
  2: 'Scheduled',
  3: 'Delivery In Progress',
  4: 'Arrived',
  5: 'Completed',
  6: 'Cancelled',
  7: 'At Supplier',
};

@Pipe({
  name: 'orderStatus',
})
export class OrderStatusPipe implements PipeTransform {
  public transform(status: OrderDetails.StatusMap[keyof OrderDetails.StatusMap]): string {
    return prettyNameMap[status];
  }
}
