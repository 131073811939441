import { ChangeDetectorRef, Directive, OnDestroy, TemplateRef, ViewContainerRef } from '@angular/core';
import { InitializationService } from '../../singleton/services/initialization.service';
import { take } from 'rxjs/operators';
import { Subscription } from 'rxjs';

@Directive({
  selector: '[vtHideFromInventoryOnly]',
})
export class HideFromInventoryOnlyDirective implements OnDestroy {
  private sub: Subscription;

  constructor(
    private userService: InitializationService,
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private cd: ChangeDetectorRef,
  ) {
    this.checkIfDisabled();
  }

  private async checkIfDisabled() {
    this.sub = this.userService.myUserInfo$.subscribe((user) => {
      if (user?.inventoryOnly) {
        this.viewContainer.clear();
        this.cd.markForCheck();
      } else {
        this.viewContainer.createEmbeddedView(this.templateRef);
        this.cd.markForCheck();
      }
    });
  }

  public ngOnDestroy(): void {
    this.sub.unsubscribe();
  }
}
