import { ChangeDetectorRef, Directive, Input, OnDestroy, TemplateRef, ViewContainerRef } from '@angular/core';
import { InitializationService } from '../../singleton/services/initialization.service';
import { AuthService } from '../../singleton/services/auth.service';
import { BehaviorSubject, combineLatest, Subscription } from 'rxjs';

@Directive({
  selector: '[vtHasPermissions]',
})
export class HasPermissionsDirective implements OnDestroy {
  private rawPermissions$$ = new BehaviorSubject<string[]>([]);
  private elseTemplate$$ = new BehaviorSubject<TemplateRef<any>>(null);
  private hasView = false;
  private hasElseView = false;
  private subscription: Subscription;

  @Input() public set vtHasPermissionsElse(elseTemplate: TemplateRef<any>) {
    this.elseTemplate$$.next(elseTemplate);
  }

  @Input() public set vtHasPermissions(permissions: string | string[]) {
    if (!permissions || !permissions.length) {
      this.rawPermissions$$.next([]);
    } else if (Array.isArray(permissions)) {
      this.rawPermissions$$.next(permissions);
    } else {
      this.rawPermissions$$.next([permissions]);
    }
  }

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private meta: InitializationService,
    private authService: AuthService,
    private cd: ChangeDetectorRef,
  ) {
    this.listenToViewChanges();
  }

  public ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
  private listenToViewChanges() {
    this.subscription = combineLatest([this.rawPermissions$$, this.meta.myUserInfo$, this.elseTemplate$$]).subscribe(
      ([permissionsIdsToCheck, myUserInfo, elseTemplate]) => {
        if (
          !permissionsIdsToCheck ||
          !permissionsIdsToCheck.length ||
          !myUserInfo ||
          !myUserInfo.userPermissionsList ||
          !myUserInfo.userPermissionsList.length
        ) {
          this.doesNotHavePermission(elseTemplate);
          return;
        }

        for (const permission of permissionsIdsToCheck) {
          for (const userPermission of myUserInfo.userPermissionsList) {
            if (permission === userPermission) {
              this.hasPermission();
              return;
            }
          }
        }
        this.doesNotHavePermission(elseTemplate);
      },
    );
  }

  private doesNotHavePermission(elseTemplate: TemplateRef<any> | null) {
    if (this.hasView) {
      this.viewContainer.clear();
      this.hasView = false;
    }
    if (elseTemplate && !this.hasElseView) {
      this.viewContainer.createEmbeddedView(elseTemplate);
      this.hasElseView = true;
    }
    this.cd.markForCheck();
  }

  private hasPermission() {
    if (!this.hasView) {
      if (this.hasElseView) {
        this.viewContainer.clear();
        this.hasElseView = false;
      }
      this.viewContainer.createEmbeddedView(this.templateRef);
      this.hasView = true;
      this.cd.markForCheck();
    }
  }
}
