import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { AuthService } from '../../../singleton/services/auth.service';
import { InitializationService } from 'src/app/singleton/services/initialization.service';
import { MyUserInformationResponse } from '~proto/user/user_api_pb';
import { Observable } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { TakeoverComponent } from '../takeover/takeover.component';
import { SnackbarService } from '../../../singleton/services/snackbar.service';

@Component({
  selector: 'vt-nav',
  templateUrl: './nav.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavComponent implements OnInit {
  public myUserInfo$: Observable<MyUserInformationResponse.AsObject>;
  constructor(
    public authService: AuthService,
    public initializationService: InitializationService,
    private matDialog: MatDialog,
    private snackbarService: SnackbarService,
  ) {}

  public ngOnInit(): void {
    this.myUserInfo$ = this.initializationService.myUserInfo$;
  } // end of onInit

  public openTakeover() {
    this.matDialog.open(TakeoverComponent, {
      closeOnNavigation: true,
      panelClass: ['w-full', 'h-full', 'absolute', 'top-0', 'left-0', 'bg-blue-900', 'max-w-full', 'p-2'],
      hasBackdrop: false,
      height: '100%',
      width: '100%',
      maxHeight: '100%',
      maxWidth: '100%',
      autoFocus: true,
      position: {
        left: '0px',
        top: '0px',
      },
      // backdropClass: 'w-full h-full absolute top-0 left-0 bg-blue-900',
    });
    this.snackbarService.showMessage('Next time try pressing S');
  }
} // end of navComponent
