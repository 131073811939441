import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fileName',
})
export class FileNamePipe implements PipeTransform {
  public transform(file: string): string {
    return getFilename(file);
  }
}

export const getFilename = (url: string): string => {
  const split = new URL(url).pathname.split('/');
  return decodeURIComponent(split[split.length - 1]);
};
