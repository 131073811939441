// package: vtv1
// file: proto/vorto/vortointernal/internal_service.proto

var proto_vorto_internal_internal_service_pb = require("../../../proto/vorto/internal/internal_service_pb");
var proto_vorto_types_types_pb = require("../../../proto/vorto/types/types_pb");
var grpc = require("@improbable-eng/grpc-web").grpc;

var InternalApiService = (function () {
  function InternalApiService() {}
  InternalApiService.serviceName = "vtv1.InternalApiService";
  return InternalApiService;
}());

InternalApiService.AmIInternal = {
  methodName: "AmIInternal",
  service: InternalApiService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_types_types_pb.Empty,
  responseType: proto_vorto_internal_internal_service_pb.AmIInternalResponse
};

InternalApiService.ListAllAccounts = {
  methodName: "ListAllAccounts",
  service: InternalApiService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_types_types_pb.Empty,
  responseType: proto_vorto_internal_internal_service_pb.ListAllAccountsResponse
};

InternalApiService.InternalSwitchAccounts = {
  methodName: "InternalSwitchAccounts",
  service: InternalApiService,
  requestStream: false,
  responseStream: false,
  requestType: proto_vorto_types_types_pb.StringId,
  responseType: proto_vorto_types_types_pb.Empty
};

exports.InternalApiService = InternalApiService;

function InternalApiServiceClient(serviceHost, options) {
  this.serviceHost = serviceHost;
  this.options = options || {};
}

InternalApiServiceClient.prototype.amIInternal = function amIInternal(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(InternalApiService.AmIInternal, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

InternalApiServiceClient.prototype.listAllAccounts = function listAllAccounts(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(InternalApiService.ListAllAccounts, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

InternalApiServiceClient.prototype.internalSwitchAccounts = function internalSwitchAccounts(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(InternalApiService.InternalSwitchAccounts, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

exports.InternalApiServiceClient = InternalApiServiceClient;

