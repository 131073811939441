import { Injectable, OnDestroy } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable, combineLatest, Subject, BehaviorSubject, race, lastValueFrom } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { takeUntil, startWith, take, filter } from 'rxjs/operators';
import { InitializationService } from '../services/initialization.service';

@Injectable()
export class RoleGuard implements CanActivate, OnDestroy {
  private destroy$$ = new Subject<void>();

  constructor(private meta: InitializationService, private authService: AuthService, private router: Router) {}

  public ngOnDestroy() {
    this.destroy$$.next();
    this.destroy$$.unsubscribe();
  }

  public async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    const currentUser = await lastValueFrom(
      this.meta.myUserInfo$.pipe(
        filter((exists) => !!exists),
        take(1),
      ),
    );

    let userAccountFeatures: string[] = [];
    if (currentUser && Array.isArray(currentUser.accountFeaturesList)) {
      userAccountFeatures = currentUser.accountFeaturesList;
    }
    if (await this.authService.isLoggedIn$) {
      // consumer -> dashbaord
      // shipper -> dashboard
      // carrier -> carrier_shipments/shipments/pending_list

      switch (true) {
        case currentUser.isCpg:
          this.router.navigate(['/', 'vmi', 'inventories']);
          return true;
        case userAccountFeatures.includes('lite'):
          this.router.navigate(['/', 'lite', 'order']);
          return true;
        case userAccountFeatures.includes('node') && currentUser.isVirtual: // virtual supplier
          this.router.navigate(['/', 'outbound']);
          return true;
        case userAccountFeatures.includes('node'): // node
          this.router.navigate(['/', 'inbound']);
          return true;
        case userAccountFeatures.includes('edge'): // edge
          this.router.navigate(['/', 'carrier_shipments']);
          return true;
        default:
          this.router.navigate(['/', 'profile']);
          return false;
      }
    } else {
      return false;
    }
  }
}
