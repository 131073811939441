import { Component, OnInit, ChangeDetectionStrategy, HostBinding } from '@angular/core';
import { InitializationService } from '../singleton/services/initialization.service';
import { Observable } from 'rxjs';
import { MyUserInformationResponse } from '~proto/user/user_api_pb';
import { map } from 'rxjs/operators';

@Component({
  selector: 'vt-profile',
  templateUrl: './profile.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProfileComponent implements OnInit {
  @HostBinding('class.overflow-y-auto') public noScroll = true;
  public myUserInfo$: Observable<MyUserInformationResponse.AsObject>;
  public hasMultipleAccounts$: Observable<boolean>;
  constructor(public initializationService: InitializationService) {}

  public ngOnInit(): void {
    this.myUserInfo$ = this.initializationService.myUserInfo$;
    this.hasMultipleAccounts$ = this.initializationService.myAllowedAccounts$.pipe(
      map((allowedAccounts) => allowedAccounts?.length > 1),
    );
  }
}
