// source: proto/vorto/types/types.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

goog.exportSymbol('proto.vtv1.AccountIDName', null, global);
goog.exportSymbol('proto.vtv1.AccountSettingsResponse', null, global);
goog.exportSymbol('proto.vtv1.ActiveSite', null, global);
goog.exportSymbol('proto.vtv1.CostCenter', null, global);
goog.exportSymbol('proto.vtv1.CreateSiteCriteria', null, global);
goog.exportSymbol('proto.vtv1.DetentionType', null, global);
goog.exportSymbol('proto.vtv1.District', null, global);
goog.exportSymbol('proto.vtv1.DistrictWithIds', null, global);
goog.exportSymbol('proto.vtv1.DriverCertification', null, global);
goog.exportSymbol('proto.vtv1.EdgeContract', null, global);
goog.exportSymbol('proto.vtv1.EdgeCosts', null, global);
goog.exportSymbol('proto.vtv1.Empty', null, global);
goog.exportSymbol('proto.vtv1.File', null, global);
goog.exportSymbol('proto.vtv1.FileWithCanDelete', null, global);
goog.exportSymbol('proto.vtv1.FlatDemandSignal', null, global);
goog.exportSymbol('proto.vtv1.FlatDemandSignalRequest', null, global);
goog.exportSymbol('proto.vtv1.FlatDemandSignalResponse', null, global);
goog.exportSymbol('proto.vtv1.FlatProduct', null, global);
goog.exportSymbol('proto.vtv1.GraphPointInt', null, global);
goog.exportSymbol('proto.vtv1.IntId', null, global);
goog.exportSymbol('proto.vtv1.IntIdName', null, global);
goog.exportSymbol('proto.vtv1.LinehaulType', null, global);
goog.exportSymbol('proto.vtv1.MileageBracketCost', null, global);
goog.exportSymbol('proto.vtv1.NullableBool', null, global);
goog.exportSymbol('proto.vtv1.NullableDouble', null, global);
goog.exportSymbol('proto.vtv1.NullableInt32', null, global);
goog.exportSymbol('proto.vtv1.NullableInt64', null, global);
goog.exportSymbol('proto.vtv1.NullableString', null, global);
goog.exportSymbol('proto.vtv1.NullableTime', null, global);
goog.exportSymbol('proto.vtv1.OptimalLoadsStats', null, global);
goog.exportSymbol('proto.vtv1.OrderStatus', null, global);
goog.exportSymbol('proto.vtv1.P2PFile', null, global);
goog.exportSymbol('proto.vtv1.P2PPTEditEntry', null, global);
goog.exportSymbol('proto.vtv1.P2PPTEntry', null, global);
goog.exportSymbol('proto.vtv1.Point', null, global);
goog.exportSymbol('proto.vtv1.Product', null, global);
goog.exportSymbol('proto.vtv1.ProductFulfillment', null, global);
goog.exportSymbol('proto.vtv1.ProductGroup', null, global);
goog.exportSymbol('proto.vtv1.ProductionPlanGroup', null, global);
goog.exportSymbol('proto.vtv1.ProductionScheduleDay', null, global);
goog.exportSymbol('proto.vtv1.PurchaseOrder', null, global);
goog.exportSymbol('proto.vtv1.PurchaseOrderLineItem', null, global);
goog.exportSymbol('proto.vtv1.PurchaseOrderOrder', null, global);
goog.exportSymbol('proto.vtv1.Report', null, global);
goog.exportSymbol('proto.vtv1.SalesOrder', null, global);
goog.exportSymbol('proto.vtv1.SalesOrderLineItem', null, global);
goog.exportSymbol('proto.vtv1.Shipment', null, global);
goog.exportSymbol('proto.vtv1.ShipmentProgressStatus', null, global);
goog.exportSymbol('proto.vtv1.ShipmentStatus', null, global);
goog.exportSymbol('proto.vtv1.Site', null, global);
goog.exportSymbol('proto.vtv1.SiteGroup', null, global);
goog.exportSymbol('proto.vtv1.SiteLoadingSchedule', null, global);
goog.exportSymbol('proto.vtv1.SiteLoadingScheduleEntry', null, global);
goog.exportSymbol('proto.vtv1.SiteLoadingScheduleType', null, global);
goog.exportSymbol('proto.vtv1.SiteStatistics', null, global);
goog.exportSymbol('proto.vtv1.SiteSubInformationBoolean', null, global);
goog.exportSymbol('proto.vtv1.SlimAccount', null, global);
goog.exportSymbol('proto.vtv1.SlimEdgeContract', null, global);
goog.exportSymbol('proto.vtv1.SlimProduct', null, global);
goog.exportSymbol('proto.vtv1.SlimProductionBatch', null, global);
goog.exportSymbol('proto.vtv1.SlimProductionPlanEntry', null, global);
goog.exportSymbol('proto.vtv1.SlimPurchaseOrder', null, global);
goog.exportSymbol('proto.vtv1.SlimSite', null, global);
goog.exportSymbol('proto.vtv1.SlimSiteInventory', null, global);
goog.exportSymbol('proto.vtv1.SlimSiteWithLngLat', null, global);
goog.exportSymbol('proto.vtv1.SlimSiteWithPickupWindow', null, global);
goog.exportSymbol('proto.vtv1.SpotMarketRequestShipperType', null, global);
goog.exportSymbol('proto.vtv1.SpotMarketRequestType', null, global);
goog.exportSymbol('proto.vtv1.Stop', null, global);
goog.exportSymbol('proto.vtv1.StringId', null, global);
goog.exportSymbol('proto.vtv1.StringIdName', null, global);
goog.exportSymbol('proto.vtv1.Task', null, global);
goog.exportSymbol('proto.vtv1.TaskAction', null, global);
goog.exportSymbol('proto.vtv1.TrailerType', null, global);
goog.exportSymbol('proto.vtv1.Unit', null, global);
goog.exportSymbol('proto.vtv1.UnmappedProduct', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.Empty = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.Empty, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.Empty.displayName = 'proto.vtv1.Empty';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.NullableString = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.NullableString, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.NullableString.displayName = 'proto.vtv1.NullableString';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.NullableInt64 = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.NullableInt64, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.NullableInt64.displayName = 'proto.vtv1.NullableInt64';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.NullableInt32 = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.NullableInt32, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.NullableInt32.displayName = 'proto.vtv1.NullableInt32';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.NullableDouble = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.NullableDouble, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.NullableDouble.displayName = 'proto.vtv1.NullableDouble';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.NullableTime = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.NullableTime, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.NullableTime.displayName = 'proto.vtv1.NullableTime';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.NullableBool = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.NullableBool, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.NullableBool.displayName = 'proto.vtv1.NullableBool';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.AccountIDName = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.AccountIDName, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.AccountIDName.displayName = 'proto.vtv1.AccountIDName';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.MileageBracketCost = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.MileageBracketCost, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.MileageBracketCost.displayName = 'proto.vtv1.MileageBracketCost';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.EdgeContract = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.EdgeContract.repeatedFields_, null);
};
goog.inherits(proto.vtv1.EdgeContract, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.EdgeContract.displayName = 'proto.vtv1.EdgeContract';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.P2PPTEntry = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.P2PPTEntry, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.P2PPTEntry.displayName = 'proto.vtv1.P2PPTEntry';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.P2PPTEditEntry = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.P2PPTEditEntry, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.P2PPTEditEntry.displayName = 'proto.vtv1.P2PPTEditEntry';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.P2PFile = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.P2PFile, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.P2PFile.displayName = 'proto.vtv1.P2PFile';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.EdgeCosts = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.EdgeCosts, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.EdgeCosts.displayName = 'proto.vtv1.EdgeCosts';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.SlimEdgeContract = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.SlimEdgeContract, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.SlimEdgeContract.displayName = 'proto.vtv1.SlimEdgeContract';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.IntId = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.IntId, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.IntId.displayName = 'proto.vtv1.IntId';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.StringId = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.StringId, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.StringId.displayName = 'proto.vtv1.StringId';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.StringIdName = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.StringIdName, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.StringIdName.displayName = 'proto.vtv1.StringIdName';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.IntIdName = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.IntIdName, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.IntIdName.displayName = 'proto.vtv1.IntIdName';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.SiteStatistics = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.SiteStatistics.repeatedFields_, null);
};
goog.inherits(proto.vtv1.SiteStatistics, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.SiteStatistics.displayName = 'proto.vtv1.SiteStatistics';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.SlimSiteInventory = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.SlimSiteInventory, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.SlimSiteInventory.displayName = 'proto.vtv1.SlimSiteInventory';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.ProductionPlanGroup = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.ProductionPlanGroup.repeatedFields_, null);
};
goog.inherits(proto.vtv1.ProductionPlanGroup, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.ProductionPlanGroup.displayName = 'proto.vtv1.ProductionPlanGroup';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.ProductionScheduleDay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.ProductionScheduleDay.repeatedFields_, null);
};
goog.inherits(proto.vtv1.ProductionScheduleDay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.ProductionScheduleDay.displayName = 'proto.vtv1.ProductionScheduleDay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.SlimProductionBatch = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.SlimProductionBatch, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.SlimProductionBatch.displayName = 'proto.vtv1.SlimProductionBatch';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.SlimProductionPlanEntry = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.SlimProductionPlanEntry, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.SlimProductionPlanEntry.displayName = 'proto.vtv1.SlimProductionPlanEntry';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.Site = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.Site.repeatedFields_, null);
};
goog.inherits(proto.vtv1.Site, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.Site.displayName = 'proto.vtv1.Site';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.District = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.District, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.District.displayName = 'proto.vtv1.District';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.DistrictWithIds = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.DistrictWithIds, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.DistrictWithIds.displayName = 'proto.vtv1.DistrictWithIds';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.SiteSubInformationBoolean = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.SiteSubInformationBoolean, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.SiteSubInformationBoolean.displayName = 'proto.vtv1.SiteSubInformationBoolean';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.SlimSite = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.SlimSite, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.SlimSite.displayName = 'proto.vtv1.SlimSite';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.SlimSiteWithLngLat = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.SlimSiteWithLngLat, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.SlimSiteWithLngLat.displayName = 'proto.vtv1.SlimSiteWithLngLat';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.SlimSiteWithPickupWindow = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.SlimSiteWithPickupWindow, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.SlimSiteWithPickupWindow.displayName = 'proto.vtv1.SlimSiteWithPickupWindow';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.ActiveSite = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.ActiveSite.repeatedFields_, null);
};
goog.inherits(proto.vtv1.ActiveSite, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.ActiveSite.displayName = 'proto.vtv1.ActiveSite';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.OptimalLoadsStats = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.OptimalLoadsStats, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.OptimalLoadsStats.displayName = 'proto.vtv1.OptimalLoadsStats';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.SiteGroup = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.SiteGroup.repeatedFields_, null);
};
goog.inherits(proto.vtv1.SiteGroup, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.SiteGroup.displayName = 'proto.vtv1.SiteGroup';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.Task = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.Task, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.Task.displayName = 'proto.vtv1.Task';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.Stop = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.Stop.repeatedFields_, null);
};
goog.inherits(proto.vtv1.Stop, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.Stop.displayName = 'proto.vtv1.Stop';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.Shipment = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.Shipment.repeatedFields_, null);
};
goog.inherits(proto.vtv1.Shipment, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.Shipment.displayName = 'proto.vtv1.Shipment';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.FlatProduct = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.FlatProduct, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.FlatProduct.displayName = 'proto.vtv1.FlatProduct';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.PurchaseOrderLineItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.PurchaseOrderLineItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.PurchaseOrderLineItem.displayName = 'proto.vtv1.PurchaseOrderLineItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.PurchaseOrder = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.PurchaseOrder.repeatedFields_, null);
};
goog.inherits(proto.vtv1.PurchaseOrder, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.PurchaseOrder.displayName = 'proto.vtv1.PurchaseOrder';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.PurchaseOrderOrder = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.PurchaseOrderOrder, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.PurchaseOrderOrder.displayName = 'proto.vtv1.PurchaseOrderOrder';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.SlimPurchaseOrder = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.SlimPurchaseOrder.repeatedFields_, null);
};
goog.inherits(proto.vtv1.SlimPurchaseOrder, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.SlimPurchaseOrder.displayName = 'proto.vtv1.SlimPurchaseOrder';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.UnmappedProduct = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.UnmappedProduct, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.UnmappedProduct.displayName = 'proto.vtv1.UnmappedProduct';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.FlatDemandSignal = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.FlatDemandSignal, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.FlatDemandSignal.displayName = 'proto.vtv1.FlatDemandSignal';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.FlatDemandSignalResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.FlatDemandSignalResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.FlatDemandSignalResponse.displayName = 'proto.vtv1.FlatDemandSignalResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.FlatDemandSignalRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.FlatDemandSignalRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.FlatDemandSignalRequest.displayName = 'proto.vtv1.FlatDemandSignalRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.SlimAccount = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.SlimAccount, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.SlimAccount.displayName = 'proto.vtv1.SlimAccount';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.SiteLoadingSchedule = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.SiteLoadingSchedule.repeatedFields_, null);
};
goog.inherits(proto.vtv1.SiteLoadingSchedule, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.SiteLoadingSchedule.displayName = 'proto.vtv1.SiteLoadingSchedule';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.SiteLoadingScheduleEntry = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.SiteLoadingScheduleEntry, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.SiteLoadingScheduleEntry.displayName = 'proto.vtv1.SiteLoadingScheduleEntry';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.Unit = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.Unit, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.Unit.displayName = 'proto.vtv1.Unit';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.Product = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.Product, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.Product.displayName = 'proto.vtv1.Product';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.ProductFulfillment = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.ProductFulfillment.repeatedFields_, null);
};
goog.inherits(proto.vtv1.ProductFulfillment, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.ProductFulfillment.displayName = 'proto.vtv1.ProductFulfillment';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.ProductGroup = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.ProductGroup, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.ProductGroup.displayName = 'proto.vtv1.ProductGroup';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.Report = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.Report, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.Report.displayName = 'proto.vtv1.Report';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.CreateSiteCriteria = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.CreateSiteCriteria.repeatedFields_, null);
};
goog.inherits(proto.vtv1.CreateSiteCriteria, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.CreateSiteCriteria.displayName = 'proto.vtv1.CreateSiteCriteria';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.AccountSettingsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.AccountSettingsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.AccountSettingsResponse.displayName = 'proto.vtv1.AccountSettingsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.GraphPointInt = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.GraphPointInt, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.GraphPointInt.displayName = 'proto.vtv1.GraphPointInt';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.TrailerType = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.TrailerType, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.TrailerType.displayName = 'proto.vtv1.TrailerType';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.File = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.File, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.File.displayName = 'proto.vtv1.File';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.FileWithCanDelete = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.FileWithCanDelete, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.FileWithCanDelete.displayName = 'proto.vtv1.FileWithCanDelete';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.DriverCertification = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.DriverCertification, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.DriverCertification.displayName = 'proto.vtv1.DriverCertification';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.SlimProduct = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.SlimProduct, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.SlimProduct.displayName = 'proto.vtv1.SlimProduct';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.Point = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.Point, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.Point.displayName = 'proto.vtv1.Point';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.CostCenter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.CostCenter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.CostCenter.displayName = 'proto.vtv1.CostCenter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.SalesOrder = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.SalesOrder.repeatedFields_, null);
};
goog.inherits(proto.vtv1.SalesOrder, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.SalesOrder.displayName = 'proto.vtv1.SalesOrder';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.SalesOrderLineItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.SalesOrderLineItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.SalesOrderLineItem.displayName = 'proto.vtv1.SalesOrderLineItem';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.Empty.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.Empty.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.Empty} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.Empty.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.Empty}
 */
proto.vtv1.Empty.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.Empty;
  return proto.vtv1.Empty.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.Empty} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.Empty}
 */
proto.vtv1.Empty.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.Empty.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.Empty.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.Empty} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.Empty.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.NullableString.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.NullableString.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.NullableString} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.NullableString.toObject = function(includeInstance, msg) {
  var f, obj = {
    valid: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    str: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.NullableString}
 */
proto.vtv1.NullableString.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.NullableString;
  return proto.vtv1.NullableString.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.NullableString} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.NullableString}
 */
proto.vtv1.NullableString.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setValid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setStr(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.NullableString.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.NullableString.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.NullableString} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.NullableString.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getValid();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getStr();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional bool valid = 1;
 * @return {boolean}
 */
proto.vtv1.NullableString.prototype.getValid = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vtv1.NullableString} returns this
 */
proto.vtv1.NullableString.prototype.setValid = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string str = 2;
 * @return {string}
 */
proto.vtv1.NullableString.prototype.getStr = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.NullableString} returns this
 */
proto.vtv1.NullableString.prototype.setStr = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.NullableInt64.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.NullableInt64.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.NullableInt64} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.NullableInt64.toObject = function(includeInstance, msg) {
  var f, obj = {
    valid: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    pb_int: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.NullableInt64}
 */
proto.vtv1.NullableInt64.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.NullableInt64;
  return proto.vtv1.NullableInt64.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.NullableInt64} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.NullableInt64}
 */
proto.vtv1.NullableInt64.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setValid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setInt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.NullableInt64.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.NullableInt64.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.NullableInt64} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.NullableInt64.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getValid();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getInt();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * optional bool valid = 1;
 * @return {boolean}
 */
proto.vtv1.NullableInt64.prototype.getValid = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vtv1.NullableInt64} returns this
 */
proto.vtv1.NullableInt64.prototype.setValid = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional int64 int = 2;
 * @return {number}
 */
proto.vtv1.NullableInt64.prototype.getInt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.NullableInt64} returns this
 */
proto.vtv1.NullableInt64.prototype.setInt = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.NullableInt32.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.NullableInt32.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.NullableInt32} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.NullableInt32.toObject = function(includeInstance, msg) {
  var f, obj = {
    valid: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    pb_int: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.NullableInt32}
 */
proto.vtv1.NullableInt32.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.NullableInt32;
  return proto.vtv1.NullableInt32.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.NullableInt32} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.NullableInt32}
 */
proto.vtv1.NullableInt32.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setValid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setInt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.NullableInt32.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.NullableInt32.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.NullableInt32} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.NullableInt32.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getValid();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getInt();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional bool valid = 1;
 * @return {boolean}
 */
proto.vtv1.NullableInt32.prototype.getValid = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vtv1.NullableInt32} returns this
 */
proto.vtv1.NullableInt32.prototype.setValid = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional int32 int = 2;
 * @return {number}
 */
proto.vtv1.NullableInt32.prototype.getInt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.NullableInt32} returns this
 */
proto.vtv1.NullableInt32.prototype.setInt = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.NullableDouble.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.NullableDouble.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.NullableDouble} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.NullableDouble.toObject = function(includeInstance, msg) {
  var f, obj = {
    valid: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    pb_double: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.NullableDouble}
 */
proto.vtv1.NullableDouble.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.NullableDouble;
  return proto.vtv1.NullableDouble.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.NullableDouble} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.NullableDouble}
 */
proto.vtv1.NullableDouble.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setValid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setDouble(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.NullableDouble.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.NullableDouble.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.NullableDouble} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.NullableDouble.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getValid();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getDouble();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
};


/**
 * optional bool valid = 1;
 * @return {boolean}
 */
proto.vtv1.NullableDouble.prototype.getValid = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vtv1.NullableDouble} returns this
 */
proto.vtv1.NullableDouble.prototype.setValid = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional double double = 2;
 * @return {number}
 */
proto.vtv1.NullableDouble.prototype.getDouble = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.NullableDouble} returns this
 */
proto.vtv1.NullableDouble.prototype.setDouble = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.NullableTime.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.NullableTime.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.NullableTime} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.NullableTime.toObject = function(includeInstance, msg) {
  var f, obj = {
    valid: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    time: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.NullableTime}
 */
proto.vtv1.NullableTime.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.NullableTime;
  return proto.vtv1.NullableTime.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.NullableTime} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.NullableTime}
 */
proto.vtv1.NullableTime.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setValid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.NullableTime.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.NullableTime.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.NullableTime} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.NullableTime.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getValid();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getTime();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional bool valid = 1;
 * @return {boolean}
 */
proto.vtv1.NullableTime.prototype.getValid = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vtv1.NullableTime} returns this
 */
proto.vtv1.NullableTime.prototype.setValid = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string time = 2;
 * @return {string}
 */
proto.vtv1.NullableTime.prototype.getTime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.NullableTime} returns this
 */
proto.vtv1.NullableTime.prototype.setTime = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.NullableBool.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.NullableBool.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.NullableBool} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.NullableBool.toObject = function(includeInstance, msg) {
  var f, obj = {
    valid: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    bool: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.NullableBool}
 */
proto.vtv1.NullableBool.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.NullableBool;
  return proto.vtv1.NullableBool.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.NullableBool} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.NullableBool}
 */
proto.vtv1.NullableBool.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setValid(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setBool(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.NullableBool.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.NullableBool.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.NullableBool} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.NullableBool.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getValid();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getBool();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional bool valid = 1;
 * @return {boolean}
 */
proto.vtv1.NullableBool.prototype.getValid = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vtv1.NullableBool} returns this
 */
proto.vtv1.NullableBool.prototype.setValid = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional bool bool = 2;
 * @return {boolean}
 */
proto.vtv1.NullableBool.prototype.getBool = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vtv1.NullableBool} returns this
 */
proto.vtv1.NullableBool.prototype.setBool = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.AccountIDName.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.AccountIDName.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.AccountIDName} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.AccountIDName.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.AccountIDName}
 */
proto.vtv1.AccountIDName.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.AccountIDName;
  return proto.vtv1.AccountIDName.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.AccountIDName} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.AccountIDName}
 */
proto.vtv1.AccountIDName.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.AccountIDName.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.AccountIDName.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.AccountIDName} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.AccountIDName.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.vtv1.AccountIDName.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.AccountIDName} returns this
 */
proto.vtv1.AccountIDName.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.vtv1.AccountIDName.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.AccountIDName} returns this
 */
proto.vtv1.AccountIDName.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.MileageBracketCost.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.MileageBracketCost.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.MileageBracketCost} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.MileageBracketCost.toObject = function(includeInstance, msg) {
  var f, obj = {
    lowerMileage: jspb.Message.getFieldWithDefault(msg, 1, 0),
    cents: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.MileageBracketCost}
 */
proto.vtv1.MileageBracketCost.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.MileageBracketCost;
  return proto.vtv1.MileageBracketCost.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.MileageBracketCost} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.MileageBracketCost}
 */
proto.vtv1.MileageBracketCost.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLowerMileage(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCents(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.MileageBracketCost.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.MileageBracketCost.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.MileageBracketCost} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.MileageBracketCost.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLowerMileage();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getCents();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * optional int64 lower_mileage = 1;
 * @return {number}
 */
proto.vtv1.MileageBracketCost.prototype.getLowerMileage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.MileageBracketCost} returns this
 */
proto.vtv1.MileageBracketCost.prototype.setLowerMileage = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 cents = 2;
 * @return {number}
 */
proto.vtv1.MileageBracketCost.prototype.getCents = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.MileageBracketCost} returns this
 */
proto.vtv1.MileageBracketCost.prototype.setCents = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.EdgeContract.repeatedFields_ = [10,20,22,23];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.EdgeContract.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.EdgeContract.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.EdgeContract} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.EdgeContract.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    nodeId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    edgeId: (f = msg.getEdgeId()) && proto.vtv1.NullableString.toObject(includeInstance, f),
    type: jspb.Message.getFieldWithDefault(msg, 5, 0),
    costIsPerTon: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    startsAt: jspb.Message.getFieldWithDefault(msg, 7, ""),
    expiresAt: (f = msg.getExpiresAt()) && proto.vtv1.NullableTime.toObject(includeInstance, f),
    costPerMile: jspb.Message.getFieldWithDefault(msg, 9, 0),
    costPerMileageBracketList: jspb.Message.toObjectList(msg.getCostPerMileageBracketList(),
    proto.vtv1.MileageBracketCost.toObject, includeInstance),
    isSpotContract: jspb.Message.getBooleanFieldWithDefault(msg, 11, false),
    closedAt: (f = msg.getClosedAt()) && proto.vtv1.NullableTime.toObject(includeInstance, f),
    locked: jspb.Message.getBooleanFieldWithDefault(msg, 13, false),
    version: jspb.Message.getFieldWithDefault(msg, 14, 0),
    createdBy: jspb.Message.getFieldWithDefault(msg, 15, ""),
    originalContractId: (f = msg.getOriginalContractId()) && proto.vtv1.NullableString.toObject(includeInstance, f),
    createdAt: jspb.Message.getFieldWithDefault(msg, 17, ""),
    changedBy: (f = msg.getChangedBy()) && proto.vtv1.NullableString.toObject(includeInstance, f),
    createdByUserName: jspb.Message.getFieldWithDefault(msg, 19, ""),
    trailerTypesList: jspb.Message.toObjectList(msg.getTrailerTypesList(),
    proto.vtv1.IntIdName.toObject, includeInstance),
    p2pFile: (f = msg.getP2pFile()) && proto.vtv1.P2PFile.toObject(includeInstance, f),
    regionsList: jspb.Message.toObjectList(msg.getRegionsList(),
    proto.vtv1.IntIdName.toObject, includeInstance),
    p2pPerTonList: jspb.Message.toObjectList(msg.getP2pPerTonList(),
    proto.vtv1.P2PPTEntry.toObject, includeInstance),
    allowCarrierManualComplete: jspb.Message.getBooleanFieldWithDefault(msg, 24, false),
    costPerHour: (f = msg.getCostPerHour()) && proto.vtv1.NullableInt64.toObject(includeInstance, f),
    minimumPerHourTotalCost: (f = msg.getMinimumPerHourTotalCost()) && proto.vtv1.NullableInt64.toObject(includeInstance, f),
    totalDurationMinutes: (f = msg.getTotalDurationMinutes()) && proto.vtv1.NullableInt64.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.EdgeContract}
 */
proto.vtv1.EdgeContract.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.EdgeContract;
  return proto.vtv1.EdgeContract.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.EdgeContract} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.EdgeContract}
 */
proto.vtv1.EdgeContract.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setNodeId(value);
      break;
    case 4:
      var value = new proto.vtv1.NullableString;
      reader.readMessage(value,proto.vtv1.NullableString.deserializeBinaryFromReader);
      msg.setEdgeId(value);
      break;
    case 5:
      var value = /** @type {!proto.vtv1.LinehaulType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCostIsPerTon(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setStartsAt(value);
      break;
    case 8:
      var value = new proto.vtv1.NullableTime;
      reader.readMessage(value,proto.vtv1.NullableTime.deserializeBinaryFromReader);
      msg.setExpiresAt(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCostPerMile(value);
      break;
    case 10:
      var value = new proto.vtv1.MileageBracketCost;
      reader.readMessage(value,proto.vtv1.MileageBracketCost.deserializeBinaryFromReader);
      msg.addCostPerMileageBracket(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSpotContract(value);
      break;
    case 12:
      var value = new proto.vtv1.NullableTime;
      reader.readMessage(value,proto.vtv1.NullableTime.deserializeBinaryFromReader);
      msg.setClosedAt(value);
      break;
    case 13:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setLocked(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setVersion(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedBy(value);
      break;
    case 16:
      var value = new proto.vtv1.NullableString;
      reader.readMessage(value,proto.vtv1.NullableString.deserializeBinaryFromReader);
      msg.setOriginalContractId(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedAt(value);
      break;
    case 18:
      var value = new proto.vtv1.NullableString;
      reader.readMessage(value,proto.vtv1.NullableString.deserializeBinaryFromReader);
      msg.setChangedBy(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedByUserName(value);
      break;
    case 20:
      var value = new proto.vtv1.IntIdName;
      reader.readMessage(value,proto.vtv1.IntIdName.deserializeBinaryFromReader);
      msg.addTrailerTypes(value);
      break;
    case 21:
      var value = new proto.vtv1.P2PFile;
      reader.readMessage(value,proto.vtv1.P2PFile.deserializeBinaryFromReader);
      msg.setP2pFile(value);
      break;
    case 22:
      var value = new proto.vtv1.IntIdName;
      reader.readMessage(value,proto.vtv1.IntIdName.deserializeBinaryFromReader);
      msg.addRegions(value);
      break;
    case 23:
      var value = new proto.vtv1.P2PPTEntry;
      reader.readMessage(value,proto.vtv1.P2PPTEntry.deserializeBinaryFromReader);
      msg.addP2pPerTon(value);
      break;
    case 24:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAllowCarrierManualComplete(value);
      break;
    case 25:
      var value = new proto.vtv1.NullableInt64;
      reader.readMessage(value,proto.vtv1.NullableInt64.deserializeBinaryFromReader);
      msg.setCostPerHour(value);
      break;
    case 26:
      var value = new proto.vtv1.NullableInt64;
      reader.readMessage(value,proto.vtv1.NullableInt64.deserializeBinaryFromReader);
      msg.setMinimumPerHourTotalCost(value);
      break;
    case 27:
      var value = new proto.vtv1.NullableInt64;
      reader.readMessage(value,proto.vtv1.NullableInt64.deserializeBinaryFromReader);
      msg.setTotalDurationMinutes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.EdgeContract.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.EdgeContract.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.EdgeContract} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.EdgeContract.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getNodeId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getEdgeId();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.vtv1.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getCostIsPerTon();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getStartsAt();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getExpiresAt();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.vtv1.NullableTime.serializeBinaryToWriter
    );
  }
  f = message.getCostPerMile();
  if (f !== 0) {
    writer.writeInt64(
      9,
      f
    );
  }
  f = message.getCostPerMileageBracketList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      10,
      f,
      proto.vtv1.MileageBracketCost.serializeBinaryToWriter
    );
  }
  f = message.getIsSpotContract();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
  f = message.getClosedAt();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      proto.vtv1.NullableTime.serializeBinaryToWriter
    );
  }
  f = message.getLocked();
  if (f) {
    writer.writeBool(
      13,
      f
    );
  }
  f = message.getVersion();
  if (f !== 0) {
    writer.writeInt64(
      14,
      f
    );
  }
  f = message.getCreatedBy();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getOriginalContractId();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      proto.vtv1.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getCreatedAt();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getChangedBy();
  if (f != null) {
    writer.writeMessage(
      18,
      f,
      proto.vtv1.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getCreatedByUserName();
  if (f.length > 0) {
    writer.writeString(
      19,
      f
    );
  }
  f = message.getTrailerTypesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      20,
      f,
      proto.vtv1.IntIdName.serializeBinaryToWriter
    );
  }
  f = message.getP2pFile();
  if (f != null) {
    writer.writeMessage(
      21,
      f,
      proto.vtv1.P2PFile.serializeBinaryToWriter
    );
  }
  f = message.getRegionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      22,
      f,
      proto.vtv1.IntIdName.serializeBinaryToWriter
    );
  }
  f = message.getP2pPerTonList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      23,
      f,
      proto.vtv1.P2PPTEntry.serializeBinaryToWriter
    );
  }
  f = message.getAllowCarrierManualComplete();
  if (f) {
    writer.writeBool(
      24,
      f
    );
  }
  f = message.getCostPerHour();
  if (f != null) {
    writer.writeMessage(
      25,
      f,
      proto.vtv1.NullableInt64.serializeBinaryToWriter
    );
  }
  f = message.getMinimumPerHourTotalCost();
  if (f != null) {
    writer.writeMessage(
      26,
      f,
      proto.vtv1.NullableInt64.serializeBinaryToWriter
    );
  }
  f = message.getTotalDurationMinutes();
  if (f != null) {
    writer.writeMessage(
      27,
      f,
      proto.vtv1.NullableInt64.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.vtv1.EdgeContract.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.EdgeContract} returns this
 */
proto.vtv1.EdgeContract.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.vtv1.EdgeContract.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.EdgeContract} returns this
 */
proto.vtv1.EdgeContract.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string node_id = 3;
 * @return {string}
 */
proto.vtv1.EdgeContract.prototype.getNodeId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.EdgeContract} returns this
 */
proto.vtv1.EdgeContract.prototype.setNodeId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional NullableString edge_id = 4;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.EdgeContract.prototype.getEdgeId = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto.vtv1.NullableString, 4));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.EdgeContract} returns this
*/
proto.vtv1.EdgeContract.prototype.setEdgeId = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.EdgeContract} returns this
 */
proto.vtv1.EdgeContract.prototype.clearEdgeId = function() {
  return this.setEdgeId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.EdgeContract.prototype.hasEdgeId = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional LinehaulType type = 5;
 * @return {!proto.vtv1.LinehaulType}
 */
proto.vtv1.EdgeContract.prototype.getType = function() {
  return /** @type {!proto.vtv1.LinehaulType} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.vtv1.LinehaulType} value
 * @return {!proto.vtv1.EdgeContract} returns this
 */
proto.vtv1.EdgeContract.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional bool cost_is_per_ton = 6;
 * @return {boolean}
 */
proto.vtv1.EdgeContract.prototype.getCostIsPerTon = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vtv1.EdgeContract} returns this
 */
proto.vtv1.EdgeContract.prototype.setCostIsPerTon = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional string starts_at = 7;
 * @return {string}
 */
proto.vtv1.EdgeContract.prototype.getStartsAt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.EdgeContract} returns this
 */
proto.vtv1.EdgeContract.prototype.setStartsAt = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional NullableTime expires_at = 8;
 * @return {?proto.vtv1.NullableTime}
 */
proto.vtv1.EdgeContract.prototype.getExpiresAt = function() {
  return /** @type{?proto.vtv1.NullableTime} */ (
    jspb.Message.getWrapperField(this, proto.vtv1.NullableTime, 8));
};


/**
 * @param {?proto.vtv1.NullableTime|undefined} value
 * @return {!proto.vtv1.EdgeContract} returns this
*/
proto.vtv1.EdgeContract.prototype.setExpiresAt = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.EdgeContract} returns this
 */
proto.vtv1.EdgeContract.prototype.clearExpiresAt = function() {
  return this.setExpiresAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.EdgeContract.prototype.hasExpiresAt = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional int64 cost_per_mile = 9;
 * @return {number}
 */
proto.vtv1.EdgeContract.prototype.getCostPerMile = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.EdgeContract} returns this
 */
proto.vtv1.EdgeContract.prototype.setCostPerMile = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * repeated MileageBracketCost cost_per_mileage_bracket = 10;
 * @return {!Array<!proto.vtv1.MileageBracketCost>}
 */
proto.vtv1.EdgeContract.prototype.getCostPerMileageBracketList = function() {
  return /** @type{!Array<!proto.vtv1.MileageBracketCost>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vtv1.MileageBracketCost, 10));
};


/**
 * @param {!Array<!proto.vtv1.MileageBracketCost>} value
 * @return {!proto.vtv1.EdgeContract} returns this
*/
proto.vtv1.EdgeContract.prototype.setCostPerMileageBracketList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 10, value);
};


/**
 * @param {!proto.vtv1.MileageBracketCost=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.MileageBracketCost}
 */
proto.vtv1.EdgeContract.prototype.addCostPerMileageBracket = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 10, opt_value, proto.vtv1.MileageBracketCost, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.EdgeContract} returns this
 */
proto.vtv1.EdgeContract.prototype.clearCostPerMileageBracketList = function() {
  return this.setCostPerMileageBracketList([]);
};


/**
 * optional bool is_spot_contract = 11;
 * @return {boolean}
 */
proto.vtv1.EdgeContract.prototype.getIsSpotContract = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vtv1.EdgeContract} returns this
 */
proto.vtv1.EdgeContract.prototype.setIsSpotContract = function(value) {
  return jspb.Message.setProto3BooleanField(this, 11, value);
};


/**
 * optional NullableTime closed_at = 12;
 * @return {?proto.vtv1.NullableTime}
 */
proto.vtv1.EdgeContract.prototype.getClosedAt = function() {
  return /** @type{?proto.vtv1.NullableTime} */ (
    jspb.Message.getWrapperField(this, proto.vtv1.NullableTime, 12));
};


/**
 * @param {?proto.vtv1.NullableTime|undefined} value
 * @return {!proto.vtv1.EdgeContract} returns this
*/
proto.vtv1.EdgeContract.prototype.setClosedAt = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.EdgeContract} returns this
 */
proto.vtv1.EdgeContract.prototype.clearClosedAt = function() {
  return this.setClosedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.EdgeContract.prototype.hasClosedAt = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional bool locked = 13;
 * @return {boolean}
 */
proto.vtv1.EdgeContract.prototype.getLocked = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 13, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vtv1.EdgeContract} returns this
 */
proto.vtv1.EdgeContract.prototype.setLocked = function(value) {
  return jspb.Message.setProto3BooleanField(this, 13, value);
};


/**
 * optional int64 version = 14;
 * @return {number}
 */
proto.vtv1.EdgeContract.prototype.getVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.EdgeContract} returns this
 */
proto.vtv1.EdgeContract.prototype.setVersion = function(value) {
  return jspb.Message.setProto3IntField(this, 14, value);
};


/**
 * optional string created_by = 15;
 * @return {string}
 */
proto.vtv1.EdgeContract.prototype.getCreatedBy = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.EdgeContract} returns this
 */
proto.vtv1.EdgeContract.prototype.setCreatedBy = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional NullableString original_contract_id = 16;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.EdgeContract.prototype.getOriginalContractId = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto.vtv1.NullableString, 16));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.EdgeContract} returns this
*/
proto.vtv1.EdgeContract.prototype.setOriginalContractId = function(value) {
  return jspb.Message.setWrapperField(this, 16, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.EdgeContract} returns this
 */
proto.vtv1.EdgeContract.prototype.clearOriginalContractId = function() {
  return this.setOriginalContractId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.EdgeContract.prototype.hasOriginalContractId = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * optional string created_at = 17;
 * @return {string}
 */
proto.vtv1.EdgeContract.prototype.getCreatedAt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.EdgeContract} returns this
 */
proto.vtv1.EdgeContract.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional NullableString changed_by = 18;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.EdgeContract.prototype.getChangedBy = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto.vtv1.NullableString, 18));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.EdgeContract} returns this
*/
proto.vtv1.EdgeContract.prototype.setChangedBy = function(value) {
  return jspb.Message.setWrapperField(this, 18, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.EdgeContract} returns this
 */
proto.vtv1.EdgeContract.prototype.clearChangedBy = function() {
  return this.setChangedBy(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.EdgeContract.prototype.hasChangedBy = function() {
  return jspb.Message.getField(this, 18) != null;
};


/**
 * optional string created_by_user_name = 19;
 * @return {string}
 */
proto.vtv1.EdgeContract.prototype.getCreatedByUserName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.EdgeContract} returns this
 */
proto.vtv1.EdgeContract.prototype.setCreatedByUserName = function(value) {
  return jspb.Message.setProto3StringField(this, 19, value);
};


/**
 * repeated IntIdName trailer_types = 20;
 * @return {!Array<!proto.vtv1.IntIdName>}
 */
proto.vtv1.EdgeContract.prototype.getTrailerTypesList = function() {
  return /** @type{!Array<!proto.vtv1.IntIdName>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vtv1.IntIdName, 20));
};


/**
 * @param {!Array<!proto.vtv1.IntIdName>} value
 * @return {!proto.vtv1.EdgeContract} returns this
*/
proto.vtv1.EdgeContract.prototype.setTrailerTypesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 20, value);
};


/**
 * @param {!proto.vtv1.IntIdName=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.IntIdName}
 */
proto.vtv1.EdgeContract.prototype.addTrailerTypes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 20, opt_value, proto.vtv1.IntIdName, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.EdgeContract} returns this
 */
proto.vtv1.EdgeContract.prototype.clearTrailerTypesList = function() {
  return this.setTrailerTypesList([]);
};


/**
 * optional P2PFile p2p_file = 21;
 * @return {?proto.vtv1.P2PFile}
 */
proto.vtv1.EdgeContract.prototype.getP2pFile = function() {
  return /** @type{?proto.vtv1.P2PFile} */ (
    jspb.Message.getWrapperField(this, proto.vtv1.P2PFile, 21));
};


/**
 * @param {?proto.vtv1.P2PFile|undefined} value
 * @return {!proto.vtv1.EdgeContract} returns this
*/
proto.vtv1.EdgeContract.prototype.setP2pFile = function(value) {
  return jspb.Message.setWrapperField(this, 21, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.EdgeContract} returns this
 */
proto.vtv1.EdgeContract.prototype.clearP2pFile = function() {
  return this.setP2pFile(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.EdgeContract.prototype.hasP2pFile = function() {
  return jspb.Message.getField(this, 21) != null;
};


/**
 * repeated IntIdName regions = 22;
 * @return {!Array<!proto.vtv1.IntIdName>}
 */
proto.vtv1.EdgeContract.prototype.getRegionsList = function() {
  return /** @type{!Array<!proto.vtv1.IntIdName>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vtv1.IntIdName, 22));
};


/**
 * @param {!Array<!proto.vtv1.IntIdName>} value
 * @return {!proto.vtv1.EdgeContract} returns this
*/
proto.vtv1.EdgeContract.prototype.setRegionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 22, value);
};


/**
 * @param {!proto.vtv1.IntIdName=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.IntIdName}
 */
proto.vtv1.EdgeContract.prototype.addRegions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 22, opt_value, proto.vtv1.IntIdName, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.EdgeContract} returns this
 */
proto.vtv1.EdgeContract.prototype.clearRegionsList = function() {
  return this.setRegionsList([]);
};


/**
 * repeated P2PPTEntry p2p_per_ton = 23;
 * @return {!Array<!proto.vtv1.P2PPTEntry>}
 */
proto.vtv1.EdgeContract.prototype.getP2pPerTonList = function() {
  return /** @type{!Array<!proto.vtv1.P2PPTEntry>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vtv1.P2PPTEntry, 23));
};


/**
 * @param {!Array<!proto.vtv1.P2PPTEntry>} value
 * @return {!proto.vtv1.EdgeContract} returns this
*/
proto.vtv1.EdgeContract.prototype.setP2pPerTonList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 23, value);
};


/**
 * @param {!proto.vtv1.P2PPTEntry=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.P2PPTEntry}
 */
proto.vtv1.EdgeContract.prototype.addP2pPerTon = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 23, opt_value, proto.vtv1.P2PPTEntry, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.EdgeContract} returns this
 */
proto.vtv1.EdgeContract.prototype.clearP2pPerTonList = function() {
  return this.setP2pPerTonList([]);
};


/**
 * optional bool allow_carrier_manual_complete = 24;
 * @return {boolean}
 */
proto.vtv1.EdgeContract.prototype.getAllowCarrierManualComplete = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 24, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vtv1.EdgeContract} returns this
 */
proto.vtv1.EdgeContract.prototype.setAllowCarrierManualComplete = function(value) {
  return jspb.Message.setProto3BooleanField(this, 24, value);
};


/**
 * optional NullableInt64 cost_per_hour = 25;
 * @return {?proto.vtv1.NullableInt64}
 */
proto.vtv1.EdgeContract.prototype.getCostPerHour = function() {
  return /** @type{?proto.vtv1.NullableInt64} */ (
    jspb.Message.getWrapperField(this, proto.vtv1.NullableInt64, 25));
};


/**
 * @param {?proto.vtv1.NullableInt64|undefined} value
 * @return {!proto.vtv1.EdgeContract} returns this
*/
proto.vtv1.EdgeContract.prototype.setCostPerHour = function(value) {
  return jspb.Message.setWrapperField(this, 25, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.EdgeContract} returns this
 */
proto.vtv1.EdgeContract.prototype.clearCostPerHour = function() {
  return this.setCostPerHour(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.EdgeContract.prototype.hasCostPerHour = function() {
  return jspb.Message.getField(this, 25) != null;
};


/**
 * optional NullableInt64 minimum_per_hour_total_cost = 26;
 * @return {?proto.vtv1.NullableInt64}
 */
proto.vtv1.EdgeContract.prototype.getMinimumPerHourTotalCost = function() {
  return /** @type{?proto.vtv1.NullableInt64} */ (
    jspb.Message.getWrapperField(this, proto.vtv1.NullableInt64, 26));
};


/**
 * @param {?proto.vtv1.NullableInt64|undefined} value
 * @return {!proto.vtv1.EdgeContract} returns this
*/
proto.vtv1.EdgeContract.prototype.setMinimumPerHourTotalCost = function(value) {
  return jspb.Message.setWrapperField(this, 26, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.EdgeContract} returns this
 */
proto.vtv1.EdgeContract.prototype.clearMinimumPerHourTotalCost = function() {
  return this.setMinimumPerHourTotalCost(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.EdgeContract.prototype.hasMinimumPerHourTotalCost = function() {
  return jspb.Message.getField(this, 26) != null;
};


/**
 * optional NullableInt64 total_duration_minutes = 27;
 * @return {?proto.vtv1.NullableInt64}
 */
proto.vtv1.EdgeContract.prototype.getTotalDurationMinutes = function() {
  return /** @type{?proto.vtv1.NullableInt64} */ (
    jspb.Message.getWrapperField(this, proto.vtv1.NullableInt64, 27));
};


/**
 * @param {?proto.vtv1.NullableInt64|undefined} value
 * @return {!proto.vtv1.EdgeContract} returns this
*/
proto.vtv1.EdgeContract.prototype.setTotalDurationMinutes = function(value) {
  return jspb.Message.setWrapperField(this, 27, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.EdgeContract} returns this
 */
proto.vtv1.EdgeContract.prototype.clearTotalDurationMinutes = function() {
  return this.setTotalDurationMinutes(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.EdgeContract.prototype.hasTotalDurationMinutes = function() {
  return jspb.Message.getField(this, 27) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.P2PPTEntry.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.P2PPTEntry.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.P2PPTEntry} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.P2PPTEntry.toObject = function(includeInstance, msg) {
  var f, obj = {
    originCity: jspb.Message.getFieldWithDefault(msg, 1, ""),
    originState: jspb.Message.getFieldWithDefault(msg, 2, ""),
    originZip: jspb.Message.getFieldWithDefault(msg, 3, ""),
    destinationCity: jspb.Message.getFieldWithDefault(msg, 4, ""),
    destinationState: jspb.Message.getFieldWithDefault(msg, 5, ""),
    destinationZip: jspb.Message.getFieldWithDefault(msg, 6, ""),
    perTonRate: jspb.Message.getFieldWithDefault(msg, 7, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.P2PPTEntry}
 */
proto.vtv1.P2PPTEntry.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.P2PPTEntry;
  return proto.vtv1.P2PPTEntry.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.P2PPTEntry} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.P2PPTEntry}
 */
proto.vtv1.P2PPTEntry.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOriginCity(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOriginState(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setOriginZip(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDestinationCity(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setDestinationState(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setDestinationZip(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPerTonRate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.P2PPTEntry.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.P2PPTEntry.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.P2PPTEntry} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.P2PPTEntry.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOriginCity();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOriginState();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getOriginZip();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDestinationCity();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getDestinationState();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getDestinationZip();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getPerTonRate();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
};


/**
 * optional string origin_city = 1;
 * @return {string}
 */
proto.vtv1.P2PPTEntry.prototype.getOriginCity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.P2PPTEntry} returns this
 */
proto.vtv1.P2PPTEntry.prototype.setOriginCity = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string origin_state = 2;
 * @return {string}
 */
proto.vtv1.P2PPTEntry.prototype.getOriginState = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.P2PPTEntry} returns this
 */
proto.vtv1.P2PPTEntry.prototype.setOriginState = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string origin_zip = 3;
 * @return {string}
 */
proto.vtv1.P2PPTEntry.prototype.getOriginZip = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.P2PPTEntry} returns this
 */
proto.vtv1.P2PPTEntry.prototype.setOriginZip = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string destination_city = 4;
 * @return {string}
 */
proto.vtv1.P2PPTEntry.prototype.getDestinationCity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.P2PPTEntry} returns this
 */
proto.vtv1.P2PPTEntry.prototype.setDestinationCity = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string destination_state = 5;
 * @return {string}
 */
proto.vtv1.P2PPTEntry.prototype.getDestinationState = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.P2PPTEntry} returns this
 */
proto.vtv1.P2PPTEntry.prototype.setDestinationState = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string destination_zip = 6;
 * @return {string}
 */
proto.vtv1.P2PPTEntry.prototype.getDestinationZip = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.P2PPTEntry} returns this
 */
proto.vtv1.P2PPTEntry.prototype.setDestinationZip = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional int64 per_ton_rate = 7;
 * @return {number}
 */
proto.vtv1.P2PPTEntry.prototype.getPerTonRate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.P2PPTEntry} returns this
 */
proto.vtv1.P2PPTEntry.prototype.setPerTonRate = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.P2PPTEditEntry.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.P2PPTEditEntry.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.P2PPTEditEntry} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.P2PPTEditEntry.toObject = function(includeInstance, msg) {
  var f, obj = {
    originCity: jspb.Message.getFieldWithDefault(msg, 1, ""),
    originState: jspb.Message.getFieldWithDefault(msg, 2, ""),
    originZip: jspb.Message.getFieldWithDefault(msg, 3, ""),
    destinationCity: jspb.Message.getFieldWithDefault(msg, 4, ""),
    destinationState: jspb.Message.getFieldWithDefault(msg, 5, ""),
    destinationZip: jspb.Message.getFieldWithDefault(msg, 6, ""),
    perTonRate: jspb.Message.getFieldWithDefault(msg, 7, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.P2PPTEditEntry}
 */
proto.vtv1.P2PPTEditEntry.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.P2PPTEditEntry;
  return proto.vtv1.P2PPTEditEntry.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.P2PPTEditEntry} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.P2PPTEditEntry}
 */
proto.vtv1.P2PPTEditEntry.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOriginCity(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOriginState(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setOriginZip(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDestinationCity(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setDestinationState(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setDestinationZip(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPerTonRate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.P2PPTEditEntry.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.P2PPTEditEntry.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.P2PPTEditEntry} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.P2PPTEditEntry.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOriginCity();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOriginState();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getOriginZip();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDestinationCity();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getDestinationState();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getDestinationZip();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getPerTonRate();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
};


/**
 * optional string origin_city = 1;
 * @return {string}
 */
proto.vtv1.P2PPTEditEntry.prototype.getOriginCity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.P2PPTEditEntry} returns this
 */
proto.vtv1.P2PPTEditEntry.prototype.setOriginCity = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string origin_state = 2;
 * @return {string}
 */
proto.vtv1.P2PPTEditEntry.prototype.getOriginState = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.P2PPTEditEntry} returns this
 */
proto.vtv1.P2PPTEditEntry.prototype.setOriginState = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string origin_zip = 3;
 * @return {string}
 */
proto.vtv1.P2PPTEditEntry.prototype.getOriginZip = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.P2PPTEditEntry} returns this
 */
proto.vtv1.P2PPTEditEntry.prototype.setOriginZip = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string destination_city = 4;
 * @return {string}
 */
proto.vtv1.P2PPTEditEntry.prototype.getDestinationCity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.P2PPTEditEntry} returns this
 */
proto.vtv1.P2PPTEditEntry.prototype.setDestinationCity = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string destination_state = 5;
 * @return {string}
 */
proto.vtv1.P2PPTEditEntry.prototype.getDestinationState = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.P2PPTEditEntry} returns this
 */
proto.vtv1.P2PPTEditEntry.prototype.setDestinationState = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string destination_zip = 6;
 * @return {string}
 */
proto.vtv1.P2PPTEditEntry.prototype.getDestinationZip = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.P2PPTEditEntry} returns this
 */
proto.vtv1.P2PPTEditEntry.prototype.setDestinationZip = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional int64 per_ton_rate = 7;
 * @return {number}
 */
proto.vtv1.P2PPTEditEntry.prototype.getPerTonRate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.P2PPTEditEntry} returns this
 */
proto.vtv1.P2PPTEditEntry.prototype.setPerTonRate = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.P2PFile.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.P2PFile.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.P2PFile} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.P2PFile.toObject = function(includeInstance, msg) {
  var f, obj = {
    fileId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.P2PFile}
 */
proto.vtv1.P2PFile.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.P2PFile;
  return proto.vtv1.P2PFile.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.P2PFile} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.P2PFile}
 */
proto.vtv1.P2PFile.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setFileId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.P2PFile.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.P2PFile.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.P2PFile} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.P2PFile.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFileId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int64 file_id = 1;
 * @return {number}
 */
proto.vtv1.P2PFile.prototype.getFileId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.P2PFile} returns this
 */
proto.vtv1.P2PFile.prototype.setFileId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.vtv1.P2PFile.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.P2PFile} returns this
 */
proto.vtv1.P2PFile.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.EdgeCosts.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.EdgeCosts.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.EdgeCosts} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.EdgeCosts.toObject = function(includeInstance, msg) {
  var f, obj = {
    detentionType: jspb.Message.getFieldWithDefault(msg, 1, 0),
    hourlyDetentionFeeCents: (f = msg.getHourlyDetentionFeeCents()) && proto.vtv1.NullableInt64.toObject(includeInstance, f),
    perLoadDetentionCapCents: (f = msg.getPerLoadDetentionCapCents()) && proto.vtv1.NullableInt64.toObject(includeInstance, f),
    originFreeTimeMinutes: (f = msg.getOriginFreeTimeMinutes()) && proto.vtv1.NullableInt64.toObject(includeInstance, f),
    destFreeTimeMinutes: (f = msg.getDestFreeTimeMinutes()) && proto.vtv1.NullableInt64.toObject(includeInstance, f),
    totalFreeTimeMinutes: (f = msg.getTotalFreeTimeMinutes()) && proto.vtv1.NullableInt64.toObject(includeInstance, f),
    deadheadFreeDistanceMiles: (f = msg.getDeadheadFreeDistanceMiles()) && proto.vtv1.NullableInt64.toObject(includeInstance, f),
    deadheadCentsPerMile: (f = msg.getDeadheadCentsPerMile()) && proto.vtv1.NullableInt64.toObject(includeInstance, f),
    minimumCostPerMile: (f = msg.getMinimumCostPerMile()) && proto.vtv1.NullableInt64.toObject(includeInstance, f),
    includeDeadheadInFuelSurcharge: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
    addFuelSurcharge: jspb.Message.getBooleanFieldWithDefault(msg, 11, false),
    returnTripBillable: jspb.Message.getBooleanFieldWithDefault(msg, 12, false),
    returnTripChargePercent: jspb.Message.getFloatingPointFieldWithDefault(msg, 13, 0.0),
    fuelSurchargeOnReturnTrip: jspb.Message.getBooleanFieldWithDefault(msg, 14, false),
    addDeadheadChargeAutomatically: jspb.Message.getBooleanFieldWithDefault(msg, 15, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.EdgeCosts}
 */
proto.vtv1.EdgeCosts.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.EdgeCosts;
  return proto.vtv1.EdgeCosts.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.EdgeCosts} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.EdgeCosts}
 */
proto.vtv1.EdgeCosts.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.vtv1.DetentionType} */ (reader.readEnum());
      msg.setDetentionType(value);
      break;
    case 2:
      var value = new proto.vtv1.NullableInt64;
      reader.readMessage(value,proto.vtv1.NullableInt64.deserializeBinaryFromReader);
      msg.setHourlyDetentionFeeCents(value);
      break;
    case 3:
      var value = new proto.vtv1.NullableInt64;
      reader.readMessage(value,proto.vtv1.NullableInt64.deserializeBinaryFromReader);
      msg.setPerLoadDetentionCapCents(value);
      break;
    case 4:
      var value = new proto.vtv1.NullableInt64;
      reader.readMessage(value,proto.vtv1.NullableInt64.deserializeBinaryFromReader);
      msg.setOriginFreeTimeMinutes(value);
      break;
    case 5:
      var value = new proto.vtv1.NullableInt64;
      reader.readMessage(value,proto.vtv1.NullableInt64.deserializeBinaryFromReader);
      msg.setDestFreeTimeMinutes(value);
      break;
    case 6:
      var value = new proto.vtv1.NullableInt64;
      reader.readMessage(value,proto.vtv1.NullableInt64.deserializeBinaryFromReader);
      msg.setTotalFreeTimeMinutes(value);
      break;
    case 7:
      var value = new proto.vtv1.NullableInt64;
      reader.readMessage(value,proto.vtv1.NullableInt64.deserializeBinaryFromReader);
      msg.setDeadheadFreeDistanceMiles(value);
      break;
    case 8:
      var value = new proto.vtv1.NullableInt64;
      reader.readMessage(value,proto.vtv1.NullableInt64.deserializeBinaryFromReader);
      msg.setDeadheadCentsPerMile(value);
      break;
    case 9:
      var value = new proto.vtv1.NullableInt64;
      reader.readMessage(value,proto.vtv1.NullableInt64.deserializeBinaryFromReader);
      msg.setMinimumCostPerMile(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIncludeDeadheadInFuelSurcharge(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAddFuelSurcharge(value);
      break;
    case 12:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setReturnTripBillable(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setReturnTripChargePercent(value);
      break;
    case 14:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setFuelSurchargeOnReturnTrip(value);
      break;
    case 15:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAddDeadheadChargeAutomatically(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.EdgeCosts.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.EdgeCosts.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.EdgeCosts} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.EdgeCosts.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDetentionType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getHourlyDetentionFeeCents();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.vtv1.NullableInt64.serializeBinaryToWriter
    );
  }
  f = message.getPerLoadDetentionCapCents();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.vtv1.NullableInt64.serializeBinaryToWriter
    );
  }
  f = message.getOriginFreeTimeMinutes();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.vtv1.NullableInt64.serializeBinaryToWriter
    );
  }
  f = message.getDestFreeTimeMinutes();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.vtv1.NullableInt64.serializeBinaryToWriter
    );
  }
  f = message.getTotalFreeTimeMinutes();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.vtv1.NullableInt64.serializeBinaryToWriter
    );
  }
  f = message.getDeadheadFreeDistanceMiles();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.vtv1.NullableInt64.serializeBinaryToWriter
    );
  }
  f = message.getDeadheadCentsPerMile();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.vtv1.NullableInt64.serializeBinaryToWriter
    );
  }
  f = message.getMinimumCostPerMile();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.vtv1.NullableInt64.serializeBinaryToWriter
    );
  }
  f = message.getIncludeDeadheadInFuelSurcharge();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
  f = message.getAddFuelSurcharge();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
  f = message.getReturnTripBillable();
  if (f) {
    writer.writeBool(
      12,
      f
    );
  }
  f = message.getReturnTripChargePercent();
  if (f !== 0.0) {
    writer.writeDouble(
      13,
      f
    );
  }
  f = message.getFuelSurchargeOnReturnTrip();
  if (f) {
    writer.writeBool(
      14,
      f
    );
  }
  f = message.getAddDeadheadChargeAutomatically();
  if (f) {
    writer.writeBool(
      15,
      f
    );
  }
};


/**
 * optional DetentionType detention_type = 1;
 * @return {!proto.vtv1.DetentionType}
 */
proto.vtv1.EdgeCosts.prototype.getDetentionType = function() {
  return /** @type {!proto.vtv1.DetentionType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.vtv1.DetentionType} value
 * @return {!proto.vtv1.EdgeCosts} returns this
 */
proto.vtv1.EdgeCosts.prototype.setDetentionType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional NullableInt64 hourly_detention_fee_cents = 2;
 * @return {?proto.vtv1.NullableInt64}
 */
proto.vtv1.EdgeCosts.prototype.getHourlyDetentionFeeCents = function() {
  return /** @type{?proto.vtv1.NullableInt64} */ (
    jspb.Message.getWrapperField(this, proto.vtv1.NullableInt64, 2));
};


/**
 * @param {?proto.vtv1.NullableInt64|undefined} value
 * @return {!proto.vtv1.EdgeCosts} returns this
*/
proto.vtv1.EdgeCosts.prototype.setHourlyDetentionFeeCents = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.EdgeCosts} returns this
 */
proto.vtv1.EdgeCosts.prototype.clearHourlyDetentionFeeCents = function() {
  return this.setHourlyDetentionFeeCents(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.EdgeCosts.prototype.hasHourlyDetentionFeeCents = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional NullableInt64 per_load_detention_cap_cents = 3;
 * @return {?proto.vtv1.NullableInt64}
 */
proto.vtv1.EdgeCosts.prototype.getPerLoadDetentionCapCents = function() {
  return /** @type{?proto.vtv1.NullableInt64} */ (
    jspb.Message.getWrapperField(this, proto.vtv1.NullableInt64, 3));
};


/**
 * @param {?proto.vtv1.NullableInt64|undefined} value
 * @return {!proto.vtv1.EdgeCosts} returns this
*/
proto.vtv1.EdgeCosts.prototype.setPerLoadDetentionCapCents = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.EdgeCosts} returns this
 */
proto.vtv1.EdgeCosts.prototype.clearPerLoadDetentionCapCents = function() {
  return this.setPerLoadDetentionCapCents(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.EdgeCosts.prototype.hasPerLoadDetentionCapCents = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional NullableInt64 origin_free_time_minutes = 4;
 * @return {?proto.vtv1.NullableInt64}
 */
proto.vtv1.EdgeCosts.prototype.getOriginFreeTimeMinutes = function() {
  return /** @type{?proto.vtv1.NullableInt64} */ (
    jspb.Message.getWrapperField(this, proto.vtv1.NullableInt64, 4));
};


/**
 * @param {?proto.vtv1.NullableInt64|undefined} value
 * @return {!proto.vtv1.EdgeCosts} returns this
*/
proto.vtv1.EdgeCosts.prototype.setOriginFreeTimeMinutes = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.EdgeCosts} returns this
 */
proto.vtv1.EdgeCosts.prototype.clearOriginFreeTimeMinutes = function() {
  return this.setOriginFreeTimeMinutes(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.EdgeCosts.prototype.hasOriginFreeTimeMinutes = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional NullableInt64 dest_free_time_minutes = 5;
 * @return {?proto.vtv1.NullableInt64}
 */
proto.vtv1.EdgeCosts.prototype.getDestFreeTimeMinutes = function() {
  return /** @type{?proto.vtv1.NullableInt64} */ (
    jspb.Message.getWrapperField(this, proto.vtv1.NullableInt64, 5));
};


/**
 * @param {?proto.vtv1.NullableInt64|undefined} value
 * @return {!proto.vtv1.EdgeCosts} returns this
*/
proto.vtv1.EdgeCosts.prototype.setDestFreeTimeMinutes = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.EdgeCosts} returns this
 */
proto.vtv1.EdgeCosts.prototype.clearDestFreeTimeMinutes = function() {
  return this.setDestFreeTimeMinutes(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.EdgeCosts.prototype.hasDestFreeTimeMinutes = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional NullableInt64 total_free_time_minutes = 6;
 * @return {?proto.vtv1.NullableInt64}
 */
proto.vtv1.EdgeCosts.prototype.getTotalFreeTimeMinutes = function() {
  return /** @type{?proto.vtv1.NullableInt64} */ (
    jspb.Message.getWrapperField(this, proto.vtv1.NullableInt64, 6));
};


/**
 * @param {?proto.vtv1.NullableInt64|undefined} value
 * @return {!proto.vtv1.EdgeCosts} returns this
*/
proto.vtv1.EdgeCosts.prototype.setTotalFreeTimeMinutes = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.EdgeCosts} returns this
 */
proto.vtv1.EdgeCosts.prototype.clearTotalFreeTimeMinutes = function() {
  return this.setTotalFreeTimeMinutes(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.EdgeCosts.prototype.hasTotalFreeTimeMinutes = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional NullableInt64 deadhead_free_distance_miles = 7;
 * @return {?proto.vtv1.NullableInt64}
 */
proto.vtv1.EdgeCosts.prototype.getDeadheadFreeDistanceMiles = function() {
  return /** @type{?proto.vtv1.NullableInt64} */ (
    jspb.Message.getWrapperField(this, proto.vtv1.NullableInt64, 7));
};


/**
 * @param {?proto.vtv1.NullableInt64|undefined} value
 * @return {!proto.vtv1.EdgeCosts} returns this
*/
proto.vtv1.EdgeCosts.prototype.setDeadheadFreeDistanceMiles = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.EdgeCosts} returns this
 */
proto.vtv1.EdgeCosts.prototype.clearDeadheadFreeDistanceMiles = function() {
  return this.setDeadheadFreeDistanceMiles(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.EdgeCosts.prototype.hasDeadheadFreeDistanceMiles = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional NullableInt64 deadhead_cents_per_mile = 8;
 * @return {?proto.vtv1.NullableInt64}
 */
proto.vtv1.EdgeCosts.prototype.getDeadheadCentsPerMile = function() {
  return /** @type{?proto.vtv1.NullableInt64} */ (
    jspb.Message.getWrapperField(this, proto.vtv1.NullableInt64, 8));
};


/**
 * @param {?proto.vtv1.NullableInt64|undefined} value
 * @return {!proto.vtv1.EdgeCosts} returns this
*/
proto.vtv1.EdgeCosts.prototype.setDeadheadCentsPerMile = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.EdgeCosts} returns this
 */
proto.vtv1.EdgeCosts.prototype.clearDeadheadCentsPerMile = function() {
  return this.setDeadheadCentsPerMile(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.EdgeCosts.prototype.hasDeadheadCentsPerMile = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional NullableInt64 minimum_cost_per_mile = 9;
 * @return {?proto.vtv1.NullableInt64}
 */
proto.vtv1.EdgeCosts.prototype.getMinimumCostPerMile = function() {
  return /** @type{?proto.vtv1.NullableInt64} */ (
    jspb.Message.getWrapperField(this, proto.vtv1.NullableInt64, 9));
};


/**
 * @param {?proto.vtv1.NullableInt64|undefined} value
 * @return {!proto.vtv1.EdgeCosts} returns this
*/
proto.vtv1.EdgeCosts.prototype.setMinimumCostPerMile = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.EdgeCosts} returns this
 */
proto.vtv1.EdgeCosts.prototype.clearMinimumCostPerMile = function() {
  return this.setMinimumCostPerMile(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.EdgeCosts.prototype.hasMinimumCostPerMile = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional bool include_deadhead_in_fuel_surcharge = 10;
 * @return {boolean}
 */
proto.vtv1.EdgeCosts.prototype.getIncludeDeadheadInFuelSurcharge = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vtv1.EdgeCosts} returns this
 */
proto.vtv1.EdgeCosts.prototype.setIncludeDeadheadInFuelSurcharge = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};


/**
 * optional bool add_fuel_surcharge = 11;
 * @return {boolean}
 */
proto.vtv1.EdgeCosts.prototype.getAddFuelSurcharge = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vtv1.EdgeCosts} returns this
 */
proto.vtv1.EdgeCosts.prototype.setAddFuelSurcharge = function(value) {
  return jspb.Message.setProto3BooleanField(this, 11, value);
};


/**
 * optional bool return_trip_billable = 12;
 * @return {boolean}
 */
proto.vtv1.EdgeCosts.prototype.getReturnTripBillable = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 12, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vtv1.EdgeCosts} returns this
 */
proto.vtv1.EdgeCosts.prototype.setReturnTripBillable = function(value) {
  return jspb.Message.setProto3BooleanField(this, 12, value);
};


/**
 * optional double return_trip_charge_percent = 13;
 * @return {number}
 */
proto.vtv1.EdgeCosts.prototype.getReturnTripChargePercent = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 13, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.EdgeCosts} returns this
 */
proto.vtv1.EdgeCosts.prototype.setReturnTripChargePercent = function(value) {
  return jspb.Message.setProto3FloatField(this, 13, value);
};


/**
 * optional bool fuel_surcharge_on_return_trip = 14;
 * @return {boolean}
 */
proto.vtv1.EdgeCosts.prototype.getFuelSurchargeOnReturnTrip = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 14, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vtv1.EdgeCosts} returns this
 */
proto.vtv1.EdgeCosts.prototype.setFuelSurchargeOnReturnTrip = function(value) {
  return jspb.Message.setProto3BooleanField(this, 14, value);
};


/**
 * optional bool add_deadhead_charge_automatically = 15;
 * @return {boolean}
 */
proto.vtv1.EdgeCosts.prototype.getAddDeadheadChargeAutomatically = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 15, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vtv1.EdgeCosts} returns this
 */
proto.vtv1.EdgeCosts.prototype.setAddDeadheadChargeAutomatically = function(value) {
  return jspb.Message.setProto3BooleanField(this, 15, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.SlimEdgeContract.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.SlimEdgeContract.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.SlimEdgeContract} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.SlimEdgeContract.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    nodeName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    nodeId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    edgeName: (f = msg.getEdgeName()) && proto.vtv1.NullableString.toObject(includeInstance, f),
    startsAt: jspb.Message.getFieldWithDefault(msg, 6, ""),
    expiresAt: (f = msg.getExpiresAt()) && proto.vtv1.NullableTime.toObject(includeInstance, f),
    version: jspb.Message.getFieldWithDefault(msg, 8, 0),
    createdAt: jspb.Message.getFieldWithDefault(msg, 9, ""),
    createdBy: jspb.Message.getFieldWithDefault(msg, 10, ""),
    allowCarrierManualComplete: jspb.Message.getBooleanFieldWithDefault(msg, 11, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.SlimEdgeContract}
 */
proto.vtv1.SlimEdgeContract.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.SlimEdgeContract;
  return proto.vtv1.SlimEdgeContract.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.SlimEdgeContract} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.SlimEdgeContract}
 */
proto.vtv1.SlimEdgeContract.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setNodeName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setNodeId(value);
      break;
    case 5:
      var value = new proto.vtv1.NullableString;
      reader.readMessage(value,proto.vtv1.NullableString.deserializeBinaryFromReader);
      msg.setEdgeName(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setStartsAt(value);
      break;
    case 7:
      var value = new proto.vtv1.NullableTime;
      reader.readMessage(value,proto.vtv1.NullableTime.deserializeBinaryFromReader);
      msg.setExpiresAt(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setVersion(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedAt(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedBy(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAllowCarrierManualComplete(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.SlimEdgeContract.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.SlimEdgeContract.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.SlimEdgeContract} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.SlimEdgeContract.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getNodeName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getNodeId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getEdgeName();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.vtv1.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getStartsAt();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getExpiresAt();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.vtv1.NullableTime.serializeBinaryToWriter
    );
  }
  f = message.getVersion();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
  f = message.getCreatedAt();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getCreatedBy();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getAllowCarrierManualComplete();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.vtv1.SlimEdgeContract.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.SlimEdgeContract} returns this
 */
proto.vtv1.SlimEdgeContract.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.vtv1.SlimEdgeContract.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.SlimEdgeContract} returns this
 */
proto.vtv1.SlimEdgeContract.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string node_name = 3;
 * @return {string}
 */
proto.vtv1.SlimEdgeContract.prototype.getNodeName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.SlimEdgeContract} returns this
 */
proto.vtv1.SlimEdgeContract.prototype.setNodeName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string node_id = 4;
 * @return {string}
 */
proto.vtv1.SlimEdgeContract.prototype.getNodeId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.SlimEdgeContract} returns this
 */
proto.vtv1.SlimEdgeContract.prototype.setNodeId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional NullableString edge_name = 5;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.SlimEdgeContract.prototype.getEdgeName = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto.vtv1.NullableString, 5));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.SlimEdgeContract} returns this
*/
proto.vtv1.SlimEdgeContract.prototype.setEdgeName = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.SlimEdgeContract} returns this
 */
proto.vtv1.SlimEdgeContract.prototype.clearEdgeName = function() {
  return this.setEdgeName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.SlimEdgeContract.prototype.hasEdgeName = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional string starts_at = 6;
 * @return {string}
 */
proto.vtv1.SlimEdgeContract.prototype.getStartsAt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.SlimEdgeContract} returns this
 */
proto.vtv1.SlimEdgeContract.prototype.setStartsAt = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional NullableTime expires_at = 7;
 * @return {?proto.vtv1.NullableTime}
 */
proto.vtv1.SlimEdgeContract.prototype.getExpiresAt = function() {
  return /** @type{?proto.vtv1.NullableTime} */ (
    jspb.Message.getWrapperField(this, proto.vtv1.NullableTime, 7));
};


/**
 * @param {?proto.vtv1.NullableTime|undefined} value
 * @return {!proto.vtv1.SlimEdgeContract} returns this
*/
proto.vtv1.SlimEdgeContract.prototype.setExpiresAt = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.SlimEdgeContract} returns this
 */
proto.vtv1.SlimEdgeContract.prototype.clearExpiresAt = function() {
  return this.setExpiresAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.SlimEdgeContract.prototype.hasExpiresAt = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional int64 version = 8;
 * @return {number}
 */
proto.vtv1.SlimEdgeContract.prototype.getVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.SlimEdgeContract} returns this
 */
proto.vtv1.SlimEdgeContract.prototype.setVersion = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional string created_at = 9;
 * @return {string}
 */
proto.vtv1.SlimEdgeContract.prototype.getCreatedAt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.SlimEdgeContract} returns this
 */
proto.vtv1.SlimEdgeContract.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string created_by = 10;
 * @return {string}
 */
proto.vtv1.SlimEdgeContract.prototype.getCreatedBy = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.SlimEdgeContract} returns this
 */
proto.vtv1.SlimEdgeContract.prototype.setCreatedBy = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional bool allow_carrier_manual_complete = 11;
 * @return {boolean}
 */
proto.vtv1.SlimEdgeContract.prototype.getAllowCarrierManualComplete = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vtv1.SlimEdgeContract} returns this
 */
proto.vtv1.SlimEdgeContract.prototype.setAllowCarrierManualComplete = function(value) {
  return jspb.Message.setProto3BooleanField(this, 11, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.IntId.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.IntId.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.IntId} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.IntId.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.IntId}
 */
proto.vtv1.IntId.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.IntId;
  return proto.vtv1.IntId.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.IntId} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.IntId}
 */
proto.vtv1.IntId.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.IntId.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.IntId.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.IntId} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.IntId.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.vtv1.IntId.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.IntId} returns this
 */
proto.vtv1.IntId.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.StringId.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.StringId.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.StringId} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.StringId.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.StringId}
 */
proto.vtv1.StringId.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.StringId;
  return proto.vtv1.StringId.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.StringId} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.StringId}
 */
proto.vtv1.StringId.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.StringId.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.StringId.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.StringId} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.StringId.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.vtv1.StringId.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.StringId} returns this
 */
proto.vtv1.StringId.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.StringIdName.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.StringIdName.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.StringIdName} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.StringIdName.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.StringIdName}
 */
proto.vtv1.StringIdName.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.StringIdName;
  return proto.vtv1.StringIdName.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.StringIdName} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.StringIdName}
 */
proto.vtv1.StringIdName.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.StringIdName.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.StringIdName.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.StringIdName} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.StringIdName.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.vtv1.StringIdName.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.StringIdName} returns this
 */
proto.vtv1.StringIdName.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.vtv1.StringIdName.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.StringIdName} returns this
 */
proto.vtv1.StringIdName.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.IntIdName.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.IntIdName.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.IntIdName} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.IntIdName.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.IntIdName}
 */
proto.vtv1.IntIdName.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.IntIdName;
  return proto.vtv1.IntIdName.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.IntIdName} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.IntIdName}
 */
proto.vtv1.IntIdName.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.IntIdName.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.IntIdName.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.IntIdName} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.IntIdName.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.vtv1.IntIdName.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.IntIdName} returns this
 */
proto.vtv1.IntIdName.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.vtv1.IntIdName.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.IntIdName} returns this
 */
proto.vtv1.IntIdName.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.SiteStatistics.repeatedFields_ = [6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.SiteStatistics.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.SiteStatistics.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.SiteStatistics} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.SiteStatistics.toObject = function(includeInstance, msg) {
  var f, obj = {
    productsNeedingAttention: jspb.Message.getFieldWithDefault(msg, 1, 0),
    loadsNeedingAttention: jspb.Message.getFieldWithDefault(msg, 2, 0),
    loadsReceivingToday: jspb.Message.getFieldWithDefault(msg, 3, 0),
    trucksOnSite: jspb.Message.getFieldWithDefault(msg, 4, 0),
    averageDayHourDwellTime: jspb.Message.getFieldWithDefault(msg, 5, 0),
    inventoryStockOutRisksList: (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.SiteStatistics}
 */
proto.vtv1.SiteStatistics.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.SiteStatistics;
  return proto.vtv1.SiteStatistics.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.SiteStatistics} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.SiteStatistics}
 */
proto.vtv1.SiteStatistics.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setProductsNeedingAttention(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLoadsNeedingAttention(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLoadsReceivingToday(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTrucksOnSite(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAverageDayHourDwellTime(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.addInventoryStockOutRisks(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.SiteStatistics.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.SiteStatistics.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.SiteStatistics} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.SiteStatistics.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProductsNeedingAttention();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getLoadsNeedingAttention();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getLoadsReceivingToday();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getTrucksOnSite();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getAverageDayHourDwellTime();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getInventoryStockOutRisksList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      6,
      f
    );
  }
};


/**
 * optional int64 products_needing_attention = 1;
 * @return {number}
 */
proto.vtv1.SiteStatistics.prototype.getProductsNeedingAttention = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.SiteStatistics} returns this
 */
proto.vtv1.SiteStatistics.prototype.setProductsNeedingAttention = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 loads_needing_attention = 2;
 * @return {number}
 */
proto.vtv1.SiteStatistics.prototype.getLoadsNeedingAttention = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.SiteStatistics} returns this
 */
proto.vtv1.SiteStatistics.prototype.setLoadsNeedingAttention = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 loads_receiving_today = 3;
 * @return {number}
 */
proto.vtv1.SiteStatistics.prototype.getLoadsReceivingToday = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.SiteStatistics} returns this
 */
proto.vtv1.SiteStatistics.prototype.setLoadsReceivingToday = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 trucks_on_site = 4;
 * @return {number}
 */
proto.vtv1.SiteStatistics.prototype.getTrucksOnSite = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.SiteStatistics} returns this
 */
proto.vtv1.SiteStatistics.prototype.setTrucksOnSite = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int64 average_day_hour_dwell_time = 5;
 * @return {number}
 */
proto.vtv1.SiteStatistics.prototype.getAverageDayHourDwellTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.SiteStatistics} returns this
 */
proto.vtv1.SiteStatistics.prototype.setAverageDayHourDwellTime = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * repeated string inventory_stock_out_risks = 6;
 * @return {!Array<string>}
 */
proto.vtv1.SiteStatistics.prototype.getInventoryStockOutRisksList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 6));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.vtv1.SiteStatistics} returns this
 */
proto.vtv1.SiteStatistics.prototype.setInventoryStockOutRisksList = function(value) {
  return jspb.Message.setField(this, 6, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.vtv1.SiteStatistics} returns this
 */
proto.vtv1.SiteStatistics.prototype.addInventoryStockOutRisks = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.SiteStatistics} returns this
 */
proto.vtv1.SiteStatistics.prototype.clearInventoryStockOutRisksList = function() {
  return this.setInventoryStockOutRisksList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.SlimSiteInventory.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.SlimSiteInventory.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.SlimSiteInventory} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.SlimSiteInventory.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    productId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    productName: jspb.Message.getFieldWithDefault(msg, 4, ""),
    productUnitName: jspb.Message.getFieldWithDefault(msg, 5, ""),
    productUnitAbbreviation: jspb.Message.getFieldWithDefault(msg, 6, ""),
    capacity: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    stock: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
    updatedAt: jspb.Message.getFieldWithDefault(msg, 9, ""),
    isAutomated: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
    nptEstimatedAt: (f = msg.getNptEstimatedAt()) && proto.vtv1.NullableTime.toObject(includeInstance, f),
    hasWarning: jspb.Message.getBooleanFieldWithDefault(msg, 12, false),
    inbound: jspb.Message.getBooleanFieldWithDefault(msg, 13, false),
    outbound: jspb.Message.getBooleanFieldWithDefault(msg, 14, false),
    autoOrder: jspb.Message.getBooleanFieldWithDefault(msg, 15, false),
    autoRestock: jspb.Message.getBooleanFieldWithDefault(msg, 16, false),
    byContainer: jspb.Message.getBooleanFieldWithDefault(msg, 17, false),
    trailerNumber: (f = msg.getTrailerNumber()) && proto.vtv1.NullableString.toObject(includeInstance, f),
    salesOrderLineItemNumber: (f = msg.getSalesOrderLineItemNumber()) && proto.vtv1.NullableString.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.SlimSiteInventory}
 */
proto.vtv1.SlimSiteInventory.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.SlimSiteInventory;
  return proto.vtv1.SlimSiteInventory.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.SlimSiteInventory} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.SlimSiteInventory}
 */
proto.vtv1.SlimSiteInventory.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductName(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductUnitName(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductUnitAbbreviation(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setCapacity(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setStock(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setUpdatedAt(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsAutomated(value);
      break;
    case 11:
      var value = new proto.vtv1.NullableTime;
      reader.readMessage(value,proto.vtv1.NullableTime.deserializeBinaryFromReader);
      msg.setNptEstimatedAt(value);
      break;
    case 12:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasWarning(value);
      break;
    case 13:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setInbound(value);
      break;
    case 14:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOutbound(value);
      break;
    case 15:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAutoOrder(value);
      break;
    case 16:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAutoRestock(value);
      break;
    case 17:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setByContainer(value);
      break;
    case 18:
      var value = new proto.vtv1.NullableString;
      reader.readMessage(value,proto.vtv1.NullableString.deserializeBinaryFromReader);
      msg.setTrailerNumber(value);
      break;
    case 19:
      var value = new proto.vtv1.NullableString;
      reader.readMessage(value,proto.vtv1.NullableString.deserializeBinaryFromReader);
      msg.setSalesOrderLineItemNumber(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.SlimSiteInventory.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.SlimSiteInventory.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.SlimSiteInventory} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.SlimSiteInventory.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getProductId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getProductName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getProductUnitName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getProductUnitAbbreviation();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getCapacity();
  if (f !== 0.0) {
    writer.writeDouble(
      7,
      f
    );
  }
  f = message.getStock();
  if (f !== 0.0) {
    writer.writeDouble(
      8,
      f
    );
  }
  f = message.getUpdatedAt();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getIsAutomated();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
  f = message.getNptEstimatedAt();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto.vtv1.NullableTime.serializeBinaryToWriter
    );
  }
  f = message.getHasWarning();
  if (f) {
    writer.writeBool(
      12,
      f
    );
  }
  f = message.getInbound();
  if (f) {
    writer.writeBool(
      13,
      f
    );
  }
  f = message.getOutbound();
  if (f) {
    writer.writeBool(
      14,
      f
    );
  }
  f = message.getAutoOrder();
  if (f) {
    writer.writeBool(
      15,
      f
    );
  }
  f = message.getAutoRestock();
  if (f) {
    writer.writeBool(
      16,
      f
    );
  }
  f = message.getByContainer();
  if (f) {
    writer.writeBool(
      17,
      f
    );
  }
  f = message.getTrailerNumber();
  if (f != null) {
    writer.writeMessage(
      18,
      f,
      proto.vtv1.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getSalesOrderLineItemNumber();
  if (f != null) {
    writer.writeMessage(
      19,
      f,
      proto.vtv1.NullableString.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.vtv1.SlimSiteInventory.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.SlimSiteInventory} returns this
 */
proto.vtv1.SlimSiteInventory.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.vtv1.SlimSiteInventory.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.SlimSiteInventory} returns this
 */
proto.vtv1.SlimSiteInventory.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string product_id = 3;
 * @return {string}
 */
proto.vtv1.SlimSiteInventory.prototype.getProductId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.SlimSiteInventory} returns this
 */
proto.vtv1.SlimSiteInventory.prototype.setProductId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string product_name = 4;
 * @return {string}
 */
proto.vtv1.SlimSiteInventory.prototype.getProductName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.SlimSiteInventory} returns this
 */
proto.vtv1.SlimSiteInventory.prototype.setProductName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string product_unit_name = 5;
 * @return {string}
 */
proto.vtv1.SlimSiteInventory.prototype.getProductUnitName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.SlimSiteInventory} returns this
 */
proto.vtv1.SlimSiteInventory.prototype.setProductUnitName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string product_unit_abbreviation = 6;
 * @return {string}
 */
proto.vtv1.SlimSiteInventory.prototype.getProductUnitAbbreviation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.SlimSiteInventory} returns this
 */
proto.vtv1.SlimSiteInventory.prototype.setProductUnitAbbreviation = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional double capacity = 7;
 * @return {number}
 */
proto.vtv1.SlimSiteInventory.prototype.getCapacity = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.SlimSiteInventory} returns this
 */
proto.vtv1.SlimSiteInventory.prototype.setCapacity = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional double stock = 8;
 * @return {number}
 */
proto.vtv1.SlimSiteInventory.prototype.getStock = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.SlimSiteInventory} returns this
 */
proto.vtv1.SlimSiteInventory.prototype.setStock = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};


/**
 * optional string updated_at = 9;
 * @return {string}
 */
proto.vtv1.SlimSiteInventory.prototype.getUpdatedAt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.SlimSiteInventory} returns this
 */
proto.vtv1.SlimSiteInventory.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional bool is_automated = 10;
 * @return {boolean}
 */
proto.vtv1.SlimSiteInventory.prototype.getIsAutomated = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vtv1.SlimSiteInventory} returns this
 */
proto.vtv1.SlimSiteInventory.prototype.setIsAutomated = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};


/**
 * optional NullableTime npt_estimated_at = 11;
 * @return {?proto.vtv1.NullableTime}
 */
proto.vtv1.SlimSiteInventory.prototype.getNptEstimatedAt = function() {
  return /** @type{?proto.vtv1.NullableTime} */ (
    jspb.Message.getWrapperField(this, proto.vtv1.NullableTime, 11));
};


/**
 * @param {?proto.vtv1.NullableTime|undefined} value
 * @return {!proto.vtv1.SlimSiteInventory} returns this
*/
proto.vtv1.SlimSiteInventory.prototype.setNptEstimatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.SlimSiteInventory} returns this
 */
proto.vtv1.SlimSiteInventory.prototype.clearNptEstimatedAt = function() {
  return this.setNptEstimatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.SlimSiteInventory.prototype.hasNptEstimatedAt = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional bool has_warning = 12;
 * @return {boolean}
 */
proto.vtv1.SlimSiteInventory.prototype.getHasWarning = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 12, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vtv1.SlimSiteInventory} returns this
 */
proto.vtv1.SlimSiteInventory.prototype.setHasWarning = function(value) {
  return jspb.Message.setProto3BooleanField(this, 12, value);
};


/**
 * optional bool inbound = 13;
 * @return {boolean}
 */
proto.vtv1.SlimSiteInventory.prototype.getInbound = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 13, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vtv1.SlimSiteInventory} returns this
 */
proto.vtv1.SlimSiteInventory.prototype.setInbound = function(value) {
  return jspb.Message.setProto3BooleanField(this, 13, value);
};


/**
 * optional bool outbound = 14;
 * @return {boolean}
 */
proto.vtv1.SlimSiteInventory.prototype.getOutbound = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 14, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vtv1.SlimSiteInventory} returns this
 */
proto.vtv1.SlimSiteInventory.prototype.setOutbound = function(value) {
  return jspb.Message.setProto3BooleanField(this, 14, value);
};


/**
 * optional bool auto_order = 15;
 * @return {boolean}
 */
proto.vtv1.SlimSiteInventory.prototype.getAutoOrder = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 15, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vtv1.SlimSiteInventory} returns this
 */
proto.vtv1.SlimSiteInventory.prototype.setAutoOrder = function(value) {
  return jspb.Message.setProto3BooleanField(this, 15, value);
};


/**
 * optional bool auto_restock = 16;
 * @return {boolean}
 */
proto.vtv1.SlimSiteInventory.prototype.getAutoRestock = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 16, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vtv1.SlimSiteInventory} returns this
 */
proto.vtv1.SlimSiteInventory.prototype.setAutoRestock = function(value) {
  return jspb.Message.setProto3BooleanField(this, 16, value);
};


/**
 * optional bool by_container = 17;
 * @return {boolean}
 */
proto.vtv1.SlimSiteInventory.prototype.getByContainer = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 17, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vtv1.SlimSiteInventory} returns this
 */
proto.vtv1.SlimSiteInventory.prototype.setByContainer = function(value) {
  return jspb.Message.setProto3BooleanField(this, 17, value);
};


/**
 * optional NullableString trailer_number = 18;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.SlimSiteInventory.prototype.getTrailerNumber = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto.vtv1.NullableString, 18));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.SlimSiteInventory} returns this
*/
proto.vtv1.SlimSiteInventory.prototype.setTrailerNumber = function(value) {
  return jspb.Message.setWrapperField(this, 18, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.SlimSiteInventory} returns this
 */
proto.vtv1.SlimSiteInventory.prototype.clearTrailerNumber = function() {
  return this.setTrailerNumber(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.SlimSiteInventory.prototype.hasTrailerNumber = function() {
  return jspb.Message.getField(this, 18) != null;
};


/**
 * optional NullableString sales_order_line_item_number = 19;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.SlimSiteInventory.prototype.getSalesOrderLineItemNumber = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto.vtv1.NullableString, 19));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.SlimSiteInventory} returns this
*/
proto.vtv1.SlimSiteInventory.prototype.setSalesOrderLineItemNumber = function(value) {
  return jspb.Message.setWrapperField(this, 19, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.SlimSiteInventory} returns this
 */
proto.vtv1.SlimSiteInventory.prototype.clearSalesOrderLineItemNumber = function() {
  return this.setSalesOrderLineItemNumber(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.SlimSiteInventory.prototype.hasSalesOrderLineItemNumber = function() {
  return jspb.Message.getField(this, 19) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.ProductionPlanGroup.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.ProductionPlanGroup.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.ProductionPlanGroup.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.ProductionPlanGroup} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.ProductionPlanGroup.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    nptEstimatedAt: (f = msg.getNptEstimatedAt()) && proto.vtv1.NullableTime.toObject(includeInstance, f),
    inventoriesList: jspb.Message.toObjectList(msg.getInventoriesList(),
    proto.vtv1.SlimSiteInventory.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.ProductionPlanGroup}
 */
proto.vtv1.ProductionPlanGroup.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.ProductionPlanGroup;
  return proto.vtv1.ProductionPlanGroup.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.ProductionPlanGroup} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.ProductionPlanGroup}
 */
proto.vtv1.ProductionPlanGroup.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = new proto.vtv1.NullableTime;
      reader.readMessage(value,proto.vtv1.NullableTime.deserializeBinaryFromReader);
      msg.setNptEstimatedAt(value);
      break;
    case 4:
      var value = new proto.vtv1.SlimSiteInventory;
      reader.readMessage(value,proto.vtv1.SlimSiteInventory.deserializeBinaryFromReader);
      msg.addInventories(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.ProductionPlanGroup.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.ProductionPlanGroup.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.ProductionPlanGroup} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.ProductionPlanGroup.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getNptEstimatedAt();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.vtv1.NullableTime.serializeBinaryToWriter
    );
  }
  f = message.getInventoriesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.vtv1.SlimSiteInventory.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.vtv1.ProductionPlanGroup.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.ProductionPlanGroup} returns this
 */
proto.vtv1.ProductionPlanGroup.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.vtv1.ProductionPlanGroup.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.ProductionPlanGroup} returns this
 */
proto.vtv1.ProductionPlanGroup.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional NullableTime npt_estimated_at = 3;
 * @return {?proto.vtv1.NullableTime}
 */
proto.vtv1.ProductionPlanGroup.prototype.getNptEstimatedAt = function() {
  return /** @type{?proto.vtv1.NullableTime} */ (
    jspb.Message.getWrapperField(this, proto.vtv1.NullableTime, 3));
};


/**
 * @param {?proto.vtv1.NullableTime|undefined} value
 * @return {!proto.vtv1.ProductionPlanGroup} returns this
*/
proto.vtv1.ProductionPlanGroup.prototype.setNptEstimatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.ProductionPlanGroup} returns this
 */
proto.vtv1.ProductionPlanGroup.prototype.clearNptEstimatedAt = function() {
  return this.setNptEstimatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.ProductionPlanGroup.prototype.hasNptEstimatedAt = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * repeated SlimSiteInventory inventories = 4;
 * @return {!Array<!proto.vtv1.SlimSiteInventory>}
 */
proto.vtv1.ProductionPlanGroup.prototype.getInventoriesList = function() {
  return /** @type{!Array<!proto.vtv1.SlimSiteInventory>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vtv1.SlimSiteInventory, 4));
};


/**
 * @param {!Array<!proto.vtv1.SlimSiteInventory>} value
 * @return {!proto.vtv1.ProductionPlanGroup} returns this
*/
proto.vtv1.ProductionPlanGroup.prototype.setInventoriesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.vtv1.SlimSiteInventory=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.SlimSiteInventory}
 */
proto.vtv1.ProductionPlanGroup.prototype.addInventories = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.vtv1.SlimSiteInventory, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.ProductionPlanGroup} returns this
 */
proto.vtv1.ProductionPlanGroup.prototype.clearInventoriesList = function() {
  return this.setInventoriesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.ProductionScheduleDay.repeatedFields_ = [3,4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.ProductionScheduleDay.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.ProductionScheduleDay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.ProductionScheduleDay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.ProductionScheduleDay.toObject = function(includeInstance, msg) {
  var f, obj = {
    displayDate: jspb.Message.getFieldWithDefault(msg, 1, ""),
    dayOfWeek: jspb.Message.getFieldWithDefault(msg, 2, 0),
    productionBatchesList: jspb.Message.toObjectList(msg.getProductionBatchesList(),
    proto.vtv1.SlimProductionBatch.toObject, includeInstance),
    productionPlanEntriesList: jspb.Message.toObjectList(msg.getProductionPlanEntriesList(),
    proto.vtv1.SlimProductionPlanEntry.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.ProductionScheduleDay}
 */
proto.vtv1.ProductionScheduleDay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.ProductionScheduleDay;
  return proto.vtv1.ProductionScheduleDay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.ProductionScheduleDay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.ProductionScheduleDay}
 */
proto.vtv1.ProductionScheduleDay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplayDate(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDayOfWeek(value);
      break;
    case 3:
      var value = new proto.vtv1.SlimProductionBatch;
      reader.readMessage(value,proto.vtv1.SlimProductionBatch.deserializeBinaryFromReader);
      msg.addProductionBatches(value);
      break;
    case 4:
      var value = new proto.vtv1.SlimProductionPlanEntry;
      reader.readMessage(value,proto.vtv1.SlimProductionPlanEntry.deserializeBinaryFromReader);
      msg.addProductionPlanEntries(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.ProductionScheduleDay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.ProductionScheduleDay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.ProductionScheduleDay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.ProductionScheduleDay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDisplayDate();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDayOfWeek();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getProductionBatchesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.vtv1.SlimProductionBatch.serializeBinaryToWriter
    );
  }
  f = message.getProductionPlanEntriesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.vtv1.SlimProductionPlanEntry.serializeBinaryToWriter
    );
  }
};


/**
 * optional string display_date = 1;
 * @return {string}
 */
proto.vtv1.ProductionScheduleDay.prototype.getDisplayDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.ProductionScheduleDay} returns this
 */
proto.vtv1.ProductionScheduleDay.prototype.setDisplayDate = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 day_of_week = 2;
 * @return {number}
 */
proto.vtv1.ProductionScheduleDay.prototype.getDayOfWeek = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.ProductionScheduleDay} returns this
 */
proto.vtv1.ProductionScheduleDay.prototype.setDayOfWeek = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * repeated SlimProductionBatch production_batches = 3;
 * @return {!Array<!proto.vtv1.SlimProductionBatch>}
 */
proto.vtv1.ProductionScheduleDay.prototype.getProductionBatchesList = function() {
  return /** @type{!Array<!proto.vtv1.SlimProductionBatch>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vtv1.SlimProductionBatch, 3));
};


/**
 * @param {!Array<!proto.vtv1.SlimProductionBatch>} value
 * @return {!proto.vtv1.ProductionScheduleDay} returns this
*/
proto.vtv1.ProductionScheduleDay.prototype.setProductionBatchesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.vtv1.SlimProductionBatch=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.SlimProductionBatch}
 */
proto.vtv1.ProductionScheduleDay.prototype.addProductionBatches = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.vtv1.SlimProductionBatch, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.ProductionScheduleDay} returns this
 */
proto.vtv1.ProductionScheduleDay.prototype.clearProductionBatchesList = function() {
  return this.setProductionBatchesList([]);
};


/**
 * repeated SlimProductionPlanEntry production_plan_entries = 4;
 * @return {!Array<!proto.vtv1.SlimProductionPlanEntry>}
 */
proto.vtv1.ProductionScheduleDay.prototype.getProductionPlanEntriesList = function() {
  return /** @type{!Array<!proto.vtv1.SlimProductionPlanEntry>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vtv1.SlimProductionPlanEntry, 4));
};


/**
 * @param {!Array<!proto.vtv1.SlimProductionPlanEntry>} value
 * @return {!proto.vtv1.ProductionScheduleDay} returns this
*/
proto.vtv1.ProductionScheduleDay.prototype.setProductionPlanEntriesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.vtv1.SlimProductionPlanEntry=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.SlimProductionPlanEntry}
 */
proto.vtv1.ProductionScheduleDay.prototype.addProductionPlanEntries = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.vtv1.SlimProductionPlanEntry, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.ProductionScheduleDay} returns this
 */
proto.vtv1.ProductionScheduleDay.prototype.clearProductionPlanEntriesList = function() {
  return this.setProductionPlanEntriesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.SlimProductionBatch.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.SlimProductionBatch.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.SlimProductionBatch} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.SlimProductionBatch.toObject = function(includeInstance, msg) {
  var f, obj = {
    productionBatchId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    productionPlanId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    productionProcessName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    unitAbbreviation: jspb.Message.getFieldWithDefault(msg, 4, ""),
    startsAt: jspb.Message.getFieldWithDefault(msg, 5, ""),
    endsAt: jspb.Message.getFieldWithDefault(msg, 6, ""),
    quantity: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    actualQuantity: (f = msg.getActualQuantity()) && proto.vtv1.NullableDouble.toObject(includeInstance, f),
    completedAt: (f = msg.getCompletedAt()) && proto.vtv1.NullableTime.toObject(includeInstance, f),
    completedBy: (f = msg.getCompletedBy()) && proto.vtv1.NullableString.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.SlimProductionBatch}
 */
proto.vtv1.SlimProductionBatch.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.SlimProductionBatch;
  return proto.vtv1.SlimProductionBatch.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.SlimProductionBatch} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.SlimProductionBatch}
 */
proto.vtv1.SlimProductionBatch.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductionBatchId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductionPlanId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductionProcessName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setUnitAbbreviation(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setStartsAt(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setEndsAt(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setQuantity(value);
      break;
    case 8:
      var value = new proto.vtv1.NullableDouble;
      reader.readMessage(value,proto.vtv1.NullableDouble.deserializeBinaryFromReader);
      msg.setActualQuantity(value);
      break;
    case 9:
      var value = new proto.vtv1.NullableTime;
      reader.readMessage(value,proto.vtv1.NullableTime.deserializeBinaryFromReader);
      msg.setCompletedAt(value);
      break;
    case 10:
      var value = new proto.vtv1.NullableString;
      reader.readMessage(value,proto.vtv1.NullableString.deserializeBinaryFromReader);
      msg.setCompletedBy(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.SlimProductionBatch.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.SlimProductionBatch.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.SlimProductionBatch} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.SlimProductionBatch.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProductionBatchId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getProductionPlanId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getProductionProcessName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getUnitAbbreviation();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getStartsAt();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getEndsAt();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getQuantity();
  if (f !== 0.0) {
    writer.writeDouble(
      7,
      f
    );
  }
  f = message.getActualQuantity();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.vtv1.NullableDouble.serializeBinaryToWriter
    );
  }
  f = message.getCompletedAt();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.vtv1.NullableTime.serializeBinaryToWriter
    );
  }
  f = message.getCompletedBy();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.vtv1.NullableString.serializeBinaryToWriter
    );
  }
};


/**
 * optional string production_batch_id = 1;
 * @return {string}
 */
proto.vtv1.SlimProductionBatch.prototype.getProductionBatchId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.SlimProductionBatch} returns this
 */
proto.vtv1.SlimProductionBatch.prototype.setProductionBatchId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string production_plan_id = 2;
 * @return {string}
 */
proto.vtv1.SlimProductionBatch.prototype.getProductionPlanId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.SlimProductionBatch} returns this
 */
proto.vtv1.SlimProductionBatch.prototype.setProductionPlanId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string production_process_name = 3;
 * @return {string}
 */
proto.vtv1.SlimProductionBatch.prototype.getProductionProcessName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.SlimProductionBatch} returns this
 */
proto.vtv1.SlimProductionBatch.prototype.setProductionProcessName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string unit_abbreviation = 4;
 * @return {string}
 */
proto.vtv1.SlimProductionBatch.prototype.getUnitAbbreviation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.SlimProductionBatch} returns this
 */
proto.vtv1.SlimProductionBatch.prototype.setUnitAbbreviation = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string starts_at = 5;
 * @return {string}
 */
proto.vtv1.SlimProductionBatch.prototype.getStartsAt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.SlimProductionBatch} returns this
 */
proto.vtv1.SlimProductionBatch.prototype.setStartsAt = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string ends_at = 6;
 * @return {string}
 */
proto.vtv1.SlimProductionBatch.prototype.getEndsAt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.SlimProductionBatch} returns this
 */
proto.vtv1.SlimProductionBatch.prototype.setEndsAt = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional double quantity = 7;
 * @return {number}
 */
proto.vtv1.SlimProductionBatch.prototype.getQuantity = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.SlimProductionBatch} returns this
 */
proto.vtv1.SlimProductionBatch.prototype.setQuantity = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional NullableDouble actual_quantity = 8;
 * @return {?proto.vtv1.NullableDouble}
 */
proto.vtv1.SlimProductionBatch.prototype.getActualQuantity = function() {
  return /** @type{?proto.vtv1.NullableDouble} */ (
    jspb.Message.getWrapperField(this, proto.vtv1.NullableDouble, 8));
};


/**
 * @param {?proto.vtv1.NullableDouble|undefined} value
 * @return {!proto.vtv1.SlimProductionBatch} returns this
*/
proto.vtv1.SlimProductionBatch.prototype.setActualQuantity = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.SlimProductionBatch} returns this
 */
proto.vtv1.SlimProductionBatch.prototype.clearActualQuantity = function() {
  return this.setActualQuantity(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.SlimProductionBatch.prototype.hasActualQuantity = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional NullableTime completed_at = 9;
 * @return {?proto.vtv1.NullableTime}
 */
proto.vtv1.SlimProductionBatch.prototype.getCompletedAt = function() {
  return /** @type{?proto.vtv1.NullableTime} */ (
    jspb.Message.getWrapperField(this, proto.vtv1.NullableTime, 9));
};


/**
 * @param {?proto.vtv1.NullableTime|undefined} value
 * @return {!proto.vtv1.SlimProductionBatch} returns this
*/
proto.vtv1.SlimProductionBatch.prototype.setCompletedAt = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.SlimProductionBatch} returns this
 */
proto.vtv1.SlimProductionBatch.prototype.clearCompletedAt = function() {
  return this.setCompletedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.SlimProductionBatch.prototype.hasCompletedAt = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional NullableString completed_by = 10;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.SlimProductionBatch.prototype.getCompletedBy = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto.vtv1.NullableString, 10));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.SlimProductionBatch} returns this
*/
proto.vtv1.SlimProductionBatch.prototype.setCompletedBy = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.SlimProductionBatch} returns this
 */
proto.vtv1.SlimProductionBatch.prototype.clearCompletedBy = function() {
  return this.setCompletedBy(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.SlimProductionBatch.prototype.hasCompletedBy = function() {
  return jspb.Message.getField(this, 10) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.SlimProductionPlanEntry.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.SlimProductionPlanEntry.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.SlimProductionPlanEntry} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.SlimProductionPlanEntry.toObject = function(includeInstance, msg) {
  var f, obj = {
    productionPlanEntryId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    productionPlanId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    productionProcessName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    unitAbbreviation: jspb.Message.getFieldWithDefault(msg, 4, ""),
    startsAt: jspb.Message.getFieldWithDefault(msg, 5, ""),
    endsAt: jspb.Message.getFieldWithDefault(msg, 6, ""),
    quantity: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.SlimProductionPlanEntry}
 */
proto.vtv1.SlimProductionPlanEntry.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.SlimProductionPlanEntry;
  return proto.vtv1.SlimProductionPlanEntry.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.SlimProductionPlanEntry} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.SlimProductionPlanEntry}
 */
proto.vtv1.SlimProductionPlanEntry.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductionPlanEntryId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductionPlanId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductionProcessName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setUnitAbbreviation(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setStartsAt(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setEndsAt(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setQuantity(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.SlimProductionPlanEntry.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.SlimProductionPlanEntry.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.SlimProductionPlanEntry} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.SlimProductionPlanEntry.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProductionPlanEntryId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getProductionPlanId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getProductionProcessName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getUnitAbbreviation();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getStartsAt();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getEndsAt();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getQuantity();
  if (f !== 0.0) {
    writer.writeDouble(
      7,
      f
    );
  }
};


/**
 * optional string production_plan_entry_id = 1;
 * @return {string}
 */
proto.vtv1.SlimProductionPlanEntry.prototype.getProductionPlanEntryId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.SlimProductionPlanEntry} returns this
 */
proto.vtv1.SlimProductionPlanEntry.prototype.setProductionPlanEntryId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string production_plan_id = 2;
 * @return {string}
 */
proto.vtv1.SlimProductionPlanEntry.prototype.getProductionPlanId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.SlimProductionPlanEntry} returns this
 */
proto.vtv1.SlimProductionPlanEntry.prototype.setProductionPlanId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string production_process_name = 3;
 * @return {string}
 */
proto.vtv1.SlimProductionPlanEntry.prototype.getProductionProcessName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.SlimProductionPlanEntry} returns this
 */
proto.vtv1.SlimProductionPlanEntry.prototype.setProductionProcessName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string unit_abbreviation = 4;
 * @return {string}
 */
proto.vtv1.SlimProductionPlanEntry.prototype.getUnitAbbreviation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.SlimProductionPlanEntry} returns this
 */
proto.vtv1.SlimProductionPlanEntry.prototype.setUnitAbbreviation = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string starts_at = 5;
 * @return {string}
 */
proto.vtv1.SlimProductionPlanEntry.prototype.getStartsAt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.SlimProductionPlanEntry} returns this
 */
proto.vtv1.SlimProductionPlanEntry.prototype.setStartsAt = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string ends_at = 6;
 * @return {string}
 */
proto.vtv1.SlimProductionPlanEntry.prototype.getEndsAt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.SlimProductionPlanEntry} returns this
 */
proto.vtv1.SlimProductionPlanEntry.prototype.setEndsAt = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional double quantity = 7;
 * @return {number}
 */
proto.vtv1.SlimProductionPlanEntry.prototype.getQuantity = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.SlimProductionPlanEntry} returns this
 */
proto.vtv1.SlimProductionPlanEntry.prototype.setQuantity = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.Site.repeatedFields_ = [13,15,17,23,24,25,26,27,28,35];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.Site.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.Site.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.Site} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.Site.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    accountId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    accountName: jspb.Message.getFieldWithDefault(msg, 4, ""),
    longitude: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    latitude: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    radius: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    siteGroupId: (f = msg.getSiteGroupId()) && proto.vtv1.NullableInt64.toObject(includeInstance, f),
    siteGroupName: (f = msg.getSiteGroupName()) && proto.vtv1.NullableString.toObject(includeInstance, f),
    archivedAt: (f = msg.getArchivedAt()) && proto.vtv1.NullableTime.toObject(includeInstance, f),
    numberOfBays: (f = msg.getNumberOfBays()) && proto.vtv1.NullableInt64.toObject(includeInstance, f),
    defaultDeliveryWindowMinutes: (f = msg.getDefaultDeliveryWindowMinutes()) && proto.vtv1.NullableInt64.toObject(includeInstance, f),
    scheduleEntriesList: jspb.Message.toObjectList(msg.getScheduleEntriesList(),
    proto.vtv1.SiteLoadingScheduleEntry.toObject, includeInstance),
    address: (f = msg.getAddress()) && proto.vtv1.NullableString.toObject(includeInstance, f),
    productionPlansList: jspb.Message.toObjectList(msg.getProductionPlansList(),
    proto.vtv1.ProductionPlanGroup.toObject, includeInstance),
    statistics: (f = msg.getStatistics()) && proto.vtv1.SiteStatistics.toObject(includeInstance, f),
    productionScheduleDaysList: jspb.Message.toObjectList(msg.getProductionScheduleDaysList(),
    proto.vtv1.ProductionScheduleDay.toObject, includeInstance),
    lastStockOutDay: (f = msg.getLastStockOutDay()) && proto.vtv1.NullableString.toObject(includeInstance, f),
    desiredMaxSupplierResponseTimeHours: (f = msg.getDesiredMaxSupplierResponseTimeHours()) && proto.vtv1.NullableInt64.toObject(includeInstance, f),
    averageDwellTimeCalculationWindow: (f = msg.getAverageDwellTimeCalculationWindow()) && proto.vtv1.NullableInt64.toObject(includeInstance, f),
    directions: (f = msg.getDirections()) && proto.vtv1.NullableString.toObject(includeInstance, f),
    loadingInstructions: (f = msg.getLoadingInstructions()) && proto.vtv1.NullableString.toObject(includeInstance, f),
    siteProductionPlansList: jspb.Message.toObjectList(msg.getSiteProductionPlansList(),
    proto.vtv1.SiteSubInformationBoolean.toObject, includeInstance),
    siteInventoriesList: jspb.Message.toObjectList(msg.getSiteInventoriesList(),
    proto.vtv1.SiteSubInformationBoolean.toObject, includeInstance),
    inventoryStockOutRiskIdsList: (f = jspb.Message.getRepeatedField(msg, 25)) == null ? undefined : f,
    sourcingRiskDemandSignalIdsList: (f = jspb.Message.getRepeatedField(msg, 26)) == null ? undefined : f,
    ordersNeedingAttentionIdsList: (f = jspb.Message.getRepeatedField(msg, 27)) == null ? undefined : f,
    batchesNeedingUpdateIdsList: (f = jspb.Message.getRepeatedField(msg, 28)) == null ? undefined : f,
    hasOutboundCarriers: jspb.Message.getBooleanFieldWithDefault(msg, 29, false),
    salesOrder: (f = msg.getSalesOrder()) && proto.vtv1.NullableString.toObject(includeInstance, f),
    facilityCode: (f = msg.getFacilityCode()) && proto.vtv1.NullableString.toObject(includeInstance, f),
    timezone: jspb.Message.getFieldWithDefault(msg, 32, ""),
    district: (f = msg.getDistrict()) && proto.vtv1.District.toObject(includeInstance, f),
    taxPercent: (f = msg.getTaxPercent()) && proto.vtv1.NullableDouble.toObject(includeInstance, f),
    fulfillmentSitesList: (f = jspb.Message.getRepeatedField(msg, 35)) == null ? undefined : f,
    siteSalesOrder: (f = msg.getSiteSalesOrder()) && proto.vtv1.SalesOrder.toObject(includeInstance, f),
    siteCostCenter: (f = msg.getSiteCostCenter()) && proto.vtv1.CostCenter.toObject(includeInstance, f),
    locationId: (f = msg.getLocationId()) && proto.vtv1.NullableString.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.Site}
 */
proto.vtv1.Site.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.Site;
  return proto.vtv1.Site.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.Site} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.Site}
 */
proto.vtv1.Site.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountName(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLongitude(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLatitude(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setRadius(value);
      break;
    case 8:
      var value = new proto.vtv1.NullableInt64;
      reader.readMessage(value,proto.vtv1.NullableInt64.deserializeBinaryFromReader);
      msg.setSiteGroupId(value);
      break;
    case 9:
      var value = new proto.vtv1.NullableString;
      reader.readMessage(value,proto.vtv1.NullableString.deserializeBinaryFromReader);
      msg.setSiteGroupName(value);
      break;
    case 10:
      var value = new proto.vtv1.NullableTime;
      reader.readMessage(value,proto.vtv1.NullableTime.deserializeBinaryFromReader);
      msg.setArchivedAt(value);
      break;
    case 11:
      var value = new proto.vtv1.NullableInt64;
      reader.readMessage(value,proto.vtv1.NullableInt64.deserializeBinaryFromReader);
      msg.setNumberOfBays(value);
      break;
    case 12:
      var value = new proto.vtv1.NullableInt64;
      reader.readMessage(value,proto.vtv1.NullableInt64.deserializeBinaryFromReader);
      msg.setDefaultDeliveryWindowMinutes(value);
      break;
    case 13:
      var value = new proto.vtv1.SiteLoadingScheduleEntry;
      reader.readMessage(value,proto.vtv1.SiteLoadingScheduleEntry.deserializeBinaryFromReader);
      msg.addScheduleEntries(value);
      break;
    case 14:
      var value = new proto.vtv1.NullableString;
      reader.readMessage(value,proto.vtv1.NullableString.deserializeBinaryFromReader);
      msg.setAddress(value);
      break;
    case 15:
      var value = new proto.vtv1.ProductionPlanGroup;
      reader.readMessage(value,proto.vtv1.ProductionPlanGroup.deserializeBinaryFromReader);
      msg.addProductionPlans(value);
      break;
    case 16:
      var value = new proto.vtv1.SiteStatistics;
      reader.readMessage(value,proto.vtv1.SiteStatistics.deserializeBinaryFromReader);
      msg.setStatistics(value);
      break;
    case 17:
      var value = new proto.vtv1.ProductionScheduleDay;
      reader.readMessage(value,proto.vtv1.ProductionScheduleDay.deserializeBinaryFromReader);
      msg.addProductionScheduleDays(value);
      break;
    case 18:
      var value = new proto.vtv1.NullableString;
      reader.readMessage(value,proto.vtv1.NullableString.deserializeBinaryFromReader);
      msg.setLastStockOutDay(value);
      break;
    case 19:
      var value = new proto.vtv1.NullableInt64;
      reader.readMessage(value,proto.vtv1.NullableInt64.deserializeBinaryFromReader);
      msg.setDesiredMaxSupplierResponseTimeHours(value);
      break;
    case 20:
      var value = new proto.vtv1.NullableInt64;
      reader.readMessage(value,proto.vtv1.NullableInt64.deserializeBinaryFromReader);
      msg.setAverageDwellTimeCalculationWindow(value);
      break;
    case 21:
      var value = new proto.vtv1.NullableString;
      reader.readMessage(value,proto.vtv1.NullableString.deserializeBinaryFromReader);
      msg.setDirections(value);
      break;
    case 22:
      var value = new proto.vtv1.NullableString;
      reader.readMessage(value,proto.vtv1.NullableString.deserializeBinaryFromReader);
      msg.setLoadingInstructions(value);
      break;
    case 23:
      var value = new proto.vtv1.SiteSubInformationBoolean;
      reader.readMessage(value,proto.vtv1.SiteSubInformationBoolean.deserializeBinaryFromReader);
      msg.addSiteProductionPlans(value);
      break;
    case 24:
      var value = new proto.vtv1.SiteSubInformationBoolean;
      reader.readMessage(value,proto.vtv1.SiteSubInformationBoolean.deserializeBinaryFromReader);
      msg.addSiteInventories(value);
      break;
    case 25:
      var value = /** @type {string} */ (reader.readString());
      msg.addInventoryStockOutRiskIds(value);
      break;
    case 26:
      var value = /** @type {string} */ (reader.readString());
      msg.addSourcingRiskDemandSignalIds(value);
      break;
    case 27:
      var value = /** @type {string} */ (reader.readString());
      msg.addOrdersNeedingAttentionIds(value);
      break;
    case 28:
      var value = /** @type {string} */ (reader.readString());
      msg.addBatchesNeedingUpdateIds(value);
      break;
    case 29:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasOutboundCarriers(value);
      break;
    case 30:
      var value = new proto.vtv1.NullableString;
      reader.readMessage(value,proto.vtv1.NullableString.deserializeBinaryFromReader);
      msg.setSalesOrder(value);
      break;
    case 31:
      var value = new proto.vtv1.NullableString;
      reader.readMessage(value,proto.vtv1.NullableString.deserializeBinaryFromReader);
      msg.setFacilityCode(value);
      break;
    case 32:
      var value = /** @type {string} */ (reader.readString());
      msg.setTimezone(value);
      break;
    case 33:
      var value = new proto.vtv1.District;
      reader.readMessage(value,proto.vtv1.District.deserializeBinaryFromReader);
      msg.setDistrict(value);
      break;
    case 34:
      var value = new proto.vtv1.NullableDouble;
      reader.readMessage(value,proto.vtv1.NullableDouble.deserializeBinaryFromReader);
      msg.setTaxPercent(value);
      break;
    case 35:
      var value = /** @type {string} */ (reader.readString());
      msg.addFulfillmentSites(value);
      break;
    case 36:
      var value = new proto.vtv1.SalesOrder;
      reader.readMessage(value,proto.vtv1.SalesOrder.deserializeBinaryFromReader);
      msg.setSiteSalesOrder(value);
      break;
    case 37:
      var value = new proto.vtv1.CostCenter;
      reader.readMessage(value,proto.vtv1.CostCenter.deserializeBinaryFromReader);
      msg.setSiteCostCenter(value);
      break;
    case 38:
      var value = new proto.vtv1.NullableString;
      reader.readMessage(value,proto.vtv1.NullableString.deserializeBinaryFromReader);
      msg.setLocationId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.Site.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.Site.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.Site} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.Site.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAccountId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAccountName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getLongitude();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getLatitude();
  if (f !== 0.0) {
    writer.writeDouble(
      6,
      f
    );
  }
  f = message.getRadius();
  if (f !== 0.0) {
    writer.writeDouble(
      7,
      f
    );
  }
  f = message.getSiteGroupId();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.vtv1.NullableInt64.serializeBinaryToWriter
    );
  }
  f = message.getSiteGroupName();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.vtv1.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getArchivedAt();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.vtv1.NullableTime.serializeBinaryToWriter
    );
  }
  f = message.getNumberOfBays();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto.vtv1.NullableInt64.serializeBinaryToWriter
    );
  }
  f = message.getDefaultDeliveryWindowMinutes();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      proto.vtv1.NullableInt64.serializeBinaryToWriter
    );
  }
  f = message.getScheduleEntriesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      13,
      f,
      proto.vtv1.SiteLoadingScheduleEntry.serializeBinaryToWriter
    );
  }
  f = message.getAddress();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      proto.vtv1.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getProductionPlansList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      15,
      f,
      proto.vtv1.ProductionPlanGroup.serializeBinaryToWriter
    );
  }
  f = message.getStatistics();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      proto.vtv1.SiteStatistics.serializeBinaryToWriter
    );
  }
  f = message.getProductionScheduleDaysList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      17,
      f,
      proto.vtv1.ProductionScheduleDay.serializeBinaryToWriter
    );
  }
  f = message.getLastStockOutDay();
  if (f != null) {
    writer.writeMessage(
      18,
      f,
      proto.vtv1.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getDesiredMaxSupplierResponseTimeHours();
  if (f != null) {
    writer.writeMessage(
      19,
      f,
      proto.vtv1.NullableInt64.serializeBinaryToWriter
    );
  }
  f = message.getAverageDwellTimeCalculationWindow();
  if (f != null) {
    writer.writeMessage(
      20,
      f,
      proto.vtv1.NullableInt64.serializeBinaryToWriter
    );
  }
  f = message.getDirections();
  if (f != null) {
    writer.writeMessage(
      21,
      f,
      proto.vtv1.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getLoadingInstructions();
  if (f != null) {
    writer.writeMessage(
      22,
      f,
      proto.vtv1.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getSiteProductionPlansList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      23,
      f,
      proto.vtv1.SiteSubInformationBoolean.serializeBinaryToWriter
    );
  }
  f = message.getSiteInventoriesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      24,
      f,
      proto.vtv1.SiteSubInformationBoolean.serializeBinaryToWriter
    );
  }
  f = message.getInventoryStockOutRiskIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      25,
      f
    );
  }
  f = message.getSourcingRiskDemandSignalIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      26,
      f
    );
  }
  f = message.getOrdersNeedingAttentionIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      27,
      f
    );
  }
  f = message.getBatchesNeedingUpdateIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      28,
      f
    );
  }
  f = message.getHasOutboundCarriers();
  if (f) {
    writer.writeBool(
      29,
      f
    );
  }
  f = message.getSalesOrder();
  if (f != null) {
    writer.writeMessage(
      30,
      f,
      proto.vtv1.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getFacilityCode();
  if (f != null) {
    writer.writeMessage(
      31,
      f,
      proto.vtv1.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getTimezone();
  if (f.length > 0) {
    writer.writeString(
      32,
      f
    );
  }
  f = message.getDistrict();
  if (f != null) {
    writer.writeMessage(
      33,
      f,
      proto.vtv1.District.serializeBinaryToWriter
    );
  }
  f = message.getTaxPercent();
  if (f != null) {
    writer.writeMessage(
      34,
      f,
      proto.vtv1.NullableDouble.serializeBinaryToWriter
    );
  }
  f = message.getFulfillmentSitesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      35,
      f
    );
  }
  f = message.getSiteSalesOrder();
  if (f != null) {
    writer.writeMessage(
      36,
      f,
      proto.vtv1.SalesOrder.serializeBinaryToWriter
    );
  }
  f = message.getSiteCostCenter();
  if (f != null) {
    writer.writeMessage(
      37,
      f,
      proto.vtv1.CostCenter.serializeBinaryToWriter
    );
  }
  f = message.getLocationId();
  if (f != null) {
    writer.writeMessage(
      38,
      f,
      proto.vtv1.NullableString.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.vtv1.Site.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.Site} returns this
 */
proto.vtv1.Site.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.vtv1.Site.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.Site} returns this
 */
proto.vtv1.Site.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string account_id = 3;
 * @return {string}
 */
proto.vtv1.Site.prototype.getAccountId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.Site} returns this
 */
proto.vtv1.Site.prototype.setAccountId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string account_name = 4;
 * @return {string}
 */
proto.vtv1.Site.prototype.getAccountName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.Site} returns this
 */
proto.vtv1.Site.prototype.setAccountName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional double longitude = 5;
 * @return {number}
 */
proto.vtv1.Site.prototype.getLongitude = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.Site} returns this
 */
proto.vtv1.Site.prototype.setLongitude = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional double latitude = 6;
 * @return {number}
 */
proto.vtv1.Site.prototype.getLatitude = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.Site} returns this
 */
proto.vtv1.Site.prototype.setLatitude = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional double radius = 7;
 * @return {number}
 */
proto.vtv1.Site.prototype.getRadius = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.Site} returns this
 */
proto.vtv1.Site.prototype.setRadius = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional NullableInt64 site_group_id = 8;
 * @return {?proto.vtv1.NullableInt64}
 */
proto.vtv1.Site.prototype.getSiteGroupId = function() {
  return /** @type{?proto.vtv1.NullableInt64} */ (
    jspb.Message.getWrapperField(this, proto.vtv1.NullableInt64, 8));
};


/**
 * @param {?proto.vtv1.NullableInt64|undefined} value
 * @return {!proto.vtv1.Site} returns this
*/
proto.vtv1.Site.prototype.setSiteGroupId = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.Site} returns this
 */
proto.vtv1.Site.prototype.clearSiteGroupId = function() {
  return this.setSiteGroupId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.Site.prototype.hasSiteGroupId = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional NullableString site_group_name = 9;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.Site.prototype.getSiteGroupName = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto.vtv1.NullableString, 9));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.Site} returns this
*/
proto.vtv1.Site.prototype.setSiteGroupName = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.Site} returns this
 */
proto.vtv1.Site.prototype.clearSiteGroupName = function() {
  return this.setSiteGroupName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.Site.prototype.hasSiteGroupName = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional NullableTime archived_at = 10;
 * @return {?proto.vtv1.NullableTime}
 */
proto.vtv1.Site.prototype.getArchivedAt = function() {
  return /** @type{?proto.vtv1.NullableTime} */ (
    jspb.Message.getWrapperField(this, proto.vtv1.NullableTime, 10));
};


/**
 * @param {?proto.vtv1.NullableTime|undefined} value
 * @return {!proto.vtv1.Site} returns this
*/
proto.vtv1.Site.prototype.setArchivedAt = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.Site} returns this
 */
proto.vtv1.Site.prototype.clearArchivedAt = function() {
  return this.setArchivedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.Site.prototype.hasArchivedAt = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional NullableInt64 number_of_bays = 11;
 * @return {?proto.vtv1.NullableInt64}
 */
proto.vtv1.Site.prototype.getNumberOfBays = function() {
  return /** @type{?proto.vtv1.NullableInt64} */ (
    jspb.Message.getWrapperField(this, proto.vtv1.NullableInt64, 11));
};


/**
 * @param {?proto.vtv1.NullableInt64|undefined} value
 * @return {!proto.vtv1.Site} returns this
*/
proto.vtv1.Site.prototype.setNumberOfBays = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.Site} returns this
 */
proto.vtv1.Site.prototype.clearNumberOfBays = function() {
  return this.setNumberOfBays(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.Site.prototype.hasNumberOfBays = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional NullableInt64 default_delivery_window_minutes = 12;
 * @return {?proto.vtv1.NullableInt64}
 */
proto.vtv1.Site.prototype.getDefaultDeliveryWindowMinutes = function() {
  return /** @type{?proto.vtv1.NullableInt64} */ (
    jspb.Message.getWrapperField(this, proto.vtv1.NullableInt64, 12));
};


/**
 * @param {?proto.vtv1.NullableInt64|undefined} value
 * @return {!proto.vtv1.Site} returns this
*/
proto.vtv1.Site.prototype.setDefaultDeliveryWindowMinutes = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.Site} returns this
 */
proto.vtv1.Site.prototype.clearDefaultDeliveryWindowMinutes = function() {
  return this.setDefaultDeliveryWindowMinutes(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.Site.prototype.hasDefaultDeliveryWindowMinutes = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * repeated SiteLoadingScheduleEntry schedule_entries = 13;
 * @return {!Array<!proto.vtv1.SiteLoadingScheduleEntry>}
 */
proto.vtv1.Site.prototype.getScheduleEntriesList = function() {
  return /** @type{!Array<!proto.vtv1.SiteLoadingScheduleEntry>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vtv1.SiteLoadingScheduleEntry, 13));
};


/**
 * @param {!Array<!proto.vtv1.SiteLoadingScheduleEntry>} value
 * @return {!proto.vtv1.Site} returns this
*/
proto.vtv1.Site.prototype.setScheduleEntriesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 13, value);
};


/**
 * @param {!proto.vtv1.SiteLoadingScheduleEntry=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.SiteLoadingScheduleEntry}
 */
proto.vtv1.Site.prototype.addScheduleEntries = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 13, opt_value, proto.vtv1.SiteLoadingScheduleEntry, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.Site} returns this
 */
proto.vtv1.Site.prototype.clearScheduleEntriesList = function() {
  return this.setScheduleEntriesList([]);
};


/**
 * optional NullableString address = 14;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.Site.prototype.getAddress = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto.vtv1.NullableString, 14));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.Site} returns this
*/
proto.vtv1.Site.prototype.setAddress = function(value) {
  return jspb.Message.setWrapperField(this, 14, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.Site} returns this
 */
proto.vtv1.Site.prototype.clearAddress = function() {
  return this.setAddress(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.Site.prototype.hasAddress = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * repeated ProductionPlanGroup production_plans = 15;
 * @return {!Array<!proto.vtv1.ProductionPlanGroup>}
 */
proto.vtv1.Site.prototype.getProductionPlansList = function() {
  return /** @type{!Array<!proto.vtv1.ProductionPlanGroup>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vtv1.ProductionPlanGroup, 15));
};


/**
 * @param {!Array<!proto.vtv1.ProductionPlanGroup>} value
 * @return {!proto.vtv1.Site} returns this
*/
proto.vtv1.Site.prototype.setProductionPlansList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 15, value);
};


/**
 * @param {!proto.vtv1.ProductionPlanGroup=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.ProductionPlanGroup}
 */
proto.vtv1.Site.prototype.addProductionPlans = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 15, opt_value, proto.vtv1.ProductionPlanGroup, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.Site} returns this
 */
proto.vtv1.Site.prototype.clearProductionPlansList = function() {
  return this.setProductionPlansList([]);
};


/**
 * optional SiteStatistics statistics = 16;
 * @return {?proto.vtv1.SiteStatistics}
 */
proto.vtv1.Site.prototype.getStatistics = function() {
  return /** @type{?proto.vtv1.SiteStatistics} */ (
    jspb.Message.getWrapperField(this, proto.vtv1.SiteStatistics, 16));
};


/**
 * @param {?proto.vtv1.SiteStatistics|undefined} value
 * @return {!proto.vtv1.Site} returns this
*/
proto.vtv1.Site.prototype.setStatistics = function(value) {
  return jspb.Message.setWrapperField(this, 16, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.Site} returns this
 */
proto.vtv1.Site.prototype.clearStatistics = function() {
  return this.setStatistics(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.Site.prototype.hasStatistics = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * repeated ProductionScheduleDay production_schedule_days = 17;
 * @return {!Array<!proto.vtv1.ProductionScheduleDay>}
 */
proto.vtv1.Site.prototype.getProductionScheduleDaysList = function() {
  return /** @type{!Array<!proto.vtv1.ProductionScheduleDay>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vtv1.ProductionScheduleDay, 17));
};


/**
 * @param {!Array<!proto.vtv1.ProductionScheduleDay>} value
 * @return {!proto.vtv1.Site} returns this
*/
proto.vtv1.Site.prototype.setProductionScheduleDaysList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 17, value);
};


/**
 * @param {!proto.vtv1.ProductionScheduleDay=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.ProductionScheduleDay}
 */
proto.vtv1.Site.prototype.addProductionScheduleDays = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 17, opt_value, proto.vtv1.ProductionScheduleDay, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.Site} returns this
 */
proto.vtv1.Site.prototype.clearProductionScheduleDaysList = function() {
  return this.setProductionScheduleDaysList([]);
};


/**
 * optional NullableString last_stock_out_day = 18;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.Site.prototype.getLastStockOutDay = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto.vtv1.NullableString, 18));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.Site} returns this
*/
proto.vtv1.Site.prototype.setLastStockOutDay = function(value) {
  return jspb.Message.setWrapperField(this, 18, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.Site} returns this
 */
proto.vtv1.Site.prototype.clearLastStockOutDay = function() {
  return this.setLastStockOutDay(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.Site.prototype.hasLastStockOutDay = function() {
  return jspb.Message.getField(this, 18) != null;
};


/**
 * optional NullableInt64 desired_max_supplier_response_time_hours = 19;
 * @return {?proto.vtv1.NullableInt64}
 */
proto.vtv1.Site.prototype.getDesiredMaxSupplierResponseTimeHours = function() {
  return /** @type{?proto.vtv1.NullableInt64} */ (
    jspb.Message.getWrapperField(this, proto.vtv1.NullableInt64, 19));
};


/**
 * @param {?proto.vtv1.NullableInt64|undefined} value
 * @return {!proto.vtv1.Site} returns this
*/
proto.vtv1.Site.prototype.setDesiredMaxSupplierResponseTimeHours = function(value) {
  return jspb.Message.setWrapperField(this, 19, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.Site} returns this
 */
proto.vtv1.Site.prototype.clearDesiredMaxSupplierResponseTimeHours = function() {
  return this.setDesiredMaxSupplierResponseTimeHours(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.Site.prototype.hasDesiredMaxSupplierResponseTimeHours = function() {
  return jspb.Message.getField(this, 19) != null;
};


/**
 * optional NullableInt64 average_dwell_time_calculation_window = 20;
 * @return {?proto.vtv1.NullableInt64}
 */
proto.vtv1.Site.prototype.getAverageDwellTimeCalculationWindow = function() {
  return /** @type{?proto.vtv1.NullableInt64} */ (
    jspb.Message.getWrapperField(this, proto.vtv1.NullableInt64, 20));
};


/**
 * @param {?proto.vtv1.NullableInt64|undefined} value
 * @return {!proto.vtv1.Site} returns this
*/
proto.vtv1.Site.prototype.setAverageDwellTimeCalculationWindow = function(value) {
  return jspb.Message.setWrapperField(this, 20, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.Site} returns this
 */
proto.vtv1.Site.prototype.clearAverageDwellTimeCalculationWindow = function() {
  return this.setAverageDwellTimeCalculationWindow(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.Site.prototype.hasAverageDwellTimeCalculationWindow = function() {
  return jspb.Message.getField(this, 20) != null;
};


/**
 * optional NullableString directions = 21;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.Site.prototype.getDirections = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto.vtv1.NullableString, 21));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.Site} returns this
*/
proto.vtv1.Site.prototype.setDirections = function(value) {
  return jspb.Message.setWrapperField(this, 21, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.Site} returns this
 */
proto.vtv1.Site.prototype.clearDirections = function() {
  return this.setDirections(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.Site.prototype.hasDirections = function() {
  return jspb.Message.getField(this, 21) != null;
};


/**
 * optional NullableString loading_instructions = 22;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.Site.prototype.getLoadingInstructions = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto.vtv1.NullableString, 22));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.Site} returns this
*/
proto.vtv1.Site.prototype.setLoadingInstructions = function(value) {
  return jspb.Message.setWrapperField(this, 22, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.Site} returns this
 */
proto.vtv1.Site.prototype.clearLoadingInstructions = function() {
  return this.setLoadingInstructions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.Site.prototype.hasLoadingInstructions = function() {
  return jspb.Message.getField(this, 22) != null;
};


/**
 * repeated SiteSubInformationBoolean site_production_plans = 23;
 * @return {!Array<!proto.vtv1.SiteSubInformationBoolean>}
 */
proto.vtv1.Site.prototype.getSiteProductionPlansList = function() {
  return /** @type{!Array<!proto.vtv1.SiteSubInformationBoolean>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vtv1.SiteSubInformationBoolean, 23));
};


/**
 * @param {!Array<!proto.vtv1.SiteSubInformationBoolean>} value
 * @return {!proto.vtv1.Site} returns this
*/
proto.vtv1.Site.prototype.setSiteProductionPlansList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 23, value);
};


/**
 * @param {!proto.vtv1.SiteSubInformationBoolean=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.SiteSubInformationBoolean}
 */
proto.vtv1.Site.prototype.addSiteProductionPlans = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 23, opt_value, proto.vtv1.SiteSubInformationBoolean, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.Site} returns this
 */
proto.vtv1.Site.prototype.clearSiteProductionPlansList = function() {
  return this.setSiteProductionPlansList([]);
};


/**
 * repeated SiteSubInformationBoolean site_inventories = 24;
 * @return {!Array<!proto.vtv1.SiteSubInformationBoolean>}
 */
proto.vtv1.Site.prototype.getSiteInventoriesList = function() {
  return /** @type{!Array<!proto.vtv1.SiteSubInformationBoolean>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vtv1.SiteSubInformationBoolean, 24));
};


/**
 * @param {!Array<!proto.vtv1.SiteSubInformationBoolean>} value
 * @return {!proto.vtv1.Site} returns this
*/
proto.vtv1.Site.prototype.setSiteInventoriesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 24, value);
};


/**
 * @param {!proto.vtv1.SiteSubInformationBoolean=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.SiteSubInformationBoolean}
 */
proto.vtv1.Site.prototype.addSiteInventories = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 24, opt_value, proto.vtv1.SiteSubInformationBoolean, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.Site} returns this
 */
proto.vtv1.Site.prototype.clearSiteInventoriesList = function() {
  return this.setSiteInventoriesList([]);
};


/**
 * repeated string inventory_stock_out_risk_ids = 25;
 * @return {!Array<string>}
 */
proto.vtv1.Site.prototype.getInventoryStockOutRiskIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 25));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.vtv1.Site} returns this
 */
proto.vtv1.Site.prototype.setInventoryStockOutRiskIdsList = function(value) {
  return jspb.Message.setField(this, 25, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.vtv1.Site} returns this
 */
proto.vtv1.Site.prototype.addInventoryStockOutRiskIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 25, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.Site} returns this
 */
proto.vtv1.Site.prototype.clearInventoryStockOutRiskIdsList = function() {
  return this.setInventoryStockOutRiskIdsList([]);
};


/**
 * repeated string sourcing_risk_demand_signal_ids = 26;
 * @return {!Array<string>}
 */
proto.vtv1.Site.prototype.getSourcingRiskDemandSignalIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 26));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.vtv1.Site} returns this
 */
proto.vtv1.Site.prototype.setSourcingRiskDemandSignalIdsList = function(value) {
  return jspb.Message.setField(this, 26, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.vtv1.Site} returns this
 */
proto.vtv1.Site.prototype.addSourcingRiskDemandSignalIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 26, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.Site} returns this
 */
proto.vtv1.Site.prototype.clearSourcingRiskDemandSignalIdsList = function() {
  return this.setSourcingRiskDemandSignalIdsList([]);
};


/**
 * repeated string orders_needing_attention_ids = 27;
 * @return {!Array<string>}
 */
proto.vtv1.Site.prototype.getOrdersNeedingAttentionIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 27));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.vtv1.Site} returns this
 */
proto.vtv1.Site.prototype.setOrdersNeedingAttentionIdsList = function(value) {
  return jspb.Message.setField(this, 27, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.vtv1.Site} returns this
 */
proto.vtv1.Site.prototype.addOrdersNeedingAttentionIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 27, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.Site} returns this
 */
proto.vtv1.Site.prototype.clearOrdersNeedingAttentionIdsList = function() {
  return this.setOrdersNeedingAttentionIdsList([]);
};


/**
 * repeated string batches_needing_update_ids = 28;
 * @return {!Array<string>}
 */
proto.vtv1.Site.prototype.getBatchesNeedingUpdateIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 28));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.vtv1.Site} returns this
 */
proto.vtv1.Site.prototype.setBatchesNeedingUpdateIdsList = function(value) {
  return jspb.Message.setField(this, 28, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.vtv1.Site} returns this
 */
proto.vtv1.Site.prototype.addBatchesNeedingUpdateIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 28, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.Site} returns this
 */
proto.vtv1.Site.prototype.clearBatchesNeedingUpdateIdsList = function() {
  return this.setBatchesNeedingUpdateIdsList([]);
};


/**
 * optional bool has_outbound_carriers = 29;
 * @return {boolean}
 */
proto.vtv1.Site.prototype.getHasOutboundCarriers = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 29, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vtv1.Site} returns this
 */
proto.vtv1.Site.prototype.setHasOutboundCarriers = function(value) {
  return jspb.Message.setProto3BooleanField(this, 29, value);
};


/**
 * optional NullableString sales_order = 30;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.Site.prototype.getSalesOrder = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto.vtv1.NullableString, 30));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.Site} returns this
*/
proto.vtv1.Site.prototype.setSalesOrder = function(value) {
  return jspb.Message.setWrapperField(this, 30, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.Site} returns this
 */
proto.vtv1.Site.prototype.clearSalesOrder = function() {
  return this.setSalesOrder(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.Site.prototype.hasSalesOrder = function() {
  return jspb.Message.getField(this, 30) != null;
};


/**
 * optional NullableString facility_code = 31;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.Site.prototype.getFacilityCode = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto.vtv1.NullableString, 31));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.Site} returns this
*/
proto.vtv1.Site.prototype.setFacilityCode = function(value) {
  return jspb.Message.setWrapperField(this, 31, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.Site} returns this
 */
proto.vtv1.Site.prototype.clearFacilityCode = function() {
  return this.setFacilityCode(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.Site.prototype.hasFacilityCode = function() {
  return jspb.Message.getField(this, 31) != null;
};


/**
 * optional string timezone = 32;
 * @return {string}
 */
proto.vtv1.Site.prototype.getTimezone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 32, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.Site} returns this
 */
proto.vtv1.Site.prototype.setTimezone = function(value) {
  return jspb.Message.setProto3StringField(this, 32, value);
};


/**
 * optional District district = 33;
 * @return {?proto.vtv1.District}
 */
proto.vtv1.Site.prototype.getDistrict = function() {
  return /** @type{?proto.vtv1.District} */ (
    jspb.Message.getWrapperField(this, proto.vtv1.District, 33));
};


/**
 * @param {?proto.vtv1.District|undefined} value
 * @return {!proto.vtv1.Site} returns this
*/
proto.vtv1.Site.prototype.setDistrict = function(value) {
  return jspb.Message.setWrapperField(this, 33, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.Site} returns this
 */
proto.vtv1.Site.prototype.clearDistrict = function() {
  return this.setDistrict(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.Site.prototype.hasDistrict = function() {
  return jspb.Message.getField(this, 33) != null;
};


/**
 * optional NullableDouble tax_percent = 34;
 * @return {?proto.vtv1.NullableDouble}
 */
proto.vtv1.Site.prototype.getTaxPercent = function() {
  return /** @type{?proto.vtv1.NullableDouble} */ (
    jspb.Message.getWrapperField(this, proto.vtv1.NullableDouble, 34));
};


/**
 * @param {?proto.vtv1.NullableDouble|undefined} value
 * @return {!proto.vtv1.Site} returns this
*/
proto.vtv1.Site.prototype.setTaxPercent = function(value) {
  return jspb.Message.setWrapperField(this, 34, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.Site} returns this
 */
proto.vtv1.Site.prototype.clearTaxPercent = function() {
  return this.setTaxPercent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.Site.prototype.hasTaxPercent = function() {
  return jspb.Message.getField(this, 34) != null;
};


/**
 * repeated string fulfillment_sites = 35;
 * @return {!Array<string>}
 */
proto.vtv1.Site.prototype.getFulfillmentSitesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 35));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.vtv1.Site} returns this
 */
proto.vtv1.Site.prototype.setFulfillmentSitesList = function(value) {
  return jspb.Message.setField(this, 35, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.vtv1.Site} returns this
 */
proto.vtv1.Site.prototype.addFulfillmentSites = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 35, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.Site} returns this
 */
proto.vtv1.Site.prototype.clearFulfillmentSitesList = function() {
  return this.setFulfillmentSitesList([]);
};


/**
 * optional SalesOrder site_sales_order = 36;
 * @return {?proto.vtv1.SalesOrder}
 */
proto.vtv1.Site.prototype.getSiteSalesOrder = function() {
  return /** @type{?proto.vtv1.SalesOrder} */ (
    jspb.Message.getWrapperField(this, proto.vtv1.SalesOrder, 36));
};


/**
 * @param {?proto.vtv1.SalesOrder|undefined} value
 * @return {!proto.vtv1.Site} returns this
*/
proto.vtv1.Site.prototype.setSiteSalesOrder = function(value) {
  return jspb.Message.setWrapperField(this, 36, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.Site} returns this
 */
proto.vtv1.Site.prototype.clearSiteSalesOrder = function() {
  return this.setSiteSalesOrder(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.Site.prototype.hasSiteSalesOrder = function() {
  return jspb.Message.getField(this, 36) != null;
};


/**
 * optional CostCenter site_cost_center = 37;
 * @return {?proto.vtv1.CostCenter}
 */
proto.vtv1.Site.prototype.getSiteCostCenter = function() {
  return /** @type{?proto.vtv1.CostCenter} */ (
    jspb.Message.getWrapperField(this, proto.vtv1.CostCenter, 37));
};


/**
 * @param {?proto.vtv1.CostCenter|undefined} value
 * @return {!proto.vtv1.Site} returns this
*/
proto.vtv1.Site.prototype.setSiteCostCenter = function(value) {
  return jspb.Message.setWrapperField(this, 37, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.Site} returns this
 */
proto.vtv1.Site.prototype.clearSiteCostCenter = function() {
  return this.setSiteCostCenter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.Site.prototype.hasSiteCostCenter = function() {
  return jspb.Message.getField(this, 37) != null;
};


/**
 * optional NullableString location_id = 38;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.Site.prototype.getLocationId = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto.vtv1.NullableString, 38));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.Site} returns this
*/
proto.vtv1.Site.prototype.setLocationId = function(value) {
  return jspb.Message.setWrapperField(this, 38, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.Site} returns this
 */
proto.vtv1.Site.prototype.clearLocationId = function() {
  return this.setLocationId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.Site.prototype.hasLocationId = function() {
  return jspb.Message.getField(this, 38) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.District.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.District.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.District} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.District.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.District}
 */
proto.vtv1.District.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.District;
  return proto.vtv1.District.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.District} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.District}
 */
proto.vtv1.District.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.District.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.District.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.District} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.District.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.vtv1.District.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.District} returns this
 */
proto.vtv1.District.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.vtv1.District.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.District} returns this
 */
proto.vtv1.District.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.DistrictWithIds.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.DistrictWithIds.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.DistrictWithIds} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.DistrictWithIds.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    districtId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.DistrictWithIds}
 */
proto.vtv1.DistrictWithIds.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.DistrictWithIds;
  return proto.vtv1.DistrictWithIds.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.DistrictWithIds} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.DistrictWithIds}
 */
proto.vtv1.DistrictWithIds.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDistrictId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.DistrictWithIds.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.DistrictWithIds.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.DistrictWithIds} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.DistrictWithIds.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDistrictId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.vtv1.DistrictWithIds.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.DistrictWithIds} returns this
 */
proto.vtv1.DistrictWithIds.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string district_id = 2;
 * @return {string}
 */
proto.vtv1.DistrictWithIds.prototype.getDistrictId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.DistrictWithIds} returns this
 */
proto.vtv1.DistrictWithIds.prototype.setDistrictId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.SiteSubInformationBoolean.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.SiteSubInformationBoolean.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.SiteSubInformationBoolean} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.SiteSubInformationBoolean.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    isgood: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.SiteSubInformationBoolean}
 */
proto.vtv1.SiteSubInformationBoolean.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.SiteSubInformationBoolean;
  return proto.vtv1.SiteSubInformationBoolean.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.SiteSubInformationBoolean} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.SiteSubInformationBoolean}
 */
proto.vtv1.SiteSubInformationBoolean.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsgood(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.SiteSubInformationBoolean.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.SiteSubInformationBoolean.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.SiteSubInformationBoolean} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.SiteSubInformationBoolean.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getIsgood();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.vtv1.SiteSubInformationBoolean.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.SiteSubInformationBoolean} returns this
 */
proto.vtv1.SiteSubInformationBoolean.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.vtv1.SiteSubInformationBoolean.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.SiteSubInformationBoolean} returns this
 */
proto.vtv1.SiteSubInformationBoolean.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool isGood = 3;
 * @return {boolean}
 */
proto.vtv1.SiteSubInformationBoolean.prototype.getIsgood = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vtv1.SiteSubInformationBoolean} returns this
 */
proto.vtv1.SiteSubInformationBoolean.prototype.setIsgood = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.SlimSite.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.SlimSite.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.SlimSite} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.SlimSite.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    siteGroupName: (f = msg.getSiteGroupName()) && proto.vtv1.NullableString.toObject(includeInstance, f),
    siteGroupId: (f = msg.getSiteGroupId()) && proto.vtv1.NullableInt64.toObject(includeInstance, f),
    siteArchivedAt: (f = msg.getSiteArchivedAt()) && proto.vtv1.NullableTime.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.SlimSite}
 */
proto.vtv1.SlimSite.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.SlimSite;
  return proto.vtv1.SlimSite.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.SlimSite} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.SlimSite}
 */
proto.vtv1.SlimSite.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = new proto.vtv1.NullableString;
      reader.readMessage(value,proto.vtv1.NullableString.deserializeBinaryFromReader);
      msg.setSiteGroupName(value);
      break;
    case 4:
      var value = new proto.vtv1.NullableInt64;
      reader.readMessage(value,proto.vtv1.NullableInt64.deserializeBinaryFromReader);
      msg.setSiteGroupId(value);
      break;
    case 5:
      var value = new proto.vtv1.NullableTime;
      reader.readMessage(value,proto.vtv1.NullableTime.deserializeBinaryFromReader);
      msg.setSiteArchivedAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.SlimSite.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.SlimSite.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.SlimSite} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.SlimSite.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSiteGroupName();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.vtv1.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getSiteGroupId();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.vtv1.NullableInt64.serializeBinaryToWriter
    );
  }
  f = message.getSiteArchivedAt();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.vtv1.NullableTime.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.vtv1.SlimSite.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.SlimSite} returns this
 */
proto.vtv1.SlimSite.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.vtv1.SlimSite.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.SlimSite} returns this
 */
proto.vtv1.SlimSite.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional NullableString site_group_name = 3;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.SlimSite.prototype.getSiteGroupName = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto.vtv1.NullableString, 3));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.SlimSite} returns this
*/
proto.vtv1.SlimSite.prototype.setSiteGroupName = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.SlimSite} returns this
 */
proto.vtv1.SlimSite.prototype.clearSiteGroupName = function() {
  return this.setSiteGroupName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.SlimSite.prototype.hasSiteGroupName = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional NullableInt64 site_group_id = 4;
 * @return {?proto.vtv1.NullableInt64}
 */
proto.vtv1.SlimSite.prototype.getSiteGroupId = function() {
  return /** @type{?proto.vtv1.NullableInt64} */ (
    jspb.Message.getWrapperField(this, proto.vtv1.NullableInt64, 4));
};


/**
 * @param {?proto.vtv1.NullableInt64|undefined} value
 * @return {!proto.vtv1.SlimSite} returns this
*/
proto.vtv1.SlimSite.prototype.setSiteGroupId = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.SlimSite} returns this
 */
proto.vtv1.SlimSite.prototype.clearSiteGroupId = function() {
  return this.setSiteGroupId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.SlimSite.prototype.hasSiteGroupId = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional NullableTime site_archived_at = 5;
 * @return {?proto.vtv1.NullableTime}
 */
proto.vtv1.SlimSite.prototype.getSiteArchivedAt = function() {
  return /** @type{?proto.vtv1.NullableTime} */ (
    jspb.Message.getWrapperField(this, proto.vtv1.NullableTime, 5));
};


/**
 * @param {?proto.vtv1.NullableTime|undefined} value
 * @return {!proto.vtv1.SlimSite} returns this
*/
proto.vtv1.SlimSite.prototype.setSiteArchivedAt = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.SlimSite} returns this
 */
proto.vtv1.SlimSite.prototype.clearSiteArchivedAt = function() {
  return this.setSiteArchivedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.SlimSite.prototype.hasSiteArchivedAt = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.SlimSiteWithLngLat.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.SlimSiteWithLngLat.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.SlimSiteWithLngLat} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.SlimSiteWithLngLat.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    siteGroupName: (f = msg.getSiteGroupName()) && proto.vtv1.NullableString.toObject(includeInstance, f),
    siteGroupId: (f = msg.getSiteGroupId()) && proto.vtv1.NullableInt64.toObject(includeInstance, f),
    lng: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    lat: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    salesOrder: (f = msg.getSalesOrder()) && proto.vtv1.NullableString.toObject(includeInstance, f),
    facilityCode: (f = msg.getFacilityCode()) && proto.vtv1.NullableString.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.SlimSiteWithLngLat}
 */
proto.vtv1.SlimSiteWithLngLat.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.SlimSiteWithLngLat;
  return proto.vtv1.SlimSiteWithLngLat.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.SlimSiteWithLngLat} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.SlimSiteWithLngLat}
 */
proto.vtv1.SlimSiteWithLngLat.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = new proto.vtv1.NullableString;
      reader.readMessage(value,proto.vtv1.NullableString.deserializeBinaryFromReader);
      msg.setSiteGroupName(value);
      break;
    case 4:
      var value = new proto.vtv1.NullableInt64;
      reader.readMessage(value,proto.vtv1.NullableInt64.deserializeBinaryFromReader);
      msg.setSiteGroupId(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLng(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLat(value);
      break;
    case 7:
      var value = new proto.vtv1.NullableString;
      reader.readMessage(value,proto.vtv1.NullableString.deserializeBinaryFromReader);
      msg.setSalesOrder(value);
      break;
    case 8:
      var value = new proto.vtv1.NullableString;
      reader.readMessage(value,proto.vtv1.NullableString.deserializeBinaryFromReader);
      msg.setFacilityCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.SlimSiteWithLngLat.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.SlimSiteWithLngLat.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.SlimSiteWithLngLat} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.SlimSiteWithLngLat.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSiteGroupName();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.vtv1.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getSiteGroupId();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.vtv1.NullableInt64.serializeBinaryToWriter
    );
  }
  f = message.getLng();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getLat();
  if (f !== 0.0) {
    writer.writeDouble(
      6,
      f
    );
  }
  f = message.getSalesOrder();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.vtv1.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getFacilityCode();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.vtv1.NullableString.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.vtv1.SlimSiteWithLngLat.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.SlimSiteWithLngLat} returns this
 */
proto.vtv1.SlimSiteWithLngLat.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.vtv1.SlimSiteWithLngLat.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.SlimSiteWithLngLat} returns this
 */
proto.vtv1.SlimSiteWithLngLat.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional NullableString site_group_name = 3;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.SlimSiteWithLngLat.prototype.getSiteGroupName = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto.vtv1.NullableString, 3));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.SlimSiteWithLngLat} returns this
*/
proto.vtv1.SlimSiteWithLngLat.prototype.setSiteGroupName = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.SlimSiteWithLngLat} returns this
 */
proto.vtv1.SlimSiteWithLngLat.prototype.clearSiteGroupName = function() {
  return this.setSiteGroupName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.SlimSiteWithLngLat.prototype.hasSiteGroupName = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional NullableInt64 site_group_id = 4;
 * @return {?proto.vtv1.NullableInt64}
 */
proto.vtv1.SlimSiteWithLngLat.prototype.getSiteGroupId = function() {
  return /** @type{?proto.vtv1.NullableInt64} */ (
    jspb.Message.getWrapperField(this, proto.vtv1.NullableInt64, 4));
};


/**
 * @param {?proto.vtv1.NullableInt64|undefined} value
 * @return {!proto.vtv1.SlimSiteWithLngLat} returns this
*/
proto.vtv1.SlimSiteWithLngLat.prototype.setSiteGroupId = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.SlimSiteWithLngLat} returns this
 */
proto.vtv1.SlimSiteWithLngLat.prototype.clearSiteGroupId = function() {
  return this.setSiteGroupId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.SlimSiteWithLngLat.prototype.hasSiteGroupId = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional double lng = 5;
 * @return {number}
 */
proto.vtv1.SlimSiteWithLngLat.prototype.getLng = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.SlimSiteWithLngLat} returns this
 */
proto.vtv1.SlimSiteWithLngLat.prototype.setLng = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional double lat = 6;
 * @return {number}
 */
proto.vtv1.SlimSiteWithLngLat.prototype.getLat = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.SlimSiteWithLngLat} returns this
 */
proto.vtv1.SlimSiteWithLngLat.prototype.setLat = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional NullableString sales_order = 7;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.SlimSiteWithLngLat.prototype.getSalesOrder = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto.vtv1.NullableString, 7));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.SlimSiteWithLngLat} returns this
*/
proto.vtv1.SlimSiteWithLngLat.prototype.setSalesOrder = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.SlimSiteWithLngLat} returns this
 */
proto.vtv1.SlimSiteWithLngLat.prototype.clearSalesOrder = function() {
  return this.setSalesOrder(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.SlimSiteWithLngLat.prototype.hasSalesOrder = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional NullableString facility_code = 8;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.SlimSiteWithLngLat.prototype.getFacilityCode = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto.vtv1.NullableString, 8));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.SlimSiteWithLngLat} returns this
*/
proto.vtv1.SlimSiteWithLngLat.prototype.setFacilityCode = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.SlimSiteWithLngLat} returns this
 */
proto.vtv1.SlimSiteWithLngLat.prototype.clearFacilityCode = function() {
  return this.setFacilityCode(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.SlimSiteWithLngLat.prototype.hasFacilityCode = function() {
  return jspb.Message.getField(this, 8) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.SlimSiteWithPickupWindow.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.SlimSiteWithPickupWindow.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.SlimSiteWithPickupWindow} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.SlimSiteWithPickupWindow.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    siteGroupName: (f = msg.getSiteGroupName()) && proto.vtv1.NullableString.toObject(includeInstance, f),
    siteGroupId: (f = msg.getSiteGroupId()) && proto.vtv1.NullableInt64.toObject(includeInstance, f),
    siteArchivedAt: (f = msg.getSiteArchivedAt()) && proto.vtv1.NullableTime.toObject(includeInstance, f),
    pickupWindowStartsAt: (f = msg.getPickupWindowStartsAt()) && proto.vtv1.NullableTime.toObject(includeInstance, f),
    pickupWindowEndsAt: (f = msg.getPickupWindowEndsAt()) && proto.vtv1.NullableTime.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.SlimSiteWithPickupWindow}
 */
proto.vtv1.SlimSiteWithPickupWindow.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.SlimSiteWithPickupWindow;
  return proto.vtv1.SlimSiteWithPickupWindow.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.SlimSiteWithPickupWindow} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.SlimSiteWithPickupWindow}
 */
proto.vtv1.SlimSiteWithPickupWindow.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = new proto.vtv1.NullableString;
      reader.readMessage(value,proto.vtv1.NullableString.deserializeBinaryFromReader);
      msg.setSiteGroupName(value);
      break;
    case 4:
      var value = new proto.vtv1.NullableInt64;
      reader.readMessage(value,proto.vtv1.NullableInt64.deserializeBinaryFromReader);
      msg.setSiteGroupId(value);
      break;
    case 5:
      var value = new proto.vtv1.NullableTime;
      reader.readMessage(value,proto.vtv1.NullableTime.deserializeBinaryFromReader);
      msg.setSiteArchivedAt(value);
      break;
    case 6:
      var value = new proto.vtv1.NullableTime;
      reader.readMessage(value,proto.vtv1.NullableTime.deserializeBinaryFromReader);
      msg.setPickupWindowStartsAt(value);
      break;
    case 7:
      var value = new proto.vtv1.NullableTime;
      reader.readMessage(value,proto.vtv1.NullableTime.deserializeBinaryFromReader);
      msg.setPickupWindowEndsAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.SlimSiteWithPickupWindow.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.SlimSiteWithPickupWindow.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.SlimSiteWithPickupWindow} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.SlimSiteWithPickupWindow.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSiteGroupName();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.vtv1.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getSiteGroupId();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.vtv1.NullableInt64.serializeBinaryToWriter
    );
  }
  f = message.getSiteArchivedAt();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.vtv1.NullableTime.serializeBinaryToWriter
    );
  }
  f = message.getPickupWindowStartsAt();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.vtv1.NullableTime.serializeBinaryToWriter
    );
  }
  f = message.getPickupWindowEndsAt();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.vtv1.NullableTime.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.vtv1.SlimSiteWithPickupWindow.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.SlimSiteWithPickupWindow} returns this
 */
proto.vtv1.SlimSiteWithPickupWindow.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.vtv1.SlimSiteWithPickupWindow.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.SlimSiteWithPickupWindow} returns this
 */
proto.vtv1.SlimSiteWithPickupWindow.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional NullableString site_group_name = 3;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.SlimSiteWithPickupWindow.prototype.getSiteGroupName = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto.vtv1.NullableString, 3));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.SlimSiteWithPickupWindow} returns this
*/
proto.vtv1.SlimSiteWithPickupWindow.prototype.setSiteGroupName = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.SlimSiteWithPickupWindow} returns this
 */
proto.vtv1.SlimSiteWithPickupWindow.prototype.clearSiteGroupName = function() {
  return this.setSiteGroupName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.SlimSiteWithPickupWindow.prototype.hasSiteGroupName = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional NullableInt64 site_group_id = 4;
 * @return {?proto.vtv1.NullableInt64}
 */
proto.vtv1.SlimSiteWithPickupWindow.prototype.getSiteGroupId = function() {
  return /** @type{?proto.vtv1.NullableInt64} */ (
    jspb.Message.getWrapperField(this, proto.vtv1.NullableInt64, 4));
};


/**
 * @param {?proto.vtv1.NullableInt64|undefined} value
 * @return {!proto.vtv1.SlimSiteWithPickupWindow} returns this
*/
proto.vtv1.SlimSiteWithPickupWindow.prototype.setSiteGroupId = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.SlimSiteWithPickupWindow} returns this
 */
proto.vtv1.SlimSiteWithPickupWindow.prototype.clearSiteGroupId = function() {
  return this.setSiteGroupId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.SlimSiteWithPickupWindow.prototype.hasSiteGroupId = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional NullableTime site_archived_at = 5;
 * @return {?proto.vtv1.NullableTime}
 */
proto.vtv1.SlimSiteWithPickupWindow.prototype.getSiteArchivedAt = function() {
  return /** @type{?proto.vtv1.NullableTime} */ (
    jspb.Message.getWrapperField(this, proto.vtv1.NullableTime, 5));
};


/**
 * @param {?proto.vtv1.NullableTime|undefined} value
 * @return {!proto.vtv1.SlimSiteWithPickupWindow} returns this
*/
proto.vtv1.SlimSiteWithPickupWindow.prototype.setSiteArchivedAt = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.SlimSiteWithPickupWindow} returns this
 */
proto.vtv1.SlimSiteWithPickupWindow.prototype.clearSiteArchivedAt = function() {
  return this.setSiteArchivedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.SlimSiteWithPickupWindow.prototype.hasSiteArchivedAt = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional NullableTime pickup_window_starts_at = 6;
 * @return {?proto.vtv1.NullableTime}
 */
proto.vtv1.SlimSiteWithPickupWindow.prototype.getPickupWindowStartsAt = function() {
  return /** @type{?proto.vtv1.NullableTime} */ (
    jspb.Message.getWrapperField(this, proto.vtv1.NullableTime, 6));
};


/**
 * @param {?proto.vtv1.NullableTime|undefined} value
 * @return {!proto.vtv1.SlimSiteWithPickupWindow} returns this
*/
proto.vtv1.SlimSiteWithPickupWindow.prototype.setPickupWindowStartsAt = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.SlimSiteWithPickupWindow} returns this
 */
proto.vtv1.SlimSiteWithPickupWindow.prototype.clearPickupWindowStartsAt = function() {
  return this.setPickupWindowStartsAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.SlimSiteWithPickupWindow.prototype.hasPickupWindowStartsAt = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional NullableTime pickup_window_ends_at = 7;
 * @return {?proto.vtv1.NullableTime}
 */
proto.vtv1.SlimSiteWithPickupWindow.prototype.getPickupWindowEndsAt = function() {
  return /** @type{?proto.vtv1.NullableTime} */ (
    jspb.Message.getWrapperField(this, proto.vtv1.NullableTime, 7));
};


/**
 * @param {?proto.vtv1.NullableTime|undefined} value
 * @return {!proto.vtv1.SlimSiteWithPickupWindow} returns this
*/
proto.vtv1.SlimSiteWithPickupWindow.prototype.setPickupWindowEndsAt = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.SlimSiteWithPickupWindow} returns this
 */
proto.vtv1.SlimSiteWithPickupWindow.prototype.clearPickupWindowEndsAt = function() {
  return this.setPickupWindowEndsAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.SlimSiteWithPickupWindow.prototype.hasPickupWindowEndsAt = function() {
  return jspb.Message.getField(this, 7) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.ActiveSite.repeatedFields_ = [14,15,19,20,21,22];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.ActiveSite.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.ActiveSite.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.ActiveSite} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.ActiveSite.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    siteGroupName: (f = msg.getSiteGroupName()) && proto.vtv1.NullableString.toObject(includeInstance, f),
    siteGroupId: (f = msg.getSiteGroupId()) && proto.vtv1.NullableInt64.toObject(includeInstance, f),
    loadsNeedingAttention: jspb.Message.getFieldWithDefault(msg, 5, 0),
    dwellTimeMinutes: jspb.Message.getFieldWithDefault(msg, 6, 0),
    totalProducts: jspb.Message.getFieldWithDefault(msg, 7, 0),
    automatedProducts: jspb.Message.getFieldWithDefault(msg, 8, 0),
    nptEstimatedAt: (f = msg.getNptEstimatedAt()) && proto.vtv1.NullableTime.toObject(includeInstance, f),
    unfulfilledDemandSignals: jspb.Message.getFieldWithDefault(msg, 10, 0),
    unassignedOrders: jspb.Message.getFieldWithDefault(msg, 11, 0),
    needsAttentionDrivers: jspb.Message.getFieldWithDefault(msg, 12, 0),
    stockOutRisks: jspb.Message.getFieldWithDefault(msg, 13, 0),
    detentionPerDayList: (f = jspb.Message.getRepeatedField(msg, 14)) == null ? undefined : f,
    optimalLoadStatsList: jspb.Message.toObjectList(msg.getOptimalLoadStatsList(),
    proto.vtv1.OptimalLoadsStats.toObject, includeInstance),
    lastStockOutDay: (f = msg.getLastStockOutDay()) && proto.vtv1.NullableString.toObject(includeInstance, f),
    operationsCounts: jspb.Message.getFieldWithDefault(msg, 17, 0),
    rollingDetentionAverage: jspb.Message.getFieldWithDefault(msg, 18, 0),
    inventoryStockOutRiskIdsList: (f = jspb.Message.getRepeatedField(msg, 19)) == null ? undefined : f,
    sourcingRiskDemandSignalIdsList: (f = jspb.Message.getRepeatedField(msg, 20)) == null ? undefined : f,
    ordersNeedingAttentionIdsList: (f = jspb.Message.getRepeatedField(msg, 21)) == null ? undefined : f,
    batchesNeedingUpdateIdsList: (f = jspb.Message.getRepeatedField(msg, 22)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.ActiveSite}
 */
proto.vtv1.ActiveSite.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.ActiveSite;
  return proto.vtv1.ActiveSite.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.ActiveSite} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.ActiveSite}
 */
proto.vtv1.ActiveSite.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = new proto.vtv1.NullableString;
      reader.readMessage(value,proto.vtv1.NullableString.deserializeBinaryFromReader);
      msg.setSiteGroupName(value);
      break;
    case 4:
      var value = new proto.vtv1.NullableInt64;
      reader.readMessage(value,proto.vtv1.NullableInt64.deserializeBinaryFromReader);
      msg.setSiteGroupId(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLoadsNeedingAttention(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDwellTimeMinutes(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotalProducts(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAutomatedProducts(value);
      break;
    case 9:
      var value = new proto.vtv1.NullableTime;
      reader.readMessage(value,proto.vtv1.NullableTime.deserializeBinaryFromReader);
      msg.setNptEstimatedAt(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUnfulfilledDemandSignals(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUnassignedOrders(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setNeedsAttentionDrivers(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStockOutRisks(value);
      break;
    case 14:
      var value = /** @type {!Array<number>} */ (reader.readPackedInt64());
      msg.setDetentionPerDayList(value);
      break;
    case 15:
      var value = new proto.vtv1.OptimalLoadsStats;
      reader.readMessage(value,proto.vtv1.OptimalLoadsStats.deserializeBinaryFromReader);
      msg.addOptimalLoadStats(value);
      break;
    case 16:
      var value = new proto.vtv1.NullableString;
      reader.readMessage(value,proto.vtv1.NullableString.deserializeBinaryFromReader);
      msg.setLastStockOutDay(value);
      break;
    case 17:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setOperationsCounts(value);
      break;
    case 18:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRollingDetentionAverage(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.addInventoryStockOutRiskIds(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.addSourcingRiskDemandSignalIds(value);
      break;
    case 21:
      var value = /** @type {string} */ (reader.readString());
      msg.addOrdersNeedingAttentionIds(value);
      break;
    case 22:
      var value = /** @type {string} */ (reader.readString());
      msg.addBatchesNeedingUpdateIds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.ActiveSite.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.ActiveSite.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.ActiveSite} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.ActiveSite.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSiteGroupName();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.vtv1.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getSiteGroupId();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.vtv1.NullableInt64.serializeBinaryToWriter
    );
  }
  f = message.getLoadsNeedingAttention();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getDwellTimeMinutes();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getTotalProducts();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
  f = message.getAutomatedProducts();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
  f = message.getNptEstimatedAt();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.vtv1.NullableTime.serializeBinaryToWriter
    );
  }
  f = message.getUnfulfilledDemandSignals();
  if (f !== 0) {
    writer.writeInt64(
      10,
      f
    );
  }
  f = message.getUnassignedOrders();
  if (f !== 0) {
    writer.writeInt64(
      11,
      f
    );
  }
  f = message.getNeedsAttentionDrivers();
  if (f !== 0) {
    writer.writeInt64(
      12,
      f
    );
  }
  f = message.getStockOutRisks();
  if (f !== 0) {
    writer.writeInt64(
      13,
      f
    );
  }
  f = message.getDetentionPerDayList();
  if (f.length > 0) {
    writer.writePackedInt64(
      14,
      f
    );
  }
  f = message.getOptimalLoadStatsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      15,
      f,
      proto.vtv1.OptimalLoadsStats.serializeBinaryToWriter
    );
  }
  f = message.getLastStockOutDay();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      proto.vtv1.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getOperationsCounts();
  if (f !== 0) {
    writer.writeInt64(
      17,
      f
    );
  }
  f = message.getRollingDetentionAverage();
  if (f !== 0) {
    writer.writeInt64(
      18,
      f
    );
  }
  f = message.getInventoryStockOutRiskIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      19,
      f
    );
  }
  f = message.getSourcingRiskDemandSignalIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      20,
      f
    );
  }
  f = message.getOrdersNeedingAttentionIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      21,
      f
    );
  }
  f = message.getBatchesNeedingUpdateIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      22,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.vtv1.ActiveSite.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.ActiveSite} returns this
 */
proto.vtv1.ActiveSite.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.vtv1.ActiveSite.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.ActiveSite} returns this
 */
proto.vtv1.ActiveSite.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional NullableString site_group_name = 3;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.ActiveSite.prototype.getSiteGroupName = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto.vtv1.NullableString, 3));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.ActiveSite} returns this
*/
proto.vtv1.ActiveSite.prototype.setSiteGroupName = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.ActiveSite} returns this
 */
proto.vtv1.ActiveSite.prototype.clearSiteGroupName = function() {
  return this.setSiteGroupName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.ActiveSite.prototype.hasSiteGroupName = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional NullableInt64 site_group_id = 4;
 * @return {?proto.vtv1.NullableInt64}
 */
proto.vtv1.ActiveSite.prototype.getSiteGroupId = function() {
  return /** @type{?proto.vtv1.NullableInt64} */ (
    jspb.Message.getWrapperField(this, proto.vtv1.NullableInt64, 4));
};


/**
 * @param {?proto.vtv1.NullableInt64|undefined} value
 * @return {!proto.vtv1.ActiveSite} returns this
*/
proto.vtv1.ActiveSite.prototype.setSiteGroupId = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.ActiveSite} returns this
 */
proto.vtv1.ActiveSite.prototype.clearSiteGroupId = function() {
  return this.setSiteGroupId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.ActiveSite.prototype.hasSiteGroupId = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional int64 loads_needing_attention = 5;
 * @return {number}
 */
proto.vtv1.ActiveSite.prototype.getLoadsNeedingAttention = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.ActiveSite} returns this
 */
proto.vtv1.ActiveSite.prototype.setLoadsNeedingAttention = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int64 dwell_time_minutes = 6;
 * @return {number}
 */
proto.vtv1.ActiveSite.prototype.getDwellTimeMinutes = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.ActiveSite} returns this
 */
proto.vtv1.ActiveSite.prototype.setDwellTimeMinutes = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int64 total_products = 7;
 * @return {number}
 */
proto.vtv1.ActiveSite.prototype.getTotalProducts = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.ActiveSite} returns this
 */
proto.vtv1.ActiveSite.prototype.setTotalProducts = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int64 automated_products = 8;
 * @return {number}
 */
proto.vtv1.ActiveSite.prototype.getAutomatedProducts = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.ActiveSite} returns this
 */
proto.vtv1.ActiveSite.prototype.setAutomatedProducts = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional NullableTime npt_estimated_at = 9;
 * @return {?proto.vtv1.NullableTime}
 */
proto.vtv1.ActiveSite.prototype.getNptEstimatedAt = function() {
  return /** @type{?proto.vtv1.NullableTime} */ (
    jspb.Message.getWrapperField(this, proto.vtv1.NullableTime, 9));
};


/**
 * @param {?proto.vtv1.NullableTime|undefined} value
 * @return {!proto.vtv1.ActiveSite} returns this
*/
proto.vtv1.ActiveSite.prototype.setNptEstimatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.ActiveSite} returns this
 */
proto.vtv1.ActiveSite.prototype.clearNptEstimatedAt = function() {
  return this.setNptEstimatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.ActiveSite.prototype.hasNptEstimatedAt = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional int64 unfulfilled_demand_signals = 10;
 * @return {number}
 */
proto.vtv1.ActiveSite.prototype.getUnfulfilledDemandSignals = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.ActiveSite} returns this
 */
proto.vtv1.ActiveSite.prototype.setUnfulfilledDemandSignals = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional int64 unassigned_orders = 11;
 * @return {number}
 */
proto.vtv1.ActiveSite.prototype.getUnassignedOrders = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.ActiveSite} returns this
 */
proto.vtv1.ActiveSite.prototype.setUnassignedOrders = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional int64 needs_attention_drivers = 12;
 * @return {number}
 */
proto.vtv1.ActiveSite.prototype.getNeedsAttentionDrivers = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.ActiveSite} returns this
 */
proto.vtv1.ActiveSite.prototype.setNeedsAttentionDrivers = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional int64 stock_out_risks = 13;
 * @return {number}
 */
proto.vtv1.ActiveSite.prototype.getStockOutRisks = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.ActiveSite} returns this
 */
proto.vtv1.ActiveSite.prototype.setStockOutRisks = function(value) {
  return jspb.Message.setProto3IntField(this, 13, value);
};


/**
 * repeated int64 detention_per_day = 14;
 * @return {!Array<number>}
 */
proto.vtv1.ActiveSite.prototype.getDetentionPerDayList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 14));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.vtv1.ActiveSite} returns this
 */
proto.vtv1.ActiveSite.prototype.setDetentionPerDayList = function(value) {
  return jspb.Message.setField(this, 14, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.vtv1.ActiveSite} returns this
 */
proto.vtv1.ActiveSite.prototype.addDetentionPerDay = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 14, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.ActiveSite} returns this
 */
proto.vtv1.ActiveSite.prototype.clearDetentionPerDayList = function() {
  return this.setDetentionPerDayList([]);
};


/**
 * repeated OptimalLoadsStats optimal_load_stats = 15;
 * @return {!Array<!proto.vtv1.OptimalLoadsStats>}
 */
proto.vtv1.ActiveSite.prototype.getOptimalLoadStatsList = function() {
  return /** @type{!Array<!proto.vtv1.OptimalLoadsStats>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vtv1.OptimalLoadsStats, 15));
};


/**
 * @param {!Array<!proto.vtv1.OptimalLoadsStats>} value
 * @return {!proto.vtv1.ActiveSite} returns this
*/
proto.vtv1.ActiveSite.prototype.setOptimalLoadStatsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 15, value);
};


/**
 * @param {!proto.vtv1.OptimalLoadsStats=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.OptimalLoadsStats}
 */
proto.vtv1.ActiveSite.prototype.addOptimalLoadStats = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 15, opt_value, proto.vtv1.OptimalLoadsStats, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.ActiveSite} returns this
 */
proto.vtv1.ActiveSite.prototype.clearOptimalLoadStatsList = function() {
  return this.setOptimalLoadStatsList([]);
};


/**
 * optional NullableString last_stock_out_day = 16;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.ActiveSite.prototype.getLastStockOutDay = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto.vtv1.NullableString, 16));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.ActiveSite} returns this
*/
proto.vtv1.ActiveSite.prototype.setLastStockOutDay = function(value) {
  return jspb.Message.setWrapperField(this, 16, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.ActiveSite} returns this
 */
proto.vtv1.ActiveSite.prototype.clearLastStockOutDay = function() {
  return this.setLastStockOutDay(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.ActiveSite.prototype.hasLastStockOutDay = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * optional int64 operations_counts = 17;
 * @return {number}
 */
proto.vtv1.ActiveSite.prototype.getOperationsCounts = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 17, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.ActiveSite} returns this
 */
proto.vtv1.ActiveSite.prototype.setOperationsCounts = function(value) {
  return jspb.Message.setProto3IntField(this, 17, value);
};


/**
 * optional int64 rolling_detention_average = 18;
 * @return {number}
 */
proto.vtv1.ActiveSite.prototype.getRollingDetentionAverage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 18, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.ActiveSite} returns this
 */
proto.vtv1.ActiveSite.prototype.setRollingDetentionAverage = function(value) {
  return jspb.Message.setProto3IntField(this, 18, value);
};


/**
 * repeated string inventory_stock_out_risk_ids = 19;
 * @return {!Array<string>}
 */
proto.vtv1.ActiveSite.prototype.getInventoryStockOutRiskIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 19));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.vtv1.ActiveSite} returns this
 */
proto.vtv1.ActiveSite.prototype.setInventoryStockOutRiskIdsList = function(value) {
  return jspb.Message.setField(this, 19, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.vtv1.ActiveSite} returns this
 */
proto.vtv1.ActiveSite.prototype.addInventoryStockOutRiskIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 19, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.ActiveSite} returns this
 */
proto.vtv1.ActiveSite.prototype.clearInventoryStockOutRiskIdsList = function() {
  return this.setInventoryStockOutRiskIdsList([]);
};


/**
 * repeated string sourcing_risk_demand_signal_ids = 20;
 * @return {!Array<string>}
 */
proto.vtv1.ActiveSite.prototype.getSourcingRiskDemandSignalIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 20));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.vtv1.ActiveSite} returns this
 */
proto.vtv1.ActiveSite.prototype.setSourcingRiskDemandSignalIdsList = function(value) {
  return jspb.Message.setField(this, 20, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.vtv1.ActiveSite} returns this
 */
proto.vtv1.ActiveSite.prototype.addSourcingRiskDemandSignalIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 20, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.ActiveSite} returns this
 */
proto.vtv1.ActiveSite.prototype.clearSourcingRiskDemandSignalIdsList = function() {
  return this.setSourcingRiskDemandSignalIdsList([]);
};


/**
 * repeated string orders_needing_attention_ids = 21;
 * @return {!Array<string>}
 */
proto.vtv1.ActiveSite.prototype.getOrdersNeedingAttentionIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 21));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.vtv1.ActiveSite} returns this
 */
proto.vtv1.ActiveSite.prototype.setOrdersNeedingAttentionIdsList = function(value) {
  return jspb.Message.setField(this, 21, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.vtv1.ActiveSite} returns this
 */
proto.vtv1.ActiveSite.prototype.addOrdersNeedingAttentionIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 21, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.ActiveSite} returns this
 */
proto.vtv1.ActiveSite.prototype.clearOrdersNeedingAttentionIdsList = function() {
  return this.setOrdersNeedingAttentionIdsList([]);
};


/**
 * repeated string batches_needing_update_ids = 22;
 * @return {!Array<string>}
 */
proto.vtv1.ActiveSite.prototype.getBatchesNeedingUpdateIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 22));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.vtv1.ActiveSite} returns this
 */
proto.vtv1.ActiveSite.prototype.setBatchesNeedingUpdateIdsList = function(value) {
  return jspb.Message.setField(this, 22, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.vtv1.ActiveSite} returns this
 */
proto.vtv1.ActiveSite.prototype.addBatchesNeedingUpdateIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 22, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.ActiveSite} returns this
 */
proto.vtv1.ActiveSite.prototype.clearBatchesNeedingUpdateIdsList = function() {
  return this.setBatchesNeedingUpdateIdsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.OptimalLoadsStats.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.OptimalLoadsStats.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.OptimalLoadsStats} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.OptimalLoadsStats.toObject = function(includeInstance, msg) {
  var f, obj = {
    dateTimestamp: jspb.Message.getFieldWithDefault(msg, 1, ""),
    numberOfOrders: jspb.Message.getFieldWithDefault(msg, 2, 0),
    numberOfOptimalOrders: jspb.Message.getFieldWithDefault(msg, 3, 0),
    percentOptimalOrders: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.OptimalLoadsStats}
 */
proto.vtv1.OptimalLoadsStats.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.OptimalLoadsStats;
  return proto.vtv1.OptimalLoadsStats.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.OptimalLoadsStats} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.OptimalLoadsStats}
 */
proto.vtv1.OptimalLoadsStats.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDateTimestamp(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setNumberOfOrders(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setNumberOfOptimalOrders(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPercentOptimalOrders(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.OptimalLoadsStats.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.OptimalLoadsStats.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.OptimalLoadsStats} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.OptimalLoadsStats.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDateTimestamp();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getNumberOfOrders();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getNumberOfOptimalOrders();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getPercentOptimalOrders();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
};


/**
 * optional string date_timestamp = 1;
 * @return {string}
 */
proto.vtv1.OptimalLoadsStats.prototype.getDateTimestamp = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.OptimalLoadsStats} returns this
 */
proto.vtv1.OptimalLoadsStats.prototype.setDateTimestamp = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 number_of_orders = 2;
 * @return {number}
 */
proto.vtv1.OptimalLoadsStats.prototype.getNumberOfOrders = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.OptimalLoadsStats} returns this
 */
proto.vtv1.OptimalLoadsStats.prototype.setNumberOfOrders = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 number_of_optimal_orders = 3;
 * @return {number}
 */
proto.vtv1.OptimalLoadsStats.prototype.getNumberOfOptimalOrders = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.OptimalLoadsStats} returns this
 */
proto.vtv1.OptimalLoadsStats.prototype.setNumberOfOptimalOrders = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 percent_optimal_orders = 4;
 * @return {number}
 */
proto.vtv1.OptimalLoadsStats.prototype.getPercentOptimalOrders = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.OptimalLoadsStats} returns this
 */
proto.vtv1.OptimalLoadsStats.prototype.setPercentOptimalOrders = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.SiteGroup.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.SiteGroup.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.SiteGroup.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.SiteGroup} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.SiteGroup.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    sitesInGroupList: jspb.Message.toObjectList(msg.getSitesInGroupList(),
    proto.vtv1.StringIdName.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.SiteGroup}
 */
proto.vtv1.SiteGroup.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.SiteGroup;
  return proto.vtv1.SiteGroup.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.SiteGroup} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.SiteGroup}
 */
proto.vtv1.SiteGroup.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = new proto.vtv1.StringIdName;
      reader.readMessage(value,proto.vtv1.StringIdName.deserializeBinaryFromReader);
      msg.addSitesInGroup(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.SiteGroup.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.SiteGroup.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.SiteGroup} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.SiteGroup.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSitesInGroupList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.vtv1.StringIdName.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.vtv1.SiteGroup.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.SiteGroup} returns this
 */
proto.vtv1.SiteGroup.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.vtv1.SiteGroup.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.SiteGroup} returns this
 */
proto.vtv1.SiteGroup.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated StringIdName sites_in_group = 3;
 * @return {!Array<!proto.vtv1.StringIdName>}
 */
proto.vtv1.SiteGroup.prototype.getSitesInGroupList = function() {
  return /** @type{!Array<!proto.vtv1.StringIdName>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vtv1.StringIdName, 3));
};


/**
 * @param {!Array<!proto.vtv1.StringIdName>} value
 * @return {!proto.vtv1.SiteGroup} returns this
*/
proto.vtv1.SiteGroup.prototype.setSitesInGroupList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.vtv1.StringIdName=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.StringIdName}
 */
proto.vtv1.SiteGroup.prototype.addSitesInGroup = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.vtv1.StringIdName, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.SiteGroup} returns this
 */
proto.vtv1.SiteGroup.prototype.clearSitesInGroupList = function() {
  return this.setSitesInGroupList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.Task.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.Task.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.Task} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.Task.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    action: jspb.Message.getFieldWithDefault(msg, 2, 0),
    productId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    quantity: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    bolNumber: (f = msg.getBolNumber()) && proto.vtv1.NullableString.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.Task}
 */
proto.vtv1.Task.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.Task;
  return proto.vtv1.Task.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.Task} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.Task}
 */
proto.vtv1.Task.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {!proto.vtv1.TaskAction} */ (reader.readEnum());
      msg.setAction(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductId(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setQuantity(value);
      break;
    case 5:
      var value = new proto.vtv1.NullableString;
      reader.readMessage(value,proto.vtv1.NullableString.deserializeBinaryFromReader);
      msg.setBolNumber(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.Task.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.Task.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.Task} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.Task.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getAction();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getProductId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getQuantity();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getBolNumber();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.vtv1.NullableString.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.vtv1.Task.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.Task} returns this
 */
proto.vtv1.Task.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional TaskAction action = 2;
 * @return {!proto.vtv1.TaskAction}
 */
proto.vtv1.Task.prototype.getAction = function() {
  return /** @type {!proto.vtv1.TaskAction} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.vtv1.TaskAction} value
 * @return {!proto.vtv1.Task} returns this
 */
proto.vtv1.Task.prototype.setAction = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional string product_id = 3;
 * @return {string}
 */
proto.vtv1.Task.prototype.getProductId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.Task} returns this
 */
proto.vtv1.Task.prototype.setProductId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional double quantity = 4;
 * @return {number}
 */
proto.vtv1.Task.prototype.getQuantity = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.Task} returns this
 */
proto.vtv1.Task.prototype.setQuantity = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional NullableString bol_number = 5;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.Task.prototype.getBolNumber = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto.vtv1.NullableString, 5));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.Task} returns this
*/
proto.vtv1.Task.prototype.setBolNumber = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.Task} returns this
 */
proto.vtv1.Task.prototype.clearBolNumber = function() {
  return this.setBolNumber(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.Task.prototype.hasBolNumber = function() {
  return jspb.Message.getField(this, 5) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.Stop.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.Stop.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.Stop.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.Stop} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.Stop.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    sequence: jspb.Message.getFieldWithDefault(msg, 2, 0),
    siteId: (f = msg.getSiteId()) && proto.vtv1.NullableString.toObject(includeInstance, f),
    tasksList: jspb.Message.toObjectList(msg.getTasksList(),
    proto.vtv1.Task.toObject, includeInstance),
    arrivalWindowStartsAt: (f = msg.getArrivalWindowStartsAt()) && proto.vtv1.NullableTime.toObject(includeInstance, f),
    arrivalWindowMinutes: (f = msg.getArrivalWindowMinutes()) && proto.vtv1.NullableInt64.toObject(includeInstance, f),
    address: (f = msg.getAddress()) && proto.vtv1.NullableString.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.Stop}
 */
proto.vtv1.Stop.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.Stop;
  return proto.vtv1.Stop.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.Stop} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.Stop}
 */
proto.vtv1.Stop.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSequence(value);
      break;
    case 3:
      var value = new proto.vtv1.NullableString;
      reader.readMessage(value,proto.vtv1.NullableString.deserializeBinaryFromReader);
      msg.setSiteId(value);
      break;
    case 4:
      var value = new proto.vtv1.Task;
      reader.readMessage(value,proto.vtv1.Task.deserializeBinaryFromReader);
      msg.addTasks(value);
      break;
    case 5:
      var value = new proto.vtv1.NullableTime;
      reader.readMessage(value,proto.vtv1.NullableTime.deserializeBinaryFromReader);
      msg.setArrivalWindowStartsAt(value);
      break;
    case 6:
      var value = new proto.vtv1.NullableInt64;
      reader.readMessage(value,proto.vtv1.NullableInt64.deserializeBinaryFromReader);
      msg.setArrivalWindowMinutes(value);
      break;
    case 7:
      var value = new proto.vtv1.NullableString;
      reader.readMessage(value,proto.vtv1.NullableString.deserializeBinaryFromReader);
      msg.setAddress(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.Stop.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.Stop.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.Stop} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.Stop.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getSequence();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getSiteId();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.vtv1.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getTasksList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.vtv1.Task.serializeBinaryToWriter
    );
  }
  f = message.getArrivalWindowStartsAt();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.vtv1.NullableTime.serializeBinaryToWriter
    );
  }
  f = message.getArrivalWindowMinutes();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.vtv1.NullableInt64.serializeBinaryToWriter
    );
  }
  f = message.getAddress();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.vtv1.NullableString.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.vtv1.Stop.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.Stop} returns this
 */
proto.vtv1.Stop.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 sequence = 2;
 * @return {number}
 */
proto.vtv1.Stop.prototype.getSequence = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.Stop} returns this
 */
proto.vtv1.Stop.prototype.setSequence = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional NullableString site_id = 3;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.Stop.prototype.getSiteId = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto.vtv1.NullableString, 3));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.Stop} returns this
*/
proto.vtv1.Stop.prototype.setSiteId = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.Stop} returns this
 */
proto.vtv1.Stop.prototype.clearSiteId = function() {
  return this.setSiteId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.Stop.prototype.hasSiteId = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * repeated Task tasks = 4;
 * @return {!Array<!proto.vtv1.Task>}
 */
proto.vtv1.Stop.prototype.getTasksList = function() {
  return /** @type{!Array<!proto.vtv1.Task>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vtv1.Task, 4));
};


/**
 * @param {!Array<!proto.vtv1.Task>} value
 * @return {!proto.vtv1.Stop} returns this
*/
proto.vtv1.Stop.prototype.setTasksList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.vtv1.Task=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.Task}
 */
proto.vtv1.Stop.prototype.addTasks = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.vtv1.Task, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.Stop} returns this
 */
proto.vtv1.Stop.prototype.clearTasksList = function() {
  return this.setTasksList([]);
};


/**
 * optional NullableTime arrival_window_starts_at = 5;
 * @return {?proto.vtv1.NullableTime}
 */
proto.vtv1.Stop.prototype.getArrivalWindowStartsAt = function() {
  return /** @type{?proto.vtv1.NullableTime} */ (
    jspb.Message.getWrapperField(this, proto.vtv1.NullableTime, 5));
};


/**
 * @param {?proto.vtv1.NullableTime|undefined} value
 * @return {!proto.vtv1.Stop} returns this
*/
proto.vtv1.Stop.prototype.setArrivalWindowStartsAt = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.Stop} returns this
 */
proto.vtv1.Stop.prototype.clearArrivalWindowStartsAt = function() {
  return this.setArrivalWindowStartsAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.Stop.prototype.hasArrivalWindowStartsAt = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional NullableInt64 arrival_window_minutes = 6;
 * @return {?proto.vtv1.NullableInt64}
 */
proto.vtv1.Stop.prototype.getArrivalWindowMinutes = function() {
  return /** @type{?proto.vtv1.NullableInt64} */ (
    jspb.Message.getWrapperField(this, proto.vtv1.NullableInt64, 6));
};


/**
 * @param {?proto.vtv1.NullableInt64|undefined} value
 * @return {!proto.vtv1.Stop} returns this
*/
proto.vtv1.Stop.prototype.setArrivalWindowMinutes = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.Stop} returns this
 */
proto.vtv1.Stop.prototype.clearArrivalWindowMinutes = function() {
  return this.setArrivalWindowMinutes(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.Stop.prototype.hasArrivalWindowMinutes = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional NullableString address = 7;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.Stop.prototype.getAddress = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto.vtv1.NullableString, 7));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.Stop} returns this
*/
proto.vtv1.Stop.prototype.setAddress = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.Stop} returns this
 */
proto.vtv1.Stop.prototype.clearAddress = function() {
  return this.setAddress(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.Stop.prototype.hasAddress = function() {
  return jspb.Message.getField(this, 7) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.Shipment.repeatedFields_ = [6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.Shipment.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.Shipment.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.Shipment} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.Shipment.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    edgeContractId: (f = msg.getEdgeContractId()) && proto.vtv1.NullableString.toObject(includeInstance, f),
    shipmentStatus: jspb.Message.getFieldWithDefault(msg, 3, 0),
    driverId: (f = msg.getDriverId()) && proto.vtv1.NullableString.toObject(includeInstance, f),
    description: (f = msg.getDescription()) && proto.vtv1.NullableString.toObject(includeInstance, f),
    stopsList: jspb.Message.toObjectList(msg.getStopsList(),
    proto.vtv1.Stop.toObject, includeInstance),
    ticketNumber: (f = msg.getTicketNumber()) && proto.vtv1.NullableString.toObject(includeInstance, f),
    edgeId: (f = msg.getEdgeId()) && proto.vtv1.NullableString.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.Shipment}
 */
proto.vtv1.Shipment.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.Shipment;
  return proto.vtv1.Shipment.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.Shipment} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.Shipment}
 */
proto.vtv1.Shipment.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new proto.vtv1.NullableString;
      reader.readMessage(value,proto.vtv1.NullableString.deserializeBinaryFromReader);
      msg.setEdgeContractId(value);
      break;
    case 3:
      var value = /** @type {!proto.vtv1.ShipmentStatus} */ (reader.readEnum());
      msg.setShipmentStatus(value);
      break;
    case 4:
      var value = new proto.vtv1.NullableString;
      reader.readMessage(value,proto.vtv1.NullableString.deserializeBinaryFromReader);
      msg.setDriverId(value);
      break;
    case 5:
      var value = new proto.vtv1.NullableString;
      reader.readMessage(value,proto.vtv1.NullableString.deserializeBinaryFromReader);
      msg.setDescription(value);
      break;
    case 6:
      var value = new proto.vtv1.Stop;
      reader.readMessage(value,proto.vtv1.Stop.deserializeBinaryFromReader);
      msg.addStops(value);
      break;
    case 7:
      var value = new proto.vtv1.NullableString;
      reader.readMessage(value,proto.vtv1.NullableString.deserializeBinaryFromReader);
      msg.setTicketNumber(value);
      break;
    case 8:
      var value = new proto.vtv1.NullableString;
      reader.readMessage(value,proto.vtv1.NullableString.deserializeBinaryFromReader);
      msg.setEdgeId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.Shipment.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.Shipment.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.Shipment} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.Shipment.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEdgeContractId();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.vtv1.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getShipmentStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getDriverId();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.vtv1.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getDescription();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.vtv1.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getStopsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.vtv1.Stop.serializeBinaryToWriter
    );
  }
  f = message.getTicketNumber();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.vtv1.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getEdgeId();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.vtv1.NullableString.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.vtv1.Shipment.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.Shipment} returns this
 */
proto.vtv1.Shipment.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional NullableString edge_contract_id = 2;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.Shipment.prototype.getEdgeContractId = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto.vtv1.NullableString, 2));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.Shipment} returns this
*/
proto.vtv1.Shipment.prototype.setEdgeContractId = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.Shipment} returns this
 */
proto.vtv1.Shipment.prototype.clearEdgeContractId = function() {
  return this.setEdgeContractId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.Shipment.prototype.hasEdgeContractId = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional ShipmentStatus shipment_status = 3;
 * @return {!proto.vtv1.ShipmentStatus}
 */
proto.vtv1.Shipment.prototype.getShipmentStatus = function() {
  return /** @type {!proto.vtv1.ShipmentStatus} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.vtv1.ShipmentStatus} value
 * @return {!proto.vtv1.Shipment} returns this
 */
proto.vtv1.Shipment.prototype.setShipmentStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional NullableString driver_id = 4;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.Shipment.prototype.getDriverId = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto.vtv1.NullableString, 4));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.Shipment} returns this
*/
proto.vtv1.Shipment.prototype.setDriverId = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.Shipment} returns this
 */
proto.vtv1.Shipment.prototype.clearDriverId = function() {
  return this.setDriverId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.Shipment.prototype.hasDriverId = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional NullableString description = 5;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.Shipment.prototype.getDescription = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto.vtv1.NullableString, 5));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.Shipment} returns this
*/
proto.vtv1.Shipment.prototype.setDescription = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.Shipment} returns this
 */
proto.vtv1.Shipment.prototype.clearDescription = function() {
  return this.setDescription(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.Shipment.prototype.hasDescription = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * repeated Stop stops = 6;
 * @return {!Array<!proto.vtv1.Stop>}
 */
proto.vtv1.Shipment.prototype.getStopsList = function() {
  return /** @type{!Array<!proto.vtv1.Stop>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vtv1.Stop, 6));
};


/**
 * @param {!Array<!proto.vtv1.Stop>} value
 * @return {!proto.vtv1.Shipment} returns this
*/
proto.vtv1.Shipment.prototype.setStopsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.vtv1.Stop=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.Stop}
 */
proto.vtv1.Shipment.prototype.addStops = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.vtv1.Stop, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.Shipment} returns this
 */
proto.vtv1.Shipment.prototype.clearStopsList = function() {
  return this.setStopsList([]);
};


/**
 * optional NullableString ticket_number = 7;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.Shipment.prototype.getTicketNumber = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto.vtv1.NullableString, 7));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.Shipment} returns this
*/
proto.vtv1.Shipment.prototype.setTicketNumber = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.Shipment} returns this
 */
proto.vtv1.Shipment.prototype.clearTicketNumber = function() {
  return this.setTicketNumber(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.Shipment.prototype.hasTicketNumber = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional NullableString edge_id = 8;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.Shipment.prototype.getEdgeId = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto.vtv1.NullableString, 8));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.Shipment} returns this
*/
proto.vtv1.Shipment.prototype.setEdgeId = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.Shipment} returns this
 */
proto.vtv1.Shipment.prototype.clearEdgeId = function() {
  return this.setEdgeId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.Shipment.prototype.hasEdgeId = function() {
  return jspb.Message.getField(this, 8) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.FlatProduct.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.FlatProduct.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.FlatProduct} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.FlatProduct.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    consumerId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    consumerName: jspb.Message.getFieldWithDefault(msg, 4, ""),
    unitId: jspb.Message.getFieldWithDefault(msg, 5, 0),
    unitName: jspb.Message.getFieldWithDefault(msg, 6, ""),
    unitAbbreviation: jspb.Message.getFieldWithDefault(msg, 7, ""),
    archivedAt: (f = msg.getArchivedAt()) && proto.vtv1.NullableTime.toObject(includeInstance, f),
    hasPo: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
    defaultLoadQuantity: (f = msg.getDefaultLoadQuantity()) && proto.vtv1.NullableDouble.toObject(includeInstance, f),
    isAttachedToFinishedGood: jspb.Message.getBooleanFieldWithDefault(msg, 11, false),
    inventoryId: (f = msg.getInventoryId()) && proto.vtv1.NullableString.toObject(includeInstance, f),
    inventoryName: (f = msg.getInventoryName()) && proto.vtv1.NullableString.toObject(includeInstance, f),
    materialNumber: (f = msg.getMaterialNumber()) && proto.vtv1.NullableString.toObject(includeInstance, f),
    poundsPerUnit: (f = msg.getPoundsPerUnit()) && proto.vtv1.NullableDouble.toObject(includeInstance, f),
    halInventoryByUnit: jspb.Message.getBooleanFieldWithDefault(msg, 16, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.FlatProduct}
 */
proto.vtv1.FlatProduct.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.FlatProduct;
  return proto.vtv1.FlatProduct.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.FlatProduct} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.FlatProduct}
 */
proto.vtv1.FlatProduct.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setConsumerId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setConsumerName(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUnitId(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setUnitName(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setUnitAbbreviation(value);
      break;
    case 8:
      var value = new proto.vtv1.NullableTime;
      reader.readMessage(value,proto.vtv1.NullableTime.deserializeBinaryFromReader);
      msg.setArchivedAt(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasPo(value);
      break;
    case 10:
      var value = new proto.vtv1.NullableDouble;
      reader.readMessage(value,proto.vtv1.NullableDouble.deserializeBinaryFromReader);
      msg.setDefaultLoadQuantity(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsAttachedToFinishedGood(value);
      break;
    case 12:
      var value = new proto.vtv1.NullableString;
      reader.readMessage(value,proto.vtv1.NullableString.deserializeBinaryFromReader);
      msg.setInventoryId(value);
      break;
    case 13:
      var value = new proto.vtv1.NullableString;
      reader.readMessage(value,proto.vtv1.NullableString.deserializeBinaryFromReader);
      msg.setInventoryName(value);
      break;
    case 14:
      var value = new proto.vtv1.NullableString;
      reader.readMessage(value,proto.vtv1.NullableString.deserializeBinaryFromReader);
      msg.setMaterialNumber(value);
      break;
    case 15:
      var value = new proto.vtv1.NullableDouble;
      reader.readMessage(value,proto.vtv1.NullableDouble.deserializeBinaryFromReader);
      msg.setPoundsPerUnit(value);
      break;
    case 16:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHalInventoryByUnit(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.FlatProduct.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.FlatProduct.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.FlatProduct} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.FlatProduct.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getConsumerId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getConsumerName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getUnitId();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getUnitName();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getUnitAbbreviation();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getArchivedAt();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.vtv1.NullableTime.serializeBinaryToWriter
    );
  }
  f = message.getHasPo();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getDefaultLoadQuantity();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.vtv1.NullableDouble.serializeBinaryToWriter
    );
  }
  f = message.getIsAttachedToFinishedGood();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
  f = message.getInventoryId();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      proto.vtv1.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getInventoryName();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      proto.vtv1.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getMaterialNumber();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      proto.vtv1.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getPoundsPerUnit();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      proto.vtv1.NullableDouble.serializeBinaryToWriter
    );
  }
  f = message.getHalInventoryByUnit();
  if (f) {
    writer.writeBool(
      16,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.vtv1.FlatProduct.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.FlatProduct} returns this
 */
proto.vtv1.FlatProduct.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.vtv1.FlatProduct.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.FlatProduct} returns this
 */
proto.vtv1.FlatProduct.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string consumer_id = 3;
 * @return {string}
 */
proto.vtv1.FlatProduct.prototype.getConsumerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.FlatProduct} returns this
 */
proto.vtv1.FlatProduct.prototype.setConsumerId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string consumer_name = 4;
 * @return {string}
 */
proto.vtv1.FlatProduct.prototype.getConsumerName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.FlatProduct} returns this
 */
proto.vtv1.FlatProduct.prototype.setConsumerName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int64 unit_id = 5;
 * @return {number}
 */
proto.vtv1.FlatProduct.prototype.getUnitId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.FlatProduct} returns this
 */
proto.vtv1.FlatProduct.prototype.setUnitId = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional string unit_name = 6;
 * @return {string}
 */
proto.vtv1.FlatProduct.prototype.getUnitName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.FlatProduct} returns this
 */
proto.vtv1.FlatProduct.prototype.setUnitName = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string unit_abbreviation = 7;
 * @return {string}
 */
proto.vtv1.FlatProduct.prototype.getUnitAbbreviation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.FlatProduct} returns this
 */
proto.vtv1.FlatProduct.prototype.setUnitAbbreviation = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional NullableTime archived_at = 8;
 * @return {?proto.vtv1.NullableTime}
 */
proto.vtv1.FlatProduct.prototype.getArchivedAt = function() {
  return /** @type{?proto.vtv1.NullableTime} */ (
    jspb.Message.getWrapperField(this, proto.vtv1.NullableTime, 8));
};


/**
 * @param {?proto.vtv1.NullableTime|undefined} value
 * @return {!proto.vtv1.FlatProduct} returns this
*/
proto.vtv1.FlatProduct.prototype.setArchivedAt = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.FlatProduct} returns this
 */
proto.vtv1.FlatProduct.prototype.clearArchivedAt = function() {
  return this.setArchivedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.FlatProduct.prototype.hasArchivedAt = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional bool has_po = 9;
 * @return {boolean}
 */
proto.vtv1.FlatProduct.prototype.getHasPo = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vtv1.FlatProduct} returns this
 */
proto.vtv1.FlatProduct.prototype.setHasPo = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};


/**
 * optional NullableDouble default_load_quantity = 10;
 * @return {?proto.vtv1.NullableDouble}
 */
proto.vtv1.FlatProduct.prototype.getDefaultLoadQuantity = function() {
  return /** @type{?proto.vtv1.NullableDouble} */ (
    jspb.Message.getWrapperField(this, proto.vtv1.NullableDouble, 10));
};


/**
 * @param {?proto.vtv1.NullableDouble|undefined} value
 * @return {!proto.vtv1.FlatProduct} returns this
*/
proto.vtv1.FlatProduct.prototype.setDefaultLoadQuantity = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.FlatProduct} returns this
 */
proto.vtv1.FlatProduct.prototype.clearDefaultLoadQuantity = function() {
  return this.setDefaultLoadQuantity(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.FlatProduct.prototype.hasDefaultLoadQuantity = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional bool is_attached_to_finished_good = 11;
 * @return {boolean}
 */
proto.vtv1.FlatProduct.prototype.getIsAttachedToFinishedGood = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vtv1.FlatProduct} returns this
 */
proto.vtv1.FlatProduct.prototype.setIsAttachedToFinishedGood = function(value) {
  return jspb.Message.setProto3BooleanField(this, 11, value);
};


/**
 * optional NullableString inventory_id = 12;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.FlatProduct.prototype.getInventoryId = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto.vtv1.NullableString, 12));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.FlatProduct} returns this
*/
proto.vtv1.FlatProduct.prototype.setInventoryId = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.FlatProduct} returns this
 */
proto.vtv1.FlatProduct.prototype.clearInventoryId = function() {
  return this.setInventoryId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.FlatProduct.prototype.hasInventoryId = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional NullableString inventory_name = 13;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.FlatProduct.prototype.getInventoryName = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto.vtv1.NullableString, 13));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.FlatProduct} returns this
*/
proto.vtv1.FlatProduct.prototype.setInventoryName = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.FlatProduct} returns this
 */
proto.vtv1.FlatProduct.prototype.clearInventoryName = function() {
  return this.setInventoryName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.FlatProduct.prototype.hasInventoryName = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional NullableString material_number = 14;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.FlatProduct.prototype.getMaterialNumber = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto.vtv1.NullableString, 14));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.FlatProduct} returns this
*/
proto.vtv1.FlatProduct.prototype.setMaterialNumber = function(value) {
  return jspb.Message.setWrapperField(this, 14, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.FlatProduct} returns this
 */
proto.vtv1.FlatProduct.prototype.clearMaterialNumber = function() {
  return this.setMaterialNumber(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.FlatProduct.prototype.hasMaterialNumber = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional NullableDouble pounds_per_unit = 15;
 * @return {?proto.vtv1.NullableDouble}
 */
proto.vtv1.FlatProduct.prototype.getPoundsPerUnit = function() {
  return /** @type{?proto.vtv1.NullableDouble} */ (
    jspb.Message.getWrapperField(this, proto.vtv1.NullableDouble, 15));
};


/**
 * @param {?proto.vtv1.NullableDouble|undefined} value
 * @return {!proto.vtv1.FlatProduct} returns this
*/
proto.vtv1.FlatProduct.prototype.setPoundsPerUnit = function(value) {
  return jspb.Message.setWrapperField(this, 15, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.FlatProduct} returns this
 */
proto.vtv1.FlatProduct.prototype.clearPoundsPerUnit = function() {
  return this.setPoundsPerUnit(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.FlatProduct.prototype.hasPoundsPerUnit = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional bool hal_inventory_by_unit = 16;
 * @return {boolean}
 */
proto.vtv1.FlatProduct.prototype.getHalInventoryByUnit = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 16, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vtv1.FlatProduct} returns this
 */
proto.vtv1.FlatProduct.prototype.setHalInventoryByUnit = function(value) {
  return jspb.Message.setProto3BooleanField(this, 16, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.PurchaseOrderLineItem.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.PurchaseOrderLineItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.PurchaseOrderLineItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.PurchaseOrderLineItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    productId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    productName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    unitId: jspb.Message.getFieldWithDefault(msg, 4, 0),
    unitName: jspb.Message.getFieldWithDefault(msg, 5, ""),
    unitAbbreviation: jspb.Message.getFieldWithDefault(msg, 6, ""),
    totalQuantityMin: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    totalQuantityMax: (f = msg.getTotalQuantityMax()) && proto.vtv1.NullableDouble.toObject(includeInstance, f),
    dailyQuantityMin: jspb.Message.getFloatingPointFieldWithDefault(msg, 9, 0.0),
    dailyQuantityMax: (f = msg.getDailyQuantityMax()) && proto.vtv1.NullableDouble.toObject(includeInstance, f),
    shortfallUnitCostCents: jspb.Message.getFieldWithDefault(msg, 11, 0),
    unitCostCents: jspb.Message.getFieldWithDefault(msg, 12, 0),
    name: jspb.Message.getFieldWithDefault(msg, 13, ""),
    description: (f = msg.getDescription()) && proto.vtv1.NullableString.toObject(includeInstance, f),
    quantityInCost: jspb.Message.getFloatingPointFieldWithDefault(msg, 15, 0.0),
    freightTerms: (f = msg.getFreightTerms()) && proto.vtv1.NullableString.toObject(includeInstance, f),
    backhaul: jspb.Message.getBooleanFieldWithDefault(msg, 17, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.PurchaseOrderLineItem}
 */
proto.vtv1.PurchaseOrderLineItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.PurchaseOrderLineItem;
  return proto.vtv1.PurchaseOrderLineItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.PurchaseOrderLineItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.PurchaseOrderLineItem}
 */
proto.vtv1.PurchaseOrderLineItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductName(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUnitId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setUnitName(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setUnitAbbreviation(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalQuantityMin(value);
      break;
    case 8:
      var value = new proto.vtv1.NullableDouble;
      reader.readMessage(value,proto.vtv1.NullableDouble.deserializeBinaryFromReader);
      msg.setTotalQuantityMax(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setDailyQuantityMin(value);
      break;
    case 10:
      var value = new proto.vtv1.NullableDouble;
      reader.readMessage(value,proto.vtv1.NullableDouble.deserializeBinaryFromReader);
      msg.setDailyQuantityMax(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setShortfallUnitCostCents(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUnitCostCents(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 14:
      var value = new proto.vtv1.NullableString;
      reader.readMessage(value,proto.vtv1.NullableString.deserializeBinaryFromReader);
      msg.setDescription(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setQuantityInCost(value);
      break;
    case 16:
      var value = new proto.vtv1.NullableString;
      reader.readMessage(value,proto.vtv1.NullableString.deserializeBinaryFromReader);
      msg.setFreightTerms(value);
      break;
    case 17:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setBackhaul(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.PurchaseOrderLineItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.PurchaseOrderLineItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.PurchaseOrderLineItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.PurchaseOrderLineItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getProductId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getProductName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getUnitId();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getUnitName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getUnitAbbreviation();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getTotalQuantityMin();
  if (f !== 0.0) {
    writer.writeDouble(
      7,
      f
    );
  }
  f = message.getTotalQuantityMax();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.vtv1.NullableDouble.serializeBinaryToWriter
    );
  }
  f = message.getDailyQuantityMin();
  if (f !== 0.0) {
    writer.writeDouble(
      9,
      f
    );
  }
  f = message.getDailyQuantityMax();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.vtv1.NullableDouble.serializeBinaryToWriter
    );
  }
  f = message.getShortfallUnitCostCents();
  if (f !== 0) {
    writer.writeInt64(
      11,
      f
    );
  }
  f = message.getUnitCostCents();
  if (f !== 0) {
    writer.writeInt64(
      12,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getDescription();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      proto.vtv1.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getQuantityInCost();
  if (f !== 0.0) {
    writer.writeDouble(
      15,
      f
    );
  }
  f = message.getFreightTerms();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      proto.vtv1.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getBackhaul();
  if (f) {
    writer.writeBool(
      17,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.vtv1.PurchaseOrderLineItem.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.PurchaseOrderLineItem} returns this
 */
proto.vtv1.PurchaseOrderLineItem.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string product_id = 2;
 * @return {string}
 */
proto.vtv1.PurchaseOrderLineItem.prototype.getProductId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.PurchaseOrderLineItem} returns this
 */
proto.vtv1.PurchaseOrderLineItem.prototype.setProductId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string product_name = 3;
 * @return {string}
 */
proto.vtv1.PurchaseOrderLineItem.prototype.getProductName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.PurchaseOrderLineItem} returns this
 */
proto.vtv1.PurchaseOrderLineItem.prototype.setProductName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int64 unit_id = 4;
 * @return {number}
 */
proto.vtv1.PurchaseOrderLineItem.prototype.getUnitId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.PurchaseOrderLineItem} returns this
 */
proto.vtv1.PurchaseOrderLineItem.prototype.setUnitId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string unit_name = 5;
 * @return {string}
 */
proto.vtv1.PurchaseOrderLineItem.prototype.getUnitName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.PurchaseOrderLineItem} returns this
 */
proto.vtv1.PurchaseOrderLineItem.prototype.setUnitName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string unit_abbreviation = 6;
 * @return {string}
 */
proto.vtv1.PurchaseOrderLineItem.prototype.getUnitAbbreviation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.PurchaseOrderLineItem} returns this
 */
proto.vtv1.PurchaseOrderLineItem.prototype.setUnitAbbreviation = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional double total_quantity_min = 7;
 * @return {number}
 */
proto.vtv1.PurchaseOrderLineItem.prototype.getTotalQuantityMin = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.PurchaseOrderLineItem} returns this
 */
proto.vtv1.PurchaseOrderLineItem.prototype.setTotalQuantityMin = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional NullableDouble total_quantity_max = 8;
 * @return {?proto.vtv1.NullableDouble}
 */
proto.vtv1.PurchaseOrderLineItem.prototype.getTotalQuantityMax = function() {
  return /** @type{?proto.vtv1.NullableDouble} */ (
    jspb.Message.getWrapperField(this, proto.vtv1.NullableDouble, 8));
};


/**
 * @param {?proto.vtv1.NullableDouble|undefined} value
 * @return {!proto.vtv1.PurchaseOrderLineItem} returns this
*/
proto.vtv1.PurchaseOrderLineItem.prototype.setTotalQuantityMax = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.PurchaseOrderLineItem} returns this
 */
proto.vtv1.PurchaseOrderLineItem.prototype.clearTotalQuantityMax = function() {
  return this.setTotalQuantityMax(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.PurchaseOrderLineItem.prototype.hasTotalQuantityMax = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional double daily_quantity_min = 9;
 * @return {number}
 */
proto.vtv1.PurchaseOrderLineItem.prototype.getDailyQuantityMin = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 9, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.PurchaseOrderLineItem} returns this
 */
proto.vtv1.PurchaseOrderLineItem.prototype.setDailyQuantityMin = function(value) {
  return jspb.Message.setProto3FloatField(this, 9, value);
};


/**
 * optional NullableDouble daily_quantity_max = 10;
 * @return {?proto.vtv1.NullableDouble}
 */
proto.vtv1.PurchaseOrderLineItem.prototype.getDailyQuantityMax = function() {
  return /** @type{?proto.vtv1.NullableDouble} */ (
    jspb.Message.getWrapperField(this, proto.vtv1.NullableDouble, 10));
};


/**
 * @param {?proto.vtv1.NullableDouble|undefined} value
 * @return {!proto.vtv1.PurchaseOrderLineItem} returns this
*/
proto.vtv1.PurchaseOrderLineItem.prototype.setDailyQuantityMax = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.PurchaseOrderLineItem} returns this
 */
proto.vtv1.PurchaseOrderLineItem.prototype.clearDailyQuantityMax = function() {
  return this.setDailyQuantityMax(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.PurchaseOrderLineItem.prototype.hasDailyQuantityMax = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional int64 shortfall_unit_cost_cents = 11;
 * @return {number}
 */
proto.vtv1.PurchaseOrderLineItem.prototype.getShortfallUnitCostCents = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.PurchaseOrderLineItem} returns this
 */
proto.vtv1.PurchaseOrderLineItem.prototype.setShortfallUnitCostCents = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional int64 unit_cost_cents = 12;
 * @return {number}
 */
proto.vtv1.PurchaseOrderLineItem.prototype.getUnitCostCents = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.PurchaseOrderLineItem} returns this
 */
proto.vtv1.PurchaseOrderLineItem.prototype.setUnitCostCents = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional string name = 13;
 * @return {string}
 */
proto.vtv1.PurchaseOrderLineItem.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.PurchaseOrderLineItem} returns this
 */
proto.vtv1.PurchaseOrderLineItem.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional NullableString description = 14;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.PurchaseOrderLineItem.prototype.getDescription = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto.vtv1.NullableString, 14));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.PurchaseOrderLineItem} returns this
*/
proto.vtv1.PurchaseOrderLineItem.prototype.setDescription = function(value) {
  return jspb.Message.setWrapperField(this, 14, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.PurchaseOrderLineItem} returns this
 */
proto.vtv1.PurchaseOrderLineItem.prototype.clearDescription = function() {
  return this.setDescription(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.PurchaseOrderLineItem.prototype.hasDescription = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional double quantity_in_cost = 15;
 * @return {number}
 */
proto.vtv1.PurchaseOrderLineItem.prototype.getQuantityInCost = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 15, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.PurchaseOrderLineItem} returns this
 */
proto.vtv1.PurchaseOrderLineItem.prototype.setQuantityInCost = function(value) {
  return jspb.Message.setProto3FloatField(this, 15, value);
};


/**
 * optional NullableString freight_terms = 16;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.PurchaseOrderLineItem.prototype.getFreightTerms = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto.vtv1.NullableString, 16));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.PurchaseOrderLineItem} returns this
*/
proto.vtv1.PurchaseOrderLineItem.prototype.setFreightTerms = function(value) {
  return jspb.Message.setWrapperField(this, 16, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.PurchaseOrderLineItem} returns this
 */
proto.vtv1.PurchaseOrderLineItem.prototype.clearFreightTerms = function() {
  return this.setFreightTerms(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.PurchaseOrderLineItem.prototype.hasFreightTerms = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * optional bool backhaul = 17;
 * @return {boolean}
 */
proto.vtv1.PurchaseOrderLineItem.prototype.getBackhaul = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 17, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vtv1.PurchaseOrderLineItem} returns this
 */
proto.vtv1.PurchaseOrderLineItem.prototype.setBackhaul = function(value) {
  return jspb.Message.setProto3BooleanField(this, 17, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.PurchaseOrder.repeatedFields_ = [9,13,22,23];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.PurchaseOrder.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.PurchaseOrder.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.PurchaseOrder} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.PurchaseOrder.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    consumerId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    consumerName: jspb.Message.getFieldWithDefault(msg, 4, ""),
    supplierId: jspb.Message.getFieldWithDefault(msg, 5, ""),
    supplierName: jspb.Message.getFieldWithDefault(msg, 6, ""),
    shipperId: jspb.Message.getFieldWithDefault(msg, 7, ""),
    shipperName: jspb.Message.getFieldWithDefault(msg, 8, ""),
    supplierSitesList: jspb.Message.toObjectList(msg.getSupplierSitesList(),
    proto.vtv1.StringIdName.toObject, includeInstance),
    startsAt: jspb.Message.getFieldWithDefault(msg, 10, ""),
    expiresAt: (f = msg.getExpiresAt()) && proto.vtv1.NullableTime.toObject(includeInstance, f),
    closedAt: (f = msg.getClosedAt()) && proto.vtv1.NullableTime.toObject(includeInstance, f),
    lineItemsList: jspb.Message.toObjectList(msg.getLineItemsList(),
    proto.vtv1.PurchaseOrderLineItem.toObject, includeInstance),
    leadTimeHours: jspb.Message.getFieldWithDefault(msg, 14, 0),
    autoConfirmRequest: jspb.Message.getBooleanFieldWithDefault(msg, 15, false),
    confirmedAt: (f = msg.getConfirmedAt()) && proto.vtv1.NullableTime.toObject(includeInstance, f),
    declinedAt: (f = msg.getDeclinedAt()) && proto.vtv1.NullableTime.toObject(includeInstance, f),
    confirmedBy: (f = msg.getConfirmedBy()) && proto.vtv1.NullableString.toObject(includeInstance, f),
    declinedBy: (f = msg.getDeclinedBy()) && proto.vtv1.NullableString.toObject(includeInstance, f),
    shipmentsRequireSupplierInformation: jspb.Message.getBooleanFieldWithDefault(msg, 20, false),
    pausedBy: (f = msg.getPausedBy()) && proto.vtv1.NullableString.toObject(includeInstance, f),
    unmappedProductsList: jspb.Message.toObjectList(msg.getUnmappedProductsList(),
    proto.vtv1.UnmappedProduct.toObject, includeInstance),
    ordersList: jspb.Message.toObjectList(msg.getOrdersList(),
    proto.vtv1.PurchaseOrderOrder.toObject, includeInstance),
    billable: jspb.Message.getBooleanFieldWithDefault(msg, 24, false),
    internalInboundOutbound: (f = msg.getInternalInboundOutbound()) && proto.vtv1.NullableBool.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.PurchaseOrder}
 */
proto.vtv1.PurchaseOrder.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.PurchaseOrder;
  return proto.vtv1.PurchaseOrder.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.PurchaseOrder} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.PurchaseOrder}
 */
proto.vtv1.PurchaseOrder.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setConsumerId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setConsumerName(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setSupplierId(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setSupplierName(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setShipperId(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setShipperName(value);
      break;
    case 9:
      var value = new proto.vtv1.StringIdName;
      reader.readMessage(value,proto.vtv1.StringIdName.deserializeBinaryFromReader);
      msg.addSupplierSites(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setStartsAt(value);
      break;
    case 11:
      var value = new proto.vtv1.NullableTime;
      reader.readMessage(value,proto.vtv1.NullableTime.deserializeBinaryFromReader);
      msg.setExpiresAt(value);
      break;
    case 12:
      var value = new proto.vtv1.NullableTime;
      reader.readMessage(value,proto.vtv1.NullableTime.deserializeBinaryFromReader);
      msg.setClosedAt(value);
      break;
    case 13:
      var value = new proto.vtv1.PurchaseOrderLineItem;
      reader.readMessage(value,proto.vtv1.PurchaseOrderLineItem.deserializeBinaryFromReader);
      msg.addLineItems(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLeadTimeHours(value);
      break;
    case 15:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAutoConfirmRequest(value);
      break;
    case 16:
      var value = new proto.vtv1.NullableTime;
      reader.readMessage(value,proto.vtv1.NullableTime.deserializeBinaryFromReader);
      msg.setConfirmedAt(value);
      break;
    case 17:
      var value = new proto.vtv1.NullableTime;
      reader.readMessage(value,proto.vtv1.NullableTime.deserializeBinaryFromReader);
      msg.setDeclinedAt(value);
      break;
    case 18:
      var value = new proto.vtv1.NullableString;
      reader.readMessage(value,proto.vtv1.NullableString.deserializeBinaryFromReader);
      msg.setConfirmedBy(value);
      break;
    case 19:
      var value = new proto.vtv1.NullableString;
      reader.readMessage(value,proto.vtv1.NullableString.deserializeBinaryFromReader);
      msg.setDeclinedBy(value);
      break;
    case 20:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setShipmentsRequireSupplierInformation(value);
      break;
    case 21:
      var value = new proto.vtv1.NullableString;
      reader.readMessage(value,proto.vtv1.NullableString.deserializeBinaryFromReader);
      msg.setPausedBy(value);
      break;
    case 22:
      var value = new proto.vtv1.UnmappedProduct;
      reader.readMessage(value,proto.vtv1.UnmappedProduct.deserializeBinaryFromReader);
      msg.addUnmappedProducts(value);
      break;
    case 23:
      var value = new proto.vtv1.PurchaseOrderOrder;
      reader.readMessage(value,proto.vtv1.PurchaseOrderOrder.deserializeBinaryFromReader);
      msg.addOrders(value);
      break;
    case 24:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setBillable(value);
      break;
    case 25:
      var value = new proto.vtv1.NullableBool;
      reader.readMessage(value,proto.vtv1.NullableBool.deserializeBinaryFromReader);
      msg.setInternalInboundOutbound(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.PurchaseOrder.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.PurchaseOrder.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.PurchaseOrder} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.PurchaseOrder.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getConsumerId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getConsumerName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getSupplierId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getSupplierName();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getShipperId();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getShipperName();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getSupplierSitesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      9,
      f,
      proto.vtv1.StringIdName.serializeBinaryToWriter
    );
  }
  f = message.getStartsAt();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getExpiresAt();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto.vtv1.NullableTime.serializeBinaryToWriter
    );
  }
  f = message.getClosedAt();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      proto.vtv1.NullableTime.serializeBinaryToWriter
    );
  }
  f = message.getLineItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      13,
      f,
      proto.vtv1.PurchaseOrderLineItem.serializeBinaryToWriter
    );
  }
  f = message.getLeadTimeHours();
  if (f !== 0) {
    writer.writeInt64(
      14,
      f
    );
  }
  f = message.getAutoConfirmRequest();
  if (f) {
    writer.writeBool(
      15,
      f
    );
  }
  f = message.getConfirmedAt();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      proto.vtv1.NullableTime.serializeBinaryToWriter
    );
  }
  f = message.getDeclinedAt();
  if (f != null) {
    writer.writeMessage(
      17,
      f,
      proto.vtv1.NullableTime.serializeBinaryToWriter
    );
  }
  f = message.getConfirmedBy();
  if (f != null) {
    writer.writeMessage(
      18,
      f,
      proto.vtv1.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getDeclinedBy();
  if (f != null) {
    writer.writeMessage(
      19,
      f,
      proto.vtv1.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getShipmentsRequireSupplierInformation();
  if (f) {
    writer.writeBool(
      20,
      f
    );
  }
  f = message.getPausedBy();
  if (f != null) {
    writer.writeMessage(
      21,
      f,
      proto.vtv1.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getUnmappedProductsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      22,
      f,
      proto.vtv1.UnmappedProduct.serializeBinaryToWriter
    );
  }
  f = message.getOrdersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      23,
      f,
      proto.vtv1.PurchaseOrderOrder.serializeBinaryToWriter
    );
  }
  f = message.getBillable();
  if (f) {
    writer.writeBool(
      24,
      f
    );
  }
  f = message.getInternalInboundOutbound();
  if (f != null) {
    writer.writeMessage(
      25,
      f,
      proto.vtv1.NullableBool.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.vtv1.PurchaseOrder.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.PurchaseOrder} returns this
 */
proto.vtv1.PurchaseOrder.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.vtv1.PurchaseOrder.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.PurchaseOrder} returns this
 */
proto.vtv1.PurchaseOrder.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string consumer_id = 3;
 * @return {string}
 */
proto.vtv1.PurchaseOrder.prototype.getConsumerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.PurchaseOrder} returns this
 */
proto.vtv1.PurchaseOrder.prototype.setConsumerId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string consumer_name = 4;
 * @return {string}
 */
proto.vtv1.PurchaseOrder.prototype.getConsumerName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.PurchaseOrder} returns this
 */
proto.vtv1.PurchaseOrder.prototype.setConsumerName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string supplier_id = 5;
 * @return {string}
 */
proto.vtv1.PurchaseOrder.prototype.getSupplierId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.PurchaseOrder} returns this
 */
proto.vtv1.PurchaseOrder.prototype.setSupplierId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string supplier_name = 6;
 * @return {string}
 */
proto.vtv1.PurchaseOrder.prototype.getSupplierName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.PurchaseOrder} returns this
 */
proto.vtv1.PurchaseOrder.prototype.setSupplierName = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string shipper_id = 7;
 * @return {string}
 */
proto.vtv1.PurchaseOrder.prototype.getShipperId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.PurchaseOrder} returns this
 */
proto.vtv1.PurchaseOrder.prototype.setShipperId = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string shipper_name = 8;
 * @return {string}
 */
proto.vtv1.PurchaseOrder.prototype.getShipperName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.PurchaseOrder} returns this
 */
proto.vtv1.PurchaseOrder.prototype.setShipperName = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * repeated StringIdName supplier_sites = 9;
 * @return {!Array<!proto.vtv1.StringIdName>}
 */
proto.vtv1.PurchaseOrder.prototype.getSupplierSitesList = function() {
  return /** @type{!Array<!proto.vtv1.StringIdName>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vtv1.StringIdName, 9));
};


/**
 * @param {!Array<!proto.vtv1.StringIdName>} value
 * @return {!proto.vtv1.PurchaseOrder} returns this
*/
proto.vtv1.PurchaseOrder.prototype.setSupplierSitesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 9, value);
};


/**
 * @param {!proto.vtv1.StringIdName=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.StringIdName}
 */
proto.vtv1.PurchaseOrder.prototype.addSupplierSites = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 9, opt_value, proto.vtv1.StringIdName, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.PurchaseOrder} returns this
 */
proto.vtv1.PurchaseOrder.prototype.clearSupplierSitesList = function() {
  return this.setSupplierSitesList([]);
};


/**
 * optional string starts_at = 10;
 * @return {string}
 */
proto.vtv1.PurchaseOrder.prototype.getStartsAt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.PurchaseOrder} returns this
 */
proto.vtv1.PurchaseOrder.prototype.setStartsAt = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional NullableTime expires_at = 11;
 * @return {?proto.vtv1.NullableTime}
 */
proto.vtv1.PurchaseOrder.prototype.getExpiresAt = function() {
  return /** @type{?proto.vtv1.NullableTime} */ (
    jspb.Message.getWrapperField(this, proto.vtv1.NullableTime, 11));
};


/**
 * @param {?proto.vtv1.NullableTime|undefined} value
 * @return {!proto.vtv1.PurchaseOrder} returns this
*/
proto.vtv1.PurchaseOrder.prototype.setExpiresAt = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.PurchaseOrder} returns this
 */
proto.vtv1.PurchaseOrder.prototype.clearExpiresAt = function() {
  return this.setExpiresAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.PurchaseOrder.prototype.hasExpiresAt = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional NullableTime closed_at = 12;
 * @return {?proto.vtv1.NullableTime}
 */
proto.vtv1.PurchaseOrder.prototype.getClosedAt = function() {
  return /** @type{?proto.vtv1.NullableTime} */ (
    jspb.Message.getWrapperField(this, proto.vtv1.NullableTime, 12));
};


/**
 * @param {?proto.vtv1.NullableTime|undefined} value
 * @return {!proto.vtv1.PurchaseOrder} returns this
*/
proto.vtv1.PurchaseOrder.prototype.setClosedAt = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.PurchaseOrder} returns this
 */
proto.vtv1.PurchaseOrder.prototype.clearClosedAt = function() {
  return this.setClosedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.PurchaseOrder.prototype.hasClosedAt = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * repeated PurchaseOrderLineItem line_items = 13;
 * @return {!Array<!proto.vtv1.PurchaseOrderLineItem>}
 */
proto.vtv1.PurchaseOrder.prototype.getLineItemsList = function() {
  return /** @type{!Array<!proto.vtv1.PurchaseOrderLineItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vtv1.PurchaseOrderLineItem, 13));
};


/**
 * @param {!Array<!proto.vtv1.PurchaseOrderLineItem>} value
 * @return {!proto.vtv1.PurchaseOrder} returns this
*/
proto.vtv1.PurchaseOrder.prototype.setLineItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 13, value);
};


/**
 * @param {!proto.vtv1.PurchaseOrderLineItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.PurchaseOrderLineItem}
 */
proto.vtv1.PurchaseOrder.prototype.addLineItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 13, opt_value, proto.vtv1.PurchaseOrderLineItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.PurchaseOrder} returns this
 */
proto.vtv1.PurchaseOrder.prototype.clearLineItemsList = function() {
  return this.setLineItemsList([]);
};


/**
 * optional int64 lead_time_hours = 14;
 * @return {number}
 */
proto.vtv1.PurchaseOrder.prototype.getLeadTimeHours = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.PurchaseOrder} returns this
 */
proto.vtv1.PurchaseOrder.prototype.setLeadTimeHours = function(value) {
  return jspb.Message.setProto3IntField(this, 14, value);
};


/**
 * optional bool auto_confirm_request = 15;
 * @return {boolean}
 */
proto.vtv1.PurchaseOrder.prototype.getAutoConfirmRequest = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 15, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vtv1.PurchaseOrder} returns this
 */
proto.vtv1.PurchaseOrder.prototype.setAutoConfirmRequest = function(value) {
  return jspb.Message.setProto3BooleanField(this, 15, value);
};


/**
 * optional NullableTime confirmed_at = 16;
 * @return {?proto.vtv1.NullableTime}
 */
proto.vtv1.PurchaseOrder.prototype.getConfirmedAt = function() {
  return /** @type{?proto.vtv1.NullableTime} */ (
    jspb.Message.getWrapperField(this, proto.vtv1.NullableTime, 16));
};


/**
 * @param {?proto.vtv1.NullableTime|undefined} value
 * @return {!proto.vtv1.PurchaseOrder} returns this
*/
proto.vtv1.PurchaseOrder.prototype.setConfirmedAt = function(value) {
  return jspb.Message.setWrapperField(this, 16, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.PurchaseOrder} returns this
 */
proto.vtv1.PurchaseOrder.prototype.clearConfirmedAt = function() {
  return this.setConfirmedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.PurchaseOrder.prototype.hasConfirmedAt = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * optional NullableTime declined_at = 17;
 * @return {?proto.vtv1.NullableTime}
 */
proto.vtv1.PurchaseOrder.prototype.getDeclinedAt = function() {
  return /** @type{?proto.vtv1.NullableTime} */ (
    jspb.Message.getWrapperField(this, proto.vtv1.NullableTime, 17));
};


/**
 * @param {?proto.vtv1.NullableTime|undefined} value
 * @return {!proto.vtv1.PurchaseOrder} returns this
*/
proto.vtv1.PurchaseOrder.prototype.setDeclinedAt = function(value) {
  return jspb.Message.setWrapperField(this, 17, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.PurchaseOrder} returns this
 */
proto.vtv1.PurchaseOrder.prototype.clearDeclinedAt = function() {
  return this.setDeclinedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.PurchaseOrder.prototype.hasDeclinedAt = function() {
  return jspb.Message.getField(this, 17) != null;
};


/**
 * optional NullableString confirmed_by = 18;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.PurchaseOrder.prototype.getConfirmedBy = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto.vtv1.NullableString, 18));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.PurchaseOrder} returns this
*/
proto.vtv1.PurchaseOrder.prototype.setConfirmedBy = function(value) {
  return jspb.Message.setWrapperField(this, 18, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.PurchaseOrder} returns this
 */
proto.vtv1.PurchaseOrder.prototype.clearConfirmedBy = function() {
  return this.setConfirmedBy(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.PurchaseOrder.prototype.hasConfirmedBy = function() {
  return jspb.Message.getField(this, 18) != null;
};


/**
 * optional NullableString declined_by = 19;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.PurchaseOrder.prototype.getDeclinedBy = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto.vtv1.NullableString, 19));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.PurchaseOrder} returns this
*/
proto.vtv1.PurchaseOrder.prototype.setDeclinedBy = function(value) {
  return jspb.Message.setWrapperField(this, 19, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.PurchaseOrder} returns this
 */
proto.vtv1.PurchaseOrder.prototype.clearDeclinedBy = function() {
  return this.setDeclinedBy(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.PurchaseOrder.prototype.hasDeclinedBy = function() {
  return jspb.Message.getField(this, 19) != null;
};


/**
 * optional bool shipments_require_supplier_information = 20;
 * @return {boolean}
 */
proto.vtv1.PurchaseOrder.prototype.getShipmentsRequireSupplierInformation = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 20, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vtv1.PurchaseOrder} returns this
 */
proto.vtv1.PurchaseOrder.prototype.setShipmentsRequireSupplierInformation = function(value) {
  return jspb.Message.setProto3BooleanField(this, 20, value);
};


/**
 * optional NullableString paused_by = 21;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.PurchaseOrder.prototype.getPausedBy = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto.vtv1.NullableString, 21));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.PurchaseOrder} returns this
*/
proto.vtv1.PurchaseOrder.prototype.setPausedBy = function(value) {
  return jspb.Message.setWrapperField(this, 21, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.PurchaseOrder} returns this
 */
proto.vtv1.PurchaseOrder.prototype.clearPausedBy = function() {
  return this.setPausedBy(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.PurchaseOrder.prototype.hasPausedBy = function() {
  return jspb.Message.getField(this, 21) != null;
};


/**
 * repeated UnmappedProduct unmapped_products = 22;
 * @return {!Array<!proto.vtv1.UnmappedProduct>}
 */
proto.vtv1.PurchaseOrder.prototype.getUnmappedProductsList = function() {
  return /** @type{!Array<!proto.vtv1.UnmappedProduct>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vtv1.UnmappedProduct, 22));
};


/**
 * @param {!Array<!proto.vtv1.UnmappedProduct>} value
 * @return {!proto.vtv1.PurchaseOrder} returns this
*/
proto.vtv1.PurchaseOrder.prototype.setUnmappedProductsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 22, value);
};


/**
 * @param {!proto.vtv1.UnmappedProduct=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.UnmappedProduct}
 */
proto.vtv1.PurchaseOrder.prototype.addUnmappedProducts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 22, opt_value, proto.vtv1.UnmappedProduct, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.PurchaseOrder} returns this
 */
proto.vtv1.PurchaseOrder.prototype.clearUnmappedProductsList = function() {
  return this.setUnmappedProductsList([]);
};


/**
 * repeated PurchaseOrderOrder orders = 23;
 * @return {!Array<!proto.vtv1.PurchaseOrderOrder>}
 */
proto.vtv1.PurchaseOrder.prototype.getOrdersList = function() {
  return /** @type{!Array<!proto.vtv1.PurchaseOrderOrder>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vtv1.PurchaseOrderOrder, 23));
};


/**
 * @param {!Array<!proto.vtv1.PurchaseOrderOrder>} value
 * @return {!proto.vtv1.PurchaseOrder} returns this
*/
proto.vtv1.PurchaseOrder.prototype.setOrdersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 23, value);
};


/**
 * @param {!proto.vtv1.PurchaseOrderOrder=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.PurchaseOrderOrder}
 */
proto.vtv1.PurchaseOrder.prototype.addOrders = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 23, opt_value, proto.vtv1.PurchaseOrderOrder, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.PurchaseOrder} returns this
 */
proto.vtv1.PurchaseOrder.prototype.clearOrdersList = function() {
  return this.setOrdersList([]);
};


/**
 * optional bool billable = 24;
 * @return {boolean}
 */
proto.vtv1.PurchaseOrder.prototype.getBillable = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 24, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vtv1.PurchaseOrder} returns this
 */
proto.vtv1.PurchaseOrder.prototype.setBillable = function(value) {
  return jspb.Message.setProto3BooleanField(this, 24, value);
};


/**
 * optional NullableBool internal_inbound_outbound = 25;
 * @return {?proto.vtv1.NullableBool}
 */
proto.vtv1.PurchaseOrder.prototype.getInternalInboundOutbound = function() {
  return /** @type{?proto.vtv1.NullableBool} */ (
    jspb.Message.getWrapperField(this, proto.vtv1.NullableBool, 25));
};


/**
 * @param {?proto.vtv1.NullableBool|undefined} value
 * @return {!proto.vtv1.PurchaseOrder} returns this
*/
proto.vtv1.PurchaseOrder.prototype.setInternalInboundOutbound = function(value) {
  return jspb.Message.setWrapperField(this, 25, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.PurchaseOrder} returns this
 */
proto.vtv1.PurchaseOrder.prototype.clearInternalInboundOutbound = function() {
  return this.setInternalInboundOutbound(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.PurchaseOrder.prototype.hasInternalInboundOutbound = function() {
  return jspb.Message.getField(this, 25) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.PurchaseOrderOrder.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.PurchaseOrderOrder.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.PurchaseOrderOrder} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.PurchaseOrderOrder.toObject = function(includeInstance, msg) {
  var f, obj = {
    orderId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    quantity: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    productId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    productName: jspb.Message.getFieldWithDefault(msg, 4, ""),
    unitAbbreviation: jspb.Message.getFieldWithDefault(msg, 5, ""),
    status: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.PurchaseOrderOrder}
 */
proto.vtv1.PurchaseOrderOrder.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.PurchaseOrderOrder;
  return proto.vtv1.PurchaseOrderOrder.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.PurchaseOrderOrder} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.PurchaseOrderOrder}
 */
proto.vtv1.PurchaseOrderOrder.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrderId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setQuantity(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductName(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setUnitAbbreviation(value);
      break;
    case 6:
      var value = /** @type {!proto.vtv1.OrderStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.PurchaseOrderOrder.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.PurchaseOrderOrder.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.PurchaseOrderOrder} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.PurchaseOrderOrder.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrderId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getQuantity();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getProductId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getProductName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getUnitAbbreviation();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
};


/**
 * optional string order_id = 1;
 * @return {string}
 */
proto.vtv1.PurchaseOrderOrder.prototype.getOrderId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.PurchaseOrderOrder} returns this
 */
proto.vtv1.PurchaseOrderOrder.prototype.setOrderId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional double quantity = 2;
 * @return {number}
 */
proto.vtv1.PurchaseOrderOrder.prototype.getQuantity = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.PurchaseOrderOrder} returns this
 */
proto.vtv1.PurchaseOrderOrder.prototype.setQuantity = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional string product_id = 3;
 * @return {string}
 */
proto.vtv1.PurchaseOrderOrder.prototype.getProductId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.PurchaseOrderOrder} returns this
 */
proto.vtv1.PurchaseOrderOrder.prototype.setProductId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string product_name = 4;
 * @return {string}
 */
proto.vtv1.PurchaseOrderOrder.prototype.getProductName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.PurchaseOrderOrder} returns this
 */
proto.vtv1.PurchaseOrderOrder.prototype.setProductName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string unit_abbreviation = 5;
 * @return {string}
 */
proto.vtv1.PurchaseOrderOrder.prototype.getUnitAbbreviation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.PurchaseOrderOrder} returns this
 */
proto.vtv1.PurchaseOrderOrder.prototype.setUnitAbbreviation = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional OrderStatus status = 6;
 * @return {!proto.vtv1.OrderStatus}
 */
proto.vtv1.PurchaseOrderOrder.prototype.getStatus = function() {
  return /** @type {!proto.vtv1.OrderStatus} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.vtv1.OrderStatus} value
 * @return {!proto.vtv1.PurchaseOrderOrder} returns this
 */
proto.vtv1.PurchaseOrderOrder.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.SlimPurchaseOrder.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.SlimPurchaseOrder.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.SlimPurchaseOrder.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.SlimPurchaseOrder} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.SlimPurchaseOrder.toObject = function(includeInstance, msg) {
  var f, obj = {
    poName: (f = msg.getPoName()) && proto.vtv1.StringIdName.toObject(includeInstance, f),
    sitesList: jspb.Message.toObjectList(msg.getSitesList(),
    proto.vtv1.StringIdName.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.SlimPurchaseOrder}
 */
proto.vtv1.SlimPurchaseOrder.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.SlimPurchaseOrder;
  return proto.vtv1.SlimPurchaseOrder.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.SlimPurchaseOrder} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.SlimPurchaseOrder}
 */
proto.vtv1.SlimPurchaseOrder.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.vtv1.StringIdName;
      reader.readMessage(value,proto.vtv1.StringIdName.deserializeBinaryFromReader);
      msg.setPoName(value);
      break;
    case 2:
      var value = new proto.vtv1.StringIdName;
      reader.readMessage(value,proto.vtv1.StringIdName.deserializeBinaryFromReader);
      msg.addSites(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.SlimPurchaseOrder.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.SlimPurchaseOrder.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.SlimPurchaseOrder} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.SlimPurchaseOrder.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPoName();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.vtv1.StringIdName.serializeBinaryToWriter
    );
  }
  f = message.getSitesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.vtv1.StringIdName.serializeBinaryToWriter
    );
  }
};


/**
 * optional StringIdName po_name = 1;
 * @return {?proto.vtv1.StringIdName}
 */
proto.vtv1.SlimPurchaseOrder.prototype.getPoName = function() {
  return /** @type{?proto.vtv1.StringIdName} */ (
    jspb.Message.getWrapperField(this, proto.vtv1.StringIdName, 1));
};


/**
 * @param {?proto.vtv1.StringIdName|undefined} value
 * @return {!proto.vtv1.SlimPurchaseOrder} returns this
*/
proto.vtv1.SlimPurchaseOrder.prototype.setPoName = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.SlimPurchaseOrder} returns this
 */
proto.vtv1.SlimPurchaseOrder.prototype.clearPoName = function() {
  return this.setPoName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.SlimPurchaseOrder.prototype.hasPoName = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated StringIdName sites = 2;
 * @return {!Array<!proto.vtv1.StringIdName>}
 */
proto.vtv1.SlimPurchaseOrder.prototype.getSitesList = function() {
  return /** @type{!Array<!proto.vtv1.StringIdName>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vtv1.StringIdName, 2));
};


/**
 * @param {!Array<!proto.vtv1.StringIdName>} value
 * @return {!proto.vtv1.SlimPurchaseOrder} returns this
*/
proto.vtv1.SlimPurchaseOrder.prototype.setSitesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.vtv1.StringIdName=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.StringIdName}
 */
proto.vtv1.SlimPurchaseOrder.prototype.addSites = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.vtv1.StringIdName, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.SlimPurchaseOrder} returns this
 */
proto.vtv1.SlimPurchaseOrder.prototype.clearSitesList = function() {
  return this.setSitesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.UnmappedProduct.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.UnmappedProduct.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.UnmappedProduct} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.UnmappedProduct.toObject = function(includeInstance, msg) {
  var f, obj = {
    consumerProductId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    consumerProductName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    consumerProductUnit: (f = msg.getConsumerProductUnit()) && proto.vtv1.Unit.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.UnmappedProduct}
 */
proto.vtv1.UnmappedProduct.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.UnmappedProduct;
  return proto.vtv1.UnmappedProduct.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.UnmappedProduct} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.UnmappedProduct}
 */
proto.vtv1.UnmappedProduct.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setConsumerProductId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setConsumerProductName(value);
      break;
    case 3:
      var value = new proto.vtv1.Unit;
      reader.readMessage(value,proto.vtv1.Unit.deserializeBinaryFromReader);
      msg.setConsumerProductUnit(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.UnmappedProduct.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.UnmappedProduct.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.UnmappedProduct} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.UnmappedProduct.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getConsumerProductId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getConsumerProductName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getConsumerProductUnit();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.vtv1.Unit.serializeBinaryToWriter
    );
  }
};


/**
 * optional string consumer_product_id = 1;
 * @return {string}
 */
proto.vtv1.UnmappedProduct.prototype.getConsumerProductId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.UnmappedProduct} returns this
 */
proto.vtv1.UnmappedProduct.prototype.setConsumerProductId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string consumer_product_name = 2;
 * @return {string}
 */
proto.vtv1.UnmappedProduct.prototype.getConsumerProductName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.UnmappedProduct} returns this
 */
proto.vtv1.UnmappedProduct.prototype.setConsumerProductName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional Unit consumer_product_unit = 3;
 * @return {?proto.vtv1.Unit}
 */
proto.vtv1.UnmappedProduct.prototype.getConsumerProductUnit = function() {
  return /** @type{?proto.vtv1.Unit} */ (
    jspb.Message.getWrapperField(this, proto.vtv1.Unit, 3));
};


/**
 * @param {?proto.vtv1.Unit|undefined} value
 * @return {!proto.vtv1.UnmappedProduct} returns this
*/
proto.vtv1.UnmappedProduct.prototype.setConsumerProductUnit = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.UnmappedProduct} returns this
 */
proto.vtv1.UnmappedProduct.prototype.clearConsumerProductUnit = function() {
  return this.setConsumerProductUnit(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.UnmappedProduct.prototype.hasConsumerProductUnit = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.FlatDemandSignal.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.FlatDemandSignal.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.FlatDemandSignal} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.FlatDemandSignal.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    consumerAccountId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    siteId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    quantity: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    deliveryWindowStartsAt: jspb.Message.getFieldWithDefault(msg, 5, ""),
    deliveryWindowMinutes: jspb.Message.getFieldWithDefault(msg, 6, 0),
    archivedAt: (f = msg.getArchivedAt()) && proto.vtv1.NullableTime.toObject(includeInstance, f),
    productId: jspb.Message.getFieldWithDefault(msg, 8, ""),
    createdBy: (f = msg.getCreatedBy()) && proto.vtv1.NullableString.toObject(includeInstance, f),
    quantityPerLoad: jspb.Message.getFloatingPointFieldWithDefault(msg, 10, 0.0),
    suppliersAccepted: jspb.Message.getFieldWithDefault(msg, 11, 0),
    suppliersTotal: jspb.Message.getFieldWithDefault(msg, 12, 0),
    siteName: jspb.Message.getFieldWithDefault(msg, 13, ""),
    productName: jspb.Message.getFieldWithDefault(msg, 14, ""),
    unitId: jspb.Message.getFieldWithDefault(msg, 15, 0),
    unitAbbreviation: jspb.Message.getFieldWithDefault(msg, 16, ""),
    unitName: jspb.Message.getFieldWithDefault(msg, 17, ""),
    createdAt: jspb.Message.getFieldWithDefault(msg, 18, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.FlatDemandSignal}
 */
proto.vtv1.FlatDemandSignal.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.FlatDemandSignal;
  return proto.vtv1.FlatDemandSignal.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.FlatDemandSignal} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.FlatDemandSignal}
 */
proto.vtv1.FlatDemandSignal.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setConsumerAccountId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSiteId(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setQuantity(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeliveryWindowStartsAt(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDeliveryWindowMinutes(value);
      break;
    case 7:
      var value = new proto.vtv1.NullableTime;
      reader.readMessage(value,proto.vtv1.NullableTime.deserializeBinaryFromReader);
      msg.setArchivedAt(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductId(value);
      break;
    case 9:
      var value = new proto.vtv1.NullableString;
      reader.readMessage(value,proto.vtv1.NullableString.deserializeBinaryFromReader);
      msg.setCreatedBy(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setQuantityPerLoad(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSuppliersAccepted(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSuppliersTotal(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setSiteName(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductName(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUnitId(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setUnitAbbreviation(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setUnitName(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.FlatDemandSignal.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.FlatDemandSignal.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.FlatDemandSignal} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.FlatDemandSignal.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getConsumerAccountId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSiteId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getQuantity();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getDeliveryWindowStartsAt();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getDeliveryWindowMinutes();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getArchivedAt();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.vtv1.NullableTime.serializeBinaryToWriter
    );
  }
  f = message.getProductId();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getCreatedBy();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.vtv1.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getQuantityPerLoad();
  if (f !== 0.0) {
    writer.writeDouble(
      10,
      f
    );
  }
  f = message.getSuppliersAccepted();
  if (f !== 0) {
    writer.writeInt64(
      11,
      f
    );
  }
  f = message.getSuppliersTotal();
  if (f !== 0) {
    writer.writeInt64(
      12,
      f
    );
  }
  f = message.getSiteName();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getProductName();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getUnitId();
  if (f !== 0) {
    writer.writeInt64(
      15,
      f
    );
  }
  f = message.getUnitAbbreviation();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getUnitName();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getCreatedAt();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.vtv1.FlatDemandSignal.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.FlatDemandSignal} returns this
 */
proto.vtv1.FlatDemandSignal.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string consumer_account_id = 2;
 * @return {string}
 */
proto.vtv1.FlatDemandSignal.prototype.getConsumerAccountId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.FlatDemandSignal} returns this
 */
proto.vtv1.FlatDemandSignal.prototype.setConsumerAccountId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string site_id = 3;
 * @return {string}
 */
proto.vtv1.FlatDemandSignal.prototype.getSiteId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.FlatDemandSignal} returns this
 */
proto.vtv1.FlatDemandSignal.prototype.setSiteId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional double quantity = 4;
 * @return {number}
 */
proto.vtv1.FlatDemandSignal.prototype.getQuantity = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.FlatDemandSignal} returns this
 */
proto.vtv1.FlatDemandSignal.prototype.setQuantity = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional string delivery_window_starts_at = 5;
 * @return {string}
 */
proto.vtv1.FlatDemandSignal.prototype.getDeliveryWindowStartsAt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.FlatDemandSignal} returns this
 */
proto.vtv1.FlatDemandSignal.prototype.setDeliveryWindowStartsAt = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional int64 delivery_window_minutes = 6;
 * @return {number}
 */
proto.vtv1.FlatDemandSignal.prototype.getDeliveryWindowMinutes = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.FlatDemandSignal} returns this
 */
proto.vtv1.FlatDemandSignal.prototype.setDeliveryWindowMinutes = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional NullableTime archived_at = 7;
 * @return {?proto.vtv1.NullableTime}
 */
proto.vtv1.FlatDemandSignal.prototype.getArchivedAt = function() {
  return /** @type{?proto.vtv1.NullableTime} */ (
    jspb.Message.getWrapperField(this, proto.vtv1.NullableTime, 7));
};


/**
 * @param {?proto.vtv1.NullableTime|undefined} value
 * @return {!proto.vtv1.FlatDemandSignal} returns this
*/
proto.vtv1.FlatDemandSignal.prototype.setArchivedAt = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.FlatDemandSignal} returns this
 */
proto.vtv1.FlatDemandSignal.prototype.clearArchivedAt = function() {
  return this.setArchivedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.FlatDemandSignal.prototype.hasArchivedAt = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional string product_id = 8;
 * @return {string}
 */
proto.vtv1.FlatDemandSignal.prototype.getProductId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.FlatDemandSignal} returns this
 */
proto.vtv1.FlatDemandSignal.prototype.setProductId = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional NullableString created_by = 9;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.FlatDemandSignal.prototype.getCreatedBy = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto.vtv1.NullableString, 9));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.FlatDemandSignal} returns this
*/
proto.vtv1.FlatDemandSignal.prototype.setCreatedBy = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.FlatDemandSignal} returns this
 */
proto.vtv1.FlatDemandSignal.prototype.clearCreatedBy = function() {
  return this.setCreatedBy(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.FlatDemandSignal.prototype.hasCreatedBy = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional double quantity_per_load = 10;
 * @return {number}
 */
proto.vtv1.FlatDemandSignal.prototype.getQuantityPerLoad = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 10, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.FlatDemandSignal} returns this
 */
proto.vtv1.FlatDemandSignal.prototype.setQuantityPerLoad = function(value) {
  return jspb.Message.setProto3FloatField(this, 10, value);
};


/**
 * optional int64 suppliers_accepted = 11;
 * @return {number}
 */
proto.vtv1.FlatDemandSignal.prototype.getSuppliersAccepted = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.FlatDemandSignal} returns this
 */
proto.vtv1.FlatDemandSignal.prototype.setSuppliersAccepted = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional int64 suppliers_total = 12;
 * @return {number}
 */
proto.vtv1.FlatDemandSignal.prototype.getSuppliersTotal = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.FlatDemandSignal} returns this
 */
proto.vtv1.FlatDemandSignal.prototype.setSuppliersTotal = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional string site_name = 13;
 * @return {string}
 */
proto.vtv1.FlatDemandSignal.prototype.getSiteName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.FlatDemandSignal} returns this
 */
proto.vtv1.FlatDemandSignal.prototype.setSiteName = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string product_name = 14;
 * @return {string}
 */
proto.vtv1.FlatDemandSignal.prototype.getProductName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.FlatDemandSignal} returns this
 */
proto.vtv1.FlatDemandSignal.prototype.setProductName = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional int64 unit_id = 15;
 * @return {number}
 */
proto.vtv1.FlatDemandSignal.prototype.getUnitId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.FlatDemandSignal} returns this
 */
proto.vtv1.FlatDemandSignal.prototype.setUnitId = function(value) {
  return jspb.Message.setProto3IntField(this, 15, value);
};


/**
 * optional string unit_abbreviation = 16;
 * @return {string}
 */
proto.vtv1.FlatDemandSignal.prototype.getUnitAbbreviation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.FlatDemandSignal} returns this
 */
proto.vtv1.FlatDemandSignal.prototype.setUnitAbbreviation = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional string unit_name = 17;
 * @return {string}
 */
proto.vtv1.FlatDemandSignal.prototype.getUnitName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.FlatDemandSignal} returns this
 */
proto.vtv1.FlatDemandSignal.prototype.setUnitName = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional string created_at = 18;
 * @return {string}
 */
proto.vtv1.FlatDemandSignal.prototype.getCreatedAt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.FlatDemandSignal} returns this
 */
proto.vtv1.FlatDemandSignal.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.FlatDemandSignalResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.FlatDemandSignalResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.FlatDemandSignalResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.FlatDemandSignalResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    demandSignalRequestId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    siteId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    quantity: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.FlatDemandSignalResponse}
 */
proto.vtv1.FlatDemandSignalResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.FlatDemandSignalResponse;
  return proto.vtv1.FlatDemandSignalResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.FlatDemandSignalResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.FlatDemandSignalResponse}
 */
proto.vtv1.FlatDemandSignalResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDemandSignalRequestId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSiteId(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setQuantity(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.FlatDemandSignalResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.FlatDemandSignalResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.FlatDemandSignalResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.FlatDemandSignalResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getDemandSignalRequestId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getSiteId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getQuantity();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.vtv1.FlatDemandSignalResponse.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.FlatDemandSignalResponse} returns this
 */
proto.vtv1.FlatDemandSignalResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 demand_signal_request_id = 2;
 * @return {number}
 */
proto.vtv1.FlatDemandSignalResponse.prototype.getDemandSignalRequestId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.FlatDemandSignalResponse} returns this
 */
proto.vtv1.FlatDemandSignalResponse.prototype.setDemandSignalRequestId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string site_id = 3;
 * @return {string}
 */
proto.vtv1.FlatDemandSignalResponse.prototype.getSiteId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.FlatDemandSignalResponse} returns this
 */
proto.vtv1.FlatDemandSignalResponse.prototype.setSiteId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional double quantity = 4;
 * @return {number}
 */
proto.vtv1.FlatDemandSignalResponse.prototype.getQuantity = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.FlatDemandSignalResponse} returns this
 */
proto.vtv1.FlatDemandSignalResponse.prototype.setQuantity = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.FlatDemandSignalRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.FlatDemandSignalRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.FlatDemandSignalRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.FlatDemandSignalRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    demandSignalId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    siteId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    siteName: jspb.Message.getFieldWithDefault(msg, 4, ""),
    productId: jspb.Message.getFieldWithDefault(msg, 5, ""),
    productName: jspb.Message.getFieldWithDefault(msg, 6, ""),
    deliveryWindowStartsAt: jspb.Message.getFieldWithDefault(msg, 7, ""),
    deliveryWindowMinutes: jspb.Message.getFieldWithDefault(msg, 8, 0),
    quantityPerLoad: jspb.Message.getFloatingPointFieldWithDefault(msg, 9, 0.0),
    consumerAccountId: jspb.Message.getFieldWithDefault(msg, 10, ""),
    consumerAccountName: jspb.Message.getFieldWithDefault(msg, 11, ""),
    quantity: jspb.Message.getFloatingPointFieldWithDefault(msg, 12, 0.0),
    totalQuantity: jspb.Message.getFloatingPointFieldWithDefault(msg, 13, 0.0),
    unitId: jspb.Message.getFieldWithDefault(msg, 14, 0),
    unitAbbreviation: jspb.Message.getFieldWithDefault(msg, 15, ""),
    unitName: jspb.Message.getFieldWithDefault(msg, 16, ""),
    deliveryWindowEndsAt: jspb.Message.getFieldWithDefault(msg, 17, ""),
    createdAt: jspb.Message.getFieldWithDefault(msg, 18, ""),
    updatedAt: (f = msg.getUpdatedAt()) && proto.vtv1.NullableTime.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.FlatDemandSignalRequest}
 */
proto.vtv1.FlatDemandSignalRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.FlatDemandSignalRequest;
  return proto.vtv1.FlatDemandSignalRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.FlatDemandSignalRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.FlatDemandSignalRequest}
 */
proto.vtv1.FlatDemandSignalRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRequestId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDemandSignalId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSiteId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setSiteName(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductId(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductName(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeliveryWindowStartsAt(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDeliveryWindowMinutes(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setQuantityPerLoad(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setConsumerAccountId(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setConsumerAccountName(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setQuantity(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalQuantity(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUnitId(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setUnitAbbreviation(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setUnitName(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeliveryWindowEndsAt(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedAt(value);
      break;
    case 19:
      var value = new proto.vtv1.NullableTime;
      reader.readMessage(value,proto.vtv1.NullableTime.deserializeBinaryFromReader);
      msg.setUpdatedAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.FlatDemandSignalRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.FlatDemandSignalRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.FlatDemandSignalRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.FlatDemandSignalRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getDemandSignalId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSiteId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSiteName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getProductId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getProductName();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getDeliveryWindowStartsAt();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getDeliveryWindowMinutes();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
  f = message.getQuantityPerLoad();
  if (f !== 0.0) {
    writer.writeDouble(
      9,
      f
    );
  }
  f = message.getConsumerAccountId();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getConsumerAccountName();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getQuantity();
  if (f !== 0.0) {
    writer.writeDouble(
      12,
      f
    );
  }
  f = message.getTotalQuantity();
  if (f !== 0.0) {
    writer.writeDouble(
      13,
      f
    );
  }
  f = message.getUnitId();
  if (f !== 0) {
    writer.writeInt64(
      14,
      f
    );
  }
  f = message.getUnitAbbreviation();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getUnitName();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getDeliveryWindowEndsAt();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getCreatedAt();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
  f = message.getUpdatedAt();
  if (f != null) {
    writer.writeMessage(
      19,
      f,
      proto.vtv1.NullableTime.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 request_id = 1;
 * @return {number}
 */
proto.vtv1.FlatDemandSignalRequest.prototype.getRequestId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.FlatDemandSignalRequest} returns this
 */
proto.vtv1.FlatDemandSignalRequest.prototype.setRequestId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string demand_signal_id = 2;
 * @return {string}
 */
proto.vtv1.FlatDemandSignalRequest.prototype.getDemandSignalId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.FlatDemandSignalRequest} returns this
 */
proto.vtv1.FlatDemandSignalRequest.prototype.setDemandSignalId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string site_id = 3;
 * @return {string}
 */
proto.vtv1.FlatDemandSignalRequest.prototype.getSiteId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.FlatDemandSignalRequest} returns this
 */
proto.vtv1.FlatDemandSignalRequest.prototype.setSiteId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string site_name = 4;
 * @return {string}
 */
proto.vtv1.FlatDemandSignalRequest.prototype.getSiteName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.FlatDemandSignalRequest} returns this
 */
proto.vtv1.FlatDemandSignalRequest.prototype.setSiteName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string product_id = 5;
 * @return {string}
 */
proto.vtv1.FlatDemandSignalRequest.prototype.getProductId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.FlatDemandSignalRequest} returns this
 */
proto.vtv1.FlatDemandSignalRequest.prototype.setProductId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string product_name = 6;
 * @return {string}
 */
proto.vtv1.FlatDemandSignalRequest.prototype.getProductName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.FlatDemandSignalRequest} returns this
 */
proto.vtv1.FlatDemandSignalRequest.prototype.setProductName = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string delivery_window_starts_at = 7;
 * @return {string}
 */
proto.vtv1.FlatDemandSignalRequest.prototype.getDeliveryWindowStartsAt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.FlatDemandSignalRequest} returns this
 */
proto.vtv1.FlatDemandSignalRequest.prototype.setDeliveryWindowStartsAt = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional int64 delivery_window_minutes = 8;
 * @return {number}
 */
proto.vtv1.FlatDemandSignalRequest.prototype.getDeliveryWindowMinutes = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.FlatDemandSignalRequest} returns this
 */
proto.vtv1.FlatDemandSignalRequest.prototype.setDeliveryWindowMinutes = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional double quantity_per_load = 9;
 * @return {number}
 */
proto.vtv1.FlatDemandSignalRequest.prototype.getQuantityPerLoad = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 9, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.FlatDemandSignalRequest} returns this
 */
proto.vtv1.FlatDemandSignalRequest.prototype.setQuantityPerLoad = function(value) {
  return jspb.Message.setProto3FloatField(this, 9, value);
};


/**
 * optional string consumer_account_id = 10;
 * @return {string}
 */
proto.vtv1.FlatDemandSignalRequest.prototype.getConsumerAccountId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.FlatDemandSignalRequest} returns this
 */
proto.vtv1.FlatDemandSignalRequest.prototype.setConsumerAccountId = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string consumer_account_name = 11;
 * @return {string}
 */
proto.vtv1.FlatDemandSignalRequest.prototype.getConsumerAccountName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.FlatDemandSignalRequest} returns this
 */
proto.vtv1.FlatDemandSignalRequest.prototype.setConsumerAccountName = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional double quantity = 12;
 * @return {number}
 */
proto.vtv1.FlatDemandSignalRequest.prototype.getQuantity = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 12, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.FlatDemandSignalRequest} returns this
 */
proto.vtv1.FlatDemandSignalRequest.prototype.setQuantity = function(value) {
  return jspb.Message.setProto3FloatField(this, 12, value);
};


/**
 * optional double total_quantity = 13;
 * @return {number}
 */
proto.vtv1.FlatDemandSignalRequest.prototype.getTotalQuantity = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 13, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.FlatDemandSignalRequest} returns this
 */
proto.vtv1.FlatDemandSignalRequest.prototype.setTotalQuantity = function(value) {
  return jspb.Message.setProto3FloatField(this, 13, value);
};


/**
 * optional int64 unit_id = 14;
 * @return {number}
 */
proto.vtv1.FlatDemandSignalRequest.prototype.getUnitId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.FlatDemandSignalRequest} returns this
 */
proto.vtv1.FlatDemandSignalRequest.prototype.setUnitId = function(value) {
  return jspb.Message.setProto3IntField(this, 14, value);
};


/**
 * optional string unit_abbreviation = 15;
 * @return {string}
 */
proto.vtv1.FlatDemandSignalRequest.prototype.getUnitAbbreviation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.FlatDemandSignalRequest} returns this
 */
proto.vtv1.FlatDemandSignalRequest.prototype.setUnitAbbreviation = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional string unit_name = 16;
 * @return {string}
 */
proto.vtv1.FlatDemandSignalRequest.prototype.getUnitName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.FlatDemandSignalRequest} returns this
 */
proto.vtv1.FlatDemandSignalRequest.prototype.setUnitName = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional string delivery_window_ends_at = 17;
 * @return {string}
 */
proto.vtv1.FlatDemandSignalRequest.prototype.getDeliveryWindowEndsAt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.FlatDemandSignalRequest} returns this
 */
proto.vtv1.FlatDemandSignalRequest.prototype.setDeliveryWindowEndsAt = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional string created_at = 18;
 * @return {string}
 */
proto.vtv1.FlatDemandSignalRequest.prototype.getCreatedAt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.FlatDemandSignalRequest} returns this
 */
proto.vtv1.FlatDemandSignalRequest.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};


/**
 * optional NullableTime updated_at = 19;
 * @return {?proto.vtv1.NullableTime}
 */
proto.vtv1.FlatDemandSignalRequest.prototype.getUpdatedAt = function() {
  return /** @type{?proto.vtv1.NullableTime} */ (
    jspb.Message.getWrapperField(this, proto.vtv1.NullableTime, 19));
};


/**
 * @param {?proto.vtv1.NullableTime|undefined} value
 * @return {!proto.vtv1.FlatDemandSignalRequest} returns this
*/
proto.vtv1.FlatDemandSignalRequest.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 19, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.FlatDemandSignalRequest} returns this
 */
proto.vtv1.FlatDemandSignalRequest.prototype.clearUpdatedAt = function() {
  return this.setUpdatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.FlatDemandSignalRequest.prototype.hasUpdatedAt = function() {
  return jspb.Message.getField(this, 19) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.SlimAccount.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.SlimAccount.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.SlimAccount} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.SlimAccount.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    virtual: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.SlimAccount}
 */
proto.vtv1.SlimAccount.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.SlimAccount;
  return proto.vtv1.SlimAccount.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.SlimAccount} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.SlimAccount}
 */
proto.vtv1.SlimAccount.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setVirtual(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.SlimAccount.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.SlimAccount.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.SlimAccount} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.SlimAccount.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getVirtual();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.vtv1.SlimAccount.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.SlimAccount} returns this
 */
proto.vtv1.SlimAccount.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.vtv1.SlimAccount.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.SlimAccount} returns this
 */
proto.vtv1.SlimAccount.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool virtual = 3;
 * @return {boolean}
 */
proto.vtv1.SlimAccount.prototype.getVirtual = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vtv1.SlimAccount} returns this
 */
proto.vtv1.SlimAccount.prototype.setVirtual = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.SiteLoadingSchedule.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.SiteLoadingSchedule.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.SiteLoadingSchedule.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.SiteLoadingSchedule} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.SiteLoadingSchedule.toObject = function(includeInstance, msg) {
  var f, obj = {
    siteId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    entriesList: jspb.Message.toObjectList(msg.getEntriesList(),
    proto.vtv1.SiteLoadingScheduleEntry.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.SiteLoadingSchedule}
 */
proto.vtv1.SiteLoadingSchedule.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.SiteLoadingSchedule;
  return proto.vtv1.SiteLoadingSchedule.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.SiteLoadingSchedule} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.SiteLoadingSchedule}
 */
proto.vtv1.SiteLoadingSchedule.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSiteId(value);
      break;
    case 2:
      var value = new proto.vtv1.SiteLoadingScheduleEntry;
      reader.readMessage(value,proto.vtv1.SiteLoadingScheduleEntry.deserializeBinaryFromReader);
      msg.addEntries(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.SiteLoadingSchedule.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.SiteLoadingSchedule.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.SiteLoadingSchedule} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.SiteLoadingSchedule.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSiteId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEntriesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.vtv1.SiteLoadingScheduleEntry.serializeBinaryToWriter
    );
  }
};


/**
 * optional string site_id = 1;
 * @return {string}
 */
proto.vtv1.SiteLoadingSchedule.prototype.getSiteId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.SiteLoadingSchedule} returns this
 */
proto.vtv1.SiteLoadingSchedule.prototype.setSiteId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated SiteLoadingScheduleEntry entries = 2;
 * @return {!Array<!proto.vtv1.SiteLoadingScheduleEntry>}
 */
proto.vtv1.SiteLoadingSchedule.prototype.getEntriesList = function() {
  return /** @type{!Array<!proto.vtv1.SiteLoadingScheduleEntry>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vtv1.SiteLoadingScheduleEntry, 2));
};


/**
 * @param {!Array<!proto.vtv1.SiteLoadingScheduleEntry>} value
 * @return {!proto.vtv1.SiteLoadingSchedule} returns this
*/
proto.vtv1.SiteLoadingSchedule.prototype.setEntriesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.vtv1.SiteLoadingScheduleEntry=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.SiteLoadingScheduleEntry}
 */
proto.vtv1.SiteLoadingSchedule.prototype.addEntries = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.vtv1.SiteLoadingScheduleEntry, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.SiteLoadingSchedule} returns this
 */
proto.vtv1.SiteLoadingSchedule.prototype.clearEntriesList = function() {
  return this.setEntriesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.SiteLoadingScheduleEntry.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.SiteLoadingScheduleEntry.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.SiteLoadingScheduleEntry} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.SiteLoadingScheduleEntry.toObject = function(includeInstance, msg) {
  var f, obj = {
    daysSinceSunday: jspb.Message.getFieldWithDefault(msg, 1, 0),
    openMinuteOfDay: jspb.Message.getFieldWithDefault(msg, 2, 0),
    closeMinuteOfDay: jspb.Message.getFieldWithDefault(msg, 3, 0),
    type: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.SiteLoadingScheduleEntry}
 */
proto.vtv1.SiteLoadingScheduleEntry.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.SiteLoadingScheduleEntry;
  return proto.vtv1.SiteLoadingScheduleEntry.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.SiteLoadingScheduleEntry} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.SiteLoadingScheduleEntry}
 */
proto.vtv1.SiteLoadingScheduleEntry.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDaysSinceSunday(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setOpenMinuteOfDay(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCloseMinuteOfDay(value);
      break;
    case 4:
      var value = /** @type {!proto.vtv1.SiteLoadingScheduleType} */ (reader.readEnum());
      msg.setType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.SiteLoadingScheduleEntry.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.SiteLoadingScheduleEntry.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.SiteLoadingScheduleEntry} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.SiteLoadingScheduleEntry.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDaysSinceSunday();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getOpenMinuteOfDay();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getCloseMinuteOfDay();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
};


/**
 * optional int64 days_since_sunday = 1;
 * @return {number}
 */
proto.vtv1.SiteLoadingScheduleEntry.prototype.getDaysSinceSunday = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.SiteLoadingScheduleEntry} returns this
 */
proto.vtv1.SiteLoadingScheduleEntry.prototype.setDaysSinceSunday = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 open_minute_of_day = 2;
 * @return {number}
 */
proto.vtv1.SiteLoadingScheduleEntry.prototype.getOpenMinuteOfDay = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.SiteLoadingScheduleEntry} returns this
 */
proto.vtv1.SiteLoadingScheduleEntry.prototype.setOpenMinuteOfDay = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 close_minute_of_day = 3;
 * @return {number}
 */
proto.vtv1.SiteLoadingScheduleEntry.prototype.getCloseMinuteOfDay = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.SiteLoadingScheduleEntry} returns this
 */
proto.vtv1.SiteLoadingScheduleEntry.prototype.setCloseMinuteOfDay = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional SiteLoadingScheduleType type = 4;
 * @return {!proto.vtv1.SiteLoadingScheduleType}
 */
proto.vtv1.SiteLoadingScheduleEntry.prototype.getType = function() {
  return /** @type {!proto.vtv1.SiteLoadingScheduleType} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.vtv1.SiteLoadingScheduleType} value
 * @return {!proto.vtv1.SiteLoadingScheduleEntry} returns this
 */
proto.vtv1.SiteLoadingScheduleEntry.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.Unit.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.Unit.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.Unit} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.Unit.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    abbreviation: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.Unit}
 */
proto.vtv1.Unit.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.Unit;
  return proto.vtv1.Unit.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.Unit} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.Unit}
 */
proto.vtv1.Unit.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAbbreviation(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.Unit.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.Unit.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.Unit} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.Unit.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAbbreviation();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.vtv1.Unit.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.Unit} returns this
 */
proto.vtv1.Unit.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.vtv1.Unit.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.Unit} returns this
 */
proto.vtv1.Unit.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string abbreviation = 3;
 * @return {string}
 */
proto.vtv1.Unit.prototype.getAbbreviation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.Unit} returns this
 */
proto.vtv1.Unit.prototype.setAbbreviation = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.Product.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.Product.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.Product} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.Product.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    unit: (f = msg.getUnit()) && proto.vtv1.Unit.toObject(includeInstance, f),
    archivedAt: (f = msg.getArchivedAt()) && proto.vtv1.NullableTime.toObject(includeInstance, f),
    materialNumber: (f = msg.getMaterialNumber()) && proto.vtv1.NullableString.toObject(includeInstance, f),
    fulfillment: (f = msg.getFulfillment()) && proto.vtv1.ProductFulfillment.toObject(includeInstance, f),
    poundsPerUnit: (f = msg.getPoundsPerUnit()) && proto.vtv1.NullableDouble.toObject(includeInstance, f),
    halInventoryByUnit: jspb.Message.getBooleanFieldWithDefault(msg, 8, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.Product}
 */
proto.vtv1.Product.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.Product;
  return proto.vtv1.Product.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.Product} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.Product}
 */
proto.vtv1.Product.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = new proto.vtv1.Unit;
      reader.readMessage(value,proto.vtv1.Unit.deserializeBinaryFromReader);
      msg.setUnit(value);
      break;
    case 4:
      var value = new proto.vtv1.NullableTime;
      reader.readMessage(value,proto.vtv1.NullableTime.deserializeBinaryFromReader);
      msg.setArchivedAt(value);
      break;
    case 5:
      var value = new proto.vtv1.NullableString;
      reader.readMessage(value,proto.vtv1.NullableString.deserializeBinaryFromReader);
      msg.setMaterialNumber(value);
      break;
    case 6:
      var value = new proto.vtv1.ProductFulfillment;
      reader.readMessage(value,proto.vtv1.ProductFulfillment.deserializeBinaryFromReader);
      msg.setFulfillment(value);
      break;
    case 7:
      var value = new proto.vtv1.NullableDouble;
      reader.readMessage(value,proto.vtv1.NullableDouble.deserializeBinaryFromReader);
      msg.setPoundsPerUnit(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHalInventoryByUnit(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.Product.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.Product.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.Product} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.Product.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getUnit();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.vtv1.Unit.serializeBinaryToWriter
    );
  }
  f = message.getArchivedAt();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.vtv1.NullableTime.serializeBinaryToWriter
    );
  }
  f = message.getMaterialNumber();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.vtv1.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getFulfillment();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.vtv1.ProductFulfillment.serializeBinaryToWriter
    );
  }
  f = message.getPoundsPerUnit();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.vtv1.NullableDouble.serializeBinaryToWriter
    );
  }
  f = message.getHalInventoryByUnit();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.vtv1.Product.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.Product} returns this
 */
proto.vtv1.Product.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.vtv1.Product.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.Product} returns this
 */
proto.vtv1.Product.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional Unit unit = 3;
 * @return {?proto.vtv1.Unit}
 */
proto.vtv1.Product.prototype.getUnit = function() {
  return /** @type{?proto.vtv1.Unit} */ (
    jspb.Message.getWrapperField(this, proto.vtv1.Unit, 3));
};


/**
 * @param {?proto.vtv1.Unit|undefined} value
 * @return {!proto.vtv1.Product} returns this
*/
proto.vtv1.Product.prototype.setUnit = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.Product} returns this
 */
proto.vtv1.Product.prototype.clearUnit = function() {
  return this.setUnit(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.Product.prototype.hasUnit = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional NullableTime archived_at = 4;
 * @return {?proto.vtv1.NullableTime}
 */
proto.vtv1.Product.prototype.getArchivedAt = function() {
  return /** @type{?proto.vtv1.NullableTime} */ (
    jspb.Message.getWrapperField(this, proto.vtv1.NullableTime, 4));
};


/**
 * @param {?proto.vtv1.NullableTime|undefined} value
 * @return {!proto.vtv1.Product} returns this
*/
proto.vtv1.Product.prototype.setArchivedAt = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.Product} returns this
 */
proto.vtv1.Product.prototype.clearArchivedAt = function() {
  return this.setArchivedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.Product.prototype.hasArchivedAt = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional NullableString material_number = 5;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.Product.prototype.getMaterialNumber = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto.vtv1.NullableString, 5));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.Product} returns this
*/
proto.vtv1.Product.prototype.setMaterialNumber = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.Product} returns this
 */
proto.vtv1.Product.prototype.clearMaterialNumber = function() {
  return this.setMaterialNumber(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.Product.prototype.hasMaterialNumber = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional ProductFulfillment fulfillment = 6;
 * @return {?proto.vtv1.ProductFulfillment}
 */
proto.vtv1.Product.prototype.getFulfillment = function() {
  return /** @type{?proto.vtv1.ProductFulfillment} */ (
    jspb.Message.getWrapperField(this, proto.vtv1.ProductFulfillment, 6));
};


/**
 * @param {?proto.vtv1.ProductFulfillment|undefined} value
 * @return {!proto.vtv1.Product} returns this
*/
proto.vtv1.Product.prototype.setFulfillment = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.Product} returns this
 */
proto.vtv1.Product.prototype.clearFulfillment = function() {
  return this.setFulfillment(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.Product.prototype.hasFulfillment = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional NullableDouble pounds_per_unit = 7;
 * @return {?proto.vtv1.NullableDouble}
 */
proto.vtv1.Product.prototype.getPoundsPerUnit = function() {
  return /** @type{?proto.vtv1.NullableDouble} */ (
    jspb.Message.getWrapperField(this, proto.vtv1.NullableDouble, 7));
};


/**
 * @param {?proto.vtv1.NullableDouble|undefined} value
 * @return {!proto.vtv1.Product} returns this
*/
proto.vtv1.Product.prototype.setPoundsPerUnit = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.Product} returns this
 */
proto.vtv1.Product.prototype.clearPoundsPerUnit = function() {
  return this.setPoundsPerUnit(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.Product.prototype.hasPoundsPerUnit = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional bool hal_inventory_by_unit = 8;
 * @return {boolean}
 */
proto.vtv1.Product.prototype.getHalInventoryByUnit = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vtv1.Product} returns this
 */
proto.vtv1.Product.prototype.setHalInventoryByUnit = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.ProductFulfillment.repeatedFields_ = [6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.ProductFulfillment.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.ProductFulfillment.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.ProductFulfillment} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.ProductFulfillment.toObject = function(includeInstance, msg) {
  var f, obj = {
    enabled: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    group: (f = msg.getGroup()) && proto.vtv1.StringIdName.toObject(includeInstance, f),
    description: (f = msg.getDescription()) && proto.vtv1.NullableString.toObject(includeInstance, f),
    fileUrl: (f = msg.getFileUrl()) && proto.vtv1.NullableString.toObject(includeInstance, f),
    fileId: (f = msg.getFileId()) && proto.vtv1.NullableInt64.toObject(includeInstance, f),
    sitesList: jspb.Message.toObjectList(msg.getSitesList(),
    proto.vtv1.StringIdName.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.ProductFulfillment}
 */
proto.vtv1.ProductFulfillment.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.ProductFulfillment;
  return proto.vtv1.ProductFulfillment.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.ProductFulfillment} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.ProductFulfillment}
 */
proto.vtv1.ProductFulfillment.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEnabled(value);
      break;
    case 2:
      var value = new proto.vtv1.StringIdName;
      reader.readMessage(value,proto.vtv1.StringIdName.deserializeBinaryFromReader);
      msg.setGroup(value);
      break;
    case 3:
      var value = new proto.vtv1.NullableString;
      reader.readMessage(value,proto.vtv1.NullableString.deserializeBinaryFromReader);
      msg.setDescription(value);
      break;
    case 4:
      var value = new proto.vtv1.NullableString;
      reader.readMessage(value,proto.vtv1.NullableString.deserializeBinaryFromReader);
      msg.setFileUrl(value);
      break;
    case 5:
      var value = new proto.vtv1.NullableInt64;
      reader.readMessage(value,proto.vtv1.NullableInt64.deserializeBinaryFromReader);
      msg.setFileId(value);
      break;
    case 6:
      var value = new proto.vtv1.StringIdName;
      reader.readMessage(value,proto.vtv1.StringIdName.deserializeBinaryFromReader);
      msg.addSites(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.ProductFulfillment.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.ProductFulfillment.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.ProductFulfillment} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.ProductFulfillment.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEnabled();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getGroup();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.vtv1.StringIdName.serializeBinaryToWriter
    );
  }
  f = message.getDescription();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.vtv1.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getFileUrl();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.vtv1.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getFileId();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.vtv1.NullableInt64.serializeBinaryToWriter
    );
  }
  f = message.getSitesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.vtv1.StringIdName.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool enabled = 1;
 * @return {boolean}
 */
proto.vtv1.ProductFulfillment.prototype.getEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vtv1.ProductFulfillment} returns this
 */
proto.vtv1.ProductFulfillment.prototype.setEnabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional StringIdName group = 2;
 * @return {?proto.vtv1.StringIdName}
 */
proto.vtv1.ProductFulfillment.prototype.getGroup = function() {
  return /** @type{?proto.vtv1.StringIdName} */ (
    jspb.Message.getWrapperField(this, proto.vtv1.StringIdName, 2));
};


/**
 * @param {?proto.vtv1.StringIdName|undefined} value
 * @return {!proto.vtv1.ProductFulfillment} returns this
*/
proto.vtv1.ProductFulfillment.prototype.setGroup = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.ProductFulfillment} returns this
 */
proto.vtv1.ProductFulfillment.prototype.clearGroup = function() {
  return this.setGroup(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.ProductFulfillment.prototype.hasGroup = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional NullableString description = 3;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.ProductFulfillment.prototype.getDescription = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto.vtv1.NullableString, 3));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.ProductFulfillment} returns this
*/
proto.vtv1.ProductFulfillment.prototype.setDescription = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.ProductFulfillment} returns this
 */
proto.vtv1.ProductFulfillment.prototype.clearDescription = function() {
  return this.setDescription(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.ProductFulfillment.prototype.hasDescription = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional NullableString file_url = 4;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.ProductFulfillment.prototype.getFileUrl = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto.vtv1.NullableString, 4));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.ProductFulfillment} returns this
*/
proto.vtv1.ProductFulfillment.prototype.setFileUrl = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.ProductFulfillment} returns this
 */
proto.vtv1.ProductFulfillment.prototype.clearFileUrl = function() {
  return this.setFileUrl(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.ProductFulfillment.prototype.hasFileUrl = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional NullableInt64 file_id = 5;
 * @return {?proto.vtv1.NullableInt64}
 */
proto.vtv1.ProductFulfillment.prototype.getFileId = function() {
  return /** @type{?proto.vtv1.NullableInt64} */ (
    jspb.Message.getWrapperField(this, proto.vtv1.NullableInt64, 5));
};


/**
 * @param {?proto.vtv1.NullableInt64|undefined} value
 * @return {!proto.vtv1.ProductFulfillment} returns this
*/
proto.vtv1.ProductFulfillment.prototype.setFileId = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.ProductFulfillment} returns this
 */
proto.vtv1.ProductFulfillment.prototype.clearFileId = function() {
  return this.setFileId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.ProductFulfillment.prototype.hasFileId = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * repeated StringIdName sites = 6;
 * @return {!Array<!proto.vtv1.StringIdName>}
 */
proto.vtv1.ProductFulfillment.prototype.getSitesList = function() {
  return /** @type{!Array<!proto.vtv1.StringIdName>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vtv1.StringIdName, 6));
};


/**
 * @param {!Array<!proto.vtv1.StringIdName>} value
 * @return {!proto.vtv1.ProductFulfillment} returns this
*/
proto.vtv1.ProductFulfillment.prototype.setSitesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.vtv1.StringIdName=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.StringIdName}
 */
proto.vtv1.ProductFulfillment.prototype.addSites = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.vtv1.StringIdName, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.ProductFulfillment} returns this
 */
proto.vtv1.ProductFulfillment.prototype.clearSitesList = function() {
  return this.setSitesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.ProductGroup.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.ProductGroup.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.ProductGroup} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.ProductGroup.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    description: (f = msg.getDescription()) && proto.vtv1.NullableString.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.ProductGroup}
 */
proto.vtv1.ProductGroup.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.ProductGroup;
  return proto.vtv1.ProductGroup.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.ProductGroup} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.ProductGroup}
 */
proto.vtv1.ProductGroup.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = new proto.vtv1.NullableString;
      reader.readMessage(value,proto.vtv1.NullableString.deserializeBinaryFromReader);
      msg.setDescription(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.ProductGroup.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.ProductGroup.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.ProductGroup} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.ProductGroup.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDescription();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.vtv1.NullableString.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.vtv1.ProductGroup.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.ProductGroup} returns this
 */
proto.vtv1.ProductGroup.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.vtv1.ProductGroup.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.ProductGroup} returns this
 */
proto.vtv1.ProductGroup.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional NullableString description = 3;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.ProductGroup.prototype.getDescription = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto.vtv1.NullableString, 3));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.ProductGroup} returns this
*/
proto.vtv1.ProductGroup.prototype.setDescription = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.ProductGroup} returns this
 */
proto.vtv1.ProductGroup.prototype.clearDescription = function() {
  return this.setDescription(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.ProductGroup.prototype.hasDescription = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.Report.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.Report.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.Report} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.Report.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    description: jspb.Message.getFieldWithDefault(msg, 2, ""),
    estimatedDurationMinutes: jspb.Message.getFieldWithDefault(msg, 3, 0),
    createdByUserName: jspb.Message.getFieldWithDefault(msg, 4, ""),
    createdAt: jspb.Message.getFieldWithDefault(msg, 5, ""),
    resolvedByUserName: (f = msg.getResolvedByUserName()) && proto.vtv1.NullableString.toObject(includeInstance, f),
    resolvedAt: (f = msg.getResolvedAt()) && proto.vtv1.NullableTime.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.Report}
 */
proto.vtv1.Report.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.Report;
  return proto.vtv1.Report.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.Report} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.Report}
 */
proto.vtv1.Report.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setEstimatedDurationMinutes(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedByUserName(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedAt(value);
      break;
    case 6:
      var value = new proto.vtv1.NullableString;
      reader.readMessage(value,proto.vtv1.NullableString.deserializeBinaryFromReader);
      msg.setResolvedByUserName(value);
      break;
    case 7:
      var value = new proto.vtv1.NullableTime;
      reader.readMessage(value,proto.vtv1.NullableTime.deserializeBinaryFromReader);
      msg.setResolvedAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.Report.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.Report.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.Report} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.Report.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getEstimatedDurationMinutes();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getCreatedByUserName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCreatedAt();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getResolvedByUserName();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.vtv1.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getResolvedAt();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.vtv1.NullableTime.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.vtv1.Report.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.Report} returns this
 */
proto.vtv1.Report.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string description = 2;
 * @return {string}
 */
proto.vtv1.Report.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.Report} returns this
 */
proto.vtv1.Report.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 estimated_duration_minutes = 3;
 * @return {number}
 */
proto.vtv1.Report.prototype.getEstimatedDurationMinutes = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.Report} returns this
 */
proto.vtv1.Report.prototype.setEstimatedDurationMinutes = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string created_by_user_name = 4;
 * @return {string}
 */
proto.vtv1.Report.prototype.getCreatedByUserName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.Report} returns this
 */
proto.vtv1.Report.prototype.setCreatedByUserName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string created_at = 5;
 * @return {string}
 */
proto.vtv1.Report.prototype.getCreatedAt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.Report} returns this
 */
proto.vtv1.Report.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional NullableString resolved_by_user_name = 6;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.Report.prototype.getResolvedByUserName = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto.vtv1.NullableString, 6));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.Report} returns this
*/
proto.vtv1.Report.prototype.setResolvedByUserName = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.Report} returns this
 */
proto.vtv1.Report.prototype.clearResolvedByUserName = function() {
  return this.setResolvedByUserName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.Report.prototype.hasResolvedByUserName = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional NullableTime resolved_at = 7;
 * @return {?proto.vtv1.NullableTime}
 */
proto.vtv1.Report.prototype.getResolvedAt = function() {
  return /** @type{?proto.vtv1.NullableTime} */ (
    jspb.Message.getWrapperField(this, proto.vtv1.NullableTime, 7));
};


/**
 * @param {?proto.vtv1.NullableTime|undefined} value
 * @return {!proto.vtv1.Report} returns this
*/
proto.vtv1.Report.prototype.setResolvedAt = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.Report} returns this
 */
proto.vtv1.Report.prototype.clearResolvedAt = function() {
  return this.setResolvedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.Report.prototype.hasResolvedAt = function() {
  return jspb.Message.getField(this, 7) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.CreateSiteCriteria.repeatedFields_ = [17,20];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.CreateSiteCriteria.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.CreateSiteCriteria.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.CreateSiteCriteria} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.CreateSiteCriteria.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    longitude: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    latitude: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    radius: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    siteGroupId: (f = msg.getSiteGroupId()) && proto.vtv1.NullableInt64.toObject(includeInstance, f),
    numberOfBays: jspb.Message.getFieldWithDefault(msg, 6, 0),
    defaultDeliveryWindowMinutes: (f = msg.getDefaultDeliveryWindowMinutes()) && proto.vtv1.NullableInt64.toObject(includeInstance, f),
    address: (f = msg.getAddress()) && proto.vtv1.NullableString.toObject(includeInstance, f),
    desiredMaxSupplierResponseTimeHours: (f = msg.getDesiredMaxSupplierResponseTimeHours()) && proto.vtv1.NullableInt64.toObject(includeInstance, f),
    averageDwellTimeCalculationWindow: (f = msg.getAverageDwellTimeCalculationWindow()) && proto.vtv1.NullableInt64.toObject(includeInstance, f),
    directions: (f = msg.getDirections()) && proto.vtv1.NullableString.toObject(includeInstance, f),
    loadingInstructions: (f = msg.getLoadingInstructions()) && proto.vtv1.NullableString.toObject(includeInstance, f),
    salesOrder: (f = msg.getSalesOrder()) && proto.vtv1.NullableString.toObject(includeInstance, f),
    facilityCode: (f = msg.getFacilityCode()) && proto.vtv1.NullableString.toObject(includeInstance, f),
    districtId: (f = msg.getDistrictId()) && proto.vtv1.NullableString.toObject(includeInstance, f),
    taxPercent: (f = msg.getTaxPercent()) && proto.vtv1.NullableDouble.toObject(includeInstance, f),
    fulfillmentSiteIdsList: (f = jspb.Message.getRepeatedField(msg, 17)) == null ? undefined : f,
    contactPhone: (f = msg.getContactPhone()) && proto.vtv1.NullableString.toObject(includeInstance, f),
    contactEmail: (f = msg.getContactEmail()) && proto.vtv1.NullableString.toObject(includeInstance, f),
    siteUserIdsList: (f = jspb.Message.getRepeatedField(msg, 20)) == null ? undefined : f,
    costCenterId: (f = msg.getCostCenterId()) && proto.vtv1.NullableString.toObject(includeInstance, f),
    salesOrderId: (f = msg.getSalesOrderId()) && proto.vtv1.NullableString.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.CreateSiteCriteria}
 */
proto.vtv1.CreateSiteCriteria.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.CreateSiteCriteria;
  return proto.vtv1.CreateSiteCriteria.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.CreateSiteCriteria} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.CreateSiteCriteria}
 */
proto.vtv1.CreateSiteCriteria.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLongitude(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLatitude(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setRadius(value);
      break;
    case 5:
      var value = new proto.vtv1.NullableInt64;
      reader.readMessage(value,proto.vtv1.NullableInt64.deserializeBinaryFromReader);
      msg.setSiteGroupId(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setNumberOfBays(value);
      break;
    case 7:
      var value = new proto.vtv1.NullableInt64;
      reader.readMessage(value,proto.vtv1.NullableInt64.deserializeBinaryFromReader);
      msg.setDefaultDeliveryWindowMinutes(value);
      break;
    case 8:
      var value = new proto.vtv1.NullableString;
      reader.readMessage(value,proto.vtv1.NullableString.deserializeBinaryFromReader);
      msg.setAddress(value);
      break;
    case 9:
      var value = new proto.vtv1.NullableInt64;
      reader.readMessage(value,proto.vtv1.NullableInt64.deserializeBinaryFromReader);
      msg.setDesiredMaxSupplierResponseTimeHours(value);
      break;
    case 10:
      var value = new proto.vtv1.NullableInt64;
      reader.readMessage(value,proto.vtv1.NullableInt64.deserializeBinaryFromReader);
      msg.setAverageDwellTimeCalculationWindow(value);
      break;
    case 11:
      var value = new proto.vtv1.NullableString;
      reader.readMessage(value,proto.vtv1.NullableString.deserializeBinaryFromReader);
      msg.setDirections(value);
      break;
    case 12:
      var value = new proto.vtv1.NullableString;
      reader.readMessage(value,proto.vtv1.NullableString.deserializeBinaryFromReader);
      msg.setLoadingInstructions(value);
      break;
    case 13:
      var value = new proto.vtv1.NullableString;
      reader.readMessage(value,proto.vtv1.NullableString.deserializeBinaryFromReader);
      msg.setSalesOrder(value);
      break;
    case 14:
      var value = new proto.vtv1.NullableString;
      reader.readMessage(value,proto.vtv1.NullableString.deserializeBinaryFromReader);
      msg.setFacilityCode(value);
      break;
    case 15:
      var value = new proto.vtv1.NullableString;
      reader.readMessage(value,proto.vtv1.NullableString.deserializeBinaryFromReader);
      msg.setDistrictId(value);
      break;
    case 16:
      var value = new proto.vtv1.NullableDouble;
      reader.readMessage(value,proto.vtv1.NullableDouble.deserializeBinaryFromReader);
      msg.setTaxPercent(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.addFulfillmentSiteIds(value);
      break;
    case 18:
      var value = new proto.vtv1.NullableString;
      reader.readMessage(value,proto.vtv1.NullableString.deserializeBinaryFromReader);
      msg.setContactPhone(value);
      break;
    case 19:
      var value = new proto.vtv1.NullableString;
      reader.readMessage(value,proto.vtv1.NullableString.deserializeBinaryFromReader);
      msg.setContactEmail(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.addSiteUserIds(value);
      break;
    case 21:
      var value = new proto.vtv1.NullableString;
      reader.readMessage(value,proto.vtv1.NullableString.deserializeBinaryFromReader);
      msg.setCostCenterId(value);
      break;
    case 22:
      var value = new proto.vtv1.NullableString;
      reader.readMessage(value,proto.vtv1.NullableString.deserializeBinaryFromReader);
      msg.setSalesOrderId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.CreateSiteCriteria.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.CreateSiteCriteria.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.CreateSiteCriteria} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.CreateSiteCriteria.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLongitude();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getLatitude();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getRadius();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getSiteGroupId();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.vtv1.NullableInt64.serializeBinaryToWriter
    );
  }
  f = message.getNumberOfBays();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getDefaultDeliveryWindowMinutes();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.vtv1.NullableInt64.serializeBinaryToWriter
    );
  }
  f = message.getAddress();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.vtv1.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getDesiredMaxSupplierResponseTimeHours();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.vtv1.NullableInt64.serializeBinaryToWriter
    );
  }
  f = message.getAverageDwellTimeCalculationWindow();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.vtv1.NullableInt64.serializeBinaryToWriter
    );
  }
  f = message.getDirections();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto.vtv1.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getLoadingInstructions();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      proto.vtv1.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getSalesOrder();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      proto.vtv1.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getFacilityCode();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      proto.vtv1.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getDistrictId();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      proto.vtv1.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getTaxPercent();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      proto.vtv1.NullableDouble.serializeBinaryToWriter
    );
  }
  f = message.getFulfillmentSiteIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      17,
      f
    );
  }
  f = message.getContactPhone();
  if (f != null) {
    writer.writeMessage(
      18,
      f,
      proto.vtv1.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getContactEmail();
  if (f != null) {
    writer.writeMessage(
      19,
      f,
      proto.vtv1.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getSiteUserIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      20,
      f
    );
  }
  f = message.getCostCenterId();
  if (f != null) {
    writer.writeMessage(
      21,
      f,
      proto.vtv1.NullableString.serializeBinaryToWriter
    );
  }
  f = message.getSalesOrderId();
  if (f != null) {
    writer.writeMessage(
      22,
      f,
      proto.vtv1.NullableString.serializeBinaryToWriter
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.vtv1.CreateSiteCriteria.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.CreateSiteCriteria} returns this
 */
proto.vtv1.CreateSiteCriteria.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional double longitude = 2;
 * @return {number}
 */
proto.vtv1.CreateSiteCriteria.prototype.getLongitude = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.CreateSiteCriteria} returns this
 */
proto.vtv1.CreateSiteCriteria.prototype.setLongitude = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double latitude = 3;
 * @return {number}
 */
proto.vtv1.CreateSiteCriteria.prototype.getLatitude = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.CreateSiteCriteria} returns this
 */
proto.vtv1.CreateSiteCriteria.prototype.setLatitude = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional double radius = 4;
 * @return {number}
 */
proto.vtv1.CreateSiteCriteria.prototype.getRadius = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.CreateSiteCriteria} returns this
 */
proto.vtv1.CreateSiteCriteria.prototype.setRadius = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional NullableInt64 site_group_id = 5;
 * @return {?proto.vtv1.NullableInt64}
 */
proto.vtv1.CreateSiteCriteria.prototype.getSiteGroupId = function() {
  return /** @type{?proto.vtv1.NullableInt64} */ (
    jspb.Message.getWrapperField(this, proto.vtv1.NullableInt64, 5));
};


/**
 * @param {?proto.vtv1.NullableInt64|undefined} value
 * @return {!proto.vtv1.CreateSiteCriteria} returns this
*/
proto.vtv1.CreateSiteCriteria.prototype.setSiteGroupId = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.CreateSiteCriteria} returns this
 */
proto.vtv1.CreateSiteCriteria.prototype.clearSiteGroupId = function() {
  return this.setSiteGroupId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.CreateSiteCriteria.prototype.hasSiteGroupId = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional int64 number_of_bays = 6;
 * @return {number}
 */
proto.vtv1.CreateSiteCriteria.prototype.getNumberOfBays = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.CreateSiteCriteria} returns this
 */
proto.vtv1.CreateSiteCriteria.prototype.setNumberOfBays = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional NullableInt64 default_delivery_window_minutes = 7;
 * @return {?proto.vtv1.NullableInt64}
 */
proto.vtv1.CreateSiteCriteria.prototype.getDefaultDeliveryWindowMinutes = function() {
  return /** @type{?proto.vtv1.NullableInt64} */ (
    jspb.Message.getWrapperField(this, proto.vtv1.NullableInt64, 7));
};


/**
 * @param {?proto.vtv1.NullableInt64|undefined} value
 * @return {!proto.vtv1.CreateSiteCriteria} returns this
*/
proto.vtv1.CreateSiteCriteria.prototype.setDefaultDeliveryWindowMinutes = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.CreateSiteCriteria} returns this
 */
proto.vtv1.CreateSiteCriteria.prototype.clearDefaultDeliveryWindowMinutes = function() {
  return this.setDefaultDeliveryWindowMinutes(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.CreateSiteCriteria.prototype.hasDefaultDeliveryWindowMinutes = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional NullableString address = 8;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.CreateSiteCriteria.prototype.getAddress = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto.vtv1.NullableString, 8));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.CreateSiteCriteria} returns this
*/
proto.vtv1.CreateSiteCriteria.prototype.setAddress = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.CreateSiteCriteria} returns this
 */
proto.vtv1.CreateSiteCriteria.prototype.clearAddress = function() {
  return this.setAddress(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.CreateSiteCriteria.prototype.hasAddress = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional NullableInt64 desired_max_supplier_response_time_hours = 9;
 * @return {?proto.vtv1.NullableInt64}
 */
proto.vtv1.CreateSiteCriteria.prototype.getDesiredMaxSupplierResponseTimeHours = function() {
  return /** @type{?proto.vtv1.NullableInt64} */ (
    jspb.Message.getWrapperField(this, proto.vtv1.NullableInt64, 9));
};


/**
 * @param {?proto.vtv1.NullableInt64|undefined} value
 * @return {!proto.vtv1.CreateSiteCriteria} returns this
*/
proto.vtv1.CreateSiteCriteria.prototype.setDesiredMaxSupplierResponseTimeHours = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.CreateSiteCriteria} returns this
 */
proto.vtv1.CreateSiteCriteria.prototype.clearDesiredMaxSupplierResponseTimeHours = function() {
  return this.setDesiredMaxSupplierResponseTimeHours(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.CreateSiteCriteria.prototype.hasDesiredMaxSupplierResponseTimeHours = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional NullableInt64 average_dwell_time_calculation_window = 10;
 * @return {?proto.vtv1.NullableInt64}
 */
proto.vtv1.CreateSiteCriteria.prototype.getAverageDwellTimeCalculationWindow = function() {
  return /** @type{?proto.vtv1.NullableInt64} */ (
    jspb.Message.getWrapperField(this, proto.vtv1.NullableInt64, 10));
};


/**
 * @param {?proto.vtv1.NullableInt64|undefined} value
 * @return {!proto.vtv1.CreateSiteCriteria} returns this
*/
proto.vtv1.CreateSiteCriteria.prototype.setAverageDwellTimeCalculationWindow = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.CreateSiteCriteria} returns this
 */
proto.vtv1.CreateSiteCriteria.prototype.clearAverageDwellTimeCalculationWindow = function() {
  return this.setAverageDwellTimeCalculationWindow(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.CreateSiteCriteria.prototype.hasAverageDwellTimeCalculationWindow = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional NullableString directions = 11;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.CreateSiteCriteria.prototype.getDirections = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto.vtv1.NullableString, 11));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.CreateSiteCriteria} returns this
*/
proto.vtv1.CreateSiteCriteria.prototype.setDirections = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.CreateSiteCriteria} returns this
 */
proto.vtv1.CreateSiteCriteria.prototype.clearDirections = function() {
  return this.setDirections(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.CreateSiteCriteria.prototype.hasDirections = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional NullableString loading_instructions = 12;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.CreateSiteCriteria.prototype.getLoadingInstructions = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto.vtv1.NullableString, 12));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.CreateSiteCriteria} returns this
*/
proto.vtv1.CreateSiteCriteria.prototype.setLoadingInstructions = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.CreateSiteCriteria} returns this
 */
proto.vtv1.CreateSiteCriteria.prototype.clearLoadingInstructions = function() {
  return this.setLoadingInstructions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.CreateSiteCriteria.prototype.hasLoadingInstructions = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional NullableString sales_order = 13;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.CreateSiteCriteria.prototype.getSalesOrder = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto.vtv1.NullableString, 13));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.CreateSiteCriteria} returns this
*/
proto.vtv1.CreateSiteCriteria.prototype.setSalesOrder = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.CreateSiteCriteria} returns this
 */
proto.vtv1.CreateSiteCriteria.prototype.clearSalesOrder = function() {
  return this.setSalesOrder(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.CreateSiteCriteria.prototype.hasSalesOrder = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional NullableString facility_code = 14;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.CreateSiteCriteria.prototype.getFacilityCode = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto.vtv1.NullableString, 14));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.CreateSiteCriteria} returns this
*/
proto.vtv1.CreateSiteCriteria.prototype.setFacilityCode = function(value) {
  return jspb.Message.setWrapperField(this, 14, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.CreateSiteCriteria} returns this
 */
proto.vtv1.CreateSiteCriteria.prototype.clearFacilityCode = function() {
  return this.setFacilityCode(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.CreateSiteCriteria.prototype.hasFacilityCode = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional NullableString district_id = 15;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.CreateSiteCriteria.prototype.getDistrictId = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto.vtv1.NullableString, 15));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.CreateSiteCriteria} returns this
*/
proto.vtv1.CreateSiteCriteria.prototype.setDistrictId = function(value) {
  return jspb.Message.setWrapperField(this, 15, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.CreateSiteCriteria} returns this
 */
proto.vtv1.CreateSiteCriteria.prototype.clearDistrictId = function() {
  return this.setDistrictId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.CreateSiteCriteria.prototype.hasDistrictId = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional NullableDouble tax_percent = 16;
 * @return {?proto.vtv1.NullableDouble}
 */
proto.vtv1.CreateSiteCriteria.prototype.getTaxPercent = function() {
  return /** @type{?proto.vtv1.NullableDouble} */ (
    jspb.Message.getWrapperField(this, proto.vtv1.NullableDouble, 16));
};


/**
 * @param {?proto.vtv1.NullableDouble|undefined} value
 * @return {!proto.vtv1.CreateSiteCriteria} returns this
*/
proto.vtv1.CreateSiteCriteria.prototype.setTaxPercent = function(value) {
  return jspb.Message.setWrapperField(this, 16, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.CreateSiteCriteria} returns this
 */
proto.vtv1.CreateSiteCriteria.prototype.clearTaxPercent = function() {
  return this.setTaxPercent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.CreateSiteCriteria.prototype.hasTaxPercent = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * repeated string fulfillment_site_ids = 17;
 * @return {!Array<string>}
 */
proto.vtv1.CreateSiteCriteria.prototype.getFulfillmentSiteIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 17));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.vtv1.CreateSiteCriteria} returns this
 */
proto.vtv1.CreateSiteCriteria.prototype.setFulfillmentSiteIdsList = function(value) {
  return jspb.Message.setField(this, 17, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.vtv1.CreateSiteCriteria} returns this
 */
proto.vtv1.CreateSiteCriteria.prototype.addFulfillmentSiteIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 17, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.CreateSiteCriteria} returns this
 */
proto.vtv1.CreateSiteCriteria.prototype.clearFulfillmentSiteIdsList = function() {
  return this.setFulfillmentSiteIdsList([]);
};


/**
 * optional NullableString contact_phone = 18;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.CreateSiteCriteria.prototype.getContactPhone = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto.vtv1.NullableString, 18));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.CreateSiteCriteria} returns this
*/
proto.vtv1.CreateSiteCriteria.prototype.setContactPhone = function(value) {
  return jspb.Message.setWrapperField(this, 18, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.CreateSiteCriteria} returns this
 */
proto.vtv1.CreateSiteCriteria.prototype.clearContactPhone = function() {
  return this.setContactPhone(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.CreateSiteCriteria.prototype.hasContactPhone = function() {
  return jspb.Message.getField(this, 18) != null;
};


/**
 * optional NullableString contact_email = 19;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.CreateSiteCriteria.prototype.getContactEmail = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto.vtv1.NullableString, 19));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.CreateSiteCriteria} returns this
*/
proto.vtv1.CreateSiteCriteria.prototype.setContactEmail = function(value) {
  return jspb.Message.setWrapperField(this, 19, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.CreateSiteCriteria} returns this
 */
proto.vtv1.CreateSiteCriteria.prototype.clearContactEmail = function() {
  return this.setContactEmail(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.CreateSiteCriteria.prototype.hasContactEmail = function() {
  return jspb.Message.getField(this, 19) != null;
};


/**
 * repeated string site_user_ids = 20;
 * @return {!Array<string>}
 */
proto.vtv1.CreateSiteCriteria.prototype.getSiteUserIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 20));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.vtv1.CreateSiteCriteria} returns this
 */
proto.vtv1.CreateSiteCriteria.prototype.setSiteUserIdsList = function(value) {
  return jspb.Message.setField(this, 20, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.vtv1.CreateSiteCriteria} returns this
 */
proto.vtv1.CreateSiteCriteria.prototype.addSiteUserIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 20, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.CreateSiteCriteria} returns this
 */
proto.vtv1.CreateSiteCriteria.prototype.clearSiteUserIdsList = function() {
  return this.setSiteUserIdsList([]);
};


/**
 * optional NullableString cost_center_id = 21;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.CreateSiteCriteria.prototype.getCostCenterId = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto.vtv1.NullableString, 21));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.CreateSiteCriteria} returns this
*/
proto.vtv1.CreateSiteCriteria.prototype.setCostCenterId = function(value) {
  return jspb.Message.setWrapperField(this, 21, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.CreateSiteCriteria} returns this
 */
proto.vtv1.CreateSiteCriteria.prototype.clearCostCenterId = function() {
  return this.setCostCenterId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.CreateSiteCriteria.prototype.hasCostCenterId = function() {
  return jspb.Message.getField(this, 21) != null;
};


/**
 * optional NullableString sales_order_id = 22;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.CreateSiteCriteria.prototype.getSalesOrderId = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto.vtv1.NullableString, 22));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.CreateSiteCriteria} returns this
*/
proto.vtv1.CreateSiteCriteria.prototype.setSalesOrderId = function(value) {
  return jspb.Message.setWrapperField(this, 22, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.CreateSiteCriteria} returns this
 */
proto.vtv1.CreateSiteCriteria.prototype.clearSalesOrderId = function() {
  return this.setSalesOrderId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.CreateSiteCriteria.prototype.hasSalesOrderId = function() {
  return jspb.Message.getField(this, 22) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.AccountSettingsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.AccountSettingsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.AccountSettingsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.AccountSettingsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    autoDispatch: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    useVortoPoNumber: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.AccountSettingsResponse}
 */
proto.vtv1.AccountSettingsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.AccountSettingsResponse;
  return proto.vtv1.AccountSettingsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.AccountSettingsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.AccountSettingsResponse}
 */
proto.vtv1.AccountSettingsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAutoDispatch(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setUseVortoPoNumber(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.AccountSettingsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.AccountSettingsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.AccountSettingsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.AccountSettingsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAutoDispatch();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getUseVortoPoNumber();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional bool auto_dispatch = 1;
 * @return {boolean}
 */
proto.vtv1.AccountSettingsResponse.prototype.getAutoDispatch = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vtv1.AccountSettingsResponse} returns this
 */
proto.vtv1.AccountSettingsResponse.prototype.setAutoDispatch = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional bool use_vorto_po_number = 2;
 * @return {boolean}
 */
proto.vtv1.AccountSettingsResponse.prototype.getUseVortoPoNumber = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vtv1.AccountSettingsResponse} returns this
 */
proto.vtv1.AccountSettingsResponse.prototype.setUseVortoPoNumber = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.GraphPointInt.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.GraphPointInt.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.GraphPointInt} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.GraphPointInt.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    value: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.GraphPointInt}
 */
proto.vtv1.GraphPointInt.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.GraphPointInt;
  return proto.vtv1.GraphPointInt.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.GraphPointInt} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.GraphPointInt}
 */
proto.vtv1.GraphPointInt.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.GraphPointInt.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.GraphPointInt.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.GraphPointInt} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.GraphPointInt.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValue();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.vtv1.GraphPointInt.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.GraphPointInt} returns this
 */
proto.vtv1.GraphPointInt.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 value = 2;
 * @return {number}
 */
proto.vtv1.GraphPointInt.prototype.getValue = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.GraphPointInt} returns this
 */
proto.vtv1.GraphPointInt.prototype.setValue = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.TrailerType.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.TrailerType.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.TrailerType} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.TrailerType.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.TrailerType}
 */
proto.vtv1.TrailerType.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.TrailerType;
  return proto.vtv1.TrailerType.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.TrailerType} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.TrailerType}
 */
proto.vtv1.TrailerType.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.TrailerType.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.TrailerType.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.TrailerType} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.TrailerType.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.vtv1.TrailerType.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.TrailerType} returns this
 */
proto.vtv1.TrailerType.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.vtv1.TrailerType.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.TrailerType} returns this
 */
proto.vtv1.TrailerType.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.File.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.File.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.File} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.File.toObject = function(includeInstance, msg) {
  var f, obj = {
    url: jspb.Message.getFieldWithDefault(msg, 1, ""),
    createdAt: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.File}
 */
proto.vtv1.File.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.File;
  return proto.vtv1.File.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.File} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.File}
 */
proto.vtv1.File.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrl(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.File.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.File.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.File} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.File.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUrl();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCreatedAt();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string url = 1;
 * @return {string}
 */
proto.vtv1.File.prototype.getUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.File} returns this
 */
proto.vtv1.File.prototype.setUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string created_at = 2;
 * @return {string}
 */
proto.vtv1.File.prototype.getCreatedAt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.File} returns this
 */
proto.vtv1.File.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.FileWithCanDelete.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.FileWithCanDelete.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.FileWithCanDelete} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.FileWithCanDelete.toObject = function(includeInstance, msg) {
  var f, obj = {
    url: jspb.Message.getFieldWithDefault(msg, 1, ""),
    createdAt: jspb.Message.getFieldWithDefault(msg, 2, ""),
    canDelete: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.FileWithCanDelete}
 */
proto.vtv1.FileWithCanDelete.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.FileWithCanDelete;
  return proto.vtv1.FileWithCanDelete.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.FileWithCanDelete} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.FileWithCanDelete}
 */
proto.vtv1.FileWithCanDelete.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrl(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedAt(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCanDelete(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.FileWithCanDelete.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.FileWithCanDelete.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.FileWithCanDelete} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.FileWithCanDelete.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUrl();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCreatedAt();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCanDelete();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional string url = 1;
 * @return {string}
 */
proto.vtv1.FileWithCanDelete.prototype.getUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.FileWithCanDelete} returns this
 */
proto.vtv1.FileWithCanDelete.prototype.setUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string created_at = 2;
 * @return {string}
 */
proto.vtv1.FileWithCanDelete.prototype.getCreatedAt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.FileWithCanDelete} returns this
 */
proto.vtv1.FileWithCanDelete.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool can_delete = 3;
 * @return {boolean}
 */
proto.vtv1.FileWithCanDelete.prototype.getCanDelete = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vtv1.FileWithCanDelete} returns this
 */
proto.vtv1.FileWithCanDelete.prototype.setCanDelete = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.DriverCertification.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.DriverCertification.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.DriverCertification} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.DriverCertification.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    description: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.DriverCertification}
 */
proto.vtv1.DriverCertification.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.DriverCertification;
  return proto.vtv1.DriverCertification.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.DriverCertification} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.DriverCertification}
 */
proto.vtv1.DriverCertification.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.DriverCertification.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.DriverCertification.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.DriverCertification} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.DriverCertification.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.vtv1.DriverCertification.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.DriverCertification} returns this
 */
proto.vtv1.DriverCertification.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.vtv1.DriverCertification.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.DriverCertification} returns this
 */
proto.vtv1.DriverCertification.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string description = 3;
 * @return {string}
 */
proto.vtv1.DriverCertification.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.DriverCertification} returns this
 */
proto.vtv1.DriverCertification.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.SlimProduct.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.SlimProduct.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.SlimProduct} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.SlimProduct.toObject = function(includeInstance, msg) {
  var f, obj = {
    productId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    productName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    unitId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    unitName: jspb.Message.getFieldWithDefault(msg, 4, ""),
    unitAbbreviation: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.SlimProduct}
 */
proto.vtv1.SlimProduct.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.SlimProduct;
  return proto.vtv1.SlimProduct.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.SlimProduct} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.SlimProduct}
 */
proto.vtv1.SlimProduct.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductName(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUnitId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setUnitName(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setUnitAbbreviation(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.SlimProduct.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.SlimProduct.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.SlimProduct} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.SlimProduct.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProductId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getProductName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getUnitId();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getUnitName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getUnitAbbreviation();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string product_id = 1;
 * @return {string}
 */
proto.vtv1.SlimProduct.prototype.getProductId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.SlimProduct} returns this
 */
proto.vtv1.SlimProduct.prototype.setProductId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string product_name = 2;
 * @return {string}
 */
proto.vtv1.SlimProduct.prototype.getProductName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.SlimProduct} returns this
 */
proto.vtv1.SlimProduct.prototype.setProductName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 unit_id = 3;
 * @return {number}
 */
proto.vtv1.SlimProduct.prototype.getUnitId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.SlimProduct} returns this
 */
proto.vtv1.SlimProduct.prototype.setUnitId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string unit_name = 4;
 * @return {string}
 */
proto.vtv1.SlimProduct.prototype.getUnitName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.SlimProduct} returns this
 */
proto.vtv1.SlimProduct.prototype.setUnitName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string unit_abbreviation = 5;
 * @return {string}
 */
proto.vtv1.SlimProduct.prototype.getUnitAbbreviation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.SlimProduct} returns this
 */
proto.vtv1.SlimProduct.prototype.setUnitAbbreviation = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.Point.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.Point.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.Point} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.Point.toObject = function(includeInstance, msg) {
  var f, obj = {
    lng: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    lat: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.Point}
 */
proto.vtv1.Point.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.Point;
  return proto.vtv1.Point.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.Point} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.Point}
 */
proto.vtv1.Point.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLng(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLat(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.Point.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.Point.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.Point} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.Point.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLng();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = message.getLat();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
};


/**
 * optional double lng = 1;
 * @return {number}
 */
proto.vtv1.Point.prototype.getLng = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.Point} returns this
 */
proto.vtv1.Point.prototype.setLng = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional double lat = 2;
 * @return {number}
 */
proto.vtv1.Point.prototype.getLat = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vtv1.Point} returns this
 */
proto.vtv1.Point.prototype.setLat = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.CostCenter.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.CostCenter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.CostCenter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.CostCenter.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    number: jspb.Message.getFieldWithDefault(msg, 2, ""),
    name: jspb.Message.getFieldWithDefault(msg, 3, ""),
    description: (f = msg.getDescription()) && proto.vtv1.NullableString.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.CostCenter}
 */
proto.vtv1.CostCenter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.CostCenter;
  return proto.vtv1.CostCenter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.CostCenter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.CostCenter}
 */
proto.vtv1.CostCenter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setNumber(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 4:
      var value = new proto.vtv1.NullableString;
      reader.readMessage(value,proto.vtv1.NullableString.deserializeBinaryFromReader);
      msg.setDescription(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.CostCenter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.CostCenter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.CostCenter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.CostCenter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getNumber();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDescription();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.vtv1.NullableString.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.vtv1.CostCenter.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.CostCenter} returns this
 */
proto.vtv1.CostCenter.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string number = 2;
 * @return {string}
 */
proto.vtv1.CostCenter.prototype.getNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.CostCenter} returns this
 */
proto.vtv1.CostCenter.prototype.setNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string name = 3;
 * @return {string}
 */
proto.vtv1.CostCenter.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.CostCenter} returns this
 */
proto.vtv1.CostCenter.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional NullableString description = 4;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.CostCenter.prototype.getDescription = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto.vtv1.NullableString, 4));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.CostCenter} returns this
*/
proto.vtv1.CostCenter.prototype.setDescription = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.CostCenter} returns this
 */
proto.vtv1.CostCenter.prototype.clearDescription = function() {
  return this.setDescription(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.CostCenter.prototype.hasDescription = function() {
  return jspb.Message.getField(this, 4) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.SalesOrder.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.SalesOrder.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.SalesOrder.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.SalesOrder} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.SalesOrder.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    number: jspb.Message.getFieldWithDefault(msg, 2, ""),
    lineItemsList: jspb.Message.toObjectList(msg.getLineItemsList(),
    proto.vtv1.SalesOrderLineItem.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.SalesOrder}
 */
proto.vtv1.SalesOrder.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.SalesOrder;
  return proto.vtv1.SalesOrder.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.SalesOrder} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.SalesOrder}
 */
proto.vtv1.SalesOrder.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setNumber(value);
      break;
    case 4:
      var value = new proto.vtv1.SalesOrderLineItem;
      reader.readMessage(value,proto.vtv1.SalesOrderLineItem.deserializeBinaryFromReader);
      msg.addLineItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.SalesOrder.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.SalesOrder.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.SalesOrder} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.SalesOrder.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getNumber();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLineItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.vtv1.SalesOrderLineItem.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.vtv1.SalesOrder.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.SalesOrder} returns this
 */
proto.vtv1.SalesOrder.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string number = 2;
 * @return {string}
 */
proto.vtv1.SalesOrder.prototype.getNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.SalesOrder} returns this
 */
proto.vtv1.SalesOrder.prototype.setNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated SalesOrderLineItem line_items = 4;
 * @return {!Array<!proto.vtv1.SalesOrderLineItem>}
 */
proto.vtv1.SalesOrder.prototype.getLineItemsList = function() {
  return /** @type{!Array<!proto.vtv1.SalesOrderLineItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vtv1.SalesOrderLineItem, 4));
};


/**
 * @param {!Array<!proto.vtv1.SalesOrderLineItem>} value
 * @return {!proto.vtv1.SalesOrder} returns this
*/
proto.vtv1.SalesOrder.prototype.setLineItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.vtv1.SalesOrderLineItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.SalesOrderLineItem}
 */
proto.vtv1.SalesOrder.prototype.addLineItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.vtv1.SalesOrderLineItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.SalesOrder} returns this
 */
proto.vtv1.SalesOrder.prototype.clearLineItemsList = function() {
  return this.setLineItemsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.SalesOrderLineItem.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.SalesOrderLineItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.SalesOrderLineItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.SalesOrderLineItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    number: jspb.Message.getFieldWithDefault(msg, 2, ""),
    description: (f = msg.getDescription()) && proto.vtv1.NullableString.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.SalesOrderLineItem}
 */
proto.vtv1.SalesOrderLineItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.SalesOrderLineItem;
  return proto.vtv1.SalesOrderLineItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.SalesOrderLineItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.SalesOrderLineItem}
 */
proto.vtv1.SalesOrderLineItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setNumber(value);
      break;
    case 3:
      var value = new proto.vtv1.NullableString;
      reader.readMessage(value,proto.vtv1.NullableString.deserializeBinaryFromReader);
      msg.setDescription(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.SalesOrderLineItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.SalesOrderLineItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.SalesOrderLineItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.SalesOrderLineItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getNumber();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDescription();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.vtv1.NullableString.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.vtv1.SalesOrderLineItem.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.SalesOrderLineItem} returns this
 */
proto.vtv1.SalesOrderLineItem.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string number = 2;
 * @return {string}
 */
proto.vtv1.SalesOrderLineItem.prototype.getNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.SalesOrderLineItem} returns this
 */
proto.vtv1.SalesOrderLineItem.prototype.setNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional NullableString description = 3;
 * @return {?proto.vtv1.NullableString}
 */
proto.vtv1.SalesOrderLineItem.prototype.getDescription = function() {
  return /** @type{?proto.vtv1.NullableString} */ (
    jspb.Message.getWrapperField(this, proto.vtv1.NullableString, 3));
};


/**
 * @param {?proto.vtv1.NullableString|undefined} value
 * @return {!proto.vtv1.SalesOrderLineItem} returns this
*/
proto.vtv1.SalesOrderLineItem.prototype.setDescription = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.SalesOrderLineItem} returns this
 */
proto.vtv1.SalesOrderLineItem.prototype.clearDescription = function() {
  return this.setDescription(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.SalesOrderLineItem.prototype.hasDescription = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * @enum {number}
 */
proto.vtv1.LinehaulType = {
  UNKNOWN: 0,
  EDGE_CONTRACT_TYPE_PER_MILE: 1,
  EDGE_CONTRACT_TYPE_PER_MILEAGE_BRACKET: 2,
  EDGE_CONTRACT_TYPE_POINT_TO_POINT: 3,
  EDGE_CONTRACT_TYPE_POINT_TO_POINT_PER_TON: 4,
  EDGE_CONTRACT_TYPE_PER_HOUR: 5
};

/**
 * @enum {number}
 */
proto.vtv1.DetentionType = {
  EDGE_CONTRACT_ORIGIN_DESTINATION: 0,
  EDGE_CONTRACT_BLANKET_CAP: 1
};

/**
 * @enum {number}
 */
proto.vtv1.OrderStatus = {
  ORDER_STATUS_UNKNOWN: 0,
  ORDER_STATUS_IN_PROGRESS: 1,
  ORDER_STATUS_CANCELLED: 2,
  ORDER_STATUS_COMPLETED: 3,
  ORDER_STATUS_PENDING: 4
};

/**
 * @enum {number}
 */
proto.vtv1.TaskAction = {
  TASK_ACTION_UNKNOWN: 0,
  TASK_ACTION_PICKUP: 1,
  TASK_ACTION_DROPOFF: 2,
  TASK_ACTION_RETURN_PICKUP: 3,
  TASK_ACTION_RETURN_DROPOFF: 4
};

/**
 * @enum {number}
 */
proto.vtv1.ShipmentStatus = {
  SHIPMENT_STATUS_UNKNOWN: 0,
  SHIPMENT_STATUS_PENDING: 1,
  SHIPMENT_STATUS_ASSIGNED: 2,
  SHIPMENT_STATUS_DRIVER_ACCEPTED: 3,
  SHIPMENT_STATUS_COMPLETED: 4,
  SHIPMENT_STATUS_CANCELLED: 5
};

/**
 * @enum {number}
 */
proto.vtv1.ShipmentProgressStatus = {
  SHIPMENT_PROGRESS_UNKNOWN: 0,
  SHIPMENT_PROGRESS_SCHEDULED: 1,
  SHIPMENT_PROGRESS_STARTED: 2,
  SHIPMENT_PROGRESS_AT_ORIGIN: 3,
  SHIPMENT_PROGRESS_EN_ROUTE: 4,
  SHIPMENT_PROGRESS_AT_DESTINATION: 5,
  SHIPMENT_PROGRESS_COMPLETED: 6,
  SHIPMENT_PROGRESS_AT_CARRIER: 7,
  SHIPMENT_PROGRESS_WAITING_FOR_SUPPLIER: 8
};

/**
 * @enum {number}
 */
proto.vtv1.SiteLoadingScheduleType = {
  SCHEDULE_UNKNOWN: 0,
  SCHEDULE_LOADING: 1,
  SCHEDULE_UNLOADING: 2,
  SCHEDULE_ORDERING: 3
};

/**
 * @enum {number}
 */
proto.vtv1.SpotMarketRequestType = {
  SPOT_MARKET_UNKNOWN_TYPE: 0,
  SPOT_MARKET_BLIND_BID: 1,
  SPOT_MARKET_REVERSE_AUCTION: 2,
  SPOT_MARKET_NONE: 3
};

/**
 * @enum {number}
 */
proto.vtv1.SpotMarketRequestShipperType = {
  SUPPLIER_PAYS: 0,
  CONSUMER_PAYS: 1,
  SUPPLIER_CHOICE: 2
};

goog.object.extend(exports, proto.vtv1);
