import { Component, OnInit, ChangeDetectionStrategy, ViewChild } from '@angular/core';
import { ConsumerSitesService } from '../../../singleton/services/node-general/node-sites.service';
import { combineLatest, Observable, of } from 'rxjs';
import { ActiveSite, StringIdName, SlimSite } from '~proto/types/types_pb';
import { Router } from '@angular/router';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  pluck,
  shareReplay,
  startWith,
  switchMap,
  throttleTime,
} from 'rxjs/operators';
import { sortBy } from 'remeda/dist/es';
import { InventoryManagementService } from '../../../modules/node/node-site-management/inventory-management.service';
import { Inventory } from '~proto/node/node_pb';
import {
  UpdateInventoryStockRequest,
  GlobalSearchResponse,
  GlobalSearchResponseInventory,
  GlobalSearchResponseUser,
} from '~proto/node/node_api_pb';
import { SnackbarService } from '../../../singleton/services/snackbar.service';
import { UntypedFormControl } from '@angular/forms';
import Fuse from 'fuse.js';
import { fuse } from '~utilities';
import { SearchService } from '../../services/search-service.service';
import { RouterStateService } from '../../../singleton/services/router-state-service.service';
import { valueTrimmed } from '~utilities/validateTrimmed';
import { MatDialog } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { FeatureFlagService } from '../../../singleton/service/feature-flag.service';

interface Action {
  name: string;
  icon: string | null;
  path: string;
  priority: number;
  default: boolean;
  featureFlag?: string;
}

const actions: Array<Action> = [
  {
    name: 'Create Order',
    icon: './assets/images/list_plus.svg',
    path: '/consumer/orders/create_order',
    priority: 0,
    default: true,
  },
  {
    name: 'Create Advanced Order',
    icon: './assets/images/list_plus.svg',
    path: '/consumer/orders/create_order_v2',
    priority: 0,
    default: true,
  },
  {
    name: 'Multi-Inventory Update',
    icon: './assets/images/orange_pencil.svg',
    path: '/facility_dashboard/multi-update',
    priority: 1,
    default: true,
  },
  {
    name: 'Create Facility',
    icon: './assets/images/folded_map.svg',
    path: '/facility_management/create',
    priority: 2,
    default: true,
  },
  {
    name: 'Create Carrier Contract',
    icon: './assets/images/truck2.svg',
    path: '/carrier_management/new',
    priority: 3,
    default: true,
  },
  {
    name: 'Create Purchase Order',
    icon: './assets/images/list_plus.svg',
    path: '/purchase_orders/purchase_order/create',
    priority: 4,
    default: true,
  },
  {
    name: 'Create Product',
    icon: './assets/images/list_plus.svg',
    path: '/sku_management/create',
    priority: 5,
    default: false,
  },
  {
    name: 'Add User',
    icon: './assets/images/list_plus.svg',
    path: '/users/web/new',
    priority: 6,
    default: false,
  },
  {
    name: 'Notification Settings',
    icon: './assets/images/orange_pencil.svg',
    path: '/profile/notifications',
    priority: 7,
    default: true,
  },
  {
    name: 'AutoForecasting',
    icon: './assets/images/lightning.svg',
    path: '/spot_market/list/landing',
    priority: 8,
    default: false,
  },
  {
    name: 'Create RFQ',
    icon: './assets/images/list_plus.svg',
    path: '/spot_market/create_request',
    priority: 9,
    default: false,
  },
  {
    name: 'Billing Dashboard',
    icon: './assets/images/truck2.svg',
    path: '/billing/invoicing/receivable',
    priority: 10,
    default: false,
  },
  {
    name: 'Draft Purchase Orders',
    icon: './assets/images/payload.svg',
    path: '/draft-pos',
    priority: 11,
    default: true,
    featureFlag: 'draftPurchaseOrders',
  },
];

const searchOptions: Fuse.IFuseOptions<Action> = {
  distance: 100,
  keys: ['name'],
  location: 0,
  minMatchCharLength: 1,
  shouldSort: true,
  threshold: 0.2,
};

@Component({
  selector: 'vt-takeover',
  templateUrl: './takeover.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TakeoverComponent implements OnInit {
  @ViewChild('selectSite') public selectSite;
  public mySites$: Observable<StringIdName.AsObject[]>;

  public selectedSite: ActiveSite.AsObject;
  public inventories: Observable<Array<Inventory.AsObject>>;
  public selectedInv: Inventory.AsObject;

  public sitesWithBatchesToComplete$: Observable<ActiveSite.AsObject[]>;
  public sitesWithInventories$: Observable<ActiveSite.AsObject[]>;

  public searchBox = new UntypedFormControl();

  public searchResults$: Observable<GlobalSearchResponse.AsObject>;
  public actions$: Observable<Action[]>;

  public stockVal = 0;

  constructor(
    private sitesService: ConsumerSitesService,
    private snackbarService: SnackbarService,
    private matDialogService: MatDialog,
    private routerLink: Router,
    private routerState: RouterStateService,
    private searchService: SearchService,
    private featureFlagService: FeatureFlagService,
  ) {}

  public ngOnInit(): void {
    this.searchResults$ = this.searchBox.valueChanges.pipe(
      startWith(''),
      map((value) => (!!value ? value : '')),
      switchMap((value) => this.searchService.search(value)),
      shareReplay(1),
    );

    this.actions$ = combineLatest([
      of(actions).pipe(map(fuse(searchOptions))),
      this.searchBox.valueChanges.pipe(startWith('')),
    ]).pipe(
      map(([fused, searchTerm]) => {
        if (!searchTerm) {
          return fused.data.filter((value) => value.default).sort((a, b) => a.priority - b.priority);
        }
        return fused.fuse
          .search(searchTerm)
          .map((r) => r.item)
          .sort((a, b) => a.priority - b.priority);
      }),
    );
  }

  public closeDialogs() {
    this.matDialogService.closeAll();
  }

  public selectInv($event: MatSelectChange) {
    this.selectedInv = $event.value;
    this.stockVal = this.selectedInv?.stock;
  }
}
