/* Angular Imports */
import { Injectable } from '@angular/core';

/* Material Imports */

/* Environment Import */
import { environment } from '../../../environments/environment';
import { IntercomService } from 'src/app/intercom.service';
import { lastValueFrom } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';

// eslint-disable-next-line @typescript-eslint/naming-convention
declare const Intercom: any;

@Injectable({
  providedIn: 'root',
})
export class SnackbarService {
  constructor(private matSnackBar: MatSnackBar) {}

  /**
   * Takes in a general message and displays it accordingly
   */
  public showMessage(message: string, messageDuration: number = 2000): void {
    this.matSnackBar.open(`${message}`, null, { duration: messageDuration });
  } // end o fshowMessage

  /**
   * Takes in an error message and displays it accordingly
   */
  public showError(message: string, linkToSupport = false, messageDuration: number = 3000): void {
    if (linkToSupport && typeof Intercom !== 'undefined') {
      const ref = this.matSnackBar.open(message, 'Contact Support', {
        panelClass: ['snackbar-error'],
        duration: messageDuration,
      });
      const sub = ref.onAction().subscribe(() => {
        Intercom('showNewMessage');
      });
      lastValueFrom(ref.afterDismissed()).then(() => {
        if (sub) {
          sub.unsubscribe();
        }
      });
    } else {
      this.matSnackBar.open(message, null, {
        panelClass: ['snackbar-error'],
        duration: messageDuration,
      });
    }
  } // end of showError
} // end of service
