<div class="bg-indigo-900 w-full h-full absolute top-0 left-0 flex flex-col items-center p-2">
  <div class="p-2 mb-2 bg-white rounded w-full">
    <mat-form-field class="w-full">
      <mat-label>Search</mat-label>
      <input matInput [formControl]="searchBox" />
    </mat-form-field>
  </div>
  <div class="flex flex-row justify-around overflow-x-auto p-2 w-full">
    <ng-container *ngIf="actions$ | async as actions">
      <div class="mx-2" *ngIf="actions.length">
        <div class="text-lg text-white font-bold">Actions</div>
        <ng-container *ngFor="let action of actions; trackBy: actions | trackByKey2 : 'name'">
          <a
            *ngIf="!action.featureFlag; else featureFlagged"
            mat-dialog-close=""
            [routerLink]="action.path"
            class="flex items-center grid grid-cols-6 bg-indigo-800 hover:bg-indigo-700 p-2 rounded-lg mt-1 cursor-pointer"
          >
            <img *ngIf="!!action.icon" class="mr-3 col-span-1 w-3 h-3" [src]="action.icon" /><span
              class="text-2xl text-white font-semibold col-span-5 break-words"
              >{{ action.name }}</span
            >
          </a>
          <ng-template #featureFlagged>
            <a
              *vtFeatureFlag="action.featureFlag"
              mat-dialog-close=""
              [routerLink]="action.path"
              class="flex items-center grid grid-cols-6 bg-indigo-800 hover:bg-indigo-700 p-2 rounded-lg mt-1 cursor-pointer"
            >
              <img *ngIf="!!action.icon" class="mr-3 col-span-1 w-3 h-3" [src]="action.icon" /><span
                class="text-2xl text-white font-semibold col-span-5 break-words"
                >{{ action.name }}</span
              >
            </a>
          </ng-template>
        </ng-container>
      </div>
    </ng-container>
    <ng-container *ngIf="(searchResults$ | async)?.ordersList as orders">
      <div class="mx-2" *ngIf="orders.length">
        <div class="text-lg text-white font-bold">Orders</div>
        <a
          mat-dialog-close=""
          [routerLink]="
            order.fulfillmentId.valid && !order.supplier
              ? ['/', 'fulfillment', 'shop', 'order', order.fulfillmentId.str, 'details']
              : ['/', 'orders', 'order', order.id]
          "
          *ngFor="let order of orders; trackBy: 'name' | trackByKey"
          class="flex flex-col justify-center items-start bg-indigo-800 hover:bg-indigo-700 pl-3 pr-5 py-3 rounded-lg mt-1 cursor-pointer"
        >
          <span class="text-2xl text-white font-semibold col-span-5 break-words">
            {{ order.id }}
          </span>
        </a>
      </div>
      <ng-container *ngIf="(searchResults$ | async)?.fulfillmentsList as fulfillments">
        <div class="mx-2" *ngIf="fulfillments.length">
          <div class="text-lg text-white font-bold" *ngIf="orders?.length === 0">Orders</div>
          <a
            mat-dialog-close=""
            [routerLink]="
              !ful.supplier
                ? ['/', 'fulfillment', 'shop', 'order', ful.id, 'details']
                : ['/', 'fulfillment', 'fulfillment', 'order', ful.id, 'fulfill']
            "
            *ngFor="let ful of fulfillments; trackBy: 'name' | trackByKey"
            class="flex flex-col justify-center items-start bg-indigo-800 hover:bg-indigo-700 pl-3 pr-5 py-3 rounded-lg mt-1 cursor-pointer"
          >
            <span class="text-2xl text-white font-semibold col-span-5 break-words">
              {{ ful.id }}
            </span>
          </a>
        </div>
      </ng-container>
    </ng-container>
    <ng-container *vtHideFromInventoryOnly>
      <ng-container *ngIf="(searchResults$ | async)?.purchaseOrdersList as purchaseOrders">
        <div class="mx-2" *ngIf="purchaseOrders.length">
          <div class="text-lg text-white font-semibold">Purchase Orders</div>
          <a
            mat-dialog-close
            [routerLink]="['purchase_orders', 'purchase_order', purchaseOrder.id]"
            *ngFor="let purchaseOrder of purchaseOrders; trackBy: 'name' | trackByKey"
            class="flex flex-col justify-center items-start bg-indigo-800 hover:bg-indigo-700 pl-3 pr-5 py-3 rounded-lg mt-1 cursor-pointer"
          >
            <span class="text-2xl text-white font-semibold col-span-5 break-words">
              {{ purchaseOrder.name }}
            </span>
          </a>
        </div>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="(searchResults$ | async)?.inventoriesList as inventories">
      <div class="mx-2" *ngIf="inventories.length">
        <div class="text-lg text-white font-bold">Inventories</div>
        <a
          mat-dialog-close
          [routerLink]="['/facility_management', 'site', inventory.siteId, 'inventory', inventory.inventoryId]"
          *ngFor="let inventory of inventories; trackBy: 'name' | trackByKey"
          class="flex flex-col justify-center items-start bg-indigo-800 hover:bg-indigo-700 pl-3 pr-5 py-3 rounded-lg mt-1 cursor-pointer"
        >
          <span class="text-xs text-gray-700">{{ inventory.siteName }}</span>
          <span class="text-2xl text-white font-semibold col-span-5 break-words">
            {{ inventory.productName }}
          </span>
        </a>
      </div>
    </ng-container>
    <ng-container *ngIf="(searchResults$ | async)?.sitesList as sites">
      <div class="mx-2" *ngIf="sites.length">
        <div class="text-lg text-white font-bold">Sites</div>
        <a
          mat-dialog-close
          [routerLink]="['/facility_management', 'site', site.id]"
          *ngFor="let site of sites; trackBy: 'name' | trackByKey"
          class="flex flex-col justify-center items-start bg-indigo-800 hover:bg-indigo-700 pl-3 pr-5 py-3 rounded-lg mt-1 cursor-pointer"
        >
          <span class="text-2xl text-white font-semibold col-span-5 break-words">
            {{ site.name }}
          </span>
        </a>
      </div>
    </ng-container>
    <ng-container *vtHideFromInventoryOnly>
      <ng-container *ngIf="(searchResults$ | async)?.contractsList as carrierContracts">
        <div class="mx-2" *ngIf="carrierContracts.length">
          <div class="text-lg text-white font-bold">Carrier Contracts</div>
          <a
            mat-dialog-close
            [routerLink]="['carrier_management', contracts.id]"
            *ngFor="let contracts of carrierContracts; trackBy: 'name' | trackByKey"
            class="flex flex-col justify-center items-start bg-indigo-800 hover:bg-indigo-700 pl-3 pr-5 py-3 rounded-lg mt-1 cursor-pointer"
          >
            <span class="text-2xl text-white font-semibold col-span-5 break-words">
              {{ contracts.name }}
            </span>
          </a>
        </div>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="(searchResults$ | async)?.usersList as users">
      <div class="mx-2" *ngIf="users.length">
        <div class="text-lg text-white font-bold">Users</div>
        <a
          mat-dialog-close
          [routerLink]="user.driver ? ['users', 'drivers', user.id] : ['users', 'web', user.id]"
          *ngFor="let user of users; trackBy: 'name' | trackByKey"
          class="flex flex-col justify-center items-start bg-indigo-800 hover:bg-indigo-700 pl-3 pr-5 py-3 rounded-lg mt-1 cursor-pointer"
        >
          <span class="text-2xl text-white font-semibold col-span-5 break-words">{{ user.name }}</span>
        </a>
      </div>
    </ng-container>
  </div>
  <mat-icon mat-dialog-close class="text-gray-600 my-2 mx-auto cursor-pointer">close</mat-icon>
</div>
<!--<div class="flex flex-col mt-3" *ngIf="!action; else selectSite">
  <a
    (click)="updateAction('batch')"
    class="flex items-center grid grid-cols-10 bg-indigo-800 hover:bg-indigo-700 pl-3 pr-5 py-3 rounded-lg mt-1 cursor-pointer"
    ><img class="mr-3 col-span-1 w-3 h-3" src="./assets/images/check_clipboard.svg" /><span
      class="text-2xl text-white font-semibold col-span-6"
      >Complete Batch</span
    ></a
  >
  <a
    (click)="updateAction('inv-edit')"
    class="flex items-center grid grid-cols-10 bg-indigo-800 hover:bg-indigo-700 pl-3 pr-5 py-3 rounded-lg mt-2 cursor-pointer"
    ><img class="mr-3 col-span-1 w-3 h-3" src="./assets/images/orange_pencil.svg" /><span
      class="text-2xl text-white font-semibold col-span-6"
      >Update Inventory</span
    >
  </a>
  <a
    (click)="updateAction('plan')"
    class="flex items-center grid grid-cols-10 bg-indigo-800 hover:bg-indigo-700 pl-3 pr-5 py-3 rounded-lg mt-2 cursor-pointer"
    ><img class="mr-3 col-span-1 w-3 h-3" src="./assets/images/list_plus.svg" /><span
      class="text-2xl text-white font-semibold col-span-6"
      >Create Production Plan</span
    ></a
  >
  <a
    routerLink="/consumer/orders/create_order"
    mat-dialog-close
    class="flex items-center grid grid-cols-10 bg-indigo-800 hover:bg-indigo-700 pl-3 pr-5 py-3 rounded-lg mt-2 cursor-pointer"
    ><img class="mr-3 col-span-1 w-3 h-3" src="./assets/images/list_plus.svg" /><span
      class="text-2xl text-white font-semibold col-span-6"
      >Create New Order</span
    ></a
  >
  <a
    (click)="updateAction('inv')"
    class="flex items-center flex grid grid-cols-10 bg-indigo-800 hover:bg-indigo-700 pl-3 pr-5 py-3 rounded-lg mb-2 mt-2 cursor-pointer"
    ><img class="mr-3 col-span-1 w-3 h-3" src="./assets/images/error_clipboard.svg" /><span
      class="text-2xl text-white font-semibold col-span-8"
      >Report Issue</span
    >
  </a>
  <mat-icon mat-dialog-close class="text-gray-600 mx-auto cursor-pointer">close</mat-icon>
</div>
</div>

<ng-template #selectSite>
<style>
  .not-empty-select.mat-selected {
    color: white !important;
  }
  .not-empty-select.mat-selected {
    color: white !important;
  }

  :host /deep/ .mat-select-value-text {
    color: white !important;
  }

  :host /deep/ .mat-select-placeholder {
    color: white !important;
  }

  :host /deep/ .mat-form-field-appearance-legacy .mat-hint {
    color: inherit !important;
  }
</style>
<div class="flex flex-col text-white">
  <mat-icon
    class="text-gray-600 mx-auto cursor-pointer"
    (click)="action = ''; selectedSite = null; inventories = null; selectedInv = null"
    >arrow_back</mat-icon
  >
  <div class="mt-1 bg-indigo-800 rounded-lg p-6 flex flex-col items-center">
    <mat-form-field style="width: 300px;" class="pb-3">
      <mat-label class="text-white">Select Facility</mat-label>
      <mat-placeholder class="text-white"></mat-placeholder>
      <mat-select class="text-white border-white" [(value)]="selectedSite" (selectionChange)="makeLink($event)">
        <mat-option
          *ngFor="
            let site of action === 'batch'
              ? (sitesWithBatchesToComplete$ | async)
              : action === 'inv-edit'
              ? (sitesWithInventories$ | async)
              : (mySites$ | async);
            trackBy: 'id' | trackByKey
          "
          [value]="site"
        >
          {{ site.name }}
        </mat-option>
        <mat-option *ngIf="(sitesWithBatchesToComplete$ | async).length === 0 && action === 'batch'">
          No sites have batches to complete
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field style="width: 300px;" class="pb-3" *ngIf="inventories | async as invs">
      <mat-label class="text-white">Select Inventory</mat-label>
      <mat-placeholder class="text-white"></mat-placeholder>
      <mat-select (selectionChange)="selectInv($event)">
        <mat-option *ngIf="invs.length === 0">No Inventory</mat-option>
        <mat-option *ngFor="let inv of invs; trackBy: 'id' | trackByKey" [value]="inv">{{
          inv.productName
        }}</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field style="width: 300px;" class="pb-3" *ngIf="selectedInv">
      <mat-label class="text-white">New stock level ({{ selectedInv.productUnitAbbreviation }})</mat-label>
      <mat-placeholder class="text-white"></mat-placeholder>
      <input matInput type="text" vtNumberInput [(ngModel)]="stockVal" />
      <mat-hint class="text-white"
        >Max capacity is {{ selectedInv.capacity | number }} {{ selectedInv.productUnitAbbreviation }}</mat-hint
      >
    </mat-form-field>
    <vt-button *ngIf="selectedInv" class="mt-1" type="button" color="indigo" (click)="updateStock()"
      >Update</vt-button
    >
  </div>
</div>
</ng-template>
-->
