<ng-template #NatoHelper>
  <p style="font-variant: small-caps;" *ngFor="let natoLine of natoReading; trackBy: null | trackByKey">
    {{ natoLine }}
  </p>
</ng-template>

<span class="flex" max-width="400"
  >{{ copyOfWord }}
  <img src="/assets/images/info.svg" alt="info" class="ml-1" [tooltip]="NatoHelper" content-type="template" />
</span>
