import { Pipe, PipeTransform } from '@angular/core';
import { NullableDouble } from '~proto/types/types_pb';

@Pipe({
  name: 'checkInventoryThreshold',
})
export class CheckInventoryThresholdPipe implements PipeTransform {
  public transform(value: {
    targetZeroStock: boolean;
    stock: number;
    warningThreshold: NullableDouble.AsObject;
  }): boolean {
    if (!value.warningThreshold.valid) {
      return false;
    }
    return value.targetZeroStock
      ? value.stock >= value.warningThreshold.pb_double
      : value.stock <= value.warningThreshold.pb_double;
  }
}
