import { Pipe, PipeTransform } from '@angular/core';
import { isDate, isValid, add } from 'date-fns';
import { stringOrDateToDate } from '~utilities/stringOrDateToString';

@Pipe({
  name: 'addMinutesToDate',
})
export class AddMinutesToDatePipe implements PipeTransform {
  public transform(value: Date | string, minuteToAdd: number): Date | string {
    if (!value) {
      return '—';
    }
    const asDate = stringOrDateToDate(value);
    // If this still isn't a valid date, just return that value, whatever it is
    if (!isValid(asDate)) {
      return value;
    }
    return add(asDate, { minutes: minuteToAdd });
  }
}
