import { ShipmentStatusMap, ShipmentStatus } from '~proto/types/types_pb';

interface HasStatus {
  status: ShipmentStatusMap[keyof ShipmentStatusMap];
}

export const isPending = (s: HasStatus): boolean => s.status === ShipmentStatus.SHIPMENT_STATUS_PENDING;

export const isAssigned = (s: HasStatus): boolean => s.status === ShipmentStatus.SHIPMENT_STATUS_ASSIGNED;

export const isDriverAccepted = (s: HasStatus): boolean => s.status === ShipmentStatus.SHIPMENT_STATUS_DRIVER_ACCEPTED;

export const isInProgress = (s: HasStatus): boolean => isAssigned(s) || isDriverAccepted(s);

export const isCompleted = (s: HasStatus): boolean => s.status === ShipmentStatus.SHIPMENT_STATUS_COMPLETED;

export const isCancelled = (s: HasStatus): boolean => s.status === ShipmentStatus.SHIPMENT_STATUS_CANCELLED;

export const isEnded = (s: HasStatus): boolean => isCompleted(s) || isCancelled(s);
