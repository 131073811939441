import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'arrayFilterTruthy',
})
export class ArrayFilterTruthyPipe implements PipeTransform {
  public transform<T>(value: T[]): T[] {
    return value.filter((value1) => !!value1);
  }
}
