import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from './material/material.module';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';

const tooltipOptions = {
  placement: 'right',
  'show-delay': 200,
  'tooltip-class': 'tooltip-overrides',
};

import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { TooltipModule } from 'ng2-tooltip-directive';
import {
  CurrencyInputDirective,
  HasAccountFeatureDirective,
  NumberInputDirective,
  PageTitleDirective,
  TrackFormFocusDirective,
} from './directives';
import { TrackByKey } from './pipes';
import {
  PhonePipe,
  PlusMorePipe,
  ShipmentStatusPipe,
  StandardDatePipe,
  TaskActionPipe,
  MinutesToHumanPipe,
  StandardTimePipe,
  NPTFromNowPipe,
  IsAfterInMinutes,
  ShipmentProgressStatusPipe,
  OrderStatusPipe,
  SCSStatusPipe,
  StatusOrderPipe,
} from './pipes';

import { NavComponent, SideNavComponent } from './_layout';
import { ButtonComponent, ExtendedNatoComponent, MultiNumberBadgeComponent, NumberBadgeComponent } from './components';
import { AgoPipe } from './pipes/ago.pipe';
import { FromNowPipe } from './pipes/from-now.pipe';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { ApproxBigNumberPipe } from './pipes/approx-big-number.pipe';
import { MiniBarChartComponent } from './components/mini-bar-chart/mini-bar-chart.component';
import { TakeoverComponent } from './_layout/takeover/takeover.component';
import { AddMinutesToDatePipe } from './pipes/add-minutes-to-date.pipe';
import { CurrencyInPenniesPipe } from './pipes';
import { SortIndicatorComponent } from './components/sort-indicator/sort-indicator.component';
import { SpotMarketTypePipe } from './pipes/spot-market-type.pipe';
import { JoinWithAndPipe } from './pipes/join-with-and.pipe';
import { MatNativeDateModule } from '@angular/material/core';
import { InvoiceStatusPipe } from './pipes';
import { ChargeTypePipe } from './pipes/charge-type.pipe';
import { JoinWithOrPipe } from './pipes/join-with-or.pipe';
import { IsAfterDate } from './pipes/isAfterDate';
import { DashIfNotValidPipe } from './pipes/dash-if-not-valid.pipe';
import { IsBeforeInMinutes } from './pipes/isBeforeInMinutes.pipe';
import { SingularUnitPipe } from './pipes/singular-unit.pipe';
import { FeatureFlagDirective } from './directives/feature-flag.directive';
import { DatePickerDirective } from './directives';
import { NegativeMoneyDirective } from './directives/negative-money.directive';
import { ToDatePipe } from './pipes/to-date.pipe';
import { CountdownPipe } from './pipes/countdown.pipe';
import { PluralPipe } from './pipes/plural.pipe';
import { MapPipe } from './pipes/map.pipe';
import { SumPipe } from './pipes/sum.pipe';
import { NotViewOnlyDirective } from './directives/not-view-only.directive';
import { ViewOnlyDisableFormDirective } from './directives/view-only-disable-form.directive';
import { TrackByKey2Pipe } from './pipes/track-by-key-2.pipe';
import { HideFromInventoryOnlyDirective } from './directives/hide-from-inventory-only.directive';
import { CompareWithPipe } from './pipes/compare-with.pipe';
import { IsImagePipe } from './pipes/is-image.pipe';
import { FileNamePipe } from './pipes/file-name.pipe';
import { ArrayFilterTruthyPipe } from './pipes/array-filter-truthy.pipe';
import { FilterPipe } from './pipes/filter.pipe';
import { HasPermissionsDirective } from './directives/has-permissions.directive';
import { NgxMatIntlTelInputComponent } from 'ngx-mat-intl-tel-input';
import { JoinWithCommaPipe } from './pipes/join-with-comma.pipe';
import { CheckInventoryThresholdPipe } from './pipes/check-inventory-theshold.pipe';
import { IsCpgDirective } from './directives/is-cpg.directive';
import { CpgSideNavComponent } from './_layout/side-nav/cpg-side-nav/cpg-side-nav.component';
import { RemoveAriaOwnsDirective } from './directives/remove-aria-owns.directive';

@NgModule({
  declarations: [
    ButtonComponent,
    PhonePipe,
    HasAccountFeatureDirective,
    TaskActionPipe,
    TrackByKey,
    ExtendedNatoComponent,
    ShipmentStatusPipe,
    StandardDatePipe,
    NumberBadgeComponent,
    MultiNumberBadgeComponent,
    CurrencyInputDirective,
    NumberInputDirective,
    TrackFormFocusDirective,
    PlusMorePipe,
    NavComponent,
    AgoPipe,
    MinutesToHumanPipe,
    FromNowPipe,
    SafeHtmlPipe,
    NegativeMoneyDirective,
    SideNavComponent,
    ApproxBigNumberPipe,
    SpotMarketTypePipe,
    PageTitleDirective,
    StandardTimePipe,
    MiniBarChartComponent,
    TakeoverComponent,
    NPTFromNowPipe,
    AddMinutesToDatePipe,
    CurrencyInPenniesPipe,
    SortIndicatorComponent,
    IsAfterInMinutes,
    IsBeforeInMinutes,
    ShipmentProgressStatusPipe,
    InvoiceStatusPipe,
    OrderStatusPipe,
    JoinWithAndPipe,
    JoinWithOrPipe,
    JoinWithCommaPipe,
    ChargeTypePipe,
    IsAfterDate,
    DashIfNotValidPipe,
    SCSStatusPipe,
    SingularUnitPipe,
    StatusOrderPipe,
    FeatureFlagDirective,
    DatePickerDirective,
    ToDatePipe,
    CountdownPipe,
    PluralPipe,
    MapPipe,
    SumPipe,
    NotViewOnlyDirective,
    ViewOnlyDisableFormDirective,
    TrackByKey2Pipe,
    HideFromInventoryOnlyDirective,
    CompareWithPipe,
    IsImagePipe,
    FileNamePipe,
    ArrayFilterTruthyPipe,
    FilterPipe,
    HasPermissionsDirective,
    JoinWithCommaPipe,
    CheckInventoryThresholdPipe,
    IsCpgDirective,
    CpgSideNavComponent,
    CpgSideNavComponent,
    RemoveAriaOwnsDirective,
  ],
  exports: [
    ButtonComponent,
    PhonePipe,
    HasAccountFeatureDirective,
    TaskActionPipe,
    TrackByKey,
    ExtendedNatoComponent,
    ShipmentStatusPipe,
    StandardDatePipe,
    NumberBadgeComponent,
    MultiNumberBadgeComponent,
    CurrencyInputDirective,
    NumberInputDirective,
    TrackFormFocusDirective,
    PlusMorePipe,
    NavComponent,
    MaterialModule,
    TooltipModule,
    AgoPipe,
    MinutesToHumanPipe,
    SpotMarketTypePipe,
    NgxMaterialTimepickerModule,
    FromNowPipe,
    NgxChartsModule,
    SafeHtmlPipe,
    NgxMatSelectSearchModule,
    NegativeMoneyDirective,
    NgxMatIntlTelInputComponent,
    SideNavComponent,
    ApproxBigNumberPipe,
    PageTitleDirective,
    StandardTimePipe,
    MatNativeDateModule,
    MiniBarChartComponent,
    NPTFromNowPipe,
    AddMinutesToDatePipe,
    CurrencyInPenniesPipe,
    SortIndicatorComponent,
    IsAfterInMinutes,
    ShipmentProgressStatusPipe,
    InvoiceStatusPipe,
    OrderStatusPipe,
    JoinWithAndPipe,
    JoinWithOrPipe,
    JoinWithCommaPipe,
    IsAfterDate,
    IsBeforeInMinutes,
    DashIfNotValidPipe,
    SCSStatusPipe,
    SingularUnitPipe,
    StatusOrderPipe,
    FeatureFlagDirective,
    DatePickerDirective,
    ToDatePipe,
    CountdownPipe,
    PluralPipe,
    MapPipe,
    SumPipe,
    NotViewOnlyDirective,
    ViewOnlyDisableFormDirective,
    TrackByKey2Pipe,
    HideFromInventoryOnlyDirective,
    CompareWithPipe,
    IsImagePipe,
    FileNamePipe,
    ChargeTypePipe,
    ArrayFilterTruthyPipe,
    FilterPipe,
    HasPermissionsDirective,
    CheckInventoryThresholdPipe,
    IsCpgDirective,
    RemoveAriaOwnsDirective,
  ],
  imports: [
    RouterModule,
    CommonModule,
    MaterialModule,
    ReactiveFormsModule,
    NgxMaterialTimepickerModule,
    TooltipModule.forRoot(tooltipOptions),
    NgxChartsModule,
    NgxMatSelectSearchModule,
    FormsModule,
    NgxMatIntlTelInputComponent,
  ],
})
export class SharedModule {}
