import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'vt-number-badge',
  templateUrl: './number-badge.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NumberBadgeComponent implements OnInit {
  @Input() public count = 0;
  @Input() public bgClass = 'bg-indigo-600';
  @Input() public showIfZero = false;
  @Input() public showMargin = true;

  constructor() {}

  public ngOnInit(): void {}
}
