// Stolen and modified from https://github.com/bameyrick/sort-by-typescript/blob/master/src/index.ts which doesn't support nulls as the object value

export const sortBy = <T = any>(...properties: Array<string>): ((a: T, b: T) => number) => (
  obj1: any,
  obj2: any,
): number => {
  const props = properties.filter((prop) => typeof prop === 'string') as string[];
  let i = 0;
  let result = 0;

  const numberOfProperties = props.length;

  while (result === 0 && i < numberOfProperties) {
    result = sort(props[i])(obj1, obj2);
    i++;
  }

  return result;
};

const sort = (property: string): any => {
  let sortOrder = 1;

  if (property[0] === '-') {
    sortOrder = -1;
    property = property.substr(1);
  }

  const transformer = (index: string, value: any) => {
    if (Array.isArray(value)) {
      // This will sort the array by the length then alpha by first entry if a string
      const response = value.length;
      if (value.length && typeof value[0] === 'string') {
        return `${response}${value[0].toLocaleLowerCase()}`;
      }
      return value.length;
    }
    return typeof value === 'string' ? value.toLocaleLowerCase() : value;
  };

  return (a: any, b: any): number => {
    let result = 0;

    const mappedA = transformer(property, objectPath(a, property));
    const mappedB = transformer(property, objectPath(b, property));

    if (!mappedA && mappedB) {
      result = 1 * sortOrder;
    } else if (mappedA && !mappedB) {
      result = -1 * sortOrder;
    } else if (mappedA < mappedB) {
      result = -1;
    } else if (mappedA > mappedB) {
      result = 1;
    }

    return result * sortOrder;
  };
};

const objectPath = (object: any, path: string): any => {
  const pathParts = path.split('.');

  let result: any = object;

  pathParts.forEach((part) => {
    result = result[part];
  });

  return result;
};
