import { ChangeDetectionStrategy, Component } from '@angular/core';
import { SideNavComponent } from '../side-nav.component';

@Component({
  selector: 'vt-cpg-side-nav',
  templateUrl: './cpg-side-nav.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CpgSideNavComponent {
  constructor(public sideNav: SideNavComponent) {}
}
