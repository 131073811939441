// source: proto/vorto/notification/notification_api.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var proto_vorto_types_types_pb = require('../../../proto/vorto/types/types_pb.js');
goog.object.extend(proto, proto_vorto_types_types_pb);
var proto_vorto_notification_notification_pb = require('../../../proto/vorto/notification/notification_pb.js');
goog.object.extend(proto, proto_vorto_notification_notification_pb);
goog.exportSymbol('proto.vtv1.NotificationUserPreferencesResponse', null, global);
goog.exportSymbol('proto.vtv1.UpdatePreferenceRequest', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.UpdatePreferenceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vtv1.UpdatePreferenceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.UpdatePreferenceRequest.displayName = 'proto.vtv1.UpdatePreferenceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vtv1.NotificationUserPreferencesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vtv1.NotificationUserPreferencesResponse.repeatedFields_, null);
};
goog.inherits(proto.vtv1.NotificationUserPreferencesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vtv1.NotificationUserPreferencesResponse.displayName = 'proto.vtv1.NotificationUserPreferencesResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.UpdatePreferenceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.UpdatePreferenceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.UpdatePreferenceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.UpdatePreferenceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    notificationTypeId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    sendFrequencyMinutes: (f = msg.getSendFrequencyMinutes()) && proto_vorto_types_types_pb.NullableInt64.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.UpdatePreferenceRequest}
 */
proto.vtv1.UpdatePreferenceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.UpdatePreferenceRequest;
  return proto.vtv1.UpdatePreferenceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.UpdatePreferenceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.UpdatePreferenceRequest}
 */
proto.vtv1.UpdatePreferenceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setNotificationTypeId(value);
      break;
    case 2:
      var value = new proto_vorto_types_types_pb.NullableInt64;
      reader.readMessage(value,proto_vorto_types_types_pb.NullableInt64.deserializeBinaryFromReader);
      msg.setSendFrequencyMinutes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.UpdatePreferenceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.UpdatePreferenceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.UpdatePreferenceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.UpdatePreferenceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNotificationTypeId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSendFrequencyMinutes();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_vorto_types_types_pb.NullableInt64.serializeBinaryToWriter
    );
  }
};


/**
 * optional string notification_type_id = 1;
 * @return {string}
 */
proto.vtv1.UpdatePreferenceRequest.prototype.getNotificationTypeId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vtv1.UpdatePreferenceRequest} returns this
 */
proto.vtv1.UpdatePreferenceRequest.prototype.setNotificationTypeId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional NullableInt64 send_frequency_minutes = 2;
 * @return {?proto.vtv1.NullableInt64}
 */
proto.vtv1.UpdatePreferenceRequest.prototype.getSendFrequencyMinutes = function() {
  return /** @type{?proto.vtv1.NullableInt64} */ (
    jspb.Message.getWrapperField(this, proto_vorto_types_types_pb.NullableInt64, 2));
};


/**
 * @param {?proto.vtv1.NullableInt64|undefined} value
 * @return {!proto.vtv1.UpdatePreferenceRequest} returns this
*/
proto.vtv1.UpdatePreferenceRequest.prototype.setSendFrequencyMinutes = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vtv1.UpdatePreferenceRequest} returns this
 */
proto.vtv1.UpdatePreferenceRequest.prototype.clearSendFrequencyMinutes = function() {
  return this.setSendFrequencyMinutes(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vtv1.UpdatePreferenceRequest.prototype.hasSendFrequencyMinutes = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vtv1.NotificationUserPreferencesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vtv1.NotificationUserPreferencesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.vtv1.NotificationUserPreferencesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vtv1.NotificationUserPreferencesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.NotificationUserPreferencesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    groupsList: jspb.Message.toObjectList(msg.getGroupsList(),
    proto_vorto_notification_notification_pb.NotificationGroup.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vtv1.NotificationUserPreferencesResponse}
 */
proto.vtv1.NotificationUserPreferencesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vtv1.NotificationUserPreferencesResponse;
  return proto.vtv1.NotificationUserPreferencesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vtv1.NotificationUserPreferencesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vtv1.NotificationUserPreferencesResponse}
 */
proto.vtv1.NotificationUserPreferencesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_vorto_notification_notification_pb.NotificationGroup;
      reader.readMessage(value,proto_vorto_notification_notification_pb.NotificationGroup.deserializeBinaryFromReader);
      msg.addGroups(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vtv1.NotificationUserPreferencesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vtv1.NotificationUserPreferencesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vtv1.NotificationUserPreferencesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vtv1.NotificationUserPreferencesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGroupsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto_vorto_notification_notification_pb.NotificationGroup.serializeBinaryToWriter
    );
  }
};


/**
 * repeated NotificationGroup groups = 1;
 * @return {!Array<!proto.vtv1.NotificationGroup>}
 */
proto.vtv1.NotificationUserPreferencesResponse.prototype.getGroupsList = function() {
  return /** @type{!Array<!proto.vtv1.NotificationGroup>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_vorto_notification_notification_pb.NotificationGroup, 1));
};


/**
 * @param {!Array<!proto.vtv1.NotificationGroup>} value
 * @return {!proto.vtv1.NotificationUserPreferencesResponse} returns this
*/
proto.vtv1.NotificationUserPreferencesResponse.prototype.setGroupsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.vtv1.NotificationGroup=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vtv1.NotificationGroup}
 */
proto.vtv1.NotificationUserPreferencesResponse.prototype.addGroups = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.vtv1.NotificationGroup, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vtv1.NotificationUserPreferencesResponse} returns this
 */
proto.vtv1.NotificationUserPreferencesResponse.prototype.clearGroupsList = function() {
  return this.setGroupsList([]);
};


goog.object.extend(exports, proto.vtv1);
