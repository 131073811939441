<div class="py-3">
  <mat-form-field>
    <mat-label>Switch account to</mat-label>
    <mat-select [formControl]="accountSelectorFC" (opened)="search?.focus()">
      <div class="sticky top-0 bg-white z-10">
        <mat-form-field class="w-full px-2 pt-1">
          <mat-label>Search</mat-label>
          <input matInput [formControl]="searchField" autocomplete="off" #search />
        </mat-form-field>
      </div>
      <mat-option
        *ngFor="let account of allowedAccounts$ | async; trackBy: allowedAccounts$ | trackByKey2: 'id'"
        [value]="account.id"
      >
        {{ account.name }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>
